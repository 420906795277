import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  padding: "1.5rem",
  ".ant-table-content":{
    minHeight: "687.95px"
  },
  ".ant-table-thead > tr:not(:last-child) > th[colspan]": {
    backgroundColor: `${Colors.columnHeaderBackground} !important`,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
    borderRight: `1px solid ${Colors.neutral[50]}`,
    padding: "1.12rem 1.5rem !important ",
    ...textStyles.s1,
  },
  ".ant-table-container table > thead > tr:first-child th:first-child": {
    borderTopLeftRadius: "0.375rem",
    borderBottomLeftRadius: "0",
    borderLeft: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-thead tr:nth-child(1) th: last-child": {
    borderTopRightRadius: "0.375rem",
    borderBottomLeftRadius: "0",
  },
  ".ant-table-thead tr:nth-child(1) th": {
    ...textStyles.s1,
    backgroundColor: `${Colors.columnHeaderBackground} !important`,
    borderRight: `1px solid ${Colors.neutral[50]}`,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-thead tr:nth-child(2) th": {
    ...textStyles.s1,
    backgroundColor: `${Colors.primary[50]} !important`,
    borderRight: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
    padding: "0.375rem 1.5rem !important ",
  },
  ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before":
    {
      display: "none",
    },
  ".ant-table-filter-column": {
    justifyContent: "flex-start",
    columnGap: "0.5rem",
    alignItems: "center",
  },
  ".ant-table-column-title": {
    flex: "none",
  },
  ".ant-table-column-sorters": {
    justifyContent: "flex-start",
    columnGap: "1rem",
  },
  ".ant-table-filter-trigger": {
    margin: 0,
  },
  ".ant-table-column-sorter": {
    marginLeft: 0,
  },
  // "tbody, td, tfoot, th, thead, tr": {
  //   border: `1px solid ${Colors.neutral[50]} !important`,
  //   overflowWrap: "break-word"
  // },
  ".ant-table-measure-row": {
    height: "0px !important",
  },
  "td:first-child.ant-table-cell div": {
    color: `${Colors.neutral[900]} !important`,
  },
  ".ranking-table-design .ant-pagination-item, .latest-table .ant-pagination-item":
    {
      minWidth: "30px",
      height: " 30px",
      border: 0,
    },
  ".ant-pagination-item-active": {
    background: "#E2E8F0",
  },
  ".ant-pagination-item": {
    border: 0,
    ...textStyles.s3,
    lineHeight: "24px !important",
  },
  ".ant-pagination-item-ellipsis": {
    height: "19px",
  },
  ".ant-table-container" : {
    borderTopLeftRadius: "0.4rem",
    borderTopRightRadius: "0.4rem",
  },
  ".ant-table-content .ant-table-thead": {
    textTransform: "none",
  },
  ".ant-table-measure-row *" : {
    height: "0 !important",
    padding: "0 !important",
  },
  ".ant-skeleton-element .ant-skeleton-button-sm": {
    height: "1.6rem !important",
  },
});

export const exportTableContainer = css({
  padding: "0.75rem",
});

export const empty = css({
  ...textStyles.h6,
  color: Colors.neutral[600],
  textAlign: "center",
  minHeight: "calc(100vh - 32rem)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const cell = css({
  ...textStyles.b1,
  color: Colors.neutral[900]
})

export const keyword = css({
  width: "13.4rem",  
});
export const categories = css({
  width: "5rem",
});
export const rank = css({
  width: "6.38rem",
});
export const urlStyle = css({
  width: "15.5rem",
  wordWrap: "break-word",
});

export const searchInput = css({
  ...textStyles.b3,
  border: `1px solid ${Colors.neutral[800]}`,
});

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

  export const domainTitle = css({
    textAlign: "left",
  })

  export const errorScreen = css({
    minHeight: "19rem"
  })

  export const longTitle = css({
    wordWrap: "normal",
    width: "5.5rem"
  })