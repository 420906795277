/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import DropdownInput from "../customInput";
import { useTicketingStore } from "../ticketingStore";
import * as styles from "./styles"
import Textarea from '@semcore/ui/textarea';
import Input from '@semcore/ui/input';
import Label from "../../shared/label";
import DatePickerInput from "../customDatePicker";
import CustomDatePicker from "../customComponents/datePicker";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Colors } from "../../../styles/colors";
import moment from "moment";
import { getClientsByOrg, getDepartmentsByProject, getMembersByDepartment, getProjectsByClient } from "../../../services/ticketing";
import { debounce } from "../../../utility/helpers";


const CreateTicket = () => {

    // STORE STATES
    const drawerOpen = useTicketingStore((state) => state.drawerOpen)

    const ticketTypes = useTicketingStore((state) => state.ticketTypes)
    const priorityList = useTicketingStore((state) => state.priorityList)

    const ticketForm = useTicketingStore((state) => state.ticketForm)
    const setTicketForm = useTicketingStore((state) => state.setTicketForm)
    const formEdit = useTicketingStore((state) => state.formEdit)
    const setFormEdit = useTicketingStore((state) => state.setFormEdit)

    const ticketFormValidation = useTicketingStore((state) => state.ticketFormValidation)
    const setTicketFormValidation = useTicketingStore((state) => state.setTicketFormValidation)

    const ticketDetails = useTicketingStore((state) => state.ticketDetails)


    const checkFor = useTicketingStore((state) => state.checkFor)
    const setCheckFor = useTicketingStore((state) => state.setCheckFor)

    // STATIC STATES

    // API STATES
    const [subj, setSubj] = useState('')
    const [desc, setDesc] = useState('')

    const [clientList, setClientList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [memberList, setMemberList] = useState([])

    const [loading, setLoading] = useState(false)

    const getClients = (typeId) => {
        setLoading(true)
        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId"),
            ticket_type: typeId ?? ticketForm?.typeId
        }
        getClientsByOrg(body).then((response) => {
            if (response?.status == "success") {
                let clientData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id }
                })
                setClientList(clientData)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setClientList([])
        })
    }

    const getProjects = (clientId) => {
        setLoading(true)
        let id = clientId ?? ticketForm?.clientId
        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId"),
            ...(id && { client_id: id }),
            ticket_type: ticketForm?.typeId
        }
        getProjectsByClient(body).then((response) => {
            if (response?.status == "success") {
                let projectData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id }
                })
                setProjectList(projectData)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setProjectList([])
        })
    }

    const getDepartments = (projectId) => {
        setLoading(true)
        let projId = projectId ?? ticketForm?.projectId
        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId"),
            ...(projId && { project_id: projId }),
            ...(ticketForm?.clientId && { client_id: ticketForm?.clientId }),
            ticket_type: ticketForm?.typeId
        }
        getDepartmentsByProject(body).then((response) => {
            if (response?.status == "success") {
                let deptData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id }
                })
                setDepartmentList(deptData)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setDepartmentList([])
        })
    }

    const getMembers = (departmentId) => {
        setLoading(true)
        let depId = departmentId ?? ticketForm?.departmentId
        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId"),
            department_id: depId ?? "",
            project_id: ticketForm?.projectId ?? "",
            client_id: ticketForm?.clientId ?? ""
            // ...(depId ? { department_id: depId } : ticketForm?.projectId ? { project_id: ticketForm?.projectId } : ticketForm?.clientId && { client_id: ticketForm?.clientId })
        }
        getMembersByDepartment(body).then((response) => {
            if (response?.status == "success") {
                let memberData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id }
                })
                setMemberList(memberData)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setMemberList([])
        })
    }

    // const handleTicketForm = (name, value) => {
    //     setTicketFormValidation({ ...ticketFormValidation, [name]: null })
    //     setTicketForm({ ...ticketForm, [name]: value })
    // }
    const handleInputChange = (name, value) => {
        if (name == "subject") {
            setSubj(value)
        } else {
            setDesc(value)
        }
    }

    const handleTicketForm = debounce((name, value) => {
        setTicketFormValidation({ ...ticketFormValidation, [name]: null })
        setTicketForm({ ...ticketForm, [name]: value })
    }, 300);


    useEffect(() => {
        ticketForm?.subject ? setSubj(ticketForm?.subject) : setSubj("")
        ticketForm?.description ? setSubj(ticketForm?.description) : setDesc("")
    }, [drawerOpen?.open, formEdit])



    return (
        <div css={[styles.main,{ marginBottom: "14rem !important" }]}>
            <div>
                <DropdownInput
                    label={"Type"}
                    placeholder={"Select Ticket Type"}
                    required={checkFor?.includes("type")}
                    value={ticketForm?.type ? `${ticketForm?.type} Ticket` : ""}
                    // disabled={formEdit}
                    defaultValue={ticketForm?.typeId ?? ""}
                    handleChange={(e) => {
                        // setValue(e)
                        let name = ticketTypes?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, typeId: e, type: name, clientId: "", client: "", project: "", projectId: "", departmentId: "", department: "", member: "", memberId: "" })
                        setTicketFormValidation({ type: null })
                        setCheckFor(name)
                        // getClients(e)
                        // getDepartments()
                        // getMembers()
                    }}
                    options={ticketTypes}
                    showSearchBar={true}
                    optionAddon="Ticket"
                    addDropdownStyles={styles.inputDropdown(formEdit)}
                    error={ticketFormValidation?.type}
                />
            </div>
            <div>
                <DropdownInput
                    label={"Client"}
                    placeholder={"Select Client"}
                    required={checkFor?.includes("client")}
                    loading={loading}
                    value={ticketForm?.client || ""}
                    onClick={() => getClients()}
                    // disabled={formEdit}
                    defaultValue={ticketForm?.clientId || ""}
                    handleChange={(e) => {
                        let name = clientList?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, clientId: e, client: name, project: "", projectId: "", departmentId: "", department: "", member: "", memberId: "" })
                        setTicketFormValidation({ ...ticketFormValidation, client: null })
                        // getProjects(e)
                    }}
                    options={clientList}
                    showSearchBar={true}
                    addDropdownStyles={styles.inputDropdown(formEdit)}
                    error={ticketFormValidation?.client}
                    showResetOption={true}
                />
            </div>
            <div>
                <DropdownInput
                    label={"Project"}
                    placeholder={"Select Project"}
                    required={checkFor?.includes("project")}
                    // disabled={formEdit ?? (checkFor?.includes("project") && !ticketForm?.clientId)}
                    value={ticketForm?.project || ""}
                    defaultValue={ticketForm?.projectId || ""}
                    onClick={() => getProjects()}
                    loading={loading}
                    handleChange={(e) => {
                        let name = projectList?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, projectId: e, project: name, departmentId: "", department: "", member: "", memberId: "" })
                        setTicketFormValidation({ ...ticketFormValidation, project: null })
                        // getDepartments(e)
                    }}
                    options={projectList}
                    showSearchBar={true}
                    addDropdownStyles={styles.inputDropdown(formEdit || (checkFor?.includes("project") ? !ticketForm?.clientId : false))}
                    error={ticketFormValidation?.project}
                    showResetOption={true}
                />
            </div>
            <div css={{ marginBottom: "1rem", ...(formEdit && { pointerEvents: "none", opacity: "70%" }) }}>
                <Label label={"Subject"} required={checkFor?.includes("subject")} />
                <div css={styles.inputStyles}>
                    <Input hMin={"33px"}>
                        <Input.Value
                            placeholder='Enter Subject Here'
                            value={subj}
                            onChange={(value) => {
                                // setSubj(value)
                                handleInputChange("subject", value)
                                handleTicketForm("subject", value)
                            }}
                        // value={ticketForm?.subject}
                        // onChange={(value) => {
                        //     setTicketFormValidation({ ...ticketFormValidation, subject: null })
                        //     setTicketForm({ ...ticketForm, subject: value })
                        // }}
                        />
                    </Input>
                </div>
                {ticketFormValidation?.subject && <div css={styles.errorMsg}>{ticketFormValidation?.subject}</div>}
            </div>
            <div css={{ marginBottom: "1rem", ...(formEdit && { pointerEvents: "none", opacity: "70%" }) }}>
                <Label label={"Description"} />
                <div css={styles.textArea()}>
                    <Textarea
                        w={"100%"}
                        minRows={3}
                        // disabled={formEdit}
                        maxRows={3}
                        value={desc}
                        onChange={(value) => {
                            handleInputChange("description", value)
                            handleTicketForm("description", value)
                        }}
                        // value={ticketForm?.description}
                        // onChange={(value) => {
                        //     setTicketFormValidation({ ...ticketFormValidation, description: null })
                        //     setTicketForm({ ...ticketForm, description: value })
                        // }}
                        id='autoscalable-textarea'
                        placeholder='Enter Description Here'
                    />
                </div>
                {ticketFormValidation?.description && <div css={styles.errorMsg}>{ticketFormValidation?.description}</div>}
            </div>
            <div css={{ marginBottom: "1rem", ...((formEdit && ["Completed", "Awaiting Approval"]?.includes(ticketDetails?.mainDetails?.ticket_status_name)) && { pointerEvents: "none", opacity: "70%" }) }}>
                <Label label={"ETA"} required={checkFor?.includes("eta")} />
                <CustomDatePicker
                    minDate={new Date()}
                    value={ticketForm?.eta ? new Date(ticketForm?.eta) : ""}
                    handleDateApply={(date) => {
                        setTicketFormValidation({ ...ticketFormValidation, eta: null })
                        setTicketForm({ ...ticketForm, eta: moment(date).format("YYYY-MM-DD") })
                    }}
                />
                {ticketFormValidation?.eta && <div css={styles.errorMsg}>{ticketFormValidation?.eta}</div>}
            </div>
            <div>
                <DropdownInput
                    label={"Priority"}
                    placeholder={"Select Priority"}
                    required={checkFor?.includes("priority")}
                    // disabled={formEdit}
                    value={ticketForm?.priority}
                    defaultValue={ticketForm?.priorityId}
                    // loading={loading}
                    handleChange={(e) => {
                        let name = priorityList?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, priorityId: e, priority: name })
                        setTicketFormValidation({ ...ticketFormValidation, priority: null })
                    }}
                    options={priorityList}
                    addDropdownStyles={styles.inputDropdown(formEdit ? ["Completed", "Awaiting Approval"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) : false)}
                    error={ticketFormValidation?.priority}
                />
            </div>
            <div>
                <DropdownInput
                    label={"Department"}
                    placeholder={"Select Department"}
                    required={checkFor?.includes("department")}
                    // disabled={formEdit}
                    value={ticketForm?.department}
                    defaultValue={ticketForm?.departmentId}
                    loading={loading}
                    onClick={() => getDepartments()}
                    handleChange={(e) => {
                        let name = departmentList?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, departmentId: e, department: name, member: "", memberId: "" })
                        setTicketFormValidation({ ...ticketFormValidation, department: null })
                        // getMembers(e)
                    }}
                    options={departmentList}
                    showSearchBar={true}
                    addDropdownStyles={styles.inputDropdown(formEdit)}
                    // placement="top-end"
                    error={ticketFormValidation?.department}
                    showResetOption={true}
                />
            </div>
            <div>
                <DropdownInput
                    label={"Assign To"}
                    placeholder={"Select Team Member"}
                    dropdownHeight={"200px"}
                    // disabled={formEdit && ticketDetails?.mainDetails?.ticket_state_name !== "Not PickedUp"}
                    required={checkFor?.includes("member")}
                    value={ticketForm?.member}
                    defaultValue={ticketForm?.memberId}
                    onClick={() => getMembers()}
                    loading={loading}
                    handleChange={(e) => {
                        let name = memberList?.filter((type) => type.value == e)[0]?.label
                        setTicketForm({ ...ticketForm, memberId: e, member: name })
                        setTicketFormValidation({ ...ticketFormValidation, member: null })
                    }}
                    options={memberList}
                    // addDropdownStyles={styles.inputDropdown(ticketDetails?.mainDetails?.ticket_state_name ? ticketDetails?.mainDetails?.ticket_state_name !== "Not PickedUp" : false)}
                    addDropdownStyles={styles.inputDropdown(formEdit ?
                        // ["SOW", "SOW Adhoc"].includes(ticketDetails?.mainDetails?.ticket_type_name) ? true :
                        ["Completed", "Picked Up", "In Progress", "Awaiting Approval"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) : false
                    )}
                    // placement="top-end"
                    showSearchBar={true}
                    error={ticketFormValidation?.member}
                    showResetOption={true}
                />
            </div>
        </div>
    )
}

export default CreateTicket;