/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Switch from "@semcore/ui/switch";
import Popper from "@semcore/ui/popper";
import moment from "moment";

const CampaignCard = ({
  campaignname,
  id,
  isActive,
  creditsRequired,
  keyword_count,
  competitors,
  device,
  updated_by,
  updated_at,
  addStyles,
  permissionToActivate,
  goToEditCampaign,
  onStatusChange,
  ondeleteClick,
  isRankingConfigUnlocked,
}) => {
  return (
    <div css={[styles.main, addStyles && addStyles]}>
      <div css={styles.wrapper}>
        <div css={styles.header}>
          <div>{campaignname}</div>
          {isRankingConfigUnlocked && (
              <Popper placement="bottom-end" interaction="click">
                <Popper.Trigger>
                  <FeatherIcon icon={"more-vertical"} css={styles.icon} />
                </Popper.Trigger>
                <Popper.Popper css={styles.popper}>
                  <div onClick={goToEditCampaign} css={styles.option}>
                    Edit
                  </div>
                  <div css={styles.option} onClick={() => ondeleteClick(id)}>
                    Delete
                  </div>
                </Popper.Popper>
              </Popper>
            )}
        </div>
        <div css={styles.detail}>
          <div css={styles.detailItem(false)}>
            {permissionToActivate ? (
              <Switch size="l" theme={"success"}>
                <Switch.Addon>Off</Switch.Addon>
                <div
                  onClick={() => {
                    if (isRankingConfigUnlocked) {
                      onStatusChange(!isActive);
                    }
                  }}
                >
                  <Switch.Value
                    checked={isActive}
                    id="email-subscription"
                    disabled={!isRankingConfigUnlocked}
                  ></Switch.Value>
                </div>
                <Switch.Addon>On</Switch.Addon>
              </Switch>
            ) : isActive ? (
              <div css={[styles.data, styles.active]}>Active</div>
            ) : (
              <div css={[styles.data, styles.deactive]}>Paused</div>
            )}
            <div css={styles.detailLabel}>Rank Tracking</div>
          </div>
          <div css={styles.detailItem(false)}>
            <div css={styles.data}>{creditsRequired}</div>
            <div css={styles.detailLabel}>Credits Required</div>
          </div>
          <div css={styles.detailItem(false)}>
            <div css={styles.data}>{keyword_count}</div>
            <div css={styles.detailLabel}>Keywords</div>
          </div>
          <div css={styles.detailItem(false)}>
            <div css={styles.data}>{competitors}</div>
            <div css={styles.detailLabel}>No. of Competitors</div>
          </div>
          <div css={styles.detailItem(false)}>
            <div css={styles.data}>{device}</div>
            <div css={styles.detailLabel}>Device</div>
          </div>
          <div css={styles.detailItem(false)}>
            <div css={styles.data}>{updated_by}</div>
            <div css={styles.detailLabel}>Last updated by</div>
          </div>
          <div css={styles.detailItem(true)}>
            <div css={styles.data}>
              {moment(updated_at).format("DD MMM YYYY")}
            </div>
            <div css={styles.detailLabel}>Last updated on</div>
          </div>
        </div>
      </div>
      {/* {!permissionToActivate && (
        <div>
          <FeatherIcon icon={"chevron-right"} styles={styles.icon} />
        </div>
      )} */}
    </div>
  );
};

export default CampaignCard;
