import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";

import {
  createProject,
  documentFileType,
  getClientByOrgId,
  GET_Server_URL,
  updateProject,
  getCountryByOrg,
  getAllCityByCountry,
  getSowExist,
  getProjectByClientId,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import { isValidBulkUploadSow, removeTrailingSlash } from "../utility/Globals";
import { toast } from "react-hot-toast";
import FeatherIcon from 'feather-icons-react';
import { useLocation } from "react-router-dom";
import { createSow, getPriorityList, getSowId, updateSowApi } from "../services/scopeOfWork";
import { Colors } from "../styles/colors";
import { textStyles } from "../styles/font";
// import sowTemplate from "../../assets/templates/SOW_Template.xlsx";
import sowTemplate from "../components/assets/templates/SOW_Template.xlsx"


function ProjectsListSalesDirAddUpdate({ update }) {
  const [isUpdateProjectData, setIsUpdateProjectData] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [projectCode, setprojectCode] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [domainName, setdomainName] = useState("");
  const [cost, setcost] = useState("");
  const [poc, setpoc] = useState("");
  const [creditForRankings, setcreditForRankings] = useState("0");
  const [wordCount, setWordCount] = useState("0");
  const [contact, setcontact] = useState("");
  const [filelist, setfilelist] = useState([]);
  const [errors, setErrors] = useState({});
  const [save, setsave] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [cityselected, setcityselected] = useState("");
  const [statueList, setStatueList] = useState([
    { name: "Active", id: "0" },
    { name: "Lead", id: "1" },
    { name: "Prospect", id: "2" },
    { name: "Completed", id: "3" },
  ]);
  const [statusySelected, setStatusySelected] = useState("0");
  const [documentURL, setDocumentURL] = useState("");
  const [industry, setIndustry] = useState("");
  const [sowDocumentFile, setSowDocumentFile] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [isSowExist, setIsSOWExist] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  function changeStatus(e) {
    let value = e.target.value;
    setStatusySelected(value);
  }
  const [customerName, setCustomerName] = useState([]);
  const [projectList, setProjectList] = useState([])
  const [selectedClient, setClientsSelected] = useState("");
  const locationforUpdate = useLocation();
  const [removeDocFile, setRemoveDocFile] = useState(false);
  const [removeSowFile, setRemoveSowFile] = useState(false);
  const [uploadedSOW, setUploadedSOW] = useState("");
  const [priorities, setPriorities] = useState([])
  const [sowId, setSowId] = useState("")

  const [sowUploadState, setSowUploadState] = useState({
    status: "true",
    // data
  })

  const [submitFailed, setSubmitFailed] = useState(false)

  function changeCustomer(e) {
    let value = e.target.value;
    setClientsSelected(value);
  }

  useEffect(() => {
    if (locationforUpdate.state.isUpdateProjectData) {
      setIsUpdateProjectData(true);
      setprojectCode(locationforUpdate.state.set_ProjectCode);
      setClientsSelected(locationforUpdate.state.set_CustomerName);
      setstartDate(locationforUpdate.state.set_StartDate);
      setendDate(locationforUpdate.state.set_EndDate);
      setdomainName(locationforUpdate.state.set_DomainName);
      setcost(locationforUpdate.state.set_Cost);
      setCountrySelected(locationforUpdate.state.set_Country);
      setpoc(locationforUpdate.state.set_Poc);
      setcontact(locationforUpdate.state.set_Contact);
      setStatusySelected(locationforUpdate.state.set_StatusySelected);
      setSelectedProjectId(locationforUpdate.state.set_SelectedProjectId);
      setcreditForRankings(locationforUpdate.state?.set_CreditForRankings);
      setDocumentURL(locationforUpdate.state.set_DocumentURL);
      setIndustry(locationforUpdate.state.set_Industry);
      // isSowExistFunction(locationforUpdate.state.set_SelectedProjectId);
      fetchSowId(locationforUpdate.state.set_SelectedProjectId)
      setWordCount(locationforUpdate.state?.set_SelectedItem?.wordCount);
      if (locationforUpdate.state && locationforUpdate.state?.set_SelectedItem?.sowFileUrl) {
        const url = locationforUpdate.state?.set_SelectedItem?.sowFileUrl.split('Document\\');
        if (url.length)
          setUploadedSOW(url[1]);
      }
    } else {
      setIsUpdateProjectData(false);
      setIsSOWExist(true);
    }
    getClientList();
    getCountryList();
    getPriorities();
  }, []);


  useEffect(() => {
    setStatueList([
      { name: "Active", id: "0" },
      { name: "Lead", id: "1" },
      { name: "Prospect", id: "2" },
      { name: "Completed", id: "3" }
    ])
  }, [])

  function isSowExistFunction(projectId) {
    APIManager.getAPI(getSowExist + projectId).then((response) => {
      if (response && response.status === 200) {
        setIsSOWExist(response.data);
      }
    });
  }
  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            name: item.name,
            id: item.id,
          };
          list.push(obj);
        });
        setCustomerName(list);
        if (locationforUpdate.state.isUpdateProjectData) {
        } else {
          setClientsSelected(list[0]?.id);
        }
      }
    });
  }

  useEffect(() => {
    selectedClient && getProjectList(selectedClient)
  }, [selectedClient])

  function getProjectList(clientId) {
    APIManager.getAPI(
      getProjectByClientId + clientId
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            name: item.projectCode,
            id: item.id,
          };
          list.push(obj);
        });
        setProjectList(list);
      }
    });
  }


  function getCountryList() {
    const orgId = localStorage.getItem("organizationId");
    APIManager.getAPI(getCountryByOrg + orgId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.countryName,
          };
          list.push(obj);
        });
        setCountryList(list);
        if (locationforUpdate.state.isUpdateProjectData) {
          if (locationforUpdate.state.set_Country) {
            getCityList(locationforUpdate.state.set_Country);
          }
        } else {
          setCountrySelected(list[0].id);
          getCityList(list[0].id);
        }
      }
    });
  }

  function getCityList(countryId) {
    APIManager.getAPI(getAllCityByCountry + countryId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.cityName,
          };
          list.push(obj);
        });
        setcityList(list);
        if (locationforUpdate.state.isUpdateProjectData) {
          list.forEach((i) => {
            if (i.id === locationforUpdate.state.set_SelectedItem?.city?.id) {
              setcityselected(i.id);
            }
          });
        } else {
          setcityselected(list[0]?.id);
        }
      }
    });
  }

  const getPriorities = () => {
    getPriorityList(localStorage.getItem("organizationId")).then((response) => {
      if (response.status == "success") {
        setPriorities(response.data)
      }
    })
  }

  const fetchSowId = (projectId) => {
    getSowId(projectId).then((response) => {
      if (response.status == "success") {
        setSowId(response?.data[0])
      }
    })
  }


  const reUploadSow = () => {
    let highPriorityId = priorities?.filter((value) => value.name == "High")[0]?.id

    let sowFormData = new FormData()
    sowFormData.append("orgid", sowUploadState?.orgId)
    sowFormData.append("projectid", sowUploadState?.projectId)
    sowFormData.append("userid", localStorage.getItem("userId"))
    sowFormData.append("status", "Not Assigned")
    sowFormData.append("priority", highPriorityId)
    sowFormData.append("file", sowDocumentFile)
    uploadSow(sowFormData, sowUploadState?.orgId, sowUploadState?.projectId)
  }

  const uploadSow = (sowFormData, orgId, projectId) => {
    let error = { ...errors }
    let flag = true
    if (locationforUpdate.state.isUpdate === false) {
      if (sowDocumentFile === "") {
        setErrorMsg("SowFile is required!");
        error["sowDocumentFile"] = "SowFile is required!";
        flag = false
      } else {
        setErrorMsg("")
        delete error["sowDocumentFile"]
      }
    }
    setErrors(error)

    flag && createSow(sowFormData).then((sowResponse) => {
      if (sowResponse?.status == "success") {
        setSowUploadState({ status: true })
        toast.success("Successfully Uploaded");
        clearForm();
        setIsUpdateProjectData(false);
        update("projectList");
        return;
      }
    }).catch((error) => {
      if (error.response.data.status == "error") {
        if (error.response.data?.error_message) {
          toast.error(error.response.data.error_message)
        } else if (error.response.data?.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error("Failed to upload SOW file.")
        }
        setRemoveSowFile(false);
        document.getElementById("myfile").value = "";
        setFile(null)
        setSowDocumentFile("")
        setSowUploadState({ orgId, projectId, status: false })
        // clearForm();
        setIsUpdateProjectData(false);
        // update("projectList");
        return
      }
    })
  }

  const updateSow = (sowFormData, orgId, projectId) => {

    updateSowApi(sowFormData).then((sowResponse) => {
      if (sowResponse?.status == "success") {
        setSowUploadState({ status: true })
        toast.success("Successfully Updated SOW File");
        clearForm();
        setIsUpdateProjectData(false);
        update("projectList");
        return;
      }
    }).catch((error) => {
      if (error.response.data.status == "error") {
        if (error.response.data?.error_message) {
          toast.error(error.response.data.error_message)
        } else if (error.response.data?.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error("Failed to upload SOW file.")
        }
        setRemoveSowFile(false);
        document.getElementById("myfile").value = "";
        setFile(null)
        setSowUploadState({ orgId, projectId, status: true })
        // clearForm();
        setIsUpdateProjectData(true);
        // update("projectList");
        return
      }
    })
  }

  function createProjects() {
    var bodyFormData = new FormData();
    bodyFormData.append("ClientId", selectedClient?.trim());
    bodyFormData.append("ProjectCode", projectCode?.trim());
    bodyFormData.append("StartDate", startDate);
    bodyFormData.append("EndDate", endDate ? endDate : "");
    bodyFormData.append("DomainName", removeTrailingSlash(domainName)?.trim());
    bodyFormData.append("Cost", cost);
    bodyFormData.append("CountryId", countrySelected);
    bodyFormData.append("CityId", cityselected);
    bodyFormData.append("POC", poc.trim());
    bodyFormData.append("Contact", contact?.trim());
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("creditForRankings", creditForRankings);
    bodyFormData.append("WordCount", wordCount);
    // bodyFormData.append("SowFile", sowDocumentFile);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );

    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.DocumentType", 0);
      bodyFormData.append("Document.File", documentFile);
    }

    APIManager.postAPI(createProject, {}, bodyFormData, false).then(
      (response) => {
        if (response?.response?.data && response?.response?.data?.errors && response?.response?.data?.errors[0]?.errorMessage === 'This Project Code already exist') {
          toast.error('This Project Code might be already exist');
        }
        if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
          toast.error('Some Columns are Missing');
          return;
        }
        else if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Invalid File') {
          toast.error('Invalid File');
          return;
        }
        if (response && response?.data?.errors) {
          toast.error(response?.data?.errors[0]?.errorMessage);
          return;
        }
        else if (response && response?.status === 200 && !response?.data?.errors) {
          setSowUploadState({ status: true, orgid: response?.data?.organizationId, projectId: response?.data?.id })
          let highPriorityId = priorities?.filter((value) => value.name == "High")[0]?.id
          var sowFormData = new FormData();
          sowFormData.append("orgid", response?.data?.organizationId)
          sowFormData.append("projectid", response?.data?.id)
          sowFormData.append("userid", localStorage.getItem("userId"))
          sowFormData.append("status", "Not Assigned")
          sowFormData.append("priority", highPriorityId)
          sowFormData.append("file", sowDocumentFile)


          uploadSow(sowFormData, response?.data?.organizationId, response?.data?.id)

        }
      }
    ).catch(err => {
      console.log(err);
    });
  }

  function setcountry(e) {
    var countryVar = e.target.value;
    setCountrySelected(countryVar);
    getCityList(countryVar);
  }

  function setcity(e) {
    setcityselected(e.target.value);
  }

  useEffect(() => {
    const checkValid = () => validateFields()
    submitFailed && document.addEventListener("mousedown", checkValid);
    return () => {
      document.removeEventListener("mousedown", checkValid);
    };
  }, []);


  function validateFields() {
    let error = { ...errors };

    projectCode.trim() === ""
      ? (error["projectCode"] = "Project name is required!")
      : delete error["projectCode"];


    if (projectCode?.length) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9_&@?()\\\-"/,._:'\s]*$/
      );

      // if (pattern?.test(projectCode) === false) {
      //   error["projectCode"] = "Please enter valid Project name!";
      // } else {
      if (projectCode?.length > 50) {
        error["projectCode"] = "POC must be within 50 characters"
      } else {
        if (locationforUpdate.state.isUpdateProjectData) {
          if ((projectCode?.toLowerCase() !== locationforUpdate?.state?.set_ProjectCode?.toLowerCase()) && projectList?.map((names) => names?.name?.toLowerCase())?.includes(projectCode?.toLowerCase())) {
            error["projectCode"] = "Project Name Already Exists"
          } else {
            delete error["projectCode"]
          }
        } else {
          if (projectList?.map((names) => names?.name?.toLowerCase())?.includes(projectCode?.toLowerCase())) {
            error["projectCode"] = "Project Name Already Exists"
          } else {
            delete error["projectCode"]
          }
        }
      }
      // }
    }

    selectedClient.trim() === ""
      ? (error["selectedClient"] = "Customer name is required!")
      : delete error["selectedClient"];

    startDate.trim() === ""
      ? (error["startDate"] = "Start date is required!")
      : delete error["startDate"];

    // endDate.trim() === ""
    //   ? (error["endDate"] = "Estimated Completion Date is required!")
    //   : delete error["endDate"];

    if (startDate !== "" && endDate !== "") {
      if (endDate < startDate) {
        error["startDate"] = "Start Date should be less than End Date!";
      } else {
        delete error["startDate"];
      }
    }

    domainName.trim() === ""
      ? (error["domainName"] = "Domain URL is required!")
      : delete error["domainName"];

    if (domainName.trim()?.length) {
      var pattern1 = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern1.test(domainName.trim())) {
        error["domainName"] = "Invalid domain!";
      } else {
        delete error["domainName"];
      }
    }
    Number(cost) > 0
      ? delete error["cost"]
      : (error["cost"] = "Cost is required!")

    countrySelected?.length === 0 ?
      error["country"] = "Country is required"
      : delete error["country"]

    cityselected?.length === 0 ?
      error["city"] = "City is required"
      : delete error["city"]

    poc.trim() === ""
      ? (error["poc"] = "POC is required!")
      : poc.length > 40 ?
        error["poc"] = "POC must be within 40 characters"
        : delete error["poc"]



    // creditForRankings === null
    //   ? (error["creditForRankings"] = "Credit is required!")
    //   : delete error["creditForRankings"];

    creditForRankings <= 0
      ? (error["creditForRankings"] = "Please enter positive number!")
      : delete error["creditForRankings"];

    // wordCount === null
    //   ? (error["wordCount"] = "Word Count is required!")
    //   : delete error["wordCount"];

    wordCount <= 0
      ? (error["wordCount"] = "Please enter positive number!")
      : delete error["wordCount"];

    if (locationforUpdate.state.isUpdate === false) {
      if (sowDocumentFile === "") {
        setErrorMsg("SowFile is required!");
        error["sowDocumentFile"] = "SowFile is required!";
      } else {
        setErrorMsg("")
        delete error["sowDocumentFile"]
      }
    }

    contact.trim() === ""
      ? (error["contact"] = "Contact is required!")
      : error["contact"] = "";

    if (contact.trim()?.length) {
      var pattern2 = new RegExp(/^[0-9\b]+$/);
      if (!pattern2.test(contact.trim())) {
        error["contact"] = "Please enter only number";
      } else if (contact.trim().length > 10) {
        error["contact"] =
          "Contact number must not exceed more than 10 characters.";
      } else if (contact.trim().length < 10) {
        error["contact"] = "Contact number must be of at least 10 characters.";
      } else {
        delete error["contact"];
      }
    }

    setErrors(error);
    let keys = Object.keys(error);
    return keys?.length ? false : true;
  }

  function updateProjects() {

    setIsUpdateProjectData(true);
    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedProjectId.trim());
    bodyFormData.append("ProjectCode", projectCode);
    bodyFormData.append("StartDate", startDate);
    bodyFormData.append("EndDate", endDate);
    bodyFormData.append("DomainName", removeTrailingSlash(domainName));
    bodyFormData.append("Cost", cost);
    bodyFormData.append("CountryId", countrySelected);
    bodyFormData.append("CityId", cityselected);
    bodyFormData.append("POC", poc.trim());
    bodyFormData.append("Contact", contact);
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("CreditForRankings", creditForRankings);
    bodyFormData.append("WordCount", wordCount);
    bodyFormData.append("ClientId", selectedClient);
    // if (isSowExist) {
    //   bodyFormData.append("SowFile", sowDocumentFile);
    // }
    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", documentFile);
    }
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );


    APIManager.putAPI(updateProject, {}, bodyFormData, false, false).then(
      (response) => {

        // if (response && response.data && !response?.data?.errors) {
        //   setIsUpdateProjectData(false);
        //   // update("projectList");
        // }
        if (response && response.status === 200 && !response?.data?.errors) {
          setSowUploadState({ status: true, orgid: response?.data?.organizationId, projectId: response?.data?.id })

          var sowFormData = new FormData();
          sowFormData.append("orgid", response?.data?.organizationId)
          sowFormData.append("projectid", response?.data?.id)
          sowFormData.append("userid", localStorage.getItem("userId"))
          sowFormData.append("sowid", sowId)
          sowFormData.append("file", sowDocumentFile)
          if (sowDocumentFile) {
            sowId ? updateSow(sowFormData, response?.data?.organizationId, response?.data?.id) : uploadSow(sowFormData, response?.data?.organizationId, response?.data?.id)
          } else {
            toast.success("Successfully Updated SOW File");
            clearForm();
            setIsUpdateProjectData(false);
            update("projectList");
          }
        }
        if (response?.data && response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
          toast.error('Some Columns are Missing');
          return;
        }
        if (response?.data && response?.data?.errors[0]?.errorMessage === 'Invalid File') {
          toast.error('Invalid File');
          return;
        }
        if (response && response?.data?.errors) {
          toast.error(response?.data?.errors[0]?.errorMessage);
          return;
        }


      }
    );
  }

  function clearForm() {
    setprojectCode("");
    setstartDate("");
    setendDate("");
    setdomainName("");
    setcost("");
    setpoc("");
    setcreditForRankings("");
    setWordCount("");
    setSowDocumentFile("");
    setDocumentFile("");
    setDocumentURL("");
    setIndustry("");
    setfilelist("");
    setcontact("");
    setSelectedProjectId("");
    setStatusySelected("0");
    setIsUpdateProjectData(false);
    update("projectList");
  }

  function updateList() {
    var input = document.getElementById("myfile2");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
      setDocumentFile(input?.files?.item(i));
      setDocumentURL("")
    }
    setfilelist(data);
  }

  function updateListsow() {
    var input = document.getElementById("myfile");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
      setErrorMsg("")
      setSowDocumentFile(input.files.item(i));
    }
  }
  const inputRef = useRef(null);
  const inputRefdocu = useRef(null);
  const handleButtonClick = () => {
    inputRef.current?.click();
  };
  const handleButtonClickdocument = () => {
    inputRefdocu.current?.click();
  };
  const [file, setFile] = useState(null);
  const [filedoc, setFiledoc] = useState(null);



  const handleFileChange = (e) => {
    setRemoveSowFile(true)
    let input = document.getElementById("myfile");
    if (input.files.length) {
      const fileExtension = input.files[0].name.split(".").at(-1);
      let isvalid = isValidBulkUploadSow(fileExtension);
      if (!isvalid) return;
      if (isvalid === true) {
        updateListsow();
      }
      else {
        document.getElementById("myfile").value = "";
      }
    }
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      setFile(file);
      setRemoveSowFile(true)
    } else {
      setFile(null);
    }
  };
  const handleFileChangedoc = (e) => {
    setRemoveDocFile(true)
    let input = document.getElementById("myfile2");
    if (input.files.length) {
      const fileExtension = input.files[0].name.split(".").at(-1);
      let isvalid = isValidBulkUploadSow(fileExtension);
      if (!isvalid) return;
      if (isvalid === true) {
        updateList();
      }
      else {
        document.getElementById("myfile2").value = "";
      }
    }
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      setFiledoc(file);
      setRemoveSowFile(true)
    } else {
      setFiledoc(null);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = sowTemplate;
    link.setAttribute('download', 'SOW_Template.xlsx');
    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="portlet project-dir slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg
            onClick={() => {
              update("projectList");
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left close-left-icon"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} -{" "}
            {isUpdateProjectData ? "Update " : "Add "}Project{" "}
          </h3>
        </div>
        <div className="portlet-body">
          <div class="common-wrapper project-form">
            <div class="common-wcard">
              <div class="common-form-fields">
                <div class="add-user" style={{ width: 100 + "%" }}>
                  <div class="col-md-12">
                    <label className="form-label">Project Name</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Project Name"
                      required
                      value={projectCode}
                      onChange={(text) => {
                        setprojectCode(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.projectCode && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.projectCode}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Customer Name</label>
                    <label class="field-required"> * </label>
                    <select
                      className="form-select "
                      value={selectedClient}
                      onChange={changeCustomer}
                    >
                      {customerName.map((item) => {
                        return (
                          <option className="customer-name" value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {save && errors?.selectedClient && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.selectedClient}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3 strat-date">
                    <label className="form-label">Start Date</label>
                    <label class="field-required"> * </label>
                    <input
                      type="date"
                      name=""
                      value={startDate}
                      required
                      onChange={(text) => {
                        setstartDate(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.startDate && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.startDate}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3 strat-date completion-date">
                    <label className="form-label">
                      Estimated Completion Date
                    </label>
                    {/* <label class="field-required"> * </label> */}
                    <input
                      type="date"
                      className="from-control"
                      name=""
                      required
                      value={endDate}
                      onChange={(text) => {
                        setendDate(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.endDate && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.endDate}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Domain URL</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Domain URL"
                      required
                      value={domainName}
                      onChange={(text) => {
                        setdomainName(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.domainName && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.domainName}</span>
                      </div>
                    </>
                  )}
                  {locationforUpdate?.state?.isUpdateProjectData ? (
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Industry</label>
                      <div className="col-md-12 d-flex p-0">
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          value={industry}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Cost</label>
                    <label class="field-required"> * </label>
                    <div className="col-md-12 d-flex p-0">
                      <input
                        className="text-center"
                        value={"$"}
                        disabled={true}
                        style={{
                          width: 30 + "px",
                          border: "1px solid #d4dee7",
                        }}
                      />
                      <input
                        type="text"
                        name=""
                        placeholder="Enter Cost"
                        required
                        value={cost}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, '');
                          setcost(value);
                          // validateFields();
                        }}
                      />
                    </div>
                  </div>
                  {save && errors?.cost && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.cost}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Country</label>
                    <label class="field-required"> * </label>
                    <select
                      className="form-select w-100 "
                      required
                      value={countrySelected}
                      onChange={setcountry}
                    >
                      {countryList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  {save && errors?.country && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.country}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">City</label>
                    <label class="field-required"> * </label>
                    <select
                      className="form-select w-100 "
                      required
                      value={cityselected}
                      onChange={setcity}
                    >
                      {cityList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  {save && errors?.city && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.city}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">POC</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter POC"
                      required
                      value={poc}
                      onChange={(text) => {
                        setpoc(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.poc && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.poc}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Contact</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      required
                      placeholder="Enter Contact"
                      value={contact}
                      onChange={(text) => {
                        setcontact(text.target.value.trim());
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.contact && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.contact}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Status</label>
                    <label class="field-required"> * </label>
                    <select
                      className="form-select "
                      value={statusySelected}
                      onChange={changeStatus}
                    >
                      {statueList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3 project-credit">
                    <label className="form-label">Credits for Rankings</label>
                    {/* <label class="field-required"> * </label> */}
                    <input
                      className="w-100 mb-2"
                      type="number"
                      name=""
                      placeholder="Enter Credits for Rankings"
                      required
                      value={creditForRankings}
                      onChange={(text) => {
                        setcreditForRankings(text.target.value);
                        validateFields();
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  {save && errors?.creditForRankings && (
                    <>
                      {/* {!creditForRankings && <div className="field-required ms-3 mt-2">
                        <span> Credit is required!</span>
                      </div>} */}
                      {(creditForRankings && creditForRankings <= 0) && <div className="field-required ms-3 mt-2">
                        <span> Please enter positive number!</span>
                      </div>}
                    </>
                  )}
                  <div class="col-md-12 mt-3 project-credit">
                    <label className="form-label">Word Count</label>
                    {/* <label class="field-required"> * </label> */}
                    <input
                      className="w-100 mb-2"
                      type="number"
                      name=""
                      placeholder="Enter Word Count"
                      required
                      value={wordCount}
                      onChange={(text) => {
                        setWordCount(text.target.value);
                        validateFields();
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  {save && errors?.wordCount && (
                    <>
                      {/* {!wordCount && <div className="field-required ms-3 mt-2">
                        <span> Word Count is required!</span>
                      </div>} */}
                      {(wordCount && wordCount <= 0) && <div className="field-required ms-3 mt-2">
                        <span> Please enter positive number!</span>
                      </div>}
                    </>
                  )}

                  <div
                    class="col-md-12 mt-3"
                  // style={
                  //   isUpdateProjectData
                  //     ? isSowExist
                  //       ? { display: "block" }
                  //       : { display: "none" }
                  //     : { display: "block" }
                  // }
                  >
                    <label className="form-label">
                      Upload Scope of work (SOW)
                    </label>
                    <label class="field-required"> * </label>
                    <br />
                    <>
                      <div
                        class="ms-2 pt-2"
                        style={{ color: "red" }}
                      >
                        {errorMsg}
                      </div>
                    </>
                    <div className=" d-flex align-items-center justify-content-between project-choose-file p-3 w-100">
                      <div className="d-flex align-items-center">
                        <button className={`${removeSowFile ? "custome-file_upload active" : "custome-file_upload inactive"}`}
                          onClick={handleButtonClick}
                        >Select file</button>
                        <input ref={inputRef} id="myfile" type="file" name="myfile" hidden onChange={handleFileChange} accept={documentFileType} multiple={false} />
                        {file && <span className="ms-3">{file.name}</span>}
                      </div>
                      {removeSowFile && document.getElementById("myfile") && document.getElementById("myfile").files[0] && document.getElementById("myfile").files[0].name &&
                        <div className="d-flex align-items-center justify-content-end" onClick={() => {
                          setRemoveSowFile(false);
                          document.getElementById("myfile").value = "";
                          setFile(null)
                          setSowDocumentFile("")
                        }}>
                          <FeatherIcon style={{ width: "15px !important" }} icon="x" />
                        </div>}
                    </div>
                    <label id="sowfileList">
                      {uploadedSOW
                        ? uploadedSOW
                        : ""}
                    </label>
                    <br />
                    <a
                      class="scope-download custome-file_download h-auto"
                      onClick={() => { handleDownload() }}
                    // target="_blank"
                    // // rel="noreferrer"
                    // href={sowTemplate}
                    // download={"SOW Template"}
                    >
                      Download Template{" "}
                    </a>

                  </div>
                  {/* <span className="pt-5 ps-3" style={{ color: "#757575" }}>
                    {isSowExist === false ? "SOW file already uploaded" : ""}
                  </span> */}


                  <div class="col-md-12">
                    <label className="form-label"> Document Upload</label>
                    <br />
                    <div className=" d-flex align-items-center justify-content-between project-choose-file p-3 w-100">
                      <div className="d-flex align-items-center">
                        <button className={`${removeDocFile ? "custome-file_upload active" : "custome-file_upload inactive"}`}
                          onClick={handleButtonClickdocument}
                        >Select file</button>
                        <input ref={inputRefdocu} id="myfile2" type="file" name="myfile2" hidden onChange={handleFileChangedoc} accept={documentFileType} multiple={false} />
                        {filedoc && <span className="ms-3">{filedoc.name}</span>}
                      </div>
                      {removeDocFile && document.getElementById("myfile2") && document.getElementById("myfile2").files[0].name &&
                        <div className="d-flex align-items-center justify-content-end" onClick={() => {
                          setfilelist("")
                          setRemoveDocFile(false);
                          document.getElementById("myfile2").value = "";
                          setFiledoc(null)
                        }}>
                          <FeatherIcon style={{ width: "15px !important" }} icon="x" />
                        </div>}
                    </div>
                    {documentURL ? (
                      <label id="fileLabel">
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          href={GET_Server_URL + documentURL}
                        >
                          {documentURL}
                        </a>
                      </label>
                    ) : null}

                    <label id="fileList2">
                      {filelist.length > 0 && documentURL !== "" ? `${filelist.length} files` : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div className="col-md-12 ps-0">
            <button
              class="btn btn-primary me-3"
              onClick={sowUploadState?.status ?
                () => {
                  setsave(true);
                  setSubmitFailed(true)
                  if (validateFields()) {
                    isUpdateProjectData ? updateProjects() : createProjects();
                  }
                } :
                () => {
                  reUploadSow()
                }}
            >
              {isUpdateProjectData ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline-secondary"
              // disabled={!sowUploadState?.status}
              onClick={() => {
                clearForm();
                setIsUpdateProjectData(false);
              }}
            >
              Cancel
            </button>
          </div>
          {/* {!sowUploadState?.status && <div className="form-label mt-2" style={{ color: Colors.error[800], fontWeight: 500 }}>SOW File is Required</div>} */}
        </div>
      </div>
    </>
  );
}

export default ProjectsListSalesDirAddUpdate;
