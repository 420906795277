import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import { getPageAuthoritychart } from "../../utility/Constant";
import {
  fixTwoPlaceDecimal,
  getLastMonthYearName,
  getMonthYearName,
  mmDDyyyyFormateDateSet
} from "../../utility/Globals";

function PageAuthority(props) {
  useEffect(() => {
    let rowData = {
      projectId: localStorage.getItem("projectGId"),
      DateRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
      },
      CompareDateRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
      },
    };
    getPageAuthority(rowData);
  }, [props?.dataParentToChild]);

  const [pa, setPa] = useState([]);
  const [pacomp, setCompPa] = useState([]);

  let firstDayy;
  let lastDayy;
  const today = new Date();
  firstDayy = new Date(today.setDate(today.getDate() - today.getDay() - 7));
  lastDayy = new Date(today.setDate(today.getDate() - today.getDay() + 6));

  const [percentage, setPercentage] = useState(0);
  const [statusData, setStatusData] = useState(["Completed", "Remaining"]);

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: statusData,
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };

  function getColorOfData(value) {
    if (value > 0) {
      return "digit-green";
    } else if (value < 0) {
      return "digit-red";
    } else {
      return "";
    }
  }
  
  function getPageAuthority(rowData) {
    APIManager.postAPI(getPageAuthoritychart, rowData, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          let list = [];
          let list1 = [];
          response.data.table.map((item) => {
            let obj = {
              pa: item.pa,
              projectId: localStorage.getItem("projectGId"),
            };
            list.push(obj);
            setPercentage(fixTwoPlaceDecimal(item.pa));
            if (item.pa >= 0 && item.pa <= 30) {
              setStatusData(["Needs work", "Remaining"]);
            } else if (item.pa >= 30 && item.pa <= 75) {
              setStatusData(["Good", "Remaining"]);
            } else if (item.pa > 75) {
              setStatusData(["Excellent", "Remaining"]);
            }
          });
          response.data.table1.map((item) => {
            let obj1 = {
              pa: item.pa,
              projectId: localStorage.getItem("projectGId"),
            };
            list1.push(obj1);
          });
          setPa(response?.data.table[0]?.pa);
          setCompPa(response?.data.table1[0]?.pa);
        }
      }
    );
  }

  return (
    <>
      <div className="dash-content round-chart position-relative custome_canvas_chart">
        <div className="d-flex align-items-center justify-content-center pageauthority-doughnut seo-health-doughnut py-2">
          <Doughnut
            data={data}
            options={{
              cutoutPercentage: 80,
              responsive: true,
              maintainAspectRatio: true,
              tooltips: {
                enabled: false
              },
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            }}
          />
          <span class="hover-img ranking-img">
            <div height="200" width="300" className="d-flex">
              <div class="row">
                <div className="d-flex align-items-center p-0">
                  <table>
                    <tr>
                      <td className="traffic-value border-rightside px-3">
                        {(pa, Math.round(pa))}
                      </td>
                      <td className="traffic-value border-rightside px-3">
                        {(pacomp, Math.round(pacomp))}
                      </td>
                      <td className="traffic-value  px-3 text-center">
                        <div
                          className={getColorOfData(
                            Math.round(pa) - Math.round(pacomp)
                          )}
                        >
                          {Math.round(pa) - Math.round(pacomp)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="traffic-date border-rightside px-3">
                        {getLastMonthYearName()}
                      </td>
                      <td className="traffic-date border-rightside px-3">
                        {getMonthYearName()}
                      </td>
                      <td className="traffic-date px-3 text-center">Change</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="pageauthority percentage-text">
          <div className="chart_outer_side">
            <div className="text-name score-text">{`${(pa, Math.round(pa))
              }`}</div>
            <div className="audit-total">
              <div
                className={getColorOfData(
                  Math.round(pa) - Math.round(pacomp)
                )}
              >
                {(Math.round((pa - pacomp) * 100 / pacomp))}
                %
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="page-authority text-center mt-4">
        <h6>Page Authority</h6>
      </div>
    </>
  );
}

export default PageAuthority;
