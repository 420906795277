import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  padding: "1.5rem",
  ".___SInput_letz7_gg_._state_normal_letz7_gg_ .___SOutline_letz7_gg_ ": {
    borderColor: Colors.black,
  },
});

export const form = css({
  display: "flex",
  flexDirection: "column",
});

export const subtitle = css({
  ...textStyles.h7,
  color: Colors.neutral[900],
});

export const redText = css({
  color: Colors.error[800],
});

export const searchVolumeContainer = css({
  padding: "1.5rem 0",
});

export const searchVolumeHead = css({
  display: "flex",
  alignItems: "center",
});
export const label = css({
  ...textStyles.b1,
  width: "13.75rem",
  color: Colors.neutral[900],
});
export const value = css({
  ...textStyles.s2,
  color: Colors.neutral[800],
});

export const rangeInputContainer = css({
  width: "12.5rem",
});

export const buttonContainer = css({
  display: "flex",
  justifyContent: "flex-end",
});

export const error = css({
  ...textStyles.b3,
  marginTop: "0.13rem",
  marginLeft: "13.75rem",
  color: Colors.error[800],
  minHeight: "2.5rem",
});

export const inputContainer = css({
  width: "34.375rem",
});

export const formControll = css({
  display: "flex",
});
