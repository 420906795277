import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';


export const actionContainer = () => {
    return css({
        ...textStyles.s2Medium,
        width: "100%",
        cursor: "default",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem",
        backgroundColor: Colors.plainWhite
    })
}

export const modalBody = css({
    display: "flex",
    padding: "1rem 0",

})

export const modalFooter = css({
    display: "flex",
    flexDirection: "row-reverse",
    padding: "1rem 0",
})

export const icon = (theme) => {
    let color
    switch (theme) {
        case 'primary':
            color = Colors.primary[50]
            break;
        case 'success':
            color = Colors.success[300]
            break;
        case 'error':
            color = Colors.error[50]
            break;
        default:
            color = Colors.neutral[50]
    }


    return css({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "1rem",
        padding: "0.5rem",
        height: "2.5rem",
        width: "2.5rem",
        borderRadius: "100%",
        backgroundColor: color
    })
}

export const cancelButton = (color) => {
    return css({
        "button": {
            margin: "0 1rem",
            color: `${color} !important`,
            border: `1px solid ${color} !important`,
            backgroundColor: `${Colors.plainWhite} !important`
        }
    })
}