import { css } from '@emotion/react';
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const wrapper = css({
    background: Colors.plainWhite,
    height: "calc(100vh - 8rem)"
})

export const header = css({
    ...textStyles.h5,
    color: Colors.black,
    padding: '1.5rem',
    display:'flex',
    alignItems: 'center',
    columnGap: '0.65rem',
    borderBottom: `1px solid ${Colors.neutral[50]}`
})

export const icon = css({
    height: '1.5rem',
    width: '1.5rem',
    cursor: 'pointer'
})

export const main = css({
    padding: '1.5rem',
})

export const section = css({

})

export const pillGroup = css({
    display: "flex",
    columnGap:"1.12rem",
    justifyContent: "space-between"
})

export const mb75 = css({
    marginBottom: '0.75rem'
})

export const pillInfo  = css({
    display: "flex",
    alignItems : "center"
})

export const consumedCredits = (underLimit) => css({
    ...textStyles.s1,
    color: underLimit ? Colors.semantic[500] : Colors.error[800],
    marginRight: "0.2rem"
})

export const availableCredits = css({
    ...textStyles.b3,
    color: Colors.neutral[600]
})

export const totalKeyWords = css({
    ...textStyles.s1,
    color: Colors.neutral[900]
})

export const dividerLine = css({
    margin: "2rem 0"
})

export const activeCampaignHeader = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.5rem"
})

export const campaigns = css({
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem"
})

export const empty = css({
    ...textStyles.h6,
    color: Colors.neutral[600],
    textAlign: "center",
    display:"flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 32rem)"
})

export const modalDescription = css({
    ...textStyles.b1,
    color: Colors.neutral[900]
  })

  export const loaderContainer = css({
    width: "100%",
    minHeight: "calc(100vh - 13rem)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  export const errorContainer = css({
    display: "flex",
    flexDirection : "column",
    rowGap: "1rem",
    justifyContent: "center",
    alignItems: "center"
  })