import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";
import { css } from "@emotion/react";

export const trigger = css({
  ...textStyles.b3,
});

export const popperContainer = css({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: "22rem",
  position: "relative",
  boxShadow: "none",
});

export const removeBorderShadow = css({
  border: "none",
  shadow: "none",
});

export const noticeContainer = {
  borderTop: "none",
  borderRight: "none",
  borderLeft: "none",
  borderRadius: "0",
  padding: "1rem",
};

export const noticeLabelContainer = css({
  flexDirection: "column",
  rowGap: "0.25rem",
  padding: 0,
});

export const noticeTitle = css({
  ...textStyles.s2,
  color: Colors.neutral[900],
});

export const noticeIcon = css({
  width: "1rem",
  height: "1rem",
});

export const noticeDescription = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const headerTitle = css({
  ...textStyles.s1,
  color: Colors.neutral[600],
});

export const week = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const date = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const calendar = css({
  padding: "1rem",
});

export const calendarText = css({
  ...textStyles.b3,
});

export const bottomContainer = css({
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem",
});

export const compareContainer = css({
  ...textStyles.b3,
  display: "flex",
  alignItems: "center",
  columnGap: "0.05rem",
  ".___SCheckbox_1fyp5_gg_._size_m_1fyp5_gg_": {
    marginTop: `0`,
  },
  label: {
    marginBottom: 0,
  },
});

export const compareText = (isDisabled) => {
  return css({
    color: isDisabled ? Colors.neutral[300] : Colors.neutral[900],
  })
}