import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  ".apexcharts-toolbar" : {
    display: "none"
  },
  ".apexcharts-yaxis .apexcharts-text.apexcharts-yaxis-label tspan ": {
    ...textStyles.b3,
    fill: Colors.black,
  },
  ".apexcharts-xaxis .apexcharts-text.apexcharts-xaxis-label tspan": {
    ...textStyles.b1,
    fill: Colors.black,
  },
  "apexcharts-crosshairs": {
    width: "8rem",
    transform: "translate(-2.5rem, 0px)",
  },
});

export const titles = css({
  color: "red",
});

export const emptyContainer = css({
  height: 313,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

export const info = css({
  ...textStyles.h5,
  color: Colors.neutral[500],
  textAlign: "center"
})

export const errorScreen = css({
  minHeight : "313px"
})
