import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors"
import { textStyles } from "../../../../styles/font"


export const main =  css({
  width: "100%",
  display: "flex",
  alignItems: "center"
});

export const inputContainer = css({
    width: "34.375rem",
})

export const label = css({
    ...textStyles.b1,
    minWidth: "13.75rem",
    textAlign: "left"
})

export const redText = css({
  color: Colors.error[800]
})


