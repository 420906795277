import axios from "axios";
import { API_BASE_URL, sendEmailApi, userAuth } from "../utility/Constant";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { clearUserInformation } from "../utility/Globals";
import settings from "../utility/settings";

const notifySave = () => toast.success("Successfully Saved!!");
const notifyAdd = () => toast.success("Successfully Added!");
const notifyUpdate = () => toast.success("Successfully Updated!");
const notifyDelete = () => toast.success("Successfully Deleted!");
const notifyNotDelete = () => toast.success("You can't delete this Keyword because it is in use");
const notifyErr = () => toast.error("Something went wrong!");
const notifyErrMsg = (msg) => toast.error(msg);
const notifyMgs = (msg) => toast.success(msg);

class APIManager {

  getAPI(subUrl) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");
    return axios.get(API_BASE_URL + subUrl).then(
      (response) => {
        if (response && JSON.stringify(response).includes('"status":500')) {
          toast.error('Something went wrong!');
          return;
        }
        if (JSON.stringify(response).includes('"message":"Network Error"')) {
          clearUserInformation();
          let redirectUrl = (process.env.NODE_ENV === "production"
            ? settings.defaultSettings.REACT_APP_BASE_URL
            : settings.defaultSettings.DEV_REACT_APP_BASE_URL) + "login?session=expired"
          window.location.assign(redirectUrl);
        }
        return response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAutomationAPI(url) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");
    return axios.get(url).then((response) => {
      if (!response.status) {
      }
      return response;
    });
  }

  postAPI(subUrl, rowData, formData, isRowData) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");

    return axios
      .post(API_BASE_URL + subUrl, isRowData ? rowData : formData, {
        headers: {
          "content-type": isRowData
            ? "application/json"
            : "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response && JSON.stringify(response).includes('"status":500')) {
          toast.error('Request failed with status code 500');
          return;
        }
        if (response.response?.status === 400) {
          if (response.response?.data.errors[0]?.errorMessage) {
            // notifyErrMsg(response.response?.data.errors[0].errorMessage);
          } else if (response.response?.data.title) {
            // notifyErrMsg(response.response?.data.title);
          }
        } else if (response.status === 200) {
          if (response.data.message) {
            if (subUrl === sendEmailApi) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          } else {
            if (subUrl != userAuth) {
              // notifyAdd();
            }
          }
        }
        return response;
      });
  }

  postAPIForDelete(subUrl, rowData, formData, isRowData) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");

    return axios
      .post(API_BASE_URL + subUrl, isRowData ? rowData : formData, {
        headers: {
          "content-type": isRowData
            ? "application/json"
            : "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response && JSON.stringify(response).includes('"status":500')) {
          toast.error('Request failed with status code 500');
          return;
        }
        if (response.response?.status === 400) {
          if (response.response?.data.errors[0]?.errorMessage) {
            // notifyErrMsg(response.response?.data.errors[0].errorMessage);
          } else if (response.response?.data.title) {
            // notifyErrMsg(response.response?.data.title);
          }
        } else if (response.status === 200) {
          if (response.data.message) {
            if (subUrl === sendEmailApi) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          } else {
            if (subUrl != userAuth) {
              // notifyAdd();
              notifyDelete()
            }
          }
        }
        return response;
      });
  }

  putAPI(subUrl, rowData, formData, isRowData, noToast = true) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");

    return axios
      .put(API_BASE_URL + subUrl, isRowData ? rowData : formData, {
        headers: {
          "content-type": isRowData
            ? "application/json"
            : "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response && JSON.stringify(response).includes('"status":500')) {
          toast.error('Request failed with status code 500');
          return;
        }
        if (response.response?.status === 400) {
          if (response.response?.data.errors[0]?.errorMessage) {
            // notifyErrMsg(response.response?.data.errors[0].errorMessage);
          } else if (response.response?.data.title) {
            // notifyErrMsg(response.response?.data.title);
          }
        } else if (response.status === 200 && !response?.data?.errors && noToast) {
          notifyUpdate();
        }
        return response;
      });
  }

  async deleteAPI(subUrl) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              axios.defaults.headers.common["Authorization"] =
                `Bearer ` + localStorage.getItem("token");
              return axios.delete(API_BASE_URL + subUrl).then(
                (response) => {
                  if (response && JSON.stringify(response).includes('"status":500')) {
                    toast.error('Request failed with status code 500');
                    return;
                  }
                  if (response.status === 200) {
                    if (response?.data?.message) {
                      notifyErrMsg(response?.data?.message);
                    } else {
                      notifyDelete();
                    }
                    resolve(response.status);
                  }
                  else {
                    notifyNotDelete();
                  }
                },
                (error) => {
                  if (JSON.stringify(error).includes('"timeout":0')) {
                  }
                  notifyErr();
                  reject(error);
                }
              );
            },
          },
          {
            label: "No",
            onClick: () => {
              reject("No");
            },
          },
        ],
      });
    });
  }

  async ticketdeleteAPI(subUrl) {

    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui delete-alert'>
              <div className='icon-box'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
              <div className='text ms-3'>
                <h3>Delete Ticket?</h3><p>This will delete the ticket including all the subtickets.</p>
                <div className='d-flex align-items-center justify-content-end mt-5 pt-3'>
                  <button class="btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn-delete"
                    onClick={() => {
                      axios.defaults.headers.common["Authorization"] =
                        `Bearer ` + localStorage.getItem("token");
                      return axios.delete(API_BASE_URL + subUrl).then(
                        (response) => {
                          if (response.status === 200) {
                            onClose();
                            if (response?.data?.message) {
                              notifyErrMsg(response?.data?.message);
                            } else {
                              notifyDelete();
                            }
                            resolve(response.status);
                          }
                          else {
                            notifyNotDelete();
                          }
                        },
                        (error) => {
                          if (JSON.stringify(error).includes('"timeout":0')) {
                          }
                          notifyErr();
                          reject(error);
                        }
                      );
                    }}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        }
      });
    });
  }

  deteletItem(subUrl) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("token");

    return axios.delete(API_BASE_URL + subUrl).then(
      (response) => {
        if (response && JSON.stringify(response).includes('"status":500')) {
          toast.error('Request failed with status code 500');
          return;
        }
        if (response?.status === 200)
          if (response?.data?.message) {
            notifyMgs(response?.data?.message);
          }
        return response;
      },
      (error) => {
        notifyErr();
      }
    );
  }

  async deleteAPIUser(subUrl) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              axios.defaults.headers.common["Authorization"] =
                `Bearer ` + localStorage.getItem("token");
              return axios.delete(API_BASE_URL + subUrl).then(
                (response) => {
                  if (response && JSON.stringify(response).includes('"status":500')) {
                    toast.error('Request failed with status code 500');
                    return;
                  }
                  if (response.status === 200) {
                    if (response?.data?.message) {
                      notifyErrMsg(response?.data?.message);
                    }
                    else if (response?.data?.status === false) {
                      notifyErrMsg("You can't delete this Member because Member is in use!");
                    } else {
                      notifyDelete();
                    }
                    resolve(response.status);
                  }
                  else {
                    notifyNotDelete();
                  }
                },
                (error) => {
                  if (JSON.stringify(error).includes('"timeout":0')) {
                  }
                  notifyErr();
                  reject(error);
                }
              );
            },
          },
          {
            label: "No",
            onClick: () => {
              reject("No");
            },
          },
        ],
      });
    });
  }

}

export default new APIManager();
