/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../styles/colors";
import { useSelector } from "react-redux";
import { returnMagnitude } from "../../../../utility/Globals";
import SpinContainer from "@semcore/ui/spin-container";
import { Col, Table } from "antd";
import { formatDate } from "../../../../utility/dates";

const CustomTable = () => {
  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const isCustom = useSelector((state) => state.rankingReport.isCustom);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  function removeSpaces(str) {
    return str.split(" ").join("");
  }

  useEffect(() => {
    if (graphData.series.length > 0) {
      const data = formatData();
      setTableData(data);
      const columns = createTableLayout(data[0]);
      setColumns(columns);
    }
  }, [graphData]);

  const createTableLayout = (dataLayout) => {
    const columns = [];
    const keys = Object.keys(dataLayout);
    keys.forEach((key) => {
      let columnObject = {};
      columnObject.title = <div css={styles.title}>{key}</div>;
      columnObject.key = key;
      columnObject.render = (value, record) => (
        <div css={styles.cell(value[key] > 0, value[key] === 0, isCompare)}>
          {value[key]}
        </div>
      );
      columns.push(columnObject);
    });
    return columns;
  };

  const formatData = () => {
    if (isCustom) {
      const rows = graphData.xAxisCategories.map((date,index)=>{
        let object = {}
        object.Date = formatDate(date)
        graphData.series.map((item)=>{
          object[item.name] = item.data[index]
        })
        return object
      })
      return rows
    } else {
      const data = [];
      const rows = !isCompare
        ? [...graphData.series]
        : [...graphData.series, "dummyItemForChange"];
      rows.map((tableRow, seriesIndex) => {
        let object = {};
        object.Date =
          seriesIndex === 0
            ? formatDate(selectedDates.selection.startDate)
            : seriesIndex === 1
            ? formatDate(selectedDates.compare.startDate)
            : "Change";
        if (seriesIndex === 2) {
          graphData?.change?.map((item, index) => {
            object[graphData.xAxisCategories[index]] = item;
          });
        } else {
          graphData.series[seriesIndex].data.map((item, index) => {
            object[graphData.xAxisCategories[index]] = item;
          });
        }
        data.push(object);
      });
      if(isCompare){
        const latestDate = data[1]
        const previousData = data[0]
        const change = data[2]
        return [latestDate,previousData,change]
      }
      return data;
    }
  };

  const rowClassName = (record, index) => {
    if (index === 0) {
      return "first-row";
    } else if (index === 1) {
      return "second-row";
    } else if (index === 2) {
      return isCustom?"":"third-row";
    }
  };

  const cellClassName = (record, dataIndex) => {
    const value = record[dataIndex];
    // Apply styles based on the condition (negative or positive)
    return value < 0 ? "negative-value" : "positive-value";
  };

  return (
    <div css={styles.main}>
      {tableData.length > 0 && columns.length > 0 && (
        <Table
          id="graph-table"
          dataSource={tableData}
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
        />
      )}
    </div>
  );
};
export default CustomTable;
