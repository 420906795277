/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import Modal from '@semcore/ui/modal';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Colors } from "../../../../styles/colors";
import { textStyles } from '../../../../styles/font';
import CustomButton from "../../button";

const CustomDeleteModal = ({ visible, handleModalClose, width = "50rem", modalSubHeading, modalHeading, handleModalClick }) => {
    return (
        <Modal visible={visible} closable={false} onClose={() => { handleModalClose() }}>
            <Modal.Overlay zIndex={40000}>
                <Modal.Window w={width} px={5} py={2.5}>
                    {/* <Modal.Close>
                        <FeatherIcon icon={"x"} />
                    </Modal.Close> */}

                    <div>
                        <div css={styles.modalBody}>
                            <div css={styles.icon("error")}>
                                <FeatherIcon icon={"trash-2"} color={Colors.error[800]} size={16} />
                            </div>
                            <div>
                                <div css={{ ...textStyles.h6 }}>{modalHeading}</div>
                                <div css={{ ...textStyles.H1Medium, color: Colors.neutral[500] }}>{modalSubHeading}</div>
                            </div>
                        </div>
                        <div css={styles.modalFooter}>
                            <CustomButton text={"Delete"} use={"primary"} theme={"danger"} onPress={() => {
                                handleModalClick()
                            }} />
                            <div className="mr-3">
                                <CustomButton text={"Cancel"} use={"secondary"} onPress={() => {
                                    handleModalClose()
                                }} />
                            </div>
                        </div>
                    </div>
                </Modal.Window>
            </Modal.Overlay>
        </Modal>

    )
}

export default CustomDeleteModal;