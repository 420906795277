import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import "antd/dist/antd.css";
import Card from "react-bootstrap/Card";
import { ModuleExpandTickets } from "./index";
import DatePicker, { DateObject } from "react-multi-date-picker";
import {
  CompetitionLevel,
  exportInToCSV,
  exportToEXCEL,
  // getCheckPermission,
  mmDDyyyyFormateDateSet,
  setMonths,
  themeStyle,
  getDaysInMonth,
} from "../utility/Globals";
import Chart from "react-apexcharts";
import APIManager from "../services/APIManager";
import {
  getKeyWordByProjectId,
  getLanguage,
  createKeywords,
  getKeywordideas,
  getKeyWordideabyuserid,
  getMonthlySearchVolumesById,
  getKeyWordlistbyuser,
  getKeywordResearchChartbyuser,
  getResearchkeyworduserconfig,
  getCountryByOrg,
} from "../utility/Constant";
import { Sorter } from "../common/Sorter";
//TODO: Find replacement of below library
// import ReactChipInput from "react-chip-input";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";

const CheckBox = (props) => {
  return (
    <li>
      <input
        key={props.id}
        onChange={props.handleCheckChieldElement}
        checked={props.isChecked}
        value={props.value}
      />{" "}
      {props.label}
    </li>
  );
};

function ResearchModuleExpandKeywordResearch() {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="hidden"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setisAllSelected(false);
    setselectedRows([]);
    setSelectedExportList([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [isChartDivOpen, setisChartDivOpen] = useState(false);
  const [isAllSelected, setisAllSelected] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isChartDivOpen && ref.current && !ref.current.contains(e.target)) {
        setisChartDivOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isChartDivOpen]);

  const [options, setObject] = useState({
    chart: {
      height: 200,
      type: "bar",
      events: {
        click: function () {},
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: { show: false },
  });

  const [series, setSeries] = useState([]);

  const [open, setOpen] = useState(false);

  const [searched, setSearched] = useState([]);
  const [teamlist, setteamList] = useState([]);

  const [languageSelected, setLanguageSelected] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [teamcolHidden, setteamcolHidden] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [KeywordResearch, setKeywordResearch] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(0);
  const [keywords, setKeywords] = useState([]);
  const columns = [
    { id: "keyword", label: "Keyword" },
    { id: "volume", label: "Search Volume" },
    {
      id: "competition",
      label: "Competition",
    },
    {
      id: "bids",
      label: "Top Page Bids",
    },
  ];

  function onChangeDataSource(e) {
    var c = e.target.value;
    setSelectedDataSource(c);
  }

  useEffect(() => {
    getkeywordbyueser();
    getCountryList();
    var data = [];
    setteamList(data);
    const columnsHidden = [
      {
        dataIndex: "keyword",
      },
      {
        dataIndex: "volume",
      },
      {
        dataIndex: "competition",
      },
      {
        dataIndex: "bids",
      },
    ];
    setteamcolHidden(columnsHidden);
    const columns = [
      {
        title: "Keyword Ideas",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Search Volume",
        dataIndex: "volume",
        key: "volume",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "Competition",
        dataIndex: "competition",
        key: "competition",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.competition, b.competition),
          multiple: 3,
        },
      },
      {
        title: "Top Page Bids",
        dataIndex: "bids",
        key: "bids",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.bids, b.bids),
          multiple: 3,
        },
      },
    ];
    setteamcol(columns);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          keyword: item.keyword,
          volume: item.volume,
          competition: CompetitionLevel[item.competition],
          bids: item.bids,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function addKeyword(value) {
    if (value) {
      if (value.includes(",")) {
        let values = value.split(",");
        const words = [...keywords];
        values?.map((key) => {
          words.push(key);
        });
        setKeywords(words);
      } else {
        const words = [...keywords];
        words.push(value);
        setKeywords(words);
      }
    }
  }

  function removeKeyword(index) {
    const words = [...keywords];
    words.splice(index, 1);
    setKeywords(words);
  }

  function getKeywordData(getChartdata, rowDataa) {
    let location = [];
    countrySelected?.map((data) => {
      location.push({
        LocationId: data.id,
      });
    });
    let rowdata = {
      LanguageId: languageSelected,
      DataSource: selectedDataSource,
      userId: localStorage.getItem("userId"),
      keywords: keywords,
      KeywordResearchConfigurationLocationUserMappings: location,
      startDate: getChartdata.startDate,
      endDate: getChartdata.endDate,
    };

    APIManager.postAPI(
      getKeywordideas,
      rowDataa == "" ? rowdata : rowDataa,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getKeywordChartData(getChartdata);
        const exportList = [];
        let list1 = [];
        let list2 = [];
        let i = 0;
        response.data.map((item) => {
          exportList.push({
            keyword: item.keyword,
            volume: item.keywordIdeaMetrics[0].avgMonthlySearches,
            competition:
              CompetitionLevel[item.keywordIdeaMetrics[0].competition],
            bids:
              Math.round(
                item.keywordIdeaMetrics[0].highTopOfPageBidMicros * 100
              ) / 100,
          });

          list1.push({
            key: (i += 1),
            keyword: item.keyword,
            volume: (
              <div className="col-md-12 d-flex justify-content-between">
                {item.keywordIdeaMetrics[0].avgMonthlySearches}
                <div
                  onClick={() => {
                    getMonthlySearchVolumes(item.keywordIdeaMetrics[0].id);
                  }}
                >
                  <i
                    class="fa fa-plus-circle position-relative"
                    aria-hidden="true"
                  ></i>{" "}
                </div>
              </div>
            ),
            competition:
              CompetitionLevel[item.keywordIdeaMetrics[0].competition],
            bids:
              Math.round(
                item.keywordIdeaMetrics[0].highTopOfPageBidMicros * 100
              ) / 100,
          });
        });

        setteamList(list1);
        setExportList(exportList);
      }
    });
  }

  function getKeywordResearchData() {
    APIManager.getAPI(
      getResearchkeyworduserconfig + localStorage.getItem("userId")
    ).then((response) => {
      if (response && response.status == 200) {
        const laglist = [];
        laglist.push({
          key: response.data?.languageId,
          id: response.data?.languageId,
          label: response.data?.language?.languageName,
          value: response.data?.language?.languageName,
        });
        setLanguageSelected(laglist[0].id);
        const keywordList = [];
        setKeywords(response.data.keywords);
        const countrylist = [];
        countrylist.push({
          key: response.data.keywordResearchConfigurationLocationUserMappings[0]
            ?.location.id,
          id: response.data.keywordResearchConfigurationLocationUserMappings[0]
            ?.location.id,
          value:
            response.data.keywordResearchConfigurationLocationUserMappings[0]
              ?.location.countryName,
          label:
            response.data.keywordResearchConfigurationLocationUserMappings[0]
              ?.location.countryName,
        });
        setCountrySelected([countrylist[0]]);

        let listMapData = [];

        listMapData.push(keywordList[0]?.value);
        let getChartdata;
        let rowObj;
        if (is1stTime) {
          is1stTime = false;
          let d = new Date(head2);
          let month = d.getMonth();
          let year = d.getFullYear();
          var daysInMonth = getDaysInMonth(year, month);
          let date2 = year + "/" + month + "/" + daysInMonth;
          getChartdata = {
            startDate: head1?.toString(),
            endDate: date2?.toString(),
            Keywords: response.data.keywords,
            userId: localStorage.getItem("userId"),
          };
          rowObj = {
            LanguageId: laglist[0].id,
            DataSource: selectedDataSource,
            keywords: response.data.keywords,
            KeywordResearchConfigurationLocationUserMappings: [
              { LocationId: countrylist[0].id },
            ],
            userId: localStorage.getItem("userId"),
            startDate: head1?.toString(),
            endDate: date2?.toString(),
          };
        } else {
          getChartdata = {
            startDate: "01/" + head1?.toString(),
            endDate: "01/" + head2?.toString(),
            Keywords: response.data.keywords,
            userId: localStorage.getItem("userId"),
          };
          rowObj = {
            LanguageId: laglist[0].id,
            DataSource: selectedDataSource,
            keywords: response.data.keywords,
            KeywordResearchConfigurationLocationUserMappings: [
              { LocationId: countrylist[0].id },
            ],
            userId: localStorage.getItem("userId"),
            startDate: "01/" + head1?.toString(),
            endDate: "01/" + head2?.toString(),
          };
        }

        if (response.data.keywords) {
          getKeywordData(getChartdata, rowObj);
        }
      }
    });
  }
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  const [dates, setDates] = useState([
    new DateObject().subtract(12, "month"),
    new DateObject().add(-1, "month"),
  ]);

  const [open1, setOpen1] = useState(false);
  const [head1, setHead1] = useState(dates[0]?.format());
  const [head2, setHead2] = useState(dates[1]?.format());

  let is1stTime = true;
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open1 && ref.current && !ref.current.contains(e.target)) {
        setOpen1(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  function handleCountryChange(selected) {
    setCountrySelected(selected);
  }

  function getKeywordChartData(rowChartData) {
    APIManager.postAPI(
      getKeywordResearchChartbyuser,
      rowChartData,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setSeries([]);
        let graphData = [];
        let option = [];
        let chartdata = [];

        let monthList = [];
        let monthListData = [];
        const points = response.data;
        points.sort(function (a, b) {
          return a.month - b.month;
        });
        points.map((item, index) => {
          monthList.push(setMonths(parseInt(item.month) - 1));
          monthListData.push(item.searchVolume);
          if (graphData?.length) {
            graphData[index] = item.searchVolume ? item.searchVolume : 0;
          }
        });
        let keywords = [];

        option = {
          chart: {
            height: 200,
            type: "bar",
            events: {
              click: function () {},
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            title: {
              text: "Days",
              style: {
                color: "#2196f3",
              },
            },
          },
          yaxis: { show: false },
        };
        setObject(option);
        chartdata.push({
          name: keywords,
          data: graphData,
        });
        setSeries(chartdata);

        setObject3({
          title: {
            text: "",
            align: "left",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          dataLabels: {
            formatter: () => {
              return "";
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            show: false,
            logBase: 0,
            tickAmount: 1,
            min: 0,
          },
        });

        setSeries3([
          {
            name: "",
            data: monthListData,
            color: "#2196f3",
          },
        ]);
      } else {
        setSeries([]);
      }
    });
  }

  function getCountryList() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      getLanguageList();
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            key: item.id,
            id: item.id,
            value: item.countryName,
            label: item.countryName,
          });
        });
        setCountryList(list);
        list.map((item) => {
          if (item.value == "India") {
            setCountrySelected(item.id);
          }
        });
      }
    });
  }

  const [monthlyList, setMonthlyList] = useState([]);
  function getMonthlySearchVolumes(id) {
    APIManager.getAPI(getMonthlySearchVolumesById + id).then((response) => {
      if (response && response.status == 200) {
        setisChartDivOpen(!isChartDivOpen);
        const monthlySearchesList = [];
        const myList = [];
        response.data.map((item) => {
          myList.push(setMonths(parseInt(item.month) - 1));
          monthlySearchesList.push(item.monthlySearches);
        });

        setMonthYear(myList);
        setMonthlyList(monthlySearchesList);

        setObject1({
          chart: {
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            show: false,
            tickPlacement: "on",
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            labels: {
              formatter: function (value) {
                return value;
              },
            },
          },
          yaxis: {
            show: false,
            logBase: 0,
            labels: {
              formatter: function (value) {
                return value;
              },
            },
          },
        });

        setSeries1([
          {
            name: "",
            data: monthlySearchesList,
            color: "#2196f3",
          },
        ]);
      }
    });
  }

  function getkeywordbyueser() {
    APIManager.getAPI(
      getKeyWordlistbyuser + localStorage.getItem("userId")
    ).then((response) => {
      if (response && response.status == 200) {
        const listData = [];
        let i = 0;
        response.data.keywordResearchs?.map((item) => {
          exportList.push({
            keyword: item.keyword,
            volume: item.keywordIdeaMetrics[0].avgMonthlySearches,
            competition:
              CompetitionLevel[item.keywordIdeaMetrics[0].competition],
            bids:
              Math.round(
                item.keywordIdeaMetrics[0].highTopOfPageBidMicros * 100
              ) / 100,
          });

          listData.push({
            key: (i += 1),
            keyword: item.keyword,
            volume: item.keywordIdeaMetrics[0].avgMonthlySearches,
            competition:
              CompetitionLevel[item.keywordIdeaMetrics[0].competition],
            bids:
              Math.round(
                item.keywordIdeaMetrics[0].highTopOfPageBidMicros * 100
              ) / 100,
          });
        });

        setteamList(listData);
        setExportList(exportList);
      }
    });
  }
  const [keywordList, setKeywordList] = useState([]);
  const [isAddKeyword, setKeywordAdd] = useState(false);

  const [selectkeyword, setselectkeyword] = useState([]);
  const [keywordname, setkeywordname] = useState("");

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      getCountryList();
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
          });
        });
        setKeywordList(list);
        setselectkeyword([list[0]]);
        setkeywordname([list[0]]);
      }
    });
  }

  function getLanguageList() {
    APIManager.getAPI(getLanguage).then((response) => {
      if (response && response.status == 200) {
        setLanguageList(response.data);
        getKeywordResearchData();
        response.data?.map((item) => {
          if (item.languageName == "English") {
            setLanguageSelected(item.id);
          }
        });
      }
    });
  }

  const [monthYear, setMonthYear] = useState([
    "OCT2019",
    "NOV",
    "DEC",
    "JAN2020",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
  ]);

  const [options1, setObject1] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      show: false,
      tickPlacement: "on",
      categories: [],
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: {
      show: false,
      logBase: 0,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  const [series1, setSeries1] = useState([
    {
      name: "",
      data: [],
      color: "#2196f3",
    },
  ]);

  const [options3, setObject3] = useState({
    title: {
      text: "Reports",
      align: "left",
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      show: false,
      tickPlacement: "on",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: {
      show: false,
      logBase: 0,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  const [series3, setSeries3] = useState([]);
  const [selectedRows, setselectedRows] = useState([]);
  function onRowSelect(e, row) {
    let noOfRows = page * rowsPerPage;
    let totalNo =
      noOfRows + rowsPerPage <= teamlist?.length
        ? rowsPerPage
        : teamlist?.length - noOfRows;
    let rows = [...selectedRows];
    if (e.target.checked) {
      rows.push(row);
    } else {
      let findIndex = rows.findIndex((x) => x.key == row.key);
      rows.splice(findIndex, 1);
    }
    setisAllSelected(rows?.length == totalNo);
    setselectedRows(rows);
    setSelectedExportList(rows);
  }

  function onSelectAll(e) {
    setisAllSelected(e.target.checked);
    let noOfRows = page * rowsPerPage;
    let totalNo =
      noOfRows + rowsPerPage <= teamlist?.length
        ? rowsPerPage
        : teamlist?.length - noOfRows;

    let rows = [];
    if (e.target.checked) {
      for (let i = noOfRows; i < totalNo + noOfRows; i++) {
        rows.push(teamlist[i]);
      }
    }
    setselectedRows(rows);
    setSelectedExportList(rows);
  }
  return (
    <>
      {/* <div className="row">
        <div ref={ref} class="calendar-main">
          <div className="add-new-btnw"></div>
          <div className="text-right">
            <DatePicker
              onlyMonthPicker
              range
              value={dates}
              onChange={(dateObjects) => {
                setDates(dateObjects);

                setHead1(dateObjects[0]?.format());
                setHead2(dateObjects[1]?.format());
              }}
              plugins={[<DatePanel />]}
            />
          </div>
        </div>
      </div> */}
      <div className="row">
        <Tabs>
          <div className="portlet ">
            <div className="portlet-header portlet-header-bordered my-3">
              <div className="col-md-8 col-sm-12">
                <TabList className="nav nav-tabs portlet-nav">
                  <Tab
                    className={
                      KeywordResearch
                        ? "nav-item nav-link "
                        : "nav-item nav-link  active"
                    }
                    onClick={() => {
                      setKeywordResearch(false);
                    }}
                  >
                    Keyword Research
                  </Tab>
                  {/* <Tab
                    className={
                      KeywordResearch
                        ? "nav-item nav-link  active "
                        : "nav-item nav-link"
                    }
                    onClick={() => {
                      setKeywordResearch(true);
                    }}
                  >
                    Tickets
                  </Tab> */}
                </TabList>
              </div>
              <div
                ref={ref}
                class="col-md-4 col-sm-12 calendar-main keyword-calender"
              >
                <div className="add-new-btnw"></div>
                <div className="text-right">
                  <DatePicker
                    onlyMonthPicker
                    range
                    value={dates}
                    onChange={(dateObjects) => {
                      setDates(dateObjects);

                      setHead1(dateObjects[0]?.format());
                      setHead2(dateObjects[1]?.format());
                    }}
                    plugins={[<DatePanel />]}
                  />
                </div>
              </div>
            </div>
          </div>

          <TabPanel>
            <div className="portlet  portlet-header-bordered py-4 px-4">
              <div className="col-md-12 col-sm-12 d-flex justify-content-between p-0 align-items-center keywordresearch">
                <div class="col-md-4">
                  <label>Keywords</label>
                  {/*TODO: Find replacement of below library */}
                  {/* <ReactChipInput
                    chip-color="blue"
                    classes="class1 class2 chipinput"
                    chips={keywords}
                    onSubmit={(value) => addKeyword(value)}
                    onRemove={(index) => removeKeyword(index)}
                  /> */}
                </div>
                <div class="col-md-3 keyword-label">
                  <label>Location</label>
                  <br />
                  <ReactSelect
                    styles={themeStyle()}
                    className="w-200"
                    options={countryList}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    onChange={handleCountryChange}
                    value={countrySelected}
                    allowSelectAll={true}
                  />
                </div>
                <div class="col-md-2 keyword-label">
                  <label id="addUrlPS2">Language</label>
                  <select
                    className="w-100"
                    style={{ padding: 8 + "px" }}
                    required
                    value={languageSelected}
                    onChange={(e) => {
                      var c = e.target.value;
                      setLanguageSelected(c);
                    }}
                  >
                    {languageList?.map((item) => {
                      return (
                        <option value={item.id}>{item.languageName}</option>
                      );
                    })}
                  </select>
                </div>
                <div class="col-md-2 keyword-label">
                  <label id="addUrlPS2">Data Source</label>
                  <select
                    className="w-100"
                    style={{ padding: 8 + "px" }}
                    value={selectedDataSource}
                    onChange={onChangeDataSource}
                  >
                    <option value="0">Google</option>
                    <option value="1">Google and Search Partners</option>
                  </select>
                </div>
                <div class="col-md-1 mt-4 keyword-label">
                  <button
                    onClick={() => {
                      var newdate = dates[0];

                      let getChartdata;
                      getChartdata = {
                        startDate:
                          dates[0]
                            ?.format()
                            .toString()
                            .split("/")
                            .reverse()
                            .join("/") + "/01",
                        endDate:
                          dates[1]
                            ?.format()
                            .toString()
                            .split("/")
                            .reverse()
                            .join("/") + "/01",
                        Keywords: keywords,
                        userId: localStorage.getItem("userId"),
                      };
                      getKeywordData(getChartdata, "");
                    }}
                    className="btn btn-primary pb-0"
                    style={{ height: 36 + "px" }}
                  >
                    Generate
                  </button>
                </div>
                <div class="col-md-3">
                  {isAddKeyword ? (
                    <div style={{ marginTop: 24 + "px" }}>
                      <div class="form-wrappers">
                        <label></label>
                        <input
                          className="w-75"
                          type="text"
                          placeholder="Value"
                        />
                        <button style={{ border: "none", background: "none" }}>
                          {" "}
                          <i
                            class="fa fa-times d-flex"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                        <button style={{ border: "none", background: "none" }}>
                          {" "}
                          <i class="fa fa-check" aria-hidden="true"></i>{" "}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <div>
                  {open ? (
                    <>
                      <div id="example-collapse-text">
                        <Card
                          body
                          className="keyword-card text-center"
                          style={{ width: "70%", minWidth: "250px" }}
                        >
                          <div className="row">
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2 common-label">
                                Metric Type
                              </label>
                              <select>
                                <option value="Keyword">Keyword</option>
                              </select>
                            </div>
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2">
                                Expression
                              </label>
                              <select>
                                <option value="All">All</option>
                                <option value="Contains">Contains</option>
                                <option>Does not Contain</option>
                                <option>Exact match</option>
                                <option value="regex">Regex</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 my-2">
                              <label className=" me-2">Value</label>
                              <input type="text" placeholder="enter value" />
                            </div>
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2 ">
                                Type of match
                              </label>
                              <select>
                                <option value="Exact Match">Exact match</option>
                                <option value="Semantic Matc">
                                  Semantic Match
                                </option>
                              </select>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row">
              <div className="portlet p-0">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Keyword Research Line Chart</h3>
                </div>
                <div className="col-md-12 justify-content-center"></div>
                <Chart
                  options={options3}
                  series={series3}
                  type="line"
                  height="200"
                />
              </div>
              <div className="col-md-12 px-0">
                {searched.length === 0 ? (
                  <></>
                ) : (
                  <>
                    <div></div>
                  </>
                )}
                <div className="portlet">
                  <div className="portlet-header portlet-header-bordered">
                    <h3 className="portlet-title">Keyword Ideas</h3>
                  </div>
                  <div className="portlet-body">
                    <div className="d-flex justify-content-end mt-3 align-items-center">
                      <span class="dropdown">
                        <button
                          class="btn btn-primary dropdown-toggle"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "Keyword Research"
                            );
                          }}
                        >
                          Export
                        </button>

                        {/* <div
                          class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            onClick={() => {
                              exportToEXCEL(
                                selectedExportList?.length > 0
                                  ? selectedExportList
                                  : exportList,
                                "Keyword Research"
                              );
                            }}
                          >
                            <a class="dropdown-item">Excel</a>
                          </div>
                          <div
                            onClick={() => {
                              exportInToCSV(
                                selectedExportList?.length > 0
                                  ? selectedExportList
                                  : exportList,
                                "Keyword Research"
                              );
                            }}
                          >
                            <a class="dropdown-item">CSV</a>
                          </div>
                        </div> */}
                      </span>
                    </div>

                    <div className="col-md-12 table table-bordered table-hover mt-3 table-2-design">
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    indeterminate={
                                      !isAllSelected &&
                                      selectedRows?.length &&
                                      selectedRows?.length !== teamlist?.length
                                    }
                                    checked={isAllSelected}
                                    onChange={(e) => onSelectAll(e)}
                                  />
                                </TableCell>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="left" colSpan={5}>
                                  Keywords Provided
                                </TableCell>
                              </TableRow>
                              {teamlist
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  if (
                                    keywords?.some(
                                      (x) => x.trim() == row.keyword.trim()
                                    )
                                  ) {
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                      >
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            checked={selectedRows.some(
                                              (x) => x.key == row.key
                                            )}
                                            onChange={(e) =>
                                              onRowSelect(e, row)
                                            }
                                          />
                                        </TableCell>
                                        {columns.map((column) => {
                                          const value = row[column.id];
                                          return (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                            >
                                              {column.format &&
                                              typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  }
                                })}
                              <TableRow>
                                <TableCell align="left" colSpan={5}>
                                  Keywords Ideas
                                </TableCell>
                              </TableRow>
                              {teamlist
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  if (
                                    !keywords?.some(
                                      (x) => x.trim() == row.keyword.trim()
                                    )
                                  ) {
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                      >
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            checked={selectedRows.some(
                                              (x) => x.key == row.key
                                            )}
                                            onChange={(e) =>
                                              onRowSelect(e, row)
                                            }
                                          />
                                        </TableCell>
                                        {columns.map((column) => {
                                          const value = row[column.id];
                                          return (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                            >
                                              {column.format &&
                                              typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  }
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          component="div"
                          count={teamlist.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                          }}
                        />
                      </Paper>
                      <div className="keyword-research">
                        <div
                          className={isChartDivOpen ? "" : "d-none"}
                          style={
                            isChartDivOpen
                              ? {
                                  position: "absolute",
                                  transition: "0.5s",
                                  width: "354px",
                                  zIndex: "9999",
                                  opacity: "1",
                                  top: "85px",
                                  right: "0",
                                  background: "rgb(255, 255, 255)",
                                  right: "107px",
                                  bordertop: "0",
                                  border: "2px solid #bcdefa",
                                  WebkitBoxShadow:
                                    "-1px 8px 49px -10px rgba(94,94,94,0.75)",
                                }
                              : {
                                  position: "absolute",
                                  transition: "0.5s",
                                  width: "0px",
                                  zIndex: "9999",
                                  opacity: "0",
                                }
                          }
                        >
                          <div>
                            <Chart
                              options={options1}
                              series={series1}
                              type="line"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel>
            <ModuleExpandTickets
              startDate={head1}
              endDate={head2}
              refreshTicketData={refreshTicketData}
            />
          </TabPanel> */}
        </Tabs>
      </div>
    </>
  );
}

function KeywordRefinement() {
  const [fruites, setfruites] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "Non-Brands", isChecked: false },
    { groupId: 2, id: 1, rolename: 1, value: "nike", isChecked: false },
    { groupId: 2, id: 2, rolename: 1, value: "adidas", isChecked: false },
    { groupId: 2, id: 3, rolename: 1, value: "jordans", isChecked: false },
    { groupId: 2, id: 4, rolename: 1, value: "converse", isChecked: false },
    { groupId: 2, id: 5, rolename: 1, value: "crocs", isChecked: false },
    { groupId: 2, id: 6, rolename: 1, value: "skechers", isChecked: false },
    { groupId: 2, id: 7, rolename: 1, value: "puma", isChecked: false },
  ]);
  const [shoes, setshoes] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "sneakers", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "nike shoes", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "adidas shoes", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "boots", isChecked: false },
    { groupId: 1, id: 5, rolename: 1, value: "bata shoes", isChecked: false },
    {
      groupId: 1,
      id: 6,
      rolename: 1,
      value: "liberty shoes",
      isChecked: false,
    },
    { groupId: 1, id: 7, rolename: 1, value: "puma shoes", isChecked: false },
  ]);
  const [Gender, setGender] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "male", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "female", isChecked: false },
  ]);
  const [Color, setColor] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "brown", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "black", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "pink", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "green", isChecked: false },
    { groupId: 1, id: 5, rolename: 1, value: "white", isChecked: false },
    { groupId: 1, id: 6, rolename: 1, value: "red", isChecked: false },
    { groupId: 1, id: 7, rolename: 1, value: "yellow", isChecked: false },
  ]);
  const [Other, setOther] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "air", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "water", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "2020", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "2021", isChecked: false },
  ]);
  function handleAllChecked(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(fruites));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setfruites(fruiteslocal);
  }

  function handleCheckChieldElement(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(fruites));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setfruites(fruiteslocal);
  }

  function handleAllCheckedShoes(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(shoes));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setshoes(fruiteslocal);
  }

  function handleCheckChieldElementShoes(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(shoes));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setshoes(fruiteslocal);
  }

  function handleAllCheckedGender(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Gender));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setGender(fruiteslocal);
  }

  function handleCheckChieldElementGender(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Gender));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setGender(fruiteslocal);
  }

  function handleAllCheckedColor(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Color));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setColor(fruiteslocal);
  }

  function handleCheckChieldElementColor(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Color));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setColor(fruiteslocal);
  }

  function handleAllCheckedOther(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Other));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setOther(fruiteslocal);
  }

  function handleCheckChieldElementOther(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Other));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setOther(fruiteslocal);
  }

  const [brandMain, setbrandMain] = useState(false);
  const [ShoeMain, setShoeMain] = useState(false);
  const [GenderMain, setGenderMain] = useState(false);
  const [ColorMain, setColorMain] = useState(false);
  const [OtherMain, setOtherMain] = useState(false);

  return (
    <>
      <div className="kr-list">
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setbrandMain(!brandMain);
          }}
        >
          <div className="custom-column-80">
            Brand or Non brand
            <p>Non Brands, nike, crocs, adidas</p>
          </div>
          <div className="custom-column-20">
            {brandMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div
          className={`kr-list-inner ${brandMain ? `` : `none`}`}
          id="brand-non-brand"
        >
          {[
            { id: 1, name: "Non Brands" },
            { id: 2, name: "Brands" },
          ].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllChecked(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {fruites
                  .filter((fruit) => fruit.groupId === item.id)
                  .map((fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElement}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  })}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setShoeMain(!ShoeMain);
          }}
        >
          <div className="custom-column-80">
            Shoe
            <p>sneakers, nike shoes, adidas, boots</p>
          </div>
          <div className="custom-column-20">
            {ShoeMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div className={`kr-list-inner ${ShoeMain ? `` : `none`}`} id="shoe">
          {[{ id: 1, name: "Shoes" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedShoes(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {shoes
                  .filter((fruit) => fruit.groupId === item.id)
                  .map((fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElementShoes}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  })}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setGenderMain(!GenderMain);
          }}
        >
          <div className="custom-column-80">
            Gender
            <p>Women, men</p>
          </div>
          <div className="custom-column-20">
            {GenderMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div
          className={`kr-list-inner ${GenderMain ? `` : `none`}`}
          id="gender"
        >
          {[{ id: 1, name: "Gender" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedGender(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {Gender.filter((fruit) => fruit.groupId === item.id).map(
                  (fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={
                          handleCheckChieldElementGender
                        }
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  }
                )}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setColorMain(!ColorMain);
          }}
        >
          <div className="custom-column-80">
            Color
            <p>black, white, brown, pink</p>
          </div>
          <div className="custom-column-20">
            {ColorMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div className={`kr-list-inner ${ColorMain ? `` : `none`}`} id="color">
          {[{ id: 1, name: "Color" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedColor(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {Color.filter((fruit) => fruit.groupId === item.id).map(
                  (fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElementColor}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  }
                )}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setOtherMain(!OtherMain);
          }}
        >
          <div className="custom-column-80">
            Others
            <p>air, water, 2020,2021</p>
          </div>
          <div className="custom-column-20">
            {OtherMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
      </div>
      <div className={`kr-list-inner ${OtherMain ? `` : `none`}`} id="others">
        {[{ id: 1, name: "Other" }].map((item) => (
          <div>
            <input
              type="checkbox"
              onChange={(e) => {
                handleAllCheckedOther(item.id, e);
              }}
              value="checkedall"
            />{" "}
            {item.name}
            <ul>
              {Other.filter((fruit) => fruit.groupId === item.id).map(
                (fruite) => {
                  return (
                    <CheckBox
                      key={`${item.id}-${fruite.id}`}
                      handleCheckChieldElement={handleCheckChieldElementOther}
                      {...fruite}
                      value={`${item.id}-${fruite.id}`}
                      label={fruite.value}
                    />
                  );
                }
              )}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}

export default ResearchModuleExpandKeywordResearch;
