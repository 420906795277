import { css } from '@emotion/react';
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const main = css({
    width: "100%",
    minHeight: "calc(100vh - 8rem)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.plainWhite
  });

  export const errorContainer = css({
    display: "flex",
    flexDirection : "column",
    rowGap: "0.25rem",
    justifyContent: "center",
    alignItems: "center",
  })

  export const image= css({
    width: "5rem",
    hwight: "auto"
  })
  export const message = css({
    ...textStyles.s1
  })

  export const subtitle = css({
    ...textStyles.b3,
    color: Colors.neutral[600]
  })

  export const button = css({
    marginTop: "0.75rem"
  })