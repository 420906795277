import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  downloadPDFfile,
  dateRagngeSelectWeek,
  getBase64path,
  mmDDyyyyFormateDate,
  dateRagngeSelectFornightly,
  dateRagngeSelectMonthly,
  getWeek,
  getCheckPermission,
} from "../utility/Globals";
import {
  getPageSpeedData,
  getPageSpeedGetConfig,
  getpagespeedurl,
  modulePageSpeed,
  pageSpeedDataGet,
  sendEmailApi,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import Chart from "react-apexcharts";
import {
  exportToEXCEL,
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
  mmDDyyyyFormateForForm,
  themeStyle,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import Loading from "../utility/Loading";
import { PDFExport } from "@progress/kendo-react-pdf";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import NotFound from "../NotFound";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const colourOptions1 = [
  { value: "FCP", label: "FCP" },
  { value: "FID", label: "FID" },
  { value: "LCP", label: "LCP" },
  { value: "CLS", label: "CLS" },
  { value: "PerformanceScore", label: "Score" },
];

function ModuleExpandPageSpeed() {
  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoomin: true,
          zoomout: true,
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: "Date",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          color: "#000",
        },
      },
      logBase: 0,
      tickAmount: 6,
      min: 0,
    },
  });

  const [series, setSeries] = useState([]);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Page Speed Reports");
  const [optionSelected1, setoptionSelected1] = useState("");
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [keytablelist, setkeytablelist] = useState([]);
  const [keyTableCol, setkeyTableCol] = useState([]);
  const [pagespeedtable, setpagespeedtable] = useState([]);
  const [PageSpeedCol, setPageSpeedCol] = useState([]);
  const [selectedCoreExportList, setSelectedCoreExportList] = useState([]);
  const [coreexportList, setCoreExportList] = useState([]);
  const [selectedPageExportList, setSelectedPageExportList] = useState([]);
  const [pageexportList, setPageExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [selectedDeviceType, setDeviceType] = useState("0");
  const [isdDeviceType, setIsDeviceType] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [noConfig, setNoConfig] = useState(true);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [calendarType, setCalendarType] = useState(0);
  const [notFound, setNotFound] = useState(true);

  useEffect(() => {
    setNoContent(false)
    setNotFound(getCheckPermission("page_speed", "reports"));
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Page Speed Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    getPageSpeedConfig();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedExportList(selectedRows);
    },
  };

  const corerowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          url: item.url,
          fid: item.fid,
          lcp: item.lcp,
          cls: item.cls,
        };
        list.push(obj);
      });
      setSelectedCoreExportList(list);
    },
  };

  const pagerowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          url: item.url,
          fcp: item.fcp,
          performscore: item.performscore,
          speedindex: item.speedindex,
          timetointeract: item.timetointeract,
          frstpaint: item.frstpaint,
          totalblocktime: item.totalblocktime,
          serverresponse: item.serverresponse,
        };
        list.push(obj);
      });
      setSelectedPageExportList(list);
    },
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() === "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() === "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() === "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    applyBtn2.current.click();
    let fileBase64 = await getBase64path(image);
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: modulePageSpeed,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status === 200) {
        setReceiver("");
        setSubject("Page Speed Reports");
        setEmailMessage(EmailMessage1);
        if (theme === 'dark') {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  function getPageSpeedConfig() {
    APIManager.getAPI(
      getPageSpeedGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response?.data) {
          setNoConfig(false);
          setoptionSelected1(colourOptions1[0].label);
          setCalendarType(response?.data.frequency);
          var deviceType = setDeviceType(response?.data.deviceType.toString());
          if (response?.data.deviceType === 2) {
            setIsDeviceType(true);
            setDeviceType("0");
            deviceType = "0";
          }

          if (response?.data.frequency === 1) {
            var todayDate = new Date();
            var week = getWeek(new Date(todayDate));

            let selection = {
              selection1: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection1",
              },
              selection2: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.frequency === 2) {
            var todayDate2 = new Date();

            let selection = {
              selection1: {
                startDate: addDays(todayDate2, -14),
                endDate: addDays(todayDate2, 0),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(todayDate2, -14),
                endDate: addDays(todayDate2, 0),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.frequency === 3) {
            var todayDate3 = new Date();
            var firstDay = new Date(todayDate3.getFullYear(), todayDate3.getMonth(), 1);
            var lastDay = new Date(todayDate3.getFullYear(), todayDate3.getMonth() + 1, 0);

            let selection = {
              selection1: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection1",
              },
              selection2: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          } else {
            let selection = {
              selection1: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          }

          var columns = [
            {
              title: "URL",
              dataIndex: "url",
              key: "url",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
                multiple: 3,
              },
            },
            {
              title: "FID",
              dataIndex: "fid",
              key: "fid",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.fid, b.fid),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "FID (in ms) - should be less than 100 ms",
              },
              render: (val) => (
                <div className={getfidColor(Math.round(val * 100) / 100)}>
                  {val}
                </div>
              ),
            },
            {
              title: "LCP",
              dataIndex: "lcp",
              key: "lcp",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.lcp, b.lcp),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "LCP (in sec) - should be less than 2.5 s",
              },
              render: (val) => (
                <div className={getlcpColor(Math.round(val * 100) / 100)}>
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "CLS",
              dataIndex: "cls",
              key: "cls",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.cls, b.cls),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "CLS - should be less than 0.25",
              },
              render: (val) => (
                <div className={getclsColor(Math.round(val * 100) / 100)}>
                  {val}{" "}
                </div>
              ),
            },
          ];
          setteamcol(columns);
          columns = [];
          setkeyTableCol(columns);
          columns = [
            {
              title: "URL",
              dataIndex: "url",
              key: "url",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
                multiple: 3,
              },
            },
            {
              title: "FCP",
              dataIndex: "fcp",
              key: "fcp",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.fcp, b.fcp),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "FCP (in sec) - should be less than 1.8 s",
              },
              render: (val) => (
                <div className={getfcpColor(Math.round(val * 100) / 100)}>
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "Performance Score",
              dataIndex: "performscore",
              key: "performscore",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.performscore, b.performscore),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "Performance Score - should be between 90 - 100",
              },
              render: (val) => (
                <div
                  className={getperformanceScoreolor(
                    Math.round(val * 100) / 100
                  )}
                >
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "Speed Index",
              dataIndex: "speedindex",
              key: "speedindex",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.speedindex, b.speedindex),
                multiple: 3,
              },
              showSorterTooltip: {
                title: "Speed Index (in sec) - should be between 0 - 3.4 s",
              },
              render: (val) => (
                <div
                  className={getspeedIndexColor(Math.round(val * 100) / 100)}
                >
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "Time to Interactive",
              dataIndex: "timetointeract",
              key: "timetointeract",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.timetointeract, b.timetointeract),
                multiple: 3,
              },
              showSorterTooltip: {
                title:
                  "Time to Interactive (in sec) - should be between 0 - 3.8 s",
              },
              render: (val) => (
                <div
                  className={gettimeToInteractiveColor(
                    Math.round(val * 100) / 100
                  )}
                >
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "First Meaningful Paint",
              dataIndex: "frstpaint",
              key: "frstpaint",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.frstpaint, b.frstpaint),
                multiple: 3,
              },
              showSorterTooltip: {
                title:
                  "First Meaningful Paint (in sec) - should be between 0 - 2 s",
              },
              render: (val) => (
                <div
                  className={getfirstMeaningfulPaintColor(
                    Math.round(val * 100) / 100
                  )}
                >
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "Total Blocking Time",
              dataIndex: "totalblocktime",
              key: "totalblocktime",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.totalblocktime, b.totalblocktime),
                multiple: 3,
              },
              showSorterTooltip: {
                title:
                  "Total Blocking Time (in ms) - should be less than 300 ms",
              },
              render: (val) => (
                <div
                  className={gettotalBlockingTimeolor(
                    Math.round(val * 100) / 100
                  )}
                >
                  {val}{" "}
                </div>
              ),
            },
            {
              title: "Server Response Time",
              dataIndex: "serverresponse",
              key: "serverresponse",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.serverresponse, b.serverresponse),
                multiple: 3,
              },
              showSorterTooltip: {
                title:
                  "Server Response Time (in ms) - should be less than 200 ms",
              },
              render: (val) => (
                <div
                  className={getserverResponseTimeColor(
                    Math.round(val * 100) / 100
                  )}
                >
                  {val}{" "}
                </div>
              ),
            },
          ];
          setPageSpeedCol(columns);
          let getdata =
            "StartDate=" +
            mmDDyyyyFormateDateSet(head1) +
            "&EndDate=" +
            mmDDyyyyFormateDateSet(head2) +
            "&projectId=" +
            localStorage.getItem("projectGId") +
            "&Device=" +
            deviceType;
          getPageSpeedInfo(getdata);
          getUrlList(deviceType);
        }
      }
    });
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  const [pagespeeddata, setpagespeeddata] = useState(false);
  const [urlList, setURLList] = useState([]);
  const [selectedURL, setURLSelected] = useState([]);
  const [lastUpdateDate, setDate] = useState("");

  function getfcpColor(value) {
    if (value >= 3) return "text-danger";
    else if (value >= 1.8 && value <= 3) return "text-warning";
    else if (value <= 1.8) return "text-successpage";
  }

  function getlcpColor(value) {
    if (value >= 4) return "text-danger";
    else if (value >= 2.5 && value <= 4) return "text-warning";
    else if (value <= 2.5) return "text-successpage";
  }

  function getfidColor(value) {
    if (value >= 300) return "text-danger";
    else if (value >= 100 && value < 300) return "text-warning";
    else if (value < 100) return "text-successpage";
  }

  function getclsColor(value) {
    if (value >= 0.1) return "text-danger";
    else if (value >= 0.25 && value <= 0.1) return "text-warning";
    else if (value <= 0.25) return "text-successpage";
  }

  function getperformanceScoreolor(value) {
    if (value <= 49) return "text-danger";
    else if (value >= 50 && value <= 89) return "text-warning";
    else if (value >= 90) return "text-successpage";
  }

  function getspeedIndexColor(value) {
    if (value >= 5.8) return "text-danger";
    else if (value >= 3.4 && value < 5.8) return "text-warning";
    else if (value < 3.4) return "text-successpage";
  }

  function gettimeToInteractiveColor(value) {
    if (value >= 7.3) return "text-danger";
    else if (value >= 3.9 && value < 7.3) return "text-warning";
    else if (value < 3.9) return "text-successpage";
  }

  function getfirstMeaningfulPaintColor(value) {
    if (value >= 4) return "text-danger";
    else if (value >= 2 && value < 4) return "text-warning";
    else if (value < 2) return "text-successpage";
  }

  function gettotalBlockingTimeolor(value) {
    if (value >= 600) return "text-danger";
    else if (value >= 300 && value < 600) return "text-warning";
    else if (value < 300) return "text-successpage";
  }

  function getserverResponseTimeColor(value) {
    if (value >= 500) return "text-danger";
    else if (value >= 200 && value < 500) return "text-warning";
    else if (value < 200) return "text-successpage";
  }

  function getPageSpeedInfo(getdata) {
    APIManager.getAPI(getPageSpeedData + getdata, true).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportListcore = [];
        const exportListpage = [];
        let i = 0;
        setDate(response.data[0]?.latestDate);
        response.data.forEach((item) => {
          exportListpage.push({
            url: item.url,
            fcp: Math.round(item.fcp * 100) / 100,
            performscore: Math.round(item.performanceScore * 100) / 100,
            speedindex: Math.round(item.speedIndex * 100) / 100,
            timetointeract: Math.round(item.timeToInteractive * 100) / 100,
            frstpaint: Math.round(item.firstMeaningfulPaint * 100) / 100,
            totalblocktime: Math.round(item.totalBlockingTime * 100) / 100,
            serverresponse: Math.round(item.serverResponseTime * 100) / 100,
          });
          exportListcore.push({
            url: item.url,
            fid: Math.round(item.fid * 100) / 100,
            lcp: Math.round(item.lcp * 100) / 100,
            cls: Math.round(item.cls * 100) / 100,
          });
          let obj = {
            key: (i += 1),
            projectId: localStorage.getItem("projectGId"),
            url: item.url,
            deviceType: item.deviceType,
            fcp: Math.round(item.fcp * 100) / 100,
            fid: Math.round(item.fid * 100) / 100,
            lcp: Math.round(item.lcp * 100) / 100,
            cls: Math.round(item.cls * 100) / 100,
            performscore: Math.round(item.performanceScore * 100) / 100,
            speedindex: Math.round(item.speedIndex * 100) / 100,
            timetointeract: Math.round(item.timeToInteractive * 100) / 100,
            frstpaint: Math.round(item.firstMeaningfulPaint * 100) / 100,
            totalblocktime: Math.round(item.totalBlockingTime * 100) / 100,
            serverresponse: Math.round(item.serverResponseTime * 100) / 100,
          };
          list.push(obj);
        });
        setteamList(list);
        setpagespeedtable(list);
        setPageExportList(exportListpage);
        setCoreExportList(exportListcore);
      }
    });
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function getUrlList(deviceType) {
    const url = getpagespeedurl + localStorage.getItem("projectGId");
    APIManager.getAPI(url, true).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportlist = [];
        response.data.forEach((item) => {
          list.push({
            key: item.urlId,
            id: item.urlId,
            url: item.url,
            label: item.url,
            value: item.url,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
          exportlist.push({
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
          });          
        });
        setURLSelected([list[0]]);
        setURLList(list);
        let rowdata = {
          url: [list[0]],
          dataType: optionSelected1,
          device: deviceType,
          startDate: mmDDyyyyFormateDateSet(head1),
          EndDate: mmDDyyyyFormateDateSet(head2),
          projectId: localStorage.getItem("projectGId"),
        };
        getPageSpeedByDataType(rowdata, false);
      }
    });
  }

  function getPageSpeedByDataType(rowdata, isClick) {
    let urlList = [];
    if (rowdata.url?.length) {
      rowdata.url?.forEach((url) => {
        urlList.push(url.url);
      });
    } else {
      selectedURL.forEach((item) => {
        urlList.push(item.url);
      });
    }
    rowdata.url = urlList;
    rowdata.dataType = optionSelected1
      ? optionSelected1
      : colourOptions1[0].label;
    APIManager.postAPI(pageSpeedDataGet, rowdata, null, true).then(
      (response) => {
        if (response && response.status === 200) {
          if (isClick) {
            applyBtn.current.click();
          }
          if (response.data.length > 0) {
            const list = [];
            let i = 0;
            let dataForGraph = [];
            let graphSeries = [];
            let columns = [
              {
                title: "URL",
                dataIndex: "url",
                key: "url",
                fixed: "left",
                width: 200,
              },
            ];
            const startDate = rowdata.startDate;
            const endDate = rowdata.EndDate;
            const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
            const diffDays = 0 | (diffTime / 864e5);
            for (let i = 0; i <= diffDays; i++) {
              const newdate = formateDateIntoDatemonth(
                new Date(new Date(startDate).getTime() + i * 864e5)
              );
              const datedata = mmDDyyyyFormateDateSet(
                new Date(new Date(startDate).getTime() + i * 864e5)
              );
              graphSeries.push(
                formateDateIntoDatemonth(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                )
              );
              let obj = {
                title: newdate,
                dataIndex: datedata,
                key: datedata,
              };
              columns.push(obj);
            }
            response.data.forEach((item) => {
              let pageSpeedData = [];
              let keys = Object.keys(item);
              let dates = [];
              keys.forEach((date) => {
                if (date !== "DataType" && date !== "Url") {
                  dates.push({
                    date: date,
                    value: item[date],
                  });
                }
              });
              pageSpeedData.push({
                DataType: item.DataType,
                Url: item.Url,
              });
              let graphData = [];
              let options = [];


              let obj = {
                key: (i += 1),
                url: item.Url,
              };
              for (let i = 0; i <= diffDays; i++) {
                const newdate = mmDDyyyyFormateForForm(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                );
                let point2Value;
                if (item[newdate]) {
                  if (rowdata.dataType === "FID") {
                    point2Value = Math.round(item[newdate] * 100) / 100;
                  } else {
                    point2Value = Math.round(item[newdate] * 100) / 100;
                  }
                } else {
                  point2Value = 0;
                }
                obj[newdate] = point2Value;
                graphData.push(point2Value);
                graphSeries.push(
                  formateDateIntoDatemonth(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  )
                );
              }
              if (
                rowdata.dataType.toUpperCase() === item.DataType.toUpperCase()
              ) {
                list.push(obj);
                dataForGraph.push({
                  name: item.Url,
                  data: graphData,
                });
              }
             
              options = {
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  tickPlacement: "on",
                  categories: graphSeries,
                  title: {
                    text: "Date",
                    style: {
                      color: "#000",
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "Speed",
                    style: {
                      color: "#000",
                    },
                  },
                  logBase: 0,
                  tickAmount: 6,
                  min: 0,
                  max: (max) => {
                    return max * 0.30 + max;
                  },
                },
              };
              setObject(options);
              setExportList(list);
              setSeries(dataForGraph);
              setkeyTableCol(columns);
              setkeytablelist(list);
              setExportList(list);
              setpagespeeddata(false);
            });
          } else {
            setpagespeeddata(true);
          }
        }
      }
    );
  }
  const [PageSpeed, setPageSpeed] = useState(false);
  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {noConfig ? (
            <>
              <div class=" message text-center ">
                <h4>
                  The Page Speed configuration is not set. <br />
                  Please contact your administrator to set configuration.
                </h4>
              </div>
            </>
          ) : (
            <div className="row">
              <Tabs>
                <div className="portlet ">
                  <div className="portlet-header portlet-header-bordered my-3">
                    <div className="col-md-9 col-sm-12">
                      <TabList className="nav nav-lines portlet-nav">
                        <Tab
                          className={
                            PageSpeed
                              ? "nav-item nav-link "
                              : "nav-item nav-link  active"
                          }
                          onClick={() => {
                            setPageSpeed(false);
                          }}
                        >
                          Page Speed
                        </Tab>
                      </TabList>
                    </div>
                    {!PageSpeed ? (
                      <div
                        ref={ref}
                        class="col-md-3 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender page-speed cal_response"
                      >
                        <div className="add-new-btnw">
                          <button
                            className="btn btn-primary px-3"
                            style={{ width: "max-content" }}
                            onClick={() => setOpen(!open)}
                          >
                            {calanderDate(head1, head2)}
                          </button>
                        </div>
                        {open && (
                          <div id="example-collapse-text-calendar">
                            <Card body className="daterange-picker-card  mt-2">
                              <div className="d-flex calendar-flex">
                                <div className="calendar-col">
                                  <DateRangePicker
                                    onChange={(item) => {
                                      let isCompareVarNew = localStorage.getItem("isCompareVar");
                                      if (calendarType === 1) {
                                        let selection = {};
                                        if (isCompareVarNew === "false") {
                                          let selection23 = {
                                            selection1: {
                                              startDate: item?.selection1
                                                ? item?.selection1?.startDate
                                                : item?.selection2?.startDate,
                                              endDate: item?.selection1
                                                ? item?.selection1?.endDate
                                                : item?.selection2?.endDate,
                                              key: "selection1",
                                            },
                                          };

                                          selection = dateRagngeSelectWeek(
                                            selection23,
                                            state,
                                            isCompareVarNew
                                          );

                                          setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                          setHead1(new DateObject(selection?.selection1?.startDate).format());
                                          setHead2(new DateObject(selection?.selection1?.endDate).format());
                                        }
                                        setState({
                                          ...state,
                                          ...item,
                                          ...selection,
                                        });
                                      } else if (calendarType === 2) {
                                        let selection = {};

                                        if (isCompareVarNew === "false") {
                                          let selection23 = {
                                            selection1: {
                                              startDate: item?.selection1
                                                ? item?.selection1?.startDate
                                                : item?.selection2?.startDate,
                                              endDate: item?.selection1
                                                ? item?.selection1?.endDate
                                                : item?.selection2?.endDate,
                                              key: "selection1",
                                            },
                                          };

                                          selection = dateRagngeSelectFornightly(
                                            selection23,
                                            state,
                                            isCompareVarNew
                                          );

                                          setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                          setHead1(new DateObject(selection?.selection1?.startDate).format());
                                          setHead2(new DateObject(selection?.selection1?.endDate).format());
                                        }

                                        setState({
                                          ...state,
                                          ...item,
                                          ...selection,
                                        });
                                      } else if (calendarType === 3) {
                                        let selection = {};

                                        if (isCompareVarNew === "false") {
                                          let selection23 = {
                                            selection1: {
                                              startDate: item?.selection1
                                                ? item?.selection1?.startDate
                                                : item?.selection2?.startDate,
                                              endDate: item?.selection1
                                                ? item?.selection1?.endDate
                                                : item?.selection2?.endDate,
                                              key: "selection1",
                                            },
                                          };

                                          selection = dateRagngeSelectMonthly(
                                            selection23,
                                            state,
                                            isCompareVarNew
                                          );

                                          setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                          setHead1(new DateObject(selection?.selection1?.startDate).format());
                                          setHead2(new DateObject(selection?.selection1?.endDate).format());
                                        }

                                        setState({
                                          ...state,
                                          ...item,
                                          ...selection,
                                        });
                                      } else {
                                        if (isCompareVarNew === "false") {
                                          let selection23 = {};
                                          if (item?.selection1) {
                                            selection23 = {
                                              selection2: {
                                                startDate: item?.selection1?.startDate,
                                                endDate: item?.selection1?.endDate,
                                                key: "selection2",
                                              },
                                            };

                                            setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                            setHead1(new DateObject(item?.selection1?.startDate).format());
                                            setHead2(new DateObject(item?.selection1?.endDate).format());

                                          } else if (item?.selection2) {
                                            selection23 = {
                                              selection1: {
                                                startDate: item?.selection2?.startDate,
                                                endDate: item?.selection2?.endDate,
                                                key: "selection1",
                                              },
                                            };

                                            setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                            setHead1(new DateObject(item?.selection2?.startDate).format());
                                            setHead2(new DateObject(item?.selection2?.endDate).format());
                                          }

                                          setState({
                                            ...state,
                                            ...item,
                                            ...selection23,
                                          });
                                        }
                                      }
                                    }}

                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={[state?.selection1, state?.selection2]}
                                    direction="horizontal"
                                    staticRanges={[
                                      ...createStaticRanges([
                                        {
                                          label: 'Yesterday',
                                          range: () => ({
                                            startDate: defineds.startOfYesterday,
                                            endDate: defineds.endOfYesterday,
                                          }),
                                        },

                                        {
                                          label: 'This Week',
                                          range: () => ({
                                            startDate: defineds.startOfWeek,
                                            endDate: defineds.endOfWeek,
                                          }),
                                        },
                                        {
                                          label: 'Last Week',
                                          range: () => ({
                                            startDate: defineds.startOfLastWeek,
                                            endDate: defineds.endOfLastWeek,
                                          }),
                                        },
                                        {
                                          label: 'This Month',
                                          range: () => ({
                                            startDate: defineds.startOfMonth,
                                            endDate: defineds.endOfMonth,
                                          }),
                                        },
                                        {
                                          label: 'Last Month',
                                          range: () => ({
                                            startDate: defineds.startOfLastMonth,
                                            endDate: defineds.endOfLastMonth,
                                          }),
                                        },
                                      ])
                                    ]}
                                    ariaLabels={{
                                      dateInput: {
                                        selection1: {
                                          startDate: "start date input of selction 1",
                                          endDate: "end date input of selction 1",
                                        },
                                        selection2: {
                                          startDate: "start date input of selction 2",
                                          endDate: "end date input of selction 2",
                                        },
                                      },

                                      monthPicker: "month picker",
                                      yearPicker: "year picker",
                                      prevButton: "previous month button",
                                      nextButton: "next month button",
                                    }}
                                    maxDate={
                                      new Date(
                                        new Date().getFullYear(),
                                        new Date().getMonth(),
                                        new Date().getDate() - 1
                                      )
                                    }
                                  />
                                </div>
                                <div className="mt-3 mt-lg-0 text-center ms-3">
                                  <button
                                    onClick={() => {
                                      setheadvalues();
                                      setOpen(!open);
                                      let getdata =
                                        "StartDate=" +
                                        mmDDyyyyFormateDateSet(head1) +
                                        "&EndDate=" +
                                        mmDDyyyyFormateDateSet(head2) +
                                        "&projectId=" +
                                        localStorage.getItem("projectGId") +
                                        "&Device=" +
                                        selectedDeviceType;
                                      getPageSpeedInfo(getdata);
                                      let rowdata = {
                                        url: selectedURL,
                                        dataType: optionSelected1,
                                        device: selectedDeviceType,
                                        startDate: mmDDyyyyFormateDateSet(head1),
                                        EndDate: mmDDyyyyFormateDateSet(head2),
                                        projectId:
                                          localStorage.getItem("projectGId"),
                                      };
                                      getPageSpeedByDataType(rowdata, false);                                     
                                    }}
                                    className="outline-btn btn  btn-primary"
                                    style={{ width: 70 + "px", height: 30 + "px" }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </Card>
                          </div>
                        )}

                        <div className="d-flex 3-icons">
                          <a
                            className="bar-link-icon ms-3"
                            style={{ color: "black" }}
                          >
                            <div class="dropdown">
                              <a
                                type="button"
                                ref={applyBtn}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-filter" aria-hidden="true"></i>
                              </a>
                              <form class="dropdown-menu p-4">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-12 my-2">
                                        <div className="select-url">
                                          <label class="common-label">URL</label>
                                          <ReactSelect
                                            styles={themeStyle()}
                                            className="w-200"
                                            options={urlList}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            closeMenuOnScroll={false}
                                            hideSelectedOptions={false}
                                            controlShouldRenderValue={false}
                                            components={{ Option }}
                                            onChange={(selectedDomainUrl) => {
                                              setURLSelected(selectedDomainUrl);
                                            }}
                                            value={selectedURL}
                                            allowSelectAll={true}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12 my-2 select-lable">
                                        <label class="common-label">
                                          Data Type
                                        </label>
                                        <select
                                          style={{ padding: 8 + "px" }}
                                          className="w-100"
                                          value={optionSelected1?.value}
                                          onChange={(e) => {
                                            let selected = {
                                              label: e.target.value,
                                              value: e.target.value,
                                            };
                                            setoptionSelected1(selected.label);
                                          }}
                                        >
                                          {colourOptions1?.map((item) => {
                                            return (
                                              <option value={item.value}>
                                                {item.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      {isdDeviceType && (
                                        <div className="col-md-12 my-2">
                                          <label style={{ fontSize: 14 + "px" }}>
                                            Device Type
                                          </label>
                                          <select
                                            style={{ padding: 8 + "px" }}
                                            className="w-100"
                                            value={selectedDeviceType}
                                            onChange={(e) => {
                                              setDeviceType(e.target.value);
                                            }}
                                          >
                                            <option value="0">Mobile</option>
                                            <option value="1">Desktop</option>
                                          </select>
                                        </div>
                                      )}
                                      <div className="col-md-12 my-3">
                                        <button
                                          type="button"
                                          class="btn btn-primary me-3"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            let rowdata = {
                                              url: selectedURL,
                                              dataType: optionSelected1,
                                              device: selectedDeviceType,
                                              startDate:
                                                mmDDyyyyFormateDateSet(head1),
                                              EndDate:
                                                mmDDyyyyFormateDateSet(head2),
                                              projectId:
                                                localStorage.getItem("projectGId"),
                                            };
                                            getPageSpeedByDataType(rowdata, true);
                                            let getdata =
                                              "StartDate=" +
                                              mmDDyyyyFormateDateSet(head1) +
                                              "&EndDate=" +
                                              mmDDyyyyFormateDateSet(head2) +
                                              "&projectId=" +
                                              localStorage.getItem("projectGId") +
                                              "&Device=" +
                                              selectedDeviceType;
                                            getPageSpeedInfo(getdata);
                                          }}
                                        >
                                          {" "}
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                          <div className="ms-3  ">
                            <a
                              className="share bar-link-icon"
                              style={{ color: "black" }}
                            >
                              <div class="dropdown">
                                <a
                                  type="button"
                                  ref={applyBtn2}
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="fa fa-share-alt" aria-hidden="true"></i>
                                </a>
                                <form class="dropdown-menu p-4 filter-dropdown">
                                  <div className="filter-popup">
                                    <div className="portlet px-2 py-2">
                                      <div className="row">
                                        <div className="col-md-2 text-start">
                                          <label class="my-2 pt-2">From</label>
                                        </div>
                                        <div className="col-md-10">
                                          {" "}
                                          <input
                                            type="text"
                                            required
                                            placeholder="Sender Email Id"
                                            value={Sender}
                                            className="w-100 form-control my-2"
                                            name="Sender"
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-2 text-start">
                                          <label class="my-2 pt-2">To</label>
                                          <label class="field-required"> * </label>
                                        </div>
                                        <div className="col-md-10">
                                          {" "}
                                          <input
                                            type="text"
                                            required
                                            placeholder="Recipient Email Id"
                                            className="w-100 form-control my-2"
                                            value={Receiver}
                                            name="Receiver"
                                            onChange={(text) =>
                                              setReceiver(text.target.value)
                                            }
                                          />
                                          <div style={{ color: "red" }}>
                                            {MessageReceiver}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-2 text-start">
                                          <label class="my-2 pt-2">Subject</label>
                                          <label class="field-required"> * </label>
                                        </div>
                                        <div className="col-md-10">
                                          <input
                                            type="text"
                                            required
                                            placeholder="Subject"
                                            value={subject}
                                            onChange={(text) => {
                                              setSubject(text.target.value);
                                            }}
                                            className="w-100 form-control my-2"
                                            name="Subject"
                                          />
                                          <div style={{ color: "red" }}>
                                            {MessageSubject}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-2 text-start">
                                          <label class="my-2 pt-2">Message</label>
                                          <label class="field-required"> * </label>
                                        </div>
                                        <div className="col-md-10">
                                          <textarea
                                            name=""
                                            id=""
                                            cols="80"
                                            rows="7"
                                            className="w-100 form-control my-2 gscmsg"
                                            value={EmailMessage}
                                            onChange={(text) => {
                                              setEmailMessage(text.target.value);
                                            }}
                                          ></textarea>
                                          <div style={{ color: "red" }}>
                                            {Message}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-10 my-2">
                                          <button
                                            type="button"
                                            class="btn btn-primary"
                                            style={{
                                              width: 70 + "px",
                                              height: 30 + "px",
                                            }}
                                            onClick={() => {
                                              if (isValidEmailData()) {
                                                sendEmail();
                                              }
                                            }}
                                          >
                                            {" "}
                                            Send
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </a>
                          </div>
                          <div className="row">
                            <div className="col-md-12 download-filter">
                              <span
                                class="dropdown"
                                style={{
                                  marginRight: 0 + "px",
                                  textAlign: "right",
                                  display: "block",
                                }}
                              >
                                {/* {getCheckPermission("export", "modules") ? ( */}
                                <button
                                  class="outline-btn dropdown-toggle btn btn-primary"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    class="fa fa-download "
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                {/* ) : (
                              <></>
                            )} */}
                                <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div
                                    onClick={async () => {
                                      setLoading(true);
                                      const promise = await downloadPDFfile(
                                        image,
                                        modulePageSpeed
                                      );
                                      try {
                                        if (promise) {
                                          setLoading(false);
                                        } else {
                                          setLoading(false);
                                        }
                                      } catch (e) {
                                        setLoading(false);
                                      }
                                    }}
                                  >
                                    <a class="dropdown-item text-center">PDF</a>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <TabPanel>
                  <PDFExport
                    paperSize="A1"
                    margin="2cm"
                    fileName={modulePageSpeed}
                    ref={pdfExportComponent}
                    forcePageBreak=".page-break"
                  >
                    <div ref={image}>
                      <div className="row" id="web-page-pdf">
                        <div className="col-md-12 mb-4 d-flex justify-content-end"></div>
                        {noContent ? (
                          <>
                            <div class=" message text-center ">
                              <h4>
                                The Page Speed is yet in progress. <br />
                                The status will be visible here once the Page Speed
                                completed.
                              </h4>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="portlet">
                              <div className="portlet-header portlet-header-bordered ps-0">
                                <h3 className="portlet-title">Page Speed</h3>
                              </div>
                              {pagespeeddata ? (
                                <>
                                  <div className="my-5 no-data text-center">
                                    <svg
                                      class="ant-empty-img-simple"
                                      width="64"
                                      height="41"
                                      viewBox="0 0 64 41"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        transform="translate(0 1)"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <ellipse
                                          class="ant-empty-img-simple-ellipse"
                                          cx="32"
                                          cy="33"
                                          rx="32"
                                          ry="7"
                                        ></ellipse>
                                        <g
                                          class="ant-empty-img-simple-g"
                                          fill-rule="nonzero"
                                        >
                                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                          <path
                                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                            class="ant-empty-img-simple-path"
                                          ></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span className="d-block"> No data</span>
                                  </div>
                                </>
                              ) : (
                                <div className="justify-content-center align-items-center mt-3">
                                  <Chart
                                    options={options}
                                    series={series}
                                    type="line"
                                    height="300"
                                  />
                                </div>
                              )}
                            </div>

                            <div className="portlet">
                              <div className="portlet-header portlet-header-bordered ps-0">
                                <h3 className="portlet-title ">
                                  {" "}
                                  {optionSelected1}{" "}
                                </h3>
                              </div>{" "}
                              {pagespeeddata ? (
                                <>
                                  <div className="my-5 no-data text-center">
                                    <svg
                                      class="ant-empty-img-simple"
                                      width="64"
                                      height="41"
                                      viewBox="0 0 64 41"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        transform="translate(0 1)"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <ellipse
                                          class="ant-empty-img-simple-ellipse"
                                          cx="32"
                                          cy="33"
                                          rx="32"
                                          ry="7"
                                        ></ellipse>
                                        <g
                                          class="ant-empty-img-simple-g"
                                          fill-rule="nonzero"
                                        >
                                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                          <path
                                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                            class="ant-empty-img-simple-path"
                                          ></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <span className="d-block"> No data</span>
                                  </div>
                                </>
                              ) : (
                                <div className="portlet-body">
                                  <div className="col-md-12 common-mt-24 mt-3 text-end">
                                    <span class="dropdown text-right">
                                      {/* {getCheckPermission("export", "modules") ? ( */}
                                      <button
                                        class="btn btn-primary dropdown-toggle mx-2"
                                        type="button"
                                        onClick={() => {
                                          exportToEXCEL(
                                            selectedExportList?.length > 0
                                              ? selectedExportList
                                              : exportList,
                                            "Page Speed"
                                          );
                                        }}
                                      >
                                        Export
                                      </button>
                                      {/* ) : (
                                    <></>
                                  )} */}
                                      {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : exportList,
                                          "Page Speed"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item" href="#">
                                        Excel
                                      </a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : exportList,
                                          "Page Speed"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item" href="#">
                                        CSV
                                      </a>
                                    </div>
                                  </div> */}
                                    </span>

                                    <Table
                                      className="page-speed-second table table-bordered table-hover mt-3 w-100"
                                      style={{ overflow: "hidden" }}
                                      columns={keyTableCol}
                                      dataSource={[...keytablelist]}
                                      rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                      }}
                                      pagination={{
                                        position: ["bottomRight"],
                                        showSizeChanger: true,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <Tabs
                              className="tabs-inner-page-speed common-mb-24 common-mt-24 px-0"
                              defaultIndex={0}
                              onSelect={(index) => {                            
                              }}
                            >
                              <div className="portlet  portlet-header-bordered py-4 px-2">
                                <div class="row">
                                  <div class="col-md-12 d-flex pad-lzero mt-3">
                                    <TabList>
                                      <Tab>Core Web Vitals</Tab>
                                      <Tab>Page Speed</Tab>
                                    </TabList>
                                  </div>
                                </div>
                              </div>

                              <TabPanel>
                                <div className="portlet ">
                                  <div className="portlet-header portlet-header-bordered">
                                    <h3 className="portlet-title">
                                      Core Web Vitals{" "}
                                    </h3>
                                  </div>
                                  <div className="portlet-body">
                                    <div className="row">
                                      <div className="col-md-12 d-flex justify-content-end">
                                        <div className="me-3 mt-2">
                                          Last update date :{" "}
                                          {lastUpdateDate
                                            ? mmDDyyyyFormateDate(lastUpdateDate)
                                            : " - "}
                                        </div>
                                        <span
                                          class="dropdown"
                                          style={{
                                            marginRight: 0 + "px",
                                            textAlign: "right",
                                            display: "block",
                                          }}
                                        >
                                          {/* {getCheckPermission(
                                        "export",
                                        "modules"
                                      ) ? ( */}
                                          <button
                                            class="outline-btn dropdown-toggle btn btn-primary"
                                            type="button"
                                            onClick={() => {
                                              exportToEXCEL(
                                                selectedCoreExportList?.length > 0
                                                  ? selectedCoreExportList
                                                  : coreexportList,
                                                "Core Web Vitals"
                                              );
                                            }}
                                          >
                                            Export
                                          </button>
                                          {/* ) : (
                                        <></>
                                      )} */}
                                          {/* <div
                                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <div
                                          onClick={() => {
                                            exportToEXCEL(
                                              selectedCoreExportList?.length > 0
                                                ? selectedCoreExportList
                                                : coreexportList,
                                              "Core Web Vitals"
                                            );
                                          }}
                                        >
                                          <a class="dropdown-item">Excel</a>
                                        </div>
                                        <div
                                          onClick={() => {
                                            exportInToCSV(
                                              selectedCoreExportList?.length > 0
                                                ? selectedCoreExportList
                                                : coreexportList,
                                              "Core Web Vitals"
                                            );
                                          }}
                                        >
                                          <a class="dropdown-item">CSV</a>
                                        </div>
                                      </div> */}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="common-mt-24">
                                      <Table
                                        className="table table-bordered table-hover mt-3"
                                        id="datatable-1"
                                        columns={teamcol}
                                        dataSource={teamlist}
                                        rowSelection={{
                                          type: "checkbox",
                                          ...corerowSelection,
                                        }}
                                        pagination={{
                                          position: ["bottomRight"],
                                          showSizeChanger: true,
                                        }}
                                        showSorterTooltip={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>

                              <TabPanel>
                                <div className="portlet">
                                  <div className="portlet-header portlet-header-bordered">
                                    <h3 className="portlet-title">Page Speed </h3>
                                  </div>
                                  <div className="portlet-body">
                                    <div className="col-md-12 d-flex justify-content-end">
                                      <div className="me-3 mt-2">
                                        Last update date :{" "}
                                        {lastUpdateDate
                                          ? mmDDyyyyFormateDate(lastUpdateDate)
                                          : " - "}
                                      </div>
                                      <span
                                        class="dropdown mb-3"
                                        style={{
                                          marginRight: 0 + "px",
                                          textAlign: "right",
                                          display: "block",
                                        }}
                                      >
                                        {/* {getCheckPermission("export", "modules") ? ( */}
                                        <button
                                          class="outline-btn dropdown-toggle btn btn-primary"
                                          type="button"
                                          onClick={() => {
                                            exportToEXCEL(
                                              selectedPageExportList?.length > 0
                                                ? selectedPageExportList
                                                : pageexportList,
                                              "Page Speed"
                                            );
                                          }}
                                        >
                                          Export
                                        </button>
                                        {/* ) : (
                                      <></>
                                    )} */}
                                        {/* <div
                                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <div
                                        onClick={() => {
                                          exportToEXCEL(
                                            selectedPageExportList?.length > 0
                                              ? selectedPageExportList
                                              : pageexportList,
                                            "Page Speed"
                                          );
                                        }}
                                      >
                                        <a class="dropdown-item">Excel</a>
                                      </div>
                                      <div
                                        onClick={() => {
                                          exportInToCSV(
                                            selectedPageExportList?.length > 0
                                              ? selectedPageExportList
                                              : pageexportList,
                                            "Page Speed"
                                          );
                                        }}
                                      >
                                        <a class="dropdown-item">CSV</a>
                                      </div>
                                    </div> */}
                                      </span>
                                    </div>
                                    <div class="common-mt-24">
                                      <Table
                                        className="table table-bordered table-hover pagespeed-table mt-3"
                                        id="datatable-1"
                                        columns={PageSpeedCol}
                                        dataSource={pagespeedtable}
                                        rowSelection={{
                                          type: "checkbox",
                                          ...pagerowSelection,
                                        }}
                                        pagination={{
                                          position: ["bottomRight"],
                                          showSizeChanger: true,
                                        }}
                                        showSorterTooltip={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </>
                        )}
                      </div>
                    </div>
                  </PDFExport>
                </TabPanel>
              </Tabs>
            </div>
          )}

          <Loading isLoading={isLoading} />
        </>
      )}</>
  );
}

export default ModuleExpandPageSpeed;
