import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
    position: "absolute",
    top:0,
    left:0,
    right:0,
    bottom:0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99999 
})

export const backdrop = css({
    position: "absolute",
    top:0,
    left:0,
    right:0,
    bottom:0,
    backgroundColor: Colors.neutral[900],
    opacity:"60%",
    zIndex: 99999 
})

export const modal = css({
    backgroundColor: Colors.plainWhite,
    padding: "1rem",
    width: "50rem",
    borderRadius: "0.5rem"
})

export const top= css({
    display: "flex",
    columnGap: "1rem"
})

export const iconContainer = (theme) => css({
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "100%",
    backgroundColor: theme === "info" ? Colors.primary[75]: theme === "danger" ? Colors.error[50] : "",
    display: "flex",
    alignItems : "center",
    justifyContent: "center",
    flexShrink: 0
})

export const icon = (theme) => css({
    width: "1.25rem",
    height: "1.25rem",
    strokeWidth: 2.5,
    color: theme === "info" ? Colors.primary[500]: theme === "danger" ? Colors.error[800] : ""
})

export const title = css({
    ...textStyles.h6,
    color: Colors.neutral[900]
})

export const buttonContainer = css({
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "0.75rem"
  })

  export const secondaryButton = (theme) => css({
    backgroundColor: "white !important",
    color: theme === "info" ? Colors.neutral[800]: theme === "danger" ? Colors.error[800] : "",
    borderColor: theme === "info" ? Colors.neutral[800]: theme === "danger" ? Colors.error[800] : "",
  })