import React, {useRef, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart3 = (props) => {
    const [series, setSeries] = useState(props.data);

    // useEffect(() => {
    //     const filteredSeries = props.data.filter((value) => value !== 0);
    //     setSeries(filteredSeries);
    // }, [props.data]);

    const [options, setOptions] = useState({
        chart: {
            type: 'pie',
        },
        stroke: {
            width: 0,
        },
        labels: props.labels,
        tooltip: {
            style: {
                fontSize: '15px', 
                fontWeight: 'bold',
                color: '#ffffff',
            },
        },
        title: {
            text: props.title, 
            align: 'center', 
            margin: 0, 
            offsetX: 0, 
            offsetY: 100, 
            floating: true, 
            style: {
              fontSize: '15px',
                fontFamily:'Montserrat , sans-serif',
                fontWeight: '500', 
              color: '#757575',
            },
        },
        responsive: [
            {
                // breakpoint: 200,
                options: {
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false,
                        showForSingleSeries: false
                    }

                },
            },
        ],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                console.log(opts.w.config.series[opts.seriesIndex], 'actualValue')
                return val === 0 ? '' : opts.w.config.series[opts.seriesIndex]
            },
            style: {
                fontSize: '15px',

            },
            offsetY:8,
            dropShadow: {
                enabled: false,
            },

            percentage: false
        },
        plotOptions: {
            stroke: {
                show: false, 
                color: 'transparent', 
            },
            pie:{
                dataLabels:{
                    offset: -5
                }
            }
        },
        colors: ['#91e395', '#ffaa46', '#ef5350'], 

    });

    const tooltipRef1 = useRef(null);

    /* To Remove the Tooltip while exporting the pdf*/
    const handleMouseOver = () => {
        if (tooltipRef1.current) {
            const tooltipElement = tooltipRef1.current.querySelector('.apexcharts-tooltip-series-group');
            const toolTipStyleRemove = tooltipRef1.current.querySelector('.apexcharts-tooltip');
            if (tooltipElement) {
                tooltipElement.classList.remove('apexcharts-active');
                toolTipStyleRemove.removeAttribute('style');
                let currentStyle = tooltipElement.getAttribute('style');
                const stylesToRemove = ['display: flex;', 'background-color: rgb(145, 227, 149);'];
                stylesToRemove.forEach(style => {
                    currentStyle = currentStyle.replace(style, '');
                });

                tooltipElement.setAttribute('style', currentStyle);

                const yLabelElement = tooltipRef1.current.querySelectorAll('.apexcharts-tooltip-text-y-label');
                const yValueElement = tooltipRef1.current.querySelectorAll('.apexcharts-tooltip-text-y-value')
                yLabelElement.forEach(element => {
                    element.innerHTML = ''; // Remove the text content
                });

                yValueElement.forEach(element => {
                    element.innerHTML = ''; // Remove the text content
                });
            }
        }
    };

    const handleMouseScroll = () => {
        if (tooltipRef1.current) {
          const tooltipElement = tooltipRef1.current.querySelector('.apexcharts-tooltip-series-group');
          const toolTipStyleRemove = tooltipRef1.current.querySelector('.apexcharts-tooltip');
          if (tooltipElement) {
            tooltipElement.classList.remove('apexcharts-active');
            toolTipStyleRemove.removeAttribute('style');
            let currentStyle = tooltipElement.getAttribute('style');
            const stylesToRemove = ['display: flex;', 'background-color: rgb(145, 227, 149);'];
            stylesToRemove.forEach((style) => {
              currentStyle = currentStyle.replace(style, '');
            });
      
            tooltipElement.setAttribute('style', currentStyle);
      
            const yLabelElement = tooltipRef1.current.querySelectorAll('.apexcharts-tooltip-text-y-label');
            const yValueElement = tooltipRef1.current.querySelectorAll('.apexcharts-tooltip-text-y-value');
      
            yLabelElement.forEach((element) => {
              element.innerHTML = ''; // Remove the text content
            });
      
            yValueElement.forEach((element) => {
              element.innerHTML = ''; // Remove the text content
            });
          }
        }
      };

    useEffect(() => {
        if (tooltipRef1.current) {
            tooltipRef1.current.addEventListener('wheel', handleMouseScroll);
            tooltipRef1.current.addEventListener('mouseover', handleMouseOver);
        }

        return () => {
            if (tooltipRef1.current) {
                tooltipRef1.current.removeEventListener('wheel', handleMouseScroll);
                tooltipRef1.current.addEventListener('mouseover', handleMouseOver);
            }
        };
    }, []);

    return (
        <div ref={tooltipRef1} id={props.id}>
            <ReactApexChart options={options} series={series} type="pie" width="100%" height="80%" />
            {/* <div className="chart-title">
                <div className="text-center">
                    <div className="d-flexx">
                        {props.title}
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default PieChart3;
