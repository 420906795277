import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main =  css({
    ".apexcharts-xaxis .apexcharts-text.apexcharts-xaxis-label tspan" : {
        fontSize: "0.75rem !important",
        fontWeight: 500,
        fill: Colors.neutral[600],
        marginTop: -10
    },
    ".css-1whk94d-LineChart .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series":{
        pointerEvents: "none"
    }
})