import { useEffect } from "react";
import APIManager from "../services/APIManager";
import { getCampaignDetails, getCampaignStatus } from "../utility/Constant";
import { useState } from "react";

function CampaignApproval() {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let rankingCampaignConfigurationId = params.get("rankingCampaignConfigurationId");
        // let rankingCampaignConfigurationId = "a39c7282-111c-46f9-8f6c-08db5ab2034f"
        setCampaignConfigId(rankingCampaignConfigurationId)
        getCampaignDetailsFun(rankingCampaignConfigurationId);
    }, []);

    const [campaignName, setCampaignName] = useState("")
    const [credits, setCredits] = useState(0)
    const [domain, setDomain] = useState("")
    const [loation, setLocation] = useState("")
    const [keywords, setKeywords] = useState(0)
    const [competitors, setCompetitors] = useState(0)
    const [searchEngine, setSearchEngine] = useState("")
    const [frequency, setFrequency] = useState("")
    const [device, setDevice] = useState("")
    const [buckets, setBuckets] = useState("")
    const [name, setName] = useState("")
    const [createdName, setCreatedName] = useState("")
    const [campaignConfigId, setCampaignConfigId] = useState("")
    const [userId, setUserId] = useState("")
    const [flag, setFlag] = useState(true)
    const [message, setMessage] = useState("")

    function getCampaignDetailsFun(rankingCampaignConfigurationId) {
        APIManager.getAPI(getCampaignDetails + rankingCampaignConfigurationId).then(
            (response) => {
                if (response && response.status == 200) {
                    response.data.map((item) => {
                        setCampaignName(item.campaignName != null ? item.campaignName : "")
                        setCredits(item.credits != null ? item.credits : 0)
                        setDomain(item.domain != null ? item.domain : "")
                        setLocation(item.country != null ? item.country : "")
                        setKeywords(item.noOfKeywords != null ? item.noOfKeywords : 0)
                        setCompetitors(item.noOfCompetitors != null ? item.noOfCompetitors : 0)
                        setSearchEngine(item.searchEngine != null ? item.searchEngine : "")
                        setFrequency(item.frequency != null ? item.frequency : "")
                        setDevice(item.device != null ? item.device : "")
                        setBuckets(item.rankingBuckets != null ? item.rankingBuckets : "")
                        setName(item.name)
                        setCreatedName(item.createdName)
                        setUserId(item.userId)
                    })
                }
            }
        );
    }

    function getCampaignStatusFun(accept) {
        APIManager.getAPI(getCampaignStatus + campaignConfigId + "&accept=" + accept + "&userId=" + userId).then(
            (response) => {
                if (response && response.status == 200) {
                    if(response.data.status ===1 ){
                        setMessage(" Campaign is Accepted")
                    }
                    else{
                        setMessage("Campaign is Rejected")
                    }
                }
            }
        );
    }

    return (
        <>
            {flag ?
                (<div className="campaign-approval-template my-5">
                    <div className="row d-flex justify-content-center align-items-center row-box">
                        <div className="col-md-6 campaign-template-inner">
                            <div className="main-header">
                                <img src="images/infidigit-logo.png" />
                            </div>
                            <div className="contentt-box">
                                <div className="main-title-icon mt-4  d-flex justify-content-center">
                                    <div className="wrap-box text-center">
                                        <div className="img-box mb-4">
                                            <img src="images/leaderboard.png" />
                                        </div>
                                        <h4>Ranking Campaign Details</h4>
                                    </div>
                                </div>
                                <div className="title-texts mb-5 mt-5 pt-4">
                                    <div className="hi-text mb-3">Hi {name},</div>
                                    <div> <span className="highlight-text">{createdName}</span> has requested campaign approval with the campaign details as mentioned below:</div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Input Fields</th>
                                            <th>Values</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Campaign Name</td>
                                            <td>{campaignName}</td>
                                        </tr>
                                        <tr>
                                            <td>Credits</td>
                                            <td>{credits}</td>
                                        </tr>
                                        <tr>
                                            <td>Domain</td>
                                            <td>{domain}</td>
                                        </tr>
                                        <tr>
                                            <td>Country/City</td>
                                            <td>{loation}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of Keywords</td>
                                            <td>{keywords}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of Competitors</td>
                                            <td>{competitors}</td>
                                        </tr>
                                        <tr>
                                            <td>Search Engine</td>
                                            <td>{searchEngine}</td>
                                        </tr>
                                        <tr>
                                            <td>Frequency</td>
                                            <td>{frequency == 0? "Weekly" : "Monthly"}</td>
                                        </tr>
                                        <tr>
                                            <td>Device</td>
                                            <td>{device == 0 ? "Mobile" : (device == 1 ? "Desktop" : "Both")}</td>
                                        </tr>
                                        <tr>
                                            <td>Ranking Buckets</td>
                                            <td>{buckets}</td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div className="ending-text my-5">
                                    <span className="red-text">Note :</span> To ensure that the campaign's rankings are tracked, we kindly request that you approve or reject the campaign before Thursday at 5 pm this week.
                                </div>
                                <div className="button-box d-flex">
                                    <button className="blue-btn me-2"
                                        onClick={() => {
                                            setFlag(false);
                                            getCampaignStatusFun(true)
                                        }}>Accept Request</button>
                                    <button className="red-btn ms-2"
                                        onClick={() => {
                                            setFlag(false);
                                            getCampaignStatusFun(false)
                                        }}>Reject Request</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
                    <div class="message text-center" style={{ top: "50%", left: "50%" }}>
                        <h4>{message}</h4>
                    </div>
                )
            }
        </>
    );
}
export default CampaignApproval;