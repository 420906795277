import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import APIManager from "../../services/APIManager";
import {
  updateRoleAccess,
  getRoleByDept,
  getRoleAccess,
  getDepartmentById,
  getMemberByRoleId,
  getUserAccess,
  useraccesslist,
  userpermission,
} from "../../utility/Constant";

const AccessPermissions = ({ update }) => {
  const notifyUpdate = () => toast.success("Successfully Updated!");
  const [selectedRole, setSelectedRole] = useState("");
  const [rolList, setRolList] = useState([]);
  const [organisationlist, setorganisationlist] = useState([]);
  const [clientsetuplist, setclientsetuplist] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [roleAccessList, setRoleAccessList] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  const [secondcolumnchecked, setsecondcolumnchecked] = useState(false);
  const [firstData, setFirstData] = useState([]);
  const [firstclientdata, setfirstclientdata] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [thirdData, setThirdData] = useState([]);
  const [forthData, setForthData] = useState([]);
  const [reportsFirstClmData, setReportsFirstClmData] = useState([]);
  const [selectedPermissionTab, setSelectedPermissionTab] = useState('organization');

  useEffect(() => {
    getDepartment();
  }, []);

  function changeRole(e) {
    let value = e.target.value;
    setSelectedRole(value);
    getRoleAccessData(value);
    setProjectId(value);
    getmembersData(value);
    setRoleAccessList([]);
  }

  function updateAccessPermissions() {
    const margeList = [
      ...organisationlist,
      ...firstclientdata,
      ...firstData,
      ...secondData,
      ...thirdData,
      ...forthData,
      ...reportsFirstClmData
    ];
    APIManager.postAPI(
      updateRoleAccess,
      {
        roleId: projectId,
        RoleAccesses: margeList,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  function updateuserAccessPermissions() {
    const margeList =
      [...organisationlist,
      ...clientsetuplist,
      ...firstData,
      ...secondData,
      ...thirdData,
      ...forthData,
      ...reportsFirstClmData]
    APIManager.postAPI(
      useraccesslist,
      {
        UserId: memberlistSelected,
        UserAccesses: margeList,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  function getRoleAccessData(id) {
    if (id !== "select") {
      setorganisationlist([]);
      setfirstclientdata([]);
      setFirstData([]);
      setSecondData([]);
      setThirdData([]);
      setForthData([]);
      setReportsFirstClmData([]);
      APIManager.getAPI(getRoleAccess + id).then((response) => {
        if (response && response.status === 200) {
          response.data.sort((a, b) => +a.srNo - +b.srNo);
          setRoleAccessList(response.data);
          if (response?.data && response?.data?.length !== 0) {
            setRoleAccessList(true);
          } else {
            setRoleAccessList(false);
          }
          let list = response.data;
          let organizationclient = [];
          let clientsetup = [];
          let firstColumn = [];
          let firstorganazationColumn = [];
          let secondorganazationColumn = [];
          let secondColumn = [];
          let thirdColumn = [];
          let forthColumn = [];
          let reportFirstColumn = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i].module === "organization_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo === "1" || list[i].srNo <= 6) {
                firstorganazationColumn.push(list[i]);
              }
              if (list[i].srNo >= 7) {
                list[i]["isChecked"] = false;
                secondorganazationColumn.push(list[i]);
              }
              organizationclient.push(list[i]);
            } else if (list[i].module === "projects_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo >= 1 && list[i].srNo <= 4) {
                firstColumn.push(list[i]);
              }
              if (list[i].srNo > 4 && list[i].srNo <= 11) {
                secondColumn.push(list[i]);
              }
              if (list[i].srNo > 11 && list[i].srNo <= 19) {
                thirdColumn.push(list[i]);
              }
              if (list[i].srNo > 19) {
                forthColumn.push(list[i]);
              }
              firstColumn.sort((a, b) => +a.srNo - +b.srNo);
              secondColumn.sort((a, b) => +a.srNo - +b.srNo);
              thirdColumn.sort((a, b) => +a.srNo - +b.srNo);
              forthColumn.sort((a, b) => +a.srNo - +b.srNo);
            } else if (list[i].module === "clients_setup") {
              clientsetup.push(list[i]);
            }
            else if (list[i].module === "reports") {
              list[i].srNo = +list[i].srNo;
              reportFirstColumn.push(list[i]);
              reportFirstColumn.sort((a, b) => +a.srNo - +b.srNo);
            }
          }
          setFirstData(firstColumn);
          setSecondData(secondColumn);
          setsecondcolumnchecked(secondColumn.every((x) => x.permission));
          setThirdData(thirdColumn);
          setForthData(forthColumn);
          setfirstclientdata(clientsetup);
          setorganisationlist(organizationclient);
          setclientsetuplist(clientsetup);
          setReportsFirstClmData(reportFirstColumn);
        }
      });
    }
  }

  function getRolesData(departmentId) {
    APIManager.getAPI(getRoleByDept + departmentId).then((response) => {
      if (response && response.status === 200) {
        if (response?.data.length > 0) {
          setRolList(response?.data);
          setSelectedRole(response?.data[0].id);
          getRoleAccessData(response?.data[0].id);
          setProjectId(response?.data[0].id);
          getmembersData(response?.data[0].id);
        } else {
          setRolList([]);
          setSelectedRole("");
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getDepartment() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data.length > 0) {
          if (response?.data) {
            setDepartmentList(response.data);
          }
          setDepartmentSelected(response.data[0].id);
          getRolesData(response.data[0].id);
        } else {
          setDepartmentList([]);
          setDepartmentSelected("");
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getmembersData(roleId) {
    APIManager.getAPI(getMemberByRoleId + roleId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        list.push({
          id: "select",
          name: "Select",
        });
        if (response.data.length > 0) {
          response.data.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
            };
            list.push(obj);
          });
          setmemberlist(list);
          setmemberlistSelected(list[0].id);
        } else {
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getmemberuseraccess(userid) {
    if (userid !== "select") {
      setorganisationlist([]);
      setfirstclientdata([]);
      setFirstData([]);
      setSecondData([]);
      setThirdData([]);
      setForthData([]);
      setReportsFirstClmData([]);
      APIManager.getAPI(getUserAccess + userid).then((response) => {
        if (response && response.status === 200) {
          const userlist = [];
          response.data.forEach((item) => {
            let obj = {
              userId: item.userId,
              permission: item.permission,
              accessId: item.accessId,
            };
            userlist.push(obj);
          });
        }
        if (response && response.status === 200) {
          response.data.sort((a, b) => +a.srNo - +b.srNo);
          setRoleAccessList(response.data);
          if (response?.data && response?.data?.length !== 0) {
            setRoleAccessList(true);
          } else {
            setRoleAccessList(false);
          }
          let list = response.data;
          let organizationclient = [];
          let clientsetup = [];
          let firstColumn = [];
          let firstorganazationColumn = [];
          let secondorganazationColumn = [];
          let secondColumn = [];
          let thirdColumn = [];
          let forthColumn = [];
          let reportFirstColumn = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i].module === "organization_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo === "1" || list[i].srNo <= 6) {
                firstorganazationColumn.push(list[i]);
              }
              if (list[i].srNo >= 7) {
                list[i]["isChecked"] = false;
                secondorganazationColumn.push(list[i]);
              }
              organizationclient.push(list[i]);
            } else if (list[i].module === "projects_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo >= 1 && list[i].srNo <= 4) {
                firstColumn.push(list[i]);
              }
              if (list[i].srNo > 4 && list[i].srNo <= 11) {
                secondColumn.push(list[i]);
              }
              if (list[i].srNo > 11 && list[i].srNo <= 19) {
                thirdColumn.push(list[i]);
              }
              if (list[i].srNo > 19) {
                forthColumn.push(list[i]);
              }
              firstColumn.sort((a, b) => +a.srNo - +b.srNo);
              secondColumn.sort((a, b) => +a.srNo - +b.srNo);
              thirdColumn.sort((a, b) => +a.srNo - +b.srNo);
              forthColumn.sort((a, b) => +a.srNo - +b.srNo);
            } else if (list[i].module === "clients_setup") {
              clientsetup.push(list[i]);
            }
            else if (list[i].module === "reports") {
              list[i].srNo = +list[i].srNo;
              reportFirstColumn.push(list[i]);
              reportFirstColumn.sort((a, b) => +a.srNo - +b.srNo);
            }
          }
          setFirstData(firstColumn);
          setSecondData(secondColumn);
          setsecondcolumnchecked(secondColumn.every((x) => x.permission));
          setThirdData(thirdColumn);
          setForthData(forthColumn);
          setfirstclientdata(clientsetup);
          setorganisationlist(organizationclient);
          setclientsetuplist(clientsetup);
          setReportsFirstClmData(reportFirstColumn);
        }
      });
    }
  }

  async function userselected(userid) {
    try {
      const response = await APIManager.deleteAPI(userpermission + userid);
      if (response === 200) {
        setmemberlistSelected("select");
        getDepartment();
      }
    } catch (e) { }
  }

  const handleOnChange = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action === "allSecondColSelected") {
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      setsecondcolumnchecked(isChecked);
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setsecondcolumnchecked(updatedList.every((x) => x.permission));
    setSecondData(updatedList);
  };

  const handleOnChangeALLclients = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action === "allColSelecteddd") {
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setclientsetuplist(updatedList);
  };

  const handleOnChangeALLorganazation = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action === "allColSelectedd") {
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setorganisationlist(updatedList);
  };

  const handleOnChangeALL = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action === "allColSelected") {
      let forthList = [];
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      forthData?.forEach((p) => {
        p["permission"] = isChecked;
        forthList.push(p);
      });
      setForthData(forthList);
      handleOnChange(
        secondData,
        {
          action: "allSecondColSelected",
          displayName: "All Modules",
        },
        0,
        isChecked
      );
      handleOnChangeThird(
        thirdData,
        {
          action: "allThirdColSelected",
          displayName: "All Modules",
        },
        0,
        isChecked
      );
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setFirstData(updatedList);
  };
  const handleOnChangeThird = (list, name, i, isChecked) => {
    let updatedList = [];
    let fourthLsit = forthData;
    if (name.action === "allThirdColSelected") {
      fourthLsit = [];
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      forthData.forEach((p) => {
        p["permission"] = isChecked;
        fourthLsit.push(p);
      });
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });

    }
    setThirdData(updatedList);
  };

  const handleOnChangeReportFirst = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action === "allModuleSelected") {
      list.forEach((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
    } else {
      list.forEach((p, n) => {
        if (n === i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setReportsFirstClmData(updatedList);
  };
  return (
    <div className="custom-column-70 accesspermision_main">
      {roleAccessList ? (
        <div className="portlet mt-3 slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Access Permission
            </h3>
          </div>
          <div className="portlet-body">
            <div className="common-form-fields select-role mt-2 ">
              <div className="row border-bottom">
                <div className="col-sm-6 ps-0">
                  <label className="selectrole">Select Role</label>
                </div>
              </div>

              <div className="row role-depart-designation pt-4">
                <div className="col-md-4 ps-0">
                  <div className="row align-items-center">
                    <label className="depart mb-3">Department</label>
                    <div className="ps-0">
                      <select className="form-select"
                        name="depart"
                        value={departmentSelected}
                        onChange={(e) => {
                          setDepartmentSelected(e.target.value);
                          getRolesData(e.target.value);
                        }}
                      >
                        {departmentList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row align-items-center">
                    <label className="design mb-3">Designation</label>
                    <div className="ps-0">
                      <select
                        className="form-select accesspermission"
                        value={selectedRole}
                        onChange={changeRole}
                      >
                        {rolList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row align-items-center">
                    <label className="access-team-member mb-3">
                      Team Member
                    </label>
                    <div className="ps-0">
                      <select
                        className="form-select "
                        name="depart"
                        value={memberlistSelected}
                        onChange={(e) => {
                          setmemberlistSelected(e.target.value);
                          getmemberuseraccess(e.target.value);
                        }}
                      >
                        {memberlist.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  {memberlistSelected !== "select" ? <div className="mt-3">
                    <a href="#" className="revoke-user-permission ms-2 d-flex justify-content-end" style={memberlistSelected === "" || memberlistSelected === "select" ? { display: "none" } : { display: "block" }}
                      onClick={() => {
                        userselected(memberlistSelected);
                      }}
                    >Reset User Permission
                    </a>
                  </div> : <></>}
                </div>
              </div>
            </div>
            {departmentSelected === "Select Department" ? (
              <></>
            ) : (
              <>
                <div className="mt-5">
                  <div className="common-form-fields border-bottom  mt-3">
                    <div className="col-md-12 ps-0">
                      <label className="select-pemission">
                        Select Permission
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 ps-0 main-permission-section">
                    <div className="row">
                      <div className="col-md-3 mt-0 ps-0 pe-0 main-premission-content">
                        <div className={"left-permission-menu " + (selectedPermissionTab === 'organization' ? 'menu-text-active' : '')} onClick={() => {
                          setSelectedPermissionTab('organization');
                        }}>
                          <div className="menu-text">
                            Organization
                          </div>
                        </div>
                        <div className={"left-permission-menu " + (selectedPermissionTab === 'client' ? 'menu-text-active' : '')} onClick={() => {
                          setSelectedPermissionTab('client');
                        }}>
                          <div className="menu-text">
                            Clients
                          </div>
                        </div>
                        <div className={"left-permission-menu " + (selectedPermissionTab === 'project' ? 'menu-text-active' : '')} onClick={() => {
                          setSelectedPermissionTab('project');
                        }}>
                          <div className="menu-text">
                            Projects
                          </div>
                        </div>
                        <div className={"left-permission-menu " + (selectedPermissionTab === 'reports' ? 'menu-text-active' : '')} onClick={() => {
                          setSelectedPermissionTab('reports');
                        }}>
                          <div className="menu-text">
                            Reports
                          </div>
                        </div>
                      </div>
                      {selectedPermissionTab === 'organization' ?
                        <div className="col-md-9">
                          <div className="left-permission-menu d-flex justify-content-between">
                            <div className="menu-text ml-0">
                              Primary Controls
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList = [];
                              organisationlist.forEach((item, i) => {
                                if (i < 4) {
                                  item["permission"] = false;
                                  item["permission"] = !item["permission"];
                                }
                                updatedList.push(item);
                              });
                              setorganisationlist(updatedList);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 mt-0 mt-md-3 ps-0">
                            {organisationlist.map((item, i) => {
                              return i < 4 ? (
                                <>
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChangeALLorganazation(
                                          organisationlist,
                                          item,
                                          i,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span className="right-section-points">{item.displayName}</span>
                                  </label>
                                </>
                              ) : (
                                <></>
                              );
                            })}
                          </div>

                          <div className="left-permission-menu d-flex justify-content-between mb-3">
                            <div className="menu-text ml-0">
                              Functional Controls
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList = [];
                              organisationlist.forEach((item, i) => {
                                if (i > 4) {
                                  item["permission"] = false;
                                  item["permission"] = !item["permission"];
                                }
                                updatedList.push(item);
                              });
                              setorganisationlist(updatedList);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          {organisationlist.map((item, i) => {
                            return i > 4 ? (
                              <>
                                <label className="d-flex">
                                  <input
                                    className="me-2"
                                    defaultChecked={item.permission}
                                    style={{ madginRight: "10px" }}
                                    type="checkbox"
                                    checked={item.permission}
                                    onChange={(e) => {
                                      handleOnChangeALLorganazation(
                                        organisationlist,
                                        item,
                                        i,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className="right-section-points">{item.displayName}</span>
                                </label>
                              </>
                            ) : <></>
                          })}
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                if (
                                  memberlistSelected !== "select"
                                ) {
                                  updateuserAccessPermissions();
                                } else {
                                  updateAccessPermissions();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div> : <></>}
                      {selectedPermissionTab === 'client' ?
                        <div className="col-md-9">
                          <div className="left-permission-menu d-flex justify-content-between">
                            <div className="menu-text ml-0">
                              Primary Controls
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList = [];
                              clientsetuplist.forEach((item, i) => {
                                if (i < 5) {
                                  item["permission"] = false;
                                  item["permission"] = !item["permission"];
                                }
                                updatedList.push(item);
                              });
                              setclientsetuplist(updatedList);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 mt-0 mt-md-3 ps-0">
                            {clientsetuplist.map((item, i) => {
                              return (
                                <label className="d-flex">
                                  <input
                                    className="me-2"
                                    defaultChecked={item.permission}
                                    style={{ madginRight: "10px" }}
                                    type="checkbox"
                                    checked={item.permission}
                                    onChange={(e) => {
                                      handleOnChangeALLclients(
                                        clientsetuplist,
                                        item,
                                        i,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className="right-section-points">{item.displayName}</span>
                                </label>
                              );
                            })}
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                if (
                                  memberlistSelected !== "select"
                                ) {
                                  updateuserAccessPermissions();
                                } else {
                                  updateAccessPermissions();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div> : <></>}
                      {selectedPermissionTab === 'project' ?
                        <div className="col-md-9">
                          <div className="left-permission-menu d-flex justify-content-between">
                            <div className="menu-text ml-0">
                              Primary Controls
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList = [];
                              firstData.forEach((item, i) => {
                                if (i < 4) {
                                  item["permission"] = false;
                                  item["permission"] = !item["permission"];
                                }
                                updatedList.push(item);
                              });
                              setFirstData(updatedList);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 mt-0 mt-md-3 ps-0">
                            {firstData.map((item, i) => {
                              return (
                                <label className="d-flex">
                                  <input
                                    className="me-2"
                                    defaultChecked={item.permission}
                                    style={{ madginRight: "10px" }}
                                    type="checkbox"
                                    checked={item.permission}
                                    onChange={(e) => {
                                      handleOnChangeALL(
                                        firstData,
                                        item,
                                        i,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className="right-section-points"> {item.displayName}</span>
                                </label>
                              );
                            })}
                          </div>
                          <div className="left-permission-menu d-flex justify-content-between mb-3">
                            <div className="menu-text ml-0">
                              Modules Configuration
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList1 = [];
                              let updatedList2 = [];
                              let updatedList3 = [];
                              secondData.forEach((item, i) => {
                                item["permission"] = false;
                                item["permission"] = !item["permission"];
                                updatedList1.push(item);
                              });
                              setsecondcolumnchecked(updatedList1.every((x) => x.permission));
                              setSecondData(updatedList1);
                              thirdData.forEach((item, i) => {
                                item["permission"] = false;
                                item["permission"] = !item["permission"];
                                updatedList2.push(item);
                              });
                              setThirdData(updatedList2);
                              forthData.forEach((item, i) => {
                                item["permission"] = false;
                                item["permission"] = !item["permission"];
                                updatedList3.push(item);
                              });
                              setForthData(updatedList3);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 ps-0">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={secondcolumnchecked}
                                  onChange={(e) => {
                                    handleOnChange(
                                      secondData,
                                      {
                                        action: "allSecondColSelected",
                                        displayName: "All Modules",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span className="right-section-points"> Complete Setup</span>
                              </label>
                              {secondData.map((item, index) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChange(
                                          secondData,
                                          item,
                                          index,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span className="right-section-points">{item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                            <div className="col-md-4">
                              {thirdData.map((item, index) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChangeThird(
                                          thirdData,
                                          item,
                                          index,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span className="right-section-points">{item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                            <div className="col-md-4">
                              {forthData.map((item, i) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      onChange={(e) => {
                                        let updatedList = [];
                                        forthData.forEach((p, n) => {
                                          if (n === i) {
                                            p["permission"] = !p["permission"];
                                          }
                                          updatedList.push(p);
                                        });
                                        setForthData(updatedList);
                                      }}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                    />
                                    <span className="right-section-points"> {item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                if (
                                  memberlistSelected !== "select"
                                ) {
                                  updateuserAccessPermissions();
                                } else {
                                  updateAccessPermissions();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div> : <></>}
                      {selectedPermissionTab === 'reports' ?
                        <div className="col-md-9">
                          <div className="left-permission-menu d-flex justify-content-between">
                            <div className="menu-text ml-0">
                              Modules Reporting Screen
                            </div>
                            <div className="select-all ml-0" onClick={() => {
                              let updatedList = [];
                              reportsFirstClmData.forEach((item, i) => {
                                item["permission"] = false;
                                item["permission"] = !item["permission"];
                                updatedList.push(item);
                              });

                              setReportsFirstClmData(updatedList);
                            }
                            }>
                              Select All
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3 mt-0 mt-md-3 ps-0">
                            {reportsFirstClmData.map((item, index) => {
                              return (
                                <label className="d-flex">
                                  <input
                                    className="me-2"
                                    defaultChecked={item.permission}
                                    style={{ madginRight: "10px" }}
                                    type="checkbox"
                                    checked={item.permission}
                                    onChange={(e) => {
                                      handleOnChangeReportFirst(
                                        reportsFirstClmData,
                                        item,
                                        index,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <span className="right-section-points">{item.displayName}</span>
                                </label>
                              );
                            })}
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                if (
                                  memberlistSelected !== "select"
                                ) {
                                  updateuserAccessPermissions();
                                } else {
                                  updateAccessPermissions();
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div> : <></>}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div> NO ACCESS PERMISSIONS </div>
      )}
    </div>
  );
};

export default AccessPermissions;
