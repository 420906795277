/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import Input from "@semcore/ui/input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect } from "react";
import ButtonDropDown from "../Dropdown/Button";

const LabeledInput = ({
  addStyles,
  id,
  placeholder,
  type,
  value,
  onChange,
  label,
  onBlur,
  rightIcon,
  onPressEnter,
  autoFocus,
  ref,
  disable,
  width,
  size,
  addInputStyles,
  leftAddonCustom,
  rightAddonCustom,
  readOnly,
  hMin,
  selectLabel,
  selectOptions,
}) => {
  return (
    <div css={[styles.main, addStyles && addStyles]}>
      {label && <div css={styles.label}>{label} <span css={styles.redText}>*</span>:</div>}
      {selectLabel &&
        <>
          <ButtonDropDown
            placement="bottom-start"
            customTrigger={selectLabel}
            options={selectOptions}
            customPopperStyles={{ minWidth: "13.75rem !important", maxWidth: "13.75rem !important" }} />
        </>
      }
      <Input css={[styles.input, addInputStyles && addInputStyles]} hMin={hMin} disabled={disable} onChange={onChange} onBlur={onBlur} w={width} size={size}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onPressEnter) {
            onPressEnter();
          }
        }}>
        {leftAddonCustom && <Input.Addon>
          {leftAddonCustom}
        </Input.Addon>}
        <Input.Value
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          id={id}
          name={id}
          ref={ref}
        />
        {(rightAddonCustom || rightIcon) && <Input.Addon interactive={true}>
          {rightAddonCustom ?
            rightAddonCustom :
            <FeatherIcon icon={rightIcon} css={styles.icon} />}
        </Input.Addon>}
      </Input>
    </div>
  );
};

export default LabeledInput;
