/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from "react";
import * as styles from "./styles";
import { Input, Skeleton, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { Colors } from "../../../styles/colors";
import { Sorter } from "../../../common/Sorter";
import { useDispatch, useSelector } from "react-redux";
import { shortenNumber } from "../../../utility/helpers";
import {
  setExportTableFilteredCategories,
  setExportTableFilteredTypes,
  setExportTableTypes,
} from "../rankingReportSlice";
import ErrorScreen from "../../shared/errorScreen";
import AssetsImg from "../../assets/images";
import { Status, ranksDefault } from "../../../utility/Constant";

const ExportTable = () => {
  const status = useSelector((state) => state.rankingReport.exportTable.status);
  const columnOptions = useSelector(
    (state) => state.rankingReport.exportTable.columnOptions
  );
  const competitors = useSelector(
    (state) => state.rankingReport.exportTable.competitors
  );
  const ownDomainName = useSelector(
    (state) => state.rankingReport.exportTable.own_domain_name
  );
  const data = useSelector((state) => state.rankingReport.exportTable.data);
  const selectedCategories = useSelector(
    (state) => state.rankingReport.exportTable.categories
  );
  const types = useSelector((state) => state.rankingReport.exportTable.types);
  const [searchText, setSearchText] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const dispatch = useDispatch();

  const searchInputRef = useRef();

  const getColumns = () => {
    return columnOptions.options
      ?.filter((option, i) => columnOptions.active.includes(i))
      ?.filter((option) => option.fixed)
      ?.map((data, index) => {
        if (data.type == "filter") {
          return {
            title: data.label,
            dataIndex: data.label,
            key: data.label,
            fixed: "left",
            filterIcon: (filtered) => (
              <FeatherIcon
                icon={"filter"}
                css={styles.searchIcon(filtered)}
                fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
              />
            ),
            filters: types.map((type) => {
              return { text: type, value: type };
            }),
            onFilter: (value, record) => record[data.label] === value,
            render: (value, record) => {
              return status === Status.LOADING ? (
                <div css={[styles.cell, styles.categories]}>
                  <Skeleton.Button
                    active={true}
                    size={"small"}
                    shape={"round"}
                    block={true}
                  />
                </div>
              ) : (
                <div css={[styles.cell, styles.categories]}>{value}</div>
              );
            },
          };
        } else if (data.type == "sort") {
          return {
            title: <div css={styles.longTitle}>{data.label}</div> ,
            dataIndex: data.label,
            hidden: false,
            key: data.label,
            fixed: "left",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a[data.label], b[data.label]),
              multiple: 3,
            },
            render: (value, record) => {
              return status === Status.LOADING ? (
                <div css={[styles.cell, styles.categories]}>
                  <Skeleton.Button
                    active={true}
                    size={"small"}
                    shape={"round"}
                    block={true}
                  />
                </div>
              ) : (
                <div css={[styles.cell, styles.categories]}>
                  {shortenNumber(value)}
                </div>
              );
            },
          };
        } else {
          return {
            title: data.label,
            dataIndex: data.label,
            hidden: false,
            key: data.label,
            fixed: "left",
            render: (value, record) => {
              return status === Status.LOADING ? (
                <div css={[styles.cell, styles.categories]}>
                  <Skeleton.Button
                    active={true}
                    size={"small"}
                    shape={"round"}
                    block={true}
                  />
                </div>
              ) : (
                <div css={[styles.cell, styles.categories]}>{value}</div>
              );
            },
          };
        }
      });
  };

  const getCompetitorColumns = () => {
    const competitorColumns = columnOptions?.options
      ?.filter((option, i) => columnOptions.active.includes(i))
      ?.filter((option) => !option.fixed);

    if (competitorColumns?.length < 1) return [];
    else
      return competitors.options
        ?.filter((option, i) => competitors.active.includes(i))
        ?.map((data, index) => {
          return {
            title: (
              <div css={styles.domainTitle}>{data.label.toLowerCase()}</div>
            ),
            children: competitorColumns?.map((value, i) => {
              if (value?.type == "sort") {
                return {
                  title: <div css={styles.longTitle}>Competitor Rank</div>,
                  dataIndex: `${data.label}rank`,
                  key: `${data.label}rank`,
                  sorter: {
                    compare: (a, b) =>
                      Sorter.DEFAULT(
                        a[`${data.label}rank`],
                        b[`${data.label}rank`]
                      ),
                    multiple: 3,
                  },
                  render: (value, record) => {
                    return status === Status.LOADING ? (
                      <div css={[styles.cell, styles.rank]}>
                        <Skeleton.Button
                          active={true}
                          size={"small"}
                          shape={"round"}
                          block={true}
                        />
                      </div>
                    ) : (
                      <div css={[styles.cell, styles.rank]}>{value === ranksDefault.NOT_RANKED ? "Not Ranking":value === ranksDefault.NOT_TRACKED? "Not Tracked" : value}</div>
                    );
                  },
                };
              } else {
                return {
                  title: "Competitor URL",
                  dataIndex: `${data.label}url`,
                  key: `${data.label}rank`,
                  render: (value, record) => {
                    return status === Status.LOADING ? (
                      <div css={[styles.cell, styles.urlStyle]}>
                        <Skeleton.Button
                          active={true}
                          size={"small"}
                          shape={"round"}
                          block={true}
                        />
                      </div>
                    ) : (
                      <div css={[styles.cell, styles.urlStyle]}>{value === "NA" ? "-" : value}</div>
                    );
                  },
                };
              }
            }),
          };
        });
  };

  // Export Table Column data
  const columns = [
    {
      title: "",
      fixed: "left",
      children: [
        {
          title: () => <div>Keywords</div>,
          dataIndex: "keywords",
          key: "keywords",
          fixed: "left",
          filterDropdown: ({
            selectedKeys,
            setSelectedKeys,
            confirm,
            handleSave,
          }) => (
            <>
              <Input
                ref={searchInputRef}
                placeholder={"Search"}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setSelectedKeys(!!e.target.value ? [e.target.value] : []);
                  confirm(false);
                }}
                onPressEnter={() => {
                  confirm();
                }}
                css={styles.searchInput}
                suffix={<FeatherIcon icon={"search"} css={styles.searchIcon} />}
              />
            </>
          ),
          filterIcon: (filtered) => (
            <FeatherIcon
              icon="search"
              css={styles.searchIcon(filtered)}
              onClick={() => {
                setIsSearchOpen(!isSearchOpen);
              }}
            />
          ),
          onFilter: (value, record) =>
            record.keywords
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => searchInputRef.current?.select(), 100);
            }
          },
          render: (value, record) => {
            return status === Status.LOADING ? (
              <div css={[styles.cell, styles.keyword]}>
                <Skeleton.Button
                  active={true}
                  size={"small"}
                  shape={"round"}
                  block={true}
                />
              </div>
            ) : (
              <div css={[styles.cell, styles.keyword]}>{value}</div>
            );
          },
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          fixed: "left",
          filterIcon: (filtered) => (
            <FeatherIcon
              icon={"filter"}
              css={styles.searchIcon(filtered)}
              fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
            />
          ),
          filters: selectedCategories.map((category) => {
            return { text: category, value: category };
          }),
          onFilter: (value, record) => record.category === value,
          render: (value, record) => {
            return status === Status.LOADING ? (
              <div css={[styles.cell, styles.categories]}>
                <Skeleton.Button
                  active={true}
                  size={"small"}
                  shape={"round"}
                  block={true}
                />
              </div>
            ) : (
              <div css={[styles.cell, styles.categories]}>{value}</div>
            );
          },
        },
        ...getColumns(),
      ],
    },
    {
      title: !!ownDomainName ? (
        <div css={styles.domainTitle}>{ownDomainName}</div>
      ) : (
        <div css={styles.domainTitle}>My Domain</div>
      ),
      fixed: "left",
      children: [
        {
          title: "My Rank",
          fixed: "left",
          dataIndex: "myRank",
          key: "myRank",
          sorter: {
            compare: (a, b) => Sorter.DEFAULT(a.myRank, b.myRank),
            multiple: 3,
          },
          render: (value, record) => {
            return status === Status.LOADING ? (
              <div css={[styles.cell, styles.rank]}>
                <Skeleton.Button
                  active={true}
                  size={"small"}
                  shape={"round"}
                  block={true}
                />
              </div>
            ) :(
              <div css={[styles.cell, styles.rank]}>{ value === ranksDefault.NOT_RANKED ? "Not Ranking":value === ranksDefault.NOT_TRACKED?"Not Tracked" : value}</div>
            );
          },
        },
        {
          title: "My URL",
          fixed: "left",
          dataIndex: "myUrl",
          key: "myUrl",
          render: (value, record) => {
            return status === Status.LOADING ? (
              <div css={[styles.cell, styles.urlStyle]}>
                <Skeleton.Button
                active={true}
                size={"small"}
                shape={"round"}
                block={true}
              />
              </div>
              
            ) : (
              <div css={[styles.cell, styles.urlStyle]}>{value === "NA" ? "-" : value}</div>
            );
          },
        },
      ],
    },
    ...getCompetitorColumns(),
  ];

  return (
    <>
      <div css={styles.main}>
        <Table
          id="export-keywords-table"
          // className="table table-hover mt-3 "
          dataSource={data}
          columns={columns}
          scroll={{
            x: "max-content",
          }}
          pagination={{
            size: "small",
            position: ["bottomRight"],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]} to ${range[1]} of ${total} Keywords`,
          }}
          getPopupContainer={() =>
            document.getElementById("export-keywords-table")
          }
          onChange={(a, filters) => {
            dispatch(setExportTableFilteredCategories(filters.category));
            dispatch(setExportTableFilteredTypes(filters.Type));
          }}
          locale={{
            emptyText: (
              <ErrorScreen
                title={"No Data found"}
                icon={AssetsImg.ic_empty_table_icon}
                addStyles={styles.errorScreen}
              />
            ),
          }}
          bordered
          loading={status === Status.LOADING}
        />
      </div>
    </>
  );
};

export default ExportTable;
