import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  addAuditInstruction,
  getAuditInstruction,
  GET_FrontEnd_URL,
  GET_Server_URL,
} from "../../utility/Constant";
import JoditEditor from "jodit-pro-react";

const AuditInstruction = ({ update }) => {
  const [instruction1, setInstruction] = useState("");

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
    enter: "div",
    pasteHTMLActionList: [{ text: "insert_only_text", value: "insert_only_text" }],
    pasteFromWordActionList: [{ text: "insert_only_text", value: "insert_only_text" }]
  };

  useEffect(() => {
    getAuditInstructionData();
  }, []);

  function getAuditInstructionData() {
    APIManager.getAPI(getAuditInstruction).then((response) => {
      if (response && response.status === 200) {
        setInstruction(response?.data?.instruction);
      }
    });
  }

  function appAuditInstruction() {
    APIManager.postAPI(
      addAuditInstruction,
      {
        instruction: instruction1.trim(),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        clearData();
      }
    });
  }

  function clearData() {
    setInstruction("");
    update("auditChecklist");
  }

  return (
    <>
      <div className="custom-column-70 auditinstruction_main">
        <div class="portlet roles-setting-top">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => {
              update("auditChecklist");
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Audit Introduction
            </h3>
          </div>
          <div className="portlet-body">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Introduction</label>
                      <JoditEditor
                        value={instruction1 || ""}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {
                          setInstruction(text);
                        }}
                      ></JoditEditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="portlet-footer portlet-footer-bordered mb-4">
            <div className="col-md-12">
              <button
                class="btn btn-primary me-3"
                onClick={() => {
                  appAuditInstruction();
                }}
              >
                Save
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  clearData();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditInstruction;
