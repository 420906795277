/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import * as styles from "./styles";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import Title from "../shared/title";
import Campaigns from "./campaigns";
import CreateEditCampaign from "./createEditCampaign";
import { useRef } from "react";
import { RankingConfig } from "../../utility/Constant";
const RankingV3 = ({ navigateToConfig }) => {
  const [header, setHeader] = useState("Ranking Configuration");
  const [activeComponent, setActiveComponent] = useState(RankingConfig.CAMPAIGNS);
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (activeComponent === RankingConfig.CAMPAIGNS) {
      setHeader("Ranking Configuration");
    } else {
      if (isEdit) {
        setHeader("Update Ranking Campaign");
      } else {
        setHeader("Create New Ranking Campaign");
      }
    }
  }, [activeComponent]);

  const handleBack = () => {
    if (activeComponent === RankingConfig.CREATEEDITCAMPAIGN) {
      setActiveComponent(RankingConfig.CAMPAIGNS);
    } else {
      navigateToConfig();
    }
  };

  const getComponent = () => {
    if (activeComponent == RankingConfig.CAMPAIGNS) {
      return (
        <Campaigns
          createEditCampaign={(isEdit) => {
            setIsEdit(isEdit);
            setActiveComponent(RankingConfig.CREATEEDITCAMPAIGN);
          }}
        />
      );
    } else if (activeComponent == RankingConfig.CREATEEDITCAMPAIGN) {
      return (
        <CreateEditCampaign
          isEdit={isEdit}
          gotoCampaigns={() => setActiveComponent(RankingConfig.CAMPAIGNS)}
        />
      );
    }
  };

  return (
    <div css={styles.wrapper} ref={ref}>
      <div css={styles.header}>
        <div onClick={handleBack}>
          <FeatherIcon icon={"chevron-left"} css={styles.icon} />
        </div>
        <Title text={header} />
      </div>
      <>{getComponent()}</>
    </div>
  );
};

export default RankingV3;
