/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import * as styles from "./styles";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";

const LeftIconText = ({
  icon,
  activeIcon,
  inactiveIcon,
  text,
  showArrow,
  isActive,
  onPress,
  link,
  isCollapsed,
  isSubListExpanded,
  openInNewTab,
  isCustomIcon = false,
  customIcon = false,
  isPngIcon = false
}) => {

  const [triggerOpacity, setTriggerOpacity] = useState(false);

  useEffect(()=>{
    setTriggerOpacity(true);
  })
  return (
    <Link
      to={link}
      css={styles.mainContainer(isActive,triggerOpacity)}
      onClick={onPress}
      target={openInNewTab ? "_blank" : ""}
    >
      <div css={styles.leftContainer}>
        {isCustomIcon ? (
          <div css={styles.customIcon}>{icon}</div>
        ) : isPngIcon ? (
          <img
            key={isActive}
            css={styles.pngIcon}
            src={isActive ? activeIcon : inactiveIcon}
          />
        ) : (
          <FeatherIcon icon={icon} css={styles.icon} />
        )}
        {!isCollapsed && (
          <div css={styles.text(isActive, isCollapsed)}>{text}</div>
        )}
      </div>
      {((showArrow || openInNewTab) && !isCollapsed) && (
        <FeatherIcon
          icon={
            openInNewTab
              ? "external-link"
              : isSubListExpanded && isActive
              ? "chevron-up"
              : "chevron-down"
          }
          css={styles.icon}
        />
      )}
    </Link>
  );
};

export default LeftIconText;
