import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import { deleteSowActivity, getActivityListApi } from "../../utility/Constant";
import {
    exportToEXCEL,
    getCheckPermission,
  } from "../../utility/Globals";
import AddUpdateSOWActivitiy from "./AddUpdateSOWActivity";
import { Link, useNavigate } from "react-router-dom";

const SOWActivities = ({ update }) => {
  const navigate = useNavigate();
    const [settings, setSetting] = useState("activityList");
    const [activityList, setActivityList] = useState([])
    const [activityListFilter, setActivityListFilter] = useState([])
    const [activityColumn, setActivityListColumn] = useState([])
    const [exportList,setExportList] = useState([])
    const [selectedExportList, setSelectedExportList] = useState([]);

    useEffect(() => {
        let column = [{
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Activity",
            dataIndex: "activity",
            key: "activity",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
        ];
        setActivityListColumn(column);
        getActivityList()
    }, []);

    function getComponent() {
        if (settings === "addUpdateSOWActivity") {
            return (<AddUpdateSOWActivitiy update={onClickFunction} />);
        }
    }

    let onClickFunction = (arg) => {
        setSetting(arg);
        getActivityList()
    };

    function getActivityList() {
        let list = [];
        let exportList = [];
        APIManager.getAPI(
            getActivityListApi
        ).then((response) => {
            if (response && response.status === 200) {
                let i = 0;
                response.data.forEach((item) => {
                    list.push({
                        key: (i += 1),
                        id: item.id,
                        category: item.categories,
                        activity: item.activities,
                        action: (
                            <div className="d-flex text-center">
                                 {getCheckPermission("edit","organization_setup") ?
                                <Link
                                    state={{ item: item, isEdit: true }}
                                >                                    
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit edit-icon"
                                        onClick={() => {
                                            setSetting("addUpdateSOWActivity");
                                            navigate("",{
                                              state: { item: item, isEdit: true },
                                            });
                                        }}
                                    >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                    </svg>
                                    
                                </Link>
                                  : <></>}
                                  {getCheckPermission("delete", "organization_setup") ?
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2 trash-icon ms-3 mt-1"
                                    onClick={() => {
                                        deleteActivity(item.id);
                                    }}
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                                  : <></>}
                            </div>
                        ),
                    })
                    exportList.push({
                        category: item.categories,
                        activity: item.activities,
                    })
                })
            }
            setActivityList(list)
            setExportList(exportList)
            setActivityListFilter(list)
        })
    }

    async function deleteActivity(id) {
        try {
          const response = await APIManager.deleteAPI(deleteSowActivity + id);
          if (response === 200) {
            getActivityList()
          }
        } catch (e) {}
      }
      const activityrowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          const list = [];
          selectedRows.forEach((item, index) => {
            let obj = {
                Category: item.category,
                Activity: item.activity,
            };
            list.push(obj);
          });
          setSelectedExportList(list);
        },
      };
      
    return (
        <>
            {settings === "activityList" ? (
                <div className="portlet slideInUp setting-top city_main">
                    <div class="portlet-header portlet-header-bordered">
                        <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                        <h3 className="portlet-title main-title">
                            {" "}
                            {localStorage.getItem("organizationName")} - SOW Activities
                        </h3>
                    </div>
                    <div className="portlet-body">
                        <div class="row">
                            <div class="col-sm-6 col-md-8 d-flex justify-content-start">
                                <div class="input-group-icon input-group-lg widget15-compact">
                                    <div class="input-group-prepend">
                                        <i class="fa fa-search text-primary"></i>
                                    </div>
                                    <div className="search-box">
                                        <span>
                                            <Input
                                                allowClear
                                                style={{ height: 36 + "px" }}
                                                type="text"
                                                class="form-control"
                                                placeholder="Type to search..."
                                                onChange={(nameSearch) => {
                                                    setActivityList(
                                                        activityListFilter.filter((person) =>                                                        
                                                          person.activity != null ? person.activity.toLocaleLowerCase().includes(
                                                            nameSearch.target.value.toLocaleLowerCase()
                                                          ) : ""
                                                        )
                                                      );
                                                }}
                                                id="input-s"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 mt-3 mt-sm-0">
                                <div class="data-export-client me-3">
                                    <span class="count-drop"></span>
                                    {getCheckPermission( "add", "organization_setup") ?
                                    <Link
                                        class="btn btn-primary add"
                                        state={{ isEdit: false }}
                                        onClick={() => {
                                            setSetting("addUpdateSOWActivity");
                                        }}
                                    >
                                        Add
                                    </Link>
                                      : <></>}
                                    <span class="dropdown d-inline ">
                                        <button
                                            class="btn btn-primary dropdown-toggle ms-3 export-btn"
                                            type="button"
                                            onClick={() => {
                                                exportToEXCEL(
                                                  selectedExportList?.length > 0
                                                    ? selectedExportList
                                                    : exportList,
                                                  "Activity List"
                                                );
                                            }}
                                        >
                                            Export
                                        </button>

                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3">
                            <Table
                                className="table table-bordered table-hover mt-3 city-table"
                                id="sample-module-expand"
                                dataSource={activityList}
                                columns={activityColumn}
                                rowSelection={{
                                  type: "checkbox",
                                  ...activityrowSelection,
                                }}
                                pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                getComponent()
            )}
        </>
    );
}

export default SOWActivities;