import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "./index";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import APIManager from "../services/APIManager";
import {
  calanderDate,
  getCheckPermission,
  // getCheckPermission,
  mmDDyyyyFormateDateSet,
} from "../utility/Globals";
import { getOrganic } from "../utility/Constant";
import { exportInToCSV, exportToEXCEL } from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import NotFound from "../NotFound";

function ResearchModuleExpandOrganicResearch() {
  const [QueryORTabOptions, setQueryORTabOptions] = useState([]);
  const [ORtabtable, setORtabtable] = useState([]);
  const [ORTableCol, setORTableCol] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [keywords, setkeywords] = useState([]);
  const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
  const [keyexportList, setKeyExportList] = useState([]);
  const [selectedOrgExportList, setSelectedOrgExportList] = useState([]);
  const [orgexportList, setOrgExportList] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
 const [notFound, setNotFound] = useState(true);




  useEffect(() => {
    setNotFound(getCheckPermission("organic_research", "reports"));
    localStorage.setItem("isCompareVar", false);

    var data = [
      { label: "Query", value: "Query" },
      { label: "Page", value: "Page" },
      { label: "Country", value: "Country" },
      { label: "Device", value: "Device" },
      { label: "Search Appearence", value: "Search Appearence" },
      { label: "Date", value: "Date" },
    ];
    data = [];
    setteamList(data);
    const col = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
      {
        title: "Traffic",
        dataIndex: "traffic",
        key: "traffic",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traffic, b.traffic),
          multiple: 3,
        },
      },
      {
        title: "Traffic %",
        dataIndex: "traff",
        key: "traff",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traff, b.traff),
          multiple: 3,
        },
      },
      {
        title: "Keywords",
        dataIndex: "keywords",
        key: "keywords",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keywords, b.keywords),
          multiple: 3,
        },
      },
      {
        title: "Ads Keywords",
        dataIndex: "ads",
        key: "ads",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ads, b.ads),
          multiple: 3,
        },
      },
    ];
    setteamcol(col);

    setQueryORTabOptions(data);
    var columns = [
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "SF",
        dataIndex: "sf",
        key: "sf",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.sf, b.sf),
          multiple: 3,
        },
      },
      {
        title: "Pos.",
        dataIndex: "pos",
        key: "pos",
        sorter: {
          compare: (a, b) => (a.pos, b.pos),
          multiple: 3,
        },
      },
      {
        title: "Diff.",
        dataIndex: "diff",
        key: "diff",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.diff, b.diff),
          multiple: 3,
        },
      },
      {
        title: "Traffic%",
        dataIndex: "traffic",
        key: "traffic",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traffic, b.traffic),
          multiple: 3,
        },
      },
      {
        title: "Volume",
        dataIndex: "volume",
        key: "volume",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "KD%",
        dataIndex: "kd",
        key: "kd",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.kd, b.kd),
          multiple: 3,
        },
      },
      {
        title: "CPC (USD)",
        dataIndex: "cpc",
        key: "cpc",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.cpc, b.cpc),
          multiple: 3,
        },
      },
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },

      {
        title: "Upd.",
        dataIndex: "upd",
        key: "upd",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.upd, b.upd),
          multiple: 3,
        },
      },
    ];
    setORTableCol(columns);

    setORtabtable(data);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          keyword: item.keyword,
          sf: item.sf,
          pos: item.pos,
          diff: item.diff,
          traffic: item.traffic,
          volume: item.volume,
          kd: item.kd,
          cpc: item.cpc,
          url: item.url,
          upd: item.upd,
        };
        list.push(obj);
      });
      setSelectedKeyExportList(list);
    },
  };

  const orgrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      let i = 0;
      selectedRows.map((item) => {
        let obj = {
          url: item.url,
          traffic: item.traffic,
          traff: item.traff,
          keywords: item.keywords,
          ads: item.ads,
        };
        list.push(obj);
      });
      setSelectedOrgExportList(list);
    },
  };

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }

      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);

  useEffect(() => {
    let getdata = {
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      projectId: localStorage.getItem("projectGId"),
    };
    createorganicResearchSearch(getdata);
  }, []);

  function createorganicResearchSearch(rowdata) {
    APIManager.postAPI(getOrganic, rowdata, null, true).then((response) => {
      if (response && response.status == 200) {
        const list0 = [];
        response.data.organicResearchSummaries.map((item) => {
          let obj = {
            keywords: item.fieldName,
            fieldValue: item.fieldValue,
            percentage: item.percentage,
          };
          list0.push(obj);
        });
        setkeywords(list0);

        const list1 = [];
        const expList = [];

        response.data.organicResearchModules.map((item) => {
          let obj = {
            key: item.id,
            keyword: item.keyword,
            sf: item.sf,
            pos: item.pos,
            diff: item.diff,
            traffic: item.traffic,
            volume: item.volume,
            kd: item.kd,
            cpc: item.cpc,
            url: item.url,
            upd: item.updatedAt,
          };
          list1.push(obj);
        });

        response.data.organicResearchModules.map((item) => {
          let obj = {
            keyword: item.keyword,
            sf: item.sf,
            pos: item.pos,
            diff: item.diff,
            traffic: item.traffic,
            volume: item.volume,
            kd: item.kd,
            cpc: item.cpc,
            url: item.url,
            upd: item.updatedAt,
          };
          expList.push(obj);
        });
        setORtabtable(list1);
        setKeyExportList(expList);
        const list2 = [];
        const explist = [];
        response.data.organicResearchPages.map((item) => {
          let obj2 = {
            key: item.id,
            url: item.url,
            traffic: item.traffic,
            traff: item.trafficPercentage,
            keywords: item.keywords,
            ads: item.adsKeywords,
          };
          list2.push(obj2);
        });
        response.data.organicResearchPages.map((item) => {
          let obj2 = {
            url: item.url,
            traffic: item.traffic,
            traff: item.trafficPercentage,
            keywords: item.keywords,
            ads: item.adsKeywords,
          };
          explist.push(obj2);
        });
        setteamList(list2);
        setOrgExportList(explist);
      }
    });
  }

  const [Organicesearch, setOrganicesearch] = useState(false);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (<>
        <div className="row">
          {/* <div ref={ref} class="calendar-main">
          <div className="add-new-btnw">
            <button
              className="btn btn-primary"
              style={{ width: "250px" }}
              onClick={() => setOpen(!open)}
            >
              {calanderDate(head1, head2)}
            </button>
          </div>

          {open && (
            <div id="example-collapse-text-calendar">
              <Card body className="daterange-picker-card  mt-2">
                <div className="d-flex justify-content-between">
                  <div className="calendar-col">
                    <Calendar
                      className="custom-calendar"
                      value={values}
                      onChange={(e) => {
                        setValues(e);
                        setHead1(e[0].format());
                        setHead2(e[1].format());
                      }}
                      range
                      numberOfMonths={1}
                      showOtherDays
                    />
                  </div>
                  <div className="ms-3">
                    <button
                      onClick={() => {
                        setheadvalues();
                        setOpen(!open);
                        let getdata = {
                          StartDate: mmDDyyyyFormateDateSet(head1),
                          EndDate: mmDDyyyyFormateDateSet(head2),
                          projectId: localStorage.getItem("projectGId"),
                        };
                        createorganicResearchSearch(getdata);
                        setRefreshTicketData(true);
                        setTimeout(() => {
                          setRefreshTicketData(false);
                        }, 1000);
                      }}
                      className="btn-outline btn btn-primary"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div> */}
          <Tabs>
            <div className="portlet ">
              <div className="portlet-header portlet-header-bordered my-3">
                <div className="col-md-8 col-sm-12">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        Organicesearch
                          ? "nav-item nav-link"
                          : "nav-item nav-link  active"
                      }
                      onClick={() => {
                        setOrganicesearch(false);
                      }}
                    >
                      Organic Research
                    </Tab>
                    {/* <Tab
                    className={
                      Organicesearch
                        ? "nav-item nav-link  active"
                        : "nav-item nav-link"
                    }
                    onClick={() => {
                      setOrganicesearch(true);
                    }}
                  >
                    Tickets
                  </Tab> */}
                  </TabList>
                </div>
                <div
                  ref={ref}
                  class="col-md-4 col-sm-12 calendar-main ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {calanderDate(head1, head2)}
                    </button>
                  </div>

                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card  mt-2">
                        <div className="d-flex justify-content-between">
                          <div className="calendar-col">
                            {/* <Calendar
                            className="custom-calendar"
                            value={values}
                            onChange={(e) => {
                              setValues(e);
                              setHead1(e[0].format());
                              setHead2(e[1].format());
                            }}
                            range
                            numberOfMonths={1}
                            showOtherDays
                          /> */}

                            <DateRangePicker
                              onChange={(item) => {
                                let isCompareVarNew = localStorage.getItem("isCompareVar");

                                // if(calendarType == "daily") {
                                // if (item?.selection1) {
                                //   let selection23 = {
                                //     selection2: {
                                //       startDate: item?.selection1?.startDate,
                                //       endDate: item?.selection1?.endDate,
                                //       key: "selection2",
                                //     },
                                //   };
                                //   setState({
                                //     ...state,
                                //     ...item,
                                //     ...selection23,
                                //   });
                                // } else if (item?.selection2 && isCompare) {
                                //   setState({ ...state, ...item });
                                // }

                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                    setHead1(new DateObject(item?.selection1?.startDate).format());
                                    setHead2(new DateObject(item?.selection1?.endDate).format());

                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                    setHead1(new DateObject(item?.selection2?.startDate).format());
                                    setHead2(new DateObject(item?.selection2?.endDate).format());
                                  }

                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }

                                // if (isCompareVarNew == "true") {
                                //   setState({ ...state, ...item });
                                // }
                                // } else if(calendarType == "weekly") {
                                //   let isCompareVarNew = localStorage.getItem("isCompareVar");
                                //   let selection = {};

                                //   if (isCompareVarNew == "false") {
                                //     let selection23 = {
                                //       selection1: {
                                //         startDate: item?.selection1
                                //           ? item?.selection1?.startDate
                                //           : item?.selection2?.startDate,
                                //         endDate: item?.selection1
                                //           ? item?.selection1?.endDate
                                //           : item?.selection2?.endDate,
                                //         key: "selection1",
                                //       },
                                //     };
                                //     selection = dateRagngeSelectWeek(
                                //       selection23,
                                //       state
                                //     );
                                //   }

                                //   if (isCompareVarNew == "true") {
                                //     selection = dateRagngeSelectWeek(item, state);
                                //   }

                                //   setState({
                                //     ...state,
                                //     ...item,
                                //     ...selection,
                                //   });
                                // }
                              }}

                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={[state?.selection1, state?.selection2]}
                              direction="horizontal"
                              ariaLabels={{
                                dateInput: {
                                  selection1: {
                                    startDate: "start date input of selction 1",
                                    endDate: "end date input of selction 1",
                                  },
                                  selection2: {
                                    startDate: "start date input of selction 2",
                                    endDate: "end date input of selction 2",
                                  },
                                },

                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                              }}
                              maxDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate() - 1
                                )
                              }
                            />
                          </div>
                          <div className="ms-3">
                            <button
                              onClick={() => {
                                setheadvalues();
                                setOpen(!open);
                                let getdata = {
                                  StartDate: mmDDyyyyFormateDateSet(head1),
                                  EndDate: mmDDyyyyFormateDateSet(head2),
                                  projectId: localStorage.getItem("projectGId"),
                                };
                                createorganicResearchSearch(getdata);
                                setRefreshTicketData(true);
                                setTimeout(() => {
                                  setRefreshTicketData(false);
                                }, 1000);
                              }}
                              className="btn-outline btn btn-primary"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <TabPanel>
              <div class="container px-0">
                <div className="portlet  portlet-header-bordered py-4 px-4">
                  <div className="portlet-body">
                    <div className="row">
                      <div className="col ">
                        <div className="bg-light-blue p-3 text-black font-weight-bolder text-center or-box">
                          <h2>Keywords</h2>
                          <span>
                            <strong>{keywords[0]?.fieldValue}</strong>
                          </span>
                          <p>{keywords[0]?.percentage}</p>
                        </div>
                      </div>
                      <div className="col ">
                        <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                          <h2>Traffic</h2>
                          <span>
                            <strong>{keywords[1]?.fieldValue}</strong>
                          </span>
                          <p>{keywords[1]?.percentage}</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                          <h2>Traffic Cost</h2>
                          <span>
                            <strong>{keywords[2]?.fieldValue}</strong>
                          </span>
                          <p>{keywords[2]?.percentage}</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                          <h2>Branded traffic</h2>
                          <span>
                            <strong>{keywords[3]?.fieldValue}</strong>
                          </span>
                          <p>{keywords[3]?.percentage}</p>
                        </div>
                      </div>
                      <div className="col ">
                        <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                          <h2>Non-Branded traffic</h2>
                          <span>
                            <strong>{keywords[4]?.fieldValue}</strong>
                          </span>
                          <p>{keywords[4]?.percentage}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Keywords</h3>
                </div>
                <div className="portlet-body">
                  <div className="justify-content-between">
                    <div class="col-md-12 add-new-btnw">
                      <span class="dropdown">
                        {/* {getCheckPermission("export", "modules") ? ( */}
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedKeyExportList?.length > 0
                                ? selectedKeyExportList
                                : keyexportList,
                              "Keywords"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* ) : (
                        <></>
                      )} */}
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedKeyExportList?.length > 0
                                ? selectedKeyExportList
                                : keyexportList,
                              "Keywords"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedKeyExportList?.length > 0
                                ? selectedKeyExportList
                                : keyexportList,
                              "Keywords"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <Table
                    className="table table-bordered table-hover"
                    id="datatable-1"
                    columns={ORTableCol}
                    dataSource={[...ORtabtable]}
                    rowSelection={{ type: "checkbox", ...rowSelection }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>

              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Organic Pages</h3>
                </div>
                <div className="portlet-body">
                  <div className="justify-content-between">
                    <div class="add-new-btnw">
                      <span class="dropdown">
                        {/* {getCheckPermission("export", "modules") ? ( */}
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedOrgExportList?.length > 0
                                ? selectedOrgExportList
                                : orgexportList,
                              "Organic Pages"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* ) : (
                        <></>
                      )} */}
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedOrgExportList?.length > 0
                                ? selectedOrgExportList
                                : orgexportList,
                              "Organic Pages"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedOrgExportList?.length > 0
                                ? selectedOrgExportList
                                : orgexportList,
                              "Organic Pages"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <Table
                    className="table table-bordered table-hover"
                    id="datatable-1"
                    columns={teamcol}
                    dataSource={teamlist}
                    rowSelection={{ type: "checkbox", ...orgrowSelection }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel>
            <ModuleExpandTickets
              startDate={head1}
              endDate={head2}
              refreshTicketData={refreshTicketData}
            />
          </TabPanel> */}
          </Tabs>
        </div>
      </>)}
    </>
  );
}

export default ResearchModuleExpandOrganicResearch;
