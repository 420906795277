import { Input, Table } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react';
import APIManager from '../../../services/APIManager';
import {
    campaignactive,
    campaignviewactive,
    deletecampaign,
    deletecampaignid,
    getcampaignviewdetail
} from '../../../utility/Constant';
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { exportToEXCEL } from '../../../utility/Globals';

function ViewCampaign(props) {
    useEffect(() => {
        if (props?.SelectedTab == 'viewCampaign') {
            gettablecampaigndetails();
        }
    }, [props])

    const columns = [
        {
            title: 'Ranking Campaign Name',
            dataIndex: 'campaignName',
            key: 'campaignName',
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Keywords Tracked',
            dataIndex: 'keywordsTracked',
            key: 'keywordsTracked',
            width: '12%',
        },
        {
            title: 'Device',
            dataIndex: 'device',
            width: '30%',
            key: 'device',
        },
        {
            title: 'Weekly credits',
            dataIndex: 'credits',
            width: '30%',
            key: 'addrecreditsss',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '30%',
            key: 'status',
        },
        {
            title: 'Approved By',
            dataIndex: 'approvedBy',
            width: '30%',
            key: 'approvedBy',
        },
        {
            title: 'Show to Client',
            dataIndex: 'showtoclient',
            width: '30%',
            key: 'showtoclient',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '30%',
            key: 'action',
        }
    ];

    const handleChildToProp = (id, parentID, device) => {
        props.dataChildToParent(1, id, parentID, device);
    };

    const [data, setdata] = useState([]);
    const [dataForFilter, setdataForFilter] = useState([]);
    const [compExportList, selectedCompExportList] = useState([]);

    function campaignisactive(id) {
        APIManager.postAPI(campaignactive + id, {}, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    gettablecampaigndetails();
                }
            }
        );
    }

    function campaignviewisactive(id) {
        APIManager.getAPI(campaignviewactive + id, {}, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    gettablecampaigndetails();
                }
            }
        );
    }

    async function deleteCampaignItemview(id) {
        try {
            const response = await APIManager.deleteAPI(deletecampaign + id
            );
            if (response == 200) {
                gettablecampaigndetails();
            }
        } catch (e) { }
    }

    async function deleteCampaign(id) {
        try {
            const response = await APIManager.deleteAPI(deletecampaignid + id
            );
            if (response == 200) {
                gettablecampaigndetails();
            }
        } catch (e) { }
    }

    function gettablecampaigndetails() {
        APIManager.getAPI(
            getcampaignviewdetail + localStorage.getItem("projectGId")
        ).then((response) => {
            if (response && response.status == 200) {
                let exportList = [];
                let list = [];
                let childlist = [];
                response.data.map((item, index) => {
                    childlist = [];
                    let viewobj = {
                        campaignName: <a className='a-add-view' onClick={() =>
                            handleChildToProp("", item.id, item.device)}>Add New View</a>
                    }
                    item.viewList.map((viewlistitem) => {
                        let childobj = {
                            campaignName: viewlistitem.campaignViewName,
                            device: viewlistitem.device == 2 ? "Both" : (viewlistitem.device != 1 ? "Mobile" : "Desktop"),
                            keywordsTracked: viewlistitem.keywordsTracked + " / " + item.keywordsTracked,
                            status: item.status != 1 ? "Awaiting approval" : "Active",
                            approvedBy: viewlistitem.approvedBy,
                            frequency: "_",
                            credits: "_",
                            showtoclient: (
                                <>{viewlistitem.campaignViewName != "All" ? (<>
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            disabled={item.status != 1 ? true : false}
                                            onClick={() => {
                                                campaignviewisactive(viewlistitem.id);
                                            }}
                                        />
                                        <span class="slider round"></span>
                                    </label></>) : (<></>)} </>

                            ),
                            action: (
                                <>
                                    {viewlistitem.campaignViewName != "All" ?
                                        (

                                            <div className="px-0">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-edit edit-icon me-4"
                                                    onClick={() => {
                                                        handleChildToProp(viewlistitem.id, item.id);
                                                    }}>
                                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-trash-2 trash-icon"
                                                    onClick={() => {
                                                        deleteCampaignItemview(viewlistitem.id);
                                                    }}
                                                >
                                                    <polyline points="3 6 5 6 21 6"></polyline>
                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                </svg>
                                            </div>
                                        )
                                        :
                                        (<></>)
                                    }
                                </>
                            ),
                        }
                        childlist.push(childobj);
                    })
                    childlist.push(viewobj);
                    let obj = {
                        expanded: false,
                        key: item.id,
                        campaignName: item.campaignName,
                        credits: item.credits,
                        device: item.device == 2 ? "Both" : (item.device != 1 ? "Mobile" : "Desktop"),
                        frequency: item.frequency != 1 ? "Mobile" : "Desktop",
                        keywordsTracked: item.keywordsTracked,
                        status: item.status != 1 ? "Awaiting approval" : "Active",
                        approvedBy: item.approvedBy,
                        children: childlist,
                        showtoclient: (
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    checked={item.showToClient}
                                    disabled={item.status != 1 ? true : false}
                                    onClick={() => {
                                        campaignisactive(item.id, index);
                                    }}
                                />
                                <span class="slider round"></span>
                            </label>
                        ),
                        action: (
                            <div className="px-0">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit edit-icon me-4"
                                    onClick={() => {
                                        props.dataTabChild({ id: item.id, isUpdate: true });
                                    }}>
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2 trash-icon"
                                    onClick={() => {
                                        deleteCampaign(item.id);
                                    }}
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </div>),
                    }
                    list.push(obj);
                })
                setdata(list);
                setdataForFilter(list)

                list.map((item) => {
                    item.children.map((child, i) => {
                        if (i < item.children.length - 1) {
                            exportList.push({
                                campaignName: item.campaignName,
                                viewName: child.campaignName,
                                credits: item.credits,
                                device: item.device,
                                frequency: item.frequency,
                                keywordsTracked: item.keywordsTracked,
                                status: item.status != 1 ? "Awaiting approval" : "Active",
                            });
                        }
                    })
                });
                selectedCompExportList(exportList);
            }
        });
    }

    return (
        <>
            <section className='ranking-view-campaign px-3'>
                <div className="row mt-3">
                    <div className="col-md-9 col-sm-8 col-12">
                        <div className="justify-content-start ranikngv2_search_button">
                            <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                </div>
                                <span>
                                    <Input
                                        allowClear
                                        style={{ padding: 7 + "px" }}
                                        type="text"
                                        class="form-control"
                                        placeholder="Type to search"
                                        onChange={(nameSearch) => {
                                            setdata(
                                                dataForFilter.filter((person) =>
                                                    person.campaignName
                                                        .toLocaleLowerCase()
                                                        .includes(
                                                            nameSearch.target.value.toLocaleLowerCase()
                                                        )
                                                )
                                            );
                                        }}
                                        id="input-s"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-12 justify-content-start justify-content-sm-end d-flex align-items-center">
                        <span class="export">
                            <button
                                class="btn btn-outline dropdown-toggle export-btn d-flex align-items-center mt-3 mt-sm-0"
                                type="button"
                                onClick={() => {
                                    exportToEXCEL(
                                        compExportList,
                                        localStorage.getItem("projectGName") + " Campaign"
                                    );
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                <span class="ms-2">Export</span>
                            </button>
                        </span>
                    </div>
                    <div>
                    </div>
                    <div className="ranking-campaing-table main-table table-bordered border-0 mt-4 pt-2 rankingv2_table_block">
                        {data?.length ? <Table
                            columns={columns}
                            rowClassName={(record) => {

                                return record.expanded ? "active" : "";
                            }}
                            dataSource={data}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    if (record.children == undefined) {
                                        return <span></span>;
                                    } else {
                                        return expanded ?
                                            (
                                                <UpOutlined
                                                    onClick={(e) => {
                                                        setdata([]);
                                                        let list = data
                                                        list.map(x => {
                                                            if (x.key == record.key) {
                                                                x.expanded = !expanded;
                                                            }
                                                        });
                                                        setdata(list);
                                                        onExpand(record, e)
                                                    }}
                                                />
                                            )
                                            :
                                            (
                                                <DownOutlined
                                                    onClick={(e) => {
                                                        setdata([]);
                                                        let list = data
                                                        list.map(x => {
                                                            if (x.key == record.key) {
                                                                x.expanded = !expanded;
                                                            }
                                                        });
                                                        setdata(list);
                                                        onExpand(record, e)
                                                    }}
                                                />
                                            );
                                    }
                                },
                            }}

                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                            }}
                        /> : <></>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewCampaign
