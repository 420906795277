/** @jsxImportSource @emotion/react */
import { Link, useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import { Table, Space, Drawer } from "antd";
import { useEffect } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { dummytableData } from "./data";
import { exportToEXCEL, findUnique } from "../../../../utility/Globals";
import SubTicketCounter from "../../subTicketCounter";
import CustomModal from "../modal";
import CustomTimeButton from "../timeButton";
import { useTicketingStore } from "../../ticketingStore";
import CustomTicketingButton from "../../customButton2";
import CustomDateRangePicker from "../dateRangePicker";
import DeleteModal from "../deleteModal";
import AssetsImg from "../../../assets/images";
import ErrorScreen from "../../../shared/errorScreen";
import { deleteMainTicket, deleteSubTicket, getMembersByDepartment, getMyTickets, getMyTicketsAvailable, getTeamTickets, updateMainTicket, updateSubTicket } from "../../../../services/ticketing";
import DropdownInput from "../../customInput";
import DropDownInput from "../../../shared/Dropdown/Input";
import toast from "react-hot-toast";
import SpinContainer from "@semcore/ui/spin-container";
import moment from "moment";
import { Sorter } from "../../../../common/Sorter";
import Select, { InputSearch } from "@semcore/ui/select";
import Tooltip from '@semcore/ui/tooltip';

const TicketTable = ({ tableTotal, setTableTotal }) => {
    const location = useLocation()
    // STORE STATES--------------------
    const drawerOpen = useTicketingStore((state) => state.drawerOpen)
    const setDrawerOpen = useTicketingStore((state) => state.setDrawerOpen)
    const tableData = useTicketingStore((state) => state.table)
    const setTableData = useTicketingStore((state) => state.setTableData)

    const tableData1 = useTicketingStore((state) => state.table1)
    const setTableData1 = useTicketingStore((state) => state.setTableData1)

    const currentTab = useTicketingStore((state) => state.currentTab)


    const priorityList = useTicketingStore((state) => state.priorityList)
    const ticketTypes = useTicketingStore((state) => state.ticketTypes)

    const ticket = useTicketingStore((state) => state.ticket)
    const setTicket = useTicketingStore((state) => state.setTicket)

    // COMPONENT STATES--------------------
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        id: null,
        isSubTicket: false
    })

    const [isLoading, setIsLoading] = useState(false)

    const [tableColumns, setTableColumns] = useState([]);
    const [tableFilters, setTableFilters] = useState({
        typeList: [],
        clientList: [],
        projectList: [],
        subjectList: [],
        assignList: [],
        createdByList: [],
        statusList: [],
        eta: { start: "", end: "" },
        createdOn: { start: "", end: "" }
    })


    const [filterValues, setFilterValues] = useState({})
    const [sortedInfo, setSortedInfo] = useState({})

    const [allPriorities, setAllPriorities] = useState({})
    const [allAssignedTo, setAllAssignedTo] = useState({})

    const [priorityOptions, setPriorityOptions] = useState({
        options: [],
        active: 0
    })

    const [assignedToOptions, setAssignedToOptions] = useState({
        options: [],
        active: 0
    })

    const disabledStatus = ["Picked Up", "Completed", "In Progress", "Completed", "Awaiting Approval"]
    const errorStates = {
        0: "All the tickets assigned to you will be displayed here",
        1: "All the tickets created by you will be displayed here",
        2: "All the tickets open to all will be displayed here"
    }

    const getMemberList = (record) => {
        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId"),
            department_id: record?.departmentId ?? "",
            project_id: record?.projectId ?? "",
            client_id: record?.clientId ?? ""
        }
        getMembersByDepartment(body).then((response) => {
            if (response?.status == "success") {
                let memberData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.name, id: data?.id }
                })
                setAssignedToOptions({ options: memberData, active: 0 })
            }
        })
    }

    useEffect(() => {
        const data = [
            {
                title: "Client",
                dataIndex: "client",
                key: "client",
                filters: findUnique(tableFilters?.clientList, (d) => d.value),
                filterSearch: true,
                filteredValue: filterValues?.client || null,
                onFilter: (value, record) => {
                    return record.client === value;
                },
            },
            {
                title: "Projects",
                dataIndex: "projects",
                key: "projects",
                filters: findUnique(tableFilters?.projectList, (d) => d.value),
                filterSearch: true,
                filteredValue: filterValues?.projects || null,
                onFilter: (value, record) => {
                    return record.projects === value;
                },
            },
            {
                title: "Subject",
                dataIndex: "subject",
                key: "subject",
                // filters: findUnique(tableFilters?.subjectList, (d) => d.value),
                // filterSearch: true,
                filteredValue: filterValues?.subject || null,
                onFilter: (value, record) => {
                    if (value?.length > 0) {
                        return record.ticketType === value;
                    } else {
                        return record.subject
                    }
                },
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div>
                        <Select
                            onChange={(e) => {
                                if (e?.length > 0) {
                                    setSelectedKeys([e])
                                    confirm()
                                } else {
                                    clearFilters()
                                    confirm()
                                    close()
                                }
                            }}
                            value={selectedKeys[0] ?? tableFilters?.subjectList[0]?.value}
                        >
                            <Select.List hMax='240px'>
                                {tableFilters?.subjectList?.map((option, index) => (
                                    <Select.Option value={option.value} key={index}>
                                        {option.text}
                                    </Select.Option>
                                ))}
                            </Select.List>
                        </Select>
                    </div>
                ),
                render: (_, record) => (
                    <><div onClick={() => { }}>{subjectCell(record)}</div></>
                )
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                filters: findUnique(tableFilters?.typeList, (d) => d.value),
                // filterSearch: true,
                filteredValue: filterValues?.type || null,
                onFilter: (value, record) => {
                    return record.type === value;
                },
                render: (_, record) => (
                    <><div onClick={() => { }}>{typeCell(record)}</div></>
                )
            },
            {
                title: <span css={{ display: "flex", alignItems: "center" }}>
                    ETA
                    <span css={{ margin: "0 4px 3px 10px", cursor: "pointer" }}>
                    </span>
                </span>,
                filterIcon: (filtered) => (
                    <FeatherIcon icon="calendar" size={14} color={filtered ? "" : "#000000"} />
                ),
                dataIndex: "eta",
                key: "eta",
                filteredValue: filterValues?.eta || null,
                onFilter: (value, record) => {
                    // return (new Date(record?.eta)?.getTime() >= value?.start && new Date(record?.eta)?.getTime() <= value?.end);
                    return moment(record?.eta).isSameOrAfter(value?.start) && moment(record?.eta).isSameOrBefore(value?.end);
                },
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, visible }) => (
                    <div>
                        <CustomDateRangePicker
                            visible={visible}
                            handleApply={(startDate, endDate) => {
                                setSelectedKeys([{ start: moment(startDate).startOf("day"), end: moment(endDate).endOf("day") }])
                                confirm()
                            }}
                            handleReset={() => {
                                setSelectedKeys([])
                                clearFilters()
                                confirm()
                                close()
                            }}
                        />
                    </div>
                )
            },
            {
                title: "Priority",
                dataIndex: "priority",
                key: "priority",
                render: (_, record) => (
                    <><div onClick={() => { }}>{priorityCell(record, priorityOptions)}</div></>
                ),
                filters: findUnique(priorityOptions?.options, (d) => d.value),
                filterSearch: false,
                filteredValue: filterValues?.priority || null,
                onFilter: (value, record) => {
                    return record.priority === value;
                },
            },
            {
                title: "Assigned To",
                dataIndex: "assignedTo",
                key: "assignedTo",
                render: (_, record) => (
                    <>
                        <div>
                            {assignedToCell(record)}
                        </div>
                    </>
                ),
                filters: findUnique(tableFilters?.assignList, (d) => d.value),
                filterSearch: true,
                filteredValue: filterValues?.assignedTo || null,
                onFilter: (value, record) => {
                    return record.assignedTo === value;
                },
            },
            {
                title: "Time Spent",
                dataIndex: "timeSpent",
                key: "timeSpent",
                // sortOrder: sortedInfo.columnKey === 'timeSpent' ? sortedInfo.order : null,
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(convertTimeToMinutes(a.timeSpent), convertTimeToMinutes(b.timeSpent)),
                    multiple: 3,
                },
            },
            // {
            //     title: "Time Spent (hours)",
            //     dataIndex: "timeSpentHrs",
            //     key: "timeSpentHrs",
            //     // sortOrder: sortedInfo.columnKey === 'timeSpent' ? sortedInfo.order : null,
            //     sorter: {
            //         compare: (a, b) => Sorter.DEFAULT(convertTimeToMinutes(a.timeSpentHrs), convertTimeToMinutes(b.timeSpentHrs)),
            //         multiple: 3,
            //     },
            // },
            // {
            //     title: "Time Spent (minutes)",
            //     dataIndex: "timeSpentMin",
            //     key: "timeSpentMin",
            //     // sortOrder: sortedInfo.columnKey === 'timeSpent' ? sortedInfo.order : null,
            //     sorter: {
            //         compare: (a, b) => Sorter.DEFAULT(convertTimeToMinutes(a.timeSpentMin), convertTimeToMinutes(b.timeSpentMin)),
            //         multiple: 3,
            //     },
            // },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                filters: findUnique(tableFilters?.statusList, (d) => d.value),
                // filterSearch: true,
                filteredValue: filterValues?.status || null,
                onFilter: (value, record) => {
                    // if (value == "Overdue") {
                    //     record?.state === value;
                    // } else {
                    //     return record.status === value || record?.state === value;
                    // }
                    return record.status === value || record?.state === value;

                },
                render: (_, record) => (
                    <><div onClick={() => { }}>{statusCell(record)}</div></>
                )
            },
            {
                title: <span css={{ display: "flex", alignItems: "center" }}>
                    Created On
                    <span css={{ margin: "0 4px 3px 10px", cursor: "pointer" }}>
                    </span>
                </span>,
                dataIndex: "createdOn",
                key: "createdOn",
                filterIcon: (filtered) => (
                    <FeatherIcon icon="calendar" size={14} color={filtered ? "" : "#000000"} />
                ),
                filteredValue: filterValues?.createdOn || null,
                onFilter: (value, record) => {
                    // return (new Date(record?.createdOn)?.getTime() >= value?.start && new Date(record?.createdOn)?.getTime() <= value?.end);
                    return moment(record?.createdOn).isSameOrAfter(value?.start) && moment(record?.createdOn).isSameOrBefore(value?.end);
                },
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div>
                        <CustomDateRangePicker
                            handleApply={(startDate, endDate) => {
                                // setSelectedKeys([{ start: new Date(startDate).getTime(), end: new Date(endDate).getTime() }])
                                setSelectedKeys([{ start: moment(startDate).startOf("day"), end: moment(endDate).endOf("day") }])
                                confirm()
                            }}
                            handleReset={() => {
                                setSelectedKeys([])
                                clearFilters()
                                confirm()
                                close()
                            }}
                        />
                    </div>
                )
            },
            {
                title: "Created By",
                dataIndex: "createdBy",
                key: "createdBy",
                filters: findUnique(tableFilters?.createdByList, (d) => d.value),
                filterSearch: true,
                filteredValue: filterValues?.createdBy || null,
                onFilter: (value, record) => {
                    return record.createdBy === value;
                },
            },
            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (_, record) => (
                    record?.isDelete &&
                    <div className="text-center" css={{ cursor: "pointer" }}
                        onClick={() => setDeleteModal({
                            open: true,
                            id: record?.id,
                            isSubTicket: record?.mainTicketId ? true : false
                        })}>
                        <FeatherIcon icon={"trash-2"} size={16} color={"red"} />
                    </div>
                )
            },
        ]

        setTableColumns(data)
    }, [currentTab, location?.pathname, priorityOptions, assignedToOptions, allPriorities, allAssignedTo, priorityList, filterValues])

    const getTableDataApi = () => {

        let body = {
            user_id: localStorage.getItem("userId"),
            organization_id: localStorage.getItem("organizationId")
        }
        if (location.pathname?.split('/')[2] == "team-tickets") {
            return getTeamTickets(body)
        } else {
            if (currentTab == 2) {
                return getMyTicketsAvailable(body)
            } else {
                return getMyTickets(currentTab, body["user_id"], body["organization_id"])
            }
        }
    }



    const getTableData = () => {
        setIsLoading(true)
        setFilterValues({})

        let ticketList = []
        let priorityObj = {}
        let assignedToobj = {}
        getTableDataApi()?.then((response) => {
            if (response?.status == "success") {

                response?.data?.forEach((item) => {
                    let object = {
                        id: item.id,
                        client: item.client_name ?? "-",
                        clientId: item?.client_id,
                        projects: item.project_name ?? "-",
                        projectId: item.project_id,
                        subject: item.subject,
                        description: item?.description,
                        type: item.ticket_type_name,
                        typeId: item.ticket_type,
                        departmentId: item.department_id,
                        eta: item.eta,
                        priority: item.ticket_priority_name,
                        priorityId: item?.ticket_priority,
                        assignedTo: item.assigned_to_name,
                        assignedToId: item.assigned_to,
                        timeSpent: item.time_log,
                        timeSpentHrs: parseTime(item.time_log)?.hrs,
                        timeSpentMin: parseTime(item.time_log)?.mins,
                        status: item.ticket_status_name,
                        state: item.ticket_state_name,
                        createdOn: moment(item.created_at).format("DD MMM YYYY"),
                        createdBy: item.created_by_name,
                        createdById: item.created_by,
                        isDelete: item.is_delete,
                        subTicketCount: item.sub_ticket_count,
                        mainTicketId: item.mainticket_id ?? null,
                        ticketType: item.sub_ticket_count !== undefined ? "mainticket" : "subticket"
                    }
                    priorityObj = {
                        ...priorityObj,
                        [item?.id]: item?.ticket_priority_name
                    }
                    assignedToobj = {
                        ...assignedToobj,
                        [item?.id]: item?.assigned_to_name
                    }
                    ticketList.push(object);
                })
                setTableData({ isFiltered: false, data: ticketList, currentData: ticketList, length: ticketList?.length })
                setTableData1({ isFiltered: false, data: ticketList, currentData: ticketList, length: ticketList?.length })
                setTableTotal(ticketList?.length)
                setAllPriorities(priorityObj)
                setAllAssignedTo(assignedToobj)

                let typeData = [
                    ...ticketTypes?.map((value) => {
                        return { text: value?.label, value: value?.label }
                    }),
                    { text: "SOW", value: "SOW" }, { text: "SOW Adhoc", value: "SOW Adhoc" },
                ]

                let clientData = response?.unique_client_names?.map((value) => {
                    return { text: value, value: value }
                })

                let projectData = response?.unique_project_names?.map((value) => {
                    return { text: value, value: value }
                })

                // let subjectData = response?.unique_subjects?.map((value) => {
                //     return { text: value, value: value }
                // })

                let subjectData = [
                    { text: "All Tickets", value: "" },
                    { text: "Only Main Tickets", value: "mainticket" },
                    { text: "Only Sub Tickets", value: "subticket" },
                ]

                let assignData = response?.unique_assigned_to?.map((value) => {
                    return { text: value, value: value }
                })

                let createdByData = response?.unique_created_by?.map((value) => {
                    return { text: value, value: value }
                })

                let statusData = response?.unique_statuses?.map((value) => {
                    return { text: value, value: value }
                })

                let etaData = response?.data?.map((data) => moment(data?.eta))
                let createdOnData = response?.data?.map((data) => moment(data?.created_at))

                setTableFilters({
                    typeList: typeData ?? [],
                    clientList: clientData ?? [],
                    projectList: projectData ?? [],
                    subjectList: subjectData ?? [],
                    assignList: assignData ?? [],
                    createdByList: createdByData ?? [],
                    statusList: statusData ?? [],
                    eta: etaData ?? { start: moment.max(etaData), end: moment.min(etaData) },
                    createdOn: createdOnData ?? { start: moment.max(createdOnData), end: moment.min(createdOnData) }
                })

                let priorityData = {
                    options: priorityList?.map((prio) => { return { label: prio.label, value: prio.label, id: prio.value, text: prio.label } }) ?? [],
                    active: 0
                }
                setPriorityOptions(priorityData)
                setIsLoading(false)
            }

        }).catch((error) => {
            setIsLoading(false)
            setTableData({ isFiltered: false, data: [], currentData: [], length: 0 })
            setTableData1({ isFiltered: false, data: [], currentData: [], length: 0 })
            console.log("Error fetching data:", error);
        });

    }

    useEffect(() => {
        getTableData()
    }, [currentTab, location?.pathname, priorityList])

    useEffect(() => {
        ["ticketCreated"].includes(drawerOpen.action) && getTableData()
    }, [drawerOpen.action])
    // useEffect(() => {
    //     // const handleCustomEvent = (event) => {
    //     //     // console.log('Custom event received in Component B:', event.detail.data);
    //     // };
    //     const callGetDetails = () => getTableData(currentTab)

    //     window.addEventListener('getTableData', callGetDetails);
    //     return () => {
    //         window.removeEventListener('getTableData', callGetDetails);
    //     };
    // }, []);

    const updateApi = (isSubTicket, body) => {
        if (isSubTicket) {
            return updateSubTicket(localStorage?.getItem("userId"), body)
        } else {
            return updateMainTicket(localStorage?.getItem("userId"), body)
        }
    }

    const updateTicket = ({ record, priorityId, assignedToId, eta }) => {
        let isSubTicket = record?.mainTicketId ? true : false
        let body = {
            ...(isSubTicket ? { subticket_id: record?.id } : { mainticket_id: record?.id }),
            ...(priorityId && { ticket_priority: priorityId }),
            ...(assignedToId && { assigned_to: assignedToId })
        }
        updateApi(isSubTicket, body)?.then((response) => {
            if (response.status == "success") {
                // getTableData()
                response?.data?.assignedTo && setAllAssignedTo({ ...allAssignedTo, [response?.data?.id]: response?.data?.assignedTo })
                response?.data?.ticket_priority && setAllPriorities({ ...allPriorities, [response?.data?.id]: response?.data?.ticket_priority })
                toast.success("Ticket Updated Successfully")
            }
        })?.catch((error) => {

        })
    }

    const deleteApi = () => {
        let mainBody = { mainticket_id: deleteModal?.id }
        let subBody = { subticket_id: deleteModal?.id }
        if (deleteModal?.isSubTicket) {
            return deleteSubTicket(localStorage?.getItem("userId"), subBody)
        } else {
            return deleteMainTicket(localStorage?.getItem("userId"), mainBody)
        }
    }

    const deleteTicket = () => {
        deleteApi().then((response) => {
            if (response.status == "success") {
                setDeleteModal({ open: false })
                toast.success("Ticket Deleted Successfully")
                getTableData()
            }
        }).catch((error) => {
            setDeleteModal({ open: false })
            if (error.response.data.status == "error") {
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error("Failed to Create Ticket");
                }
            }
        });
    }


    // TABLE CELLS UI ---------------------------------------------

    const subjectCell = (record) => {
        return (
            <div css={{ display: "flex", alignItems: "center" }}>
                <div>
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Link to={`/tickets/${location.pathname?.split('/')[2]}/ticket-details?${record?.id}`}>
                                <div
                                    css={{
                                        ...textStyles.s2Medium, textDecoration: "underline", cursor: "pointer", color: Colors.black,
                                        maxWidth: "22rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}
                                    onClick={() => {
                                        setTicket({
                                            ticketId: record?.id,
                                            parentId: record?.mainTicketId ?? null,
                                            isSubTicket: record?.mainTicketId ? true : false
                                        })
                                        localStorage.setItem("ticketId", record?.id)
                                    }}
                                >
                                    {record?.subject}
                                </div>
                            </Link>
                        </Tooltip.Trigger>
                        <Tooltip.Popper>{record?.subject}</Tooltip.Popper>
                    </Tooltip>

                    {record?.description?.trim()?.length > 0 &&
                        <div css={{ ...textStyles.s3, color: Colors.neutral[500] }}>{record?.description?.length > 19 ? `${record?.description?.slice(0, 20)}...` : record?.description}</div>
                    }
                </div>
                {record?.subTicketCount > 0 &&
                    <div className="ms-4">
                        <SubTicketCounter onClick={() => openDrawer("subTicket", record?.id)} count={record?.subTicketCount} />
                    </div>}
            </div>
        )
    }


    const typeCell = (record) => {
        const typeColors = {
            Universal: Colors.primary[100],
            Adhoc: Colors.error[100],
            AdHoc: Colors.error[100],
            SOW: Colors.secondary[100],
            "SOW Adhoc": Colors.secondary[100],
            "Pitch Deck": Colors.semantic[50],
        }
        return (
            <div css={{ ...textStyles.H1Medium, display: "inline-flex", backgroundColor: typeColors[record.type], borderRadius: "6px", padding: "0.63rem 0.75rem" }}>{record.type}</div>
        )
    }

    function convertTimeToMinutes(time) {
        const parts = time.split(' '); // Split the time string into parts
        let totalMinutes = 0;

        for (let i = 0; i < parts.length; i += 2) {
            const value = parseInt(parts[i]); // Extract the numeric value
            const unit = parts[i + 1]; // Extract the unit (hrs or mins)

            if (unit === 'hrs') {
                totalMinutes += value * 60; // Convert hours to minutes
            } else if (unit === 'mins') {
                totalMinutes += value; // Add minutes directly
            }
        }

        return totalMinutes;
    }


    const priorityCell = (record, options) => {
        let disabled = false
        if (currentTab == 0) disabled = true
        if (record?.createdById !== localStorage.getItem("userId")) disabled = true
        if (["Completed", "Awaiting Approval"].includes(record?.status)) disabled = true
        // if (["SOW Adhoc", "SOW"].includes(record?.type)) disabled = true

        return (
            <div css={styles.keyword(disabled)} key={allPriorities}>
                <DropDownInput
                    addPopperStyles={styles.selectDropDownContainer}
                    data={options}
                    showSearchBar={false}
                    // nestedTrigger={allPriorities[record?.id]}
                    placeholder={"High"}
                    // placement={"right-start"}
                    handleDropdownClick={(selected, value, id) => {
                        // updateSowTask({
                        //     record,
                        //     priority: priorityList?.filter((prior) => prior.value == value)[0]
                        //         .id,
                        // });
                        setAllPriorities({ ...allPriorities, [record?.id]: value })
                        updateTicket({ record, priorityId: id })
                    }}
                    defaultValue={allPriorities[record?.id]}
                    value={allPriorities[record?.id]}
                    triggerStyles={styles.priorityStyles(allPriorities[record?.id])}
                />
            </div>
        )
    }

    const assignedToCell = (record) => {
        let disabled = false
        if (currentTab !== 1) disabled = true
        if (disabledStatus.includes(record?.status)) disabled = true
        // if (["SOW Adhoc", "SOW"].includes(record?.type)) disabled = true

        return (
            <div css={styles.keyword(disabled)} onClick={() => { getMemberList(record) }}>
                <DropDownInput
                    addPopperStyles={styles.selectDropDownContainer}
                    data={assignedToOptions ?? { options: [], active: 0 }}
                    showSearchBar={true}
                    nestedTrigger={allAssignedTo[record?.id]}
                    placeholder={"Select Member"}
                    // placement={"right-start"}
                    handleDropdownClick={(selected, value, id) => {
                        // updateSowTask({
                        //     record,
                        //     priority: priorityList?.filter((prior) => prior.value == value)[0]
                        //         .id,
                        // });
                        setAllAssignedTo({ ...allAssignedTo, [record?.id]: value })
                        updateTicket({ record, assignedToId: id })
                    }}
                    defaultValue={allAssignedTo[record?.id]}
                    triggerStyles={styles.assignedToStyles}
                />
            </div>
        )
    }


    const statusCell = (record) => {
        const statusColors = {
            "In Progress": Colors.secondary[900],
            Available: Colors.primary[500],
            Completed: Colors.semantic[400],
            "Awaiting Approval": Colors.secondary[600],
            "Pending": Colors.semantic[700],
        }

        const stateColors = {
            "Overdue": {
                backgroundColor: Colors.error[50], color: Colors.error[800], border: `1px solid ${Colors.error[800]}`, borderRadius: "4px", padding: "0 6px"
            }
        }

        return (
            <div>
                <div css={{ ...textStyles.H1Medium, color: statusColors[record.status] }}>{record.status}</div>
                {record?.state && <div
                    css={{ ...textStyles.b3, display: "inline-block", ...stateColors[record?.state] }}
                >
                    {["Overdue"].includes(record?.state) ? record?.state : ""}
                </div>}
            </div>
        )
    }



    // UTILITY FUNCTIONS
    const openDrawer = (drawerName, id) => {
        setDrawerOpen({ open: true, drawer: drawerName, id: id })
    }

    const onDrawerClose = () => {
        setDrawerOpen({ ...drawerOpen, open: false, id: null })
    }

    const parseTime = (input) => {
        const regex = /(\d+)\s*hrs?\s*(\d+)\s*mins?/;
        const matches = input.match(regex);

        if (matches) {
            const hours = parseInt(matches[1], 10) || 0;
            const minutes = parseInt(matches[2], 10) || 0;
            return { hrs: `${hours} hrs`, mins: `${minutes} mins` }
        } else {
            return { hrs: "0 hrs", mins: "0 mins" };
        }
    }


    return (
        <>
            <div class="col-md-12 mt-3" css={styles.tableMain}>
                <Table
                    key={currentTab}
                    id="ticketsTable"
                    className="table table-hover mt-3 bg-white custome_filter"
                    columns={tableColumns}
                    dataSource={tableData.data}
                    getPopupContainer={() =>
                        document.getElementById("ticketsTable")
                    }
                    // rowSelection={{ type: "checkbox", ...rowSelection }}
                    scroll={{
                        x: "max-content",
                    }}
                    summary={(currentPageData) => {
                        currentPageData?.length < 1 && setTableTotal(0)
                    }}
                    pagination={{
                        defaultCurrent: 1,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        showTotal: (total, range) => {
                            setTableTotal(total)
                            return `Showing ${range[0]} to ${range[1]} of ${total} Tasks`
                        },
                    }}
                    onChange={(pagination, filters, sorter, extra) => {
                        setFilterValues(filters)
                        setSortedInfo(sorter)
                        // if (filters.client || filters.projects || filters.subject || filters.type || filters.priority || filters.assignedTo || filters.createdBy || filters.status || filters.eta || filters.createdOn) {
                        //     setTableData({ isFiltered: true, data: tableData.data, currentData: extra.currentDataSource, length: extra.currentDataSource?.length })
                        // } else {
                        //     setTableData({ isFiltered: false, data: tableData1.data, currentData: [], length: tableData1.data?.length })
                        // }
                        if (filters.client || filters.projects || filters.subject || filters.type || filters.priority || filters.assignedTo || filters.createdBy || filters.status || filters.eta || filters.createdOn) {
                            setTableData({ ...tableData, isFiltered: true, currentData: extra.currentDataSource, length: extra.currentDataSource?.length })
                        } else {
                            setTableData({ ...tableData, isFiltered: false, currentData: extra.currentDataSource, length: extra.currentDataSource?.length })
                        }
                    }}
                    locale={{
                        emptyText: (
                            <ErrorScreen
                                title={"No Tickets found"}
                                subtitle={
                                    <div>
                                        <div css={styles.subtitles}>{errorStates[currentTab]}</div>
                                    </div>
                                }
                                icon={AssetsImg.ticket_empty_table}
                                addStyles={{ minHeight: "15rem", marginTop: "10%" }}
                            />
                        ),
                    }}
                />
            </div>
            <DeleteModal visible={deleteModal?.open} handleModalClose={() => setDeleteModal({ open: false })} handleDelete={() => { deleteTicket() }} />
            {/* <DeleteModal visible={modalOpen} handleModalClose={() => exportToEXCEL(tableData, "Table Name")} /> */}
            {(isLoading) && (
                <SpinContainer
                    loading={true}
                    background={"rgba(0,0,0,0.4)"}
                    css={styles.loader}
                ></SpinContainer>
            )}
        </>
    )
}

export default TicketTable;