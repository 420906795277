import React, { useEffect } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";

function CommonTable(props) {

  useEffect(() => {
  }, []);

  return (
    <>
      <div class="title">
        <div class="d-flex align-items-center">
          <h6 class="title-text mb-0">{props.title}</h6>
          <i
            href="#"
            class="text-dark ms-3"
            data-toggle="tooltip"
            data-html="true"
            title={props.tooltip}
          >            
            <img class="ticket-info" src="/images/info.png" alt="ticket info" />
          </i>
        </div>
        <span>{props.subtitle}</span>
      </div>
      <Table
       class="table-responsive"
        columns={props.columns}
        pagination={false}
        dataSource={props.datasource}
      />
    </>
  );
}

export default CommonTable;
