/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { MonthPicker, DateRangePicker } from "@semcore/ui/date-picker";
import Notice from "@semcore/ui/notice";
import Warning from "@semcore/icon/Warning/m";
import * as styles from "./styles";
import { Box, Flex } from "@semcore/ui/flex-box";
import ChevronLeftm from "@semcore/icon/ChevronLeft/m";
import Calendar from "feather-icons-react";
import { useSelector } from "react-redux";
import Checkbox from "@semcore/ui/checkbox";
import CustomButton from "../../button";
import MonthPickerRange from "../MonthPickerRange";
import { getCurrentAndPreviousMonth } from "../../../../utility/dates";

const MonthPickerSingle = ({
  noticeTitle,
  noticeDescription,
  showNote = false,
  hideTrigger,
  onApplyClick,
  availableMonths,
  defaultValueSingle,
  defaultValueCompare,
  showCompare = true,
  addBottomContainerStyles,
  disabledDefault = false
}) => {

  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const [isChecked, setIsChecked] = useState(isCompare);
  const [isVisible, setVisible] = useState(hideTrigger ? true : false);
  const [value, setValue] = useState(defaultValueSingle);
  const currentAndPreviousMonth = getCurrentAndPreviousMonth(new Date());

  const [isApplyDisabled, setIsApplyDisabled] = useState(disabledDefault);

  useEffect(() => {
    if (availableMonths?.length === 0) {
      setIsApplyDisabled(true)
    } else if (availableMonths?.length < 1) {
      setIsChecked(false)
    }
  }, [availableMonths])

  const handleApplyClick = () => {
    onApplyClick(isChecked, value);
  };

  return (
    <Box>
      {!isChecked ? (
        <MonthPicker
          value={value}
          onChange={(date) => {
            setIsApplyDisabled(false)
            setValue(date)
          }}
          style={{ fontSize: "24px" }}
          visible={hideTrigger}
        >
          {!hideTrigger && (
            <MonthPicker.Trigger css={styles.trigger} size={"m"} />
          )}
          <MonthPicker.Popper
            css={[
              styles.popperContainer,
              hideTrigger ? styles.removeBorderShadow : "",
            ]}
          >
            {showNote && (
              <Notice theme={"warning"} css={styles.noticeContainer}>
                <Notice.Label css={styles.noticeLabelContainer}>
                  <Flex alignItems="center" gap={"0.5em"} p={"0"}>
                    <Warning css={styles.noticeIcon} />k
                    <div css={styles.noticeTitle}>{noticeTitle}</div>
                  </Flex>
                  <Flex css={styles.noticeDescription}>
                    {noticeDescription}
                  </Flex>
                </Notice.Label>
              </Notice>
            )}
            <Box css={styles.calendar}>
              <MonthPicker.Header p={0}>
                <MonthPicker.Prev />
                <MonthPicker.Title css={styles.headerTitle} />
                <MonthPicker.Next />
              </MonthPicker.Header>
              <MonthPicker.Calendar size="s">
                {(props) => {
                  return props.months.map((month, index) => (
                    <MonthPicker.Calendar.Unit
                      {...month}
                      key={index}
                      css={styles.calendarText}
                      disabled={availableMonths?.indexOf(month.date) === -1}
                    >
                      {month.children}
                    </MonthPicker.Calendar.Unit>
                  ))
                }
                }
              </MonthPicker.Calendar>
            </Box>
            <div css={[styles.bottomContainer, addBottomContainerStyles && addBottomContainerStyles]}>
              {showCompare && <div css={styles.compareContainer}>
                <Checkbox
                  checked={isChecked}
                  onChange={(checked) => setIsChecked(checked)}
                  disabled={availableMonths?.length < 2}
                />
                <span css={styles.compareText(availableMonths?.length < 2)}>Compare</span>
              </div>}
              <CustomButton
                text={"Apply"}
                theme={"info"}
                use={"primary"}
                onPress={() => handleApplyClick()}
                isDisable={isApplyDisabled}
              />
            </div>
          </MonthPicker.Popper>
        </MonthPicker>
      ) : (
        <MonthPickerRange
          singleCalendar={true}
          hideTrigger={true}
          isCompare={isChecked}
          initialMonths={currentAndPreviousMonth}
          onCompareClick={(checkState) => setIsChecked(checkState)}
          onChange={(date) => {
            onApplyClick(isChecked, date)
          }}
          availableMonths={availableMonths}
          defaultValue={defaultValueCompare}
        />
      )}
    </Box>
  );
};

export default MonthPickerSingle;
