/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import * as styles from "./styles";
import { Link } from "react-router-dom";
import { getLocal, setLocal } from "../../../../../utility/helpers";

const SubList = ({ list, onPress, isCollapsed, isExpanded, activeTab }) => {
  const [listData, setListData] = useState({ options: [], active: getLocal("activeSitebarSubOption") });
  const activeSitebarSubOption = getLocal("activeSitebarSubOption");
  let activeSitebarSubOptionLevel2
  if (!!getLocal("activeSitebarSubOptionLevel2")) {
    activeSitebarSubOptionLevel2 = JSON.parse(getLocal("activeSitebarSubOptionLevel2"));
  } else {
    activeSitebarSubOptionLevel2 = null;
  }
  useEffect(() => {
    if (!!activeSitebarSubOption) {
      setListData((prevState) => {
        return { options: list, active: activeSitebarSubOption };
      });
    } else {
      setListData((prevState) => {
        return { options: list, active: null };
      });
    }
  }, [activeSitebarSubOption]);

  return (
    !isCollapsed &&
    isExpanded && (
      <div css={styles.mainContainer}>
        {listData?.options?.map((item, index) => {
          return (
            <Link
              to={item.link}
              css={
                (index === parseInt(listData.active) && parseInt(activeTab) === parseInt(activeSitebarSubOptionLevel2))
                  ? styles.item(true && isExpanded)
                  : styles.item(false && isExpanded)
              }
              onClick={(e) => {
                !item.link && e.preventDefault()
                onPress(index);
                setListData((prevState) => {
                  return { ...prevState, active: index };
                });
                setLocal("activeSitebarSubOption", index);
                setLocal("activeSitebarSubOptionLevel2", activeTab);
              }}
              key={index}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
    )
  );
};

export default SubList;
