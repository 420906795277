import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Space, Tag } from "antd";
import { Select } from "antd";
import { DatePicker } from "antd";
import Chart from "react-apexcharts";
import Filter from "./assets/funnel-fill.svg";
import "antd/dist/antd.css";
import { Modal } from "react-bootstrap";
import { getMember, getTicket, getTicketbyorgId } from "../utility/Constant";
import {
  // getCheckPermission,
  setPriority,
  setStatus,
  setTicketType,
  ticketType,
} from "../utility/Globals";
import moment from "moment";
import APIManager from "../services/APIManager";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
  formatDate,
} from "../utility/Globals";
import { exportInToCSV, exportToEXCEL } from "../utility/Globals";
import { Link, useNavigate } from "react-router-dom";

const rowSelection = {
  onChange: () => {},
};

// filter by dropdown
const { Option } = Select;

function onChange(value) {}

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

//antd calendar
function onChange1(date, dateString) {}

function TicketsList() {
  const [options, setObject] = useState({
    title: {
      text: "Tickets",
      align: "center",
    },
    chart: {
      id: "basic-bar",
      stacked: true,
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: "Days",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "No.of Tickets",
        style: {
          color: "#000",
        },
      },
    },
  });

  const [series, setSeries] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [searchdata, setsearch] = useState([]);
  const [showApplyFilter, setShowApplyFilter] = useState(false);
  function handleModal() {
    setshow(!show);
  }
  const [notes, setnotes] = useState([]);
  const [show, setshow] = useState(false);

  function addticket() {
    var a = document.getElementById("newticket").value;
    var data = JSON.parse(JSON.stringify(teamlist));
    var i = teamlist.length;
    data.push({
      key: i,
      id: (
        <Link
          // to={{
          //   pathname: ,
          //   search: ,
          //   state: ,
          // }}
          to={"/Tickets"}
          state={{ detail: "1" }}
          search={`?id=${i}`}
        >
          {" "}
          {i}{" "}
        </Link>
      ),
      type: "Alert",
      subject: a,
      priority: "High",
      status: <Tag color="#f50">Over Due</Tag>,
      raisedby: "BOT",
      raisedon: "03/05/2020",
      duedate: "03/05/2021",
      assignedto: "Emp1",
      project: "Myntra Shoes",
      module: "Website Authority",
      lastupdate: "RAJ",
      view: <a href="view-client-details">View</a>,
    });
    setteamList(data);
    document.getElementById("newticket").value = "";
  }

  useEffect(() => {
    var data = [];
    var filtercity = [];
    var filtertype = [],
      filterpriority = [],
      filterassignedto = [],
      filterproject = [];
    for (let i = 0; i < 2; i++) {
      data.push({});
    }
    setnotes(data);
    data = [];
    filtertype.push({});
    filterpriority.push({});
    filterassignedto.push({});
    filterproject.push({});
    filtercity.push({});

    for (let i = 1; i < 100; i++) {
      filtertype.push({
        text: "Competitor Alert",
        value: "Competitor Alert",
      });
      filterpriority.push({
        text: "Low",
        value: "Low",
      });
      filterassignedto.push({
        text: "Emp2",
        value: "Emp2",
      });
      filterproject.push({
        text: "Myntra Fashion",
        value: "Myntra Fashion",
      });
      filtercity.push({
        text: "Bangalore",
        value: "Bangalore",
      });
    }

    filtertype = [...new Set(filtertype.map(JSON.stringify))].map(JSON.parse);
    filterpriority = [...new Set(filterpriority.map(JSON.stringify))].map(
      JSON.parse
    );

    filterassignedto = [...new Set(filterassignedto.map(JSON.stringify))].map(
      JSON.parse
    );
    filterproject = [...new Set(filterproject.map(JSON.stringify))].map(
      JSON.parse
    );
    filtercity = [...new Set(filtercity.map(JSON.stringify))].map(JSON.parse);

    const columns = [
      {
        title: "Ticket",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        filters: filtertype,
        filterSearch: true,
        onFilter: (value, record) => record.type.indexOf(value) === 0,
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        filters: filterpriority,
        filterSearch: true,
        onFilter: (value, record) => record.priority.indexOf(value) === 0,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Raised By",
        dataIndex: "raisedby",
        key: "raisedby",
      },
      {
        title: "Raised On",
        dataIndex: "raisedon",
        key: "raisedon",
      },
      {
        title: "Due Date",
        dataIndex: "duedate",
        key: "duedate",
      },
      {
        title: "Assigned To",
        dataIndex: "assignedto",
        key: "assignedto",
        filters: filterassignedto,
        filterSearch: true,
        onFilter: (value, record) => record.assignedto.indexOf(value) === 0,
      },
      {
        title: "Project",
        dataIndex: "project",
        key: "project",
        filters: filterproject,
        filterSearch: true,
        onFilter: (value, record) => record.project.indexOf(value) === 0,
      },
      {
        title: "Module",
        dataIndex: "module",
        key: "module",
      },

      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setteamcol(columns);
    getmembers();
    getTicketlist();
    getTicketChartsDataorg();
  }, []);

  const navigate = useNavigate();
  const addticketnew = () => {
    navigate("/Tickets");
  };
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  function addnote() {
    var note = document.getElementById("notes-input").value;
    var data = [];
    var id = 0;
    notes.map((i) => {
      data.push(i);
      id += 1;
    });
    data.push({
      id: id,
      name: "Raj",
      date: "17/05/2021",
      time: "16:57",
      description: `${note}`,
    });
    setnotes(data);

    document.getElementById("notes-input").value = "";
  }

  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");

  function getmembers() {
    APIManager.getAPI(getMember).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
        setmemberlistSelected(list[0].id);
      }
    });
  }

  function members(e) {
    var c = e.target.value;
    setmemberlistSelected(c);
  }

  const [Ticketlist, setTicketlist] = useState([]);

  function getTicketlist() {
    APIManager.getAPI(getTicket).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const list2 = [];
        response.data.map((item) => {
          list.push({
            id: item.id,
            name: item.subject,
          });
          list2.push({
            key: item.id,
            id: (
              <Link
                to={"/Tickets"}
                search={`?id=${item.id}`}
                state = { {detail: "1"} }
              >
                {" "}
                {item.id}{" "}
              </Link>
            ),
            type: setTicketType(item.ticketType),
            subject: item.subject,
            priority: setPriority(item.priority),
            status: setStatus(item.status),
            raisedby: item.raisedBy,
            raisedon: moment(item.raisedOn).format("DD/MM/YYYY"),
            duedate: moment(item.dueOn).format("DD/MM/YYYY"),
            assignedto: item.assignedMembers.name,
            project: item.project.projectCode,
            module: item.module?.name,

            view: <a href="view-client-details">View</a>,
            action: (
              <i
                class="fa fa-info-circle"
                onClick={() => {
                  handleModal();
                }}
              ></i>
            ),
          });
        });
        setTicketlist(list);
        setsearch(list2);
        setteamList(list2);
      }
    });
  }

  function getTicketChartsDataorg() {
    let newDate = new Date().toISOString().slice(0, 10);
    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);
    const url = `${getTicketbyorgId}?organizationId=${localStorage.getItem(
      "organizationId"
    )}&startDate=${mmDDyyyyFormateDateSet(
      myPastDate
    )}&endDate=${mmDDyyyyFormateDateSet(newDate)}`;
    APIManager.getAPI(url, true).then((response) => {
      if (response && response.status == 200) {
        let inProgressgraphData = [];
        let overDuegraphData = [];
        let resolvedgraphData = [];
        let dates = [
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 6)
          ),
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 5)
          ),
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 4)
          ),
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 3)
          ),
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 2)
          ),
          formateDateIntoDatemonth(
            new Date().setDate(new Date().getDate() - 1)
          ),
          formateDateIntoDatemonth(new Date()),
        ];

        dates.map((date) => {
          if (response.data.some((x) => formatDate(x.date) === date)) {
            response.data.map((item) => {
              if (date === formatDate(item.date)) {
                inProgressgraphData.push(item.inProgress ? item.inProgress : 0);
                overDuegraphData.push(item.overDue ? item.overDue : 0);
                resolvedgraphData.push(item.resolved ? item.resolved : 0);
              }
            });
          } else {
            inProgressgraphData.push(0);
            overDuegraphData.push(0);
            resolvedgraphData.push(0);
          }
        });
        setSeries([
          {
            name: "InProgress",
            data: inProgressgraphData,
            color: "#4f81bc",
          },
          {
            name: "Overdue",
            data: overDuegraphData,
            color: "#c0504e",
          },
          {
            name: "Resolved",
            data: resolvedgraphData,
            color: "#9aba58",
          },
        ]);
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="Common-Row-Div">
          <div className="Common-mt-64 mt-0 w-100 ms-0">
            <div class="row">
              <div class="col-sm-5 pad-lzero">
                <div class="main-title">Tickets List</div>
              </div>
              <div class="col-sm-7 add-new-btnw">
                <button
                  type="button "
                  class="btn btn-primary"
                  onClick={addticketnew}
                >
                  Add New Ticket
                </button>
              </div>
            </div>
            <div class="top-ticket-frame">
              <div className="row">
                <div className="col-sm-3">
                  <div class="col-md-12">
                    <select name="acc" id="acc" class="w-100">
                      <option value="" selected="selected">
                        Select
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-9 add-new-btnw">
                  <select
                    class="mx-2"
                    style={{
                      width: "25%",
                    }}
                    value={memberlistSelected}
                    onChange={members}
                  >
                    {memberlist.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>

                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={addticket}
                  >
                    Assign
                  </button>
                  <img
                    src={Filter}
                    alt="filter"
                    onClick={() => setShowApplyFilter((prev) => !prev)}
                    class="filterimage"
                  />
                </div>
              </div>
            </div>
            <br />
            {showApplyFilter && (
              <div className="apply-filter">
                <div></div>
                <span>
                  <Input.Search
                    style={{ width: 200, margin: "12px" }}
                    allowClear
                    placeholder="Ticket"
                    onSearch={() => {}}
                    id="input-s"
                  />
                </span>

                <span>
                  <Select
                    showSearch
                    style={{ width: 200, margin: "12px" }}
                    placeholder="Ticket Type"
                    optionFilterProp="children"
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ticketType.map((i) => {
                      return <option value={i.value}>{i.label}</option>;
                    })}
                  </Select>
                </span>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Project"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="MyntraShoes">Myntra Shoes</Option>
                  <Option value="AmazonMerchandise">Amazon Merchandise</Option>
                  <Option value="MyntraFashion">Myntra Fashion</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Module"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="da/pachecker">Website Authority</Option>
                  <Option value="googletrends">Google Trends</Option>
                  <Option value="pagespeedandcorewebvitals">
                  PageSpeed
                  </Option>
                  <Option value="clickshare">Click Share</Option>
                  <Option value="ranktracking">Rankings</Option>
                  <Option value="siteuptimemonitor">Site Uptime Monitor</Option>
                  <Option value="gscdataextractor">Impressions & Clicks</Option>
                  <Option value="organicresearch">Organic Research</Option>
                  <Option value="roicalculator">Return on Investment</Option>
                  <Option value="contentword">Content Word Count on a Page</Option>
                  <Option value="backlinkcount">Backlink Profile</Option>
                  <Option value="keywordsearch">Keyword Search</Option>
                  <Option value="seovolatality">SERP Volatility</Option>
                  <Option value="seomanualaudit">Audit Findings</Option>
                  <Option value="googleanalyticedataextractor">
                  Traffic
                  </Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Priority"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="low">Low</Option>
                  <Option value="medium">Medium</Option>
                  <Option value="high">High</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Status"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="open">Open</Option>
                  <Option value="inprogress">Inprogress</Option>
                  <Option value="resolved">Resolved</Option>
                  <Option value="reopen">Reopen</Option>
                  <Option value="closed">Closed</Option>
                  <Option value="overdue">Overdue</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Assigned"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="emp1">Emp 1</Option>
                  <Option value="emp2">Emp 2</Option>
                  <Option value="emp3">Emp 3</Option>
                </Select>
                <Select
                  showSearch
                  style={{ width: 200, margin: "12px" }}
                  placeholder="Raised By"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="customer">Customer</Option>
                  <Option value="bot">Bot</Option>
                  <Option value="self">Self</Option>
                </Select>
                <Space
                  style={{ margin: "12px" }}
                  className="create-cal"
                  direction="vertical"
                >
                  <DatePicker placeholder="Created On" onChange={onChange1} />
                </Space>
                <Space style={{ margin: "12px" }} direction="vertical">
                  <DatePicker placeholder="Due On" onChange={onChange1} />
                </Space>
                <span>
                  <Input.Search
                    style={{ width: 200, margin: "12px" }}
                    allowClear
                    placeholder="Key word search"
                    onSearch={() => {}}
                    id="input-s"
                  />
                </span>
                <div
                  class="add-new-btnw"
                  style={{ marginRight: 10 + "px", marginBottom: 10 + "px" }}
                >
                  <button
                    class="outline-btn"
                    onClick={() => setShowApplyFilter((prev) => !prev)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
            <br />
            <div className="d-flex justify-content-center">
              <Chart
                options={options}
                series={series}
                type="bar"
                width="700"
                height="300"
              />
            </div>
            <div class="common-table">
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-7">
                  <div class="add-new-btnw">
                    <span class="dropdown d-inline">
                      {/* {getCheckPermission("export", "tickets_list") ? ( */}
                        <button
                          class="btn btn-primary dropdown-toggle my-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL();
                          }}
                        >
                          Export
                        </button>
                      {/* ) : (
                        <></>
                      )} */}
                      {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL();
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV();
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <Table
                  className="table table-bordered table-hover"
                  id="sample"
                  columns={teamcol}
                  dataSource={[...teamlist]}
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => handleModal()} className="edit-notes">
        <Modal.Header closeButton>View / Add Notes</Modal.Header>
        <Modal.Body>
          {notes &&
            notes.map((i) => {
              return (
                <div className="notes-list ">
                  <span class="profile-pic">
                    <img src="images/profile-pic.jpeg" alt="" />
                  </span>
                  <span class="notes-name">{i.name} - </span>
                  <span class="notes-date">{i.date} </span>
                  <span class="notes-time"> {i.time}</span>
                  <div className="description">{i.description}</div>
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <div className="notes-input-box tickets-view" id="notes-input-box">
            <div>
              <textarea
                rows="4"
                cols="45"
                id="notes-input"
                placeholder="Enter notes..."
              ></textarea>
            </div>
            <div>
              <button class="send-button" onClick={addnote}>
                <i class="fa fa-send"></i>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TicketsList;
