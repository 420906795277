import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import { deleteCityById, getAllCountry } from "../../utility/Constant";
import { exportToEXCEL, getCheckPermission } from "../../utility/Globals";
import EditCityDir from "./EditCityDir";
import { Link, useNavigate } from "react-router-dom";

const City = ({ update }) => {
  const navigate = useNavigate();
  const [exportList, setExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [usercity, setusercity] = useState([]);
  const [usercityList, setusercityList] = useState([]);
  const [usercitycol, setusercitycol] = useState([]);
  const [settings, setSetting] = useState("cityList");

  useEffect(() => {
    var data = [
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.city, b.city),
          multiple: 3,
        },
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.country, b.country),
          multiple: 3,
        },
      },
      {
        title: "UULE",
        dataIndex: "uule",
        key: "uule",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.uule, b.uule),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "4%",
        key: "action",
      },
    ];
    setusercitycol(data);
    getCityData();
  }, []);

  const cityrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item, index) => {
        let obj = {
          city: item.city,
          country: item.country,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function getCityData() {
    APIManager.getAPI(
      getAllCountry + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        response.data.forEach((item) => {
          item.cities?.forEach((city) => {
            let obj = {
              key: city.cityId,
              id: city.cityId,
              city: city.cityName,
              uule: city.uule,
              country: item.countryName,
              action: (
                <div className="d-flex">
                  <div
                    to={{
                      state: {
                        item: {
                          cityId: city.cityId,
                          cityName: city.cityName,
                          uule: city.uule,
                          countryId: item.countryId,
                        },
                        isEdit: true,
                      },
                    }}
                  >
                     {getCheckPermission("edit", "organization_setup") ?
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon ms-2"
                      onClick={() => {
                        setSetting("addEditCity");
                        navigate("",{
                          state: {
                            item: {
                              cityId: city.cityId,
                              cityName: city.cityName.trim(),
                              uule: city.uule,
                              countryId: item.countryId,
                            },
                            isEdit: true,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    : <></>}
                  </div>
                  {getCheckPermission("delete", "organization_setup") ?
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2 trash-icon ms-3 mt-1"
                    onClick={() => {
                      deleteCityItem(city.cityId);
                    }}
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  : <></>}
                </div>
              ),
            };
            list.push(obj);
            exportList.push({
              // id: city.cityId,
              uule: city.uule,
              city: city.cityName,
              country: item.countryName,
            });
          });
        });
        setusercity(list);
        setusercityList(list);
        setExportList(exportList);
      }
    });
  }

  async function deleteCityItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteCityById + id);
      if (response === 200) {
        getCityData();
      }
    } catch (e) { }
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getCityData();
  };

  function getComponent() {
    if (settings === "addEditCity") {
      return <EditCityDir update={onClickFunction} />;
    }
  }
  return (
    <>
      {settings === "cityList" ? (
        <div className="portlet slideInUp setting-top city_main">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 className="portlet-title main-title">
              {" "}
              {localStorage.getItem("organizationName")} - City
            </h3>
          </div>
          <div className="portlet-body">
            <div class="row">
              <div class="col-sm-6 col-md-8 d-flex justify-content-start">
                <div class="input-group-icon input-group-lg widget15-compact">
                  <div class="input-group-prepend">
                    <i class="fa fa-search text-primary"></i>
                  </div>
                  <div className="search-box">
                    <span>
                      <Input
                        allowClear
                        style={{ height: 36 + "px" }}
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        onChange={(nameSearch) => {                          
                          setusercity(
                            usercityList.filter((person) =>
                              person.city != null ? person.city.toLocaleLowerCase().includes(
                                nameSearch.target.value.toLocaleLowerCase()
                              ) : ""
                            )
                          );
                        }}
                        id="input-s"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 mt-3 mt-sm-0">
                <div class="data-export-client me-3">
                  <span class="count-drop"></span>
                  {getCheckPermission( "add", "organization_setup") ?
                  <Link
                    class="btn btn-primary add"
                    state={{ isEdit: false }}
                    onClick={() => {
                      setSetting("addEditCity");
                    }}
                  >
                    Add
                  </Link>
                  : <></>}
                  <span class="dropdown d-inline ">
                    <button
                      class="btn btn-primary dropdown-toggle ms-3 export-btn"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "City"
                        );
                      }}
                    >
                      Export
                    </button>
                 
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <Table
                className="table table-bordered table-hover mt-3 city-table"
                id="sample-module-expand"
                dataSource={usercity}
                columns={usercitycol}
                rowSelection={{
                  type: "checkbox",
                  ...cityrowSelection,
                }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        getComponent()
      )}
    </>
  );
};

export default City;
