import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  minWidth: "16.125rem !important",
  // ".___SDropdownMenuItem_9x64n_gg_ > ._size_m_9x64n_gg_": {
  //   boxShadow: "none !important",
  // }
});

export const iconStyle = (isOpen) => { 
  return css({
  ".___SIcon_1jhua_gg_": {
    transform: `rotate(${isOpen?"180deg":"360deg"})`
  }
})}

export const trigger = css({
  ...textStyles.b3,
  width: "100% !important",
  border: "1px solid black",
});
export const triggerText = css({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const popper = css({
  width: "100% ",
});

export const customTrigger = css({
  border: "none",
  padding: 0,
});

export const searchWrapper = css({
  top: "0 !important",
  left: "0 !important",
});
export const searchIcon = css({
  height: "1.25rem !important",
});

export const text = css({
  ...textStyles.b2,
  color: Colors.textLink,
});

export const labelText = css({
  ...textStyles.b2,
  lineHeight: "1.375rem !important",
});

export const searchInput = css({
  padding: "0.6rem 0.75rem !important",
  height: "2.5rem !important",
});

export const optionContainer = css({
  width: "100%",
  display: "block",
  padding: "0.38rem 0.5rem !important",
  minHeight: "auto !important",
  color: "red !important"
});

export const customPopper = css({
  padding:"0 0.25rem",
  minWidth: "0.93rem"
})

export const optionHeader = css({
  width: "100%",
  color: `${Colors.neutral[900]} !important`,
  ...textStyles.b2,
  lineHeight: "1.5rem !important",
});
export const customOption = (isActive) => css({
  width: "100%",
  color: isActive? `${Colors.primary[500]} !important` : `${Colors.neutral[900]} !important`,
  ...textStyles.b2,
  lineHeight: "1.5rem !important",
});

export const checkbox = css({
  height: "1rem !important",
  width: "1rem !important",
});

export const tooltip = css({
  ...textStyles.b3,
  padding: "0.5rem",
});

export const infoIcon = css({
  width: "1rem",
  height: "1rem",
  marginLeft: "0.25rem",
});

export const secondaryColor = css({
  color: `${Colors.neutral[600]} !important`,
});

export const footer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.5rem",
  borderTop: `1px solid ${Colors.neutral[50]}`,
  ...textStyles.s3,
});

export const apply = (isSelected) => {
  return css({
    color: isSelected ? Colors.primary[500] : Colors.primary[100],
    cursor: "pointer",
  });
};

export const reset = css({
  color: Colors.secondary[900],
  cursor: "pointer",
});


export const option = (isActive) =>{ 
  return css({
  backgroundColor: isActive ? "rgba(196, 229, 254, 0.7)": Colors.white,
  boxShadow:isActive ? "2px 0 0 0 #008ff8 inset": "none",
  cursor: "pointer"
})};