import React, { useState, useEffect } from "react";
import { Table } from "antd";
import APIManager from "../services/APIManager";
import {
    calanderDate,
    DDMMyyyyFormateDateSet,
    exportInToCSV,
    exportToEXCEL,
    formatRankDate,
    // getCheckPermission,
    mmDDyyyyFormateDate,
    mmDDyyyyFormateForForm,
    removeHttp,
    themeStyle,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { gettabledata } from "../utility/Constant";
import { style } from "@mui/system";
import { Link } from "react-router-dom";







function ModuleExpandNewTable() {

    const [tableCol, settableCol] = useState([]);
    const [tabledata, settabledata] = useState([]);



    useEffect(() => {
        var columns = [
            {

                title: "comment",
                dataIndex: "name",
                key: "name",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.name, b.name),
                    multiple: 3,
                },
            },
            // {

            //     title: "fileName",
            //     dataIndex: "fileName",
            //     key: "fileName",
            //   },
            {

                title: "Url",
                dataIndex: "pageUrl",
                key: "pageUrl",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.pageUrl, b.pageUrl),
                    multiple: 3,
                },
            },
            {

                title: "Date",
                dataIndex: "postDate",
                key: "postDate",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.postDate, b.postDate),
                    multiple: 3,
                },
            },
        ];
        settableCol(columns);
        gettableconfig();

        // const data = [
        //     {
        //       key: '1',
        //       uId: 'John Brown',
        //       postDate: 32,
        //       status: 'New York No. 1 Lake Park',
        //     },
        //     {
        //       key: '2',
        //       name: 'Jim Green',
        //       age: 42,
        //       address: 'London No. 1 Lake Park',
        //     },
        //     {
        //       key: '3',
        //       name: 'Joe Black',
        //       age: 32,
        //       address: 'Sidney No. 1 Lake Park',
        //     },
        //     {
        //       key: '4',
        //       name: 'Disabled User',
        //       age: 99,
        //       address: 'Sidney No. 1 Lake Park',
        //     },
        //   ];
        //   settabledata(data);
    }, []);


    function gettableconfig() {
        APIManager.getAPI(
            gettabledata
        ).then((response) => {
            const list = [];
            response.data.map((item) => {
                let obj = {
                    key: item.id,
                    name: item.comment,
                    name:
                        <p className="comment">
                            <Link
                                to={"/main/bug"}
                                state={{ item }}
                            >
                                {item.comment}
                                {item.pageUrl}
                            </Link>
                        </p>,
                    comment: item.comment,
                    pageUrl: item.pageUrl,
                    postDate: item.postDate,
                    fileName: item.fileName,

                };
                list.push(obj);

            })

            settabledata(list);

            setSelectedExportList(list);


        });
    }


    const [selectionTypeKeyTable, setSelectionTypeKeyTable] =
        useState("checkbox");
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedExportList(selectedRows);
        },
    };
    const [exportList, setExportList] = useState([]);
    const [selectedExportList, setSelectedExportList] = useState([]);
    const [selectedRankTrendsExportList, setSelectedRankTrendsExportList] =
        useState([]);
    const [ranktrendsexportList, setrankTrendsExportList] = useState([]);
    const [trendsCol, settrendsCol] = useState([]);
    const ranktrendsrowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRankTrendsExportList(selectedRows);
        },
    };
    return (
        <>
            <div className="col-md-12 ps-0 pe-0 rank-tracking">

                <div className="portlet">
                    <div className="portlet-header portlet-header-bordered">
                        <h3 className="portlet-title">Bugs</h3>
                    </div>
                    <div className="portlet-body px-0">
                        <div className="row mt-3">
                            <div className="col-md-12 table-graph-modules">
                                <div className="text-right">

                                    <span
                                        class="dropdown mt-4 ms-3"
                                        data-html2canvas-ignore
                                    >
                                        {/* {getCheckPermission("export", "modules") ? ( */}
                                        <button
                                            class="btn btn-primary dropdown-toggle"
                                            type="button"
                                            onClick={() => {
                                                exportToEXCEL(
                                                    selectedExportList?.length > 0
                                                        ? selectedExportList
                                                        : exportList,
                                                    "bug"
                                                );
                                            }}
                                        >
                                            Export
                                        </button>
                                        {/* ) : (
                                            <></>
                                        )} */}
                                        {/* <div
                                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <div
                                                onClick={() => {
                                                    exportToEXCEL(
                                                        selectedExportList?.length > 0
                                                            ? selectedExportList
                                                            : exportList,
                                                        "bug"
                                                    );
                                                }}
                                            >
                                                <a class="dropdown-item">Excel</a>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    exportInToCSV(
                                                        selectedExportList?.length > 0
                                                            ? selectedExportList
                                                            : exportList,

                                                        "bug"

                                                    );
                                                }}
                                            >
                                                <a class="dropdown-item">CSV</a>
                                            </div>
                                        </div> */}
                                    </span>
                                </div>{" "}
                                <div className="col-md-12 ps-0 pe-0">
                                    <Table
                                        className="table table-bordered table-hover mt-3 w-100 ranktracking-table d-tracking"
                                        id="datatable-1"
                                        position="fixed"
                                        columns={tableCol}
                                        rowSelection={{
                                            type: "checkbox",
                                            ...rowSelection,
                                        }}
                                        dataSource={tabledata}
                                        pagination={{
                                            position: ["bottomRight"],
                                            showSizeChanger: true,
                                        }}

                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );

}
export default ModuleExpandNewTable;