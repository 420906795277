import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  
});

export const subtitle = css({
  ...textStyles.h7,
  color: Colors.neutral[900],
  marginBottom: "2rem"
})


export const form = css({
  display: "flex",
  flexDirection: "column",
})

export const inputContainer = css({
  minHeight: "calc(100vh - 28rem)"
})

export const error = css({
  ...textStyles.b3,
  marginTop: "0.13rem",
  marginLeft: '13.75rem',
  color: Colors.error[800],
  minHeight: "2.5rem"
})

export const buttonContainer = css({
  display: "flex",
  justifyContent: "flex-end"
})

export const buttonGroup = css({
  display: "flex",
  columnGap: "0.75rem"
})
