/** @jsxImportSource @emotion/react */
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";
import LabeledInput from "../../shared/Input";
import { CircleSvg } from "../circleSvg";
import CustomTicketingButton from "../customButton2";
import { TicketSvg } from "../ticketSvg";
import { useTicketingStore } from "../ticketingStore";
import * as styles from "./styles"
import { useEffect, useState } from "react";
import DropdownInput from "../customInput";
import { DatePicker } from "@semcore/date-picker";
import DatePickerInput from "../customDatePicker";
import { Link, useLocation } from "react-router-dom";
import MoreWidget from "../customComponents/moreWidget";
import CustomDatePicker from "../customComponents/datePicker";
import DeleteModal from "../customComponents/deleteModal";
import DropDownInput from "../../shared/Dropdown/Input";
import { createSubticket, deleteSubTicket, getAllDetailsOfTicket, getMembersForSubticket, updateSubTicket } from "../../../services/ticketing";
import toast from "react-hot-toast";
import moment from "moment";


const SubTicket = () => {
    const location = useLocation();

    // STORE STATES-------------------
    const ticketDetails = useTicketingStore((state) => state.ticketDetails)
    const setTicketDetails = useTicketingStore((state) => state.setTicketDetails)
    const priorityList = useTicketingStore((state) => state.priorityList)

    const [allPriorities, setAllPriorities] = useState({})
    const [allAssignedTo, setAllAssignedTo] = useState({})
    const [allEta, setAllEta] = useState({})

    const [priorityOptions, setPriorityOptions] = useState({
        options: [],
        active: 0
    })

    const [assignedToOptions, setAssignedToOptions] = useState({
        options: [],
        active: 0
    })

    const [subjectInput, setSubjectInput] = useState('')
    const [validations, setValidations] = useState({})
    const [createTicketData, setCreateTicketData] = useState({})

    const drawerOpen = useTicketingStore((state) => state.drawerOpen)
    const setDrawerOpen = useTicketingStore((state) => state.setDrawerOpen)


    const [deleteModal, setDeleteModal] = useState({})

    // APIS
    const getTicketDetails = (id) => {
        let body = {
            user_id: localStorage.getItem("userId")
        }
        let ticketId = id ? id : location?.search?.slice(1) ? location?.search?.slice(1) : localStorage.getItem("ticketId")
        getAllDetailsOfTicket(ticketId, body)?.then((response) => {
            if (response?.status == "success") {
                let mainDetails = {}
                let subTicketsDetails = [];
                let isSubTicket = false
                let isCreator = false
                if (!response?.mainticket_data) {
                    mainDetails = response?.subticket_data[0]
                    isSubTicket = true
                } else {
                    mainDetails = response?.mainticket_data
                    subTicketsDetails = response?.subticket_data ?? []
                    isSubTicket = false
                }
                isCreator = mainDetails?.created_by == localStorage?.getItem("userId")
                localStorage.setItem("ticketId", mainDetails?.id)
                setTicketDetails({ mainDetails, subTicketsDetails, isSubTicket, isCreator })
                // getMembers(isSubTicket ? mainDetails?.mainticket_id : mainDetails?.id)
                let priorityObj = {}
                let assignObj = {}
                let etaObj = {}
                subTicketsDetails?.map((item) => {
                    priorityObj = {
                        ...priorityObj,
                        [item?.id]: item?.ticket_priority_name
                    }
                    assignObj = {
                        ...assignObj,
                        [item?.id]: item?.assigned_to_name
                    }
                    etaObj = {
                        ...etaObj,
                        [item?.id]: item?.eta
                    }
                })
                setAllPriorities(priorityObj)
                setAllAssignedTo(assignObj)
                setAllEta(etaObj)
            }
        })
    }

    const getMembers = (id) => {
        let body = {
            mainticket_id: id,
            user_id: localStorage.getItem("userId"),
            // organization_id: localStorage.getItem("organizationId"),
        }
        getMembersForSubticket(body)?.then((response) => {
            if (response?.status == "success") {
                let memberData = {
                    options: response?.data?.map((val) => { return { label: val.name, value: val.name, id: val.id } }) ?? [],
                    active: 0
                }
                setAssignedToOptions(memberData)
            }
        })
    }

    const addSubTicket = (eta) => {
        let body = {
            mainticket_id: ticketDetails?.isSubTicket ? ticketDetails?.mainDetails?.mainticket_id : ticketDetails?.mainDetails?.id,
            assigned_to: createTicketData?.assignedToId ?? "",
            subject: createTicketData?.subject,
            eta: moment(eta).format("YYYY-MM-DD"),
            ticket_priority: createTicketData?.priorityId,
            created_by: localStorage.getItem("userId")
        }
        createSubticket(body).then((response) => {
            if (response?.status == "success") {
                getTicketDetails()
                onDrawerClose("ticketCreated")
            }
        })
    }

    const updateTicket = ({ id, priorityId, assignedToId, eta }) => {
        let body = {
            subticket_id: id,
            ...(priorityId && { ticket_priority: priorityId }),
            ...(assignedToId && { assigned_to: assignedToId }),
            ...(eta && { eta: eta })
            // eta: ticketForm?.eta
        }
        updateSubTicket(localStorage?.getItem("userId"), body)?.then((response) => {
            if (response.status == "success") {
                // getTicketDetails()
                response?.data?.assignedTo && setAllAssignedTo({ ...allAssignedTo, [response?.data?.id]: response?.data?.assignedTo })
                response?.data?.ticket_priority && setAllPriorities({ ...allPriorities, [response?.data?.id]: response?.data?.ticket_priority })
                response?.data?.eta && setAllEta({ ...allEta, [response?.data?.id]: response?.data?.eta })
                toast.success("Ticket Updated Successfully")
            }
        })?.catch((error) => {
            if (error.response.data.status == "error") {
                getTicketDetails()
                if (error.response.data.errors) {
                    toast.error(error.response.data.errors);
                } else {
                    toast.error("Failed to Update Ticket");
                }
            }
        })
    }

    const deleteTicket = () => {
        let subBody = { subticket_id: deleteModal?.id }
        deleteSubTicket(localStorage?.getItem("userId"), subBody).then((response) => {
            if (response.status == "success") {
                setDeleteModal({ open: false })
                toast.success("Ticket Deleted Successfully")
                getTicketDetails()
            }
        }).catch((error) => {
            if (error.response.data.status == "error") {
                setDeleteModal({ open: false })
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error("Failed to Update Ticket");
                }
            }
        })
    }

    // UTILITY FUNCTIONS ---------------------------
    const openDrawer = (drawerName) => {
        setDrawerOpen({ open: true, drawer: drawerName })
        setCreateTicketData({})
    }

    const onDrawerClose = (action) => {
        setDrawerOpen({ ...drawerOpen, open: false, id: null, action: action })
    }

    const checkValidation = () => {
        let validForm = true;
        let validation = {}

        if (!subjectInput?.trim()?.length) {
            validForm = false
            validation["subject"] = "Name is Required."
        } else if (subjectInput?.trim()?.length < 5 || subjectInput?.trim()?.length > 60) {
            validForm = false
            validation["subject"] = "Name must be Between 5 to 60 characters."
        }

        setValidations(validation)
        return validForm;
    }


    useEffect(() => {
        getTicketDetails(drawerOpen?.id)
        let priorityData = {
            options: priorityList?.map((prio) => { return { label: prio.label, value: prio.label, id: prio.value } }) ?? [],
            active: 0
        }
        setPriorityOptions(priorityData)
        let initial = priorityList?.filter((value) => value?.label == "High")[0]
        setCreateTicketData({ priority: initial?.label, priorityId: initial?.value })
    }, [drawerOpen?.id])

    return (
        <div>
            <div>
                <div css={{ display: "flex", maxWidth: "40.18rem" }}>
                    <div css={styles.subTicketIcon}>
                        <TicketSvg color={Colors.primary[500]} />
                    </div>
                    <div>
                        <div css={styles.ticketTitle}>{ticketDetails?.mainDetails?.subject}</div>
                        <div css={styles.ticketDetails}>
                            {/* <div css={{ marginRight: "1.5rem" }}>#00001</div> */}
                            <div>
                                {/* <ul><li>26 January 2024, 12.30 PM</li></ul> */}
                                <ul><li>{ticketDetails?.mainDetails?.created_at}</li></ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div css={[styles.inputContainer, styles.main]}>
                    <LabeledInput
                        width={"100%"} hMin={"33px"}
                        placeholder={"Write a sub ticket name"}
                        value={subjectInput}
                        disable={createTicketData?.subject}
                        onChange={(e) => {
                            if (e.target.value?.length > 4 && e.target.value?.length < 61) {
                                setValidations({})
                            }
                            setSubjectInput(e.target.value)
                        }}
                        addInputStyles={styles.inputStyles()}
                        leftAddonCustom={<CircleSvg />}
                    />
                    <CustomTicketingButton
                        className={"ms-3"}
                        disabled={
                            ["Completed"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) ? true
                                : ["SOW", "SOW Adhoc"]?.includes(ticketDetails?.mainDetails?.ticket_type_name) ? true
                                    : ![ticketDetails?.mainDetails?.created_by, ticketDetails?.mainDetails?.assigned_to].includes(localStorage.getItem("userId")) ? true
                                        : subjectInput?.length == 0
                        }
                        size={3}
                        onClick={() => {
                            if (checkValidation()) {
                                setCreateTicketData({ ...createTicketData, subject: subjectInput?.trim() })
                                setSubjectInput('')
                            }
                        }}
                        buttonName={"Add Subticket"}
                        featherIcon={"plus"}
                    />
                </div>
                {validations?.subject && <div css={styles.errorMsg}>{validations?.subject}</div>}

            </div>
            <div className="mt-4 pt-4 border-top">
                <div css={styles.ticketTitle}>{`Subtickets (${ticketDetails?.subTicketsDetails?.length ?? "0"})`}</div>

                {
                    createTicketData?.subject &&
                    <div css={[styles.inputContainer, { alignItems: "center" }]}>
                        <LabeledInput
                            addStyles={styles.subjectStyle}
                            width={"100%"} hMin={"33px"}
                            value={createTicketData?.subject}
                            placeholder={"Subticket Name"}
                            addInputStyles={styles.inputStyles({ cursor: "default", ...textStyles.H1Medium })}
                            readOnly={true}
                            leftAddonCustom={<CircleSvg />}
                        />
                        <div css={
                            styles.keyword(
                                ![ticketDetails?.mainDetails?.created_by, ticketDetails?.mainDetails?.assigned_to].includes(localStorage.getItem("userId"))
                                ,
                                "7.6rem")}>
                            <DropDownInput
                                addPopperStyles={styles.selectDropDownContainer}
                                data={priorityOptions}
                                showSearchBar={false}
                                nestedTrigger={createTicketData?.priority}
                                placeholder={"Select"}
                                // placement={"right-start"}
                                handleDropdownClick={(selected, value, id) => {
                                    setCreateTicketData({ ...createTicketData, priority: value, priorityId: id })
                                    // updateTicket({ id: data?.id, priorityId: id })
                                }}
                                defaultValue={createTicketData?.priority}
                                triggerStyles={styles.priorityStyles(createTicketData?.priority)}
                            />
                        </div>
                        <div css={styles.keyword(
                            ![ticketDetails?.mainDetails?.created_by, ticketDetails?.mainDetails?.assigned_to].includes(localStorage.getItem("userId"))
                            , "11.25rem")}>
                            <DropDownInput
                                addPopperStyles={styles.selectDropDownContainer}
                                data={assignedToOptions}
                                showSearchBar={true}
                                nestedTrigger={createTicketData?.assignedTo}
                                placeholder={"Select"}
                                // placement={"right-start"}
                                handleDropdownClick={(selected, value, id) => {
                                    setCreateTicketData({ ...createTicketData, assignedTo: value, assignedToId: id })
                                    // updateTicket({ id: data?.id, assignedToId: id })
                                }}
                                onClick={() => getMembers(ticketDetails?.isSubTicket ? ticketDetails?.mainDetails?.mainticket_id : ticketDetails?.mainDetails?.id)}
                                defaultValue={createTicketData?.assignedTo}
                                triggerStyles={styles.assignedToStyles}
                            />
                        </div>
                        <CustomDatePicker
                            minDate={new Date()}
                            placeholder={"ETA"} placement={"left-end"} wMin={"10rem"} addInputStyles={textStyles.H1Medium}
                            value={createTicketData?.eta}
                            // disabled={!(createTicketData?.priority)}
                            disabled={![ticketDetails?.mainDetails?.created_by, ticketDetails?.mainDetails?.assigned_to].includes(localStorage.getItem("userId"))}
                            handleDateApply={(date) => {
                                // updateTicket({ eta: moment(date).format("YYYY-MM-DD") })
                                setCreateTicketData({ ...createTicketData, eta: date })
                                addSubTicket(date)
                            }}
                        />
                        <div>
                            {/* <FeatherIcon icon={"more-vertical"} size={20} /> */}
                            <MoreWidget dataOptions={[
                                { name: "Delete", icon: "trash-2", color: Colors.error[800], action: () => { setCreateTicketData({}) } }
                            ]} />
                        </div>
                    </div>
                }

                {ticketDetails?.subTicketsDetails?.map((data) => {
                    return (
                        <div>
                            <div css={[styles.inputContainer, { alignItems: "center" }]}>
                                <LabeledInput
                                    addStyles={styles.subjectStyle}
                                    width={"100%"} hMin={"33px"}
                                    value={data?.subject?.slice(0, -2)}
                                    placeholder={"Subticket Name"}
                                    addInputStyles={styles.inputStyles({ cursor: "default", ...textStyles.H1Medium })}
                                    readOnly={true}
                                    leftAddonCustom={<CircleSvg />}
                                    rightAddonCustom={
                                        <span
                                            onClick={() => {
                                                onDrawerClose()
                                                localStorage.setItem("ticketId", data?.id)
                                            }}
                                            css={{ color: Colors.primary[500], ...textStyles.s2, marginRight: "0.5rem" }}>
                                            {/* <a href="/tickets/ticket-details"> */}
                                            <Link to={`/tickets/${location.pathname?.split('/')[2]}/ticket-details?${data?.id}`}>
                                                View Details
                                            </Link>
                                            {/* </a> */}
                                        </span>
                                    }
                                />
                                <div css={styles.keyword(
                                    // ["SOW", "SOW Adhoc"]?.includes(data?.ticket_type_name) ? true :
                                    ticketDetails?.isCreator ?
                                        ["Completed", "Awaiting Approval"]?.includes(data?.ticket_status_name)
                                        : true, "7.6rem")}>
                                    <DropDownInput
                                        addPopperStyles={styles.selectDropDownContainer}
                                        data={priorityOptions}
                                        showSearchBar={false}
                                        nestedTrigger={allPriorities[data?.id]}
                                        placeholder={"Select"}
                                        // placement={"right-start"}
                                        handleDropdownClick={(selected, value, id) => {
                                            updateTicket({ id: data?.id, priorityId: id })
                                        }}
                                        defaultValue={allPriorities[data?.id]}
                                        triggerStyles={styles.priorityStyles(allPriorities[data?.id])}
                                    />
                                </div>
                                <div css={styles.keyword(
                                    // ["SOW", "SOW Adhoc"]?.includes(data?.ticket_type_name) ? true :
                                    ticketDetails?.isCreator ? ["Completed", "Awaiting Approval", "Picked Up", "In Progress"]?.includes(data?.ticket_status_name) : true, "11.25rem")}>
                                    <DropDownInput
                                        addPopperStyles={styles.selectDropDownContainer}
                                        data={assignedToOptions}
                                        showSearchBar={true}
                                        nestedTrigger={allAssignedTo[data?.id]}
                                        placeholder={"Select"}
                                        // placement={"right-start"}
                                        handleDropdownClick={(selected, value, id) => {
                                            updateTicket({ id: data?.id, assignedToId: id })
                                        }}
                                        onClick={() => getMembers(ticketDetails?.isSubTicket ? ticketDetails?.mainDetails?.mainticket_id : ticketDetails?.mainDetails?.id)}
                                        defaultValue={allAssignedTo[data?.id]}
                                        triggerStyles={styles.assignedToStyles}
                                    />
                                </div>
                                <div css={{
                                    ...((
                                        // ["SOW", "SOW Adhoc"]?.includes(data?.ticket_type_name) ? true :
                                        ticketDetails?.isCreator ? ["Completed", "Awaiting Approval"]?.includes(data?.ticket_status_name) : true
                                    ) && { pointerEvents: "none", opacity: "70%" })
                                }}>
                                    <CustomDatePicker
                                        minDate={new Date()}
                                        placeholder={"ETA"} placement={"left-end"} wMin={"10rem"} addInputStyles={textStyles.H1Medium}
                                        value={allEta[data?.id]}
                                        handleDateApply={(date) => {
                                            updateTicket({ id: data?.id, eta: moment(date).format("YYYY-MM-DD") })
                                        }}
                                    />
                                </div>
                                <div>
                                    <MoreWidget
                                        disabled={!data?.is_delete}
                                        dataOptions={[
                                            {
                                                name: "Delete", icon: "trash-2", color: Colors.error[800],
                                                action:
                                                    !data?.is_delete ? () => { } :
                                                        // ticketDetails?.isCreator ?
                                                        () => { setDeleteModal({ open: true, id: data?.id }) }
                                                //  : () => { }
                                            }
                                        ]} />
                                </div>
                            </div>

                        </div>
                    )
                })}

            </div>
            <DeleteModal visible={deleteModal?.open} handleModalClose={() => { setDeleteModal({ open: false }) }} handleDelete={() => { deleteTicket() }} />
        </div >
    )
}

export default SubTicket;