import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "1.5rem",
  columnGap: "1.5rem",
});

export const step = css({
  ...textStyles.h6,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "100%",
});

export const active = css({
  background: Colors.secondary[600],
  color: Colors.plainWhite,
});

export const inactive = css({
  border: `1px solid ${Colors.neutral[400]}`,
  color: Colors.neutral[600],
});

export const complete = css({
    background: Colors.semantic[500],
    color: Colors.plainWhite,
});

export const stepName = css({
  ...textStyles.h7,
  color: Colors.neutral[600],
});
export const midline = css({
  width: "3rem",
  height: "0.375rem",
  backgroundColor: Colors.neutral[50],
  borderRadius: "1rem",
});
