import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const titleStyles = css({
    backgroundColor: Colors.primary[25],
    padding: "10px 16px",
    borderRadius: "7px 7px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${Colors.neutral[50]}`
})

export const bodyStyles = css({
    backgroundColor: Colors.plainWhite,
    padding: "1rem",
    borderRadius: "0 0 7px 7px",
    border: `1px solid ${Colors.neutral[50]}`
})