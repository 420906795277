import { Progress } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import FeatherIcon from "feather-icons-react";
import {
  DDMMMyyyyFormateDateSet,
  calculateWorkCompletionPercentage,
  fixTwoPlaceDecimal
} from "../../utility/Globals"
import { useNavigate } from "react-router-dom";

function ProjectOverview(props) {
  const [options, setObject] = useState({});
  const [series, setSeries] = useState([]);
  const [projectCompleted, setProjectCompleted] = useState(0);
  const [turnaroundTime, setTurnaroundTime] = useState('');
  const [turnaroundTimePercentage, setTurnaroundTimePercetange] = useState(0);
  const [isData, setIsData] = useState(true)
  const [listData, setListData] = useState(
    [{ value: "requirementRaised", label: "Requirement Raised", dataValue: "null" },
    { value: "assignWriter", label: "Assign Writer", dataValue: 0 },
    { value: "writingInProgress", label: "Writing In Progress", dataValue: 1 },
    { value: "awaitingContentQC", label: "Awaiting Content QC", dataValue: 3 },
    { value: "assignEditor", label: "Assign Editor", dataValue: 4 },
    { value: "editingInProgress", label: "Editing In Progress", dataValue: 5 },
    { value: "awaitingContentQC1", label: "Awaiting Content QC1", dataValue: 7 },
    { value: "sentForApproval", label: "Sent For Approval", dataValue: 8 },
    { value: "articleCompleted", label: "Article Completed", dataValue: 9 },]
  )
  const navigate = useNavigate();

  useEffect(() => {
    getProjectOverviewFun();
    // setTurnaroundTimePercetange(0);
  }, [props])

  function getProjectOverviewFun() {
    setProjectCompleted(fixTwoPlaceDecimal(props?.poWidget1?.projectCompletionStatus));
    setTurnaroundTime(props?.poWidget2?.turnAroundTime);

    if (props?.poWidget2?.createdAt != null)
      setTurnaroundTimePercetange(calculateWorkCompletionPercentage(props?.poWidget2?.createdAt, props?.poWidget2?.turnAroundTime))
    else
      setTurnaroundTimePercetange(0);


    let key = Object.keys(props?.poData1);
    let value = Object.values(props?.poData1);
    let key1 = [];

    key.map((item) => {
      listData.map((item1) => {
        if (item == item1.value) {
          let labelValueArray = [];
          let labelValue = item1.label.split(" ");
          for (let i = 0; i < labelValue.length; i++) {
            labelValueArray.push(labelValue[i]);
          }
          key1.push(labelValueArray);
        }
      })
    })
    let sum = value.reduce(function (x, y) {
      return x + y;
    }, 0);

    sum === 0 ? setIsData(false) : setIsData(true)

    let chartObject = {
      title: {
        text: "",
        align: "left",
      },
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
        type: "line",
        events: {
          dataPointSelection: function (event, chartContext, config) {
            let value = key[config.dataPointIndex]
            getParentElemFun(value);
          }
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: sum === 0 ? 'bottom' : 'top', // top, center, bottom
            formatter: function (val, opts) {
              if (val === 0) {
                opts.w.config.plotOptions.bar.dataLabels.position = "bottom";
              }
              else {
                opts.w.config.plotOptions.bar.dataLabels.position = "top";
              }
              return val;
            },
          },
          columnWidth: '88%'
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -27,
        style: {
          fontSize: '12px',
          colors: ["#304758"],
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: key1,
        labels: {
          rotate: 0,
          hideOverlappingLabels: false,
        }
      },
      yaxis: {
        // max: maxChartData,
        logBase: 20,
        tickAmount: 8,
        min: 0,
        show: false,
      },
      grid: {
        show: false,
      },
    }

    setSeries([
      {
        name: "",
        data: value,
        color: "#42a6f5",
      },
    ])
    setObject(chartObject)
  }

  function getParentElemFun(value) {
    listData.map((item) => {
      if (item.value == value) {
        localStorage.setItem("requirementStatus", item.dataValue);
      }
    })
    localStorage.setItem("active", 1);
    localStorage.setItem("projectDashboardCMS", props.project.value)
    navigate('/content-main/requirement-details',{
      state: {
        projectId: localStorage.getItem("projectDashboardCMS"),
        startDate: props.dataParentToChild[0],
        endDate: props.dataParentToChild[1],
      }
    }); 
  }

  return (
    <>
      <div className="title content-project-overview project-cms">
        <div className="content-status-time bg-white">
          <div className="row p-0">
            <div className="col-md-12 d-flex align-items-center pt-2 flex-wrap completion-turnaround">
              <div className="completion-status cms_bg_border d-flex p-3  me-0 me-sm-3">
                <div className="cms-dashboard-progress me-3">
                  <Progress
                    type="circle"
                    percent={projectCompleted}
                    strokeWidth={13}
                    strokeColor={"#0691EF"}
                    showInfo={false} style={{ height: "50px" }} />
                </div>
                <div className="me-5">
                  <span className="cms_fw500 cms_fs16 cms_line-height cms_fc_dark-black">Completion Status</span>
                  <p className="cms_fs18 cms_fw600 cms_fc_black">{projectCompleted}%</p>
                </div>
              </div>
              <div className="turnaround-time cms_bg_border d-flex p-3  me-0 me-sm-3">
                <div className="cms-dashboard-progress me-3">
                  <Progress type="circle"
                    percent={turnaroundTimePercentage}
                    showInfo={false}
                    strokeWidth={13}
                    strokeColor={"#0691EF"}
                    style={{ height: "50px" }} />
                </div>
                <div>
                </div>
                <div className="me-5">
                  <span className="cms_fw500 cms_fs16 cms_line-height cms_fc_dark-black">Turnaround Time</span>
                  <p className="cms_fs18 cms_fw600 cms_fc_black">{turnaroundTime ? DDMMMyyyyFormateDateSet(turnaroundTime) : ""}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="portlet-body position-relative content-chart mt-3 px-0">
            {
              isData ?
                (<Chart
                  className="cms_overview_chart"
                  options={options}
                  series={series}
                  type="bar"
                  height="380px"
                />)
                :
                (
                  <>
                  <div className="d-block text-center poc_no_data my-4 pt-5">
                  <FeatherIcon className="inbox_img" icon="inbox" />
                              <span className="d-block text-center mt-2"> No Data</span>
                              </div>
                              </>
                )
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default ProjectOverview;