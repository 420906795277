import React from "react";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "react-circular-progressbar/dist/styles.css";
import APIManager from "../services/APIManager";
import { exportToEXCEL } from "../utility/Globals";
import {
  getSEOAudit,
  getSeoAuditList,
  getSeoDetailedAuditItem,
  getAssignedMembers,
  getAssignedMembersWithCustomer,
} from "../utility/Constant";
import { Table } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ModuleExpandSEOAuditSelection from "./ModuleExpandSEOAuditSelection";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ModuleExpandSEOAuditDetailed({ update }) {
  const navigate = useNavigate();
  const [detailedColumns, setdetailedColumns] = useState([]);
  const [seoAuditListData, setSeoAuditData] = useState([]);
  const [seoAuditSelected, setAuditSelected] = useState("");
  const [seoAuditList, setSEOAuditList] = useState([]);
  const [memberlist, setmemberlist] = useState([]);
  const [memberlist1, setmemberlist1] = useState([]);
  const [searchvlue, setsearchvlue] = useState();
  const [seoAuditListFilteredData, setSeoAuditListFilteredData] = useState([]);
  const [settings, setSettings] = useState("");
  const [exportList, setExportList] = useState([]);
  const [ogData, setOgData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const detailedTableData = [];
  let detailedFilterTableData = [];
  let stage1MemberList = [];
  let stage2MemberList = [];
  const locationforAudit = useLocation();

  useEffect(() => {
    localStorage.setItem("stage1AssignToDropDown", false);
    localStorage.setItem("stage2AssignToDropDown", false);
    getAuditList();
    localStorage.setItem(
      "seoSelectedAuditId",
      locationforAudit?.state?.seoAuditId
    );
    localStorage.setItem(
      "seoSelectedAuditName",
      locationforAudit?.state?.seoAuditName
    );
    setAuditSelected(locationforAudit?.state?.seoAuditId);
  }, []);

  const onChange = (pagination, filters, sorter, extra) => {
    setTableFilter(filters);
    filterTableData(filters, searchvlue);
  };

  function filterTableData(filters, searchvlue) {
    if (!Object.values(filters).some(x => x) && !searchvlue) {
      if (seoAuditListFilteredData.length) {
        setSeoAuditListFilteredData([]);
        setSeoAuditListFilteredData(ogData);
      } else {
        setSeoAuditData([]);
        setSeoAuditData(ogData);
      }
    }
    else {
      const array = ogData;
      let tempArray = array;
      let filterArray = [];

      if (filters?.assignedToStage1) {
        tempArray?.forEach(record => {
          if (filters?.assignedToStage1?.some((value) => record?.assignedToStage1?.props?.value == value) || record?.children?.some(x => filters?.assignedToStage1?.some(value => (x?.assignedToStage1?.props?.children?.props?.value == value)))) {
            let obj;
            let filterChildren = [];

            if (record.children?.length) {
              filterChildren = record?.children?.filter(c => (filters?.assignedToStage1?.some(value => c?.assignedToStage1?.props?.value == value)));
              obj = {
                ...
                record,
                children: filterChildren
              };
              if (!detailedFilterTableData.some(x => x.key == obj.key)) {
                detailedFilterTableData.push(obj);
              }
              if (!filterArray.some(x => x.key == obj.key)) {
                filterArray.push(obj);
              }
            }
          }
          setSeoAuditListFilteredData(filterArray);
          setSeoAuditData(filterArray);
        });
      }
      if (filters?.assignedToStage2) {
        let tempFilterArray = [];
        let temp = filterArray?.length ? filterArray : tempArray;
        temp?.forEach(record => {
          if (filters?.assignedToStage2?.some((value) => record?.assignedToStage2?.props?.children?.props?.value == value) || record?.children?.some(x => filters?.assignedToStage2?.some(value => (x?.assignedToStage2?.props?.children?.props?.children?.props?.value == value)))) {
            let obj;
            let filterChildren = [];

            if (record.children?.length) {
              filterChildren = record?.children?.filter(c => (filters?.assignedToStage2?.some(value => c?.assignedToStage2?.props?.children?.props?.value == value)));
              obj = {
                ...record,
                children: filterChildren
              };
              if (!detailedFilterTableData.some(x => x.key == obj.key)) {
                detailedFilterTableData.push(obj);
              }
              if (!tempFilterArray.some(x => x.key == obj.key)) {
                tempFilterArray.push(obj);
              }
            }
          }
          filterArray = tempFilterArray;
          setSeoAuditListFilteredData(tempFilterArray);
          setSeoAuditData(tempFilterArray);
        });
      }
      if (filters?.statusStage1) {
        let tempFilterArray = [];
        let temp = filterArray?.length ? filterArray : tempArray;
        temp?.forEach(record => {
          if (filters?.statusStage1?.some((value) => record?.statusStage1?.props?.children?.props?.children == value) || record?.children?.some(x => filters?.statusStage1?.some(value => (x?.statusStage1?.props?.children?.props?.children == value)))) {
            let obj;
            let filterChildren = [];

            if (record.children?.length) {
              filterChildren = record?.children?.filter(c => (filters?.statusStage1?.some(value => c?.statusStage1?.props?.children?.props?.children == value)));
              obj = {
                ...record,
                children: filterChildren
              };
              if (!detailedFilterTableData.some(x => x.key == obj.key)) {
                detailedFilterTableData.push(obj);
              }
              if (!tempFilterArray.some(x => x.key == obj.key)) {
                tempFilterArray.push(obj);
              }
            }
          }
          filterArray = tempFilterArray;
          setSeoAuditListFilteredData(tempFilterArray);
          setSeoAuditData(tempFilterArray);
        });
      }
      if (filters?.statusStage2) {
        let tempFilterArray = [];
        let temp = filterArray?.length ? filterArray : tempArray;
        temp?.forEach(record => {
          if (filters?.statusStage2?.some((value) => record?.statusStage2?.props?.children?.props?.children == value) || record?.children?.some(x => filters?.statusStage2?.some(value => (x?.statusStage2?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children == value)))) {
            let obj;
            let filterChildren = [];

            if (record.children?.length) {
              filterChildren = record?.children?.filter(c => (filters?.statusStage2?.some(value => c?.statusStage2?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children == value)));
              obj = {
                ...record,
                children: filterChildren
              };
              if (!detailedFilterTableData.some(x => x.key == obj.key)) {
                detailedFilterTableData.push(obj);
              }
              if (!tempFilterArray.some(x => x.key == obj.key)) {
                tempFilterArray.push(obj);
              }
            }
          }
          filterArray = tempFilterArray;
          setSeoAuditListFilteredData(tempFilterArray);
          setSeoAuditData(tempFilterArray);
        });
      }
      if (searchvlue) {
        let tempFilterArray = [];
        let temp = filterArray?.length ? filterArray : tempArray;
        temp.filter(o => {
          let filterChildren = [];
          if (o.section.toLowerCase().includes(searchvlue.toLowerCase()) ||
            (o.children?.length && o.children.some(x => x.section?.props?.children?.props?.children.toLowerCase().includes(searchvlue.toLowerCase())))) {
            if (o.children?.length) {
              filterChildren = o.children.filter(k => k.section?.props?.children?.props?.children.toLowerCase().includes(searchvlue.toLowerCase()))
            }
            let obj = {
              ...o,
              children: filterChildren
            }
            tempFilterArray.push(obj);
            return obj;
          }
        })
        filterArray = tempFilterArray;
        setSeoAuditListFilteredData(tempFilterArray);
        setSeoAuditData(tempFilterArray);
      }

    }
  }

  function getAuditList() {
    APIManager.getAPI(
      getSeoAuditList + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        setSEOAuditList(response?.data);
        getmembers();
      }
    });
  }

  function getmembers() {
    APIManager.getAPI(
      getAssignedMembers + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const stage1filterType = [];
        const stage2filterType = [];

        list.push({
          id: "null",
          name: "Select Member",
        })

        response.data.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);

          let filtertypelistobj = {
            text: item.name,
            value: item.id,
          };
          stage1filterType.push(filtertypelistobj);
          stage2filterType.push(filtertypelistobj);
        });

        let stage1Statusfilter = [
          {
            text: "Not Assigned",
            value: "Not Assigned",
          },
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Complete",
            value: "Complete",
          },
        ];

        let stage2Statusfilter = [
          {
            text: "Not Assigned",
            value: "Not Assigned",
          },
          {
            text: "Ticket Raised",
            value: "Ticket Raised",
          },
          // {
          //   text: "Pending",
          //   value: "Pending",
          // },
          {
            text: "Complete",
            value: "Complete",
          },
        ];

        setmemberlist(list);
        stage1MemberList = list;

        var columns = [
          {
            title: "",
            children: [
              {
                title: "No.",
                dataIndex: "key",
                key: "key",
                width: 80,
                fixed: "left",
              },
              {
                title: "Section",
                dataIndex: "section",
                key: "section",
                width: 350,
                fixed: "left",
              },
            ],
          },
          {
            title: "Stage 1(Identification)",
            children: [
              {
                title: "Assigned to",
                dataIndex: "assignedToStage1",
                key: "assignedToStage1",
                width: 250,
                filters: stage1filterType,
                // onFilter: (value, record) => {
                //   if (record.children.length > 0) {
                //     return Object.keys(record).some((k) => {
                //       if (Array.isArray(record[k]) == true) {
                //         var filterValue = record[k];
                //         return Object.keys(filterValue).some((i) => {
                //           var filterValueObject = filterValue[i];
                //           return Object.keys(filterValueObject).some((j) => {
                //             if (filterValueObject[j]?.props?.value != undefined) {
                //               var filterValueObjectChild = filterValueObject[j]?.props?.value;
                //               return String(filterValueObjectChild).toLowerCase().indexOf(value.toLowerCase()) >= 0;
                //             }
                //           })
                //         })
                //       }
                //     })
                //   } else {
                //     return record?.assignedToStage1?.props?.value.indexOf(value) === 0
                //   }
                // },
              },
              {
                title: "Status",
                dataIndex: "statusStage1",
                key: "statusStage1",
                width: 250,
                filters: stage1Statusfilter,
                // onFilter: (value, record) => {
                //   // if (record.children.length > 0) {
                //   return Object.keys(record).some((k) => {
                //     if (Array.isArray(record[k]) == true) {
                //       var filterValue = record[k];
                //       return Object.keys(filterValue).some((i) => {
                //         var filterValueObject = filterValue[i];
                //         return Object.keys(filterValueObject).some((j) => {
                //           if (filterValueObject[j]?.props?.children?.props?.children != undefined) {
                //             var filterValueObjectChild = filterValueObject[j]?.props?.children?.props?.children;
                //             return String(filterValueObjectChild).toLowerCase().indexOf(value.toLowerCase()) >= 0;
                //           }
                //         })
                //       })
                //     }
                //   })
                //   // } else {
                //   // return record?.statusStage1?.props?.children?.props?.children.indexOf(value) === 0
                //   // }
                // }
              },
            ],
          },
          {
            title: "Stage 2(Solution)",
            children: [
              {
                title: "Assigned to",
                dataIndex: "assignedToStage2",
                key: "assignedToStage2",
                width: 250,
                filters: stage2filterType,
                // onFilter: (value, record) => {
                //   if (record.children.length > 0) {
                //     return Object.keys(record).some((k) => {
                //       if (Array.isArray(record[k]) == true) {
                //         var filterValue = record[k];
                //         return Object.keys(filterValue).some((i) => {
                //           var filterValueObject = filterValue[i];
                //           return Object.keys(filterValueObject).some((j) => {
                //             if (filterValueObject[j]?.props?.children?.props?.value != undefined) {
                //               var filterValueObjectChild = filterValueObject[j]?.props?.children?.props?.value;
                //               return String(filterValueObjectChild).toLowerCase().indexOf(value.toLowerCase()) >= 0;
                //             }
                //           })
                //         })
                //       }
                //     })
                //   } else {
                //     return record?.assignedToStage2?.props?.children?.props?.value.indexOf(value) === 0
                //   }
                // }
              },
              {
                title: "Status",
                dataIndex: "statusStage2",
                key: "statusStage2",
                width: 250,
                filters: stage2Statusfilter,
                // onFilter: (value, record) => {
                //   // if (record.children.length > 0) {
                //   return Object.keys(record).some((k) => {
                //     if (Array.isArray(record[k]) == true) {
                //       var filterValue = record[k];
                //       return Object.keys(filterValue).some((i) => {
                //         var filterValueObject = filterValue[i];
                //         return Object.keys(filterValueObject).some((j) => {

                //           if (filterValueObject[j]?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children != undefined) {
                //             var filterValueObjectChild = filterValueObject[j]?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children;
                //             return String(filterValueObjectChild).toLowerCase().indexOf(value.toLowerCase()) >= 0;
                //           }
                //         })
                //       })
                //     }
                //   })
                //   // } else {
                //   // record?.statusStage2?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children == value
                //   // }
                // }
              },
              {
                title: "Start Date",
                dataIndex: "startDate",
                key: "startDate",
                width: 250,
              },
              {
                title: "End Date",
                dataIndex: "endDate",
                key: "endDate",
                width: 250,
              },
            ],
          },
        ];
        setdetailedColumns(columns);
        getAssignedMembersFun(list)
        // getModuleList(localStorage.getItem("seoSelectedAuditId"), list);
      }
    });
  }

  function getAssignedMembersFun(list1) {
    APIManager.getAPI(getAssignedMembersWithCustomer + localStorage.getItem("projectGId")).then((response) => {
      if (response && response.status === 200) {
        const list2 = [];
        let obj;

        list2.push({
          id: "null",
          name: "Select Member",
        });

        response.data.forEach((item) => {
          obj = {
            id: item.id,
            name: item.name,
          };
          list2.push(obj);

        });

        setmemberlist1(list2);
        stage2MemberList = list2;
        getModuleList(localStorage.getItem("seoSelectedAuditId"), list1, list2);
      }
    });
  }

  function getModuleList(id, memberlist1, memberlist2) {
    setSeoAuditData([]);
    APIManager.getAPI(getSeoDetailedAuditItem + id).then((response) => {
      if (response && response.status === 200) {
        let listData = [];
        let exportListData = [];
        let childrenData = [];
        let childrenDataArray = [];
        let count = 0;
        let moduleIndex = 0;
        let pointIndex = 0;

        response?.data.sort((a, b) =>
          a.auditModuleName.trim().toLowerCase() >
            b.auditModuleName.trim().toLowerCase()
            ? 1
            : -1
        );
        let newAuditModuleName = response.data[0].auditModuleName.trim();

        response?.data.forEach((auditModule) => {
          if (
            newAuditModuleName.trim().toLowerCase() !=
            auditModule?.auditModuleName.trim().toLowerCase()
          ) {
            let Stage1DropDownStatus = getStage1AssignDropdown(
              listData[moduleIndex]
            );
            if (Stage1DropDownStatus == true)
              listData[moduleIndex].assignedToStage1.props.style.display =
                "none";

            let Stage2DropDownStatus = getStage2AssignDropdown(
              listData[moduleIndex]
            );
            if (Stage2DropDownStatus == true)
              listData[
                moduleIndex
              ].assignedToStage2.props.children.props.style.display = "none";

            count = 0;
            pointIndex = 0;
            moduleIndex = moduleIndex + 1;
            newAuditModuleName = auditModule?.auditModuleName.trim();
            childrenData = [];
            childrenDataArray = [];
            exportListData.push("\n");
          }

          if (
            newAuditModuleName.trim().toLowerCase() ==
            auditModule?.auditModuleName.trim().toLowerCase()
          ) {
            if (auditModule?.pointTitle != null) {
              childrenData = {
                key: moduleIndex + 1 + "." + (pointIndex + 1),
                section: (
                  <>
                    <div
                      style={auditModule?.stage1Status == 0 ||
                        auditModule?.stage1Status == null ? {
                        cursor: "pointer"
                      } : { cursor: "pointer", textDecoration: "underline", }}
                      onClick={() => {
                        if (auditModule?.stage1Status == 0 ||
                          auditModule?.stage1Status == null) {
                        } else {
                          setSettings("moduleExpandSeoAuditSelection");
                          navigate("",{
                            state: {
                              seoAuditId:
                                localStorage.getItem("seoSelectedAuditId"),
                              seoAuditName: localStorage.getItem(
                                "seoSelectedAuditName"
                              ),
                              seoAuditModuleId: auditModule?.auditModuleId,
                              seoAuditModulePointId: auditModule?.pointId,
                              seoAuditModulePointTitle: auditModule?.pointTitle,
                            },
                          });
                        }

                      }}
                    >
                      {auditModule?.pointTitle}
                    </div>
                  </>
                ),
                assignedToStage1: (
                  <select
                    class="form-select"
                    aria-label="Default select example w-100"
                    value={
                      auditModule?.stage1AssignedTo
                        ? auditModule?.stage1AssignedTo
                        : "null"
                    }
                    onChange={(b) => {
                      if (b.target.value != "null") {
                        let data = {
                          Id: auditModule?.id,
                          AuditId: auditModule?.pointId,
                          AuditModuleId: auditModule?.auditModuleId,
                          Stage1AssignedTo: b.target.value,
                          Stage1Status: 1,
                          ProjectAuditDataId: auditModule?.projectAuditDataId,
                        };
                        stage1AssignTo(data);
                      }
                    }}
                  >
                    {memberlist1?.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                ),
                statusStage1: (
                  <div
                    className={
                      auditModule?.stage1Status == 0 ||
                        auditModule?.stage1Status == null
                        ? "audit-detail-notassigned-active"
                        : auditModule?.stage1Status == 1
                          ? "audit-detail-pending-active"
                          : "audit-detail-completed-active"
                    }
                  >
                    <span
                      class={
                        auditModule?.stage1Status == 0 ||
                          auditModule?.stage1Status == null
                          ? "type-notassigned"
                          : auditModule?.stage1Status == 1
                            ? "type-pending"
                            : "type-completed"
                      }
                    >
                      {auditModule?.stage1Status == 0 ||
                        auditModule?.stage1Status == null
                        ? "Not Assigned"
                        : auditModule?.stage1Status == 1
                          ? "Pending"
                          : "Complete"}
                    </span>
                  </div>
                ),
                assignedToStage2: (
                  <>
                    {auditModule?.stage1Status == 2 ? (
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={
                          auditModule?.stage2AssignedTo
                            ? auditModule?.stage2AssignedTo
                            : "null"
                        }
                        onChange={(b) => {
                          if (b.target.value != "null") {
                            let data = {
                              id: auditModule?.id,
                              AuditId: auditModule?.pointId,
                              AuditModuleId: auditModule?.auditModuleId,
                              Stage2AssignedTo: b.target.value,
                              Stage2Status: 1,
                              StartDate: new Date(), // auditModule?.startDate,
                              EndDate: new Date(), // auditModule?.endDate,
                              UserId: localStorage.getItem("userId"), // "FB293F5F-DD42-44E7-6809-08DA84277504",
                              DepartmentId:
                                localStorage.getItem("userDatadepartment"), // "7398EE69-CB9F-43D0-1C0D-08DA58C91C2C",
                              ProjectId: localStorage.getItem("projectGId"), // "68675955-987A-4168-9375-08DAA3D0CBBC",
                              ClientId: localStorage.getItem("clientGId"), // "89CE88D7-9221-4CFE-7B77-08DAA3CC91A3"
                              TicketId:
                                auditModule?.ticketId != null
                                  ? auditModule?.ticketId
                                  : "",
                            };
                            stage2AssignTo(data);
                          }
                        }}
                      >
                        {/* <option value="null">select Member</option> */}
                        {/* <option value="n/a">Not Applicable</option> */}
                        {memberlist2?.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                statusStage2: (
                  <>
                    {auditModule?.stage1Status == 2 ? (
                      <>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              auditModule?.stage2Status == 0 ||
                                auditModule?.stage2Status == null
                                ? "audit-detail-notassigned-active"
                                : auditModule?.stage2Status == 1
                                  ? "audit-detail-ticket-active"
                                  : auditModule?.stage2Status == 2
                                    ? "audit-detail-pending-active"
                                    : "audit-detail-completed-active"
                            }
                          >
                            <span
                              class={
                                auditModule?.stage2Status == 0 ||
                                  auditModule?.stage2Status == null
                                  ? "type-notassigned"
                                  : auditModule?.stage2Status == 1
                                    ? "type-ticket"
                                    : auditModule?.stage2Status == 2
                                      ? "type-pending"
                                      : "type-completed"
                              }
                            >
                              {auditModule?.stage2Status == 0 ||
                                auditModule?.stage2Status == null
                                ? "Not Assigned"
                                : auditModule?.stage2Status == 1
                                  ? "Ticket Raised"
                                  : auditModule?.stage2Status == 2
                                    ? "In Progress"
                                    : "Complete"}
                            </span>
                          </div>
                          <div
                            className="ms-3"
                            align="center"
                            style={
                              // auditModule?.stage2Status != 0 ||
                              auditModule?.stage2Status != null && auditModule?.stage2Status != 0
                                ? {
                                  display: "block",
                                  color: "#0691EF",
                                  cursor: "pointer",
                                  // textDecoration: "underline",
                                }
                                : { display: "none" }
                            }
                            onClick={() => {
                              // history.push({
                              //   // pathname: "/main/tickets/dashboard",
                              //   pathname: "/ticket/ticket-view",
                              //   state: { item: { id: auditModule?.ticketId } },
                              // });
                              localStorage.setItem("state", JSON.stringify({ item: { id: auditModule?.ticketId } }));
                            }}
                          >
                            <Link
                              target="_blank"
                              to="/ticket/ticket-view"
                            >
                              View Ticket
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                startDate: (
                  <>
                    {auditModule?.stage1Status == 2 && auditModule?.stage2AssignedTo ? (
                      auditModule?.startDate != null ? (
                        new Date(auditModule?.startDate)
                          .toISOString()
                          .slice(0, 10)
                      ) : (
                        auditModule?.startDate
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ),
                endDate: (
                  <>
                    {auditModule?.stage1Status == 2 ? (
                      auditModule?.endDate != null ? (
                        new Date(auditModule?.endDate)
                          .toISOString()
                          .slice(0, 10)
                      ) : (
                        auditModule?.endDate
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ),
              };

              if (count == 0) {
                exportListData.push({
                  key: (moduleIndex + 1).toString(),
                  srNo: (moduleIndex + 1).toString(),
                  section: auditModule?.auditModuleName,
                  assignedToStage1:
                    auditModule?.stage1AssignedTo != null
                      ? getStage1AssignToName(auditModule?.stage1AssignedTo)
                      : "null",
                  statusStage1:
                    auditModule?.stage1Status == 0 ||
                      auditModule?.stage1Status == null
                      ? "Not Assigned"
                      : auditModule?.stage1Status == 1
                        ? "Pending"
                        : "Complete",
                  assignedToStage2:
                    auditModule?.stage1Status == 2
                      ? auditModule?.stage2AssignedTo != null
                        ? getStage2AssignToName(auditModule?.stage2AssignedTo)
                        : "null"
                      : "",
                  statusStage2:
                    auditModule?.stage1Status == 2
                      ? auditModule?.stage2Status == 0 ||
                        auditModule?.stage2Status == null
                        ? "Not Assigned"
                        : auditModule?.stage2Status == 1
                          ? "Ticket Raised"
                          : auditModule?.stage2Status == 2
                            ? "In Progress"
                            : "Complete"
                      : "",
                  startDate:
                    auditModule?.stage1Status == 2
                      ? auditModule?.startDate != null
                        ? new Date(auditModule?.startDate)
                          .toISOString()
                          .slice(0, 10)
                        : auditModule?.startDate
                      : "",
                  endDate:
                    auditModule?.stage1Status == 2
                      ? auditModule?.endDate != null
                        ? new Date(auditModule?.endDate)
                          .toISOString()
                          .slice(0, 10)
                        : auditModule?.endDate
                      : "",
                  // children: childrenDataArrayExport,
                });
              }

              exportListData.push({
                key: moduleIndex + 1 + "." + (pointIndex + 1),
                srNo: moduleIndex + 1 + "." + (pointIndex + 1),
                section: auditModule?.pointTitle,
                assignedToStage1:
                  auditModule?.stage1AssignedTo != null
                    ? getStage1AssignToName(auditModule?.stage1AssignedTo)
                    : "null",
                statusStage1:
                  auditModule?.stage1Status == 0 ||
                    auditModule?.stage1Status == null
                    ? "Not Assigned"
                    : auditModule?.stage1Status == 1
                      ? "Pending"
                      : "Complete",
                assignedToStage2:
                  auditModule?.stage1Status == 2
                    ? auditModule?.stage2AssignedTo != null
                      ? getStage2AssignToName(auditModule?.stage2AssignedTo)
                      : "null"
                    : "",
                statusStage2:
                  auditModule?.stage1Status == 2
                    ? auditModule?.stage2Status == 0 ||
                      auditModule?.stage2Status == null
                      ? "Not Assigned"
                      : auditModule?.stage2Status == 1
                        ? "Ticket Raised"
                        : auditModule?.stage2Status == 2
                          ? "In Progress"
                          : "Complete"
                    : "",
                startDate:
                  auditModule?.stage1Status == 2
                    ? auditModule?.startDate != null
                      ? new Date(auditModule?.startDate)
                        .toISOString()
                        .slice(0, 10)
                      : auditModule?.startDate
                    : "",
                endDate:
                  auditModule?.stage1Status == 2
                    ? auditModule?.endDate != null
                      ? new Date(auditModule?.endDate)
                        .toISOString()
                        .slice(0, 10)
                      : auditModule?.endDate
                    : "",
              });

              childrenDataArray.push(childrenData);
              pointIndex++;
            }

            if (count == 0) {
              listData.push({
                key: (moduleIndex + 1).toString(),
                section: auditModule?.auditModuleName,
                assignedToStage1: (
                  <select
                    id="assignedToStage1DropDown"
                    style={
                      localStorage.getItem("stage1AssignToDropDown") ==
                        "true" && auditModule?.stage1Status != 0
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    class="form-select"
                    aria-label="Default select example"
                    value={
                      auditModule?.stage1AssignedTo
                        ? auditModule?.stage1AssignedTo
                        : "null"
                    }
                    onChange={(b) => {
                      if (b.target.value != "null") {
                        let data = {
                          Id: auditModule?.id,
                          AuditId: null,
                          AuditModuleId: auditModule?.auditModuleId,
                          Stage1AssignedTo: b.target.value,
                          Stage1Status: 1,
                          ProjectAuditDataId: auditModule?.projectAuditDataId,
                        };
                        stage1AssignTo(data);
                      }
                    }}
                  >
                    {/* <option value="null">select Member</option> */}
                    {/* <option value="n/a">Not Applicable</option> */}
                    {memberlist1?.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                ),
                statusStage1: (
                  <div
                  // className={
                  //   auditModule?.stage1Status == 0 ||
                  //   auditModule?.stage1Status == null
                  //     ? "audit-detail-notassigned-active"
                  //     : auditModule?.stage1Status == 1
                  //     ? "audit-detail-pending-active"
                  //     : "audit-detail-completed-active"
                  // }
                  >
                    <span
                    // class={
                    //   auditModule?.stage1Status == 0 ||
                    //   auditModule?.stage1Status == null
                    //     ? "type-notassigned"
                    //     : auditModule?.stage1Status == 1
                    //     ? "type-pending"
                    //     : "type-completed"
                    // }
                    >
                      {/* {auditModule?.stage1Status == 0 ||
                      auditModule?.stage1Status == null
                        ? "Not Assigned"
                        : auditModule?.stage1Status == 1
                        ? "Pending"
                        : "Complete"} */}
                    </span>
                  </div>
                ),
                assignedToStage2: (
                  <>
                    {auditModule?.stage1Status == 2 ? (
                      <select
                        style={
                          localStorage.getItem("stage2AssignToDropDown") ==
                            "true" && auditModule?.stage2Status != 0
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        class="form-select"
                        aria-label="Default select example"
                        value={
                          auditModule?.stage2AssignedTo
                            ? auditModule?.stage2AssignedTo
                            : "null"
                        }
                        onChange={(b) => {
                          if (b.target.value != "null") {
                            let data = {
                              id: auditModule?.id,
                              AuditId: null,
                              AuditModuleId: auditModule?.auditModuleId,
                              Stage2AssignedTo: b.target.value,
                              Stage2Status: 1,
                              StartDate: new Date(), // auditModule?.startDate,
                              EndDate: new Date(), // auditModule?.endDate,
                              UserId: localStorage.getItem("userId"), // "FB293F5F-DD42-44E7-6809-08DA84277504",
                              DepartmentId:
                                localStorage.getItem("userDatadepartment"), // "7398EE69-CB9F-43D0-1C0D-08DA58C91C2C",
                              ProjectId: localStorage.getItem("projectGId"), // "68675955-987A-4168-9375-08DAA3D0CBBC",
                              ClientId: localStorage.getItem("clientGId"), // "89CE88D7-9221-4CFE-7B77-08DAA3CC91A3",
                              TicketId:
                                auditModule?.ticketId != null
                                  ? auditModule?.ticketId
                                  : "",
                            };
                            stage2AssignTo(data);
                          }
                        }}
                      >
                        {/* <option value="null">select Member</option> */}
                        {/* <option value="n/a">Not Applicable</option> */}
                        {memberlist2?.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                statusStage2: (
                  <>
                    {auditModule?.stage1Status == 2 ? (
                      <>
                        <div className="d-flex align-items-center">
                          <div

                          >
                            <span

                            >

                            </span>
                          </div>

                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                startDate: (
                  <>

                  </>
                ),
                endDate: (
                  <>

                  </>
                ),
                children: childrenDataArray,
              });
            }
            if (childrenData.length > 0)
              listData[moduleIndex].children.push(childrenDataArray);
            count++;
          }
          if (!detailedTableData.some(x => x.key === listData[moduleIndex].key)) {
            detailedTableData.push(listData[moduleIndex]);
            setOgData(detailedTableData);
          };
        });
        setSeoAuditData(listData);
        setExportList(exportListData);
      }
    });
  }

  const search = (value) => {
    filterTableData(tableFilter, value);
  };

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      search(searchvlue);
    }
  }

  function stage1AssignTo(getdata) {
    if (getdata.Stage1AssignedTo == "n/a") {

    } else {
      APIManager.putAPI(
        getSEOAudit + "updateAuditDetailStage1",
        getdata,
        null,
        true
      ).then((response) => {
        if (response && response.status == 200) {
          setAuditSelected(localStorage.getItem("seoSelectedAuditId"));
          setSeoAuditData([]);
          setTimeout(() => {
            getModuleList(
              localStorage.getItem("seoSelectedAuditId"),
              memberlist.length > 0 ? memberlist : stage1MemberList,
              memberlist1.length > 0 ? memberlist1 : stage2MemberList,
            );
          }, 3000);
        }
      });
    }
  }

  function stage2AssignTo(getdata) {
    APIManager.putAPI(
      getSEOAudit + "updateAuditDetailStage2",
      getdata,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setAuditSelected(localStorage.getItem("seoSelectedAuditId"));
        setSeoAuditData([]);
        setTimeout(() => {
          getModuleList(
            localStorage.getItem("seoSelectedAuditId"),
            memberlist.length > 0 ? memberlist : stage1MemberList,
            memberlist1.length > 0 ? memberlist1 : stage1MemberList,
          );
        }, 3000);
      }
    });
  }

  let onClickFunction = (arg) => {
    setSettings(arg);
  };

  function getComponent() {
    if (settings == "moduleExpandSeoAuditSelection") {
      return <ModuleExpandSEOAuditSelection update={onClickFunction} />;
    }
  }

  function getStage1AssignToName(memberId) {
    let memberName = "";
    memberlist?.map((item) => {
      if (item.id == memberId) {
        memberName = item.name;
      }
    });
    return memberName;
  }

  function getStage2AssignToName(memberId) {
    let memberName = "";
    memberlist1?.map((item) => {
      if (item.id == memberId) {
        memberName = item.name;
      }
    });
    return memberName;
  }

  function getStage1AssignDropdown(listData) {
    let stage1AssignDropDownStatus = false;

    let stage1AssignedTo = listData.assignedToStage1.props.value;
    let childrenStage1AssignedTo = listData.children;

    for (var j = 0; j < childrenStage1AssignedTo.length; j++) {
      let childStage1AssignTo =
        childrenStage1AssignedTo[j].assignedToStage1.props.value;
      if (
        stage1AssignedTo != childStage1AssignTo &&
        stage1AssignedTo != null &&
        childStage1AssignTo != null &&
        listData.statusStage1.props.children.props.children != "Not Assigned" &&
        childrenStage1AssignedTo[j].statusStage1.props.children.props
          .children != "Not Assigned "
      ) {
        stage1AssignDropDownStatus = true;
        break;
      }
    }
    return stage1AssignDropDownStatus;
  }

  function getStage2AssignDropdown(listData) {
    let stage2AssignDropDownStatus = false;

    let stage2AssignedTo = listData.assignedToStage2.props.children.props.value;
    let childrenStage2AssignedTo = listData.children;

    for (var j = 0; j < childrenStage2AssignedTo.length; j++) {
      let childStage2AssignTo =
        childrenStage2AssignedTo[j].assignedToStage2.props.children.props.value;

      if (
        stage2AssignedTo != childStage2AssignTo &&
        stage2AssignedTo != null &&
        childStage2AssignTo != null &&
        listData?.statusStage2?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children != "Not Assigned" &&
        childrenStage2AssignedTo[j]?.statusStage2?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children != "Not Assigned"
      ) {
        stage2AssignDropDownStatus = true;
        break;
      }
    }
    return stage2AssignDropDownStatus;
  }

  return (
    <>
      {settings == "" ? (
        <>
          <div className="pop-over">
            <div className="portlet">
              <div class="portlet-header portlet-header-bordered">
                <svg onClick={() => {
                  update("");
                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                <h3 class="portlet-title main-title">
                  {" "}
                  {localStorage.getItem("projectGName")} - Detailed Audit
                </h3>

              </div>
              <div className="portlet-body">
                <div class="audit-name-box px-0">
                  <div class="row ">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center">
                      <div className="text-box">
                        <label
                          class="audit-detailed-title me-3" >
                          Audit name :{" "}
                        </label>
                      </div>
                      <div className="input-box flex-grow-1">
                        <div className="detail-auditname w-100">
                          {seoAuditList && seoAuditList.length > 0 ? (
                            <select
                              class="form-select "
                              required
                              onChange={(e) => {
                                localStorage.setItem(
                                  "seoSelectedAuditId",
                                  e.target.value
                                );
                                setAuditSelected(
                                  localStorage.getItem("seoSelectedAuditId")
                                );
                                seoAuditList.map((item) => {
                                  if (item.seoAuditId == e.target.value) {
                                    localStorage.setItem(
                                      "seoSelectedAuditName",
                                      item.seoAuditName
                                    );
                                  }
                                });
                                setSeoAuditData([]);
                                setTimeout(() => {
                                  getModuleList(
                                    e.target.value,
                                    memberlist.length > 0 ? memberlist : stage1MemberList,
                                    memberlist1.length > 0 ? memberlist1 : stage2MemberList,
                                  );
                                }, 3000);
                              }}
                              value={seoAuditSelected}
                            >
                              {seoAuditList.map((item) => {
                                return (
                                  <option value={item.seoAuditId}>
                                    {item.seoAuditName}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-3 audit-detailed-box">
                  <div class="row pe-3">
                    <div class="col-md-10 col-sm-12 px-0 audit-detailed-search">
                      <form class="search-box w-100 d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            search(searchvlue);
                          }}
                        >
                          <i class="fa fa-search"></i>
                        </button>
                        <input
                          value={searchvlue}
                          type="text"
                          class="w-100"
                          placeholder="Type to search"
                          name="search"
                          onKeyDown={(e) => {
                            _handleKeyDown(e);
                          }}
                          onChange={(item) => {
                            setsearchvlue(item.target.value);
                          }}
                        />
                      </form>
                    </div>
                    <div class="col-md-2 col-sm-12 px-0 filter-create-btn pe-2">
                      <div
                        class="d-flex"
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <div class="filter ms-3">
                          <div
                            class="btn btn-filter d-flex align-items-center ms-2"
                            onClick={() => {
                              search(searchvlue);
                            }}
                          >
                            <span class="ms-1">Search</span>
                          </div>
                        </div>
                        <div class="ms-2">
                          <div
                            class="btn btn-primary d-block create-export-btn"
                            onClick={() => {
                              exportToEXCEL(
                                exportList,
                                localStorage.getItem("seoSelectedAuditName")
                              );
                            }}
                          >
                            Export
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0 pt-3 pb-3 audit">
                  <div className="row">
                    {" "}
                    {/* VIEW AUDIT MODULE START */}
                    <div class="audit-detail-box p-0">
                      <Table
                        className="table table-bordered table-hover mt-4 table-wrapper detail-audit-table"
                        id="datatable-1"
                        columns={detailedColumns}
                        dataSource={
                          seoAuditListFilteredData.length == 0
                            ? seoAuditListData
                            : seoAuditListFilteredData
                        }
                        expandIconColumnIndex={1}
                        expandable={{
                          expandIcon: ({ expanded, onExpand, record }) => {
                            if (record.children == undefined) {
                              return <span></span>;
                            } else {
                              var table = document.getElementsByClassName("ant-table-tbody")[0]; // document.getElementsByTagName("table")[0];
                              if (table) {
                                for (var i = 0; i < table.rows.length; i++) {
                                  for (var j = 0; j < table.rows[i].cells.length; j++) {
                                    table.rows[i].cells[j].onclick = function () {
                                      for (var k = 0; k < table.rows.length; k++) {
                                        var elementOld1 = table?.rows[k]?.getElementsByClassName("ant-table-cell-fix-left")[0];
                                        var elementOld2 = table?.rows[k]?.getElementsByClassName("ant-table-cell-fix-left")[1];

                                        if (elementOld1 != undefined) {
                                          elementOld1.style.background = "transparent";
                                        }

                                        if (elementOld2 != undefined) {
                                          elementOld2.style.background = "transparent";
                                        }
                                      }

                                      if (this.classList.contains('ant-table-cell-fix-left')) {
                                        var element1 = this.parentNode.getElementsByClassName("ant-table-cell-fix-left")[0];
                                        var element2 = this.parentNode.getElementsByClassName("ant-table-cell-fix-left")[1];

                                        if (expanded) {
                                          element1.style.background = "transparent";
                                          element2.style.background = "transparent";
                                        } else {
                                          element1.style.background = "#B9DCFA";
                                          element2.style.background = "#B9DCFA";
                                        }
                                      }
                                    };
                                  }
                                }
                              }

                              return expanded ? (
                                <UpOutlined
                                  onClick={(e) => onExpand(record, e)}
                                />
                              ) : (
                                <DownOutlined
                                  onClick={(e) => onExpand(record, e)}
                                />
                              );
                            }
                          },
                        }}
                        pagination={{
                          position: ["bottomRight"],
                          showSizeChanger: true,
                        }}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
}

export default ModuleExpandSEOAuditDetailed;