import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";
import { Col } from "react-bootstrap";

export const mainContainer = (isFixed) => {
  return css({
    backgroundColor: Colors.plainWhite,
    boxShadow: isFixed ? "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" : "",
    borderRadius: isFixed ? 0 : "0.75rem",
  });
};

export const mainHeader = (isFixed) => {
  return css({
    backgroundColor: Colors.plainWhite,
    borderRadius: "0.75rem",
    padding: isFixed ? "0 3rem 0 2rem" : 0,
  });
};

export const optionStyles = css({
    boxShadow: "none !important",
    borderRadius: "0.38rem",
    padding: "0.5rem 1rem !important"
});

export const title = css({
  ...textStyles.h5,
  color: Colors.neutral[900],
  textAlign: "left",
  display: "block",
  whiteSpace: "nowrap",
});

export const description = css({
  ...textStyles.s1,
  color: Colors.neutral[500],
  fontWeight: 500,
  textAlign: "left",
});

//dropdown styles

export const trigger = css({
  display: "flex",
});
export const triggerText = css({
  ...textStyles.s1,
  padding: 0,
  color: Colors.primary[500],
  cursor: "pointer",
  border: "none",
});

export const icon = css({
  height: "1rem",
  width: "1rem",
  color: Colors.neutral[900],
});

export const option = css({
  ...textStyles.b3,
  padding: "0.38rem 0.5rem",
  cursor: "pointer",
  color: Colors.neutral[900],
});

export const subtitle = css({
  ...textStyles.h5,
  color: Colors.primary[500],
});
