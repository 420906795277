import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ShowPermission() {
    const navigate = useNavigate();

    useEffect(() => {        
        const params = new URLSearchParams(window.location.search);
        let rankingCampaignConfigurationId = params.get("rankingCampaignConfigurationId")       
        let accept =  params.get("accept");
        let userId = params.get("userId");        
        setTimeout(() => {
            navigate("/login" +
            "?rankingCampaignConfigurationId=" + rankingCampaignConfigurationId +
            "&accept=" + accept + "&userId=" + userId);     
        }, 3000);              
    }, []);
    return (
        <>
            <div class="message text-center" style={{ top: "50%", left: "50%" }}>
               <h4>You have no access permission.</h4> 
            </div>
        </>
    );
}
export default ShowPermission;