import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Card } from "react-bootstrap";
import APIManager from "../services/APIManager";
import {
  sendEmailApi,
  googletrendslinechart,
  googletrendsbarchart,
  getGoogleTrandGetConfig,
} from "../utility/Constant";
import { moduleGoogleTrends } from "../utility/Constant";
import {
  getMonth,
  downloadPDFfile,
  getBase64path,
  mmDDyyyyFormateDateSet,
  themeStyle,
  exportToEXCEL,
  fixTwoPlaceDecimal,
  getDaysInMonth,
  calanderDate2,
  toMonthName,
  getCheckPermission,
} from "../utility/Globals";
import { DateRangePicker } from "react-date-range";
import { Calendar, DateObject } from "react-multi-date-picker";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";
import NotFound from "../NotFound";

function ModuleExpandGoogleTrends() {
  const [options, setObject] = useState({});
  const [series, setSeries] = useState([{}]);
  const [option1, setobject1] = useState({
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
          title: {
            text: "Trend Score",
            position: "top",
            style: {
              color: "#000",
            },
          },
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    xaxis: {
      tickPlacement: "on",
    },
    yaxis: {
      logBase: 10,
      tickAmount: 5,
      min: 0,
      title: {
        text: "Sub-Region",
        style: {
          color: "#000",
        },
      },
    },
  });
  const [displayTable, setDisplayTable] = useState([]);
  const [notFound, setNotFound] = useState(true);
  useEffect(() => {
    setNotFound(getCheckPermission("google_trends", "reports"));
    localStorage.setItem("isCompareVar2", false);
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid?.toString();
    var message =
      UserId +
      " has shared the Google Trends Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    setDisplayTable([])
    getGoogleTrendData();
  }, []);
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document?.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document?.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (
          document?.getElementsByClassName("rdrDateDisplayWrapper")?.length > 0
        )
          document
            ?.getElementsByClassName("rdrDateDisplayWrapper")[0]
            ?.children[1]?.classList?.add("display-none");
      }

      if (!open) {
        document
          ?.getElementsByClassName("rdrDefinedRangesWrapper")[0]
          ?.classList.add("display-none");
      }
    };
  }, [open]);

  const [values1, setValues1] = useState([
    new Date(Date.now()).toLocaleDateString(),
    new Date(Date.now()).toLocaleDateString(),
  ]);

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [series1, setSeries1] = useState([{ data: [1, 10] }]);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Google Trends Reports");
  const [noConfig, setNoConfig] = useState(true);
  const [GoogleTrends, setGoogleTrends] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [keywordList, setKeywordList] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [ChartView, setChartView] = useState(1);
  const [selectedExportList, setSelectedExportList] = useState([]);
  let firstDayy;
  let lastDayy;
  const today = new Date();
  firstDayy = new Date(today.setDate(today.getDate() - today.getDay() - 7));
  lastDayy = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [state, setState] = useState({
    selection1: {
      startDate: firstDayy,
      endDate: lastDayy,
      key: "selection1",
    },
    selection2: {
      startDate: firstDayy,
      endDate: lastDayy,
      key: "selection2",
    },
  });

  function keywordChange(item) {
    setSelectedKeyword(item);
    let rowChartData = {
      projectId: localStorage.getItem("projectGId"),
      startDate:
        ChartView === 1
          ? mmDDyyyyFormateDateSet(state.selection1.startDate)
          : convert(values1[0]),
      endDate:
        ChartView === 1
          ? mmDDyyyyFormateDateSet(state.selection1.endDate)
          : convert(values1[1]),
      keywordId: item,
      view: ChartView,
    };
    getChartData(rowChartData, ChartView);

    let rowBarChartData = {
      projectId: localStorage.getItem("projectGId"),
      startDate:
        ChartView === 1
          ? mmDDyyyyFormateDateSet(state.selection1.startDate)
          : convert(values1[0]),
      endDate:
        ChartView === 1
          ? mmDDyyyyFormateDateSet(state.selection1.endDate)
          : convert(values1[1]),
      keywordId: item,
      view: ChartView,
    };
    getBarChartData(rowBarChartData, ChartView);
  }

  function getGoogleTrendData(list) {
    APIManager.getAPI(
      getGoogleTrandGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        setNoConfig(false);
        setNoContent(true);
        const list = [];
        response?.data?.forEach((item) => {
          let obj = {
            key: item.keyword.id,
            value: item.keyword.fieldValue,
            label: item.keyword.fieldValue,
            id: item.keyword.id,
            fieldValue: item.keyword.fieldValue,
          };
          list.push(obj);
        });
        setKeywordList(list);
        setNoContent(!response.data?.length);
        setSelectedKeyword(list[0].id);
        const d = new Date();
        let dateArry = [];
        dateArry.push(d.getMonth() + 1);
        dateArry.push(d.getFullYear());
        var daysInMonth = getDaysInMonth(d.getFullYear(), d.getMonth() + 1);
        let dateObjArr = [
          new Date(dateArry[0] + "/01/" + dateArry[1]),
          new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
        ];
        setValues(dateObjArr);
        let rowChartData = {
          projectId: localStorage.getItem("projectGId"),
          startDate: mmDDyyyyFormateDateSet(state.selection1.startDate),
          endDate: mmDDyyyyFormateDateSet(state.selection1.endDate),
          keywordId: list[0]?.id,
          view: 1,
        };
        getChartData(rowChartData, ChartView);

        let rowBarChartData = {
          projectId: localStorage.getItem("projectGId"),
          startDate: mmDDyyyyFormateDateSet(state.selection1.startDate),
          endDate: mmDDyyyyFormateDateSet(state.selection1.endDate),
          keywordId: list[0]?.id,
          view: 1,
        };
        getBarChartData(rowBarChartData, ChartView);
      } else {
        setNoConfig(false);
        setNoContent(true);
      }
    });
  }

  function setChartObject(graphSeries, graphData2, maxValue, ChartView) {
    let title;
    if (ChartView === 1) {
      title = "Weeks";
    } else {
      title = "Months";
    }
    let optiondate = {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
      },
      xaxis: {
        tickPlacement: "on",
        categories: graphSeries,
        title: {
          text: ChartView ? (ChartView === 1 ? "Week" : "Month") : "",
          style: {
            color: "rgba(0, 0, 0, 0.36)",
          },
        },
        labels: {
          formatter: function (value) {
            return toMonthName(value, title);
          },
        },
      },
      yaxis: {
        logBase: 10,
        tickAmount: 10,
        min: 0,
        max: 100,
        title: {
          text: "Trends",
          style: {
            color: "rgba(0, 0, 0, 0.36)",
          },
        },
        labels: {
          formatter: function (value) {
            return fixTwoPlaceDecimal(value);
          },
        },
      },
    };
    setObject(optiondate);
  }

  function getChartData(rowChartData, ChartView) {
    APIManager.postAPI(googletrendslinechart, rowChartData, null, true).then(
      (response) => {
        if (
          response &&
          response.status === 200 &&
          response.data !== "No Content Found"
        ) {
          let dataForGraph = [];
          let graphData2 = [];
          let graphSeries = [];
          const startDate = rowChartData.startDate;
          const endDate = rowChartData.endDate;
          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);

          for (let i = 0; i <= diffDays; i++) {
            graphSeries.push(
              getMonth(new Date(new Date(startDate).getTime() + i * 864e5))
            );
          }
          let list1 = [];
          let list2 = [];
          if (response.data.tableContent.length) {
            response.data.tableContent.forEach((j) => {
              graphData2.push(j.trendScore);
              if (ChartView === 1) {
                list1.push(j.week ? j.week : 0);
              } else {
                list2.push(j.month ? j.month : 0);
              }
              if (ChartView === 1) {
                graphSeries = list1;
              } else {
                graphSeries = list2;
              }
            });
            let maxValue =
              Math.max(...graphData2) * 0.3 + Math.max(...graphData2);
            setChartObject(graphSeries, graphData2, maxValue, ChartView);
            dataForGraph.push({
              name: "Trends",
              data: graphData2,
            });
            setSeries(dataForGraph);
          } else {
            setSeries([]);
          }
        }
      }
    );
  }

  function getBarChartData(rowBarChartData, ChartView) {
    APIManager.postAPI(googletrendsbarchart, rowBarChartData, null, true).then(
      (response) => {
        if (
          response &&
          response.status === 200 &&
          response.data !== "No Content Found"
        ) {
          let dataForGraph = [];
          let optiondate = {};
          let graphData = [];

          const startDate = rowBarChartData.startDate;
          const endDate = rowBarChartData.endDate;
          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);
          for (let i = 0; i <= diffDays; i++) { }
          let expList = [];
          response.data?.tableContent.sort((a, b) =>
            a.trendScore > b.trendScore
              ? -1
              : b.trendScore > a.trendScore
                ? 1
                : 0
          );
          response.data?.tableContent.forEach((item) => {
            if (item.trendScore !== 0) {
              graphData.push(item.trendScore);
              expList.push({
                subRegion: item.subregion,
                trendScore: item.trendScore,
              });
            }
          });

          setSelectedExportList(expList);
          let list = [];
          for (let i = 0; i < expList.length; i++) {
            list.push(expList[i].subRegion);
          }
          optiondate = {
            chart: {
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                height: "auto",
                tools: {
                  download: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  customIcons: [],
                },
              },
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                dataLabels: {
                  position: "top",
                },
              },
            },
            tooltip: {
              shared: true,
              intersect: false,
            },
            stroke: {
              show: true,
              width: 1,
              colors: ["#fff"],
            },
            xaxis: {
              labels: {
                show: false,
              },
              tickPlacement: "on",
              categories: list,
            },
            yaxis: {
              logBase: 10,
              tickAmount: 5,
              min: 0,
              title: {
                text: "Sub-Region",
                style: {
                  color: "rgba(0, 0, 0, 0.36)",
                },
              },
            },
          };
          setobject1(optiondate);
          dataForGraph.push({
            name: "trendScore",
            data: graphData,
          });
          setSeries1(dataForGraph);
        } else {
          setSeries1([]);
        }
      }
    );
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() === "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() === "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() === "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let fileBase64 = await getBase64path(image);
    let theme = "light";
    if (document.querySelector("body").classList.contains("theme-dark")) {
      theme = "dark";
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: moduleGoogleTrends,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status === 200) {
        applyBtn2.current.click();
        setReceiver("");
        setSubject("Google Trends Reports");
        setEmailMessage(EmailMessage1);
        if (theme === "dark") {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  function getClassName(view) {
    switch (view) {
      case 1:
        if (ChartView === 1) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 2:
        if (ChartView === 2) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      default:
        if (ChartView === 1) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
    }
  }

  const [dateType, setDateType] = useState(1);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function handleView(value) {
    setSeries("");
    setSeries1("");
    setChartView(value);
    setDateType(value);
    let rowChartData = {
      projectId: localStorage.getItem("projectGId"),
      startDate:
        value === 1
          ? mmDDyyyyFormateDateSet(state.selection1.startDate)
          : convert(values[0]),
      endDate:
        value === 1
          ? mmDDyyyyFormateDateSet(state.selection1.endDate)
          : convert(values[1]),
      keywordId: selectedKeyword,
      view: value,
    };
    getChartData(rowChartData, value);
    let rowBarChartData = {
      projectId: localStorage.getItem("projectGId"),
      startDate:
        value === 1
          ? mmDDyyyyFormateDateSet(state.selection1.startDate)
          : convert(values[0]),
      endDate:
        value === 1
          ? mmDDyyyyFormateDateSet(state.selection1.endDate)
          : convert(values[1]),
      keywordId: selectedKeyword,
      view: value,
    };
    getBarChartData(rowBarChartData, value);
  }

  const applyBtn2 = useRef(null);
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (<>
        {noConfig ? (
          <>
            <div class="message text-center">
              <h4>
                The Google Trends configuration is not set. <br />
                Please contact your administrator to set configuration.
              </h4>
            </div>
          </>
        ) : (
          <div className="row">
            <Tabs>
              <div className="portlet ">
                <div className="portlet-header portlet-header-bordered my-3">
                  <div className="col-md-9 col-sm-12">
                    <TabList className="nav nav-lines portlet-nav">
                      <Tab
                        className={
                          GoogleTrends
                            ? "nav-item nav-link "
                            : "nav-item nav-link  active"
                        }
                        onClick={() => {
                          setGoogleTrends(false);
                        }}
                      >
                        Trends
                      </Tab>
                    </TabList>
                  </div>

                  {!GoogleTrends ? (
                    <div
                      ref={ref}
                      class="col-md-3 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender google_trends"
                    >
                      <div className="add-new-btnw">
                        <button
                          className="btn btn-primary"
                          style={{ width: "max-content" }}
                          onClick={() => setOpen(!open)}
                        >
                          {calanderDate2(
                            ChartView === 1
                              ? mmDDyyyyFormateDateSet(state.selection1.startDate)
                              : convert(values1[0]),
                            ChartView === 1
                              ? mmDDyyyyFormateDateSet(state.selection1.endDate)
                              : convert(values1[1])
                          )}
                        </button>
                      </div>
                      {open && (
                        <div id="example-collapse-text-calendar">
                          <Card body className="daterange-picker-card  mt-2">
                            <div className="d-flex calendar-flex">
                              <div className="calendar-cols">
                                {dateType === 1 ? (
                                  <DateRangePicker
                                    onChange={(item) => {
                                      let isCompareVarNew =
                                        localStorage.getItem("isCompareVar2");
                                      let selection = {};
                                      if (isCompareVarNew === "false") {
                                        selection = {
                                          selection1: {
                                            startDate: item?.selection1.startDate,
                                            endDate: item?.selection1.endDate,
                                            key: "selection1",
                                          },
                                        };
                                      }
                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    }}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={[state?.selection1]}
                                    direction="horizontal"
                                    ariaLabels={{
                                      dateInput: {
                                        selection1: {
                                          startDate:
                                            "start date input of selction 1",
                                          endDate: "end date input of selction 1",
                                        },
                                        selection2: {
                                          startDate:
                                            "start date input of selction 2",
                                          endDate: "end date input of selction 2",
                                        },
                                      },
                                      monthPicker: "month picker",
                                      yearPicker: "year picker",
                                      prevButton: "previous month button",
                                      nextButton: "next month button",
                                    }}
                                  />
                                ) : (
                                  <Calendar
                                    maxDate={new Date()}
                                    onChange={(e) => {
                                      if (e.length > 1) {
                                        var date1 = e[0].format().split("/");
                                        var date2 = e[1].format().split("/");
                                        var daysInMonth = getDaysInMonth(
                                          date2[1],
                                          date2[0]
                                        );
                                        let dateObjArr = [
                                          new Date(date1[0] + "/01/" + date1[1]),
                                          new Date(
                                            date2[0] +
                                            "/" +
                                            daysInMonth +
                                            "/" +
                                            date2[1]
                                          ),
                                        ];
                                        setValues1(dateObjArr);
                                      }
                                    }}
                                    value={values1}
                                    onlyMonthPicker
                                    range
                                  />
                                )}
                              </div>

                              <div className="mt-3 mt-lg-0 text-center">
                                <button
                                  onClick={() => {
                                    setOpen(!open);
                                    let rowChartData = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      startDate:
                                        ChartView === 1
                                          ? mmDDyyyyFormateDateSet(
                                            state.selection1.startDate
                                          )
                                          : convert(values1[0]),
                                      endDate:
                                        ChartView === 1
                                          ? mmDDyyyyFormateDateSet(
                                            state.selection1.endDate
                                          )
                                          : convert(values1[1]),
                                      keywordId: selectedKeyword,
                                      view: ChartView,
                                    };
                                    getChartData(rowChartData, ChartView);

                                    let rowBarChartData = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      startDate:
                                        ChartView === 1
                                          ? mmDDyyyyFormateDateSet(
                                            state.selection1.startDate
                                          )
                                          : convert(values1[0]),
                                      endDate:
                                        ChartView === 1
                                          ? mmDDyyyyFormateDateSet(
                                            state.selection1.endDate
                                          )
                                          : convert(values1[1]),
                                      keywordId: selectedKeyword,
                                      view: ChartView,
                                    };
                                    getBarChartData(rowBarChartData, ChartView);
                                  }}
                                  className="btn btn-primary ms-3"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}

                      <div className="d-flex 3-icons">
                        <div className="ms-3  ">
                          <a
                            href="#"
                            className="share bar-link-icon"
                            style={{ color: "black" }}
                          >
                            <div class="dropdown">
                              <a
                                type="button"
                                ref={applyBtn2}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                href="#"
                              >
                                <i class="fa fa-share-alt" aria-hidden="true"></i>
                              </a>
                              <form class="dropdown-menu p-4 filter-dropdown">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">From</label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Sender Email Id"
                                          value={Sender}
                                          className="w-100 form-control my-2"
                                          name="Sender"
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">To</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Recipient Email Id"
                                          className="w-100 form-control my-2"
                                          value={Receiver}
                                          name="Receiver"
                                          onChange={(text) =>
                                            setReceiver(text.target.value)
                                          }
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageReceiver}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Subject</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Subject"
                                          value={subject}
                                          onChange={(text) => {
                                            setSubject(text.target.value);
                                          }}
                                          className="w-100 form-control my-2"
                                          name="Subject"
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageSubject}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Message</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10">
                                        <textarea
                                          name=""
                                          id=""
                                          cols="80"
                                          rows="7"
                                          className="w-100 form-control my-2 gscmsg"
                                          value={EmailMessage}
                                          onChange={(text) => {
                                            setEmailMessage(text.target.value);
                                          }}
                                        ></textarea>
                                        <div style={{ color: "red" }}>
                                          {Message}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2"></div>
                                      <div className="col-md-10 my-2">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            if (isValidEmailData()) {
                                              sendEmail();
                                            }
                                          }}
                                        >
                                          {" "}
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-md-12 download-filter">
                            <span
                              class="dropdown"
                              style={{
                                marginRight: 0 + "px",
                                textAlign: "right",
                                display: "block",
                              }}
                            >
                              <button
                                class="outline-btn dropdown-toggle btn btn-primary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  class="fa fa-download "
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={async () => {
                                    setLoading(true);
                                    const promise = await downloadPDFfile(
                                      image,
                                      moduleGoogleTrends
                                    );
                                    try {
                                      if (promise) {
                                        setLoading(false);
                                      } else {
                                        setLoading(false);
                                      }
                                    } catch (e) {
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  <a class="dropdown-item"
                                    href="#">PDF</a>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <TabPanel>
                <PDFExport
                  paperSize="A4"
                  margin="2cm"
                  fileName={moduleGoogleTrends}
                  ref={pdfExportComponent}
                  forcePageBreak=".page-break"
                >
                  <div ref={image}>
                    <div className="row" id="web-page-pdf">
                      <div className="col-md-12 mb-4 d-flex justify-content-end"></div>
                      {displayTable && !noContent ? (
                        <>
                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered px-0">
                              <h3 className="portlet-title"> Keyword Trends </h3>
                            </div>
                            <div className="portlet-body">
                              <div className="d-flex align-items-center justify-content-between flex-wrap px-0">
                                <div className=" d-flex align-items-center justify-content-start ">
                                  <label className="form-label me-3">
                                    Keywords
                                  </label>
                                  <select
                                    styles={themeStyle()}
                                    className="w-75"
                                    options={keywordList}
                                    value={selectedKeyword}
                                    onChange={(item) => {
                                      keywordChange(item?.target?.value);
                                    }}
                                  >
                                    {keywordList?.map((item) => {
                                      return (
                                        <option value={item?.id}>
                                          {item?.fieldValue}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="d-flex align-items-center justify-content-end header-wrap google_trend_keyword">
                                  <div class="btn-group btn-group-toggle dwm-chart">
                                    <label
                                      class={getClassName(1)}
                                      role="presentation"
                                    >
                                      <input
                                        class="btn-check"
                                        checked="checked"
                                        id="pills-profile-tab"
                                        data-toggle="pill"
                                        data-target="#pills-profile"
                                        type="radio"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                        onClick={() => {
                                          handleView(1);
                                        }}
                                      />
                                      Week
                                    </label>

                                    <label
                                      class={getClassName(2)}
                                      role="presentation"
                                    >
                                      <input
                                        class="btn-check"
                                        checked="checked"
                                        id="pills-contact-tab"
                                        data-toggle="pill"
                                        data-target="#pills-contact"
                                        type="radio"
                                        role="tab"
                                        aria-controls="pills-contact"
                                        aria-selected="false"
                                        onClick={() => {
                                          handleView(2);
                                        }}
                                      />
                                      Month
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3">
                                <Chart
                                  options={options}
                                  series={series}
                                  type="line"
                                  height="300"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered px-0">
                              <div className="row w-100">
                                <div className="d-flex align-items-center justify-content-between flex-wrap px-0">
                                  <div className=" d-flex align-items-center justify-content-start  ">
                                    <h3 className="portlet-title">
                                      Sub-Region Trends{" "}
                                    </h3>
                                  </div>
                                  <div className=" d-flex align-items-center justify-content-end sub_region_trends ">
                                    <span class="dropdown ">
                                      <button
                                        class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        onClick={() => {
                                          exportToEXCEL(
                                            selectedExportList,
                                            "Google Trends"
                                          );
                                        }}
                                      >
                                        Download full report
                                      </button>                                      
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="portlet-body px-0">
                              <div className="col-md-12 col-sm-12">
                                <div className="postn">Trend Score</div>
                                <Chart
                                  options={option1}
                                  series={series1}
                                  type="bar"
                                  marginLeft="40"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {noContent ? (
                        <>
                          <div class=" message text-center ">
                            <h4>
                              The Google Trends configuration is not set. <br />
                              Please contact your administrator to set
                              configuration.
                            </h4>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </PDFExport>
              </TabPanel>
            </Tabs>
          </div>
        )}
      </>)}
      <Loading isLoading={isLoading} />

    </>
  );
}

export default ModuleExpandGoogleTrends;
