import { configureStore } from '@reduxjs/toolkit'
import campaignsSlice from './components/rankingV3/campaigns/campaignsSlice'
import campaignDetailSlice from './components/rankingV3/createEditCampaign/campaignDetailSlice'
import rankingReportSlice from './components/rankingReportV3/rankingReportSlice'


export const store = configureStore({
  reducer: {
    campaigns: campaignsSlice,
    campaignDetails: campaignDetailSlice,
    rankingReport: rankingReportSlice
  },
})