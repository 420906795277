/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { DatePicker, MonthRangePicker } from "@semcore/ui/date-picker";
import Notice from "@semcore/ui/notice";
import Warning from "@semcore/icon/Warning/m";
import * as styles from "./styles";
import { Box, Flex } from "@semcore/ui/flex-box";
import Checkbox from "@semcore/ui/checkbox";
import CustomButton from "../../button";
import moment from "moment";

const MonthPickerRange = ({
  noticeTitle,
  noticeDescription,
  showNote = false,
  showMonthPanel,
  singleCalendar,
  hideTrigger,
  isCompare,
  onCompareClick,
  onChange,
  availableMonths,
  defaultValue,
}) => {

  const [valueRange, setValueRange] = useState(defaultValue);
  const [isChecked, setIsChecked] = useState(isCompare);
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);

  const past1month = new Date();
  past1month.setMonth(past1month.getMonth() - 1);
  const past2months = new Date();
  past2months.setMonth(past2months.getMonth() - 2);
  const past3months = new Date();
  past3months.setMonth(past3months.getMonth() - 3);
  const past6months = new Date();
  past6months.setMonth(past6months.getMonth() - 6);
  const past7months = new Date();
  past7months.setMonth(past7months.getMonth() - 7);
  const periods = [
    { children: "Last 1 month", value: [past1month, new Date()] },
    { children: "Last 3 months", value: [past3months, new Date()] },
    { children: "Last 6 months", value: [past6months, new Date()] },
    { children: "Last 7 months", value: [past7months, new Date()] },
  ];

  return (
    <Box>
      <MonthRangePicker
        value={valueRange}
        onChange={(date) => {
          if (
            moment(date[0]).format("DD MMM YY") !==
            moment(date[1]).format("DD MMM YY")
          ) {
            onChange(date);
          }
        }}
        style={{ fontSize: "24px" }}
        periods={periods}
        placement={"bottom-end"}
        visible={hideTrigger}
      >
        {!hideTrigger && (
          <MonthRangePicker.Trigger css={styles.trigger} position="relative" />
        )}
        <MonthRangePicker.Popper css={styles.popperContainer(singleCalendar)}>
          {showNote && (
            <Notice theme={"warning"} css={styles.noticeContainer}>
              <Notice.Label css={styles.noticeLabelContainer}>
                <Flex alignItems="center" gap={"0.5em"} p={"0"}>
                  <Warning css={styles.noticeIcon} />
                  <div css={styles.noticeTitle}>{noticeTitle}</div>
                </Flex>
                <Flex css={styles.noticeDescription}>{noticeDescription}</Flex>
              </Notice.Label>
            </Notice>
          )}
          <Flex
            direction={showMonthPanel ? "row" : "column"}
            columnGap={showMonthPanel ? "1rem" : "0px"}
          >
            <Box p={"1rem"}>
              <Flex w={"100%"} justifyContent="space-between">
                <MonthRangePicker.Header
                  p={0}
                  style={{
                    width: singleCalendar ? "100%" : "50%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MonthRangePicker.Prev />
                  <MonthRangePicker.Title css={styles.headerTitle} />
                  {singleCalendar && <MonthRangePicker.Next />}
                </MonthRangePicker.Header>
                {!singleCalendar && (
                  <MonthRangePicker.Header
                    p={0}
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <MonthRangePicker.Title css={styles.headerTitle} />
                    <MonthRangePicker.Next />
                  </MonthRangePicker.Header>
                )}
              </Flex>
              {/* <Flex justifyContent="center" columnGap={"2rem"}> */}
              <MonthRangePicker.Calendar>
                {(props) => {
                  if (props.endSelected) {
                    setIsApplyDisabled(false);
                  } else {
                    setIsApplyDisabled(true);
                  }
                  return props.months.map((month, index) => (
                    <MonthRangePicker.Calendar.Unit
                      {...month}
                      key={index}
                      css={styles.calendarText}
                      disabled={availableMonths?.indexOf(month.date) === -1}
                    >
                      {month.children}
                    </MonthRangePicker.Calendar.Unit>
                  ));
                }}
              </MonthRangePicker.Calendar>
              {!singleCalendar && (
                <MonthRangePicker.Calendar size="s" fontSize={"5"}>
                  {({ months }) =>
                    months.map((month, index) => (
                      <MonthRangePicker.Calendar.Unit
                        {...month}
                        key={index}
                        css={styles.calendarText}
                      >
                        {month.children}
                      </MonthRangePicker.Calendar.Unit>
                    ))
                  }
                </MonthRangePicker.Calendar>
              )}
              {/* </Flex> */}
            </Box>
            {!showMonthPanel && (
              <>
                <Flex
                  justifyContent="space-between"
                  css={styles.bottomApplyContainer}
                  p={"1rem"}
                >
                  <div css={styles.compareContainer}>
                    <Checkbox
                      checked={isCompare}
                      onChange={(checked) => onCompareClick(checked)}
                    />
                    <span>Compare</span>
                  </div>
                  <MonthRangePicker.Apply size="m" css={styles.buttonText}>
                    <CustomButton
                      text={"Apply"}
                      theme={"info"}
                      use={"primary"}
                      disabled={isApplyDisabled}
                    />
                  </MonthRangePicker.Apply>
                </Flex>
              </>
            )}
            {showMonthPanel && (
              <Flex
                direction={"column"}
                justifyContent={"space-between"}
                p={"1rem"}
                css={styles.periods}
              >
                <MonthRangePicker.Period />
                <Flex justifyContent="flex-end" columnGap={"0.5rem"}>
                  <MonthRangePicker.Apply>
                    <CustomButton
                      text={"Apply"}
                      theme={"info"}
                      use={"primary"}
                      isDisable={isApplyDisabled}
                    />
                  </MonthRangePicker.Apply>
                  <MonthRangePicker.Reset />
                </Flex>
              </Flex>
            )}
          </Flex>
        </MonthRangePicker.Popper>
      </MonthRangePicker>
    </Box>
  );
};

export default MonthPickerRange;
