import React, { useEffect, useRef, useState } from "react";
import APIManager from "../../services/APIManager";
import DatePicker from "react-multi-date-picker";
import { Table } from "antd";

import {
  getROIConfig,
  getLanguage,
  documentFileType,
  cpcConfigImport,
  GET_Server_URL,
  roiConfigImport,
  cplConfigImport,
  updateRoiCalculatorConfig,
  getcpcGSCCalculatorData,
  getcpsGAsessionData,
  getGscData,
  getGAConfig,
  getGoogleAnalyticsConfig,
} from "../../utility/Constant";
import FeatherIcon from 'feather-icons-react';

const ROICalculator = ({ update }) => {
  useEffect(() => {
    setcurrentYear(currentYear);
    const columnsCPC = [
      {
        title: "SrNo",
        dataIndex: "SrNo",
        key: "SrNo",
      },
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        key: "clicks",
      },
    ];
    setCPCColumn(columnsCPC);
    const columnsCPS = [
      {
        title: "SrNo",
        dataIndex: "SrNo",
        key: "SrNo",
      },
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
      },
      {
        title: "Session",
        dataIndex: "sessions",
        key: "sessions",
      },
    ];
    setCPSColumn(columnsCPS);

    const columnsROI = [
      {
        title: "SrNo",
        dataIndex: "SrNo",
        key: "SrNo",
      },
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    setROITabcol(columnsROI);

    const columnsCPL = [
      {
        title: "SrNo",
        dataIndex: "SrNo",
        key: "SrNo",
      },
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
      },
      {
        title: "Leads",
        dataIndex: "leads",
        key: "leads",
      },
    ];
    setCPLTabcol(columnsCPL);
    getROIData(currentYear);
    getLanguageList();
  }, []);

  const [currentYear, setcurrentYear] = useState(new Date());
  const [cpcDataSource, setcpcDataSource] = useState("");
  const [ROIDataSource, setROIDataSource] = useState("");
  const [configID, setconfigID] = useState("");
  const [ROITabcol, setROITabcol] = useState([]);
  const [ROITableData, setROITableData] = useState([]);
  const [gaTableData, setGATableData] = useState([]);
  const [CPLTabcol, setCPLTabcol] = useState([]);
  const [CPLTableData, setCPLTableData] = useState([]);
  const [CPLTableData1, setCPLTableData1] = useState([]);
  const [CPCChecked, setCPCChecked] = useState(false);
  const [ROIChecked, setROIChecked] = useState(false);
  const [CPLChecked, setCPLChecked] = useState(false);
  const [CPCOpen, setCPCOpen] = useState(false);
  const [ROIOpen, setROIOpen] = useState(false);
  const [CPLOpen, setCPLOpen] = useState(false);
  const [CPSOpen, setCPSOpen] = useState(false);
  const [CPCOpenArw, setCPCOpenArw] = useState(false);
  const [ROIOpenArw, setROIOpenArw] = useState(false);
  const [CPLOpenArw, setCPLOpenArw] = useState(false);
  const [CPSOpenArw, setCPSOpenArw] = useState(false);
  const [CPSChecked, setCPSChecked] = useState(false);
  const [ROICPCId, setROICPCId] = useState("");
  const [ROIId, setROIId] = useState("");
  const [ROITableData1, setROITableData1] = useState([]);
  const [CPLImportTbl, setCPLImportTbl] = useState(false);
  const [CPCColumn, setCPCColumn] = useState([]);
  const [CPSColumn, setCPSColumn] = useState([]);
  const [CPCTableData, setCPCTableData] = useState([]);
  const [cpcGSCtable, setcpcGSCtable] = useState([]);
  const [cpsgasessiontable, setcpsgasessiontable] = useState([]);
  const [CPCTableData1, setCPCTableData1] = useState([]);
  const inputFileRef = useRef(null);
  const inputFileRef1 = useRef(null);
  const inputFileRef2 = useRef(null);
  const [messageCPC, setMessageCPC] = useState("");
  const [messageROI, setMessageROI] = useState("");
  const [GSCflag, setGSCflag] = useState(false);
  const [gaflag, setgaflag] = useState(false);
  const [cpc6, setCpc6] = useState(false)
  const [cpc9, setCpc9] = useState(false)
  const [cpc12, setCpc12] = useState(false)
  const [roi6, setRoi6] = useState(false)
  const [roi9, setRoi9] = useState(false)
  const [roi12, setRoi12] = useState(false)
  const [cpl6, setCpl6] = useState(false)
  const [cpl9, setCpl9] = useState(false)
  const [cpl12, setCpl12] = useState(false)

  const resetFileInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };
  const resetFileInput1 = () => {
    if (inputFileRef1.current) {
      inputFileRef1.current.value = null;
    }
  };
  const resetFileInput2 = () => {
    if (inputFileRef2.current) {
      inputFileRef2.current.value = null;
    }
  };
  const onBtnClickCPC = () => {
    inputFileRef.current.click();
  };
  const onBtnClickROI = () => {
    inputFileRef1.current.click();
  };
  const onBtnClickCPL = () => {
    inputFileRef2.current.click();
    setCPLImportTbl(true);
  };
  function showHideCPCbody() {
    CPCOpen ? setCPCOpen(false) : setCPCOpen(true);
    CPCOpenArw ? setCPCOpenArw(false) : setCPCOpenArw(true);
  }
  function showHideROIbody() {
    ROIOpen ? setROIOpen(false) : setROIOpen(true);
    ROIOpenArw ? setROIOpenArw(false) : setROIOpenArw(true);
  }
  function showHideCPLbody() {
    CPLOpen ? setCPLOpen(false) : setCPLOpen(true);
    CPLOpenArw ? setCPLOpenArw(false) : setCPLOpenArw(true);
  }
  function showHideCPSbody() {
    CPSOpen ? setCPSOpen(false) : setCPSOpen(true);
    CPSOpenArw ? setCPSOpenArw(false) : setCPSOpenArw(true);
  }

  function uploadCpcFile() {
    var input = document.getElementById("cpcFile");
    cpcImport(input.files.item(0));
  }

  function cpcImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      cpcConfigImport + configID,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getROIData(currentYear);
      }
    });
  }

  function uploadROIFile() {
    var input = document.getElementById("roiFile");
    ROIImport(input.files.item(0));
  }

  function ROIImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      roiConfigImport + configID,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getROIData(currentYear);
      }
    });
  }

  function uploadCPLFile() {
    var input = document.getElementById("cplFile");
    CPLImport(input.files.item(0));
  }

  function CPLImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      cplConfigImport + configID,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getROIData(currentYear);
      }
    });
  }

  function cpcMonthClick(value) {
    let list = [];
    setCPCTableData([]);
    CPCTableData1.map((item) => {
      if (item.monthNumber <= value) {
        list.push(item);
      }
    });
    list.length ? setCPCTableData(list) : setCPCTableData([])
  }


  function roiMonthClick(value) {
    let list = [];
    setROITableData([]);
    ROITableData1.map((item) => {
      if (item.monthNumber <= value) {
        list.push(item);
      }
    });
    list.length ? setROITableData(list) : setROITableData([])
  }

  function cplMonthClick(value) {
    let list = [];
    setCPLTableData([]);
    CPLTableData1.map((item) => {
      if (item.monthNumber <= value) {
        list.push(item);
      }
    });
    list.length ? setCPLTableData(list) : setCPLTableData([])
    setCPLImportTbl(true);
  }

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString("en-US", { month: "long" });
  }

  function getConfigData() {
    APIManager.getAPI(
      getGoogleAnalyticsConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setgaflag(true);
      }
    });
  }

  function getGAData(year) {
    APIManager.postAPI(
      getGAConfig +
      "?projectId=" +
      localStorage.getItem("projectGId") +
      "&year=" +
      year
    ).then((response) => {
      if (response && response.status == 200) {
        const listGA = [];
        let j = 1;
        response.data.map((item, index) => {
          let objga = {
            key: index,
            SrNo: j,
            month: getMonthName(item.month),
            clicks: item.clicks,
            monthNumber: item.month,
          };
          listGA.push(objga);
          j++;
        });
        setGATableData(listGA);
      } else {
        setGATableData([]);
      }
    });
  }

  function getROIData(date) {
    var dateObj = date;
    APIManager.getAPI(
      getROIConfig +
      "?projectId=" +
      localStorage.getItem("projectGId") +
      "&year=" +
      dateObj.getFullYear()
    ).then((response) => {
      if (response && response.status == 200) {
        const listCPC = [];
        const listROI = [];
        const listCPL = [];
        let i = 1;
        let j = 1;
        let k = 1;
        setconfigID(response.data.id);
        setCPCChecked(response.data.isCPCMatrics);
        setROIChecked(response.data.isROIMatrics);
        setCPLChecked(response.data.isCPLMatrics);
        setCPSChecked(response.data.isCPSMatrics);
        setCPCOpen(response.data.isCPCMatrics);
        setROIOpen(response.data.isROIMatrics);
        setCPLOpen(response.data.isCPLMatrics);
        setCPSOpen(response.data.isCPSMatrics);
        //Set CPC Table
        if (response.data.roicpcMatricsConfiguration != null) {
          setcpcDataSource(response.data.roicpcMatricsConfiguration.dataSource);
          let list = response.data.roicpcMatricsConfiguration.roicpcMatricsConfigurationMappings;

          if (list.length <= 6) {
            let hasSixMonhsCpc = list.every(item => item.month <= 6 && !(item.month > 6));
            if (hasSixMonhsCpc) {
              setCpc9(false)
              setCpc12(false)
              setCpc6(true)
            } else {
              setCpc6(false)
            }
          }
          if (list.length > 6 && list.length <= 9) {
            let hasNineMonhsCpc = list.every(item => item.month <= 9 && !(item.month > 9));
            if (hasNineMonhsCpc) {
              setCpc6(false)
              setCpc12(false)
              setCpc9(true)
            } else {
              setCpc9(false)
            }
          }
          if (list.length > 9 && list.length <= 12) {
            let hasTwelveMonhsCpc = list.every(item => item.month <= 12);
            if (hasTwelveMonhsCpc) {
              setCpc6(false)
              setCpc9(false)
              setCpc12(true)
            } else {
              setCpc12(false)
            }
          }

          response.data.roicpcMatricsConfiguration.roicpcMatricsConfigurationMappings.map(
            (item, index) => {
              let objCPC = {
                key: index,
                SrNo: j,
                month: getMonthName(item.month),
                clicks: item.clicks,
                monthNumber: item.month,
              };
              listCPC.push(objCPC);
              j++;
            }
          );
        }
        else {
          setcpcDataSource("Select")
        }
        if (listCPC.length) {
          setCPCTableData(listCPC);
          setCPCTableData1(listCPC);
        }
        else {
          setCPCTableData([]);
          setCPCTableData1([]);
        }
        //Set ROI Table
        if (response.data.roiMatricsConfiguration != null) {
          setROIDataSource(response.data.roiMatricsConfiguration.dataSource);
          response.data.roiMatricsConfiguration.roiMatricsConfigurationMappings.map(
            (item, index) => {
              let objROI = {
                key: index,
                SrNo: i,
                month: getMonthName(item.month),
                revenue: item.revenue,
                monthNumber: item.month,
              };
              listROI.push(objROI);
              i++;
            }
          );
          if (listROI.length <= 6) {
            let hasSixMonthsRoi = listROI.every(item => item.monthNumber <= 6);
            if (hasSixMonthsRoi) {
              setRoi9(false)
              setRoi12(false)
              setRoi6(true)
            } else {
              setRoi6(false)
            }
          }
          if (listROI.length > 6 && listROI.length <= 9) {
            let hasNineMonthsRoi = listROI.every(item => item.monthNumber <= 9);
            if (hasNineMonthsRoi) {
              setRoi6(false)
              setRoi12(false)
              setRoi9(true)
            } else {
              setRoi9(false)
            }
          }
          if (listROI.length > 9 && listROI.length <= 12) {
            let hasTwelveMonthsRoi = listROI.every(item => item.monthNumber <= 12);
            if (hasTwelveMonthsRoi) {
              setRoi6(false)
              setRoi9(false)
              setRoi12(true)
            } else {
              setRoi12(false)
            }
          }
        }
        else {
          setROIDataSource("Select")
        }
        if (listROI.length) {
          setROITableData(listROI);
          setROITableData1(listROI);
        }
        else {
          setROITableData([]);
          setROITableData1([]);
        }
        //Set CPL Table
        if (response.data.roicplMatricsConfigurations != null) {
          let cplList = response.data.roicplMatricsConfigurations;

          if (cplList.length <= 6) {
            let hasSixMonhsCpl = cplList.every(item => item.month <= 6 && !(item.month > 6));
            if (hasSixMonhsCpl) {
              setCpl9(false)
              setCpl12(false)
              setCpl6(true)
            } else {
              setCpl6(false)
            }
          }

          if (cplList.length > 6 && cplList.length <= 9) {
            let hasNineMonhsCpl = cplList.every(item => item.month <= 9 && !(item.month > 9));
            if (hasNineMonhsCpl) {
              setCpl6(false)
              setCpl12(false)
              setCpl9(true)
            } else {
              setCpl9(false)
            }
          }

          if (cplList.length > 9 && cplList.length <= 12) {
            let hasTwelveMonhsCpl = cplList.every(item => item.month <= 12);
            if (hasTwelveMonhsCpl) {
              setCpl6(false)
              setCpl9(false)
              setCpl12(true)
            } else {
              setCpl12(false)
            }
          }
          response.data.roicplMatricsConfigurations.map((item, index) => {
            let objCPL = {
              key: index,
              SrNo: k,
              month: getMonthName(item.month),
              leads: item.leads,
              monthNumber: item.month,
            };
            listCPL.push(objCPL);
            k++;
          });
        }
        //Set id for chart
        setROICPCId(response?.data?.roicpcMatricsConfiguration?.id);
        setROIId(response?.data?.roiMatricsConfiguration?.id);

        //set Dropdown selection
        setcpcDataSource(
          response?.data?.roicpcMatricsConfiguration?.dataSource
        );
        setROIDataSource(response?.data?.roiMatricsConfiguration?.dataSource);

        getConfigData();
        if (response?.data?.isROIMatrics && response?.data?.roiMatricsConfiguration?.dataSource == 0) {
          getGAData(dateObj.getFullYear());
        }

        if (response?.data?.isCPSMatrics) {
          getcpssessiontabledata(dateObj.getFullYear());
        }
        if (response?.data?.isCPCMatrics && response?.data?.roicpcMatricsConfiguration?.dataSource == 1) {
          getGSCdata(dateObj.getFullYear());
        } else {
          setcpcGSCtable([])
        }

        if (listCPL.length) {
          setCPLTableData(listCPL);
          setCPLTableData1(listCPL);
          setCPLImportTbl(true);
        } else {
          setCPLTableData([]);
          setCPLTableData1([]);
          setCPLImportTbl(false);
        }

        resetFileInput();
        resetFileInput1();
        resetFileInput2();
      }
    });
  }

  function getGSCdata(year) {
    APIManager.getAPI(getGscData + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          setGSCflag(true);
          getcpctabledata(year);
        }
      }
    );
  }
  function getcpctabledata(year) {
    APIManager.postAPI(
      getcpcGSCCalculatorData +
      "?projectId=" +
      localStorage.getItem("projectGId") +
      "&year=" +
      year
    ).then((response) => {
      if (response && response.status == 200) {
        const listCPCGSC = [];
        let j = 1;
        response.data.map((item, index) => {
          let objCPCGSC = {
            key: index,
            SrNo: j,
            month: getMonthName(item.month),
            clicks: item.clicks,
            monthNumber: item.month,
          };
          listCPCGSC.push(objCPCGSC);
          j++;
        });
        listCPCGSC.length ? setcpcGSCtable(listCPCGSC) : setcpcGSCtable([]);
      } else {
        setcpcGSCtable([]);
      }
    });
  }
  function getcpssessiontabledata(date) {
    APIManager.postAPI(
      getcpsGAsessionData +
      "?projectId=" +
      localStorage.getItem("projectGId") +
      "&year=" + date
    ).then((response) => {
      if (response && response.status == 200) {
        const listCPC = [];
        let j = 1;
        response.data.map((item, index) => {
          let objCPC = {
            key: index,
            SrNo: j,
            month: getMonthName(item.month),
            sessions: item.sessions,
            monthNumber: item.month,
          };
          listCPC.push(objCPC);
          j++;
        });
        if (listCPC.length >= 1) {
          setcpsgasessiontable(listCPC);
        } else {
          setcpsgasessiontable([]);
        }
      }
    });
  }

  function getLanguageList() {
    APIManager.getAPI(getLanguage).then((response) => {
      if (response && response.status == 200) {
        response.data?.map((item) => {
          if (item.languageName == "English") {
          }
        });
      }
    });
  }

  function updateROIConfig() {
    let cpcData;
    let roiData;
    if (CPCChecked) {
      cpcData = document.getElementById("cpcDSList").value;
      if (cpcData == "Select") {
        setMessageCPC("Please select value.");
        return;
      }
    }

    if (ROIChecked) {
      roiData = document.getElementById("roiDSList").value;
      if (roiData == "Select") {
        setMessageROI("Please select value.");
        return;
      }
    }
    let obj = {
      Id: configID, //ConfigurationId
      Year: currentYear.getFullYear(),
      ProjectId: localStorage.getItem("projectGId"),
      IsCPCMatrics: CPCChecked ? 1 : 0,
      IsROIMatrics: ROIChecked ? 1 : 0,
      IsCPLMatrics: CPLChecked ? 1 : 0,
      IsCPSMatrics: CPSChecked ? 1 : 0,
    };

    if (CPCChecked && cpcDataSource != "Select") {
      obj = {
        ...obj,
        ROICPCMatricsConfiguration: {
          id: ROICPCId,
          DataSource: cpcDataSource,
        },
      };
    } else {
    }
    if (ROIChecked && ROIDataSource != "Select") {
      obj = {
        ...obj,
        ROIMatricsConfiguration: {
          id: ROIId,
          DataSource: ROIDataSource,
        },
      };
    } else {
    }

    APIManager.putAPI(updateRoiCalculatorConfig, obj, null, true).then(
      (response) => {
        if (response?.status == 200) {
          update("");
        }
      }
    );
  }

  return (
    <div className="custom-column-70 roicalculater_main">
      <div className="portlet common-form-fields slideInUp">
        <div className="justify-content-around">
          <div className="row">
            <div className="col-md-12">
              <div className="portlet-header portlet-header-bordered roicalculator d-flex justify-content-between flex-wrap align-item-center">
                <div className="header_roicontent d-flex align-item-center mt-3 mt-md-0">
                  <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                  <h3 className="portlet-title main-title">
                    {localStorage.getItem("projectGName")} - Return on Investment
                  </h3>
                </div>
                <div className="roicalculater_button d-flex align-item-center flex-wrap">
                  <div className="roi-datepicker mr-3 mt-3 mt-md-0">
                    <DatePicker
                      value={currentYear}
                      onChange={(e) => {
                        var date = new Date(e.year, 0);
                        // e.preventDefault();
                        setcurrentYear(date);
                        getROIData(date);
                      }}
                      onlyYearPicker
                      maxDate={new Date()}
                    />
                  </div>

                  <div className="roi-download mt-3 mt-md-0">
                    <button
                      className="btn btn-primary"
                      style={{ height: "30px" }}
                      target="_blank"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Download Template
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        onClick={() => {
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href={GET_Server_URL + "audit/CPC.xlsx"}
                        >
                          CPC
                        </a>
                      </div>
                      <div
                        onClick={() => {
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href={GET_Server_URL + "audit/ROI.xlsx"}
                        >
                          ROI
                        </a>
                      </div>
                      <div
                        onClick={() => {
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href={GET_Server_URL + "audit/CPL.xlsx"}
                        >
                          CPL
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="portlet-body roi-accordian rioaccordian_block_section">
          <div className="col-md-12 mt-3">
            <label htmlFor="">Metrics to Track</label>
          </div>

          <div
            className="accordion roi-accordian"
            id="accordionPanelsStayOpenExample"
          >
            <div className="accordion-item">
              <div className="accordion-header" id="panelsStayOpen-headingOne">
                <div className="d-flex w-100 justify-content-between roi-btn-box">
                  <button
                    className={!CPCOpen ? "accordion-button" : "accordion-button active"}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                    onClick={showHideCPCbody}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={(e) => {
                          let chk = e.target.checked;
                          setCPCChecked(chk);
                          setCPCOpen(chk);
                          setROIOpen(false);
                          setCPLOpen(false);
                        }}
                        checked={CPCChecked}
                      />
                    </div>
                    CPC
                  </button>
                  <div className="d-flex roi-acco-btn">
                    {!CPCOpenArw ? <FeatherIcon className="not-active" icon="chevron-down" /> :
                      <FeatherIcon className="active" icon="chevron-up" />}
                  </div>
                </div>
              </div>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 roi-cpc">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="cpcFile"
                        name="cpcFile"
                        multiple={false}
                        accept={documentFileType}
                        ref={inputFileRef}
                        onChange={uploadCpcFile}
                      />
                      <div className="d-flex align-items-center flex-wrap">
                        <label className="me-3">Data Source</label>
                        <select
                          className="form-select me-3"
                          aria-label="Default select example"
                          style={{ width: 50 + "%" }}
                          onChange={(e) => {
                            setMessageCPC("");
                            setcpcDataSource(e.target.value);
                          }}
                          value={cpcDataSource}
                          id="cpcDSList"
                        >
                          <option value="Select">Select</option>
                          <option value="2">Manual</option>
                          {GSCflag ? (
                            <option value="1">Google Search Console</option>
                          ) : (
                            ""
                          )}
                          <option value="0">Click Share</option>
                        </select>
                        <label className="field-required me-3 mt-2 mt-sm-0"> * </label>

                        {cpcDataSource == 2 ? (
                          <button
                            className="btn btn-primary"
                            style={{ width: 70 + "px", height: 30 + "px" }}
                            onClick={onBtnClickCPC}
                          >
                            Import
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12  month_data ">
                      {cpcDataSource == 2 ? (
                        <div className="cpc-month">
                          <button
                            className={cpc6 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setCpc6(true);
                              setCpc9(false)
                              setCpc12(false)
                              cpcMonthClick(6)
                            }
                            }
                          >
                            6 Months
                          </button>
                          <button
                            className={cpc9 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setCpc6(false);
                              setCpc9(true)
                              setCpc12(false)
                              cpcMonthClick(9)
                            }}
                          >
                            9 Months
                          </button>
                          <button
                            className={cpc12 ? "month  active" : "month"}
                            onClick={() => {
                              setCpc6(false);
                              setCpc9(false)
                              setCpc12(true)
                              cpcMonthClick(12)
                            }
                            }
                          >
                            12 Months
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {cpcDataSource == 2 ? (
                      <div className=" col-md-6 col-sm-12 cpc-table mt-3 ps-0">
                        <Table
                          className="table table-bordered  table-hover"
                          id="datatable-2"
                          columns={CPCColumn}
                          dataSource={CPCTableData}
                          pagination={false}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {cpcDataSource == 1 ? (
                      <div className="col-md-6 col-sm-12 mt-3 cpc-table ">
                        {cpcGSCtable.length ? (
                          <Table
                            className="table table-bordered  table-hover cpc-table"
                            id="datatable-2"
                            columns={CPCColumn}
                            dataSource={cpcGSCtable}
                            pagination={true}
                          />
                        ) : (
                          <>
                            <b>Note:</b>Data will be directly fetched from
                            Google Search Console.
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {cpcDataSource == 0 ? (
                      <div className="mt-3">
                        <b>Note:</b> Data will be directly fetched from Click
                        Share.
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      {" "}
                      <div style={{ color: "red" }}>{messageCPC}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="accordion-item roitable">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <div className="d-flex w-100 justify-content-between roi-btn-box">
                  <button
                    className={!ROIOpen ? "accordion-button" : "accordion-button active"}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseTwo"
                    onClick={showHideROIbody}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={(e) => {
                          var chk = e.target.checked;
                          setROIChecked(chk);
                          setROIOpen(chk);
                          setCPCOpen(false);
                          setCPLOpen(false);
                        }}
                        checked={ROIChecked}
                      />
                    </div>
                    ROI
                  </button>
                  <div className="d-flex roi-acco-btn">
                    {!ROIOpenArw ? <FeatherIcon className="not-active" icon="chevron-down" /> :
                      <FeatherIcon className="active" icon="chevron-up" />}
                  </div>
                </div>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >

                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 roi-cpc">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="roiFile"
                        name="roiFile"
                        multiple={false}
                        accept={documentFileType}
                        ref={inputFileRef1}
                        onChange={uploadROIFile}
                      />

                      <div className="d-flex align-items-center flex-wrap">
                        <label className="me-3">Data Source</label>
                        <select
                          className="form-select me-3"
                          aria-label="Default select example"
                          style={{ width: 50 + "%" }}
                          onChange={(e) => {
                            setMessageROI("");
                            setROIDataSource(e.target.value);
                          }}
                          value={ROIDataSource}
                          id="roiDSList"
                        >
                          <option value="Select">Select</option>
                          {gaflag ? (
                            <option value="0">Google Analytics</option>
                          ) : (
                            " "
                          )}
                          <option value="1">Manual</option>
                        </select>
                        <label className="field-required me-3"> * </label>
                        {ROIDataSource == 1 ? (
                          <button
                            className="btn btn-primary"
                            style={{ width: 70 + "px", height: 30 + "px" }}
                            onClick={onBtnClickROI}
                          >
                            Import
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 roi-cpc-month month_data">
                      {ROIDataSource == 1 ? (
                        <div className="cpc-month">
                          <button
                            className={roi6 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setRoi6(true)
                              setRoi9(false)
                              setRoi12(false)
                              roiMonthClick(6)
                            }}
                          >
                            6 Months
                          </button>
                          <button
                            className={roi9 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setRoi6(false)
                              setRoi9(true)
                              setRoi12(false)
                              roiMonthClick(9)
                            }}
                          >
                            9 Months
                          </button>
                          <button
                            className={roi12 ? "month active" : "month"}
                            onClick={() => {
                              setRoi6(false)
                              setRoi9(false)
                              setRoi12(true)
                              roiMonthClick(12)
                            }}
                          >
                            12 Months
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="table_rio_block">
                    {ROIDataSource == 1 ? (
                      <div className=" col-md-6 col-sm-12 mt-3 cpc-table">
                        <Table
                          className="table table-bordered table-hover"
                          id="url"
                          columns={ROITabcol}
                          dataSource={ROITableData}
                          pagination={false}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {ROIDataSource == 0 ? (
                      <div className=" col-md-6 col-sm-12 mt-3 cpc-table">
                        {gaTableData.length ? (
                          <Table
                            className="table table-bordered table-hover cpc-table"
                            id="url"
                            columns={ROITabcol}
                            dataSource={gaTableData}
                            pagination={false}
                          />
                        ) : (
                          <>
                            {" "}
                            <b>Note:</b> Data will be directly fetched from
                            Google Analytics.
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      {" "}
                      <div style={{ color: "red" }}>{messageROI}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <div className="d-flex w-100 justify-content-between roi-btn-box">
                  <button
                    className={!CPLOpen ? "accordion-button" : "accordion-button active"}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseThree"
                    onClick={showHideCPLbody}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={(e) => {
                          var chk = e.target.checked;
                          setCPLChecked(chk);
                          setCPLOpen(chk);
                          setCPCOpen(false);
                          setROIOpen(false);
                        }}
                        checked={CPLChecked}
                      />
                    </div>
                    CPL
                  </button>
                  <div className="d-flex roi-acco-btn">
                    {!CPLOpenArw ? <FeatherIcon className="not-active" icon="chevron-down" /> :
                      <FeatherIcon className="active" icon="chevron-up" />}
                  </div>
                </div>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >

                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-2">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="cplFile"
                        name="cplFile"
                        multiple={false}
                        accept={documentFileType}
                        ref={inputFileRef2}
                        onChange={uploadCPLFile}
                      />
                      <button
                        className="btn btn-primary"
                        style={{ width: 70 + "px", height: 30 + "px" }}
                        onClick={onBtnClickCPL}
                      >
                        Import
                      </button>
                    </div>

                    <div className="col-md-6 cpl month_data_three_block">
                      {CPLImportTbl == 1 ? (
                        <div className="cpl-month">
                          <button
                            className={cpl6 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setCpl6(true)
                              setCpl9(false)
                              setCpl12(false)
                              cplMonthClick(6)
                            }
                            }
                          >
                            6 Months
                          </button>
                          <button
                            className={cpl9 ? "me-3 month active" : "me-3 month"}
                            onClick={() => {
                              setCpl6(false)
                              setCpl9(true)
                              setCpl12(false)
                              cplMonthClick(9)
                            }
                            }
                          >
                            9 Months
                          </button>
                          <button
                            className={cpl12 ? "month active" : "month"}
                            onClick={() => {
                              setCpl6(false)
                              setCpl9(false)
                              setCpl12(true)
                              cplMonthClick(12)
                            }
                            }
                          >
                            12 Months
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  {CPLImportTbl == 1 ? (
                    <div className=" col-md-6 col-sm-12 my-3 cpl-table ">
                      <Table
                        className="table table-bordered table-hover"
                        id="url"
                        columns={CPLTabcol}
                        dataSource={CPLTableData}
                        pagination={false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <div className="d-flex w-100 justify-content-between roi-btn-box">
                  <button
                    className={!CPSOpen ? "accordion-button" : "accordion-button active"}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseFour"
                    onClick={showHideCPSbody}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={(e) => {
                          var chk = e.target.checked;
                          setCPSChecked(chk);
                          setCPSOpen(chk);
                          setCPCOpen(false);
                          setROIOpen(false);
                          setCPLOpen(false);
                        }}
                        checked={CPSChecked}
                      />
                    </div>
                    CPS
                  </button>
                  <div className="d-flex roi-acco-btn">
                    {!CPSOpenArw ? <FeatherIcon className="not-active" icon="chevron-down" /> :
                      <FeatherIcon className="active" icon="chevron-up" />}
                  </div>
                </div>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >

                <div className="accordion-body">
                  <div className="col-md-6 col-sm-12 cps-table">
                    {cpsgasessiontable.length ? (
                      <Table
                        className="table table-bordered  table-hover cpc-table "
                        id="datatable-2"
                        columns={CPSColumn}
                        dataSource={cpsgasessiontable}
                        pagination={false}
                      />
                    ) : (
                      <>
                        {" "}
                        <b>Note:</b> Data will be directly fetched from Google
                        Analytics.
                      </>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              className=" btn btn-primary"
              onClick={() => {
                setCPCOpen(true);
                setROIOpen(true);
                setCPLOpen(true);
                setCPSOpen(true);
                setTimeout(() => {
                  updateROIConfig();
                }, 1000);
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ROICalculator;
