import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import "antd/dist/antd.css";
import FormControl from "@mui/material/FormControl";
import { userAuth, userForgotPassword } from "../utility/Constant";
import { clearUserInformation, storeUserInformation } from "../utility/Globals";
import APIManager from "../services/APIManager";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [str, setStr] = useState("");
  const configData = require("../config.json");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passValid, setPassValid] = useState(false);
  const [userdepartment, setuserdepartment] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  let query = useQuery();
  useEffect(() => {
    setStr(query.get("session"));
    if (query.get("session") == "expired") {
      setMessage("Token is expired.");
    }

    clearUserInformation();
  }, []);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function handleKeyPress(e) {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleLogin();
    }
  }

  const inputDataValidation = () => {
    let isEmailInvalid = message1?.length ? true : false;
    let isPassInvalid = message?.length ? true : false;

    if (userId == "") {
      isEmailInvalid = true;
      setMessage1("Email is required");
    }
    if (password == "") {
      isPassInvalid = true;
      setMessage("Password is required");
    }
    if (userId?.length) {
      var pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
      // var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (pattern?.test(userId) == false) {
        setMessage1("Please enter valid Email!");
        isEmailInvalid = true;
      }
    }

    if (password?.length && password?.length > 11) {
      isPassInvalid = true;
      setMessage("Please enter valid Password!");
    }
    if (isEmailInvalid || isPassInvalid) {
      return false;
    } else {
      return true;
    }
  };

  const responseGoogleSuccess = (response) => {
    setUserId(response.profileObj.email);
    userLogin(response.profileObj.email, true);
  };

  // Error Handler
  const responseGoogleError = (response) => { };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  function handleLogin() {
    if (inputDataValidation()) {
      userLogin(
        document.getElementById("email standard-adornment-weight").value,
        false
      );
    }
  }

  function userLogin(userId, isGoogle) {
    localStorage.setItem("is1stTime", true);
    let rowData;
    if (isGoogle) {
      rowData = { email: userId };
    } else {
      rowData = {
        email: document.getElementById("email standard-adornment-weight").value,
        password: document.getElementById("password").value,
      };
    }
    let UserEmailId = document.getElementById(
      "email standard-adornment-weight"
    ).value;
    localStorage.setItem("UserEmail", UserEmailId);

    setMessage("");
    setMessage1("");

    APIManager.postAPI(userAuth, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        if (response.data.token) {
          setUserId("");
          setPassword("");
          setMessage("");
          setMessage1("");
          setuserdepartment(response.data?.userDepartment)
          localStorage.setItem("loggedInUserId", response.data?.userId);
          localStorage.setItem("loggedInOrgId", response.data?.organizationId);
          localStorage.setItem(
            "clientGId",
            response.data?.clientProjects[0]?.clientId
          );
          localStorage.setItem(
            "projectGId",
            response.data?.clientProjects[0]?.projects[0]?.projectId
          );
          storeUserInformation(response.data);
          if (response.data.isForceChangePassword) {
            navigate("/new-password-set");
          }
          else if (response.data?.userDepartment == "Content") {
            navigate("/content-main/dashboardContent");
          }
          else if (response.data?.userDepartment == "Freelancer") {
            navigate("/content-main/personalDetails");
          }
          else {
            navigate("/main/dashboard");
          }
        }
      } else {
      }
    });
  }

  function forgotPassword(email) {
    if (email?.length) {
      // var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
      if (pattern?.test(email) == false) {
        setMessage1("Please enter valid Email!");
        setMessage("");
      }

    }

    APIManager.postAPI(userForgotPassword + email, {}, null, true).then(
      (response) => {
        if (response && response.status === 200) {
          toast.success('Check your Email!');
          return;
        }
      }
    );
  }

  const [themee, setThemee] = useState("fa fa-moon");
  useEffect(() => { }, [themee]);
  function appThemeChange() {
    if (themee == "fa fa-moon") {
      localStorage.setItem("theme-variant", "dark");
      setThemee("fa fa-sun");
    } else {
      localStorage.setItem("theme-variant", "light");
      setThemee("fa fa-moon");
    }
    if (document.querySelector("body").classList.contains("theme-light")) {
      document.querySelector("body").classList.remove("theme-light");
      document.querySelector("body").classList.add("theme-dark");
    } else {
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
  }

  return (
    <>
      {/* <div class="float-btn float-btn-right">
        <button
          class="btn btn-flat-primary btn-icon mb-2"
          data-toggle="tooltip"
          data-placement="right"
          title="Change theme"
        >
          <i onClick={() => appThemeChange()} class={themee}></i>
        </button>
      </div> */}

      <div class="holder">
        <div class="wrapper pl-0 login-page">
          <div class="content ">
            <div class="container-fluid">
              <div class="row no-gutters align-items-center justify-content-center h-100">
                <div class="col-lg-8 col-xl-6">
                  <div class="portlet overflow-hidden login-border">
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <div
                          class="portlet-body d-flex flex-column justify-content-center
                     align-items-start h-100 text-white left-box"
                        >
                          <div className="logo-img text-start mb-3 ps-3">
                            <a href="">
                              {/* <img
                                class="log-img-light"
                                src="images/infidigit-dark.png"
                              />
                              <img
                                class="log-img-dark"
                                src="images/infidigit-dark.png"
                              /> */}
                              <div className="aside-title logo-text text-white">InfiGrowth</div>
                              <h4 className="text-dark font-weight-bold organicdigital mt-3">
                                Organic Digital Growth Platform
                              </h4>
                            </a>
                          </div>
                          <ul>
                            <li className="mt-3">
                              <i class="fa fa-cog" aria-hidden="true"></i>
                              <span className="ms-3">Automated Monitoring</span>
                            </li>
                            <li className="mt-3">
                              <i class="fa fa-link" aria-hidden="true"></i>
                              <span className="ms-3">Intelligent Alerts</span>
                            </li>
                            <li className="mt-3">
                              <i
                                class="fa fa-line-chart"
                                aria-hidden="true"
                              ></i>
                              <span className="ms-3">270+ SEO checkpoints</span>
                            </li>
                            <li className="mt-3">
                              <i class="fab fa-chrome" aria-hidden="true"></i>
                              <span className="ms-3">Custom Dashboards</span>
                            </li>
                            <li className="mt-3">
                              <i
                                class="fab fa-superpowers"
                                aria-hidden="true"
                              ></i>
                              <span className="ms-3">Real-time updates</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="portlet-body h-100 pt-5 ped-4 ">
                          <form id="login-form">
                            <FormControl
                              class="w-100 mr-2 ml-2 float-label float-label-lg"
                              variant="standard"
                              sx={{ m: 1, mt: 3, width: "25ch" }}
                            >
                              <div className="form-group">
                                <div class="d-flex justify-content-center mb-4">
                                  <GoogleLogin
                                    class="btn-label-primary btn btn-lg btn-widest"
                                    clientId={configData.CLIENT_ID}
                                    redirectUri={configData.redirectUri}
                                    render={(renderProps) => (
                                      <a
                                        className="text-center btn btn-label-info rounded-pill"
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                      >
                                        <i class="fab fa-google me-2"></i>
                                        Google
                                      </a>
                                    )}
                                    buttonText="Login"
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleError}
                                    cookiePolicy={"single_host_origin"}
                                  />
                                </div>
                                <div class="float-label float-label-lg position-relative">
                                  <input
                                    className={
                                      message1?.length
                                        ? "form-control form-control-lg invalid-control"
                                        : emailValid
                                          ? "form-control form-control-lg valid-control"
                                          : "form-control form-control-lg"
                                    }
                                    type="email"
                                    id="email standard-adornment-weight"
                                    name="email"
                                    placeholder="Please insert your email"
                                    value={userId}
                                    onChange={(text) => {
                                      let userId = text.target.value;
                                      setUserId(userId);
                                      if (userId?.length) {
                                        var pattern = new RegExp(
                                          /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                                        );
                                        // var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                        if (pattern?.test(userId) == false) {
                                          setMessage1(
                                            message1
                                              ? "Please enter valid Email!"
                                              : ""
                                          );
                                        } else {
                                          if (message1) {
                                            setEmailValid(true);
                                            setMessage1("");
                                          }
                                        }
                                      } else {
                                        setMessage1(message1 ? message1 : "");
                                        setEmailValid(false);
                                      }
                                    }}
                                    aria-describedby="standard-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />

                                  <label for="email">Email</label>
                                  <div
                                    class="ms-2 pt-2"
                                    style={{ color: "red" }}
                                  >
                                    {message1}
                                  </div>
                                </div>
                              </div>
                            </FormControl>

                            <FormControl
                              className="w-100 mt-3"
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                            >
                              <div class="float-label float-label-lg position-relative">
                                <input
                                  className={
                                    message?.length
                                      ? "form-control form-control-lg invalid-control"
                                      : passValid
                                        ? "form-control form-control-lg valid-control"
                                        : "form-control form-control-lg"
                                  }
                                  type={
                                    !values.showPassword ? "password" : "text"
                                  }
                                  id="password"
                                  name="password"
                                  placeholder="Please insert your password"
                                  onChange={(text) => {
                                    let userId = text.target.value;
                                    setPassword(userId);
                                    if (userId?.length) {
                                      if (userId?.length > 11) {
                                        setMessage(
                                          message
                                            ? "Please enter valid Password!"
                                            : ""
                                        );
                                      } else {
                                        if (message) {
                                          setPassValid(true);
                                          setMessage("");
                                        }
                                      }
                                    } else {
                                      setMessage(message ? message : "");
                                      setPassValid(false);
                                    }
                                  }}
                                  onKeyPress={(e) => handleKeyPress(e)}
                                />

                                <label for="password">Password</label>
                                <div
                                  className={
                                    message || passValid
                                      ? "icon-btn-invalid position-absolute"
                                      : "icon-btn position-absolute"
                                  }
                                  onClick={handleClickShowPassword}
                                >
                                  {values.showPassword ? (
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                  ) : (
                                    <i
                                      class="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </div>
                                <div class="ms-2 pt-2" style={{ color: "red" }}>
                                  {message}
                                </div>
                              </div>
                            </FormControl>

                            <div class="mb-4">
                              <div className="forgotpass my-3  text-end">
                                <a

                                  className="d-inline-block"
                                  onClick={() => {
                                    if (userId == "") {
                                      setMessage1("Enter your Email Id");
                                      setMessage("");
                                    } else {
                                      var pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
                                      // var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                      if (pattern?.test(userId) == false) {
                                        setMessage1("Please enter valid Email!");
                                        return;
                                      }
                                      forgotPassword(userId);
                                    }
                                  }}
                                >
                                  Forgot password?
                                </a>
                              </div>
                            </div>
                            <div>
                              <a
                                type="submit"
                                class="btn btn-label-primary btn-lg btn-block btn-pill mx-2"
                                onClick={() => {
                                  handleLogin();
                                }}
                              >
                                Login
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
