/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useEffect, useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import TimeInput from "../../customComponents/timeInputs";
import Button from '@semcore/ui/button';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CustomTimeButton from "../../customComponents/timeButton";
import CustomModal from "../../customComponents/modal";
import CustomTicketingButton from "../../customButton2";
import MoreWidget from "../../customComponents/moreWidget";
import CustomButton from "../../../shared/button";
import { useTicketingStore } from "../../ticketingStore";
import { updateTicketTime } from "../../../../services/ticketing";
import toast from "react-hot-toast";

const TimeWidget = ({ changeTicketStatus, getTicketDetails }) => {


    const ticketDetails = useTicketingStore((state) => state.ticketDetails)
    const setTicketDetails = useTicketingStore((state) => state.setTicketDetails)
    const [isLoading, setIsLoading] = useState(false)

    const [time, setTime] = useState({
        hours: "00",
        minutes: "00"
    })

    const [visible, setVisible] = useState(false)
    const [initialTime, setInitialTime] = useState({
        hrs: "",
        mins: ""
    })
    const [inputDisable, setInputDisable] = useState(true)
    const [timeStatus, setTimeStatus] = useState(null)
    const [hours, setHours] = useState("0")
    const [mins, setMins] = useState("0")

    const [inValid, setInValid] = useState(false)

    const doubleDigit = (number) => {
        if (Number(number) <= 9) {
            return `0${Number(number)}`
        } else {
            return `${number}`
        }
    }

    // const onEdit = () => {
    //     setTimeStatus("edit")
    //     setInputDisable(false)
    //     setHours(initialTime?.hrs)
    //     setMins(initialTime.mins)
    // }

    const onReset = () => {
        setTimeStatus("reset")
        setInputDisable(false)
        setInValid(false)
        // setInputDisable(false)
        setInitialTime({
            hrs: "0",
            mins: "0"
        })
        setHours("0")
        setMins("0")
    }

    const onModalClose = () => {
        setVisible(false)
        setTimeStatus()
        setInValid(false)
        setInputDisable(true)
        setHours("0")
        setMins("0")
    }

    const logTime = () => {
        let body = {
            ticket: ticketDetails?.isSubTicket ? "subticket" : "mainticket",
            ticket_id: ticketDetails?.mainDetails?.id,
            ticket_hours: hours > 0 ? `${doubleDigit(hours)}` : "00",
            ticket_mins: mins > 0 ? `${doubleDigit(mins)}` : "00",
            // ticket_time_status: (timeStatus == "edit" || timeStatus == "reset") ? "Reset" : ""
            ticket_time_status: (timeStatus == "reset") ? "Reset" : ""
        }
        updateTicketTime(localStorage.getItem("userId"), body).then((response) => {
            if (response.status == "success") {
                getTicketDetails()

                let timeStr = response?.data?.split(' ')
                let hrs = timeStr?.length > 2 ? timeStr[0] : "00"
                let min = timeStr?.length > 2 ? timeStr[2] : timeStr[0]

                setTime({
                    hours: hrs,
                    minutes: min
                })
                setVisible(false)
                setTimeStatus()
                setHours("0")
                setMins("0")
            }
            setIsLoading(false)
        })?.catch((error) => {
            setIsLoading(false)
            if (error.response.data.status == "error") {
                // getTicketDetails()
                if (error.response.data.errors) {
                    toast.error(error.response.data.errors);
                } else {
                    toast.error("Failed to Update Ticket");
                }
            }
        })
    }

    const handleOpenModal = () => {
        setVisible(true)
        setInputDisable(true)
        setInitialTime({
            hrs: Number(time?.hours),
            mins: Number(time?.minutes)
        })
        // setHours(time?.hours)
        // setMins(time?.minutes)
    }



    useEffect(() => {
        let timeStr = ticketDetails?.mainDetails?.time_log?.split(' ')
        let hours = timeStr?.length > 2 ? timeStr[0] : "00"
        let minutes = timeStr?.length > 2 ? timeStr[2] : timeStr[0]

        setTime({
            hours: hours ?? "00",
            minutes: minutes ?? "00"
        })
        setInitialTime({
            hrs: Number(hours),
            mins: Number(minutes)
        })
        // setHours(hours)
        // setMins(minutes)
    }, [ticketDetails])

    function calculateTotalTime(additionalHours = 0, additionalMinutes = 0) {
        // Convert inputs to numbers
        let initialHours = timeStatus == "reset" ? 0 : Number(time?.hours)
        let initialMinutes = timeStatus == "reset" ? 0 : Number(time?.minutes)
        additionalHours = Number(additionalHours);
        additionalMinutes = Number(additionalMinutes);

        let totalHours = initialHours + additionalHours;
        let totalMinutes = initialMinutes + additionalMinutes;

        if (totalMinutes >= 60) {
            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes %= 60;
        }

        setInitialTime({ hrs: totalHours, mins: totalMinutes })

        return { hours: totalHours, minutes: totalMinutes };
    }

    return (
        <div>
            <div css={styles.timeWidgetContainer}>
                <div css={{ width: "50%", marginRight: "0.5rem" }}>
                    <div onClick={() => { handleOpenModal() }} css={{ ...textStyles.s2Medium, textDecoration: "underline", cursor: "pointer" }}>Time Taken</div>
                    <div css={{ ...textStyles.b3, color: Colors.neutral[500], marginTop: "0.3rem" }}>Total time taken to
                        complete the task</div>
                </div>
                <div css={{}}>
                    <div key={time} css={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <div css={styles.timeInput}>{doubleDigit(time?.hours)}</div>
                            <div css={{ ...textStyles.b3, color: Colors.neutral[500] }}>Hrs</div>
                        </div>
                        <div css={styles.inputSeperator}>:</div>
                        <div>

                            <div css={styles.timeInput}>{doubleDigit(time?.minutes)}</div>
                            <div css={{ ...textStyles.b3, color: Colors.neutral[500] }}>Min</div>
                        </div>
                    </div>

                </div>
            </div>
            <div css={{ display: "flex", marginTop: "1rem", justifyContent: "space-between" }}>
                <CustomTimeButton className={"me-2"} name={"Log Time"} icon={"clock"} width={"50%"} onClick={() => { handleOpenModal() }} />
                <CustomTimeButton name={"Close Ticket"} icon={"check"} width={"50%"} theme={"success"}
                    lock={!(Number(initialTime?.hrs) > 0 || Number(initialTime?.mins) > 0)}
                    onClick={
                        // !(Number(initialTime?.hrs) > 0 || Number(initialTime?.mins) > 0) ? () => { toast.error("Please log time to Close ticket") } :
                        () => {
                            changeTicketStatus("Awaiting Approval")
                        }} />
            </div>

            <CustomModal width={"36.5rem"} visible={visible} handleModalClose={() => { onModalClose() }}>
                <div>
                    <div css={[styles.timeModalSection, styles.timeInputSection, { ...textStyles.H1125SemiBold }]}>
                        <div>Time Logged</div>
                        <div css={{ display: "flex" }}>
                            <span>{initialTime?.hrs}<span css={{ color: Colors.neutral[500] }}>{` hrs : `}</span>{initialTime?.mins}<span css={{ color: Colors.neutral[500] }}>{` min`}</span></span>
                            <MoreWidget
                                iconSize={16}
                                show={Number(initialTime?.hrs) > 0 || Number(initialTime?.mins) > 0}
                                dataOptions={[
                                    // { name: "Edit", icon: "edit", color: Colors.black, action: () => { onEdit() } },
                                    { name: "Reset", icon: "rotate-cw", color: Colors.error[800], action: () => { onReset() } }
                                ]}
                            />
                        </div>
                    </div>
                    <div css={[styles.timeModalSection, { padding: "2rem 1rem", }]}>
                        <div css={styles.timeInputSection} className="pb-2">
                            <div>
                                <div css={{ ...textStyles.h6 }}>{timeStatus == "edit" ? "Edit Time" : "Enter Time"}</div>
                                <div css={{ ...textStyles.b3 }}>Enter time you want to log</div>
                            </div>
                            <div autoFocus={false}>
                                <TimeInput
                                    // disabled={inputDisable}
                                    hours={hours} mins={mins}
                                    onHoursChange={(e) => {
                                        setHours(e)
                                        calculateTotalTime(e, mins)
                                        setInputDisable(false)
                                    }}
                                    onMinsChange={(e) => {
                                        if (Number(e) > 59) {
                                            setInValid(true)
                                        } else {
                                            setInValid(false)
                                        }
                                        calculateTotalTime(hours, e)
                                        setMins(e)
                                        setInputDisable(false)
                                    }}
                                    minState={inValid ? "invalid" : null}
                                    maxState={null}
                                />
                            </div>
                        </div>
                        {inValid && <div css={styles.errorMsg}>{"Error: Minutes value need to be less than 60"}</div>}
                    </div>
                    <div css={styles.modalFooter}>
                        {/* <CustomTicketingButton
                            className={"ms-1"}
                            customStyles={{ height: "2.5rem !important" }}
                            size={5}
                            disabled={!Number(hours) > 0 && !Number(mins) > 0}
                            buttonName={timeStatus == "edit" ? "Update Logged Time" : "Log Time"}
                            onClick={() => { logTime() }}
                        /> */}
                        <div className="ms-1">
                            <CustomButton
                                isLoading={isLoading}
                                use={"primary"}
                                text={timeStatus == "edit" ? "Update Logged Time" : "Log Time"}
                                isDisable={
                                    inValid || inputDisable
                                    // (!Number(hours) > 0 && !Number(mins) > 0)
                                    // || (timeStatus !== "reset" && (Number(hours) == Number(time?.hours) && Number(mins) == Number(time?.minutes)))
                                }

                                onPress={() => {
                                    setIsLoading(true)
                                    !isLoading && logTime()
                                }}
                                size={"m"}
                            />
                        </div>
                        <div css={styles.secondaryButton}>
                            <CustomButton onPress={() => { onModalClose() }} size={"m"} text={"Close"} />
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default TimeWidget;