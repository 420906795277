import React, { useEffect, useState } from "react";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  createBacklink,
  getBacklinkConfigurationProjectId,
  getCompetitorsByProjectId,
  getUrlByOrgId,
} from "../../utility/Constant";
import { selectType, selectAllList, themeStyle } from "../../utility/Globals";

const BackLinksCount = ({ update }) => {
  const [selectType1, setSelectType1] = useState("");
  const [Comprtitorsselected, setCompetitorsSelected] = useState([]);
  const [selectType2, setSelectType2] = useState("");
  const [urlByProject, seturlByProject] = useState([]);
  const [competitorByProject, setcompetitorByProject] = useState([]);
  const [competitorsList, setCompetitorsList] = useState([]);
  const [urlList, setURlList] = useState([]);

  useEffect(() => {
    getUrlList(localStorage.getItem("projectGId"));
    getCompetitorsList(localStorage.getItem("projectGId"));
    getBacklinkCountDataa();
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function getBacklinkCountDataa() {
    APIManager.getAPI(
      getBacklinkConfigurationProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response) {
        const Urlslist = [];
        Urlslist.push({
          key: response?.data?.urls?.id,
          id: response?.data?.urls?.id,
          label: response?.data?.urls?.fieldValue,
          value: response?.data?.urls?.fieldValue,
        });
        seturlByProject(Urlslist);
        setSelectType1(response.data.searchType);
        const keywordsList = [];
        response.data.backLinkIntersectConfigurations.map((item) => {
          keywordsList.push({
            key: item.competitors.id,
            id: item.competitors.id,
            label: item.competitors.fieldValue,
            value: item.competitors.fieldValue,
          });
          setSelectType2(item.searchType);
        });
        setcompetitorByProject(keywordsList);
      }
    });
  }

  function getUrlList(projectIdd) {
    APIManager.getAPI(getUrlByOrgId + projectIdd).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const exportList = [];
        let c = 1;
        response.data.map((item) => {
          let obj = {
            count: c,
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            url: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          };
          c++;
          list.push(obj);
          let exportObj = {
            key: item.id,
            id: item.id,
            url: item.fieldValue,
          };
          exportList.push(exportObj);
        });
        setURlList(list);
      }
    });
  }

  function getCompetitorsList(projectIdd) {
    APIManager.getAPI(getCompetitorsByProjectId + projectIdd).then(
      (response) => {
        if (response && response.status == 200) {
          const List = [];
          List.push({
            count: 0,
            key: "key",
            value: "Select All",
            label: "Select All",
            id: 0,
            Competitors: "Select All",
          });
          let c = 1;
          const exportList = [];
          response.data.map((item) => {
            let obj = {
              count: c,
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              comp: item.fieldValue,
              delete: (
                <div style={{ marginLeft: 24 + "px" }}>
                  <i class="fa fa-trash"></i>
                </div>
              ),
            };
            c++;
            List.push(obj);
            let exportObj = {
              key: item.id,
              id: item.id,
              comp: item.fieldValue,
              delete: (
                <div style={{ marginLeft: 24 + "px" }}>
                  <i class="fa fa-trash"></i>
                </div>
              ),
            };
            exportList.push(exportObj);
          });
          setCompetitorsList(List);
        }
      }
    );
  }

  function addUpdateBackLink() {
    const competitorList = [];
    competitorByProject.map((item) => {
      competitorList.push({
        CompetitorId: item.id,
        SearchType: selectType2,
      });
    });
    APIManager.postAPI(
      createBacklink,
      {
        urlId: urlByProject?.id,
        searchType: selectType1,
        ProjectId: localStorage.getItem("projectGId"),
        BackLinkIntersectConfigurations: competitorList,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        update("");
      }
    });
  }
  const [oldList1, setOldList1] = useState([]);
  return (
    <div className="custom-column-70">
      <div className="portlet common-form-fields slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("projectGName")} - Backlink Profile
          </h3>
        </div>
        <div className="portlet-body">
          <div class="row">
            <div class="col-md-6 ">
              <label className="form-label">URL</label>
              <div className="justify-content-between">
                <ReactSelect
                  styles={themeStyle()}
                  options={urlList}
                  isMult={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option }}
                  onChange={(selected) => {
                    seturlByProject(selected);
                  }}
                  value={urlByProject}
                  allowSelectAll={true}
                />
              </div>
              <div
                style={{ marginTop: 24 + "px" }}
                className="none"
                id={"UrlPS14"}
              >
                <div class="form-wrappers">
                  <label className="form-label"></label>
                  <input
                    style={{ width: 25 + "em" }}
                    type="text"
                    name=""
                    placeholder="Value"
                    id={"inputaddUrlPS14"}
                  />
                  <button
                    style={{ border: "none", background: "none" }}
                    id={"addkeywordGT1"}
                  >
                    {" "}
                    <i class="fa fa-times d-flex" aria-hidden="true"></i>{" "}
                  </button>

                  <button
                    style={{ border: "none", background: "none" }}
                    id={"addkeywordGT1"}
                  >
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-5 backlink-count">
              <label className="form-label">Select Type</label>
              <br />
              <select
               style={{ padding: "9px 10px" }}
                class="w-100 mx-1 form-select"
                value={selectType1}
                onChange={(e) => {
                  setSelectType1(e.target.value);
                }}
              >
                {selectType.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <label className="form-label">Competitors</label>
              <div className="justify-content-between">
                <ReactSelect
                  styles={themeStyle()}
                  className="w-200"
                  options={competitorsList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option }}
                  onChange={(selectedCompetitors) => {
                    setCompetitorsSelected(
                      selectAllList(
                        selectedCompetitors,
                        oldList1,
                        competitorsList
                      )
                    );
                    setOldList1(selectedCompetitors);
                  }}
                  allowSelectAll={true}
                  value={Comprtitorsselected}
                />
              </div>
            </div>
            <div class="col-md-5 backlink-count">
              <label className="form-label">Select Type</label>
              <select
                style={{ padding: "9px 10px" }}
                class="w-100 mx-1 form-select"
                value={selectType2}
                onChange={(e) => {
                  setSelectType2(e.target.value);
                }}
              >
                {selectType.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>

            <div class="col-md-1 d-flex align-items-center"></div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div class="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdateBackLink();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackLinksCount;
