import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";
import { Home } from "feather-icons-react/build/IconComponents";

export const mainContainer = (isActive, isVisible) =>
  css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.75rem",
    backgroundColor: isActive ? "#0691EF" : "transparent",
    color: isActive ? "#ffffff" : "#757575",
    borderRadius: "0.38rem",
    ":hover": {
      backgroundColor: !isActive ? "#E2F1FC" : "#0691EF",
      color: !isActive?"#424242":"#ffffff",
    },
    cursor: "pointer !important",
    opacity: isVisible ? 1 : 0,
    transition: "all 0.2s ease-in-out",
  });
export const icon = (isActive) =>
  css({
    height: "1.25rem",
    width: "1.25rem",
  });

export const text = (isActive,isCollapsed) =>  css({
  ...textStyles.H1125SemiBold,
  lineHeight: "1.125rem",
  width: isCollapsed?0: "auto",
  // transition: "all 0.2s ease-in-out",
  whiteSpace: "nowrap",
});

export const leftContainer = css({
    display: 'flex',
    columnGap: '0.75rem',
    alignItems: 'center'
})


export const customIcon = css({
  width: "1.25rem",
})
export const pngIcon = css({
  width: "1.25rem",
})