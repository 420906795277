/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import * as styles from "./styles";
import DividerLine from "../../../shared/dividerLine";
import { Formik } from "formik";
import * as Yup from "yup";
import LabeledInput from "../../../shared/Input";
import LabeledDropDown from "../../../shared/Dropdown/Labeled";
import CustomButton from "../../../shared/button";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSingleKeywordData,
  setIsEditingKeyword,
} from "../../createEditCampaign/campaignDetailSlice";
import {
  addSingleKeyword,
  editSingleKeyword,
} from "../../../../services/ranking";
import toast from "react-hot-toast";
import { updateRemainingCredits } from "../../campaigns/campaignsSlice";
import { getLocal } from "../../../../utility/helpers";

const types = {
  options: [
    { label: "Brand", value: "Brand" },
    { label: "Non Brand", value: "Non Brand" },
  ],
};

const priority = {
  options: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
};

const AddSingleKeyword = ({ backToTable }) => {
  const isEditing = useSelector(
    (state) => state.campaignDetails.isEditingKeyword
  );
  const data = useSelector((state) => state.campaignDetails.singleKeywordData);
  const credits = useSelector((state) => state.campaigns.credits);
  const uniqueCategories = useSelector(
    (state) => state.campaignDetails.categoryFilterOptions
  );
  const campaignId = useSelector((state) => state.campaignDetails.details.id);
  const userId = getLocal("userId");
  const [category, setCategory] = useState({
    options: [],
    active: "",
  });

  useEffect(() => {
    const data = uniqueCategories.map((category) => ({
      label: category,
      value: category,
    }));

    setCategory({ options: data, active: "" });
  }, [uniqueCategories]);

  const dispatch = useDispatch();

  const detailsSchema = Yup.object().shape({
    keywordName: Yup.string().required(
      "Keyword is a required field."
    ),
    searchVolume: Yup.string()
      .required("Search volume is a required field.")
      .test({
        message: "Should be >= 0",
        test: (value) => {
          return value > -1;
        },
      }),
    category: Yup.string().required(
      "Please select a Category."
    ),
    type: Yup.string().required("Please select a Type."),
    priority: Yup.string().required(
      "Please select a Priority."
    ),
  });

  const handleSave = async (values) => {
    try {
      const payload = {
        keywords: values.keywordName,
        searchvolume: parseInt(values.searchVolume),
        keywordcategory: values.category,
        types: values.type,
        priority: values.priority,
        rankingconfigid: campaignId,
        user_id: userId

      };
      const response = await editSingleKeyword(data.key, payload);
      if (response.status === "success") {
        toast.success(`Keyword Updated successfully`);
        dispatch(resetSingleKeywordData());
        dispatch(setIsEditingKeyword(false));
        backToTable();
      }
    } catch (error) {
      if (error.response.data.error_message) {
        toast.error(error.response.data.error_message);
      } else {
        toast.error("Keyword update failed.");
      }
    }
  };

  const addKeyword = async (values) => {
    try {
      const payload = {
        keywords: values.keywordName,
        searchvolume: values.searchVolume,
        keywordcategory: values.category,
        types: values.type,
        priority: values.priority,
        TotalRemainingKeywordsCredits: credits.remaining,
        rankingconfigid: campaignId,
        user_id: userId
      };
      const response = await addSingleKeyword(payload);
      if (response.status === "success") {
        toast.success(`Keyword Updated successfully`);
        dispatch(resetSingleKeywordData());
        dispatch(setIsEditingKeyword(false));
        dispatch(
          updateRemainingCredits(response.TotalRemainingKeywordsCredits)
        );
        backToTable();
      }
    } catch (error) {
      toast.error(error.response.data.error_message);
    }
  };

  return (
    <div css={styles.main}>
      <div css={styles.subtitle}>Add Keyword Details :</div>
      <Formik
        initialValues={{
          keywordName: isEditing && data.keywords ? data.keywords : "",
          searchVolume: isEditing && data.searchvolume ? data.searchvolume : "",
          category:
            isEditing && data.keywordcategory ? data.keywordcategory : null,
          type: isEditing && data.types ? data.types : null,
          priority: isEditing && data.priority ? data.priority : null,
        }}
        onSubmit={(values) => {
          if (isEditing) {
            handleSave(values);
          } else {
            addKeyword(values);
          }
        }}
        validationSchema={detailsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setTouched,
        }) => (
          <form onSubmit={handleSubmit} css={styles.form}>
            <LabeledInput
              id={"keywordName"}
              placeholder={"Keyword Name"}
              value={values.keywordName}
              onChange={(e) => setFieldValue("keywordName", e.target.value)}
              label={"Keyword Name"}
              onBlur={()=>{
                setFieldValue("keywordName", values.keywordName.trim())
                handleBlur()
              }}
              key={"keywordName"}
              disable={isEditing}
            />
            <div css={styles.error}>
              {errors.keywordName && touched.keywordName
                ? errors.keywordName
                : ""}
            </div>
            <LabeledInput
              id={"searchVolume"}
              placeholder={"Enter Search Volume"}
              type={"number"}
              value={values.searchVolume}
              onChange={(e) => setFieldValue("searchVolume", e.target.value)}
              label={"Search Volume"}
              onBlur={handleBlur}
              key={"searchVolume"}
            />
            <div css={styles.error}>
              {errors.searchVolume && touched.searchVolume
                ? errors.searchVolume
                : ""}
            </div>
            <LabeledDropDown
              options={category}
              label={"Category"}
              placeholder={"Value"}
              onChange={(value) => setFieldValue("category", value)}
              value={values.category}
              key={category.options}
              onBlur={()=>{setTouched({...touched,category:true})}}
            />
            <div css={styles.error}>
              {errors.category && touched.category ? errors.category : ""}
            </div>
            <LabeledDropDown
              options={types}
              label={"Type"}
              placeholder={"Value"}
              onChange={(value) => setFieldValue("type", value)}
              value={values.type}
              onBlur={()=>setTouched({...touched,type:true})}
            />
            <div css={styles.error}>
              {errors.type && touched.type ? errors.type : ""}
            </div>
            <LabeledDropDown
              options={priority}
              label={"Priority"}
              placeholder={"Value"}
              onChange={(value) => setFieldValue("priority", value)}
              value={values.priority}
              onBlur={()=>setTouched({...touched,priority:true})}
            />
            <div css={styles.error}>
              {errors.priority && touched.priority ? errors.priority : ""}
            </div>
            <div css={[styles.buttonContainer, styles.buttonGroup]}>
              <CustomButton
                text={"Cancel"}
                use={"secondary"}
                theme={"info"}
                onPress={() => {
                  dispatch(resetSingleKeywordData());
                  dispatch(setIsEditingKeyword(false));
                  backToTable();
                }}
              />
              <CustomButton
                type={"submit"}
                text={"Save"}
                use={"primary"}
                theme={"info"}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddSingleKeyword;
