import { css } from '@emotion/react';
import { Colors } from '../../styles/colors';
import { textStyles } from '../../styles/font';
import { Home } from 'feather-icons-react/build/IconComponents';

export const wrapper = css({
    background: Colors.plainWhite,
    minHeight: "calc(100vh - 8rem)"
})

export const header = css({
    ...textStyles.h5,
    color: Colors.black,
    padding: '1.5rem',
    display:'flex',
    alignItems: 'center',
    columnGap: '0.65rem',
    borderBottom: `2px solid ${Colors.neutral[200]}`
})

export const icon = css({
    height: '2.25rem',
    width: '1.875rem',
    cursor: 'pointer'
})