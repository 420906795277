import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import {
  getSeoAuditByScoreChartId,
  getSEOAuditGetById,
} from "../../utility/Constant";
import {
  fixTwoPlaceDecimal,
} from "../../utility/Globals";

function AuditScore() {
  const [percentage, setPercentage] = useState(0);
  const [statusData, setStatusData] = useState(["", "Remaining"]);
  const [auditScore, setAuditScore] = useState([]);
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: statusData,
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };
  
  useEffect(() => {
    getAuditList();
  }, []);

  function getAuditList() {
    APIManager.getAPI(
      getSEOAuditGetById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        let auditList = [];
        response.data?.audits.map((item) => {
          if (item.isShown === true) {
            auditList.push(item);
          }
        });
        getSeoAuditByScoreChart(auditList[0].seoAuditId, auditList);
      }
    });
  }

  function getSeoAuditByScoreChart(projectAuditId) {
    APIManager.getAPI(
      getSeoAuditByScoreChartId +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      projectAuditId
    ).then((response) => {
      if (
        response &&
        response.status === 200 &&
        response.data != "No Content Found"
      ) {
        setAuditScore(response.data[0]?.auditScore.toFixed(0));
        setPercentage(fixTwoPlaceDecimal(response.data[0]?.auditScore.toFixed(0)));
        if (
          response.data[0]?.auditScore >= 0 &&
          response.data[0]?.auditScore <= 30
        ) {
          setStatusData(["Needs work", "Remaining"]);
        } else if (
          response.data[0]?.auditScore >= 30 &&
          response.data[0]?.auditScore <= 75
        ) {
          setStatusData(["Good", "Remaining"]);
        } else if (response.data[0]?.auditScore > 75) {
          setStatusData(["Excellent", "Remaining"]);
        }
      }
    });
  }

  return (
    <>
      <div className="dash-content  seo_health_audit_score">
        <div className="d-flex align-items-center justify-content-center py-5 my-5 position-relative">
          <Doughnut
            data={data}
            options={{
              cutoutPercentage: 80,
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            }}
          />
        </div>
        <div className="audit-score percentage-text">
          <div className="text-name score-text">{auditScore}</div>
        </div>
        <div className="mt-3 marker-dot audit-dot">
          <div class="dot_block_section d-flex flex-wrap align-items-center  justify-content-center p-0 w-100 mt-4">
            <div className="excellent-dot me-2 mt-0 mb-0"></div>
            <label className="me-3 pt-2">Excellent</label>
            <div className="good-dot me-2"></div>
            <label className="me-3 pt-2">Good</label>
            <div className="needwork-dot me-2"></div>
            <label className="me-3 pt-2">Needs Work</label>
          </div>
          <div className="mt-4"></div>
        </div>
      </div>
    </>
  );
}

export default AuditScore;
