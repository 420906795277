/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "@semcore/ui/date-picker";
import * as styles from "./styles";
import CustomButton from "../../button";
import Popper from "@semcore/ui/popper";
import MultiRangeDatePicker from "../MultiRangePicker";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOptions,
  selectBucket,
  setDatePickerOption,
  setDates,
  setFormattedDate,
  setIsCustom,
  setSelectedMonth,
  setSelectedMonthRange,
  toggleChart,
  toggleIsCompare,
} from "../../../rankingReportV3/rankingReportSlice";
import {
  dateToClosestWeek,
  formatDateRange,
  getLargestDate,
  getLastSundayOfMonth,
  getWeeksBetweenRange,
  isLastSundayOfMonth,
  sortDatesToAscending,
} from "../../../../utility/dates";
import MonthPickerSingle from "../MonthPickerSingle";
import MonthPickerRange from "../MonthPickerRange";
import moment from "moment";
import { addDays } from "date-fns";
const RankingDatePicker = ({ minDate, maxDate, unavailableDates }) => {
  const datePickerOptions = useSelector(
    (state) => state.rankingReport.datePickerOptions
  );
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const availableDates = useSelector(
    (state) =>
      state.rankingReport.campaigns.options[
        state.rankingReport.campaigns.active
      ].availableData
  );
  const activeCampaign = useSelector(
    (state) =>
      state.rankingReport.campaigns.options[
        state.rankingReport.campaigns.active
      ]
  );
  const selectedMonth = useSelector(
    (state) => state.rankingReport.selectedMonth
  );
  const selectedMonthRange = useSelector(
    (state) => state.rankingReport.selectedMonthRange
  );
  const [availableMonths, setAvailableMonths] = useState([]);
  const [defaultAvailableMonth, setDefaultAvailableMonth] = useState(
    new Date()
  );
  const [defaultAvailableMonthRange, setDefaultAvailableMonthRange] = useState([
    new Date(),
    new Date(),
  ]);
  const getAvailableMonths = () => {
    const availableMonths = availableDates
      .filter((date) => isLastSundayOfMonth(date))
      .map((date) => moment(date).format("MMM 1, YYYY"));
    const sortedMonths = sortDatesToAscending(availableMonths);
    setDefaultAvailableMonth(sortedMonths[sortedMonths.length - 1]);
    if (sortedMonths.length > 1) {
      setDefaultAvailableMonthRange([
        sortedMonths[sortedMonths.length - 2],
        sortedMonths[sortedMonths.length - 1],
      ]);
    }
    return sortedMonths;
  };
  const formattedRange = useSelector(
    (state) => state.rankingReport.formattedRange
  );
  const isCompare = useSelector((state) => state.rankingReport.isCompare);

  useEffect(() => {
    if (datePickerOptions.active !== 0) {
      resetDates();
      setAvailableMonths(getAvailableMonths());
    } 
  }, [datePickerOptions.active]);

  useEffect(() => {
    if (activeCampaign.frequency === "Monthly") {
      dispatch(setDatePickerOption(2));
    } else {
      dispatch(setDatePickerOption(1));
    }
  }, [activeCampaign]);

  const resetDates = () => {
    const latestDate = getLargestDate(availableDates);
    const startDateSelection = dateToClosestWeek(
      addDays(new Date(latestDate), 1)
    )[0];
    const endDateSelection = dateToClosestWeek(
      addDays(new Date(latestDate), 1)
    )[1];
    const startDateCompare = dateToClosestWeek(
      addDays(new Date(latestDate), -7)
    )[0];
    const endDateCompare = dateToClosestWeek(
      addDays(new Date(latestDate), -7)
    )[1];
    const latestDatesForCalenderCompare = {
      selection: {
        startDate: startDateSelection,
        endDate: endDateSelection,
        key: "range1",
      },
      compare: {
        startDate: startDateCompare,
        endDate: endDateCompare,
        key: "range2",
      },
    };
    dispatch(setDates(latestDatesForCalenderCompare));
  };

  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const getPicker = () => {
    if (datePickerOptions.active === 0) {
      return (
        <MultiRangeDatePicker
          onApplyClick={(isCompare) => {
            dispatch(toggleChart(true));
            dispatch(toggleIsCompare(false));
            dispatch(setIsCustom(true));
            const { dateRanges } = getWeeksBetweenRange(
              selectedDates.selection.startDate,
              selectedDates.selection.endDate,
              unavailableDates
            );
            dispatch(resetOptions());
            dispatch(selectBucket(-1));
            dispatch(setFormattedDate(dateRanges));
            setVisible(false);
          }}
          minDate={minDate}
          maxDate={maxDate}
          showCompare={false}
          unavailableDates={unavailableDates}
          availableDates={availableDates}
          isCustom={true}
          key={"custom"}
          isCompare={false}
        />
      );
    } else if (datePickerOptions.active === 1) {
      return (
        <MultiRangeDatePicker
          onApplyClick={(isCompare) => {
            dispatch(toggleChart(true));
            dispatch(resetOptions());
            dispatch(toggleIsCompare(isCompare));
            setVisible(false);
            let firstWeek;
            let secondWeek;
            let DateRange;
            if (isCompare) {
              firstWeek = formatDateRange(
                selectedDates.selection.startDate,
                selectedDates.selection.endDate
              );
              secondWeek = formatDateRange(
                selectedDates.compare.startDate,
                selectedDates.compare.endDate
              );
            } else {
              firstWeek = formatDateRange(
                selectedDates.selection.startDate,
                selectedDates.selection.endDate
              );
            }
            const dateRange = isCompare
              ? [...firstWeek, ...secondWeek]
              : firstWeek;
            dispatch(setFormattedDate(dateRange));
            dispatch(setIsCustom(false));
          }}
          showCompare={true}
          minDate={minDate}
          maxDate={maxDate}
          unavailableDates={unavailableDates}
          availableDates={availableDates}
          key={"biweekly"}
          isCustom={false}
          isCompare={isCompare}
        />
      );
    } else if (datePickerOptions.active === 2) {
      return (
        <MonthPickerSingle
          hideTrigger={true}
          onApplyClick={(isCompare, date) => {
            dispatch(toggleChart(true));
            dispatch(setIsCustom(false));
            dispatch(resetOptions());
            if (isCompare) {
              dispatch(setSelectedMonthRange(date));
              const lastSundayFirstMonth = getLastSundayOfMonth(date[0]);
              const lastSundaySecondMonth = getLastSundayOfMonth(date[1]);
              const lastWeekFirstMonth =
                dateToClosestWeek(lastSundayFirstMonth);
              const lastWeekSecondMonth = dateToClosestWeek(
                lastSundaySecondMonth
              );
              dispatch(
                setDates({
                  compare: {
                    startDate: lastWeekSecondMonth[0],
                    endDate: lastWeekSecondMonth[1],
                  },
                  selection: {
                    startDate: lastWeekFirstMonth[0],
                    endDate: lastWeekFirstMonth[1],
                  },
                })
              );
              dispatch(
                setFormattedDate([
                  `${moment(lastWeekFirstMonth[0]).format(
                    "DD MMM YYYY"
                  )} - ${moment(lastWeekFirstMonth[1]).format("DD MMM YYYY")}`,
                  `${moment(lastWeekSecondMonth[0]).format(
                    "DD MMM YYYY"
                  )} - ${moment(lastWeekSecondMonth[1]).format("DD MMM YYYY")}`,
                ])
              );
            } else {
              const lastSunday = getLastSundayOfMonth(date);
              const lastWeek = dateToClosestWeek(lastSunday);
              dispatch(setSelectedMonth(date));

              dispatch(
                setDates({
                  compare: selectedDates.compare,
                  selection: { startDate: lastWeek[0], endDate: lastWeek[1] },
                })
              );
              dispatch(
                setFormattedDate([
                  `${moment(lastWeek[0]).format("DD MMM YYYY")} - ${moment(
                    lastWeek[1]
                  ).format("DD MMM YYYY")}`,
                ])
              );
            }
            setVisible(false);
            dispatch(toggleIsCompare(isCompare));
          }}
          minDate={minDate}
          maxDate={maxDate}
          availableMonths={availableMonths}
          key={"month"}
          defaultValueSingle={
            !!selectedMonth ? selectedMonth : defaultAvailableMonth
          }
          defaultValueCompare={
            !!selectedMonthRange
              ? selectedMonthRange
              : defaultAvailableMonthRange
          }
        />
      );
    }
  };

  return (
    <Popper
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      // value={value}
      // onChange={setValue}
      // highlighted={highlighted}
      placement="bottom-end"
      key={datePickerOptions.active}
    >
      <Popper.Trigger css={styles.dateTrigger}>
        <FeatherIcon icon={"calendar"} css={styles.icon} />
        <div>
          {!visible
            ? isCompare
              ? `${formattedRange[0]} | ${formattedRange[1]}`
              : formattedRange[0]
            : isCompare
            ? `${formatDateRange(
                selectedDates.selection.startDate,
                selectedDates.selection.endDate
              )} | ${formatDateRange(
                selectedDates.compare.startDate,
                selectedDates.compare.endDate
              )}`
            : !!selectedDates.selection
            ? formatDateRange(
                selectedDates.selection.startDate,
                selectedDates.selection.endDate
              )
            : "Select Date"}
        </div>
      </Popper.Trigger>
      <Popper.Popper css={styles.popperContainer}>
        <div css={styles.leftPane}>
          {datePickerOptions.options.map((option, index) => {
            return (
              <div
                onClick={() => {
                  if (index === 1 && activeCampaign.frequency === "Monthly") {
                    return;
                  }
                  dispatch(setDatePickerOption(index));
                }}
                css={styles.leftPaneOptions(
                  index === datePickerOptions.active,
                  index === 1 && activeCampaign.frequency === "Monthly"
                )}
              >
                {option}
              </div>
            );
          })}
        </div>
        <div css={styles.pickerContainer}>{getPicker()}</div>
      </Popper.Popper>
    </Popper>
  );
};

export default RankingDatePicker;
