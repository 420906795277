import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import "antd/dist/antd.css";
import FormControl from "@mui/material/FormControl";
import { userAuth, userForgotPassword } from "../utility/Constant";
import { clearUserInformation, storeUserInformation } from "../utility/Globals";
import APIManager from "../services/APIManager";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

function EmailSent() {
    const navigate = useNavigate();
    const [str, setStr] = useState("");
    const configData = require("../config.json");
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [message1, setMessage1] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [passValid, setPassValid] = useState(false);
    const [userdepartment, setuserdepartment] = useState("");
    const [values, setValues] = React.useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    let query = useQuery();
    useEffect(() => {
        setStr(query.get("session"));
        if (query.get("session") == "expired") {
            setMessage("Token is expired.");
        }

        clearUserInformation();
    }, []);

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    function handleKeyPress(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            handleLogin();
        }
    }

    const inputDataValidation = () => {
        let isEmailInvalid = message1?.length ? true : false;
        let isPassInvalid = message?.length ? true : false;

        if (userId == "") {
            isEmailInvalid = true;
            setMessage1("Email is required");
        }
        if (password == "") {
            isPassInvalid = true;
            setMessage("Password is required");
        }
        if (userId?.length) {
            var pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
            if (pattern?.test(userId) == false) {
                setMessage1("Please enter valid Email!");
                isEmailInvalid = true;
            }
        }

        if (password?.length && password?.length > 11) {
            isPassInvalid = true;
            setMessage("Please enter valid Password!");
        }
        if (isEmailInvalid || isPassInvalid) {
            return false;
        } else {
            return true;
        }
    };

    const responseGoogleSuccess = (response) => {
        setUserId(response.profileObj.email);
        userLogin(response.profileObj.email, true);
    };

    // Error Handler
    const responseGoogleError = (response) => { };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    function handleLogin() {
        if (inputDataValidation()) {
            userLogin(
                document.getElementById("email standard-adornment-weight").value,
                false
            );
        }
    }

    function userLogin(userId, isGoogle) {
        localStorage.setItem("is1stTime", true);
        let rowData;
        if (isGoogle) {
            rowData = { email: userId };
        } else {
            rowData = {
                email: document.getElementById("email standard-adornment-weight").value,
                password: document.getElementById("password").value,
            };
        }
        let UserEmailId = document.getElementById(
            "email standard-adornment-weight"
        ).value;
        localStorage.setItem("UserEmail", UserEmailId);

        setMessage("");
        setMessage1("");

        APIManager.postAPI(userAuth, rowData, null, true).then((response) => {
            if (response && response.status == 200) {
                if (response.data.token) {
                    setUserId("");
                    setPassword("");
                    setMessage("");
                    setMessage1("");
                    setuserdepartment(response.data?.userDepartment)
                    localStorage.setItem("loggedInUserId", response.data?.userId);
                    localStorage.setItem("loggedInOrgId", response.data?.organizationId);
                    localStorage.setItem(
                        "clientGId",
                        response.data?.clientProjects[0]?.clientId
                    );
                    localStorage.setItem(
                        "projectGId",
                        response.data?.clientProjects[0]?.projects[0]?.projectId
                    );
                    storeUserInformation(response.data);
                    if (response.data.isForceChangePassword) {
                        navigate("/new-password-set");
                    }
                    else if (response.data?.userDepartment == "Content") {
                        navigate("/content-main/dashboardContent");
                    }
                    else if (response.data?.userDepartment == "Freelancer") {
                        navigate("/content-main/personalDetails");
                    }
                    else {
                        navigate("/main/dashboard");
                    }
                }
            } else {
            }
        });
    }

    function forgotPassword(email) {
        if (email?.length) {
            var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

            if (pattern?.test(email) == false) {
                setMessage1("Please enter valid Email!");
                setMessage("");
            }

        }

        APIManager.postAPI(userForgotPassword + email, {}, null, true).then(
            (response) => {
                if (response && response.status === 200) {
                    toast.success('Check your Email!');
                    return;
                }
            }
        );
    }

    const [themee, setThemee] = useState("fa fa-moon");
    useEffect(() => { }, [themee]);
    function appThemeChange() {
        if (themee == "fa fa-moon") {
            localStorage.setItem("theme-variant", "dark");
            setThemee("fa fa-sun");
        } else {
            localStorage.setItem("theme-variant", "light");
            setThemee("fa fa-moon");
        }
        if (document.querySelector("body").classList.contains("theme-light")) {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
        } else {
            document.querySelector("body").classList.remove("theme-dark");
            document.querySelector("body").classList.add("theme-light");
        }
    }

    return (
        <>
            {/* <div class="float-btn float-btn-right">
                <button
                    class="btn btn-flat-primary btn-icon mb-2"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Change theme"
                >
                    <i onClick={() => appThemeChange()} class={themee}></i>
                </button>
            </div> */}

            <div class="login-new emailsent-new">
                <div class="container-fluid px-0 h-100">
                    {/* <div class="row no-gutters px-0 h-100">
                        <div class="col-md-6"> */}
                        <div class="row no-gutters px-0 d-flex h-100">
                        <div class="custom-col-7">
                            <div class="image-left h-100">
                                <img src="./images/login/email-sent.png" alt="#" />
                            </div>
                        </div>
                        <div class="custom-col-5">
                            <div class="field-right h-100">
                                <div className="wrap-class">
                                    <div className="login-title text-start">
                                        <div className="d-flex align-items-center">
                                            <h2>Email Sent</h2>
                                            <img src="./images/login/e-sent.png" alt="#" />
                                        </div>
                                        <span>The password reset link is sent to
                                            <br />your email successfully </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailSent;
