import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { DateObject } from "react-multi-date-picker";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import {
  avgOfArrayItems,
  calanderDate,
  convertDate,
  exportInToCSV,
  exportToEXCEL,
  mmDDyyyyFormateForForm22,
  sumOfArrayItems,
  themeStyle,
  fixTwoPlaceDecimal,
  returnMagnitude,
  toMonthName,
  formateDateIntoDatemonth,
  getEstimatedClicksColor,
  downloadPDFfile,
  getBase64path,
  calanderDateCompare,
  getCheckPermission
} from "../utility/Globals";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import APIManager from "../services/APIManager";
import Chart from "react-apexcharts";
import {
  getGSCProperty,
  sendEmailApi,
  getChartData,
  GetTableData,
  getCountryCode,
  gecExportDataApi,
  moduleImpressionsClicksReport,
  getChartDataCompare,
  getGscData,
} from "../utility/Constant";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  CarouselProvider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Loading from "../utility/Loading";
import { PDFExport } from "@progress/kendo-react-pdf";
import NotFound from "../NotFound";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function ModuleExpandGSC() {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [dimensionsSelected, setdimensionsSelected] = useState(null);
  function handleChangeDimensions(selected) {
    setdimensionsSelected(selected);
  }
  const [DimensionsOptions, setDimensionsOptions] = useState([]);
  const [dimensionsSelected1, setdimensionsSelected1] = useState(0);
  const [MetricsOptions, setMetricsOptions] = useState([]);
  const [MetricsSelected, setMetricsSelected] = useState("impression");
  const [GSCTableCol, setGSCTableCol] = useState([]);
  const [GSCTableCol1, setGSCTableCol1] = useState([]);
  const [GSCTableColData, setGSCTableColData] = useState([]);
  const [GSCTableCompCol, setGSCTableCompCol] = useState([]);
  const [GSCTableCompCol1, setGSCTableCompCol1] = useState([]);
  const [GSCTableCompColData1, setGSCTableCompColData1] = useState([]);
  const [gscHeading, setgscHeading] = useState("Top Queries");
  const [propertySelected, setpropertySelected] = useState([]);
  const [SearchTypeSelected, setSearchTypeSelected] = useState("Web");
  const [notFound, setNotFound] = useState(true);
  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0.5,
      tickAmount: 5,
      min: -1,
      max: 1.5,
    },
  });
  const [options1, setObject1] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0.5,
      tickAmount: 5,
      min: -1,
      max: 1.5,
    },
    markers: {
      size: 5,
    },
  });
  const [options2, setObject2] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0.5,
      tickAmount: 5,
      min: -1,
      max: 1.5,
    },
    markers: {
      size: 5,
    },
  });
  const [options3, setObject3] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0.5,
      tickAmount: 5,
      min: -1,
      max: 1.5,
    },
    markers: {
      size: 5,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Total Impressions",
      data: [],
      color: "#008FFB",
    },
  ]);
  const [series1, setSeries1] = useState([
    {
      name: "Total Clicks",
      data: [],
      color: "#3366cc",
    },
  ]);
  const [series2, setSeries2] = useState([
    {
      name: "Position",
      data: [],
      color: "#C0504E",
    },
  ]);
  const [series3, setSeries3] = useState([
    {
      name: "Average CTR",
      data: [],
      color: "#77A033",
    },
  ]);
  const [sortList, setSortList] = useState([
    {
      columnName: "keyword",
      order: undefined
    }
  ])
  const [totalClicks, settotalClicks] = useState();
  const [totalimp, settotalimp] = useState(false);
  const [avgCTR, setavgCTR] = useState(false);
  const [avgPos, setavgPos] = useState(false);
  const [listArrDateIds, setListArrDateIds] = useState([]);
  const [subject, setSubject] = useState("Impressions & Clicks Reports");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [performanceMetricsCardList1, setCardListData] = useState([]);
  const [selectedQueryExportList, setSelectedQueryExportList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [noConfig, setNoConfig] = useState(true);
  const [queryListMain, setQueryListMain] = useState()
  const [pageListMain, setPageListMain] = useState()
  const [countryListMain, setcountryListMain] = useState()

  useEffect(() => {
    setSearchTypeSelected("Web")
    setNotFound(getCheckPermission("impressions_&_clicks", "reports"));
    localStorage.setItem("isCompareVar", false);
    let sortListVar = []
    sortListVar.push({
      columnName: "keyword",
      order: undefined
    })
    setSortList(sortListVar)
    getGSCConfig();
  }, []);

  function getGSCConfig() {
    APIManager.getAPI(
      getGscData + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response?.data) {
          setNoConfig(false);
          settotalimp(true);
          setavgPos(true);
          setavgCTR(true);
          settotalClicks(true);
          getCountryList();
          var data = [
            { label: "Query", value: 0 },
            { label: "Page", value: 1 },
            { label: "Country", value: 2 },
            { label: "Device", value: 3 },
            { label: "Search Appearence", value: 4 },
            { label: "Date", value: 5 },
          ];
          setDimensionsOptions(data);
          setdimensionsSelected1(data[0]);
          handleChangeDimensions(data);
          var metricsData = [
            { label: "Impressions", value: "impression" },
            { label: "Clicks", value: "click" },
            { label: "Average CTR", value: "ctr" },
            { label: "Average Position", value: "position" },
          ];
          setMetricsOptions(metricsData);
          setMetricsSelected(metricsData[0]);

          data = [];

          setColumn1(gscHeading);

          if (isDateRage) {
            let rowData = {
              projectId: localStorage.getItem("projectGId"),
              GSCDateRange: {
                from: convertDate(state.selection1.startDate),
                to: convertDate(state.selection1.endDate),
              },
              currentPage: pageNumber,
              pageSize: pageSize,
              Dimension: 0,
              sorter: sortList,
              isSorter: false
            };
            let rowData1 = getRowDataFun(rowData)
            getTableData(rowData1, pageSize);
          } else {
            let rowData = {
              projectId: localStorage.getItem("projectGId"),
              GSCDateRange: {
                from: convertDate(state.selection1.startDate),
                to: convertDate(state.selection1.endDate),
              },
              GSCCompareToRange: {
                from: convertDate(state.selection2.startDate),
                to: convertDate(state.selection2.endDate),
              },
              currentPage: pageNumber,
              pageSize: pageSize,
              Dimension: 0,
              sorter: sortList,
              isSorter: true
            };
            let rowData1 = getRowDataFun(rowData)
            getTableDataCompare(rowData1, pageSize);
          }
          document.querySelector("#QuerySelector").disabled = false;
          document.querySelector("#PageSelector").disabled = true;
          document.querySelector("#DeviceSelector").disabled = true;
          document.querySelector("#CountrySelector").disabled = true;
          getProperty();
          let dimeselected = [];
          dimensionsSelected?.forEach((item) => {
            dimeselected.push(item.label);
          });
          let getdata = {
            startdate: mmDDyyyyFormateDateSet(head1),
            enddate: mmDDyyyyFormateDateSet(head2),
            projectId: localStorage.getItem("projectGId"),
            Property: propertySelected,
            Dimension: dimeselected,
            SearchType: SearchTypeSelected,
            View: ChartView,
          };
          gscGetDataGet(getdata);
        }
      }
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          keyword: item.keyword,
          click: item.click,
          impression: item.impression,
          ctr: item.ctr,
          position: item.position,
        };
        list.push(obj);
      });

      setSelectedQueryExportList(list);
    },
  };

  const rowSelectionCompare = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          keyword: item.keyword,
          impression1: fixTwoPlaceDecimal(item.impression1),
          impression2: fixTwoPlaceDecimal(item.impression2),
          impressionDifference: fixTwoPlaceDecimal(item.impressionDifference),
          clicks1: fixTwoPlaceDecimal(item.clicks1),
          clicks2: fixTwoPlaceDecimal(item.clicks2),
          clickDifference: fixTwoPlaceDecimal(item.clickDifference),
          ctr1: fixTwoPlaceDecimal(item.ctr1) + "%",
          ctr2: fixTwoPlaceDecimal(item.ctr2) + "%",
          ctrDifference: fixTwoPlaceDecimal(item.ctrDifference) + "%",
          position1: fixTwoPlaceDecimal(item.position1),
          position2: fixTwoPlaceDecimal(item.position2),
          positionDifference: fixTwoPlaceDecimal(item.positionDifference),
        };
        list.push(obj);
      });
      setSelectedQueryExportList(list);
    },
  };

  function round(num, dec) {
    num = Math.round(num + "e" + 0);
    return Number(num + "e-" + 0);
  }

  function generateGSCChartSearchResults(tc, ti, ac, ap) {
    let isChecked1 = false,
      isChecked2 = false,
      isChecked3 = false,
      isChecked4 = false;
    let tcArr1 = [];
    let tcArr2 = [];
    let tcArr3 = [];
    let tcArr4 = [];
    let maxImp;
    let maxClk;
    let maxPos;
    let maxCtr;
    let indexForRemoval = 0;
    if (mainChartArr.length > 0) {
      if (ti) {
        isChecked1 = true;
        let TIlistData = mainChartArr[1];
        TIlistData.forEach((tcItem) => {
          tcArr1.push(round(tcItem, 2));
        });
      }
      if (tc) {
        isChecked2 = true;
        let TClistData = mainChartArr[0];
        TClistData.forEach((tcItem) => {
          tcArr2.push(round(tcItem, 2));
        });
      }
      if (ac) {
        isChecked3 = true;
        let TIlistData = mainChartArr[2];
        TIlistData.forEach((tcItem) => {
          tcArr3.push(round(tcItem, 2));
        });
      }
      if (ap) {
        isChecked4 = true;
        let TIlistData = mainChartArr[3];
        TIlistData.forEach((tcItem) => {
          tcArr4.push(round(tcItem, 2));
        });
      }
    }

    let mainArr = [];
    let mainArr1 = [];
    let mainArr2 = [];
    let mainArr3 = [];
    let maxValArr = [];
    let maxValArr1 = [];
    let maxValArr2 = [];
    let maxValArr3 = [];
    if (isChecked1) {
      var largest1 = tcArr1[1];
      for (let i = 1; i <= tcArr1.length; i++) {
        if (tcArr1[i] > largest1) {
          largest1 = tcArr1[i];
        }
      }
      maxValArr.push(largest1);
      mainArr.push({
        name: "Total Impressions",
        data: tcArr1
          .slice(0, indexForRemoval)
          .concat(tcArr1.slice(indexForRemoval + 1)),
        color: "#F79647",
      });
    }
    if (isChecked2) {
      var largest2 = tcArr2[1];
      for (let i = 1; i <= tcArr2.length; i++) {
        if (tcArr2[i] > largest2) {
          largest2 = tcArr2[i];
        }
      }
      maxImp = Math.max(...tcArr1) * 0.3 + Math.max(...tcArr1); //imp
      maxClk = Math.max(...tcArr2) * 0.3 + Math.max(...tcArr2); //click
      maxPos = Math.max(...tcArr3) * 0.3 + Math.max(...tcArr3); //pos
      maxCtr = Math.max(...tcArr4) * 0.3 + Math.max(...tcArr4); //ctr

      maxValArr1.push(largest2);
      mainArr1.push({
        name: "Total Clicks",
        data: tcArr2
          .slice(0, indexForRemoval)
          .concat(tcArr2.slice(indexForRemoval + 1)),
        color: "#3366cc",
      });
    }
    if (isChecked3) {
      var largest3 = tcArr3[1];
      for (let i = 1; i <= tcArr3.length; i++) {
        if (tcArr3[i] > largest3) {
          largest3 = tcArr3[i];
        }
      }
      maxValArr2.push(largest3);
      mainArr2.push({
        name: "Position",
        data: tcArr3
          .slice(0, indexForRemoval)
          .concat(tcArr3.slice(indexForRemoval + 1)),
        color: "#C0504E",
      });
    }
    if (isChecked4) {
      var largest4 = tcArr4[1];
      for (let i = 1; i <= tcArr4.length; i++) {
        if (tcArr4[i] > largest4) {
          largest4 = tcArr4[i];
        }
      }
      maxValArr3.push(largest4);
      mainArr3.push({
        name: "Average CTR",
        data: tcArr4
          .slice(0, indexForRemoval)
          .concat(tcArr4.slice(indexForRemoval + 1)),
        color: "#77A033",
      });
    }
    setSeries(mainArr);
    setSeries1(mainArr1);
    setSeries2(mainArr2);
    setSeries3(mainArr3);

    let cols = [];
    listArrDateIds.forEach((i, index) => {
      if (index !== 0) {
        cols.push(formateDateIntoDatemonth(i.keys));
      }
    });

    let maxValueFromList = maxValArr[0];
    for (let i = 1; i <= maxValArr.length; i++) {
      if (maxValArr[i] > maxValueFromList) {
        maxValueFromList = maxValArr[i];
      }
    }
    let maxValueFromList1 = maxValArr1[0];
    for (let i = 1; i <= maxValArr1.length; i++) {
      if (maxValArr1[i] > maxValueFromList1) {
        maxValueFromList1 = maxValArr1[i];
      }
    }
    let maxValueFromList2 = maxValArr2[0];
    for (let i = 1; i <= maxValArr2.length; i++) {
      if (maxValArr2[i] > maxValueFromList2) {
        maxValueFromList2 = maxValArr2[i];
      }
    }
    let maxValueFromList3 = maxValArr3[0];
    for (let i = 1; i <= maxValArr3.length; i++) {
      if (maxValArr3[i] > maxValueFromList3) {
        maxValueFromList3 = maxValArr3[i];
      }
    }

    let titleText = "";

    if (ChartView === 0) {
      titleText = "Days";
    } else if (ChartView === 1) {
      titleText = "Weeks";
    } else if (ChartView === 2) {
      titleText = "Months";
    }
    setObject({
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: cols,
        title: {
          text: titleText,
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return toMonthName(val, titleText);
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxImp,
        labels: {
          formatter: function (val) {
            return returnMagnitude(val);
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      markers: {
        size: 5,
      },
    });
    setObject1({
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: cols,
        title: {
          text: titleText,
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return toMonthName(val, titleText);
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxClk,
        labels: {
          formatter: function (val) {
            return returnMagnitude(val);
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      markers: {
        size: 5,
      },
    });
    setObject2({
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: cols,
        title: {
          text: titleText,
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return toMonthName(val, titleText);
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxPos,
      },
      markers: {
        size: 5,
      },
    });
    setObject3({
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: cols,
        title: {
          text: titleText,
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return toMonthName(val, titleText);
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxCtr,
      },
      markers: {
        size: 5,
      },
    });
  }

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Impressions & Clicks Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    generateGSCChartSearchResults(true, true, true, true);
  }, [date1, date2, totalimp, totalClicks, avgCTR, avgPos]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(9, "days"),
    new DateObject().add(-3, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(values[0].format());
  const [head2, setHead2] = useState(values[1].format());
  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);
  const [selectedQuery, setselectedQuery] = useState("0");
  const [selectedPages, setSelectedPages] = useState("0");
  const [queryRegxText, setqueryRegxText] = useState("");
  const [queryText, setqueryText] = useState("");
  const [pagesText, setpagesText] = useState("");
  const [pagesRegxText, setpagesRegxText] = useState("");
  const [queryRegx, setqueryRegx] = useState("0");
  const [pageRegx, setpageRegx] = useState("0");
  const [TextBoxShow, setTextBoxShow] = useState(false);
  const [regxTextBoxShow, setRegxTextBoxShow] = useState(false);
  const [regxTextBoxShow1, setRegxTextBoxShow1] = useState(false);
  const [deviceSelected, setdeviceSelected] = useState("2");
  const [TextShow, setTextShow] = useState(false);
  const [TextBoxShow1, setTextBoxShow1] = useState(false);
  const [TextShow1, setTextShow1] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("");
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        const rdrDefinedRangesWrapper =  document.getElementsByClassName("rdrDefinedRangesWrapper")[0]
        if(rdrDefinedRangesWrapper){
          rdrDefinedRangesWrapper.classList.add("display-none");
        }
      }
    };
    
  }, [open]);
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName('rdrDateDisplayWrapper').length > 0)
          document.getElementsByClassName('rdrDateDisplayWrapper')[0].children[1].classList.add("display-none");
      }
    };
  }, [open]);

  useEffect(()=>{
    setValues([
      new DateObject().subtract(9, "days"),
      new DateObject().add(-3, "days"),
    ])   
  },[])

  function getProperty() {
    APIManager.postAPI(
      getGSCProperty + "?projectId=" + localStorage.getItem("projectGId"),
      {},
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setpropertySelected(response.data[0].siteUrl);
      }
    });
  }

  function getFilterviseChartData() {
    let queryList;
    let queryRegxList;
    let pageList;
    let pageRegxList;
    setPageNumber(1)
    setPageSize(10)
    if (queryRegx === "0") {
      queryRegxList = {
        Matches: queryRegxText,
        DoesNotMatching: "",
      };
    } else if (queryRegx === "1") {
      queryRegxList = {
        Matches: "",
        DoesNotMatching: queryRegxText,
      };
    }
    if (selectedQuery === "1") {
      queryList = {
        Containing: queryText,
        NotContaining: "",
        ExactQuery: "",
        Regex: queryRegxList,
      };
    } else if (selectedQuery === "2") {
      queryList = {
        Containing: "",
        NotContaining: queryText,
        ExactQuery: "",
        Regex: queryRegxList,
      };
    } else if (selectedQuery === "3") {
      queryList = {
        Containing: "",
        NotContaining: "",
        ExactQuery: queryText,
        Regex: queryRegxList,
      };
    } else if (selectedQuery === "4") {
      queryList = {
        Containing: "",
        NotContaining: "",
        ExactQuery: "",
        Regex: queryRegxList,
      };
    }
    //page
    if (pageRegx === "matches") {
      pageRegxList = {
        Matches: pagesRegxText,
        DoesNotMatching: "",
      };
    } else if (pageRegx === "doesn't match") {
      pageRegxList = {
        Matches: "",
        DoesNotMatching: pagesRegxText,
      };
    }
    if (selectedPages === "1") {
      pageList = {
        Containing: pagesText,
        NotContaining: "",
        ExactQuery: "",
        Regex: pageRegxList,
      };
    } else if (selectedPages === "2") {
      pageList = {
        Containing: "",
        NotContaining: pagesText,
        ExactQuery: "",
        Regex: pageRegxList,
      };
    } else if (selectedPages === "3") {
      pageList = {
        Containing: "",
        NotContaining: "",
        ExactQuery: pagesText,
        Regex: pageRegxList,
      };
    } else if (selectedPages === "4") {
      pageList = {
        Containing: "",
        NotContaining: "",
        ExactQuery: "",
        Regex: pageRegxList,
      };
    }
    let countryData;
    if (countrySelected.code === "Select") {
      countryData = null;
    } else {
      countryData = countrySelected;
    }
    let rowData, rowDataCompare;
    if (isDateRage) {
      rowData = {
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        projectId: localStorage.getItem("projectGId"),
        View: ChartView,
        currentPage: 1,
        pageSize: 10,
        Dimension: dimensionsSelected1.value,
        sorter: sortList,
        isSorter: false
      };
      if (dimensionsSelected1.value === 0) {
        setQueryListMain(queryList)
        rowData = {
          ...rowData,
          Queries: queryList,
        }
      }
      else if (dimensionsSelected1.value === 1) {
        setPageListMain(pageList)
        rowData = {
          ...rowData,
          pages: pageList,
        }
      }
      else if (dimensionsSelected1.value === 2) {
        setcountryListMain(countryData)
        rowData = {
          ...rowData,
          Country: countryData,
        }
      }
      else if (dimensionsSelected1.value === 3) {
        rowData = {
          ...rowData,
          DeviceType: deviceSelected,
        }
      }
    }
    else {
      rowDataCompare = {
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        GSCCompareToRange: {
          from: convertDate(state.selection2.startDate),
          to: convertDate(state.selection2.endDate),
        },
        projectId: localStorage.getItem("projectGId"),
        View: ChartView,
        currentPage: pageNumber,
        pageSize: pageSize,
        Dimension: dimensionsSelected1.value,
        sorter: sortList,
        isSorter: true
      };
      if (dimensionsSelected1.value === 0) {
        setQueryListMain(queryList)
        rowDataCompare = {
          ...rowDataCompare,
          Queries: queryList,
        }
      }
      else if (dimensionsSelected1.value === 1) {
        setPageListMain(pageList)
        rowDataCompare = {
          ...rowDataCompare,
          pages: pageList,
        }
      }
      else if (dimensionsSelected1.value === 2) {
        setcountryListMain(countryData)
        rowDataCompare = {
          ...rowDataCompare,
          Country: countryData,
        }
      }
      else if (dimensionsSelected1.value === 3) {
        rowDataCompare = {
          ...rowDataCompare,
          DeviceType: deviceSelected,
        }
      }
    }

    let rowData1 = {
      startdate: convertDate(state.selection1.startDate),
      enddate: convertDate(state.selection1.endDate),
      projectId: localStorage.getItem("projectGId"),
      View: ChartView,
      Queries: queryList,
      pages: pageList,
      DeviceType: deviceSelected,
      Country: countryData,
    };
    gscGetDataGet(rowData1);
    var data = "";
    if (dimensionsSelected1.value === 1) {
      data = "Top Pages";
    } else if (dimensionsSelected1.value === 0) {
      data = "Top Queries";
    } else if (dimensionsSelected1.value === 2) {
      data = "Country";
    } else if (dimensionsSelected1.value === 3) {
      data = "Device";
    } else if (dimensionsSelected1.value === 4) {
      data = "Search Appearance";
    } else if (dimensionsSelected1.value === 5) {
      data = "Date";
    }
    setgscHeading(data);
    let headings = {
      title: data,
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        multiple: 3,
      },
    };

    let colList = [];
    colList.push(headings);
    GSCTableCol1.forEach((item, index) => {
      if (index !== 0) colList.push(item);
    });

    setGSCTableCol1(colList);
        
    if (isDateRage) {
      APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
        if (response && response.status === 200) {
          let filterList = [];
          response.data.tableContentCount.forEach((item) => {
            setTableParams({
              pagination: {
                current: rowData.currentPage,
                pageSize: rowData.pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: item.totalCount,
              },
            });
          });
          response.data.tableContent.forEach((item, index) => {
            if (index < pageSize) {
              filterList.push({
                key: index,
                keyword: item.keyword,
                impression: fixTwoPlaceDecimal(item.impression),
                click: fixTwoPlaceDecimal(item.click),
                ctr: fixTwoPlaceDecimal(item.ctr) + "%",
                position: fixTwoPlaceDecimal(item.position),
              });
            }
          });
          setGSCTableColData([]);
          setGSCTableColData(filterList);
        }
      });
    } else {
      APIManager.postAPI(GetTableData, rowDataCompare, null, true).then((response) => {
        if (response && response.status === 200) {
          let filterList = [];
          response.data.compareTableContentCount.forEach((item) => {
            setTableParams({
              pagination: {
                current: rowDataCompare.currentPage,
                pageSize: rowDataCompare.pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: item.totalCount,
              },
            });
          });

          let date_1 = convertDate(state.selection1.startDate) +
            " To " +
            convertDate(state.selection1.endDate);
          let date_2 = convertDate(state.selection2.startDate) +
            " To " +
            convertDate(state.selection2.endDate);
          response.data.compareTableContent.forEach((item, index) => {
            if (index < pageNumber) {
              filterList.push({
                key: index,
                [gscHeading]: item.keyWord,
                ["Impressions " + date_1]: fixTwoPlaceDecimal(item.impression1),
                ["Impressions " + date_2]: fixTwoPlaceDecimal(item.impression2),
                ["Impressions Difference"]: fixTwoPlaceDecimal(item.impressionDifference),
                ["Clicks " + date_1]: fixTwoPlaceDecimal(item.clicks1),
                ["Clicks " + date_2]: fixTwoPlaceDecimal(item.clicks2),
                ["Clicks Difference"]: fixTwoPlaceDecimal(item.clickDifference),
                ["CTR " + date_1]: fixTwoPlaceDecimal(item.ctr1) + "%",
                ["CTR " + date_2]: fixTwoPlaceDecimal(item.ctr2) + "%",
                ["CTR Difference"]: fixTwoPlaceDecimal(item.ctrDifference) + "%",
                ["Average Position " + date_1]: fixTwoPlaceDecimal(item.position1),
                ["Average Position " + date_2]: fixTwoPlaceDecimal(item.position2),
                ["Average Position Difference"]: fixTwoPlaceDecimal(item.positionDifference),
                keyword: item.keyWord,
                clicks1: fixTwoPlaceDecimal(item.clicks1),
                clicks2: fixTwoPlaceDecimal(item.clicks2),
                clickDifference: fixTwoPlaceDecimal(item.clickDifference),
                ctr1: fixTwoPlaceDecimal(item.ctr1) + "%",
                ctr2: fixTwoPlaceDecimal(item.ctr2) + "%",
                ctrDifference: fixTwoPlaceDecimal(item.ctrDifference) + "%",
                impression1: fixTwoPlaceDecimal(item.impression1),
                impression2: fixTwoPlaceDecimal(item.impression2),
                impressionDifference: fixTwoPlaceDecimal(
                  item.impressionDifference
                ),
                position1: fixTwoPlaceDecimal(item.position1),
                position2: fixTwoPlaceDecimal(item.position2),
                positionDifference: fixTwoPlaceDecimal(item.positionDifference),
              });
            }
          });
          setGSCTableCompColData1(filterList);
        }
      });
    }
  }

  function clearSelection() {
    setqueryRegxText("");
    setqueryText("");
    setpagesRegxText("");
    setpagesText("");
    setselectedQuery("0");
    setSelectedPages("0");
    setCountrySelected("0");
    setdeviceSelected("Both");
    setpageRegx("0");
    setqueryRegx("0");
    setTextShow(false);
    setTextBoxShow1(false);
    setRegxTextBoxShow(false);
    setRegxTextBoxShow1(false);
    setTextBoxShow(false);
    setTextShow1(false);
    setQueryListMain()
    setPageListMain()
    setcountryListMain()
  }

  function exportFunction(type) {
    let exportList = [];
    let rowdata;
    if (isDateRage) {
      rowdata = {
        projectId: localStorage.getItem("projectGId"),
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        Dimension: dimensionsSelected1.value,
      };
    } else {
      rowdata = {
        projectId: localStorage.getItem("projectGId"),
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        GSCCompareToRange: {
          from: convertDate(state.selection2.startDate),
          to: convertDate(state.selection2.endDate),
        },
        Dimension: dimensionsSelected1.value,
      };
    }
    APIManager.postAPI(gecExportDataApi, rowdata, null, true).then(
      (response) => {
        if (response.status === 200 && response) {
          if (isDateRage) {
            response.data.tableContent.forEach((item) => {
              exportList.push({
                keyword: item.keyword,
                click: fixTwoPlaceDecimal(item.click),
                impression: fixTwoPlaceDecimal(item.impression),
                ctr: fixTwoPlaceDecimal(item.ctr) + "%",
                position: fixTwoPlaceDecimal(item.position),
              });
            });
            if (type === "excel") {
              exportToEXCEL(
                selectedQueryExportList?.length > 0
                  ? selectedQueryExportList
                  : exportList,
                "Query GSC"
              );
            } else if (type === "csv") {
              exportInToCSV(
                selectedQueryExportList?.length > 0
                  ? selectedQueryExportList
                  : exportList,
                "Query GSC"
              );
            }
          } else {
            response.data.compareTableContent.forEach((item) => {
              exportList.push({
                [gscHeading]: item.keyWord,
                ["Impressions " + date1]: fixTwoPlaceDecimal(item.impression1),
                ["Impressions " + date2]: fixTwoPlaceDecimal(item.impression2),
                ["Impressions Difference"]: fixTwoPlaceDecimal(item.impressionDifference),
                ["Clicks " + date1]: fixTwoPlaceDecimal(item.clicks1),
                ["Clicks " + date2]: fixTwoPlaceDecimal(item.clicks2),
                ["Clicks Difference"]: fixTwoPlaceDecimal(item.clickDifference),
                ["CTR " + date1]: fixTwoPlaceDecimal(item.ctr1) + "%",
                ["CTR " + date2]: fixTwoPlaceDecimal(item.ctr2) + "%",
                ["CTR Difference"]: fixTwoPlaceDecimal(item.ctrDifference) + "%",
                ["Average Position " + date1]: fixTwoPlaceDecimal(item.position1),
                ["Average Position " + date2]: fixTwoPlaceDecimal(item.position2),
                ["Average Position Difference"]: fixTwoPlaceDecimal(item.positionDifference),
              });
            });
            if (type === "excel") {
              exportToEXCEL(
                selectedQueryExportList?.length > 0
                  ? selectedQueryExportList
                  : exportList,
                "Query GSC"
              );
            } else if (type === "csv") {

              exportInToCSV(
                selectedQueryExportList?.length > 0
                  ? selectedQueryExportList
                  : exportList,
                "Query GSC"
              );
            }
          }
        } else {
        }
      }
    );
  }

  const [mainChartArr, setMainChartArr] = useState([]);

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  function gscGetDataGet(rowData) {
    let viewOfChart = rowData.View;
    let dimeselected = [];
    dimensionsSelected?.forEach((item) => {
      dimeselected.push(item.label);
    });

    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);

    APIManager.postAPI(getChartData, rowData, null, true).then((response) => {
      if (response.status === 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.forEach((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx === 0) {
            keys.forEach((i) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }
          setListArrDateIds(listArrIds);
          setChartView(rowData.View);

          let listArrValues = [];
          values.forEach((i) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });
          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart !== 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i1 = 0; i1 < a.length; i1++) {
            var b1 = a[i1];
            let subArr = [];
            for (var j1 = 1; j1 < b1.length; j1++) {
              subArr.push(b1[j1]);
            }
            mainArr.push(subArr);
          }
        }
        let cols = [];
        listArrIds.forEach((i) => {
          if (i.keys !== "fields") {
            if (viewOfChart === 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });

        let titleText = "";

        if (viewOfChart === 0) {
          titleText = "Days";
        } else if (viewOfChart === 1) {
          titleText = "Weeks";
        } else if (viewOfChart === 2) {
          titleText = "Months";
        }

        setMainChartArr(mainArr);
        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.forEach((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key === "Clicks") {
            tcArr = arr;
          } else if (key === "Ctr") {
            tcArr3 = arr;
          } else if (key === "Impressions") {
            tcArr1 = arr;
          } else if (key === "Position") {
            tcArr2 = arr;
          }
        });
        let tcArr21 = [];
        tcArr2.forEach((item) => {
          tcArr21.push(item.toFixed(2));
        });
        let tcArr31 = [];
        tcArr3.forEach((item) => {
          tcArr31.push((item * 100).toFixed(2));
        });

        let clickAvg = sumOfArrayItems(tcArr);
        let impressionAvg = sumOfArrayItems(tcArr1);
        let ctrAvg = avgOfArrayItems(tcArr3);
        let positionvg = avgOfArrayItems(tcArr2);

        let maxClk = Math.max(...tcArr) * 0.3 + Math.max(...tcArr); //click
        let maxImp = Math.max(...tcArr1) * 0.3 + Math.max(...tcArr1); //imp
        let maxCtr = Math.max(...tcArr31) * 0.3 + Math.max(...tcArr31); //ctr
        let maxPos = Math.max(...tcArr21) * 0.3 + Math.max(...tcArr21); //pos

        let listData = [
          {
            avgData: fixTwoPlaceDecimal(impressionAvg),
            user: "Impressions",
            data: impressionAvg.toFixed(2),
            compareData: impressionAvg.toFixed(2),
            isHighData: 2,
          },
          {
            avgData: fixTwoPlaceDecimal(clickAvg),
            user: "Clicks",
            data: clickAvg.toFixed(2),
            compareData: clickAvg.toFixed(2),
            isHighData: 2,
          },
          {
            avgData: fixTwoPlaceDecimal(ctrAvg * 100) + " %",
            user: "Average CTR",
            data: (ctrAvg * 100).toFixed(2),
            compareData: (ctrAvg * 100).toFixed(2),
            isHighData: 2,
          },
          {
            avgData: fixTwoPlaceDecimal(positionvg),
            user: "Average Position",
            data: positionvg.toFixed(2),
            compareData: positionvg.toFixed(2),
            isHighData: 2,
          },
        ];

        if (viewOfChart === 0) {
          setCardListData(listData);
        }
        setObject({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: cols,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return toMonthName(val, titleText);
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxImp,
            labels: {
              formatter: function (val) {
                return returnMagnitude(val);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value;
              },
            },
          },
          markers: {
            size: 5,
          },
        });
        setObject1({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: cols,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return toMonthName(val, titleText);
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxClk,
            labels: {
              formatter: function (val) {
                return returnMagnitude(val);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value;
              },
            },
          },
          markers: {
            size: 5,
          },
        });
        setObject2({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: cols,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return toMonthName(val, titleText);
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxPos,
          },
          markers: {
            size: 5,
          },
        });
        setObject3({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: cols,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return toMonthName(val, titleText);
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxCtr,
          },
          markers: {
            size: 5,
          },
        });

        setSeries1([
          {
            name: "Total Clicks",
            data: tcArr,
            color: "#3366cc",
          },
        ]);

        setSeries([
          {
            name: "Total Impressions",
            data: tcArr1,
            color: "#008FFB",
          },
        ]);
        setSeries2([
          {
            name: "Position",
            data: tcArr21,
            color: "#C0504E",
          },
        ]);
        setSeries3([
          {
            name: "Average CTR",
            data: tcArr31,
            color: "#77A033",
          },
        ]);
      }
    });
  }

  function gscGetDataGetForRange2(rowData1, rowData2) {
    let viewOfChart = rowData1.View;
    let dimeselected = [];
    dimensionsSelected?.forEach((item) => {
      dimeselected.push(item.label);
    });

    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);

    let viewOfChart1 = rowData1.View;
    let clickAvgVar;
    let impressionAvgVar;
    let ctrAvgVar;
    let positionvgVar;

    APIManager.postAPI(getChartData, rowData1, null, true).then((response) => {
      if (response.status === 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.forEach((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx === 0) {
            keys.forEach((i) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }

          setListArrDateIds(listArrIds);
          setChartView(rowData1.View);

          let listArrValues = [];
          values.forEach((i) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });

          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart1 !== 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i1 = 0; i1 < a.length; i1++) {
            var b1 = a[i1];
            let subArr = [];
            for (var j1 = 1; j1 < b1.length; j1++) {
              subArr.push(b1[j1]);
            }
            mainArr.push(subArr);
          }
        }

        let cols = [];

        listArrIds.forEach((i) => {
          if (i.keys !== "fields") {
            if (viewOfChart1 === 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });

        setMainChartArr(mainArr);
        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.forEach((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key === "Clicks") {
            tcArr = arr;
          } else if (key === "Ctr") {
            tcArr3 = arr;
          } else if (key === "Impressions") {
            tcArr1 = arr;
          } else if (key === "Position") {
            tcArr2 = arr;
          }
        });

        let tcArr21 = [];
        tcArr2.forEach((item) => {
          tcArr21.push(item.toFixed(2));
        });
        let tcArr31 = [];
        tcArr3.forEach((item) => {
          tcArr31.push((item * 100).toFixed(2));
        });

        clickAvgVar = sumOfArrayItems(tcArr);
        impressionAvgVar = sumOfArrayItems(tcArr1);
        ctrAvgVar = avgOfArrayItems(tcArr3);
        positionvgVar = avgOfArrayItems(tcArr2);

        gscGetDataGetForRange22(
          rowData2,
          clickAvgVar,
          impressionAvgVar,
          ctrAvgVar,
          positionvgVar,
          viewOfChart
        );
      }
    });
  }

  function gscGetDataGetForRange22(
    rowData2,
    clickAvgVar,
    impressionAvgVar,
    ctrAvgVar,
    positionvgVar,
    viewOfChart
  ) {
    APIManager.postAPI(getChartData, rowData2, null, true).then((response) => {
      if (response.status === 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.forEach((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx === 0) {
            keys.forEach((i) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }

          let listArrValues = [];
          values.forEach((i) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });

          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart !== 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i1 = 0; i1 < a.length; i1++) {
            var b1 = a[i1];
            let subArr = [];
            for (var j1 = 1; j1 < b1.length; j1++) {
              subArr.push(b1[j1]);
            }
            mainArr.push(subArr);
          }
        }
        let cols = [];

        listArrIds.forEach((i) => {
          if (i.keys !== "fields") {
            if (viewOfChart === 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });

        setMainChartArr(mainArr);

        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.forEach((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key === "Clicks") {
            tcArr = arr;
          } else if (key === "Ctr") {
            tcArr3 = arr;
          } else if (key === "Impressions") {
            tcArr1 = arr;
          } else if (key === "Position") {
            tcArr2 = arr;
          }
        });

        let clickAvg = clickAvgVar;
        let impressionAvg = impressionAvgVar;
        let ctrAvg = ctrAvgVar;
        let positionvg = positionvgVar;

        let clickCmpAvg = sumOfArrayItems(tcArr);
        let impressionCmpAvg = sumOfArrayItems(tcArr1);
        let ctrCmpAvg = avgOfArrayItems(tcArr3);
        let positionCmpvg = avgOfArrayItems(tcArr2);

        let pclickAvg =
          clickAvg < clickCmpAvg
            ? (100 * (clickCmpAvg - clickAvg)) / clickCmpAvg
            : (100 * (clickAvg - clickCmpAvg)) / clickAvg;

        let pimpressionAvg =
          impressionAvg < impressionCmpAvg
            ? (100 * (impressionCmpAvg - impressionAvg)) / impressionCmpAvg
            : (100 * (impressionAvg - impressionCmpAvg)) / impressionAvg;

        let pctrAvg =
          ctrAvg < ctrCmpAvg
            ? (100 * (ctrCmpAvg - ctrAvg)) / ctrCmpAvg
            : (100 * (ctrAvg - ctrCmpAvg)) / ctrAvg;

        let ppositionvg =
          positionvg < positionCmpvg
            ? (100 * (positionCmpvg - positionvg)) / positionCmpvg
            : (100 * (positionvg - positionCmpvg)) / positionvg;

        let listData = [
          {
            avgData: pimpressionAvg.toFixed(2),
            user: "Impressions",
            data: fixTwoPlaceDecimal(impressionAvg),
            compareData: fixTwoPlaceDecimal(impressionCmpAvg),
            isHighData: impressionAvg < impressionCmpAvg,
          },
          {
            avgData: pclickAvg.toFixed(2),
            user: "Clicks",
            data: fixTwoPlaceDecimal(clickAvg),
            compareData: fixTwoPlaceDecimal(clickCmpAvg),
            isHighData: clickAvg < clickCmpAvg,
          },
          {
            avgData: pctrAvg.toFixed(2),
            user: "Average CTR",
            data: fixTwoPlaceDecimal(ctrAvg * 100),
            compareData: fixTwoPlaceDecimal(ctrCmpAvg * 100),
            isHighData: ctrAvg < ctrCmpAvg,
          },
          {
            avgData: ppositionvg.toFixed(2),
            user: "Average Position",
            data: fixTwoPlaceDecimal(positionvg),
            compareData: fixTwoPlaceDecimal(positionCmpvg),
            isHighData: positionvg < positionCmpvg,
          },
        ];

        if (viewOfChart === 0) {
          setCardListData(listData);
        }
      }
    });
  }

  function gscGetChartDataCompare(rowData) {
    let viewOfChart1 = rowData.View;
    let maxImp;
    let maxClk;
    let maxPos;
    let maxCtr;
    let maxImp1;
    let maxClk1;
    let maxPos1;
    let maxCtr1;
    let list = [];
    let list1 = [];
    let list2 = [];
    let list3 = [];

    APIManager.postAPI(getChartDataCompare, rowData, null, true).then((response) => {
      if (response.status === 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.tableContent.forEach((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx === 0) {
            keys.forEach((i) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }

          setListArrDateIds(listArrIds);

          let listArrValues = [];
          values.forEach((i) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });

          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart1 !== 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i1 = 0; i1 < a.length; i1++) {
            var b1 = a[i1];
            let subArr = [];
            for (var j1 = 1; j1 < b1.length; j1++) {
              subArr.push(b1[j1]);
            }
            mainArr.push(subArr);
          }
        }

        let cols = [];

        listArrIds.forEach((i) => {
          if (i.keys !== "fields") {
            if (viewOfChart1 === 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });
        let titleText = "";
        if (viewOfChart1 === 0) {
          titleText = "Days";
        } else if (viewOfChart1 === 1) {
          titleText = "Weeks";
        } else if (viewOfChart1 === 2) {
          titleText = "Months";
        }
        setMainChartArr(mainArr);
        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.forEach((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key === "Clicks") {
            tcArr = arr;
          } else if (key === "Ctr") {
            tcArr3 = arr;
          } else if (key === "Impressions") {
            tcArr1 = arr;
          } else if (key === "Position") {
            tcArr2 = arr;
          }
        });

        let tcArr21 = [];
        tcArr2.forEach((item) => {
          tcArr21.push(item.toFixed(2));
        });
        let tcArr31 = [];
        tcArr3.forEach((item) => {
          tcArr31.push((item * 100).toFixed(2));
        });

        maxClk = Math.max(...tcArr) * 0.3 + Math.max(...tcArr); //click
        maxImp = Math.max(...tcArr1) * 0.3 + Math.max(...tcArr1); //imp
        maxCtr = Math.max(...tcArr31) * 0.3 + Math.max(...tcArr31); //ctr
        maxPos = Math.max(...tcArr21) * 0.3 + Math.max(...tcArr21); //pos     

        list1.push({
          name: "Total Clicks",
          data: tcArr,
          // color: "#3366cc",
        })
        list.push({
          name: "Total Impressions",
          data: tcArr1,
          color: "#008FFB",
        })
        list2.push({
          name: "Position",
          data: tcArr21,
          // color: "#C0504E",
        })
        list3.push({
          name: "Average CTR",
          data: tcArr31,
          // color: "#77A033",
        })
        let listArrDatas1 = [];
        let listArrIds1 = [];
        response.data.compareTableContent.forEach((item, idx) => {
          let keys1 = Object.keys(item);
          let values1 = Object.values(item);
          if (idx === 0) {
            keys1.forEach((i) => {
              listArrIds1.push({
                keys: i,
                values: [],
              });
            });
          }

          setListArrDateIds(listArrIds1);

          let listArrValues1 = [];
          values1.forEach((i) => {
            if (i == null) {
              listArrValues1.push(0);
            } else {
              listArrValues1.push(i);
            }
          });
          listArrDatas1.push(listArrValues1);
        });
        var a1 = listArrDatas1;
        let mainArr1 = [];
        if (viewOfChart1 !== 0) {
          for (var x = 0; x < a1.length; x++) {
            var y = a1[x];
            let subArr1 = [];
            for (var z = 0; z < y.length - 1; z++) {
              subArr1.push(y[z]);
            }
            mainArr1.push(subArr1);
          }
        } else {
          for (var i_1 = 0; i_1 < a1.length; i_1++) {
            var b_1 = a1[i_1];
            let subArr1 = [];
            for (var j_1 = 1; j_1 < b_1.length; j_1++) {
              subArr1.push(b_1[j_1]);
            }
            mainArr1.push(subArr1);
          }
        }
        let cols1 = [];
        listArrIds1.forEach((i) => {
          if (i.keys !== "fields") {
            if (viewOfChart1 === 0) {
              cols1.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols1.push(i.keys);
            }
          }
        });
        let _tcArr = [];
        let _tcArr1 = [];
        let _tcArr2 = [];
        let _tcArr3 = [];

        listArrDatas1.forEach((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key === "Clicks") {
            _tcArr = arr;
          } else if (key === "Ctr") {
            _tcArr3 = arr;
          } else if (key === "Impressions") {
            _tcArr1 = arr;
          } else if (key === "Position") {
            _tcArr2 = arr;
          }
        });

        let _tcArr21 = [];
        _tcArr2.forEach((item) => {
          _tcArr21.push(item.toFixed(2));
        });
        let _tcArr31 = [];
        _tcArr3.forEach((item) => {
          _tcArr31.push((item * 100).toFixed(2));
        });

        maxClk1 = Math.max(..._tcArr) * 0.3 + Math.max(..._tcArr); //click
        maxImp1 = Math.max(..._tcArr1) * 0.3 + Math.max(..._tcArr1); //imp
        maxCtr1 = Math.max(..._tcArr31) * 0.3 + Math.max(..._tcArr31); //ctr
        maxPos1 = Math.max(..._tcArr21) * 0.3 + Math.max(..._tcArr21); //pos

        list1.push({
          name: "Total Clicks",
          data: _tcArr,
          // color: "#3366cc",
        })
        list.push({
          name: "Total Impressions",
          data: _tcArr1,
          // color: "#008FFB",
        })

        list2.push({
          name: "Position",
          data: _tcArr21,
          // color: "#C0504E",
        })
        list3.push({
          name: "Average CTR",
          data: _tcArr31,
          // color: "#77A033",
        })
        let categories = [];

        if (list[0].data.length > list[1].data.length) {
          for (i = 1; i <= list[0].data.length; i++) {
            categories.push(i)
          }
        }
        else {
          for (i = 1; i <= list[1].data.length; i++) {
            categories.push(i)
          }
        }

        setObject({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: categories,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxImp > maxImp1 ? maxImp : maxImp1,
            labels: {
              formatter: function (val) {
                return returnMagnitude(val);
              },
            },
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              let date = '-';
              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                return seriesX.findIndex(xData => xData === hoverXaxis);
              });

              let hoverList = '';
              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                if (seriesEachIndex === 0) {
                  if (viewOfChart1 === 0) {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                } else {
                  if (viewOfChart1 === 0) {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                }

              });
              const formatHoverX = hoverXaxis;

              return `<div class="card">
                <!--<div class="card-header p-10">${formatHoverX}</div>-->
                <div class="card-body p-10">
                  ${hoverList}
                </div>
              </div>`;
            },
          },
          markers: {
            size: 5,
          },
        });
        setObject1({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: categories,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxClk > maxClk1 ? maxClk : maxClk1,
            labels: {
              formatter: function (val) {
                return returnMagnitude(val);
              },
            },
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              let date = '-';
              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                return seriesX.findIndex(xData => xData === hoverXaxis);
              });

              let hoverList = '';
              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                if (seriesEachIndex === 0) {
                  if (viewOfChart1 === 0) {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                } else {
                  if (viewOfChart1 === 0) {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                }

              });
              const formatHoverX = hoverXaxis;

              return `<div class="card">
                <!--<div class="card-header p-10">${formatHoverX}</div>-->
                <div class="card-body p-10">
                  ${hoverList}
                </div>
              </div>`;
            },
          },
          markers: {
            size: 5,
          },
        });
        setObject2({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: categories,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxPos > maxPos1 ? maxPos : maxPos1,
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              let date = '-';
              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                return seriesX.findIndex(xData => xData === hoverXaxis);
              });

              let hoverList = '';
              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                if (seriesEachIndex === 0) {
                  if (viewOfChart1 === 0) {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                } else {
                  if (viewOfChart1 === 0) {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                }

              });
              const formatHoverX = hoverXaxis;

              return `<div class="card">
                <!--<div class="card-header p-10">${formatHoverX}</div>-->
                <div class="card-body p-10">
                  ${hoverList}
                </div>
              </div>`;
            },
          },
          markers: {
            size: 5,
          },
        });
        setObject3({
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: categories,
            title: {
              text: titleText,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            min: 0,
            max: maxCtr > maxCtr1 ? maxCtr : maxCtr1,

          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              let date = '-';
              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
              const hoverIndexes = w.globals.seriesX.map(seriesX => {
                return seriesX.findIndex(xData => xData === hoverXaxis);
              });

              let hoverList = '';
              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                if (seriesEachIndex === 0) {
                  if (viewOfChart1 === 0) {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                } else {
                  if (viewOfChart1 === 0) {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = item;
                      }
                    });
                  } else {
                    cols1.forEach((item, index) => {
                      if (dataPointIndex === index) {
                        date = toMonthName(item, titleText);
                      }
                    });
                  }

                  if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] !== undefined) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                }

              });
              const formatHoverX = hoverXaxis;

              return `<div class="card">
                <!--<div class="card-header p-10">${formatHoverX}</div>-->
                <div class="card-body p-10">
                  ${hoverList}
                </div>
              </div>`;
            },
          },
          markers: {
            size: 5,
          },
        });
        setSeries(list)
        setSeries1(list1)
        setSeries2(list2)
        setSeries3(list3)
      }
    });
  }

  const [GoogleSearchTab, setGoogleSearchTab] = useState(true);
  const [isDateRage, setIsDateRage] = useState(true);
  const [ChartView, setChartView] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
    },
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  function getTableData(rowData, size) {
    setColumn1(gscHeading);
    APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        let i = 0;
        let j = 0;
        const clickQueryList = [];
        const impressionQueryList = [];
        const ctrQuerList = [];
        const positionQueryList = [];
        const clickPageList = [];
        const impressionPageList = [];
        const ctrPageList = [];
        const positionPageList = [];
        const queryList = [];
        const pageList = [];
        let filterList = [];
        let dataList = [];

        response.data.tableContentCount.forEach((item) => {
          setTableParams({
            pagination: {
              current: rowData.currentPage,
              pageSize: rowData.pageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
            },
          });
        });

        response.data.tableContent.forEach((item, index) => {
          dataList.push({
            key: index,
            keyword: item.keyword,
            impression: fixTwoPlaceDecimal(item.impression),
            click: fixTwoPlaceDecimal(item.click),
            ctr: fixTwoPlaceDecimal(item.ctr),
            position: fixTwoPlaceDecimal(item.position),
          });

          if (index < size) {
            filterList.push({
              key: index,
              keyword: item.keyword,
              impression: fixTwoPlaceDecimal(item.impression),
              click: fixTwoPlaceDecimal(item.click),
              ctr: fixTwoPlaceDecimal(item.ctr) + "%",
              position: fixTwoPlaceDecimal(item.position),
            });
          }

          clickQueryList.push(item.click);
          impressionQueryList.push(item.impression);
          ctrQuerList.push(item.ctr);
          positionQueryList.push(item.position);
          queryList.push({
            key: (i = +1),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyword,
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          });

          clickPageList.push(item.click);
          impressionPageList.push(item.impression);
          ctrPageList.push(item.ctr);
          positionPageList.push(item.position);
          pageList.push({
            key: (j = +1),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyword,
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          });       
        });
        setGSCTableColData([]);
        setGSCTableColData(filterList);
      }
    });
  }

  const handleTableChange = (pagination, filter, sorter) => {
    let sortListVar = [];
    setSortList([]);
    if (sorter.length > 0) {
      sorter.forEach((item) => {
        sortListVar.push({
          columnName: item.columnKey,
          order: item.order
        })
      })
    }
    else {
      sortListVar.push({
        columnName: sorter.columnKey,
        order: sorter.order
      })
    }

    if (sortListVar.length > 0) {
      setSortList(sortListVar)
    }

    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);

    let dimension = dimensionsSelected1.value;
    var data = "";
    if (dimension === 1) {
      data = "Top Pages";
    } else if (dimension === 0) {
      data = "Top Queries";
    } else if (dimension === 2) {
      data = "Country";
    } else if (dimension === 3) {
      data = "Device";
    } else if (dimension === 4) {
      data = "Search Appearance";
    } else if (dimension === 5) {
      data = "Date";
    }

    setgscHeading(data);
    let headings = {
      title: data,
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        multiple: 3,
      },
    };

    if (isDateRage) {
      let rowData1 = {
        projectId: localStorage.getItem("projectGId"),
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        Dimension: dimension,
        sorter: sortListVar,
        isSorter: false
      };
      let rowData = getRowDataFun(rowData1)
      let colList = [];
      colList.push(headings);
      GSCTableCol1.forEach((item, index) => {
        if (index !== 0) colList.push(item);
      });

      setGSCTableCol1(colList);

      APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
        if (response && response.status === 200) {
          let filterList = [];
          response.data.tableContentCount.forEach((item) => {
            setTableParams({
              pagination: {
                current: rowData.currentPage,
                pageSize: rowData.pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: item.totalCount,
              },
            });
          });

          response.data.tableContent.forEach((item, index) => {
            if (index < pagination.pageSize) {
              filterList.push({
                key: index,
                keyword: item.keyword,
                impression: fixTwoPlaceDecimal(item.impression),
                click: fixTwoPlaceDecimal(item.click),
                ctr: fixTwoPlaceDecimal(item.ctr) + "%",
                position: fixTwoPlaceDecimal(item.position),
              });
            }
          });
          setGSCTableColData([]);
          setGSCTableColData(filterList);
        }
      });
    } else {
      let rowData1 = {
        projectId: localStorage.getItem("projectGId"),
        GSCDateRange: {
          from: convertDate(state.selection1.startDate),
          to: convertDate(state.selection1.endDate),
        },
        GSCCompareToRange: {
          from: convertDate(state.selection2.startDate),
          to: convertDate(state.selection2.endDate),
        },
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        Dimension: dimensionsSelected1.value,
        sorter: sortListVar,
        isSorter: true
      };
      let rowData = getRowDataFun(rowData1)
      let colList = [];
      colList.push(headings);
      GSCTableCompCol1.forEach((item, index) => {
        if (index !== 0) {
          colList.push(item);
        }
      });
      setGSCTableCompCol1(colList);
      APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
        if (response && response.status === 200) {
          let filterList = [];

          response.data.compareTableContentCount.forEach((item) => {
            setTableParams({
              pagination: {
                current: rowData.currentPage,
                pageSize: rowData.pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: item.totalCount,
              },
            });
          });
          let date_1 = convertDate(state.selection1.startDate) +
            " To " +
            convertDate(state.selection1.endDate);
          let date_2 = convertDate(state.selection2.startDate) +
            " To " +
            convertDate(state.selection2.endDate)
          response.data.compareTableContent.forEach((item, index) => {
            if (index < pagination.pageSize) {
              filterList.push({
                key: index,
                [gscHeading]: item.keyWord,
                ["Impressions " + date_1]: fixTwoPlaceDecimal(item.impression1),
                ["Impressions " + date_2]: fixTwoPlaceDecimal(item.impression2),
                ["Impressions Difference"]: fixTwoPlaceDecimal(item.impressionDifference),
                ["Clicks " + date_1]: fixTwoPlaceDecimal(item.clicks1),
                ["Clicks " + date_2]: fixTwoPlaceDecimal(item.clicks2),
                ["Clicks Difference"]: fixTwoPlaceDecimal(item.clickDifference),
                ["CTR " + date_1]: fixTwoPlaceDecimal(item.ctr1) + "%",
                ["CTR " + date_2]: fixTwoPlaceDecimal(item.ctr2) + "%",
                ["CTR Difference"]: fixTwoPlaceDecimal(item.ctrDifference) + "%",
                ["Average Position " + date_1]: fixTwoPlaceDecimal(item.position1),
                ["Average Position " + date_2]: fixTwoPlaceDecimal(item.position2),
                ["Average Position Difference"]: fixTwoPlaceDecimal(item.positionDifference),
                keyword: item.keyWord,
                clicks1: fixTwoPlaceDecimal(item.clicks1),
                clicks2: fixTwoPlaceDecimal(item.clicks2),
                clickDifference: fixTwoPlaceDecimal(item.clickDifference),
                ctr1: fixTwoPlaceDecimal(item.ctr1) + "%",
                ctr2: fixTwoPlaceDecimal(item.ctr2) + "%",
                ctrDifference: fixTwoPlaceDecimal(item.ctrDifference) + "%",
                impression1: fixTwoPlaceDecimal(item.impression1),
                impression2: fixTwoPlaceDecimal(item.impression2),
                impressionDifference: fixTwoPlaceDecimal(
                  item.impressionDifference
                ),
                position1: fixTwoPlaceDecimal(item.position1),
                position2: fixTwoPlaceDecimal(item.position2),
                positionDifference: fixTwoPlaceDecimal(item.positionDifference),
              });
            }
          });

          setGSCTableCompColData1(filterList);

        }
      });
    }
  };

  function dropdownChange(selected) {
    setselectedQuery(selected);
    if (selected === "0") {
      setTextBoxShow(false);
      setTextShow(false);
      setRegxTextBoxShow(false);
    } else if (selected >= "1" && selected <= "3") {
      setTextBoxShow(true);
      setTextShow(false);
      setRegxTextBoxShow(false);
    } else if (selected === "4") {
      setTextShow(true);
      setTextBoxShow(false);
      setRegxTextBoxShow(true);
    }
  }

  function dropdownChange1(selected) {
    setSelectedPages(selected);
    if (selected === "0") {
      setTextBoxShow1(false);
      setTextShow1(false);
      setRegxTextBoxShow1(false);
    } else if (selected >= "1" && selected <= "3") {
      setTextBoxShow1(true);
      setTextShow1(false);
      setRegxTextBoxShow1(false);
    } else if (selected === "4") {
      setTextShow1(true);
      setTextBoxShow1(false);
      setRegxTextBoxShow1(true);
    }
  }

  function getCountryList() {
    APIManager.getAPI(getCountryCode).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let obj1 = {
          code: "Select",
          name: "Select"
        };
        list.push(obj1);
        response.data.forEach((item) => {
          let obj2 = {
            code: item.countryCode,
            name: item.countryName,
          };
          list.push(obj2);
        });
        setCountryList(list);
        setCountrySelected(list[0]);
      }
    });
  }

  function getClassName(view) {
    switch (view) {
      case 0:
        if (ChartView === 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 1:
        if (ChartView === 1) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 2:
        if (ChartView === 2) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      default:
        if (ChartView === 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
    }
  }

  function getTableDataCompare(rowdata, size) {
    setColumns(gscHeading);

    APIManager.postAPI(GetTableData, rowdata, null, true).then((response) => {
      if (response && response.status === 200) {

        let filterList = [];
        response.data.compareTableContentCount.forEach((item) => {
          setTableParams({
            pagination: {
              current: rowdata.currentPage,
              pageSize: rowdata.pageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
            },
          });
        });
        let date_1 = convertDate(state.selection1.startDate) +
          " To " +
          convertDate(state.selection1.endDate);
        let date_2 = convertDate(state.selection2.startDate) +
          " To " +
          convertDate(state.selection2.endDate)
        response.data.compareTableContent.forEach((item, index) => {
          if (index < size) {
            filterList.push({
              key: index,
              [gscHeading]: item.keyWord,
              ["Impressions " + date_1]: fixTwoPlaceDecimal(item.impression1),
              ["Impressions " + date_2]: fixTwoPlaceDecimal(item.impression2),
              ["Impressions Difference"]: fixTwoPlaceDecimal(item.impressionDifference),
              ["Clicks " + date_1]: fixTwoPlaceDecimal(item.clicks1),
              ["Clicks " + date_2]: fixTwoPlaceDecimal(item.clicks2),
              ["Clicks Difference"]: fixTwoPlaceDecimal(item.clickDifference),
              ["CTR " + date_1]: fixTwoPlaceDecimal(item.ctr1) + "%",
              ["CTR " + date_2]: fixTwoPlaceDecimal(item.ctr2) + "%",
              ["CTR Difference"]: fixTwoPlaceDecimal(item.ctrDifference) + "%",
              ["Average Position " + date_1]: fixTwoPlaceDecimal(item.position1),
              ["Average Position " + date_2]: fixTwoPlaceDecimal(item.position2),
              ["Average Position Difference"]: fixTwoPlaceDecimal(item.positionDifference),

              keyword: item.keyWord,
              clicks1: fixTwoPlaceDecimal(item.clicks1),
              clicks2: fixTwoPlaceDecimal(item.clicks2),
              clickDifference: fixTwoPlaceDecimal(item.clickDifference),
              ctr1: fixTwoPlaceDecimal(item.ctr1) + "%",
              ctr2: fixTwoPlaceDecimal(item.ctr2) + "%",
              ctrDifference: fixTwoPlaceDecimal(item.ctrDifference) + "%",
              impression1: fixTwoPlaceDecimal(item.impression1),
              impression2: fixTwoPlaceDecimal(item.impression2),
              impressionDifference: fixTwoPlaceDecimal(
                item.impressionDifference
              ),
              position1: fixTwoPlaceDecimal(item.position1),
              position2: fixTwoPlaceDecimal(item.position2),
              positionDifference: fixTwoPlaceDecimal(item.positionDifference),
            });
          }
        });
        setGSCTableCompColData1(filterList);

        const queryList = [];
        const pageList = [];
        const queryCmpList = [];
        const pageCmpList = [];
        const clickQueryList = [];
        const impressionQueryList = [];
        const ctrQueryList = [];
        const positionQueryList = [];
        const clickPageList = [];
        const impressionPageList = [];
        const ctrPageList = [];
        const positionPageList = [];
        const clickCmpQueryList = [];
        const impressionCmpQueryList = [];
        const ctrCmpQueryList = [];
        const positionCmpQueryList = [];
        const clickCmpPageList = [];
        const impressionCmpPageList = [];
        const ctrCmpPageList = [];
        const positionCmpPageList = [];

        response.data.tableContent.forEach((item) => {
          clickQueryList.push(item.click);
          impressionQueryList.push(item.impression);
          ctrQueryList.push(item.ctr);
          positionQueryList.push(item.position);

          queryList.push({
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyword,
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          });
          clickPageList.push(item.click);
          impressionPageList.push(item.impression);
          ctrPageList.push(item.ctr);
          positionPageList.push(item.position);

          pageList.push({
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyword,
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          });
        });

        let i1 = 0;
        let i2 = 0;
        response.data.compareTableContent.forEach((item, index) => {
          clickCmpQueryList.push(item.clicks2);
          impressionCmpQueryList.push(item.impression2);
          ctrCmpQueryList.push(item.ctr2);
          positionCmpQueryList.push(item.position2);
          let obj = {
            key: (i1 += 1),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyWord,
            click: "",
            impression: "",
            ctr: "",
            position: "",
          };
          queryCmpList.push(obj);

          let obj1 = {
            key: (i1 += 1),
            keyword:
              mmDDyyyyFormateForForm22(rowdata.GSCDateRange.from) +
              " - " +
              mmDDyyyyFormateForForm22(rowdata.GSCDateRange.to),
            type: response.data.tableContent[index].type,
            projectId: localStorage.getItem("projectGId"),

            click: response.data.tableContent[index].click,
            impression: response.data.tableContent[index].impression,
            ctr: response.data.tableContent[index].ctr,
            position: response.data.tableContent[index].position,
          };

          queryCmpList.push(obj1);
          let obj2 = {
            key: (i1 += 1),
            keyword:
              mmDDyyyyFormateForForm22(rowdata.GSCCompareToRange.from) +
              " - " +
              mmDDyyyyFormateForForm22(rowdata.GSCCompareToRange.to),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          };

          queryCmpList.push(obj2);

          clickCmpPageList.push(item.click);
          impressionCmpPageList.push(item.impression);
          ctrCmpPageList.push(item.ctr);
          positionCmpPageList.push(item.position);

          let obj3 = {
            key: (i2 += 1),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            keyword: item.keyWord,
            click: "",
            impression: "",
            ctr: "",
            position: "",
          };
          pageCmpList.push(obj3);

          let obj4 = {
            key: (i2 += 1),
            keyword:
              mmDDyyyyFormateForForm22(rowdata.GSCDateRange.from) +
              " - " +
              mmDDyyyyFormateForForm22(rowdata.GSCDateRange.to),
            type: response.data.tableContent[index].type,
            projectId: localStorage.getItem("projectGId"),

            click: response.data.tableContent[index].click,
            impression: response.data.tableContent[index].impression,
            ctr: response.data.tableContent[index].ctr,
            position: response.data.tableContent[index].position,
          };

          pageCmpList.push(obj4);
          let obj5 = {
            key: (i2 += 1),
            keyword:
              mmDDyyyyFormateForForm22(rowdata.GSCCompareToRange.from) +
              " - " +
              mmDDyyyyFormateForForm22(rowdata.GSCCompareToRange.to),
            type: item.type,
            projectId: localStorage.getItem("projectGId"),
            click: item.click,
            impression: item.impression,
            ctr: item.ctr,
            position: item.position,
          };
          pageCmpList.push(obj5);
        });
      }
    });
  }

  function setColumn1(value) {
    let columns1 = [
      {
        title: value ? value : gscHeading,
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Clicks</div>,
        dataIndex: "click",
        key: "click",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Impressions</div>,
        dataIndex: "impression",
        key: "impression",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>CTR</div>,
        dataIndex: "ctr",
        key: "ctr",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Average Position</div>,
        dataIndex: "position",
        key: "position",
        sorter: {
          multiple: 3,
        },
      },
    ];
    let col1 = [];

    columns1.forEach((item, index) => {
      if (index === 0) {
        col1.push(item);
      }
      if (item.dataIndex === "impression") {
        col1.push(item);
      }
    });
    setGSCTableCol(columns1);
    setGSCTableCol1(col1);
  }

  function setColumns(value) {

    setDate1(
      convertDate(state.selection1.startDate) +
      " To " +
      convertDate(state.selection1.endDate));
    setDate2(
      convertDate(state.selection2.startDate) +
      " To " +
      convertDate(state.selection2.endDate))
    let date_1 = convertDate(state.selection1.startDate) +
      " To " +
      convertDate(state.selection1.endDate);
    let date_2 = convertDate(state.selection2.startDate) +
      " To " +
      convertDate(state.selection2.endDate);
    let columnsComp = [
      {
        title: value ? value : gscHeading,
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          multiple: 3,
        },
      },

      {
        title: (
          <div>
            Impressions
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_1}</div>
          </div>
        ),
        dataIndex: "impression1",
        key: "impression1",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            Impressions
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_2}</div>
          </div>
        ),
        dataIndex: "impression2",
        key: "impression2",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Impressions Difference</div>,
        dataIndex: "impressionDifference",
        key: "impressionDifference",
        sorter: {
          multiple: 3,
        },
        render: (val) => (
          <div className={getEstimatedClicksColor(val)}>{val}</div>
        ),
      },
      {
        title: (
          <div>
            Clicks
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_1}</div>
          </div>
        ),
        dataIndex: "clicks1",
        key: "clicks1",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            Clicks
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_2}</div>
          </div>
        ),
        dataIndex: "clicks2",
        key: "clicks2",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Clicks Difference</div>,
        dataIndex: "clickDifference",
        key: "clickDifference",
        sorter: {
          multiple: 3,
        },
        render: (val) => (
          <div className={getEstimatedClicksColor(val)}>{val}</div>
        ),
      },
      {
        title: (
          <div>
            CTR
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_1}</div>
          </div>
        ),
        dataIndex: "ctr1",
        key: "ctr1",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            CTR
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_2}</div>
          </div>
        ),
        dataIndex: "ctr2",
        key: "ctr2",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>CTR Difference</div>,
        dataIndex: "ctrDifference",
        key: "ctrDifference",
        sorter: {
          multiple: 3,
        },
        render: (val) => (
          <div className={getEstimatedClicksColor(val)}>{val}</div>
        ),
      },
      {
        title: (
          <div>
            Average Position
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_1}</div>
          </div>
        ),
        dataIndex: "position1",
        key: "position1",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            Average Position
            <br />
            <div style={{ fontSize: 10 + "px" }}>
              {date_2}</div>
          </div>
        ),
        dataIndex: "position2",
        key: "position2",
        sorter: {
          multiple: 3,
        },
      },
      {
        title: <div>Average Position Difference</div>,
        dataIndex: "positionDifference",
        key: "positionDifference",
        sorter: {
          multiple: 3,
        },
        render: (val) => (
          <div className={getEstimatedClicksColor(val)}>{val}</div>
        ),
      },
    ];
    let col1 = [];
    columnsComp.forEach((item, index) => {
      if (index === 0) {
        col1.push(item);
      }
      if (item.dataIndex === "impression1") {
        col1.push(item);
      }
      if (item.dataIndex === "impression2") {
        col1.push(item);
      }
      if (item.dataIndex === "impressionDifference") {
        col1.push(item);
      }
    });

    setGSCTableCompCol(columnsComp);
    setGSCTableCompCol1(col1);
  }

  function handleChangeMetrics(selected) {
    let col1 = [];
    let selectedData = selected;
    setMetricsSelected(selected);
    let headings = {
      title: gscHeading,
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        multiple: 3,
      },
    };
    if (isDateRage) {
      let newList = [];
      newList.push(headings);
      GSCTableCol.forEach((item, index) => {
        if (index !== 0) {
          newList.push(item);
        }
      });
      newList.forEach((item1, index) => {
        if (index === 0) {
          col1.push(item1);
        }
      });
      selectedData.forEach((item) => {
        newList.forEach((item1) => {
          if (item1.dataIndex === item.value) {
            col1.push(item1);
          }
        });
      });
      setGSCTableCol1(col1);
    } else {
      let col2 = [];
      let newList1 = [];
      newList1.push(headings);
      GSCTableCompCol.forEach((item, index) => {
        if (index !== 0) {
          newList1.push(item);
        }
      });
      newList1.forEach((item1, index) => {
        if (index === 0) {
          col2.push(item1);
        }
      });
      newList1.forEach((item1) => {
        selectedData.forEach((item) => {
          if (item.value === "impression") {
            if (
              item1.dataIndex === "impression1" ||
              item1.dataIndex === "impression2" ||
              item1.dataIndex === "impressionDifference"
            ) {
              col2.push(item1);
            }
          } else if (item.value === "click") {
            if (
              item1.dataIndex === "clicks1" ||
              item1.dataIndex === "clicks2" ||
              item1.dataIndex === "clickDifference"
            ) {
              col2.push(item1);
            }
          } else if (item.value === "ctr") {
            if (
              item1.dataIndex === "ctr1" ||
              item1.dataIndex === "ctr2" ||
              item1.dataIndex === "ctrDifference"
            ) {
              col2.push(item1);
            }
          } else if (item.value === "position") {
            if (
              item1.dataIndex === "position1" ||
              item1.dataIndex === "position2" ||
              item1.dataIndex === "positionDifference"
            ) {
              col2.push(item1);
            }
          }
        });
      });
      setGSCTableCompCol1(col2);
    }
  }

  function handleDimensionChange(selected) {
    clearSelection()
    setdimensionsSelected1(selected);
    var data = "";
    if (selected.value === 1) {
      data = "Top Pages";
      dropdownChange("0")
      document.querySelector("#QuerySelector").disabled = true;
      document.querySelector("#PageSelector").disabled = false;
      document.querySelector("#DeviceSelector").disabled = true;
      document.querySelector("#CountrySelector").disabled = true;
    } else if (selected.value === 0) {
      data = "Top Queries";
      dropdownChange1("0")
      document.querySelector("#QuerySelector").disabled = false;
      document.querySelector("#PageSelector").disabled = true;
      document.querySelector("#DeviceSelector").disabled = true;
      document.querySelector("#CountrySelector").disabled = true;
    } else if (selected.value === 2) {
      data = "Country";
      dropdownChange("0")
      dropdownChange1("0")
      document.querySelector("#QuerySelector").disabled = true;
      document.querySelector("#PageSelector").disabled = true;
      document.querySelector("#DeviceSelector").disabled = true;
      document.querySelector("#CountrySelector").disabled = false;
    } else if (selected.value === 3) {
      data = "Device";
      dropdownChange("0")
      dropdownChange1("0")
      document.querySelector("#QuerySelector").disabled = true;
      document.querySelector("#PageSelector").disabled = true;
      document.querySelector("#DeviceSelector").disabled = false;
      document.querySelector("#CountrySelector").disabled = true;
    } else if (selected.value === 4) {
      data = "Search Appearance";
    } else if (selected.value === 5) {
      data = "Date";
    }
    setgscHeading(data);
    let headings = {
      title: data,
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        multiple: 3,
      },
    };
    if (isDateRage) {
      let colList = [];
      colList.push(headings);
      GSCTableCol1.forEach((item, index) => {
        if (index !== 0) colList.push(item);
      });
      setGSCTableCol1(colList);
      handleDimensionChangeDate(selected);
    } else {
      let colList = [];
      colList.push(headings);
      GSCTableCompCol1.forEach((item, index) => {
        if (index !== 0) {
          colList.push(item);
        }
      });
      setGSCTableCompCol1(colList);
      handleDimensionChangeDateComp(selected);
    }
  }

  function handleDimensionChangeDate(selected) {
    clearSelection()
    let valueData = selected.value;
    let rowData1 = {
      projectId: localStorage.getItem("projectGId"),
      GSCDateRange: {
        from: convertDate(state.selection1.startDate),
        to: convertDate(state.selection1.endDate),
      },
      currentPage: pageNumber,
      pageSize: pageSize,
      Dimension: valueData,
      sorter: sortList,
      isSorter: false
    };

    let rowData = getRowDataFun(rowData1)

    APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        let filterList = [];

        response.data.tableContentCount.forEach((item) => {
          setTableParams({
            pagination: {
              current: rowData.currentPage,
              pageSize: rowData.pageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
            },
          });
        });

        response.data.tableContent.forEach((item, index) => {
          if (index < pageSize) {
            filterList.push({
              key: index,
              keyword: item.keyword,
              impression: fixTwoPlaceDecimal(item.impression),
              click: fixTwoPlaceDecimal(item.click),
              ctr: fixTwoPlaceDecimal(item.ctr) + "%",
              position: fixTwoPlaceDecimal(item.position),
            });
          }
        });
        setGSCTableColData([]);
        setGSCTableColData(filterList);
      }
    });
  }

  function handleDimensionChangeDateComp(selected) {
    let valueData = selected.value;
    let rowData1 = {
      projectId: localStorage.getItem("projectGId"),
      GSCDateRange: {
        from: convertDate(state.selection1.startDate),
        to: convertDate(state.selection1.endDate),
      },
      GSCCompareToRange: {
        from: convertDate(state.selection2.startDate),
        to: convertDate(state.selection2.endDate),
      },
      currentPage: pageNumber,
      pageSize: pageSize,
      Dimension: valueData,
      sorter: sortList,
      isSorter: true
    };

    let rowData = getRowDataFun(rowData1)

    APIManager.postAPI(GetTableData, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        let filterList = [];

        response.data.compareTableContentCount.forEach((item) => {
          setTableParams({
            pagination: {
              current: rowData.currentPage,
              pageSize: rowData.pageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
            },
          });
        });
        let date_1 = convertDate(state.selection1.startDate) +
          " To " +
          convertDate(state.selection1.endDate);
        let date_2 = convertDate(state.selection2.startDate) +
          " To " +
          convertDate(state.selection2.endDate)
        response.data.compareTableContent.forEach((item, index) => {
          if (index < pageSize) {
            filterList.push({
              key: index,
              [gscHeading]: item.keyWord,
              ["Impressions " + date_1]: fixTwoPlaceDecimal(item.impression1),
              ["Impressions " + date_2]: fixTwoPlaceDecimal(item.impression2),
              ["Impressions Difference"]: fixTwoPlaceDecimal(item.impressionDifference),
              ["Clicks " + date_1]: fixTwoPlaceDecimal(item.clicks1),
              ["Clicks " + date_2]: fixTwoPlaceDecimal(item.clicks2),
              ["Clicks Difference"]: fixTwoPlaceDecimal(item.clickDifference),
              ["CTR " + date_1]: fixTwoPlaceDecimal(item.ctr1) + "%",
              ["CTR " + date_2]: fixTwoPlaceDecimal(item.ctr2) + "%",
              ["CTR Difference"]: fixTwoPlaceDecimal(item.ctrDifference) + "%",
              ["Average Position " + date_1]: fixTwoPlaceDecimal(item.position1),
              ["Average Position " + date_2]: fixTwoPlaceDecimal(item.position2),
              ["Average Position Difference"]: fixTwoPlaceDecimal(item.positionDifference),
              keyword: item.keyWord,
              clicks1: fixTwoPlaceDecimal(item.clicks1),
              clicks2: fixTwoPlaceDecimal(item.clicks2),
              clickDifference: fixTwoPlaceDecimal(item.clickDifference),
              ctr1: fixTwoPlaceDecimal(item.ctr1) + "%",
              ctr2: fixTwoPlaceDecimal(item.ctr2) + "%",
              ctrDifference: fixTwoPlaceDecimal(item.ctrDifference) + "%",
              impression1: fixTwoPlaceDecimal(item.impression1),
              impression2: fixTwoPlaceDecimal(item.impression2),
              impressionDifference: fixTwoPlaceDecimal(
                item.impressionDifference
              ),
              position1: fixTwoPlaceDecimal(item.position1),
              position2: fixTwoPlaceDecimal(item.position2),
              positionDifference: fixTwoPlaceDecimal(item.positionDifference),
            });
          }
        });
        setGSCTableCompColData1(filterList);
      }
    });
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() === "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() === "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() === "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleImpressionsClicksReport
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          applyBtn.current.click();
          setReceiver("");
          setSubject("Impressions & Clicks Reports");
          setEmailMessage(EmailMessage1);
          if (theme === 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection2",
    },
  });

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0)

  function getRowDataFun(rowData) {
    if (rowData.Dimension === 0) {
      rowData = {
        ...rowData,
        Queries: queryListMain,
      }
    }
    else if (rowData.Dimension === 1) {
      rowData = {
        ...rowData,
        pages: pageListMain,
      }
    }
    else if (rowData.Dimension === 2) {
      rowData = {
        ...rowData,
        Country: countryListMain,
      }
    }
    else if (rowData.Dimension === 3) {
      rowData = {
        ...rowData,
        DeviceType: deviceSelected,
      }
    }
    return rowData;
  }
  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {noConfig ? (
            <>
              <div class="message text-center">
                <h4>
                  The Impressions & Clicks configuration is not set. <br />
                  Please contact your administrator to set configuration.
                </h4>
              </div>
            </>
          ) : (
            <div className="row">
              <Tabs>
                <div className="portlet">
                  <div className="portlet-header portlet-header-bordered my-3">
                    <div className="col-md-8 col-sm-12">
                      <TabList className="nav nav-lines portlet-nav">
                        <Tab
                          className={
                            GoogleSearchTab
                              ? "nav-item nav-link active"
                              : "nav-item nav-link "
                          }
                          onClick={() => {
                            setGoogleSearchTab(true);
                          }}
                        >
                          Visibility
                        </Tab>
                      </TabList>
                    </div>

                    <div
                      ref={ref}
                      class="col-md-4 col-sm-12 calendar-main d-flex justify-content-end align-items-center ga-calender"
                    >
                      <div className="add-new-btnw">
                        <button
                          className="btn btn-primary"
                          style={{ width: "250px" }}
                          onClick={() => setOpen(!open)}
                        >
                          {
                            !isCompare ?
                              calanderDate(
                                state.selection1?.startDate,
                                state.selection1?.endDate
                              )
                              :
                              calanderDateCompare(
                                state.selection1?.startDate,
                                state.selection1?.endDate,
                                state.selection2?.startDate,
                                state.selection2?.endDate
                              )
                          }
                        </button>
                      </div>

                      {open && (
                        <div id="example-collapse-text-calendar">
                          <Card body className="daterange-picker-card mt-2">
                            <div className="d-flex calendar-flex">
                              <div className="calendar-col">
                                <DateRangePicker
                                  onChange={(item) => {
                                    let isCompareVarNew = localStorage.getItem("isCompareVar");

                                    if (isCompareVarNew === "false") {
                                      let selection23 = {};
                                      if (item?.selection1) {
                                        selection23 = {
                                          selection2: {
                                            startDate: item?.selection1?.startDate,
                                            endDate: item?.selection1?.endDate,
                                            key: "selection2",
                                          },
                                        };
                                      } else if (item?.selection2) {
                                        selection23 = {
                                          selection1: {
                                            startDate: item?.selection2?.startDate,
                                            endDate: item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };
                                      }
                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection23,
                                      });
                                    }

                                    if (isCompareVarNew === "true") {
                                      setState({ ...state, ...item });
                                    }
                                  }}
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={[state?.selection1, state?.selection2]}
                                  direction="horizontal"
                                  ariaLabels={{
                                    dateInput: {
                                      selection1: {
                                        startDate: "start date input of selction 1",
                                        endDate: "end date input of selction 1",
                                      },
                                      selection2: {
                                        startDate: "start date input of selction 2",
                                        endDate: "end date input of selction 2",
                                      },
                                    },

                                    monthPicker: "month picker",
                                    yearPicker: "year picker",
                                    prevButton: "previous month button",
                                    nextButton: "next month button",
                                  }}
                                  maxDate={
                                    new Date(
                                      new Date().getFullYear(),
                                      new Date().getMonth(),
                                      new Date().getDate() - 3
                                    )
                                  }
                                />
                              </div>
                              <div className="mt-3 mt-lg-0 text-center">
                                <label className="mt-10 d-flex ms-3">
                                  <input
                                    className="me-3"
                                    checked={isCompare}
                                    onChange={(e) => {
                                      localStorage.setItem("isCompareVar", e.target.checked);
                                      setIsCompare(e.target.checked);
                                      if (!e.target.checked) {
                                        document.getElementsByClassName('rdrDateDisplayWrapper')[0].children[1].style.display = "none";
                                        setState({
                                          selection1: {
                                            startDate: state.selection1?.startDate,
                                            endDate: state.selection1?.endDate,
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: state.selection1?.startDate,
                                            endDate: state.selection1?.endDate,
                                            key: "selection2",
                                          },
                                        });
                                      } else {
                                        document.getElementsByClassName('rdrDateDisplayWrapper')[0].children[1].style.display = "flex";
                                        setState({
                                          selection1: {
                                            startDate: state.selection1?.startDate,
                                            endDate: state.selection1?.endDate,
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: addDays(new Date(), -9),
                                            endDate: addDays(new Date(), -3),
                                            key: "selection2",
                                          },
                                        });
                                      }
                                    }}
                                    type="checkbox"
                                  />
                                  Compare
                                </label>
                                <button
                                  onClick={() => {
                                    setheadvalues();
                                    setOpen(!open);

                                    let dimeselected = [];
                                    dimensionsSelected?.forEach((item) => {
                                      dimeselected.push(item.label);
                                    });
                                    applyBtn2.current.click();

                                    if (
                                      convertDate(state.selection1.startDate) ===
                                      convertDate(state.selection2.startDate) &&
                                      convertDate(state.selection1.endDate) ===
                                      convertDate(state.selection2.endDate)
                                    ) {
                                      setIsDateRage(true);

                                      let getdata = {
                                        startdate: convertDate(
                                          state.selection1.startDate
                                        ),
                                        enddate: convertDate(state.selection1.endDate),
                                        projectId: localStorage.getItem("projectGId"),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        View: ChartView,
                                      };
                                      gscGetDataGet(getdata);

                                      let rowData = {
                                        projectId: localStorage.getItem("projectGId"),
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        currentPage: pageNumber,
                                        pageSize: pageSize,
                                        Dimension: dimensionsSelected1.value,
                                        sorter: sortList,
                                        isSorter: false
                                      };
                                      let rowData1 = getRowDataFun(rowData)
                                      getTableData(rowData1, pageSize);
                                    } else {
                                      setIsDateRage(false);
                                      let rowData = {
                                        projectId: localStorage.getItem("projectGId"),
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        GSCCompareToRange: {
                                          from: convertDate(state.selection2.startDate),
                                          to: convertDate(state.selection2.endDate),
                                        },
                                        currentPage: pageNumber,
                                        pageSize: pageSize,
                                        Dimension: dimensionsSelected1.value,
                                        sorter: sortList,
                                        isSorter: true
                                      };
                                      let rowData4 = getRowDataFun(rowData)
                                      getTableDataCompare(rowData4, pageSize);

                                      let rowData1 = {
                                        startdate: convertDate(
                                          state.selection1.startDate
                                        ),
                                        enddate: convertDate(state.selection1.endDate),
                                        projectId: localStorage.getItem("projectGId"),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        View: ChartView,
                                      };

                                      let rowData2 = {
                                        startdate: convertDate(
                                          state.selection2.startDate
                                        ),
                                        enddate: convertDate(state.selection2.endDate),
                                        projectId: localStorage.getItem("projectGId"),
                                        Property: propertySelected,
                                        Dimension: dimensionsSelected1.value,
                                        SearchType: SearchTypeSelected,
                                        View: ChartView,
                                      };
                                      gscGetDataGetForRange2(rowData1, rowData2);

                                      let rowData3 = {
                                        projectId: localStorage.getItem("projectGId"),
                                        View: ChartView,
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        GSCCompareToRange: {
                                          from: convertDate(state.selection2.startDate),
                                          to: convertDate(state.selection2.endDate),
                                        },
                                      }

                                      gscGetChartDataCompare(rowData3)
                                    }
                                    setdimensionsSelected1(DimensionsOptions[0]);
                                    setMetricsSelected(MetricsOptions[0]);
                                  }}
                                  className="btn btn-primary ms-3"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                      <div className="d-flex 3-icons">
                        <div className="ms-3">
                          <a
                            type="button"
                            ref={applyBtn2}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></a>
                        </div>
                        <a className="bar-link-icon ms-3" style={{ color: "black" }}>
                          <div class="dropdown">
                            <a
                              type="button"
                              ref={applyBtn}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-filter" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-4 filter-dropdown">
                              <div className="filter-popup gsc-filter">
                                <div className="portlet px-2 py-2">
                                  <div className="col-md-12 my-2">
                                    <div>
                                      <div className="queries">
                                        <h3>Queries</h3>
                                      </div>

                                      <select
                                        id="QuerySelector"
                                        className="w-100"
                                        value={selectedQuery}
                                        onChange={(selected) => {
                                          dropdownChange(selected.target.value);
                                        }}
                                      >
                                        <option value="0">Select</option>
                                        <option value="1">Queries Containing</option>
                                        <option value="2">
                                          Queries Not Containing
                                        </option>
                                        <option value="3">Exact Query</option>
                                        <option value="4">Regex</option>
                                      </select>
                                      {TextShow ? (
                                        <select
                                          className="w-100 mt-3 matches"
                                          value={queryRegx}
                                          onChange={(selected) => {
                                            setqueryRegx(selected.target.value);
                                          }}
                                        >
                                          <option value="0">Matches </option>
                                          <option value="1">Doesn’t match</option>
                                        </select>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {TextBoxShow ? (
                                      <input
                                        className="w-100 mt-3"
                                        type="text"
                                        value={queryText}
                                        onChange={(text) => {
                                          setqueryText(text.target.value);
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}

                                    {regxTextBoxShow ? (
                                      <input
                                        className="w-100 mt-3"
                                        type="text"
                                        value={queryRegxText}
                                        onChange={(text) => {
                                          setqueryRegxText(text.target.value);
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <div>
                                      <div className="queries mt-3">
                                        <h3>Pages</h3>
                                      </div>
                                      <select
                                        id="PageSelector"
                                        className="w-100"
                                        value={selectedPages}
                                        onChange={(selected) => {
                                          dropdownChange1(selected.target.value);
                                        }}
                                      >
                                        <option value="0">Select</option>
                                        <option value="1">Pages Containing</option>
                                        <option value="2">Pages Not Containing</option>
                                        <option value="3">Exact Query</option>
                                        <option value="4">Regex</option>
                                      </select>
                                      {TextShow1 ? (
                                        <select
                                          className="w-100 mt-3 matches"
                                          value={pageRegx}
                                          onChange={(selected) => {
                                            setpageRegx(selected.target.value);
                                          }}
                                        >
                                          <option value="matches">Matches </option>
                                          <option value="doesn't match">
                                            Doesn’t match
                                          </option>
                                        </select>
                                      ) : (
                                        <></>
                                      )}
                                      {TextBoxShow1 ? (
                                        <input
                                          className="w-100 mt-3"
                                          type="text"
                                          value={pagesText}
                                          onChange={(text) => {
                                            setpagesText(text.target.value);
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}

                                      {regxTextBoxShow1 ? (
                                        <input
                                          className="w-100 mt-3"
                                          type="text"
                                          value={pagesRegxText}
                                          onChange={(text) => {
                                            setpagesRegxText(text.target.value);
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <div className="queries mt-3">
                                        <h3>Device</h3>
                                      </div>
                                      <select
                                        id="DeviceSelector"
                                        className="w-100"
                                        value={deviceSelected}
                                        onChange={(text) =>
                                          setdeviceSelected(text.target.value)
                                        }
                                      >
                                        <option value="2">All</option>
                                        <option value="0">Mobile</option>
                                        <option value="1">Desktop</option>
                                        <option value="3">Mobile+Tablet</option>
                                      </select>
                                      <div className="queries mt-3">
                                        <h3>Country</h3>
                                      </div>
                                      <select
                                        id="CountrySelector"
                                        className=" w-100"
                                        required
                                        value={countrySelected}
                                        onChange={(text) =>
                                          setCountrySelected(text.target.value)
                                        }
                                      >
                                        {countryList.map((item) => {
                                          return (
                                            <option value={item.name}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-md-12 text-left mt-3 p-0">
                                      <button
                                        type="button"
                                        className="btn btn-primary me-3"
                                        style={{ width: 70 + "px", height: 30 + "px" }}
                                        onClick={() => {
                                          applyBtn2.current.click();
                                          getFilterviseChartData();
                                          if (selectedQuery === "0" && selectedPages === "0" &&
                                            deviceSelected === "2" && countrySelected.code === "Select") {
                                            clearSelection();
                                          }
                                        }}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-12 my-3"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                        <div className="ms-3  ">
                          <a className="share bar-link-icon" style={{ color: "black" }}>
                            <div class="dropdown">
                              <a
                                type="button"
                                ref={applyBtn}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-share-alt" aria-hidden="true"></i>
                              </a>
                              <form class="dropdown-menu p-4 filter-dropdown">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">From</label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Sender Email Id"
                                          value={Sender}
                                          className="w-100 form-control my-2"
                                          name="Sender"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">To</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Recipient Email Id"
                                          className="w-100 form-control my-2"
                                          value={Receiver}
                                          name="Receiver"
                                          onChange={(text) =>
                                            setReceiver(text.target.value)
                                          }
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageReceiver}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Subject</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Subject"
                                          value={subject}
                                          onChange={(text) => {
                                            setSubject(text.target.value);
                                          }}
                                          className="w-100 form-control my-2"
                                          name="Subject"
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageSubject}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Message</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-10 ">
                                        <textarea
                                          name=""
                                          id=""
                                          cols="80"
                                          rows="7"
                                          className="w-100 form-control my-2 gscmsg"
                                          value={EmailMessage}
                                          onChange={(text) => {
                                            setEmailMessage(text.target.value);
                                          }}
                                        ></textarea>
                                        <div class="ms-3" style={{ color: "red" }}>
                                          {Message}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2"></div>
                                      <div className="col-md-10 my-2">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            if (isValidEmailData()) {
                                              sendEmail();
                                            }
                                          }}
                                        >
                                          {" "}
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-md-12 download-filter">
                            <span
                              class="dropdown"
                              style={{
                                marginRight: 0 + "px",
                                textAlign: "right",
                                display: "block",
                              }}
                            >
                              <button
                                class="outline-btn dropdown-toggle btn btn-primary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-download " aria-hidden="true"></i>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={async () => {
                                    setLoading(true)
                                    const promise = await downloadPDFfile(image, moduleImpressionsClicksReport)
                                    try {
                                      if (promise) {
                                        setLoading(false);
                                      } else {
                                        setLoading(false);
                                      }
                                    } catch (e) {
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  <a class="dropdown-item">PDF</a>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <TabPanel>
                  <PDFExport
                    paperSize="A1"
                    margin="2cm"
                    fileName={moduleImpressionsClicksReport}
                    ref={pdfExportComponent}
                    forcePageBreak=".page-break"
                  >
                    <div ref={image} >
                      <div id="web-page-pdf">
                        <div class="portlet">
                          <div
                            className="tab-divs  d-flex justify-content-end mx-3 my-3"
                            data-html2canvas-ignore
                          >
                            <div class="btn-group btn-group-toggle dwm-chart">
                              <label class={getClassName(0)} role="presentatiosn">
                                <input
                                  class="btn-check"
                                  checked="checked"
                                  id="pills-home-tab"
                                  data-toggle="pill"
                                  data-target="#pills-home"
                                  type="radio"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                  onClick={() => {
                                    setChartView(0);
                                    let dimeselected = [];
                                    dimensionsSelected?.forEach((item) => {
                                      dimeselected.push(item.label);
                                    });
                                    if (isDateRage) {
                                      let getdata = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 0,
                                      };
                                      gscGetDataGet(getdata);
                                    } else {
                                      let rowData1 = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 0,
                                      };

                                      let rowData2 = {
                                        startdate: convertDate(state.selection2.startDate),
                                        enddate: convertDate(state.selection2.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 0,
                                      };
                                      gscGetDataGetForRange2(rowData1, rowData2);

                                      let rowData3 = {
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 0,
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        GSCCompareToRange: {
                                          from: convertDate(state.selection2.startDate),
                                          to: convertDate(state.selection2.endDate),
                                        },
                                      }
                                      gscGetChartDataCompare(rowData3)
                                    }
                                  }}
                                />
                                Day
                              </label>

                              <label class={getClassName(1)} role="presentation">
                                <input
                                  class="btn-check"
                                  id="pills-profile-tab"
                                  data-toggle="pill"
                                  data-target="#pills-profile"
                                  type="radio"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                  onClick={() => {
                                    setChartView(1);
                                    let dimeselected = [];
                                    dimensionsSelected?.forEach((item) => {
                                      dimeselected.push(item.label);
                                    });
                                    if (isDateRage) {
                                      let getdata = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 1,
                                      };
                                      gscGetDataGet(getdata);
                                    } else {
                                      let rowData1 = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 1,
                                      };
                                      let rowData2 = {
                                        startdate: convertDate(state.selection2.startDate),
                                        enddate: convertDate(state.selection2.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 1,
                                      };
                                      gscGetDataGetForRange2(rowData1, rowData2);

                                      let rowData3 = {
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 1,
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        GSCCompareToRange: {
                                          from: convertDate(state.selection2.startDate),
                                          to: convertDate(state.selection2.endDate),
                                        },
                                      }
                                      gscGetChartDataCompare(rowData3)
                                    }
                                  }}
                                />
                                Week
                              </label>

                              <label class={getClassName(2)} role="presentation">
                                <input
                                  class="btn-check"
                                  id="pills-contact-tab"
                                  data-toggle="pill"
                                  data-target="#pills-contact"
                                  type="radio"
                                  role="tab"
                                  aria-controls="pills-contact"
                                  aria-selected="false"
                                  onClick={() => {
                                    setChartView(2);
                                    let dimeselected = [];
                                    dimensionsSelected?.forEach((item) => {
                                      dimeselected.push(item.label);
                                    });
                                    if (isDateRage) {
                                      let getdata = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 2,
                                      };
                                      gscGetDataGet(getdata);
                                    } else {
                                      let rowData1 = {
                                        startdate: convertDate(state.selection1.startDate),
                                        enddate: convertDate(state.selection1.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 2,
                                      };
                                      let rowData2 = {
                                        startdate: convertDate(state.selection2.startDate),
                                        enddate: convertDate(state.selection2.endDate),
                                        Property: propertySelected,
                                        Dimension: dimeselected,
                                        SearchType: SearchTypeSelected,
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 2,
                                      };
                                      gscGetDataGetForRange2(rowData1, rowData2);

                                      let rowData3 = {
                                        projectId: localStorage.getItem("projectGId"),
                                        View: 2,
                                        GSCDateRange: {
                                          from: convertDate(state.selection1.startDate),
                                          to: convertDate(state.selection1.endDate),
                                        },
                                        GSCCompareToRange: {
                                          from: convertDate(state.selection2.startDate),
                                          to: convertDate(state.selection2.endDate),
                                        },
                                      }
                                      gscGetChartDataCompare(rowData3)
                                    }
                                  }}
                                />
                                Month
                              </label>
                            </div>
                          </div>

                          <div
                            id="carouselExampleControls"
                            class="carousel slide pt-4"
                            data-bs-ride="carousel"
                          >
                            <CarouselProvider
                              naturalSlideWidth={100}
                              naturalSlideHeight={125}
                              totalSlides={4}
                            >
                              {selectedIndex === 0 ? <div
                                className="portlet w-100"
                              >
                                <div
                                  className="portlet-header portlet-header-bordered"
                                >
                                  <h3
                                    className="portlet-title"
                                  >Impressions</h3>
                                </div>
                                <Chart
                                  options={options}
                                  series={series}
                                  type="line"
                                  height="300"
                                />
                              </div> : <></>}
                              {selectedIndex === 1 ? <div
                                className="portlet w-100"
                              >
                                <div
                                  className="portlet-header portlet-header-bordered"
                                >
                                  <h3
                                    className="portlet-title"
                                  >Clicks</h3>
                                </div>
                                <Chart
                                  options={options1}
                                  series={series1}
                                  type="line"
                                  height="300"
                                />
                              </div> : <></>}
                              {selectedIndex === 2 ? <div
                                className="portlet w-100"
                              >
                                <div
                                  className="portlet-header portlet-header-bordered"
                                >
                                  <h3
                                    className="portlet-title"
                                  >Average Position</h3>
                                </div>
                                <Chart
                                  options={options2}
                                  series={series2}
                                  type="line"
                                  height="300"
                                />
                              </div> : <></>}
                              {selectedIndex === 3 ? <div
                                className="portlet w-100"
                              >
                                <div
                                  className="portlet-header portlet-header-bordered"
                                >
                                  <h3
                                    className="portlet-title"
                                  >CTR</h3>
                                </div>
                                <Chart
                                  options={options3}
                                  series={series3}
                                  type="line"
                                  height="300"
                                />
                              </div> : <></>}

                              <ButtonBack className="btn btn-flat-primary slick-prev-2 slick-arrow"
                                onClick={() => {
                                  setSelectedIndex(selectedIndex - 1)
                                }}
                              >
                                <i className="fa fa-angle-left"></i>
                              </ButtonBack>
                              <ButtonNext className="btn btn-flat-primary"
                                onClick={() => {
                                  setSelectedIndex(selectedIndex + 1)
                                }}
                              >
                                <i className="fa fa-angle-right"></i>
                              </ButtonNext>
                            </CarouselProvider>
                          </div>
                        </div>

                        <div className="portlet bg-white">
                          <div className="portlet bg-white">
                            <div className="portlet-header portlet-header-bordered ">
                              <h3 className="portlet-title">Performance Metrics </h3>
                            </div>{" "}
                          </div>
                          <div className="row search-results-gsc-main p-4">
                            {performanceMetricsCardList1.map((i) => {
                              return (
                                <div className="col-md-3">
                                  <div className="gabox-shadow">
                                    <h2
                                      className={
                                        i.isHighData === 2
                                          ? "avg-val"
                                          : i.isHighData === 1
                                            ? "avg-val-negative"
                                            : "avg-val-positive"
                                      }
                                    >
                                      {i.avgData}
                                      {isDateRage ? "" : "%"}
                                    </h2>
                                    <span>{i.user}</span>
                                    {i.user === "Average CTR" ? (
                                      <p>
                                        {isDateRage
                                          ? ""
                                          : i.data + "%" + " vs " + i.compareData + "%"}
                                      </p>
                                    ) : (
                                      <p>
                                        {isDateRage ? "" : i.data + " vs " + i.compareData}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="portlet mt-3 gsc-metrics" data-html2canvas-ignore>
                          <div className="row my-3">
                            <div className="col-md-4">
                              <div className="d-flex align-items-center">
                                <label className="form-label me-3">Dimension</label>
                                <ReactSelect
                                  className="multiselect-graph-ga w-100"
                                  options={DimensionsOptions}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  hideSelectedOptions={false}
                                  controlShouldRenderValue={false}
                                  components={{
                                    Option,
                                  }}
                                  styles={themeStyle()}
                                  onChange={(selected) => {
                                    handleDimensionChange(selected);
                                  }}
                                  allowSelectAll={true}
                                  value={dimensionsSelected1}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 metrics">
                              <div className="d-flex align-items-center">
                                <label className="form-label me-3">Metrics</label>
                                <ReactSelect
                                  className="multiselect-graph-ga w-100"
                                  options={MetricsOptions}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  controlShouldRenderValue={false}
                                  components={{
                                    Option,
                                  }}
                                  styles={themeStyle()}
                                  onChange={(selected) => {
                                    handleChangeMetrics(selected);
                                  }}
                                  allowSelectAll={true}
                                  value={MetricsSelected}
                                />
                              </div>
                            </div>

                            <div className="col-md-4"></div>
                          </div>
                        </div>

                        <div className="portlet">
                          <div className="portlet-body">
                            <div className="col-md-12">
                              <div className="mb-3 text-right">
                                <span class="dropdown d-inline ">
                                  <button
                                    class="btn btn-primary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Export
                                  </button>
                                  <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        var type = "excel";
                                        exportFunction(type);
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        var type = "csv";
                                        exportFunction(type);
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div>
                                </span>
                              </div>
                              {isDateRage ? (
                                <div className="ga-table">
                                  <Table
                                    className="table table-bordered  table-hover mt-3 w-100 "
                                    id="datatable-1"
                                    columns={GSCTableCol1}
                                    dataSource={GSCTableColData}
                                    rowSelection={{
                                      type: "checkbox",
                                      ...rowSelection,
                                    }}
                                    pagination={tableParams.pagination}
                                    onChange={handleTableChange}
                                    controlShouldRenderValue={true}
                                  />
                                </div>
                              ) : (
                                <div className="ga-table">
                                  <Table
                                    className="table table-bordered  table-hover mt-3 w-100 gscc-table "
                                    id="datatable-1"
                                    columns={GSCTableCompCol1}
                                    dataSource={GSCTableCompColData1}
                                    rowSelection={{
                                      type: "checkbox",
                                      ...rowSelectionCompare,
                                    }}
                                    pagination={tableParams.pagination}
                                    onChange={handleTableChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></PDFExport>
                </TabPanel>
              </Tabs>
            </div>
          )}

          <Loading isLoading={isLoading} />
        </>
      )}</>
  );
}

export default ModuleExpandGSC;
