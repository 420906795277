import React, { useState, useEffect, useRef } from "react";
import { Table, Input } from "antd";
import "react-step-progress-bar/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "antd/dist/antd.css";
import {
  deleteKeyWord,
  GET_Server_URL,
  getKeyWordByProjectId,
  keyWordImport,
  documentFileType,
  deleteMultikeywords,
  getKeywordTypeAPI,
  deleteAllKeyWord,
} from "../../utility/Constant";
import {
  exportToEXCEL, isValidImportFile,
} from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import { Sorter } from "../../common/Sorter";
import { confirmAlert } from "react-confirm-alert";
import Addkeywords from "./Addkeywords";
import { Link } from "react-router-dom";

const Keywords = ({ update }) => {
  const [KeywordTabcol, setKeywordTabcol] = useState([]);
  const [tabkeywordlist, settabkeywordList] = useState([]);
  const [Totalkeyword, setTotalKeyword] = useState(0);
  const [Selectedkeyword, setSelectedkeyword] = useState(0);
  const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
  const [keyexportList, setKeyExportList] = useState([]);
  const [KeywordTemp, setKeywordTemp] = useState([]);
  const [SelectedkeywordCheckList, setSelectedkeywordkList] = useState([]);
  const [noKeywordType, setNoKeywordType] = useState(true);
  const [settings, setSetting] = useState("");
  const [editdata, seteditdata] = useState(null);
  const [keywordType, setkeywordType] = useState("");

  useEffect(() => {
    var columns = [
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(
              a.keyword.toLocaleLowerCase(),
              b.keyword.toLocaleLowerCase()
            ),
          multiple: 3,
        },
      },
      {
        title: "Search Volume",
        dataIndex: "searchVolume",
        key: "searchVolume",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.searchVolume, b.searchVolume),
          multiple: 3,
        },
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.categoryName, b.categoryName),
          multiple: 3,
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.type, b.type),
          multiple: 3,
        },
      },

      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setKeywordTabcol(columns);
    getKeywordlist();
    getKeywordType();
  }, []);

  const keyrowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (info.type === "all") {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        tabkeywordlist.forEach((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            count: item.count,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            category: item.categoryName,
            type: keywordType,
          };
          list.push(obj);
        });
        setSelectedKeyExportList(list);
      } else {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.forEach((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            category: item.categoryName,
            type: item.type,
          };
          list.push(obj);
        });
        setSelectedKeyExportList(list);
      }
      const list1 = [];
      selectedRows.forEach((item, index) => {
        list1.push(item.id);
      });
      setSelectedkeywordkList(list1);
      if (info.type === "all") {
        setSelectedkeyword(tabkeywordlist.length);
      } else {
        setSelectedkeyword(list1.length);
      }
    },
  };

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let c = 1;
        const exportList = [];
        response.data.forEach((item) => {
          exportList.push({
            key: item.id,
            keyword: item.fieldValue,
            searchVolume: item.searchVolume,
            category: item.categoryName,
            type: item.type,
          });

          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
            searchVolume: item.searchVolume,
            categoryId: item.categoryId,
            category: item.categoryName,
            type: keywordType,

            delete: (
              <div className="d-flex text-centet">
                <Link
                  state={{ item: item, isEdit: true }}
                  onClick={() => {
                    seteditdata(item);
                    setSetting("addUpdateKeywords");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon me-3"
                    onClick={() => {
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Link>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                  onClick={() => {
                    setSelectedkeywordkList([]);
                    deletekeywordItem(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
          c++;
        });
        settabkeywordList(list);
        setKeywordTemp(list);
        setKeyExportList(exportList);
        setTotalKeyword(list.length);
      }
    });
  }

  function getKeywordType() {
    APIManager.getAPI(
      getKeywordTypeAPI + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data.length > 0) {
          setkeywordType(response?.data[0]?.type);
          setNoKeywordType(false);
        } else {
          setNoKeywordType(true);
        }
      }
    });
  }

  async function deletekeywordItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteKeyWord + id);
      if (response === 200) {
        getKeywordlist();
      }
    } catch (e) { }
  }

  function filterDataFromList(textData) {
    settabkeywordList(
      KeywordTemp.filter((person) =>
        person.keyword
          .toLocaleLowerCase()
          .includes(textData.toLocaleLowerCase())
      )
    );
  }

  function uploadkeywordFile() {
    let input = inputFileRef.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid === true) {
      keyWordimport(input.files.item(0));
    }
  }

  function keyWordimport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      keyWordImport + localStorage.getItem("projectGId"),
      {},
      bodyFormData,
      false
    ).then(() => {
      getKeywordlist();
    });
  }

  function deleteMultikeywordId() {
    return new Promise((resolve, reject) => {
      APIManager.postAPIForDelete(
        deleteMultikeywords,
        SelectedkeywordCheckList,
        {},
        true
      ).then((response) => {
        if (response && response.status === 200) {
          getKeywordlist();
          if (response.status === 200)           
          resolve(response.status);
        }
      });
    });
  }

  function deleteAllkeyword() {
    return new Promise((resolve, reject) => {
      APIManager.deteletItem(
        deleteAllKeyWord + localStorage.getItem("projectGId")
      ).then((response) => {
        if (response && response.status === 200) {
          getKeywordlist();
          if (response.status === 200)            
          resolve(response.status);
        }
      });
    });
  }

  function submit() {
    confirmAlert({
      title: "Confirm to Delete",
      message:
        Selectedkeyword +
        " of " +
        Totalkeyword +
        " keywords selected will be deleted.",
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            if (localStorage.getItem("selectionInfoType") !== "all") {
              deleteMultikeywordId();
            } else {
              deleteAllkeyword();
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  let onClickFunction = (arg) => {
    setSetting(arg);
    getKeywordlist();
    getKeywordType();
  };

  function getComponent() {
    if (settings === "addUpdateKeywords") {
      return <Addkeywords data={editdata} update={onClickFunction} />;
    }
  }

  return (
    <>
      <div className="custom-column-70">
        <div className="portlet slideInUp">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("projectGName")} - Keywords
            </h3>
          </div>
          {noKeywordType ? (
            <>
              <div class=" message text-center ">
                <h4>Keyword type needs to set up first.</h4>
              </div>
            </>
          ) : (
            <div className="portlet-body">
              {settings === "" ? (
                <>
                  <div className="row">
                    <div className="col-md-8">
                      {" "}
                      <div className="keyword-select text-dark fw-bold">
                        {SelectedkeywordCheckList.length > 0 ? (
                          <>
                            {Selectedkeyword} of {Totalkeyword} Keywords
                            selected.
                          </>
                        ) : (
                          <>{Totalkeyword} Keywords</>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row mt-3">
                    <div
                      className="col-md-3 mb-0 col-sm-12 keyword-search res-w-100 d-flex justify-content-start align-items-center"
                      style={{ height: "36px" }}
                    >
                      <div class="input-group-icon input-group-lg widget15-compact">
                        <div class="input-group-prepend">
                          <i class="fa fa-search text-primary"></i>
                        </div>
                        <Input
                          type="text"
                          style={{ padding: 7 + "px" }}
                          class="form-control"
                          placeholder="Type to search"
                          allowClear
                          onChange={(nameSearch) => {
                            filterDataFromList(nameSearch.target.value);
                          }}
                          id="input-s"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-9 d-flex flex-wrap justify-content-end align-items-center tab-panel-url-options keyword-tab">
                      <button
                        disabled={!SelectedkeywordCheckList?.length}
                        class="btn btn-primary delete me-3 mt-3"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Delete
                      </button>
                      <div class="data-export-client">
                        <Link
                          class="btn btn-primary me-3 mt-3 add"
                          state={{ isEdit: false }}
                          onClick={() => {
                            seteditdata(null);
                            setSetting("addUpdateKeywords");
                          }}
                        >
                          Add
                        </Link>
                      </div>                     
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="keywordFile"
                        name="keywordFile"
                        multiple={false}
                        accept={documentFileType}
                        ref={inputFileRef}
                        onChange={uploadkeywordFile}
                      />

                      <button
                        class="btn btn-primary import me-3 mt-3"
                        style={{ width: 70 + "px" }}
                        onClick={onBtnClick}
                      >
                        Import
                      </button>
                      <a
                        class="btn btn-primary download-temp me-3 mt-3"
                        target="_blank"
                        href={GET_Server_URL + "audit/Keyword.xlsx"}
                        rel="noreferrer"
                      >
                        Download Template
                      </a>
                      <button
                        class="btn btn-primary dropdown-toggle export-btn mt-3"
                        type="button"
                        onClick={() => {
                          exportToEXCEL(
                            selectedKeyExportList?.length > 0
                              ? selectedKeyExportList
                              : keyexportList,
                            "Keywords"
                          );
                        }}
                      >
                        Export
                      </button>                     
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <Table
                      className="table table-bordered  table-hover keyword-table"
                      id="url"
                      columns={KeywordTabcol}
                      dataSource={tabkeywordlist}
                      rowSelection={{
                        type: "checkbox",
                        ...keyrowSelection,
                      }}
                      pagination={{
                        defaultPageSize: 100,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </>
              ) : (
                <>{getComponent()}</>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Keywords;
