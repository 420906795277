import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import {
  deleteMemberById,
  getClientUser,
  getProjectAssignment,
} from "../../utility/Constant";
import {
  exportInToCSV,
  exportToEXCEL,
  mmDDyyyyFormateDate,
  themeStyle,
} from "../../utility/Globals";
import { useNavigate } from "react-router-dom";

const AssignTeamMembers = (props) => {
  const [teamcol, setteamcol] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [colourOptions, setcolor] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const [exportTeamList, setExportTeamList] = useState([]);
  const [teamlistMember, setteamListMember] = useState([]);
  const [modulelist, setmodulelist] = useState([]);

  useEffect(() => {
    var columns = [
      {
        title: "Team Member",
        dataIndex: "teammember",
        key: "teammember",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.teammember, b.teammember),
          multiple: 3,
        },
      },
      {
        title: "Allocation %",
        dataIndex: "allocation",
        key: "allocation",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.allocation, b.allocation),
          multiple: 3,
        },
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.location, b.location),
          multiple: 3,
        },
      },
      {
        title: "Billing Start Date",
        dataIndex: "billingstart",
        key: "billingstart",
      },
      {
        title: "Billing End Date",
        dataIndex: "billingend",
        key: "billingend",
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setteamcol(columns);
    getProjectAssignmentData();
    getClientUserData();
  }, []);

  function getClientUserData() {
    APIManager.getAPI(getClientUser).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            key: item.memberId,
            value: item.members.name,
            label: item.members.name,
          });
        });
        setcolor(list);
      }
    });
  }

  function getProjectAssignmentData() {
    APIManager.getAPI(getProjectAssignment).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let c = 1;
        let list2 = [];
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            modulename: item.name,
            delete: (
              <a style={{ marginLeft: 24 + "px" }}>
                <i class="fa fa-trash"></i>
              </a>
            ),
          });
          c++;
          list2.push({
            key: item.id,
            teammember: item.teamMembers[0]?.members.name,
            allocation: item.allocation + "%",
            location: item.location,
            billingstart: mmDDyyyyFormateDate(item.billingStartDate),
            billingend: mmDDyyyyFormateDate(item.billingEndDate),
            delete: (
              <div
                onClick={() => {
                  deleteMemberByIdData(item.id);
                }}
              >
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
        });
        setmodulelist(list);
        setteamList(list2);
        setteamListMember(list2);
        setExportTeamList(list2);
      }
    });
  }

  async function deleteMemberByIdData(id) {
    try {
      const response = await APIManager.deleteAPI(deleteMemberById + id);
      if (response == 200) {
        getProjectAssignmentData();
      }
    } catch (e) {}
  }
  function handleChange(selected) {
    setoptionSelected(selected);
  }

  function assign() {
    if (optionSelected.length == 1) handleModal();
    else handleModal1();
  }

  const [show, setshow] = useState(false);
  function handleModal() {
    setshow(!show);
  }

  const [show1, setshow1] = useState(false);
  function handleModal1() {
    setshow1(!show1);
  }

  function assign1() {
    if (select.selectedRowKeys.length == 1) handleModal();
    else handleModal1();
  }
  const [select, setSelect] = useState({
    selectedRowKeys: [],
    loading: false,
  });
  const navigate = useNavigate();
  return (
    <div className="custom-column-70">
      <div class="row">
        <div class="dis-none">
          <i
            class="fa fa-chevron-circle-left"
            style={{ fontSize: 20 + "px", color: "#2196f3" }}
            onClick={() => {
              navigate(-1);
            }}
          ></i>
        </div>
        <div class="col-sm-5 pad-lzero">
          <div class="main-title">Assign Team Members</div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div>
      <div className="row">
        <div className="col-md-3 col-8">
          <ReactSelect
            styles={themeStyle()}
            options={colourOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
            }}
            onChange={handleChange}
            allowSelectAll={true}
            value={optionSelected}
          />
        </div>
        <div className="col-md-3 col-4 my-2">
          <button onClick={() => assign()} class="btn btn-primary">
            Assign
          </button>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-5"></div>
        <div className="col-sm-7 add-new-btnw">
          <div
            class="search"
            style={{ marginLeft: 0 + "px", width: 100 + "%" }}
          >
            <div class="input-group" style={{ display: "block" }}>
              <Input.Search
                allowClear
                placeholder="Search"
                onChange={(nameSearch) => {
                  let textData = nameSearch.target.value;
                  setteamList(
                    teamlist.filter((person) => {
                      person.teammember.includes(textData);
                    })
                  );
                  if (textData == "" && textData?.length == 0) {
                    setteamList(teamlistMember);
                  }
                }}
                id="input-s"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="common-table">
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-5 mt-4 text-end">
                  <button
                    onClick={() => assign1()}
                    class="btn btn-primary me-3"
                  >
                    Edit
                  </button>

                  <span class="export" style={{ marginRight: 0 + "px" }}>
                    <button
                      class="btn btn-primary dropdown-toggle"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          exportTeamList,
                          "Assigned-Team-members"
                        );
                      }}
                    >
                      Export
                    </button>
                    {/* <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        onClick={() => {
                          exportToEXCEL(
                            exportTeamList,
                            "Assigned-Team-members"
                          );
                        }}
                      >
                        <a class="dropdown-content">Excel</a>
                      </div>
                      <div
                        onClick={() => {
                          exportInToCSV(
                            exportTeamList,
                            "Assigned-Team-members"
                          );
                        }}
                      >
                        <a class="dropdown-content">CSV</a>
                      </div>
                    </div> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table columns={teamcol} dataSource={teamlist} />
      </div>
    </div>
  );
};

export default AssignTeamMembers;
