import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({ 
    
});

export const inputGroup = css({
    display: "flex",
    columnGap: "0.62rem",
    alignItems: "center"

})

export const inputContainer = css({ 
    width: "6rem",
    position: "relative"
});