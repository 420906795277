import React from "react";
import { VerifyUserMembers } from "../utility/Constant";
import { useEffect, useState } from "react";
import APIManager from "../services/APIManager";
import { useLocation } from "react-router-dom";

function VerifyUser() {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");

  useEffect(() => {}, []);

  function VerifyUserMemberId() {
    let emailId = new URLSearchParams(search).get("email");
    APIManager.postAPI(VerifyUserMembers + emailId, {}, null, true).then(
      (response) => {
        if (response && response.status == 200) {
        }
      }
    );
  }

  return (
    <>
      <section class="outer-wrapper">
        <div class="common-wrapper content-box border p-5 text-center">
          <a class="d-block">Click here to verify your account</a>
          <div
            className="btn mt-4"
            onClick={() => {
              VerifyUserMemberId();
            }}
          >
            <a href="/login">Log In </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default VerifyUser;
