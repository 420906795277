import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({

})

export const icon = css({
    width: "1.5rem",
    height: "1rem"
})


export const title = css({
    ...textStyles.s1,
    color: Colors.neutral[900]
})


export const description = css({
    ...textStyles.b3,
    marginTop: "0.25rem"
})