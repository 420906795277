/** @jsxImportSource @emotion/react */
import React, { useCallback } from "react";
import * as styles from "./styles/scopeOfWork";
import { useState, useEffect, useRef } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import APIManager from "../../services/APIManager";
import { Table, Space, Drawer } from "antd";
import toast from "react-hot-toast";
import {
  documentFileType,
  addSOWTicket,
  getTicketListSOW,
  sowAllocationTicket,
  GET_Server_URL,
  getSOWMonth,
  getAllocationDetail,
  deleteSowAllocation,
  getAssignedMembersWithCustomer,
  deleteSOW,
  sowImportNew,
  getClientByUserId,
  sowActivities,
} from "../../utility/Constant";
import {
  getDaysInMonth,
  mmDDyyyyFormateDateSet,
  removeTrailingSlash,
  isValidBulkUploadSow,
  findUnique,
  DDMMMMYYYYFormatter,
  dateFormatterWithOptions,
} from "../../utility/Globals";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Colors } from "../../styles/colors";
import DropDownInput from "../shared/Dropdown/Input";
import AssetsImg from "../assets/images";
import { Sorter } from "../../common/Sorter";
import MonthPick from "../shared/DatePicker/ScopeOfWork";
import { DatePicker } from "@semcore/date-picker";
import Notice from "@semcore/ui/notice";
import { Box } from "@semcore/flex-box";
import { textStyles } from "../../styles/font";
import CustomNotice from "../shared/notice";
import EtaDatePicker from "../shared/DatePicker/ScopeOfWork";
import additionalSowTemplate from "../assets/templates/Additional_SOW_Template.xlsx"
// import template from "../assets/templates/Additional_SOW_Template.xlsx"
import {
  bulkUploadTask,
  createSowMainTicket,
  createSowSubTicket,
  getActivities,
  getCategoriesList,
  getFrequencyList,
  getPriorityList,
  getResonsibilityList,
  getSowAssigned,
  getSowDataDates,
  getSowMembers,
  getSowTicketList,
  getStatusList,
  sowDeleteTask,
  sowUpdateTask,
  uploadIndividualTask,
} from "../../services/scopeOfWork";
import CustomDeleteModal from "../shared/modal/deleteModal";
import CustomButton from "../shared/button";
import ErrorScreen from "../shared/errorScreen";
import moment from "moment";
import MonthPickerSingle from "../shared/DatePicker/MonthPickerSingle";
import Popper from '@semcore/ui/popper';
import SpinContainer from "@semcore/ui/spin-container";
import { ca } from "date-fns/locale";

function ScopeOfWork({ update }) {
  const ref = useRef();
  const calendarRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentTableData, setCurrentTableData] = useState([])
  const [filteredTableData, setFilteredTableData] = useState([])
  const [tableData1, setTableData1] = useState([]);
  const [assignedArray, setassignedArray] = useState([]);
  const [activityHeading, setactivityHeading] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [ticketLength, setTicketLength] = useState(0);
  const [addSow, setAddSow] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [indexData, setIndexData] = useState(0);
  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState("");
  const [calenderHeading, setCalenderHeading] = useState("Pick a Month");
  const [otherValue, setOtherValue] = useState(false);
  const [activeAssigned, setActiveAssigned] = useState(null);
  const [priorityType, setPriorityType] = useState({});

  const [updateTableState, setUpdateTableState] = useState(new Date())

  const [updateFields, setUpdateFields] = useState(false);
  const [ticketMsgHidden, setTicketMsgHidden] = useState(false);

  const [sowId, setSowId] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    open: false,
  });

  const [isFiltered, setIsFiltered] = useState(false)

  const [statusList, setStatusList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [updatedByList, setUpdatedByList] = useState([]);
  const [responsibilityList, setResponsibilityList] = useState([]);

  const [totalUnitInput, setTotalUnitInput] = useState({});
  const [initialTotalUnitInput, setInitialTotalUnitInput] = useState({});
  const [totalUnitEdit, setTotalUnitEdit] = useState({
    id: "",
  });

  const [etaInput, setEtaInput] = useState({});
  const [initialEtaInput, setInitialEtaInput] = useState({});
  const [etaInputEdit, setEtaInputEdit] = useState({
    id: "",
  });

  let buttonCreateAllocatiion = document.getElementById("createAllocationBtn");
  let buttonAddSowTicket = document.getElementById("addSOWTicket");

  const [availableMonths, setAvailableMonths] = useState([]);

  const [editableMonths, setEditableMonths] = useState({})
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [isLoading, setIsLoading] = useState(false)


  let disabledStatus = ["Picked Up", "Completed", "In Progress", "Completed", "Awaiting Approval"]

  let columnOptions = {
    options: [
      {
        label: "High",
        value: "High",
        text: "High",
      },
      {
        label: "Medium",
        value: "Medium",
      },
      {
        label: "Low",
        value: "Low",
      },
    ],
    active: 2,
  };

  const getColumnHeadersData = () => {
    Promise.all([
      getStatusList(localStorage.getItem("organizationId")).then(
        (response) => response
      ),
      getCategoriesList().then((response) => response),
      getFrequencyList().then((response) => response),
    ])
      .then(
        ([
          statusResponse,
          // priorityResponse,
          catergoriesResponse,
          frequencyResponse,
        ]) => {
          let statusData = statusResponse?.data?.map((value) => {
            return { text: value?.name, value: value?.name };
          });

          // let priorityData = priorityResponse?.data?.map((value) => {
          //   return { text: value?.name, value: value?.name, id: value?.id };
          // });

          let categoryData = catergoriesResponse?.data?.map((value) => {
            return { text: value?.categories, value: value?.categories };
          });

          let frequencyData = frequencyResponse?.data?.map((value) => {
            return { text: value?.frequency, value: value?.frequency };
          });

          setStatusList(statusData ?? []);
          // setPriorityList(priorityData ?? []);
          setCategoryList(categoryData ?? []);
          setFrequencyList(frequencyData ?? []);
        }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    getPriorityList(localStorage.getItem("organizationId")).then(
      (priorityResponse) => {
        let priorityData = priorityResponse?.data?.map((value) => {
          return { text: value?.name, value: value?.name, id: value?.id };
        });
        setPriorityList(priorityData ?? []);
      })

  };

  useEffect(() => {
    const d = new Date();
    const month =
      d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();
    let string = "Upload Scope Of Work (SOW) for " + month;
    setTitle(string);
    let dateArry = [];
    dateArry.push(d.getMonth() + 1);
    dateArry.push(d.getFullYear());
    var daysInMonth = getDaysInMonth(d.getFullYear(), d.getMonth() + 1);
    let dateObjArr = [
      new Date(dateArry[0] + "/01/" + dateArry[1]),
      new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
    ];
    setValues(dateObjArr);
    setHead1(dateObjArr[0]);
    setHead2(dateObjArr[1]);
    let rowData = {
      searchText: searchText,
      startDate: mmDDyyyyFormateDateSet(dateObjArr[0]),
      endDate: mmDDyyyyFormateDateSet(dateObjArr[1]),
      projectId: localStorage.getItem("projectGId"),
    };
    getColumnHeadersData();
    getSowDates()
    getTicketList(rowData);
    getResponsibleList();
    getActivityList(selectedCategory);
    // getMonth();
    // getMonthsWithYear()
  }, []);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    const data = [
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        filters: findUnique(categoryList, (d) => d.value),
        filterSearch: true,
        onFilter: (value, record) => {
          return record.category1 === value;
        },
        filterIcon: (filtered) => (
          <FeatherIcon
            icon={"filter"}
            css={styles.searchIcon(filtered)}
            fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
          />
        ),
      },
      {
        title: <span>Responsibility</span>,
        dataIndex: "responsibilities",
        key: "responsibilities",
      },
      {
        title: "Activities",
        dataIndex: "activity",
        key: "activity",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        filterIcon: (filtered) => (
          <FeatherIcon
            icon={"filter"}
            css={styles.searchIcon(filtered)}
            fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
          />
        ),
        filters: findUnique(priorityList, (d) => d.value),
        // // width: 200,
        onFilter: (value, record) => record.priority === value,
        render: (_, record) => (
          <>
            <div onClick={() => { }}>
              {updateFields && record?.statusValue == "Not Assigned" ? (
                getPriorityFun(record.priority, record?.id, record)
              ) : (
                <div
                  css={styles.priorityStyles(record.priority, {
                    display: "inline-block",
                    borderRadius: "6px",
                    padding: "4.5px 9px",
                    textAlign: "center",
                  })}
                >
                  {record?.priority || "High"}
                </div>
              )}
            </div>
          </>
        ),
      },
      {
        title: <div css={{ maxWidth: "7rem !important" }}>
          {"Units Assigned"}
        </div>,
        dataIndex: "unitsAssigned",
        key: "unitsAssigned",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.unitsAssigned1, b.unitsAssigned1),
          multiple: 3,
        },
      },
      {
        title: "Total Units",
        dataIndex: "totalUnits",
        key: "totalUnits",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.totalUnits, b.totalUnits),
          multiple: 3,
        },
        render: (_, record) => (
          <>
            {updateFields && record?.statusValue == "Not Assigned" ? (
              <div
                css={styles.totalUnitsContainer}
                onClick={() => {
                  setTotalUnitInput(initialTotalUnitInput);
                  setTotalUnitEdit({
                    id: record?.id,
                    value: record?.totalUnits,
                  });
                }}
              >
                <div css={styles.unitInput}>
                  <input
                    type="number"
                    css={[styles.tableInputsContainer]}
                    onWheel={(event) => event.currentTarget.blur()}
                    name="search"
                    value={totalUnitInput[record?.id]}
                    // onKeyDown={(e) => {
                    //   // _handleKeyDown(e, record?.id);
                    //   handleInputKeyDown(e, record)
                    // }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setTotalUnitInput({
                        ...totalUnitInput,
                        [record?.id]: value,
                      });
                      setTotalUnitEdit({
                        ...totalUnitEdit,
                        value: value,
                      });
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {totalUnitEdit?.id == record?.id && (
                  <div className="">
                    <FeatherIcon
                      onClick={
                        ((record?.totalUnits == totalUnitEdit?.value) || Number(totalUnitEdit?.value) < 1)
                          ? () => { }
                          : (e) => {
                            e.stopPropagation();
                            updateSowTask({
                              record,
                              totalUnits: totalUnitEdit?.value,
                            });
                          }
                      }
                      icon={"check"}
                      size={18}
                      className={`mr-2 ${((record?.totalUnits == totalUnitEdit?.value) || Number(totalUnitEdit?.value) < 1) &&
                        "opacity-25"
                        }`}
                      color={Colors.semantic[500]}
                    />
                    <FeatherIcon
                      icon={"x"}
                      size={18}
                      color={Colors.error[800]}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTotalUnitEdit();
                        setTotalUnitInput(initialTotalUnitInput);
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div css={styles.totalUnitsSpacing}>{record?.units}</div>
            )}
          </>
        ),
      },
      {
        title: "Frequency",
        dataIndex: "frequency",
        key: "frequency",
        filters: findUnique(frequencyList, (d) => d.value),
        // filterSearch: true,
        onFilter: (value, record) => {
          return record.frequency === value;
        },
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        render: (_, record) => (
          <>
            {updateFields ? (
              <div>
                {updateFields && record?.statusValue == "Not Assigned" ? (
                  <Box css={styles.etaCalendar}>
                    <DatePicker
                      placement="top-start"
                      // disabled={[new Date(new Date().getFullYear(), 0, 1), [new Date(), false], '* * 6,7']}
                      value={etaInput[record?.id] ? new Date(etaInput[record?.id]) : new Date(initialEtaInput[record?.id])}
                      onChange={(e) => {
                        setEtaInput({ ...etaInput, [record?.id]: e });
                        setEtaInputEdit({ ...etaInputEdit, eta: e });
                      }}
                      locale="en-UK"
                    >
                      <DatePicker.Trigger
                        // style={{ cursor: "pointer" }}
                        readOnly={true}
                        // onChange={() => { }}
                        onClick={() => {
                          setEtaInputEdit({
                            id: record?.id,
                            eta: record?.eta,
                            record,
                          });
                        }}
                      />
                      <DatePicker.Popper
                        ref={calendarRef}
                        visible={etaInputEdit?.id == record?.id}
                      >
                        <DatePicker.Header>
                          <DatePicker.Prev />
                          <DatePicker.Title />
                          <DatePicker.Next />
                        </DatePicker.Header>
                        {/* <DatePicker.Calendar /> */}
                        <DatePicker.Calendar size="s">
                          {(props) => {
                            return props.days.map((day, index) => (
                              <DatePicker.Calendar.Unit
                                {...day}
                                key={index}
                                css={styles.calendarText}
                                disabled={new Date(day.date).getTime() < new Date(new Date().toLocaleDateString()).getTime()}
                              >
                                {day.children}
                              </DatePicker.Calendar.Unit>
                            ))
                          }
                          }
                        </DatePicker.Calendar>
                        <div
                          className="border-top"
                          css={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                            paddingTop: "1rem",
                          }}
                        >
                          <CustomButton
                            onPress={() => {
                              setEtaInputEdit({});
                              updateSowTask({
                                record: etaInputEdit?.record,
                                eta: new Date(etaInputEdit?.eta),
                              });
                            }}
                            text={"Apply"}
                            use={"primary"}
                          />
                          <CustomButton
                            onPress={() => {
                              setEtaInputEdit({});
                              setEtaInput(initialEtaInput);
                            }}
                            text={"Cancel"}
                          />
                        </div>
                      </DatePicker.Popper>
                    </DatePicker>
                  </Box>
                ) : (
                  // <EtaDatePicker />
                  <div css={{ paddingLeft: "1rem" }}>
                    {dateFormatterWithOptions(record?.eta, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {dateFormatterWithOptions(record?.eta, {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </div>
            )}
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        filters: findUnique(statusList, (d) => d.value),
        filterSearch: true,
        onFilter: (value, record) => {
          return record.statusValue === value;
        },
      },
      {
        title: "Raise Ticket",
        dataIndex: "assignedto",
        key: "assignedto",
        render: (_, record) => (
          <div>
            {getAssignedNames(record, null)}
            <Space size="middle" class=" display-flex align-items-center">
              {true ? (
                <a
                  onMouseEnter={() => {
                    setActiveAssigned(record.ticketId);
                  }}
                  onMouseLeave={() => {
                    setActiveAssigned(null);
                  }}
                  id="plusIcon"
                  class="scope-A d-flex"
                  onClick={() => {
                    getSowSubTicketData(record?.id)
                    setMessage1("");
                    updateFields
                      ? setMessage("")
                      : setMessage("Units Remaining : 00");
                    setactivityHeading(record.activityFilter);
                    setUnitsData(record.units);
                    setSowDetailIdd(record.sowDetailId);
                    setTicketId(record.ticketId);
                    setAssignedMemberNames(record?.raiseTicket)
                    // getAllocationDetailFun(record.sowDetailIdd, record.units);
                    setaddSowTicketButton("Add SOW Ticket");
                  }}
                >
                  {getIconFun(record?.raiseTicket)}
                </a>
              ) : (
                <></>
              )}

              {record?.raiseTicket?.length > 2 ? (
                <a
                  onMouseEnter={() => {
                    setActiveAssigned(record.ticketId);
                  }}
                  onMouseLeave={() => {
                    setActiveAssigned(null);
                  }}
                  id="plusIcon1"
                  class="scope-more margin-left-minus py-0"
                  aria-hidden="true"
                  type="button"
                  onClick={() => {
                    getSowSubTicketData(record?.id)
                    setMessage1("");
                    updateFields
                      ? setMessage("")
                      : setMessage("Units Remaining : 00");
                    setactivityHeading(record.activityFilter);
                    setUnitsData(record.units);
                    setSowDetailIdd(record.sowDetailId);
                    setTicketId(record.ticketId);
                    setAssignedMemberNames(record?.raiseTicket)
                    // getAllocationDetailFun(record.sowDetailIdd, record.units);
                    setaddSowTicketButton("Add SOW Ticket");
                  }}
                >
                  <span>{`${record?.raiseTicket?.length - 2}`} </span>
                </a>
              ) : (
                <></>
              )}

              {true ? (
                <a
                  id="plusIcon1"
                  class={`${updateFields ? "scope-plus-active" : "scope-plus"
                    } margin-left-minus py-0`}
                  aria-hidden="true"
                  type="button"
                  onClick={
                    updateFields
                      ? () => {
                        getSowSubTicketData(record?.id)
                        setMessage1("");
                        setMessage("");
                        setactivityHeading(record.activityFilter);
                        setUnitsData(record.units);
                        setSowDetailIdd(record.sowDetailId);
                        setTicketId(record.ticketId);
                        setAssignedMemberNames(record?.raiseTicket)
                        // getAllocationDetailFun(
                        //   record.sowDetailIdd,
                        //   record.units
                        // );
                        setaddSowTicketButton("Add SOW Ticket");
                      }
                      : () => { }
                  }
                >
                  <span>+ </span>
                </a>
              ) : (
                <></>
              )}
            </Space>
          </div>
        ),
      },
      {
        title: "Last Updated by",
        dataIndex: "uploadedby",
        key: "uploadedby",
        filters: findUnique(updatedByList, (d) => d.value),
        filterSearch: true,
        onFilter: (value, record) => {
          return record.uploadedby === value;
        },
      },
      {
        title: "Action",
        dataIndex: "edit",
        key: "edit",
        render: (_, record) => (
          <>
            {record.ticketTypeName == "SOW Adhoc" ? updateFields && record?.ticketToDelete && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#FC3D39"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class={`feather feather-trash-2 scope-trash-icon trash-icon  ms-3 ${!updateFields && "opacity-25"
                  }`}
                onClick={
                  updateFields
                    ? () => {
                      // deleteEditproj(record.sowDetailIdd);
                      setDeleteModal({ open: true, id: record?.id });
                    }
                    : () => { }
                }
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ];
    setTableColumns(data);
  }, [
    categoryList,
    activeAssigned,
    updateFields,
    totalUnitInput,
    totalUnitEdit,
    etaInput,
    etaInputEdit,
  ]);

  useEffect(() => {
    const handler = (event) => {
      if (!calendarRef.current) {
        return;
      }
      if (!calendarRef.current.contains(event.target)) {
        setEtaInputEdit({});
        initEtaInput()
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const initEtaInput = () => {
    setEtaInput(initialEtaInput)
  }

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [head1, setHead1] = useState(values[0]);
  const [head2, setHead2] = useState(values[1]);
  const [calendarDay, setCalendarDay] = useState()
  const [clientList, SetclientList] = useState([]);
  const [clientSelected, setClientSelected] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [units, setUnits] = useState();
  const [frequency, setFrequency] = useState(0);
  const [unitsData, setUnitsData] = useState(); //Create Allocation
  const [searchText, setSearchText] = useState("");
  const [memberlist, setmemberlist] = useState([]);
  const [addSowTicketButton, setaddSowTicketButton] =
    useState("Add SOW Ticket");
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [messageFrequency, setMessageFrequency] = useState("")
  const [messageUnits, setMessageUnits] = useState("");
  const [messageActivity, setMessageActivity] = useState("");
  const [responsibleErrorMsg, setResponsibleErrorMsg] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [mainTicketId, setMainTicketId] = useState("")
  const [assignedMemberNames, setAssignedMemberNames] = useState([])
  const [sowDetailIdd, setSowDetailIdd] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No File Selected");
  const [monthListChips, setmonthListChips] = useState([]);
  const [sowActivityData, setSOWActivity] = useState([]);
  const [selectedSOWActivity, setSelectedSOWActivity] = useState("");
  const [SOWActivityText, setSOWActivityText] = useState("");
  const [title, setTitle] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [save, setsave] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [size1, setSize1] = useState();
  const [activityDisabled, setActivityDisabled] = useState(false);

  let isModelDismiss = false;
  let isModelDismiss1 = false;

  const onClose1 = () => {
    setOpen1(false);
    buttonCreateAllocatiion.disabled = false;
    buttonAddSowTicket.disabled = false;
    setMessage1("");
  };

  const showLargeDrawer1 = () => {
    setSize1("large");
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const [size2, setSize2] = useState();
  const onClose2 = () => {
    setOpen2(false);
    document.getElementById(
      "sowFile"
    ).value = "";
    setSelectedCategory();
    setSelectedSOWActivity();
    setClientSelected();
  };
  const showLargeDrawer2 = () => {
    setSize2("large");
    setOpen2(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { },
  };

  // OLD API
  // function getClientList() {
  //   let list = [];
  //   APIManager.getAPI(
  //     getClientByUserId + localStorage.getItem("userId")
  //   ).then((response) => {
  //     if (response.status === 200) {
  //       response.data.forEach((item) => {
  //         let object = {
  //           name: item.name,
  //           id: item.id,
  //         };
  //         list.push(object);
  //       });
  //     }
  //     SetclientList(list);
  //     setClientSelected(list[0].id);
  //   });
  // }

  // function getActivities(category) {
  //   let list = [];
  //   APIManager.getAPI(
  //     sowActivities + category
  //   ).then((response) => {
  //     if (response.status === 200) {
  //       response.data.forEach((item) => {
  //         let object = {
  //           name: item.activities,
  //           id: item.id,
  //         };
  //         list.push(object);
  //       });
  //     }
  //     setSOWActivity(list);
  //     setSelectedSOWActivity(list[0].id);
  //   });
  // }

  function getMonth() {
    APIManager.getAPI(getSOWMonth).then((response) => {
      if (response.status === 200) {
        let list = [];
        let obj;
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let index1;
        const d = new Date();
        let currentMonth = month[d.getMonth()];
        let year = d.getFullYear();
        let monthYr = currentMonth + " " + year;
        response.data.forEach((item, index) => {
          obj = {
            monthName: item.column1,
            key: index,
          };
          list.push(obj);
          if (item.column1 === monthYr) {
            index1 = index;
          }
        });
        let list1 = [];
        list.forEach((item, index) => {
          if (index <= 3) {
            list1.push(item);
          }
        });
        setmonthListChips(list1);
        setIndexData(index1);
        setMonthSelected(true);
      }
    });
  }


  function getMonthNumberFromName(monthName, year) {
    return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
  }

  function addTicket() {
    let formBody = new FormData();
    formBody.append("sowid", sowId);
    formBody.append("orgid", localStorage.getItem("organizationId"))
    formBody.append("category", selectedCategory);
    formBody.append("activities",
      selectedCategory == "Others" ? SOWActivityText?.replace(/\s+/g, ' ')?.trim() : selectedSOWActivity
    );
    formBody.append("userid", localStorage.getItem("userId"));
    formBody.append("frequency", frequency);
    formBody.append("total_units", units);
    formBody.append("type_of_ticket", "SOW Adhoc");
    formBody.append("responsibility", clientSelected);
    formBody.append(
      "priority",
      priorityList?.filter((prior) => prior.value == "High")[0].id
    );
    formBody.append("status", "Not Assigned");

    uploadIndividualTask(formBody)
      .then((response) => {
        if (response.status == "success") {
          setOtherValue(false);
          setSelectedCategory();
          setUnits();
          setSOWActivityText();
          setSelectedSOWActivity();
          setTimeout(() => {
            getTicketList();
          }, 500);
          onClose2();
          toast.success("Task Uploaded Successfully");
        }
      })
      .catch((error) => {
        if (error.response.data.status == "error") {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.response.data.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Failed to Upload Task");
          }
        }
      });

    // OLD API
    // let rowData
    // if (selectedCategory !== "4") {
    //   rowData = {
    //     ProjectId: localStorage.getItem("projectGId"),
    //     CreatedBy: localStorage.getItem("userId"),
    //     SowDetails: [
    //       {
    //         Category: selectedCategory,
    //         ActivitiesId: selectedSOWActivity,
    //         Responsibilities: clientSelected,
    //         Units: units,
    //         Frequency: frequency,
    //       },
    //     ],
    //   };
    // } else {
    //   rowData = {
    //     ProjectId: localStorage.getItem("projectGId"),
    //     CreatedBy: localStorage.getItem("userId"),
    //     SowDetails: [
    //       {
    //         Category: selectedCategory,
    //         Activities: SOWActivityText,
    //         Responsibilities: clientSelected,
    //         Units: units,
    //         Frequency: frequency,
    //       },
    //     ],
    //   };
    // }
    // APIManager.postAPI(addSOWTicket, rowData, null, true).then((response) => {
    //   if (response && response.status === 200) {
    //     setOtherValue(false)
    //     setSelectedCategory();
    //     setUnits();
    //     setSOWActivityText("")
    //     setTimeout(() => {
    //       getTicketList();
    //     }, 500);
    //     onClose2();
    //     toast.success("Email Send for Approval!");
    //   }
    // });
  }

  const [classSOW, setClassSOW] = useState("btn btn-light active");
  const [isActive, setIsActive] = useState(false);

  function getClassName(index, indexData, isMonthSelected) {
    setClassSOW("");
    setMonthSelected(isMonthSelected);
    if (isMonthSelected && indexData === index) {
      setClassSOW("btn btn-light active");
    } else {
      setClassSOW("btn btn-light");
    }
  }

  function getPriorityFun(priority, ticketId, record) {
    return (
      <div css={styles.keyword}>
        <DropDownInput
          addPopperStyles={styles.selectDropDownContainer}
          data={columnOptions}
          showSearchBar={false}
          nestedTrigger={priority}
          placeholder={"High"}
          // placement={"right-start"}
          handleDropdownClick={(selected, value) => {
            updateSowTask({
              record,
              priority: priorityList?.filter((prior) => prior.value == value)[0]
                .id,
            });
          }}
          defaultValue={
            columnOptions?.options?.filter(
              (option) => option.value == priority
            )[0]?.label || columnOptions?.options[0].label
          }
          triggerStyles={styles.priorityStyles(priority)}
        />
      </div>
    );
  }

  function getStatusFun(status) {
    if (status === "Partially Assigned") {
      return <div class="sow btn btn-warning text-worning ">{status}</div>;
    } else if (status === "Not Assigned" || status === "Pending" || status === "Not Completed") {
      return <div class="sow btn text-error btn-error">{status}</div>;
    } else if (status === "Assigned") {
      return <div class="sow btn btn-success">{status}</div>;
    } else if (status === "Completed") {
      return <div class="sow btn btn-success">{status}</div>;
    }
  }

  function getAssignedNames(item) {
    return (
      <>
        {item.raiseTicket?.length > 0 && activeAssigned == item?.ticketId && (
          <div css={styles.assignedBox(true)} className={`shadow`}>
            {item.raiseTicket?.slice(0, 2)?.map((name) => {
              return <div>{name}</div>;
            })}
            {item.raiseTicket?.length > 2 && (
              <div css={styles.moreText}>{`+${item.raiseTicket?.length - 2
                } more`}</div>
            )}
          </div>
        )}
      </>
    );
  }

  function _handleKeyDown(e) {
    let data = tableData1

    if (e.key === "Enter") {
      e.preventDefault();
      let list = data.filter((person) =>
        person.activityFilter
          .toLocaleLowerCase()
          .includes(searchText?.toLocaleLowerCase()?.trim())
      );
      setTableData(list);
      // setTicketLength(list?.length);
    }
  }

  function handleInputKeyDown(e, record) {
    if (e.key === "Enter") {
      updateSowTask({ record, totalUnits: totalUnitInput[record?.id] });
    }
  }

  const getSowDates = () => {
    getSowDataDates(localStorage.getItem("projectGId")).then((response) => {
      if (response.status == "success") {
        let data = response?.unique_dates?.map((date) => { return moment(date).format("MMM 1, YYYY") })
        setEditableMonths(response?.editable_month)
        setAvailableMonths(data)
        getMonthsWithYear(response?.editable_month)
      }
    })
  }

  function getTicketList(rowData) {
    setIsLoading(true)
    document.getElementById("sowFile").value = "";
    let data;
    if (rowData) {
      data = rowData;
    } else {
      data = {
        searchText: searchText,
        startDate: mmDDyyyyFormateDateSet(head1),
        endDate: mmDDyyyyFormateDateSet(head2),
        projectId: localStorage.getItem("projectGId"),
      };
    }

    let selectedDateTime = new Date(
      new Date(data.startDate).getFullYear(),
      new Date(data.startDate).getMonth(),
      1
    ).getTime();
    let currentDateTime = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).getTime();



    // if (selectedDateTime < currentDateTime) {
    //   setUpdateFields(false);
    // } else {
    //   setUpdateFields(true);
    // }

    let selectedDate = new Date(data?.endDate || new Date()).toLocaleDateString(
      "en-uk",
      { month: "long", year: "numeric" }
    );
    var body = new FormData();
    body.append("date", selectedDate);

    getSowTicketList(localStorage.getItem("projectGId"), body).then(
      (ticketResponse) => {
        if (ticketResponse && ticketResponse.status == "success") {
          let ticketList = [];

          if (ticketResponse?.data?.length > 0) {
            let object;
            let unitObj;
            let etaObj;
            ticketResponse?.data?.forEach((item, index) => {
              object = {
                key: item.id,
                id: item.id,
                sowid: item.sowid,
                category: item.category_name,
                category1: item.category_name,
                responsibilities: item.responsibility,
                activity: (
                  <>
                    {`${item.activities_name}`}
                    {item.sow_ticket_type_name.name == "SOW Adhoc" && (
                      <span css={styles.ticketType}>
                        {item.sow_ticket_type_name.name}
                      </span>
                    )}
                  </>
                ),
                // activity: item.activities_name,
                // activity as link ?
                activityFilter: item.activities_name,
                units: item.total_units,
                sowDetailIdd: item.sowid,
                priority: item.priority_name,
                priorityId: item.priority,
                unitsAssigned: (
                  <div>
                    <span>{item?.units_assigned || 0}</span>
                    <span
                      style={{
                        color: Colors.neutral[500],
                        fontSize: "0.90rem",
                      }}
                    >{`/${item.total_units}`}</span>
                  </div>
                ),
                unitsAssigned1: item.units_assigned,
                totalUnits: item.total_units,
                frequency: item.frequency,
                ticketId: item.id,
                raiseTicket: item?.raised_ticket || [],
                status: getStatusFun(item.sow_task_status_name),
                statusId: item.sow_task_status,
                uploadedby: item.uploaded_by,
                statusValue: item.sow_task_status_name,
                createdBy: item.uploaded_by,
                eta: item.eta,
                ticketTypeId: item.ticket_type,
                ticketTypeName: item.sow_ticket_type_name.name,
                ticketToDelete: item.sow_ticket_type_name.to_delete,
              };
              item.activities_name && ticketList.push(object);
              unitObj = {
                ...unitObj,
                [item?.id]: Number(item.total_units),
              };
              etaObj = {
                ...etaObj,
                [item?.id]: item?.eta,
              };
            });
            let updatedByFilter = ticketResponse?.unique_updated_by?.map(
              (data) => {
                return { text: data, value: data };
              }
            );
            setUpdatedByList(updatedByFilter ?? []);
            setSowId(ticketList[0]?.sowid);
            setTotalUnitInput(unitObj);
            setInitialTotalUnitInput(unitObj);
            setEtaInput(etaObj);
            setInitialEtaInput(etaObj);
            setTableData(ticketList);
            // setIsFiltered(false)
            setCurrentTableData(ticketList)
            setTableData1(ticketList);
            setTicketLength(ticketList?.length);
            setIsLoading(false)
          } else {
            setTableData([]);
            setCurrentTableData([])
            setTableData1([]);
            setTicketLength(0);
            setIsLoading(false)
          }
        }
      }
    ).catch((error) => {
      setIsLoading(false)
      if (error.response.data.status == "error") {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Failed to Fetch Tasks");
        }
      }
    });

  }

  const updateSowTask = ({
    record,
    eta = null,
    priority = null,
    totalUnits = null,
  }) => {
    let formEta = eta ?? record?.eta;
    let formUnits = totalUnits ?? record?.totalUnits;
    let formBody = new FormData();
    formBody.append(
      "eta",
      new Date(formEta).toLocaleDateString("en-uk", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    );
    formBody.append("priority", priority ?? record?.priorityId);
    formBody.append("total_unit", Number(formUnits));
    formBody.append("userid", localStorage.getItem("userId"));

    sowUpdateTask(record?.id, formBody)
      ?.then((response) => {
        if (response.status == "success") {
          setTotalUnitEdit();
          toast.success("Task Updated Successfully");
          setTimeout(() => {
            getTicketList();
          }, 500);
        }
      })
      .catch((error) => {
        if (error.response.data.status == "error") {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Failed to Update Task");
          }
        }
      });
  };

  const deleteSowTask = (id) => {
    let formBody = new FormData();
    formBody.append("ticket_type", "SOW Adhoc");
    formBody.append("user_id", localStorage.getItem("userId"))
    sowDeleteTask(id, formBody)?.then((response) => {
      setDeleteModal({ open: false });
      if (response.status == "success") {
        toast.success("Task deleted successfully");
        getTicketList();
      } else {
        toast.error("Failed to delete Task");
      }
    }).catch((error) => {
      setDeleteModal({ open: false });
      if (error.response.data.status == "error") {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Failed to Delete Task");
        }
      } else {
        toast.error("Failed to Delete Task");
      }
    });
  };

  const getResponsibleList = () => {
    let formBody = new FormData()
    formBody.append("orgid", localStorage.getItem("organizationId"))
    formBody.append("project_id", localStorage.getItem("projectGId"))
    getResonsibilityList(formBody).then(
      (response) => {
        if (response.status == "success") {
          let responsibleData = [
            { name: response?.data[0]?.org_name },
            { name: response?.data[0]?.project_name }
          ]
          // responsibleData = response.data?.map((data) => {
          //   return { name: data?.project_name }
          // })

          setResponsibilityList(responsibleData);
        }
      }
    );
  };

  const getActivityList = (category) => {
    getActivities(category).then((response) => {
      if (response.status == "success") {
        setSOWActivity(response.data);
      }
    });
  };

  const createSowTicket = () => {
    let body = {
      organization_id: localStorage.getItem("organizationId"),
      user_id: localStorage.getItem("userId"),
      sow_task_id: ticketId,
      sow_id: sowId
    }
    createSowMainTicket(body).then((response) => {
      if (response.status == "success") {
        createSubTicket(response.message.id)
      }
    })
  }

  const createSubTicket = (mainTicketId) => {
    let assignedArray1 = [];

    assignedArray.forEach((i) => {
      let obj = {
        assigned_to: i.AssignedId,
        units: i.AssignedUnit ? parseInt(i.AssignedUnit) : "",
        // subticketId: i.isNew ? null : ticketId,
        id: i.AllocationId ?? "",
      };
      assignedArray1.push(obj);
    });

    let body = {
      sow_task_id: ticketId,
      mainticket_id: mainTicketId,
      created_by: localStorage.getItem("userId"),
      assigned_to_units: assignedArray1
    }

    createSowSubTicket(body).then((response) => {
      if (response.status == "success") {
        onClose1();
        getTicketList()
        toast.success("Tickets Added Successfully")
      }
    })
  }

  useEffect(() => {
    let totalUNITS = 0;
    assignedArray.forEach((i) => {
      totalUNITS = totalUNITS + parseInt(i.AssignedUnit);
    });
    if (buttonCreateAllocatiion) {
      if (unitsData >= totalUNITS) {
        buttonCreateAllocatiion.disabled = false;
        // buttonAddSowTicket.disabled = false;
      } else {
        buttonCreateAllocatiion.disabled = true;
        // buttonAddSowTicket.disabled = true;
      }
    }
  }, [assignedArray])

  const createTicket = () => {
    let totalUNITS = 0;
    let assignedArray1 = [];
    let flag;
    let isValidData = true;
    flag = assignedArray.some((code) => code.AssignedUnit === "");
    assignedArray.forEach((i) => {
      totalUNITS = totalUNITS + parseInt(i.AssignedUnit);
      let obj = {
        AssignedTo: i.AssignedTo,
        AssignedUnit: i.AssignedUnit ? parseInt(i.AssignedUnit) : "",
        subticketId: i.isNew ? null : ticketId,
        AllocationId: i.AssignedId,
      };
      assignedArray1.push(obj);
    });

    if (assignedArray?.length > 0) {

      if (assignedArray.some((code) => code.AssignedId === "")) {
        isValidData = false;
        setMessage1("Please Select Member to assign Units.")
      } else {
        if (assignedArray.some((code) => code.AssignedUnit === "")) {
          isValidData = false;
          setMessage1("Please provide a value for Units.")
        } else {
          if (unitsData >= totalUNITS) {
            isModelDismiss = true;
            isValidData = true;
          } else {
            isModelDismiss = false;
            isValidData = false;
            setMessage1("Total units should not exceed " + unitsData);
            buttonCreateAllocatiion.disabled = true;
            buttonAddSowTicket.disabled = true;
          }
        }
      }
    } else {
      isValidData = false
      setMessage1("Please Add a Team member")
      buttonAddSowTicket.disabled = true;
    }


    if (isValidData === true) {
      setMessage1("");
      if (!mainTicketId) {
        createSowTicket()
      } else {
        createSubTicket(mainTicketId)
      }
    } else {
      isModelDismiss = false;
      // buttonCreateAllocatiion.disabled = true;
      // buttonAddSowTicket.disabled = true;
    }


  }


  const getSowSubTicketData = (id) => {
    getSowAssigned(id).then((response) => {
      if (response.status == "success") {
        var tempArray = [];
        let sumOfUnits = 0;
        let members = []
        response.data.forEach((item) => {
          tempArray.push({
            AssignedTo: item.assigned_to_name,
            AssignedUnit: item.assigned_units,
            AssignedId: item.assigned_to,
            isNew: false,
            AllocationId: item.id,
            status: item?.ticket_status_name
          });
          sumOfUnits =
            sumOfUnits +
            parseInt(item.assignedUnit === "" ? "0" : item.assignedUnit);
          members.push(item.assigned_to)
        });
        setMainTicketId(response.mainticket_id)

        // if (units !== sumOfUnits) {
        showLargeDrawer1();
        // }
        setassignedArray([]);
        setassignedArray(tempArray);
        getMemberList()

      }
    })
  }

  const getMemberList = (members) => {
    let body = {
      organisation_id: localStorage.getItem("organizationId"),
      user_id: localStorage.getItem("userId"),
      // "sow_id":"0b1a8db2-5e61-404f-b1a1-7f7278fcf8da",
      sow_id: sowId,
      member_id: members ?? []
    }
    getSowMembers(body).then((response) => {
      if (response.status == "success") {
        let members = []
        response.members_list.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          members.push(obj);
        });
        setmemberlist(members)
      }
    })
  }

  function totalUnitsFun(array) {
    var i = 0;
    array.forEach((item) => {
      i = i + parseInt(item.AssignedUnit === "" ? "0" : item.AssignedUnit);
    });
    let unitsRemaining = unitsData - i;
    if (unitsRemaining < 0) {
      setMessage("Total should not increase " + unitsData);
      document.querySelector("#addSOWTicket").disabled = true;
      document.querySelector("#createAllocationBtn").disabled = true;
    } else if (unitsRemaining === 0) {
      setMessage("Units Remaining : 00");
      document.querySelector("#addSOWTicket").disabled = false;
      document.querySelector("#createAllocationBtn").disabled = true;
    } else {
      setMessage("Units Remaining : " + unitsRemaining);
      document.querySelector("#addSOWTicket").disabled = false;
      document.querySelector("#createAllocationBtn").disabled = false;
    }
  }

  function addContent() {
    setMessage1("")
    if (!memberlist?.length) return;
    var tempArray = JSON.parse(JSON.stringify(assignedArray));
    tempArray.push({
      AssignedTo: "",
      AssignedUnit: "",
      AssignedId: "",
      isNew: true,
      AllocationId: "",
    });
    setassignedArray(tempArray);
    var text = "Generate " + tempArray?.length + " Sub Tickets";
    setaddSowTicketButton(text);
    totalUnitsFun(tempArray);
  }

  function removeContent(i) {
    let list = [...assignedArray];
    list.splice(i, 1);
    setassignedArray(list);
    if (list?.length !== 0) {
      var text = "Generate " + list?.length + " Sub Tickets";
    } else {
      text = "Add SOW Ticket";
      setMessage("");
      setMessage1("");
    }
    setaddSowTicketButton(text);
    totalUnitsFun(list);
  }

  async function deleteSowFun(sowId) {
    try {
      const response = await APIManager.deteletItem(
        deleteSowAllocation + sowId
      );
      if (response && response.status === 200) {
        let rowData = {
          searchText: searchText,
          startDate: mmDDyyyyFormateDateSet(head1),
          endDate: mmDDyyyyFormateDateSet(head2),
          projectId: localStorage.getItem("projectGId"),
        };
        getTicketList(rowData);
      }
    } catch (e) { }
  }



  function getAllocationDetailFun(sowDetailId, units) {
    APIManager.getAPI(getAllocationDetail + sowDetailId).then((response) => {
      if (response.status === 200) {
        buttonCreateAllocatiion = document.getElementById(
          "createAllocationBtn"
        );
        var tempArray = [];
        let sumOfUnits = 0;
        response.data.forEach((item) => {
          tempArray.push({
            AssignedTo: item.assignedTo,
            AssignedUnit: item.assignedUnit,
            AssignedId: item.id,
            isNew: false,
            AllocationId: item.id,
          });
          sumOfUnits =
            sumOfUnits +
            parseInt(item.assignedUnit === "" ? "0" : item.assignedUnit);
        });

        // if (units !== sumOfUnits) {
        showLargeDrawer1();
        // }
        setassignedArray([]);
        setassignedArray(tempArray);
      }
    });
  }

  function getmembersData() {
    APIManager.getAPI(
      getAssignedMembersWithCustomer + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
      }
    });
  }

  function uploadkeywordFile() {
    setAddSow(true);
    setFileName("");
    document.querySelector("#sowFile").disabled = false;
    var input = document.getElementById("sowFile");
    let file = input.files.item(0);
    if (file.name?.length > 0) {
      // document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      // document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileSetelctedMsg("");
    setFileName(file.name);
    clearFile();
  }

  const clearFile = () => {
    // document.querySelector(
    //   "#textInputUrl"
    // ).disabled = false;
  };

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function sowListImport() {
    var input = document.getElementById("sowFile");
    let file = input.files.item(0);
    var bodyFormData = new FormData();
    // bodyFormData.append("projectId", localStorage.getItem("projectGId"));
    bodyFormData.append("sowid", sowId);
    bodyFormData.append("orgid", localStorage.getItem("organizationId"));
    bodyFormData.append("userid", localStorage.getItem("userId"));
    bodyFormData.append("status", "Not Assigned");
    bodyFormData.append(
      "priority",
      priorityList?.filter((value) => value?.value == "High")[0]?.id
    );
    bodyFormData.append("type_of_ticket", "SOW Adhoc");
    let isImport = true;

    // if (url &&
    //   document.querySelector("#textInputUrl").disabled === false &&
    //   isValidURL()
    // ) {
    //   bodyFormData.append("url", removeTrailingSlash(url));
    //   isImport = true;
    // } else

    if (document.querySelector("#sowFile").disabled === false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }
    if (isImport) {
      bulkUploadTask(bodyFormData)
        .then((response) => {
          if (response.status == "success") {
            getTicketList();
            onClose2();
            setCloseButton(false);
            setIsBulk(false);
            toast.success("Tasks Uploaded Successfully");
            setUrl("");
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            if (error.response.data?.error) {
              toast.error(error.response.data?.error);
            } else {
              toast.error("Failed to Upload SOW tasks");
            }
            // getTicketList();
            // onClose2();
            // setCloseButton(false);
            // setIsBulk(false);
            // setUrl("");
            // return;
          }
        });

      // OLD API
      // APIManager.postAPI(sowImportNew, {}, bodyFormData, false).then(
      //   (response) => {
      //     if (response?.data && response?.data?.errors) {
      //       toast.error(response?.data?.errors[0]?.errorMessage);
      //       getTicketList();
      //       onClose2();
      //       setCloseButton(false);
      //       setIsBulk(false);
      //       setUrl("");
      //       return;
      //     }
      //     else if (response.status === 200) {
      //       getTicketList();
      //       onClose2();
      //       setCloseButton(false);
      //       setIsBulk(false);
      //       toast.success('Message sent for Approval');
      //       setUrl("");
      //     }
      //   }
      // );
    }
  }

  function getIconFun(asignList) {
    let list = [];
    if (asignList?.length) {
      asignList.forEach((item) => {
        list.push(<span>{item.charAt(0).toUpperCase()}</span>);
      });
      if (list?.length > 0) {
        return list?.slice(0, 2);
      }
      // else {
      //   return <span>{asignList.length}</span>;
      // }
    } else {
      return null;
    }
  }

  function isValid() {
    let flag = true;
    let flag1 = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    var pattern1 = new RegExp(/^-?\d+$/);
    const positiveNumberPattern = /^\d+(\.\d+)?$/;

    if (clientSelected?.trim()?.length > 0) {
      setResponsibleErrorMsg("");
    } else {
      flag = false;
      setResponsibleErrorMsg("Please Select Responsibility");
    }

    if (units === "" || units === undefined) {
      setMessageUnits("Please provide a value for units.");
      flag = false;
    } else if (units !== "") {
      setMessageUnits("");
      flag = true;
      if (!pattern?.test(units)) {
        setMessageUnits("Invalid unit value.");
        flag = false;
      }
      if (!pattern1?.test(units)) {
        setMessageUnits("Invalid unit value.");
        flag = false;
      }
      if (!positiveNumberPattern?.test(units)) {
        setMessageUnits("Invalid unit value.");
        flag = false;
      }
      if (units === "0") {
        setMessageUnits("Units should be greater than 0.");
        flag = false;
      }
    }
    if (otherValue) {
      if (SOWActivityText?.trim()?.length > 0) {
        setMessageActivity("");
        flag1 = true;
      } else {
        setMessageActivity("Please enter activity.");
        flag1 = false;
      }
    } else {
      if (selectedSOWActivity?.trim()?.length > 0) {
        setMessageActivity("");
        flag1 = true;
      } else {
        setMessageActivity("Please enter activity.");
        flag1 = false;
      }
    }

    if (String(frequency)?.trim() && String(frequency)?.trim()?.length > 1) {
      setMessageFrequency("");
      flag1 = true;
    } else {
      setMessageFrequency("Please enter Frequency.");
      flag1 = false;
    }


    if (flag === false) {
      flag1 = false;
    }
    isModelDismiss1 = flag1;
    return flag1;
  }


  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }


  function getMonthsWithYear(editMonths) {
    const result = [];
    const currentDate = new Date();

    for (let i = 0; i < 4; i++) {
      result.push(currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }));
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    let months = result?.reverse()?.map((data, index) => {
      return { monthName: data, key: index }
    })
    let index1 = months[months?.length - 1]?.key
    setmonthListChips(months)
    setIndexData(index1);
    setMonthSelected(true);
    if (editMonths[months[months?.length - 1]?.monthName] == true) {
      setUpdateFields(true)
    } else {
      setUpdateFields(false)
    }
  }

  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = template;
  //   link.download = "Additional_SOW_Template.xlsx";
  //   link.click();
  // };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = additionalSowTemplate;
    link.setAttribute('download', 'Additional_SOW_Template.xlsx');
    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // const getDaysInMonth = (month, year) => {
  //   return new Date(year, month, 0).getDate();
  // };

  // const generateDatesArray = (date) => {
  //   const daysInMonth = getDaysInMonth(date.getMonth() + 1, date.getFullYear());
  //   const datesArray = [];
  //   for (let i = 1; i <= daysInMonth; i++) {
  //     datesArray.push(new Date(date.getFullYear(), date.getMonth(), i));
  //   }
  //   return datesArray;
  // };

  // const disabledDates = generateDatesArray(new Date(2024, 1));

  return (
    <>
      <div class="portlet portlet-header portlet-header-bordered ms-3 me-1 sow-heading">
        <svg
          onClick={() => update("")}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          class="feather feather-chevron-left close-left-icon ms-3"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
        <h3 class="portlet-title main-title">Scope Of Work</h3>
      </div>

      <div className="ms-3 me-2">
        <CustomNotice
          hidden={ticketMsgHidden}
          addedStyle={{ marginBottom: "1rem" }}
          leftIcon={"info"}
          title={"Ticket Generation"}
          addedTitleStyles={styles.ticketMessageHeader}
          // addedDescriptionStyles={{...textStyles.b2}}
          description={
            "Below is the list of SOW tasks for each month. Tickets and Sub tickets for each activity will be generated on assigning the units to the team members."
          }
          theme={"warning"}
          onClose={() => setTicketMsgHidden(true)}
        />
      </div>

      <div className="row ">
        <div className="col-md-12 d-flex flex-wrap align-items-center sow-month p-0 mt-1">
          {monthListChips.map((item, index) =>
            availableMonths?.includes(moment(item.monthName).format("MMM 1, YYYY")) && (
              <div className="  ms-3 pb-0">
                <button
                  type="button"
                  class={
                    monthSelected && indexData === index
                      ? classSOW
                      : "btn btn-light"
                  }
                  onClick={() => {
                    setCalenderHeading("Pick a Month");
                    setIsActive(false);
                    setMonthSelected(true);
                    setIndexData(index);
                    let isMonthSelected = true;
                    getClassName(index, index, isMonthSelected);
                    let text = item.monthName;
                    let myArray = text.split(" ");
                    let monthNum = getMonthNumberFromName(myArray[0], myArray[1]);
                    var daysInMonth = getDaysInMonth(myArray[1], monthNum);
                    let dateObjArr = [
                      new Date(monthNum + "/01/" + myArray[1]),
                      new Date(monthNum + "/" + daysInMonth + "/" + myArray[1]),
                    ];
                    setHead1(dateObjArr[0]);
                    setHead2(dateObjArr[1]);
                    let rowData = {
                      searchText: searchText,
                      startDate: mmDDyyyyFormateDateSet(dateObjArr[0]),
                      endDate: mmDDyyyyFormateDateSet(dateObjArr[1]),
                      projectId: localStorage.getItem("projectGId"),
                    };
                    if (editableMonths[moment(dateObjArr[0]).format("MMMM YYYY")] == true) {
                      setUpdateFields(true)
                    } else {
                      setUpdateFields(false)
                    }
                    setUpdateTableState(new Date())
                    getTicketList(rowData);
                  }}
                >
                  {monthSelected && indexData === index ? (
                    <i class="fa fa-check me-3" aria-hidden="true"></i>
                  ) : (
                    <></>
                  )}
                  {moment(item.monthName).format("MMM YYYY")}
                </button>
              </div>
            ))}
          <div ref={ref} className="px-3  pb-0">
            <Popper visible={open} placement="bottom-start">
              <Popper.Trigger>
                <button
                  type="button"
                  class={
                    isActive === true ? "btn btn-light active" : " btn btn-light"
                  }
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <i class="fa fa-calendar me-3" aria-hidden="true"></i>
                  {calenderHeading}
                </button></Popper.Trigger>
              <Popper.Popper>
                <div className="shadow-lg" css={{ marginTop: "1rem" }}>
                  <MonthPickerSingle
                    defaultValueSingle={calendarDay}
                    addBottomContainerStyles={{ flexDirection: "row-reverse !important" }}
                    showCompare={false}
                    hideTrigger={true}
                    availableMonths={availableMonths}
                    disabledDefault={!calendarDay}
                    onApplyClick={
                      // !calendarDay ?
                      //   () => { } :
                      (checked, value) => {
                        let monthName = new Date(value).toLocaleString("default", {
                          month: "long",
                        });
                        let year = new Date(value).getFullYear();
                        let monthYr = monthName + " " + year;
                        setCalenderHeading(moment(monthYr).format("MMM YYYY"));
                        setIsActive(true);
                        setClassSOW("");
                        setMonthSelected(false);
                        setClassSOW("btn btn-light");
                        setMonthSelected(false);
                        setCalendarDay(value)
                        let rowData = {
                          searchText: searchText,
                          startDate: mmDDyyyyFormateDateSet(value),
                          endDate: mmDDyyyyFormateDateSet(value),
                          projectId: localStorage.getItem("projectGId"),
                        };
                        if (editableMonths[moment(value).format("MMMM YYYY")] == true) {
                          setUpdateFields(true)
                        } else {
                          setUpdateFields(false)
                        }
                        setUpdateTableState(new Date())
                        getTicketList(rowData)
                        setOpen(!open);
                      }}
                  />
                </div>
              </Popper.Popper>
            </Popper>
          </div>
        </div>

        <div class=" d-flex align-items-center justify-content-between">
          <div className="right-box d-flex align-items-center">
            <div className="calendar-main d-flex justify-content-end align-items-center ga-calender ms-3"></div>
          </div>
        </div>
      </div>

      <div class="all-ticket-box mt-4">
        <div class="row scope-search px-0">
          <div className="d-flex justify-content-between align-items-center position-relative search-wrap">
            <div class="sow-search-box d-flex  w-100 me-3 position-relative">
              <i class={`fa fa-search ${isSearchFocused && "focused"}`}></i>
              <input
                type="text"
                css={styles.globalSearchBar(isSearchFocused)}
                className="scope-input w-100 ms-1"
                placeholder="Search Activity"
                name="search"
                value={searchText}
                onKeyDown={(e) => {
                  _handleKeyDown(e);
                }}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                onChange={(industrySearch) => {
                  let data = tableData1
                  setSearchText(industrySearch.target.value);
                  let list = data.filter((person) =>
                    person.activityFilter
                      .toLocaleLowerCase()
                      .includes(industrySearch.target.value.toLocaleLowerCase()?.trim())
                  );
                  setTableData(list)
                  // setTicketLength(list?.length);
                }}
              />
            </div>
            <div className="scope-work-btn d-flex 3-icons align-items-center">
              <div class="scope-work-btn scope-work me-3">
                <span>
                  <input
                    className="w-100"
                    style={{ display: "none" }}
                    type="file"
                    id="sowFile"
                    name="sowFile"
                    multiple={false}
                    accept={documentFileType}
                    ref={inputFileRef}
                    onChange={() => {
                      let input = inputFileRef.current;
                      const fileExtension = input.files[0].name
                        .split(".")
                        .at(-1);
                      let isvalid = isValidBulkUploadSow(fileExtension);
                      if (isvalid === true) {
                        uploadkeywordFile();
                      } else {
                        document.getElementById("industryFile").value = "";
                      }
                    }}
                  />
                  {updateFields && (
                    <a
                      className="btn btn-primary scope-download h-auto"
                      onClick={() => { handleDownload() }}
                    // target="_blank"
                    // href={"../assets/templates/Additional_SOW_Template.xlsx"}
                    // download={"Additional_SOW_Template.xlsx"}
                    // rel="noreferrer"
                    >
                      Download Template{" "}
                    </a>
                  )}
                </span>
              </div>

              {updateFields && (
                <button
                  class=" btn btn-light btn-icon h-auto d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setOtherValue(false);
                    setSelectedCategory();
                    setUnits();
                    setSOWActivityText("");
                    setFileName("");
                    setIsBulk(false);
                    setIsBulk(false);
                    setIsIndividual(false);
                    setAddSow(false);
                    showLargeDrawer2();
                    setMessageActivity("");
                    setMessageFrequency("")
                    setMessageUnits("");
                    setResponsibleErrorMsg("");
                    setCloseButton(false);
                    setUrl("");
                    setErrors("");
                    setClassName1("");
                    setClassName2("");
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: 500,
                      marginRight: "4px",
                    }}
                  >
                    +
                  </span>{" "}
                  Add Task
                </button>
              )}
              <Drawer
                title={
                  <div css={{ ...textStyles.h6 }}>{title}</div>
                }
                className="create-subticket industries-modal upload-scope-modal"
                placement="right"
                size={"default"}
                onClose={onClose2}
                open={open2}
                footer={
                  addSow && (
                    <div>
                      {addSow ? (
                        <div className="col-md-12 d-flex justify-content-end">
                          <div class="portlet-footer text-end scopebtn">
                            <button
                              className="btn btn-outline-secondary me-3"
                              onClick={onClose2}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class={`btn btn-primary w-auto ${isBulk ? (fileName ? "" : "disabled") : ""
                                }`}
                              data-dismiss={isModelDismiss1 ? "modal" : ""}
                              onClick={() => {
                                if (isBulk) {
                                  setsave(true);
                                  sowListImport();
                                } else {
                                  if (isValid()) {
                                    addTicket();
                                  }
                                }
                              }}
                            >
                              <div>Create SOW Tasks</div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                }
              >
                <div class="modal-dialog scope-modal" role="document">
                  <div class="modal-content">
                    <div class="modal-body p-4">
                      <div className="portlet common-form-fields border-0">
                        <div className="">
                          <div
                            className={className1}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setIsBulk(true);
                              setIsIndividual(false);
                              setClassName1("active");
                              setClassName2("");
                              setAddSow(true);
                            }}
                          >
                            <div className="radio">
                              <div className="ms-3">
                                <input
                                  className="me-3"
                                  type="radio"
                                  value="option1"
                                  checked={isBulk}
                                  id="option1"
                                  name="uploadType"
                                  onClick={(e) => {
                                    // setIsBulk(e.target.checked);
                                    // setIsIndividual(false);
                                    // setClassName1("active");
                                    // setClassName2("");
                                    // setAddSow(false);
                                  }}
                                />
                                <label css={{ color: isBulk ? Colors.primary[500] : Colors.black }} className={`bulk-upload  main-title`}>
                                  Bulk Upload
                                </label>
                              </div>

                              <span className="ms-5 text-grey ">
                                Setup multiple (bulk) upload with the details
                                through excel file
                              </span>

                              {isBulk ? (
                                <>
                                  <div className="border-top mt-3 pt-3 ">
                                    {/* <span className="ms-5 industries-csv-file">
                                      Add URL (Google Sheet Link)
                                    </span> */}
                                    {/* <div className="mt-2 ms-5 me-3">
                                      <input
                                        className="form-control w-100"
                                        placeholder="URL"
                                        id="textInputUrl"
                                        value={url}
                                        onChange={(e) => {
                                          setUrl(e.target.value);
                                          if (e.target.value.length > 0) {
                                            setAddSow(true);
                                            document.querySelector(
                                              "#sowFile"
                                            ).disabled = true;
                                          } else {
                                            setAddSow(false);
                                            setErrors("");
                                            document.querySelector(
                                              "#sowFile"
                                            ).disabled = false;
                                          }
                                        }}
                                      ></input>
                                      {save && errors?.url && (
                                        <>
                                          <div className="field-required mt-2">
                                            <span> {errors?.url}</span>
                                          </div>
                                        </>
                                      )}
                                    </div> */}
                                    {/* <div className="my-3 text-center or">
                                      <span>OR</span>
                                    </div> */}
                                    <span className="ms-5 csv-file">
                                      Add attachment (Only Excel file)
                                    </span>
                                  </div>
                                  <div
                                    className="d-flex align-items-center"
                                    css={{ width: "100%" }}
                                  >
                                    <div
                                      css={{ width: "60%" }}
                                      className="scope-choose-file d-flex align-items-center justify-content-between p-2 mt-1 ms-5 me-3"
                                    >
                                      <div css={{ maxWidth: "90%", }} className="scope-choose-file p-2 d-flex align-items-center justify-content-start border-0">
                                        <button
                                          css={{
                                            ...textStyles.s2,
                                            minWidth: "8rem !important",
                                          }}
                                          onClick={onBtnClick}
                                          id="btnChooseFile"
                                          className="btn btn-primary me-3"
                                        >
                                          Choose File
                                        </button>
                                        <span className="file-not-select ">
                                          {fileSetelctedMsg}
                                        </span>
                                        <div css={{

                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis"
                                        }}>
                                          {" "}
                                          {fileName.length ? fileName : ""}{" "}
                                        </div>
                                      </div>
                                      {closeButton ? (
                                        <>
                                          <div className="d-flex align-items-center justify-content-end">
                                            <button
                                              type="button"
                                              title="Close pe-0"
                                              style={{
                                                border: "0",
                                                background: "none",
                                              }}
                                              class="close"
                                              onClick={() => {
                                                setFileName("");
                                                setCloseButton(false);
                                                document.getElementById(
                                                  "sowFile"
                                                ).value = "";
                                                // document.querySelector(
                                                //   "#textInputUrl"
                                                // ).disabled = false;
                                              }}
                                            >
                                              {" "}
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div
                                      css={{
                                        ...textStyles.H1Medium,
                                        textDecoration: "underline",
                                        color: Colors.primary[500],
                                        textDecorationColor:
                                          Colors.primary[500],
                                      }}
                                    >
                                      {updateFields && (
                                        <a
                                          onClick={() => {
                                            handleDownload()
                                          }}
                                        // target="_blank"
                                        // href={additionalSowTemplate}
                                        // download={"Additional_SOW_Template.xlsx"}
                                        // rel="noreferrer"
                                        >
                                          Download Template{" "}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div
                            className={className2}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsIndividual(true);
                              setIsBulk(false);
                              setClassName2("active");
                              setClassName1("");
                              setMessageUnits("");
                              setMessageFrequency("")
                              setResponsibleErrorMsg("");
                              setMessageActivity("");
                              setAddSow(true);
                            }}
                          >
                            <div className="radio mt-2">
                              <div>
                                <input
                                  className="ms-3"
                                  type="radio"
                                  value="option2"
                                  checked={isIndividual}
                                  id="option2"
                                  name="uploadType"
                                  onClick={(e) => {
                                    // setIsIndividual(e.target.checked);
                                    // setIsBulk(false);
                                    // setClassName2("active");
                                    // setClassName1("");
                                    // setMessageUnits("");
                                    // setMessageActivity("");
                                    // setAddSow(true);
                                  }}
                                />
                                <label css={{ color: isIndividual ? Colors.primary[500] : Colors.black }} className=" individual-upload  ms-3 main-title">
                                  Individual Upload
                                </label>
                              </div>
                              <span className="mt-3 ms-5 text-grey">
                                Setup individual SOW tasks with details
                              </span>
                              <div className="">
                                {isIndividual ? (
                                  <div
                                    css={styles.individualFormStyles}
                                    className="portlet-body  individual-SOW border-top mt-3 "
                                  >
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Category
                                      </label>
                                      <select
                                        style={{ padding: 8 + "px" }}
                                        className="w-100 rounded form-select"
                                        value={selectedCategory}
                                        onChange={(e) => {
                                          setSelectedCategory(e.target.value);
                                          if (e.target.value === "Others") {
                                            setOtherValue(true);
                                          } else {
                                            setOtherValue(false);
                                            getActivityList(e.target.value);
                                          }
                                        }}
                                      >
                                        <option hidden value={null}>
                                          Select Category
                                        </option>
                                        {categoryList?.map((data) => {
                                          return (
                                            <option value={data.value}>
                                              {data.value}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Responsibility
                                      </label>
                                      <label class="field-required"> * </label>
                                      <select
                                        className="rounded form-select"
                                        required
                                        value={clientSelected}
                                        onChange={(e) => {
                                          setClientSelected(e.target.value);
                                        }}
                                      >
                                        <option hidden value={null}>
                                          Select
                                        </option>
                                        {responsibilityList.map((item) => {
                                          return (
                                            <option value={item.name}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div css={styles.errorMsg}>
                                        {responsibleErrorMsg}
                                      </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Activity
                                      </label>
                                      <label class="field-required"> * </label>

                                      {otherValue ? (
                                        <>
                                          {" "}
                                          <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            value={SOWActivityText}
                                            onChange={(e) => {
                                              setSOWActivityText(
                                                e.target.value
                                              );
                                              // const newValue = e.target.value.replace(/\s+/g, ' ');
                                              // setSOWActivityText(newValue.trim());
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <select
                                          disabled={!selectedCategory}
                                          className="rounded form-select"
                                          required
                                          defaultValue={"Select Activity"}
                                          placeHolder="Select Activity"
                                          value={selectedSOWActivity}
                                          onChange={(e) => {
                                            setSelectedSOWActivity(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option hidden value={null}>
                                            Select Activity
                                          </option>
                                          {sowActivityData.map((item) => {
                                            return (
                                              <option value={item.activities}>
                                                {item.activities}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      )}

                                      <div css={styles.errorMsg}>
                                        {messageActivity}
                                      </div>
                                    </div>

                                    <div class="col-md-12 unit mt-3">
                                      <label className="form-label">
                                        Total Units
                                      </label>
                                      <label class="field-required"> * </label>
                                      <input
                                        className="form-control form-control-lg"
                                        onWheel={(event) => event.currentTarget.blur()}
                                        type="number"
                                        placeholder="Enter total units"
                                        value={units}
                                        onChange={(e) => {
                                          const value = e.target.value.replace(/[^0-9]/g, '');
                                          setUnits(value);
                                        }}
                                      />
                                      <div css={styles.errorMsg}>
                                        {messageUnits}
                                      </div>
                                    </div>

                                    <div class="col-md-12 unit mt-3">
                                      <label className="form-label">
                                        Frequency
                                      </label>
                                      <label class="field-required"> * </label>
                                      <select
                                        style={{ padding: 8 + "px" }}
                                        className="w-100 rounded form-select"
                                        value={frequency}
                                        onChange={(e) => {
                                          setFrequency(e.target.value);
                                        }}
                                      >
                                        <option hidden value={null}>
                                          Select
                                        </option>
                                        {frequencyList?.map((data) => {
                                          return (
                                            <>
                                              {data.value && (
                                                <option value={data?.value}>
                                                  {data?.value}
                                                </option>
                                              )}
                                            </>
                                          );
                                        })}
                                      </select>
                                      <div css={styles.errorMsg}>
                                        {messageFrequency}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="btn-box-bottom">
                          {addSow ? (
                            <div className="col-md-12 d-flex justify-content-end">
                              <div class="portlet-footer portlet-footer-bordered mt-3 text-end scopebtn">
                                <button
                                  className="btn btn-outline-secondary me-3"
                                  onClick={onClose2}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class={`btn btn-primary w-auto ${isBulk ? fileName ? "" : "disabled" : ""}`}
                                  data-dismiss={isModelDismiss1 ? "modal" : ""}
                                  onClick={() => {
                                    if (isBulk) {
                                      setsave(true);
                                      sowListImport();
                                    } else {
                                      if (isValid()) {
                                        addTicket();
                                      }
                                    }
                                  }}
                                >
                                  <div>Create SOW Tasks</div>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
              <Drawer
                title={`${updateFields ? "Create " : ""}Subticket`}
                className="create-subticket-modal industries-modal p-0 align-items-center"
                placement="right"
                size={size1}
                onClose={onClose1}
                open={open1}
              // open={true}
              >
                <div class="modal-dialog subticket-modal" role="document">
                  <div class="modal-content">
                    <div className="col-md-12 scope-ticket-btn">
                      <div class="portlet-footer portlet-footer-bordered mt-3 add-SOW-Ticket w-100" css={{ backgroundColor: Colors.plainWhite, padding: "1rem" }}>
                        <div className="ms-3 pb-3" style={{ color: "red", textAlign: "center" }}>
                          {message1}
                        </div>
                        <button
                          id="addSOWTicket"
                          type="button"
                          class={`btn btn-primary w-100 ${!updateFields && "disabled"
                            }`}
                          data-dismiss={isModelDismiss ? "modal" : ""}
                          onClick={() => {
                            // addAllocation();.
                            createTicket()
                          }}
                        >
                          {/* Add SOW Ticket */}
                          {addSowTicketButton}
                        </button>
                        <div class="mt-3 text-center unit-remmaining ms-3 ps-1">
                          {message}
                        </div>
                      </div>
                    </div>
                    <div class="modal-body p-0">
                      <div className="portlet common-form-fields border-0 ">
                        <div className="portlet-body mt-2 border-bottom pb-3">
                          <div className="row">
                            <div className="d-flex align-items-center justify-content-between" css={{ width: "100%" }}>
                              <div className="d-flex align-items-top justify-content-start">
                                <div className="subticket-svg">
                                  <svg
                                    width="26"
                                    height="20"
                                    viewBox="0 0 26 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sub-addticket-icon"
                                  >
                                    <path
                                      d="M23 10C23 9.05 23.5375 8.225 24.325 7.8C25.075 7.3875 25.5 6.5375 25.5 5.675V2.5C25.5 1.125 24.375 0 23 0H3C1.625 0 0.5125 1.1125 0.5125 2.4875V5.675C0.5125 6.5375 0.925 7.3875 1.6875 7.7875C2.475 8.225 3 9.05 3 10C3 10.95 2.4625 11.7875 1.675 12.2C0.925 12.6125 0.5 13.4625 0.5 14.325V17.5C0.5 18.875 1.625 20 3 20H23C24.375 20 25.5 18.875 25.5 17.5V14.325C25.5 13.4625 25.075 12.6125 24.325 12.2C23.5375 11.775 23 10.95 23 10ZM16.125 15.125L13 13.125L9.875 15.1375C9.4 15.4375 8.7875 15 8.9375 14.45L9.875 10.85L7 8.5C6.5625 8.1375 6.7875 7.425 7.3625 7.3875L11.0625 7.175L12.4125 3.7375C12.625 3.2125 13.375 3.2125 13.575 3.7375L14.925 7.1875L18.625 7.4C19.1875 7.4375 19.425 8.15 18.9875 8.5125L16.1125 10.8625L17.0625 14.4375C17.2125 15 16.6 15.4375 16.125 15.125Z"
                                      fill="#0691EF"
                                    />
                                  </svg>
                                </div>
                                <h6 className="form-label text-capitalize activity-heading ms-3 w-70">
                                  {activityHeading}
                                </h6>
                              </div>
                              {/* <button
                                className={`btn btn-primary d-flex align-items-center ${!updateFields && "disabled"
                                  } scope-addteam-member`}
                                onClick={() => addContent()}
                                id="createAllocationBtn"
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                <span className="ms-2">Add Team Member</span>
                              </button> */}
                              <CustomButton
                                id={"createAllocationBtn"} use={"primary"} text={"Add Team Member"} leftIcon={"plus"}
                                disabled={!updateFields}
                                onPress={() => addContent()}
                              />
                            </div>
                          </div>
                          <div className="d-flex mt-1 align-items-center scope-total-unit">
                            <label className="form-label me-3">
                              Total Units
                            </label>
                            <input
                              className="form-control"
                              value={unitsData}
                              disabled={true}
                            />
                            {!memberlist?.length ? (
                              <div
                                className="ms-3 ps-1"
                                style={{ color: "red" }}
                              >
                                There is no member for assign
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div css={{ paddingBottom: "8rem" }}>
                          {true ? (
                            assignedArray.map((item, index) => (
                              <div className="portlet-body border-bottom pt-1 pb-3">
                                <div className="d-flex align-items-center sow-create justify-content-between ">
                                  <div className="d-flex align-items-center width-available">
                                    <div className="me-2 w-100">
                                      <label className="form-label justify-content-between assignto">
                                        Assigned to
                                      </label>
                                      <select
                                        disabled={!updateFields ? true : disabledStatus?.includes(item.status)}
                                        className="form-select w-100 "
                                        value={assignedArray[index]?.AssignedId}
                                        onChange={(e) => {
                                          const updateArrayObject =
                                            assignedArray.map((item1, i) => {
                                              if (i === index) {
                                                let obj = {
                                                  AssignedTo: "",
                                                  AssignedUnit:
                                                    item1.AssignedUnit,
                                                  AssignedId: e.target.value,
                                                  isNew: item.isNew,
                                                  AllocationId: item.AllocationId,
                                                };
                                                return obj;
                                              } else {
                                                return item1;
                                              }
                                            });
                                          setassignedArray(updateArrayObject);
                                        }}
                                      >
                                        <option hidden value={null}>
                                          Select Member
                                        </option>
                                        {memberlist.map((item) => {
                                          return (
                                            <option value={item?.id?.toLowerCase()} hidden={assignedArray?.map((e) => e.AssignedId?.toLowerCase()).includes(item.id?.toLowerCase())}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className=" scope-unit w-100">
                                      <label className="form-label justify-content-between assignto">
                                        Units
                                      </label>
                                      <input
                                        disabled={!updateFields ? true : disabledStatus?.includes(item.status)}
                                        type="number"
                                        className=" form-control w-100"
                                        value={assignedArray[index].AssignedUnit}
                                        onKeyPress={(e) => {
                                          if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          var pattern = new RegExp(/^[0-9\b]+$/);
                                          var pattern1 = new RegExp(/^-?\d+$/);

                                          const value = e.target.value.replace(/[^0-9]/g, '');

                                          const updateArrayObject =
                                            assignedArray.map((c, i) => {
                                              if (i === index) {
                                                let obj = {
                                                  AssignedTo: c.AssignedTo,
                                                  AssignedUnit: value,
                                                  AssignedId: c.AssignedId,
                                                  isNew: c.isNew,
                                                  AllocationId: item.AllocationId,
                                                };
                                                return obj;
                                              } else {
                                                return c;
                                              }
                                            });
                                          setassignedArray(updateArrayObject);
                                          totalUnitsFun(updateArrayObject);
                                          if (+value === 0) {
                                            setMessage1(
                                              "Unit should not be Zero(0) or Empty"
                                            );
                                            buttonCreateAllocatiion.disabled = true;
                                            buttonAddSowTicket.disabled = true;
                                          } else if (+value < 0) {
                                            setMessage1(
                                              "Unit should be greater than zero."
                                            );
                                            buttonCreateAllocatiion.disabled = true;
                                            buttonAddSowTicket.disabled = true;
                                          } else {
                                            if (!pattern.test(value)) {
                                              setMessage1(
                                                "Please enter only numbers"
                                              );
                                              buttonAddSowTicket.disabled = true;
                                              buttonCreateAllocatiion.disabled = true;
                                            } else {
                                              buttonAddSowTicket.disabled = false;
                                              buttonCreateAllocatiion.disabled = false;

                                              setMessage1("");
                                            }
                                            if (!pattern1.test(value)) {
                                              setMessage1(
                                                "Please do not enter decimal value"
                                              );
                                              buttonAddSowTicket.disabled = true;
                                              buttonCreateAllocatiion.disabled = true;
                                            } else {
                                              buttonAddSowTicket.disabled = false;
                                              buttonCreateAllocatiion.disabled = false;
                                              setMessage1("");
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="svg-box mt-4 pt-3 ms-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke={"#FC3D39"}
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className={`feather feather-trash-2 scope-trash-icon trash-icon text-danger my-3 ${!updateFields ? true : disabledStatus?.includes(item.status) && "opacity-25"
                                        }`}
                                      onClick={
                                        updateFields && !disabledStatus?.includes(item.status)
                                          ? () => {
                                            removeContent(index);
                                            // deleteSowFun(item.AssignedId);
                                            buttonAddSowTicket.disabled = false;
                                          }
                                          : () => { }
                                      }
                                    >
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                      ></line>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                          {/* <div className="ms-3 mt-1 ps-1" style={{ color: "red" }}>
                            {message1}
                          </div> */}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
        <div className="col-md-12 sow-table-info mt-3 ms-2">
          <h4>SOW Tasks ({ticketLength})</h4>
          <span>All the SOW tasks are listed below</span>
        </div>
        <div css={styles.tableContainer}>
          <div class="col-md-12 mt-3 ms-1" css={styles.tableMain}>
            <Table
              key={updateTableState}
              onChange={(pagination, filters, sorter, extra) => {
                // setTicketLength(extra.currentDataSource?.length);
                if (filters?.category || filters?.priority || filters?.frequency || filters?.status || filters?.uploadedby) {
                  setIsFiltered(true)
                  setCurrentTableData(extra.currentDataSource)
                  // setTableData(extra.currentDataSource)
                } else {
                  setIsFiltered(false)
                  // setTableData(tableData1)
                  setCurrentTableData(extra.currentDataSource)
                  // setTicketLength(tableData1?.length)
                }
              }}
              id="sowTable"
              getPopupContainer={() =>
                document.getElementById("sowTable")
              }
              className="table table-hover mt-3 bg-white custome_filter"
              // id="sample-module-expand"
              columns={tableColumns}
              dataSource={tableData}
              // rowSelection={{ type: "checkbox", ...rowSelection }}
              scroll={{
                x: "max-content",
              }}
              summary={(currentPageData) => {
                currentPageData?.length < 1 && setTicketLength(0)
              }}
              pagination={{
                size: "small",
                position: ["bottomRight"],
                showSizeChanger: true,
                showTotal: (total, range) => {
                  setTicketLength(total)
                  return `Showing ${range[0]} to ${range[1]} of ${total} Tasks`
                }
              }}
              locale={{
                emptyText: (
                  <ErrorScreen
                    title={"No Data found"}
                    icon={AssetsImg.ic_empty_table_icon}
                    addStyles={{ minHeight: "15rem", marginTop: "15%" }}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <CustomDeleteModal
        modalHeading={"Delete Ticket?"}
        modalSubHeading={
          "This will delete the ticket including all the subtickets."
        }
        visible={deleteModal?.open}
        handleModalClick={() => {
          deleteSowTask(deleteModal?.id);
        }}
        handleModalClose={() => setDeleteModal({ open: false })}
      />
      {(isLoading) && (
        <SpinContainer
          loading={true}
          background={"rgba(0,0,0,0.4)"}
          css={styles.loader}
        ></SpinContainer>
      )}
    </>
  );
}

export default ScopeOfWork;
