import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

// Ticket Generator Message Section -----------------

export const ticketMessageContainer = css({
  backgroundColor: Colors.secondary[50],
  padding: "16px",
  border: `1px solid ${Colors.secondary[800]}`,
  borderRadius: "6px",
  marginBottom: "1rem",
});

export const loader = css({
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 99999,
})

export const ticketMessageHeader = css({
  ...textStyles.s1,
});

export const ticketType = css({
  backgroundColor: Colors.neutral[50],
  padding: "4px",
  borderRadius: "6px",
  marginLeft: "1rem",
  fontSize: "12   px",
  fontWeight: 400,
});

export const disclaimerIcon = css({
  width: "1rem",
  height: "1rem",
  marginRight: "6px",
});

// Calendar Styles
export const calendarMain = css({
  ".rmdp-calendar": {
    padding: "0 1rem 1rem 1rem",
    maxWidth: "200px",
  },
  ".rmdp-top-class .only.rmdp-month-picker": {
    maxWidth: "180px",
  },
  ".rmdp-shadow": {
    boxShadow: "none",
  },
  ".rmdp-top-class .rmdp-header": {
    marginBottom: "0.5rem",
  },
  ".rmdp-header-values span": {
    ...textStyles.H1125SemiBold,
  },
  ".rmdp-day": {
    width: "60px",
    marginRight: "6px !important",
    marginBottom: "3px !important",
  },
  ".rmdp-wrapper": {
    margin: 0,
  },
  ".rmdp-day.rmdp-today span": {
    borderRadius: "6px",
    padding: "6px",
  },
  ".rmdp-top-class .rmdp-ym .rmdp-range.start, .rmdp-top-class .rmdp-ym .rmdp-range.end":
  {
    borderRadius: "6px",
  },
});

export const calendarContainer = css({
  ...textStyles.b3,
  position: "absolute",
  top: "3.5rem",
  background: "white",
  zIndex: 4,
  borderRadius: "6px",
  padding: "0.6rem 0",
});

// Minor Styles ---------------

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

export const keyword = css({
  ...textStyles.H1Medium,
  color: Colors.neutral[900],
  // backgroundColor: "red",
  textAlign: "center",
  maxWidth: "fit-content",
});

export const selectDropDownContainer = css({
  width: "6.25rem !important",
});

export const individualFormStyles = css({
  ...textStyles.H16Medium,
});

export const antDrawerStyles = css({
  ".ant-drawer-mask": {
    background: "rgba(0, 0, 0, 0.95) !important",
  },
});

// TABLE COMPONENT STYLES -----------

// Table styles

export const tableMain = css({
  ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before":
  {
    width: "0px !important",
  },
  ".ant-table-content .ant-table-thead": {
    height: "5rem",
  },
  ".ant-table-content": {
    minHeight: "687.95px"
  },
  ".ant-table-column-title": {
    flex: "none",
  },
  ".ant-table-column-sorters": {
    justifyContent: "flex-start",
    columnGap: "1rem",
  },
  ".ant-table-filter-trigger": {
    marginLeft: "0.5rem",
  },
  ".ant-table-filter-column": {
    display: "flex",
    justifyContent: "flex-start",
  },
  ".ant-table-column-sorter": {
    marginLeft: 0,
  },
  ".ant-table": {
    padding: 0,
    border: `1px solid #E2E8F0`,
    borderRadius: `0.375rem 0.375rem 0rem 0rem`,
  },
  ".ant-table-tbody > tr > td": {
    ...textStyles.b1,
    borderBottom: "none",
  },
  ".ant-table-measure-row": {
    display: "none",
  },
  ".ranking-table-design .ant-pagination-item, .latest-table .ant-pagination-item":
  {
    minWidth: "30px",
    height: " 30px",
    border: 0,
  },
  ".ant-pagination-item-active": {
    background: "#E2E8F0",
  },
  ".ant-pagination-item": {
    border: 0,
    ...textStyles.s3,
    lineHeight: "24px !important",
  },
  ".ant-pagination-item-ellipsis": {
    height: "19px",
  },
  "ul.configuration-tab-list li:hover a, td:first-child.ant-table-cell div": {
    color: `${Colors.black} !important`
  }
});

// Task Priority Styles

export const priorityStyles = (priority, custom) => {
  let background = Colors.error[50];
  let color = Colors.error[800];
  if (priority == "Medium") {
    background = Colors.warning[50];
    color = Colors.warning[500];
  }
  if (priority == "Low") {
    background = Colors.success[50];
    color = Colors.success[200];
  }
  if (priority == "High") {
    background = Colors.error[50];
    color = Colors.error[800];
  }
  return css({
    ...textStyles.b1,
    background: background,
    color: color,
    border: `1px solid ${color}`,
    ...custom,
  });
};

// Assigned To Styles

export const assignedBox = (hover) =>
  css({
    ...textStyles.b2,
    display: hover ? "" : "none",
    backgroundColor: "white",
    width: "184px",
    borderRadius: "6px",
    padding: "6px 8px 6px 8px",
    position: "absolute",
    bottom: "4.5rem",
    left: "2rem",
    zIndex: 30,
  });

export const moreText = css({
  ...textStyles.H1Medium,
  color: Colors.neutral[500],
});

// Total Units
export const totalUnitsSpacing = css({
  padding: "0 16px",
});

export const tableInputsContainer = css({
  padding: "8px 16px 8px 16px",
  borderRadius: "6px",
  height: "2.5rem",
  width: "140px",
  border: "1px solid",
});

// ETA
export const etaContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 10px",
  borderRadius: "6px",
  height: "2.5rem",
  width: "180px",
  border: "1px solid",
  cursor: "pointer",
});

export const etaCalendarContainer = css({
  ...textStyles.b3,
  position: "absolute",
  background: "white",
  zIndex: 4,
  borderRadius: "6px",
});

export const etaCalendar = css({
  ".___SInput_letz7_gg_": {
    flexDirection: "row-reverse",
    paddingLeft: "1rem !important",
    // paddingRight: "1rem !important"
  },
  ".___SInputMask_bjwsq_gg_.__noHumanizedDate_bjwsq_gg_ .___SMask_bjwsq_gg_, .___SInputMask_bjwsq_gg_.__noHumanizedDate_bjwsq_gg_ .___SValue_bjwsq_gg_":
  {
    border: "none",
  },
});

export const errorMsg = css({
  ...textStyles.s2Medium,
  marginTop: "0.25rem",
  color: Colors.error[800],
});

export const totalUnitsContainer = css({
  backgroundColor: Colors.plainWhite,
  display: "flex",
  alignItems: "center",
  padding: "0 1rem",
  justifyContent: "space-between",
  width: "100%",
  minWidth: "11.5rem !important",
  maxWidth: "11.5rem !important",
  border: `1px solid ${Colors.neutral[500]}`,
  borderRadius: "6px",
  svg: {
    cursor: "pointer",
  },
});

export const unitInput = css({
  width: "50%",
  input: {
    background: "transparent",
    padding: 0,
    width: "80%",
    border: "none",
  },
});

export const globalSearchBar = (isFocused) => {
  return css({
    borderRadius: "0.375rem",
    border: '1px solid',
    ...(isFocused && { borderColor: `${Colors.primary[500]} !important` }),
    transition: 'border-color 0.3s',
  });
}

export const calendarText = css({
  ...textStyles.b3,
});

export const tableContainer = css({
  padding: "0.5rem 1rem",
});

// export const focused = css({
//   borderColor: 'blue',
// })