import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  credentialAddUpdate,
  getCredentialByOrgId,
} from "../../utility/Constant";
import Loading from "../../utility/Loading";

const Credentials = ({ update }) => {
  const [isLoading, setLoading] = useState(false);
  const [fileUpload, setfileUpload] = useState([]);
  const [prnemail, setprnemail] = useState("");
  const [auth2scope, setauth2scope] = useState("");
  const [developertoken, setdevelopertoken] = useState("");
  const [customerid, setcustomerid] = useState("");
  const [cprnemail, setcprnemail] = useState(false);
  const [cprnemailMsg, setcprnemailMsg] = useState("");
  const [cauth2scope, setcauth2scope] = useState(false);
  const [cauth2scopeMsg, setcauth2scopeMsg] = useState("");
  const [cdevelopertoken, setcdevelopertoken] = useState(false);
  const [cdevelopertokenMsg, setcdevelopertokenMsg] = useState("");
  const [ccustomerid, setccustomerid] = useState(false);
  const [ccustomeridMsg, setccustomeridMsg] = useState("");
  const [bidNumber, setBidsNumber] = useState("1000000");
  const [cbids, setcbidsid] = useState(false);
  const [cbidsMsg, setbidsMsg] = useState("");

  useEffect(() => {
    getCredential();
    setLoading(false)
  }, []);

  function getCredential() {
    APIManager.getAPI(
      getCredentialByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        setfileUpload({ name: response.data[0]?.credential.split("\\")[1] });
        setprnemail(response.data[0]?.oAuth2PrnEmail);
        setauth2scope(response.data[0]?.oAuth2Scope);
        setdevelopertoken(response.data[0]?.developerToken);
        setcustomerid(response.data[0]?.customerId);
        setBidsNumber(response.data[0]?.bidNumber);
      }
    });
  }

  function updateFile() {
    var input = document.getElementById("myfile");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
    }
    setfileUpload(input.files.item(0));
  }

  function addCredentialFiles() {
    var bodyFormData = new FormData();
    bodyFormData.append("File", fileUpload);
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    bodyFormData.append("OAuth2PrnEmail", prnemail?.trim());
    bodyFormData.append("OAuth2Scope", auth2scope?.trim());
    bodyFormData.append("DeveloperToken", developertoken?.trim());
    bodyFormData.append("CustomerId", customerid?.trim());
    bodyFormData.append("BidNumber", bidNumber?.trim());
    APIManager.postAPI(credentialAddUpdate, {}, bodyFormData, false).then(
      (response) => {
        if (response.status === 200) {
          getCredential();
        }
      }
    );
  }

  function formValidation() {
    let isValidFields = true;
    if (prnemail?.trim() === "") {
      isValidFields = false;
      setcprnemail(true);
      setcprnemailMsg("OAuth2 Email is required!");
    } else {
      setcprnemail(false);
      setcprnemailMsg("");
    }

    if (auth2scope?.trim() === "") {
      isValidFields = false;
      setcauth2scope(true);
      setcauth2scopeMsg("OAuth2 Scope is required!");
    } else {
      setcauth2scope(false);
      setcauth2scopeMsg("");
    }

    if (developertoken?.trim() === "") {
      isValidFields = false;
      setcdevelopertoken(true);
      setcdevelopertokenMsg("Developer Token is required!");
    } else {
      setcdevelopertoken(false);
      setcdevelopertokenMsg("");
    }

    if (customerid?.trim() === "") {
      isValidFields = false;
      setccustomerid(true);
      setccustomeridMsg("Customer Id is required!");
    } else {
      setccustomerid(false);
      setccustomeridMsg("");
    }

    if (bidNumber?.trim() === "") {
      isValidFields = false;
      setcbidsid(true);
      setbidsMsg("Bids is required!");
    } else {
      setcbidsid(false);
      setbidsMsg("");
    }
    return isValidFields;
  }
  return (
    <>
      <div className="row common-mb-26 credential_main">
        <div className="portlet p-0 slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
          <svg   onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 className="portlet-title main-title">
              {" "}
              {localStorage.getItem("organizationName")} - Credentials
            </h3>
          </div>
          <div className="portlet-body">
            <div className="col-md-12 col-sm-12 bids mt-4">
              <div class="data-export mb-3">
                <span class="export mb-2">
                  <label className="col-md-12 ms-0 mb-3 form-label" id="fileLabel">
                    {fileUpload?.name}
                  </label>
                  <input
                    className="form-control w-100 input_file_upload"
                    placeholder="No file chosen"
                    type="file"
                    id="myfile"
                    name="myfile"
                    multiple={false}
                    accept={".json"}
                    onChange={updateFile}
                  />
                </span>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <label className="form-label me-2">OAuth2 Email</label>
              <label class="field-required"> * </label>

              <input
                placeholder="Enter OAuth2 Email"
                type="text"
                value={prnemail}
                onChange={(text) => {
                  setprnemail(text.target.value);
                }}
                name=""
                class="form-control w-100"
              ></input>
              {cprnemail ? (
                <div className="field-required">
                  <span> {cprnemailMsg}</span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div class="col-md-12 mt-4">
              <label className="form-label me-2">OAuth2 Scope</label>
              <label class="field-required"> * </label>

              <input
                placeholder="Enter OAuth2 Scope"
                type="text"
                value={auth2scope}
                onChange={(text) => {
                  setauth2scope(text.target.value);
                }}
                name=""
                class="form-control w-100"
              ></input>
              {cauth2scope ? (
                <div className="field-required">
                  <span> {cauth2scopeMsg}</span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div class="col-md-12 mt-4">
              <label className="form-label me-2">Developer Token</label>
              <label class="field-required"> * </label>
              <input
                placeholder="Developer Token"
                type="text"
                value={developertoken}
                onChange={(text) => {
                  setdevelopertoken(text.target.value);
                }}
                name=""
                class="form-control w-100"
              ></input>
              {cdevelopertoken ? (
                <div className="field-required">
                  <span> {cdevelopertokenMsg}</span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div class="col-md-12 mt-4">
              <label className="form-label me-2">Customer Id</label>
              <label class="field-required"> * </label>
              <input
                placeholder="Customer Id"
                type="text"
                value={customerid}
                onChange={(text) => {
                  setcustomerid(text.target.value);
                }}
                name=""
                class="form-control w-100"
              ></input>
              {ccustomerid ? (
                <div className="field-required">
                  <span> {ccustomeridMsg}</span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div class="col-md-12 mt-3 bids">
              <label className="form-label me-2">Bids</label>
              <label class="field-required"> * </label>
              <input
                placeholder="Bids"
                type="number"
                value={bidNumber}
                onChange={(text) => {
                  setBidsNumber(text.target.value);
                }}
                name=""
                class="form-control w-100"
              ></input>
              {cbids ? (
                <div className="field-required">
                  <span> {cbidsMsg}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="portlet-footer portlet-footer-bordered mt-3">
            <div class="col-md-12 data-export upload-credental ">
              <button
                class="btn btn-primary "
                style={{ width: "auto", height: 40 + "px" }}
                onClick={() => {
                  if (formValidation()) {
                    addCredentialFiles();
                  }
                }}
              >
                Upload Credentials
              </button>
            </div>
          </div>

          <Loading isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Credentials;
