import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function TextSkeleton({
    loading,
    boxWidth = "100%",
    animation = "wave",
    width = "100%",
    height = 30,
    lines = 2,
    customLineWidth = ["100%", "100%"]
}) {
    let numberOfLines = [];
    for (let i = 1; i <= lines; i++) {
        numberOfLines.push(i);
    }
    return (
        <>
            {loading && <Box sx={{ width: boxWidth }}>
                {numberOfLines?.map((data, index) => {
                    return (
                        <Skeleton animation={animation} height={height} width={customLineWidth[index] ?? width} />
                    )
                })}
            </Box>}
        </>
    );
}