import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Drawer, Table } from "antd";
import APIManager from "../../services/APIManager";
import { default as ReactSelect } from "react-select";
import {
  deleteTickets,
  getClientByOrgId,
  getCustomerByUserId,
  getfilterallviewtickets,
  getMemberByOrgId,
  pickupticket,
  prioritystatus,
} from "../../utility/Constant";
import {
  DDMMyyyyFormateDateSet,
  etain1,
  getCheckPermission,
  mmDDyyyyFormateDateSet,
  ticketpriority,
  ticketpriority1,
  tickettypes,
  tickettypes1,
  ticketypecustomer,
} from "../../utility/Globals";
import CreateNewTicket from "./CreateNewTicket";
import NotFound from "../../NotFound";
import { Sorter } from "../../common/Sorter";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Personaltickets() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabSelected, setTabSelected] = useState(1);
  const [assigndTicketCols, setAssigndTicketCol] = useState([]);
  const [assigndTicketList, setAssigndTicketList] = useState([]);
  const [assigndTicketList1, setAssigndTicketList1] = useState([]);
  const [ticketLength, setTicketLength] = useState(0);
  const [closedTicketCols, setClosedTicketCol] = useState([]);
  const [searchvlue, setsearchvlue] = useState();

  const viewonclose = () => {
    !isApply
      ? localStorage.setItem("myteamFilterCount", 0)
      : localStorage.setItem("myteamFilterCount", filterCount);
    let filteredValueVar = JSON.parse(
      localStorage.getItem("myTeamFilterValues")
    );
    setFilterValues({
      createdOn: !isApply ? "" : filteredValueVar?.createdOn,
      closeOn: !isApply ? "" : filteredValueVar?.closeOn,
      priority: !isApply ? "" : filteredValueVar?.priority,
      // ETA: !isApply ? "" : filteredValueVar?.ETA,
      client: !isApply ? "" : filteredValueVar?.client,
      ticketType: !isApply ? "" : filteredValueVar?.ticketType,
      ProjectId:
        !isApply || localStorage.getItem("userRole") !== "Customer"
          ? ""
          : filteredValueVar.ProjectId,
    });
    var array = Object.keys(filteredValueVar);
    let length = 0;
    array.map((e) => {
      if (filteredValueVar[e] != null && filteredValueVar[e] != "") {
        length += 1;
      }
    });
    if (length == 0) {
      clearForm();
      setFilterCount(0);
    }
    if (!isApply) {
      setDisbaleButton(true);
    } else {
      setDisbaleButton(false);
      setFilterCount(length);
    }
    setviewopen(false);
  };

  const [viewopen, setviewopen] = useState(false);
  const [IsModel, setIsModel] = useState(false);
  const [isApply, setIsApply] = useState(false);

  const viewshowDrawer = () => {
    localStorage.setItem("myTeamFilterValues", JSON.stringify(filterValues));
    var array = Object.keys(filterValues);
    array.map((e) => {
      if (e == "createdOn") setselectdatecreated(filterValues[e]);
      if (e == "closeOn") setselectdate(filterValues[e]);
      if (e == "priority") setpriority(filterValues[e]);
      if (e == "client") setclientseleted(filterValues[e]);
      if (e == "ticketType") settickettype(filterValues[e]);
      if (e == "ETA") setEtaInlist(filterValues[e]);
      if (e == "ProjectId") setprojectselected(filterValues[e]);
    });
    setviewopen(true);
  };

  const [createTicketsviewopen, setcreateTicketsviewopen] = useState(false);
  const ticketdrawer = () => {
    setIsModel(true);
    setcreateTicketsviewopen(true);
  };
  const ticketviewclose = () => {
    setIsModel(false);
    setcreateTicketsviewopen(false);
  };

  const [priority, setpriority] = useState("");
  const [selectdate, setselectdate] = useState();
  const [selectdatecreated, setselectdatecreated] = useState();
  const [tickettype, settickettype] = useState("");
  const [EtaInlist, setEtaInlist] = useState("2");
  const [clientlist, setclientlist] = useState();
  const [clientcustomerlist, setclientcustomerlist] = useState([]);
  const [projectcustlist, setprojectcustlist] = useState([]);
  const [memberlist, setmemberlist] = useState([]);
  const [assignmemberselected, setassignmemberselected] = useState();
  const [clientseleted, setclientseleted] = useState("");
  const [projectselected, setprojectselected] = useState();
  const [countdata, setcountdata] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [availablecounts, setavailablecounts] = useState(0);
  const [createcount, setcreatecount] = useState(0);
  const [disbaleButton, setDisbaleButton] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [filterValues, setFilterValues] = useState({
    createdOn: "",
    closeOn: "",
    priority: "",
    ETA: "",
    client: "",
    ticketType: "",
    ProjectId: "",
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: "none",
      height: 43,
      fontSize: "1.1rem",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0 12px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#212121", // Custom colour
    }),
    menuList: (styles) => ({
      ...styles,
      height: "100px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "212121",
      fontWeight: "500",
    }),
  };
  useEffect(() => {
    localStorage.setItem("myteamFilterCount", 0);
    setFilterValues({
      createdOn: "",
      closeOn: "",
      priority: "",
      ETA: "",
      client: "",
      ticketType: "",
      ProjectId: "",
    });
    localStorage.setItem(
      "myTeamFilterValues",
      JSON.stringify({
        createdOn: "",
        closeOn: "",
        priority: "",
        ETA: "",
        client: "",
        ticketType: "",
        ProjectId: "",
      })
    );
    if (localStorage.getItem("ticketStatus") === 2) setTabSelected(2);
    setNotFound(getCheckPermission("tickets", "reports"));
    const columns = [
      {
        title: "Projects",
        dataIndex: "projects",
        key: "projects",
        // width: "10%",
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects",
        // width: "20%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        // width: "10%",
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.eta.props.children, b.eta.props.children),
          multiple: 3,
        },
        // width: "10%",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        // width: "10%",
      },
      {
        title: "Assigned To",
        dataIndex: "assignedto",
        key: "assignedto",
        // width: "10%",
      },
      {
        title: "Subtickets",
        dataIndex: "subtickets",
        key: "subtickets",
        // width: "10%",
      },
      {
        title: "Created On",
        dataIndex: "createdon",
        key: "createdon",
        // width: "10%",
      },
      {
        title: "Created By",
        dataIndex: "createdby",
        key: "createdby",
        // width: "10%",
      },
    ];
    const closecolumns = [
      {
        title: "Projects",
        dataIndex: "projects",
        key: "projects",
        // width: "10%",
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects",
        // width: "20%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        // width: "10%",
      },
      {
        title: "Closed Date",
        dataIndex: "eta",
        key: "eta",
        // width: "10%",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        // width: "10%",
      },
      {
        title: "Assigned To",
        dataIndex: "assignedto",
        key: "assignedto",
        // width: "10%",
      },
      {
        title: "Subtickets",
        dataIndex: "subtickets",
        key: "subtickets",
        // width: "10%",
      },

      {
        title: "Created On",
        dataIndex: "createdon",
        key: "createdon",
        // width: "10%",
      },
      {
        title: "Created By",
        dataIndex: "createdby",
        key: "createdby",
        // width: "10%",
      },
    ];
    setAssigndTicketCol(columns);
    setClosedTicketCol(closecolumns);

    getmembers();
    if (localStorage.getItem("userRole") === "Customer") {
      getClientListcustomer();
    } else {
      getClientList();
    }

    localStorage.setItem("ticketstatus", 1);
    localStorage.setItem("tickettype", 0);

    if (location?.state?.ticketstatus?.ticketstatus != null) {
      localStorage.setItem(
        "ticketstatus",
        location?.state?.ticketstatus?.ticketstatus
      );
      setTabSelected(+location?.state?.ticketstatus?.ticketstatus);
    }
  }, []);

  function removeEmptyValues(values, key) {
    const filterNumber = [];
    const updatedObject = {
      ...filterValues,
      [key]: values,
    };
    setFilterValues(updatedObject);
    for (const key in filterValues) {
      const value = filterValues[key];
      if (!value || value !== "Select") {
        filterNumber.push(value);
      }
      const filteredArray = filterNumber.filter(
        (item) =>
          item !== null &&
          item !== undefined &&
          item !== "" &&
          !(Array.isArray(item) && item.length === 0) &&
          !(typeof item === "object" && Object.keys(item).length === 0)
      );
      setFilterCount(filteredArray.length);
    }
  }

  function handleClick(tickettype) {
    if (isActive != tickettype) {
      setIsActive(tickettype);
      console.log("hit11 handleClick");
      getallticktes(
        {
          ticketstatus: localStorage.getItem("ticketstatus"),
          ViewTicket: tickettype,
          organizationId: localStorage.getItem("organizationId"),
          IsTeam: 0,
          CreatedBy: localStorage.getItem("userId"),
          clientId:
            localStorage.getItem("userRole") !== "Customer"
              ? clientseleted == "Select"
                ? ""
                : clientseleted
              : localStorage.getItem("clientGId"),
          etaIn: "2"
        },
        memberlist
      );
    }
  }
  function Gettickettypename(val) {
    var finalVal = val;
    tickettypes.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function prioritylist(val) {
    var finalVal = val;
    ticketpriority.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function clearForm() {
    setselectdatecreated("");
    setselectdate("");
    settickettype("");
    setclientseleted("");
    setEtaInlist("");
    setassignmemberselected("");
    setselectdatecreated("");
    setpriority("");
    setprojectselected("");
    setFilterValues({
      createdOn: "",
      closeOn: "",
      priority: "",
      ETA: "",
      client: "",
      ticketType: "",
      ProjectId: "",
    });
  }

  function getallticktes(data, memberlistData) {
    let d = data;
    setAssigndTicketList([]);
    setAssigndTicketList1([]);
    setcountdata(0);
    let memberList1 = memberlistData.length ? memberlistData : memberlist;
    let filterValues = {
      createdOn: data.createdOn ? data.createdOn : "",
      closeOn: data.closedOn ? data.closedOn : "",
      priority: data.priority ? data.priority : "",
      ETA: data.etaIn ? data.etaIn : "2",
      client: data.clientId ? data.clientId : "",
      ticketType: data.ticketType ? data.ticketType : "",
      ProjectId: data.ProjectId ? data.ProjectId : "",
    };
    setFilterValues(filterValues);
    localStorage.setItem("myTeamFilterValues", JSON.stringify(filterValues));
    var array = Object.keys(filterValues);
    let length = 0;
    array.map((e) => {
      if (filterValues[e] != null && filterValues[e] != "") {
        length += 1;
      }
    });
    setFilterCount(length);
    localStorage.setItem("myteamFilterCountVar", length);

    APIManager.postAPI(getfilterallviewtickets, d, null, true).then(
      (response) => {
        console.log("hit11 called search");
        if (response && response.status == 200) {
          console.log("hit11 success");
          setcountdata(response.data.table.length);
          setavailablecounts(response.data.table1[0].availableCount);
          setcreatecount(response.data.table2[0].createdCount);
          const list1 = [];
          let a = 0;
          response.data.table.map((item) => {
            let obj1 = {
              countdata: (a += 1),
              projects: <th scope="row">{item.projectCode}</th>,
              subjects: (
                <>
                  <Link
                    to={
                      item.parentTicketId
                        ? "/ticket/subticket-view"
                        : "/ticket/ticket-view"
                    }
                    state={
                      item.parentTicketId
                        ? {
                            item: item,
                            viewtickets: {
                              IsTeam: location?.state?.viewtickets?.IsTeam,
                              ticketnumber: item.ticketNumber,
                            },
                            source: "main",
                          }
                        : { item }
                    }
                  >
                    {item.subject}
                  </Link>
                  <span className="d-block assign-t-span">
                    {item.description}
                  </span>
                </>
              ),
              type: (
                <span
                  class={`type-${
                    +item.ticketTypeId > 0 ? "other" : "universal"
                  }`}
                >
                  {Gettickettypename(item.ticketTypeId)}
                </span>
              ),
              eta: (
                <a
                  class={`eta-text ${
                    DDMMyyyyFormateDateSet(item.eta) &&
                    localStorage.getItem("ticketstatus") == 2
                      ? "overdue-date"
                      : localStorage.getItem("ticketstatus") == 5
                      ? "closed-date"
                      : "normal-date"
                  }`}
                >
                  {DDMMyyyyFormateDateSet(
                    localStorage.getItem("ticketstatus") == "5"
                      ? item.closedOn
                      : item.eta
                  )}
                </a>
              ),
              priority: (
                <select
                  class={`form-select ${prioritylist(
                    item.priorityId
                  ).toLowerCase()}-selected`}
                  aria-label="Default select example"
                  value={item.priorityId}
                  onChange={(i) => {
                    item.priorityId = i.target.value;
                    setpriority(i.target.value);
                    let getdata = {
                      ticketid: item.id,
                      priorityList: i.target.value,
                    };
                    prooritystatuschange(getdata);
                  }}
                >
                  {ticketpriority?.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              ),
              assignedto: (
                <ReactSelect
                  options={memberList1}
                  isMulti={false}
                  styles={customStyles}
                  isDisabled={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={memberList1.map((x) => {
                    if (x.id == item.assignedMemberId) {
                      return x;
                    }
                  })}
                  onChange={(b) => {
                    item.assignedMemberId = b.value;
                    let data = {
                      TicketId: item.id,
                      MemberId: b.value,
                      AssignedOn: mmDDyyyyFormateDateSet(new Date()),
                      Status: 1,
                      CreatedBy: localStorage.getItem("userId"),
                    };
                    AssignTickets(data);
                  }}
                ></ReactSelect>
              ),
              createdon: (
                <span class="created-on">
                  {DDMMyyyyFormateDateSet(item.createdOn)}
                </span>
              ),
              createdby: (
                <div class="d-flex justify-content-between align-items-center">
                  <span>{item.createdMember}</span>
                  <div>
                    <span class="dropdown">
                      {item.createdBy == localStorage.getItem("userId") ||
                      item.assignedMemberId ==
                        localStorage.getItem("userId") ? (
                        <button
                          type="kabeb button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                      ) : (
                        <></>
                      )}
                      <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {item.createdBy == localStorage.getItem("userId") ||
                        item.assignedMemberId ==
                          localStorage.getItem("userId") ? (
                          <div
                            onClick={() => {
                              navigate("/ticket/create-ticket", {
                                state: {
                                  id: item.id,
                                  isUpdate: true,
                                  ClientId: item.clientId,
                                  projectId: item.projectId,
                                  Subject: item.subject,
                                  Description: item.description,
                                  TicketTypeId: item.ticketTypeId,
                                  ETA: item.eta,
                                  PriorityId: item.priorityId,
                                  DepartmentId: item.departmentId,
                                  AssignedTo: item.assignedMemberId,
                                  status: item.status,
                                  createdby: item.createdBy,
                                },
                              });
                            }}
                          >
                            <a class="dropdown-item d-flex align-items-center dot-drop-color edit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit"
                              >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                              </svg>
                              <span className="ms-3">Edit</span>
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                        {item.createdBy == localStorage.getItem("userId") ? (
                          <div
                            onClick={() => {
                              daleteticktes(item.id);
                            }}
                          >
                            <a class="dropdown-item d-flex align-items-center dot-drop-color delete">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                              <span className="ms-3">delete</span>
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              ),
              subtickets: (
                <div class="d-flex justify-content-center align-items-center">
                  <div class="number">
                    <img class="ticket-icon" src="/images/ticket.png" />
                    <span class="fw-bold ms-2">{item.subTicketTotal}</span>
                  </div>
                </div>
              ),
            };
            list1.push(obj1);
            setTimeout(() => {
              document.getElementById("mainTicketDiv").click();
            }, 1000);
          });
          setAssigndTicketList(list1);
          setAssigndTicketList1(list1);
          setTicketLength(list1.length);
        }
      }
    );
  }

  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setclientlist(list);
        setclientseleted(list[0].id);
      }
    });
  }
  function getClientListcustomer() {
    APIManager.getAPI(
      getCustomerByUserId + localStorage.getItem("userId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const prolist = [];
        let obj = {
          id: response.data.client.id,
          name: response.data.client.name,
        };
        list.push(obj);
        setclientcustomerlist(list);
        setclientseleted(list[0].id);
        prolist.push({
          id: "",
          name: "Select",
        });
        response.data.customerProjectMappings.map((item) => {
          let proobj = {
            id: item.projectId,
            name: item.project.projectCode,
          };
          prolist.push(proobj);
        });
        setprojectcustlist(prolist);
      }
    });
  }
  function getmembers() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select",
          key: "",
          value: "",
          label: "Select",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
        setassignmemberselected(list[0].id);
        console.log("hit 11", {
          ticketstatus: localStorage.getItem("ticketstatus"),
          ViewTicket: isActive ?? 0,
          organizationId: localStorage.getItem("organizationId"),
          IsTeam: 0,
          CreatedBy: localStorage.getItem("userId"),
          clientId:
            localStorage.getItem("userRole") !== "Customer"
              ? clientseleted == "Select"
                ? ""
                : clientseleted
              : localStorage.getItem("clientGId"),
        });
        console.log(
          "hit11",
          JSON.stringify({
            ticketstatus: localStorage.getItem("ticketstatus"),
            ViewTicket: isActive ?? 0,
            organizationId: localStorage.getItem("organizationId"),
            IsTeam: 0,
            CreatedBy: localStorage.getItem("userId"),
            clientId:
              localStorage.getItem("userRole") !== "Customer"
                ? clientseleted == "Select"
                  ? ""
                  : clientseleted
                : localStorage.getItem("clientGId"),
          },null,2),
          list
        );
        getallticktes(
          {
            ticketstatus: localStorage.getItem("ticketstatus"),
            ViewTicket: isActive ?? 0,
            organizationId: localStorage.getItem("organizationId"),
            IsTeam: 0,
            CreatedBy: localStorage.getItem("userId"),
            clientId:
              localStorage.getItem("userRole") !== "Customer"
                ? clientseleted == "Select"
                  ? ""
                  : clientseleted
                : localStorage.getItem("clientGId"),
            etaIn: "2"
          },
          list
        );
      }
    });
  }

  function AssignTickets(getdata) {
    APIManager.postAPI(pickupticket, getdata, null, true).then((response) => {
      if (response && response.status == 200) {
        getmembers();
      }
    });
  }

  function prooritystatuschange(getdata) {
    APIManager.putAPI(
      prioritystatus +
        "ticketId=" +
        getdata.ticketid +
        "&memberId=" +
        localStorage.getItem("userId") +
        "&priorityList=" +
        getdata.priorityList,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getmembers();
        console.log("hit11 poority");
        getallticktes(
          {
            ticketstatus: localStorage.getItem("ticketstatus"),
            ViewTicket: localStorage.getItem("tickettype"),
            organizationId: localStorage.getItem("organizationId"),
            IsTeam: 0,
            CreatedBy: localStorage.getItem("userId"),
            clientId:
              localStorage.getItem("userRole") !== "Customer"
                ? clientseleted == "Select"
                  ? ""
                  : clientseleted
                : localStorage.getItem("clientGId"),
            etaIn: "2"
          },
          memberlist
        );
      }
    });
  }

  async function daleteticktes(id) {
    try {
      const response = await APIManager.deleteAPI(deleteTickets + id);
      if (response == 200) {
        getmembers();
      }
    } catch (e) {}
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      let getdata = {
        searchText: searchvlue,
        organizationId: localStorage.getItem("organizationId"),
        IsTeam: 0,
        CreatedBy: localStorage.getItem("userId"),
        ticketStatus: tabSelected,
        clientId:
          localStorage.getItem("userRole") !== "Customer"
            ? clientseleted == "Select"
              ? ""
              : clientseleted
            : localStorage.getItem("clientGId"),
      };
      getallticktes(getdata, memberlist);
      console.log("hit11 handleKeyDown");
      clearForm();
      let filteredValueVar = JSON.parse(
        localStorage.getItem("myTeamFilterValues")
      );
      setFilterValues({
        createdOn: !isApply ? "" : filteredValueVar.createdOn,
        closeOn: !isApply ? "" : filteredValueVar.closeOn,
        priority: !isApply ? "" : filteredValueVar.priority,
        ETA: !isApply ? "" : filteredValueVar.ETA,
        client: !isApply ? "" : filteredValueVar.client,
        ticketType: !isApply ? "" : filteredValueVar.ticketType,
        ProjectId:
          !isApply || localStorage.getItem("userRole") !== "Customer"
            ? ""
            : filteredValueVar.ProjectId,
      });
    }
  }

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-sm-12 p-0" id="mainTicketDiv">
              <div class="top-title d-flex ticket-bredcrumb-top">
                <ul>
                  <li>
                    <h6 class="top-tickets mb-0 text-capitalize ticket-dashboard">
                      tickets
                    </h6>
                  </li>
                  <li className="mx-4">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </li>
                  <li>
                    {" "}
                    <h6 class="ticket-dashboard mb-0 text-capitalize active">
                      view tickets
                    </h6>
                  </li>
                </ul>
              </div>

              <div class="all-ticket-box">
                <div class="d-flex align-items-center justify-content-between custom-block">
                  <div class="px-0 allticket-search">
                    <form class="search-box w-100 d-flex">
                      <button
                        type="button"
                        onClick={() => {
                          let getdata = {
                            searchText: searchvlue,
                            organizationId:
                              localStorage.getItem("organizationId"),
                            IsTeam: 0,
                            CreatedBy: localStorage.getItem("userId"),
                            ticketStatus: tabSelected,
                            clientId:
                              localStorage.getItem("userRole") !== "Customer"
                                ? clientseleted == "Select"
                                  ? ""
                                  : clientseleted
                                : localStorage.getItem("clientGId"),
                          };
                          console.log("hit11 6");
                          getallticktes(getdata, memberlist);
                          if (!isApply) {
                            clearForm();
                            let filteredValueVar = JSON.parse(
                              localStorage.getItem("myTeamFilterValues")
                            );
                            setFilterValues({
                              createdOn: !isApply
                                ? ""
                                : filteredValueVar.createdOn,
                              closeOn: !isApply ? "" : filteredValueVar.closeOn,
                              priority: !isApply
                                ? ""
                                : filteredValueVar.priority,
                              ETA: !isApply ? "" : filteredValueVar.ETA,
                              client: !isApply ? "" : filteredValueVar.client,
                              ticketType: !isApply
                                ? ""
                                : filteredValueVar.ticketType,
                              ProjectId:
                                !isApply ||
                                localStorage.getItem("userRole") !== "Customer"
                                  ? ""
                                  : filteredValueVar.ProjectId,
                            });
                          }
                        }}
                      >
                        <i class="fa fa-search"></i>
                      </button>
                      <input
                        value={searchvlue}
                        type="text"
                        class="w-100"
                        placeholder="Search tickets"
                        name="search"
                        onKeyDown={(e) => {
                          _handleKeyDown(e);
                        }}
                        onChange={(item) => {
                          setsearchvlue(item.target.value);
                          let list = assigndTicketList1.filter((data) =>
                            data?.subjects?.props?.children[0]?.props?.children
                              .toLocaleLowerCase()
                              .includes(item.target.value.toLocaleLowerCase())
                          );
                          setAssigndTicketList(list);
                          setTicketLength(list.length);
                        }}
                      />
                    </form>
                  </div>
                  <div class="px-0 filter-create-btn">
                    <div class="d-flex justify-content-between align-tems-center filter-create">
                      <div class="filter ms-3 w-50">
                        <div
                          class={`btn btn-filter d-flex align-items-center ${
                            filterCount ? "active" : ""
                          }`}
                          // class="btn btn-filter d-flex align-items-center active"
                          onClick={viewshowDrawer}
                        >
                          <img src="/images/vector.png" />
                          <div class="ms-2 ps-1 d-flex align-items-center">
                            <span>filters</span>
                            <span className="ms-2">
                              {" "}
                              ({localStorage.getItem("myteamFilterCountVar")})
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="create-btn ms-3 w-100"
                        onClick={() => {
                          ticketdrawer(true);
                        }}
                      >
                        <div class="btn btn-primary d-block">
                          <div class="d-flex align-items-center">
                            <span class="plus">+ </span>
                            <span class="ms-2 ps-1">create ticket</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="all-ticket-bread-crumb p-0 view-breadcrum">
                {localStorage.getItem("userRole") !== "Customer" ? (
                  <>
                    <ul class="d-flex px-0 align-items-center flex-wrap">
                      <li
                        class={` ${isActive == 0 ? "active" : ""}`}
                        onClick={() => {
                          handleClick(0);
                          localStorage.setItem("tickettype", 0);
                          setsearchvlue("");
                        }}
                      >
                        <span className="personal-tabview">
                          {isActive == 0 ? (
                            <i class="fa fa-check me-2" aria-hidden="true"></i>
                          ) : (
                            <></>
                          )}
                          all tickets
                        </span>
                      </li>
                      <li
                        class={`mx-3 ${isActive == 1 ? "active" : ""}`}
                        onClick={() => {
                          handleClick(1);
                          setsearchvlue("");
                          localStorage.setItem("tickettype", 1);
                        }}
                      >
                        <span className="personal-tabview">
                          {isActive == 1 ? (
                            <i class="fa fa-check me-2" aria-hidden="true"></i>
                          ) : (
                            <></>
                          )}
                          client tickets
                        </span>
                      </li>
                      <li
                        class={` ${isActive == 2 ? "active" : ""}`}
                        onClick={() => {
                          handleClick(2);
                          localStorage.setItem("tickettype", 2);
                          setsearchvlue("");
                        }}
                      >
                        <span className="personal-tabview">
                          {isActive == 2 ? (
                            <i class="fa fa-check me-2" aria-hidden="true"></i>
                          ) : (
                            <></>
                          )}
                          internal tickets
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div class="all-ticket-table-box mt-3 minus-margin">
                <div class="title mb-4">
                  <h4 class="fw-bold text-capitalize d-flex mt-5">
                    <span>
                      {isActive == 0 ? (
                        <>
                          {" "}
                          {tabSelected == 1 ? (
                            <>
                              <span>Assigned tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 2 ? (
                            <>
                              <span>Overdue Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 4 ? (
                            <>
                              <span>Awaiting Approval Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 5 ? (
                            <>
                              <span>Closed Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 0 ? (
                            <>
                              <span>Available Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 6 ? (
                            <>
                              <span>Created By Me </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span class="ticket-count"> ({ticketLength})</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span>
                      {isActive == 1 ? (
                        <>
                          {" "}
                          {tabSelected == 1 ? (
                            <>
                              <span>Assigned tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 2 ? (
                            <>
                              <span>Overdue Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 4 ? (
                            <>
                              <span>Awaiting Approval Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 5 ? (
                            <>
                              <span>Closed Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 0 ? (
                            <>
                              <span>Available Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 6 ? (
                            <>
                              <span>Created By Me </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span class="ticket-count"> ({ticketLength})</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                    <span>
                      {isActive == 2 ? (
                        <>
                          {" "}
                          {tabSelected == 1 ? (
                            <>
                              <span>Assigned tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 2 ? (
                            <>
                              <span>Overdue Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 4 ? (
                            <>
                              <span>Awaiting Approval Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 5 ? (
                            <>
                              <span>Closed Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 0 ? (
                            <>
                              <span>Available Tickets</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {tabSelected == 6 ? (
                            <>
                              <span>Created By Me </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <span class="ticket-count"> ({ticketLength})</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h4>
                  <span>
                    {tabSelected == 0 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Available Tickets are listed below
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabSelected == 1 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Assigned Tickets are listed below
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabSelected == 2 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Overdue Tickets are listed below
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabSelected == 4 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Awaiting Approval are listed below
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabSelected == 5 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Closed Tickets are listed below
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {tabSelected == 6 ? (
                      <>
                        {" "}
                        <span class="available-ticket text-capitalize mt-2">
                          All Tickets Created By You Are Listed Here
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>

                <Tabs>
                  <div className="portlet py-3 mb-0">
                    <div className="ticketlist col-md-12 col-sm-12  ">
                      <TabList className="nav nav-lines portlet-nav">
                        <Tab
                          className={
                            tabSelected == "1"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(1);
                            if (tabSelected != 1) {
                              localStorage.setItem("ticketstatus", 1);
                              console.log("hit11 7");
                              getallticktes(
                                {
                                  ticketstatus: 1,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Assigned Tickets
                        </Tab>
                        <Tab
                          className={
                            tabSelected == "2"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(2);
                            if (tabSelected != 2) {
                              localStorage.setItem("ticketstatus", 2);
                              console.log("hit11 8");
                              getallticktes(
                                {
                                  ticketstatus: 2,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Overdue Tickets
                        </Tab>
                        <Tab
                          className={
                            tabSelected == "4"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(4);
                            if (tabSelected != 4) {
                              localStorage.setItem("ticketstatus", 4);
                              console.log("hit11 9");
                              getallticktes(
                                {
                                  ticketstatus: 4,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Awaiting Approval
                        </Tab>
                        <Tab
                          className={
                            tabSelected == "5"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(5);
                            localStorage.setItem("ticketstatus", 5);
                            if (tabSelected != 5) {
                              console.log("hit11 10");
                              getallticktes(
                                {
                                  ticketstatus: 5,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Closed Tickets
                        </Tab>
                        <Tab
                          className={
                            tabSelected == "0"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(0);
                            localStorage.setItem("ticketstatus", 0);
                            if (tabSelected != 0) {
                              console.log("hit11 11");
                              getallticktes(
                                {
                                  ticketstatus: 0,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Available Tickets
                          <span className="available-tnumber">
                            {availablecounts}
                          </span>
                        </Tab>
                        <Tab
                          className={
                            tabSelected == "6"
                              ? "nav-item nav-link  active"
                              : "nav-item nav-link"
                          }
                          onClick={() => {
                            setsearchvlue("");
                            setTabSelected(6);
                            localStorage.setItem("ticketstatus", 6);
                            if (tabSelected != 6) {
                              console.log("hit11 12");
                              getallticktes(
                                {
                                  ticketstatus: 6,
                                  ViewTicket:
                                    localStorage.getItem("tickettype"),
                                  organizationId:
                                    localStorage.getItem("organizationId"),
                                  IsTeam: 0,
                                  CreatedBy: localStorage.getItem("userId"),
                                  createdOn: selectdatecreated,
                                  closedOn: selectdate,
                                  priority: priority,
                                  assignedTo:
                                    assignmemberselected == "Select"
                                      ? ""
                                      : assignmemberselected,
                                  etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                                  // clientId:
                                  //   clientseleted == "Select"
                                  //     ? ""
                                  //     : clientseleted,
                                  clientId:
                                    localStorage.getItem("userRole") !==
                                    "Customer"
                                      ? clientseleted == "Select"
                                        ? ""
                                        : clientseleted
                                      : localStorage.getItem("clientGId"),
                                  ticketType:
                                    tickettype == "Select" ? "" : tickettype,
                                  ProjectId:
                                    projectselected == "Select" ||
                                    localStorage.getItem("userRole") !==
                                      "Customer"
                                      ? ""
                                      : projectselected,
                                },
                                memberlist
                              );
                            }
                          }}
                        >
                          Created by Me
                          <span className="available-tnumber">
                            {createcount}
                          </span>
                        </Tab>
                      </TabList>
                    </div>
                  </div>

                  <TabPanel>
                    <div className="row ">
                      <div className="col-md-12 text-start align-items-center px-0">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={assigndTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-md-12 text-start px-0 ">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={assigndTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <div className="col-md-12 text-start align-items-center px-0">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={assigndTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <div className="col-md-12 text-start align-items-center px-0">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={closedTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <div className="col-md-12 text-start align-items-center px-0">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={assigndTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <div className="col-md-12 text-start align-items-center px-0">
                        <label
                          style={{
                            fontSize: 14 + "px",
                            fontWeight: 500,
                          }}
                        ></label>
                        <Table
                          className="portlet table table-hover ticket-table view-all-tickets"
                          columns={assigndTicketCols}
                          dataSource={assigndTicketList}
                          rowClassName={(record) => {
                            return record?.subjects?.props?.children[0]?.props
                              ?.to?.state?.item?.isOpen === 0 &&
                              (tabSelected == 1 || tabSelected == 0)
                              ? "highlighted-row"
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="portlet">
            <Drawer
              title={
                <>
                  <div class="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#212121"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-filter"
                    >
                      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                    </svg>
                    <span className="ms-2">Filter ({filterCount})</span>
                  </div>
                </>
              }
              className="search-team-member-modal filter-closed filter-modal"
              placement="right"
              onClose={viewonclose}
              open={viewopen}
            >
              <div class="container">
                <div class="portlet-body pt-0 p-0">
                  <form class="ticket-filter-form">
                    <div class="col-md-12 mt-3 ">
                      <label for="Target" class="form-label mb-2">
                        created on
                      </label>
                      <input
                        // class="w-100 date-input"
                        class={`${
                          selectdatecreated
                            ? "w-100 date-input form-select yes-selected"
                            : "w-100 date-input"
                        }`}
                        type="date"
                        id="date"
                        name="date"
                        value={selectdatecreated}
                        onChange={(item) => {
                          filterValues["createdOn"] = item.target.value;
                          removeEmptyValues(item.target.value, "createdOn");
                          setselectdatecreated(item.target.value);
                          setDisbaleButton(false);
                        }}
                      />
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="form-group">
                        <label for="Target" class="form-label mb-2">
                          closed on
                        </label>
                        <input
                          // class="w-100 date-input"
                          class={`${
                            selectdate
                              ? "w-100 date-input form-select yes-selected"
                              : "w-100 date-input"
                          }`}
                          type="date"
                          id="date"
                          name="date"
                          value={selectdate}
                          onChange={(item) => {
                            filterValues["closeOn"] = item.target.value;
                            removeEmptyValues(item.target.value, "closeOn");
                            setselectdate(item.target.value);
                            setDisbaleButton(false);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">
                        priority
                      </label>
                      <select
                        // class="w-100 form-select yes-selected"
                        class={`${
                          priority
                            ? "w-100 form-select yes-selected"
                            : "w-100 form-select"
                        }`}
                        aria-label="Default select example"
                        value={priority}
                        onChange={(item) => {
                          filterValues["priority"] = item.target.value;
                          removeEmptyValues(item.target.value, "priority");
                          if (item.target.value == "Select") {
                            setpriority("");
                          } else {
                            setpriority(item.target.value);
                          }
                          setDisbaleButton(false);
                        }}
                      >
                        {ticketpriority1?.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">
                        ETA
                      </label>
                      <select
                        // class="w-100 form-select"
                        class={`${
                          EtaInlist
                            ? "w-100 form-select yes-selected"
                            : "w-100 form-select"
                        }`}
                        aria-label="Default select example"
                        value={EtaInlist}
                        onChange={(item) => {
                          filterValues["ETA"] = item.target.value;
                          removeEmptyValues(item.target.value, "ETA");
                          setEtaInlist(item.target.value);
                          setDisbaleButton(false);
                        }}
                      >
                        {etain1?.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">
                        client
                      </label>
                      <select
                        // class="w-100 form-select"
                        class={`${
                          clientseleted
                            ? "w-100 form-select yes-selected"
                            : "w-100 form-select"
                        }`}
                        aria-label="Default select example"
                        value={clientseleted}
                        disabled={
                          clientcustomerlist.length === 1 ? true : false
                        }
                        onChange={(item) => {
                          filterValues["client"] = item.target.value;
                          removeEmptyValues(item.target.value, "client");
                          if (item.target.value == "Select") {
                            setclientseleted("");
                          } else {
                            setclientseleted(item.target.value);
                          }
                          setDisbaleButton(false);
                        }}
                      >
                        {localStorage.getItem("userRole") === "Customer" ? (
                          <>
                            {clientcustomerlist?.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {clientlist?.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                          </>
                        )}
                      </select>
                    </div>
                    {localStorage.getItem("userRole") === "Customer" ? (
                      <>
                        <div class="col-md-12 mt-3">
                          <label for="exampleInputcode1" class="form-label">
                            Project
                          </label>
                          <select
                            // class="w-100 form-select"
                            class={`${
                              projectselected
                                ? "w-100 form-select yes-selected"
                                : "w-100 form-select"
                            }`}
                            aria-label="Default select example"
                            value={projectselected}
                            disabled={
                              projectcustlist.length === 1 ? true : false
                            }
                            onChange={(item) => {
                              filterValues["ProjectId"] = item.target.value;
                              removeEmptyValues(item.target.value, "ProjectId");
                              if (item.target.value == "Select") {
                                setprojectselected("");
                              } else {
                                setprojectselected(item.target.value);
                              }
                              setDisbaleButton(false);
                            }}
                          >
                            {projectcustlist?.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">
                        ticket type
                      </label>
                      <select
                        // class="w-100 form-select mb-2"
                        class={`${
                          tickettype
                            ? "w-100 form-select yes-selected"
                            : "w-100 form-select"
                        }`}
                        aria-label="Default select example"
                        value={tickettype}
                        onChange={(e) => {
                          filterValues["ticketType"] = e.target.value;
                          removeEmptyValues(e.target.value, "ticketType");
                          if (e.target.value == "Select") {
                            settickettype("");
                          } else {
                            settickettype(e.target.value);
                          }
                          setDisbaleButton(false);
                        }}
                      >
                        {localStorage.getItem("userRole") === "Customer" ? (
                          <>
                            {ticketypecustomer.map((i) => {
                              return <option value={i.value}>{i.label}</option>;
                            })}
                          </>
                        ) : (
                          <>
                            {tickettypes1.map((i) => {
                              return <option value={i.value}>{i.label}</option>;
                            })}
                          </>
                        )}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3 text-end remove-all mb-5 pb-5">
                      <a
                        // href="#"
                        className="text-capitalize text-primary fw-bold"
                        onClick={() => {
                          clearForm();
                          if (!isApply) {
                            setFilterCount(0);
                            localStorage.setItem("myteamFilterCountVar", 0);
                          }
                        }}
                      >
                        Remove all
                      </a>
                    </div>
                  </form>
                </div>
                <div class="portlet-footer portlet-footer-bordered mt-3">
                  <div class="col-md-12 p-0" onClick={() => {}}>
                    <div class=" d-flex align-items-center justify-content-center">
                      <button
                        onClick={() => {
                          let getdata = {
                            createdOn: selectdatecreated,
                            closedOn: selectdate,
                            priority: priority,
                            assignedTo:
                              assignmemberselected == "Select"
                                ? ""
                                : assignmemberselected,
                            etaIn: EtaInlist == "Select" ? "2" : EtaInlist,
                            // clientId:
                            //   clientseleted == "Select" ? "" : clientseleted,
                            clientId:
                              localStorage.getItem("userRole") !== "Customer"
                                ? clientseleted == "Select"
                                  ? ""
                                  : clientseleted
                                : localStorage.getItem("clientGId"),
                            ticketType:
                              tickettype == "Select" ? "" : tickettype,
                            ProjectId:
                              projectselected == "Select" ||
                              localStorage.getItem("userRole") !== "Customer"
                                ? ""
                                : projectselected,
                            organizationId:
                              localStorage.getItem("organizationId"),
                            IsTeam: 0,
                            CreatedBy: localStorage.getItem("userId"),
                            ticketStatus: tabSelected,
                          };
                          localStorage.setItem(
                            "myteamFilterCount",
                            filterCount
                          );
                          console.log("hit11 13");
                          getallticktes(getdata, memberlist);
                          setIsApply(true);
                          setviewopen(false);
                        }}
                        className="btn btn-primary w-100"
                        disabled={disbaleButton ? true : false}
                      >
                        <i class="fa fa-check me-3" aria-hidden="true"></i>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
          <Drawer
            className="search-team-member-modal filter-closed create-ticket-modal"
            placement="right"
            onClose={ticketviewclose}
            open={createTicketsviewopen}
          >
            <CreateNewTicket
              isModel={true}
              resetForm={IsModel}
              closeModel={() => {
                ticketviewclose();
              }}
            />
          </Drawer>
        </>
      )}
    </>
  );
}

export default Personaltickets;
