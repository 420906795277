import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Table, Input } from "antd";
import {
  deleteProjects,
  getProjectByOrgId,
} from "../utility/Constant";
import {
  exportToEXCEL,
  getCheckPermission,
  mmDDyyyyFormateDate,
  findUnique,
  mmDDyyyyFormateForForm23,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import { Sorter } from "../common/Sorter";
import ProjectsListSalesDirAddUpdate from "./ProjectsListSalesDirAddUpdate";
import EditProjectDir from "./EditProjectDir";
import { Link, useNavigate } from "react-router-dom";

function ProjectsListSalesDir({ update }) {
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamlistProj, setteamListProj] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const navigate = useNavigate();
  const [settings, setSetting] = useState("projectList");

  useEffect(() => {
    getProjetList();
    setStatusList([
      { text: "Active", value: 0, isActive: false },
      { text: "Lead", value: 1, isActive: false },
      { text: "Prospect", value: 2, isActive: false },
      { text: "Completed", value: 3, isActive: false },
    ])
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item, index) => {
        let obj = {
          projname: item.projcode.props.children,
          client: item.client,
          projpoc: item.projpoc,
          contractstart: item.contractstart,
          contractend: item.contractend,
          status: item.status,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  const [statusList, setStatusList] = useState([
    { text: "Active", value: 0, isActive: false },
    { text: "Lead", value: 1, isActive: false },
    { text: "Prospect", value: 2, isActive: false },
    { text: "Completed", value: 3, isActive: false },
  ]);

  function getStatus(statusdId) {
    let status = "";
    switch (statusdId) {
      case 0:
        status = "Active";
        break;
      case 1:
        status = "Lead";
        break;
      case 2:
        status = "Prospect";
        break;
      case 3:
        status = "Completed";
        break;
      default:
        break;
    }
    return status;
  }

  function getProjetList() {
    APIManager.getAPI(
      getProjectByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportlist = [];
        const filterclient = [];
        const filterstatus = [];
        response.data.forEach((item) => {
          let s;
          if (item.status === 0) {
            s = "Active";
          } else if (item.status === 1) {
            s = "Lead";
          } else if (item.status === 2) {
            s = "Prospect";
          } else {
            s = "Active";
          }
          let obj = {
            key: item.id,
            projcode: getCheckPermission("edit", "projects_setup") ? (
              <Link
                onClick={() => {
                  setSetting("editProject");
                  localStorage.setItem("projectGId", item.id);
                }}
              >
                {item.projectCode}
              </Link>
            ) : (
              <div>{item.projectCode}</div>
            ),
            projcodeId: item?.domainName,
            client: item?.clients?.name,
            projpoc: item?.poc,
            contractstart: mmDDyyyyFormateDate(item?.startDate),
            contractend: mmDDyyyyFormateDate(item?.endDate),
            status: getStatus(item.status),
            edit: (
              <div>
                {s === "Active" || s === "Completed" ? (
                  <div>
                    {getCheckPermission("edit", "projects_setup") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-edit edit-icon"
                        onClick={() => {
                          setSetting("projectAddUpdate");
                          navigate("",{
                            state: {
                              isUpdateProjectData: true,
                              set_SelectedItem: item,
                              set_SelectedProjectId: item.id,
                              set_ProjectCode: item.projectCode,
                              set_CustomerName: item.clients.id,
                              set_StartDate: mmDDyyyyFormateForForm23(
                                item.startDate
                              ),
                              set_EndDate: mmDDyyyyFormateForForm23(
                                item.endDate
                              ),
                              set_DomainName: item.domainName,
                              set_Cost: item.cost,
                              set_Location: item.location,
                              set_City: item?.city?.id,
                              set_Country: item?.countryId,
                              set_Poc: item.poc,
                              set_CreditForRankings: item.creditForRankings
                                ? item.creditForRankings
                                : 0,
                              set_Contact: item.contact,
                              set_StatusySelected: item.status,
                              set_DocumentURL: item?.document?.fileURL,
                              set_Industry: item?.clients?.industries?.industriesName
                            },
                          });
                        }}
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    ) : (
                      <></>
                    )}
                    {getCheckPermission("delete", "projects_setup") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2 trash-icon mx-3"
                        onClick={() => {
                          deleteClientItem(item.id);
                        }}
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    ) : (
                      <></>
                    )}
                    {getCheckPermission("view_only", "projects_setup") ? (
                      <Link
                        to={`/main/dashboard`}
                      >
                        View Report
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : getCheckPermission("edit", "projects_setup") ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon me-3"
                      onClick={() => {
                        setSetting("projectAddUpdate");
                        navigate("",{
                          state: {
                            isUpdateProjectData: true,
                            set_SelectedProjectId: item.id,
                            set_ProjectCode: item.projectCode,
                            set_CustomerName: item.clients.id,
                            set_StartDate: mmDDyyyyFormateForForm23(
                              item.startDate
                            ),
                            set_EndDate: mmDDyyyyFormateForForm23(item.endDate),
                            set_DomainName: item.domainName,
                            set_Cost: item.cost,
                            set_Location: item.location,
                            set_City: item?.city?.id,
                            set_Country: item?.countryId,
                            set_Poc: item.poc,
                            set_CreditForRankings: item.creditForRankings,
                            set_Contact: item.contact,
                            set_StatusySelected: item.status,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon"
                      onClick={() => {
                        deleteClientItem(item.id);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ),
          };
          list.push(obj);
          let exportObj = {
            projname: item.projectCode,
            client: item?.clients?.name,
            projpoc: item.poc,
            contractstart: item.startDate,
            contractend: item.endDate,
            status: getStatus(item.status),
          };

          let filterclientobj = {
            text: item?.clients?.name,
            value: item?.clients?.name,
          };
          filterclient.push(filterclientobj);
          filterstatus.push(item.status);
          exportlist.push(exportObj);
        });
        let filterStatusList = [];
        statusList.forEach((status) => {
          if (filterstatus.includes(status.value)) {
            filterStatusList.push({
              value: status.text,
              text: status.text,
            });
          }
        });
        setteamListProj(list);
        setteamList(list);
        setExportList(exportlist);

        const columns = [
          {
            title: "Project Name",
            dataIndex: "projcode",
            key: "projcode",
            sorter: {
              compare: (a, b) =>
                Sorter.DEFAULT(
                  a.projcode.props.children,
                  b.projcode.props.children
                ),
              multiple: 3,
            },
          },
          {
            title: "Client",
            dataIndex: "client",
            key: "client",
            filters: findUnique(filterclient, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.client === value,
          },
          {
            title: "POC",
            dataIndex: "projpoc",
            key: "projpoc",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.projpoc, b.projpoc),
              multiple: 3,
            },
          },
          {
            title: "Start Date",
            dataIndex: "contractstart",
            key: "contractstart",
          },
          {
            title: "Estimated Closure Date",
            dataIndex: "contractend",
            key: "contractend",
          },
          {
            title: "Status",
            dataIndex: "status",
            filters: findUnique(filterStatusList, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
          },
          {
            title: "Actions",
            dataIndex: "edit",
            key: "edit",
          },
        ];
        setteamcol(columns);
      }
    });
  }

  function deleteClientItem(id) {
    APIManager.deleteAPI(deleteProjects + id).then((response) => {
      getProjetList();
    });
  }

  function filterDataFromList(textData) {
    setteamList(
      teamlistProj.filter((person) =>
        person.projcode.props.children
          .toLocaleLowerCase()
          .includes(textData.toLocaleLowerCase())
      )
    );
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getProjetList();
  };

  function getComponent() {
    if (settings === "projectAddUpdate") {
      return <ProjectsListSalesDirAddUpdate update={onClickFunction} />;
    } else if (settings === "editProject") {
      return <EditProjectDir update={onClickFunction} />;
    }
  }

  return (
    <>
      {settings === "projectList" || settings === "" ? (
        <div className="portlet slideInUp">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => {
              update("");
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {" "}
              {localStorage.getItem("organizationName")} -Projects
            </h3>
          </div>

          <div class="common-table">
            <div class="row">
              <div class="col-md-4 text-left d-flex justify-content-start ">
                <div class="search-box text-right ">
                  <div class="input-group"></div>
                  <div class="input-group-icon input-group-lg widget15-compact">
                    <div class="input-group-prepend">
                      <i class="fa fa-search text-primary"></i>
                    </div>
                    {getCheckPermission("view_only", "projects_setup") ? (
                      <Input
                        allowClear
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        style={{ height: 36 + "px" }}
                        onChange={(nameSearch) => {
                          filterDataFromList(nameSearch.target.value);
                        }}
                        id="input-s"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-8 text-end">
                <span class="count-drop" style={{ width: 100 + "px" }}></span>
                <span class="export">
                  {getCheckPermission("add", "projects_setup") ? (
                    <button
                      class="ms-5 btn btn-primary add"
                      onClick={() => {
                        setSetting("projectAddUpdate");
                        navigate("",{
                          state: {
                            isUpdate: false,
                          },
                        });
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <></>
                  )}

                  <span class="dropdown d-inline ">
                    {getCheckPermission("view_only", "projects_setup") ? (
                      <button
                        class="btn btn-primary dropdown-toggle export-btn ms-3"
                        style={{
                          width: 70 + "px",
                          height: 30 + "px",
                        }}
                        type="button"
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Projects"
                          );
                        }}
                      >
                        Export
                      </button>
                    ) : (
                      <></>
                    )}                   
                  </span>
                </span>
              </div>
            </div>
            {getCheckPermission("view_only", "projects_setup") ? (
              <Table
                className="table table-bordered table-hover ps-0 pe-0 mt-3 project-table custome_filter"
                id="datatable-3"
                columns={teamcol}
                dataSource={teamlist}
                rowSelection={{ type: "checkbox", ...rowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        getComponent()
      )}
    </>
  );
}

export default ProjectsListSalesDir;
