import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const buttonStyles = (custom) => css({
    ...textStyles.s2Medium,
    ...custom,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    "svg": {
        width: "1.5rem !important",
        height: "1.5rem !important",
        marginRight: "3px"
    }
})