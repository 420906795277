/** @jsxImportSource @emotion/react */
import React from 'react'
import * as styles from "./styles";
import CustomButton from '../button';

const ErrorScreen = ({title, subtitle, onRetry, icon, buttonIcon, buttonText, addStyles}) => {
  return (
    <div css={[styles.main, addStyles && addStyles]}>
        <div css={styles.errorContainer}>
        <img
                src={icon}
                css={styles.image}
              />
          {title && <div css={styles.message}>{title}</div>}
          {subtitle && <div css={styles.subtitle} >{subtitle}</div>}
          {buttonText && <CustomButton
            text={buttonText}
            use={"primary"}
            leftIcon={buttonIcon}
            theme={"info"}
            onPress={onRetry}
            addStyles={styles.button}
          />}
        </div>
      </div>
  )
}

export default ErrorScreen
