import React, { useEffect, useRef, useState } from "react";
import APIManager from "../services/APIManager";
import {
  getconfigcheckdashboard,
  getSeoPerformanceWidget,
} from "../utility/Constant";
import {
  calanderDateCompareforDashboard,
  calanderDateforDashboard,
  convertDate,
  returnMagnitude,
  mmDDyyyyFormateDateSet,
  formatRankDate,
  getMonthName,
  getCheckPermission,
} from "../utility/Globals";

import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import Gacchart from "./common/Gacchart";
import Pagespeedchart from "./common/Pagespeedchart";
import DashboardTicketTest from "./common/DashboardTicketTest";
import SEOHealth from "./common/SEOHealth";
import DomainAuthority from "./common/DomainAuthority";
import PageAuthority from "./common/PageAuthority";
import AuditScore from "./common/AuditScore";
import SiteUpTime from "./common/SiteUpTime";
import Gachart from "./common/Gachart";
import Roidashboard from "./common/Roidashboard";
import Backlink from "./common/Backlink";
import Clicksharechart from "./common/Clicksharechart";
import ImplementationChart from "./common/ImplementationChart";
import RankingChartV2 from "./common/RankingChartV2";
import { Link } from "react-router-dom";

function Dashboard2() {
  const [isCompare, setIsCompare] = useState(true);

  const [traffic, setTraffic] = useState();
  const [compareTraffic, setCompareTraffic] = useState();
  const [trafficDifference, setTrafficDifference] = useState(0);
  const [ranking, setRanking] = useState();
  const [compareRanking, setCompareRanking] = useState();
  const [rankingDifference, setRankingDifference] = useState();
  const [isImplementChart, setIsImplementChart] = useState(false);
  const [seodatashow, setseodatashow] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);
  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -16),
      endDate: addDays(new Date(), -10),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection2",
    },
  });

  useEffect(() => {
    getconfig();
    let rowdata = {
      ProjectId: localStorage.getItem("projectGId"),
      DashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(state?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(state?.selection1?.endDate),
      },
      CompareDashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(state?.selection2?.startDate),
        TO: mmDDyyyyFormateDateSet(state?.selection2?.endDate),
      },
    };
    getSeoWidgets(rowdata);
  }, []);

  useEffect(() => {
    handleClickChart();
  }, []);

  const [showComponents, setShowComponents] = useState(true);

  function getSeoWidgets(rowdata) {
    APIManager.postAPI(getSeoPerformanceWidget, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          response.data.table.map((item) => {
            setTraffic(item.session);
            setCompareTraffic(item.sessionCompare);
            let trafficDiff = item.sessionCompare - item.session;
            setTrafficDifference(trafficDiff);
          });
          response.data.table1.map((item) => {
            setRanking(item.rank);
            setCompareRanking(item.rankCompare);
            let rankingDiff = item.rankCompare - item.rank;
            setRankingDifference(rankingDiff);
          });
        }
      }
    );
  }

  function getColorOfData(value) {
    if (value > 0) {
      return "digit-green";
    } else if (value < 0) {
      return "digit-red";
    } else {
      return "";
    }
  }
  const [parentToChild, setParentToChild] = useState({
    selection1: {
      startDate: addDays(new Date(), -16),
      endDate: addDays(new Date(), -10),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection2",
    },
  });

  const [i, setI] = useState(0);
  const [isChart, setIsChart] = useState(0);
  const [isAuditChart, setIsAuditChart] = useState(0);
  const [isChartAll, setChartAll] = useState(0);
  function handleClickChart() {
    setI(i + 1);
    setIsChart(i);
    setChartAll(i);
  }
  function getclassName() {
    if (isImplementChart) {
      return "col-md-12 col-sm-12 auditscore-full mb-4";
    } else {
      return "col-md-4 col-sm-12 auditscore-half";
    }
  }
  function getconfig() {
    APIManager.getAPI(
      getconfigcheckdashboard +
      "?projectId=" +
      localStorage.getItem("projectGId") +
      "&userId=" +
      localStorage.getItem("userId")
    ).then((response) => {
      if (response && response.status == 200) {
        setseodatashow(response.data);
      }
    });
  }
  return (
    <>
      <div class="row new-dashboard dashboard_top_header calender_responsive_design">
        <div class="header-holder header-holder-desktop portlet d-flex px-0 mb-0">
          <div class="header-container dashboard_header_content container-fluid flex-wrap w-100 d-flex justify-content-between">
            <h4 class="header-title">Dashboard</h4>
            <div className="side-cal d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-calendar"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              <button
                className="btn btn-primary"
                onClick={() => setOpen(!open)}
              >
                {!isCompare
                  ? calanderDateforDashboard(
                    state.selection1?.startDate,
                    state.selection1?.endDate
                  )
                  : calanderDateCompareforDashboard(
                    state.selection1?.startDate,
                    state.selection1?.endDate,
                    state.selection2?.startDate,
                    state.selection2?.endDate
                  )}
              </button>
            </div>
          </div>
          <div></div>
        </div>
        <div class="d-flex" ref={ref}>
          {open && (
            <div id="example-collapse-text-calendar">
              <Card body className="daterange-picker-card mt-2">
                <div className="d-flex calendar-flex">
                  <div className="calendar-col">
                    <DateRangePicker
                      onChange={(item) => {
                        let isCompareVarNew = "true";
                        if (isCompareVarNew == "false") {
                          let selection23 = {};
                          if (item?.selection1) {
                            selection23 = {
                              selection2: {
                                startDate: item?.selection1?.startDate,
                                endDate: item?.selection1?.endDate,
                                key: "selection2",
                              },
                            };
                          } else if (item?.selection2) {
                            selection23 = {
                              selection1: {
                                startDate: item?.selection2?.startDate,
                                endDate: item?.selection2?.endDate,
                                key: "selection1",
                              },
                            };
                          }
                          setState({
                            ...state,
                            ...item,
                            ...selection23,
                          });
                        }

                        if (isCompareVarNew == "true") {
                          setState({ ...state, ...item });
                        }
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={[state?.selection1, state?.selection2]}
                      direction="horizontal"
                      ariaLabels={{
                        dateInput: {
                          selection1: {
                            startDate: "start date input of selction 1",
                            endDate: "end date input of selction 1",
                          },
                          selection2: {
                            startDate: "start date input of selction 2",
                            endDate: "end date input of selction 2",
                          },
                        },

                        monthPicker: "month picker",
                        yearPicker: "year picker",
                        prevButton: "previous month button",
                        nextButton: "next month button",
                      }}
                      maxDate={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDate() - 3
                        )
                      }
                    />
                  </div>
                  <div className="mt-3 mt-lg-0 text-center">
                    <label className="mt-10 d-flex ms-3">
                      <input
                        className="me-3"
                        checked={isCompare}
                        onChange={(e) => {
                          localStorage.setItem(
                            "isCompareVar",
                            e.target.checked
                          );
                          setIsCompare(e.target.checked);
                          if (!e.target.checked) {
                            document.getElementsByClassName(
                              "rdrDateDisplayWrapper"
                            )[0].children[1].style.display = "none";
                            setState({
                              selection1: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection1",
                              },
                              selection2: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection2",
                              },
                            });
                          } else {
                            document.getElementsByClassName(
                              "rdrDateDisplayWrapper"
                            )[0].children[1].style.display = "flex";
                            setState({
                              selection1: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection1",
                              },
                              selection2: {
                                startDate: addDays(new Date(), -9),
                                endDate: addDays(new Date(), -3),
                                key: "selection2",
                              },
                            });
                          }
                        }}
                        type="checkbox"
                        hidden
                      />
                    </label>
                    <button
                      onClick={() => {
                        setParentToChild(state);
                        setOpen(!open);

                        let rowdata = {
                          ProjectId: localStorage.getItem("projectGId"),
                          DashboardDateRange: {
                            FROM: mmDDyyyyFormateDateSet(
                              state?.selection1?.startDate
                            ),
                            TO: mmDDyyyyFormateDateSet(
                              state?.selection1?.endDate
                            ),
                          },
                          CompareDashboardDateRange: {
                            FROM: mmDDyyyyFormateDateSet(
                              state?.selection2?.startDate
                            ),
                            TO: mmDDyyyyFormateDateSet(
                              state?.selection2?.endDate
                            ),
                          },
                        };
                        getSeoWidgets(rowdata);
                      }}
                      className="btn btn-primary ms-3"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>

      <div className="row dash-box-tab seo_health_second_doughnut mt-4">
        <div
          className="col-md-4 col-sm-12 px-0 seo-performance-dashboard"
          onClick={() => {
            setShowComponents(true);
          }}
        >
          <div
            className={showComponents ? "main-box main-box-active" : "main-box"}
          >
            <div className="title">
              <h4>SEO Performance</h4>
            </div>
            <div className="dash-content">
              {seodatashow == true ? (
                <>
                  <div className="graph-box custome_graph_range h-100">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="left">
                        <a className="seo-category-imgbox">
                          <div className="content-title">Traffic</div>
                          <div className="img-box">
                            <img src="/images/seo-ste.png" alt="#" />
                            <span class="hover-img trafic-img">
                              <div className="dashboard-img d-flex">
                                <div class="row">
                                  <div className="d-flex align-items-center p-0">
                                    <table>
                                      <tr>
                                        <td className="traffic-value border-rightside px-3">
                                          {returnMagnitude(traffic)}
                                        </td>
                                        <td className="traffic-value border-rightside px-3 ">
                                          {returnMagnitude(compareTraffic)}
                                        </td>
                                        <td className="traffic-value border-rightsid px-3 text-center">
                                          <div
                                            className={getColorOfData(
                                              trafficDifference
                                            )}
                                          >
                                            {returnMagnitude(trafficDifference)}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="traffic-date border-rightside px-3">
                                          {formatRankDate(
                                            state?.selection1?.startDate
                                          )}
                                        </td>
                                        <td className="traffic-date border-rightside px-3 ">
                                          {formatRankDate(
                                            state?.selection2?.startDate
                                          )}
                                        </td>
                                        <td className="traffic-date px-3 text-center ">
                                          Change
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div className="right text-center">
                        <div className="big-digit">
                          {returnMagnitude(compareTraffic)}
                        </div>
                        <div className={getColorOfData(trafficDifference)}>
                          {returnMagnitude(trafficDifference)}
                        </div>
                        <div className="small-title">Sessions</div>
                      </div>
                    </div>
                  </div>
                  <div className="graph-box custome_graph_range h-100">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="left">
                        <a className="seo-category-imgbox ranking-category">
                          <div className="content-title">Ranking</div>
                          <div className="img-box ">
                            <img src="/images/seo-ste.png" alt="#" />
                            <span class="hover-img ranking-img">
                              <div height="200" width="300" className="d-flex">
                                <div class="row">
                                  <div className="d-flex align-items-center p-0">
                                    <table>
                                      <tr>
                                        <td className="traffic-value border-rightside px-3">
                                          {returnMagnitude(ranking)}
                                        </td>
                                        <td className="traffic-value border-rightside px-3">
                                          {returnMagnitude(compareRanking)}
                                        </td>
                                        <td className="traffic-value  px-3 text-center ">
                                          <div
                                            className={getColorOfData(
                                              rankingDifference
                                            )}
                                          >
                                            {returnMagnitude(rankingDifference)}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="traffic-date border-rightside px-3">
                                          {formatRankDate(
                                            state?.selection1?.startDate
                                          )}
                                        </td>
                                        <td className="traffic-date border-rightside px-3">
                                          {formatRankDate(
                                            state?.selection2?.startDate
                                          )}
                                        </td>
                                        <td className="traffic-date px-3 text-center">
                                          Change
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div className="right text-center">
                        <div className="big-digit">
                          {returnMagnitude(compareRanking)}
                        </div>
                        <div className={getColorOfData(rankingDifference)}>
                          {returnMagnitude(rankingDifference)}
                        </div>
                        <div className="small-title">Top 10</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="dashboard-msg text-center">
                    <p> Data Unavailable </p>

                    <span> No modules are configured under this section </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-4 col-sm-12 mt-3 px-0 px-md-3 mt-md-0 seo-health-dashboard"
          onClick={() => {
            setShowComponents(false);
          }}
        >
          <div
            className={showComponents ? "main-box" : "main-box main-box-active"}
          >
            <SEOHealth dataParentToChild={parentToChild} />
          </div>
        </div>

        <div className="col-md-4 px-0 col-sm-12 mt-3 mt-md-0 ticketdashboard-tab">
          <a href="/ticket/dashboard" target="_blank">
            <div className="main-box">
              <DashboardTicketTest dataParentToChild={parentToChild} />
            </div>
          </a>
        </div>
      </div>

      {showComponents ? (
        <>
          <div
            className="row my-4 seo_perfomance_block seo_perfomance_main"
            id="seo-per-content"
          >
            <div className="title-bar d-flex flex-wrap align-items-center justify-content-between">
              <h4 className="pb-3 pb-sm-0">SEO Performance</h4>
              <div className="btn-box main-tab">
                <div className="d-flex align-items-center">
                  <button
                    className={`${isChart == 0 ? "active" : ""}`}
                    onClick={() => {
                      let type = 0;
                      setIsChart(type);
                    }}
                  >
                    <img
                      src={`${isChart == 0
                          ? "/images/graph-icon-white.svg"
                          : "/images/graph-icon-black.svg"
                        }`}
                      alt=""
                    />
                  </button>
                  <button
                    className={`${isChart == 1 ? "active" : ""}`}
                    onClick={() => {
                      let type = 1;
                      setIsChart(type);
                    }}
                  >
                    <img
                      src={`${isChart == 1
                          ? "/images/table-icon-white.svg"
                          : "/images/table-icon-black.svg"
                        }`}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="row px-0">
              {getCheckPermission("rankings", "reports") ? (
                <div className="col-md-4 col-sm-12 dashboard-ranking">
                  <div className="seo-per-box ps-0 h-100">
                    <RankingChartV2
                      IsSeoChart={isChart}
                      dataParentToChild={parentToChild}
                    ></RankingChartV2>
                    <div className="text-right">
                      <Link
                        to={"/main/ranking"}
                        onClick={() => {
                          localStorage.setItem('startDate', "")
                          localStorage.setItem('endDate', "")
                          localStorage.setItem('startDate2', "")
                          localStorage.setItem('endDate2', "")
                          localStorage.setItem("isCompareVar", false);
                        }}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("impressions_&_clicks", "reports") ? (
                <div className="col-sm-12 col-md-8 mt-4 mt-md-0 ">
                  <div className="seo-per-box h-100">
                    <Gacchart
                      IsSeoChart={isChart}
                      dataParentToChild={parentToChild}
                    />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-gsc/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {getCheckPermission("traffic", "reports") ? (
                <div className="col-sm-12 mt-4">
                  <div className="seo-per-box ga-seo">
                    <Gachart
                      IsSeoChart={isChart}
                      dataParentToChild={parentToChild}
                    />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-google-analytics/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("click_share", "reports") ? (
                <div className="col-sm-12 mt-4">
                  <div className="seo-per-box">
                    <Clicksharechart
                      IsSeoChart={isChart}
                      dataParentToChild={parentToChild}
                    />
                    <div className="text-right ">
                      <Link
                        to={"/main/module-expand-click-share/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("return_on_investment", "reports") ? (
                <div className="col-md-12 col-sm-12 roi-dashboard mt-4 mb-4">
                  <div className="seo-per-box">
                    <Roidashboard
                      IsSeoChart={isChart}
                      dataParentToChild={parentToChild}
                    />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-roi-calc/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row my-4" id="seo-per-content">
            <div className="title-bar d-flex flex-wrap align-items-center justify-content-between">
              <h4 className="pb-3 pb-sm-0">SEO Health</h4>
              <div className="btn-box main-tab">
                <div className="d-flex align-items-center">
                  <button
                    className={`${isChartAll == 0 ? "active" : ""}`}
                    onClick={() => {
                      let type = 0;
                      setChartAll(type);
                      setIsAuditChart(type);
                      setIsImplementChart(false);
                    }}
                  >
                    <img
                      src={`${isChartAll == 0
                          ? "/images/graph-icon-white.svg"
                          : "/images/graph-icon-black.svg"
                        }`}
                      alt=""
                    />
                  </button>
                  <button
                    className={`${isChartAll == 1 ? "active" : ""}`}
                    onClick={() => {
                      let type = 1;
                      setChartAll(type);
                      setIsAuditChart(type);
                      setIsImplementChart(true);
                    }}
                  >
                    <img
                      src={`${isChartAll == 1
                          ? "/images/table-icon-white.svg"
                          : "/images/table-icon-black.svg"
                        }`}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="row px-0">
              <div className={getclassName()}>
                {getCheckPermission("audit_findings", "reports") ? (
                  <div className="seo-per-box h-100">
                    <div className="title pb-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 pb-sm-0">
                          <h4 className="me-3">Audit Score</h4>
                        </div>
                        {isImplementChart ? (
                          <>
                            <div className="col-md-4 d-flex align-items-center d-none d-md-block ">
                              <h4 className="ms-3 me-3">
                                Implementation Status
                              </h4>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="btn-box">
                          <div className="d-flex align-items-center">
                            <button
                              className={`${isAuditChart == 0 ? "active" : ""}`}
                              onClick={() => {
                                setIsImplementChart(false);
                                setIsAuditChart(0);
                              }}
                            >
                              <img
                                src={`${isAuditChart == 0
                                    ? "/images/graph-icon-white.svg"
                                    : "/images/graph-icon-black.svg"
                                  }`}
                                alt=""
                              />
                            </button>
                            <button
                              className={`${isAuditChart == 1 ? "active" : ""}`}
                              onClick={() => {
                                setIsImplementChart(true);
                                setIsAuditChart(1);
                              }}
                            >
                              <img
                                src={`${isAuditChart == 1
                                    ? "/images/table-icon-white.svg"
                                    : "/images/table-icon-black.svg"
                                  }`}
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex-align-items-center justify-content-between">
                      <div
                        className={`${isImplementChart ? "col-md-4" : "col-md-12"
                          }`}
                      >
                        <AuditScore isChart={isChartAll} />
                      </div>
                      <div className={`${isImplementChart ? "col-md-8" : ""}`}>
                        {isImplementChart ? <ImplementationChart /> : <></>}
                      </div>
                    </div>
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-seo-audit/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {getCheckPermission("website_authority", "reports") ? (
                <div className="col-md-8 col-sm-12 mt-4 mt-md-0">
                  <div className="seo-per-box website_authority_block">
                    <div className="title d-flex align-items-center justify-content-between">
                      <div className="d-block d-sm-flex py-2 mb-1">
                        <h4>Website Authority </h4>
                        <h4 className="dashborad-ticket-date ms-2 me-2">
                          ({getMonthName()} - {new Date().getFullYear()})
                        </h4>
                      </div>
                      <div className="d-flex align-items-center"></div>
                    </div>
                    <div className="row px-0 mt-4 d-flex align-items-center">
                      <div className="col-md-6 col-sm-12 ">
                        <div className="dash-content authority">
                          <DomainAuthority dataParentToChild={parentToChild} />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mt-4 mt-md-0">
                        {" "}
                        <div className="dash-content authority">
                          <PageAuthority dataParentToChild={parentToChild} />
                        </div>
                      </div>
                    </div>
                    <div className="btn-link-blue text-end mt-3">
                      <Link
                        to={"/main/module-expand-da/:id"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("page_speed", "reports") ? (
                <div className="col-sm-12 mt-4">
                  <div className="seo-per-box">
                    <Pagespeedchart
                      dataParentToChild={parentToChild}
                      isChart={isChartAll}
                    />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-page-speed/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("site_uptime_monitor", "reports") ? (
                <div className="col-md-4 col-sm-12  mt-4 mb-4">
                  <div className="seo-per-box h-100">
                    <SiteUpTime dataParentToChild={parentToChild} />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-site-uptime/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {getCheckPermission("backlink_profile", "reports") ? (
                <div className="col-md-8 col-sm-12 backlink-profile mt-4 mb-4">
                  <div className="seo-per-box h-100">
                    <Backlink
                      isChart={isChartAll}
                      dataParentToChild={parentToChild}
                    />
                    <div className="text-right">
                      <Link
                        to={"/main/module-expand-backlinks/"}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard2;
