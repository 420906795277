import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const etaCalendar = css({
    ".___SPopper_cw9ff_gg_": {
        padding: "0 1rem !important"
    }
})

export const inputStyles = css({
    ".___SValue_11bfw_gg_:not(:disabled):read-only~.___SOutline_11bfw_gg_": {
        background: "none !important"
    },
    "input": {
        cursor: "default",
        ...textStyles.H1125Medium,
    }
})