import { useEffect, useState, useRef } from "react";
import { default as ReactSelect } from "react-select";
import {
  addFreelancer,
  getFreelancers,
  GET_Server_URL,
  freelancerBulkImport,
  getnichelist,
  onlyFileType,
  onlyXlsFile,
  successrate
} from "../../utility/Constant";
import { isValidPDFDOCFile, isValidBulkUpload, fixTwoPlaceDecimal } from "../../utility/Globals";
import { Drawer } from "antd";
import APIManager from "../../services/APIManager";
import { toast } from "react-hot-toast";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";

function DatabaseContent() {
  const [List, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isBulk, setIsBulk] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [isAddFreelancer, setIsAddFreelancer] = useState(false);
  const [fileSelectedMsg, setFileSelectedMsg] = useState("No file selected");
  const [fileName, setFileName] = useState("");
  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState();
  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [save, setsave] = useState(false);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();

  const [freelancerCatagory, setFreelancerCategory] = useState("");
  const [freelancerType, setFreelancerType] = useState("0");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [articlePerDay, setArticlePerDay] = useState("");
  const [costPerWord, setCostPerWord] = useState("");
  const [costPerArticle, setCostPerArticle] = useState("");
  const [selectedNiche, setSelectedNiche] = useState([]);
  const [nicheList, setNicheList] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [documentFile, setDocumentFile] = useState("");
  const [isActive, setIsActive] = useState([0, 1, 2]);
  const [comptrlist, setcomptrlist] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");
  let nichestr = "";
  const navigate = useNavigate();
  const [successRateId, setSuccessRateId] = useState([]);
  useEffect(() => {
    getNichelist();
    getFreelancerList();
    getsuccessrate();
  }, []);

  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setFreelancerCategory("");
    setFreelancerType("0");
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setEmail("");
    setArticlePerDay("");
    setCostPerArticle("");
    setCostPerWord("");
    setfilelist([]);
    setSelectedNiche([]);
  }

  const onClose = () => {
    setIsBulk("");
    setIsIndividual(false);
    setOpen(false);
    clearForm();
  };
  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #212121 ",
      boxShadow: 'none',
      // height: 38,
      // minHeight: 38,
      fontSize: '1rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0 7px',
      bottom: '4px',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121"
    }),
    input: (provided) => ({
      ...provided,
      height: '43px',
      Padding: '0',
      Margin: '0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
      // height: '25px',
      // marginTop: "-12px"
    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0',
      height: '200px'
    }),
  };

  function addFreelancerInBulk() {
    setCloseButton(false);
    var bodyFormData = new FormData();
    var input = document.getElementById("freelancerFile");
    let file = input.files[0];
    let isImport = true;
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    // if (
    //   document.querySelector("#textInputUrl").disabled == false &&
    //   isValidURL()
    // ) {
    //   bodyFormData.append("url", url);
    //   isImport = true;
    // } else 
    if (document.querySelector("#freelancerFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }
    if (isImport) {
      APIManager.postAPI(freelancerBulkImport, {}, bodyFormData, false).then(
        (response) => {
          if (response?.data?.errors) {
            if (response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
              toast.error('Some Columns are Missing');
              return;
            }
          }
          else if (response && response.status == 200) {
            notifyAdd();
            getFreelancerList();
            onClose();
            setErrors("");
            setUrl("");
            getsuccessrate();
          }
        }
      );
    }
  }

  function updateList() {
    var input = document.getElementById("myfile1");
    var output = document.getElementById("fileList");
    var data = [];
    var children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      setDocumentFile(input?.files?.item(i));
    }
    setfilelist(data);
    setCloseButton(true);
    //output.innerHTML = "<ul>" + children + "</ul>";
  }

  function formValidation() {
    let error = { ...errors };

    freelancerCatagory.trim() == ""
      ? (error["freelancerCatagory"] = "Freelancer Catagory is required!")
      : delete error["freelancerCatagory"];

    firstName.trim() == ""
      ? (error["firstName"] = "First Name is required!")
      : !firstName.trim().match(/^[a-zA-Z\s]*$/)
        ? (error["firstName"] = "Please enter alphabet characters only.")
        : delete error["firstName"];

    lastName.trim() == ""
      ? (error["lastName"] = "Last Name is required!")
      : !lastName.trim().match(/^[a-zA-Z\s]*$/)

        ? (error["lastName"] = "Please enter alphabet characters only.")
        : delete error["lastName"];

    mobileNumber.trim() == ""
      ? (error["mobileNumber"] = "Mobile Number is required !")
      : !mobileNumber.trim().match(/^[0-9]{10}$/)
        ? (error["mobileNumber"] = "Please enter number only!")
        : delete error["mobileNumber"];

    if (mobileNumber?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(mobileNumber)) {
        error["mobileNumber"] = "Please enter number only!";
      } else if (mobileNumber.length != 10) {
        error["mobileNumber"] =
          "Phone number should be only of 10 numbers!";
      } else {
        delete error["mobileNumber"];
      }
    }

    email.trim() == ""
      ? (error["email"] = "Email is required!")
      : !email.trim().match(/^([\w._]+)@([\w_]+\.)+([\w]{2,})$/i)
        ? (error["email"] = "Please enter valid email-ID.")
        : delete error["email"];

    selectedNiche.length == 0
      ? (error["niche"] = "Industries is required!")
      : delete error["niche"];

    (/^\d{0,5}(\.\d{0,2})?$/i).test(costPerWord)
      ? delete error["costPerWord"]
      : error["costPerWord"] = "Number should have follow pattern i.e. 12345.23";

    (/^\d{0,5}(\.\d{0,2})?$/i).test(costPerArticle)
      ? delete error["costPerArticle"]
      : error["costPerArticle"] = "Number should have follow pattern i.e. 12345.23";

    (/^\d{0,5}(\.\d{0,2})?$/i).test(articlePerDay)
      ? delete error["articlePerDay"]
      : error["articlePerDay"] = "Number should have follow pattern i.e. 12345.23";

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function addFreelancerData() {
    let bodyFormData = new FormData();

    bodyFormData.append("freelancerCategory", freelancerCatagory);
    bodyFormData.append("freelancerType", freelancerType);
    bodyFormData.append("firstName", firstName);
    bodyFormData.append("lastName", lastName);
    bodyFormData.append("mobile", mobileNumber);
    bodyFormData.append("email", email);
    bodyFormData.append("articlesPerDay", articlePerDay);
    bodyFormData.append("costPerWord", costPerWord);
    bodyFormData.append("costPerArticle", costPerArticle);
    bodyFormData.append("SampleWorkFile", documentFile);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );
    selectedNiche.map((item, index) => {
      bodyFormData.append("niche[" + index + "].industriesId", item.value);
    });

    APIManager.postAPI(addFreelancer, {}, bodyFormData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          onClose();
          getFreelancerList();
          clearForm();
          notifyAdd();
          getsuccessrate();
        } else {
          // toast.error("Email id already exists!");
        }
      }
    );
  }

  const getFreelancerList = () => {
    APIManager.getAPI(getFreelancers).then((response) => {
      if (response && response.status == 200) {
        setList(response.data);
        setcomptrlist(response.data);
        setFilterList(response.data);
        setIsActive([0, 1, 2]);
      }
    });
  };

  const getsuccessrate = () => {
    APIManager.getAPI(successrate).then((response) => {
      if (response && response.status == 200) {
        setSuccessRateId(response.data);
      }
    })
  }
  const getNichelist = () => {
    let organizationId = localStorage.getItem("organizationId");
    APIManager.getAPI(getnichelist + organizationId).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        response.data.map((item) => {
          list.push({ value: item.id, label: item.industriesName });
        });

        setNicheList(list);
      }
    });
  };

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function uploadFreelancerFile() {
    setIsAddFreelancer(true);
    var input = document.getElementById("freelancerFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      // document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      // document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileSelectedMsg("");
    setFileName(file.name);
  }

  const filterByCategory = (selectedfilter, category) => {
    if (selectedfilter.includes(category) && selectedfilter.length > 1)
      selectedfilter.splice(selectedfilter.indexOf(category), 1);
    else selectedfilter.push(category);

    setIsActive(selectedfilter);
    setFilterList(
      List.filter((item) => isActive.includes(item.freelancerCategory))
    );
    setcomptrlist(
      List.filter((item) => isActive.includes(item.freelancerCategory))
    );
  };

  return (
    <>
      <div class="row work-history-box ps-0 database_page mx-3">
        <div className="d-flex justify-content-between align-items-center database-search ps-0 pe-0 ">
          <div class="d-flex align-items-center px-0 work-history-search databse-box w-100">
            <form class="search-box w-100 d-flex">
              <button type="button">
                <i class="fa fa-search"></i>
              </button>
              <input
                type="text"
                class="scope-input w-100"
                placeholder="Search..."
                name="search"
                onChange={(nameSearch) => {
                  if (nameSearch != "") {
                    setFilterList(
                      comptrlist
                        .filter((item) =>
                          item.firstName
                            .toLocaleLowerCase()
                            .includes(
                              nameSearch.target.value.toLocaleLowerCase()
                            )
                        )
                        .concat(
                          comptrlist.filter((item) =>
                            item.lastName
                              .toLocaleLowerCase()
                              .includes(
                                nameSearch.target.value.toLocaleLowerCase()
                              )
                          )
                        )
                    );
                  } else {
                    setFilterList(comptrlist);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    e.preventDefault();
                  }
                }}
              />
            </form>
          </div>
          {/* <div className="btn_inner V"> */}

          {/* <div className="custom_download_btn d-flex align-items-center justify-content-end"> */}
          <div className="scope-work-btn d-flex 3-icons align-items-center">
            <div class=" scope-work me-3">
              <span>
                <a
                  class="btn btn-primary scope-download  h-auto ms-3 gray_btn"
                  target="_blank"
                  href={GET_Server_URL + "audit/Freelancer.xlsx"}
                >
                  Download Template{" "}
                </a>
              </span>
            </div>
            <button
              className=" btn btn-primary add-freelancer-btn ps-2"
              type="button"
              onClick={() => {
                setIsBulk(false);
                setIsIndividual(false);
                setFileSelectedMsg("No file selected");
                setIsAddFreelancer(false);
                setFileName("");
                showLargeDrawer();
                setUrl("");
                setErrors("");
                setClassName1("");
                setClassName2("");
                setCloseButton(false);
              }}
            >
              <FeatherIcon className="custome_feather_wh me-2" icon="plus" />

              Add Freelancers
            </button>
            <Drawer
              title=" Add Freelancer"
              className=" industries-modal add-industries freelancer-drawer"
              placement="right"
              size={size}
              onClose={onClose}
              open={open}
            >
              <div
                class="modal-dialog scope-modal keyword-details-modal"
                role="document"
              >
                <div class="modal-content h-100">
                  <div className="col-md-12 industries-ticket-btn"></div>
                  <div class="modal-body p-0">
                    <div className="portlet common-form-fields database_input_box border-0">
                      <div className="mx-3">
                        <div className={className1}>
                          <div className="radio">
                            <div className="d-flex align-items-center ms-3">
                              <input
                                className="me-3"
                                type="radio"
                                value="option1"
                                id="option1"
                                name="uploadType"
                                checked={isBulk == "bulk" ?? "false"}
                                onClick={(e) => {
                                  setIsBulk("bulk");
                                  setIsIndividual(false);
                                  setIsAddFreelancer(false);
                                  // setIndustryName("");
                                  setClassName1("active");
                                  setClassName2("");
                                }}
                              />
                              <label
                                className={
                                  isBulk == "bulk"
                                    ? "bulk-upload mb-1 text-primary"
                                    : "bulk-upload main-title mb-1"
                                }
                              >
                                Bulk Upload
                              </label>
                            </div>
                            <div className="ms-5">
                              <span className="text-grey ">
                                {/* Setup multiple (bulk) upload with the details by
                                uploading Google Sheet link/.xlsx/.csv file */}

                                Setup multiple(bulk) upload with the details
                                through XLSX file
                              </span>
                            </div>
                            {isBulk == "bulk" ? (
                              <>
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box ">
                                  {/* <div className="ms-3">
                                    <span className=" industries-csv-file">
                                      Add URL (Google Sheet Link)
                                    </span>
                                  </div> */}
{/* 
                                  <div className="mt-2 ms-3 me-3">
                                    <input
                                      className="form-control w-100"
                                      placeholder="URL"
                                      id="textInputUrl"
                                      value={url}
                                      onChange={(e) => {
                                        setUrl(e.target.value);
                                        if (e.target.value.length > 0) {
                                          setIsAddFreelancer(true);
                                          document.querySelector(
                                            "#freelancerFile"
                                          ).disabled = true;
                                        } else {
                                          setErrors("");
                                          setIsAddFreelancer(false);
                                          document.querySelector(
                                            "#freelancerFile"
                                          ).disabled = false;
                                        }
                                      }}
                                    ></input>
                                    {save && errors?.url && (
                                      <>
                                        <div className="field-required mt-2">
                                          <span> {errors?.url}</span>
                                        </div>
                                      </>
                                    )}
                                  </div> */}
                                  {/* <div className="my-3 text-center or">
                                    <span>OR</span>
                                  </div> */}
                                  <span className="ms-3 industries-csv-file">
                                    Add Attachments (Only .xlsx or .CSV Files)
                                  </span>
                                </div>
                                <div className="industries-choose-file d-flex align-items-center justify-content-between p-2 mt-1 ms-3 me-3 ps-3 pe-0">
                                  <div className="btn-choosefile d-flex align-items-center flex-wrap">
                                    <input
                                      className="w-100"
                                      style={{ display: "none" }}
                                      type="file"
                                      id="freelancerFile"
                                      name="freelancerFile"
                                      multiple={false}
                                      accept={onlyXlsFile}
                                      ref={inputFileRef}
                                      onClick={() => { document.getElementById("freelancerFile").value = ""; }}
                                      onChange={() => {
                                        let input = inputFileRef.current;
                                        const fileExtension = input.files[0].name.split(".").at(-1);
                                        let isvalid = isValidBulkUpload(fileExtension)
                                        if (isvalid === true) {
                                          uploadFreelancerFile()
                                        }
                                        else {
                                          document.getElementById("freelancerFile").value = "";
                                        }
                                      }}
                                    />
                                    <a
                                      onClick={onBtnClick}
                                      id="btnChooseFile"
                                      className="btn btn-primary me-3"
                                    >
                                      Choose File
                                    </a>
                                    <span className="file-not-select ">
                                      {fileSelectedMsg}
                                    </span>
                                    <span>
                                      {fileName.length ? fileName : ""}
                                    </span>
                                  </div>


                                  {closeButton ? (
                                    <>
                                      <button
                                        type="button"
                                        title="Close"
                                        style={{
                                          border: "0",
                                          background: "none",
                                        }}
                                        class="close"
                                        // data-dismiss="modal"
                                        // aria-label="Close"
                                        onClick={() => {
                                          setFileName("");
                                          setCloseButton(false);
                                          setIsAddFreelancer(false);
                                          // document.querySelector(
                                          //   "#textInputUrl"
                                          // ).disabled = false;
                                          document.getElementById(
                                            "freelancerFile"
                                          ).value = "";
                                        }}
                                      >
                                        {" "}
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className={className2}>
                          <div className="radio mt-3 database-individual">
                            <div className="d-flex align-items-center">
                              <input
                                className="ms-3"
                                type="radio"
                                value="option2"
                                id="option2"
                                name="uploadType"
                                checked={isBulk == "individual" ?? "false"}
                                onClick={(e) => {
                                  setIsIndividual(e.target.checked);
                                  setIsBulk("individual");
                                  setIsAddFreelancer(true);
                                  setFileSelectedMsg("No file selected");
                                  setFileName("");
                                  setClassName2("active");
                                  setClassName1("");
                                  getNichelist();
                                }}
                              />
                              <label
                                className={
                                  isBulk == "individual"
                                    ? "individual-upload ms-3 text-primary mb-1"
                                    : "individual-upload ms-3 main-title mb-1"
                                }
                              >
                                Individual Upload
                              </label>
                            </div>
                            <div className="ms-5">
                              <span className=" text-grey">
                                Add freelancer by manually adding details
                              </span>
                            </div>

                            <div>
                              {isIndividual ? (
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box global-scrollbar ">
                                  <div className="industries-individual freelancer-modal ms-2">
                                    <div class="col-md-12">
                                      <label className="form-label">
                                        Freelancer Category
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            id="writer"
                                            value="0"
                                            class="h-auto"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="writer"
                                          >
                                            Writer
                                          </label>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            value="1"
                                            class="h-auto"
                                            id="editor"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="editor"
                                          >
                                            Editor
                                          </label>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            value="2"
                                            class="h-auto"
                                            id="both"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="both"
                                          >
                                            Writer + Editor
                                          </label>
                                        </div>
                                      </div>

                                      {save && errors?.freelancerCatagory ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span>
                                              {" "}
                                              {errors?.freelancerCatagory}
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Freelancer Type
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <select
                                        name="freeclancerType"
                                        id="freeclancerType"
                                        class="form-select"
                                        value={freelancerType}
                                        onChange={(e) => {
                                          setFreelancerType(e.target.value);
                                        }}
                                      >
                                        <option value="0">Internal</option>
                                        <option value="1">External</option>
                                        <option value="2">Agency</option>
                                      </select>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        First Name
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => {
                                          setFirstName(e.target.value);
                                        }}
                                      />
                                      {save && errors?.firstName ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.firstName}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Last Name
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => {
                                          setLastName(e.target.value);
                                        }}
                                      />
                                      {save && errors?.lastName ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.lastName}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Mobile Number
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        className="w-100"
                                        type="number"
                                        value={mobileNumber}
                                        onChange={(e) => {
                                          setMobileNumber(e.target.value);
                                        }}
                                      />
                                      {save && errors?.mobileNumber ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.mobileNumber}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Email ID
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => {
                                          setEmail(e.target.value);
                                        }}
                                      />
                                      {save && errors?.email ? (
                                        <>
                                          <div className="field-required  mt-1">
                                            <span> {errors?.email}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Article per day
                                      </label>
                                      <input
                                        className="w-100"
                                        type="number"
                                        placeholder="Ex. 12345.23"
                                        value={articlePerDay}
                                        onChange={(e) => {
                                          setArticlePerDay(e.target.value);
                                        }}
                                      />
                                      {save && errors?.articlePerDay ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.articlePerDay}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Cost per Word
                                      </label>
                                      <input className="w-100"
                                        type="number"
                                        placeholder="Ex. 12345.23"
                                        value={costPerWord}
                                        onChange={(e) => {
                                          setCostPerWord(e.target.value);
                                        }}
                                      />
                                      {save && errors?.costPerWord ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.costPerWord}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Cost per Article
                                      </label>
                                      <input
                                        className="w-100"
                                        type="number"
                                        placeholder="Ex. 12345.23"
                                        value={costPerArticle}
                                        onChange={(e) => {
                                          setCostPerArticle(e.target.value);
                                        }}
                                      />
                                      {save && errors?.costPerArticle ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.costPerArticle}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3 mb-4 niche-input">
                                      <label className="form-label mb-0">
                                        Industries
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <ReactSelect
                                        // styles={themeStyle()}
                                        styles={customStylesSearch}
                                        isMulti
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                        // className="border-0"
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        options={nicheList}
                                        value={selectedNiche}
                                        onChange={(item) => {
                                          setSelectedNiche(item);
                                        }}
                                      ></ReactSelect>

                                      {save && errors?.niche ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.niche}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="profile-portlet personal-doc col-md-12 mt-3">
                                      <label className="form-label">
                                        {" "}
                                        Upload Sample Work{" "}
                                        <small>(Only word & .pdf Files)</small>
                                      </label>
                                      <br />
                                      <div className="personal-choose-file d-flex align-items-center justify-content-between cms_fs14 cms_fw500 cms_fc_black p-3 h-auto ">
                                        <div className="btn-choosefile d-flex align-items-center flex-wrap">
                                          <input
                                            className="w-100"
                                            style={{ display: "none" }}
                                            type="file"
                                            id="myfile1"
                                            name="myfile1"
                                            multiple={false}
                                            accept={onlyFileType}
                                            ref={inputFileRef}
                                            onChange={() => {
                                              let input = inputFileRef.current;
                                              const fileExtension = input.files[0].name.split(".").at(-1);
                                              let isvalid = isValidPDFDOCFile(fileExtension)
                                              if (isvalid === true) {
                                                updateList()
                                              }
                                              else {
                                                document.getElementById("myfile1").value = "";
                                              }
                                            }}
                                          />
                                          <a
                                            onClick={onBtnClick}
                                            id="btnChooseFile"
                                            className="btn btn-primary me-3"
                                          >
                                            Choose File
                                          </a>
                                          <span>
                                            {filelist.length ? filelist : ""}
                                          </span>
                                        </div>
                                        {closeButton ? (
                                          <>
                                            <button
                                              type="button"
                                              title="Close"
                                              style={{
                                                border: "0",
                                                background: "none",
                                              }}
                                              class="close"
                                              onClick={() => {
                                                setfilelist([]);
                                                setDocumentFile("");
                                                setCloseButton(false);
                                              }}
                                            >
                                              {" "}
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {isAddFreelancer ? (
                            <>
                              <div className="btn-box-bottom w-100 freelancer_idividual position-relative">
                                <div className="col-md-12 ">
                                  <div class="portlet-footer portlet-footer-bordered mt-3 px-0">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <button
                                        className="btn btn-outline-secondary close me-3"
                                        type="button"
                                        onClick={onClose}
                                      //   class="close pe-0"
                                      // aria-label="Close"
                                      // data-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        class="btn-outline btn btn-primary w-auto"
                                        onClick={() => {
                                          setsave(true);
                                          setFileName("");
                                          if (isBulk == "bulk") {
                                            addFreelancerInBulk();
                                          } else if (isIndividual) {
                                            if (formValidation()) {
                                              addFreelancerData();
                                            }
                                          }
                                        }}
                                      // data-dismiss={
                                      //     isModelDismiss1 ? "modal" : ""
                                      // }
                                      >
                                        <div className="d-flex align-items-center add_freelancer_btn2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke=" #FFFFFF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-check me-1 "
                                          >
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                          </svg>
                                          {isBulk == "bulk" ? "Add Freelancers" : "Add Freelancer"}
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>

      <div className="das-content-filter mt-4 ps-1 mb-4 mx-3">
        <h4 className="cms_fs16 cms_fw500 cms_fc_dark-black">Filter by</h4>
      </div>
      <div class="writer-editors-tab  p-0 view-breadcrum mx-3">
        <ul class="d-flex mb- px-0 mb-3">
          <li
            class={` ${isActive.includes(0) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 0);
            }}
          >
            <span className="personal-tabview cms_fs16 cms_fw500 cms_radius6  cursor-pointer">
              {isActive.includes(0) ? (
                <FeatherIcon className="custome_feather me-2" icon="check" />
              ) : (
                <></>
              )}
              Writers
            </span>
          </li>
          <li
            class={`mx-3 filter-writer-btn ${isActive.includes(1) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 1);
            }}
          >
            <span className="personal-tabview cms_fs16 cms_fw500 cms_radius6 cursor-pointer">
              {isActive.includes(1) ? (
                <FeatherIcon className="custome_feather me-2" icon="check" />
              ) : (
                <></>
              )}
              Editors
            </span>
          </li>
          <li
            class={` ${isActive.includes(2) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 2);
            }}
          >
            <span className="personal-tabview cms_fs16 cms_fw500 cms_radius6 cursor-pointer">
              {isActive.includes(2) ? (
                <FeatherIcon className="custome_feather me-2" icon="check" />
              ) : (
                <></>
              )}
              Writers & Editors
            </span>
          </li>
        </ul>
      </div>
      <div className="row html2pdf__page-break all-channel-table cms-database-box pb-4">
        {/* <div className="d-flex"> */}
        <div className="row d-flex py-0 allchannel-table px-0 mt-3">
          {filterList.length > 0 ? (
            filterList.map((item) => {
              nichestr = "";
              return (
                <>
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 database-box mb-3"
                    key={item.id}
                    onClick={() => {
                      localStorage.setItem("active", 2);
                      navigate(`/content-main/FreelancerProfile/${item.id}`);
                    }}
                  >
                    <div className="db-content-box cms_border_gray cursor-pointer bg-white position-relative">

                      <div className="d-flex align-items-center  justify-content-between db-card">
                        <div className="d-flex align-items-center justify-content-between db-name ms-3">

                          <div className="writer-box cms_bg_gray position-relative ">
                            <div className="profile_detail">
                              {item.imageURL != "null" &&
                                item.imageURL != null && item.imageURL != "" ? (

                                <>

                                  <img
                                    alt="profile-image"
                                    src={
                                      GET_Server_URL + item.imageURL
                                    }
                                    className="writer-img overflow-hidden"
                                  />
                                </>
                              ) : (<>
                                {/* <img
                                alt="profile-image"
                                src="/images/profile_avtar_img.jpg"
                                className="writer-img"/> */}
                                <div className="database_first_latter">
                                  <span className="position-absolute cms_fs16 cms_fc_dark cms_line-height fw-bold">
                                    {item.firstName.substring(0, 1)?.toUpperCase()}{item.lastName.substring(0, 1)?.toUpperCase()}
                                  </span>
                                </div>
                              </>
                              )}
                            </div>
                            {item.isActive == true ? (
                              <div
                                className="red-green-status green"
                                title="Active on projects"
                              ></div>
                            ) : (
                              <div
                                className="red-green-status red"
                                title="Not active on any project"
                              ></div>
                            )}
                          </div>
                          <div className="d-flex my-0 content-client-name px-0">
                            <div>
                              <h3 className="fw-bold cms_fs18 cms_fc_black ms-3 mb-0">
                                {item.firstName + " " + item.lastName}
                              </h3>
                              <span class="cms_fs14 cms_fw500 cms_fc_blue ms-3">
                                {item.freelancerCategory == 0
                                  ? "Writer"
                                  : item.freelancerCategory == 1
                                    ? "Editor"
                                    : "Writer & Editors"}
                              </span>
                            </div>

                            <button
                              className="db-client-btn bg-white cms_fs14 cms_fw500 cms_fc_dark-black cms_radius6 ms-3 "
                              type="button"
                            >
                              {item.freelancerType == 0
                                ? "Internal"
                                : item.freelancerType == 1
                                  ? "External"
                                  : "Agency"}
                            </button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between  me-3 require-sucess">
                          <button type="button" class="database-success  ms-3">
                            {/* <img
                                className="success-img me-2"
                                src="/images/green-trending-up.svg"
                                alt=""
                              /> */}
                            <FeatherIcon className=" me-1" icon="trending-up" />
                            {successRateId.map((rate) => {

                              if (rate.userId == item.userId) {

                                return <span className="cms_fs14 cms_fw500 me-2">Success: {rate.successRate ? fixTwoPlaceDecimal(rate.successRate) + "%" : 0 + "%"}</span>
                              }
                            })}

                          </button>
                        </div>
                      </div>

                      <div className="border-bottom my-3"></div>

                      <div className="d-flex justify-content-start align-items-center writer-website-name px-3">

                        <h5 className="cms_fs14 cms_fw500 cms_fc_dark-gray my-1 me-3 mt-2">Industries</h5>

                        {item.niche.map((item, index) => {
                          return nicheList.map((list) => {
                            if (
                              item.industriesId.toLocaleLowerCase().trim() ==
                              list.value.toLocaleLowerCase().trim()
                            ) {
                              nichestr =
                                nichestr != "" ? nichestr + ", " : "";
                              // if (index < 3) {
                              nichestr = nichestr + list.label;
                              return (
                                <div className="freelancer-details mt-2" title={nichestr}>
                                  <button
                                    className="website-btn cms_fs14 cms_fw500 cms_fc_black cms_bg_gray cms_border_gray cms_radius6 mx-1"
                                    type="button"
                                  >
                                    {list.label}
                                  </button>
                                </div>
                              );
                              // } else {
                              //   nichestr = nichestr + list.label;
                              // }
                            }
                          });
                        })}
                        {/* {item.niche.length > 3 ? ( */}
                        {/* <span title={nichestr}></span> */}
                        {/* ) : ( */}
                        {/* ""
                          )} */}
                      </div>


                      <div className="border-bottom my-3"></div>

                      <div className="d-flex justify-content-start content-price px-3 pb-1">
                        <span className="cms_fs14 cms_fw500 cms_fc_dark-gray my-2 me-4">Price</span>
                        {item.costPerWord ? (
                          <>
                            <p className="cms_fc_dark-black cms_fw500 cms_fs14 ps-1 me-3  my-2 ">
                              Rs.{item.costPerWord}/Word
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                        {item.costPerWord && item.costPerArticle ? (
                          <div className="border-rightside"></div>
                        ) : (
                          ""
                        )}
                        {item.costPerArticle ? (
                          <>
                            <p className="cms_fc_dark-black cms_fw500 cms_fs14 ps-1 me-3  my-2">
                              Rs.{item.costPerArticle}/Article
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>


                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div className="no-data">
                <div className=" no-data-msg ">
                  <div className="ms-1">
                    <svg
                      class="ant-empty-img-simple"
                      width="100"
                      // height="100"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(0 1)"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <ellipse
                          class="ant-empty-img-simple-ellipse"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        ></ellipse>
                        <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            class="ant-empty-img-simple-path"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="d-block align-items-center ms-4">
                    <span> No Data</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* </div> */}
      </div>
    </>
  );
}

export default DatabaseContent;
