import React from "react";
import "antd/dist/antd.css";

const HTMLdata = (props) => {
    return (<>
        <div className="col-md-12 col-sm-12 para-box seo-table">
            <div className="title">
                
                    <label>{props.title}</label>
                
            </div>
            <div className="bg-transparent observe-recommendation-data"
                 dangerouslySetInnerHTML={{ __html: props.data }}
            ></div>
        </div>
    </>)
}

export default HTMLdata