import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = {
  ...textStyles.b3,
  minWidth: "100%",
  border: "none !important",
  position: "relative",
};

export const dropdown = {
  height: "10rem",
  position: "absolute",
  overflowY: "scroll",
};
export const dropdownContainer = {
  height: "10rem",
  overflowY: "scroll",
};

export const input = css({
  ...textStyles.b3,
  border: "none !important",
  position: "relative",
});

export const option = css({
  ...textStyles.b3,
  padding: "0.38rem 0.5rem",
});

export const spinnerContainer = css({
  width: "100%",
  height: "10rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

export const optionContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ":hover": {
    backgroundColor: Colors.primary[50],
  },
  cursor: "pointer",
  zIndex: 2,
});

export const type = css({
  ...textStyles.b3,
  color: Colors.neutral[500],
  paddingRight: "1rem"
})

export const icon = css({
  height: "1.125rem",
  width: "1.125rem",
});
