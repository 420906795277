import { Input, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import APIManager from "../../services/APIManager";
import { Sorter } from "../../common/Sorter";
import {
  auditIsActive,
  deleteAudit,
  deleteAuditMainModule,
  GET_Server_URL,
  getAuditModuleByOrgId2,
} from "../../utility/Constant";
import {
  getCheckPermission,
} from "../../utility/Globals";
import AuditModuleAddUpdate from "./AuditModuleAddUpdate";
import AuditInstruction from "./AuditInstruction";
import AuditAddUpdate from "./AuditAddUpdate";
import AuditUpdate from "./AuditUpdate";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import FeatherIcon from 'feather-icons-react';
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const AuditChecklist = ({ update }) => {
  const navigate = useNavigate();
  useEffect(() => {
    var columns = [
      {
        title: "Audit Module",
        dataIndex: "modulename",
        key: "modulename",
        width: "45%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.modulename, b.modulename),
          multiple: 3,
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
      },
    ];
    columns = [
      {
        title: "Modules",
        dataIndex: "text",
        key: "text",
        width: "40%",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
      },
      {
        title: "Activate/Deactivate",
        dataIndex: "actde",
        key: "actde",
        width: "15%",
      },
    ];
    setAuditCol(columns);
    getAuditList();
  }, []);

  const [AuditCol, setAuditCol] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [searchdataAuditTab, setsearchAuditTab] = useState([]);
  const [settings, setSetting] = useState("auditChecklist");
  const [mainSelection, setMainSelection] = useState("");

  function mappingData(list) {
    return list.reduce(function (acc, curr) {
      var findIfNameExist = acc.findIndex(function (item) {
        return item.name === curr.name;
      });
      if (findIfNameExist === -1) {
        let o = {
          name: curr.pointTitle,
          id: curr.id,
          listItem: curr,
        };
        let obj = {
          name: curr.name,
          title: curr.pointTitle,
          listItem: curr,
          value: [o],
        };
        acc.push(obj);
      } else {
        let o = {
          name: curr.pointTitle,
          id: curr.id,
          listItem: curr,
        };
        acc[findIfNameExist].value.push(o);
      }
      return acc;
    }, []);
  }

  const [AuditImport, setAuditImport] = useState(false);
  function handleAuditImport() {
    setAuditImport(!AuditImport);
  }

  async function deleteAuditItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteAudit + id);
      if (response === 200) {
        getAuditList();
      }
    } catch (e) { }
  }

  async function deleteAuditModule(id) {
    try {
      const response = await APIManager.deleteAPI(deleteAuditMainModule + id);
      if (response === 200) {
        getAuditList();
      }
    } catch (e) { }
  }

  function addInChild(data) {
    let childArray = [];
    let j = 1;
    data?.auditChkResponses?.map(item => {
      let obj = {
        searchText: item.pointTitle,
        text: item.isActive ? item.pointTitle : <span style={{ color: '#A3A3A3' }}>{item.pointTitle}</span>,
        id: item.id,
        action: (
          <div>
            {getCheckPermission("edit", "organization_setup") ?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit edit-icon me-3"
                onClick={() => {
                  setSetting("auditUpdate");
                  navigate("",{
                    state: {
                      listItem: item,
                      name: data.auditModuleName,
                    },
                  });
                }}
              >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </svg>
              : <></>}
            {/* {getCheckPermission("delete", "organization_setup") ?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-trash-2 trash-icon text-right"
                onClick={() => {
                  deleteAuditItem(item.id);
                }}
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
              : <></>} */}
          </div>
        ),
        actde: (
          <label class="switch">
            <input
              type="checkbox"
              checked={item.isActive}
              onClick={() => {
                checkWithAlert(item, 'child', data);
              }}
            />
            <span class="slider round"></span>
          </label>
        ),
      }
      childArray.push(obj);
    });
    return childArray;
  }

  function getAuditList() {
    var id = localStorage.getItem("organizationId");
    let allData = [];
    let i = 0;
    APIManager.getAPI(getAuditModuleByOrgId2 + id).then((response) => {
      if (response && response.status === 200) {
        // const updatedList = mappingData(response.data);
        response.data?.forEach((parent) => {
          let obj = {
            expanded: false,
            children: addInChild(parent),
            key: i += 1,
            text: parent.auditModuleName,
            id: parent.auditModuleId,
            action: (
              <div>
                {getCheckPermission("edit", "organization_setup") ?
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon me-3"
                    onClick={() => {
                      setSetting("addUpdateAuditModule");
                      navigate("",{
                        state: {
                          isUpdate: true,
                          name: parent.auditModuleName,
                          description: parent?.description,
                          id: parent.auditModuleId,
                        },
                      });
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                  : <></>}
              </div>
            ),
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={parent.isActive}
                  onClick={() => {
                    checkWithAlert(parent, 'parent', parent);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
          }
          allData.push(obj);
        });
        setAuditData(allData);
        setsearchAuditTab(allData)
      }
    });
  }

  function checkWithAlert(item, type, allData) {
    if (item.isActive) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <div className="d-flex">
                <div className="icon">
                  <FeatherIcon icon="alert-circle" />
                </div>
                <div className="text ps-3 border-bottom pb-3">
                  <h4 className="fw-bold">{item?.auditModuleName ? 'Deactivate "' + item?.auditModuleName + '" and its associate pointers?' : 'Deactivate Selected Audit pointer?'}</h4>
                  {item?.auditModuleName ? <p className="mb-0">
                    This action will hide the Category Name and their related pointers from the audit checklist.
                  </p> : <p className="mb-0">
                    This action will hide the selected pointer from the audit checklist.
                  </p>}
                </div>
              </div>
              <div className="d-flex w-100 justify-content-end pt-3">
                <button className="btn btn-outline" onClick={onClose}>Cancel</button>
                <button className="btn btn-danger ms-3"
                  onClick={() => {
                    updateStatus(item, type, allData);
                    onClose();
                  }}>
                  Deactivate
                </button>
              </div>
            </div>
          );
        }
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui activate-selected'>
              <div className="d-flex">
                <div className="icon">
                  <FeatherIcon icon="alert-circle" />
                </div>
                <div className="text ps-3 border-bottom pb-3">
                  <h4 className="fw-bold">{item?.auditModuleName ? 'Activate "' + item?.auditModuleName + '" and its associate pointers?' : 'Activate Selected Audit pointer?'}</h4>
                  {item?.auditModuleName ? <p className="mb-0">
                    This action will make the Category and their related pointers visible again in the audit checklist.
                  </p> : <p className="mb-0">
                    This action will make the selected pointer visible again in the audit checklist.
                  </p>}

                </div>
              </div>
              <div className="d-flex w-100 justify-content-end pt-3">
                <button className="btn btn-outline" onClick={onClose}>Cancel</button>
                <button className="btn btn-primary ms-3"
                  onClick={() => {
                    updateStatus(item, type, allData);
                    onClose();
                  }}>
                  Activate
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }

  function updateStatus(item, type, allData) {
    const payload1 = {
      "AuditModuleList": [{
        "ModuleId": item.auditModuleId,
        "IsActive": item.isActive ? 0 : 1,
      }]
    }
    allData?.auditChkResponses.forEach((e, i) => {
      if (e.id === item.id) {
        allData.auditChkResponses[i]['isActive'] = allData.auditChkResponses[i]['isActive'] ? 0 : 1;
      }
    });
    const payload2 = {
      "AuditModuleList": [{
        "ModuleId": allData.auditModuleId,
        "IsActive": allData.isActive,
        "AuditPointerList": allData.auditChkResponses
      }]
    }
    APIManager.postAPI(
      auditIsActive,
      type === 'child' ? payload2 : payload1,
      null,
      true
    ).then((response) => {
      if (response.status === 200) {
        getAuditList();
      }
    });
  }

  function filterAuditChecklist(textData) {
    let childList = [];
    let parentObj;
    let mainList = [];
    searchdataAuditTab.forEach((item) => {
      childList = []
      parentObj = {
        actde: item.actde,
        action: item.action,
        expanded: item.expanded,
        id: item.id,
        key: item.key,
        text: item.text,
        searchText: item.searchText
      }
      item.children.filter((person) => {
        if (person.searchText.toLocaleLowerCase().includes(textData.toLocaleLowerCase())) {
          childList.push(person)
        }
      }
      )
      parentObj = {
        ...parentObj,
        children: childList
      }
      mainList.push(parentObj)
    })
    setAuditData(
      mainList
    );
  }


  let onClickFunction = (arg) => {
    setSetting(arg);
    getAuditList();
  };

  function getComponent() {
    if (settings === "addUpdateAuditModule") {
      return <AuditModuleAddUpdate update={onClickFunction} />;
    } else if (settings === "auditIntroduction") {
      return <AuditInstruction update={onClickFunction} />;
    } else if (settings === "addAuditPoint") {
      return <AuditAddUpdate update={onClickFunction} />;
    } else if (settings === "auditUpdate") {
      return <AuditUpdate update={onClickFunction} />;
    }
  }

  function headerSelection(type) {
    setMainSelection(type);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item, index) => {
        let i = 0;
        let obj = {
          expanded: false,
          children: addInChild(item),
          key: i += 1,
          text: item.text,
          searchText: item.searchText,
          id: item.id,
          action: (
            <div>
              {getCheckPermission("edit", "organization_setup") ?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit edit-icon me-3"
                  onClick={() => {
                    setSetting("addUpdateAuditModule");
                    navigate("",{
                      state: {
                        isUpdate: true,
                        name: item.name,
                        description: item?.description,
                        id: item.id,
                      },
                    });
                  }}
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
                : <></>}
            </div>
          ),
          actde: item.actde,
        }
        list.push(obj);
      });
    },
  };

  return (
    <>
      <div className="custom-column-70 auditchecklist_main" id="audit">
        {settings === "auditChecklist" ? (
          <div className="portlet slideInUp setting-top">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {localStorage.getItem("organizationName")} - Audit Checklist
              </h3>
            </div>
            <div className="portlet-body p-0">
              <div class="common-table">
                <div class="row mt-3 auditchecklist">
                  {mainSelection === "" && (
                    <>
                      <div class=" col-md-3 search ">
                        <div class="input-group" style={{ display: "block" }}>
                          <div class="input-group-icon input-group-lg widget15-compact">
                            <div class="input-group-prepend">
                              <i class="fa fa-search text-primary"></i>
                            </div>
                            <Input
                              allowClear
                              type="text"
                              class="form-control"
                              placeholder="Type to search..."
                              onChange={(nameSearch) => {
                                filterAuditChecklist(nameSearch.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div class="col-md-12 col-sm-12 text-left text-md-right audit-btn px-0">
                          {getCheckPermission("add", "organization_setup") ?
                            <button
                              style={{ color: "#fff" }}
                              class="btn btn-primary mx-1 download-temp mb-2 mb-xxl-0"
                              onClick={() => {
                                setSetting("auditIntroduction");
                                navigate("",{});
                              }}
                            >
                              Add Audit Introduction
                            </button>
                            : <></>}
                          {getCheckPermission("add", "organization_setup") ?
                            <button
                              style={{ color: "#fff" }}
                              class="btn btn-primary mx-1 download-temp mb-2 mb-xxl-0"
                              onClick={() => {
                                setSetting("addUpdateAuditModule");
                                navigate("",{
                                  state: {
                                    isUpdate: false,
                                  },
                                });
                              }}
                            >
                              Add Audit Module
                            </button>
                            : <></>}
                          {getCheckPermission("add", "organization_setup") ?
                            <button
                              style={{ color: "#fff" }}
                              class="btn btn-primary mx-1 download-temp mb-2 mb-xxl-0"
                              onClick={() => {
                                setSetting("addAuditPoint");
                                navigate("",{
                                  state: null,
                                });
                              }}
                            >
                              Add Audit Point
                            </button>
                            : <></>}

                          <a
                            class="btn btn-primary mx-1 download-temp mb-2 mb-xxl-0"
                            target="_blank"
                            rel="noreferrer"
                            href={GET_Server_URL + "audit/Audit.xlsx"}
                          >
                            Download Template
                          </a>
                          {getCheckPermission("add", "organization_setup") ?
                            <button
                              className="btn btn-primary mx-1 import mb-2 mb-xxl-0"
                              for="auditImportFile"
                              onClick={() => {
                                handleAuditImport();
                                navigate("/main/audit-checklist-import");
                              }}
                            >
                              Import
                            </button>
                            : <></>}
                        </div>
                      </div>
                    </>
                  )}
                  {
                    mainSelection === "activate" && (
                      <>
                        <div class="px-2">
                          <div className="d-flex flex-wrap audit-checklist-search px-4 justify-content-between">
                            <div class=" search me-3">
                              <div class="input-group w-100" style={{ display: "block" }}>
                                <div class="input-group-icon input-group-lg widget15-compact">
                                  <div class="input-group-prepend">
                                    <i class="fa fa-search text-primary"></i>
                                  </div>
                                  <Input
                                    allowClear
                                    type="text"
                                    class="form-control"
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterAuditChecklist(nameSearch.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn-box">
                              <div class="col-md-12 col-sm-12 text-left text-md-right audit-btn px-0">
                                <button
                                  style={{ color: "#fff" }}
                                  class="btn btn-danger mx-1 download-temp mb-2 mb-xxl-0"
                                  onClick={() => {
                                    confirmAlert({
                                      customUI: ({ onClose }) => {
                                        return (
                                          <div className='custom-ui'>
                                            <div className="d-flex">
                                              <div className="icon">
                                                <FeatherIcon icon="alert-circle" />
                                              </div>
                                              <div className="text ps-3 border-bottom pb-3">
                                                <h4 className="fw-bold">Deactivate Selected Audit Pointers?</h4>
                                                <p className="mb-0">
                                                  This action will hide the selected pointers from the audit checklist.
                                                </p>
                                              </div>
                                            </div>
                                            {/* <p>You want to delete this file?</p> */}
                                            <div className="d-flex w-100 justify-content-end pt-3">
                                              <button className="btn btn-outline" onClick={onClose}>Cancel</button>
                                              <button className="btn btn-danger ms-3"
                                                onClick={() => {
                                                  // this.handleClickDelete();
                                                  onClose();
                                                }}>
                                                Deactivate
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      }
                                    });

                                  }}
                                >
                                  Deactivate Selected
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                  {
                    mainSelection === "deactivate" && (
                      <>
                        <div class=" col-md-9 search ">
                          <div class="input-group" style={{ display: "block" }}>
                            <div class="input-group-icon input-group-lg widget15-compact">
                              <div class="input-group-prepend">
                                <i class="fa fa-search text-primary"></i>
                              </div>
                              <Input
                                allowClear
                                type="text"
                                class="form-control"
                                placeholder="Type to search..."
                                onChange={(nameSearch) => {
                                  filterAuditChecklist(nameSearch.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div class="col-md-12 col-sm-12 text-left text-md-right audit-btn px-0">
                            <button
                              style={{ color: "#fff" }}
                              class="btn btn-primary mx-1 download-temp mb-2 mb-xxl-0"
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className='custom-ui activate-selected'>
                                        <div className="d-flex">
                                          <div className="icon">
                                            <FeatherIcon icon="alert-circle" />
                                          </div>
                                          <div className="text ps-3 border-bottom pb-3">
                                            <h4 className="fw-bold">Activate Selected Audit Pointers?</h4>
                                            <p className="mb-0">
                                              This action will make the selected pointers visible again in the audit checklist.
                                            </p>
                                          </div>
                                        </div>
                                        {/* <p>You want to delete this file?</p> */}
                                        <div className="d-flex w-100 justify-content-end pt-3">
                                          <button className="btn btn-outline" onClick={onClose}>Cancel</button>
                                          <button className="btn btn-primary ms-3"
                                            onClick={() => {
                                              // this.handleClickDelete();
                                              onClose();
                                            }}>
                                            Activate
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                });

                              }}
                            >
                              Activate Selected
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>

                <div class="row common-mb-24 auditchecklist_table_block mt-3 mt-md-0">
                  <div class={"col-md-12 position-relative"}>
                    {/* <Table
                      className="table table-bordered table-hover accordian-table auditchecklist-table"
                      id="datatable-4"
                      columns={AuditCol}
                      dataSource={DataAudit}
                      pagination={false}
                    /> */}

                    {/* HEADER DROPDOWN */}
                    {/* <div className="position-arrow position-absolute">
                      <a>
                        <FeatherIcon icon="chevron-down" />
                      </a>
                      <div className="select-choice">
                        <ul>
                          <li className="mb-2 border-bottom pe-5 ps-3 py-2" style={{ cursor: 'pointer' }}
                            onClick={() => {
                              headerSelection('activate');
                            }}>activate</li>
                          <li className="pe-5 ps-3 py-2" style={{ cursor: 'pointer' }} onClick={() => {
                            headerSelection('deactivate');
                          }}>deactivate</li>
                        </ul>
                      </div>
                    </div> */}

                    <Table
                      className="table Bandwidth-Split table-hover table-responsive0 border-0 custome_filter audit-check-table"
                      id="sample"
                      columns={AuditCol}
                      dataSource={auditData}
                      expandRowByClick={true}
                      // rowSelection={{ type: "checkbox", ...rowSelection }}
                      rowClassName={(record) => {
                        return record.expanded ? "active" : "";
                      }}
                      expandable={{
                        onExpandedRowsChange: (data) => {
                          setAuditData([]);
                          let list = auditData;
                          list.forEach(x => {
                            x.expanded = data && data.includes(x.key);
                          });
                          setAuditData(list);
                        },
                        expandIcon: ({ expanded, onExpand, record }) => {
                          return record.expanded ?
                            (
                              record?.children && record?.children?.length ? (<UpOutlined style={{ float: 'right' }} />) : <></>
                            )
                            :
                            (
                              record?.children && record?.children?.length ? (<DownOutlined style={{ float: 'right' }} />) : <></>
                            );
                        },
                      }}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>{getComponent()}</>
        )}
      </div>
    </>
  );
};

export default AuditChecklist;
