import { css } from "@emotion/react";
import { textStyles } from "../../../../../styles/font"


export const mainContainer = css({
  padding: `0  1rem`,
  marginLeft: '1rem',
  textAlign: "left",
  borderLeft: "solid 1px rgba(117, 117, 117, 0.3)",
  marginTop: '1.25rem',
  display: "flex",
  flexDirection: "column",
  rowGap: '0.5rem',
  zIndex: "10"
});

export const item = (isActive) =>
  css({
    ...textStyles.H1Medium,
    padding: "0.75rem 0.62rem",
    backgroundColor: isActive ? "#E2F1FC" : "transparent",
    color: isActive ? "#0691EF" : "#757575",
    cursor: "pointer",
    borderRadius: "0.5rem",
    whiteSpace: "nowrap",
    ":hover": {
      backgroundColor: "#E2F1FC",
      color: !isActive? "#424242" : "#0691EF",
    },
  });