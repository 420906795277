/** @jsxImportSource @emotion/react */
import * as styles from "./styles"
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const CustomTicketingButton = ({ buttonName, disabled, onClick, buttonType = "btn-primary", className, size = 1, customStyles, customIcon, featherIcon, iconLeft = true }) => {
    const buttonSizes = {
        1: "10rem",
        2: "16.3rem",
        3: "11rem",
        4: "12rem",
        5: "6.5rem"
    }

    return (
        <button
            className={`btn ${buttonType} ${className}`} css={styles.buttonStyles({ ...customStyles, maxWidth: buttonSizes[size] ?? buttonSizes[1], flexDirection: iconLeft ? "row" : "row-reverse" })}
            onClick={() => onClick()}
            disabled={disabled}
        >
            {customIcon ? customIcon : featherIcon && <FeatherIcon icon={featherIcon} />}
            <div style={{ width: "100%" }}>{buttonName}</div>
        </button>
    )
}

export default CustomTicketingButton;