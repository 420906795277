import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "../index";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import { backlinkinfo, getLinkIntersect } from "../../utility/Constant";
import {
  calanderDate,
  competitorsType,
  exportInToCSV,
  exportToEXCEL,
  getCheckPermission,
  mmDDyyyyFormateDateSet,
} from "../../utility/Globals";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import NotFound from "../../NotFound";

function ResearchModuleExpandBackLinks() {
  const u = localStorage.getItem("state");
  const [inputData, setInputData] = useState([
    {
      id: "1",
    },
  ]);
  const [tablelist1, settableList1] = useState([]);
  const [tablecol1, settablecol1] = useState([]);
  const [tablelist2, settableList2] = useState([]);
  const [tablecol2, settablecol2] = useState([]);
  const [tablelist3, settableList3] = useState([]);
  const [tablecol3, settablecol3] = useState([]);
  const [tablelist4, settableList4] = useState([]);
  const [tablecol4, settablecol4] = useState([]);
  const [referringDomainsCount, setreferringDomainsCount] = useState("");
  const [referringBackLinksCount, setreferringBackLinksCount] = useState("");
  const [selectedRefDExportList, setSelectedRefDExportList] = useState([]);
  const [refdexportList, setRefDExportList] = useState([]);
  const [selectedRefBExportList, setSelectedRefBExportList] = useState([]);
  const [refbexportList, setRefBExportList] = useState([]);
  const [selectedBackLinkExportList, setSelectedBackLinkExportList] = useState(
    []
  );
  const [backlinkexportList, setBackLinkExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [selecttype, setselecttype] = useState("");
  const [notFound, setNotFound] = useState(true);

  useEffect(() => {
    setNotFound(getCheckPermission("backlink_profile", "reports"));
    localStorage.setItem("isCompareVar", false);
    var data1 = [];
    settableList1(data1);
    let getdata = {
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      projectId: localStorage.getItem("projectGId"),
    };
    backlinkdata(getdata);

    const columns1 = [
      {
        title: "Types of Ref. domains",
        dataIndex: "types",
        key: "types",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.types, b.types),
          multiple: 3,
        },
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.count, b.count),
          multiple: 3,
        },
      },
    ];
    settablecol1(columns1);
    var data2 = [];
    settableList2(data2);
    const columns2 = [
      {
        title: "Types of Backlinks",
        dataIndex: "types",
        key: "types",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.types, b.types),
          multiple: 3,
        },
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.count, b.count),
          multiple: 3,
        },
      },
    ];
    settablecol2(columns2);
    var data3 = [];
    settableList3(data3);

    const columns3 = [
      {
        title: "Referring page",
        dataIndex: "refering",
        key: "refering",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.refering, b.refering),
          multiple: 3,
        },
      },
      {
        title: "DR",
        dataIndex: "dr",
        key: "dr",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.dr, b.dr),
          multiple: 3,
        },
      },
      {
        title: "Domain Traffic",
        dataIndex: "domain",
        key: "domain",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.domain, b.domain),
          multiple: 3,
        },
      },
      {
        title: "Referring Domains",
        dataIndex: "referingDomains",
        key: "referingDomains",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.referingDomains, b.referingDomains),
          multiple: 3,
        },
      },
      {
        title: "Linked Domains",
        dataIndex: "linkedDomains",
        key: "linkedDomains",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.linkedDomains, b.linkedDomains),
          multiple: 3,
        },
      },
      {
        title: "Ext",
        dataIndex: "ext",
        key: "ext",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ext, b.ext),
          multiple: 3,
        },
      },
      {
        title: "Page Trafic",
        dataIndex: "pageTrafic",
        key: "pageTrafic",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.pageTrafic, b.pageTrafic),
          multiple: 3,
        },
      },
      {
        title: "Kw",
        dataIndex: "kw",
        key: "kw",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.kw, b.kw),
          multiple: 3,
        },
      },
      {
        title: "Anchor and Target Url",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
    ];
    settablecol3(columns3);
    let linkgetdata = {
      startDate: mmDDyyyyFormateDateSet(head1),
      endDate: mmDDyyyyFormateDateSet(head2),
      projectId: localStorage.getItem("projectGId"),
    };
    Linkintersectdata(linkgetdata);
  }, []);

  const rowSelection1 = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          types: item.types,
          count: item.count.replace(",", ""),
        };
        list.push(obj);
      });
      setSelectedRefDExportList(list);
    },
  };

  const rowSelection2 = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          types: item.types,
          count: item.count.replace(",", ""),
        };
        list.push(obj);
      });
      setSelectedRefBExportList(list);
    },
  };

  const rowSelection3 = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          refering: item.refering,
          dr: item.dr,
          domain: item.domain,
          referingDomains: item.referingDomains,
          linkedDomains: item.linkedDomains,
          ext: item.ext,
          pageTrafic: item.pageTrafic,
          kw: item.kw,
          url: item.url,
        };
        list.push(obj);
      });
      setSelectedBackLinkExportList(list);
    },
  };
  const rowSelection4 = {
    onChange: (selectedRowKeys, selectedRows) => {
      let columns = Object.keys(selectedRows[0]);
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {};
        columns?.forEach((key) => {
          obj[key] = item[key];
        });
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function backlinkdata(rowdata) {
    APIManager.postAPI(backlinkinfo, rowdata, null, true).then((response) => {
      if (response && response.status == 200) {
        setreferringDomainsCount(response?.data.referringDomainsCount);
        setreferringBackLinksCount(response?.data.referringBackLinksCount);
        const list1 = [];
        const list_1 = [];
        let i = 0;
        response.data.referringDomains.map((item) => {
          let obj = {
            key: (i += 1),
            types: item.field,
            count: item.count,
          };
          list1.push(obj);
        });
        response.data.referringDomains.map((item) => {
          let obj = {
            types: item.field,
            count: item.count,
          };
          list_1.push(obj);
        });
        settableList1(list1);
        setRefDExportList(list_1);
        const list2 = [];
        const list_2 = [];

        let j = 0;
        response.data.referringBacklinks.map((item) => {
          let obj2 = {
            key: (j += 1),
            types: item.field,
            count: item.count,
          };
          list2.push(obj2);
        });

        response.data.referringBacklinks.map((item, index) => {
          let obj2 = {
            types: item.field,
            count: item.count,
          };
          list_2.push(obj2);
        });
        settableList2(list2);
        setRefBExportList(list_2);
        const list3 = [];
        const exportList = [];
        let n = 0;
        response.data.backLinks.map((item, index) => {
          exportList.push({
            refering: item.referringPage,
            dr: item.dr,
            domain: item.domainTraffic,
            referingDomains: item.referringDomains,
            linkedDomains: item.linkedDomains,
            ext: item.ext,
            pageTrafic: item.pageTraffic,
            kw: item.kw,
            url: item.anchorTargetURL,
          });
          let obj3 = {
            key: (n += 1),
            refering: item.referringPage,
            dr: item.dr,
            domain: item.domainTraffic,
            referingDomains: item.referringDomains,
            linkedDomains: item.linkedDomains,
            ext: item.ext,
            pageTrafic: item.pageTraffic,
            kw: item.kw,
            url: item.anchorTargetURL,
          };
          list3.push(obj3);
        });
        settableList3(list3);
        setBackLinkExportList(exportList);
      }
    });
  }
  function Linkintersectdata(getdata) {
    APIManager.postAPI(getLinkIntersect, getdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          let i = 0;
          if (response.data?.length) {
            response.data.map((item) => {
              let keys = [];
              const columns = [];
              keys = Object.keys(response.data[0]);
              let obj = {};
              keys.map((key) => {
                let col = {
                  title: key,
                  dataIndex: key,
                  key: key,
                  sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a[key], b[key]),
                    multiple: 3,
                  },
                };
                columns.push(col);

                obj[key] = item[key];
              });
              list.push(obj);
              settablecol4(columns);
            });
          }
          settableList4(list);
        }
      }
    );
  }

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  const [BackLinksTab, setBackLinksTab] = useState(true);
  const [LinksTab, setLinksTab] = useState(false);
  const [TicketTab, setTicketTab] = useState(false);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (<>
        <div className="row">
          <Tabs>
            <div className="portlet ">
              <div className="portlet-header portlet-header-bordered my-3">
                <div className="col-md-8 col-sm-12">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        BackLinksTab
                          ? "nav-item nav-link  active "
                          : "nav-item nav-link "
                      }
                      onClick={() => {
                        setLinksTab(false);
                        setTicketTab(false);
                        setBackLinksTab(true);
                      }}
                    >
                      Count of Backlinks
                    </Tab>
                    <Tab
                      className={
                        LinksTab
                          ? "nav-item nav-link  active "
                          : "nav-item nav-link "
                      }
                      onClick={() => {
                        setLinksTab(true);
                        setTicketTab(false);
                        setBackLinksTab(false);
                      }}
                    >
                      Link Intersect
                    </Tab>
                    <Tab
                      className={
                        TicketTab
                          ? "nav-item nav-link  active "
                          : "nav-item nav-link "
                      }
                      onClick={() => {
                        setLinksTab(false);
                        setBackLinksTab(false);
                        setTicketTab(true);
                      }}
                    >
                      Tickets
                    </Tab>
                  </TabList>
                </div>
                <div
                  ref={ref}
                  class="col-md-4 col-sm-12 calendar-main ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {calanderDate(head1, head2)}
                    </button>
                  </div>

                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card  mt-2">
                        <div className="d-flex calendar-flex">
                          <div className="calendar-col">
                            <DateRangePicker
                              onChange={(item) => {
                                let isCompareVarNew = localStorage.getItem("isCompareVar");
                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                    setHead1(new DateObject(item?.selection1?.startDate).format());
                                    setHead2(new DateObject(item?.selection1?.endDate).format());

                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                    setHead1(new DateObject(item?.selection2?.startDate).format());
                                    setHead2(new DateObject(item?.selection2?.endDate).format());
                                  }

                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }
                              }}

                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={[state?.selection1, state?.selection2]}
                              direction="horizontal"
                              ariaLabels={{
                                dateInput: {
                                  selection1: {
                                    startDate: "start date input of selction 1",
                                    endDate: "end date input of selction 1",
                                  },
                                  selection2: {
                                    startDate: "start date input of selction 2",
                                    endDate: "end date input of selction 2",
                                  },
                                },

                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                              }}
                              maxDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate() - 1
                                )
                              }
                            />
                          </div>
                          <div className="mt-3 mt-lg-0 text-center">
                            <div className="bttn-box">
                              <button
                                onClick={() => {
                                  setheadvalues();
                                  setOpen(!open);
                                  let getdata = {
                                    StartDate: mmDDyyyyFormateDateSet(head1),
                                    EndDate: mmDDyyyyFormateDateSet(head2),
                                    projectId: localStorage.getItem("projectGId"),
                                  };
                                  backlinkdata(getdata);
                                  let linkgetdata = {
                                    StartDate: mmDDyyyyFormateDateSet(head1),
                                    EndDate: mmDDyyyyFormateDateSet(head2),
                                    projectId: localStorage.getItem("projectGId"),
                                  };
                                  Linkintersectdata(linkgetdata);
                                  setRefreshTicketData(true);
                                  setTimeout(() => {
                                    setRefreshTicketData(false);
                                  }, 1000);
                                }}
                                className="outline-btn btn btn-primary"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <TabPanel>
              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Referring Domains Count</h3>
                </div>
                <div className="portlet-body">
                  <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4 text-end">
                      <span class="dropdown d-inline">
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedRefDExportList?.length > 0
                                ? selectedRefDExportList
                                : refdexportList,
                              "Referring Domains Count"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedRefDExportList?.length > 0
                                ? selectedRefDExportList
                                : refdexportList,
                              "Referring Domains Count"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedRefDExportList?.length > 0
                                ? selectedRefDExportList
                                : refdexportList,
                              "Referring Domains Count"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12  mt-3">
                  <Table
                    className="table table-bordered  table-hover"
                    id="datatable-1"
                    columns={tablecol1}
                    dataSource={tablelist1}
                    rowSelection={{ type: "checkbox", ...rowSelection1 }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>

              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Referring Backlink Profile</h3>
                </div>
                <div className="portlet-body">
                  <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4 text-end">
                      <span class="dropdown d-inline">
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedRefBExportList?.length > 0
                                ? selectedRefBExportList
                                : refbexportList,
                              "Referring Backlink Profile"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedRefBExportList?.length > 0
                                ? selectedRefBExportList
                                : refbexportList,
                              "Referring Backlink Profile"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedRefBExportList?.length > 0
                                ? selectedRefBExportList
                                : refbexportList,
                              "Referring Backlink Profile"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <Table
                    className="table table-bordered table-hover w-100"
                    id="datatable-1"
                    columns={tablecol2}
                    dataSource={tablelist2}
                    rowSelection={{ type: "checkbox", ...rowSelection2 }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>

              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Backlinks</h3>
                </div>
                <div className="portlet-body">
                  <div className="row ">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4 text-end">
                      <span class="dropdown d-inline">
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedBackLinkExportList?.length > 0
                                ? selectedBackLinkExportList
                                : backlinkexportList,
                              "BackLinks"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedBackLinkExportList?.length > 0
                                ? selectedBackLinkExportList
                                : backlinkexportList,
                              "BackLinks"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedBackLinkExportList?.length > 0
                                ? selectedBackLinkExportList
                                : backlinkexportList,
                              "BackLinks"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <Table
                    className="table table-bordered  table-hover w-100"
                    id="datatable-1"
                    columns={tablecol3}
                    dataSource={tablelist3}
                    rowSelection={{ type: "checkbox", ...rowSelection3 }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Add Competitors</h3>
                </div>
                <div className="portlet-body">
                  <div className="row">
                    {inputData.map((item) => {
                      return (
                        <>
                          <div id={item.id} className="col-md-6 my-2 mt-3">
                            <label
                              className="me-3"
                              style={{ fontSize: 14 + "px" }}
                            >
                              Select Type
                            </label>
                            <select
                              className="w-50"
                              onChange={(e) => {
                                var c = e.target.value;
                                setselecttype(c);
                              }}
                            >
                              {competitorsType.map((i) => {
                                return <option value={i.value}>{i.label}</option>;
                              })}
                            </select>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="portlet">
                <div className="portlet-header portlet-header-bordered">
                  <h3 className="portlet-title">Link Intersect</h3>
                </div>
                <div className="portlet-body">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-lg-4 text-right">
                      <span class="export">
                        <button
                          class="btn btn-primary dropdown-toggle ms-3"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "BackLinks"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "BackLinks"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "BackLinks"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <Table
                    className="table table-bordered table-hover ps-0 pe-0 w-100"
                    id="datatable-1"
                    columns={tablecol4}
                    dataSource={tablelist4}
                    rowSelection={{ type: "checkbox", ...rowSelection4 }}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <ModuleExpandTickets
                startDate={head1}
                endDate={head2}
                refreshTicketData={refreshTicketData}
              />
            </TabPanel>
          </Tabs>
        </div>
      </>)}
    </>
  );
}

export default ResearchModuleExpandBackLinks;
