import React, { useEffect } from "react";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Pagination } from 'antd';
import { getWriterOverview } from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";

function WriterOverview(props) {

    const navigate = useNavigate();
    const [userList, setUserList] = useState([])
    const [userListMain, setUserListMain] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [listTotal, setListTotal] = useState(0)
    const [isData, setIsData] = useState(true)

    useEffect(() => {
        let rowData = {
            projectId: props.project,
            startDate: props.dataParentToChild[0],
            endDate: props.dataParentToChild[1],
            currentPage: pageNumber,
            pageSize: pageSize,
        }
        getWriterData(rowData)
    }, [props])

    // const [current, setCurrent] = useState(3);
    const handleChange = (page, pageSize) => {
        // userListMain.map()
        // setCurrent(page);
        setPageNumber(page)
        setPageSize(pageSize)
        let rowData = {
            projectId: props.project,
            startDate: props.dataParentToChild[0],
            endDate: props.dataParentToChild[1],
            currentPage: page,
            pageSize: pageSize,
        }
        getWriterData(rowData)
    };

    function getWriterData(rowData) {
        APIManager.postAPI(getWriterOverview, rowData, null, true).then(
            (response) => {
                if (response?.status == 200) {
                    let obj = [];
                    if (response.data.table.length) {
                        setIsData(true)
                        response.data?.table.map((item) => {
                            obj.push({
                                name: item.firstName + " " + item.lastName,
                                status: item.status != null ? item.status : 0,
                                // noOfRequirement: item.noOfRequirement,
                                // freelancerId: item.freelancerId,
                                id: item.id,
                                units: item.status != null ? item.status : 0,
                                requirementRaised:item.requirementRaised ? item.requirementRaised  : 0,
                                articleCompleted:item.articleCompleted ? item.articleCompleted : 0
                            })
                        });
                        setUserList(obj)
                        setUserListMain(obj)
                    }
                    else {
                        setIsData(false)
                    }
                    // setListTotal(obj.length)
                    setListTotal(response.data.table1[0]?.noOfWriter);
                }
            }
        );
    }
    return (
        <>
            <div className="title ">
                {/* <div className="d-flex justify-content-between align-items-center"> */}
                <div className="d-flex justify-content-between align-items-center write-overview cms_bg_border p-4">
                    <h4 className="cms_fs18 cms_fw600 cms_fc_dark me-3 my-2">Writer's Overview ({listTotal})</h4>
                </div>
                {/* </div> */}
                {
                    isData ?
                        (<>
                            <div class="align-items-center writer-status  cms_border_gray px-0 bg-white">
                                <div className="row html2pdf__page-break all-channel-table editor-writer-dashbox">
                                    <div className="d-flex">
                                        <div className="row w-100">
                                            {userList.map((item) => {
                                                return (<>
                                                    <div className="col-xl-3 col-lg-6 col-md-6 cursor-pointer editor-dash-box mt-2 mb-2" onClick={() => {
                                                        localStorage.setItem("active" , 2)
                                                        navigate(`/content-main/FreelancerProfile/${item.id}`,{
                                                            state: {
                                                                tab: "workhistory",
                                                                userId: item.id,
                                                                projectId: props.project,
                                                                category: "writer",
                                                                startDate: props.dataParentToChild[0],
                                                                endDate: props.dataParentToChild[1],
                                                            },
                                                        });

                                                    }}>
                                                        <div className="content-units units-completed bg-white writer_overview_block">
                                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                                <div className="d-flex align-items-center">
                                                                    <div class=" d-flex align-items-center unit-first-latter">
                                                                        <h4 className="text-center fw-bold cms_fs16 cms_fc_black">{(item.name).charAt(0).toUpperCase()}</h4>
                                                                    </div>
                                                                    <div>
                                                                        <p className="align-items-center cms_fs16 cms_fw600 cms_fc_dark cms_line-height my-2 mx-2">{item.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-end">
                                                                    <FeatherIcon className="custome_feather" icon="chevron-right" />
                                                                </div>

                                                            </div>
                                                            <div className="content-name border-bottom"></div>
                                                            <div className="d-flex justify-content-start mt-3 ">
                                                                <p className="me-3 mb-2">
                                                                    <span className="cms_fs14 cms_fw600 cms_fc_black">Units Completed : {item.articleCompleted }/{item.requirementRaised}</span> </p>
                                                            </div>
                                                            <ProgressBar className="d-flex content-progress mt-2">
                                                                <ProgressBar
                                                                    now={Math.ceil(item.articleCompleted *(100/item.requirementRaised))}
                                                                    key={1}
                                                                />
                                                            </ProgressBar>
                                                        </div>
                                                    </div>
                                                </>
                                                )
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row writer-pagination">
                                <div className="col-md-12 d-flex align-items-center justify-content-between px-0">
                                    <div className="d-flex align-items-start content-page">
                                        {/* Showing 1 to 4 of 08 Writers */}
                                    </div>
                                    <div className="d-flex align-items-end all-pagination w-100">
                                        <Pagination
                                            defaultCurrent={pageNumber}
                                            // total={}
                                            total={listTotal}
                                            defaultPageSize={4}
                                            showTotal={(total, range) => {
                                                if (range[0] == -3) {
                                                    return `Showing ${1} to ${total} of ${total} Writers`
                                                }
                                                else {
                                                    return `Showing ${range[0]} to ${range[1]} of ${total} Writers`
                                                }
                                            }}
                                            // showTotal={(total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Writers`}
                                            onChange={handleChange}
                                        // pageSizeOptions={[4, 8]}
                                        // showSizeChanger={true}
                                        /></div>
                                </div>
                            </div>
                        </>) :
                        (<>
                            <div className="d-block text-center poc_no_data bg-white py-5">
                                <FeatherIcon className="inbox_img" icon="inbox" />
                                <span className="d-block text-center mt-2"> No Data</span>
                            </div>
                        </>)
                }

            </div>
        </>
    )
}
export default WriterOverview;