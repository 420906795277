import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import {
  documentFileType,
  GET_Server_URL,
  getSiteUpTimeConfig,
  siteUpTimeConfigImport,
  deleteSiteuptime,
} from "../../utility/Constant";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import { findUnique, isValidImportFile, SiteUpTimeTypes } from "../../utility/Globals";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import SiteUpTimeMonitor1 from "./SiteUpTimeMonitor1";
import { Link, useNavigate } from "react-router-dom";

function SiteUpTimeMonitorList({ update }) {
  const navigate = useNavigate();

  useEffect(() => {
    getSiteupTimeMonitorlist();
  }, []);

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [isEditSiteUPTime, setIsEditSiteUPTime] = useState(false);
  const [settings, setSetting] = useState("siteuptimeMonitor");

  const notifyDelete = () => toast.success("Successfully Deleted!");

  const rowSelection = {};

  function uploadSiteuptimeMonitorFile() {
    let input = inputFileRef.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if(isvalid ==  true){
      uploadImportFile(input?.files?.item(0));
    }   
  }

  function uploadImportFile(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      siteUpTimeConfigImport + localStorage.getItem("projectGId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
        toast.error('Some Columns are Missing');
        getSiteupTimeMonitorlist();
        return;
      }
     else if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Invalid File') {
        toast.error('Invalid File');
        getSiteupTimeMonitorlist();
        return;
      }
     else if (response && response.status == 200) {
        toast.success('Successfully Added!');
        getSiteupTimeMonitorlist();
      }
    });
  }

  async function deleteSiteuptimeItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteSiteuptime + id);
      if (response == 200) {
        getSiteupTimeMonitorlist();
      }
    } catch (e) { }
  }

  function submit(item) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteSiteuptimeItem(item.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function getSiteupTimeMonitorlist() {
    APIManager.getAPI(
      getSiteUpTimeConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const filterType = [];
        const filterUrl = [];
        let count = 1;
        response?.data?.siteUptimeConfigurationMappings?.map((item) => {
          let obj = {
            key: count,
            id: item.id,
            type: item.type,
            url: item.url,
            result1: item.result1,
            result2: item.result2,
            result3: item.result3,
            result4: item.result4,
            result5: item.result5,
            siteUpTimeConfigurationId: item.siteUpTimeConfigurationId,
            action: (
              <div class="text-center">
                <Link
                  state={{
                    item: {
                      id: item.id,
                      Type: item.type,
                      URL: {
                        key: item.id,
                        id: item.id,
                        value: item.url,
                        label: item.url,
                        url: item.url,
                      },
                      result1: item.result1,
                      result2: item.result2,
                      result3: item.result3,
                      result4: item.result4,
                      result5: item.result5,
                      siteUpTimeConfigurationId:
                        item.siteUpTimeConfigurationId,
                    },
                    isEdit: true,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon"
                    onClick={() => {
                      setIsEditSiteUPTime(true);
                      setSetting("siteUpTimeMonitorAddEdit");
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Link>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon ms-3"
                  onClick={() => {
                    submit(item);
                    deleteSiteuptimeItem(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
          count++;

          let filtertypelistobj = {
            text: getType(item.type),
            value: item.type,
          };
          filterType.push(filtertypelistobj);

          let filterurllistobj = {
            text: item.url,
            value: item.url,
          };
          filterUrl.push(filterurllistobj);
        });

        const columns = [
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            filters: findUnique(filterType, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.type == value;
            },
            render: (val) => <div>{getType(val)}</div>,
          },
          {
            title: "Url",
            dataIndex: "url",
            key: "url",
            filters: findUnique(filterUrl, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.url == value;
            },
          },
          {
            title: "Expected Result1",
            dataIndex: "result1",
            key: "result1",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.result1, b.result1),
              multiple: 3,
            },
          },
          {
            title: "Expected Result2",
            dataIndex: "result2",
            key: "result2",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.result2, b.result2),
              multiple: 3,
            },
          },
          {
            title: "Expected Result3",
            dataIndex: "result3",
            key: "result3",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.result3, b.result3),
              multiple: 3,
            },
          },
          {
            title: "Expected Result4",
            dataIndex: "result4",
            key: "result4",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.result4, b.result4),
              multiple: 3,
            },
          },
          {
            title: "Expected Result5",
            dataIndex: "result5",
            key: "result5",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.result5, b.result5),
              multiple: 3,
            },
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
        ];
        setteamcol(columns);
        setteamList(list);
      }
    });
  }

  function getType(item) {
    let type;
    SiteUpTimeTypes.map((element) => {
      if (element?.value == item) {
        type = element.label;
      }
    });
    return type;
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getSiteupTimeMonitorlist();
  };

  function getComponent() {
    if (settings == "siteUpTimeMonitorAddEdit") {
      return <SiteUpTimeMonitor1 update={onClickFunction} />;
    }
  }
  return (
    <>
      {settings === "siteuptimeMonitor" ? (
        <div className="portlet slideInUp">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("projectGName")} - Site Uptime Monitor
            </h3>
          </div>
          <div className="portlet-body p-0">
            <div class="common-table px-3">
              <div class="row">
                <div class="col-md-8"></div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 text-right mt-3 pe-0 teammember-tab siteuptime_tab">
                    <button
                      className="btn btn-primary add"
                      onClick={() => {
                        setSetting("siteUpTimeMonitorAddEdit");
                        navigate("",{
                          state: {
                            isEdit: false,
                          },
                        });
                      }}
                    >
                      Add
                    </button>

                    <input
                      style={{ display: "none" }}
                      type="File"
                      id="siteuptimeFile"
                      name="siteuptimeFile"
                      multiple={false}
                      accept={documentFileType}
                      ref={inputFileRef}
                      onChange={uploadSiteuptimeMonitorFile}
                    />

                    <a
                      className="btn btn-primary mx-2 download-temp"
                      target="_blank"
                      href={GET_Server_URL + "audit/SiteUpTime.xlsx"}
                    >
                      Download Template
                    </a>

                    <button
                      className="btn btn-primary import"
                      onClick={onBtnClick}
                    >
                      Import
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Table
                  className="table table-bordered table-hover  mt-3 siteuptime-table custome_filter"
                  columns={teamcol}
                  dataSource={teamlist}
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
}

export default SiteUpTimeMonitorList;
