/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import * as styles from "./styles";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_RANKING_BASE_URL,
  Status,
  notConfigured,
  tableLoadingData,
} from "../../utility/Constant";
import SpinContainer from "@semcore/ui/spin-container";
import ErrorScreen from "../shared/errorScreen";
import {
  onTabChange,
  resetExportCompetitors,
  resetRankingReport,
  setActiveCampaign,
  setActiveCampaignCategories,
  setActiveColumns,
  setActiveCompetitors,
  setActiveKeywordOption,
  setActiveRankOption,
  setCampaigns,
  setDatePickerOption,
  setDates,
  setExportCompetitors,
  setExportDownloadStatus,
  setExportKeywordDate,
  setExportKeywords,
  setExportTableCategories,
  setExportTableFilteredCategories,
  setExportTableFilteredTypes,
  setExportTableStatus,
  setExportTableTypes,
  setFormattedDate,
  setGraphSeries,
  setGraphStatus,
  setIsCustom,
  setKeywordDistributionTab,
  setKeywordTabsStatus,
  setKeywords,
  setOwnDomainName,
  setSelectedCategories,
  setSelectedDevice,
  setStatusRankingReport,
  toggleCampaignDropDown,
  toggleIsCompare,
} from "./rankingReportSlice";
import { Box, Flex } from "@semcore/ui/flex-box";
import ModuleHeader from "../shared/header/module";
import DropDownInput from "../shared/Dropdown/Input";
import {
  getDropdownFormat,
  getLocal,
  parseRankString,
} from "../../utility/helpers";
import NestedDropdown from "../shared/Dropdown/Nested";
import RankingDatePicker from "../shared/DatePicker/Ranking";
import KeyDistributionCard from "./keyDistributionCard";
import ComparisionCard from "./comparisionCard";
import OverallRankingDistribution from "./overallRankingDistribution";
import {
  getCompareGraphData,
  getCustomGraphData,
  getExportKeywords,
  getGraphData,
  getKeywords,
  getKeywordsFile,
  getRankingTabsData,
  getReportingCampaignsCategories,
} from "../../services/rankingReport";
import {
  dateToClosestWeek,
  formatDate,
  formatDateRange,
  getDisabledDates,
  getLargestDate,
  getSecondLargestDate,
  getSmallestDate,
  getWeeksBetweenRange,
} from "../../utility/dates";
import { addDays } from "date-fns";
import toast from "react-hot-toast";
import ExportTable from "./exportTable";
import CustomButton from "../shared/button";
import { is } from "date-fns/locale";
import AssetsImg from "../assets/images";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const RankingReportV3 = ({ isLocked, isCollapsed }) => {
  //local state
  const [isLoading, setIsLoading] = useState(true)
  //reduxValues
  const status = useSelector((state) => state.rankingReport.status);
  const keywordOptions = useSelector(
    (state) => state.rankingReport.keywordOptions
  );
  const rankOptions = useSelector((state) => state.rankingReport.rankOptions);
  const campaigns = useSelector((state) => state.rankingReport.campaigns);
  const keywordDistributionTabs = useSelector(
    (state) => state.rankingReport.keywordDistributionTabs
  );
  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const averagePosition = useSelector(
    (state) => state.rankingReport.averagePosition
  );
  const isGraphView = useSelector((state) => state.rankingReport.isGraphView);
  const selectedCampaign = useSelector(
    (state) => state.rankingReport.selectedCampaign
  );
  const selectedFormattedRange = useSelector(
    (state) => state.rankingReport.formattedRange
  );
  const selectedCategories = useSelector(
    (state) => state.rankingReport.selectedCategories
  );
  const filteredCategories = useSelector(
    (state) => state.rankingReport.exportTable.filteredCategories
  );
  const filteredTypes = useSelector(
    (state) => state.rankingReport.exportTable.filteredtypes
  );
  const exportTypes = useSelector(
    (state) => state.rankingReport.exportTable.types
  );
  const selectedTypes = useSelector(
    (state) =>
      state.rankingReport.keywordOptions.options[keywordOptions.active].label
  );
  const formattedRange = useSelector(
    (state) => state.rankingReport.formattedRange
  );
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const columnOptions = useSelector(
    (state) => state.rankingReport.exportTable.columnOptions
  );
  const competitors = useSelector(
    (state) => state.rankingReport.exportTable.competitors
  );
  const exportTableDate = useSelector(
    (state) => state.rankingReport.exportTable.date
  );
  const exportDownloadStatus = useSelector(
    (state) => state.rankingReport.exportTable.downloadStatus
  );
  const isCustom = useSelector((state) => state.rankingReport.isCustom);
  const selectedDevice = useSelector(
    (state) => state.rankingReport.selectedDevice
  );

  const isMonthly = useSelector(
    (state) => state.rankingReport.datePickerOptions.active === 2
  );
  const exportTableStatus = useSelector((state) => state.rankingReport.exportTable.status);
  const keywordsStautus = useSelector(
    (state) => state.rankingReport.keywords.status
  );
  const graphDataStatus = useSelector((state) => state.rankingReport.graphData.status);
  const projectId = getLocal("projectGId");

  //utility hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //useEffects
  useEffect(() => {
    fetchReport();
    return () => {
      dispatch(resetRankingReport());
    };
  }, []);

  useEffect(() => {
    fetchTabsData();
  }, [
    selectedCategories,
    keywordOptions.active,
    formattedRange,
    rankOptions.active,
  ]);

  useEffect(() => {
    if (isCustom) {
      fetchCustomGraphData();
    } else {
      fetchGraphData();
    }
    fetchKeywords();
  }, [
    selectedCategories,
    keywordOptions.active,
    keywordDistributionTabs.active,
    formattedRange,
    rankOptions.active,
    isCompare,
    graphData.active,
  ]);

  useEffect(() => {
    fetchExportKeywords();
  }, [selectedCategories, selectedCampaign, formattedRange, keywordOptions]);

  useEffect(() => {
    if(status === Status.LOADING || exportTableStatus === Status.LOADING || keywordsStautus === Status.LOADING || graphDataStatus === Status.LOADING){
      setIsLoading(true)  
    } else {
      setIsLoading(false)
    }
  },[status, exportTableStatus, keywordsStautus, graphDataStatus])

  //API Calls
  const fetchReport = async () => {
    try {
      const response = await getReportingCampaignsCategories(projectId);
      const last_received_rankings_date =
        response.data[0].last_received_rankings_date.filter(
          (date) =>
            new Date() > new Date(date) ||
            moment(new Date()).format("DD MMM YY") ===
              moment(new Date(date)).format("DD MMM YY")
        );
      if (
        response.status === "success"
        // last_received_rankings_date?.length > 0
      ) {
        const latestDate = getLargestDate(last_received_rankings_date);
        const secondAvailableDate = getSecondLargestDate(
          last_received_rankings_date
        );
        const startDateSelection = dateToClosestWeek(
          addDays(new Date(latestDate), 1)
        )[0];
        const endDateSelection = dateToClosestWeek(
          addDays(new Date(latestDate), 1)
        )[1];
        const startDateCompare = dateToClosestWeek(
          addDays(new Date(secondAvailableDate), 1)
        )[0];
        const endDateCompare = dateToClosestWeek(
          addDays(new Date(secondAvailableDate), 1)
        )[1];
        const formattedRange = formatDateRange(
          startDateSelection,
          endDateSelection
        );
        dispatch(setFormattedDate(formattedRange));
        const latestDatesForCalender = {
          selection: {
            startDate: startDateSelection,
            endDate: endDateSelection,
            key: "range1",
          },
          compare: {
            startDate: startDateCompare,
            endDate: endDateCompare,
            key: "range2",
          },
        };
        dispatch(setDates(latestDatesForCalender));
        const formatedData = formatHeaderDropDownData(response.data);
        dispatch(setCampaigns(formatedData));
        dispatch(setStatusRankingReport(Status.SUCCESS));
        dispatch(setSelectedCategories(response.data[0].unique_categories));
        dispatch(setActiveCampaign(response.data[0].config_id));
        dispatch(
          setSelectedDevice(
            response.data[0].device.toLowerCase() === "alternate"
              ? "mobile"
              : response.data[0].device.toLowerCase() === "desktop"
              ? "desktop"
              : "mobile"
          )
        );
        if (selectedCategories.length > 0) {
          fetchTabsData();
        }
      } else {
        dispatch(setStatusRankingReport(Status.ERROR));
      }
    } catch (error) {
      if (error?.response?.data?.error === notConfigured) {
        dispatch(setStatusRankingReport(Status.NOT_CONFIGURED));
      } else {
        dispatch(setStatusRankingReport(Status.ERROR));
      }
    }
  };

  const fetchTabsData = async () => {
    dispatch(setKeywordTabsStatus(Status.LOADING));
    if (selectedCategories.length > 0) {
      const dateRange = isCustom
        ? [
            selectedFormattedRange[0],
            selectedFormattedRange[selectedFormattedRange.length - 1],
          ]
        : selectedFormattedRange;
      const response = await getRankingTabsData(
        selectedCampaign,
        dateRange,
        rankOptions.options[rankOptions.active].value,
        selectedCategories,
        keywordOptions.options[keywordOptions.active].value,
        isCompare,
        isCustom,
        selectedFormattedRange
      );
      if (response.status === "success") {
        dispatch(
          setKeywordDistributionTab(
            isCompare || isCustom ? response.filterData : response.data
          )
        );
        dispatch(setKeywordTabsStatus(Status.SUCCESS));
      }
    }
  };

  const fetchCustomGraphData = async () => {
    try {
      dispatch(setGraphStatus(Status.LOADING));
      const response = await getCustomGraphData(
        selectedCampaign,
        selectedFormattedRange,
        rankOptions.options[rankOptions.active].value,
        selectedCategories,
        keywordOptions.options[keywordOptions.active].value,
        keywordDistributionTabs.options[keywordDistributionTabs.active].title
      );
      if (response.status === "success") {
        if (rankOptions.active === 2) {
          const series = [];
          const { startDates } = getWeeksBetweenRange(
            selectedDates.selection.startDate,
            selectedDates.selection.endDate,
            campaigns.options[campaigns.active].unavailableDates
          );
          const graphCategories = startDates;
          for (const [key, value] of Object.entries(
            response.week_data.week_1
          )) {
            const data = [];
            selectedFormattedRange.map((date, index) => {
              data.push(response.week_data[`week_${index + 1}`][key]);
            });
            let bucket = key;
            let range = bucket.split("-");
            if (range.length === 2 && range[0] === range[1]) {
              bucket = range[0];
            }
            series.push({
              name: `Rank ${bucket}`,
              data,
            });
          }
          dispatch(
            setGraphSeries({
              series,
              categories: graphCategories,
            })
          );
        } else {
          const { startDates } = getWeeksBetweenRange(
            selectedDates.selection.startDate,
            selectedDates.selection.endDate,
            campaigns.options[campaigns.active].unavailableDates
          );
          const graphCategories = startDates;
          const series = response.data[0].map((bucket, index) => {
            const bucketData = response.data.map((week) => week[index]);
            return {
              name: `Rank ${index + 1}`,
              data: bucketData,
            };
          });
          dispatch(
            setGraphSeries({
              series,
              categories: graphCategories,
            })
          );
        }
        dispatch(setGraphStatus(Status.SUCCESS));
      } else {
        dispatch(setStatusRankingReport(Status.ERROR));
      }
    } catch (error) {
      dispatch(setStatusRankingReport(Status.ERROR));
    }
  };

  const fetchGraphData = async () => {
    try {
      dispatch(setGraphStatus(Status.LOADING));
      let response;
      if (isCompare) {
        const response = await getCompareGraphData(
          selectedCampaign,
          selectedFormattedRange,
          rankOptions.options[rankOptions.active].value,
          selectedCategories,
          keywordOptions.options[keywordOptions.active].value,
          keywordDistributionTabs.options[keywordDistributionTabs.active].title
        );
        if (response.status === "success") {
          if (rankOptions.active === 2) {
            let firstWeek = [];
            let secondWeek = [];
            let graphCategories = [];
            let change = [];
            let progress = [];
            for (const [key, value] of Object.entries(response.first_week)) {
              firstWeek.push(value);
              graphCategories.push(`Rank ${key}`);
            }
            for (let i = 0; i < graphCategories.length; i++) {
              let rank = graphCategories[i];
              if (rank.startsWith("Rank")) {
                let parts = rank.split(" ");
                let range = parts[1].split("-");
                if (range.length === 2 && range[0] === range[1]) {
                  graphCategories[i] = parts[0] + " " + range[0];
                }
              }
            }
            for (const [key, value] of Object.entries(response.second_week)) {
              secondWeek.push(value);
            }
            for (const [key, value] of Object.entries(response.change_week)) {
              change.push(value);
            }
            for (const [key, value] of Object.entries(
              response.week_on_week_progress
            )) {
              progress.push(value);
            }
            const series = [
              {
                name: formatDate(selectedDates.selection.startDate),
                group: "startWeek",
                data: firstWeek,
              },
              {
                name: formatDate(selectedDates.compare.startDate),
                group: "endWeek",
                data: secondWeek,
              },
            ];
            dispatch(
              setGraphSeries({
                series,
                categories: graphCategories,
                change,
                progress,
              })
            );
            dispatch(setGraphStatus(Status.SUCCESS));
          } else {
            const series = [
              {
                name: formatDate(selectedDates.selection.startDate),
                group: "startWeek",
                data: response.first_week_data,
              },
              {
                name: formatDate(selectedDates.compare.startDate),
                group: "endWeek",
                data: response.second_week_data,
              },
            ];
            const graphCategories = response.first_week_data.map(
              (item, index) => `Rank ${index + 1}`
            );
            const change = response.change_in_two_week;
            const progress = response.week_on_week_progress;
            dispatch(
              setGraphSeries({
                series,
                categories: graphCategories,
                change,
                progress,
              })
            );
          }
          dispatch(setGraphStatus(Status.SUCCESS));
        } else {
          dispatch(setStatusRankingReport(Status.ERROR));
        }
      } else {
        if (selectedCategories.length > 0) {
          dispatch(setGraphStatus(Status.LOADING));
          response = await getGraphData(
            selectedCampaign,
            selectedFormattedRange,
            rankOptions.options[rankOptions.active].value,
            selectedCategories,
            keywordOptions.options[keywordOptions.active].value,
            keywordDistributionTabs.options[keywordDistributionTabs.active]
              .title
          );
          if (response.status === "success") {
            if (rankOptions.active === 2) {
              const series = [
                {
                  name: "Number of Keywords",
                  group: "startWeek",
                  data: response.series,
                },
              ];
              const buckets = response.bucket_list;
              for (let i = 0; i < buckets.length; i++) {
                let rank = buckets[i];
                if (rank.startsWith("Rank")) {
                  let parts = rank.split(" ");
                  let range = parts[1].split("-");
                  if (range.length === 2 && range[0] === range[1]) {
                    buckets[i] = parts[0] + " " + range[0];
                  }
                }
              }
              const graphCategories = buckets;
              dispatch(setGraphSeries({ series, categories: graphCategories }));
            } else {
              const series = [
                {
                  name: "Number of Keywords",
                  group: "startWeek",
                  data: response.data,
                },
              ];
              const graphCategories = response.data.map(
                (item, index) => `Rank ${index + 1}`
              );
              dispatch(setGraphSeries({ series, categories: graphCategories }));
            }
            dispatch(setGraphStatus(Status.SUCCESS));
          } else {
            dispatch(setStatusRankingReport(Status.ERROR));
            dispatch(setGraphStatus(Status.SUCCESS));
          }
        }
      }
    } catch (error) {
      dispatch(setStatusRankingReport(Status.ERROR));
      dispatch(setGraphStatus(Status.SUCCESS));
    }
  };

  const fetchKeywords = async () => {
    try {
      if (selectedCategories.length > 0) {
        dispatch(
          setKeywords({
            data: tableLoadingData,
            unique_categories: [],
            status: Status.LOADING,
          })
        );
        const selectedBucket =
          graphData.active === -1
            ? []
            : isCustom
            ? parseRankString(graphData.series[graphData.active].name)
            : parseRankString(graphData.xAxisCategories[graphData.active]);
        const response = await getKeywords(
          selectedCampaign,
          selectedFormattedRange,
          rankOptions.options[rankOptions.active].value,
          selectedCategories,
          keywordOptions.options[keywordOptions.active].value,
          keywordDistributionTabs.options[keywordDistributionTabs.active].title,
          selectedBucket,
          isCompare,
          isCustom
        );
        if (response.status === "success") {
          dispatch(
            setKeywords({
              data: response.data,
              unique_categories: response.unique_categories,
              status: Status.SUCCESS,
            })
          );
        } else {
          dispatch(setStatusRankingReport(Status.ERROR));
          dispatch(
            setKeywords({
              data: [],
              unique_categories: [],
              status: Status.SUCCESS,
            })
          );
        }
      }
    } catch (error) {
      dispatch(setStatusRankingReport(Status.ERROR));
      dispatch(
        setKeywords({
          data: [],
          unique_categories: [],
          status: Status.SUCCESS,
        })
      );
    }
  };

  const fetchExportKeywords = async () => {
    dispatch(resetExportCompetitors());
    try {
      if (selectedCategories.length > 0 && !isCompare && !isCustom) {
        dispatch(setExportTableStatus(Status.LOADING));
        const response = await getExportKeywords(
          selectedCampaign,
          selectedFormattedRange,
          selectedCategories,
          keywordOptions.options[keywordOptions.active].value
        );
        if (response.status === "success") {
          const competitorsDropdown = response.compeittors.map((competitor) => {
            return {
              label: competitor.competitor,
              value: competitor.id,
            };
          });
          const data = response.data.map((keyword) => {
            let competitors = {};
            keyword.competitors_rank_url.map((competitor) => {
              competitors[`${competitor.competitor_name}rank`] =
                competitor.rank;
              competitors[`${competitor.competitor_name}url`] = competitor.url;
            });
            return {
              keywords: keyword.keywords,
              category: keyword.keywordcategory,
              "Search Volume": keyword.searchvolume,
              Type: keyword.types,
              Location: keyword.location,
              myRank: keyword.own_domain_rank,
              myUrl: keyword.own_domain_url,
              ...competitors,
            };
          });
          dispatch(setExportCompetitors(competitorsDropdown));
          dispatch(setExportKeywords(data));
          dispatch(
            setExportKeywordDate(response.data[0].last_received_rankings_date)
          );
          dispatch(setExportTableCategories(response.unique_categories));
          dispatch(
            setExportTableTypes(
              selectedTypes === "Overall"
                ? ["Brand", "Non Brand"]
                : [selectedTypes]
            )
          );
          dispatch(setExportTableFilteredCategories([]));
          dispatch(setExportTableFilteredTypes([]));
          dispatch(setOwnDomainName(response.own_domain_name));
          dispatch(setExportTableStatus(Status.SUCCESS))
        } else {
          dispatch(setExportCompetitors([]));
          dispatch(setExportKeywords([]));
          dispatch(setExportTableFilteredCategories([]));
          dispatch(setExportTableFilteredTypes([]));
          dispatch(setExportTableStatus(Status.SUCCESS))
        }
      }
    } catch (error) {
      dispatch(setExportCompetitors([]));
      dispatch(setExportKeywords([]));
      dispatch(
        setExportTableTypes(
          selectedTypes === "Overall" ? ["Brand", "Non Brand"] : [selectedTypes]
        )
      );
      dispatch(setExportTableFilteredCategories([]));
      dispatch(setExportTableFilteredTypes([]));
      dispatch(setExportTableStatus(Status.SUCCESS))
    }
  };

  const exportKeywords = async () => {
    try {
      dispatch(setExportDownloadStatus(Status.LOADING));
      const defaultColumns = ["keywordcategory"];
      const selectedColumns = columnOptions.options
        .map((column, index) =>
          columnOptions.active.includes(index) ? column.value : null
        )
        .filter((val) => val !== null);
      const finalColumns = [...defaultColumns, ...selectedColumns];
      const selectedCompetitors = competitors.options
        .map((competitor, index) =>
          competitors.active.includes(index) ? competitor.label : null
        )
        .filter((val) => val !== null);
      const types =
        !!filteredTypes &&
        filteredTypes?.length > 0 &&
        filteredTypes?.length < 2
          ? filteredTypes[0]
          : selectedTypes;
      const payload = {
        config_id: selectedCampaign,
        date_range: selectedFormattedRange,
        category:
          !!filteredCategories && filteredCategories?.length > 0
            ? filteredCategories
            : selectedCategories,
        types,
        name_of_columns: finalColumns,
        name_of_competitors: selectedCompetitors,
      };
      const config = {
        method: "POST",
        url: `${GET_RANKING_BASE_URL}/rankingsv2/export_rank_table_data/`,
        data: payload,
        responseType: "blob",
      };
      const response = await axios(config);
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition.match(/filename="(.+)"$/);
      let outputFilename = `Keywords.xlsx`;
      if (filenameMatch) {
        outputFilename = filenameMatch[1];
      }
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      dispatch(setExportDownloadStatus(Status.SUCCESS));
    } catch (error) {
      toast.error("Unable to Download File");
      dispatch(setExportDownloadStatus(Status.ERROR));
    }
  };

  //Data Formatting
  const formatHeaderDropDownData = (data) => {
    const campaigns = data.map((campaign, index) => {
      const last_received_rankings_date =
        campaign.last_received_rankings_date.filter((date) => {
          return (
            new Date() > new Date(date) ||
            moment(new Date()).format("DD MMM YY") ===
              moment(new Date(date)).format("DD MMM YY")
          );
        });
      return {
        label: campaign.campaignname,
        value: campaign.config_id,
        deviceType: campaign.device.toLowerCase(),
        frequency: campaign.frequency,
        availableData: last_received_rankings_date,
        minDate: getSmallestDate(last_received_rankings_date),
        maxDate: getLargestDate(last_received_rankings_date),
        unavailableDates: getDisabledDates(last_received_rankings_date),
        categories: {
          options: campaign.unique_categories.map((category) => {
            return { label: category, value: category };
          }),
          active:
            index === 0
              ? campaign.unique_categories.map((item, index) => index)
              : [],
          isOpen: false,
        },
      };
    });
    return { options: campaigns, active: 0 };
  };

  const handleCategoryChange = (
    activeCategories,
    activeCategoriesIndexes,
    activeCampaign,
    activeCampaignIndex
  ) => {
    const categoryArray = activeCategories.map((category) => category.value);
    dispatch(setExportTableFilteredCategories([]));
    dispatch(setExportTableFilteredTypes([]));
    dispatch(setDatePickerOption(1));
    dispatch(setIsCustom(false));
    dispatch(
      setActiveCampaignCategories({
        activeCampaignIndex,
        activeCategoriesIndexes,
      })
    );
    dispatch(toggleIsCompare(false));
    const availableDates = campaigns.options[activeCampaignIndex].availableData;
    const latestDate = getLargestDate(availableDates);
    const secondAvailableDate = getSecondLargestDate(availableDates);
    const startDateSelection = dateToClosestWeek(
      addDays(new Date(latestDate), 1)
    )[0];
    const endDateSelection = dateToClosestWeek(
      addDays(new Date(latestDate), 1)
    )[1];
    const startDateCompare = dateToClosestWeek(
      addDays(new Date(secondAvailableDate), 1)
    )[0];
    const endDateCompare = dateToClosestWeek(
      addDays(new Date(secondAvailableDate), 1)
    )[1];
    const formattedRange = formatDateRange(
      startDateSelection,
      endDateSelection
    );
    dispatch(setFormattedDate(formattedRange));
    const latestDatesForCalender = {
      selection: {
        startDate: startDateSelection,
        endDate: endDateSelection,
        key: "range1",
      },
      compare: {
        startDate: startDateCompare,
        endDate: endDateCompare,
        key: "range2",
      },
    };
    dispatch(setSelectedCategories(categoryArray));
    dispatch(setActiveCampaign(activeCampaign.value));
    dispatch(toggleCampaignDropDown(false));
    dispatch(setDates(latestDatesForCalender));
    dispatch(setActiveKeywordOption(0));
    dispatch(setStatusRankingReport(Status.SUCCESS));
  };

  //Renders
  if (status === Status.NOT_CONFIGURED) {
    return (
      <ErrorScreen
        title={"No Data found"}
        subtitle={"No campaigns are configured yet."}
        onRetry={() => navigate("/main/configuration")}
        buttonIcon={"settings"}
        icon={AssetsImg.ic_empty_table_icon}
        buttonText={"Go to Configuration"}
      />
    );
  }
  if (status === Status.ERROR) {
    return (
      <>
        <ErrorScreen
          title={"Something Went Wrong"}
          icon={AssetsImg.ic_empty_table_icon}
          buttonText={"Retry"}
          buttonIcon={"refresh-cw"}
          onRetry={fetchReport}
        />
      </>
    );
  }

  if (status === Status.SUCCESS) {
    return (
      <>
        <div
          css={[
            styles.headerContainer,
            styles.headerFixed,
            styles.wrapper(isCollapsed, isLocked),
          ]}
        >
          <ModuleHeader
            moduleName={"Ranking"}
            dropDown={keywordOptions}
            onDropDownSelect={(activeKeywordIndex) => {
              dispatch(setActiveKeywordOption(activeKeywordIndex));
            }}
            isFixed={true}
          >
            <div css={styles.headerLeft}>
              {campaigns.options.length > 0 && (
                <div css={styles.nestedDropdownContainer}>
                  <NestedDropdown
                    key={campaigns.active}
                    data={campaigns}
                    showSearchBar={true}
                    defaultValue={campaigns.options[campaigns.active]}
                    onChange={(
                      activeCategories,
                      activeCategoriesIndexes,
                      activeCampaign,
                      activeCampaignIndex
                    ) => {
                      handleCategoryChange(
                        activeCategories,
                        activeCategoriesIndexes,
                        activeCampaign,
                        activeCampaignIndex
                      );
                    }}
                    isVisible={campaigns.isOpen}
                    toggleVisible={(isVisible) =>
                      dispatch(toggleCampaignDropDown(isVisible))
                    }
                  />
                </div>
              )}
              <RankingDatePicker
                minDate={campaigns.options[campaigns.active].minDate}
                maxDate={campaigns.options[campaigns.active].maxDate}
                unavailableDates={
                  campaigns.options[campaigns.active].unavailableDates
                }
              />
              <div css={styles.deviceContainer}>
                <div css={styles.switchContainer}>
                  {(campaigns.options[campaigns.active].deviceType ===
                    "alternate" ||
                    campaigns.options[campaigns.active].deviceType ===
                      "mobile") && (
                    <div
                      css={styles.buttonTable(
                        selectedDevice === "mobile",
                        campaigns.options[campaigns.active].deviceType !==
                          "alternate"
                      )}
                      onClick={() => dispatch(setSelectedDevice("mobile"))}
                    >
                      <img
                        css={styles.mobileIcon(selectedDevice === "mobile")}
                        src={
                          selectedDevice === "mobile" &&
                          campaigns.options[campaigns.active].deviceType ===
                            "alternate"
                            ? AssetsImg.rankv2_mobile_white
                            : AssetsImg.rankv2_mobile_black
                        }
                        alt="table-icon"
                      />
                    </div>
                  )}
                  {(campaigns.options[campaigns.active].deviceType ===
                    "alternate" ||
                    campaigns.options[campaigns.active].deviceType ===
                      "desktop") && (
                    <div
                      css={styles.buttonGraph(
                        selectedDevice === "desktop",
                        campaigns.options[campaigns.active].deviceType !==
                          "alternate"
                      )}
                      onClick={() => dispatch(setSelectedDevice("desktop"))}
                    >
                      <img
                        css={styles.icon(selectedDevice === "desktop")}
                        src={
                          selectedDevice === "desktop" &&
                          campaigns.options[campaigns.active].deviceType ===
                            "alternate"
                            ? AssetsImg.rankv2_desktop_white
                            : AssetsImg.rankv2_desktop_black
                        }
                        alt="chart-icon"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModuleHeader>
        </div>
        <SpinContainer css={styles.content}>
          <div>
            <div css={styles.keyDistributionContainer}>
              <ModuleHeader
                moduleName={"Keyword Distribution"}
                dropDown={rankOptions}
                onDropDownSelect={(activeRank) =>
                  dispatch(setActiveRankOption(activeRank))
                }
                key={keywordOptions.active}
              />
              <div css={styles.keyDistributionTabs}>
                {keywordDistributionTabs?.options?.map((tab, index) => {
                  return (
                    <KeyDistributionCard
                      loading={keywordDistributionTabs.status == Status.LOADING}
                      title={tab.title}
                      avgPosition={tab.average_position}
                      count={tab.count}
                      totalKeywordCount={tab.total_keyword_count}
                      isActive={keywordDistributionTabs.active == index}
                      handleClick={() => {
                        dispatch(onTabChange(index));
                        if (!isCompare && tab.count === 0) {
                          dispatch(setGraphStatus(Status.EMPTY));
                        } else if (
                          isCompare &&
                          tab.compare.every((item) => item === 0)
                        ) {
                          dispatch(setGraphStatus(Status.EMPTY));
                        } else {
                          dispatch(setGraphStatus(Status.SUCCESS));
                        }
                      }}
                      isCompare={isCompare}
                      showTooltip={index > 0}
                      info={tab.info}
                      growth={tab.growth}
                      compareDates={[
                        formatDate(selectedDates?.selection?.startDate),
                        formatDate(selectedDates?.compare?.startDate),
                      ]}
                      comparison={tab.compare}
                      isCustom={isCustom}
                    />
                  );
                })}
              </div>
              {(isCompare || isCustom) && (
                <div css={styles.comparisionCardcontainer}>
                  <ComparisionCard
                    title={"Average Position"}
                    loading={keywordDistributionTabs.status == Status.LOADING}
                    compareDates={
                      isCustom
                        ? [
                            graphData.xAxisCategories[0],
                            graphData.xAxisCategories[
                              graphData.xAxisCategories.length - 1
                            ],
                          ]
                        : [
                            formatDate(selectedDates.selection.startDate),
                            formatDate(selectedDates.compare.startDate),
                          ]
                    }
                    growth={
                      keywordDistributionTabs.options[
                        keywordDistributionTabs.active
                      ].average_growth
                    }
                    comparision={
                      keywordDistributionTabs.options[
                        keywordDistributionTabs.active
                      ].average_position
                    }
                  />
                </div>
              )}
              <OverallRankingDistribution isGraphView={isGraphView} />
            </div>
            {!isCompare && !isCustom && (
              <div
                css={styles.exportTableContainer}
                key={`${selectedCategories}${selectedTypes}`}
              >
                <div css={styles.exportTableHeader}>
                  <ModuleHeader
                    moduleName={"Rankings for"}
                    subtitle={moment(
                      !!exportTableDate
                        ? exportTableDate
                        : selectedDates.selection.startDate
                    ).format(isMonthly ? "MMMM YYYY" : "DD MMMM YYYY")}
                  >
                    <div css={styles.headerLeft}>
                      {columnOptions.options.length > 0 && (
                        <div css={styles.selectDropDownContainer}>
                          <DropDownInput
                            addPopperStyles={styles.selectDropDownContainer}
                            data={columnOptions}
                            multiselect={true}
                            showSearchBar={true}
                            nestedTrigger={
                              columnOptions?.active?.length ==
                              columnOptions?.options.length
                                ? "All Columns"
                                : columnOptions?.options
                                    ?.filter((option, index) =>
                                      columnOptions?.active.includes(index)
                                    )
                                    ?.map((option) => option.label)
                                    ?.join(", ")
                            }
                            placeholder={"Select Columns"}
                            value={""}
                            // placement={"right-start"}
                            handleDropdownClick={(selected) => {
                              let selectedLabels = selected?.map(
                                (data) => data?.label
                              );
                              const selectedColumns = columnOptions?.options
                                ?.map((option, index) => {
                                  if (selectedLabels?.includes(option.label)) {
                                    return index;
                                  }
                                })
                                ?.filter((val) => val !== undefined);
                              if (selected.length > 0) {
                                dispatch(setActiveColumns(selectedColumns));
                              } else {
                                dispatch(setActiveColumns([]));
                              }
                            }}
                            defaultValue={columnOptions.active}
                            key={columnOptions.active}
                          />
                        </div>
                      )}
                      {competitors.options.length > 0 && (
                        <div
                          css={styles.selectDropDownContainer}
                          key={competitors}
                        >
                          <DropDownInput
                            addPopperStyles={styles.selectDropDownContainer}
                            data={competitors}
                            multiselect={true}
                            showSearchBar={true}
                            nestedTrigger={
                              competitors?.active?.length ==
                              competitors?.options.length
                                ? "All Competitors"
                                : `${competitors?.active.length} Competitors Selected`
                            }
                            placeholder={"Select Competitors"}
                            value={""}
                            // placement={"right-start"}
                            handleDropdownClick={(selected) => {
                              let selectedLabels = selected?.map(
                                (data) => data?.label
                              );
                              const selectedCompetitors = competitors?.options
                                ?.map((option, index) => {
                                  if (selectedLabels?.includes(option.label)) {
                                    return index;
                                  }
                                })
                                ?.filter((val) => val !== undefined);

                              if (selected.length > 0) {
                                dispatch(
                                  setActiveCompetitors(selectedCompetitors)
                                );
                              } else {
                                dispatch(setActiveCompetitors([]));
                              }
                            }}
                            defaultValue={competitors.active}
                            key={competitors.active}
                          />
                        </div>
                      )}
                      <CustomButton
                        text={"Export"}
                        leftIcon={"download"}
                        use={"primary"}
                        isDisable={exportDownloadStatus === Status.LOADING}
                        onPress={() => {
                          exportKeywords();
                        }}
                        isLoading={exportDownloadStatus === Status.LOADING}
                      />
                    </div>
                  </ModuleHeader>
                </div>
                <div>
                  <ExportTable />
                </div>
              </div>
            )}
          </div>
        </SpinContainer>
        {(exportDownloadStatus === Status.LOADING ||
          status === Status.LOADING || isLoading) && (
          <SpinContainer
            loading={true}
            background={"rgba(0,0,0,0.4)"}
            css={styles.exportLoader}
          ></SpinContainer>
        )}
      </>
    );
  }
};

export default RankingReportV3;
