import React from "react";
import { useState } from "react";
import APIManager from "../services/APIManager";
import { createCustomer } from "../utility/Constant";
import toast from "react-hot-toast";
import { authenticationType } from "../utility/Globals";

function EditUser() {
  const [filelist, setfilelist] = useState([]);
  const [Name, setName] = useState("");
  const [Client, setClient] = useState("");
  const [Project, setProject] = useState("");
  const [Customercode, setCustomercode] = useState("");
  const [CustomerEmailid, setCustomerEmailid] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [AuthenticationType, setAuthenticationType] = useState("0");

  function updateList() {
    var input = document.getElementById("myfile");
    var output = document.getElementById("fileList");
    var data = [];
    var children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children +=
        "<li id=filename" + i + ">" + input.files.item(i).name + '"</li>';
      data.push(input.files.item(i).name);
    }
    setfilelist(data);
    output.innerHTML = "<ul>" + children + "</ul>";
  }

  function addUser() {
    if (Name.trim() === "") {
      toast.error("Customer name is required!");
      return;
    }
    if (Customercode.trim() === "") {
      toast.error("Customer code is required!");
      return;
    }
    if (CustomerEmailid.trim() === "") {
      toast.error("Customer email is required!");
      return;
    }
    if (PhoneNumber.toString().trim() == "") {
      toast.error("Customer phone number is required!");
      return;
    }
    APIManager.postAPI(
      createCustomer,
      {
        clientID: localStorage.getItem("clientGId"),
        projectId: localStorage.getItem("projectGId"),
        organizationId: localStorage.getItem("organizationId"),
        customerName: Name,
        customerCode: Customercode,
        customerEmail: CustomerEmailid,
        AuthenticationType: AuthenticationType,
        PhoneNumber: PhoneNumber,
      },
      null,
      true
    ).then(() => {
      setName("");
      setCustomercode("");
      setCustomerEmailid("");
      setAuthenticationType("0");
      setPhoneNumber("");
      window.location.reload(false);
    });
  }

  function removeItem(event) {}
  return (
    <>
      <div className="row">
        <div class="row">
          <div class="col-sm-5 pad-lzero">
            <div class="main-title">Add User</div>
          </div>
          <div class="col-sm-7 add-new-btnw"></div>
        </div>
        <div class="common-wrapper">
          <div class="common-wcard">
            <div class="common-form-fields">
              <div class="add-user">
                <div class="form-wrappers">
                  <label>Customer Name</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={Name}
                    onChange={(text) => {
                      setName(text.target.value);
                    }}
                    placeholder=""
                  />
                </div>

                <div class="form-wrappers">
                  <label>Customer code</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={Customercode}
                    onChange={(text) => {
                      setCustomercode(text.target.value);
                    }}
                    placeholder=""
                  />
                </div>

                <div class="form-wrappers">
                  <label>Phone Number</label>
                  <label class="field-required"> * </label>
                  <br />
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      value={PhoneNumber}
                      required
                      onChange={(text) => {
                        setPhoneNumber(text.target.value);
                      }}
                      name=""
                      placeholder=""
                    />
                  </div>
                </div>

                <div class="form-wrappers">
                  <label>Customer Email id</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={CustomerEmailid}
                    onChange={(text) => {
                      setCustomerEmailid(text.target.value);
                    }}
                    placeholder=""
                  />
                </div>

                <div class="form-wrappers">
                  <label>Authentication Type</label>
                  <select
                    value={AuthenticationType}
                    onChange={(item) => {
                      setAuthenticationType(item.target.value);
                    }}
                  >
                    {authenticationType.map((i) => {
                      return <option value={i.value}>{i.label}</option>;
                    })}
                  </select>
                </div>

                <div id="fileList">
                  <ul>
                    {filelist &&
                      filelist.map((i, index) => {
                        return (
                          <li id={i}>
                            {i}{" "}
                            <i
                              class="fa fa-trash"
                              onClick={() => {
                                removeItem(i);
                              }}
                            ></i>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <ul class="form-action-wrapper">
            <button
              type="button"
              onClick={() => {
                setName("");
                setClient("");
                setProject("");
                setCustomercode("");
                setCustomerEmailid("");
                setAuthenticationType("");
                setPhoneNumber("");
              }}
              className="ol-btn"
            >
              Cancel
            </button>

            <li>
              <a
                onClick={() => {
                  addUser();
                }}
                class="btn btn-primary"
              >
                Save
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default EditUser;
