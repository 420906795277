import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import "antd/dist/antd.css";
import FormControl from "@mui/material/FormControl";
import { userAuth, userForgotPassword } from "../utility/Constant";
import { clearUserInformation, storeUserInformation } from "../utility/Globals";
import APIManager from "../services/APIManager";
import { toast } from "react-hot-toast";
import FeatherIcon from 'feather-icons-react';
import { Link, useLocation, useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate();
    const [str, setStr] = useState("");
    const configData = require("../config.json");
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [message1, setMessage1] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [passValid, setPassValid] = useState(false);
    const [userdepartment, setuserdepartment] = useState("");
    const [values, setValues] = React.useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    let query = useQuery();
    useEffect(() => {
        setStr(query.get("session"));
        if (query.get("session") == "expired") {
            setMessage("Token is expired.");
            clearUserInformation();
        }
    }, []);

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    function handleKeyPress(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            handleLogin();
        }
    }

    const inputDataValidation = () => {
        let isEmailInvalid = message1?.length ? true : false;
        let isPassInvalid = message?.length ? true : false;

        if (userId == "") {
            isEmailInvalid = true;
            setMessage1("Email is required");
        }
        if (password == "") {
            isPassInvalid = true;
            setMessage("Password is required");
        }
        if (userId?.length) {
            // var pattern = new RegExp(/\S[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
            var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let uId = userId;
            if (pattern?.test(uId) == false) {
                setMessage1("Please enter valid Email!");
                isEmailInvalid = true;
            }
        }

        if (password?.length && password?.length > 11) {
            isPassInvalid = true;
            setMessage("Please enter valid Password!");
        }
        if (isEmailInvalid || isPassInvalid) {
            return false;
        } else {
            return true;
        }
    };

    const responseGoogleSuccess = (response) => {
        setUserId(response.profileObj.email);
        userLogin(response.profileObj.email, true);
    };

    // Error Handler
    const responseGoogleError = (response) => { };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    function handleLogin() {
        if (inputDataValidation()) {
            userLogin(
                document.getElementById("email standard-adornment-weight").value,
                false
            );
        }
    }

    function userLogin(userId, isGoogle) {
        localStorage.setItem("is1stTime", true);
        let rowData;
        if (isGoogle) {
            rowData = { email: userId };
        } else {
            rowData = {
                email: document.getElementById("email standard-adornment-weight").value,
                password: document.getElementById("password").value,
            };
        }
        let UserEmailId = document.getElementById(
            "email standard-adornment-weight"
        ).value;
        localStorage.setItem("UserEmail", UserEmailId);

        setMessage("");
        setMessage1("");

        const params = new URLSearchParams(window.location.search);
        let rankingCampaignConfigurationId = params.get("rankingCampaignConfigurationId")
        let accept = params.get("accept");
        let userIdForCampaignStatusApproval = params.get("userId");

        APIManager.postAPI(userAuth, rowData, null, true).then((response) => {
            if (response && response.status == 200) {
                if (response.data.token) {
                    setUserId("");
                    setPassword("");
                    setMessage("");
                    setMessage1("");
                    setuserdepartment(response.data?.userDepartment)
                    localStorage.setItem("loggedInUserId", response.data?.userId);
                    localStorage.setItem("loggedInOrgId", response.data?.organizationId);
                    localStorage.setItem(
                        "clientGId",
                        response.data?.clientProjects[0]?.clientId
                    );
                    localStorage.setItem(
                        "projectGId",
                        response.data?.clientProjects[0]?.projects[0]?.projectId
                    );
                    storeUserInformation(response.data);
                    if (sessionStorage.getItem("requirementid") !== null) {
                        let requirementId = sessionStorage.getItem("requirementid");
                        navigate(`/content-main/editorContent/?requirementid=${requirementId}`,{state: { isEditor: true }});
                    }
                    else if (userIdForCampaignStatusApproval !== null) {
                        navigate("/campaign-status" +
                            "?rankingCampaignConfigurationId=" + rankingCampaignConfigurationId +
                            "&accept=" + accept + "&userId=" + userId);
                    }
                    else if (response.data.isForceChangePassword) {
                        navigate("/new-password-set");
                    }
                    else if (response.data?.userDepartment == "Content") {
                        localStorage.setItem("active", 0);
                        navigate("/content-main/DashboardContent");
                    }
                    else if (response.data?.userDepartment == "Freelancer") {
                        localStorage.setItem("active", 2);
                        navigate("/content-main/FreelancersRequirementDetails");
                    }
                    else {
                        navigate("/main/dashboard");
                    }
                }
            } else {
            }
        });
    }

    function forgotPassword(email) {
        if (email?.length) {
            var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            // var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
            if (pattern?.test(email) == false) {
                setMessage1("Please enter valid Email!");
                setMessage("");
            }

        }

        APIManager.postAPI(userForgotPassword + email, {}, null, true).then(
            (response) => {
                if (response && response.status === 200) {
                    toast.success('Check your Email!');
                    return;
                }
            }
        );
    }

    const [themee, setThemee] = useState("fa fa-moon");
    useEffect(() => { }, [themee]);
    function appThemeChange() {
        if (themee == "fa fa-moon") {
            localStorage.setItem("theme-variant", "dark");
            setThemee("fa fa-sun");
        } else {
            localStorage.setItem("theme-variant", "light");
            setThemee("fa fa-moon");
        }
        if (document.querySelector("body").classList.contains("theme-light")) {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
        } else {
            document.querySelector("body").classList.remove("theme-dark");
            document.querySelector("body").classList.add("theme-light");
        }
    }

    return (
        <>


            <div class="login-new">

                <div class="container-fluid px-0">
                    <div class="row no-gutters px-0 d-flex">
                        <div class="custom-col-7">
                            <div class="html-bg image-mobile">
                                <div className="html-image d-flex justify-content-center align-items-center h-100">
                                    <div className="main-relative-image position-relative text-center">
                                        <img class="main-img" src="./images/login/html/main.png" alt="#" />
                                        <div className="img-boxx box-1 d-flex align-items-top">
                                            <img src="./images/login/html/01.png" alt="#" />
                                            <span class="ms-3 mt-3">Custom Reports</span>
                                        </div>

                                        <div className="img-boxx box-2 d-flex align-items-top">
                                            <img src="./images/login/html/02.png" alt="#" />
                                            <span class="ms-3 mt-4">Advanced Rank Tracking</span>
                                        </div>
                                        <div className="img-boxx box-3 d-flex align-items-top">
                                            <img src="./images/login/html/03.png" alt="#" />
                                            <span class="ms-3 mt-5">400+ SEO Checkpoints</span>
                                        </div>
                                        <div className="img-boxx box-4 d-flex align-items-top">
                                            <img src="./images/login/html/04.png" alt="#" />
                                            <span class="ms-3 mt-5">Intelligent Alerts</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-col-5">
                            <div class="field-right">
                                <div className="wrap-class">
                                    <div className="login-title">
                                        <h2>Welcome to InfiGrowth</h2>
                                        <span>Organic Digital Growth Platform </span>
                                    </div>
                                    <form id="login-form">
                                        <div class="btn-google">
                                            <GoogleLogin
                                                clientId={configData.CLIENT_ID}
                                                redirectUri={configData.redirectUri}
                                                render={(renderProps) => (
                                                    <a
                                                        className="btn-outline"
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                    >
                                                        <img src="./images/login/google.png" alt="" />
                                                        <span>Log in with Google</span>
                                                    </a>
                                                )}
                                                buttonText="Login"
                                                onSuccess={responseGoogleSuccess}
                                                onFailure={responseGoogleError}
                                                cookiePolicy={"single_host_origin"}
                                            />
                                        </div>
                                        <div className="or-box">
                                            <img src="./images/login/line.png" alt="#" />
                                            <span>or</span>
                                            <img src="./images/login/line.png" alt="#" />
                                        </div>
                                        <FormControl
                                            class="custom-input"
                                            variant="standard">
                                            <div className="form-group">

                                
                                                <label for="email">Email</label>
                                                <input
                                                    className={
                                                        message1?.length
                                                            ? "form-control form-control-lg invalid-control"
                                                            : emailValid
                                                                ? "form-control form-control-lg valid-control"
                                                                : "form-control form-control-lg"
                                                    }
                                                    type="email"
                                                    id="email standard-adornment-weight"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={userId}
                                                    onChange={(text) => {
                                                        let userId = text.target.value;
                                                        setUserId(userId);
                                                        if (userId?.length) {
                                                        
                                                            var pattern = /^[a-zA-Z0-9_.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                            if (pattern?.test(userId) == false) {
                                                                setMessage1(
                                                                    message1
                                                                        ? "Please enter valid Email!"
                                                                        : ""
                                                                );
                                                            } else {
                                                                if (message1) {
                                                                    setEmailValid(true);
                                                                    setMessage1("");
                                                                }
                                                            }
                                                        } else {
                                                            setMessage1(message1 ? message1 : "");
                                                            setEmailValid(false);
                                                        }
                                                    }}
                                                    aria-describedby="standard-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                />
                                                <div
                                                    class="ms-2 pt-2"
                                                    style={{ color: "red" }}
                                                >
                                                    {message1}
                                                </div>
                                           
                                            </div>
                                        </FormControl>

                                        <FormControl
                                            class="custom-input mt-4"
                                            variant="outlined"
                                        >
                                       
                                            <label for="password">Password</label>
                                            <div className="po-relative-box">
                                                <input
                                                    className={
                                                        message?.length
                                                            ? "form-control form-control-lg invalid-control"
                                                            : passValid
                                                                ? "form-control form-control-lg valid-control"
                                                                : "form-control form-control-lg"
                                                    }
                                                    type={
                                                        !values.showPassword ? "password" : "text"
                                                    }
                                                    id="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={(text) => {
                                                        let userId = text.target.value;
                                                        setPassword(userId);
                                                        if (userId?.length) {
                                                            if (userId?.length > 11) {
                                                                setMessage(
                                                                    message
                                                                        ? "Please enter valid Password!"
                                                                        : ""
                                                                );
                                                            } else {
                                                                if (message) {
                                                                    setPassValid(true);
                                                                    setMessage("");
                                                                }
                                                            }
                                                        } else {
                                                            setMessage(message ? message : "");
                                                            setPassValid(false);
                                                        }
                                                    }}
                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                />
                                                <div
                                                    className={
                                                        message || passValid
                                                            ? "icon-btn-invalid"
                                                            : "icon-btn"
                                                    }
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {values.showPassword ? (
                                                        <FeatherIcon class="view-icon" icon="eye" />
                                                    ) : (
                                                        <FeatherIcon class="view-icon" icon="eye-off" />
                                                    )}
                                                </div> </div>
                                            <div class="ms-2 pt-2" style={{ color: "red" }}>
                                                {message}
                                            </div>
                                     
                                        </FormControl>

                                
                                        <div className="forgot-pass text-end">
                                            <Link
                                                className="d-inline-block"
                                                to={"/forgot-password"}
                                                state={userId}
                                            >
                                                Forgot password
                                            </Link>
                                        </div>
                                 
                                        <div className="btn-login mt-3">
                                            <a
                                                type="submit"
                                                class="btn-primary"
                                                onClick={() => {
                                                    handleLogin();
                                                }}
                                            >
                                                Login
                                            </a>
                                        </div>
                               
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
         
            </div>
        </>
    );
}

export default Home;
