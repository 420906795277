const NotFound = () => {
	return (
		<>
			{/* <div className='login'>
				<h1 className='text-center'>
					😰
					<br />
					You don't have permission to access this page. <br /> Kindly contact your administrator.
				</h1>
			</div> */}
			<div className="message text-center" style={{ left: '50%', top: '50%' }}>
				<h4>
					Access denied.  <br />You don't have permission to perform this action.
				</h4>
			</div>
		</>
	);
};

export default NotFound;
