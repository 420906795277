import React, { useEffect } from "react";
import { useState } from "react";
import APIManager from "../services/APIManager";
import {
  getProfile,
  GET_Server_URL,
  imageFileType,
  updateProfile,
} from "../utility/Constant";
import { DDMMyyyyFormateDateSet, isValidImageFile } from "../utility/Globals";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [designation, setdesignation] = useState("");
  const [inputimg, setinputimg] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectImageFile, setImageFile] = useState(null);
  const [images, setimages] = useState("");
  const [joinedOn, setJoinedOn] = useState("");
  const [name, setname] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [srSupervisor, setReportingManager] = useState("");
  const [resData, setResData] = useState(null);

  let navigate = useNavigate();
  useEffect(() => {
    getUserProfile();
  }, []);

  function getUserProfile() {
    let memberId = localStorage.getItem("userId");
    APIManager.getAPI(getProfile + memberId).then((response) => {
      if (response && response.status == 200) {
        setResData(response.data);
        setname(response.data.name);
        setdesignation(response.data.role?.name);
        setReportingManager(response.data.srSupervisor?.name);
        setJoinedOn(DDMMyyyyFormateDateSet(response.data.joinedOn));
        setphoneNumber(response.data.phoneNumber);
        setimages(response.data.images.fileURL);
      }
    });
  }

  function updateProfileData() {
    var bodyFormData = new FormData();
    for (var key in resData) {
      if (
        key == "role" ||
        key == "phoneNumber" ||
        key == "images.file" ||
        resData[key] == null
      ) {
      } else {
        bodyFormData.append(key, resData[key]);
      }
    }
    bodyFormData.append("phoneNumber", phoneNumber);
    if (selectImageFile != null) {
      bodyFormData.append("images.file", selectImageFile);
    }
    APIManager.putAPI(updateProfile, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          if (selectImageFile != null) {
            localStorage.setItem("userIcon", response?.data?.images?.fileURL);
          }
          navigate("/main/dashboard");
          window.location.reload(false);
        }
      }
    );
  }

  function addnewimg1(event) {
    let b = JSON.parse(JSON.stringify(inputimg));
    b.push({ imgsrc: URL.createObjectURL(event.target.files[0]) });
    setinputimg(b);
    let input = document.getElementById("fileMy1");
    let imagesFile = files;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImageFile(fileExtension)
    if (isvalid == false) {
      return false;
    }
    setimages("");
    setImageFile(input.files[0]);
    for (var i = 0; i < input.files.length; ++i) {
      imagesFile.push(input.files.item(i));
    }
    setFiles(imagesFile);
  }

  return (
    <>
      <div className="portlet px-4 py-4 profile-portlet">
        <div className="row">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <div className="profile-box position-relative">
                <div className="text-circle">
                  <div
                    className="avatar-display"
                  >
                    {" "}
                    {name}{" "}
                  </div>
                </div>
                {images ? (
                  <img
                    class="w-100"
                    src={GET_Server_URL + images}
                    className="profile-img"
                  />
                ) : (
                  inputimg.map((j) => {
                    return (
                      <>
                        <img
                          class="w-100"
                          src={j.imgsrc}
                          className="profile-img"
                        />
                      </>
                    );
                  })
                )}
                <button
                  class="file-btn"
                  onClick={() => {
                    document.getElementById("fileMy1").click();
                  }}
                >
                  <i class="fa-solid fa-square-pen" aria-hidden="true"></i>
                </button>
                <input
                  type="file"
                  inputProps={{ accept: imageFileType }}
                  accept={imageFileType}
                  id="fileMy1"
                  class="none"
                  multiple={false}
                  onChange={(e) => {
                    addnewimg1(e);
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-start">
              <label class="my-4 pt-2">Name</label>
            </div>
            <div className="col-md-10 profilevalue">
              <input
                className="w-100 my-4"
                type="text"
                value={name}
                disabled
                onChange={(text) => {
                  setname(text.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-start">
              <label class="my-4 pt-2">Role</label>
            </div>
            <div className="col-md-10 profilevalue">
              <input
                className="w-100 my-4"
                type="text"
                style={{ width: 400 + "px" }}
                value={designation}
                disabled
                onChange={(text) => {
                  setdesignation(text.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-start">
              <label class="my-4 pt-2">Reporting Manager</label>
            </div>
            <div className="col-md-10 profilevalue">
              <input
                className="w-100 my-4"
                type="text"
                style={{ width: 400 + "px" }}
                value={srSupervisor}
                disabled
                onChange={(text) => {
                  setReportingManager(text.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-start">
              <label class="my-4 pt-2">Joined On</label>
            </div>
            <div className="col-md-10 profilevalue">
              <input
                className="w-100 my-4"
                type="text"
                style={{ width: 400 + "px" }}
                value={joinedOn}
                disabled={joinedOn}
                onChange={(text) => {
                  setJoinedOn(text.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-start">
              <label class="my-4 pt-2">Contact No.</label>
            </div>
            <div className="col-md-10 profilevalue">
              <input
                className="w-100 my-4"
                type="text"
                style={{ width: 400 + "px" }}
                value={phoneNumber}
                onChange={(text) => {
                  setphoneNumber(text.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"> </div>

            <div className="col-md-10 mt-3 text-right ">
              <div
                class="btn btn-primary"
                onClick={() => {
                  updateProfileData();
                }}
              >
                Update
              </div>
              <button
                className="btn btn-outline-secondary ms-3"
                onClick={() => {
                  navigate("/main/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default Profile;
