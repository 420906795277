import { create } from 'zustand'

export const useTicketingStore = create((set, get) => ({

    // COMPONENT STATES-------------------------------

    drawerOpen: {
        open: false,
        drawerOpen: "createTicket",
        id: null
    },
    setDrawerOpen: (payload) => set({ drawerOpen: payload }),

    currentTab: 0,
    setCurrentTab: (payload) => set({ currentTab: payload }),

    table: {
        isFiltered: false,
        data: [],
        currentData: []
    },
    setTableData: (payload) => set({ table: payload }),

    table1: {
        isFiltered: false,
        data: [],
        currentData: []
    },
    setTableData1: (payload) => set({ table1: payload }),


    // TICKET DATA STATES----------------------------------

    ticket: {
        ticketId: "#0001",
        parentId: null,
        isSubTicket: false
    },
    setTicket: (payload) => set({ ticket: payload }),

    ticketDetails: {},
    setTicketDetails: (payload) => set({ ticketDetails: payload }),

    selectedTicket: "Universal",
    setSelectedTicket: (payload) => set({ selectedTicket: payload }),

    initialForm: {
        "clientId": "",
        "client": "",
        "project": "",
        "projectId": "",
        "subject": "",
        "description": "",
        "eta": "",
        "priorityId": "",
        "priority": "",
        "departmentId": "",
        "department": "",
        "member": "",
        "memberId": ""
    },
    ticketForm: {
        type: null,
        typeId: null,
        client: null,
        clientId: null
    },
    formEdit: false,
    setFormEdit: (payload) => set({ formEdit: payload }),
    setTicketForm: (payload) => set({ ticketForm: payload }),

    ticketFormInit: {},
    setTicketFormInit: (payload) => set({ ticketFormInit: payload }),

    checkFor: ["type", "subject", "eta", "priority"],
    setCheckFor: (type) => {
        switch (type) {
            case "Universal":
                set({ checkFor: ["type", "subject", "eta", "priority"] })
                break;
            case "AdHoc":
                set({ checkFor: ["type", "subject", "eta", "priority", "client", "project"] })
                break;
            case "Adhoc":
                set({ checkFor: ["type", "subject", "eta", "priority", "client", "project"] })
                break;
            case "Pitch Deck":
                set({ checkFor: ["type", "subject", "eta", "priority"] })
                break;
            default:
                set({ checkFor: ["type", "subject", "eta", "priority"] })
        }
    },
    ticketFormValidation: {
        client: ""
    },
    setTicketFormValidation: (payload) => set({ ticketFormValidation: payload }),

    // DATA STATES------------------------------------------

    ticketTypes: [],
    setTicketTypes: (payload) => set({ ticketTypes: payload }),

    priorityList: [],
    setPriorityList: (payload) => set({ priorityList: payload }),


    // increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    // removeAllBears: () => set({ bears: 0 }),
}))
