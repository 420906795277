import React from "react";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import { useState } from "react";
import {
  getRoiDashboardChart,
  getDashboardROIWidget,
} from "../../utility/Constant";
import {
  returnMagnitude,
  fixTwoPlaceDecimal,
  compareValues,
  toMonthName,
} from "../../utility/Globals";
import Chart from "react-apexcharts";

const Roidashboard = (props) => {
  const [selectedMonths, setselectedMonths] = useState("0");
  const [startDatesNew, setStartDatesNew] = useState("");
  const [comapreStartDatesNew, setComapreStartDatesNew] = useState("");
  const [monthList, setMonthList] = useState([])
  const [comapreMonthList, setComapreMonthList] = useState([])

  let monthList1 = []
  let comapreMonthList1 = []

  const moment = require("moment");

  useEffect(() => {
    localStorage.setItem("selectDataType", "ROI");
    getLastThreeMonths();
  }, []);

  useEffect(() => {
    let rowdata1 = {
      ProjectId: localStorage.getItem("projectGId"),
      widgetType: selectedMonths,
    };
    let rowdata = {
      ProjectId: localStorage.getItem("projectGId"),
      Type: selectDataType,
      widgetType: selectedMonths,
    };
    getChartDataNew(rowdata);
    getRoidashboard(rowdata1);
    getMonthwiseDates(selectedMonths);
  }, [props?.dataParentToChild]);

  useEffect(() => {
    setIsChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  const [selectDataType, setSelectdataType] = useState("ROI");
  const [isChart, setIsChart] = useState(0);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }

  const [series1, setSeries1] = useState([]);
  const [CPC, setCPC] = useState(0);
  const [CPL, setCPL] = useState(0);
  const [CPS, setCPS] = useState(0);
  const [ROI, setROI] = useState(0);
  const [CPCComp, setCPCComp] = useState(0);
  const [CPLComp, setCPLComp] = useState(0);
  const [CPSComp, setCPSComp] = useState(0);
  const [ROIComp, setROIComp] = useState(0);
  const [diffCPC, setdiffCPC] = useState(0);
  const [diffCPL, setdiffCPL] = useState(0);
  const [diffCPS, setdiffCPS] = useState(0);
  const [diffROI, setdiffROI] = useState(0);
  const [CPCComparechk, setCPCComparechk] = useState(0);
  const [CPSComparechk, setCPSComparechk] = useState(0);
  const [CPLComparechk, setCPLComparechk] = useState(0);
  const [ROIComparechk, setROIComparechk] = useState(0);
  const [options1, setObject1] = useState({
    title: {
      text: "",
      align: "left",
    },
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
      title: {
        text: "Day",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      logBase: 0,
      tickAmount: 9,
      min: 0,
    },
  });

  function getroiColorClassName(i) {
    if (i == 2) {
      return "avg-val";
    } else if (i == 1) {
      return "avg-val-positive";
    } else if (i == 0) {
      return "avg-val-negative";
    }
  }

  function getRoidashboard(rowdata1) {
    APIManager.postAPI(getRoiDashboardChart, rowdata1, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          let cpc = 0;
          let cpl = 0;
          let cps = 0;
          let roi = 0;
          let cpcCompare = 0;
          let cplCompare = 0;
          let cpsCompare = 0;
          let roiCompare = 0;
          let cpc1 = 0;
          let cpl1 = 0;
          let cps1 = 0;
          let roi1 = 0;

          response?.data?.table?.table.map((item) => {
            cpc1 += item?.cpc;
          });
          response?.data?.table?.table1.map((item) => {
            cps1 += item?.cps;
          });
          response?.data?.table?.table2.map((item) => {
            cpl1 += item?.cpl;
          });
          response?.data?.table?.table3.map((item) => {
            roi1 += item?.roi;
          });

          cpc = cpc1 != 0 ? cpc1 / (response?.data?.table?.table.length != 0 ? response?.data?.table?.table.length : 1) : 0;
          cps = cps1 != 0 ? cps1 / (response?.data?.table?.table1.length != 0 ? response?.data?.table?.table1.length : 1) : 0;
          cpl = cpl1 != 0 ? cpl1 / (response?.data?.table?.table2.length != 0 ? response?.data?.table?.table2.length : 1) : 0;
          roi = roi1 != 0 ? roi1 / (response?.data?.table?.table3.length != 0 ? response?.data?.table?.table3.length : 1) : 0;

          setCPC(fixTwoPlaceDecimal(cpc));
          setCPL(fixTwoPlaceDecimal(cpl));
          setCPS(fixTwoPlaceDecimal(cps));
          setROI(fixTwoPlaceDecimal(roi));

          let cpcCompare1 = 0;
          let cplCompare1 = 0;
          let cpsCompare1 = 0;
          let roiCompare1 = 0;

          response?.data?.table1?.table.map((item) => {
            cpcCompare1 += item?.cpc;
          });
          response?.data?.table1?.table1.map((item) => {
            cpsCompare1 += item?.cps;
          });
          response?.data?.table1?.table2.map((item) => {
            cplCompare1 += item?.cpl;
          });
          response?.data?.table1?.table3.map((item) => {
            roiCompare1 += item?.roi;
          });

          cpcCompare = cpcCompare1 != 0 ? cpcCompare1 / (response?.data?.table1?.table.length != 0 ? response?.data?.table1?.table.length : 1) : 0;
          cpsCompare = cpsCompare1 != 0 ? cpsCompare1 / (response?.data?.table1?.table1.length != 0 ? response?.data?.table1?.table1.length : 1) : 0;
          cplCompare = cplCompare1 != 0 ? cplCompare1 / (response?.data?.table1?.table2.length != 0 ? response?.data?.table1?.table2.length : 1) : 0;
          roiCompare = roiCompare1 != 0 ? roiCompare1 / (response?.data?.table1?.table3.length != 0 ? response?.data?.table1?.table3.length : 1) : 0;

          setCPCComp(fixTwoPlaceDecimal(cpcCompare));
          setCPLComp(fixTwoPlaceDecimal(cplCompare));
          setCPSComp(fixTwoPlaceDecimal(cpsCompare));
          setROIComp(fixTwoPlaceDecimal(roiCompare));

          setCPCComparechk(compareValues(cpc, cpcCompare));
          setCPSComparechk(compareValues(cps, cpsCompare));
          setCPLComparechk(compareValues(cpl, cplCompare));
          setROIComparechk(compareValues(roi, roiCompare));

          let diffRoi =
            roi < roiCompare
              ? (100 * (roiCompare - roi)) / roiCompare
              : (100 * (roi - roiCompare)) / roi;

          let diffCpc =
            cpc < cpcCompare
              ? (100 * (cpcCompare - cpc)) / cpcCompare
              : (100 * (cpc - cpcCompare)) / cpc;

          let diffCps =
            cps < cpsCompare
              ? (100 * (cpsCompare - cps)) / cpsCompare
              : (100 * (cps - cpsCompare)) / cps;

          let diffCpl =
            cpl < cplCompare
              ? (100 * (cplCompare - cpl)) / cplCompare
              : (100 * (cpl - cplCompare)) / cpl;

          setdiffCPC(diffCpc.toFixed(2));
          setdiffCPL(diffCpl.toFixed(2));
          setdiffCPS(diffCps.toFixed(2));
          setdiffROI(diffRoi?.toFixed(2));
        }
      }
    );
  }
  
  function getMonthwiseDates(Months) {
    var date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    if (Months === "0") {
      let newMonth = month - 2;
      let sDate = moment([year, newMonth]);
      let sDate1 = moment([year, newMonth - 2]);
      let secondDate = moment([year, date.getMonth()]);
    } else if (Months === "1") {
      let newMonth = month - 5;
      const currentDate = new Date();
      currentDate.setMonth(newMonth);
      currentDate.setDate(1);
      let secondDate = moment([year, date.getMonth()]);
    } else if (Months === "2") {
      let sDate = moment([year - 1, date.getMonth()]);
      let secondDate = moment([year, date.getMonth()]);
    }
  }

  function getChartDataNew(rowChartData) {
    APIManager.postAPI(getDashboardROIWidget, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let monthsListVar = [];
          let monthsListCompareVar = [];
          monthsListVar = monthList1.length > 0 ? monthList1 : monthList;
          monthsListCompareVar = comapreMonthList1.length > 0 ? comapreMonthList1 : comapreMonthList;

          let chartOBJ = [];
          let cpcList = [];
          let cplList = [];
          let cpsList = [];
          let rolList = [];
          let cpc = 0;
          let cpl = 0;
          let cps = 0;
          let roi = 0;
          let cpcCompare = 0;
          let cplCompare = 0;
          let cpsCompare = 0;
          let roiCompare = 0;
          let cpcComapreList = [];
          let cplComapreList = [];
          let cpsComapreList = [];
          let rolComapreList = [];
          response?.data?.table.map((item) => {
            cpcList.push(item?.cpc);
            cplList.push(item?.cpl);
            cpsList.push(item?.cps);
            rolList.push(item?.roi);
            cpc += item?.cpc;
            cpl += item?.cpl;
            cps += item?.cps;
            roi += item?.roi;
          });

          response?.data?.table1.map((item) => {
            cpcComapreList.push(item?.cpc);
            cplComapreList.push(item?.cpl);
            cpsComapreList.push(item?.cps);
            rolComapreList.push(item?.roi);
            cpcCompare += item?.cpc;
            cplCompare += item?.cpl;
            cpsCompare += item?.cps;
            roiCompare += item?.roi;
          });

          if (rowChartData.Type == "ROI") {
            chartOBJ.push({
              name: "ROI",
              data: rolList,
              color: "#91E395",
            });
            chartOBJ.push({
              name: "ROI",
              data: rolComapreList,
              color: "#5EB1F3",
            });
          }
          if (rowChartData.Type == "CPC") {
            chartOBJ.push({
              name: "CPC",
              data: cpcList,
              color: "#91E395",
            });
            chartOBJ.push({
              name: "CPC",
              data: cpcComapreList,
              color: "#5EB1F3",
            });
          }
          if (rowChartData.Type == "CPS") {
            chartOBJ.push({
              name: "CPS",
              data: cpsList,
              color: "#91E395",
            });

            chartOBJ.push({
              name: "CPS",
              data: cpsComapreList,
              color: "#5EB1F3",
            });
          }
          if (rowChartData.Type == "CPL") {
            chartOBJ.push({
              name: "CPL",
              data: cplList,
              color: "#91E395",
            });

            chartOBJ.push({
              name: "CPL",
              data: cplComapreList,
              color: "#5EB1F3",
            });
          }

          setSeries1(chartOBJ);
          setObject1({
            title: {
              text: "",
              align: "left",
            },
            chart: {
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  customIcons: [],
                },
              },
            },
            dataLabels: {
              formatter: () => {
                return "";
              },
              enable: false,
            },
            legend: {
              show: false,
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              title: {
                text: "Month",
                style: {
                  color: "#000",
                },
              },
            },
            markers: {
              size: 5,
            },
            yaxis: {
              logBase: 0,
              tickAmount: 9,
              min: 0,
              max: (max) => {
                return max * 0.3 + max;
              },
              labels: {
                formatter: function (val) {
                  return returnMagnitude(val);
                },
              },
            },
            tooltip: {
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let data = "";
                let month1 = toMonthName(monthsListVar.length > 0
                  ? monthsListVar[dataPointIndex]
                  : "", "Months")
                let month2 = toMonthName(monthsListCompareVar.length > 0
                  ? monthsListCompareVar[dataPointIndex]
                  : "", "Months")

                data += `<span> <span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span>${month1} : 
                ${(series[0][dataPointIndex] != undefined ? series[0][dataPointIndex] : 0)} </span><br/>`;

                data += `<span><span class="apexcharts-legend-marker dash-roi-tooltip" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px; "></span> ${month2} : 
                ${(series[1][dataPointIndex] != undefined ? series[1][dataPointIndex] : 0)} </span><br/>`;

                return `<div class="card">
                      <div class="card-body p-10">
                        ${data}
                      </div>
                    </div>`;
              },
              x: {
                show: false,
              },              
            },
          });
        }
      }
    );
  }

  function getDateRangeValues(months) {
    if (months == 0) {
      getLastThreeMonths();
    } else if (months == 1) {
      getLastSixMonths();
    } else if (months == 2) {
      getLastOneYear();
    }
  }
  function getLastThreeMonths() {
    let months = [];
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    let month1;
    let monthNumList = []
    // Get last three months from the current month
    for (let i = 2; i >= 0; i--) {
      month1 = currentMonth - i;
      let year = month1 >= 0 ? currentYear : currentYear - 1;
      let monthName = getMonthName(month1);
      months.push(`${monthName}${year}`);
      let month = `${monthName}${year}`;
      const date = new Date(`${month.substring(0, 3)} 1, 2000`);
      const monthNumber = date.getMonth() + 1;
      monthNumList.push(monthNumber)
    }

    // Get three months preceding the last three months
    let month2 = month1 - 3;
    let previousYear = month2 >= 0 ? currentYear : currentYear - 1;
    for (let i = 11; i >= 9; i--) {
      let year = month2 < 0 ? previousYear - 1 : previousYear;
      let monthName = getMonthName(month2);
      months.push(`${monthName}${year}`);
      let month = `${monthName}${year}`;
      const date = new Date(`${month.substring(0, 3)} 1, 2000`);
      const monthNumber = date.getMonth() + 1;
      monthNumList.push(monthNumber)
      month2--;
    }
    for (let i = 0; i <= 2; i++) {
      monthList1.push(monthNumList[i])
    }
    for (let i = 5; i >= 3; i--) {
      comapreMonthList1.push(monthNumList[i])
    }
    setMonthList(monthList1)
    setComapreMonthList(comapreMonthList1)
    setStartDatesNew(months[0] + "-" + months[2]);
    setComapreStartDatesNew(months[5] + "-" + months[3]);
  }

  function getLastSixMonths() {
    let months = [];
    let currentDate = new Date();
    let monthNumList = []
    for (let i = 0; i < 12; i++) {
      let month = currentDate.getMonth() - i;
      let year =
        currentDate.getFullYear() +
        Math.floor((currentDate.getMonth() - i) / 12);
      let monthName = getMonthName(month);
      months.push(`${monthName} ${year}`);
      let monthNew = `${monthName}${year}`;
      const date = new Date(`${monthNew.substring(0, 3)} 1, 2000`);
      const monthNumber = date.getMonth() + 1;
      monthNumList.push(monthNumber)
    }
    for (let i = 5; i >= 0; i--) {
      monthList1.push(monthNumList[i])
    }
    for (let i = 11; i >= 6; i--) {
      comapreMonthList1.push(monthNumList[i])
    }
    setMonthList(monthList1)
    setComapreMonthList(comapreMonthList1)
    setStartDatesNew(months[5] + "-" + months[0]);
    setComapreStartDatesNew(months[11] + "-" + months[6]);
  }

  function getLastOneYear() {
    let months = [];
    let currentDate = new Date();
    let monthNumList = []

    for (let i = 0; i < 24; i++) {
      let month = currentDate.getMonth() - i;
      let value = Math.floor((currentDate.getMonth() - i) / 12);
      let year = currentDate.getFullYear() + value;
      let monthName = getMonthName(month);
      months.push(`${monthName} ${year}`);
      let monthNew = `${monthName}${year}`;
      const date = new Date(`${monthNew.substring(0, 3)} 1, 2000`);
      const monthNumber = date.getMonth() + 1;
      monthNumList.push(monthNumber)
    }
    for (let i = 11; i >= 0; i--) {
      monthList1.push(monthNumList[i])
    }
    for (let i = 23; i >= 12; i--) {
      comapreMonthList1.push(monthNumList[i])
    }
    setMonthList(monthList1)
    setComapreMonthList(comapreMonthList1)
    setStartDatesNew(months[11] + "-" + months[0]);
    setComapreStartDatesNew(months[23] + "-" + months[12]);
  }

  function getMonthName(month) {
    let date = new Date(2000, month, 1);
    return date.toLocaleString("default", { month: "short" });
  }

  return (
    <>
      <div className="title pb-4 return_page_select">
        <div className="d-flex justify-content-between flex-wrap align-items-center roi-responsive ">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Return on Investment</h4>
          </div>
          <div className="d-flex flex-sm-nowrap flex-wrap  align-items-center roi-btn-tab">
            <div className=" mt-sm-0 mt-3 pr-3">
              <select
                className="form-select roi-lmd"
                id="months-select"
                value={selectedMonths}
                onChange={(e) => {
                  let ghostSelect = document.createElement("select");
                  let select = document.getElementById("months-select");
                  let x = select.options[select.selectedIndex].text;
                  let ghostOption = document.createElement("option");
                  ghostOption.setAttribute("value", x);
                  let t = document.createTextNode(x);
                  ghostOption.appendChild(t);
                  ghostSelect.appendChild(ghostOption);
                  window.document.body.appendChild(ghostSelect);
                  select.style.width = ghostSelect.offsetWidth + "px";
                  window.document.body.removeChild(ghostSelect);
                  getDateRangeValues(e.target.value);
                  setselectedMonths(e.target.value);
                  getMonthwiseDates(e.target.value);
                  let rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    Type: selectDataType,
                    widgetType: e.target.value,
                  };
                  getChartDataNew(rowdata);
                  let rowdata1 = {
                    ProjectId: localStorage.getItem("projectGId"),
                    widgetType: e.target.value,
                  };
                  getRoidashboard(rowdata1);
                }}
              >
                <option value="0">Last 3 Months</option>
                <option value="1">Last 6 Months</option>
                <option value="2">Last 1 Year</option>
              </select>
            </div>
            <div className="mt-sm-0 mt-3 pr-3">
              {isChart == 0 ? (
                <select
                  className="form-select"
                  value={selectDataType}
                  onChange={(e) => {
                    setSelectdataType(e.target.value);
                    localStorage.setItem("selectDataType", e.target.value);
                    let rowdata = {
                      ProjectId: localStorage.getItem("projectGId"),
                      Type: e.target.value,
                      widgetType: selectedMonths,
                    };
                    getChartDataNew(rowdata, e.target.value);
                  }}
                >
                  <option value="ROI">ROI</option>
                  <option value="CPC">CPC</option>
                  <option value="CPS">CPS</option>
                  <option value="CPL">CPL</option>
                </select>
              ) : (
                <></>
              )}
            </div>
            <div className="btn-box mt-sm-0 mt-3">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" my-3">
        {isChart == 0 ? (
          <>
            {" "}
            <Chart
              options={options1}
              series={series1}
              type="line"
              height="300"
            />
            <div className="legend-box d-flex flex-wrap align-items-center justify-content-center w-100">
              <div className="d-flex align-items-center pr-5">
                <div className="legend-dot blue"></div>
                <div className="ml-2">{comapreStartDatesNew}</div>
              </div>
              <div className="d-flex align-items-center pr-5">
                <div className="legend-dot"></div>
                <div className="ml-2">{startDatesNew}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-sm-right text-left backlink-daterange">
              Date Range : {startDatesNew} - {comapreStartDatesNew}
            </div>

            <div className="row p-0 roidashboard-table rio_dashboard_table">
              <div className="col-md-3 col-xl-3 col-lg-6 col-sm-12 my-2">
                <div className=" roi">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>ROI</h4>
                    <div className="grren-domain text-right">
                      {ROIComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>

                  <h3 className={getroiColorClassName(ROIComparechk)}>
                    {diffROI > 0 ? diffROI : 0}%
                  </h3>

                  <div className="row common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 pl-0 pr-2">
                        <p>{ROIComp}</p>
                        <span>{comapreStartDatesNew}</span>
                      </div>

                      <div className="col-md-6 roi-total-compare pl-2 pr-0">
                        <p>{ROI}</p>
                        <span>{startDatesNew}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-6 col-sm-12 my-2">
                <div className=" roi">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPL</h4>
                    <div className="grren-domain text-right">
                      {CPLComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>

                  <h3 className={getroiColorClassName(CPLComparechk)}>
                    {diffCPL > 0 ? diffCPL : 0}%
                  </h3>

                  <div className="row common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 pl-0 pr-2">
                        <p>{CPLComp}</p>
                        <span>{comapreStartDatesNew}</span>
                      </div>

                      <div className="col-md-6 roi-total-compare pl-2 pr-0">
                        <p>{CPL}</p>
                        <span>{startDatesNew}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-6 col-sm-12 my-2">
                <div className=" roi">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPS</h4>
                    <div className="grren-domain text-right">
                      {CPSComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getroiColorClassName(CPSComparechk)}>
                    {diffCPS > 0 ? diffCPS : 0}%
                  </h3>

                  <div className="row common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 pl-0 pr-2">
                        <p>{CPSComp}</p>
                        <span>{comapreStartDatesNew}</span>
                      </div>

                      <div className="col-md-6 roi-total-compare pl-2 pr-0">
                        <p>{CPS}</p>
                        <span>{startDatesNew}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-xl-3 col-lg-6 col-sm-12 my-2">
                <div className=" roi">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPC</h4>
                    <div className="grren-domain text-right">
                      <div className="grren-domain text-right">
                        {CPCComparechk != 0 ? (
                          <>
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                              <g clip-path="url(#clip0_4_4546)">
                                <path
                                  d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.667 12H26.667V17"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4546">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(7.5 7)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="17"
                                transform="matrix(1 0 0 -1 17 17)"
                                fill="#FFC0C2"
                              />
                              <g clip-path="url(#clip0_4_4443)">
                                <path
                                  d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.167 22H26.167V17"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4443">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 7 27)"
                                  />
                                </clipPath>{" "}
                              </defs>
                            </svg>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className={getroiColorClassName(CPCComparechk)}>
                    {diffCPC > 0 ? diffCPC : 0}%
                  </h3>

                  <div className="row common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 pl-0 pr-2">
                        <p>{CPCComp}</p>
                        <span>{comapreStartDatesNew}</span>
                      </div>

                      <div className="col-md-6 roi-total-compare pl-2 pr-0">
                        <p>{CPC}</p>
                        <span>{startDatesNew}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Roidashboard;
