import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import ModelScopeOfWork from "./components/ModelScopeOfWork";

function TestPage() {

  const [modelOpen, setModelOpen] = useState(false);
  useEffect(() => {
  }, [modelOpen]);

  return (
    <>
      <div className="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">test page</h3>
        </div>

        <div onClick={() => {
          setModelOpen(true)
        }}>
          <h3 class="portlet-title main-title">model open</h3>
        </div>

        <div class="portlet-header portlet-header-bordered">

          <ModelScopeOfWork modelShow={modelOpen} modelCloseClick={() => {
            setModelOpen(false)
          }} />
        </div>

      </div>

    </>
  );
}

export default TestPage;
