/** @jsxImportSource @emotion/react */
import { Link, useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import { Calendar } from 'react-date-range';
import Popper from '@semcore/ui/popper';
import CustomButton from "../../../shared/button";
import Input from "@semcore/ui/input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { addDays } from "date-fns";
import { useEffect } from "react";
import { parseISO } from 'date-fns';
import moment from "moment";

const CustomDatePicker = ({ customTrigger,
    containerStyles,
    addInputStyles,
    placement = "bottom-start",
    placeholder = "DD/MM/YYYY",
    wMin = "10rem",
    value,
    handleDateApply,
    disabled,
    minDate
}) => {
    const [date, setDate] = useState(value ?? new Date())
    // const [value, setValue] = useState("")

    const [isVisible, setIsVisible] = useState(false)

    return (
        <div css={styles.mainContainer(containerStyles)}>
            <Popper placement={placement} visible={isVisible} onVisibleChange={() => { setIsVisible(!isVisible) }}>
                <Popper.Trigger>
                    {/* {customTrigger && customTrigger} */}
                    <div css={styles.inputStyles(addInputStyles)}>
                        <Input disabled={disabled} hMin={"33px"} w={"100%"} wMin={wMin}>
                            <Input.Value
                                readOnly={true}
                                placeholder={placeholder}
                                value={value ? moment(value).format("DD/MM/YYYY") : ""}
                            // onChange={(v) => { }}
                            />
                            <Input.Addon><FeatherIcon icon={"calendar"} size={16} color={Colors.black} /></Input.Addon>
                        </Input>
                    </div>
                    {/* <div>yo</div> */}
                </Popper.Trigger>
                <Popper.Popper zIndex={30000}>
                    <div className="shadow mb-3">
                        <div css={styles.calendarStyles}>
                            <Calendar
                                date={date}
                                minDate={minDate}
                                onChange={(e) => { setDate(new Date(e)) }}
                                showDateDisplay={false}
                            // scroll={{ enabled: true }}
                            />
                        </div>
                        <div
                            onClick={() => setIsVisible(!isVisible)}
                            css={styles.applyContainer}
                        >
                            <CustomButton
                                isDisable={!date}
                                text={"Apply"}
                                use={"primary"}
                                onPress={() => {
                                    handleDateApply(date)
                                }} />
                        </div>
                    </div>
                </Popper.Popper>
            </Popper>
        </div>
    )
}

export default CustomDatePicker;