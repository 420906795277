import React, { useState, useRef, useEffect } from "react";
import {
    downloadPDFfile,
    getDaysInMonth,
    mmDDyyyyFormateDateSet,
    setMonths,
    convertDate,
    exportToEXCEL,
    getStatusCms,
    DDMMMMYYYYFormatter
} from "../../utility/Globals";
import { Card } from "react-bootstrap";
import ProjectOverview from "./ProjectOverview";
import WriterOverview from "./WriterOverview";
import EditorOverview from "./EditorOverview";
import { Calendar } from "react-multi-date-picker";
import PocOverview from "./PocOverview";
import {
    getProjectByOrgId,
    getProjectOverview,
    dashboardReportCMS
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../../utility/Loading";
import FeatherIcon from 'feather-icons-react';
import { default as ReactSelect } from "react-select";
import { useNavigate } from "react-router-dom";

function DashboardContent({ update }) {
    const navigate = useNavigate();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const ref = useRef();
    const domElem = useRef();
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState([
        new Date(Date.now()).toLocaleDateString(),
        new Date(Date.now()).toLocaleDateString(),
    ]);
    const [projectList, setProjectList] = useState([]);
    const [projectSelected, setProjectSelected] = useState();
    const [valuesParentToChild, setValuesParentToChild] = useState([
        mmDDyyyyFormateDateSet(new Date(firstDay).toLocaleDateString()),
        mmDDyyyyFormateDateSet(new Date(lastDay).toLocaleDateString()),
    ]);
    const [monthYear, setMonthYear] = useState(setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear());
    const image = React.useRef(null);
    const pdfExportComponent = React.useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [poWidget1, setpoWidget1] = useState([]);
    const [poWidget2, setpoWidget2] = useState([]);
    const [poData, setpoData] = useState([]);
    const [writerOverviewData, setWriterOverviewData] = useState();
    const [editorOverviewData, setEditorOverviewData] = useState();
    const [pocOverviewData, setPocOverviewData] = useState();


    const customStylesSearch = {
        control: base => ({
            ...base,
            border: "1px solid #212121",
            boxShadow: 'none',
            fontSize: '1rem',
            borderRadius: '8px',
            cursor: 'pointer'
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '40px',
            padding: '0 7px',
            // bottom: '3px',
            Margin: '0'
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#212121",
        }),
        input: (provided) => ({
            ...provided,
            height: '38px',
            Padding: '0',
            Margin: '0'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '212121',
            fontWeight: "500",
            // marginbottom: '6px',
            Margin: '0',
            boxShadow: 'none'
        }),
        menuList: styles => ({
            ...styles,
            Padding: '0',
            Margin: '0',
            width: "max-content",
            // minWidth: "100%",
            background: '#fff',
            border: '1px solid #ddd',
            borderLeft: '0',
            boxShadow: 'none'
        }),
    };
    localStorage.setItem("active", 0)

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);

    useEffect(() => {
        getProjetList()
    }, []);

    function getProjetList() {
        APIManager.getAPI(
            getProjectByOrgId + localStorage.getItem("organizationId")
        ).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let object;
                response.data.map((item) => {
                    object = {
                        label: item.projectCode,
                        value: item.id
                    }
                    list.push(object)
                })
                setProjectList(list)
                setProjectSelected(list[0]);
                getCMSDashboardData(list[0].value);
                // localStorage.setItem('projectDashboardCMS', list[0].value)

                let obj = <>
                    <WriterOverview
                        dataParentToChild={valuesParentToChild}
                        project={list[0].value}
                        writerOverviewData={writerOverviewData}
                    />
                </>
                setWriterOverviewData(obj);
                let obj1 = <>
                    <EditorOverview
                        dataParentToChild={valuesParentToChild}
                        project={list[0].value}
                        editorOverviewData={editorOverviewData} />
                </>
                setEditorOverviewData(obj1);

                let obj2 = <>
                    <PocOverview
                        dataParentToChild={valuesParentToChild}
                        // project={list[0].value}
                        getParentElem={getElem}
                        pocOverviewData={pocOverviewData}
                    /></>
                setPocOverviewData(obj2)
            }
        });
    }

    function getCMSDashboardData(id) {
        APIManager.postAPI(getProjectOverview + "projectId=" + id +
            "&startDate=" + convertDate(valuesParentToChild[0]) + "&endDate=" + convertDate(valuesParentToChild[1])).then(
                (response) => {
                    if (response && response.status == 200) {
                        setpoWidget1(response?.data?.table[0])
                        setpoWidget2(response?.data?.table1.length ? response?.data?.table1[0] : "")
                        setpoData(response?.data?.table2[0])
                    }
                }
            );
    }

    function getElem() {
        return domElem.current.value;
    }

    function downloadReport() {
        APIManager.getAPI(dashboardReportCMS + "projectId=" + (projectSelected ? projectSelected.value : projectList[0].value) +
            "&startDate=" + convertDate(valuesParentToChild[0]) + "&endDate=" + convertDate(valuesParentToChild[1])).then(
                (response) => {
                    if (response && response.status == 200) {
                        let exportList = [];
                        response.data.map((item) => {
                            let object = {
                                ArticleID: item.articleId ? item.articleId : "-",
                                KeywordName: (item.keywordName).trim() != "" ? item.keywordName : "-",
                                Project: (item.project).trim() != "" ? item.project : "-",
                                POC: (item.poc).trim() != "" ? item.poc : "-",
                                WriterAssigned: (item.writerAssigned).trim() != "" ? item.writerAssigned : "-",
                                EditorAssigned: (item.editorAssigned).trim() != "" ? item.editorAssigned : "-",
                                Status: item.status ? getStatusCms(item.status) : "-",
                                TurnaroundTime: item.turnAroundTime ? DDMMMMYYYYFormatter(item.turnAroundTime) : "-",
                                Rework: item.rework ? item.rework : "-",
                            }
                            exportList.push(object)
                        })
                        exportToEXCEL(exportList, "Dashboard Report")
                    }
                }
            );
    }

    return (
        <>
            <PDFExport
                paperSize="A1"
                margin="2cm"
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
            >
                <div
                    ref={image}
                >
                    <div class="row new-dashboard content-dashboard">
                        <div class=" portlet  px-0 mb-0">
                            <div class="new-dashboard-container w-100 d-flex align-items-center justify-content-between flex-wrap p-3">
                                <div className="ms-2">
                                    <h4 class="header-title fw-bold ">Welcome {localStorage.getItem("userName")},</h4>
                                    <h6 className="cms_fs16 cms_fw500 cms_fc_gray">These are the updates for all your projects</h6>
                                </div>
                                <div className="side-cal d-flex align-items-center me-2 cursor-pointer" ref={ref}>
                                    <FeatherIcon className="custome_feather ms-2" icon="calendar" />
                                    <button
                                        className="btn btn-primary ps-2 dashboard-cal pb-1"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {monthYear}
                                    </button>
                                    {open && (
                                        <div className="scope-calendar">
                                            <div id="example-collapse-text-calendar" style={{ right: '0' }}>
                                                <Card body className="daterange-picker-card  mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="calendar-col">
                                                            <Calendar
                                                                maxDate={new Date()}
                                                                onChange={(e) => {
                                                                    var date1 = e[0].format().split("/")
                                                                    var daysInMonth = getDaysInMonth(date1[1], date1[0])
                                                                    let dateObjArr = [
                                                                        mmDDyyyyFormateDateSet(new Date(date1[0] + "/01/" + date1[1])),
                                                                        mmDDyyyyFormateDateSet(new Date(date1[0] + "/" + daysInMonth + "/" + date1[1])),
                                                                    ];
                                                                    setValues(dateObjArr);
                                                                    setMonthYear(setMonths(new Date(date1[0]).getMonth() + 1) + " " + new Date(date1[1]).getFullYear());
                                                                    setValuesParentToChild(dateObjArr);
                                                                }
                                                                }
                                                                value={values}
                                                                onlyMonthPicker
                                                                range
                                                            />
                                                        </div>
                                                        <div className="ms-3">
                                                            <button
                                                                className="btn-outline btn btn-primary"
                                                                onClick={() => {
                                                                    setOpen(!open);
                                                                    getCMSDashboardData(projectSelected.value);
                                                                    let obj = <>
                                                                        <WriterOverview
                                                                            dataParentToChild={values}
                                                                            project={projectSelected.value}
                                                                            writerOverviewData={writerOverviewData}
                                                                        />
                                                                    </>
                                                                    setWriterOverviewData(obj);
                                                                    let obj1 = <>
                                                                        <EditorOverview
                                                                            dataParentToChild={values}
                                                                            project={projectSelected.value}
                                                                            editorOverviewData={editorOverviewData} />
                                                                    </>
                                                                    setEditorOverviewData(obj1);
                                                                    let obj2 = <>
                                                                        <PocOverview
                                                                            dataParentToChild={valuesParentToChild}
                                                                            // project={projectSelected.value}
                                                                            getParentElem={getElem}
                                                                            pocOverviewData={pocOverviewData}
                                                                        /></>
                                                                    setPocOverviewData(obj2)
                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    )}
                                    <FeatherIcon className="custome_feather " icon="chevron-down" />
                                </div>
                            </div>
                            <div></div>
                        </div>

                    </div>
                    <div className="title content-project-overview mt-3">
                        <div className="d-flex justify-content-between align-items-center project-overview cms_bg_border p-4 flex-wrap">
                            <div className="d-flex align-items-center cms-project-btn flex-wrap mt-3 mt-md-0">
                                <h4 className="cms_fw600 cms_fs18 cms_fc_dark me-3 my-1">Project Overview</h4>

                                <div className="content-project-select cms_radius6">
                                    <ReactSelect
                                        className="write-box"
                                        ref={domElem}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        isMulti={false}
                                        // styles={customStyles}
                                        styles={customStylesSearch}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        options={projectList}
                                        value={projectSelected}
                                        onChange={(e) => {
                                            setProjectSelected(e)
                                            // localStorage.setItem('projectDashboardCMS', e.value)
                                            getCMSDashboardData(e.value);

                                            let obj = <>
                                                <WriterOverview
                                                    dataParentToChild={valuesParentToChild}
                                                    project={e.value}
                                                    writerOverviewData={writerOverviewData}
                                                />
                                            </>
                                            setWriterOverviewData(obj);
                                            let obj1 = <>
                                                <EditorOverview
                                                    dataParentToChild={valuesParentToChild}
                                                    project={e.value}
                                                    editorOverviewData={editorOverviewData} />
                                            </>
                                            setEditorOverviewData(obj1);
                                            let obj2 = <>
                                                <PocOverview
                                                    dataParentToChild={valuesParentToChild}
                                                    // project={e.value}
                                                    getParentElem={getElem}
                                                    pocOverviewData={pocOverviewData}
                                                /></>
                                            setPocOverviewData(obj2)
                                        }}
                                    ></ReactSelect>
                                    {/* <select className="form-select ps-3 cursor-pointer"
                                        id="elem" ref={domElem}
                                        onChange={(e) => {
                                            setProjectSelected(e.target.value)
                                            localStorage.setItem('projectDashboardCMS',e.target.value)
                                            getCMSDashboardData(e.target.value);
                                            let obj = <>
                                                <WriterOverview
                                                        dataParentToChild={valuesParentToChild}
                                                        project={e.target.value}
                                                        writerOverviewData={writerOverviewData}
                                                    />
                                                </>
                                            setWriterOverviewData(obj);
                                            let obj1 = <>
                                                <EditorOverview
                                                    dataParentToChild={valuesParentToChild}
                                                    project={e.target.value}
                                                    editorOverviewData={editorOverviewData} />
                                            </>
                                            setEditorOverviewData(obj1);


                                        }}>
                                        {projectList.map((item) => {
                                            return <option value={item.value}>{item.label}</option>
                                        })}
                                    </select> */}
                                </div>

                            </div>

                            <div className="d-flex align-items-center download-view-btn flex-wrap">
                                <div>
                                    <button className="mt-3 mt-md-0 content-download cms_fc_blue cms_border_blue cms_fs16 cms_fw600 cms_radius6 cms_bg_white cms_line-height p-2 me-3"
                                        onClick={async () => {
                                            setLoading(true)
                                            downloadReport()
                                            // const promise = await downloadPDFfile(image, "Report.pdf")
                                            try {
                                                setLoading(false);

                                                // if (promise) {
                                                //     setLoading(false);
                                                // } else {
                                                // }

                                            } catch (e) {
                                                setLoading(false);
                                            }
                                        }}
                                    >

                                        <FeatherIcon className="custome_feather" icon="download" />
                                        <span class="ms-1"> Download Report</span>
                                    </button>
                                </div>
                                <div>
                                    <button className="mt-3 mt-md-0 content-viewdetails cms_border_blue cms_fs16 cms_fw600 cms_radius6 cms_fc_white cms_bg_blue cms_line-height p-2"
                                        onClick={() => {
                                            localStorage.setItem('projectDashboardCMS', projectSelected ? projectSelected.value : projectList[0].value)
                                            navigate('/content-main/requirement-details',{
                                                state: {
                                                    projectId: localStorage.getItem("projectDashboardCMS"),
                                                    startDate: valuesParentToChild[0],
                                                    endDate: valuesParentToChild[1],
                                                }
                                            });
                                        }}> View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="seo-per-box ga-seo">
                                <ProjectOverview
                                    dataParentToChild={valuesParentToChild}
                                    project={projectSelected}
                                    poWidget1={poWidget1}
                                    poWidget2={poWidget2}
                                    poData1={poData}
                                    getParentElem={getElem}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                {
                                    writerOverviewData
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                {
                                    editorOverviewData
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                {
                                    pocOverviewData
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </PDFExport>
            <Loading isLoading={isLoading} />
        </>
    )
}
export default DashboardContent;