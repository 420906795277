import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  createKeywords,
  updateSearch,
  getCategoryByProject,
} from "../../utility/Constant";
import { toast } from "react-hot-toast";

const Addkeywords = (props) => {
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [fieldValue, SetfieldValue] = useState("");
  const [SearchVolume, setSearchVolume] = useState();
  const [CategorySelected, setCategorySelected] = useState("");
  const [save, setsave] = useState(false);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    getCategoryData();    
    if (props?.data) {
      SetfieldValue(props.data?.fieldValue);
      setSearchVolume(props.data?.searchVolume);
      setCategorySelected(props.data?.categoryId);
    }
  }, [props?.data]);

  function formValidation() {
    var pattern = new RegExp(/^[0-9\b]+$/);
    let isValidFields = true;
    if (fieldValue.trim() === "") {
      setMessage("Keyword is required!");
      isValidFields = false;
    } else {
      setMessage("");
    }
    if (SearchVolume === undefined) {
      setMessage1("Search Volume is required!")
      isValidFields = false;
    }
    else if (!pattern.test(parseFloat(SearchVolume))) {
      setMessage1("Please enter only Number")
      isValidFields = false;
    } else {
      setMessage1("");
    }
    return isValidFields;
  }

  function addkeyword() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: fieldValue.trim(),
        SearchVolume: SearchVolume,
        CategoryId: CategorySelected,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        clearForm();
        notifyAdd();
      }
    });
  }

  function updatekeyword() {
    APIManager.putAPI(
      updateSearch,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: fieldValue.trim(),
        SearchVolume: SearchVolume,
        id: props?.data?.id,
        CategoryId: CategorySelected,
      },
      null,
      true
    ).then((response) => {

      if (response && response.status === 200) {
        clearForm();
      }
    });
  }

  function getCategoryData() {
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        setCategoryType(response.data);
        if (props?.data) {
          setCategorySelected(props.data?.categoryId);
        }else{
          setCategorySelected(response.data[0]?.id);
        }
      }
    });
  }

  function clearForm() {
    SetfieldValue("");
    setSearchVolume("");
    setCategorySelected("");
    props.update("");
  }

  return (
    <>
      <div className="common-form-fields slideInUp">
        <div className="portlet-body">
          <div class="col-md-12">
            <label className="form-label">Keyword</label>
            <label class="field-required"> * </label>
            <input
              className="form-control w-100"
              type="text"
              name=""
              placeholder="Enter Keyword"
              value={fieldValue}
              onChange={(text) => {
                SetfieldValue(text.target.value);
              }}
            ></input>
            <div class="ms-3" style={{ color: "red" }}>
              {message}
            </div>
          </div>
          <div className="col-md-12 mt-3 ">
            <label className="form-label">Search Volume</label>
            <label class="field-required"> * </label>
            <input
              className="form-control w-100"
              type="number"
              name=""
              placeholder="Enter Search Volume"
              value={SearchVolume}
              onChange={(text) => {
                setSearchVolume(text.target.value);
              }}
            ></input>
          </div>
          <div class="ms-3" style={{ color: "red" }}>
            {message1}
          </div>
          {save && message1?.SearchVolume && (
            <>
              <div className="field-required">
                <span> {message1}</span>
              </div>
            </>
          )}
          <div className="col-md-12 mt-3 ">
            <label className="form-label">Category</label>
            <br />
            <select
              className="w-100"
              style={{ padding: 8 + "px" }}
              required
              value={CategorySelected}
              onChange={(e) => {
                var c = e.target.value;
                setCategorySelected(c);
              }}
            >
              {categoryType?.map((item) => {
                return <option value={item.id}>{item.categoryType}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  props?.data ? updatekeyword() : addkeyword();
                }
              }}
              className="btn btn-primary me-3"
            >
              {props?.data ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                props.update("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addkeywords;