/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import DividerLine from "../../../shared/dividerLine";

const RemainingCompetitorsCard = ({ competetorsCount, totlaCompetitors }) => {
  return (
    <div css={styles.main}>
      <div css={styles.details}>
        <div css={ styles.count}>
          <div css={[styles.competitorsCount, competetorsCount <= totlaCompetitors ? styles.success : styles.failure]}>{competetorsCount}</div>
          <div css={styles.totlaCompetitors}>{`/ ${totlaCompetitors}`}</div>
          </div>
          <div css={styles.label}>No. Of Competitors</div>
      </div>
    </div>
  );
};

export default RemainingCompetitorsCard;
