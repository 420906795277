import {
  ModuleExpandSEOAudit,
  ModuleExpandSEOAuditV2,
  Main,
  EditEmployeeDir,
  EditProjectDir,
  EditClientDir,
  Profile,
  Home,
  Dashboard,
  TeamMembers,
  ModuleExpandDAPA,
  ModuleExpandPageSpeed,
  TeamMembersSalesDir,
  ClientsSalesDir,
  ViewClientDetailsSalesDir,
  ProjectsListSalesDir,
  Tickets,
  TicketsList,
  ModuleExpandSiteUptime,
  ModuleExpandRankTracking,
  ModuleExpandRankTracking1,
  ModuleExpandGSC,
  ModuleExpandContentWordCount,
  ModuleExpandGoogleAnalytics,
  ModuleExpandSEOVolatality,
  ModuleExpandKeywordResearch,
  ModuleExpandClickShare,
  ModuleExpandROI,
  ModuleExpandBackLinks,
  ModuleExpandOrganicResearch,

  ForgotPassword,
  CampaignStatus,
  CampaignApproval,
  NewPasswordSet,
  EditUser,
  TokenExpired,
  VerifyUser,
  ModuleExpandSEOAuditPopUp,
  ProjectsListSalesDirAddUpdate,
  TeamMembersSalesDirAddUpdateCustomer,
  AccessPermissions,
  AddClients,
  AddLeads,
  AddOrganization,
  AuditChecklist,
  BackLinksCount,
  ClickShare,
  Competitors,
  ContentWordCount,
  DAPAChecker,
  Department,
  Employees,
  GoogleAnalytics,
  GoogleTrends,
  GSCDataExtractor,
  KeywordResearch,
  Keywords,
  KeywordsCategory,
  OrganicResearch,
  PageSpeed,
  RankTracking,
  ROICalculator,
  Roles,
  SiteUptimeMonitor,
  Url,
  UserAccess,
  TeamMemberss,
  CustomizeDashboardd,
  AssignTeamMembers,
  AuditAddUpdate,
  SEOAudit,
  EditprojectAssignTeamMembers,
  TeamMembersSalesDirAddUpdate,
  ModuleExpandSEOAuditSelection,
  AuditChecklistImport,
  GoogleTrendsView,
  Organization,
  KeywordType,
  SiteUpTimeMonitorList,
  GoogleTrendsnew,
  AddGoogleTrends,
  test,
  DashboardPage,
  ViewAllTickets,
  CreateNewTicket,
  TeamEvaluation,
  TicketDashboard,
  ModuleExpandSEOAuditDetailed,
  OrganizationsList,
  Settings,
  RankingsChart,
  ModuleExpandSEOAuditV3,
  RequirementDetails,
  ShowPermission,
  ModuleExpandSEOAuditV4
} from "./components";

import AuditModuleAddUpdate from "./components/configuration/AuditModuleAddUpdate";
import City from "./components/configuration/City";
import Country from "./components/configuration/Country";
import EditCityDir from "./components/configuration/EditCityDir";
import EditCountryDir from "./components/configuration/EditCountryDir";
import Moudles from "./components/configuration/Moudles";
import Addkeywords from "./components/configuration/Addkeywords";
import AuditUpdate from "./components/configuration/AuditUpdate";
import Credentials from "./components/configuration/Credentials";
import ResearchModuleExpandKeywordResearch from "./components/ResearchModuleExpandKeywordResearch";
import ResearchModuleExpandOrganicResearch from "./components/ResearchModuleExpandOrganicResearch";
import ResearchModuleExpandBackLinks from "./components/configuration/ResearchModuleExpandBackLinks";
// import IndexPage from "./components/IndexPage";
import AuditInstruction from "./components/configuration/AuditInstruction";
import KeywordCategory from "./components/configuration/KeywordCategory";
import ModuleExpandNewTable from "./components/ModuleExpandNewTable";
import Moduleexpandcommentdata from "./components/Moduleexpandcommentdata";
import BugReport from "./components/bug-report";
import SiteUpTimeMonitor1 from "./components/configuration/SiteUpTimeMonitor1";
import SiteUptimeMonitorDoc from "./components/SiteUptimeMonitorDoc";
import SiteUptimeMonitorTestData from "./components/SiteupTestData";
import ModuleExpandROICalculator from "./components/ModuleExpandROICalculator";
import ModuleExpandWebsiteAuthority from "./components/ModuleExpandwebsiteAuthority";
import ModuleExpandGoogleTrends from "./components/ModuleExpandGoogleTrends";
import ticketsview from "./components/ticket/TicketView";
import ScopeOfWork from "./components/ticket/ScopeOfWork";
import SubTicketView from "./components/ticket/SubTicketView";
import Configuration3 from "./components/Configuration3";

import Dashboard2 from "./components/Dashboard2";

import Personaltickets from "./components/ticket/Personaltickets";
import TicketMain from "./components/TicketMain";
import ImplementationChart from "./components/common/ImplementationChart";
import Industries from "./components/configuration/Industries";
import RankingReporting from "./components/RankingReporting";
import SOWApproval from "./components/ticket/components/SOWApproval";
import SOWActivities from "./components/configuration/SOWActivities";

import DashboardContent from "./components/contentManagement/DashboardContent"
import RequirementContent from "./components/contentManagement/RequirementContent"
import DatabaseContent from "./components/contentManagement/DatabaseContent"
import ProjectOverview from "./components/contentManagement/ProjectOverview"
import ContentMain from "./components/contentManagement/ContentMain"
import WriterOverview from "./components/contentManagement/WriterOverview";
import EditorOverview from "./components/contentManagement/EditorOverview";
import PocOverview from "./components/contentManagement/PocOverview";
import PersonalDetails from "./components/contentManagement/PersonalDetails";
import EditorContent from "./components/contentManagement/EditorContent";
import RequirementKeywordDetails from "./components/contentManagement/RequirementKeywordDetails";
import FreelancersRequirementDetails from "./components/contentManagement/FreelancersRequirementDetails";
import FreelancersMyRequirement from "./components/contentManagement/FreelancersMyRequirement";
import BandwidthMoniter from "./components/BandwidthMoniter";
import QueryExecutor from "./components/QueryExecutor";
import LatestLogin from "./components/LatestLogin";
import LatestLoginHTML from "./components/LatestLoginHTML";
import ForgotPasswordNew from "./components/ForgotPasswordNew";
import EmailSent from "./components/EmailSent";
import ChangePassword from "./components/ChangePassword";
import PasswordSuccess from "./components/PasswordSuccess";
import AddUpdateSOWActivitiy from "./components/configuration/AddUpdateSOWActivity";
import NotFound from "./NotFound";
import RankingReportV3 from "./components/rankingReportV3";
import Ticketing from "./components/ticketing-v2";
import TicketDetailPage from "./components/ticketing-v2/ticketDetails";
import TicketListingPage from "./components/ticketing-v2/ticketListing";

const routes = [
  {
    path: "/login",
    //component: Home, //old login page 123
    component: LatestLogin, //new login page
  },
  {
    path: "/loginhtml",
    //component: Home, //old login page 123
    component: LatestLoginHTML, //new login page
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordNew,
  },
  {
    path: "/email-sent",
    component: EmailSent,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/password-success",
    component: PasswordSuccess,
  },
  {
    path: "/login/:id",
    component: Home,
  },
  {
    path: "/new-password-set",
    component: NewPasswordSet,
  },
  {
    path: "/reset-password",
    //component: NewPasswordSet,//old page
    component: ChangePassword//New pagee
  },
  {
    path: "/sow-Approval",
    component: SOWApproval,
  },
  {
    path: "/verify-user",
    component: VerifyUser,
  },
  {
    path: "/campaign-status",
    component: CampaignStatus,
  },
  {
    path: "/show-permission",
    component: ShowPermission,
  },
  {
    path: "/campaign-approval",
    component: CampaignApproval,
  },
  {
    path: "/main",
    component: Main,
    routes: [
      {
        path: "/main/dashboard",
        component: Dashboard2,
      },
      {
        path: "/main/profile",
        component: Profile,
      },
      {
        path: "/main/reset-password",
        component: ForgotPassword,
      },
      {
        path: "/main/token-expired",
        component: TokenExpired,
      },
      {
        path: "/main/edit-employee-dir",
        component: EditEmployeeDir,
      },
      {
        path: "/main/edit-project-dir",
        component: EditProjectDir,
      },
      {
        path: "/main/edit-client-dir",
        component: EditClientDir,
      },
      {
        path: "/main/edit-city-dir",
        component: EditCityDir,
      },
      {
        path: "/main/edit-country-dir",
        component: EditCountryDir,
      },
      {
        path: "/main/edit-User",
        component: EditUser,
      },
      {
        path: "/main/edit-project-assign-teammember",
        component: EditprojectAssignTeamMembers,
      },
      {
        path: "/main/module-expand-da/",
        component: ModuleExpandWebsiteAuthority,
      },
      {
        path: "/main/module-expand-page-speed/",
        component: ModuleExpandPageSpeed,
      },
      {
        path: "/main/team-members-sales-dir",
        component: TeamMembersSalesDir,
      },
      {
        path: "/main/modules",
        component: Moudles,
      },
      {
        path: "/main/credentials",
        component: Credentials,
      },
      {
        path: "/main/addupdate-team-members-sales-dir",
        component: TeamMembersSalesDirAddUpdate,
      },
      {
        path: "/main/addupdate-customer-team-members-sales-dir",
        component: TeamMembersSalesDirAddUpdateCustomer,
      },
      {
        path: "/main/module-expand-seo-audit-selection",
        component: ModuleExpandSEOAuditSelection,
      },
      {
        path: "/new-password-set",
        component: NewPasswordSet,
      },

      {
        path: "/main/clients-sales-dir",
        component: ClientsSalesDir,
      },
      {
        path: "/main/view-client-sales-dir",
        component: ViewClientDetailsSalesDir,
      },
      // {
      //   path: "/main/index-page",
      //   component: IndexPage,
      // },
      {
        path: "/main/project-list-sales-dir",
        component: ProjectsListSalesDir,
      },
      {
        path: "/main/add-update-project-list-sales-dir",
        component: ProjectsListSalesDirAddUpdate,
      },
      // {
      //   path: "/main/tickets",
      //   component: Tickets,
      // },
      // {
      //   path: "/main/ticketslist",
      //   component: TicketsList,
      // },
      {
        path: "/main/module-expand-site-uptime/",
        component: SiteUptimeMonitorDoc,
      },
      {
        path: "/main/module-expand-rank-tracking/",
        component: ModuleExpandRankTracking,
      },
      {
        path: "/main/module-expand-rank-tracking1",
        component: ModuleExpandRankTracking1,
      },
      {
        path: "/main/module-expand-gsc/",
        component: ModuleExpandGSC,
      },
      {
        path: "/main/content-word-count/:id",
        component: ModuleExpandContentWordCount,
      },
      {
        path: "/main/module-expand-google-analytics/",
        component: ModuleExpandGoogleAnalytics,
      },
      {
        path: "/main/module-expand-seo-volatility/:id",
        component: ModuleExpandSEOVolatality,
      },
      {
        path: "/main/module-expand-keyword-research/:id",
        component: ModuleExpandKeywordResearch,
      },
      {
        path: "/main/module-expand-click-share/",
        component: ModuleExpandClickShare,
      },
      {
        path: "/main/module-expand-roi/:id",
        component: ModuleExpandROI,
      },
      {
        path: "/main/module-expand-roi-calc/",
        component: ModuleExpandROICalculator,
      },
      {
        path: "/main/module-expand-backlinks/",
        component: ModuleExpandBackLinks,
      },
      {
        path: "/main/module-expand-organic-research/:id",
        component: ModuleExpandOrganicResearch,
      },
      {
        path: "/main/module-expand-seo-audit/",
        // component: ModuleExpandSEOAudit,
        component: ModuleExpandSEOAuditV2,
      },
      {
        path: "/main/module-expand-seo-audit-view/",
        component: ModuleExpandSEOAuditV3,
      },
      {
        path: "/main/module-expand-seo-audit-view-v1/",
        component: ModuleExpandSEOAuditV4,
      },
      // {
      //   path: "/main/module-expand-seo-auditv2/:id",
      //   component: ModuleExpandSEOAuditV2,
      // },
      {
        path: "/main/configuration",
        component: Configuration3,
      },
      {
        path: "/main/access-permissions",
        component: AccessPermissions,
      },
      {
        path: "/main/seo-audit",
        component: SEOAudit,
      },

      { path: "/main/add-clients", component: AddClients },
      { path: "/main/add-leads", component: AddLeads },
      {
        path: "/main/add-organization",
        component: AddOrganization,
      },
      {
        path: "/main/audit-checklist",
        component: AuditChecklist,
      },
      {
        path: "/main/add-update-auditinstruction",
        component: AuditInstruction,
      },
      {
        path: "/main/audit-checklist-import",
        component: AuditChecklistImport,
      },
      {
        path: "/main/add-audit",
        component: AuditAddUpdate,
      },
      {
        path: "/main/update-audit",
        component: AuditUpdate,
      },
      {
        path: "/main/add-update-auditmodule",
        component: AuditModuleAddUpdate,
      },
      {
        path: "/main/backlinks-count",
        component: BackLinksCount,
      },
      {
        path: "/main/click-share",
        component: ClickShare,
      },
      {
        path: "/main/competitors",
        component: Competitors,
      },
      {
        path: "/main/content-word-count",
        component: ContentWordCount,
      },
      {
        path: "/main/dapa-checker",
        component: DAPAChecker,
      },
      {
        path: "/main/department",
        component: Department,
      },
      {
        path: "/main/organizationsList",
        component: OrganizationsList,
      },
      {
        path: "/main/employees",
        component: Employees,
      },
      {
        path: "/main/google-analytics",
        component: GoogleAnalytics,
      },
      {
        path: "/main/google-trends",
        component: GoogleTrends,
      },
      {
        path: "/main/gsc-data-extractor",
        component: GSCDataExtractor,
      },
      {
        path: "/main/keyword-research",
        component: KeywordResearch,
      },
      {
        path: "/main/keywords",
        component: Keywords,
      },
      {
        path: "/main/keyword-category",
        component: KeywordCategory,
      },
      {
        path: "/main/organic-research",
        component: OrganicResearch,
      },
      {
        path: "/main/Organization",
        component: Organization,
      },
      {
        path: "/main/page-speed",
        component: PageSpeed,
      },
      {
        path: "/main/rank-tracking",
        component: RankTracking,
      },
      {
        path: "/main/roi-calculator",
        component: ROICalculator,
      },
      {
        path: "/main/roles",
        component: Roles,
      },
      {
        path: "/main/site-uptime-monitor",
        component: SiteUptimeMonitor,
      },
      {
        path: "/main/site-uptime-monitor-list",
        component: SiteUpTimeMonitorList,
      },
      {
        path: "/main/google-trends-new",
        component: GoogleTrendsnew,
      },
      {
        path: "/main/google-trends-add-form",
        component: AddGoogleTrends,
      },
      // {
      //   path: "/main/tickets/scopeofwork",
      //   component: ScopeOfWork,
      // },
      {
        path: "/main/url",
        component: Url,
      },
      {
        path: "/main/user-access",
        component: UserAccess,
      },
      {
        path: "/main/team-memberss",
        component: TeamMemberss,
      },
      {
        path: "/main/customize-dashboard",
        component: CustomizeDashboardd,
      },
      {
        path: "/main/keyword-type",
        component: KeywordType,
      },
      {
        path: "/main/assign-team-members",
        component: AssignTeamMembers,
      },
      // {
      //   path: "/main/g-trends/:id",
      //   component: GoogleTrendsView,
      // },
      {
        path: "/main/module-expand-seo-audit-popup",
        component: ModuleExpandSEOAuditPopUp,
      },
      // {
      //   path: "/main/customize-dashboard",
      //   component: CustomizeDashboard,
      // },
      {
        path: "/main/city",
        component: City,
      },
      {
        path: "/main/country",
        component: Country,
      },
      {
        path: "/main/addkeywords",
        component: Addkeywords,
      },
      {
        path: "/main/research/module-expand-keyword-research/:id",
        component: ResearchModuleExpandKeywordResearch,
      },
      {
        path: "/main/research/module-expand-organic-research/:id",
        component: ResearchModuleExpandOrganicResearch,
      },
      {
        path: "/main/buglist",
        component: ModuleExpandNewTable,
      },
      {
        path: "/main/bug",
        component: Moduleexpandcommentdata,
      },
      {
        path: "/main/research/module-expand-backlinks/:id",
        component: ResearchModuleExpandBackLinks,
      },
      {
        path: "/main/bug-report",
        component: BugReport,
      },
      {
        path: "/main/siteuptimemonitor1",
        component: SiteUpTimeMonitor1,
      },
      // {
      //   path: "/main/siteup_monitor_doc/:id",
      //   component: SiteUptimeMonitorDoc,
      // },
      {
        path: "/main/siteup_monitor_testdata",
        component: SiteUptimeMonitorTestData,
      },
      {
        path: "/main/website_authority",
        component: ModuleExpandWebsiteAuthority,
      },
      // {
      //   path: "/main/tickets/test",
      //   component: test,
      // },
      // {
      //   path: "/main/tickets/dashboardpage",
      //   component:DashboardPage,
      // },

      // {
      //   path: "/main/tickets/dashboard",
      //   component: TicketDashboard,
      // },
      // {
      //   path: "/main/tickets/team-evaluation",
      //   component: TeamEvaluation,
      // },
      // {
      //   path: "/main/tickets/view-all-tickets",
      //   component: ViewAllTickets,
      // },
      // {
      //   path: "/main/tickets/personal-tickets",
      //   component: Personaltickets,
      // },
      // {
      //   path: "/main/tickets/create-ticket",
      //   component: CreateNewTicket,
      // },
      {
        path: "/main/module-expand-google-trends",
        component: ModuleExpandGoogleTrends,
      },
      // {
      //   path: "/main/tickets/ticket-view",
      //   component: ticketsview,
      // },
      // {
      //   path: "/main/tickets/subticket-view",
      //   component: SubTicketView,
      // },
      {
        path: "/main/module-expand-seo-audit-detailed",
        component: ModuleExpandSEOAuditDetailed,
      },
      {
        path: "/main/dashboard",
        component: Dashboard2,
      },
      {
        path: "/main/rankingsChart",
        component: RankingsChart,
      },
      {
        path: "/main/implementationChart",
        component: ImplementationChart,
      },
      {
        path: "/main/industries",
        component: Industries,
      },
      {
        path: "/main/ranking",
        component: RankingReporting,
      },
      {
        path: "/main/bandwidth",
        component: BandwidthMoniter,
      },
      {
        path: "/main/sql",
        component: QueryExecutor,
      },
      {
        path: "/main/sowActivities",
        component: SOWActivities,
      },
      {
        path: "/main/edit-sowActivities-dir",
        component: AddUpdateSOWActivitiy,
      },
    ],
  },
  {
    path: "/ticket",
    component: TicketMain,
    routes: [
      // {
      //   path: "/ticket/dashboard",
      //   component: Dashboard2,
      // },
      {
        path: "/ticket/dashboard",
        component: TicketDashboard,
      },
      {
        path: "/ticket/team-evaluation",
        component: TeamEvaluation,
      },
      {
        path: "/ticket/view-all-tickets",
        component: ViewAllTickets,
      },
      {
        path: "/ticket/personal-tickets",
        component: Personaltickets,
      },
      {
        path: "/ticket/create-ticket",
        component: CreateNewTicket,
      },
      {
        path: "/ticket/ticket-view",
        component: ticketsview,
      },
      {
        path: "/ticket/subticket-view",
        component: SubTicketView,
      },
      {
        path: "/ticket/scopeofwork",
        component: ScopeOfWork,
      },

      {
        path: "/ticket/test",
        component: test,
      },
    ],
  },

  {
    path: "/dashboard2",
    component: Dashboard,
  },
  {
    path: "/content-main",
    component: ContentMain,

    routes: [
      {
        path: "/content-main/dashboardContent",
        component: DashboardContent,
      },
      {
        path: "/content-main/requirementContent",
        component: RequirementContent,
      },
      {
        path: "/content-main/databaseContent",
        component: DatabaseContent,
      },
      {
        path: "/content-main/editorContent",
        component: EditorContent,
      },
      {
        path: "/content-main/projectOverview",
        component: ProjectOverview,
      },
      {
        path: "/content-main/writerOverview",
        component: WriterOverview,
      },
      {
        path: "/content-main/requirement-details",
        component: RequirementDetails,
      },
      {

        path: "/content-main/editorOverview",
        component: EditorOverview,
      },
      {
        path: "/content-main/eocOverview",
        component: PocOverview,
      },
      {
        path: "/content-main/personalDetails",
        component: PersonalDetails,
      },
      {
        path: "/content-main/requirement-keyword-details",
        component: RequirementKeywordDetails,
      },
      {
        path: "/content-main/FreelancersRequirementDetails",
        component: FreelancersRequirementDetails,
      },
      {
        path: "/content-main/FreelancerProfile/:id",
        component: FreelancersMyRequirement,
      }
    ]
  },
];


const routesV2 = [
  {
    //Router Migrated
    path: "/login",
    //component: Home, //old login page 123
    component: LatestLogin, //new login page
  },
  {
    //Router Migrated
    path: "/loginhtml",
    //component: Home, //old login page 123
    component: LatestLoginHTML, //new login page
  },
  {
    //Router Migrated
    path: "/forgot-password",
    component: ForgotPasswordNew,
  },
  {
    //Router Migrated
    path: "/email-sent",
    component: EmailSent,
  },
  {
    //Router Migrated
    path: "/change-password",
    component: ChangePassword,
  },
  {
    //Router Migrated
    path: "/password-success",
    component: PasswordSuccess,
  },
  {
    //Router Migrated
    path: "/login/:id",
    component: Home,
  },
  {
    //Router Migrated
    path: "/new-password-set",
    component: NewPasswordSet,
  },
  {
    //Router Migrated
    path: "/reset-password",
    //component: NewPasswordSet,//old page
    component: ChangePassword//New pagee
  },
  {
    //Router Migrated
    path: "/sow-Approval",
    component: SOWApproval,
  },
  {
    //Router Migrated
    path: "/verify-user",
    component: VerifyUser,
  },
  {
    //Router Migrated
    path: "/campaign-status",
    component: CampaignStatus,
  },
  {
    //Router Migrated
    path: "/show-permission",
    component: ShowPermission,
  },
  {
    //Router Migrated
    path: "/campaign-approval",
    component: CampaignApproval,
  },
  {
    //Router Migrated
    path: "/main/*",
    //component: Home, //old login page 123
    component: Main, //new login page
    children: [
      {
        //Router Migrated
        path: "*",
        component: NotFound,
      },
      {
        //Router Migrated
        path: "dashboard",
        component: Dashboard2,
      },
      {
        //Router Migrated
        path: "profile",
        component: Profile,
      },
      {
        //Router Migrated
        path: "reset-password",
        component: ForgotPassword,
      },
      {
        //Router Migrated
        path: "token-expired",
        component: TokenExpired,
      },
      {
        //Router Migrated
        path: "edit-employee-dir",
        component: EditEmployeeDir,
      },
      {
        //Router Migrated
        path: "edit-project-dir",
        component: EditProjectDir,
      },
      {
        //Router Migrated
        path: "edit-client-dir",
        component: EditClientDir,
      },
      {
        //Router Migrated
        path: "edit-city-dir",
        component: EditCityDir,
      },
      {
        //Router Migrated
        path: "edit-country-dir",
        component: EditCountryDir,
      },
      {
        //Router Migrated
        path: "edit-User",
        component: EditUser,
      },
      {
        //Router Migrated
        path: "edit-project-assign-teammember",
        component: EditprojectAssignTeamMembers,
      },
      {
        //Router Migrated
        path: "module-expand-da/*",
        component: ModuleExpandWebsiteAuthority,
      },
      {
        //Router Migrated
        path: "module-expand-page-speed/*",
        component: ModuleExpandPageSpeed,
      },
      {
        //Router Migrated
        path: "team-members-sales-dir",
        component: TeamMembersSalesDir,
      },
      {
        //Router Migrated
        path: "modules",
        component: Moudles,
      },
      {
        //Router Migrated
        path: "credentials",
        component: Credentials,
      },
      {
        //Router Migrated
        path: "addupdate-team-members-sales-dir",
        component: TeamMembersSalesDirAddUpdate,
      },
      {
        //Router Migrated
        path: "addupdate-customer-team-members-sales-dir",
        component: TeamMembersSalesDirAddUpdateCustomer,
      },
      {
        //Router Migrated
        path: "module-expand-seo-audit-selection/*",
        component: ModuleExpandSEOAuditSelection,
      },
      {
        //Router Migrated
        path: "/new-password-set",
        component: NewPasswordSet,
      },

      {
        //Router Migrated
        path: "clients-sales-dir",
        component: ClientsSalesDir,
      },
      {
        //Router Migrated
        path: "view-client-sales-dir",
        component: ViewClientDetailsSalesDir,
      },
      // {
      //   path: "index-page",
      //   component: IndexPage,
      // },
      {
        //Router Migrated
        path: "project-list-sales-dir",
        component: ProjectsListSalesDir,
      },
      {
        //Router Migrated
        path: "add-update-project-list-sales-dir",
        component: ProjectsListSalesDirAddUpdate,
      },
      // {
      //   path: "tickets",
      //   component: Tickets,
      // },
      // {
      //   path: "ticketslist",
      //   component: TicketsList,
      // },
      {
        //Router Migrated
        path: "module-expand-site-uptime/*",
        component: SiteUptimeMonitorDoc,
      },
      {
        //Router Migrated
        path: "module-expand-rank-tracking/*",
        component: ModuleExpandRankTracking,
      },
      {
        //Router Migrated
        path: "module-expand-rank-tracking1",
        component: ModuleExpandRankTracking1,
      },
      {
        //Router Migrated
        path: "module-expand-gsc/*",
        component: ModuleExpandGSC,
      },
      {
        //Router Migrated
        path: "content-word-count/:id",
        component: ModuleExpandContentWordCount,
      },
      {
        //Router Migrated
        path: "module-expand-google-analytics/*",
        component: ModuleExpandGoogleAnalytics,
      },
      {
        //Router Migrated
        path: "module-expand-seo-volatility/:id",
        component: ModuleExpandSEOVolatality,
      },
      {
        //Router Migrated
        path: "module-expand-keyword-research/:id",
        component: ModuleExpandKeywordResearch,
      },
      {
        //Router Migrated
        path: "module-expand-click-share/*",
        component: ModuleExpandClickShare,
      },
      {
        //Router Migrated
        path: "module-expand-roi/:id",
        component: ModuleExpandROI,
      },
      {
        //Router Migrated
        path: "module-expand-roi-calc/*",
        component: ModuleExpandROICalculator,
      },
      {
        //Router Migrated
        path: "module-expand-backlinks/*",
        component: ModuleExpandBackLinks,
      },
      {
        //Router Migrated
        path: "module-expand-organic-research/:id",
        component: ModuleExpandOrganicResearch,
      },
      {
        //Router Migrated
        path: "module-expand-seo-audit/*",
        // component: ModuleExpandSEOAudit,
        component: ModuleExpandSEOAuditV2,
      },
      {
        //Router Migrated
        path: "module-expand-seo-audit-view/*",
        component: ModuleExpandSEOAuditV3,
      },
      {
        //Router Migrated
        path: "module-expand-seo-audit-view-v1/*",
        component: ModuleExpandSEOAuditV4,
      },
      // {
      //   path: "module-expand-seo-auditv2/:id",
      //   component: ModuleExpandSEOAuditV2,
      // },
      {
        //Router Migrated
        path: "configuration",
        component: Configuration3,
      },
      {
        //Router Migrated
        path: "access-permissions",
        component: AccessPermissions,
      },
      {
        //Router Migrated
        path: "seo-audit",
        component: SEOAudit,
      },
      //Router Migrated
      { path: "add-clients", component: AddClients },
      //Router Migrated
      { path: "add-leads", component: AddLeads },
      {
        //Router Migrated
        path: "add-organization",
        component: AddOrganization,
      },
      {
        //Router Migrated
        path: "audit-checklist",
        component: AuditChecklist,
      },
      {
        //Router Migrated
        path: "add-update-auditinstruction",
        component: AuditInstruction,
      },
      {
        //Router Migrated
        path: "audit-checklist-import",
        component: AuditChecklistImport,
      },
      {
        //Router Migrated
        path: "add-audit",
        component: AuditAddUpdate,
      },
      {
        //Router Migrated
        path: "update-audit",
        component: AuditUpdate,
      },
      {
        //Router Migrated
        path: "add-update-auditmodule",
        component: AuditModuleAddUpdate,
      },
      {
        //Router Migrated
        path: "backlinks-count",
        component: BackLinksCount,
      },
      {
        //Router Migrated
        path: "click-share",
        component: ClickShare,
      },
      {
        //Router Migrated
        path: "competitors",
        component: Competitors,
      },
      {
        //Router Migrated
        path: "content-word-count",
        component: ContentWordCount,
      },
      {
        //Router Migrated
        path: "dapa-checker",
        component: DAPAChecker,
      },
      {
        //Router Migrated
        path: "department",
        component: Department,
      },
      {
        //Router Migrated
        path: "organizationsList",
        component: OrganizationsList,
      },
      {
        //Router Migrated
        path: "employees",
        component: Employees,
      },
      {
        //Router Migrated
        path: "google-analytics",
        component: GoogleAnalytics,
      },
      {
        //Router Migrated
        path: "google-trends",
        component: GoogleTrends,
      },
      {
        //Router Migrated
        path: "gsc-data-extractor",
        component: GSCDataExtractor,
      },
      {
        //Router Migrated
        path: "keyword-research",
        component: KeywordResearch,
      },
      {
        //Router Migrated
        path: "keywords",
        component: Keywords,
      },
      {
        //Router Migrated
        path: "keyword-category",
        component: KeywordCategory,
      },
      {
        //Router Migrated
        path: "organic-research",
        component: OrganicResearch,
      },
      {
        //Router Migrated
        path: "Organization",
        component: Organization,
      },
      {
        //Router Migrated
        path: "page-speed",
        component: PageSpeed,
      },
      {
        //Router Migrated
        path: "rank-tracking",
        component: RankTracking,
      },
      {
        //Router Migrated
        path: "roi-calculator",
        component: ROICalculator,
      },
      {
        //Router Migrated
        path: "roles",
        component: Roles,
      },
      {
        //Router Migrated
        path: "site-uptime-monitor",
        component: SiteUptimeMonitor,
      },
      {
        //Router Migrated
        path: "site-uptime-monitor-list",
        component: SiteUpTimeMonitorList,
      },
      {
        //Router Migrated
        path: "google-trends-new",
        component: GoogleTrendsnew,
      },
      {
        //Router Migrated
        path: "google-trends-add-form",
        component: AddGoogleTrends,
      },
      // {
      //   path: "tickets/scopeofwork",
      //   component: ScopeOfWork,
      // },
      {
        //Router Migrated
        path: "url",
        component: Url,
      },
      {
        //Router Migrated
        path: "user-access",
        component: UserAccess,
      },
      {
        //Router Migrated
        path: "team-memberss",
        component: TeamMemberss,
      },
      {
        //Router Migrated
        path: "customize-dashboard",
        component: CustomizeDashboardd,
      },
      {
        //Router Migrated
        path: "keyword-type",
        component: KeywordType,
      },
      {
        //Router Migrated
        path: "assign-team-members",
        component: AssignTeamMembers,
      },
      // {
      //   path: "g-trends/:id",
      //   component: GoogleTrendsView,
      // },
      {
        //Router Migrated
        path: "module-expand-seo-audit-popup/*",
        component: ModuleExpandSEOAuditPopUp,
      },
      // {
      //   path: "customize-dashboard",
      //   component: CustomizeDashboard,
      // },
      {
        //Router Migrated
        path: "city",
        component: City,
      },
      {
        //Router Migrated
        path: "country",
        component: Country,
      },
      {
        //Router Migrated
        path: "addkeywords",
        component: Addkeywords,
      },
      {
        //Router Migrated
        path: "research/module-expand-keyword-research/:id",
        component: ResearchModuleExpandKeywordResearch,
      },
      {
        //Router Migrated
        path: "research/module-expand-organic-research/:id",
        component: ResearchModuleExpandOrganicResearch,
      },
      {
        //Router Migrated
        path: "buglist",
        component: ModuleExpandNewTable,
      },
      {
        //Router Migrated
        path: "bug",
        component: Moduleexpandcommentdata,
      },
      {
        //Router Migrated
        path: "research/module-expand-backlinks/:id",
        component: ResearchModuleExpandBackLinks,
      },
      {
        //Router Migrated
        path: "bug-report",
        component: BugReport,
      },
      {
        //Router Migrated
        path: "siteuptimemonitor1",
        component: SiteUpTimeMonitor1,
      },
      // {
      //   path: "siteup_monitor_doc/:id",
      //   component: SiteUptimeMonitorDoc,
      // },
      {
        //Router Migrated
        path: "siteup_monitor_testdata",
        component: SiteUptimeMonitorTestData,
      },
      {
        //Router Migrated
        path: "website_authority",
        component: ModuleExpandWebsiteAuthority,
      },
      // {
      //   path: "tickets/test",
      //   component: test,
      // },
      // {
      //   path: "tickets/dashboardpage",
      //   component:DashboardPage,
      // },

      // {
      //   path: "tickets/dashboard",
      //   component: TicketDashboard,
      // },
      // {
      //   path: "tickets/team-evaluation",
      //   component: TeamEvaluation,
      // },
      // {
      //   path: "tickets/view-all-tickets",
      //   component: ViewAllTickets,
      // },
      // {
      //   path: "tickets/personal-tickets",
      //   component: Personaltickets,
      // },
      // {
      //   path: "tickets/create-ticket",
      //   component: CreateNewTicket,
      // },
      {
        //Router Migrated
        path: "module-expand-google-trends/*",
        component: ModuleExpandGoogleTrends,
      },
      // {
      //   path: "tickets/ticket-view",
      //   component: ticketsview,
      // },
      // {
      //   path: "tickets/subticket-view",
      //   component: SubTicketView,
      // },
      {
        //Router Migrated
        path: "module-expand-seo-audit-detailed/*",
        component: ModuleExpandSEOAuditDetailed,
      },
      {
        //Router Migrated
        path: "dashboard",
        component: Dashboard2,
      },
      {
        //Router Migrated
        path: "rankingsChart",
        component: RankingsChart,
      },
      {
        //Router Migrated
        path: "implementationChart",
        component: ImplementationChart,
      },
      {
        //Router Migrated
        path: "industries",
        component: Industries,
      },
      {
        //Router Migrated
        path: "ranking/*",
        component: RankingReportV3,
        // component: RankingReporting,
      },
      {
        //Router Migrated
        path: "bandwidth",
        component: BandwidthMoniter,
      },
      {
        //Router Migrated
        path: "sql",
        component: QueryExecutor,
      },
      {
        //Router Migrated
        path: "sowActivities",
        component: SOWActivities,
      },
      {
        //Router Migrated
        path: "edit-sowActivities-dir",
        component: AddUpdateSOWActivitiy,
      },
    ],
  },
  {
    //Router Migrated
    path: "/ticket/*",
    component: TicketMain,
    children: [
      // {
      //   path: "/ticket/dashboard",
      //   component: Dashboard2,
      // },
      {
        //Router Migrated
        path: "*",
        component: NotFound,
      },
      {
        //Router Migrated
        path: "dashboard",
        component: TicketDashboard,
      },
      {
        //Router Migrated
        path: "team-evaluation",
        component: TeamEvaluation,
      },
      {
        //Router Migrated
        path: "view-all-tickets",
        component: ViewAllTickets,
      },
      {
        //Router Migrated
        path: "personal-tickets",
        component: Personaltickets,
      },
      {
        //Router Migrated
        path: "create-ticket",
        component: CreateNewTicket,
      },
      {
        //Router Migrated
        path: "ticket-view",
        component: ticketsview,
      },
      {
        //Router Migrated
        path: "subticket-view",
        component: SubTicketView,
      },
      {
        //Router Migrated
        path: "scopeofwork",
        component: ScopeOfWork,
      },
      {
        //Router Migrated
        path: "test",
        component: test,
      },
    ],
  },
  {
    // Router Migrated
    path: "/tickets/*",
    component: Ticketing,
    children: [
      {
        //Router Migrated
        path: "*",
        component: NotFound,
      },
      {
        //Router Migrated
        path: "my-tickets",
        component: TicketListingPage,
      },
      {
        //Router Migrated
        path: "team-tickets",
        component: TicketListingPage,
      },
      {
        //Router Migrated
        path: "my-tickets/ticket-details",
        component: TicketDetailPage,
      },
      {
        //Router Migrated
        path: "team-tickets/ticket-details",
        component: TicketDetailPage,
      },
    ]
  },
  {
    //Router Migrated
    path: "/dashboard2",
    component: Dashboard,
  },
  {
    //Router Migrated
    path: "/content-main/*",
    component: ContentMain,
    children: [
      {
        //Router Migrated
        path: "*",
        component: NotFound,
      },
      {
        //Router Migrated
        path: "dashboardContent",
        component: DashboardContent,
      },
      {
        //Router Migrated
        path: "requirementContent",
        component: RequirementContent,
      },
      {
        //Router Migrated
        path: "databaseContent",
        component: DatabaseContent,
      },
      {
        //Router Migrated
        path: "editorContent",
        component: EditorContent,
      },
      {
        //Router Migrated
        path: "projectOverview",
        component: ProjectOverview,
      },
      {
        //Router Migrated
        path: "writerOverview",
        component: WriterOverview,
      },
      {
        //Router Migrated
        path: "requirement-details",
        component: RequirementDetails,
      },
      {
        //Router Migrated
        path: "editorOverview",
        component: EditorOverview,
      },
      {
        //Router Migrated
        path: "eocOverview",
        component: PocOverview,
      },
      {
        //Router Migrated
        path: "personalDetails",
        component: PersonalDetails,
      },
      {
        //Router Migrated
        path: "requirement-keyword-details",
        component: RequirementKeywordDetails,
      },
      {
        //Router Migrated
        path: "FreelancersRequirementDetails",
        component: FreelancersRequirementDetails,
      },
      {
        //Router Migrated
        path: "FreelancerProfile/:id",
        component: FreelancersMyRequirement,
      }
    ]
  }
]

export default routesV2;