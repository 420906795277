import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import FormControl from "@mui/material/FormControl";
import { changePassword } from "../utility/Constant";
import APIManager from "../services/APIManager";
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from "react-router-dom";

function ChangePassword() {
    const [email, setemail] = useState("");
    const [token, settoken] = useState("");

    useEffect(() => {
        const paramss = new URLSearchParams(window.location.search);
        let email = paramss.get("email");
        setemail(email);
        let token = paramss.get("token");
        settoken(token);
    }, []);

    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [cpassword, setcpassword] = useState("");
    const [conformpassword, setconformpassword] = useState(false);
    const [conformpasswordMsg, setconformpasswordMsg] = useState("");
    const [newpassword, setnewpassword] = useState(false);
    const [newpasswordMsg, setnewpasswordMsg] = useState("");
    const [values, setValues] = React.useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });
    const [cvalues, setcValues] = React.useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });

    function formValidation() {
        let isValidFields = true;
        if (cpassword.trim() == "") {
            isValidFields = false;
            setconformpassword(true);
            setconformpasswordMsg("Confirm Password is required!");
        } else {
            setconformpassword(false);
            setconformpasswordMsg("");
        }
        return isValidFields;
    }

    function Validation() {
        let isValidFields = true;
        if (password.trim() == "") {
            isValidFields = false;
            setnewpassword(true);
            setnewpasswordMsg("New Password is required!");
        } else {
            setnewpassword(false);
            setnewpasswordMsg("");
        }
        return isValidFields;
    }

    function updatePassword() {              
        if (password && cpassword && password === cpassword) {
            APIManager.postAPI(
                changePassword +
                email +
                "&token=" + token +
                "&password=" +
                password.trim(),
                {},
                null,
                true
            ).then((response) => {
                if (response && response.status == 200) {     
                    navigate('/password-success');                           
                }
            });
        }
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowCPassword = () => {
        setcValues({
            ...cvalues,
            showPassword: !cvalues.showPassword,
        });
    };

    return (
        <>
            {/* <div class="float-btn float-btn-right">
                <button
                    class="btn btn-flat-primary btn-icon mb-2"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Change theme"
                >
                </button>
            </div> */}

            <div class="login-new change-password">
                <div class="container-fluid px-0">
                <div class="row no-gutters px-0 d-flex">
                        <div class="custom-col-7">
                            <div class="image-left">
                                <img src="./images/login/change-password.png" alt="#" />
                            </div>
                        </div>
                        <div class="custom-col-5">
                            <div class="field-right h-100">
                                <div className="wrap-class">
                                    <div className="login-title text-start">
                                        <h2>Change Password</h2>
                                        <span>Enter new password </span>
                                    </div>
                                    <form id="login-form">
                                        <FormControl
                                            class="custom-input"
                                            variant="standard"
                                        >
                                            <div className="form-group">

                                                <label for="password">New Password</label>
                                                <div className="po-relative-box">
                                                <input
                                                    type={!values.showPassword ? "password" : "text"}
                                                    name=""
                                                    placeholder="Password"
                                                    required
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                />
                                                {newpassword ? (
                                                    <div className="field-required">
                                                        <span> {newpasswordMsg}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    className="icon-btn"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {values.showPassword ? (
                                                        <FeatherIcon class="view-icon" icon="eye" />
                                                    ) : (
                                                        <FeatherIcon class="view-icon" icon="eye-off" />
                                                    )}
                                                </div>
                                                </div>
                                            </div>
                                        </FormControl>

                                        <FormControl
                                            class="custom-input mt-4"
                                            variant="standard"
                                        >
                                            <div className="form-group">

                                                <label for="password">Confirm New Password</label>
                                                <div className="po-relative-box">
                                                <input
                                                    type={!cvalues.showPassword ? "password" : "text"}
                                                    name=""
                                                    placeholder="Password"
                                                    required
                                                    value={cpassword}
                                                    onChange={(e) => {
                                                        setcpassword(e.target.value);
                                                    }}
                                                />
                                                {conformpassword ? (
                                                    <div className="field-required">
                                                        <span> {conformpasswordMsg}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    className="icon-btn"
                                                    onClick={handleClickShowCPassword}
                                                >
                                                    {cvalues.showPassword ? (
                                                        <FeatherIcon class="view-icon" icon="eye" />
                                                    ) : (
                                                        <FeatherIcon class="view-icon" icon="eye-off" />
                                                    )}
                                                </div>
                                                </div>
                                            </div>
                                        </FormControl>

                                        <div className="btn-login mt-5">
                                            <a
                                                type="submit"
                                                class="btn-primary"
                                                onClick={() => {
                                                    Validation();
                                                    formValidation();
                                                    updatePassword();
                                                }}
                                            >
                                                Save
                                            </a>
                                        </div>
                                        <div className="link-box">
                                            <a onClick={() => {
                                                navigate("/login")
                                            }}><span>Back to Login</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
