/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";

const StepperIndicator = ({ data, addStyles, activeStep }) => {
  return <div css={[styles.main,addStyles && addStyles]}>
    {data.map((step,index)=> (
        <>
        <div css={[styles.step, index === activeStep ? styles.active : step.isComplete ? styles.complete: styles.inactive ]}>
            {index + 1}
        </div>
        <div css={styles.stepName}>{step.name}</div>
        {index !== data.length -1 && <div css={styles.midline}></div>}
        </>
    ))}
  </div>;
};

export default StepperIndicator;