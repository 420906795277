/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Switch from "@semcore/ui/switch";
import Popper from "@semcore/ui/popper";
import {
  ErrorMessage,
  FastField,
  Field,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import LabeledInput from "../../shared/Input";
import * as Yup from "yup";
import LabeledDropDown from "../../shared/Dropdown/Labeled";
import { DisplayFormikState } from "../../../utility/formik-helper";
import RankingBuckets from "./buckets";
import DropDownRangeInput from "../../shared/Dropdown/RangeInput";
import RangeInput from "../../shared/Input/RangeInput";
import CustomButton from "../../shared/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setDetails,
  setDomainType,
  setFormState,
  setLocationOptions,
  setTouchedInputs,
} from "../createEditCampaign/campaignDetailSlice";
import { getLocations, validateCampaignName } from "../../../services/ranking";
import {
  capitalizeWords,
  getLocal,
  validateURL,
} from "../../../utility/helpers";
import SuggestionDropdown from "../../shared/Dropdown/suggestion";
import { defaultLocations } from "../../../utility/defaultLocations";
import { set } from "date-fns";
import { Colors } from "../../../styles/colors";

const searchEngine = {
  options: [
    { label: "https://www.google.com/", value: "https://www.google.com/" },
  ],
  active: [],
};

const frequency = {
  options: [
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
  ],
  active: [],
};

const CampaignDetails = ({ addStyles, next, isEdit }) => {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaignDetails.details);
  const domainType = useSelector((state) => state.campaignDetails.isDomain);
  const locationOptions = useSelector(
    (state) => state.campaignDetails.locationOptions
  );
  const touchedInputs = useSelector(
    (state) => state.campaignDetails.touchedInputs
  );
  const oldCampaignName = useSelector(
    (state) => state.campaignDetails.tempName
  );
  const [campaignNameDebounce, setCampaignNameDebounce] = useState("");
  const [isCampaignPresent, setIsCampaignPresent] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [isSearchingLoacation, setIsSearchingLocation] = useState(false);
  const [isCampaignNameInvalid, setIsCampaignNameInvalid] = useState(false);
  const projectId = getLocal("projectGId");
  const isDomain = useSelector((state) => state.campaignDetails.isDomain);
  const formRef = useRef();
  const deviceOptions = getLocal("organizationName") === "Infidigit" ? [
    { label: "Mobile", value: "Mobile" },
    { label: "Desktop", value: "Desktop" },
    { label: "Mobile or Desktop (Track Alternately)", value: "Alternate" },
  ] :  [{ label: "Mobile", value: "Mobile" },
  { label: "Desktop", value: "Desktop" },]
  const device = {
    options: deviceOptions,
    active: [],
  };

  useEffect(() => {
    const checkValidity = setTimeout(() => {
      if (!!campaignNameDebounce) {
        campaignNameValidateAPI();
      }
    }, 1000);

    return () => clearTimeout(checkValidity);
  }, [campaignNameDebounce]);

  useEffect(() => {
    domainType ? dispatch(setDomainType(true)) : dispatch(setDomainType(false));
  }, [domainType]);

  useEffect(() => {
    const locations = setTimeout(() => {
      if (locationInput.length > 2) {
        fetchLocations();
      } else {
        dispatch(
          setLocationOptions({ options: [...defaultLocations], active: "" })
        );
        setIsSearchingLocation(false);
      }
    }, 1000);
    return () => clearTimeout(locations);
  }, [locationInput]);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        setFormState({ component: "campaignDetails", isComplete: true })
      );
      dispatch(setFormState({ component: "addKeywords", isComplete: true }));
      dispatch(setFormState({ component: "addCompetitors", isComplete: true }));
    }
  }, []);

  const campaignNameValidateAPI = async () => {
    try {
      if (campaignNameDebounce === oldCampaignName) {
        setIsCampaignPresent(false);
      } else {
        const response = await validateCampaignName(
          campaignNameDebounce.trim(),
          projectId
        );
        if (response.status === "success") {
          setIsCampaignPresent(response.isPresent);
        }
      }
    } catch (error) {
      setIsCampaignPresent(true);
    }
  };

  const fetchLocations = async () => {
    try {
      const cities = [],
        countries = [];
      const response = await getLocations(locationInput);
      if (response.status === "success") {
        if (response.Country_List.length > 0) {
          response.Country_List.map((item) => {
            cities.push({
              label: item.countryname,
              value: item.countrycode,
              type: "Country",
            });
          });
        }
        if (response.City_List.length > 0) {
          response.City_List.map((item) => {
            countries.push({
              label: item.cityname,
              value: item.cityname,
              type: "City",
            });
          });
        }
        dispatch(
          setLocationOptions({ options: [...countries, ...cities], active: "" })
        );
        setIsSearchingLocation(false);
      }
    } catch (error) {
      setIsSearchingLocation(false);
    }
  };

  function validateArray(arr) {
    const isEmpty = arr.length === 0;
    let hasEmptyObject = false;
    let allObjectHaveValues = true;
    if (!isEmpty) {
      hasEmptyObject = arr.some((obj) => Object.keys(obj).length === 0);
      allObjectHaveValues = arr.every((obj) =>
        Object.values(obj).every(
          (value) => value !== undefined && value !== null && value !== ""
        )
      );
    }
    return !isEmpty && !hasEmptyObject && allObjectHaveValues;
  }

  const initialValues = {
    id: campaign.id,
    name: !!campaign.name ? campaign.name : "",
    domain: !!campaign.domain ? campaign.domain : "",
    subDomain: !!campaign.subDomain ? campaign.subDomain : "",
    location: !!campaign.location ? campaign.location : "",
    searchEngine: !!campaign.searchEngine
      ? campaign.searchEngine
      : "https://www.google.com/",
    frequency: !!campaign.frequency ? campaign.frequency : null,
    device: !!campaign.device ? campaign.device : null,
    torsoMin: !!campaign.torsoMin ? campaign.torsoMin : "",
    torsoMax: !!campaign.torsoMax ? campaign.torsoMax : "",
    buckets:
      !!campaign.buckets.length > 0
        ? campaign.buckets
        : [{ start: "", end: "" }],
  };

  const detailsSchema = Yup.object().shape({
    name: Yup.string().required("Campaign Name is a required field."),
    domain: Yup.string()
      .test({
        message: "Domain is a required field.",
        test: function (value) {
          if (isDomain) {
            return value !== "";
          } else {
            return true;
          }
        },
      })
      .test({
        message: "Please enter a valid domain.",
        test: function (value) {
          if (isDomain) {
            return validateURL(value, !isDomain);
          } else {
            return true;
          }
        },
      }),
    subDomain: Yup.string()
      .test({
        message: "Subdomain is a required field.",
        test: function (value) {
          if (!isDomain) {
            return value !== "";
          } else {
            return true;
          }
        },
      })
      .test({
        message: "Please enter a valid subdomain.",
        test: function (value) {
          if (!isDomain) {
            return validateURL(value, !isDomain);
          } else {
            return true;
          }
        },
      }),
    location: Yup.string().required("Location is a required field."),
    searchEngine: Yup.string().required("Search Engine is a required field."),
    frequency: Yup.string().required("Device is a required field."),
    device: Yup.string().required("Please select device."),
    buckets: Yup.array().test({
      message: "Ranking Bucket is a required field.",
      test: function (value) {
        return validateArray(value);
      },
    }),
    torsoMin: Yup.string()
      .required("Select min value")
      .test({
        message: "Min value cannot be less than 2",
        test: function (value) {
          return parseInt(value) > 1;
        },
      })
      .test({
        message: "Min value cannot be less than max value",
        test: function (value) {
          if (this.parent.torsoMax !== "") {
            return parseInt(value) < parseInt(this.parent.torsoMax);
          } else {
            return true;
          }
        },
      }),
    torsoMax: Yup.string()
      .required("Select max value.")
      .test({
        message: "Max value cannot be less than 3",
        test: function (value) {
          return parseInt(value) > 2;
        },
      })
      .test({
        message: "Max value should be greater than min value.",
        test: function (value) {
          if (this.parent.torsoMin !== "") {
            const torsoMin = this.parent.torsoMin;
            return parseInt(value) >= parseInt(torsoMin);
          } else {
            return true;
          }
        },
      }),
  });

  const onSubmit = (values) => {
    let data = {
      ...values,
      ...(isDomain
        ? { domain: values.domain, subDomain: null }
        : { domain: null, subDomain: values.subDomain }),
    };
    dispatch(setDetails(data));
    dispatch(setFormState({ component: "campaignDetails", isComplete: true }));
    dispatch(setActiveStep("addKeywords"));
  };

  useEffect(() => {
    formRef.current.validateField("domain");
  }, [isDomain]);

  const validateName = (value) => {
    let error;
    if (value.includes("/")) {
      setIsCampaignPresent(false);
      setIsCampaignNameInvalid(true);
    } else {
      setIsCampaignNameInvalid(false);
      setCampaignNameDebounce(value);
    }
    return error;
  };

  return (
    <div css={[styles.main, addStyles && addStyles]}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={detailsSchema}
        validateOnChange={true}
        validateOnMount={isEdit}
        touched={touchedInputs}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          isValid,
          setTouched,
          validateField,
        }) => {
          const handleTouched = (name) => {
            setTouched({ ...touchedInputs, [name]: true });
            dispatch(setTouchedInputs({ ...touchedInputs, [name]: true }));
          };
          return (
            <Form css={styles.form}>
              <FastField
                name="name"
                validateOnChange={true}
                validate={validateName}
              >
                {({ field }) => (
                  <LabeledInput
                    id={field.name}
                    placeholder={"Ranking Campaign Name"}
                    value={field.value}
                    onChange={(e) => {
                      setFieldValue("name", capitalizeWords(e.target.value));
                    }}
                    label={"Campaign Name"}
                    onBlur={() => {
                      setFieldValue(
                        "name",
                        capitalizeWords(values.name.trim())
                      );
                      validateName(values.name);
                      handleTouched("name");
                    }}
                    key={"name"}
                  />
                )}
              </FastField>
              <div css={styles.error}>
                {isCampaignNameInvalid ? (
                  "Campaign Name is invalid."
                ) : isCampaignPresent ? (
                  <div>Campaign Name Already Exist.</div>
                ) : errors.name && touched.name ? (
                  errors.name
                ) : (
                  ""
                )}
              </div>
              <FastField
                name={isDomain ? "domain" : "subDomain"}
                key={isDomain}
              >
                {({ field }) => {
                  return (
                    <LabeledInput
                      id={isDomain ? "domain" : "subDomain"}
                      placeholder={
                        isDomain ? "Enter Domain" : "Enter Sub Domain"
                      }
                      value={isDomain ? values.domain : values.subDomain}
                      onChange={(e) => {
                        if(isDomain){
                          setFieldValue("domain", e.target.value.trim());
                        } else {
                          setFieldValue("subDomain", e.target.value.trim());
                        }
                      }}
                      label={
                        isEdit ? (isDomain ? "Domain" : "Sub Domain") : false
                      }
                      selectLabel={
                        !isEdit && (
                          <div css={styles.label}>
                            {!isDomain ? "Sub Domain" : "Domain"}{" "}
                            <span css={styles.redText}>
                              *
                              <FeatherIcon
                                icon={"chevron-down"}
                                size={16}
                                color={Colors.neutral[900]}
                              />
                            </span>
                            :
                          </div>
                        )
                      }
                      selectOptions={{
                        options: [
                          {
                            label: "Domain",
                            value: "domain",
                            action: (e) => {
                              dispatch(setDomainType(true));
                            },
                          },
                          {
                            label: "Sub Domain",
                            value: "subdomain",
                            action: (e) => {
                              dispatch(setDomainType(false));
                            },
                          },
                        ],
                      }}
                      onBlur={() =>
                        handleTouched(isDomain ? "domain" : "subDomain")
                      }
                      key={"Domain"}
                      shouldTrim={true}
                    />
                  );
                }}
              </FastField>
              <div css={styles.error}>
                <ErrorMessage name={isDomain ? "domain" : "subDomain"} />
              </div>
              <div css={styles.formControll}>
                <div css={styles.label} htmlFor={"locaiton"}>
                  Location<span css={styles.redText}>*</span>:
                </div>
                <Field name="location">
                  {({ field }) => {
                    return (
                      <SuggestionDropdown
                        key={values.location}
                        data={locationOptions}
                        value={field.value}
                        rightIcon={"search"}
                        widthStyle={{ minWidth: "34.375rem" }}
                        handleDropdownClick={(value) => {
                          setFieldValue("location", value);
                        }}
                        handleSearch={(value) => {
                          setIsSearchingLocation(true);
                          setLocationInput(value);
                        }}
                        isDropdownLoading={isSearchingLoacation}
                        placeholder={"Select Location"}
                        onBlur={() => handleTouched("location")}
                      />
                    );
                  }}
                </Field>
              </div>
              <div css={styles.error}>
                <ErrorMessage name="location" />
              </div>
              <FastField name="searchEngine">
                {({ field }) => {
                  return (
                    <LabeledDropDown
                      options={searchEngine}
                      value={field.value}
                      label={"Search Engine"}
                      placeholder={"Select Search Engine"}
                      onChange={(value) => setFieldValue("searchEngine", value)}
                      key={"Search Engine"}
                      onBlur={() => handleTouched("searchEngine")}
                    />
                  );
                }}
              </FastField>
              <div css={styles.error}>
                <ErrorMessage name="searchEngine" />
              </div>
              <FastField name="device">
                {({ field }) => {
                  return (
                    <LabeledDropDown
                      key={"device"}
                      options={device}
                      value={field.value}
                      label={"Device"}
                      placeholder={"Select Device"}
                      onChange={(value) => setFieldValue("device", value)}
                      onBlur={() => handleTouched("device")}
                    />
                  );
                }}
              </FastField>
              <div css={styles.error}>
                <ErrorMessage name="device" />
              </div>
              <FastField name="frequency">
                {({ field }) => {
                  return (
                    <LabeledDropDown
                      options={frequency}
                      value={field.value}
                      label={"Frequency"}
                      placeholder={"Select Frequency"}
                      onChange={(value) => setFieldValue("frequency", value)}
                      onBlur={() => handleTouched("frequency")}
                      key={"frequency"}
                    />
                  );
                }}
              </FastField>
              <div css={styles.error}>
                <ErrorMessage name="frequency" />
              </div>
              <FastField name="buckets">
                {({ field }) => {
                  return (
                    <RankingBuckets
                      onChange={(buckets) => {
                        setFieldValue("buckets", buckets);
                      }}
                      value={field.value}
                      isEdit={isEdit}
                      onBlur={() => handleTouched("buckets")}
                      key={"buckets"}
                      configId={campaign.id}
                    />
                  );
                }}
              </FastField>
              <div css={styles.error}>
                {errors.buckets && touched.buckets ? errors.buckets : ""}
              </div>
              <div css={styles.subtitle}>
                Search Volume Range<span css={styles.redText}>*</span>:
              </div>
              <div css={styles.searchVolumeContainer}>
                <div css={styles.searchVolumeHead}>
                  <div css={styles.label}>Head :</div>
                  <div css={styles.value}>{`> ${
                    isEdit ? values.torsoMax : "Max. Search volume of Torso"
                  }`}</div>
                </div>
                <div css={styles.error}></div>
                <div css={styles.searchVolumeHead}>
                  <div css={styles.label}>
                    Torso<span css={styles.redText}>*</span>:
                  </div>
                  <RangeInput
                    type={"number"}
                    placeholderStart={"Min. Search volume"}
                    placeholderEnd={"Max. Search volume"}
                    startValue={values.torsoMin}
                    endValue={values.torsoMax}
                    onStartChange={(value) => setFieldValue("torsoMin", value)}
                    onEndChange={(value) => setFieldValue("torsoMax", value)}
                    inputContainerStyle={styles.rangeInputContainer}
                    startId={"inputStart"}
                    endId={"inputEnd"}
                    onStartBlur={touched.torsoMin}
                    onEndBlur={touched.torsoMax}
                    idStart={"torsoMin"}
                    idEnd={"torsoMax"}
                    key={"torsoMinMax"}
                  />
                </div>
                <div css={styles.error}>
                  <span>{errors.torsoMin ? errors.torsoMin : ""}</span>{" "}
                  <span>{errors.torsoMin ? " & " : ""}</span>{" "}
                  <span>{errors.torsoMax ? errors.torsoMax : ""}</span>
                </div>
                <div css={styles.searchVolumeHead}>
                  <div css={styles.label}>Tail :</div>
                  <div css={styles.value}>{`< ${
                    isEdit ? values.torsoMin : "Min. Search volume of Torso"
                  }`}</div>
                </div>
              </div>
              <div css={styles.buttonContainer}>
                <CustomButton
                  text={"Next"}
                  type={"submit"}
                  use={"primary"}
                  theme={"info"}
                  isDisable={!isValid || isCampaignPresent}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CampaignDetails;
