/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import Modal from '@semcore/ui/modal';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Colors } from "../../../../styles/colors";
import { textStyles } from '../../../../styles/font';
import CustomTimeButton from "../timeButton";
import CustomTicketingButton from "../../customButton2";
import CustomModal from "../modal";


const DeleteModal = ({ visible, handleModalClose, handleDelete }) => {
    return (
        <CustomModal width={"50rem"} visible={visible} handleModalClose={() => handleModalClose()}>
            <div>
                <div css={styles.modalBody}>
                    <div css={styles.icon("error")}>
                        <FeatherIcon icon={"trash-2"} color={Colors.error[800]} size={16} />
                    </div>
                    <div>
                        <div css={{ ...textStyles.h6 }}>{`Delete Ticket ?`}</div>
                        <div css={{ ...textStyles.H1Medium, color: Colors.neutral[500] }}>{`This will delete the ticket including all the subtickets.`}</div>
                    </div>
                </div>
                <div css={styles.modalFooter}>
                    <CustomTimeButton size="m" name={"Delete"} theme={"danger"} onClick={() => handleDelete()} />
                    <CustomTicketingButton onClick={() => { handleModalClose() }} buttonName={"Cancel"} size={5} buttonType={"btn-outline-secondary"} className={"me-3"} />
                </div>
            </div>
        </CustomModal>
    )
}

export default DeleteModal;