/** @jsxImportSource @emotion/react */
import React from "react";
import TabLine from "@semcore/ui/tab-line";
import * as styles from "./styles";

const Tabs = ({ tabs, onChane, activeTab }) => {
  if (tabs.length > 0) {
    return (
      <TabLine onChange={onChane} value={activeTab} aria-label="Page">
        {tabs.map((option, index) => (
          <TabLine.Item
            value={index}
            p={"0.5rem"}
            m={"0"}
            css={styles.tabLineItem(index === activeTab)}
          >
            <TabLine.Item.Text
              m={"0"}
              css={[
                styles.tabLineText,
                index === activeTab
                  ? styles.tabLineTextActive
                  : styles.tabLineTextInActive,
              ]}
            >
              {option}
            </TabLine.Item.Text>
          </TabLine.Item>
        ))}
      </TabLine>
    );
  } else {
    return <></>;
  }
};

export default Tabs;
