export const TicketSvg = ({ color = "white", width = 20, height = 20 }) => {
    return (
        // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <g clip-path="url(#clip0_1117_3003)">
        //         <path d="M16.668 9.99967C16.668 9.36634 17.0263 8.81634 17.5513 8.53301C18.0513 8.25801 18.3346 7.69134 18.3346 7.11634V4.99967C18.3346 4.08301 17.5846 3.33301 16.668 3.33301H3.33464C2.41797 3.33301 1.6763 4.07467 1.6763 4.99134V7.11634C1.6763 7.69134 1.9513 8.25801 2.45964 8.52467C2.98464 8.81634 3.33464 9.36634 3.33464 9.99967C3.33464 10.633 2.9763 11.1913 2.4513 11.4663C1.9513 11.7413 1.66797 12.308 1.66797 12.883V14.9997C1.66797 15.9163 2.41797 16.6663 3.33464 16.6663H16.668C17.5846 16.6663 18.3346 15.9163 18.3346 14.9997V12.883C18.3346 12.308 18.0513 11.7413 17.5513 11.4663C17.0263 11.183 16.668 10.633 16.668 9.99967ZM12.0846 13.4163L10.0013 12.083L7.91797 13.4247C7.6013 13.6247 7.19297 13.333 7.29297 12.9663L7.91797 10.5663L6.0013 8.99967C5.70964 8.75801 5.85964 8.28301 6.24297 8.25801L8.70964 8.11634L9.60963 5.82467C9.7513 5.47467 10.2513 5.47467 10.3846 5.82467L11.2846 8.12467L13.7513 8.26634C14.1263 8.29134 14.2846 8.76634 13.993 9.00801L12.0763 10.5747L12.7096 12.958C12.8096 13.333 12.4013 13.6247 12.0846 13.4163Z"
        //             fill={color} />
        //     </g>
        //     <defs>
        //         <clipPath id="clip0_1117_3003">
        //             <rect width="20" height="20" fill="white" />
        //         </clipPath>
        //     </defs>
        // </svg>

        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M21.0049 2.99979C21.5572 2.99979 22.0049 3.4475 22.0049 3.99979V9.49979C20.6242 9.49979 19.5049 10.6191 19.5049 11.9998C19.5049 13.3805 20.6242 14.4998 22.0049 14.4998V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V14.4998C3.38559 14.4998 4.50488 13.3805 4.50488 11.9998C4.50488 10.6191 3.38559 9.49979 2.00488 9.49979V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979H21.0049ZM20.0049 4.99979H4.00488V7.96779L4.16077 8.04886C5.49935 8.78084 6.42516 10.1733 6.49998 11.788L6.50488 11.9998C6.50488 13.704 5.55755 15.1869 4.16077 15.9507L4.00488 16.0308V18.9998H20.0049V16.0308L19.849 15.9507C18.5104 15.2187 17.5846 13.8263 17.5098 12.2116L17.5049 11.9998C17.5049 10.2956 18.4522 8.81266 19.849 8.04886L20.0049 7.96779V4.99979ZM16.0049 8.99979V14.9998H8.00488V8.99979H16.0049Z" fill={color} >
            </path>
        </svg>

    )
}