/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Tooltip from "@semcore/ui/tooltip";
import { Tool } from "feather-icons-react/build/IconComponents";
import AssetsImg from "../../assets/images";
import { Grow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleChart } from "../rankingReportSlice";
import Graph from "./graph";
import CustomTable from "./table";
import KeywordTable from "./keywordTable";
import ComparisionCard from "../comparisionCard";
import { formatDate } from "../../../utility/dates";
import LineChart from "./lineChart";
import { Status } from "../../../utility/Constant";
import SpinContainer from "@semcore/ui/spin-container";
import ErrorScreen from "../../shared/errorScreen";

const OverallRankingDistribution = ({ isGraphView }) => {
  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const isCustom = useSelector((state) => state.rankingReport.isCustom);
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const [graphdataPresent, setGraphdataPresent] = useState(false);
  const dispatch = useDispatch();

  return (
    <div css={styles.main}>
      <div css={styles.header}>
        <div>Overall Rank Distribution</div>
        <div css={styles.switchContainer}>
          <div
            css={styles.buttonGraph(isGraphView)}
            onClick={() => dispatch(toggleChart(true))}
          >
            <img
              css={styles.icon(isGraphView)}
              src={
                isGraphView
                  ? AssetsImg.ic_graphIconWhite
                  : AssetsImg.ic_graphIconBlack
              }
              alt="chart-icon"
            />
          </div>
          <div
            css={styles.buttonTable(isGraphView)}
            onClick={() => dispatch(toggleChart(false))}
          >
            <img
              css={styles.icon(isGraphView)}
              src={
                isGraphView
                  ? AssetsImg.ic_tableIconBlack
                  : AssetsImg.ic_tableIconWhite
              }
              alt="table-icon"
            />
          </div>
        </div>
      </div>
     {graphData.status === Status.EMPTY ?  <ErrorScreen
        title={"No Data Found"}
        icon={AssetsImg.ic_empty_table_icon}
        addStyles={styles.errorScreen}
      />: <>
     <SpinContainer
        loading={graphData.status === Status.LOADING}
        p="3px"
        size="m"
        theme="dark"
      >
        {!isGraphView ? <CustomTable /> : isCustom ? <LineChart /> : <Graph />}
      </SpinContainer>
      {isCompare &&
        graphData.active > -1 &&
        graphData?.progress?.length > 0 && (
          <div css={styles.comparisionCardcontainer}>
            <ComparisionCard
              title={"Week on Week Progress for"}
              subtitle={graphData.xAxisCategories[graphData.active]}
              compareDates={[
                formatDate(selectedDates.selection.startDate),
                formatDate(selectedDates.compare.startDate),
              ]}
              growth={graphData?.progress[graphData?.active]}
              comparision={[
                graphData?.series[0]?.data[graphData.active],
                graphData?.series[1]?.data[graphData.active],
              ]}
            />
          </div>
        )}
      <div css={styles.keywordTable}>
        <KeywordTable />
      </div>
      </>}
    </div>
  );
};

export default OverallRankingDistribution;
