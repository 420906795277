/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";
import * as styles from "./styles"
import { useState } from "react";
import TabLine from '@semcore/ui/tab-line';
import TicketTable from "../customComponents/ticketsTable";
import DateRangePicker from "../customComponents/dateRangePicker";
import { useTicketingStore } from "../ticketingStore";


const TicketListingPage = () => {
    const location = useLocation()

    const tableData = useTicketingStore((state) => state.table)
    const setTableData = useTicketingStore((state) => state.setTableData)

    const [tableTotal, setTableTotal] = useState(0)

    const currentTab = useTicketingStore((state) => state.currentTab)
    const setCurrentTab = useTicketingStore((state) => state.setCurrentTab)
    // const [currentTab, setCurrentTab] = useState(0)
    const tabData = [
        { label: "Assigned to Me", value: 0 },
        { label: "Created by Me", value: 1 },
        { label: "Available", value: 2 },
    ]

    return (
        <div>

            <div className="mb-3">
                <div css={{ ...textStyles.h6 }}>{`All Tickets (${tableTotal ?? "0"})`}</div>
                <div css={{ ...textStyles.H1Medium, color: Colors.neutral[500] }}>All the tickets are listed below</div>
            </div>

            {location.pathname?.split('/')[2] !== "team-tickets" && <div css={styles.tabContainer}>
                <TabLine value={currentTab} onChange={setCurrentTab} behavior='manual' underlined={false}>
                    {
                        tabData?.map((data, index) => {
                            return (
                                <TabLine.Item value={index}>
                                    <span css={{ ...textStyles.H1125SemiBold, color: currentTab == index ? Colors.primary[500] : Colors.neutral[500] }}>
                                        {data.label}
                                    </span>
                                </TabLine.Item>
                            )
                        })
                    }
                </TabLine>
            </div>}

            <div>
                <TicketTable tableTotal={tableTotal} setTableTotal={setTableTotal} />
            </div>
        </div>
    )
}

export default TicketListingPage;