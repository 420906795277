/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import CustomTimeButton from "../timeButton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CustomModal from "../modal";
import CustomButton from "../../../shared/button";

const TicketActionCard = ({ handleSubmit, lastUpdated }) => {
    const [modalOpen, setModalOpen] = useState({
        open: false,
        modal: "approve"
    })
    const modalBody = {
        approve: {
            icon: "alert-circle",
            iconColor: "",
            theme: "primary",
            color: Colors.primary[500],
            use: "info",
            title: "Confirm Approve",
            subText: "Once the ticket is approved, it will be permanently closed.",
            button: "Approve",
            action: () => { },
            status: "Completed"
        },
        reject: {
            icon: "trash-2",
            iconColor: Colors.error[800],
            theme: "error",
            use: "danger",
            color: Colors.error[800],
            title: "Confirm Reject",
            subText: "This will reassign the ticket back to the assigned team member. Are you sure you want to reject this ticket?",
            button: "Confirm Reject",
            action: () => { },
            status: "Rejected"
        }
    }

    const openModal = (type) => {
        setModalOpen({
            open: true,
            modal: type
        })
    }

    const closeModal = () => {
        setModalOpen({ ...modalOpen, open: false })
    }

    return (
        <>
            <div css={styles.actionContainer()}>
                <div css={{}}>
                    <div>{`Awaiting Approval`}</div>
                    <div css={{ ...textStyles.b3, color: Colors.neutral[500] }}>{`Submitted on ${lastUpdated}`}</div>
                </div>
                {true &&
                    <div css={{ display: "flex", alignItems: "center" }}>
                        <CustomTimeButton className={"me-2"} name={
                            <FeatherIcon icon={"check"} />
                        } theme="success" onClick={() => { openModal("approve") }} />
                        <CustomTimeButton name={
                            <FeatherIcon icon={"x"} />
                        } theme={"danger"} onClick={() => { openModal("reject") }} />
                    </div>
                }
            </div>
            <CustomModal width={"50rem"} visible={modalOpen.open} handleModalClose={() => closeModal()}>
                <div>
                    <div css={styles.modalBody}>
                        <div css={styles.icon(modalBody[modalOpen.modal]?.theme)}>
                            <FeatherIcon icon={modalBody[modalOpen.modal]?.icon} color={modalBody[modalOpen.modal]?.iconColor} size={16} />
                        </div>
                        <div>
                            <div css={{ ...textStyles.h6 }}>{modalBody[modalOpen.modal]?.title}</div>
                            <div css={{ ...textStyles.H1Medium, color: Colors.neutral[500] }}>{modalBody[modalOpen.modal]?.subText}</div>
                        </div>
                    </div>
                    <div css={styles.modalFooter}>
                        <CustomTimeButton size="m" name={modalBody[modalOpen.modal]?.button} theme={modalBody[modalOpen.modal]?.use}
                            onClick={() => {
                                handleSubmit(modalBody[modalOpen.modal]?.status)
                                closeModal()
                            }} />
                        <div css={styles.cancelButton(modalBody[modalOpen.modal]?.color)}>
                            <CustomButton onPress={() => closeModal()} text={"Cancel"} />
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}

export default TicketActionCard;