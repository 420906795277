/** @jsxImportSource @emotion/react */
import React from 'react'
import * as styles from "./styles";

const Subtitle = ({text, addStyles}) => {
  return (
    <div css={[styles.subtitle, addStyles && addStyles]}>
        {text}
    </div>
  )
}

export default Subtitle