import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  createAudit,
  getAuditModuleByOrgId,
  GET_FrontEnd_URL,
  GET_Server_URL,
  createCustomAudit,
} from "../../utility/Constant";
import JoditEditor from "jodit-pro-react";
import { useLocation } from "react-router-dom";

const AuditAddUpdate = ({ update, handleClick }) => {
  const location = useLocation();
  const [auditName, setAuditName] = useState("");
  const [auditTitle, setAuditTitle] = useState("");
  const [message, setMessage] = useState("");
  const [auditDescription, setAuditDescription] = useState("");
  const [recommendation, setAuditrecommendation] = useState("");

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        const field = "files";
        if (data[field] && data[field].length) {
          for (let i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
    enter: "div"
  };

  useEffect(() => {
    getAuditModuleList();   
  }, []);

  function addAudit(auditName, auditTitle, waitageSelected, auditDescription, recommendation) {
    var bodyFormData = new FormData();
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    if (location.state?.seoAuditId) {
      if (localStorage.getItem("projectGId") !== "") {
        bodyFormData.append("projectId", localStorage.getItem("projectGId"));
        bodyFormData.append("projectAuditId", location.state?.seoAuditId);
      }
    }
    bodyFormData.append("name", auditName.trim());
    bodyFormData.append("pointTitle", auditTitle);
    bodyFormData.append("AuditModuleId", auditSelected);
    bodyFormData.append("waitage", waitageSelected);
    bodyFormData.append("Description", auditDescription.trim());
    bodyFormData.append("Recommendation", recommendation.trim());
    APIManager.postAPI(location.state?.seoAuditId ? createCustomAudit : createAudit, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status === 200) {
          clearData();
        }
      }
    );
  }

  function clearData() {
    descriptionData2 = "";
    setAuditTitle("");
    setAuditName("");
    setWaitageSelected("");
    if (location.state?.seoAuditId) {
      update("seoAuditSelection");
    } else {
      update("auditChecklist");
    }
  }

  const [auditList, setAuditList] = useState([]);
  const [auditSelected, setAuditSelected] = useState("");
  const [waitageSelected, setWaitageSelected] = useState(10);

  function getAuditModuleList() {
    APIManager.getAPI(
      getAuditModuleByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        setAuditName(response.data[0]?.auditModuleName);
        let auditModuleName;
        response.data.forEach((item) => {
          let obj = {
            id: item.id,
            name: item.auditModuleName,
          };
          list.push(obj);
          setAuditSelected(response?.data[0]?.id);
          if (location.state?.moduleName1 === item.id) {
            auditModuleName = item.auditModuleName;
          }
        });
        setAuditList(list);
        if (location.state?.moduleName1) {
          setAuditSelected(location.state?.moduleName1);
          setAuditName(auditModuleName);
        }
      }
    });
  }

  var descriptionData2 = "";
  var auditreacomndation = "";

  return (
    <>
      <div className="custom-column-70 auditaddupdate_main">
        {/* <div class="portlet mt-3 slideInUp setting-top  "> */}
        <div class={location.state?.seoAuditId ? "slideInUp" : "portlet mt-3 slideInUp setting-top"}>

          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => {
              if (location.state?.seoAuditId) {
                update("seoAuditSelection");
              } else {
                update("auditChecklist");
              }
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Add Audit Point
            </h3>
          </div>
          <div className="portlet-body">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <div class="col-md-12">
                      <label className="form-label">Module</label>
                      <select
                        required
                        value={auditSelected}
                        onChange={(e) => {
                          setAuditSelected(e.target.value);
                        }}
                      >
                        {auditList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>

                    {location.state?.seoAuditId ? (<></>) : (<>
                      {/* <div
                      style={getCheckPermission(
                        "audit_checklist",
                        "organization_setup"
                      ) ? { display: "block" } : { display: "none" }}
                      class="col-md-12 mt-3"
                    > */}
                      <div class="col-md-12 mt-3">
                        <label className="form-label">Audit Weightage</label>
                        <input
                          type="number"
                          className="form-control w-100"
                          placeholder="0"
                          min="0"
                          max="20"
                          value={waitageSelected}
                          onChange={(e) => {
                            setWaitageSelected(e.target.value);
                          }}
                        />
                      </div>
                    </>)}
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Audit Point</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        value={auditTitle}
                        onChange={(text) => {
                          setAuditTitle(text.target.value);
                        }}
                        placeholder="Enter Audit Point Title"
                      />
                      <div class="ms-3" style={{ color: "red" }}>
                        {message}
                      </div>
                    </div>
                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Description</label>
                      <JoditEditor
                        value={auditDescription}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {
                          descriptionData2 = text;
                          setAuditDescription(descriptionData2);
                        }}
                      ></JoditEditor>
                    </div>
                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Recommendation</label>
                      <JoditEditor
                        value={recommendation}
                        config={config}
                        tabIndex={2}
                        onBlur={(txt) => {
                          auditreacomndation = txt;
                          setAuditrecommendation(auditreacomndation);
                        }}
                      ></JoditEditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portlet-footer portlet-footer-bordered">
            <div className="col-md-12">
              <button
                class="btn btn-primary me-3"
                onClick={() => {
                  if (auditTitle !== '') {
                    addAudit(
                      auditName,
                      auditTitle,
                      waitageSelected,
                      auditDescription,
                      recommendation
                    );
                    handleClick()
                  } else {
                    setMessage("Audit Point is required!");
                  }

                }}
              >
                Save
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  clearData();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditAddUpdate;
