/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../../styles/colors";

const TrendLineChart = ({ data, isPositive, addStyles, width, height,isStable }) => {
  const [chartData, setGraphData] = useState({
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
      height: "100%"
    },
    markers: {
        discrete: [{
            seriesIndex: 0,
            dataPointIndex: data.length -1,
            fillColor: isStable?Colors.secondary[900] : isPositive ? Colors.semantic[500] : Colors.error[800],
            strokeColor: '#fff',
            size: 5,
            shape: "circle" // "circle" | "square" | "rect"
          }]
    },
    dataLabels: {
      enabled: false,
    },
    colors: [isStable?Colors.secondary[900] : isPositive ? Colors.semantic[500] : Colors.error[800]],
    series: [
      {
        name: "Series A",
        data: data,
      },
    ],
    stroke: {
      width: 1,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      // min: Math.min([...data]),
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
  });

  useEffect(() => {
    // setGraphData(options)
  }, [data]);

  const chart = useRef(null);

  return (
    <div css={addStyles && addStyles}>
      <Chart
        id="LINE"
        options={chartData}
        series={chartData.series}
        type="line"
        width={"100%"}
        height={"100%"}
        ref={chart}
      />
    </div>
  );
};

export default TrendLineChart;
