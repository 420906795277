import Chart from "react-apexcharts";
import { useEffect } from "react";
import React, { useState } from "react";
import APIManager from "../../services/APIManager";
import {
  getSeoAuditByProjectAuditId,
  getSEOAuditGetById,
  getSEOAuditGraphData,
} from "../../utility/Constant";
import { toMonthName } from "../../utility/Globals";

function ImplementationChart() {
  const [options, setObject] = useState({});
  const [changeRequestList, setchangeRequestList] = useState([]);
  const [implementedList, setimplementedList] = useState([]);
  const [wipList, setwipList] = useState([]);
  const [onHoldList, setonHoldList] = useState([]);
  const [pendingList, setpendingList] = useState([]);
  const [implementationList, setimplementationList] = useState([]);

  useEffect(() => {
    getAuditList();
  }, []);

  function getAuditList() {
    APIManager.getAPI(
      getSEOAuditGetById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let auditList = [];
        response.data?.audits.map((item) => {
          if (item.isShown == true) {
            auditList.push(item);
          }
        });
        if (auditList && auditList.length > 0) {
          getSeoAuditByProjectAuditIdList(auditList[0].seoAuditId);
        }
      }
    });
  }

  function getSeoAuditByProjectAuditIdList(projectAuditId) {
    APIManager.getAPI(
      getSeoAuditByProjectAuditId +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      projectAuditId +
      "&view="
    ).then((response) => {
      if (response && response.status == 200) {
        let totalNCP = 0;
        response.data?.seoAuditItems.map((item) => {
          totalNCP = totalNCP + item.nonCompliantPoints;
        });
        setNcp(totalNCP);
        getAuditChartData(2, projectAuditId, totalNCP);
      }
    });
  }

  const [ncp, setNcp] = useState(0);

  function getAuditChartData(id, auditId, totalNCP) {
    const createdDateListData = [];
    let changeRequestList = [];
    let changeRequestListTemp = [];
    let implementationList = [];
    let implementedList = [];
    let implementedListTemp = [];
    let onHoldList = [];
    let onHoldListTemp = [];
    let pendingList = [];
    let pendingListTemp = [];
    let wipList = [];
    let wipListTemp = [];
    let monthlyList = [];

    const d = new Date();
    let currentMonth = d.getMonth() + 1;
    let currentYear = d.getFullYear();
    if (id == 2) {
      if (currentMonth > 3) {
        for (let i = 3; i >= 1; i--) {
          createdDateListData.push(toMonthName((currentMonth - i), "Months") + " " + currentYear);
          monthlyList.push({ monthNo: currentMonth - i, year: currentYear })
        }
        createdDateListData.push(toMonthName(currentMonth, "Months") + " " + currentYear);
        monthlyList.push({ monthNo: currentMonth, year: currentYear });
      } else if (currentMonth <= 3) {
        for (let i = 3; i >= 1; i--) {
          let month = currentMonth - i;
          createdDateListData.push(toMonthName((currentMonth - i), "Months") + " " + (currentYear - 1));
          if (month <= 0) {
            month = 12 - (month < 0 ? -month : month);
            monthlyList.push({ monthNo: month, year: currentYear - 1 });
          } else {
            monthlyList.push({ monthNo: month, year: currentYear })
          }
        }
        createdDateListData.push(toMonthName(currentMonth, "Months") + " " + currentYear);
        monthlyList.push({ monthNo: currentMonth, year: currentYear });
      }
    }
    APIManager.getAPI(
      getSEOAuditGraphData +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId +
      "&view=" +
      id
    ).then((response) => {
      if (response && response.status == 200) {

        if (id == 2) {
          monthlyList.map(x => {
            if (!response.data.some(m => m.monthNo == x.monthNo && m.years == x.year)) {
              response.data.push({
                "years": x.year,
                "monthNo": x.monthNo,
                "months": toMonthName(x.monthNo, "Months"),
                "projectAuditName": "",
                "changeRequest": 0,
                "implemented": 0,
                "implementation": 0,
                "onHold": 0,
                "pending": 0,
                "wip": 0
              })
            } 
          })
        }
        response.data?.map((item) => {
          if (item.monthNo == 0 && item.years == 0) { return; }
          else {
            if (id == 0) {
              createdDateListData.push(item.quartersRange);
            }
            if (id == 1) {
              createdDateListData.push(item.halfYearlyRange);
            }
            let crTotoal = 0;
            let implementedTotal = 0;
            let onHoldTotal = 0;
            let pendingTotal = 0;
            let wipTotal = 0;

            changeRequestListTemp.push(item.changeRequest);
            implementedListTemp.push(item.implemented);
            onHoldListTemp.push(item.onHold);
            pendingListTemp.push(item.pending);
            wipListTemp.push(item.wip);

            changeRequestListTemp?.map((i, index) => {
              crTotoal = (crTotoal + i) - (index + 1 >= 1 ? response.data[index + 1 - 1].implemented : 0);
            });
            implementedListTemp?.map((i) => {
              implementedTotal = (implementedTotal + i);
            });
            onHoldListTemp?.map((i) => {
              onHoldTotal = (onHoldTotal + i);
            });
            pendingListTemp?.map((i) => {
              pendingTotal = (pendingTotal + i);
            });
            wipListTemp?.map((i) => {
              wipTotal = (wipTotal + i);
            });
            let NCP = totalNCP == 0 ? ncp : totalNCP;
            if (id != 2 || ((currentMonth > 3 && item.monthNo <= currentMonth && item.monthNo >= currentMonth - 3 && item.years == currentYear) || (currentMonth <= 3 && (item.monthNo <= currentMonth && item.years == currentYear) || (item.monthNo >= (12 - (4 - (currentMonth + 1))) && item.monthNo <= 12 && item.years == currentYear - 1)))) {
              changeRequestList.push(Math.round(crTotoal));
              implementedList.push(Math.round(implementedTotal * 100) / 100);
              onHoldList.push(Math.round(onHoldTotal * 100) / 100);
              pendingList.push(Math.round(pendingTotal * 100) / 100);
              wipList.push(Math.round(wipTotal * 100) / 100);
              implementationList.push(
                Math.round(((implementedTotal * 100) / NCP) * 100) / 100
              );
            }
          }
        });
        setchangeRequestList(changeRequestList);
        setimplementationList(implementationList);
        setimplementedList(implementedList);
        setonHoldList(onHoldList);
        setpendingList(pendingList);
        setwipList(wipList);
        setChartData(createdDateListData);
      }
    });
  }

  function setChartData(createdDateList) {
    setObject({
      chart: {
        height: 350,
        width: 500,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0, 0, 0, 0, 3],
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        offsetY: -9,
        style: {
          fontSize: '12px',
          colors: ["#000000"],
          fontWeight: 'normal',
        },
        background: {
          enabled: false,
        }
      },
      labels: createdDateList,
      grid: {
        show: false,
        padding: {
          left:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
          right:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
        },
      },
      yaxis: [
        {
          seriesName: "Changes Requested",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#757575",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#91E395",
          },
          labels: {
            style: {
              colors: "#91E395",
            },
          },
          title: {
            text: "Implemented",
            style: {
              color: "#91E395",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "WIP",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "On Hold",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Pending",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Implementation %",
          opposite: true,
          max: 100,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#735EC9",
          },
          labels: {
            style: {
              colors: "#735EC9",
            },
          },
          title: {
            style: {
              color: "#735EC9",
            },
          },
        },
      ],
    });
  }

  return (<>
    <div>
      <h4 className="heading_static_name_implementation d-block d-md-none my-5 ">Implementation Status</h4>
      <Chart
        options={options}
        series={[
          {
            name: "Changes Requested",
            type: "column",
            color: "#37b3f9",
            data: changeRequestList,
          },
          {
            name: "WIP",
            type: "column",
            color: "#ffa624",
            data: wipList,
          },
          {
            name: "On Hold",
            type: "column",
            color: "#ff4248",
            data: onHoldList,
          },
          {
            name: "Pending",
            type: "column",
            color: "#ffd957",
            data: pendingList,
          },
          {
            name: "Implemented",
            type: "column",
            color: "#91E395",
            data: implementedList,
          },
          {
            name: "Implementation %",
            type: "line",
            color: "#735EC9",
            data: implementationList,
          },
        ]}
        type="line"
        height="300"
      />
    </div>
  </>)
}

export default ImplementationChart;