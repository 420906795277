import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const inputContainer = css({
    display: "flex",
    alignItems: "center",
    "input": {
        ...textStyles.H1125SemiBold,
        padding: "0.5rem !important",
        // ...custom,
        textAlign: "center"
    }

})

export const inputStyles = (custom) => css({
    ".___SValue_11bfw_gg_:not(:disabled):read-only~.___SOutline_11bfw_gg_": {
        background: "none !important"
    },
    "input": {
        ...textStyles.H1125SemiBold,
        // padding: "0.5rem",
        ...custom,
    }
})

export const inputSeperator = css({
    ...textStyles.H18SemiBold,
    margin: "0 0.5rem 1.5rem 0.5rem"
})
