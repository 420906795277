import React, { useEffect, useState, useRef } from "react";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import toast from "react-hot-toast";
import {
  createDAPA,
  getCompetitorsByProjectId,
  getDAPAconfig,
  getUrlByOrgId,
  documentFileType,
  GET_Server_URL,
  createUrl,
  dapaConfigImport,
} from "../../utility/Constant";
import {
  frequencyListt,
  selectAllList,
  themeStyle,
  frequencyListKeyValue,
  isValidImportFile,
} from "../../utility/Globals";

const DAPAChecker = ({ update }) => {
  const [Comprtitorsselected, setCompetitorsSelected] = useState([]);
  const [urlList, setURlList] = useState([]);
  const [competitorsList, setCompetitorsList] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [frequencyDAPA, setFrequencyDAPA] = useState();
  const [value, setValue] = useState();
  const [oldCompList, setOldCompList] = useState([]);
  const [competitors, setCompetitors] = useState("");
  const notifyUpdate = () => toast.success("Successfully Updated!");
  const [contentArray, setContentArray] = useState([
    {
      id: 1,
      urlKeywordCWC: "",
      CompetitorSelected: [],
    },
  ]);
  const [IsActive, SetIsActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [urlName, setURLName] = useState("");

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label className="text-elipsis ms-2 mt-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    getUrlList();
    setFrequency(frequencyListt[0].value);
  }, []);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  function importFile() {
    let input = inputFileRef.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid == true) {
      for (var i = 0; i < input.files.length; ++i) { }
      uploadImportFile(input.files.item(0));
      resetFileInput();
    }
  }

  function uploadImportFile(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("File", file);
    bodyFormData.append("ProjectId", localStorage.getItem("projectGId"));
    bodyFormData.append("Frequency", frequencyDAPA);
    APIManager.postAPI(dapaConfigImport, {}, bodyFormData, false).then(
      (response) => {
        if (response.status == 200) {
          getUrlList();
          getDAPAData();
        }
      }
    );
  }

  const resetFileInput = () => {
    inputFileRef.current.value = null;
  };

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.push({
      id: tempArray.length + 1,
      urlKeywordCWC: urlList[0]?.id,
      CompetitorSelected: [],
    });
    setContentArray(tempArray);
  }

  function subtrackContent(index) {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.splice(index, 1);
    setContentArray(tempArray);
  }

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        getUrlList(localStorage.getItem("projectGId"));
        SetIsActive(false);
        setSelectedIndex("");
      }
    });
  }

  function addCompetitors() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: competitors,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitors("");
        getCompetitorsList();
        SetIsActive2(false);
        SetIsActive3(false);
        SetIsActive4(false);
        setSelectedIndex("");
      }
    });
  }

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          list.push({
            key: "key",
            value: "Select All",
            label: "Select All",
            id: 0,
            url: "Select All",
          });
          response.data.map((item) => {
            let obj = {
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
            };
            list.push(obj);
          });
          setURlList(list);
          getCompetitorsList();
          getDAPAData();
          let temp = contentArray;
          temp[0].urlKeywordCWC = list[0].id;
          setContentArray(temp);
        }
      }
    );
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          comp: "Select All",
        });
        response.data.map((item) => {
          let obj = {
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            comp: item.fieldValue,
          };
          list.push(obj);
        });
        setCompetitorsList(list);
      }
    });
  }

  function getDAPAData() {
    APIManager.getAPI(getDAPAconfig + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          if (response?.data) {
            setFrequencyDAPA(response.data.frequency);
            const List = [];
            response?.data?.dapaUrlConfigurations?.map((item) => {
              const URLsList = [];
              item?.dapaUrlConfigurations?.map((item) => {
                URLsList.push({
                  key: item.urls.id,
                  id: item.urls.id,
                  value: item.urls.fieldValue,
                  label: item.urls.fieldValue,
                });
              });
              const cList = [];
              item?.dapaCompetitorConfigurations?.map((item) => {
                cList.push({
                  id: item?.competitors?.id,
                  key: item.competitors?.id,
                  label: item?.competitors?.fieldValue,
                  value: item?.competitors?.fieldValue,
                  comp: item?.competitors?.fieldValue,
                });
              });
              List.push({
                id: item.id,
                urlKeywordCWC: item.urlId,
                CompetitorSelected: cList,
              });
            });
            setContentArray(List);
          }
        }
      }
    );
  }

  function addupdateDAPAModule() {
    let mainList = [];
    const competitorList = [];
    contentArray.map((item) => {
      let competitorList = [];
      item.CompetitorSelected.map((b) => {
        if (b.id != "0") {
          competitorList.push({ CompetitorId: b.id });
        }
      });
      let chield1 = {
        UrlId: item.urlKeywordCWC,
        DAPACompetitorConfigurations: competitorList,
      };
      mainList.push(chield1);
    });
    let objMain = {
      Frequency: frequencyDAPA,
      ProjectId: localStorage.getItem("projectGId"),
      DAPAUrlConfigurations: mainList,
      DAPACompetitorConfigurations: competitorList,
    };
    let list2 = Comprtitorsselected.splice(
      Comprtitorsselected.findIndex((e) => e.value != "Select All"),
      Comprtitorsselected.length
    );
    list2.map((item) => {
      competitorList.push({
        CompetitorId: item.id,
      });
    });
    APIManager.postAPI(createDAPA, objMain, null, true).then((response) => {
      if (response && response.status == 200) {
        update("");
        notifyUpdate();
      }
    });
  }
  const inputFileRef = useRef(null);
  const [IsActive2, SetIsActive2] = useState(false);
  const [IsActive3, SetIsActive3] = useState(false);
  const [IsActive4, SetIsActive4] = useState(false);

  return (
    <div className="custom-column-70 websiteauthority_main">
      <div className="portlet common-form-fields slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("projectGName")} - Website Authority
          </h3>
        </div>

        <div className="portlet-body px-4">
          <div className="col-md-12  d-flex justify-content-sm-end justify-content-start tab-panel-url-options">
            <span class="export me-0">
              <input
                style={{ display: "none" }}
                type="file"
                id="DAPAFile"
                name="DAPAFile"
                multiple={false}
                accept={documentFileType}
                ref={inputFileRef}
                onChange={() => {
                  importFile();
                }}
              />
              <a
                class="btn btn-primary download-temp mr-3 mb-3 mb-sm-0"
                // style={{ marginLeft: 18 + "px" }}
                target="_blank"
                href={GET_Server_URL + "audit/WebsiteAuthority.xlsx"}
              >
                Download Template
              </a>
              <button
                class="btn btn-primary import mb-3 mb-sm-0"
                onClick={onBtnClick}
              >
                Import
              </button>
            </span>
          </div>

          <div className="tab-panel-url-options mt-3"></div>
          <div className="table-responsive">
            <table class="col-md-12 dapa-table table-modules text-center table table-bordered  table-hover  px-0">
              <thead class="table-modules-head">
                <tr>
                  <th className="contenttable w-50">URLs</th>
                  <th className="contenttable w-50">Competitor</th>
                  <th className="contenttable"></th>
                </tr>
              </thead>
              <tbody>
                {contentArray.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td className="px-0 pe-4">
                        <div class="col-md-12 d-flex justify-content-between px-0 select_overflow">
                          <select
                            className="form-select w-100"
                            value={item.urlKeywordCWC}
                            onChange={(e) => {
                              var tempArray = JSON.parse(
                                JSON.stringify(contentArray)
                              );
                              tempArray[index].urlKeywordCWC = e.target.value;
                              setContentArray(tempArray);
                              setValue({});
                            }}
                          >
                            {urlList.map((item) => {
                              return <option value={item.id}>{item.url}</option>;
                            })}
                          </select>
                          <button
                            className="border plus ms-3"
                            style={{ background: "#ffff" }}
                            onClick={() => {
                              SetIsActive(true);
                              setSelectedIndex(index);
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-plus-circle "
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        </div>
                        {IsActive && selectedIndex == index ? (
                          <div class="col-md-12">
                            <label></label>
                            <div className="timecheck-icon d-flex">
                              <input
                                className="w-100"
                                type="text"
                                name=""
                                placeholder="Enter URL"
                                value={urlName}
                                onChange={(text) => {
                                  setURLName(text.target.value);
                                }}
                              />
                              <button
                                style={{ border: "none", background: "#ffff" }}
                                onClick={() => {
                                  SetIsActive(false);
                                  setSelectedIndex("");
                                  setURLName("");
                                }}
                              >
                                {" "}
                                <i
                                  class="fa fa-times d-flex ms-3"
                                  aria-hidden="true"
                                ></i>{" "}
                              </button>
                              <button
                                style={{ border: "none", background: "#ffff" }}
                                id={"rightbtn"}
                                onClick={() => {
                                  if (urlName) {
                                    addUrl();
                                  }
                                }}
                              >
                                {" "}
                                <i
                                  class="fa fa-check"
                                  aria-hidden="true"
                                ></i>{" "}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="px-0 pe-4">
                        <div class="col-md-12 d-flex justify-content-between addclose_button">
                          <ReactSelect
                            styles={themeStyle()}
                            className="w-200"
                            options={competitorsList}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={(selected) => {
                              var tempArray = JSON.parse(
                                JSON.stringify(contentArray)
                              );
                              tempArray[index].CompetitorSelected = selectAllList(
                                selected,
                                oldCompList,
                                competitorsList
                              );
                              setContentArray(tempArray);
                              setOldCompList(tempArray[index].CompetitorSelected);
                              setValue({});
                            }}
                            value={item.CompetitorSelected}
                            allowSelectAll={true}
                          />
                          <button
                            className="border plus ms-3"
                            style={{ background: "#ffff" }}
                            onClick={() => {
                              SetIsActive2(true);
                              setSelectedIndex(index);
                            }}
                          >
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                          </button>
                        </div>
                        {IsActive2 && selectedIndex == index ? (
                          <div class="col-md-12">
                            <label></label>
                            <div className="timecheck-icon d-flex align-item-center crosstrue_icon">
                              <input
                                className="w-100"
                                type="text"
                                name=""
                                placeholder="Enter competitors"
                                value={competitors}
                                onChange={(text) => {
                                  setCompetitors(text.target.value);
                                }}
                              />
                              <button
                                style={{ border: "none", background: "#ffff" }}
                                onClick={() => {
                                  SetIsActive2(false);
                                  setSelectedIndex("");
                                  setCompetitors("");
                                }}
                              >
                                {" "}
                                <i
                                  class="fa fa-times d-flex"
                                  aria-hidden="true"
                                ></i>{" "}
                              </button>
                              <button
                                style={{ border: "none", background: "#ffff" }}
                                id={"rightbtn"}
                                onClick={() => {
                                  if (competitors) {
                                    addCompetitors();
                                  }
                                }}
                              >
                                {" "}
                                <i
                                  class="fa fa-check"
                                  aria-hidden="true"
                                ></i>{" "}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}{" "}
                      </td>
                      <td className="px-0 pe-4">
                        <td class="plus-minus-btn  d-flex pt-0">
                          {contentArray.length > 1 && (
                            <button
                              className="me-3"
                              style={{ border: "none", background: "#ffff" }}
                              onClick={() => subtrackContent(index)}
                            >
                              {" "}
                              <i
                                class="fa fa-minus-square"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>
                          )}
                          {contentArray.length - 1 === index && (
                            <button style={{ border: "none", background: "#ffff" }} onClick={addContent} classNamems="ms-3">
                              {" "}
                              <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>
                          )}
                        </td>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div class="col-md-12 mt-3 px-0">
            <label htmlFor="" style={{ marginRight: 24 + "px" }}>
              Frequency
            </label>
            <br />
            <select
              style={{ padding: 8 + "px" }}
              class="form-select w-100"
              value={frequencyDAPA}
              onChange={(e) => {
                setFrequencyDAPA(e.target.value);
              }}
            >
              {frequencyListKeyValue.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered pb-4">
          <div class="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addupdateDAPAModule();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DAPAChecker;
