import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import APIManager from "../services/APIManager";
import { changePassword } from "../utility/Constant";
import { useNavigate } from "react-router-dom";

function NewPasswordSet() {
  const [email, setemail] = useState("");
  const [token, settoken] = useState("");

  useEffect(() => {
    const paramss = new URLSearchParams(window.location.search);
    let email = paramss.get("email");
    setemail(email);
    let token = paramss.get("token");
    settoken(token);
  }, []);

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [conformpassword, setconformpassword] = useState(false);
  const [conformpasswordMsg, setconformpasswordMsg] = useState("");
  const [newpassword, setnewpassword] = useState(false);
  const [newpasswordMsg, setnewpasswordMsg] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [cvalues, setcValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  function formValidation() {
    let isValidFields = true;
    if (cpassword.trim() == "") {
      isValidFields = false;
      setconformpassword(true);
      setconformpasswordMsg("Confirm Password is required!");
    } else {
      setconformpassword(false);
      setconformpasswordMsg("");
    }
    return isValidFields;
  }

  function Validation() {
    let isValidFields = true;
    if (password.trim() == "") {
      isValidFields = false;
      setnewpassword(true);
      setnewpasswordMsg("New Password is required!");
    } else {
      setnewpassword(false);
      setnewpasswordMsg("");
    }
    return isValidFields;
  }

  function updatePassword() {
    if (password && cpassword && password === cpassword) {
      APIManager.postAPI(
        changePassword +
        email +
        "&token=" + token +
        "&password=" +
        password.trim(),
        {},
        null,
        true
      ).then((response) => {
        if (response && response.status == 200) {
          navigate("/login");
        }
      });
    }
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowCPassword = () => {
    setcValues({
      ...cvalues,
      showPassword: !cvalues.showPassword,
    });
  };

  return (
    <>
      <div class="holder">
        <div class="wrapper pl-0 login-page">
          <div class="content ">
            <div class="container-fluid">
              <div class="row no-gutters align-items-center justify-content-center h-100">
                <div class="portlet overflow-hidden hv-center">
                  <div class="row no-gutters">
                    <div class="col-md-12" id="login-form">
                      <h4 className="fw-bold mb-4">Set Password</h4>
                      <div class="form-wrappers p-0 ">
                        <label class="">New Password</label>
                        <label class="field-required"> * </label>
                        <div className="position-relative">
                          <input
                            class="d-flex w-100 px-3"
                            type={!values.showPassword ? "password" : "text"}
                            name=""
                            placeholder=""
                            required
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          ></input>
                          {newpassword ? (
                            <div className="field-required">
                              <span> {newpasswordMsg}</span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className="icon-btn position-absolute"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="form-wrappers p-0 my-4">
                        <label>Confirm Password</label>
                        <label class="field-required"> * </label>
                        <div className="position-relative">
                          <input
                            class="d-flex w-100 px-3"
                            type={!cvalues.showPassword ? "password" : "text"}
                            name=""
                            placeholder=""
                            required
                            value={cpassword}
                            onChange={(e) => {
                              setcpassword(e.target.value);
                            }}
                          ></input>
                          {conformpassword ? (
                            <div className="field-required">
                              <span> {conformpasswordMsg}</span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className="icon-btn position-absolute"
                            onClick={handleClickShowCPassword}
                          >
                            {cvalues.showPassword ? (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-100 text-end">
                        <div
                          class="btn btn-primary"
                          onClick={() => {
                            Validation();
                            formValidation();
                            updatePassword();
                          }}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPasswordSet;
