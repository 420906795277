import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  DDMMyyyyFormateDateSet,
  dateRagngeSelectWeek,
  downloadPDFfile,
  exportInToCSV,
  exportToEXCEL,
  formatRankDate,
  getBase64path,
  // getCheckPermission,
  mmDDyyyyFormateDate,
  removeHttp,
  dateRagngeSelectFornightly,
  dateRagngeSelectMonthly,
  getWeek,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import {
  createRankTrackingDataByCompititor,
  getRankTrackingReportConfig,
  createRankTrackingdata,
  getCategoryByProject,
  getKeywordTypeAPI,
  createRankTrackingDataByCompititortable,
  sendEmailApi,
  getRTKeywordHistory,
  getRankingTrendsTable,
  getRankingTrendsTableExport,
  moduleRankTracking,
  createRankTrackingdataByPage
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import APIManager from "../services/APIManager";
import { PDFExport } from "@progress/kendo-react-pdf";
import "jspdf-autotable";
import Loading from "../utility/Loading";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

const Option = (props) => {
  return (
    <div className="select-list">
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function ModuleExpandRankTracking() {
  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    yaxis: {
      title: {
        legend: {
          show: true,
          position: "bottom",
          floating: true,
          verticalAlign: "bottom",
          align: "center",
        },
      },
    },
  });

  const [selectedDeviceType, setDeviceType] = useState("0");
  const [series, setSeries] = useState([]);
  const [selectedRankExportList, setSelectedRankExportList] = useState([]);
  const [rankexportList, setRankExportList] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [selectedRankTrendsExportList, setSelectedRankTrendsExportList] =
    useState([]);
  const [teamlist, setteamList] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [teamcol, setteamcol] = useState([]);
  const [keytablelist, setkeytablelist] = useState([]);
  const [trendsColist, settrendsColist] = useState([]);

  const [selectionTypeKeyTable, setSelectionTypeKeyTable] =
    useState("checkbox");
  const [keyTableCol, setkeyTableCol] = useState([]);
  const [trendsCol, settrendsCol] = useState([]);

  const [displayTable, setDisplayTable] = useState(false);
  const [competitorSelected, setcompetitorSelected] = useState([0]);
  const [competitorSelected2, setcompetitorSelected2] = useState([]);
  const [competitorsList, setCompetitorsList] = useState([]);

  const [noContent, setNoContent] = useState(false);
  const [noConfig, setNoConfig] = useState(false);

  const [isdDeviceType, setIsDeviceType] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [keywordTypeList, setKeywordTypeList] = useState([]);
  const [selectedCategory, setselectedCategory] = useState({ id: "0" });
  const [selectedCategory2, setselectedCategory2] = useState([]);
  const [selectedType, setselectedType] = useState({ id: "0" });
  const [selectedType2, setselectedType2] = useState([]);
  const [subject, setSubject] = useState("Rankings Reports");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [selectedProject, setselectedProject] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [lastUD, setLastUD] = useState("");
  const [dataList, setDataList] = useState([]);
  const [lastUpdateDate, setDate] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
    },
  });
  const [tableParams1, setTableParams1] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
    },
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber1, setPageNumber1] = useState(1);
  const [pageSize1, setPageSize1] = useState(10);
  const [calendarType, setCalendarType] = useState(0);

  function handleChangecompeSelectedBottom() {
    applyBtn2.current.click();
    let ids = [];
    ids.push(competitorSelected.value);

    let rowdata;
    if (selectedCategory.id == "0" && selectedType.id == "0") {
      rowdata = {
        Urls: ids,
        FromTrackDate: mmDDyyyyFormateDateSet(head1),
        ToTrackDate: mmDDyyyyFormateDateSet(head2),
        ProjectId: localStorage.getItem("projectGId"),
        DeviceType: selectedDeviceType,
      };
    } else {
      rowdata = {
        Urls: ids,
        FromTrackDate: mmDDyyyyFormateDateSet(head1),
        ToTrackDate: mmDDyyyyFormateDateSet(head2),
        ProjectId: localStorage.getItem("projectGId"),
        DeviceType: selectedDeviceType,
        CategoryId: selectedCategory.id,
        TypeId: selectedType.id,
      };
    }
    generatecompetitor(rowdata);
    generatecompetitortable(rowdata);
    generateranktracking(selectedDeviceType, isExpand, selectedProject);
    getRankingsTreandsTableData(selectedDeviceType);
  }

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    getRnkTrackingConfig();
    getCategory();
    //getRankingsTreandsTableData();
  }, []);

  const rankrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRankExportList(selectedRows);
    },
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let listData = [];
      selectedRows.map((j) => {
        exportList.map((i) => {
          if (j.keyword === i.keyword) {
            listData.push(i);
          }
        });
      });
      setSelectedExportList(listData);
    },
  };

  const ranktrendsrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRankTrendsExportList(selectedRows);
    },
  };

  function getRnkTrackingConfig() {
    APIManager.getAPI(
      getRankTrackingReportConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data.rankTrackingConfiguration) {
          setNoConfig(false);
          setCalendarType(response?.data.rankTrackingConfiguration.frequency);

          // var devicet =
          // response.data.rankTrackingConfiguration.deviceType.toString();
          setDeviceType(
            response.data.rankTrackingConfiguration.deviceType.toString()
          );
          if (response.data.rankTrackingConfiguration.deviceType == 2) {
            setIsDeviceType(true);
            setDeviceType("0");
            //devicet = "0";
          }

          //setDeviceType(response?.data.rankTrackingConfiguration.deviceType);

          if (response?.data.rankTrackingConfiguration.frequency == 1) {
            var todayDate = new Date();
            var week = getWeek(new Date(todayDate));

            let selection = {
              selection1: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection1",
              },
              selection2: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.rankTrackingConfiguration.frequency == 2) {
            var todayDate = new Date();

            let selection = {
              selection1: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.rankTrackingConfiguration.frequency == 3) {
            var todayDate = new Date();
            var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
            var lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

            let selection = {
              selection1: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection1",
              },
              selection2: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else {
            let selection = {
              selection1: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          }

          var columns = [
            {
              title: "Rank Group",
              dataIndex: "rankgroup",
              key: "rankgroup",
              sorter: true,
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
              },
            },
          ];
          setteamcol(columns);
          columns = [];
          setkeyTableCol(columns);
          setteamList([]);
          setkeytablelist([]);
          setDisplayTable(true);
          let isExpandVar = false;
          setIsExpand(isExpandVar);
          getRanktrackData(isExpandVar);
        } else {
          setNoConfig(true);
        }
      }
    });
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }

      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  const [RankTracking, setRankTracking] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [options1, setObject1] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      show: false,
      tickPlacement: "on",
      categories: [],
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: {
      show: false,
      logBase: 0,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  const [series1, setSeries1] = useState([
    {
      name: "",
      data: [],
      color: "#2196f3",
    },
  ]);

  function generatecompetitor(rowdata1) {
    APIManager.postAPI(
      createRankTrackingDataByCompititor,
      rowdata1,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        if (response.data.length) {
          let createdDateList = [];
          let series1 = [];
          let series2 = [];
          let series3 = [];
          let maxVal = 0;
          let selectedSeries = 1;
          let selectedSeriesName = "Rank 1";

          response.data.map((item) => {
            var keys = Object.keys(item);
            var values = Object.values(item);
            keys.map((key, index) => {
              switch (key) {
                case "rank 1":
                  series1.push(values[index]);
                  if (values[index] > maxVal) {
                    maxVal = values[index];
                    selectedSeries = 1;
                    selectedSeriesName = "Rank 1";
                  }
                  break;
                case "rank 2-5":
                  series2.push(values[index]);
                  if (values[index] > maxVal) {
                    maxVal = values[index];
                    selectedSeries = 2;
                    selectedSeriesName = "Rank 2-5";
                  }
                  break;
                case "rank 6-10":
                  series3.push(values[index]);
                  if (values[index] > maxVal) {
                    maxVal = values[index];
                    selectedSeries = 3;
                    selectedSeriesName = "Rank 6-10";
                  }
                  break;
              }
            });
            createdDateList.push(DDMMyyyyFormateDateSet(item.trackDate));
          });

          let option = {
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              tickPlacement: "on",
              title: {
                text: "Rank Date",
                style: {
                  color: "#000",
                },
              },
            },
            labels: createdDateList,
            dataLabels: {
              style: {
                colors: ["#e0e0e0"],
              },
            },
            grid: {
              show: true,
              padding: {
                left: 50,
                right: 50,
              },
            },
            yaxis: [
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 1 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: true,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 2 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 3 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 4 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 5 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
              {
                seriesName: selectedSeriesName,
                show: selectedSeries == 6 ? true : false,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                  color: "#008FFB",
                },
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                },
                title: {
                  text: "Rank",
                  style: {
                    color: "#008FFB",
                  },
                },
              },
            ],
          };
          setObject(option);
          var nseries = [
            {
              name: "Rank 1",
              type: "column",
              data: series1,
            },
            {
              name: "Rank 2-5",
              type: "column",
              data: series2,
            },
            {
              name: "Rank 6-10",
              type: "column",
              data: series3,
            },
          ];
          setSeries(nseries);
        } else {
          setSeries([]);
        }
      }
    });
  }

  function generatecompetitortable(rowdata1) {
    APIManager.postAPI(
      createRankTrackingDataByCompititortable,
      rowdata1,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let i = 0;
        var columns = [
          {
            title: "Rank Date",
            dataIndex: "trackDate",
            key: "trackDate",
          },
          {
            title: "Rank 1",
            dataIndex: "rank 1",
            key: "rank 1",
          },
          {
            title: "Rank 2-5",
            dataIndex: "rank 2-5",
            key: "rank 2-5",
          },
          {
            title: "Rank 6-10",
            dataIndex: "rank 6-10",
            key: "rank 6-10",
          },
          {
            title: "Rank 11-20",
            dataIndex: "rank 11-20",
            key: "rank 11-20",
          },
          {
            title: "Rank 21-30",
            dataIndex: "rank 21-30",
            key: "rank 21-30",
          },
          {
            title: "Rank 31-40",
            dataIndex: "rank 31-40",
            key: "rank 31-40",
          },
          {
            title: "Rank 41-50",
            dataIndex: "rank 41-50",
            key: "rank 41-50",
          },
          {
            title: "Rank 61-70",
            dataIndex: "rank 61-70",
            key: "rank 61-70",
          },
          {
            title: "Rank 71-100",
            dataIndex: "rank 71-100",
            key: "rank 71-100",
          },
          {
            title: "Total",
            dataIndex: "total",
            key: "total",
          },
        ];

        setteamcol(columns);
        if (response.data?.length) {
          response.data.map((item, index) => {
            let keys = [];
            keys = Object.keys(response.data[index]);
            let obj = {};
            obj["key"] = i += 1;
            keys.map((key) => {
              if (key == "trackDate") {
                obj[key] = DDMMyyyyFormateDateSet(item[key]);
              } else {
                obj[key] = item[key];
              }
            });
            list.push(obj);
          });
          setteamList(list);
          setRankExportList(exportList);
        } else {
          setteamList(list);
          setRankExportList(exportList);
        }
      }
    });
  }

  function getRanktrackData(isExpandVar) {
    setIsExpand(isExpandVar);
    APIManager.getAPI(
      getRankTrackingReportConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let urlList = [];
        let projectUrl =
          response?.data?.rankTrackingConfiguration.project?.domainName;
        setselectedProject(
          response?.data?.rankTrackingConfiguration.project?.domainName
        );
        urlList.push({
          key: response?.data?.rankTrackingConfiguration.project?.id,
          id: response?.data?.rankTrackingConfiguration.project?.id,
          value: response?.data?.rankTrackingConfiguration.project?.domainName,
          label: response?.data?.rankTrackingConfiguration.project?.domainName,
        });
        urlList.push({
          key: response.data.rankTrackingConfiguration.urlId,
          id: response.data.rankTrackingConfiguration.urlId,
          value: response.data.rankTrackingConfiguration.urls,
          label: response.data.rankTrackingConfiguration.urls,
        });
        const complist = [];
        const complist1 = [];
        const keywordslist = [];
        complist.push({
          key: response?.data?.rankTrackingConfiguration.project?.id,
          id: response?.data?.rankTrackingConfiguration.project?.id,
          value: response?.data?.rankTrackingConfiguration.project?.domainName,
          label: response?.data?.rankTrackingConfiguration.project?.domainName,
        });
        response.data.rankTrackingConfiguration.rankTrackingCompetitorConfigurations.map(
          (item) => {
            complist1.push({
              key: item.competitorId,
              id: item.competitorId,
              value: item.competitor,
              label: item.competitor,
            });
          }
        );
        setcompetitorSelected(complist[0]);
        setCompetitorsList(complist1);

        // response.data?.rankTrackingConfiguration.rankTrackingKeywordConfigurations.map(
        //   (item) => {
        //     keywordslist.push({
        //       key: item.keywordId,
        //       id: item.keywordId,
        //       value: item.keyword,
        //       label: item.keyword,
        //     });
        //   }
        // );

        var devicet = response.data.rankTrackingConfiguration.deviceType.toString();
        if (response.data.rankTrackingConfiguration.deviceType == 2) {
          devicet = "0";
        }

        let ids = [];
        let idList = [];
        idList.push(complist[0].value);
        let rowdata;
        if (selectedCategory.id == "0" && selectedType.id == "0") {
          rowdata = {
            Urls: idList,
            FromTrackDate: mmDDyyyyFormateDateSet(head1),
            ToTrackDate: mmDDyyyyFormateDateSet(head2),
            ProjectId: localStorage.getItem("projectGId"),
            DeviceType: devicet,
          };
        } else {
          rowdata = {
            Urls: idList,
            FromTrackDate: mmDDyyyyFormateDateSet(head1),
            ToTrackDate: mmDDyyyyFormateDateSet(head2),
            ProjectId: localStorage.getItem("projectGId"),
            DeviceType: devicet,
            CategoryId: selectedCategory.id,
            TypeId: selectedType.id,
          };
        }
        generatecompetitor(rowdata);
        generatecompetitortable(rowdata);
        generateranktracking(devicet, isExpandVar, projectUrl);
        getRankingsTreandsTableData(devicet);
      }
    });
  }

  function generateranktrackingExport(devicet, isExpandVar, projectUrl, tag) {
    // setIsExpand(isExpandVar); 
    // var devicetype = selectedDeviceType;
    // if (devicet) {
    //   devicetype = devicet;
    // }
    let subURL = "";
    if (selectedCategory?.id != "0" && selectedType?.id != "0") {
      subURL =
        "projectid=" +
        localStorage.getItem("projectGId") +
        "&devicetype=" +
        devicet +
        "&categoryId=" +
        selectedCategory.id +
        "&typeId=" +
        selectedType.id;
    } else {
      subURL =
        "projectid=" +
        localStorage.getItem("projectGId") +
        "&devicetype=" +
        devicet;
    }

    APIManager.getAPI(createRankTrackingdata + subURL, {}, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const exportlist = [];
          //setNoContent(!response.data?.length);
          setDate(response.data[0].trackDate);
          let i = 0;
          if (response.data?.length) {
            response.data.map((item) => {
              let keys = [];
              const columns = [];
              keys = Object.keys(response.data[0]);
              let keys1 = [];
              let indexUrl;
              let urlLink;
              keys.map((item, index) => {
                if (item == projectUrl) {
                  indexUrl = index;
                  urlLink = index + 1;
                }
              });
              keys.map((item, index) => {
                if (index <= 4) {
                  keys1.push(item);
                } else if (index == indexUrl || index == urlLink) {
                  keys1.push(item);
                }
              });
              keys.map((item, index) => {
                if (index <= 4) {
                  return;
                } else if (index == indexUrl || index == urlLink) {
                  return;
                } else {
                  keys1.push(item);
                }
              });
              let exportobj = {};
              var totalColumn = 0;
              keys1.map((key, index) => {
                let colName;
                if (
                  key != "keyword" &&
                  key != "trackDate" &&
                  key != "country" &&
                  key != "category" &&
                  key != "type" &&
                  key != "searchVolume"
                ) {
                  colName = removeHttp(key);
                  totalColumn++;
                } else {
                  colName = key;
                }
                if (key != "trackDate" && (totalColumn <= 2 || isExpandVar)) {
                  let col = {
                    title: colName,
                    dataIndex: colName,
                    key: colName,
                    sorter: {
                      compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                      multiple: 3,
                    },
                  };
                  columns.push(col);
                }
                if (index == 5) {
                  if (item[key] != null) {
                    exportobj[
                      (key)] = item[key];
                  } else {
                    exportobj[(key)] = "";
                  }
                } else if (key == "trackDate") {
                  let date = mmDDyyyyFormateDate(item[key]);
                  setLastUD(date);
                  exportobj[key] = date;
                } else if (
                  key != "keyword" &&
                  key != "trackDate" &&
                  key != "country"
                ) {
                  if (item[key] != null) {
                    if (item[keys1[index - 1]] == 0) {
                      exportobj[(key)] = "";
                    } else {
                      exportobj[(key)] = item[key];
                    }
                  } else {
                    exportobj[(key)] = "";
                  }
                } else {
                  if (item[key] != null) {
                    exportobj[key] = item[key];
                  } else {
                    exportobj[removeHttp(key)] = "";
                  }
                }
              });
              exportlist.push(exportobj);
            });
            if (tag == "excel") {
              exportToEXCEL(
                selectedExportList?.length > 0
                  ? selectedExportList
                  : exportlist,
                "Rankings"
              );
            }
            else if (tag == "csv") {
              exportInToCSV(
                selectedExportList?.length > 0
                  ? selectedExportList
                  : exportlist,
                "Rankings"
              );
            }
          }
        }
      }
    );
  }

  function generateranktracking(devicet, isExpandVar, projectUrl, pageNo, pSize) {
    setIsExpand(isExpandVar);
    // var devicetype = selectedDeviceType;
    // if (devicet) {
    //   devicetype = devicet;
    // }
    let subURL = "";
    if (selectedCategory?.id != "0" && selectedType?.id != "0") {
      subURL = {
        projectId: localStorage.getItem("projectGId"),
        deviceType: devicet,
        categoryId: selectedCategory.id,
        typeId: selectedType.id,
        currentPage: pageNo ? pageNo : pageNumber,
        pageSize: pSize ? pSize : pageSize,
      }
    } else {
      subURL = {
        projectId: localStorage.getItem("projectGId"),
        deviceType: devicet,
        currentPage: pageNo ? pageNo : pageNumber,
        pageSize: pSize ? pSize : pageSize,
      }
    }
    APIManager.postAPI(createRankTrackingdataByPage, subURL, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setDataList(response.data.table1);
          const list = [];
          const exportlist = [];
          //setNoContent(!response.data?.table1?.length);
          setDate(response.data?.table1[0].trackDate);
          let i = 0;
          if (response.data?.table1.length) {
            response.data.table1.map((item) => {
              let keys = [];

              const columns = [];
              keys = Object.keys(response.data.table1[0]);
              let keys1 = [];
              let indexUrl;
              let urlLink;
              keys.map((item, index) => {
                if (item == projectUrl) {
                  indexUrl = index;
                  urlLink = index + 1;
                }
              });
              keys.map((item, index) => {
                if (index <= 4) {
                  keys1.push(item);
                } else if (index == indexUrl || index == urlLink) {
                  keys1.push(item);
                }
              });
              keys.map((item, index) => {
                if (index <= 4) {
                  return;
                } else if (index == indexUrl || index == urlLink) {
                  return;
                } else {
                  keys1.push(item);
                }
              });

              let obj = {};
              let exportobj = {};
              var totalColumn = 0;
              keys1.map((key, index) => {
                let colName;
                if (
                  key != "keyword" &&
                  key != "trackDate" &&
                  key != "country" &&
                  key != "category" &&
                  key != "type" &&
                  key != "searchVolume"
                ) {
                  colName = removeHttp(key);
                  totalColumn++;
                } else {
                  colName = key;
                }
                if (key != "trackDate" && (totalColumn <= 2 || isExpandVar)) {
                  let col = {
                    title: colName,
                    dataIndex: colName,
                    key: colName,
                    sorter: {
                      compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                      multiple: 3,
                    },
                  };
                  columns.push(col);
                }

                obj["key"] = i += 1;

                if (index == 5) {
                  if (item[key] != null) {
                    obj[removeHttp(key)] = (
                      <div
                        className="col-md-12 d-flex justify-content-between"
                      >
                        {item[key]}
                        <div
                          onClick={() => {
                            let keywordName = item.keyword;
                            let keywordValue = item[key];
                            getRankTrackingKeywordHistory(
                              keywordName,
                              keywordValue
                            );
                            setActive("#bcdefa");
                          }}
                        >
                          <i
                            class="fa fa-plus-circle position-relative"
                            aria-hidden="true"
                          ></i>{" "}
                        </div>
                      </div>
                    );
                    exportobj[removeHttp(key)] = item[key];
                  } else {
                    obj[removeHttp(key)] = "---";
                    exportobj[removeHttp(key)] = "---";
                  }
                } else if (key == "trackDate") {
                  let date = mmDDyyyyFormateDate(item[key]);
                  setLastUD(date);
                  obj[key] = date;
                  exportobj[key] = date;
                } else if (
                  key != "keyword" &&
                  key != "trackDate" &&
                  key != "country"
                ) {
                  if (item[key] != null) {
                    if (item[keys1[index - 1]] == 0) {
                      // obj[removeHttp(key)] = "---";
                      exportobj[removeHttp(key)] = "---";
                    } else {
                      obj[removeHttp(key)] = item[key];
                      exportobj[removeHttp(key)] = item[key];
                    }
                  } else {
                    obj[removeHttp(key)] = "---";
                    exportobj[removeHttp(key)] = "---";
                  }
                } else {
                  if (item[key] != null) {
                    obj[key] = item[key];
                    exportobj[key] = item[key];
                  } else {
                    obj[removeHttp(key)] = "---";
                    exportobj[removeHttp(key)] = "---";
                  }
                }
              });
              list.push(obj);
              exportlist.push(exportobj);
              setkeyTableCol(columns);
            });
          }
          setExportList(exportlist);
          setkeytablelist(list);
          let totalPageCount;
          response.data.table.map((item) => {
            totalPageCount = item.totalCount;
          })
          setTableParams({
            pagination: {
              current: subURL.currentPage,
              pageSize: subURL.pageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: totalPageCount,
            },
          });
        }
      }
    );
  }

  const handleTableChange = (pagination) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
    generateranktracking(selectedDeviceType, isExpand, selectedProject, pagination.current, pagination.pageSize);
  };

  const handleTableChange1 = (pagination) => {
    setPageNumber1(pagination.current);
    setPageSize1(pagination.pageSize);
    getRankingsTreandsTableData(selectedDeviceType, pagination.current, pagination.pageSize);
  };
  const [active, setActive] = useState("#fff");

  function expandTableData(isExpandVar) {
    setIsExpand(isExpandVar);
    const list = [];
    let i = 0;
    if (dataList?.length) {
      dataList.map((item) => {
        let keys = [];
        const columns = [];
        keys = Object.keys(dataList[0]);
        let keys1 = [];
        let indexUrl;
        let urlLink;
        keys.map((item, index) => {
          if (item == selectedProject) {
            indexUrl = index;
            urlLink = index + 1;
          }
        });
        keys.map((item, index) => {
          if (index <= 4) {
            keys1.push(item);
          } else if (index == indexUrl || index == urlLink) {
            keys1.push(item);
          }
        });
        keys.map((item, index) => {
          if (index <= 4) {
            return;
          } else if (index == indexUrl || index == urlLink) {
            return;
          } else {
            keys1.push(item);
          }
        });

        let obj = {};
        obj["key"] = i += 1;
        var totalColumn = 0;
        keys1.map((key, index) => {
          let colName;
          if (
            key != "keyword" &&
            key != "trackDate" &&
            key != "country" &&
            key != "category" &&
            key != "type" &&
            key != "searchVolume"
          ) {
            colName = removeHttp(key);
            totalColumn++;
          } else {
            colName = key;
          }
          if (key != "trackDate" && (totalColumn <= 2 || isExpandVar)) {
            let col = {
              title: colName,
              dataIndex: colName,
              key: colName,
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                multiple: 3,
              },
            };
            columns.push(col);
          }

          if (index == 5) {
            if (item[key] != null) {
              obj[removeHttp(key)] = (
                <div
                  className="col-md-12 d-flex justify-content-between"
                >
                  {item[key]}
                  <div
                    onClick={() => {
                      let keywordName = item.keyword;
                      let keywordValue = item[key];
                      getRankTrackingKeywordHistory(keywordName, keywordValue);
                      setActive("#bcdefa");
                    }}
                  >
                    <i
                      class="fa fa-plus-circle position-relative"
                      aria-hidden="true"
                    ></i>{" "}
                  </div>
                </div>
              );
            } else {
              obj[removeHttp(key)] = "---";
            }
          } else if (key == "trackDate") {
            let date = mmDDyyyyFormateDate(item[key]);
            setLastUD(date);
            obj[key] = date;
          } else if (
            key != "keyword" &&
            key != "trackDate" &&
            key != "country"
          ) {
            if (item[key] != null) {
              if (item[keys1[index - 1]] == 0) {
                // obj[removeHttp(key)] = "---";
              } else {
                obj[removeHttp(key)] = item[key];
              }
            } else {
              obj[removeHttp(key)] = "---";
            }
          } else {
            if (item[key] != null) {
              obj[key] = item[key];
            } else {
              obj[removeHttp(key)] = "---";
            }
          }
        });

        list.push(obj);
        setkeyTableCol(columns);
      });
    }
    setkeytablelist(list);
  }

  function getCategory() {
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          key: "0",
          value: "All",
          label: "All",
          id: "0",
          category: "All",
        });
        response.data.map((item) => {
          list.push({
            key: item.id,
            value: item.categoryType,
            label: item.categoryType,
            id: item.id,
            category: item.categoryType,
          });
        });
        setCategoryList(list);
        getKeywordType();
      }
    });
  }

  function getKeywordType() {
    APIManager.getAPI(
      getKeywordTypeAPI + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          key: "0",
          value: "All",
          label: "All",
          id: "0",
          keywordType: "All",
        });
        response.data.map((item) => {
          list.push({
            key: item.id,
            value: item.type,
            label: item.type,
            id: item.id,
            keywordType: item.type,
          });
        });
        setKeywordTypeList(list);
      }
    });
  }

  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Rank Tracking Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);

  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image);
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: moduleRankTracking,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status == 200) {
        setReceiver("");
        setSubject("RankTracking Reports");
        setEmailMessage(EmailMessage1);
        if (theme == 'dark') {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }
  const [isChartDivOpen, setisChartDivOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isChartDivOpen && ref.current && !ref.current.contains(e.target)) {
        setisChartDivOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isChartDivOpen]);

  function getRankTrackingKeywordHistory(keywordName) {
    APIManager.getAPI(
      getRTKeywordHistory +
      localStorage.getItem("projectGId") +
      "&keywordName=" +
      keywordName +
      "&device=" +
      selectedDeviceType +
      "&startDate=" +
      mmDDyyyyFormateDateSet(head1) +
      "&endDate=" +
      mmDDyyyyFormateDateSet(head2)
    ).then((response) => {
      if (response && response.status == 200) {
        setisChartDivOpen(!isChartDivOpen);
        const keywordValueList = [];
        const trackDateList = [];
        response.data.map((item) => {
          keywordValueList.push(item.trackRank);
          var date = new Date(item.trackDate);
          var date1 =
            (date.getMonth() > 8
              ? date.getMonth() + 1
              : "0" + (date.getMonth() + 1)) +
            "/" +
            (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            date.getFullYear();

          trackDateList.push(date1);
          setObject1({
            chart: {
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              show: false,
              tickPlacement: "on",
              categories: trackDateList,
              labels: {
                formatter: function (value) {
                  return value;
                },
              },
            },
            yaxis: {
              show: false,
              logBase: 0,
              labels: {
                formatter: function (value) {
                  return value;
                },
              },
            },
          });
          setSeries1([
            {
              name: "",
              data: keywordValueList,
              color: "#2196f3",
            },
          ]);
        });
      }
    });
  }

  function GetcolName(colName) {
    if (
      colName == "keyword" ||
      colName == "searchVolume" ||
      colName == "keywordCategory" ||
      colName == "keywordType"
    ) {
      switch (colName) {
        case "keyword":
          return "Keywords";
        case "searchVolume":
          return "Search Volume";
        case "keywordCategory":
          return "Keyword Category";
        case "keywordType":
          return "Keyword Type";
      }
    } else {
      return formatRankDate(colName);
    }
  }

  function getRankingsTreandsTableDataExport(tag) {
    let rowDataForTable;
    if (selectedCategory.id == "0" && selectedType.id == "0") {
      rowDataForTable = {
        projectId: localStorage.getItem("projectGId"),
        url: competitorSelected.value,
        startDate: mmDDyyyyFormateDateSet(head1),
        endDate: mmDDyyyyFormateDateSet(head2),
        DeviceType: selectedDeviceType,
      };
    } else {
      rowDataForTable = {
        projectId: localStorage.getItem("projectGId"),
        url: competitorSelected.value,
        startDate: mmDDyyyyFormateDateSet(head1),
        endDate: mmDDyyyyFormateDateSet(head2),
        DeviceType: selectedDeviceType,
        CategoryId: selectedCategory.id,
        TypeId: selectedType.id,
      };
    }
    APIManager.postAPI(getRankingTrendsTableExport, rowDataForTable, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          let i = 0;
          response.data.map((item) => {
            let keys = [];
            const columns = [];
            keys = Object.keys(response.data[0]);
            let obj = {};
            obj["key"] = i += 1;
            keys.map((key) => {
              let colName;
              colName = key;
              let col = {
                title: GetcolName(colName),
                dataIndex: colName,
                key: colName,
                sorter: {
                  compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                  multiple: 3,
                },
              };
              columns.push(col);
              obj[key] = item[key];
            });
            list.push(obj);
            settrendsCol(columns);
          });
          if (tag == "excel") {
            exportToEXCEL(
              selectedRankTrendsExportList?.length >
                0
                ? selectedRankTrendsExportList
                : list,
              "Ranking Trends"
            );
          }
          else if (tag == "csv") {
            exportInToCSV(
              selectedRankTrendsExportList?.length >
                0
                ? selectedRankTrendsExportList
                : list,
              "Ranking Trends"
            );
          }
        }
      }
    );
  }

  function getRankingsTreandsTableData(deviceType, pageNo, pSize) {
    let rowDataForTable;
    if (selectedCategory.id == "0" && selectedType.id == "0") {
      rowDataForTable = {
        projectId: localStorage.getItem("projectGId"),
        url: competitorSelected.value,
        startDate: mmDDyyyyFormateDateSet(head1),
        endDate: mmDDyyyyFormateDateSet(head2),
        DeviceType: deviceType,
        currentPage: pageNo ? pageNo : pageNumber1,
        pageSize: pSize ? pSize : pageSize1,
      };
    } else {
      rowDataForTable = {
        projectId: localStorage.getItem("projectGId"),
        url: competitorSelected.value,
        startDate: mmDDyyyyFormateDateSet(head1),
        endDate: mmDDyyyyFormateDateSet(head2),
        DeviceType: deviceType,
        CategoryId: selectedCategory.id,
        TypeId: selectedType.id,
        currentPage: pageNo ? pageNo : pageNumber1,
        pageSize: pSize ? pSize : pageSize1,
      };
    }
    APIManager.postAPI(getRankingTrendsTable, rowDataForTable, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          if (typeof response.data.table1 != "undefined") {
            const list = [];
            let i = 0;
            response?.data?.table1.map((item) => {
              let keys = [];
              const columns = [];
              keys = Object.keys(response?.data?.table1[0]);
              let obj = {};
              obj["key"] = i += 1;
              keys.map((key) => {
                let colName;
                colName = key;
                let col = {
                  title: GetcolName(colName),
                  dataIndex: colName,
                  key: colName,
                  sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                    multiple: 3,
                  },
                };
                columns.push(col);
                obj[key] = item[key];
              });
              list.push(obj);
              settrendsCol(columns);
            });
            let tableCountVar;
            response.data.table.map((item) => {
              tableCountVar = item.totalCount;
            })
            setTableParams1({
              pagination: {
                current: rowDataForTable.currentPage,
                pageSize: rowDataForTable.pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: tableCountVar,
              },
            });
            settrendsColist(list);
          }
        }
      }
    );
  }

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {noConfig ? (
        <>
          <div class=" message text-center ">
            <h4>
              The Rankings configuration is not set. <br />
              Please contact your administrator to set configuration.
            </h4>
          </div>
        </>
      ) : (
        <>
          <div className="row ">
            <Tabs>
              <div className="portlet mb-0">
                <div className="portlet-header portlet-header-bordered my-3">
                  <div className="col-md-9 col-sm-12">
                    <TabList className="nav nav-lines portlet-nav">
                      <Tab
                        className={
                          RankTracking
                            ? "nav-item nav-link"
                            : "nav-item nav-link  active"
                        }
                        onClick={() => {
                          setRankTracking(false);
                        }}
                      >
                        Rankings
                      </Tab>
                    </TabList>
                  </div>
                  {!RankTracking ? (
                    <div
                      ref={ref}
                      class="col-md-3 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender "
                    >
                      <div className="add-new-btnw me-3">
                        <button
                          className="btn btn-primary"
                          style={{ width: "250px" }}
                          onClick={() => setOpen(!open)}
                        >
                          {calanderDate(head1, head2)}
                        </button>
                      </div>
                      {open && (
                        <div id="example-collapse-text-calendar">
                          <Card body className="daterange-picker-card  mt-2">
                            <div className="d-flex justify-content-between">
                              <div className="calendar-col">
                                <DateRangePicker
                                  onChange={(item) => {
                                    console.log(item)
                                    let isCompareVarNew = localStorage.getItem("isCompareVar");
                                    if (calendarType == 1) {
                                      let selection = {};
                                      if (isCompareVarNew == "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        selection = dateRagngeSelectWeek(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );

                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }

                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else if (calendarType == 2) {
                                      let selection = {};

                                      if (isCompareVarNew == "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        selection = dateRagngeSelectFornightly(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );

                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }

                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else if (calendarType == 3) {
                                      let selection = {};

                                      if (isCompareVarNew == "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        selection = dateRagngeSelectMonthly(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );

                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }

                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else {
                                      if (isCompareVarNew == "false") {
                                        let selection23 = {};
                                        if (item?.selection1) {
                                          selection23 = {
                                            selection2: {
                                              startDate: item?.selection1?.startDate,
                                              endDate: item?.selection1?.endDate,
                                              key: "selection2",
                                            },
                                          };

                                          setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                          setHead1(new DateObject(item?.selection1?.startDate).format());
                                          setHead2(new DateObject(item?.selection1?.endDate).format());

                                        } else if (item?.selection2) {
                                          selection23 = {
                                            selection1: {
                                              startDate: item?.selection2?.startDate,
                                              endDate: item?.selection2?.endDate,
                                              key: "selection1",
                                            },
                                          };

                                          setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                          setHead1(new DateObject(item?.selection2?.startDate).format());
                                          setHead2(new DateObject(item?.selection2?.endDate).format());
                                        }

                                        setState({
                                          ...state,
                                          ...item,
                                          ...selection23,
                                        });
                                      }
                                    }
                                  }}

                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={[state?.selection1, state?.selection2]}
                                  direction="horizontal"
                                  ariaLabels={{
                                    dateInput: {
                                      selection1: {
                                        startDate: "start date input of selction 1",
                                        endDate: "end date input of selction 1",
                                      },
                                      selection2: {
                                        startDate: "start date input of selction 2",
                                        endDate: "end date input of selction 2",
                                      },
                                    },

                                    monthPicker: "month picker",
                                    yearPicker: "year picker",
                                    prevButton: "previous month button",
                                    nextButton: "next month button",
                                  }}
                                  maxDate={
                                    new Date(
                                      new Date().getFullYear(),
                                      new Date().getMonth(),
                                      new Date().getDate() - 1
                                    )
                                  }
                                />
                              </div>
                              <div className="ms-3">
                                <button
                                  onClick={() => {
                                    setheadvalues();
                                    setOpen(!open);
                                    let ids = [];

                                    ids.push(competitorSelected.value);
                                    let rowdata;
                                    if (
                                      selectedCategory.id == "0" &&
                                      selectedType.id == "0"
                                    ) {
                                      rowdata = {
                                        Urls: ids,
                                        FromTrackDate:
                                          mmDDyyyyFormateDateSet(head1),
                                        ToTrackDate:
                                          mmDDyyyyFormateDateSet(head2),
                                        ProjectId:
                                          localStorage.getItem("projectGId"),
                                        DeviceType: selectedDeviceType,
                                      };
                                    } else {
                                      rowdata = {
                                        Urls: ids,
                                        FromTrackDate:
                                          mmDDyyyyFormateDateSet(head1),
                                        ToTrackDate:
                                          mmDDyyyyFormateDateSet(head2),
                                        ProjectId:
                                          localStorage.getItem("projectGId"),
                                        DeviceType: selectedDeviceType,
                                        CategoryId: selectedCategory.id,
                                        TypeId: selectedType.id,
                                      };
                                    }
                                    generatecompetitor(rowdata);
                                    generatecompetitortable(rowdata);
                                    generateranktracking(selectedDeviceType, isExpand, selectedProject);
                                    getRankingsTreandsTableData(selectedDeviceType);
                                    setRefreshTicketData(true);
                                    setTimeout(() => {
                                      setRefreshTicketData(false);
                                    }, 1000);
                                  }}
                                  className="btn btn-primary"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}

                      <div className="d-flex 3-icons">
                        <a className="bar-link-icon" style={{ color: "black" }}>
                          <div class="dropdown">
                            <a
                              className="imagebar"
                              ref={applyBtn2}
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-filter" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-4">
                              <div className="filter-popup">
                                <div className="row">
                                  <div className="portlet px-2 py-2">
                                    <div className="col-md-12 my-2">
                                      <label className="common-label">
                                        WebSite
                                      </label>
                                      <select
                                        className="w-100"
                                        style={{ padding: 8 + "px" }}
                                        value={competitorSelected2}
                                        onChange={(item) => {
                                          competitorsList.map((i) => {
                                            if (i.id == item.target.value) {
                                              setcompetitorSelected(i);
                                              setcompetitorSelected2(i.id);
                                            }
                                          });
                                        }}
                                      >
                                        {competitorsList.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.value}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {isdDeviceType && (
                                        <div>
                                          <label className="common-label ranktracking-label">
                                            Device
                                          </label>
                                          <select
                                            style={{ padding: 8 + "px" }}
                                            className="w-100"
                                            value={selectedDeviceType}
                                            onChange={(e) => {
                                              setDeviceType(e.target.value);
                                            }}
                                          >
                                            <option value="0">Mobile</option>
                                            <option value="1">Desktop</option>
                                          </select>
                                        </div>
                                      )}
                                      <label className="common-label ranktracking-label mt-3">
                                        Category
                                      </label>
                                      <select
                                        className="w-100"
                                        style={{ padding: 8 + "px" }}
                                        value={selectedCategory2}
                                        onChange={(item) => {
                                          categoryList.map((i) => {
                                            if (i.id == item.target.value) {
                                              setselectedCategory(i);
                                              setselectedCategory2(i.id);
                                            }
                                          });
                                        }}
                                      >
                                        {categoryList.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.category}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      <label className="common-label ranktracking-label mt-3">
                                        Type
                                      </label>
                                      <select
                                        className="w-100"
                                        style={{ padding: 8 + "px" }}
                                        value={selectedType2}
                                        onChange={(item) => {
                                          keywordTypeList.map((i) => {
                                            if (i.id == item.target.value) {
                                              setselectedType(i);
                                              setselectedType2(i.id);
                                            }
                                          });
                                        }}
                                      >
                                        {keywordTypeList.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.keywordType}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div className="col-md-12 my-3 p-0">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            handleChangecompeSelectedBottom();
                                          }}
                                        >
                                          {" "}
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                        <div className="mx-2  ">
                          <a
                            className="share bar-link-icon"
                            style={{ color: "black" }}
                          >
                            <div class="dropdown">
                              <a
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  class="fa fa-share-alt"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <form class="dropdown-menu p-4 filter-dropdown">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">From</label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Sender Email Id"
                                          value={Sender}
                                          className="w-100 form-control my-2"
                                          name="Sender"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">To</label>
                                        <label class="field-required my-2 pt-2">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Recipient Email Id"
                                          className="w-100 form-control my-2"
                                          value={Receiver}
                                          name="Receiver"
                                          onChange={(text) =>
                                            setReceiver(text.target.value)
                                          }
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageReceiver}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Subject</label>
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Subject"
                                          value={subject}
                                          onChange={(text) => {
                                            setSubject(text.target.value);
                                          }}
                                          className="w-100 form-control my-2"
                                          name="Subject"
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageSubject}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Message</label>
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <textarea
                                          name=""
                                          id=""
                                          cols="80"
                                          rows="7"
                                          className="w-100 form-control my-2 gscmsg"
                                          value={EmailMessage}
                                          onChange={(text) => {
                                            setEmailMessage(text.target.value);
                                          }}
                                        ></textarea>
                                        <div style={{ color: "red" }}>
                                          {Message}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-2"></div>
                                      <div className="col-md-10 my-2">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            if (isValidEmailData()) {
                                              sendEmail();
                                            }
                                          }}
                                        >
                                          {" "}
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                        </div>
                        <span
                          class="dropdown"
                          style={{
                            marginRight: 0 + "px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {/* {getCheckPermission("export", "modules") ? ( */}
                          <button
                            class="outline-btn dropdown-toggle btn btn-primary "
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-download " aria-hidden="true"></i>
                          </button>
                          {/* ) : (
                            <></>
                          )} */}
                          <div
                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              onClick={async () => {
                                setLoading(true);
                                const promise = await downloadPDFfile(
                                  image,
                                  moduleRankTracking
                                );
                                try {
                                  if (promise) {
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                } catch (e) {
                                  setLoading(false);
                                }
                              }}
                            >
                              <a class="dropdown-item">PDF</a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <TabPanel>
                <PDFExport
                  paperSize="A1"
                  margin="2cm"
                  fileName={moduleRankTracking}
                  ref={pdfExportComponent}
                  forcePageBreak=".page-break"
                >
                  <div ref={image}>
                    <div
                      className="Rank-track-top"
                      style={{
                        display: "flex",
                        float: "right",
                        marginBottom: 24 + "px",
                      }}
                    ></div>
                    <br />
                    {displayTable && !noContent ? (
                      <>
                        <div id="web-page-pdf">
                          <div className="row">
                            <div className="portlet p-0">
                              <div className="portlet-header portlet-header-bordered">
                                <h3 className="portlet-title">
                                  Rank Distribution
                                </h3>
                                <lable>{competitorSelected.value}</lable>
                              </div>
                              <div className="col-md-12">
                                <Chart
                                  options={options}
                                  series={series}
                                  type="bar"
                                  height="300"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="portlet  detailed-rank-distribution ">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">
                                Detailed Rank Distribution
                              </h3>
                              <lable> {competitorSelected.value}</lable>
                            </div>
                            <div className="overall-rank">
                              <div className="overall-title">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex justify-content-between align-items-center">

                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <button
                                      className="rank-export"
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedRankExportList?.length > 0
                                            ? selectedRankExportList
                                            : teamlist,
                                          "Rankings"
                                        );
                                      }}
                                    >
                                      Export
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="portlet-body">
                              {/* <div className="text-right">
                                <span class="dropdown mt-4">
                                  <button
                                    class="btn btn-primary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Export
                                  </button>
                                  <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedRankExportList?.length > 0
                                            ? selectedRankExportList
                                            : teamlist,
                                          "Rankings"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedRankExportList?.length > 0
                                            ? selectedRankExportList
                                            : teamlist,
                                          "Rankings"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div>
                                </span>
                              </div> */}
                              <div className="col-md-12 ps-0 pe-0  mt-3">
                                <Table
                                  className="table table-bordered table-hover rank-distribution"
                                  id="datatable-1"
                                  columns={teamcol}
                                  dataSource={teamlist}
                                  rowSelection={{
                                    type: selectionType,
                                    ...rankrowSelection,
                                  }}
                                  pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="portlet html2pdf__page-break">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">Ranking Trends</h3>
                              <lable> {competitorSelected.value}</lable>
                            </div>
                            <div className="portlet-body px-0">
                              <div className="row mt-3">
                                <div className="col-md-12 table-graph-modules">

                                  <div className="overall-rank">
                                    <div className="overall-title">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between align-items-center">

                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                          <button
                                            className="rank-export"
                                            onClick={() => {
                                              getRankingsTreandsTableDataExport("excel")
                                            }}
                                          >
                                            Export
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="text-right">
                                   
                                     <span
                                      class="dropdown mt-4 ms-3"
                                      data-html2canvas-ignore
                                    >
                                      
                                     
                                      <button
                                        class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Export
                                      </button>
                                     
                                      <div
                                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <div
                                          onClick={() => {
                                            getRankingsTreandsTableDataExport("excel")
                                          }}
                                        >
                                          <a class="dropdown-item">Excel</a>
                                        </div>
                                        <div
                                          onClick={() => {
                                            getRankingsTreandsTableDataExport("csv")
                                          }}
                                        >
                                          <a class="dropdown-item">CSV</a>
                                        </div>
                                      </div>
                                    </span> 
                                  </div>{" "} */}
                                  <div className="col-md-12 ps-0 pe-0">
                                    <Table
                                      className="table table-bordered table-hover mt-3 w-100 ranktracking-table d-tracking"
                                      id="datatable-1"
                                      position="fixed"
                                      columns={trendsCol}
                                      dataSource={[...trendsColist]}
                                      rowSelection={{
                                        type: selectionTypeKeyTable,
                                        ...ranktrendsrowSelection,
                                      }}
                                      pagination={tableParams1.pagination}
                                      onChange={handleTableChange1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">Latest Rankings</h3>
                            </div>
                            <div className="portlet-body px-0">
                              <div className="row">
                                <div className="col-md-12 table-graph-modules">
                                <div className="overall-rank">
                                    <div className="overall-title">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between align-items-center">
                                        Last update date :{" "}
                                      {lastUpdateDate
                                        ? mmDDyyyyFormateDate(lastUpdateDate)
                                        : " - "}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                          <button
                                            className="rank-export"
                                            onClick={() => {
                                              generateranktrackingExport(selectedDeviceType, isExpand, selectedProject, "excel");
                                            }}
                                          >
                                            Export
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex justify-content-end">
                                    <div className="mt-2">
                                      Last update date :{" "}
                                      {lastUpdateDate
                                        ? mmDDyyyyFormateDate(lastUpdateDate)
                                        : " - "}
                                    </div>
                                    <span
                                      class="dropdown ms-3"
                                      data-html2canvas-ignore
                                    >
                                     
                                      <button
                                        class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Export
                                      </button>
                                     
                                      <div
                                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <div
                                          onClick={() => {
                                            generateranktrackingExport(selectedDeviceType, isExpand, selectedProject, "excel");
                                          }}
                                        >
                                          <a class="dropdown-item">Excel</a>
                                        </div>
                                        <div
                                          onClick={() => {
                                            let tag = "csv"
                                            generateranktrackingExport(selectedDeviceType, isExpand, selectedProject, "csv");
                                          }}
                                        >
                                          <a class="dropdown-item">CSV</a>
                                        </div>
                                      </div>
                                    </span>
                                  </div>{" "} */}
                                  <div className="col-md-12 text-right rank-tracking-arrow">
                                    <span class="icon">
                                      {!isExpand ? (
                                        <i
                                          class="fa fa-angle-right"
                                          aria-hidden="true"
                                          onClick={() => {
                                            var expand = true;
                                            setIsExpand(true);
                                            expandTableData(expand);
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-angle-left"
                                          aria-hidden="true"
                                          onClick={() => {
                                            var expand = false;
                                            setIsExpand(false);
                                            expandTableData(expand);
                                          }}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-md-12 ps-0 pe-0 rank-tracking">
                                    <Table
                                      className="table table-bordered table-hover w-100 ranktracking-table1 d-tracking"
                                      id="datatable-1"
                                      position="fixed"
                                      columns={keyTableCol}
                                      dataSource={[...keytablelist]}
                                      rowSelection={{
                                        type: selectionTypeKeyTable,
                                        ...rowSelection,
                                      }}
                                      pagination={tableParams.pagination}
                                      onChange={handleTableChange}
                                    />
                                    <div className="rank-tracking">
                                      <div
                                        className={
                                          isChartDivOpen
                                            ? "rank-chart"
                                            : "d-none "
                                        }
                                        style={
                                          isChartDivOpen
                                            ? {
                                              position: "absolute",
                                              transition: "0.5s",

                                              zIndex: "9999",
                                              opacity: "1",
                                              top: "85px",
                                              right: "10px",
                                              background:
                                                "rgb(255, 255, 255)",
                                              right: "107px",
                                              bordertop: "0",
                                              border: "2px solid #bcdefa",
                                              WebkitBoxShadow:
                                                "-1px 8px 49px -10px rgba(94,94,94,0.75)",
                                            }
                                            : {
                                              position: "absolute",
                                              transition: "0.5s",
                                              width: "0px",
                                              zIndex: "9999",
                                              opacity: "0",
                                            }
                                        }
                                      >
                                        <div>
                                          <Chart
                                            options={options1}
                                            series={series1}
                                            type="line"
                                            width="400px"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {noContent ? (
                      <>
                        <div class=" message text-center ">
                          <h4>
                            The Rankings is yet in progress. <br />
                            The status will be visible here once the Rankings
                            completed.
                          </h4>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </PDFExport>
              </TabPanel>
            </Tabs>
          </div>
          <Loading isLoading={isLoading} />
        </>
      )}
    </>
  );
}

export default ModuleExpandRankTracking;
