import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utility/Constant";
import { dateToClosestWeek } from "../../utility/dates";
import { addDays } from "date-fns";

const initialState = {
  status: Status.LOADING,
  device: "",
  selectedDevice: "",
  keywordOptions: {
    options: [
      { label: "Overall", value: "Overall" },
      { label: "Brand", value: "Brand" },
      { label: "Non Brand", value: "Non Brand" },
    ],
    active: 0,
  },
  campaigns: {
    options: [],
    active: 0,
    isOpen: false,
  },
  datePickerOptions: {
    options: ["Custom", "By Weekly", "By Monthly"],
    active: 1,
  },
  minDateRange: "",
  maxDateRange: "",
  disabledDates: [],
  selectedDates: {},
  selectedMonth: "",
  selectedMonthRange: [],
  formattedRange: [],
  isCompare: false,
  frequency: "",
  isGraphView: true,
  averagePosition: { count: [22, 15], growth: "+10" },
  rankOptions: {
    options: [
      { label: "Rank 1-5", value: "5" },
      { label: "Rank 1-10", value: "10" },
      { label: "Rank 1-100", value: "100" },
    ],
    active: 2,
  },
  keywordDistributionTabs: {
    options: [
      {
        title: "Overall",
        average_position: "0",
        count: 0,
        info: "",
        growth: 0,
        compare: ["0", "0"],
      },
      {
        title: "Head",
        average_position: "0",
        count: 0,
        info: "",
        growth: 0,
        compare: ["0", "0"],
      },
      {
        title: "Torso",
        average_position: "0",
        count: 0,
        info: "",
        growth: 0,
        compare: ["0", "0"],
      },
      {
        title: "Tail",
        average_position: "0",
        count: 0,
        info: "",
        growth: 0,
        compare: ["0", "0"],
      },
    ],
    active: 0,
    status: Status.LOADING,
  },
  selectedCampaign: "",
  selectedCategories: [],
  graphData: {
    series: [],
    xAxisCategories: [],
    change: [],
    active: -1,
    progress: [],
    status: Status.LOADING,
  },
  keywords: {
    status: Status.LOADING,
    data: [],
    unique_categories: [],
  },
  exportTable: {
    status: Status.LOADING,
    data: [],
    categories: [],
    types: [],
    filteredCategories: [],
    filteredtypes: [],
    columnOptions: {
      options: [
        {
          id: 0,
          label: "Search Volume",
          value: "searchvolume",
          type: "sort",
          fixed: true,
        },
        {
          id: 1,
          label: "Type",
          value: "types",
          type: "filter",
          fixed: true,
        },
        {
          id: 2,
          label: "Location",
          value: "location",
          fixed: true,
        },
        {
          id: 3,
          label: "Competitor Rank",
          value: "competitor rank",
          type: "sort",
          fixed: false,
        },
        {
          id: 4,
          label: "Competitor URL",
          value: "competitor url",
          fixed: false,
        },
      ],
      active: [0, 3, 4],
    },
    competitors: {
      options: [],
      active: [],
    },
    date: "",
    isCustom: false,
    own_domain_name: "",
    downloadStatus: Status.SUCCESS,
  },
  isLoginLoading: false,
};

export const rankingReportSlice = createSlice({
  name: "rankingReport",
  initialState,
  reducers: {
    resetRankingReport: () => initialState,
    resetOptions: (state, action) => {
      state.keywordDistributionTabs.active = 0;
      state.rankOptions.active = 2;
      state.keywordOptions.active = 0;
      state.graphData.active = -1;
    },
    setStatusRankingReport: (state, action) => {
      state.status = action.payload;
    },
    setActiveKeywordOption: (state, action) => {
      state.keywordOptions.active = action.payload;
      state.rankOptions.active = 2;
    },
    setActiveRankOption: (state, action) => {
      state.rankOptions.active = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    onTabChange: (state, action) => {
      state.keywordDistributionTabs.active = action.payload;
    },
    toggleChart: (state, action) => {
      state.isGraphView = action.payload;
    },
    setDatePickerOption: (state, action) => {
      state.datePickerOptions.active = action.payload;
    },
    toggleIsCompare: (state, action) => {
      state.isCompare = action.payload;
    },
    setDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    setActiveCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    setActiveCampaignCategories: (state, action) => {
      state.campaigns.active = action.payload.activeCampaignIndex;
      state.campaigns.options.map((campaign, index) => {
        if (index === action.payload.activeCampaignIndex) {
          state.campaigns.options[index].categories.active =
            action.payload.activeCategoriesIndexes;
        } else {
          state.campaigns.options[index].categories.active = [];
        }
      });
    },
    toggleCampaignDropDown: (state, action) => {
      state.campaigns.isOpen = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setFormattedDate: (state, action) => {
      state.formattedRange = action.payload;
    },
    setKeywordDistributionTab: (state, action) => {
      state.keywordDistributionTabs.options = action.payload;
      state.keywordDistributionTabs.active = 0;
      state.graphData.active = -1;
    },
    setGraphSeries: (state, action) => {
      state.graphData.series = action.payload.series;
      state.graphData.xAxisCategories = action.payload.categories;
      state.graphData.change = action.payload.change;
      state.graphData.progress = action.payload.progress;
    },
    setKeywords: (state, action) => {
      state.keywords.data = action.payload.data;
      state.keywords.unique_categories = action.payload.unique_categories;
      state.keywords.status = action.payload.status;
    },
    selectBucket: (state, action) => {
      state.graphData.active = action.payload;
    },
    setActiveColumns: (state, action) => {
      state.exportTable.columnOptions.active = action.payload;
    },
    setActiveCompetitors: (state, action) => {
      state.exportTable.competitors.active = action.payload;
      state.isGraphView = action.payload;
    },
    setExportCompetitors: (state, action) => {
      state.exportTable.competitors.options = action.payload;
      state.exportTable.competitors.active = action.payload.map(
        (value, index) => index
      );
    },
    setExportKeywords: (state, action) => {
      state.exportTable.data = action.payload;
    },
    setExportKeywordDate: (state, action) => {
      state.exportTable.date = action.payload;
    },
    setIsCustom: (state, action) => {
      state.isCustom = action.payload;
    },
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    setKeywordTabsStatus: (state, action) => {
      state.keywordDistributionTabs.status = action.payload;
    },
    setGraphStatus: (state, action) => {
      state.graphData.status = action.payload;
    },
    setExportDownloadStatus: (state, action) => {
      state.exportTable.downloadStatus = action.payload;
    },
    setExportTableCategories: (state, action) => {
      state.exportTable.categories = action.payload;
    },
    setExportTableTypes: (state, action) => {
      state.exportTable.types = action.payload;
    },
    setExportTableFilteredTypes: (state, action) => {
      state.exportTable.filteredtypes = action.payload;
    },
    setExportTableFilteredCategories: (state, action) => {
      state.exportTable.filteredCategories = action.payload;
    },
    setOwnDomainName: (state, action) => {
      state.exportTable.own_domain_name = action.payload;
    },
    setSelectedMonth: (state, action) => {
      state.selectedMonth = action.payload;
    },
    setSelectedMonthRange: (state, action) => {
      state.selectedMonthRange = action.payload;
    },
    setExportTableStatus: (state, action) => {
      state.exportTable.status = action.payload;
    },
    resetExportCompetitors: (state, action) => {
      state.exportTable.competitors = {
        options: [],
        active: [],
      };
    },
    setIsLoginLoading: (state, action) => {
      state.isLoginLoading = action.payload;
    }
  },
});

export const {
  resetRankingReport,
  resetSingleKeywordData,
  setStatusRankingReport,
  setActiveKeywordOption,
  setCampaigns,
  setActiveRankOption,
  onTabChange,
  toggleChart,
  setDatePickerOption,
  toggleIsCompare,
  setDates,
  setActiveCampaign,
  setSelectedCategories,
  setFormattedDate,
  setKeywordDistributionTab,
  setGraphSeries,
  setActiveCategories,
  setActiveCampaignCategories,
  toggleCampaignDropDown,
  setKeywords,
  selectBucket,
  setActiveColumns,
  setActiveCompetitors,
  setExportCompetitors,
  setExportKeywords,
  setIsCustom,
  setSelectedDevice,
  setKeywordTabsStatus,
  setGraphStatus,
  setExportKeywordDate,
  setExportDownloadStatus,
  resetOptions,
  setExportTableCategories,
  setExportTableTypes,
  setOwnDomainName,
  setSelectedMonthRange,
  setSelectedMonth,
  setExportTableFilteredCategories,
  setExportTableFilteredTypes,
  setExportTableStatus,
  resetExportCompetitors,
  setIsLoginLoading
} = rankingReportSlice.actions;

export default rankingReportSlice.reducer;
