import React, { useEffect, useState } from "react";
import {
  compareValues,
  formateDateIntoDatemonth,
  formatRankDate,
  mmDDyyyyFormateDateSet,
  mmDDyyyyFormateForForm,
} from "../../utility/Globals";
import Chart from "react-apexcharts";
import {
  getpagespeedurl,
  pageSeedSearchDashboard,
  pageSpeedDataGet,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";

function Pagespeedchart(props) {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [isChart, setIsChart] = useState(0);

  useEffect(() => {
    setIsChart(props?.isChart);
  }, [props?.isChart]);

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );

    let rowData = {
      ProjectId: localStorage.getItem("projectGId"),
      StartDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.startDate
      ),
      EndDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.endDate
      ),
      Device: 1,
      CompareStartDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection2?.startDate
      ),
      CompareEndDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection2?.endDate
      ),
    };
    getPageSpeedCompareTable(rowData);
    getUrlList(rowData);
  }, [props?.dataParentToChild]);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }

  const colourOptions1 = [
    { value: "FCP", label: "FCP" },
    { value: "FID", label: "FID" },
    { value: "LCP", label: "LCP" },
    { value: "CLS", label: "CLS" },
    { value: "PerformanceScore", label: "Score" },
  ];

  const [clsTotal, setclsTotal] = useState(0);
  const [lcpTotal, setlcpTotal] = useState(0);
  const [fidTotal, setfidTotal] = useState(0);
  const [scoreTotal, setscoreTotal] = useState(0);
  const [clsTotalCompare, setclsTotalCompare] = useState(0);
  const [lcpTotalCompare, setlcpTotalCompare] = useState(0);
  const [fidTotalCompare, setfidTotalCompare] = useState(0);
  const [scoreTotalCompare, setscoreTotalCompare] = useState(0);
  const [clsTotaldiff, setclsTotaldiff] = useState(0);
  const [lcpTotaldiff, setlcpTotaldiff] = useState(0);
  const [fidTotaldiff, setfidTotaldiff] = useState(0);
  const [scoreTotaldiff, setscoreTotaldiff] = useState(0);
  const [clsTotaldiffchk, setclsTotaldifchk] = useState(0);
  const [lcpTotaldiffchk, setlcpTotaldiffchk] = useState(0);
  const [fidTotaldiffchk, setfidTotaldiffchk] = useState(0);
  const [scoreTotaldiffchk, setscoreTotaldiffchk] = useState(0);
  const [optionSelected1, setoptionSelected1] = useState(
    colourOptions1[0]?.value
  );
  const [selectedDeviceType, setDeviceType] = useState("0");
  const [URLname, setURLname] = useState("");
  const [PageSpeedoptions, setPageSpeedObject] = useState({
    chart: {
      fontFamily: "Montserrat, sans- serif ",
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoomin: true,
          zoomout: true,
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: "Date",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          color: "#000",
        },
      },
      logBase: 0,
      tickAmount: 6,
      min: 0,
    },
  });

  const [PageSpeedseries, setPageSpeedSeries] = useState([]);

  function getColorClassName(i) {
    if (i == 2) {
      return "avg-val";
    } else if (i == 0) {
      return "avg-val-positive";
    } else if (i == 1) {
      return "avg-val-negative";
    }
  }

  function getUrlList(rowData) {
    const url = getpagespeedurl + localStorage.getItem("projectGId");
    APIManager.getAPI(url, true).then((response) => {
      if (response && response.status == 200) {
        if (response.data[0]?.url) {
          setURLname(response.data[0]?.url);
        }

        let rowdata1 = {
          url: [response?.data[0]?.url],
          dataType: optionSelected1,
          device: selectedDeviceType,
          startDate: rowData?.StartDate,
          EndDate: rowData?.EndDate,
          projectId: localStorage.getItem("projectGId"),
        };
        getPageSpeedByDataType(rowdata1);
      }
    });
  }

  function getPageSpeedByDataType(rowdata) {
    APIManager.postAPI(pageSpeedDataGet, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          if (response.data.length > 0) {
            const list = [];
            let i = 0;
            let dataForGraph = [];
            let graphSeries = [];
            let count = 0;
            let columns = [
              {
                title: "URL",
                dataIndex: "url",
                key: "url",
                fixed: "left",
                width: 200,
              },
            ];
            const startDate = rowdata.startDate;
            const endDate = rowdata.EndDate;
            const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
            const diffDays = 0 | (diffTime / 864e5);
            for (let i = 0; i <= diffDays; i++) {
              const newdate = formateDateIntoDatemonth(
                new Date(new Date(startDate).getTime() + i * 864e5)
              );
              const datedata = mmDDyyyyFormateDateSet(
                new Date(new Date(startDate).getTime() + i * 864e5)
              );
              graphSeries.push(
                formateDateIntoDatemonth(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                )
              );
              let obj = {
                title: newdate,
                dataIndex: datedata,
                key: datedata,
              };
              columns.push(obj);
            }
            response.data.map((item) => {
              let pageSpeedData = [];
              let keys = Object.keys(item);
              let dates = [];
              keys.map((date) => {
                if (date !== "DataType" && date !== "Url") {
                  dates.push({
                    date: date,
                    value: item[date],
                  });
                }
              });
              pageSpeedData.push({
                DataType: item.DataType,
                Url: item.Url,
              });
              let graphData = [];
              let options = [];

              let obj = {
                key: (i += 1),
                url: item.Url,
              };
              for (let i = 0; i <= diffDays; i++) {
                const newdate = mmDDyyyyFormateForForm(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                );
                let point2Value;
                if (item[newdate]) {
                  if (rowdata.dataType == "FID") {
                    point2Value = Math.round(item[newdate] * 100) / 100;
                  } else {
                    point2Value = Math.round(item[newdate] * 100) / 100;
                  }
                } else {
                  point2Value = 0;
                }
                obj[newdate] = point2Value;
                graphData.push(point2Value);
                graphSeries.push(
                  formateDateIntoDatemonth(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  )
                );
              }
              if (rowdata.dataType.toUpperCase() == item.DataType.toUpperCase()) {
                list.push(obj);
              }
              if (rowdata.dataType.toUpperCase() == item.DataType.toUpperCase()) {
              dataForGraph.push({
                  name: item.Url,
                  data: graphData,
                });
              }
              options = {
                chart: {
                  id: "basic-bar",
                },
                xaxis: {
                  tickPlacement: "on",
                  categories: graphSeries,
                  title: {
                    text: "",
                    style: {
                      color: "#000",
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "Speed",
                    style: {
                      color: "#000",
                    },
                  },
                  logBase: 0,
                  tickAmount: 6,
                  min: 0,
                  max: (max) => {
                    return max * 0.3 + max;
                  },
                },
              };
              setPageSpeedObject(options);
              setPageSpeedSeries(dataForGraph);
              count++;
            });
          }
        }
      }
    );
  }

  function getPageSpeedCompareTable(rowData) {
    APIManager.postAPI(pageSeedSearchDashboard, rowData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let fcp = 0;
          let fid = 0;
          let cls = 0;
          let lcp = 0;
          let score = 0;

          response.data.table.map((item) => {
            fcp += item.fcp;
            fid += item.fid;
            cls += item.cls;
            lcp += item.lcp;
            score += item.performanceScore;
          });

          setscoreTotal(score);
          setfidTotal(fid);
          setlcpTotal(lcp);
          setclsTotal(cls);

          let fcpCompare = 0;
          let fidCompare = 0;
          let clsCompare = 0;
          let lcpCompare = 0;
          let scoreCompare = 0;

          response.data.table1.map((item) => {
            fcpCompare += item.fcp;
            fidCompare += item.fid;
            clsCompare += item.cls;
            lcpCompare += item.lcp;
            scoreCompare += item.performanceScore;
          });

          setscoreTotalCompare(scoreCompare);
          setfidTotalCompare(fidCompare);
          setlcpTotalCompare(lcpCompare);
          setclsTotalCompare(clsCompare);

          let diffFid =
            fid < fidCompare
              ? (100 * (fidCompare - fid)) / fidCompare
              : (100 * (fid - fidCompare)) / fid;
          let diffCls =
            cls < clsCompare
              ? (100 * (clsCompare - cls)) / clsCompare
              : (100 * (cls - clsCompare)) / cls;
          let diffLcp =
            lcp < lcpCompare
              ? (100 * (lcpCompare - lcp)) / lcpCompare
              : (100 * (lcp - lcpCompare)) / lcp;
          let diffScore =
            score < scoreCompare
              ? (100 * (scoreCompare - score)) / scoreCompare
              : (100 * (score - scoreCompare)) / score;

          setclsTotaldiff(diffCls.toFixed(2));
          setlcpTotaldiff(diffLcp.toFixed(2));
          setfidTotaldiff(diffFid.toFixed(2));
          setscoreTotaldiff(diffScore.toFixed(2));
          setclsTotaldifchk(compareValues(cls, clsCompare));
          setlcpTotaldiffchk(compareValues(lcp, lcpCompare));
          setfidTotaldiffchk(compareValues(fid, fidCompare));
          setscoreTotaldiffchk(compareValues(score, scoreCompare));
        }
      }
    );
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Page Speed </h4>
          </div>
          <div className="d-flex flex-wrap  align-items-center">
            <div className="header-url me-2">{URLname}</div>
            <div className="me-2 mt-3 mt-sm-0">
              {isChart == 0 ? (
                <select
                  style={{ padding: 8 + "px" }}
                  className="form-select"
                  id="page-speed-id"
                  value={optionSelected1?.value}
                  onChange={(e) => {
                    let ghostSelect = document.createElement("select");
                    let select = document.getElementById("page-speed-id");
                    let x = select.options[select.selectedIndex].text;
                    let ghostOption = document.createElement("option");
                    ghostOption.setAttribute("value", x);
                    let t = document.createTextNode(x);
                    ghostOption.appendChild(t);
                    ghostSelect.appendChild(ghostOption);
                    window.document.body.appendChild(ghostSelect);
                    select.style.width = ghostSelect.offsetWidth + "px";
                    window.document.body.removeChild(ghostSelect);
                    let selected = {
                      label: e.target.value,
                      value: e.target.value,
                    };
                    setoptionSelected1(selected.label);
                    let rowdata = {
                      url: [URLname],
                      dataType: selected.label,
                      device: selectedDeviceType,
                      startDate: startDate,
                      EndDate: endDate,
                      projectId: localStorage.getItem("projectGId"),
                    };
                    getPageSpeedByDataType(rowdata);
                  }}
                >
                  {colourOptions1?.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              ) : (
                <></>
              )}
            </div>
            <div className="me-2 mt-3 mt-sm-0">
              <select
                style={{ padding: 8 + "px" }}
                className="form-select"
                value={selectedDeviceType}
                id="device-type-ps"
                onChange={(e) => {
                  let ghostSelect = document.createElement("select");
                  let select = document.getElementById("device-type-ps");
                  let x = select.options[select.selectedIndex].text;
                  let ghostOption = document.createElement("option");
                  ghostOption.setAttribute("value", x);
                  let t = document.createTextNode(x);
                  ghostOption.appendChild(t);
                  ghostSelect.appendChild(ghostOption);
                  window.document.body.appendChild(ghostSelect);
                  select.style.width = ghostSelect.offsetWidth + "px";
                  window.document.body.removeChild(ghostSelect);

                  setDeviceType(e.target.value);
                  let rowdata = {
                    url: [URLname],
                    dataType: optionSelected1,
                    device: e.target.value,
                    startDate: startDate,
                    EndDate: endDate,
                    projectId: localStorage.getItem("projectGId"),
                  };
                  getPageSpeedByDataType(rowdata);
                }}
              >
                <option value="0">Mobile</option>
                <option value="1">Desktop</option>
              </select>
            </div>
            <div className="btn-box mt-3 mt-sm-0">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" my-3">
        {isChart == 0 ? (
          <>
            <div className="col-md-12">
              <div>
                <div className="mt-3 round-chart">
                  <Chart
                    series={PageSpeedseries}
                    options={PageSpeedoptions}
                    height="300"
                    type="line"
                  ></Chart>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>
            <div className="row pagespeed-dashboard">
              <div className="col-md-3">
                <div className=" pagespeed-domain mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Score</h4>
                    <div className="grren-domain text-right">
                      {scoreTotaldiffchk != 1 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getColorClassName(scoreTotaldiffchk)}>
                    {scoreTotaldiff > 0 ? scoreTotaldiff : 0}%
                  </h3>
                  <div className="row mt-3 page_speed_common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 p-0">
                        <p>{scoreTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-sm-6 total-compare pl-2 pr-0">
                        <p>{scoreTotalCompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className=" pagespeed-domain mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>FID</h4>
                    <div className="grren-domain text-right">
                      {fidTotaldiffchk != 1 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getColorClassName(fidTotaldiffchk)}>
                    {fidTotaldiff > 0 ? fidTotaldiff : 0}%
                  </h3>
                  <div className="row mt-3 page_speed_common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 p-0">
                        <p>{fidTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-sm-6 total-compare pl-2 pr-0">
                        <p>{fidTotalCompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className=" pagespeed-domain mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>LCP</h4>
                    <div className="grren-domain text-right">
                      {lcpTotaldiffchk != 1 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getColorClassName(lcpTotaldiffchk)}>
                    {lcpTotaldiff > 0 ? lcpTotaldiff : 0}%
                  </h3>
                  <div className="row mt-3 page_speed_common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 p-0">
                        <p>{lcpTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-sm-6 total-compare pl-2 pr-0">
                        <p>{lcpTotalCompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className=" pagespeed-domain mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CLS</h4>
                    <div className="grren-domain text-right">
                      {clsTotaldiffchk != 1 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getColorClassName(clsTotaldiffchk)}>
                    {clsTotaldiff > 0 ? clsTotaldiff : 0}%
                  </h3>
                  <div className="row mt-3 page_speed_common_line">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-sm-6 p-0">
                        <p>{clsTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-sm-6 total-compare pl-2 pr-0">
                        <p>{clsTotalCompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Pagespeedchart;
