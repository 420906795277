import React from "react";
import { List, Table, Tabs } from "antd";
import { useState } from "react";
import { useEffect, useRef } from "react";
import APIManager from "../../services/APIManager";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import { saveAs } from "file-saver";
import {
  getFreelancers,
  updateFreelancer,
  onlyPDF,
  onlyFileType,
  GET_Server_URL,
  imageFileType,
  getProjectByOrgId,
  dashboardWorkHistory,
  freelancerproject,
  imagedelete,
  categoryWiseTable
} from "../../utility/Constant";
import {
  DDMMyyyyFormateDateSet,
  findUnique,
  exportToEXCEL,
  getStatus,
  getDaysInMonth,
  convertDate,
  setMonths,
  isValidPDFDOCFile,
  isValidImageFile,
  getStatusExp,
  formatRankDate,
  fixTwoPlaceDecimal
} from "../../utility/Globals";
import FeatherIcon from "feather-icons-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function FreelancersMyRequirement(props) {
  let param = useParams();
  const ref = useRef();
  const [projectCols, setProjectCol] = useState([]);
  const [projectWorkListMain, setProjectWorkListMain] = useState([]);
  const [projectWorkList, setProjectWorkList] = useState([]);
  const [projectseleted, setprojectseleted] = useState();
  const [projectList, setProjectList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [articlePerDay, setArticlePerDay] = useState("");
  const [costPerWord, setCostPerWord] = useState("");
  const [costPerArticle, setCostPerArticle] = useState("");
  const [JoiningData, setJoiningData] = useState("");
  const [errors, setErrors] = useState("");
  const [documentFile, setDocumentFile] = useState(null);
  const [images, setimages] = useState("");
  const [documentURL, setDocumentURL] = useState("");
  const [inputimg, setinputimg] = useState([]);
  const [selectImageFile, setImageFile] = useState(null);
  const [activeTab, setactiveTab] = useState("personalinfo");
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [filelist, setfilelist] = useState([]);
  const [articlesCompleted, setArticlesCompleted] = useState(0);
  const [reworkNeeded, setReworkNeeded] = useState(0);
  const [successRate, setSuccessRate] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [exportList, setExportList] = useState([]);
  const [freelancerid, setFreelancerid] = useState("");
  const [freelancerCategory, setfreelancerCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [closeButton, setCloseButton] = useState(false);
  const [id, setId] = useState("");
  const [head1, setHead1] = useState(
    convertDate(new Date().getMonth() + 1 + "-01-" + new Date().getFullYear())
  );
  const [head2, setHead2] = useState(
    convertDate(
      convertDate(
        new Date().getMonth() +
        1 +
        "-" +
        getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      )
    )
  );
  const [monthYear, setMonthYear] = useState(
    setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear()
  );
  const inputFileRef = useRef(null);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  let DBStatus = [];
  if (freelancerCategory == 0) {
    DBStatus.push(
      { value: 1, text: "In Progress" },
      { value: 2, text: "Rework" },
      { value: 3, text: "Awaiting Approval" },
      { value: 4, text: "Completed" });

  }
  else if (freelancerCategory == 1) {
    DBStatus.push(
      { value: 5, text: "In Progress" },
      { value: 6, text: "Rework" },
      { value: 7, text: "Awaiting Approval" },
      { value: 8, text: "Completed" });
  }
  else {
    DBStatus.push(
      { value: 1, text: "In Progress" },
      { value: 2, text: "Rework" },
      { value: 3, text: "Awaiting Approval" },
      { value: 4, text: "Completed" },
      { value: 5, text: "In Progress" },
      { value: 6, text: "Rework" },
      { value: 7, text: "Awaiting Approval" },
      { value: 8, text: "Completed" });

  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
        if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false);
        }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
    };
}, [open]);

  useEffect(() => {
    if (location.state) {
      setactiveTab(location.state.tab);
      setHead1(location.state.startDate);
      setHead2(location.state.endDate);
      setMonthYear(setMonths(new Date(location.state.startDate).getMonth() + 1) + " " + new Date(location.state.endDate).getFullYear())
    } else {
      setactiveTab("personalinfo");
    }
    getFreelancer();
  }, []);

  useEffect(() => {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        // width: "20%",
      },
      {
        title: "PROJECT",
        dataIndex: "project",
        key: "project",
        // width: "10%",
      },

      {
        title: "KEYWORD",
        dataIndex: "keyword",
        key: "keyword",
        // width: "10%",
      },
      {
        title: "COST",
        dataIndex: "cost",
        key: "cost",
        // width: "10%",
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        // width: "10%",
      },
      {
        title: "REWORK",
        dataIndex: "rework",
        key: "rework",
        // width: "10%",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        filters: findUnique(DBStatus, (d) => d.text),
        onFilter: (value, record) => {
          let itemValue = DBStatus.find(o => o.value === value);
          return record?.status?.props?.children == itemValue.text;
        },
      },
    ];
    setProjectCol(columns);
  }, [freelancerCategory])

  const getFreelancer = () => {
    APIManager.getAPI(getFreelancers + param.id).then((response) => {
      if (response && response.status == 200) {
        setDataList(response.data.value);
        setFirstName(response.data.value.firstName);
        setLastName(response.data.value.lastName);
        setMobileNumber(response.data.value.mobile);
        setEmail(response.data.value.email);
        setArticlePerDay(response.data.value.articlesPerDay ?? '');
        setCostPerWord(response.data.value.costPerWord ?? '');
        setCostPerArticle(response.data.value.costPerArticle ?? '');
        setJoiningData(response.data.value.createdAt);
        setinputimg(
          response.data.value.imageURL == "null" ||
            response.data.value.imageURL == null || response.data.value.imageURL == ""
            ? "/images/profile_avtar_img.jpg"
            : GET_Server_URL + response.data.value.imageURL
        );
        setimages(response.data.value.imageURL);
        setDocumentURL(
          response.data.value.sampleWork == "null"
            ? ""
            : response.data.value.sampleWork
        );
        setfreelancerCategory(response.data.value.freelancerCategory);
        setIsActive(response.data.value.isActive);
        setFreelancerid(response.data.value.userId);
        setId(response.data.value.id);
        if (location.state) {
          getProjetList(response.data.value.userId , response.data.value.freelancerCategory);
        }
      }
    });
  };

  let filename = [];
  if (documentURL) {
    filename = documentURL.split('_');
  }



  function updateFreelancerData() {
    var bodyFormData = new FormData();

    bodyFormData.append("freelancerCategory", dataList.freelancerCategory);
    bodyFormData.append("freelancerType", dataList.freelancerType);
    bodyFormData.append("FirstName", firstName);
    bodyFormData.append("LastName", lastName);
    bodyFormData.append("Mobile", mobileNumber);
    bodyFormData.append("email", email);
    bodyFormData.append("articlesPerDay", articlePerDay ?? "");
    bodyFormData.append("costPerWord", costPerWord ?? "");
    bodyFormData.append("costPerArticle", costPerArticle ?? "");
    bodyFormData.append("niche", dataList.niche);
    bodyFormData.append("isActive", dataList.isActive);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );
    if (documentFile) {
      bodyFormData.append("SampleWorkFile", documentFile);
    } else {
      bodyFormData.append("sampleWork", documentURL);
    }
    if (selectImageFile) {
      bodyFormData.append("File", selectImageFile);
    } else {
      bodyFormData.append("imageURL", images);
    }
    bodyFormData.append("id", param.id);
    bodyFormData.append("userId", dataList.userId);

    if (
      firstName != "" &&
      lastName != "" &&
      mobileNumber != "" &&
      email != "" &&
      JoiningData != ""
    ) {
      APIManager.putAPI(updateFreelancer, {}, bodyFormData, false).then(
        (response) => {
          if (response && response.status == 200) {
            localStorage.setItem("active", 2);
            navigate("/content-main/databaseContent");
          }
        }
      );
    } else {
      formValidation();
    }
  }

  function removeimage() {
    APIManager.putAPI(imagedelete + "?id=" + id).then(
      (response) => {
        if (response && response.status == 200) {
          setimages(null);
          setFiles([]);
          setImageFile(null);
          setinputimg("");
          getFreelancer()
        }
      }
    );
  }

  function formValidation() {
    let error = { ...errors };

    firstName.trim() == ""
      ? (error["firstName"] = "First Name is required!")
      : !firstName.trim().match(/^[a-zA-Z]*$/)
        ? (error["firstName"] = "Please enter alphabet characters only.")
        : delete error["firstName"];

    lastName.trim() == ""
      ? (error["lastName"] = "Last Name is required!")
      : !lastName.trim().match(/^[a-zA-Z]*$/)
        ? (error["lastName"] = "Please enter alphabet characters only.")
        : delete error["lastName"];

    mobileNumber.trim() == ""
      ? (error["mobileNumber"] = "Mobile Number is required !")
      : !mobileNumber.trim().match(/^[0-9]{10}$/)
        ? (error["mobileNumber"] = "Please enter number only.")
        : delete error["mobileNumber"];

    if (mobileNumber?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(mobileNumber)) {
        error["mobileNumber"] = "Please enter only number";
      } else if (mobileNumber.length != 10) {
        error["mobileNumber"] =
          "Phone number should be only of 10 numbers!";
      }  else {
        delete error["mobileNumber"];
      }
    }

    JoiningData.trim() == ""
      ? (error["JoiningData"] = "Joining Data is required !")
      : delete error["JoiningData"];

      (/^\d{0,5}(\.\d{0,2})?$/i).test(costPerWord) 
      ? delete error["costPerWord"] 
      : error["costPerWord"] = "Number should have follow pattern i.e. 12345.23";
  
      (/^\d{0,5}(\.\d{0,2})?$/i).test(costPerArticle) 
      ? delete error["costPerArticle"] 
      : error["costPerArticle"] = "Number should have follow pattern i.e. 12345.23";
  
      (/^\d{0,5}(\.\d{0,2})?$/i).test(articlePerDay) 
      ? delete error["articlePerDay"] 
      : error["articlePerDay"] = "Number should have follow pattern i.e. 12345.23";

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }
  const [files, setFiles] = useState([]);
  function addnewimg1(event) {
    setinputimg(URL.createObjectURL(event.target.files[0]));
    let input = document.getElementById("fileMy1");
    let imagesFile = files;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImageFile(fileExtension)
    if (isvalid == false) {
      setinputimg(inputimg);
      return false;

    }
    setimages("");
    setImageFile(input.files[0]);
    for (let i = 0; i < input.files.length; ++i) {
      imagesFile.push(input.files.item(i));
    }
    setFiles(imagesFile);

  }

  function updateList() {
    let input = document.getElementById("myfile1");
    let output = document.getElementById("fileList");
    let data = [];
    let children = "";
    for (let i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      setDocumentFile(input?.files?.item(i));
    }
    setfilelist(data);
    setCloseButton(true);
    //output.innerHTML = "<ul>" + children + "</ul>";
  }

  function getProjetList(id , cat) {
    APIManager.getAPI(freelancerproject + id).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let object = {
          value: "00000000-0000-0000-0000-000000000000",
          label: "All Projects",
        };
        list.push(object);
        response.data.map((item) => {
          let object = {
            label: item.projectName,
            value: item.projectId,
          };
          list.push(object);
        });
        setProjectList(list);
        setprojectseleted(
          location.state ? location.state.projectId : list[0].value
        );
        if (location.state) {
          getWorkHistoryFunEditor(location.state.projectId, id , cat)
        }
        else {
          getWorkHistoryFunEditor(list[0].value , id , cat )
        }

      }
    });
  }
  function getWorkHistoryFunEditor(id ,  userid = null , category = null) {
    APIManager.postAPI(
      dashboardWorkHistory,
      {
        projectId: id,
        UserId: freelancerid ? freelancerid : userid,
        IsWriter: freelancerCategory ?  freelancerCategory : category,
        startDate: location.state?.startDate ? location.state.startDate :head1 ,
        endDate: location.state?.endDate ? location.state.endDate :head2 
      },
      {},
      true
    ).then((response) => {
      let list = [];
      let exportList = [];
      if (response && response.status == 200) {
        response.data.table.map((item) => {
          setArticlesCompleted(item.articlesCompleted);
          setReworkNeeded(item.rework);
          setSuccessRate(fixTwoPlaceDecimal(item.successRate));
        });
        response.data.table1.map((item) => {
          let object = {
            id: "#" + item.id,
            project: item.project,
            keyword: item.keyword,
            cost: <span><i class="fa fa-inr me-2" aria-hidden="true"></i>{item.cost == 0 ? item.costPerWord + "/Word" : item.costPerArticle  + "/Article"}</span>,
            eta: formatRankDate(item.eta),
            rework: (
              <div className="d-flex align-items-center require-repeat">
                <img src="/images/black-repeat.svg" alt="repeat menu"></img>
                <span className="ms-2">{item.rework != null ? item.rework : 0}</span>
              </div>
            ),
            status: articleStatus(item.status, category, item.isWriter)
          };
          list.push(object);

          let exportObject = {
            Id: "#" + item.id,
            Project: item.project,
            Keyword: item.keyword,
            Cost: item.cost == 0 ? item.costPerWord + "/Word" : item.costPerArticle + "/Article",
            Eta: formatRankDate(item.eta),
            Rework: item.rework != null ? item.rework : 0,
            Status: articleStatusExp(item.status, category, item.isWriter),
          };
          exportList.push(exportObject);
        });
        setProjectWorkList(list);
        setProjectWorkListMain(list);
        setExportList(exportList);
      }
    });
  }

  const searchByValue = (value) => {
    const filterTable = projectWorkListMain.filter((o) => {
      return Object.keys(o).some((k) => {
        if (k != "cost" && k != "eta" && k != "rework") {
          return String(o[k]).toLowerCase().includes(value.toLowerCase());
        }
      });
    });
    setProjectWorkList(filterTable);
  };


  function articleStatus(status , cat, isWriter ) {
    cat = cat ?? freelancerCategory
    if (cat == 0 ) {
      switch (status) {
        case 1:
          return getStatus(1);
          break;
        case 2:
          return getStatus(2);
          break;
        case 3:
          return getStatus(3);
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return getStatus(4);
          break;
      }
    }
    else if (cat == 1 ) {
      switch (status) {
        case 5:
          return getStatus(1);
          break;
        case 6:
          return getStatus(2);
          break;
        case 7:
          return getStatus(3);
          break;
        case 8:
        case 9:
          return getStatus(4);
          break;
      }
    }
    else if(cat == 2){
      if(isWriter == 1)
      {
        switch (status) {
          case 1:
            return getStatus(1);
            break;
          case 2:
            return getStatus(2);
            break;
          case 3:
            return getStatus(3);
            break;
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            return getStatus(4);
            break;
        }  
      }
      else{
        switch (status) {
          case 5:
            return getStatus(1);
            break;
          case 6:
            return getStatus(2);
            break;
          case 7:
            return getStatus(3);
            break;
          case 8:
          case 9:
            return getStatus(4);
            break;
        }
      }
    }
  }
  function articleStatusExp(status , cat, isWriter) {
    cat = cat ?? freelancerCategory
    if (cat == 0) {
      switch (status) {
        case 1:
          return getStatusExp(1);
          break;
        case 2:
          return getStatusExp(2);
          break;
        case 3:
          return getStatusExp(3);
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return getStatusExp(4);
          break;
      }
    }
    else  if (cat == 1) {
      switch (status) {
        case 5:
          return getStatusExp(1);
          break;
        case 6:
          return getStatusExp(2);
          break;
        case 7:
          return getStatusExp(3);
          break;
        case 8:
        case 9:
          return getStatusExp(4);
          break;
      }
    }
    else{
      if(isWriter == 1)
      {
        switch (status) {
          case 1:
            return getStatus(1);
            break;
          case 2:
            return getStatus(2);
            break;
          case 3:
            return getStatus(3);
            break;
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            return getStatus(4);
            break;
        }  
      }
      else{
        switch (status) {
          case 5:
            return getStatus(1);
            break;
          case 6:
            return getStatus(2);
            break;
          case 7:
            return getStatus(3);
            break;
          case 8:
          case 9:
            return getStatus(4);
            break;
        }
      }
    }
  }


  return (
    <>
      <Tabs
        className="nav nav-lines  portlet-nav myrequirement database_requierment"
        activeKey={activeTab}
        onChange={(e) => {
          if (e == "personalinfo") {
            setactiveTab("personalinfo");
          } else {
            setactiveTab("workhistory");
            getProjetList(freelancerid , freelancerCategory);

          }
        }}
      >
        <Tabs.TabPane tab="Personal Info" key="personalinfo">
          <div className="portlet mb-0 rounded-0">
            <div className="px-2 py-2 profile-portlet">
              <div className="row ">
                <div className="d-flex align-items-center justify-content-center justify-content-sm-start flex-wrap flex-sm-nowrap">
                  <div className="">
                    <div className="personal-box position-relative cms_bg_gray cms_border_gray mt-3">
                      <div className="profile_detail">
                        {inputimg != "/images/profile_avtar_img.jpg" ?
                          <img
                            class="w-100"
                            // src="/images/profileInageSample.jpg"
                            src={inputimg}
                            className="personal-img"
                          />
                          :
                          <div className="profile_first_latter">
                            <span className="fw-bold position-absolute cms_fc_dark">
                              {firstName.substring(0, 1)?.toUpperCase()}{lastName.substring(0, 1)?.toUpperCase()}
                              </span>
                          </div>}

                      </div>
                      {/* <div className="online-status"></div> */}
                      {isActive == true ? (
                        <div
                          className="online-status green"
                          title="Active on projects"
                        ></div>
                      ) : (
                        <div
                          className="online-status red"
                          title="Not active on any project"
                        ></div>
                      )}
                    </div>
                  </div>
                  <div className="pic-change ms-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        document.getElementById("fileMy1").click();
                      }}
                    >
                      <FeatherIcon className="custome_feather_wh me-2" icon="camera" />
                      Change Photo
                    </button>
                    <input
                      type="file"
                      inputProps={{ accept: imageFileType }}
                      accept={imageFileType}
                      id="fileMy1"
                      class="none"
                      multiple={false}
                      onClick={() => { document.getElementById("fileMy1").value = ""; }}
                      onChange={(e) => {
                        addnewimg1(e);
                      }}
                    ></input>
                    {inputimg != "/images/profile_avtar_img.jpg" ?
                      <>
                        <div className="d-block text-center mt-3">
                          <span className="freelancer-profile-remove" onClick={() => { removeimage() }}>Remove</span>
                        </div>
                      </> : ''}

                  </div>

                </div>
                <div className="row personal-info my-3">
                  <div className="col-md-6 text-start personal_input">
                    <label className="cms_fs16 cms_fw500 cms_fc_black">
                      First Name<label class="field-required"> * </label>
                    </label>
                    <input
                      className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="text"
                      value={firstName}
                      onChange={(text) => {
                        setFirstName(text.target.value);
                        formValidation();
                      }}
                    />
                    {errors?.firstName ? (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.firstName}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 text-start">
                    <label className="cms_fs16 cms_fw500 cms_fc_black">
                      Last Name<label class="field-required"> * </label>
                    </label>
                    <input
                      className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="text"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        formValidation();
                      }}
                    />
                    {errors?.lastName ? (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.lastName}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="row personal-info">
                  <div className="col-md-6 text-start personal_input">
                    <label className="cms_fs16 cms_fw500 cms_fc_black">
                      Mobile No<label class="field-required"> * </label>
                    </label>
                    <input
                      className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="text"
                      value={mobileNumber}
                      onBlur={() => { formValidation() }}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        formValidation();
                      }}
                    />
                    {errors?.mobileNumber ? (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.mobileNumber}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 text-start joining-date ">
                    <label className="cms_fs16 cms_fw500 cms_fc_gray">
                      Email Id<label class="field-required"> * </label>
                    </label>
                    <input
                     className="cms_fs16 cms_fw500  cms_fc_gray cms_line-height w-100"
                      type="text"
                      value={email}
                      readonly
                      // onChange={(e) => {
                      //   setEmail(e.target.value);
                      //   formValidation();
                      // }}
                    />
                    {errors?.email ? (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.email}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="row personal-info my-3">
                  <div className="col-md-6 text-start joining-date personal_input">
                    <label className="cms_fs16 cms_fw500 cms_fc_black cms_fc_gray">
                      Joining Date<label class="field-required"> * </label>
                    </label>
                    <input
                      className="cms_fs16 cms_fw500 cms_fc_gray cms_line-height w-100"
                      type="text"
                      readonly
                      value={DDMMyyyyFormateDateSet(JoiningData)}
                      onChange={() => {
                        formValidation();
                      }}
                    />
                    {errors?.JoiningData ? (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.JoiningData}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 text-start">
                    <label className="cms_fs16 cms_fw500 cms_fc_black mb-3">Articles per Day</label>
                    <input
                      className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="number"
                      value={articlePerDay}
                      placeholder="Ex. 12345.23"
                      onChange={(e) => {
                        setArticlePerDay(e.target.value);
                      }}
                    />
                    {errors?.articlePerDay ? (
                      <>
                        <div className="field-required mt-1">
                          <span> {errors?.articlePerDay}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="row personal-info">
                  <div className="col-md-6 text-start personal_input">
                    <label  className="cms_fs16 cms_fw500 cms_fc_black mb-3">Cost Per Word</label>
                    <input
                     className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="number"
                      value={costPerWord}
                      placeholder="Ex. 12345.23"
                      onChange={(e) => {
                        setCostPerWord(e.target.value);
                      }}
                    />
                    { errors?.costPerWord ? (
                      <>
                        <div className="field-required mt-1">
                          <span> {errors?.costPerWord}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 text-start">
                    <label className="cms_fs16 cms_fw500 cms_fc_black mb-3">Cost Per Article</label>
                    <input
                    className="cms_fs16 cms_fw500 cms_fc_black cms_line-height w-100"
                      type="number"
                      placeholder="Ex. 12345.23"
                      value={costPerArticle}
                      onChange={(e) => {
                        setCostPerArticle(e.target.value);
                      }}
                    />
                    {errors?.costPerArticle ? (
                      <>
                        <div className="field-required mt-1">
                          <span> {errors?.costPerArticle}</span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {/* <div className="personal-doc ms-4 mt-3">
                    <p>
                      Upload Sample Work <span>(Only word & .pdf files)</span>
                    </p>

                    <div className="ms-0 end-box">
                      <div className="d-flex align-items-center justify-content-between personal-choose-file w-50  p-3">
                        <div className="btn-choosefile d-flex align-items-center">
                          <input
                            className=""
                            style={{ display: "none" }}
                            type="file"
                            id="myfile1"
                            name="myfile1"
                            multiple={false}
                            accept={onlyFileType}
                            ref={inputFileRef}
                            onClick={()=>{ document.getElementById("myfile1").value = "";}}
                            onChange={() => {
                              let input = inputFileRef.current;
                              const fileExtension = input.files[0].name.split(".").at(-1);
                              let isvalid = isValidPDFDOCFile(fileExtension)
                              if (isvalid === true) {
                                updateList()
                              }
                              else {
                                document.getElementById("myfile1").value = "";
                              }
                            }}
                          />
                          <a onClick={onBtnClick} id="btnChooseFile" className="btn btn-primary me-3">
                            Choose File
                          </a>
                          <span >{filelist.length ? filelist : <>{(documentURL && !documentFile) ? (
                                  <a
                                    className="fs-6"
                                    target={"_blank"}
                                    href={GET_Server_URL + documentURL}
                                  >
                                    {documentURL}
                                  </a>
                                ) : null}</>}
                          </span>

                        </div>
                        {documentURL || documentFile ? 
                        <>
                          <button
                              type="button"
                              title="Close"
                              style={{
                                border: "0",
                                background: "none",
                              }}
                              class="close d-flex align-items-center"
                              onClick={() => {
                                setfilelist([]);
                                setDocumentFile("");
                                setCloseButton(false);
                                setDocumentURL("");
                              }}
                            >
                              {" "}
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </> : ''}
                            
                      </div>
                    </div>
                    {(documentURL && !documentFile)  ? 
                    <div className="mt-3 freelancer-profile-download">
                      <u><a href={GET_Server_URL + "/" + documentURL} download target="_blank" onClick={() => {
                        saveAs(
                          GET_Server_URL + documentURL);
                      }}>Download</a></u>
                    </div>
                    : <></>}
                  </div> */}
                <div className="personal-doc ms-4 mt-3">
                  <p className="cms_fs16 cms_fw500 cms_fc_black cms_line-height">
                    Upload Sample Work <span className="cms_fs16 cms_fw400 cms_fc_dark-black cms_line-height">(Only word & .pdf files)</span>
                  </p>

                  {/* <div className="end-box ms-0"> */}
                  <div className="industries-choose-file d-flex align-items-center justify-content-between personal-choose-file w-50 p-3">
                    <div className="btn-choosefile d-flex align-items-center">
                      <input
                        className=""
                        style={{ display: "none" }}
                        type="file"
                        id="myfile1"
                        name="myfile1"
                        multiple={false}
                        accept={onlyFileType}
                        ref={inputFileRef}
                        onClick={() => { document.getElementById("myfile1").value = ""; }}
                        onChange={() => {
                          let input = inputFileRef.current;
                          const fileExtension = input.files[0].name
                            .split(".")
                            .at(-1);
                          let isvalid = isValidPDFDOCFile(fileExtension);
                          if (isvalid === true) {
                            updateList();
                          } else {
                            document.getElementById("myfile1").value = "";
                          }
                        }}
                      />
                      <a
                        onClick={onBtnClick}
                        id="btnChooseFile"
                        className="btn btn-primary me-3"
                      >
                        Choose File
                      </a>
                      <span  className="cms_fs14 cms_fw500 cms_fc_black">{filelist.length ? filelist :
                        <>{(documentURL && !documentFile) ? (
                          <a
                            className="cms_fs14 cms_fw500 cms_fc_black"
                            target={"_blank"}
                            href={GET_Server_URL + documentURL}
                          >
                            {documentURL.length > 0 ? filename[1] : ''}
                          </a>
                        ) : null}</>
                      }
                      </span>
                    </div>
                    {documentURL || documentFile ? (
                      <>
                        <button
                          type="button"
                          title="Close"
                          style={{
                            border: "0",
                            background: "none",
                          }}
                          class="close"
                          onClick={() => {
                            setfilelist([]);
                            setDocumentFile("");
                            setCloseButton(false);
                            setDocumentURL("");
                          }}
                        >
                          {" "}
                          <span  aria-hidden="true">&times;</span>
                        </button>
                      </>
                    ) : (
                      <></>
                    )}

                  </div>

                  {/* </div> */}
                  {(documentURL && !documentFile) ?
                    <div className="mt-3 freelancer-profile-download">
                        <u><a href={GET_Server_URL + "/" + documentURL} target="_blank" download onClick={() => {
                        saveAs(
                          GET_Server_URL + "/" + documentURL);
                      }}>Download</a></u>
                    </div>
                    : <></>}
                </div>


                <div className="row mt-3 mb-5">
                  <div className="text-right">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        localStorage.setItem("active", 2);
                        navigate("/content-main/databaseContent/");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-primary ms-3"
                      onClick={() => {
                        if (formValidation()) {
                          updateFreelancerData();
                        }
                      }}
                    >
                      Update Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Work History" key="workhistory">
          <div className="portlet work-history-cal cms_border_gray mt-3 mb-3">
            <div className="work-hisory-select work-select">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <select
                  class=" form-select w-auto cms_fs16  ps-3 mb-3 mb-xs-0"
                  aria-label="Default select"
                  value={projectseleted}
                  onChange={(item) => {
                    setprojectseleted(item.target.value);
                    getWorkHistoryFunEditor(item.target.value , freelancerid ,freelancerCategory);
                  }}
                >
                  {projectList?.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>

                <div className="d-flex align-items-center mb-3 mb-xs-0 justify-content-end require-cal cms_fs16 cms_fw500 cms_fc_black cms_line-height cms_radius8 cursor-pointer" ref={ref}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-calendar me-2"
                  >
                    <rect
                      x="3"
                      y="4"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg> */}
                  <FeatherIcon className="custome_feather ms-2 me-2" icon="calendar" />
                  <div className="" onClick={() => setOpen(!open)}>
                    {monthYear}
                  </div>
                  {open && (
                    <div className="scope-calendar">
                      <div
                        id="example-collapse-text-calendar"
                        style={{ right: "0" }}
                      >
                        <Card body className="daterange-picker-card  mt-2">
                          <div className="d-flex justify-content-between">
                            <div className="calendar-col">
                              <Calendar
                                //date={new Date()}
                                maxDate={new Date()}
                                onlyMonthPicker
                                onChange={(e) => {
                                  let date1 = e.format().split("/");
                                  let daysInMonth = getDaysInMonth(
                                    date1[1],
                                    date1[0]
                                  );
                                  let dateObjArr = [
                                    new Date(date1[0] + "/01/" + date1[1]),
                                    new Date(
                                      date1[0] +
                                      "/" +
                                      daysInMonth +
                                      "/" +
                                      date1[1]
                                    ),
                                  ];
                                  setMonthYear(
                                    setMonths(dateObjArr[0].getMonth() + 1) +
                                    " " +
                                    date1[1]
                                  );
                                  setHead1(
                                    convertDate(
                                      dateObjArr[0].toLocaleDateString()
                                    )
                                  );
                                  setHead2(
                                    convertDate(
                                      dateObjArr[1].toLocaleDateString()
                                    )
                                  );
                                }}
                              />
                            </div>
                            <div className="ms-3">
                              <button
                                className="btn-outline btn btn-primary"
                                onClick={() => {

                                  delete location.state;
                                  setOpen(false);
                                  getWorkHistoryFunEditor(projectseleted ,freelancerid ,freelancerCategory);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  )}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg> */}
                  <FeatherIcon className="custome_feather ms-2" icon="chevron-down" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="work-history-tab px-0">
              <div className="d-flex align-items-center justify-content-between work-history-tab p-0 flex-wrap">
                <div className="col-md-4 ps-0 article-complete ps-0">
                  <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3">
                    <div className="raise-svg me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#0691EF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </div>
                    <div>
                      <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Articles Completed </label>
                      <h3 className="fw-bold cms_fc_black">{articlesCompleted}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 rework-need px-2">
                  <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3 ">
                    <div className="raise-svg me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#0691EF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-repeat"
                      >
                        <polyline points="17 1 21 5 17 9"></polyline>
                        <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                        <polyline points="7 23 3 19 7 15"></polyline>
                        <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                      </svg>
                    </div>
                    <div>
                      <label class="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Rework Needed</label>
                      <h3 className="fw-bold cms_fc_black">{reworkNeeded}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pe-0 success-rate pe-0">
                  <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3">
                    <div className="raise-svg me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#0691EF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-award"
                      >
                        <circle cx="12" cy="8" r="7"></circle>
                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                      </svg>
                    </div>
                    <div>
                      <label class="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Success Rate</label>
                      <h3 className="fw-bold cms_fc_black">{successRate} %</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="work-history-box px-0 my-3">
            <div class="d-flex align-items-center justify-content-between work_search_box">
              <div class="px-0 work-history-search w-100">
                <form class="search-box w-100 d-flex">
                  <button type="button">
                    <i class="fa fa-search"></i>
                  </button>
                  <input
                    value={searchText}
                    type="text"
                    class="w-100"
                    placeholder="Search..."
                    name="search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      searchByValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        e.preventDefault();
                      }
                    }}
                  />
                </form>
              </div>
              <div class="px-0 ">
                <div class=" ms-3 w-100 work_export_btn">
                  <button class="btn btn-primary export-btn">
                    <div class="d-flex align-items-center fs-6 fw-6 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-download"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                      </svg>
                      <span
                        class="ms-2 ps-1"
                        onClick={() => {
                          exportToEXCEL(exportList, "Work History");
                        }}
                      >
                        Export
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 px-0">
            <Table
              className="table table-hover work-history-table common_work-history_table table-responsive"
              columns={projectCols}
              dataSource={projectWorkList}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} keywords`,
              }}
              scroll={{ x: 100 }}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
export default FreelancersMyRequirement;
