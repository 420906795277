import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';


export const main = css({
    ".___SBaseTrigger_15wp9_gg_": {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
    },
    ".___SOutline_letz7_gg_" : {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
        background: "none !important"
    },
    ".___SBoxInline_ibl27_gg_" : {
        width: "100%",
        background: "none !important"
    },
})
export const subTicketIcon = css({
    backgroundColor: "#E2F1FC",
    padding: "5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    maxWidth: "2.5rem",
    maxHeight: "2.5rem",
    marginRight: "1rem"
})

export const ticketTitle = css({
    ...textStyles.h6
})

export const ticketDetails = css({
    ...textStyles.s2Medium,
    color: Colors.neutral[500],
    display: "flex",
    alignItems: "center",
    marginTop: "1rem"
})

export const errorMsg = css({
    ...textStyles.b1,
    marginTop: "0.25rem",
    color: Colors.error[800]
})

export const inputContainer = css({
    display: "flex",
    marginTop: "2rem"
})

export const inputStyles = (custom) => css({
    ".___SValue_1sh6a_gg_:not(:disabled):read-only~.___SOutline_1sh6a_gg_": {
        background: "none !important",
    },
    "input": {
        ...textStyles.H1125Medium,
        ...custom,
    }
})

export const subjectStyle = css({
    marginRight: "0.50rem",
    ".___SValue_letz7_gg_:not(:disabled):read-only~.___SOutline_letz7_gg_": {
        background: "none !important"
    },
    ".___SBaseTrigger_15wp9_gg_": {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
    },
    ".___SOutline_letz7_gg_" : {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
        background: "none !important"
    },
    ".___SBoxInline_ibl27_gg_" : {
        width: "100%",
        background: "none !important"
    },
})

export const keyword = (disabled = false, width) => {
    return css({
        ...textStyles.H1Medium,
        color: Colors.neutral[900],
        // backgroundColor: "red",
        textAlign: "center",
        maxWidth: width ?? "fit-content",
        ...(width && { minWidth: width }),
        marginRight: "0.50rem",
        ...(disabled && {
            pointerEvents: "none",
            opacity: "75%"
        })

    });
}

export const selectDropDownContainer = css({
    width: "6.25rem !important",
});

export const priorityStyles = (priority, custom) => {
    let background = Colors.error[50];
    let color = Colors.error[800];
    if (priority == "Medium") {
        background = Colors.warning[50];
        color = Colors.warning[500];
    }
    if (priority == "Low") {
        background = Colors.success[50];
        color = Colors.success[200];
    }
    if (priority == "High") {
        background = Colors.error[50];
        color = Colors.error[800];
    }
    return css({
        ...textStyles.s2Medium,
        background: background,
        color: color,
        minHeight: "2.5rem !important",
        border: `1px solid ${color}`,
        ...custom,
    });
};

export const disable = (disabled) => {
    return css({
        ...(disabled && {
            pointerEvents: "none",
            opacity: "75%"
        })
    })
}

export const assignedToStyles = css({
    ...textStyles.b2,
    color: Colors.neutral[800],
    minHeight: "2.5rem !important",
})
