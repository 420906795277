import axios from "axios";
import { API_BASE_URL, GET_RANKING_BASE_URL, GET_TICKETING_BASE_URL } from "../utility/Constant";

const instance = axios.create({
  baseURL: GET_RANKING_BASE_URL
});

instance.interceptors.request.use(
  (config) => {
    // Modify the request config before sending it
    // For example, you can add headers here
    // config.headers.Authorization = `Bearer ${yourAuthToken}`;
    //  config.headers['X-CSRF-TOKEN'] = "nHNj8WnAQuITHiPLwXaVHjyoOQVNUKkL";
    // config.headers['Content-type'] = "application/json"
    // config.headers['ngrok-skip-browser-warning'] = true
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    // Modify the response data before resolving the promise
    // For example, you can extract data or handle errors here
    return response.data;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

const APIV1 = async (method, url, data) => {
  try {
    const response = await instance[method](url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export default APIV1;
