import React, { useState, useEffect } from "react";
import { Table, Input, Space } from "antd";
import { Select } from "antd";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import {
  exportInToCSV,
  exportToEXCEL,
  setPriority,
  setTicketType,
  setStatus,
  setExportStatus,
  themeStyle,
  // getCheckPermission,
  findUnique,
  ticketType,
} from "../utility/Globals";
import moment from "moment";
import { default as ReactSelect } from "react-select";
import Chart from "react-apexcharts";
import { components } from "react-select";
import APIManager from "../services/APIManager";
import { Sorter } from "../common/Sorter";
import {
  getMemberByOrgId,
  getTicketById,
  getTicketByOMPid,
  addTicketAssign,
} from "../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
  formatDate,
} from "../utility/Globals";
import { mySingleton } from "../singleton/LocalDataStore";
import { Calendar } from "react-multi-date-picker";
import { useLocation } from "react-router-dom";

const { Option } = Select;

function onChange(value) {}

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

function onChange1(date, dateString) {}

function TicketsList(props) {
  const [gid, setGid] = useState("");

  let isRefresh = props.refreshTicketData;
  if (isRefresh) {
    setTimeout(() => {
      isRefresh = false;
    }, 5000);
  }
  const location = useLocation();
  var params = location.pathname.split("/");

  const [options, setObject] = useState({
    title: {
      text: "Tickets",
      align: "center",
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stacked: true,
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    xaxis: {
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: "Days",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "No.of Tickets ",
        style: {
          color: "#000",
        },
      },
    },
  });

  const [series, setSeries] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [searchdata, setsearch] = useState([]);
  const [showApplyFilter, setShowApplyFilter] = useState(false);

  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);

  function addticket() {
    let idList = [];
    optionSelected1.map((item) => {
      idList.push(item.key);
    });

    APIManager.postAPI(
      addTicketAssign,
      {
        Ids: idList,
        memberId: memberlistSelected,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
      }
    });
  }

  useEffect(() => {
    getTicktes();
    getTicketlist();
    getTicketChartsData();
    getmembersData();
  }, [props.refreshTicketData]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          ticket: item.ticket.props.children,
          type: item.ticketType,
          subject: item.subject,
          priority: item.priority,
          status: item.status.props.children,
          raisedby: item.raisedby,
          raisedon: item.raisedon,
          duedate: item.duedate,
          assignedto: item.assignedto,
          project: item.project,
          module: item.module,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };
  const addticketnew = () => {
    mySingleton.getInstance().setIsAdd(false);
    // history.push("/main/Tickets");
  };

  function getTicktes() {
    var params = !gid ? location.pathname.split("/") : [];
    let id = gid ? gid : params[params.length - 1];
    setGid(gid ? gid : id);
    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 6);
    const url = `${getTicketById}?moduleId=${id}&startDate=${mmDDyyyyFormateDateSet(
      props.startDate
    )}&endDate=${mmDDyyyyFormateDateSet(
      props.endDate
    )}&projectId=${localStorage.getItem("projectGId")}`;
    APIManager.getAPI(url).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const filtertype = [];
        const filterpriority = [];
        const filterassignedto = [];
        const filterproject = [];
        let exportList = [];
        let c = 1;
        response.data.map((item) => {
          exportList.push({
            ticket: c,
            type: setTicketType(item.ticketType),
            subject: item.subject,
            priority: setPriority(item.priority),
            status: setExportStatus(item.status),
            raisedby: item.raisedBy,
            raisedon: item.raisedOn,
            duedate: item.dueOn,
            assignedto: item.assignedMembers.name,
            project: item.project.projectCode,
            module: item.module?.name,
          });
          list.push({
            key: item.id,
            ticket: (
              <div
                style={{ color: "#0d6efd" }}
                onClick={() => {
                  mySingleton.getInstance().setIsAdd(true);
                  mySingleton.getInstance().setSelectedObj(item);
                  // history.push("/main/Tickets");
                }}
              >
                {c}
              </div>
            ),

            type: setTicketType(item.ticketType),
            subject: item.subject,
            priority: setPriority(item.priority),
            status: setStatus(item.status),
            raisedby: item.raisedBy,
            raisedon: moment(item.raisedOn).format("DD/MM/YYYY"),
            duedate: moment(item.dueOn).format("DD/MM/YYYY"),
            assignedto: item.assignedMembers.name,
            project: item.project.projectCode,
            module: item.module?.name,
          });
          let filtertypeobj = {
            text: setTicketType(item.ticketType),
            value: setTicketType(item.ticketType),
          };
          filtertype.push(filtertypeobj);
          let filterpriorityobj = {
            text: setPriority(item.priority),
            value: setPriority(item.priority),
          };
          filterpriority.push(filterpriorityobj);
          let filterassignedtoobj = {
            text: item.assignedMembers.name,
            value: item.assignedMembers.name,
          };
          filterassignedto.push(filterassignedtoobj);
          let filterprojectobj = {
            text: item.project.projectCode,
            value: item.project.projectCode,
          };
          filterproject.push(filterprojectobj);
          c++;
        });
        setsearch(list);
        setExportList(exportList);
        setteamList(list);

        const columns = [
          {
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            width: "10%",
            sorter: {
              compare: (a, b) =>
                Sorter.DEFAULT(
                  a.ticket.props.children,
                  b.ticket.props.children
                ),
              multiple: 3,
            },
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            filters: findUnique(filtertype, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.type == value,
          },
          {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
            width: "10%",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.subject, b.subject),
              multiple: 3,
            },
          },
          {
            title: "Priority",
            dataIndex: "priority",
            key: "priority",
            filters: findUnique(filterpriority, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.priority == value,
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "Raised By",
            dataIndex: "raisedby",
            key: "raisedby",
          },
          {
            title: "Raised On",
            dataIndex: "raisedon",
            key: "raisedon",
          },
          {
            title: "Due Date",
            dataIndex: "duedate",
            key: "duedate",
          },
          {
            title: "Assigned To",
            dataIndex: "assignedto",
            key: "assignedto",
            filters: findUnique(filterassignedto, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.assignedto == value,
          },
          {
            title: "Project",
            dataIndex: "project",
            key: "project",
            filters: findUnique(filterproject, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.project == value,
          },
          {
            title: "Module",
            dataIndex: "module",
            key: "module",
            width: "10%",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.module, b.module),
              multiple: 3,
            },
          },
        ];
        setteamcol(columns);
      }
    });
  }

  function getTicketlist() {
    var params = !gid ? location.pathname.split("/") : [];
    let id = gid ? gid : params[params.length - 1];
    setGid(gid ? gid : id);
    const url = `${getTicketById}?moduleId=${id}`;
    APIManager.getAPI(url).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.subject,
          };
          list.push(obj);
        });
        setTicketlist(list);
      }
    });
  }
  function getTicketChartsData() {
    let ProjectId = localStorage.getItem("projectGId");
    let organizationId = localStorage.getItem("organizationId");
    let newDate = new Date().toISOString().slice(0, 10);
    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);
    var params = location.pathname.split("/");
    var Module = params[params.length - 1];
    var bodyFormData = new FormData();
    bodyFormData.append(
      "StartDate",
      mmDDyyyyFormateDateSet(new Date(props.startDate))
    );
    bodyFormData.append(
      "EndDate",
      mmDDyyyyFormateDateSet(new Date(props.endDate))
    );
    // bodyFormData.append("organizationId", organizationId);
    bodyFormData.append("ModuleId", Module);
    bodyFormData.append("projectGId", ProjectId);
    APIManager.postAPI(getTicketByOMPid, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          let inProgressgraphData = [];
          let overDuegraphData = [];
          let resolvedgraphData = [];
          let dates = [
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 6)
            ),
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 5)
            ),
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 4)
            ),
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 3)
            ),
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 2)
            ),
            formateDateIntoDatemonth(
              new Date().setDate(new Date().getDate() - 1)
            ),
            formateDateIntoDatemonth(new Date()),
          ];

          dates.map((date) => {
            if (response.data.some((x) => formatDate(x.date) === date)) {
              response.data.map((item) => {
                if (date === formatDate(item.date)) {
                  inProgressgraphData.push(
                    item.inProgress ? item.inProgress : 0
                  );
                  overDuegraphData.push(item.overDue ? item.overDue : 0);
                  resolvedgraphData.push(item.resolved ? item.resolved : 0);
                }
              });
            } else {
              inProgressgraphData.push(0);
              overDuegraphData.push(0);
              resolvedgraphData.push(0);
            }
          });
          setSeries([
            {
              name: "InProgress",
              data: inProgressgraphData,
              color: "#4f81bc",
            },
            {
              name: "Overdue",
              data: overDuegraphData,
              color: "#c0504e",
            },
            {
              name: "Resolved",
              data: resolvedgraphData,
              color: "#9aba58",
            },
          ]);
        }
      }
    );
  }

  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  function getmembersData() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
        setmemberlistSelected(list[0].id);
      }
    });
  }
  function members(e) {
    var c = e.target.value;
    setmemberlistSelected(c);
  }
  const [optionSelected1, setoptionSelected1] = useState([]);
  function handleChange1(selected) {
    setoptionSelected1(selected);
  }

  const [Ticketlist, setTicketlist] = useState([]);

  const Option = (props) => {
    return (
      <div className="rect-selectbox">
        <components.Option {...props}>
          <label>{props.data.label}</label>
        </components.Option>
      </div>
    );
  };
  return (
    <>
      <section class="outer-wrapper client-list ticket-list mt-3">
        <div class="row">
          <div class="col-sm-5 pad-lzero"></div>
          <div class="col-sm-7 add-new-btnw">
            <button
              type="button"
              class="btn btn-primary"
              onClick={addticketnew}
            >
              Add New Ticket
            </button>
          </div>
        </div>

        <div class="top-ticket-frame portlet px-4 py-4">
          <div className="row">
            <div className="col-md-3">
              <ReactSelect
                options={Ticketlist}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                styles={themeStyle()}
                onChange={handleChange1}
                allowSelectAll={true}
                value={optionSelected1}
              />
            </div>

            <div className="col-sm-9 add-new-btnw">
              <select
                className="me-3"
                style={{ padding: 10 + "px" }}
                value={memberlistSelected}
                onChange={members}
              >
                {memberlist.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
              <button
                type="button"
                class="btn btn-primary"
                onClick={addticket}
                style={{ padding: 10 + "px" }}
              >
                Assign
              </button>
            </div>
          </div>
        </div>

        {showApplyFilter && (
          <div className="apply-filter">
            <div></div>
            <span>
              <Input.Search
                allowClear
                placeholder="Ticket"
                onSearch={() => {}}
                id="input-s"
              />
            </span>
            <span>
              <Select
                showSearch
                placeholder="Ticket Type"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ticketType.map((i) => {
                  return <option value={i.value}>{i.label}</option>;
                })}
              </Select>
            </span>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Project"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="MyntraShoes">Myntra Shoes</Option>
              <Option value="AmazonMerchandise">Amazon Merchandise</Option>
              <Option value="MyntraFashion">Myntra Fashion</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Module"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="da/pachecker">Website Authority</Option>
              <Option value="googletrends">Google Trends</Option>
              <Option value="pagespeedandcorewebvitals">PageSpeed</Option>
              <Option value="clickshare">Click Share</Option>
              <Option value="ranktracking">Rankings</Option>
              <Option value="siteuptimemonitor">Site Uptime Monitor</Option>
              <Option value="gscdataextractor">Impressions & Clicks</Option>
              <Option value="organicresearch">Organic Research</Option>
              <Option value="roicalculator">Return on Investment</Option>
              <Option value="contentword">Content Word Count on a Page</Option>
              <Option value="backlinkcount">Backlink Profile</Option>
              <Option value="keywordsearch">Keyword Search</Option>
              <Option value="seovolatality">SERP Volatility</Option>
              <Option value="seomanualaudit">Audit Findings</Option>
              <Option value="googleanalyticedataextractor">Traffic</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Priority"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="low">Low</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Status"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="open">Open</Option>
              <Option value="inprogress">Inprogress</Option>
              <Option value="resolved">Resolved</Option>
              <Option value="reopen">Reopen</Option>
              <Option value="closed">Closed</Option>
              <Option value="overdue">Overdue</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Assigned"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="emp1">Emp 1</Option>
              <Option value="emp2">Emp 2</Option>
              <Option value="emp3">Emp 3</Option>
            </Select>
            <Select
              showSearch
              style={{ width: 200, margin: "12px" }}
              placeholder="Raised By"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="customer">Customer</Option>
              <Option value="bot">Bot</Option>
              <Option value="self">Self</Option>
            </Select>
            <Space
              style={{ margin: "12px" }}
              className="create-cal"
              direction="vertical"
            >
              <DatePicker placeholder="Created On" onChange={onChange1} />
            </Space>
            <Space style={{ margin: "12px" }} direction="vertical">
              <DatePicker placeholder="Due On" onChange={onChange1} />
            </Space>
            <span>
              <Input.Search
                style={{ width: 200, margin: "12px" }}
                allowClear
                placeholder="Key word search"
                onSearch={() => {}}
                id="input-s"
              />
            </span>
            <div
              class="add-new-btnw"
              style={{ marginRight: 10 + "px", marginBottom: 10 + "px" }}
            >
              <button
                class="btn btn-primary"
                onClick={() => setShowApplyFilter((prev) => !prev)}
              >
                Apply
              </button>
            </div>
          </div>
        )}
 
{/* <div className="calendar-col">
<div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      // onClick={() => setOpen(!open)}
                    >
                      Date Pick
                      {/* {calanderDate(head1, head2)} */}
                    {/* </button>
                  </div>
                        <Calendar
                          className="custom-calendar"
                          // value={values}
                          // onChange={(e) => {
                          //   setValues(e);
                          //   setHead1(e[0].format());
                          //   setHead2(e[1]?.format());
                          // }}
                          range
                          numberOfMonths={1}
                          showOtherDays
                        />
                      </div>
                       */} 
        <div className="portlet mt-3">
          <div class="portlet-header portlet-header-bordered">
            <h3 class="portlet-title main-title">Tickets Line chart</h3>
          </div>
          <div className="d-flex justify-content-center mt-3">
            {series ? (
              <Chart options={options} series={series} type="bar" width="700" />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="portlet mt-3">
          <div class="portlet-header portlet-header-bordered">
            <h3 class="portlet-title main-title">Tickets</h3>
          </div>

          <div class="common-table">
            <div class="row">
              <div class="col-md-12 text-end">
                <span>
                  {/* {getCheckPermission("export", "modules") ? ( */}
                    <button
                      class="btn btn-primary dropdown-toggle"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Tickets"
                        );
                      }}
                    >
                      Export
                    </button>
                  {/* ) : (
                    <></>
                  )} */}
                  {/* <div
                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      onClick={() => {
                        exportToEXCEL(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Tickets"
                        );
                      }}
                    >
                      <a class="dropdown-item">Excel</a>
                    </div>
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Tickets"
                        );
                      }}
                    >
                      <a class="dropdown-item">CSV</a>
                    </div>
                  </div> */}
                </span>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <Table
                className="table table-bordered table-hover mt-3 ticket-table custome_filter"
                id="datatable-1"
                columns={teamcol}
                dataSource={[...teamlist]}
                rowSelection={{ type: "checkbox", ...rowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TicketsList;
