import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Card } from "react-bootstrap";
import { Table } from "antd";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  exportToEXCEL,
  convertDate,
  downloadPDFfile,
  getBase64path,
  dateRagngeSelectWeek,
  dateRagngeSelectFornightly,
  dateRagngeSelectMonthly,
  getWeek,
  getCheckPermission,
  getDomainName2,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  addcontentwordcount,
  getContentWordGetConfig,
  sendEmailApi,
  getKeywordTypeAPI,
  moduleContentWordCount,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";
import NotFound from "../NotFound";

function ModuleExpandContentWordCount() {
  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
    },
    yaxis: {
      logBase: 5,
      tickAmount: 5,
      min: 0,
    },
  });

  const [series, setSeries] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [urls, setUrl] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [mainDataList, setMainDataList] = useState([])
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
    },
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [mainList1, setMainList1] = useState([])
  const applyBtn = useRef(null);
  const applyBtn1 = useRef(null);
  function handleUrlChange(selected) {
    setSelectedUrl(selected.target.value);
  }
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [keywordTypeList, setKeywordTypeList] = useState([]);
  const [col, setCol] = useState([]);
  const [dataSourcee, setDataSourcee] = useState([]);
  const [noConfig, setNoConfig] = useState(true);
  const [selectedType, setselectedType] = useState("0");
  const [calendarType, setCalendarType] = useState(0);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());
  const [isDateRage, setIsDateRage] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const ref = useRef();

  useEffect(() => {
    setNotFound(getCheckPermission("content_word_count_on_a_page", "reports"));
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  useEffect(() => {
    getContentWordConfig();
  }, []);

  function getContentWordConfig() {
    APIManager.getAPI(
      getContentWordGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      var chartStartDate, chartEndDate;
      if (response && response.status === 200) {
        if (response?.data) {
          setNoConfig(false);
          setCalendarType(response?.data.frequency);
          if (response?.data.frequency === 1) {
            var todayDate = new Date();
            var week = getWeek(new Date(todayDate));

            let selection = {
              selection1: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection1",
              },
              selection2: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.frequency === 2) {
            var todayDate2 = new Date();

            let selection = {
              selection1: {
                startDate: addDays(todayDate2, -14),
                endDate: addDays(todayDate2, 0),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(todayDate2, -14),
                endDate: addDays(todayDate2, 0),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          } else if (response?.data.frequency === 3) {
            var today_Date = new Date();
            var firstDay = new Date(today_Date.getFullYear(), today_Date.getMonth(), 1);
            var lastDay = new Date(today_Date.getFullYear(), today_Date.getMonth() + 1, 0);
            let selection = {
              selection1: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection1",
              },
              selection2: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;
            setState((state) => {
              return state;
            });

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          } else {
            let selection = {
              selection1: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHeadValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());

          }
        }
        const list = [];
        let urlList = [];
        response.data?.contentWordCountUrlConfigurations.forEach((item) => {
          list.push({
            id: item?.urls?.id,
            value: item?.urls?.fieldValue,
            label: item?.urls?.fieldValue,
          });
          urlList?.push(item?.urls?.id);
        });
        setUrl(list);
        setSelectedUrl(list[0].id);
        getKeywordType([]);
        setExportList([]);
        setCol([]);
        setDataSourcee([]);
        setIsDateRage(true);
        getDataCWC(urlList[0], chartStartDate, chartEndDate);
      }
    });
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };


  function getDataCWC(urlid, chartStartDate, chartEndDate) {
    let rowData;
    let StartDate1 = convertDate(chartStartDate);
    let EndDate1 = convertDate(chartEndDate);

    let StartDate2 = convertDate(chartStartDate);
    let EndDate2 = convertDate(chartEndDate);
    if (isDateRage) {
      rowData = {
        url: urlid,
        projectId: localStorage.getItem("projectGId"),
        ContentWordDateRange: { FROM: convertDate(chartStartDate), TO: convertDate(chartEndDate) },
        KeywordTypeId: selectedType.id,
        ContentWordCompareDateRange: { FROM: convertDate(chartStartDate), TO: convertDate(chartEndDate) },
      };
    } else {
      rowData = {
        url: urlid,
        projectId: localStorage.getItem("projectGId"),
        ContentWordDateRange: {
          FROM: StartDate1,
          TO: EndDate1,
        },
        KeywordTypeId: selectedType.id,
        ContentWordCompareDateRange: {
          FROM: StartDate2,
          TO: EndDate2,
        },
      };
    }
    createaddcontentwordcount(rowData);
  }

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Content Word Count Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          url: item.url,
          teast6: item.teast6,
          totalWords: Math.floor(item.totalWords),
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  function createaddcontentwordcount(rowData2) {
    setCol([]);
    APIManager.postAPI(addcontentwordcount, rowData2, null, true).then(
      (response) => {
        if (response && response.status === 200) {
          let cols = [];
          let flag = true;
          let graphData1 = [];
          let graphData2 = [];
          let dataForGraph = [];
          let seriesData1 = [];
          let seriesData2 = [];
          let respo = [...response.data.tableContent]

          if (respo.length !== 0 && response.data.compareTableContent.length !== 0) {
            let tempList = [respo.length]
            respo.forEach((i, index) => {
              tempList[index] = i;
            })
            setMainList1(tempList)
            respo.forEach((item) => {
              let list;
              if (flag) {
                list = Object.keys(item);
                for (let i = 0; i <= 1; i++) {
                  if (list[i] !== 'isScrape') {
                    let obj = {
                      title: list[i].toUpperCase(),
                      dataIndex: list[i],
                      key: list[i],
                    };
                    cols.push(obj);
                    flag = false;
                  }
                }
                for (let i = 2; i < list.length; i++) {
                  if (list[i] !== 'isScrape') {
                    let d = i - 1;
                    let obj = {
                      title: list[i].toUpperCase(),
                      dataIndex: list[i],
                      key: list[i],
                      children: [
                        {
                          title: "Frequency",
                          dataIndex: `frequency_${d}`,
                          key: `frequency_${d}`,
                        },
                        {
                          title: "Density",
                          dataIndex: `density_${d}`,
                          key: `density_${d}`,
                        },
                      ],
                    };
                    cols.push(obj);
                    flag = false;
                  }
                }
              }
              seriesData1.push(getDomainName2(item.url));
              graphData1.push(Math.floor(item.totalWords));
            });

            response.data.compareTableContent.forEach((item) => {
              seriesData2.push(item.url);
              graphData2.push(Math.floor(item.totalWords));
            });
            setCol(cols);

            let keyDataIndex = [];
            cols.forEach((i, index) => {
              if (index <= 1) {
                keyDataIndex.push(i.dataIndex);
              } else if (index > 1) {
                keyDataIndex.push(i.dataIndex);
                i.children.forEach((j, index1) => {
                  keyDataIndex.push(j.dataIndex);
                });
              }
            });

            let listData = [];
            let newListData = [];
            respo.forEach((item, index) => {
              let keys = Object.keys(item);
              item.totalWords = parseInt(item.totalWords);
              item["key"] = index + 1;
              for (let i = 1; i <= cols.length - 2; i++) {
                item[`density_${i}`] =
                  (
                    ((item[keys[i + 1]] ? +item[keys[i + 1]] : 0) * 100) /
                    item.totalWords
                  ).toFixed(2) + "%";
                item[`frequency_${i}`] = item[keys[i + 1]] ? item[keys[i + 1]] : "-";
                item[keys[i + 1]] = item[keys[i + 1]] ? item[keys[i + 1]] : "-";
              }
              listData.push(item);
              setMainDataList(listData)
              newListData.push(item);
            });
            listData.forEach(e => {
              e.url = +e.isScrape === 0 ? e.url + ' - Not Scrapable URL' : e.url;
            });

            setDataSourcee(listData);
            setChartObject(seriesData1);
            let obj = {
              pagination: {
                current: pageNumber,
                pageSize: pageSize,
                position: ["bottomRight"],
                showSizeChanger: true,
                total: listData.length,
              },
            }
            setTableParams(obj);
            let exportListData = newListData;
            for (let i = 0; i < exportListData.length; i++) {
              let newExportListData = exportListData[i];

              let listKeys = Object.keys(newExportListData);
              let newDensityKey = "density_";
              let newFrequencyKey = "frequency_";
              let count = 1;

              listKeys = listKeys.filter(e => !e.includes(newDensityKey) && !e.includes(newFrequencyKey));

              for (let j = 0; j < listKeys.length; j++) {
                delete newExportListData[newDensityKey + count];
                delete newExportListData[newFrequencyKey + count];
                if (listKeys[j] !== "url" && listKeys[j] !== "totalWords" && listKeys[j] !== "key" && listKeys[j] !== "isScrape") {
                  let density = (((!isNaN(newExportListData[listKeys[j]]) ? +newExportListData[listKeys[j]] : 0) * 100) / newExportListData.totalWords).toFixed(2) + "%";
                  let densityKey = "density_" + count;
                  let frequencyKey = "frequency_" + count;
                  newExportListData[listKeys[j]] = {
                    [densityKey]: density,
                    [frequencyKey]: newExportListData[listKeys[j]] ? newExportListData[listKeys[j]] : "-"
                  }
                  count++;
                }
                if (count > 3)
                  count = 1;
              }
              exportListData[i] = newExportListData;
            }
            setExportList(exportListData);
            dataForGraph.push(
              {
                name: head1 + " - " + head2,
                data: graphData1,
              }
            );
            setSeries(dataForGraph);
          }
          else {
            setChartObject(seriesData1);
            dataForGraph.push(
              {
                name: head1 + " - " + head2,
                data: graphData1,
              }
            );
            setSeries(dataForGraph);
            const columns = [
              {
                title: "URL",
              },
              {
                title: "TOTALWORDS",
              },
              {
                title: "Keyword",
                children: [
                  {
                    title: "Frequency",
                  },
                  {
                    title: "Density",
                  },
                ],
              },
            ];
            setCol(columns)
            setDataSourcee([]);
          }
        }
      }
    );
  }

  function setChartObject(seriesData1) {
    let option = {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        tickPlacement: "on",
        categories: seriesData1,
      },
      yaxis: {
        logBase: 5,
        tickAmount: 5,
        min: 0,
      },

      markers: {
        size: 5,
        colorField: ["#ba4636"],
      },
    };

    setObject(option);
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() === "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() === "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() === "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    applyBtn.current.click();
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleContentWordCount
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          setReceiver("");
          setSubject("Content Word Count Reports");
          setEmailMessage(EmailMessage1);
          if (theme === 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  function getKeywordType(item) {
    APIManager.getAPI(
      getKeywordTypeAPI + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        list.push({
          key: "0",
          value: "All",
          label: "All",
          id: "0",
          keywordType: "All",
        });
        response.data.forEach((item) => {
          list.push({
            key: item.id,
            value: item.type,
            label: item.type,
            id: item.id,
            keywordType: item.type,
          });
        });
        setKeywordTypeList(list);
      }
    }
    )
  }

  const [ContentWordCount, setContentWordCount] = useState(false);
  const [showcalendaer, setshowcalendaer] = useState(false);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Content Word Count");
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  function handlePagination(pagination) {
    setPageSize(pagination.pageSize)
    setPageNumber(pagination.current)
    let pageNo = pagination.current
    let pageSizeVar = pagination.pageSize
   
    let obj = {
      pagination: {
        current: pageNo,
        pageSize: pageSizeVar,
        position: ["bottomRight"],
        showSizeChanger: true,
        total: mainDataList.length,
      },
    }
    setTableParams(obj);
    let tempList = []
    mainList1.forEach((i) => {
      let valuess = Object.values(i);
      i.density_1 = valuess[2]?.density_1;
      i['frequency_1'] = valuess[2]?.frequency_1
      tempList.push(i)
    })
  }

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (<>
        {noConfig ? (
          <>
            <div class=" message text-center ">
              <h4>
                The Content Word Count configuration is not set. <br />
                Please contact your administrator to set configuration.
              </h4>
            </div>
          </>
        ) : (
          <div className="row">
            {showcalendaer && (
              <div ref={ref} class="calendar-main">
                <div className="add-new-btnw">
                  <button
                    className="btn btn-primary"
                    style={{ width: "250px" }}
                    onClick={() => setOpen(!open)}
                  >
                    {calanderDate(head1, head2)}
                  </button>
                </div>

                {open && (
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card  mt-2">
                      <div className="row">
                        <div className="calendar-col">
                          <DateRangePicker
                            onChange={(item) => {
                              let isCompareVarNew = localStorage.getItem("isCompareVar");
                              if (calendarType === 1) {
                                let selection = {};

                                if (isCompareVarNew === "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectWeek(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );

                                  setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                  setHead1(new DateObject(selection?.selection1?.startDate).format());
                                  setHead2(new DateObject(selection?.selection1?.endDate).format());
                                }
                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                              } else {
                                if (isCompareVarNew === "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                    setHead1(new DateObject(item?.selection1?.startDate).format());
                                    setHead2(new DateObject(item?.selection1?.endDate).format());

                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                    setHead1(new DateObject(item?.selection2?.startDate).format());
                                    setHead2(new DateObject(item?.selection2?.endDate).format());
                                  }

                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }
                              }
                            }}

                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={[state?.selection1, state?.selection2]}
                            direction="horizontal"
                            ariaLabels={{
                              dateInput: {
                                selection1: {
                                  startDate: "start date input of selction 1",
                                  endDate: "end date input of selction 1",
                                },
                                selection2: {
                                  startDate: "start date input of selction 2",
                                  endDate: "end date input of selction 2",
                                },
                              },

                              monthPicker: "month picker",
                              yearPicker: "year picker",
                              prevButton: "previous month button",
                              nextButton: "next month button",
                            }}
                            maxDate={
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - 1
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-4 mt-3 mt-lg-0 text-center">
                          <div className="row mt-3">
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setheadvalues();
                                  setOpen(!open);
                                  setCol([]);
                                  setExportList([]);
                                  setDataSourcee([]);
                                  getDataCWC(selectedUrl, state?.selection1?.startDate, state?.selection1?.endDate);
                                }}
                                className="btn btn-primary"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            )}
            <Tabs>
              <div className="portlet">
                <div className="row portlet-header-bordered my-4">
                  <div className="col-md-6">
                    <TabList className="nav nav-lines ">
                      <Tab
                        className={
                          ContentWordCount
                            ? "nav-item nav-link "
                            : "nav-item nav-link  active  "
                        }
                        onClick={() => {
                          setContentWordCount(false);
                          setshowcalendaer(false);
                        }}
                      >
                        Content Word Count on a Page
                      </Tab>
                    </TabList>
                  </div>

                  <div ref={ref} class="col-md-6 calendar-main  ga-calender">
                    <div className=" calender_toup contentword_calender calender_responsive_design  d-block d-sm-flex justify-content-start justify-content-md-end mt-3 mt-md-0">
                      <div className="add-new-btnw text-left mr-3 mt-3 mt-xl-0 mb-0">
                        <button
                          className="btn btn-primary"
                          style={{ width: "250px" }}
                          onClick={() => setOpen(!open)}
                        >
                          {calanderDate(
                            state.selection1?.startDate,
                            state.selection1?.endDate
                          )}
                        </button>
                      </div>
                      {open && (
                        <div id="example-collapse-text-calendar">
                          <Card body className="daterange-picker-card mt-2">
                            <div className="d-flex calendar-flex">
                              <div className="calendar-col">
                                <DateRangePicker
                                  onChange={(item) => {
                                    let isCompareVarNew = localStorage.getItem("isCompareVar");
                                    if (calendarType === 1) {
                                      let selection = {};

                                      if (isCompareVarNew === "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        selection = dateRagngeSelectWeek(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );

                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }
                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else if (calendarType === 2) {
                                      let selection = {};

                                      if (isCompareVarNew === "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        selection = dateRagngeSelectFornightly(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );

                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }

                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else if (calendarType === 3) {
                                      let selection = {};

                                      if (isCompareVarNew === "false") {
                                        let selection23 = {
                                          selection1: {
                                            startDate: item?.selection1
                                              ? item?.selection1?.startDate
                                              : item?.selection2?.startDate,
                                            endDate: item?.selection1
                                              ? item?.selection1?.endDate
                                              : item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };
                                        selection = dateRagngeSelectMonthly(
                                          selection23,
                                          state,
                                          isCompareVarNew
                                        );
                                        setValues([new DateObject(selection?.selection1?.startDate), new DateObject(selection?.selection1?.endDate)]);
                                        setHead1(new DateObject(selection?.selection1?.startDate).format());
                                        setHead2(new DateObject(selection?.selection1?.endDate).format());
                                      }
                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection,
                                      });
                                    } else {
                                      if (isCompareVarNew === "false") {
                                        let selection23 = {};
                                        if (item?.selection1) {
                                          selection23 = {
                                            selection2: {
                                              startDate: item?.selection1?.startDate,
                                              endDate: item?.selection1?.endDate,
                                              key: "selection2",
                                            },
                                          };

                                          setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                          setHead1(new DateObject(item?.selection1?.startDate).format());
                                          setHead2(new DateObject(item?.selection1?.endDate).format());

                                        } else if (item?.selection2) {
                                          selection23 = {
                                            selection1: {
                                              startDate: item?.selection2?.startDate,
                                              endDate: item?.selection2?.endDate,
                                              key: "selection1",
                                            },
                                          };

                                          setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                          setHead1(new DateObject(item?.selection2?.startDate).format());
                                          setHead2(new DateObject(item?.selection2?.endDate).format());
                                        }

                                        setState({
                                          ...state,
                                          ...item,
                                          ...selection23,
                                        });
                                      }
                                    }
                                  }}

                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={[state?.selection1, state?.selection2]}
                                  direction="horizontal"
                                  ariaLabels={{
                                    dateInput: {
                                      selection1: {
                                        startDate: "start date input of selction 1",
                                        endDate: "end date input of selction 1",
                                      },
                                      selection2: {
                                        startDate: "start date input of selction 2",
                                        endDate: "end date input of selction 2",
                                      },
                                    },

                                    monthPicker: "month picker",
                                    yearPicker: "year picker",
                                    prevButton: "previous month button",
                                    nextButton: "next month button",
                                  }}
                                />
                              </div>
                              <div className="mt-3 mt-lg-0 text-center">
                                <button
                                  className="btn btn-primary ms-3"
                                  onClick={() => {
                                    setheadvalues();
                                    setOpen(!open);                                   
                                    setheadvalues();
                                    setOpen(!open);
                                    setCol([]);
                                    setExportList([]);
                                    setDataSourcee([]);
                                    getDataCWC(selectedUrl, state?.selection1?.startDate, state?.selection1?.endDate);
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                      <div className="flex-wrap flex-md-nowrap d-flex 3-icons content-count">
                        <a
                          className="bar-link-icon mr-3  mt-3 mt-xl-0"
                          style={{ color: "black" }}
                        >
                          <div class="dropdown">
                            <a
                              type="button"
                              ref={applyBtn1}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-filter" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-0 custome_dropdownmenu word_count_on_page filter-dropdown">
                              <div className="filter-popup dropdown_pop_filter">
                                <div className="portlet px-2 py-2">
                                  <div className="row">
                                    <div className="col-md-12 my-2">
                                      <label className="common-label">URL</label>
                                      <select
                                        className="w-100"
                                        style={{ padding: 8 + "px" }}
                                        value={selectedUrl}
                                        onChange={handleUrlChange}
                                      >
                                        {urls.map((i) => {
                                          return (
                                            <option value={i.id}>
                                              {i.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-md-12 my-2">
                                      <label style={{ fontSize: 14 + "px" }}>
                                        Keyword Type
                                      </label>
                                      <select
                                        className="w-100"
                                        style={{ padding: 8 + "px" }}
                                        value={selectedType}
                                        onChange={(item) => {
                                          setselectedType(item.target.value);
                                        }}
                                      >
                                        {keywordTypeList.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.keywordType}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12 text-left mt-3">
                                      <button
                                        type="button"
                                        className="btn btn-primary me-3"
                                        onClick={() => {
                                          applyBtn1.current.click();
                                          let rowData2 = {
                                            url: selectedUrl,
                                            projectId:
                                              localStorage.getItem("projectGId"),
                                            ContentWordDateRange: {
                                              FROM: mmDDyyyyFormateDateSet(
                                                state.selection1.startDate
                                              ),
                                              TO: mmDDyyyyFormateDateSet(
                                                state.selection1.endDate
                                              ),
                                            },
                                            ContentWordCompareDateRange: {
                                              FROM: mmDDyyyyFormateDateSet(
                                                state.selection1.startDate
                                              ),
                                              TO: mmDDyyyyFormateDateSet(
                                                state.selection1.endDate
                                              ),
                                            },
                                          };
                                          rowData2 = selectedType !== "0" ? { ...rowData2, KeywordTypeId: selectedType } : rowData2;
                                          createaddcontentwordcount(rowData2);
                                        }}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                    <div className="col-md-12 my-3"></div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                        <div className="mr-3 mt-3 mt-xl-0">
                          <a
                            className="share bar-link-icon"
                            style={{ color: "black" }}
                          >
                            <div class="dropdown">
                              <a
                                type="button"
                                ref={applyBtn}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-share-alt" aria-hidden="true"></i>
                              </a>
                              <form class="dropdown-menu custome_dropdownmenu content_word_menu p-4 filter-dropdown">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-12 text-start">
                                        <label class="my-2 pt-2">From</label>
                                      </div>
                                      <div className="col-md-12">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Sender Email Id"
                                          value={Sender}
                                          className="w-100 form-control my-2"
                                          name="Sender"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 text-start">
                                        <label class="my-2 pt-2">To</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-12">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Recipient Email Id"
                                          className="w-100 form-control my-2"
                                          value={Receiver}
                                          name="Receiver"
                                          onChange={(text) =>
                                            setReceiver(text.target.value)
                                          }
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageReceiver}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 text-start">
                                        <label class="my-2 pt-2">Subject</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-12">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Subject"
                                          value={subject}
                                          onChange={(text) => {
                                            setSubject(text.target.value);
                                          }}
                                          className="w-100 form-control my-2"
                                          name="Subject"
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageSubject}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 text-start">
                                        <label class="my-2 pt-2">Message</label>
                                        <label class="field-required"> * </label>
                                      </div>
                                      <div className="col-md-12">
                                        <textarea
                                          name=""
                                          id=""
                                          cols="80"
                                          rows="7"
                                          className="w-100 form-control my-2  gscmsg"
                                          value={EmailMessage}
                                          onChange={(text) => {
                                            setEmailMessage(text.target.value);
                                          }}
                                        ></textarea>
                                        <div style={{ color: "red" }}>
                                          {Message}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12"></div>
                                      <div className="col-md-12 my-2">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            if (isValidEmailData()) {
                                              sendEmail();
                                            }
                                          }}
                                        >
                                          Send
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                        </div>

                        <div className="mr-3 mt-3 mt-xl-0">
                          <div className=" download-filter">
                            <span
                              class="dropdown"
                              style={{
                                marginRight: 0 + "px",
                                textAlign: "right",
                                display: "block",
                              }}
                            >
                              <button
                                class="outline-btn dropdown-toggle btn btn-primary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-download " aria-hidden="true"></i>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={async () => {
                                    setLoading(true)
                                    const promise = await downloadPDFfile(image, moduleContentWordCount)
                                    try {
                                      if (promise) {
                                        setLoading(false);
                                      } else {
                                        setLoading(false);
                                      }
                                    } catch (e) {
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  <a class="dropdown-item">PDF</a>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TabPanel>
                <PDFExport
                  paperSize="A1"
                  margin="2cm"
                  fileName={moduleContentWordCount}
                  ref={pdfExportComponent}
                  forcePageBreak=".page-break"
                >
                  <div ref={image} >
                    <div id="web-page-pdf">
                      <div className="portlet">
                        <div className="portlet-header portlet-header-bordered">
                          <h3
                            className="portlet-title word-count ms-2"
                            style={{ fontweight: 500 }}
                          >
                            Word Count Comparison
                          </h3>
                        </div>
                        <div className="col-md-12 mt-3 content-chart">
                          <Chart
                            options={options}
                            series={series}
                            type="bar"
                            height="300"
                            id="chartId"
                          />
                        </div>
                      </div>
                      <div className="portlet" id="web-page-pdf">
                        <div className="portlet-header portlet-header-bordered">
                          <h3 className="portlet-title">
                            Keyword Frequency & Density
                          </h3>
                        </div>
                        <div className="portlet-body">
                          <section>
                            <div class="col-md-12 text-right">
                              <span class="dropdown">
                                <button
                                  class="btn btn-primary dropdown-toggle"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Content Word Count"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                                {/* <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Content Word Count"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">Excel</a>
                                </div>
                                <div
                                  onClick={() => {
                                    exportInToCSV(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Content Word Count"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">CSV</a>
                                </div>
                              </div> */}
                              </span>
                            </div>

                            <Table
                              className="table table-bordered table-hover mt-3 w-100 keyword-frequency-table"
                              style={{ overflow: "hidden" }}
                              columns={col}
                              dataSource={dataSourcee}
                              rowSelection={{ type: "checkbox", ...rowSelection }}
                              pagination={tableParams.pagination}
                              onChange={handlePagination}
                            />
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </PDFExport>
              </TabPanel>
            </Tabs>
          </div>
        )}
        <Loading isLoading={isLoading} />
      </>)}
    </>
  );
}

export default ModuleExpandContentWordCount;
