import React, { useEffect, useState } from "react";
import APIManager from "../services/APIManager";
import {
  createProjectAssignment,
  getMemberIdByPieChartData,
} from "../utility/Constant";
import { Button } from "react-bootstrap";
import { mmDDyyyyFormateDateSet, TimeZone } from "../utility/Globals";
import Chart from "react-google-charts";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

function EditprojectAssignTeamMembers({ update }) {
  const pieOptions = {
    title: "",
    slices: [
      {
        color: "#2BB673",
      },
      {
        color: "#d91e48",
      },
    ],
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "233238",
        fontSize: 12,
      },
    },
    tooltip: {
      showColorCode: true,
    },
    chartArea: {
      left: 20,
      top: 0,
      width: "100%",
    },
  };
  const [optionSelected, setoptionSelected] = useState(null);
  const [billingStartDate, setbillingStartDate] = useState("");
  const [billingEndDate, setbillingEndDate] = useState("");
  const [allocation, setallocation] = useState("");
  const [timezone, settimezone] = useState("IST");
  const [location, setlocation] = useState("");
  const [piedata, setpiedata] = useState([]);
  const [errors, setErrors] = useState({});
  const [save, setsave] = useState(false);

  const location2 = useLocation();

  useEffect(() => {
    if (location2.state?.isUpdate) {
      setlocation(location2.state.location);
      setbillingStartDate(mmDDyyyyFormateDateSet(location2.state.billingstart));
      setbillingEndDate(mmDDyyyyFormateDateSet(location2.state.billingend));
      setallocation(location2.state.allocation?.replace("%", ""));
      settimezone(location2.state.timezone);
    }
    if (location2.state?.optionSelected) {
      setoptionSelected(location2.state.optionSelected);
      getAssignPieChartData();
    }
    const a = [
      ["Task", "Hours per Day"],
      ["Utilised", 6],
      ["Not utilised", 4],
    ];
    setpiedata(a);
  }, []);

  function ProjectAssignmentcreate() {
    let teamMemberObj = [];
    teamMemberObj.push({
      memberId: optionSelected.key,
    });
    let memberselect = [];
    memberselect.push({ MemberId: location2.state.optionSelected.id });
    if (location2.state.isUpdate) {
      APIManager.putAPI(
        createProjectAssignment,
        {
          id: location2.state.id,
          projectId: localStorage.getItem("projectGId"),
          MemberId: location2.state.optionSelected.id,
          billingStartDate: mmDDyyyyFormateDateSet(billingStartDate),
          billingEndDate: mmDDyyyyFormateDateSet(billingEndDate),
          allocation: allocation,
          location: location.trim(),
          timezone: timezone ? timezone : "IST",
        },
        null,
        true
      ).then((response) => {
        if (response && response.status === 200) {
          clearForm();
        }
      });
    } else {
      APIManager.postAPI(
        createProjectAssignment,
        {
          projectId: localStorage.getItem("projectGId"),
          MemberId: location2.state.optionSelected.id,
          billingStartDate: mmDDyyyyFormateDateSet(billingStartDate),
          billingEndDate: mmDDyyyyFormateDateSet(billingEndDate),
          allocation: allocation,
          location: location.trim(),
          timezone: timezone,
        },
        null,
        true
      ).then((response) => {
        if (response && response.status === 200) {
          clearForm();
        }
      });
    }
  }

  function clearForm() {
    setbillingStartDate("");
    setbillingEndDate("");
    setlocation("");
    setallocation("");
    settimezone("IST");
    update("editProjectDir");
  }

  function formValidation() {
    let error = { ...errors };
    location.trim() === ""
      ? (error["location"] = "Location is required!")
      : delete error["location"];
    if (location.trim()?.length) {
      var pattern = new RegExp(/^[a-zA-Z\s]*$/);
      !pattern.test(location.trim())
        ? (error["location"] = "Enter a valid Location!")
        : delete error["location"];
    }
    allocation?.trim() === ""
      ? (error["allocation"] = "Percentage Allocation is required!")
      : delete error["allocation"];
    if (allocation?.trim()?.length) {
      var pattern1 = new RegExp(/^[0-9]+$/);
      !pattern1.test(allocation?.trim())
        ? (error["allocation"] = "Enter a number!")
        : delete error["allocation"];
    }
    billingStartDate === "" ?
      (error["billingStartDate"] = "Billing Start Date is required!") :
      (delete error["billingStartDate"])

    billingEndDate === "" ?
      (error["billingEndDate"] = "Billing End Date is required!") :
      (delete error["billingEndDate"])

    if (billingStartDate !== "" && billingEndDate !== "") {
      if (billingStartDate > billingEndDate) {
        (error["billingEndDate"] = "Billing End Date should be bigger than Biling Start Date!")
      } else {
        delete error["billingEndDate"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function getAssignPieChartData() {
    APIManager.getAPI(
      getMemberIdByPieChartData + location2.state.optionSelected.id
    ).then((response) => {
      if (response && response.status === 200) {
        response.data.forEach((i) => {
          const a = [
            ["Task", "Hours per Day"],
            ["Utilised", +i.allocationSum >= 100 ? 100 : +i.allocationSum],
            ["Not utilised", +i.allocationSum >= 100 ? 0 : 100 - +i.allocationSum],
          ];
          setpiedata(a);
        });

      }
    });
  }
  return (
    <>
      <div class="row">
        <div className="slideInUp">
          <div className="portlet mb-0 ">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => update("editProjectDir")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {" "}
                {localStorage.getItem("projectGName")} - Edit Project{" "}
              </h3>
            </div>
            <div className="row  p-0">
              <div class="col-md-9 ps-0">
                <div className="portlet-body">
                  <div class="common-wrapper">
                    <div class="common-wcard">
                      <div class="common-form-fields">
                        <div class="add-user">
                          <div class="col-md-12 ">
                            <label>Name:</label>
                            <input
                              type="text"
                              name=""
                              placeholder=""
                              value={location2.state?.optionSelected?.label}
                              disabled
                            />
                          </div>
                          <div class="col-md-12 mt-3">
                            <label>Role:</label>
                            <input
                              type="text"
                              name=""
                              placeholder=""
                              disabled
                              value={location2.state?.optionSelected?.role}
                            />
                          </div>

                          <div class="col-md-12  mt-3">
                            <label>Location:</label>
                            <input
                              type="text"
                              name=""
                              placeholder=""
                              value={location}
                              onChange={(text) => {
                                setlocation(text.target.value);
                                formValidation()
                              }}
                            />
                          </div>
                          {save && errors?.location && (
                            <>
                              <div className="field-required">
                                <span> {errors?.location}</span>
                              </div>
                            </>
                          )}
                          <div class="col-md-12 mt-3">
                            <label>Billing Start Date</label>
                            <input
                              type="date"
                              name=""
                              id="startDate"
                              value={billingStartDate}
                              onChange={(text) => {
                                setbillingStartDate(text.target.value);
                                formValidation()
                              }}
                            />
                          </div>
                          {save && errors?.billingStartDate && (
                            <>
                              <div className="field-required">
                                <span> {errors?.billingStartDate}</span>
                              </div>
                            </>
                          )}
                          <div class="col-md-12 mt-3">
                            <label>Billing End Date</label>
                            <input
                              type="date"
                              name=""
                              id="endDate"
                              value={billingEndDate}
                              onChange={(text) => {
                                setbillingEndDate(text.target.value);
                                formValidation()
                              }}
                            />
                          </div>
                          {save && errors?.billingEndDate && (
                            <>
                              <div className="field-required">
                                <span> {errors?.billingEndDate}</span>
                              </div>
                            </>
                          )}
                          <div class="col-md-12 mt-3">
                            <label>Percentage Allocation</label>
                            <input
                              type="text"
                              name=""
                              placeholder="Enter %"
                              maxlength="3"
                              value={allocation}
                              onChange={(text) => {
                                setallocation(text.target.value);
                                formValidation()
                              }}
                            />
                          </div>
                          {save && errors?.allocation && (
                            <>
                              <div className="field-required">
                                <span> {errors?.allocation}</span>
                              </div>
                            </>
                          )}
                          <div class="col-md-12 mt-3">
                            <label>Time Zone</label>
                            <select
                              name=""
                              id="timezone"
                              value={timezone}
                              onChange={(e) => {
                                var c = e.target.value;
                                settimezone(c);
                              }}
                            >
                              {TimeZone.map((i) => {
                                return (
                                  <option value={i.value}>{i.label}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portlet-footer portlet-footer-bordered mb-4">
                  <div className="col-md-12">
                    <Button
                      onClick={() => {
                        setsave(true);
                        if (location2.state.isAlreadyGroupHead === true && location2.state?.optionSelected?.role === "Group Head") {
                          toast.error("Only one Group Head is allowed!");
                        }
                        else {
                          if (formValidation()) {
                            ProjectAssignmentcreate();
                          }
                        }
                      }}
                    >
                      Update
                    </Button>

                    <button
                      className="btn btn-outline-secondary ms-3"
                      onClick={() => {
                        update("editProjectDir");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-3 pe-0">
                <div className="" style={{ height: 97 + "%" }}>
                  <div className="portlet-body mt-4">
                    <div id="piechart">
                      <Chart
                        chartType="PieChart"
                        data={piedata}
                        options={pieOptions}
                        graph_id="PieChart"
                        width={"100%"}
                        legend_toggle
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditprojectAssignTeamMembers;
