/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import { Colors } from "../../../styles/colors";

const Label = ({ label, required }) => {
    return (
        <div css={styles.labelStyles}>
            <span>{label}</span>
            {required && <span css={{ color: Colors.error[800] }}>*</span>}
        </div>
    )
}

export default Label;