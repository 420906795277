/** @jsxImportSource @emotion/react */
import { Link, useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import { DateRangePicker } from 'react-date-range';
import Popper from '@semcore/ui/popper';
import CustomButton from "../../../shared/button";

const CustomDateRangePicker = ({ range, customTrigger, handleApply, handleReset, visible }) => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    return (
        <div className="shadow">
            <div css={styles.calendarStyles}>
                {true && <DateRangePicker
                    key={visible}
                    className=""
                    ranges={[selectionRange]}
                    onChange={(e) => {
                        setSelectionRange({
                            startDate: new Date(e.selection.startDate),
                            endDate: new Date(e.selection.endDate),
                            key: 'selection',
                        })

                        // console.log(e, "errer")
                    }}
                    showDateDisplay={false}
                // scroll={{ enabled: true }}
                />}
            </div>
            <div
                // onClick={() => setIsVisible(!isVisible)}
                css={styles.applyContainer}
            >
                <CustomButton
                    text={"Apply"}
                    use={"primary"}
                    onPress={() => {
                        handleApply(selectionRange?.startDate, selectionRange?.endDate)
                    }} />
                <CustomButton
                    text={"Reset"}
                    use={"tertiary"}
                    onPress={() => {
                        setSelectionRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            key: 'selection',
                        })
                        handleReset()
                    }} />
            </div>
        </div>
    )
}

export default CustomDateRangePicker;