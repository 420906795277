/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import DividerLine from "../../../shared/dividerLine";
import { Formik } from "formik";
import * as Yup from "yup";
import LabeledInput from "../../../shared/Input";
import LabeledDropDown from "../../../shared/Dropdown/Labeled";
import CustomButton from "../../../shared/button";
import { useSelector } from "react-redux";
import { addSingleCompetitor } from "../../../../services/ranking";
import toast from "react-hot-toast";
import { getLocal, validateURL } from "../../../../utility/helpers";

const AddSingleCompetitor = ({ keywordCount, totalCredits, backToTable }) => {
  const competitor = useSelector(
    (state) => state.campaignDetails.singleCompetitorData
  );
  const isEditing = useSelector(
    (state) => state.campaignDetails.isEditingCompetitor
  );
  const campaignId = useSelector((state) => state.campaignDetails.details.id);
  const user_id = getLocal("userId");
  const detailsSchema = Yup.object().shape({
    competitor:Yup.string()
    .required("Competitor is a required field.")
  });

  const handleSave = async (values) => {
    try {
      const payload = {
        competitor: values.competitor,
        rankingconfigid: campaignId,
        user_id
      };
      const response = await addSingleCompetitor(payload);
      if (response.status === "success") {
        toast.success("Competitor Added Successfully");
        backToTable();
      } else {
        toast.error("Add Competitor Failed");
      }
    } catch (error) {
      toast.error(error.response.data.error_message);
    }
  };

  return (
    <div css={styles.main}>
      <div css={styles.subtitle}>Add Competitor Details :</div>
      <Formik
        initialValues={{
          competitor: !!isEditing && !!competitor ? competitor : "",
        }}
        onSubmit={(values) => {
          handleSave(values);
        }}
        validationSchema={detailsSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} css={styles.form}>
            <div css={styles.inputContainer}>
              <LabeledInput
                id={"competitor"}
                placeholder={"Competitor Name"}
                value={values.competitor}
                onChange={(e) => setFieldValue("competitor", e.target.value.trim())}
                label={"Competitor Name"}
                onBlur={handleBlur}
                key={"competitor"}
              />
              <div css={styles.error}>
                {errors.competitor && touched.competitor
                  ? errors.competitor
                  : ""}
              </div>
            </div>
            <div css={[styles.buttonContainer, styles.buttonGroup]}>
              <CustomButton
                text={"Cancel"}
                use={"secondary"}
                theme={"info"}
                onPress={() => backToTable()}
              />
              <CustomButton
                type={"submit"}
                text={"Save"}
                use={"primary"}
                theme={"info"}
                onPress={""}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddSingleCompetitor;
