/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../styles/colors";

const AreaChart = ({
  data,
  isPositive,
  isStable,
  addStyles,
  xAxis,
  width,
  height,
  title,
}) => {
  const [chartData, setGraphData] = useState({
    chart: {
      type: "area",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 9,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "10.5px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: Colors.black,
      },
    },
    markers: {
      size: 5,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [isStable ? Colors.secondary[900] : isPositive ? Colors.semantic[500] : Colors.error[800]],
    series: [
      {
        name: "Count",
        data: data,
      },
    ],
    stroke: {
      width: 1,
      curve: "straight",
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          console.log(series, "seriesconsole")
          return xAxis[dataPointIndex]
        }
      },
    },
    xaxis: {
      axisBorder: {
        show: true,
        color: Colors.neutral[600],
        height: "0.0625rem",
      },
      categories: xAxis,
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: 0,
        formatter: function (value, timestamp, opts, index) {
          return xAxis.indexOf(value) === 0 ||
            xAxis.indexOf(value) === xAxis.length - 1
            ? value
            : "";
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          if (value >= 1000) {
            return parseFloat(value / 1000).toFixed(2) + "k";
          } else {
            return parseFloat(value).toFixed(0);
          }
        },
      },
      floating: false,
      tickAmount: 5,
      axisBorder: {
        show: true,
        color: Colors.neutral[600],
        width: "0.0625rem",
        fontSize: "10px",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  });

  useEffect(() => {
    // setGraphData(options)
  }, [data]);

  const chart = useRef(null);

  return (
    <div css={[styles.main, addStyles && addStyles]}>
      <Chart
        id="AreaChart"
        options={chartData}
        series={chartData.series}
        type="area"
        width={width}
        height={height}
        ref={chart}
      />
    </div>
  );
};

export default AreaChart;
