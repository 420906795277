import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import APIManager from "../../services/APIManager";
import { default as ReactSelect } from "react-select";
import {
  createTickets,
  getClientByOrgId,
  getCustomerByUserId,
  getDepartmentById,
  getMemberByOrgId,
  getmemberbyproject,
  getmemberByProjectId,
  getProjectByClientId,
  updateticket,
} from "../../utility/Constant";
import {
  creattypes,
  getCheckPermission,
  mmDDyyyyFormateDateSet,
} from "../../utility/Globals";
import { toast } from "react-hot-toast";
import NotFound from "../../NotFound";
import { useLocation, useNavigate } from "react-router-dom";
function CreateNewTicket(props) {
  const [description, setdescription] = useState("");
  const [subject, setsubjects] = useState("");
  const [ETA, setETA] = useState("");
  const [ticket, setticket] = useState("0");
  const [ticketType, setticketType] = useState("Support Ticket");
  const [selectedprioriry, setselectedprioriry] = useState("");
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const location2 = useLocation();
  const [memberlist, setmemberlist] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [clientlist, setclientlist] = useState([]);
  const [clientcustomerlist, setclientcustomerlist] = useState([]);
  const [projectcustlist, setprojectcustlist] = useState([]);
  const [clientseleted, setclientseleted] = useState("");
  const [projectlist, setprojectlist] = useState([]);
  const [selectpoject, setselectpoject] = useState("");
  const [status, setstatus] = useState("");
  const [createdBy, setcreartedBy] = useState("");
  const notifyAdd = () => toast.success(" Create Ticket Successfully ");
  const [custdepartmentid, setcustdepartmentid] = useState("");
  const [notFound, setNotFound] = useState(true);

  const selectedProject = useRef();

  const CreateStyles = {
    control: base => ({
      ...base,
      boxShadow: 'none',
      // height: 43,
      fontSize: '1.1rem',

    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: '43px',
      padding: '0 12px',

    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#606060"
    }),
    input: (provided) => ({
      ...provided,
      // height: '43px',
      Padding: '0',
      Margin: '0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
      // height: '25px',
      // marginTop: "-12px"
    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0',
      height: '100px'
    }),
  };

  function Tickettype(e) {
    let value = e.target.value;
    setticket(value);
    setErrors("");
  }

  let navigate = useNavigate();
  useEffect(() => {
  }, props.isModel)
  useEffect(() => {
    let dateField = document.getElementById('myDateField');
    dateField.addEventListener('keydown', function (e) {
      e.preventDefault();
    });
    setNotFound(getCheckPermission("tickets", "projects_setup"));
    if (location2.state?.isUpdate) {
      setETA(mmDDyyyyFormateDateSet(location2?.state?.ETA));
      setsubjects(location2?.state?.Subject);
      setticket(location2?.state?.TicketTypeId);
      setselectedprioriry(location2?.state?.PriorityId);
      setdescription(location2?.state?.Description);
      setstatus(location2?.state?.status);
      setcreartedBy(location2?.state?.createdby);
      setclientseleted(location2?.state?.ClientId);
      setselectpoject(location2?.state?.projectId);
      setSelectedMember(location2?.state?.AssignedTo)
    }
    if (localStorage.getItem('userRole') === 'Customer') {
      getClientListcustomer();
    }
    else {
      getClientlist();
    }
    getDepartmentData();
  }, []);

  const Prioritylist = [
    {
      value: "0",
      lable: "Low",
    },
    {
      value: "1",
      lable: "Medium",
    },
    {
      value: "2",
      lable: "High",
    },
  ];

  const [ClientsProjectChosen, setClientsProjectChosen] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const regex = /^[a-zA-Z0-9_&@?()\\\-"/,._:'\s]*$/; // Regular expression pattern

    setInputValue(value);
    setIsValid(regex.test(value));
    setsubjects(value);
    let error = { ...errors };

    if (!isValid) {
      (error["subject"] = "Enter valid input.")
    }

  };
  useEffect(() => { }, [ClientsProjectChosen]);

  const [departmentlist, setDepartmentlist] = useState([]);

  function formValidation() {
    let error = { ...errors };
    if (subject.trim() == "") {
      (error["subject"] = "Subject is required!")
    } else if (!isValid) {
      (error["subject"] = "Enter valid input.")
    } else {
      delete error["subject"];
    }

    !selectedprioriry
      ? (error["selectedprioriry"] = "Priority is required!")
      : delete error["selectedprioriry"];

    !ETA
      ? (error["ETA"] = "ETA is required!")
      : delete error["ETA"];

    if (ticket == 2) {
      clientseleted.trim() == ""
        ? (error["clientseleted"] = "Client is required!")
        : delete error["clientseleted"];

      selectpoject.trim() == ""
        ? (error["selectpoject"] = "Project is required!")
        : delete error["selectpoject"];
    }

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    selectedProject.current.value = '';
    setsubjects("");
    setdescription("");
    setticket("");
    setETA("");
    setclientseleted("");
    setselectpoject("");
    setSelectedMember("");
    setSelectedDepartment("");
    setselectedprioriry("");
    setIsValid(true);
    setInputValue("");
    setsave(false);
    setprojectlist([]);
    setprojectcustlist([]);
    getMemberListReset();
  }

  useEffect(() => {
    setErrors("");
    if (props?.resetForm) {
      if (localStorage.getItem('userRole') !== 'Customer') {
        clearForm();
      }
    }
  }, [props?.resetForm]);

  function createNewTicketData() {
    console.log(selectedMember);
    let rowData = {
      ClientId: clientseleted,
      ProjectId: selectpoject,
      Subject: subject,
      Description: description,
      TicketTypeId: (ticketType == "Support Ticket" && localStorage.getItem('userRole') === 'Customer') ? "5" : (ticket ? ticket : '0'),
      ETA: ETA,
      PriorityId: selectedprioriry,
      DepartmentId: selectedDepartment,
      AssignedTo: selectedMember,
      createdby: localStorage.getItem("userId"),
    };
    if (!selectedMember)
      delete rowData.AssignedTo;

    APIManager.postAPI(createTickets, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        notifyAdd();
        if (props?.isModel) {
          props?.closeModel();
        }
        navigate("/ticket/personal-tickets");
      }
    });
  }

  function updateTickets() {
    const data = {
      id: location2.state.id,
      ClientId: clientseleted,
      ProjectId: selectpoject,
      Subject: subject,
      Description: description,
      TicketTypeId: ticket,
      ETA: ETA,
      PriorityId: selectedprioriry,
      DepartmentId: selectedDepartment,
      AssignedTo: selectedMember,
      status: status,
      createdby: createdBy,
    }
    if (!selectedMember)
      delete data.AssignedTo;
    APIManager.putAPI(
      updateticket,
      data,
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearForm();
        if (props?.isModel) {
          props?.closeModel();
        }
        navigate("/ticket/personal-tickets");
      }
    });
  }

  function getClientlist() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "0",
          name: "Select Client",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setclientlist(list);
        getprojetclistclientwise(location2?.state?.ClientId ?? list[0].id)
      }
    });
  }

  function getClientListcustomer() {
    APIManager.getAPI(
      getCustomerByUserId + localStorage.getItem("userId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const prolist = [];
        let obj = {
          id: response.data.client.id,
          name: response.data.client.name,
        };
        list.push(obj);
        setclientcustomerlist(list);
        setclientseleted(list[0].id);
        response.data.customerProjectMappings.map((item) => {
          let proobj = {
            id: item.projectId,
            name: item.project.projectCode,
          };
          prolist.push(proobj);
        });
        setprojectcustlist(prolist);
        setselectpoject(prolist[0].id)
        getMemberlist(custdepartmentid, prolist.length === 1 ? prolist[0].id : location2?.state?.projectId ?? prolist[0].projectId)
      }
    });
  }

  function getprojetclistclientwise(clientid) {
    if (clientid === '0') setprojectlist([]);
    if (clientid) {
      APIManager.getAPI(getProjectByClientId + clientid).then((response) => {
        if (response && response.status == 200) {
          const list = [];
          response.data.map((item) => {
            list.push({
              id: item.id,
              projectCode: item.projectCode,
            });
          });
          setprojectlist(list);
          if (location2?.state) {
            setselectpoject(location2?.state?.projectId)
          } else {
            setselectpoject(list[0].id)
          }
          // setselectpoject(location2?.state?.projectId ?? list[0].id);
          // getMemberlist(location2?.state?.projectId ?? list[0].id);
          getprojetclistbyMember(selectedDepartment, location2?.state?.projectId ?? list[0].id)
        }
      });
    }
  }

  function getDepartmentData() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        list.push({
          id: "",
          name: "Select Department",
          key: "",
          value: "",
          label: "Select Department",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            role: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        if (localStorage.getItem('userRole') === 'Customer') {
          list = list.filter(x => x.role === "Digital Growth")
        }
        setDepartmentlist(list);
        getMemberlist(location2?.state?.DepartmentId ?? list[1]?.id ?? list[0]?.id);
        setcustdepartmentid(list[1]?.id ?? list[0]?.id)
        setSelectedDepartment(location2?.state?.DepartmentId ?? list[0].id);
        setSelectedDepartment(location2?.state?.DepartmentId ?? list[0].id);
      }
    });
  }

  function getMemberlist(departmentId, projectid, item) {
    if (departmentId && localStorage.getItem('userRole') !== 'Customer') {
      APIManager.getAPI(getMemberByOrgId + localStorage.getItem("organizationId")).then(
        (response) => {
          if (response && response.status == 200) {
            const list = [];
            list.push({
              id: "",
              name: "Select Member",
              key: "",
              value: "",
              label: "Select Member",
            });
            response.data.map((item) => {
              list.push({
                id: item.id,
                role: item.name,
                key: item.id,
                value: item.id,
                label: item.name,
              });
            });
            setmemberlist(list);
            setSelectedMember(location2?.state?.AssignedTo ?? list[0].id);
          }
        }
      );
    }
    else {
      APIManager.getAPI(
        localStorage.getItem('userRole') === 'Customer' ?
          getmemberByProjectId + localStorage.getItem('projectGId') : getMemberByOrgId + localStorage.getItem("organizationId")
      ).then(
        (response) => {
          if (response && response.status == 200) {
            const list = [];
            list.push({
              id: "",
              name: "Select Member",
              key: "",
              value: "",
              label: "Select Member",
            });
            response.data.map((item) => {
              list.push({
                id: item.id,
                role: item.name,
                key: item.id,
                value: item.id,
                label: item.name,
              });
            });
            setmemberlist(list);
          }
        }
      );
    }
  }

  function getMemberListReset() {
    APIManager.getAPI(getMemberByOrgId + localStorage.getItem("organizationId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          list.push({
            id: "",
            name: "Select Member",
            key: "",
            value: "",
            label: "Select Member",
          });
          response.data.map((item) => {
            list.push({
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
            });
          });
          setmemberlist(list);
          setSelectedMember(location2?.state?.AssignedTo ?? list[0].id);
        }
      }
    );
  }

  function getprojetclistbyMember(depmentid, projectid) {
    let memberListAPI = '';
    if (depmentid && projectid)
      memberListAPI = getmemberbyproject + "?projectId=" + projectid + "&departmentId=" + depmentid;

    if (depmentid && !projectid)
      memberListAPI = getmemberbyproject + "?departmentId=" + depmentid;

    if (!depmentid && projectid)
      memberListAPI = getmemberbyproject + "?projectId=" + projectid;
    APIManager.getAPI(memberListAPI).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select Member",
          key: "",
          value: "",
          label: "Select Member",
        });
        response.data.map((item) => {
          list.push({
            id: item.memberId,
            role: item.name,
            key: item.memberId,
            value: item.memberId,
            label: item.name,
          });
        });
        setmemberlist(list);
        setSelectedMember(location2?.state?.AssignedTo ?? list[0].id);
      }
    });
  }

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          <div className="portlet border-0 px-0">
            <div class="portlet-header portlet-header-bordered  b-border-dark mb-0">
              <h3 class="portlet-title main-title ps-2"> {location2.state?.isUpdate ? "Edit Ticket" : "Create Ticket"}</h3>
            </div>
            <div className="portlet-body px-0">
              <div class="common-wrapper">
                <div class="common-wcard">
                  <div class="common-form-fields create-ticket-form">
                    <div class="add-user" style={{ width: 100 + "%" }}>
                      <div class="container create-new-ticket">
                        <div class="col-md-12">
                          <label className="form-label">Type</label>
                          <label class="field-required"> * </label>
                          {localStorage.getItem('userRole') !== 'Customer' ? (<>
                            <select
                              class=" w-100 form-select"
                              aria-label="Default select example"
                              value={ticket}
                              onChange={Tickettype}
                            >
                              {creattypes.map((item) => {
                                return <option value={item.value}>{item.label}</option>;
                              })}
                            </select>
                          </>) : (<>
                            <input
                              type="text"
                              className="form-control form-control-lg text-capitalize"
                              required
                              value={ticketType}
                              disabled
                            />
                          </>)}

                        </div>

                        <div class="col-md-12 mt-4 client-field">
                          <label className="form-label">Client</label>
                          {ticket == 2 ? (<label class="field-required"> * </label>) : (<></>)}
                          <select
                            class="w-100 form-select"
                            aria-label="Default select example"
                            disabled={clientcustomerlist.length ? true : false}
                            value={clientseleted}
                            onChange={(e) => {
                              setclientseleted(e.target.value);
                              formValidation();
                              getprojetclistclientwise(e.target.value);
                              if (+e.target.value === 0)
                                getMemberListReset();
                            }}
                          >

                            {localStorage.getItem('userRole') === 'Customer' ? (<>
                              {clientcustomerlist?.map((item) => {
                                return <option value={item.id}>{item.name}</option>;
                              })}
                            </>) : (<>
                              {clientlist?.map((item) => {
                                <option value="">Select Client</option>;
                                return <option value={item.id}>{item.name}</option>;
                              })}
                            </>)

                            }
                          </select>
                          {save && errors?.clientseleted && (
                            <>
                              <div className="field-required ms-3 mt-2">
                                <span> {errors?.clientseleted}</span>
                              </div>
                            </>
                          )}
                        </div>

                        <div class="col-md-12 mt-4">
                          <label className="form-label">Project</label>
                          {ticket == 2 ? (<label class="field-required"> * </label>) : (<></>)}
                          <select
                            id="projectListDrop"
                            className={(localStorage.getItem('userRole') === 'Customer') ? (!projectcustlist.length ? "w-100 form-select pe-none" : "w-100 form-select") : (!projectlist.length ? "w-100 form-select pe-none" : "w-100 form-select")}
                            aria-label="Default select example"
                            value={selectpoject}
                            ref={selectedProject}
                            disabled={(localStorage.getItem('userRole') === 'Customer' && projectcustlist?.length === 1) ? true : false}
                            onChange={(e) => {
                              setselectpoject(e.target.value);
                              formValidation();
                              getprojetclistbyMember(selectedDepartment, e.target.value)
                            }}
                          >
                            {(localStorage.getItem('userRole') === 'Customer') ? <>
                              {<option value="">Select Project</option>}</> :
                              <>
                                {!projectlist.length && <option value="">No Project Selected</option>}
                                {projectcustlist?.length > 1 && <option value="">Select Project</option>}
                              </>}

                            {localStorage.getItem('userRole') === 'Customer' ? (<>
                              {projectcustlist.map((item) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </>) : (<>
                              {projectlist.map((item) => {
                                return (
                                  <option value={item.id}>{item.projectCode}</option>
                                );
                              })}
                            </>)}

                          </select>
                          {save && errors?.selectpoject && (
                            <>
                              <div className="field-required ms-3 mt-2">
                                <span> {errors?.selectpoject}</span>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="col-md-12 mt-4">
                          <label className="form-label">Subject{save}</label>
                          <label class="field-required"> * </label>
                          <input
                            type="text"
                            value={inputValue}
                            placeholder="Enter Subject"
                            onChange={handleInputChange}
                          />
                          {!isValid && <div className="field-required ms-3 mt-2">
                            <span>Enter valid input.</span>
                          </div>}
                          {(save && !subject) && <div className="field-required ms-3 mt-2">
                            <span>Subject is required!</span>
                          </div>}
                        </div>

                        <div class="col-md-12 mt-4">
                          <label className="form-label">Description{save}</label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Enter Description"
                            required
                            maxLength={1000}
                            value={description}
                            onChange={(text) => {
                              setdescription(text.target.value);
                            }}
                          />
                        </div>
                        {save && errors?.description && (
                          <>
                            <div className="field-required ms-3 mt-2">
                              <span> {errors?.description}</span>
                            </div>
                          </>
                        )}

                        <div class="col-md-12 mt-4">
                          <label className="form-label">ETA</label>
                          <label class="field-required"> * </label>
                          <input
                            id="myDateField"
                            onkeydown="false"
                            type="date"
                            name="ETA"
                            min={mmDDyyyyFormateDateSet(new Date())}
                            onBlur={() => {
                              formValidation();
                            }}

                            class="ticket-date w-100"
                            value={ETA}
                            onChange={(text) => {
                              setETA(text.target.value);
                              formValidation();
                            }}
                          />
                        </div>
                        {save && errors?.ETA && (
                          <>
                            <div className="field-required ms-3 mt-2">
                              <span> {errors?.ETA}</span>
                            </div>
                          </>
                        )}

                        <div class="col-md-12 mt-4">
                          <label className="form-label">Priority</label>
                          <label class="field-required"> * </label>
                          <select
                            class=" w-100 form-select"
                            aria-label="Default select example"
                            required
                            value={selectedprioriry}
                            onChange={(text) => {
                              setselectedprioriry(text.target.value);
                              formValidation()
                            }}
                          >
                            <option value="">Select Priority</option>;
                            {Prioritylist.map((item) => {
                              return <option value={item.value}>{item.lable}</option>;
                            })}
                          </select>
                        </div>
                        {save && errors?.selectedprioriry && (
                          <>
                            <div className="field-required ms-3 mt-2">
                              <span> {errors?.selectedprioriry}</span>
                            </div>
                          </>
                        )}
                        <div class="col-md-12 mt-4 custom-search">
                          <label className="form-label">Department</label>
                          <ReactSelect
                            className="create-ticket-style"
                            // isDisabled={localStorage.getItem('userRole') === 'Customer' ? true : false}
                            isMulti={false}
                            styles={CreateStyles}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            allowSelectAll={true}
                            options={departmentlist}
                            value={departmentlist?.map(x => {
                              if (x.id == selectedDepartment) {
                                return x;
                              }
                            })}
                            onChange={(e) => {
                              setSelectedDepartment(e.value);
                              setmemberlist([]);
                              if (e.value || selectpoject)
                                getprojetclistbyMember(e.value, selectpoject);
                              if (!e.value && !selectpoject)
                                getMemberlist(e.value);
                              // getprojetclistbyMember(e.value, selectpoject);
                            }}
                          >
                          </ReactSelect>
                        </div>

                        <div class="col-md-12 mt-4 custom-search">
                          <label className="form-label">Assigned to</label>
                          <ReactSelect
                            className="create-ticket-style"
                            isMulti={false}
                            styles={CreateStyles}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            allowSelectAll={true}
                            options={memberlist}
                            value={memberlist?.map(x => {
                              if (x.id == selectedMember) {
                                return x;
                              }
                            })}
                            onChange={(item) => {
                              setSelectedMember(item.value);
                            }}
                          >
                          </ReactSelect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="portlet-footer portlet-footer-bordered  mt-4 pt-4 create-ticket-btns 
        b-border-dark position-relative">
              <div class="col-md-12 text-end">
                <button
                  className="btn btn-outline-secondary me-3"
                  onClick={() => {
                    if (localStorage.getItem('userRole') !== 'Customer') {
                      clearForm();
                    }
                    setErrors("");
                    if (props?.isModel) {
                      props?.closeModel();
                    }
                    else {
                      navigate(-1);
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary w-auto"
                  onClick={() => {
                    setsave(true);
                    let error = { ...errors };
                    if (!subject.trim()) {
                      (error["subject"] = "Subject is required!")
                    }
                    if (formValidation()) {
                      location2.state?.isUpdate
                        ? updateTickets()
                        : createNewTicketData();
                    }
                  }}
                >
                  {location2.state?.isUpdate ? "Update" : "Create Ticket"}
                </button>
              </div>
            </div>
          </div >
        </>
      )
      }</>
  );
}

export default CreateNewTicket;
