/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Tooltip from "@semcore/ui/tooltip";
import { Tool } from "feather-icons-react/build/IconComponents";
import TextSkeleton from "../../shared/Skeleton";
import AssetsImg from "../../assets/images";

const ComparisionCard = ({
  title,
  growth,
  subtitle,
  compareDates,
  comparision,
  loading
}) => {

  return (
    <div css={styles.main}>
      <div css={styles.title}>
        <div>{title} </div>
        <div css={styles.subtitle}>{subtitle}</div>
        {growth !== 0 && <div>
          <FeatherIcon
            icon={growth === "infinity" ?(comparision[0] < comparision[1] ? "trending-down": "trending-up"): growth > 0 ? "trending-up" : "trending-down"}
            css={styles.growthIcon(growth === "infinity" ? comparision[0] > comparision[1] : growth > 0)}
          />
        </div>}
      </div>
      <TextSkeleton loading={loading} lines={2} />
      {!loading && <div css={styles.comparisionWrapper}>
        <div css={styles.left}>
          {Array.isArray(comparision) && comparision?.toReversed().map((count, index) => (
            <div css={styles.date}>
              {`${compareDates.toReversed()[index]}:`}
              <span css={styles.count}> {count}</span>{""}
            </div>
          ))}
        </div>
        <div css={styles.growth(growth > 0, growth === 0)}>
        {growth === "infinity" ? <img src={comparision[0] < comparision[1] ? AssetsImg.ic_infinity_red : AssetsImg.ic_infinity_green} css={styles.infinityIcon} /> : `${growth}%`}
        </div>
      </div>}
    </div>
  );
};

export default ComparisionCard;
