import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors"
import { textStyles } from "../../../styles/font"


export const buttonContainer = css({

});

export const icon = css({
    height: '1rem',
    width: '1rem',
    color: "inherit",
    strokeWidth: 3.5
})

export const text = css({
    ...textStyles.button1
})