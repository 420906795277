import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  addBugReport,
  documentFileType,
  GET_FrontEnd_URL,
  GET_Server_URL,
} from "../utility/Constant";
import "antd/dist/antd.css";
import JoditEditor from "jodit-pro-react";
import APIManager from "../services/APIManager";
import { mmDDyyyyFormateForForm } from "../utility/Globals";
import { useLocation, useNavigate } from "react-router-dom";

function BugReport() {
  const [bugMessage, setBugMessage] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setuserRole] = useState("");
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setEmail(localStorage.getItem("UserEmail"));
    setName(localStorage.getItem("userName"));
    setuserRole(localStorage.getItem("userRole"));
    if (location.state) {
      setUrl(location.state.search);
    }
  });

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    enableDragAndDropFileToEditor: true,
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",      
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    uploader: { insertImageAsBase64URI: true },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: true,
    toolbarSticky: true,
  };

  function handleChangebugReport() {
    var bodyFormData = new FormData();
    bodyFormData.append("comment", bugMessage);
    bodyFormData.append("pageUrl", url);
    bodyFormData.append("postDate", mmDDyyyyFormateForForm(new Date()));
    bodyFormData.append("userId", localStorage.getItem("userId"));
    bodyFormData.append("status", "0");
    bodyFormData.append("Files", "test.pdf");

    APIManager.postAPI(addBugReport, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status == 200) {
          setBugMessage("");
          setUrl("");
          navigate(-1)
        }
      }
    );
  }

  return (
    <>
      <div className="row">
        <div class="dis-none align-items-center p-0">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                navigate(-1)
              }}
            ></i>
          </div>
        </div>
      </div>

      <div className="portlet common-form-fields ">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Bug Report</h3>
        </div>
        <div className="portlet-body">
          <div className="col-md-12">
          </div>
          <div className="col-md-12 mt-3">
          </div>
          <div className="col-md-12 mt-3">
          </div>
          <div class="col-md-12 mt-3">
            <label className="form-label">Comments</label>
            <textarea
              className="form-control w-100"
              name="name"
              config={config}
              tabIndex={1}
              onChange={(text) => {
                setBugMessage(text.target.value);
              }}
            >
            </textarea>
            <div>
              <form action="" method="post" enctype="multipart/form-data" name="form1" id="form1">
                <label><input type="file" name="file" id="file" multiple="true" /></label>
              </form>
            </div>
            <JoditEditor
              value={bugMessage || ""}
              config={config}
              tabIndex={1}
              onBlur={(text) => {
                setBugMessage(text);
              }}
            ></JoditEditor>

            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                navigate(-1)
              }}
            >
              Cancel
            </button>
          </div>

          <div class="col-md-12 mt-3">
          </div>
          <div class="portlet-footer portlet-footer-bordered mt-3">
            <div className="col-md-12  p-0">
              <button
                type="button"
                class="btn btn-primary"
                style={{
                  width: 70 + "px",
                  height: 30 + "px",
                }}
                onClick={() => {
                  handleChangebugReport();
                }}
              >
                {" "}
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BugReport;
