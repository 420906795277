/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import Button from '@semcore/ui/button';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { textStyles } from '../../../../styles/font';


const CustomTimeButton = ({
    active,
    disabled,
    loading,
    size = "l",
    theme = "info",
    use = "primary",
    icon,
    name,
    width,
    onClick,
    className,
    lock
}) => {
    return (
        <Button
            className={className}
            active={active}
            disabled={disabled}
            loading={loading}
            size={size}
            theme={theme}
            use={use}
            w={width}
            style={{ ...(lock && { pointerEvents: "none", opacity: "30%" }) }}
            onClick={() => {
                onClick()
            }}
        >
            <div css={{ display: "flex", alignItems: "center", ...textStyles.s2Medium }}>
                <FeatherIcon icon={icon} size={18} />
                <span className={icon && "ms-2"}>{name}</span>
            </div>
        </Button >
    )
}

export default CustomTimeButton