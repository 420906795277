/** @jsxImportSource @emotion/react */
import { useTicketingStore } from "../ticketingStore";
import * as styles from "./styles"
import React, { useState } from "react";
import Select, { InputSearch } from '@semcore/ui/select';
import { Flex } from '@semcore/ui/flex-box';
import { Colors } from '../../../styles/colors';
import Label from "../../shared/label";
import SpinContainer from "@semcore/ui/spin-container";



const DropdownInput = ({

    label,
    required = false,
    value,
    defaultValue = null,
    handleChange,
    placeholder = "Select",
    options,
    optionAddon = "",
    dropdownHeight = "240px",
    disabled = false,
    placement = "bottom-start",
    addDropdownStyles,
    width,
    wMin,
    wMax,
    error,
    onClick,
    loading,
    showSearchBar,
    showResetOption
}) => {
    const [filter, setFilter] = React.useState("");
    const optionsData = React.useMemo(
        () =>
            options.filter((option) =>
                option.label.toString().toLowerCase().includes(filter.toLowerCase())
            ),
        [filter, options]
    );

    return (
        <div css={[styles.dropDownStyles, addDropdownStyles && addDropdownStyles]}>
            {label && <Label label={label} required={required} />}
            <Select disabled={disabled} onChange={(e) => { handleChange(e) }} value={defaultValue} placeholder={placeholder} placement={placement}>
                <Select.Trigger w={width} hMin={"33px"} wMax={wMax} wMin={wMin} onClick={onClick ? () => onClick() : () => { }}>
                    <input placeholder={placeholder} value={value} readOnly={true} />
                    {/* <Select.Trigger.Addon>
                    <Flags iso2={value} />
                </Select.Trigger.Addon> */}
                    {/* <Select.Trigger.Text>{value}</Select.Trigger.Text> */}
                </Select.Trigger>
                <Select.Popper zIndex={20000}>
                    {({ highlightedIndex, visible }) => (
                        <>
                            {showSearchBar && (
                                <InputSearch value={filter} onChange={setFilter}>
                                    <InputSearch.Value
                                        placeholder="Search"
                                        role="combobox"
                                        aria-autocomplete="list"
                                        aria-controls="search-list"
                                        aria-owns="search-list"
                                        aria-expanded="true"
                                        aria-activedescendant={`option-${highlightedIndex}`}
                                        css={[styles.searchInput, styles.text]}
                                    />
                                    <InputSearch.Clear
                                        onClick={() => {
                                            // console.log(visible, "visbe")
                                            return true;
                                        }}
                                    />
                                </InputSearch>
                            )}
                            {optionsData?.length > 0 ?
                                <Select.List hMax={dropdownHeight}>
                                    {showResetOption && <div>
                                        <Select.Option css={styles.resetOption} value={null} >{`Reset`}</Select.Option>
                                    </div>}
                                    {optionsData?.map((option, index) => {
                                        return (
                                            <Select.Option value={option.value} key={index}>{`${option.label} ${optionAddon}`}</Select.Option>
                                        )
                                    })}
                                </Select.List>
                                :
                                <div css={{ padding: "1rem" }}>
                                    {loading ? <SpinContainer loading={true} size='m' h={30} />
                                        :
                                        "No Data Found"}
                                </div>}
                        </>
                    )}
                </Select.Popper>
            </Select>
            {error && <div css={styles.errorMsg}>{error}</div>}
        </div >
    )
}

export default DropdownInput;