/** @jsxImportSource @emotion/react */
import { Colors } from "../../styles/colors";
import { textStyles } from "../../styles/font";
import { TicketSvg } from "./ticketSvg";

const SubTicketCounter = ({ css, onClick, count }) => {
    const doubleDigit = (value) => {
        if (Number(value) < 10) {
            return `0${value}`
        } else {
            return value
        }
    }
    return (
        <div css={{
            ...textStyles.s2Medium,
            ...css,
            display: "flex",
            alignItems: "center",
            color: Colors.neutral[500],
            cursor: "pointer"
        }}
            onClick={() => onClick()}
        >
            <TicketSvg color={Colors.neutral[500]} height={18} width={18} />
            <span css={{ marginLeft: "5px" }}>{doubleDigit(count)}</span>
        </div>
    )
}

export default SubTicketCounter