import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const TicketDetailPage = css({
    display: "flex",
    width: "100%",
    height: "100%",
    // backgroundColor: "red"
})

export const rightSection = css({
    "::-webkit-scrollbar": {
        display: "none",
        width: 0
    },
    overflowY: "scroll",
    width: "20%",
    padding: "1rem",
    minWidth: "23rem",
    backgroundColor: Colors.secondary[25]
})

export const ticketDetailMainCard = css({
    backgroundColor: Colors.plainWhite,
    padding: "1rem",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
})

export const subTicketIcon = css({
    backgroundColor: "#E2F1FC",
    padding: "5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    maxWidth: "2.5rem",
    maxHeight: "2.5rem",
    marginRight: "1rem"
})

export const subTicketPill = css({
    ...textStyles.h7,
    display: "inline-flex",
    alignItems: "center",
    padding: "0.25rem 0.75rem",
    border: `1px solid ${Colors.success[300]}`,
    borderRadius: "0.5rem",
    color: Colors.success[300],
    backgroundColor: Colors.success[50]
})

export const ticketDetails = css({
    ...textStyles.s3,
    color: Colors.neutral[500],
    display: "flex",
    alignItems: "center",
    margin: "1rem 0"
})

export const pickUpButton = css({
    ...textStyles.s2Medium,
    color: Colors.plainWhite,
    padding: "12px 0",
    borderRadius: "5px",
    backgroundColor: Colors.primary[800],
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
})

export const timeValue = css({
    ...textStyles.h7
})
export const timeText = css({
    color: Colors.neutral[600]
})

export const detailField = (priority) => css({
    ...textStyles.H1Medium,
    border: `1px solid ${Colors.neutral[900]}`,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    color: Colors.neutral[900],
    minHeight: "2.5rem !important",
    padding: "0 1rem"
})

export const priorityField = (priority) => {
    let background = Colors.error[50];
    let color = Colors.error[800];
    if (priority == "Medium") {
        background = Colors.warning[50];
        color = Colors.warning[500]
    }
    if (priority == "Low") {
        background = Colors.success[50];
        color = Colors.success[200];
    }
    if (priority == "High") {
        background = Colors.error[50];
        color = Colors.error[800];
    }
    return css({
        ...textStyles.H1Medium,
        display: "inline-flex",
        alignItems: "center",
        border: `1px solid ${color}`,
        borderRadius: "8px",
        backgroundColor: background,
        color: color,
        minHeight: "2.5rem !important",
        padding: "0 1rem"
    })
}

export const selectDropDownContainer = css({
    width: "6.25rem !important",
});

export const priorityStyles = (priority, disabled) => {
    let background = Colors.error[50];
    let color = Colors.error[800];
    if (priority == "Medium") {
        background = Colors.warning[50];
        color = Colors.warning[500];
    }
    if (priority == "Low") {
        background = Colors.success[50];
        color = Colors.success[200];
    }
    if (priority == "High") {
        background = Colors.error[50];
        color = Colors.error[800];
    }
    return css({
        ...textStyles.s2Medium,
        background: background,
        color: color,
        minHeight: "2.5rem !important",
        border: `1px solid ${color}`,
        ...(disabled && {
            pointerEvents: "none",
        }),
    });
};

export const assignedToStyles = (disabled) => {
    return css({
        ...textStyles.b2,
        color: Colors.neutral[800],
        minHeight: "2.5rem !important",
        ...(disabled && {
            pointerEvents: "none",
        })
    })
}

export const loader = css({
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 99999,
})