export const textStyles = {
  H24Medium: {
    fontFamily: "Montserrat !important",
    fontSize: "2.4rem !important",
    fontWeight: "500 !important",
    lineHeight: "1.5rem !important",
  },
  H16Medium: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  H18SemiBold: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  H1125SemiBold: {
    fontFamily: "Montserrat",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
  },
  H1125Medium: {
    fontFamily: "Montserrat",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  H1Medium: {
    fontFamily: "Montserrat",
    fontSize: "1rem !important",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5rem",
  },
  h3: {
    fontFamily: "Montserrat !important",
    fontSize: "0.875rem !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    lineHeight: "1.05rem !important",
  },
  h4: {
    fontFamily: "Montserrat !important",
    fontSize: "1.875rem !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    lineHeight: "2.25rem !important",
  },
  h5: {
    fontFamily: "Montserrat !important",
    fontSize: "1.5rem !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    lineHeight: "2.25rem !important",
  },
  h6: {
    fontFamily: "Montserrat !important",
    fontSize: "1.25rem !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    lineHeight: "1.875rem !important",
  },
  h7: {
    fontFamily: "Montserrat !important",
    fontSize: "1.125rem !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    lineHeight: "1.6875rem",
  },
  s1: {
    fontFamily: "Montserrat !important",
    fontSize: "1rem !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    lineHeight: "1.5rem !important",
  },
  s2: {
    fontFamily: "Montserrat !important",
    fontSize: "0.875rem !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    lineHeight: "1.3125rem !important",
  },
  s2Medium: {
    fontFamily: "Montserrat !important",
    fontSize: "1rem !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    lineHeight: "1.5rem !important",
  },
  s3: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.3125rem",
  },
  b1: {
    fontFamily: "Montserrat !important",
    fontSize: "1rem !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    lineHeight: "1.5rem !important",
  },
  b2: {
    fontFamily: "Montserrat",
    fontSize: "1rem !important",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.55rem",
  },
  b3: {
    fontFamily: "Montserrat !important",
    fontSize: "0.875rem !important",
    fontStyle: "normal !important",
    fontWeight: `400   !important`,
    lineHeight: "1.3125rem !important",
  },
  button1: {
    fontFamily: "Montserrat !important",
    fontSize: "1rem !important",
    fontStyle: "normal !important",
    fontWeight: '600 !important',
    lineHeight: "1.5rem !important",
  },
};
