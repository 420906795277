import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import Widget from "./components/Widget";
import CommonTable from "./components/CommonTable";
import { Button, Card, Drawer, Table, Tabs } from "antd";
import {
  customerAssignRoles,
  getAssignMemberByRole,
  getCustomerAssignMembersRole,
  getdashboardview,
  getdashboardviewcustomer,
  getdepartment,
  getdesignationlistforcustomer,
  getProjectByMember,
  getTicketDashboard,
  getTicketDashboardSummery,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  convertDate,
  DDMMyyyyFormateDateSet,
  getCheckPermission,
  mmDDyyyyFormateDateSet,
  zeroPad,
} from "../../utility/Globals";
import { DateRangePicker } from "react-date-range";
import { default as ReactSelect } from "react-select";
import NotFound from "../../NotFound";
import { Link } from "react-router-dom";

function TicketDashboard() {
  const [allTicket, setAllTicket] = useState([]);
  const [allmissiedTicket, setallmissiedTicket] = useState([]);
  const [personalmissiedticket, setpersonalmissiedticket] = useState([]);
  const [open, setOpen] = useState(false);
  const [opencalender, setopencalender] = useState(false);
  const [opencalender1, setopencalender1] = useState(false);
  const [viewopen, setviewopen] = useState(false);
  const [headValues1, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [head1, setHead1] = useState(headValues1[0].format());
  const [head2, setHead2] = useState(headValues1[1].format());
  const [widgitlist, setwidgitlist] = useState([]);
  const [projectList, setProjectlist] = useState([]);
  const [projectselected, setprojectselected] = useState("");
  const [sDate, setSdate] = useState(null);
  const [sweek, setsweek] = useState(null);
  const [eDate, setEdate] = useState(null);
  const [eweek, seteweek] = useState(null);
  const [memberList, setmemberlist] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [isActive, setIsActive] = useState(0);
  const [isActivePersonal, setIsActivePersonal] = useState(0);
  const [isApplyTeam, setIsApplyTeam] = useState(false);

  var timePeriod = 'thisMonth';
  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #757575",
      boxShadow: 'none',
      height: 42,
      minHeight: 42,
      fontSize: '1.2rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '42px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#757575"
    }),
  };

  const ref = useRef();
  const ref1 = useRef();

  const [notFound, setNotFound] = useState(true);

  const showDrawer = () => {
    // getDepartmentData(activeTab);
    setOpen(true);
    setIsApplyTeam(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const viewonclose = () => {
    setviewopen(false);
  };

  useEffect(() => {
    setNotFound(getCheckPermission("tickets", "reports"));
    const checkIfClickedOutside = (e) => {
      if (opencalender && ref.current && !ref.current.contains(e.target)) {
        setopencalender(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      if (!opencalender && document
        .getElementsByClassName("rdrDefinedRangesWrapper").length) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [opencalender]);

  useEffect(() => {
    setNotFound(getCheckPermission("tickets", "reports"));
    const checkIfClickedOutside = (e) => {
      if (opencalender1 && ref1.current && !ref1.current.contains(e.target)) {
        setopencalender1(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      if (!opencalender1 && document
        .getElementsByClassName("rdrDefinedRangesWrapper").length) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [opencalender1]);

  const statuslist = [
    { value: 0, label: "Available" },
    { value: 1, label: "Assigned" },
    { value: 2, label: "Overdue" },
    { value: 3, label: "Approaching ETA" },
    { value: 4, label: "Awaiting Approval" },
    { value: 5, label: "Closed" },
  ];

  function getstatusdata(val) {
    var finalVal = val;
    statuslist.map((item) => {
      if (item.value === val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  const [tabledata, settabledata] = useState([]);
  const [personaltabledata, setpersonaltabledata] = useState([]);
  const [personaltickettabledata, setpersonaltickettabledata] = useState([]);
  const [tablesdata, settablesdata] = useState([]);
  const [availablelist, setavailablelist] = useState([]);
  const titlecolumns = [
    {
      title: "Tasks",
      dataIndex: "task",
      key: "task",
    },
  ];

  const titlecolumn = [
    {
      title: "Tasks",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render(text) {
        return {
          props: {
            style: { color: text === "Overdue" ? "red" : "" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "ETA",
      dataIndex: "eta",
      key: "eta",

    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
    },
  ];

  const [selectedType, setselectedType] = useState("0");
  const [selectedCategory, setselectedCategory] = useState("0");
  const [activeTab, setactiveTab] = useState("teamview");
  let firstDayy;
  let lastDayy;
  const today = new Date();
  firstDayy = new Date(today.setDate(today.getDate() - today.getDay() - 7));
  lastDayy = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [state, setState] = useState({
    selection1: {
      startDate: new Date(head1),
      endDate: new Date(head2),
      key: "selection1",
    },
    selection2: {
      startDate: new Date(head1),
      endDate: new Date(head2),
      key: "selection2",
    },
  });
  useEffect(() => {
    getDashboardview();
    var d1 = new Date();
    let d2 = new Date();
    d2.setDate(d2.getDate());
    var firstDay = new Date(d1.getFullYear(), d1.getMonth(), 1);
    var lastDay = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
    let newStdate = mmDDyyyyFormateDateSet(firstDay);
    let newEdate = mmDDyyyyFormateDateSet(lastDay);
    setSdate(newStdate);
    setEdate(newEdate);
    var today = new Date(); // get current date
    const firstdayweek = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastdayweek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    setsweek(firstdayweek);
    seteweek(lastdayweek);
    localStorage.setItem("projectTId", "00000000-0000-0000-0000-000000000000");
    setprojectselected(localStorage.getItem("projectTId"));
    setallmissiedTicket([]);
  }, []);

  const [departmentlist, setDepartmentlist] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  function getDepartmentData(isPersonal) {
    APIManager.getAPI(
      getdepartment + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.map((item) => {
          if (localStorage.getItem("userDatadepartmentName") === "Management" || localStorage.getItem("userDatadepartment") === item.id) {
            let obj = {
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          }

        });
        setDepartmentlist(list);
        let departmentId;
        list?.map((x) => {
          if (x.id === localStorage.getItem("userDatadepartment")) {
            departmentId = x.id;
            setdepartment(x.role);
            setSelectedDepartment(x.id);
          }
        });
        if (!departmentId) {
          setdepartment(list[0].role);
          setSelectedDepartment(list[0].id);
        }
        getRolesData(departmentId ? departmentId : list[0].id, isPersonal);
      }
    });
  }

  const [userrolesList, setuserrolesList] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");

  function getRolesData(departmentId, isPersonal, itemFrom) {
    APIManager.getAPI(localStorage.getItem('userRole') === 'Customer' ?
      customerAssignRoles + '?customerId=' + localStorage.getItem('loggedInUserId') : getdesignationlistforcustomer + '?deptId=' + departmentId).then((response) => {
        if (response && response.status === 200) {
          const list = [];
          list.push({
            id: "00000000-0000-0000-0000-000000000000",
            role: "All Designations",
            key: "00000000-0000-0000-0000-000000000000",
            value: "00000000-0000-0000-0000-000000000000",
            label: "All Designations",
          });

          let userRoleLevel;
          const list1 = [];
          response.data.map((item) => {
            let obj = {
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
              level: item.level
            };
            list1.push(obj);
            if (localStorage.getItem("userRole") === item.name) {
              userRoleLevel = item.level ?? 0;
            }
          });
          if (departmentId === localStorage.getItem("userDatadepartment")) {
            list1?.map((item) => {
              if (!item.level || item.level > userRoleLevel) {
                let obj = {
                  id: item.id,
                  role: item.role,
                  key: item.key,
                  value: item.value,
                  label: item.label,
                  level: item.level
                };
                list.push(obj);
              }
            });
          }
          else {
            list1?.map((item) => {
              let obj = {
                id: item.id,
                role: item.role,
                key: item.key,
                value: item.value,
                label: item.label,
                level: item.level
              };
              list.push(obj);
            });
          }

          let designationId;

          list?.map((x) => {
            if (x.id === localStorage.getItem("selectedDesignation")) {
              setdesignation(x.role);
              designationId = x.id;
              setSelectedDesignation(x.id);
            }
          });
          if (!designationId) {
            designationId = list[0].id;
            setdesignation(list[0]?.role);
            setSelectedDesignation(list[0].id);
            localStorage.setItem(
              "selectedDesignation",
              designationId
            );
          }
          setuserrolesList(list);
          if (itemFrom != 'fromFilter')
            getMemberlist(departmentId, designationId, isPersonal);
        }
      });
  }

  function getMemberlist(departmentId, designationId, isPersonal, itemFrom) {
    if (designationId === "00000000-0000-0000-0000-000000000000") {

      localStorage.setItem("selectedMember", "00000000-0000-0000-0000-000000000000");

      const list = [];
      list.push({
        id: "00000000-0000-0000-0000-000000000000",
        role: "All Members",
        key: "00000000-0000-0000-0000-000000000000",
        value: "00000000-0000-0000-0000-000000000000",
        label: "All Members",
      })

      let finalmemberId;
      if (activeTab === 'teamview') {
        let members = list;
        setmemberid(members[0].id);
        setmemberName(members[0].role);
        finalmemberId = members[0].id;
      } else {
        setmemberid(list[0].id);
        setmemberName(list[0].role);
        finalmemberId = list[0].id;
      }
      let memberId;
      list?.map((x) => {
        if (x.id === localStorage.getItem("selectedMember")) {
          memberId = x.id;
          setSelectedMember(x.id);
          setmemberid(x.id);
          setmemberName(x.role);
          finalmemberId = x.id;
        }
      });
      if (!memberId) {
        setSelectedMember(list[0]?.id);
        localStorage.setItem("selectedMember", list[0]?.id);
        setmemberid(list[0].id);
        setmemberName(list[0].role);
        finalmemberId = list[0].id;
      }
      var d1 = new Date();
      let d2 = new Date();
      d2.setDate(d2.getDate());
      var firstDay = new Date(d1.getFullYear(), d1.getMonth(), 1);
      var lastDay = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
      let newStdate = mmDDyyyyFormateDateSet(firstDay);
      let newEdate = mmDDyyyyFormateDateSet(lastDay);

      let rowData1 = {
        memberId: finalmemberId,
        projectId: localStorage.getItem("projectTId"),
        DesignationId: designationId,
        DepartmentId: departmentId,
        startDate: newStdate,
        endDate: newEdate,
        ViewMore: false,
        IsTeamView: true,
      };
      if (itemFrom != 'fromFilter')
        getDashboardSummerytabledata(rowData1, activeTab);
      let summaryData = {
        memberId: finalmemberId,
        projectId: localStorage.getItem("projectTId"),
        DesignationId: designationId,
        DepartmentId: departmentId,
        startDate: newStdate,
        endDate: newEdate,
        IsTeamView: true,
      };
      if (itemFrom != 'fromFilter')
        getDashboardSummery(summaryData, activeTab);

      if (activeTab === 'teamview' || !isPersonal) {
        let rowData = {
          departmentId: departmentId,
          designationId: designationId,
          memberId: localStorage.getItem("userId"),
        };

        getDashboardProject(rowData, false);
      }
      else {
        let rowData = {
          memberId: localStorage.getItem("userId"),
        };

        getDashboardProject(rowData, true);
      }
      APIManager.getAPI(
        localStorage.getItem('userRole') === 'Customer' ?
          getCustomerAssignMembersRole + '?customerId=' + localStorage.getItem('loggedInUserId') + '&roleId=00000000-0000-0000-0000-000000000000' :
          getAssignMemberByRole + '?roleId=' + designationId + '&deptId=' + departmentId
      ).then((response) => {
        if (response && response.status === 200) {
          const list = [];
          list.push({
            id: "00000000-0000-0000-0000-000000000000",
            role: "All Members",
            key: "00000000-0000-0000-0000-000000000000",
            value: "00000000-0000-0000-0000-000000000000",
            label: "All Members",
          })
          response.data.map((item) => {
            list.push({
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
            });
          });
          setmemberlist(list);
        }
      });
    }
    else {
      APIManager.getAPI(
        localStorage.getItem('userRole') === 'Customer' ?
          getCustomerAssignMembersRole + '?customerId=' + localStorage.getItem('loggedInUserId') + '&roleId=' + designationId : getAssignMemberByRole + '?roleId=' + designationId + '&deptId=' + departmentId
      ).then((response) => {
        if (response && response.status === 200) {
          const list = [];
          list.push({
            id: "00000000-0000-0000-0000-000000000000",
            role: "All Members",
            key: "00000000-0000-0000-0000-000000000000",
            value: "00000000-0000-0000-0000-000000000000",
            label: "All Members",
          })
          response.data.map((item) => {
            list.push({
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
            });
          });
          setmemberlist(list);
          let finalmemberId;
          if (activeTab === 'teamview') {
            let members = list;
            setmemberid(members[0].id);
            setmemberName(members[0].role);
            finalmemberId = members[0].id;
          } else {
            setmemberid(list[0].id);
            setmemberName(list[0].role);
            finalmemberId = list[0].id;
          }
          let memberId;
          list?.map((x) => {
            if (x.id === localStorage.getItem("selectedMember")) {
              memberId = x.id;
              setSelectedMember(x.id);
              setmemberid(x.id);
              setmemberName(x.role);
              finalmemberId = x.id;
            }
          });
          if (!memberId) {
            setSelectedMember(list[0]?.id);
            localStorage.setItem("selectedMember", list[0]?.id);
            setmemberid(list[0]?.id);
            setmemberName(list[0]?.role);
            finalmemberId = list[0]?.id;
          }

          var d1 = new Date();
          let d2 = new Date();
          d2.setDate(d2.getDate());
          var firstDay = new Date(d1.getFullYear(), d1.getMonth(), 1);
          var lastDay = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
          let newStdate = mmDDyyyyFormateDateSet(firstDay);
          let newEdate = mmDDyyyyFormateDateSet(lastDay);

          let rowData1 = {
            memberId: finalmemberId,
            projectId: localStorage.getItem("projectTId"),
            DesignationId: designationId,
            DepartmentId: departmentId,
            startDate: newStdate,
            endDate: newEdate,
            ViewMore: false,
            IsTeamView: true,
          };
          if (itemFrom != 'fromFilter')
            getDashboardSummerytabledata(rowData1, activeTab);
          let summaryData = {
            memberId: finalmemberId,
            projectId: localStorage.getItem("projectTId"),
            DesignationId: designationId,
            DepartmentId: departmentId,
            startDate: newStdate,
            endDate: newEdate,
            IsTeamView: true,
          };
          if (itemFrom != 'fromFilter')
            getDashboardSummery(summaryData, activeTab);

          if (activeTab === 'teamview') {
            let rowData = {
              departmentId: departmentId,
              designationId: designationId,
              memberId: localStorage.getItem("userId"),
            };
            getDashboardProject(rowData, false);
          }
          else {
            let rowData = {
              memberId: localStorage.getItem("userId"),
            };
            getDashboardProject(rowData, true);
          }
        }
      });
    }
  }

  const [memberName, setmemberName] = useState("");
  const [departmentName, setdepartment] = useState("");
  const [designationname, setdesignation] = useState("");
  const [memberid, setmemberid] = useState("");

  function selecthandleChange(membarId) {

    memberList.map((i) => {
      if (i.id === membarId) {
        setmemberName(i.role);
        setmemberid(membarId);
      }
    });
  }
  function selecthandleChangedepartment(departmentId) {
    departmentlist.map((i) => {
      if (i.id === departmentId) {
        setdepartment(i.role);
        setmemberid(departmentId);
      }
    });
  }

  function getDashboardSummery(rowData, tab) {
    if (!timePeriod) {
      timePeriod = 'thisMonth';
    }
    localStorage.getItem('userRole') === 'Customer' ?
      rowData['IsCustomer'] = true :
      rowData['IsCustomer'] = false;

    rowData['IsTeamView'] = tab == 'teamview' ? true : false;
    if (timePeriod == 'thisMonth') {
      var d1 = new Date();
      let d2 = new Date();
      d2.setDate(d2.getDate());
      var firstDay = new Date(d1.getFullYear(), d1.getMonth(), 1);
      var lastDay = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
      let newStdate = mmDDyyyyFormateDateSet(firstDay);
      let newEdate = mmDDyyyyFormateDateSet(lastDay);
      rowData['startDate'] = newStdate;
      rowData['endDate'] = newEdate;
    }

    if (timePeriod == 'thisWeek') {
      var firstwDay = new Date();
      const nextWeek = new Date()
      nextWeek.setDate(new Date().getDate() + 7)
      let newStwdate = mmDDyyyyFormateDateSet(firstwDay);
      let newEwdate = mmDDyyyyFormateDateSet(nextWeek);
      rowData['startDate'] = newStwdate;
      rowData['endDate'] = newEwdate;
    }

    if (timePeriod == 'today') {
      let todayDate = mmDDyyyyFormateDateSet(new Date());
      rowData['startDate'] = todayDate;
      rowData['endDate'] = todayDate;
    }
    APIManager.postAPI(getTicketDashboardSummery, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          const Widget = [];
          response.data.map((item) => {
            let keys = Object.keys(item);
            keys.map((k) => {
              let tooltipText = "";
              if (k === "assigned") {
                tooltipText = "These are the tickets which have been assigned";
              } else if (k === "overdue") {
                tooltipText = "These are the tickets which have missed their ETA's";
              } else if (k === "approaching ETA") {
                tooltipText =
                  "These are the tickets which have an Approaching ETA as well contains tickets who have missed the ETA’s.";
              } else if (k === "awaiting Approval") {
                tooltipText =
                  "These are the tickets which are Pending for Approval";
              } else if (k === "closed") {
                tooltipText = "These are the tickets which are Closed ";
              }
              let obj = {
                type: k,
                total: item[k],
                tooltip: tooltipText,
              };
              Widget.push(obj);
            });
          });
          setwidgitlist(Widget);
        }
      }
    );
  }

  function getDashboardSummerytabledata(rowData, tab) {
    if (!timePeriod) {
      timePeriod = 'thisMonth';
    }

    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    localStorage.getItem('userRole') === 'Customer' ?
      rowData['IsCustomer'] = true :
      rowData['IsCustomer'] = false;

    rowData['IsTeamView'] = tab == 'teamview' ? true : false;
    rowData['startDate'] = mmDDyyyyFormateDateSet(head1);
    rowData['endDate'] = mmDDyyyyFormateDateSet(head2);
    if (timePeriod == 'thisMonth') {
      var d1 = new Date();
      let d2 = new Date();
      d2.setDate(d2.getDate());
      var firstDay = new Date(d1.getFullYear(), d1.getMonth(), 1);
      var lastDay = new Date(d2.getFullYear(), d2.getMonth() + 1, 0);
      let newStdate = mmDDyyyyFormateDateSet(firstDay);
      let newEdate = mmDDyyyyFormateDateSet(lastDay);
      rowData['startDate'] = newStdate;
      rowData['endDate'] = newEdate;
    }

    if (timePeriod == 'thisWeek') {
      var firstwDay = new Date();
      const nextWeek = new Date()
      nextWeek.setDate(new Date().getDate() + 7)
      let newStwdate = mmDDyyyyFormateDateSet(firstwDay);
      let newEwdate = mmDDyyyyFormateDateSet(nextWeek);
      rowData['startDate'] = newStwdate;
      rowData['endDate'] = newEwdate;
    }

    if (timePeriod == 'today') {
      let todayDate = mmDDyyyyFormateDateSet(new Date());
      rowData['startDate'] = todayDate;
      rowData['endDate'] = todayDate;
    }
    settabledata([]);
    setavailablelist([]);
    APIManager.postAPI(getTicketDashboard, rowData, null, true).then(
      (response) => {
        setAllTicket([]);
        if (response && response.status === 200) {
          const list = [];
          setAllTicket([]);
          if (
            response.data.dashboardAssignedResponse.length &&
            response.data.dashboardAssignedResponse[0].createdName
          ) {
            response.data.dashboardAssignedResponse.map((item) => {
              let obj = {
                name: item.createdName,
                task: (
                  <>
                    <Link
                      to={"/ticket/ticket-view"}
                      state={{ item: { id: item.ticketId } }}
                    >
                      {item.subject}
                    </Link>
                  </>
                ),
              };
              list.push(obj);
              setAllTicket((allTicket) => [...allTicket, obj]);
            });
            if (list.length) {
              settabledata([]);
              let listLength = 0;
              list.length > 5 ? (listLength = 5) : (listLength = list.length);
              for (let i = 0; i < listLength; i++) {
                settabledata((tabledata) => [...tabledata, list[i]]);
              }
            } else {
              settabledata([]);
            }
          } else {
            settabledata([]);
          }
          const tlist = [];
          setallmissiedTicket([]);
          response.data.tickets.map((item) => {
            let object = {
              task: (
                <>
                  <Link
                    to={"/ticket/ticket-view"}
                    state={{ item: { id: item.ticketId } }}
                  >
                    {item.subject}
                  </Link>
                </>
              ),
              status: getstatusdata(item.status),
              eta: <span class={`eta-text ${getstatusdata(item.status) === "Overdue" ? "overdue-new" : "normal-date"
                }`}>{DDMMyyyyFormateDateSet(item.eta)}</span>,
              project: item.projectCode,
            };
            tlist.push(object);
            setallmissiedTicket((missiedTicket) => [...missiedTicket, object]);
          });

          settablesdata([]);
          let listLength = 0;
          if (tab === "Personal view") {
            listLength = tlist.length;
            let availablelist = [];
            setavailablelist([]);
            response.data.dashboardAvailableResponse.map((item) => {
              let obj = {
                name: item.createdName,
                task: (
                  <>
                    <Link
                      to={"/ticket/ticket-view"}
                      state={{ item: { id: item.ticketId } }}
                    >
                      {item.subject}
                    </Link>
                  </>
                ),
              };
              availablelist.push(obj);
            });
            setavailablelist(availablelist);
            setpersonaltabledata([]);
            if (availablelist.length) {
              let listLength = 0;
              availablelist.length > 5
                ? (listLength = 5)
                : (listLength = availablelist.length);
              let personaltabledata = [];
              for (let i = 0; i < listLength; i++) {
                if (availablelist[i]?.task?.props?.children?.props?.children)
                  personaltabledata.push(availablelist[i]);
              }

              setpersonaltabledata(personaltabledata);
            }
            const personallist = [];
            setpersonaltickettabledata([]);
            response.data.tickets.map((item) => {
              let personalobject = {
                task: (
                  <>
                    <Link
                      to={"/ticket/ticket-view"}
                      state={{ item: { id: item.ticketId }}}
                    >
                      {item.subject}
                    </Link>
                  </>
                ),
                status: getstatusdata(item.status),
                eta: <span class={`eta-text ${getstatusdata(item.status) == "Overdue" ? "overdue-new" : "normal-date"
                  }`}>{DDMMyyyyFormateDateSet(item.eta)}</span>,
                project: item.projectCode,
              };
              personallist.push(personalobject);
              setpersonalmissiedticket((missiedTicket) => [
                ...missiedTicket,
                personalobject,
              ]);
              if (personallist.length) {
                let listLength = 0;
                personallist.length > 5
                  ? (listLength = 5)
                  : (listLength = personallist.length);
                let updatedList = [];
                for (let i = 0; i < listLength; i++) {
                  updatedList.push(personallist[i]);
                }
                setpersonaltickettabledata(updatedList);
              }
            });
          } else {
            tlist.length > 5 ? (listLength = 5) : (listLength = tlist.length);
          }

          let updatedData = [];
          for (let i = 0; i < listLength; i++) {
            updatedData.push(tlist[i])
          }
          settablesdata(updatedData);
        } else {
        }
      }
    );
  }
  function getDashboardProject(rowData, isPersonal) {
    if (localStorage.getItem('userRole') === 'Customer' && rowData.departmentId) {
      delete rowData.departmentId;
    }
    if (rowData && rowData.memberId) {
      if (isPersonal) {
        rowData['memberId'] = localStorage.getItem("userId");
      }
      else {
        if (rowData.designationId === '00000000-0000-0000-0000-000000000000') {
          rowData['memberId'] = '00000000-0000-0000-0000-000000000000';
        }
        else {
          rowData['memberId'] = localStorage.getItem("selectedMember");
        }
      }
    }
    APIManager.postAPI(getProjectByMember, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          const list = [];
          if ((localStorage.getItem('userRole') !== 'Customer' && response.data.length > 1)) {
            list.push({
              value: "00000000-0000-0000-0000-000000000000",
              lable: "All",
            })
          }
          response.data.map((item) => {
            const clientsProject = JSON.parse(localStorage.getItem('clientProjects'));
            if (localStorage.getItem('userRole') === 'Customer' && clientsProject[0].projects.length) {
              clientsProject[0].projects.map(x => {
                if (x.projectId === item.id) {
                  list.push({
                    value: item.id,
                    lable: item.projectCode,
                  });
                }
              });
            } else {
              list.push({
                value: item.id,
                lable: item.projectCode,
              });
            }
          });
          if ((localStorage.getItem('userRole') !== 'Customer') && response.data.length > 1) {
            list.push({
              value: "11111111-1111-1111-1111-111111111111",
              lable: "Others",
            })
          }
          setProjectlist(list);
          setprojectselected(list[0].value);
          localStorage.setItem("projectTId", list[0].value);
        }
      }
    );
  }

  const columns = [
    {
      title: "",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "count",
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      count: 32,
    },
    {
      key: "2",
      name: "Jim Green",
      count: 42,
    },
  ];

  function handleClick(tickettype) {
    setIsApplyTeam(false)
    if (isActive != tickettype) {
      setIsActive(tickettype);
    }
  }

  function handleClickPersonal(type) {
    if (isActivePersonal !== type) {
      setIsActivePersonal(type);
    }
  }
  const [view, setview] = useState("");
  function getDashboardview() {
    APIManager.getAPI(
      (localStorage.getItem('userRole') === 'Customer' ?
        getdashboardviewcustomer : getdashboardview) + localStorage.getItem("userId")).then(
          (response) => {
            if (response && response.status === 200) {
              setview(response.data);
              if (response.data === true) {
                setactiveTab("teamview");
              } else {
                setactiveTab("Personal view");
              }
              getDepartmentData(!response.data);
            }
          }
        );
  }
  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          <section>
            <div class="row p-0">
              <div class="col-sm-12 p-0">
                <div class="top-title d-flex ticket-bredcrumb-top">
                  <ul>
                    <li>
                      <h6 class="top-tickets mb-0 text-capitalize ticket-dashboard">tickets</h6>
                    </li>
                    <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                    <li>
                      {" "}
                      <h6 class="ticket-dashboard mb-0 text-capitalize active">
                        ticket dashboard
                      </h6>
                    </li>
                  </ul>
                </div>

                <div class="title-content-tab">
                  <Tabs
                    activeKey={activeTab}
                    onChange={(e) => {
                      localStorage.setItem("projectTId",projectList[0].value);
                      setIsApplyTeam(false);
                      setIsActive(0);
                      setIsActivePersonal(0);
                      setactiveTab(e);
                      setopencalender(false);
                      setopencalender1(false);
                      setprojectselected(localStorage.getItem("projectTId"));
                      setallmissiedTicket([]);
                      if (e === "Personal view") {
                        let rowData = {
                          memberId: '00000000-0000-0000-0000-000000000000'
                        }
                        getDashboardProject(rowData, true);
                      } else {
                        let rowData = {
                          departmentId: selectedDepartment,
                          designationId: selectedDesignation,
                          memberId: e === "Personal view"
                            ? localStorage.getItem("userId")
                            : selectedMember,
                        };
                        getDashboardProject(rowData, false);
                      }
                      let rowData1 = {
                        memberId:
                          e === "Personal view"
                            ? localStorage.getItem("userId")
                            : selectedMember,
                        projectId: localStorage.getItem("projectTId"),
                        DesignationId: selectedDesignation,
                        DepartmentId: selectedDepartment,
                        startDate: mmDDyyyyFormateDateSet(sDate),
                        endDate: mmDDyyyyFormateDateSet(eDate),
                        ViewMore: false,
                        IsTeamView: e === "Personal view" ? false : true,
                      };
                      getDashboardSummerytabledata(rowData1, e);
                      let summaryData = {
                        memberId:
                          e === "Personal view"
                            ? localStorage.getItem("userId")
                            : selectedMember,
                        DesignationId: selectedDesignation,
                        DepartmentId: selectedDepartment,
                        projectId: localStorage.getItem("projectTId"),
                        startDate: mmDDyyyyFormateDateSet(sDate),
                        endDate: mmDDyyyyFormateDateSet(eDate),
                        IsTeamView: e === "Personal view" ? false : true,
                      };
                      getDashboardSummery(summaryData, e);
                    }}
                  >
                    <Tabs.TabPane
                      tab="Team view"
                      key="teamview"
                      disabled={view !== true}
                    >
                      <div class="tab-content p-0 m-0" id="pills-tabContent">
                        <div
                          class="portlet-border-0 tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="portlet over-view ticket-bread-crumb mt-3 mb-0 ms-3">
                            <ul>
                              <li>
                                <span>
                                  {departmentName
                                    ? "" + departmentName
                                    : "For " + localStorage.getItem("userName")}
                                </span>
                              </li>
                              <li>
                                <span>
                                  {designationname
                                    ? "" + designationname
                                    : "For " + localStorage.getItem("userName")}
                                </span>
                              </li>
                              <li>
                                <span
                                  className="p-0 border-0"
                                  onClick={showDrawer}
                                >
                                  {memberName
                                  }
                                </span>
                              </li>
                              <i
                                class="fa fa-angle-right ms-3"
                                aria-hidden="true"
                              ></i>
                            </ul>
                          </div>

                          <Drawer
                            title="Ticket Closed"
                            className="search-team-member-modal"
                            placement="right"
                            onClose={viewonclose}
                            open={viewopen}
                          >
                            <h1>team member</h1>
                            <Table
                              columns={columns}
                              dataSource={data}
                              size="middle"
                            />
                          </Drawer>

                          <div className="portlet">
                            <Drawer
                              title="Search Team Member"
                              className="search-team-member-modal searchteam dashboard-modal"
                              placement="right"
                              onClose={onClose}
                              open={open}
                            >
                              <div className="portlet-body">
                                {localStorage.getItem('userRole') !== 'Customer' ? <div className="col-md-12">
                                  <label className="form-label">Department</label>
                                  <ReactSelect
                                    isMulti={false}
                                    styles={customStylesSearch}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    options={departmentlist}
                                    value={departmentlist?.map(x => {
                                      if (x.id === selectedDepartment) {
                                        return x;
                                      }
                                    })}
                                    onChange={(e) => {
                                      setSelectedDepartment(e.value);
                                      getRolesData(e.value, activeTab, 'fromFilter');
                                    }}
                                  >
                                  </ReactSelect>
                                </div> : <></>}
                                <div className="col-md-12 my-3 py-3">
                                  <label className="form-label">
                                    Designation
                                  </label>
                                  <ReactSelect
                                    isMulti={false}
                                    styles={customStylesSearch}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    options={userrolesList}
                                    value={userrolesList?.map(x => {
                                      if (x.id === selectedDesignation) {
                                        return x;
                                      }
                                    })}
                                    onChange={(e) => {
                                      setSelectedDesignation(e.value);
                                      localStorage.setItem(
                                        "selectedDesignation",
                                        e.value
                                      );
                                      var desigId = e.value;
                                      getMemberlist(selectedDepartment, desigId, activeTab, 'fromFilter');
                                    }}
                                  >
                                  </ReactSelect>

                                </div>
                                <div className="col-md-12">
                                  <label className="form-label">Member</label>
                                  <ReactSelect
                                    isMulti={false}
                                    styles={customStylesSearch}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    allowSelectAll={true}
                                    options={memberList}
                                    value={memberList?.map(x => {
                                      if (x.id == selectedMember) {
                                        return x;
                                      }
                                    })}
                                    onChange={(e) => {

                                      setSelectedMember(e.value);
                                      localStorage.setItem(
                                        "selectedMember",
                                        e.value
                                      );
                                    }}
                                  >
                                  </ReactSelect>

                                </div>
                              </div>
                              <div class="portlet-footer portlet-footer-bordered ">
                                <Button
                                  type="primary"
                                  className="btn btn-primary mx-4"
                                  onClick={() => {
                                    setIsApplyTeam(true);
                                    setIsActive(3)
                                    onClose();
                                    userrolesList.map((x) => {
                                      if (x.id === selectedDesignation) {
                                        setdesignation(x.role);
                                      }
                                    });
                                    selecthandleChange(selectedMember);
                                    selecthandleChangedepartment(
                                      selectedDepartment
                                    );
                                    let rowData = {
                                      departmentId: selectedDepartment,
                                      designationId: selectedDesignation,
                                      memberId: selectedMember
                                        ? selectedMember
                                        : localStorage.getItem("selectedMember"),
                                    };
                                    getDashboardProject(rowData, false);
                                    let rowData1 = {
                                      memberId: selectedMember,
                                      projectId:
                                        localStorage.getItem("projectTId"),
                                      DesignationId: selectedDesignation,
                                      DepartmentId: selectedDepartment,
                                      startDate: mmDDyyyyFormateDateSet(head1),
                                      endDate: mmDDyyyyFormateDateSet(head2),
                                      ViewMore: false,
                                      IsTeamView: activeTab == 'teamview' ? true : false,
                                    };
                                    APIManager.postAPI(getTicketDashboard, rowData1, null, true).then(
                                      (response) => {
                                        setAllTicket([]);
                                        if (response && response.status === 200) {
                                          const list = [];
                                          setAllTicket([]);
                                          if (
                                            response.data.dashboardAssignedResponse.length &&
                                            response.data.dashboardAssignedResponse[0].createdName
                                          ) {
                                            response.data.dashboardAssignedResponse.map((item) => {
                                              let obj = {
                                                name: item.createdName,
                                                task: (
                                                  <>
                                                    <Link
                                                      to={"/ticket/ticket-view"}
                                                      state={{ item: { id: item.ticketId } }}
                                                    >
                                                      {item.subject}
                                                    </Link>
                                                  </>
                                                ),
                                              };
                                              list.push(obj);
                                              setAllTicket((allTicket) => [...allTicket, obj]);
                                            });
                                            if (list.length) {
                                              settabledata([]);
                                              let listLength = 0;
                                              list.length > 5 ? (listLength = 5) : (listLength = list.length);
                                              for (let i = 0; i < listLength; i++) {
                                                settabledata((tabledata) => [...tabledata, list[i]]);
                                              }
                                            } else {
                                              settabledata([]);
                                            }
                                          } else {
                                            settabledata([]);
                                          }
                                          const tlist = [];
                                          setallmissiedTicket([]);
                                          response.data.tickets.map((item) => {
                                            let object = {
                                              task: (
                                                <>
                                                  <Link
                                                    to={"/ticket/ticket-view"}
                                                    state={{ item: { id: item.ticketId } }}
                                                  >
                                                    {item.subject}
                                                  </Link>
                                                </>
                                              ),
                                              status: getstatusdata(item.status),
                                              eta: <span class={`eta-text ${getstatusdata(item.status) === "Overdue" ? "overdue-new" : "normal-date"
                                                }`}>{DDMMyyyyFormateDateSet(item.eta)}</span>,
                                              project: item.projectCode,
                                            };
                                            tlist.push(object);
                                            setallmissiedTicket((missiedTicket) => [...missiedTicket, object]);
                                          });

                                          settablesdata([]);
                                          let listLength = 0;
                                          if (activeTab === "Personal view") {
                                            listLength = tlist.length;
                                            let availablelist = [];
                                            setavailablelist([]);
                                            response.data.dashboardAvailableResponse.map((item) => {
                                              let obj = {
                                                name: item.createdName,
                                                task: (
                                                  <>
                                                    <Link
                                                      to={"/ticket/ticket-view"}
                                                      state={{ item: { id: item.ticketId } }}
                                                    >
                                                      {item.subject}
                                                    </Link>
                                                  </>
                                                ),
                                              };
                                              availablelist.push(obj);
                                            });
                                            setavailablelist(availablelist);
                                            setpersonaltabledata([]);
                                            if (availablelist.length) {
                                              let listLength = 0;
                                              availablelist.length > 5
                                                ? (listLength = 5)
                                                : (listLength = availablelist.length);
                                              let personaltabledata = [];
                                              for (let i = 0; i < listLength; i++) {
                                                if (availablelist[i]?.task?.props?.children?.props?.children)
                                                  personaltabledata.push(availablelist[i]);
                                              }

                                              setpersonaltabledata(personaltabledata);
                                            }
                                            const personallist = [];
                                            setpersonaltickettabledata([]);
                                            response.data.tickets.map((item) => {
                                              let personalobject = {
                                                task: (
                                                  <>
                                                    <Link
                                                      to={"/ticket/ticket-view"}
                                                      state={{ item: { id: item.ticketId } }}
                                                    >
                                                      {item.subject}
                                                    </Link>
                                                  </>
                                                ),
                                                status: getstatusdata(item.status),
                                                eta: <span class={`eta-text ${getstatusdata(item.status) == "Overdue" ? "overdue-new" : "normal-date"
                                                  }`}>{DDMMyyyyFormateDateSet(item.eta)}</span>,
                                                project: item.projectCode,
                                              };
                                              personallist.push(personalobject);
                                              setpersonalmissiedticket((missiedTicket) => [
                                                ...missiedTicket,
                                                personalobject,
                                              ]);
                                              if (personallist.length) {
                                                let listLength = 0;
                                                personallist.length > 5
                                                  ? (listLength = 5)
                                                  : (listLength = personallist.length);
                                                let updatedList = [];
                                                for (let i = 0; i < listLength; i++) {
                                                  updatedList.push(personallist[i]);
                                                }

                                                setpersonaltickettabledata(updatedList);
                                              }
                                            });
                                          } else {
                                            tlist.length > 5 ? (listLength = 5) : (listLength = tlist.length);
                                          }

                                          let updatedData = [];
                                          for (let i = 0; i < listLength; i++) {
                                            updatedData.push(tlist[i])
                                          }
                                          settablesdata(updatedData);
                                        }
                                      }
                                    );
                                    let summaryData = {
                                      memberId: selectedMember
                                        ? selectedMember
                                        : localStorage.getItem("userId"),
                                      projectId:
                                        localStorage.getItem("projectTId"),
                                      DesignationId: selectedDesignation,
                                      DepartmentId: selectedDepartment,
                                      startDate: mmDDyyyyFormateDateSet(head1),
                                      endDate: mmDDyyyyFormateDateSet(head2),
                                      IsTeamView: activeTab == 'teamview' ? true : false,
                                    };
                                    APIManager.postAPI(getTicketDashboardSummery, summaryData, null, true).then(
                                      (response) => {
                                        if (response?.status === 200) {
                                          const Widget = [];
                                          response.data.map((item) => {
                                            let keys = Object.keys(item);
                                            keys.map((k) => {
                                              let tooltipText = "";
                                              if (k === "assigned") {
                                                tooltipText = "These are the tickets which have been assigned";
                                              } else if (k === "overdue") {
                                                tooltipText = "These are the tickets which have missed their ETA's";
                                              } else if (k === "approaching ETA") {
                                                tooltipText =
                                                  "These are the tickets which have an Approaching ETA as well contains tickets who have missed the ETA’s.";
                                              } else if (k === "awaiting Approval") {
                                                tooltipText =
                                                  "These are the tickets which are Pending for Approval";
                                              } else if (k === "closed") {
                                                tooltipText = "These are the tickets which are Closed ";
                                              }
                                              let obj = {
                                                type: k,
                                                total: item[k],
                                                tooltip: tooltipText,
                                              };
                                              Widget.push(obj);
                                            });
                                          });
                                          setwidgitlist(Widget);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  <span class="ms-2">Apply</span>
                                </Button>
                              </div>
                            </Drawer>
                          </div>

                          <div class="ticket-overview-box">
                            <div className="portlet ms-3 me-3">
                              <div class="title-project d-flex justify-content-between">
                                <div class="d-flex title align-items-center">
                                  <h5 class="me-3 text-capitalize mb-0">
                                    ticket's overview
                                  </h5>
                                  <a
                                    href="#"
                                    class="text-dark"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    title="This is the dashboard view which gives an overview of all the tickets"
                                  >
                                    <img
                                      class="ticket-info"
                                      src="/images/info.png"
                                      alt="info.png"
                                    />
                                  </a>
                                </div>
                                {projectList.length ? (<>
                                  <div class="project-select mb-0">
                                    <select
                                      className="ticket-project-select form-select"
                                      aria-label="Default select example"
                                      value={projectselected}
                                      disabled={(localStorage.getItem('userRole') === 'Customer' && projectList?.length === 1) ? true : false}
                                      onChange={(e) => {
                                        setIsApplyTeam(false);
                                        setIsActivePersonal(0);
                                        setIsActive(0);
                                        setprojectselected(e.target.value);
                                        localStorage.setItem(
                                          "projectTId",
                                          e.target.value
                                        );
                                        let summaryData = {
                                          memberId: selectedMember
                                            ? selectedMember
                                            : localStorage.getItem("userId"),
                                          projectId: e.target.value,
                                          DesignationId: selectedDesignation,
                                          DepartmentId: selectedDepartment,
                                          startDate:
                                            isActive === 0
                                              ? mmDDyyyyFormateDateSet(sDate)
                                              : isActive === 1
                                                ? mmDDyyyyFormateDateSet(sweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          endDate:
                                            isActive === 0
                                              ? mmDDyyyyFormateDateSet(eDate)
                                              : isActive === 1
                                                ? mmDDyyyyFormateDateSet(eweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          IsTeamView: activeTab == 'teamview' ? true : false,
                                        };
                                        getDashboardSummery(summaryData, activeTab);
                                        let rowData1 = {
                                          memberId: selectedMember
                                            ? selectedMember
                                            : localStorage.getItem("userId"),
                                          projectId: e.target.value,
                                          DesignationId: selectedDesignation,
                                          DepartmentId: selectedDepartment,
                                          startDate:
                                            isActive === 0
                                              ? mmDDyyyyFormateDateSet(sDate)
                                              : isActive === 1
                                                ? mmDDyyyyFormateDateSet(sweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          endDate:
                                            isActive === 0
                                              ? mmDDyyyyFormateDateSet(eDate)
                                              : isActive === 1
                                                ? mmDDyyyyFormateDateSet(eweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          ViewMore: false,
                                          IsTeamView: activeTab == 'teamview' ? true : false,
                                        };
                                        getDashboardSummerytabledata(rowData1, activeTab);
                                      }}
                                    >
                                      {projectList || (localStorage.getItem('userRole') === 'Customer' && projectList.length === 1) ? (
                                        <>
                                          {projectList.map((item) => {
                                            return (
                                              <option value={item.value}>
                                                {item.lable}
                                              </option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </select>
                                  </div>
                                </>) : (<></>)}

                              </div>

                              <div class="all-ticket-bread-crumb overview-details pt-4 pb-2">
                                <ul class="d-flex align-items-center flex-wrap">
                                  <div class="all-ticket-bread-crumb overview-details p-0">
                                    <ul class="ticketmonth d-flex align-items-center flex-wrap">
                                      <li
                                        class={` ${isActive === 0 ? "active" : "ms-2"
                                          }`}
                                        onClick={() => {
                                          handleClick(0);
                                          let summaryData = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate:
                                              mmDDyyyyFormateDateSet(sDate),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eDate),
                                            IsTeamView: true,
                                          };
                                          timePeriod = 'thisMonth';

                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate:
                                              mmDDyyyyFormateDateSet(sDate),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eDate),
                                            ViewMore: false,
                                            IsTeamView: true,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActive === 0 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          This Month
                                        </span>
                                      </li>
                                      <li
                                        class={` ${isActive === 1
                                          ? "this-week  active"
                                          : "this-week "
                                          }`}
                                        onClick={() => {
                                          handleClick(1);
                                          let summaryData = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate:
                                              mmDDyyyyFormateDateSet(sweek),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eweek),
                                            IsTeamView: true,
                                          };
                                          timePeriod = 'thisWeek';
                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate:
                                              mmDDyyyyFormateDateSet(sweek),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eweek),
                                            ViewMore: false,
                                            IsTeamView: true,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActive === 1 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          this week
                                        </span>
                                      </li>
                                      <li
                                        className={` ${isActive === 2
                                          ? "this-week  active"
                                          : "this-week "
                                          }`}
                                        onClick={() => {
                                          handleClick(2);
                                          let summaryData = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            endDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            IsTeamView: true,
                                          };
                                          timePeriod = 'today';
                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: selectedMember
                                              ? selectedMember
                                              : localStorage.getItem("userId"),
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            startDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            endDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            ViewMore: false,
                                            IsTeamView: true,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActive === 2 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          today
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="cal-box position-relative" ref={ref}>
                                    <div className="add-new-btnw">
                                      <button
                                        className={(isActive == 3 || isApplyTeam) ? "btn btn-light active" : "btn btn-light"}
                                        onClick={() =>
                                          setopencalender(!opencalender)
                                        }
                                      >
                                        <span>
                                          {calanderDate(
                                            head1,
                                            head2
                                          )}
                                        </span>
                                      </button>

                                    </div>
                                    {opencalender && (
                                      <div className="dashboard-calendar">
                                        <div id="example-collapse-text-calendar">
                                          <Card
                                            body
                                            className="daterange-picker-card  mt-2"
                                          >
                                            <div className="d-flex calendar-flex">
                                              <div className="calendar-col">
                                                <DateRangePicker
                                                  onChange={(item) => {

                                                    let selection23 = {};
                                                    if (item?.selection1) {
                                                      selection23 = {
                                                        selection2: {
                                                          startDate:
                                                            item?.selection1
                                                              ?.startDate,
                                                          endDate:
                                                            item?.selection1
                                                              ?.endDate,
                                                          key: "selection2",
                                                        },
                                                      };

                                                      setHead1(
                                                        new DateObject(
                                                          item?.selection1?.startDate
                                                        ).format()
                                                      );
                                                      setHead2(
                                                        new DateObject(
                                                          item?.selection1?.endDate
                                                        ).format()
                                                      );
                                                    } else if (item?.selection2) {
                                                      selection23 = {
                                                        selection1: {
                                                          startDate:
                                                            item?.selection2
                                                              ?.startDate,
                                                          endDate:
                                                            item?.selection2
                                                              ?.endDate,
                                                          key: "selection1",
                                                        },
                                                      };

                                                      setHead1(
                                                        new DateObject(
                                                          item?.selection2?.startDate
                                                        ).format()
                                                      );
                                                      setHead2(
                                                        new DateObject(
                                                          item?.selection2?.endDate
                                                        ).format()
                                                      );
                                                    }

                                                    setState({
                                                      ...state,
                                                      ...item,
                                                      ...selection23,
                                                    });
                                                  }}
                                                  showSelectionPreview={true}
                                                  moveRangeOnFirstSelection={
                                                    false
                                                  }
                                                  months={1}
                                                  ranges={[
                                                    state?.selection1,
                                                    // state?.selection2,
                                                  ]}
                                                  direction="horizontal"
                                                  ariaLabels={{
                                                    dateInput: {
                                                      selection1: {
                                                        startDate:
                                                          "start date input of selction 1",
                                                        endDate:
                                                          "end date input of selction 1",
                                                      },
                                                      // selection2: {
                                                      //   startDate:
                                                      //     "start date input of selction 2",
                                                      //   endDate:
                                                      //     "end date input of selction 2",
                                                      // },
                                                    },
                                                    monthPicker: "month picker",
                                                    yearPicker: "year picker",
                                                    prevButton:
                                                      "previous month button",
                                                    nextButton:
                                                      "next month button",
                                                  }}
                                                />
                                              </div>
                                              <div className="mt-3 ms-3 mt-lg-0 text-center">

                                                <button
                                                  onClick={() => {
                                                    setIsActive(3)
                                                    let rowData1 = {
                                                      memberId: selectedMember
                                                        ? selectedMember
                                                        : localStorage.getItem(
                                                          "userId"
                                                        ),
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      startDate:
                                                        mmDDyyyyFormateDateSet(
                                                          head1
                                                        ),
                                                      endDate:
                                                        mmDDyyyyFormateDateSet(
                                                          head2
                                                        ),
                                                      ViewMore: false,
                                                      IsTeamView: true,
                                                    };
                                                    let summaryData = {
                                                      memberId: selectedMember
                                                        ? selectedMember
                                                        : localStorage.getItem(
                                                          "userId"
                                                        ),
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      DesignationId: selectedDesignation,
                                                      DepartmentId: selectedDepartment,
                                                      startDate:
                                                        mmDDyyyyFormateDateSet(
                                                          head1
                                                        ),
                                                      endDate:
                                                        mmDDyyyyFormateDateSet(
                                                          head2
                                                        ),
                                                      IsTeamView: true,
                                                    };
                                                    timePeriod = 'datePicker';
                                                    getDashboardSummery(
                                                      summaryData, activeTab
                                                    );
                                                    getDashboardSummerytabledata(
                                                      rowData1, activeTab
                                                    );
                                                    setopencalender(
                                                      !opencalender
                                                    );

                                                    if (
                                                      convertDate(
                                                        state.selection1.startDate
                                                      ) ===
                                                      convertDate(
                                                        state.selection2
                                                          .startDate
                                                      ) &&
                                                      convertDate(
                                                        state.selection1.endDate
                                                      ) ===
                                                      convertDate(
                                                        state.selection2.endDate
                                                      )
                                                    ) {
                                                      let rowdata11 = {
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                        DesignationId: selectedDesignation,
                                                        DepartmentId: selectedDepartment,
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                      };
                                                      let rowdata3 = {
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                      };
                                                      let rowdata1 = {
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                        startDate: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        endDate: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                      };
                                                      if (
                                                        selectedCategory !== "0"
                                                      ) {
                                                        rowdata11 = {
                                                          ...rowdata11,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                        rowdata3 = {
                                                          ...rowdata3,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                        rowdata1 = {
                                                          ...rowdata1,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                      }
                                                      if (selectedType !== "0") {
                                                        rowdata11 = {
                                                          ...rowdata11,
                                                          KeywordTypeId:
                                                            selectedType,
                                                        };
                                                        rowdata3 = {
                                                          ...rowdata3,
                                                          TypeId: selectedType,
                                                        };
                                                        rowdata1 = {
                                                          ...rowdata1,
                                                          KeywordTypeId:
                                                            selectedType,
                                                        };
                                                      }
                                                    } else {
                                                      let rowdata11 = {
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                        ClickShareCompareToRange:
                                                        {
                                                          from: convertDate(
                                                            state.selection2
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection2
                                                              .endDate
                                                          ),
                                                        },
                                                      };
                                                      let rowdata3 = {
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                        ClickShareCompareToRange:
                                                        {
                                                          from: convertDate(
                                                            state.selection2
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection2
                                                              .endDate
                                                          ),
                                                        },
                                                      };
                                                      let rowdata1 = {
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                        startDate: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        endDate: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                      };
                                                      let rowBarChartData = {
                                                        ClickShareDateRange: {
                                                          from: convertDate(
                                                            state.selection1
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection1
                                                              .endDate
                                                          ),
                                                        },
                                                        ClickShareCompareToRange:
                                                        {
                                                          from: convertDate(
                                                            state.selection2
                                                              .startDate
                                                          ),
                                                          to: convertDate(
                                                            state.selection2
                                                              .endDate
                                                          ),
                                                        },
                                                        projectId:
                                                          localStorage.getItem(
                                                            "projectTId"
                                                          ),
                                                      };
                                                      if (
                                                        selectedCategory !== "0"
                                                      ) {
                                                        rowdata11 = {
                                                          ...rowdata11,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                        rowdata3 = {
                                                          ...rowdata3,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                        rowdata1 = {
                                                          ...rowdata1,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                        rowBarChartData = {
                                                          ...rowBarChartData,
                                                          KeywordCategoryId:
                                                            selectedCategory,
                                                        };
                                                      }
                                                      if (selectedType !== "0") {
                                                        rowdata11 = {
                                                          ...rowdata11,
                                                          KeywordTypeId:
                                                            selectedType,
                                                        };
                                                        rowdata3 = {
                                                          ...rowdata3,
                                                          TypeId: selectedType,
                                                        };
                                                        rowdata1 = {
                                                          ...rowdata1,
                                                          KeywordTypeId:
                                                            selectedType,
                                                        };
                                                        rowBarChartData = {
                                                          ...rowBarChartData,
                                                          KeywordTypeId:
                                                            selectedType,
                                                        };
                                                      }
                                                    }
                                                  }}
                                                  className="btn btn-primary"
                                                >
                                                  Apply
                                                </button>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </ul>
                              </div>

                              <div class="overview-box px-2">
                                <div class="row">
                                  {widgitlist.map((i) => {
                                    return (
                                      <>
                                        <Widget
                                          ToolTip={i.tooltip}
                                          TotalTickets={zeroPad(i.total, 2)}
                                          WidgetTypeText={i.type}
                                        ></Widget>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>

                            <div class="ticket-list mt-3">
                              <div class="container-fluid px-0 mb-5">
                                <div class="row">
                                  <div class="col-md-4 col-sm-12 mb-3">
                                    <div class="assigned-ticket">
                                      <CommonTable
                                        title={"Assigned tickets"}
                                        columns={titlecolumns}
                                        datasource={tabledata}
                                        tooltip={
                                          "These are the tickets which have been assigned"
                                        }
                                        subtitle={
                                          memberName
                                            ? "For " + memberName
                                            : "For " +
                                            localStorage.getItem("userName")
                                        }
                                      ></CommonTable>
                                      {allTicket && allTicket.length > 5 ? (
                                        <Link
                                          to={"/ticket/view-all-tickets"}
                                          state= {{
                                            ticketstatus: { ticketstatus: 1 },
                                            memberName: memberid
                                              ? memberid
                                              : localStorage.getItem("userId"),
                                          }}
                                          onClick={() => {
                                            localStorage.setItem('ticketStatus', 1);
                                          }}
                                        >
                                          <a href="#" className="list-view-btn d-block py-3"> View More</a>
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div class="col-md-8 col-sm-12 mb-3">
                                    <div class="assigned-ticket ticket-dash">
                                      <CommonTable
                                        title={"Approaching & Missed ETAs"}
                                        columns={titlecolumn}
                                        datasource={tablesdata}
                                        tooltip={
                                          "These are the tickets which have an Approaching ETA as well contains tickets who have missed the ETA’s."
                                        }
                                        subtitle={
                                          memberName
                                            ? "For " + memberName
                                            : "For " +
                                            localStorage.getItem("userName")
                                        }
                                      ></CommonTable>
                                      {allmissiedTicket &&
                                        allmissiedTicket.length > 5 ? (


                                        <Link className="list-view-btn d-block py-3"
                                          to={"/ticket/view-all-tickets"}
                                          state={{
                                            ticketstatus: { ticketstatus: 2 },
                                            memberName: memberid
                                              ? memberid
                                              : localStorage.getItem("userId"),
                                          }}
                                        // onClick={()=> {
                                        //   localStorage.setItem('ticketStatus', 2);
                                        // }}
                                        >
                                          View More
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      class="personal"
                      tab="Personal view"
                      key="Personal view"
                    >
                      <div class="personal tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="ticket-overview-box">
                            <div className="portlet">
                              <div class="title-project d-flex justify-content-between">
                                <div class="d-flex title align-items-center">
                                  <h5 class="me-3 text-capitalize mb-0">
                                    ticket's overview
                                  </h5>
                                  <a
                                    href="#"
                                    class="text-dark"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    title="This is the dashboard view which gives an overview of all the tickets"
                                  >
                                    <img
                                      class="ticket-info"
                                      src="/images/info.png"
                                    />
                                  </a>
                                </div>
                                {projectList.length ? (<>
                                  <div class="project-select mb-0">
                                    <select
                                      class="ticket-project-select form-select"
                                      aria-label="Default select example"
                                      value={projectselected}
                                      disabled={(localStorage.getItem('userRole') === 'Customer' && projectList?.length === 1) ? true : false}
                                      onChange={(e) => {
                                        if (e.target.value === 'Select Project') {
                                          return;
                                        }
                                        setIsActive(0);
                                        setIsActivePersonal(0);
                                        setprojectselected(e.target.value);
                                        localStorage.setItem(
                                          "projectTId",
                                          e.target.value
                                        );
                                        let summaryData = {
                                          memberId: localStorage.getItem("userId"),
                                          projectId: e.target.value,
                                          DesignationId: selectedDesignation,
                                          DepartmentId: selectedDepartment,
                                          startDate:
                                            isActivePersonal === 0
                                              ? mmDDyyyyFormateDateSet(sDate)
                                              : isActivePersonal === 1
                                                ? mmDDyyyyFormateDateSet(sweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          endDate:
                                            isActivePersonal === 0
                                              ? mmDDyyyyFormateDateSet(eDate)
                                              : isActivePersonal === 1
                                                ? mmDDyyyyFormateDateSet(eweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          IsTeamView: activeTab == 'teamview' ? true : false,
                                        };
                                        getDashboardSummery(summaryData, activeTab);
                                        let rowData1 = {
                                          memberId: localStorage.getItem("userId"),
                                          projectId: e.target.value,
                                          DesignationId: selectedDesignation,
                                          DepartmentId: selectedDepartment,
                                          startDate:
                                            isActivePersonal === 0
                                              ? mmDDyyyyFormateDateSet(sDate)
                                              : isActivePersonal === 1
                                                ? mmDDyyyyFormateDateSet(sweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          endDate:
                                            isActivePersonal === 0
                                              ? mmDDyyyyFormateDateSet(eDate)
                                              : isActivePersonal === 1
                                                ? mmDDyyyyFormateDateSet(eweek)
                                                : mmDDyyyyFormateDateSet(new Date()),
                                          ViewMore: false,
                                          IsTeamView: activeTab == 'teamview' ? true : false,
                                        };
                                        getDashboardSummerytabledata(rowData1, activeTab);
                                      }}
                                    >
                                      {localStorage.getItem('userRole') === 'Customer' && projectList.length === 1 ? (<>
                                        {projectList.map((item) => {
                                          return (
                                            <option value={item.value}>
                                              {item.lable}
                                            </option>
                                          );
                                        })}
                                      </>) : (<>
                                        {projectList.map((item) => {
                                          return (
                                            <option value={item.value}>
                                              {item.lable}
                                            </option>
                                          );
                                        })}
                                      </>)}

                                    </select>
                                  </div>
                                </>) : (<></>)}

                              </div>

                              <div class="all-ticket-bread-crumb overview-details pt-4 pb-2">
                                <ul class="d-flex align-items-center flex-wrap">
                                  <div class="all-ticket-bread-crumb p-0 overview-details">
                                    <ul class="d-flex align-items-center flex-wrap">
                                      <li
                                        className={`${isActivePersonal === 0 ? "active" : ""
                                          }`}
                                        onClick={() => {
                                          handleClickPersonal(0);
                                          let summaryData = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate:
                                              mmDDyyyyFormateDateSet(sDate),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eDate),
                                            IsTeamView: false,
                                          };
                                          timePeriod = 'thisMonth';
                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate:
                                              mmDDyyyyFormateDateSet(sDate),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eDate),
                                            ViewMore: false,
                                            IsTeamView: false,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActivePersonal === 0 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          this month
                                        </span>
                                      </li>
                                      <li
                                        class={`${isActivePersonal === 1 ? "active" : ""
                                          }`}
                                        onClick={() => {
                                          timePeriod = 'thisWeek';
                                          handleClickPersonal(1);
                                          let summaryData = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate:
                                              mmDDyyyyFormateDateSet(sweek),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eweek),
                                            IsTeamView: false,
                                          };
                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate:
                                              mmDDyyyyFormateDateSet(sweek),
                                            endDate:
                                              mmDDyyyyFormateDateSet(eweek),
                                            ViewMore: false,
                                            IsTeamView: false,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActivePersonal === 1 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          this week
                                        </span>
                                      </li>
                                      <li
                                        className={`${isActivePersonal === 2 ? "active" : ""
                                          }`}
                                        onClick={() => {
                                          timePeriod = 'today';
                                          handleClickPersonal(2);
                                          let summaryData = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            endDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            IsTeamView: false,
                                          };
                                          getDashboardSummery(summaryData, activeTab);
                                          let rowData1 = {
                                            memberId: localStorage.getItem("userId"),
                                            DesignationId: selectedDesignation,
                                            DepartmentId: selectedDepartment,
                                            projectId:
                                              localStorage.getItem("projectTId"),
                                            startDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            endDate: mmDDyyyyFormateDateSet(
                                              new Date()
                                            ),
                                            ViewMore: false,
                                            IsTeamView: false,
                                          };
                                          getDashboardSummerytabledata(rowData1, activeTab);
                                        }}
                                      >
                                        <span>
                                          {isActivePersonal === 2 ? (
                                            <i
                                              class="fa fa-check me-2"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <></>
                                          )}
                                          today
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="cal-box position-relative" ref={ref1}>
                                    <div className="add-new-btnw">
                                      <button
                                        className={isActivePersonal == 4 ? "btn btn-light active" : "btn btn-light"}
                                        onClick={() =>
                                          setopencalender1(!opencalender1)
                                        }
                                      >
                                        <span>
                                          {calanderDate(
                                            state.selection1?.startDate,
                                            state.selection1?.endDate
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                    {opencalender1 && (
                                      <div id="example-collapse-text-calendar">
                                        <Card
                                          body
                                          className="daterange-picker-card  mt-2"
                                        >
                                          <div className="d-flex calendar-flex">
                                            <div className="calendar-col">
                                              <DateRangePicker
                                                onChange={(item) => {

                                                  let selection23 = {};
                                                  if (item?.selection1) {
                                                    selection23 = {
                                                      selection2: {
                                                        startDate:
                                                          item?.selection1
                                                            ?.startDate,
                                                        endDate:
                                                          item?.selection1
                                                            ?.endDate,
                                                        key: "selection2",
                                                      },
                                                    };

                                                    setHead1(
                                                      new DateObject(
                                                        item?.selection1?.startDate
                                                      ).format()
                                                    );
                                                    setHead2(
                                                      new DateObject(
                                                        item?.selection1?.endDate
                                                      ).format()
                                                    );
                                                  } else if (item?.selection2) {
                                                    selection23 = {
                                                      selection1: {
                                                        startDate:
                                                          item?.selection2
                                                            ?.startDate,
                                                        endDate:
                                                          item?.selection2
                                                            ?.endDate,
                                                        key: "selection1",
                                                      },
                                                    };

                                                    setHead1(
                                                      new DateObject(
                                                        item?.selection2?.startDate
                                                      ).format()
                                                    );
                                                    setHead2(
                                                      new DateObject(
                                                        item?.selection2?.endDate
                                                      ).format()
                                                    );
                                                  }

                                                  setState({
                                                    ...state,
                                                    ...item,
                                                    ...selection23,
                                                  });
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={1}
                                                ranges={[
                                                  state?.selection1,
                                                  // state?.selection2,
                                                ]}
                                                direction="horizontal"
                                                ariaLabels={{
                                                  dateInput: {
                                                    selection1: {
                                                      startDate:
                                                        "start date input of selction 1",
                                                      endDate:
                                                        "end date input of selction 1",
                                                    },
                                                    // selection2: {
                                                    //   startDate:
                                                    //     "start date input of selction 2",
                                                    //   endDate:
                                                    //     "end date input of selction 2",
                                                    // },
                                                  },
                                                  monthPicker: "month picker",
                                                  yearPicker: "year picker",
                                                  prevButton:
                                                    "previous month button",
                                                  nextButton: "next month button",
                                                }}
                                              />
                                            </div>
                                            <div className="mt-3 ms-3 mt-lg-0 text-center">
                                              <button
                                                onClick={() => {
                                                  timePeriod = 'datePicker';
                                                  setIsActivePersonal(4)
                                                  let rowData1 = {
                                                    memberId: localStorage.getItem(
                                                      "userId"
                                                    ),
                                                    DesignationId: selectedDesignation,
                                                    DepartmentId: selectedDepartment,
                                                    projectId:
                                                      localStorage.getItem(
                                                        "projectTId"
                                                      ),
                                                    startDate:
                                                      mmDDyyyyFormateDateSet(
                                                        head1
                                                      ),
                                                    endDate:
                                                      mmDDyyyyFormateDateSet(
                                                        head2
                                                      ),
                                                    ViewMore: false,
                                                    IsTeamView: false,
                                                  };
                                                  let summaryData = {
                                                    memberId: localStorage.getItem(
                                                      "userId"
                                                    ),
                                                    DesignationId: selectedDesignation,
                                                    DepartmentId: selectedDepartment,
                                                    projectId:
                                                      localStorage.getItem(
                                                        "projectTId"
                                                      ),
                                                    startDate:
                                                      mmDDyyyyFormateDateSet(
                                                        head1
                                                      ),
                                                    endDate:
                                                      mmDDyyyyFormateDateSet(
                                                        head2
                                                      ),
                                                    IsTeamView: false,
                                                  };
                                                  getDashboardSummery(
                                                    summaryData, activeTab
                                                  );
                                                  getDashboardSummerytabledata(
                                                    rowData1, activeTab
                                                  );
                                                  setopencalender1(!opencalender1);

                                                  if (
                                                    convertDate(
                                                      state.selection1.startDate
                                                    ) ===
                                                    convertDate(
                                                      state.selection2.startDate
                                                    ) &&
                                                    convertDate(
                                                      state.selection1.endDate
                                                    ) ===
                                                    convertDate(
                                                      state.selection2.endDate
                                                    )
                                                  ) {
                                                    let rowdata11 = {
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                    };
                                                    let rowdata3 = {
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                    };
                                                    let rowdata1 = {
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                      startDate: convertDate(
                                                        state.selection1.startDate
                                                      ),
                                                      endDate: convertDate(
                                                        state.selection1.endDate
                                                      ),
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                    };
                                                    if (selectedCategory !== "0") {
                                                      rowdata11 = {
                                                        ...rowdata11,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                      rowdata3 = {
                                                        ...rowdata3,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                      rowdata1 = {
                                                        ...rowdata1,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                    }
                                                    if (selectedType !== "0") {
                                                      rowdata11 = {
                                                        ...rowdata11,
                                                        KeywordTypeId:
                                                          selectedType,
                                                      };
                                                      rowdata3 = {
                                                        ...rowdata3,
                                                        TypeId: selectedType,
                                                      };
                                                      rowdata1 = {
                                                        ...rowdata1,
                                                        KeywordTypeId:
                                                          selectedType,
                                                      };
                                                    }
                                                  } else {
                                                    let rowdata11 = {
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                      ClickShareCompareToRange: {
                                                        from: convertDate(
                                                          state.selection2
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection2.endDate
                                                        ),
                                                      },
                                                    };
                                                    let rowdata3 = {
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                      ClickShareCompareToRange: {
                                                        from: convertDate(
                                                          state.selection2
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection2.endDate
                                                        ),
                                                      },
                                                    };
                                                    let rowdata1 = {
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                      startDate: convertDate(
                                                        state.selection1.startDate
                                                      ),
                                                      endDate: convertDate(
                                                        state.selection1.endDate
                                                      ),
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                    };
                                                    let rowBarChartData = {
                                                      ClickShareDateRange: {
                                                        from: convertDate(
                                                          state.selection1
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection1.endDate
                                                        ),
                                                      },
                                                      ClickShareCompareToRange: {
                                                        from: convertDate(
                                                          state.selection2
                                                            .startDate
                                                        ),
                                                        to: convertDate(
                                                          state.selection2.endDate
                                                        ),
                                                      },
                                                      projectId:
                                                        localStorage.getItem(
                                                          "projectTId"
                                                        ),
                                                    };
                                                    if (selectedCategory !== "0") {
                                                      rowdata11 = {
                                                        ...rowdata11,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                      rowdata3 = {
                                                        ...rowdata3,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                      rowdata1 = {
                                                        ...rowdata1,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                      rowBarChartData = {
                                                        ...rowBarChartData,
                                                        KeywordCategoryId:
                                                          selectedCategory,
                                                      };
                                                    }
                                                    if (selectedType !== "0") {
                                                      rowdata11 = {
                                                        ...rowdata11,
                                                        KeywordTypeId:
                                                          selectedType,
                                                      };
                                                      rowdata3 = {
                                                        ...rowdata3,
                                                        TypeId: selectedType,
                                                      };
                                                      rowdata1 = {
                                                        ...rowdata1,
                                                        KeywordTypeId:
                                                          selectedType,
                                                      };
                                                      rowBarChartData = {
                                                        ...rowBarChartData,
                                                        KeywordTypeId:
                                                          selectedType,
                                                      };
                                                    }
                                                  }
                                                }}
                                                className="btn btn-primary"
                                              >
                                                Apply
                                              </button>
                                            </div>
                                          </div>
                                        </Card>
                                      </div>
                                    )}
                                  </div>
                                </ul>
                              </div>

                              <div class="overview-box ps-2">
                                <div class="row">
                                  {widgitlist.map((i) => {
                                    return (
                                      <>
                                        <Widget
                                          ToolTip={i.tooltip}
                                          TotalTickets={i.total}
                                          WidgetTypeText={i.type}
                                        ></Widget>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div class="ticket-list mt-3">
                              <div class="container-fluid px-0 mb-5">
                                <div class="row">
                                  <div class="col-md-4 col-sm-12 ps-0 mb-3">
                                    <div class="assigned-ticket">
                                      <CommonTable
                                        title={"Available tickets"}
                                        columns={titlecolumns}
                                        datasource={personaltabledata}
                                        tooltip={
                                          "These are the tickets which are available & yet to be picked up"
                                        }
                                        subtitle={localStorage.getItem(
                                          "userName"
                                        )}
                                      ></CommonTable>
                                      {availablelist &&
                                        availablelist.length > 5 ? (
                                        <Link
                                          className="list-view-btn d-block py-3"
                                          to={ "/ticket/personal-tickets"}
                                          state={{
                                            ticketstatus: { ticketstatus: 0 },
                                            memberName: memberid
                                              ? memberid
                                              : localStorage.getItem("userId"),
                                          }}
                                        >
                                          View More
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div class="col-md-8 col-sm-12 pe-0 mb-3">
                                    <div class="assigned-ticket ticket-dash">
                                      <CommonTable
                                        title={"Approaching & Missed ETAs"}
                                        columns={titlecolumn}
                                        datasource={personaltickettabledata}
                                        tooltip={
                                          "These are the tickets which have an Approaching ETA as well contains tickets who have missed the ETA’s"
                                        }
                                        subtitle={localStorage.getItem(
                                          "userName"
                                        )}
                                      ></CommonTable>
                                      {personalmissiedticket &&
                                        personalmissiedticket.length > 5 ? (
                                        <Link
                                          className="list-view-btn d-block py-3"
                                          to={"/ticket/personal-tickets"}
                                          state={{
                                            ticketstatus: { ticketstatus: 2 },
                                            memberName: memberid
                                              ? memberid
                                              : localStorage.getItem("userId"),
                                          }}
                                        >
                                          View More
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>
        </>
      )}</>
  );
}

export default TicketDashboard;
