/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Box, Flex } from "@semcore/ui/flex-box";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DropdownMenu from "@semcore/ui/dropdown-menu";
import Tabs from "../../tabs";
import DropDownInput from "../../Dropdown/Input";
import { Key } from "feather-icons-react/build/IconComponents";

const ModuleHeader = ({
  moduleName,
  description,
  dropDown,
  children,
  onDropDownSelect,
  tabs,
  subtitle,
  isFixed,
}) => {
  const [isDropdoDownVisible, setIsDropDownVisible] = useState(false);
  const [value, setValue] = React.useState(0);
  return (
    <Box p={"1rem"} css={styles.mainContainer(isFixed)}>
      <Flex css={styles.mainHeader(isFixed)} justifyContent="space-between" alignItems={"center"}>
        {moduleName && (
          <Box>
            <Flex alignItems="center" columnGap={"0.5rem"}>
              <div css={styles.title}>{moduleName}</div>
              {subtitle && <div css={styles.subtitle}>{subtitle}</div>}
              {dropDown && (
                // <Box>
                //   <DropdownMenu>
                //     {(props, handlers) => (
                //       <>
                //         <DropdownMenu.Trigger>
                //           <Flex
                //             css={styles.triggerText}
                //             h={"100%"}
                //             alignItems="center"
                //             p={"0.3rem"}
                //             columnGap={"0.5rem"}
                //           >
                //             <Box>{dropDown.options[dropDown.active].label}</Box>
                //             <FeatherIcon
                //               icon={"chevron-down"}
                //               css={styles.icon}
                //             />
                //           </Flex>
                //         </DropdownMenu.Trigger>
                //         <DropdownMenu.Popper ptb={"0.3rem"}>
                //           {dropDown.options.map((option, index) => (
                //             <DropdownMenu.Item
                //               p="0"
                //               onClick={() => {
                //                 onDropDownSelect(index);
                //                 handlers.visible(false);
                //               }}
                //               active={index === dropDown.active}
                //             >
                //               <Box css={styles.option}>{option.label}</Box>
                //             </DropdownMenu.Item>
                //           ))}
                //         </DropdownMenu.Popper>
                //       </>
                //     )}
                //   </DropdownMenu>
                // </Box>
                <DropDownInput key={dropDown.active} triggerStyles={styles.triggerText} optionStyles={styles.optionStyles} data={dropDown} multiselect={false} defaultValue={dropDown.options[dropDown.active].label} handleDropdownClick={(selected)=>{
                  onDropDownSelect(selected)
                }}/>
              )}
            </Flex>
            {description && <Box css={styles.description}>{description}</Box>}
          </Box>
        )}
        {children}
      </Flex>
      {tabs && (
        <Tabs activeTab={value} onChane={setValue} tabs={tabs.options}/>
      )}
    </Box>
  );
};

export default ModuleHeader;
