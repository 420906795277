import React from "react";
import { useState, useEffect, useRef } from "react";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import APIManager from "../../services/APIManager";
import { Table, Drawer } from "antd";
import { ProgressBar } from "react-bootstrap";
import {
  getDaysInMonth,
  convertDate,
  setMonths,
  findUnique,
  removeTrailingSlash,
  ArticleStatus,
  mmDDyyyyFormateDateSet,
  formatMonthYeartime,
  formateDateIntoDatemonth,
  isValidBulkUpload,
  mmDDyyyyFormateForForm,
  exportToEXCEL,
  ArticleStatusExp
} from "../../utility/Globals";
import {
  getEditor,
  getrequirementlist,
  getWriter,
  onlyXlsFile,
  requirementBulkImport,
  AssignWriter,
  AssignEditor,
  GET_Server_URL,
  updateRequirementStatus,
  requirementById,
  getProjectById,
  mutipledeleterequirement,
  singledeleterequirememt,
  getProjectByOrgId
} from "../../utility/Constant";
import { confirmAlert } from "react-confirm-alert";
import { default as ReactSelect } from "react-select";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";

function RequirementDetails(props) {
  const ref = useRef();
  const location = useLocation();
  const [isPOCAssigned, setIsPOCAssigned] = useState(
    location?.state?.poc ? true : false
  );
  const [projectname, setProjectName] = useState(
    location?.state?.projectname
  );
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No File Selected");
  const [fileName, setFileName] = useState("");
  const [requirementData, setRequirementData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [size, setSize] = useState();
  const [sizeEdit, setSizeEdit] = useState();
  const [writeropen, setWriterOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [RequirementColumns, setRequirementColumns] = useState("");
  const [projectID, setProjectId] = useState(
    props.location.state?.projectId ?? ""
  );
  const [head1, setHead1] = useState(
    convertDate(new Date().getMonth() + 1 + "-01-" + new Date().getFullYear())
  );
  const [head2, setHead2] = useState(
    convertDate(
      convertDate(
        new Date().getMonth() +
        1 +
        "-" +
        getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      )
    )
  );
  const [monthYear, setMonthYear] = useState();
  const [List, setList] = useState("");
  const [RequirementRaised, setRequirementRaised] = useState("");
  const [ArticlesCompleted, setArticlesCompleted] = useState("");
  const [Rework, setRework] = useState("");
  const [errors, setErrors] = useState("");
  const [editorList, setEditorList] = useState([]);
  const [writerList, setWriterList] = useState([]);
  const [writerId, setWriterID] = useState('');
  const [editorId, setEditorID] = useState('');
  const [Cost, setCost] = useState(0);
  const [eta, setETA] = useState();
  const [save, setsave] = useState(false);
  const [requirementId, setRequirementId] = useState(
    props.location.state?.requirementId
  );
  const navigate = useNavigate();
  var param = [];
  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const [deleteRequirementAll, setdeleteRequirementAll] = useState([]);
  const [deleteRequirementId, setdeleteRequirementId] = useState([]);
  const [flag, setflag] = useState(false);
  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #212121 ",
      boxShadow: 'none',
      fontSize: '1rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 7px',
      // bottom: '1px',
      Margin: '0'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121"
    }),
    input: (provided) => ({
      ...provided,
      height: '38px',
      Padding: '0',
      Margin: '0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
      Margin: '0',
    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0',
    }),
  };

  const [writerListSelect, setWriterListSelect] = useState([]);
  const [editorListSelect, setEditorListSelect] = useState([]);
  const [isCostPerWord, setIsCostPerWord] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectSelected, setProjectSelected] = useState(projectID);
  const [exportList, setExportList] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    param = props.location.state;
    if (param)
      getProjectByID(param.projectId)

    setMonthYear(
      setMonths(new Date(param?.startDate ?? new Date()).getMonth() + 1) +
      " " +
      new Date(param?.startDate ?? new Date()).getFullYear()
    );
    if (param?.requirementId) {
      getRequirementDetail();
    } else {
      setHead1(param?.startDate);
      setHead2(param?.endDate);
      requirementlist();
    }

    return () => dispatch();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const onChangeFilter = (pagination, filters, sorter, extra) => {
    if (filters.statusbar !== null)
      localStorage.setItem("requirementStatus", filters.statusbar);
    else
      localStorage.removeItem("requirementStatus");
    requirementlist();
  };

  const dispatch = () => {
    localStorage.removeItem("requirementStatus");
    localStorage.removeItem("projectDashboardCMS");
  }

  const onClose = () => {
    setOpen1(false);
    setWriterOpen(false);
    setEditorOpen(false);
    setEditorList([]);
    setWriterList([]);
    clearForm();
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen1(true);
  };

  const showLargeDrawer1 = () => {
    setSize("large");
    setWriterOpen(true);
  };

  const showLargeDrawerEdit = () => {
    setSizeEdit("large");
    setEditorOpen(true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows, info) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRowsArray(selectedRows);

      if (selectedRows.length > 0) {
        setflag(true);
      } else {
        setflag(false);
        setdeleteRequirementAll([]);
        setdeleteRequirementId([])
      }

      if (info.type == "all") {
        localStorage.setItem("selectionInfoType", info.type);
        const list1 = [];
        selectedRowKeys = [];
        requirementData.map((item) => {
          selectedRowKeys.push(item.key);
          list1.push(item.requirementId);
        });
        setdeleteRequirementAll(list1);
      } else {
        const list1 = [];
        selectedRows.map((item) => {
          list1.push(item.requirementId);
        });
        setdeleteRequirementId(list1);
      }
    },
  };

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + requirementId).then((res) => {
      if (props.location.state) {
        getProjectByID(props.location.state.projectId)
        requirementlist();
      }
      else {
        setProjectId(res.data.value.projectId);
        requirementlist(res.data.value.projectId);
        getProjectByID(res.data.value.projectId);

      }
    });
  };

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setProjectName(res.data.value.projectCode);
    });
  };

  function getProjetList() {
    APIManager.getAPI(
      getProjectByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let object;
        response.data.map((item) => {
          object = {
            label: item.projectCode,
            value: item.id
          }
          list.push(object)
        })
        setProjectList(list)

      }
    });
  }

  function uploadRequirementFile() {
    var input = document.getElementById("requirementFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      // document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      // document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileName(file.name);
  }

  function requirementlist(id = null) {
    if (head1) {
      APIManager.postAPI(
        getrequirementlist +
        "?projectId=" +
        (id ?? projectID) +
        "&startDate=" +
        convertDate(param.startDate ?? head1) +
        "&endDate=" +
        convertDate(param.endDate ?? head2)
      ).then((response) => {

        if (response.status == 200) {
          let list = [];
          let exportList = [];

          setList(response.data.table[0].targetForTheMonth);
          getProjetList()
          setRequirementRaised(response.data.table1[0].requirementRaised);
          setArticlesCompleted(response.data.table2[0].articleCompleted);
          setRework(response.data.table3[0].rework);
          response.data.table4.map((item, index) => {
            setIsPOCAssigned(true);
            let obj = {
              key: item.id,
              id: "#" + item.id,
              keyword: (
                <>
                  <Link
                    to={"/content-main/requirement-keyword-details"}
                    state={{requirementId: item.requirementId}}
                  >
                    <span className="keyword_detail">{item.keyword}</span>
                  </Link>
                </>
              ),
              requirementId: item.requirementId,
              editer: item.editor,
              writer: (
                <div className="d-flex align-items-center writer-select input_outer">
                  <input
                    className=" medium-selected assign-t-span"
                    aria-label="Default select example"
                    value={
                      item.writer && item.writer != " " ? item.writer : "Select"
                    }
                    onClick={(i) => {
                      showLargeDrawer1();
                      setCloseButton(false);
                      getAllWriter(item.writerId, item.requirementId);
                      setWriterID(item?.writerId);
                      setRequirementId(item.requirementId ?? "");
                      setErrors("");
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              ),

              editor: (
                <div className="d-flex align-items-center writer-select">
                  <input
                    className="medium-selected"
                    aria-label="Default select example"
                    disabled={
                      item.status != null
                        ? item.status > 3
                          ? false
                          : true
                        : true
                    }
                    value={
                      item.editor && item.editor != " " ? item.editor : "Select"
                    }
                    onClick={(i) => {
                      let flag = item.writer ? true : false;
                      if (flag) {
                        showLargeDrawerEdit();
                        setCloseButton(false);
                        getAllEditor(item.editorId, item.requirementId, item.writerId);
                        setEditorID(item?.editorId);
                        setRequirementId(item.requirementId ?? "");
                      }
                    }}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke={item.status > 3 ? "#000000" : "#757575"}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right "
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              ),
              status: item.status,
              statusbar: (
                <label className="w-100">
                  <div className="d-flex align-items-center justify-content-between progress_bar require-status">
                    <div className="d-flex align-items-center justify-content-start progress_report">
                      <p className="p-0 m-0">
                        {ArticleStatus.map((article) =>
                          article.value == item.status ? article.text : ""
                        )}
                      </p>
                    </div>
                    {item.status == 1 || item.status == 2 ? (
                      <>
                        <div className="d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-clock me-2 ms-4"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span className="">
                            ETA : {formateDateIntoDatemonth(item.writerETA)}
                          </span>
                        </div>
                      </>
                    ) : item.status == 5 || item.status == 6 ? (
                      <>
                        <div className="d-flex align-items-center justify-content-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-clock me-2 ms-4"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span className="">
                            ETA : {formateDateIntoDatemonth(item.editorETA)}
                          </span>
                        </div>
                        <></>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <ProgressBar className="assign-writer-progress requirement-progreesbar">
                    <ProgressBar now={Math.ceil((item.status + 1) * 10)} key={1} />
                  </ProgressBar>
                </label>
              ),
              repeatMenu: (
                <>
                  <div className="d-flex align-items-center require-repeat">
                    <img
                      src="/images/black-repeat.svg"
                      alt="repeat menu"
                      title="Rework"
                    ></img>
                    <span className="ms-2">
                      {item.rework ? item.rework : "00"}
                    </span>
                  </div>
                </>
              ),
              kababMenu: (
                <div>
                  <span class="dropdown">
                    <button className="kabab-btn"
                      type="kabeb button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>

                    <div
                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div class="dropdown-item d-flex align-items-center dot-drop-color delete">
                        <span
                          className="ms-3"
                          onClick={() => {
                            deleteConfirm(item.requirementId)
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              ),
            };
            list.push(obj);

            let exportObject = {
              Id: "#" + item.id,
              Keyword: item.keyword,
              Writer: item.writer,
              Editor: item.editor,
              Status: ArticleStatusExp(item.status),
            };
            exportList.push(exportObject);
          });

          setExportList(exportList);

          var data = [
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "KEYWORD",
              dataIndex: "keyword",
              key: "keyword",
            },
            {
              title: "WRITER",
              dataIndex: "writer",
              key: "writer",
            },
            {
              title: "EDITOR",
              dataIndex: "editor",
              key: "editor",
            },
            {
              title: "STATUS",
              dataIndex: "statusbar",
              key: "statusbar",
              style: "color:red;",
              filters: findUnique(ArticleStatus, (d) => {
                return d.text;
              }),
              filterSearch: true,
              filteredValue: (localStorage.getItem("requirementStatus") && localStorage.getItem("requirementStatus") != "null")
                ? [...localStorage.getItem("requirementStatus").split(',')] : [],
              onFilter: (value, record) => {
                return record.status == value;
              },
            },
            {
              title: "",
              dataIndex: "repeatMenu",
            },
            {
              title: "",
              dataIndex: "kababMenu",
            },
          ];
          setRequirementData(list);
          setRequirementColumns(data);
        }
      });
    }
  }

  function uploadRequirementFile() {
    var input = document.getElementById("requirementFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      // document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      // document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileName(file.name);
  }

  function submitConfirm() {
    confirmAlert({
      overlayClassName: 'requirenment-alert',
      title:


        <div className="d-flex align-items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check confirm-check "><polyline points="20 6 9 17 4 12"></polyline></svg>
          <span className="please-confirm fw-bold ms-3 mb-0">Are you sure ?</span>
        </div>,
      message:
        <span className="require-msg ms-5">The requirements will be raised for the month of <b>{formatMonthYeartime(monthYear)}</b></span>,
      buttons: [
        {
          label: <span className="require-cancel-btn bg-white">Cancel</span>,
          onClick: () => {
            setFileName("");
            document.getElementById(
              "requirementFile"
            ).value = "";
            // document.querySelector(
            //   "#textInputUrl"
            // ).disabled = false;
          }
        },
        {
          label: <span className="require-raise-btn">Raise Requirement</span>,
          onClick: () => {
            addRequirementInBulk();
            clearForm();
            // document.querySelector(
            //   "#textInputUrl"
            // ).disabled = false;
          },
        },
      ],
    });
  }
  function deleteConfirm(id) {
    confirmAlert({
      overlayClassName: "delete requirenment-alert",
      title: (
        <div className="d-flex align-items-center ">
          <FeatherIcon className="confirm-delete" icon="trash-2" />
          <span className="please-confirm fw-bold ms-3">
            Delete Requirement for {formatMonthYeartime(monthYear).toString()} ?
          </span>
        </div>
      ),

      message: (
        <span className="require-msg ms-5">
          {deleteRequirementAll.length > 0 ? "This will delete all the Selected requirement from the project" : "This will delete  the Selected requirement from the project"}

        </span>
      ),
      buttons: [
        {
          label: (
            <span
              className="require-cancel-btn bg-white"
              onClick={() => {
                setdeleteRequirementAll([]);
                setflag(false);
                setSelectedRowKeys([]);
              }}
            >
              Cancel
            </span>
          ),
        },
        {
          label: (
            <span className="require-raise-btn dot-drop-color delete require-delete ">
              Delete
            </span>
          ),
          onClick: () => {
            {
              id ? deleterequirementid(id)
                : deleteRequirementall()
              clearForm()
              setdeleteRequirementAll([])
            }


          },
        },
      ],
    });
  }

  function addRequirementInBulk() {
    var bodyFormData = new FormData();
    var input = document.getElementById("requirementFile");
    let file = input.files[0];
    let isImport = true;
    bodyFormData.append("ProjectId", projectID);
    bodyFormData.append("CreatedBy", localStorage.getItem("userId"));
    // if (
    //   document.querySelector("#textInputUrl").disabled == false &&
    //   isValidURL()
    // ) {
    //   bodyFormData.append("url", removeTrailingSlash(url));
    //   isImport = true;
    // } else
    if (document.querySelector("#requirementFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }

    if (isImport) {
      APIManager.postAPI(requirementBulkImport, {}, bodyFormData, false).then(
        (response) => {
          if (response?.data?.errors) {
            if (response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
              toast.error('Some Columns are Missing');
              return;
            }
          }
          else if (response && response.status == 200) {
            notifyAdd();
            onClose();
            setErrors("");
            setUrl("");
            requirementlist();
          }
        }
      );
    }
  }
  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  const getAllEditor = (id, reqId, wid) => {
    APIManager.getAPI(
      getEditor +
      "?organizationId=" +
      localStorage.getItem("organizationId") +
      "&requirementId=" +
      reqId
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        let obj = [];
        response.data.map((item) => {
          if (item.id != wid) {
            obj = {
              value: item.id,
              label: item.name,
              costPerWord: item.costPerWord,
              costPerArticle: item.costPerArticle,
              eta: item.eta,
            };
          }
          if (item.id == id) {
            setEditorListSelect(obj);
            setCost(item.isCostPerWord == null ? 0 : item.isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
            setIsCostPerWord(item.isCostPerWord);
            setETA(item.eta ?? new Date());
          }

          list.push(obj);
        });
        setEditorList(list);
      }
    });
  };

  const getAllWriter = (id, reqId) => {
    APIManager.getAPI(
      getWriter +
      "?organizationId=" +
      localStorage.getItem("organizationId") +
      "&requirementId=" +
      reqId
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
            costPerWord: item.costPerWord,
            costPerArticle: item.costPerArticle,
            eta: item.eta,
          };
          if (item.id == id) {
            setWriterListSelect(obj);
            setCost(item.isCostPerWord == null ? 0 : item.isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
            setIsCostPerWord(item.isCostPerWord);
            setETA(item.eta ?? new Date());
          }
          list.push(obj);
        });
        setWriterList(list);
      }
    });
  };

  function formValidation() {
    let error = { ...errors };
    eta?.trim() == ""
      ? (error["eta"] = "ETA is required!")
      : delete error["eta"];

    Cost == 0
      ? (error["cost"] = "Cost Number is required!")
      : !Cost.toString().match(/^[0-9.]+$/)
        ? (error["cost"] = "Cost Number is Number only!")
        : (/^\d{0,5}(\.\d{0,2})?$/i).test(Cost)
          ? delete error["cost"]
          : error["cost"] = "Number should have follow pattern i.e. 12345.23";

    if (editorId == '') {
      !writerId || writerId == ''
        ? (error["writer"] = "select Writer!")
        : delete error["writer"];
    }

    if (writerId == '') {
      editorId == '' || !editorId
        ? (error["editor"] = "select Editor!")
        : delete error["editor"];
    }


    let err = Object.keys(error);
    setErrors(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setWriterID("");
    setEditorID("");
    setWriterListSelect("");
    setEditorListSelect("");
    setCost("");
    setETA("");
    setErrors([]);
    setdeleteRequirementAll([]);
    setdeleteRequirementId([]);
    setflag(false);
  }

  function assignWriterData(rowdata) {
    APIManager.postAPI(AssignWriter, rowdata, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        onClose();
        // requirementStatusUpdate(1);
        requirementlist();
      }
    });
  }

  function assignEditorData(rowData) {
    APIManager.postAPI(AssignEditor, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        onClose();
        // requirementStatusUpdate(5);
        requirementlist();
      }
    });
  }

  const requirementStatusUpdate = (status) => {
    let rawData = {
      RequirementId: requirementId,
      status: status,
      userId: localStorage.getItem("userId"),
      isClient: 0,
    };
    APIManager.putAPI(
      updateRequirementStatus, rawData, {}, true
    ).then((res) => {
      if (res && res.status == 200) {
        requirementlist();
      }
    });
  };

  function deleterequirementid(requirementid) {
    APIManager.deteletItem(singledeleterequirememt + requirementid).then(
      (response) => {

        requirementlist();
        toast.success("Successfully Delete!");


      }
    );
  }

  function deleteRequirementall() {
    if (deleteRequirementId.length > 0) {
      APIManager.postAPI(
        mutipledeleterequirement,
        deleteRequirementId,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          requirementlist();
          toast.success("Successfully Delete!");
        }
      });
    } else {
      APIManager.postAPI(
        mutipledeleterequirement,
        deleteRequirementAll,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          requirementlist();
          toast.success("Successfully Delete!");
        }
      });
    }
  }
  return (
    <>

      {localStorage.getItem("projectDashboardCMS") ? <>
        <div class="portlet requirement-details bg-white cms_fs16 cms_fw500 cms_fc_black cms_line-height cms_border_gray  mb-3 p-3 mx-3">
          <div className="d-flex align-items-center justify-content-between flex-wrap">

            <div className="d-flex align-items-center justify-content-start redirect_require">
              {/* <div className="d-flex align-items-center justify-content-between"> */}
              <select
                class=" form-select cms_fs16 pe-5 "
                aria-label="Default select"
                value={projectSelected}
                onChange={(item) => {
                  setProjectSelected(item.target.value);
                  requirementlist(item.target.value)
                }}
              >
                {projectList?.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>

            <div className="d-flex align-items-center  justify-content-end require_cal_export cursor-pointer">
              <div className="d-flex align-items-center require-cal cms_radius6" ref={ref}>
                <FeatherIcon className="custome_feather ms-2 me-2" icon="calendar" />
                <div className="" onClick={() => setOpen(!open)}>
                  {monthYear}
                </div>
                {open && (
                  <div className="scope-calendar">
                    <div
                      id="example-collapse-text-calendar"
                      style={{ right: "0" }}
                    >
                      <Card body className="daterange-picker-card  mt-2">
                        <div className="d-flex justify-content-between">
                          <div className="calendar-col">
                            <Calendar
                              //date={new Date()}
                              maxDate={new Date()}
                              onlyMonthPicker
                              onChange={(e) => {
                                let date1 = e.format().split("/");
                                let daysInMonth = getDaysInMonth(
                                  date1[1],
                                  date1[0]
                                );
                                let dateObjArr = [
                                  new Date(date1[0] + "/01/" + date1[1]),
                                  new Date(
                                    date1[0] +
                                    "/" +
                                    daysInMonth +
                                    "/" +
                                    date1[1]
                                  ),
                                ];
                                setMonthYear(
                                  setMonths(dateObjArr[0].getMonth() + 1) +
                                  " " +
                                  date1[1]
                                );
                                setHead1(
                                  convertDate(
                                    dateObjArr[0].toLocaleDateString()
                                  )
                                );
                                setHead2(
                                  convertDate(
                                    dateObjArr[1].toLocaleDateString()
                                  )
                                );
                              }}
                            />
                          </div>
                          <div className="ms-3">
                            <button
                              className="btn-outline btn btn-primary"
                              onClick={() => {
                                setOpen(false);
                                requirementlist();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                )}
                <FeatherIcon className="custome_feather ms-2" icon="chevron-down" />
              </div>
              <div class=" work_export_btn ms-3">
                <button class="btn btn-primary bg-white require_export ">
                  <div class="d-flex align-items-center ">
                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FFFFFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-download"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                      </svg> */}
                    <FeatherIcon style={{ width: "15px" }} className="" icon="download" />
                    <span
                      class="ms-2 ps-1 pe-1"
                      onClick={() => {
                        exportToEXCEL(exportList, "Requirement");
                      }}
                    >
                      Export
                    </span>
                  </div>
                </button>
              </div>
            </div>
            {/* </div> */}
          </div></div>
      </>
        : <>
          <div
            className="portlet breadcum_top p-3 ps-4 mx-3"
            onClick={() => {
              navigate(-1);
            }}
          >
            {projectname}
          </div>
          <div class="portlet requierdetail_block requirement-details px-3 pb-3 pt-sm-3 pt-0 mx-3">
            <div className="d-flex align-items-center justify-content-between flex-wrap  flex-sm-noerap">

              <div className="d-flex align-items-center justify-content-start mt-3 mt-sm-0">
                <div
                  ref={ref}
                  className="d-flex align-items-center  requirement-details-cal cms_fw500 cms_fc_black cms_fs16 cms_line-height cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-calendar me-2"
                  >
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                  <div onClick={() => setOpen(!open)}>{monthYear}</div>
                  {open && (
                    <div className="scope-calendar">
                      <div id="example-collapse-text-calendar">
                        <Card body className="daterange-picker-card  mt-2">
                          <div className="d-flex justify-content-between">
                            <div className="calendar-col">
                              <Calendar
                                //date={new Date()}
                                maxDate={new Date()}
                                onlyMonthPicker
                                onChange={(e) => {
                                  var date1 = e.format().split("/");
                                  var daysInMonth = getDaysInMonth(
                                    date1[1],
                                    date1[0]
                                  );
                                  let dateObjArr = [
                                    new Date(date1[0] + "/01/" + date1[1]),
                                    new Date(
                                      date1[0] + "/" + daysInMonth + "/" + date1[1]
                                    ),
                                  ];
                                  setMonthYear(
                                    setMonths(dateObjArr[0].getMonth() + 1) +
                                    " " +
                                    date1[1]
                                  );
                                  setHead1(
                                    convertDate(dateObjArr[0].toLocaleDateString())
                                  );
                                  setHead2(
                                    convertDate(dateObjArr[1].toLocaleDateString())
                                  );
                                  delete param.startDate;
                                  delete param.endDate;
                                }}
                              />
                            </div>
                            <div className="ms-3">
                              <button
                                className="btn-outline btn btn-primary"
                                onClick={() => {
                                  setOpen(!open);
                                  requirementlist();
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  )}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-end raise-btn flex-wrap">

                {flag ? (
                  <>
                    <button
                      className="require-cancel bg-white p-2 me-3"
                      onClick={() => {
                        setdeleteRequirementAll([]);
                        setflag(false);
                        setSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="d-flex align-items-center justify-content-between require-delete p-2 "
                      onClick={() => {
                        if (
                          deleteRequirementAll.length > 0 ||
                          deleteRequirementId.length > 0
                        ) {
                          deleteConfirm();
                        }
                      }}
                    >
                      <FeatherIcon className="me-2" icon="trash-2" />
                      {deleteRequirementAll?.length == selectedRowsArray.length ? "Delete All" : "Delete"}
                    </button>
                  </>
                ) : (
                  <>

                    <a
                      class="btn btn-primary scope-download h-auto me-3 gray_btn fw-bold cms_fs16 cms_fc_black mt-3 mt-sm-0"
                      target="_blank"
                      href={GET_Server_URL + "audit/Requirement.xlsx"}
                    >
                      Download Template{" "}
                    </a>
                    <button
                      class="d-flex align-items-center btn-outline btn btn-primary cms_bg_blue mt-3 mt-sm-0"
                      type="button"
                      disabled={!isPOCAssigned}
                      onClick={() => {
                        setFileName("");
                        showLargeDrawer();
                        setUrl("");
                        setCloseButton(false);
                        const option1Btn = document.getElementById("option1");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke=" #FFFFFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus me-1 "
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                      <div>Raise Requirement</div>
                    </button>
                  </>
                )}

                <Drawer
                  title={"Raise Requirement for " + formatMonthYeartime(monthYear)}
                  className=" industries-modal add-industries freelancer-drawer"
                  placement="right"
                  size={size}
                  onClose={() => {
                    onClose();
                    setFileName("");
                    document.getElementById(
                      "requirementFile"
                    ).value = "";
                    // document.querySelector(
                    //   "#textInputUrl"
                    // ).disabled = false;
                  }}
                  open={open1}
                >
                  <div class="modal-dialog scope-modal" role="document">
                    <div class="modal-content h-100">
                      <div class="modal-body p-0">
                        <div className="portlet common-form-fields writer_assign_input-box border-0">
                          <div className="">
                            <div className="">
                              <div className="radio">
                                <div className="ms-3">
                                  <input
                                    className="me-3"
                                    type="radio"
                                    value="option1"
                                    id="option1"
                                    name="uploadType"
                                    defaultChecked
                                  />
                                  <label className="bulk-upload  main-title mb-1 active raise-bulk">
                                    Bulk Upload
                                  </label>
                                </div>
                                <div className="ms-5">
                                  <span className="text-grey ">
                                    {/* Setup multiple (bulk) upload with the details by
                                    uploading Google Sheet link/.xlsx/.csv file */}
                                    Setup multiple(bulk) upload with the details
                                    through XLSX file
                                  </span>
                                </div>
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box ">
                                  {/* <span className="ms-4 industries-csv-file">
                                    Add URL (Google Sheet Link)
                                  </span> */}
                                  {/* <div className="mt-2 ms-4 me-3">
                                    <input
                                      className="form-control w-100"
                                      placeholder="URL"
                                      id="textInputUrl"
                                      value={url}
                                      onChange={(e) => {
                                        setUrl(e.target.value);
                                        if (e.target.value.length > 0) {
                                          document.querySelector(
                                            "#requirementFile"
                                          ).disabled = true;
                                        } else {
                                          setErrors("");

                                          document.querySelector(
                                            "#requirementFile"
                                          ).disabled = false;
                                        }
                                      }}
                                    ></input>
                                    {save && errors?.url && (
                                      <>
                                        <div className="field-required mt-2">
                                          <span> {errors?.url}</span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="my-3 text-center or">
                                    <span>OR</span>
                                  </div> */}
                                  <span className="ms-4 industries-csv-file">
                                    Add Attachment (Only .xlsx or .csv file)
                                  </span>
                                </div>
                                <div className="industries-choose-file d-flex align-items-center justify-content-between p-2 mt-1 ms-4 me-3 ps-3 pe-0">
                                  <div className="btn-choosefile d-flex align-items-center">
                                    <input
                                      className="w-100"
                                      style={{ display: "none" }}
                                      type="file"
                                      id="requirementFile"
                                      name="requirementFile"
                                      multiple={false}
                                      accept={onlyXlsFile}
                                      ref={inputFileRef}
                                      onChange={() => {
                                        let input = inputFileRef.current;
                                        const fileExtension = input.files[0].name
                                          .split(".")
                                          .at(-1);
                                        let isvalid =
                                          isValidBulkUpload(fileExtension);
                                        if (isvalid === true) {
                                          uploadRequirementFile();
                                        } else {
                                          document.getElementById(
                                            "requirementFile"
                                          ).value = "";
                                        }
                                      }}
                                      onClick={() => {
                                        document.getElementById(
                                          "requirementFile"
                                        ).value = "";
                                      }}
                                    />
                                    <a
                                      onClick={onBtnClick}
                                      id="btnChooseFile"
                                      className="btn btn-primary me-3"
                                    >
                                      Choose File
                                    </a>
                                    <span className="file-not-select ">
                                      {fileName.length
                                        ? fileName
                                        : fileSetelctedMsg}
                                    </span>
                                  </div>
                                  {closeButton ? (
                                    <>
                                      <button
                                        type="button"
                                        title="Close"
                                        style={{
                                          border: "0",
                                          background: "none",
                                        }}
                                        class="close"
                                        onClick={() => {
                                          setCloseButton(false);
                                          setFileName("");
                                          document.getElementById(
                                            "requirementFile"
                                          ).value = "";
                                          // document.querySelector(
                                          //   "#textInputUrl"
                                          // ).disabled = false;
                                        }}
                                      >
                                        {" "}
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btn-box-bottom">
                            <div className="col-md-12  d-flex justify-content-end">
                              <div class="portlet-footer portlet-footer-bordered mt-3 text-end scopebtn">
                                <button
                                  className="btn btn-outline-secondary close me-3"
                                  type="button"
                                  onClick={() => {
                                    onClose();
                                    setFileName("");
                                    document.getElementById(
                                      "requirementFile"
                                    ).value = "";
                                    // document.querySelector(
                                    //   "#textInputUrl"
                                    // ).disabled = false;
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary w-auto"
                                  onClick={() => {
                                    if (
                                      url != "" ||
                                      document.getElementById("requirementFile")
                                        .value != ""
                                    ) {
                                      submitConfirm();
                                      onClose();
                                      isValidURL();
                                      document.querySelector(
                                        "#requirementFile"
                                      ).disabled = false;
                                    }
                                  }}
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke=" #FFFFFF"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-check me-1"
                                    >
                                      <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                    Raise Requirement
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </>}


      <div className="row mx-2">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6  require-details-card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white p-3 w-100">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-target"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="6"></circle>
                <circle cx="12" cy="12" r="2"></circle>
              </svg>
            </div>
            <div>
              <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Target for the Month</label>
              <h3 className="fw-bold cms_fc_black">{List}</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6  require-details-card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white p-3 w-100">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-trending-up"
              >
                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                <polyline points="17 6 23 6 23 12"></polyline>
              </svg>
            </div>
            <div>
              <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Requirement Raised </label>
              <h3 className="fw-bold cms_fc_black">{RequirementRaised}</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 require-details-card article-card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white p-3 w-100">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <div>
              <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Articles Completed </label>
              <h3 className="fw-bold cms_fc_black">{ArticlesCompleted}</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 ">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white p-3 w-100">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-repeat"
              >
                <polyline points="17 1 21 5 17 9"></polyline>
                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                <polyline points="7 23 3 19 7 15"></polyline>
                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
              </svg>
            </div>
            <div>
              <label class="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Rework </label>
              <h3 className="fw-bold cms_fc_black">{Rework}</h3>
            </div>
          </div>
        </div>
        {requirementData.length > 0 ? (
          <div class="col-md-12 mt-3  p-0">
            <Table
              className="table table-hover requirement-content-table custome_table "
              id="sample-module-expand"
              columns={RequirementColumns}
              dataSource={requirementData}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} keywords`,
              }}
              scroll={{ x: 100 }}
              onChange={onChangeFilter}
            />
            {/* Writer */}
            <Drawer
              title="Assign Writer"
              className=" industries-modal add-industries assign-writer-drawer"
              placement="right"
              size={size}
              onClose={onClose}
              open={writeropen}
            >
              <div
                class="modal-dialog scope-modal industries-form"
                role="document"
              >
                <div class="modal-content">
                  <div className="col-md-12  industries-ticket-btn">
                    <>
                      <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                        <button
                          className="btn btn-outline-secondary close me-3"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary w-auto"
                          onClick={() => {
                            setsave(true);
                            if (formValidation()) {
                              let rowdata;
                              rowdata = {
                                RequirementId: requirementId,
                                WriterId: writerId,
                                IsCostPerWord: isCostPerWord ? 1 : 0,
                                Cost: parseFloat(Cost),
                                ETA: eta,
                                CreatedBy: localStorage.getItem("userId"),
                                status: 1
                              };
                              assignWriterData(rowdata);
                              requirementlist();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke=" #FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-check me-1"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          Assign Writer
                        </button>
                      </div>
                    </>
                  </div>
                  <div class="modal-body p-0 drawer-box">
                    <div className="portlet common-form-fields writer_assign_input-box border-0">
                      <div className="px-0">
                        <div className="portlet-body industries-individual px-1">
                          <div class="col-md-12 writer-input writer-assign-input">
                            <label className="form-label mb-0">Writer</label>
                            <ReactSelect
                              className="write-box"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              isMulti={false}
                              // styles={customStyles}
                              styles={customStylesSearch}
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              allowSelectAll={true}
                              options={writerList}
                              value={writerListSelect}
                              onChange={(e) => {
                                setWriterListSelect(e);
                                writerList.map((item) => {
                                  if (e.value == item.value) {
                                    setCost(isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                                    setETA(item?.eta != null ? item.eta : "");
                                  }
                                });
                                setWriterID(e.value);
                              }}
                            ></ReactSelect>
                            <div className="field-required ms-3 mt-2">
                              <span>{errors?.writer}</span>
                            </div>
                          </div>
                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">Cost</label>
                            <input
                              className="w-100 cost-rupee"
                              type="number"
                              placeholder="Cost (in Rupees)"
                              value={Cost}
                              onChange={(e) => {
                                setCost(e.target.value);
                              }}
                            />
                            <button
                              className="cost-btn"
                              onClick={() => {
                                setIsCostPerWord(!isCostPerWord);
                                writerList.map((item) => {
                                  if (item.value == writerId) {
                                    setCost(!isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                                  }
                                });
                              }}
                            >
                              {!isCostPerWord ? "Per word" : "Per Article"}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down my-2 ms-1"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </button>
                          </div>
                          <div className="field-required ms-3 mt-2">
                            <span>{errors?.cost}</span>
                          </div>

                          <div class="col-md-12 mt-3 writer-eta">
                            <label className="form-label mb-0">ETA</label>
                            <input
                              className="w-100 ps-3 "
                              type="date"
                              name="eta"
                              value={writerId ? convertDate(eta) : ""}
                              min={mmDDyyyyFormateDateSet(new Date())}
                              // onBlur={() => {
                              //   formValidation();
                              // }}
                              onChange={(e) => {
                                setETA(e.target.value);
                                formValidation();
                              }}
                            />
                            {save && errors?.eta && (
                              <>
                                <div className="field-required ms-3 mt-2">
                                  <span>{errors?.eta}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>

            {/* editor */}
            <Drawer
              title="Assign Editor"
              className=" industries-modal add-industries assign-editor-drawer"
              placement="right"
              size={sizeEdit}
              onClose={onClose}
              open={editorOpen}
            >
              <div
                class="modal-dialog scope-modal industries-form"
                role="document"
              >
                <div class="modal-content">
                  <div className="col-md-12  industries-ticket-btn">
                    <>
                      <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                        <button
                          className="btn btn-outline-secondary close me-3"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary w-auto"
                          onClick={() => {
                            setsave(true);
                            if (formValidation()) {
                              let rowData;
                              rowData = {
                                RequirementId: requirementId,
                                EditorId: editorId,
                                Cost: parseFloat(Cost),
                                IsCostPerWord: isCostPerWord ? 1 : 0,
                                ETA: eta,
                                CreatedBy: localStorage.getItem("userId"),
                                status: 5
                              };
                              assignEditorData(rowData);
                              requirementlist();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke=" #FFFFFF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-check me-1"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                          Assign Editor
                        </button>
                      </div>
                    </>
                  </div>
                  <div class="modal-body p-0 drawer-box">
                    <div className="portlet common-form-fields writer_assign_input-box border-0">
                      <div className="px-0">
                        <div className="portlet-body industries-individual px-1">
                          <div class="col-md-12  writer-assign-input">
                            <label className="form-label mb-0">Editor</label>
                            <ReactSelect
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              isMulti={false}
                              styles={customStylesSearch}
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              allowSelectAll={false}
                              options={editorList}
                              value={editorListSelect}
                              onChange={(e) => {
                                setEditorListSelect(e);
                                // formValidation();
                                editorList.map((item) => {
                                  if (
                                    e.value == item.value
                                  ) {
                                    setCost(isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                                    setETA(item?.eta != null ? item.eta : "");
                                  }
                                  setEditorID(e.value);
                                });
                              }}
                            ></ReactSelect>
                            <div className="field-required ms-3 mt-2">
                              <span>{errors?.editor}</span>
                            </div>
                          </div>

                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">Cost</label>
                            <input
                              className="w-100 cost-rupee"
                              placeholder="Cost (in Rupees)"
                              type="number"
                              value={Cost}
                              onChange={(e) => {
                                setCost(e.target.value);
                              }}
                            />
                            <button
                              className="cost-btn"
                              onClick={() => {
                                setIsCostPerWord(!isCostPerWord);
                                editorList.map((item) => {
                                  if (item.value == editorId) {
                                    setCost(!isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                                  }
                                });
                              }}
                            >
                              {!isCostPerWord ? "Per word" : "Per Article"}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down my-2 ms-1"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </button>
                          </div>
                          <div className="field-required ms-3 mt-2">
                            <span>{errors?.cost}</span>
                          </div>

                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">ETA</label>
                            <input
                              className="w-100 "
                              type="date"
                              name="eta"
                              value={editorId ? convertDate(eta) : ""}
                              min={mmDDyyyyFormateDateSet(new Date())}
                              // onBlur={() => {
                              //   formValidation();
                              // }}
                              onChange={(e) => {
                                setETA(e.target.value);
                                formValidation();
                              }}
                            />
                            {save && errors?.eta && (
                              <>
                                <div className="field-required ms-3 mt-2">
                                  <span>{errors?.eta}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        ) : (
          <>
            <div className="text-center no-requirement-msg">
              <div>
                <span className="text-center cms_fs18 cms_fw600 cms_fc_dark-black">No Requirements Found </span>
              </div>
              <button
                class="btn btn-primary text-center bg-white mt-2"
                type="button"
                disabled={!isPOCAssigned}
                onClick={() => {
                  setFileSetelctedMsg("No File Selected");
                  setFileName("");
                  showLargeDrawer();
                  showLargeDrawerEdit();
                  setUrl("");
                  setCloseButton(false);
                  const option1Btn = document.getElementById("option1");
                }}
              >
                <div className="blue-plus-icon d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke=" #FFFFFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus me-1"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  <div>Raise Requirement</div>
                </div>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default RequirementDetails;
