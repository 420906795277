/** @jsxImportSource @emotion/react */
import { Button, Drawer, Space } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import Sidebar from "../shared/navigation/sidebar";
import * as styles from "./styles"
import toast from "react-hot-toast";
import TopNavBarCustom from "../TopNavBar";
import { exportToEXCEL, moduleIcons, routerLinks } from "../../utility/Globals";
import { ticketSidebarData } from "../../utils/sidebar";
import CustomButton from "../shared/button";
import FeatherIcon from 'feather-icons-react';
import { textStyles } from '../../styles/font';
import { TicketSvg } from "./ticketSvg";
import CustomTicketingButton from "./customButton2";
import { useTicketingStore } from "./ticketingStore"
import CreateTicket from "./createTicket";
import DropdownInput from "./customInput";
import SubTicket from "./subTicket";
import { getPrioritiesByOrg, getTicketTypesByOrg, postCreateMainTicket, updateMainTicket, updateSubTicket } from "../../services/ticketing";
import moment from "moment";
import { setLocal } from "../../utility/helpers";

const Ticketing = ({ routes }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // STORE STATES
    const storestates = useTicketingStore((state) => state)

    const drawerOpen = useTicketingStore((state) => state.drawerOpen)
    const setDrawerOpen = useTicketingStore((state) => state.setDrawerOpen)

    const ticket = useTicketingStore((state) => state.ticket)
    const setTicket = useTicketingStore((state) => state.setTicket)
    const ticketTypes = useTicketingStore((state) => state.ticketTypes)
    const setTicketTypes = useTicketingStore((state) => state.setTicketTypes)
    const setPriorityList = useTicketingStore((state) => state.setPriorityList)

    const ticketFormInit = useTicketingStore((state) => state.ticketFormInit)
    const ticketForm = useTicketingStore((state) => state.ticketForm)
    const setTicketForm = useTicketingStore((state) => state.setTicketForm)
    const formEdit = useTicketingStore((state) => state.formEdit)
    const setFormEdit = useTicketingStore((state) => state.setFormEdit)

    const ticketDetails = useTicketingStore((state) => state.ticketDetails)
    const setTicketDetails = useTicketingStore((state) => state.setTicketDetails)

    const ticketFormValidation = useTicketingStore((state) => state.ticketFormValidation)
    const setTicketFormValidation = useTicketingStore((state) => state.setTicketFormValidation)
    const checkFor = useTicketingStore((state) => state.checkFor)
    const setCheckFor = useTicketingStore((state) => state.setCheckFor)

    const tableData = useTicketingStore((state) => state.table)
    const setTableData = useTicketingStore((state) => state.setTableData)

    const tableData1 = useTicketingStore((state) => state.table1)

    const [searchInput, setSearchInput] = useState("")
    const [drawerDisabled, setDrawerDisabled] = useState(false)
    const [isSearchFocused, setIsSearchFocused] = useState(false)
    const [drawerActionLoading, setDrawerActionLoading] = useState(false)

    // NAVBAR STATES------------------------------
    const [view, setview] = useState(true);
    const [ticketsMenuList, setTicketsMenuList] = useState([
        {
            name: "Ticket Dashboard",
            link: routerLinks["Ticket Dashboard"]
                ? routerLinks["Ticket Dashboard"]
                : "",
            iconClass: moduleIcons["Ticket Dashboard"],
        }, {
            name: "Team Evaluation",
            link: routerLinks["Team Evaluation"]
                ? routerLinks["Team Evaluation"]
                : "",
            iconClass: moduleIcons["Team Evaluation"],
        },
        {
            name: "My Tickets",
            link: routerLinks["My Tickets"]
                ? routerLinks["My Tickets"]
                : "",
            iconClass: moduleIcons["My Tickets"],
        }, {
            name: "Team Tickets",
            link: routerLinks["Team Tickets"]
                ? routerLinks["Team Tickets"]
                : "",
            iconClass: moduleIcons["Team Tickets"],
        }, {
            name: "Create New Ticket",
            link: routerLinks["Create New Ticket"]
                ? routerLinks["Create New Ticket"]
                : "",
            iconClass: moduleIcons["Create New Ticket"],
        },
    ]);

    // SIDEBAR STATES & FUNCTIONS----------
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLocked, setIsLocked] = useState(true);
    const [sidebarList, setSideBarList] = useState(ticketSidebarData);
    const [isMobileSibeBarOpen, setMobileSibeBarOpen] = useState(false);
    const onSidebarItemClick = (index, subIndex) => {
        let indexOfCreateTicket = ticketSidebarData.data[0]?.subMenu?.map((data) => data.name).indexOf("Create New Ticket")
        indexOfCreateTicket == subIndex ? openDrawer("createTicket") : onDrawerClose()
        // setLocal("activeSitebarSubOption", index);
        let initialType = ticketTypes?.filter((type) => type?.label == "Universal")[0]
        setTicketForm({ ...storestates?.initialForm, type: initialType?.label, typeId: initialType?.value })
        setFormEdit(false)
        setSideBarList((prev) => {
            return { ...prev, active: index }
        })
    }

    const closeSideBar = () => {
        setMobileSibeBarOpen(false)
    }

    const openSideBar = () => {
        setIsCollapsed(false)
        setIsLocked(true)
        setMobileSibeBarOpen(true)
    }


    // TICKETING BREADCRUMBS-----------------
    const breadCrumbsComponent = () => {
        let currentRoute = location.pathname?.split("/")[2]
        let second = currentRoute?.split('-')?.join(' ')
        return (
            <div css={styles.breadCrumbs}>
                <div style={{ cursor: "default" }}>Tickets</div>
                {second &&
                    <div css={styles.breadCrumbs}>
                        <div style={{ margin: "0 0.5rem" }}>
                            <FeatherIcon icon={"chevron-right"} size={16} />
                        </div>
                        <Link to={`/tickets/${location.pathname?.split('/')[2]}`}>
                            <div onClick={() => {
                                setTicket({ ticketId: "", parentId: "" })
                                setTicketForm({ ...storestates?.initialForm })
                            }} className="text-capitalize" css={styles.breadCrumb}>{second}</div>
                        </Link>
                    </div>
                }
                {/* {ticket.parentId?.length > 0 &&
                    <div css={styles.breadCrumbs}>
                        <div style={{ margin: "0 0.5rem" }}>
                            <FeatherIcon icon={"chevron-right"} size={16} />
                        </div>
                        <Link to={`/tickets/${location.pathname?.split('/')[2]}/ticket-details?${ticket?.parentId}`}>
                            <div
                                className="text-capitalize" css={styles.breadCrumb}
                                onClick={() => {
                                    setTicket({
                                        ticketId: ticket?.parentId,
                                        parentId: null,
                                        isSubTicket: false
                                    })
                                }}
                            >
                                {ticket?.parentId}
                            </div>
                        </Link>
                    </div>
                } */}
                {/* {ticketDetails?.isSubTicket &&
                    <div css={styles.breadCrumbs}>
                        <div style={{ margin: "0 0.5rem" }}>
                            <FeatherIcon icon={"chevron-right"} size={16} />
                        </div>
                        <div className="text-capitalize" css={styles.breadCrumb}>{"Sub Ticket"}</div>
                    </div>
                } */}
            </div>
        )
    }

    useEffect(() => {
        breadCrumbsComponent()
    }, [location])

    // API 

    const getTicketTypes = () => {
        getTicketTypesByOrg(localStorage.getItem("organizationId")).then((response) => {
            if (response?.status == "success") {
                let typeData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id, id: data?.id }
                })
                setTicketTypes(typeData)
                let initialType = typeData?.filter((type) => type.label == "Universal")[0]
                setTicketForm({ ...ticketForm, typeId: initialType?.value, type: initialType?.label })
                setCheckFor(initialType?.label)
            }
        })
    }

    const getPriorities = () => {
        getPrioritiesByOrg(localStorage.getItem("organizationId")).then((response) => {
            if (response?.status == "success") {
                let priorityData = response?.data?.map((data) => {
                    return { label: data?.name, value: data?.id }
                })
                setPriorityList(priorityData)
            }
        })
    }

    useEffect(() => {
        // get api call
        getTicketTypes()
        getPriorities()
    }, [])

    const createMainTicket = () => {
        setDrawerActionLoading(true)
        let body = {
            // organization_id: "7C20C6AE-0426-4D4A-935A-0A17CDC36311",
            organization_id: localStorage.getItem("organizationId"),
            client_id: ticketForm?.clientId ?? "",
            project_id: ticketForm?.projectId ?? "",
            department_id: ticketForm?.departmentId ?? "",
            assigned_to: ticketForm?.memberId ?? "",
            ticket_type: ticketForm?.typeId,
            description: ticketForm?.description,
            subject: ticketForm?.subject,
            eta: ticketForm?.eta,
            ticket_priority: ticketForm?.priorityId,
            created_by: localStorage.getItem("userId")
        }
        ticketingValidation() && postCreateMainTicket(body)?.then((response) => {
            if (response.status == "success") {
                setDrawerActionLoading(false)
                onDrawerClose("ticketCreated")
                toast.success("Ticket Created Successfully.")
            }
        }).catch((error) => {
            setDrawerActionLoading(false)
            if (error.response.data.status == "error") {
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error("Failed to Create Ticket");
                }
            }
        });
    }

    const updateApi = (isSubTicket, body) => {
        if (isSubTicket) {
            return updateSubTicket(localStorage?.getItem("userId"), body)
        } else {
            return updateMainTicket(localStorage?.getItem("userId"), body)
        }
    }

    const updateTicket = () => {
        let isSubTicket = ticketDetails?.isSubTicket
        let body = {
            ...(isSubTicket ? { subticket_id: ticketDetails?.mainDetails?.id } : { mainticket_id: ticketDetails?.mainDetails?.id }),
            ...(ticketFormInit?.priorityId !== ticketForm?.priorityId && { ticket_priority: ticketForm?.priorityId }),
            ...(ticketFormInit?.memberId !== ticketForm?.memberId && { assigned_to: ticketForm?.memberId }),
            ...(ticketFormInit?.eta !== ticketForm?.eta && { eta: moment(ticketForm?.eta).format("YYYY-MM-DD") })
        }
        updateApi(isSubTicket, body)?.then((response) => {
            if (response.status == "success") {
                onDrawerClose()
                const updatedTicket = new CustomEvent('updatedTicket');
                window.dispatchEvent(updatedTicket);
                toast.success("Ticket Updated Successfully")
            }
        })?.catch((error) => {
            if (error.response.data.status == "error") {
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error("Failed to Update Ticket");
                }
            }
        })
    }



    // FUNCTIONS

    const openDrawer = (drawerName) => {
        setDrawerOpen({ open: true, drawer: drawerName })
    }

    const onDrawerClose = (action) => {
        setDrawerOpen({ ...drawerOpen, open: false, action: action })

        let sideIndex = ticketSidebarData?.data[0]?.subMenu?.map((data) => data?.link).indexOf(location?.pathname)
        setLocal("activeSitebarSubOption", sideIndex);

        formEdit ? setTicketForm(ticketForm) : setTicketForm(storestates?.initialForm)
        setCheckFor()
        setTicketFormValidation({})
    }

    function _handleKeyDown(e) {
        // let data = tableData?.isFiltered ? tableData : tableData1

        if (e.key === "Enter") {
            e.preventDefault();
            let list = tableData1?.data?.filter((ticket) =>
                ticket.subject
                    .toLocaleLowerCase()
                    .includes(
                        searchInput.toLocaleLowerCase()
                    )
            )
            setTableData({ ...tableData, data: list, length: list?.length });
        }
    }


    function getTimeParts(timeString) {
        let hours = 0;
        let minutes = 0;

        const regex = /(\d+)\s*hrs?\s*(\d+)?\s*mins?/;
        const matches = timeString.match(regex);

        if (matches) {
            if (matches[1]) {
                hours = parseInt(matches[1]);
            }
            if (matches[3]) {
                minutes = parseInt(matches[3]);
            }
            if (!matches[2] && matches[4]) {
                hours = (minutes / 60).toFixed(2); // Convert minutes to hours with 2 digits after the decimal point
                minutes = 0; // Reset minutes to 0
            }
        }

        return { hours, minutes };
    }

    function getTime(timeString) {
        let hours = 0;
        let minutes = 0;

        let totalHours;
        let totalMinutes

        const regex = /(\d+)\s*(hrs?)?\s*(\d+)?\s*(mins?)?/;
        const matches = timeString.match(regex);

        if (matches) {
            if (matches[1]) {
                if (matches[2]) {
                    hours = parseInt(matches[1]);
                }
                else {
                    minutes = parseInt(matches[1]);
                }
            }
            if (matches[3]) {
                if (matches[4]) {
                    minutes = parseInt(matches[3]);
                }
                else {
                    hours = parseInt(matches[3]);
                }
            }
        }
        if (hours === 0 && minutes > 0) {
            hours = minutes / 60;
            minutes = minutes;
            totalHours = hours;
            totalMinutes = minutes;
        } else {
            totalHours = hours + (minutes / 60);
            totalMinutes = (hours * 60) + minutes;
        }

        return {
            hours: Number.isInteger(Number(totalHours)) ? totalHours : totalHours.toFixed(2),
            minutes: totalMinutes
        };
    }

    const handleTimeSheetDownload = () => {
        let exportTableData = []
        if (storestates.table.isFiltered) {
            exportTableData = storestates.table.currentData
        } else {
            exportTableData = storestates.table.data
        }
        let data = []
        exportTableData?.forEach((item) => {
            let obj = {
                Category: item?.ticketType == "mainticket" ? "Main Ticket" : "Sub Ticket",
                Subject: item.subject,
                Description: item.description,
                Type: item.type,
                Client: item.client,
                Project: item.projects,
                ETA: item.eta,
                priority: item.priority,
                ["Assigned To"]: item.assignedTo ?? "",
                ["Total Hours"]: getTime(item?.timeSpent)?.hours,
                ["Total Minutes"]: getTime(item?.timeSpent)?.minutes,
                // ["Time Spent"]: item?.timeSpent,
                ["Created By"]: item?.createdBy,
                ["Created On"]: item.createdOn,
                State: item.state,
                Status: item.status,
            }
            data.push(obj)
        })
        exportToEXCEL(data, "TimeSheet")
    }

    const checkForChange = () => {
        let checks = ["eta", "priority", "priorityId", "member", "memberId"]
        let disabled = false
        if (!formEdit) {
            disabled = false
        } else {
            let data = checks?.map((check) => {
                return ticketForm[check] == ticketFormInit[check]
            })
            disabled = !data.includes(false)
        }
        setDrawerDisabled(disabled)
    }

    useEffect(() => {
        checkForChange()
    }, [ticketForm?.priorityId, ticketForm?.departmentId, ticketForm?.memberId, ticketForm?.eta])


    const ticketingValidation = () => {
        let validForm = true
        let validation = {
            type: null,
            subject: null,
            eta: null,
            priority: null,
            client: null,
            project: null,
            description: null
        }
        if (checkFor?.includes("type")) {
            if (!ticketForm?.typeId?.trim()?.length) {
                validForm = false
                validation["type"] = "Type is Required."
            }
        }
        if (checkFor?.includes("subject")) {
            if (!ticketForm?.subject?.trim()?.length) {
                validForm = false
                validation["subject"] = "Subject is Required."
            } else if (ticketForm?.subject?.trim()?.length < 5 || ticketForm?.subject?.trim()?.length > 60) {
                validForm = false
                validation["subject"] = "Subject must be Between 5 to 60 characters."
            }
        }
        if (ticketForm?.description?.trim()?.length && (ticketForm?.description?.trim()?.length < 5 || ticketForm?.description?.trim()?.length > 500)) {
            validForm = false
            validation["description"] = "Description must be Between 5 to 500 characters."
        }
        if (checkFor?.includes("priority")) {
            if (!ticketForm?.priorityId?.trim()?.length) {
                validForm = false
                validation["priority"] = "Priority is Required."
            }
        }
        if (checkFor?.includes("eta")) {
            if (!ticketForm?.eta?.trim()?.length) {
                validForm = false
                validation["eta"] = "ETA is Required."
            }
        }
        if (checkFor?.includes("client")) {
            if (!ticketForm?.clientId?.trim()?.length) {
                validForm = false
                validation["client"] = "Client is Required."
            }
        }
        if (checkFor?.includes("project")) {
            if (!ticketForm?.projectId?.trim()?.length) {
                validForm = false
                validation["project"] = "Project is Required."
            }
        }

        setDrawerActionLoading(validForm)
        setTicketFormValidation(validation)
        return validForm;
    }


    return (
        <>
            <div className="home asidebar_main">
                <Sidebar
                    {...{ sidebarList, setSideBarList, isCollapsed, setIsCollapsed, isLocked, setIsLocked, isMobileSibeBarOpen }}
                    activeTab={sidebarList.active}
                    onTabClick={onSidebarItemClick}
                    closeSideBar={closeSideBar}
                    defaultSublistExpanded={true}
                />
                <div className="holder">
                    <div className="wrapper" css={styles.wrapper(isCollapsed, isLocked)}>
                        <TopNavBarCustom
                            hideBugButton={true}
                            view={view}
                            routing={ticketsMenuList} openSideBar={openSideBar}>
                            {<span css={styles.breadCrumbsContainer(isCollapsed, isLocked)}>{breadCrumbsComponent()}</span>}
                        </TopNavBarCustom>

                        {!location?.pathname?.split("/")[3]?.length &&
                            <div css={styles.ticketActionsContainer} >
                                <div className="shadow-sm" css={styles.searchBox(isSearchFocused)}>
                                    <input
                                        type="text"
                                        class="w-100"
                                        css={styles.searchInput}
                                        onFocus={() => setIsSearchFocused(true)}
                                        onBlur={() => setIsSearchFocused(false)}
                                        placeholder="Search"
                                        name="search"
                                        // value={searchText}
                                        onKeyDown={(e) => {
                                            _handleKeyDown(e);
                                        }}
                                        onChange={(ticketSearch) => {
                                            // let dataLength = tableData?.isFiltered ? tableData?.currentData : tableData?.data
                                            // let data = tableData
                                            setSearchInput(ticketSearch.target.value)
                                            let list = tableData1?.data?.filter((ticket) =>
                                                ticket.subject
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                        ticketSearch.target.value.toLocaleLowerCase()
                                                    )
                                            )
                                            setTableData({ ...tableData, data: list, search: ticketSearch.target.value });
                                            // setTicketLength(list.length);
                                        }}
                                    />
                                    <FeatherIcon icon="search" size={18} />
                                </div>
                                <div css={styles.secondaryButton}>
                                    <CustomButton
                                        size={"l"} use={"tertiary"} leftIcon={"download"}
                                        text={location?.pathname?.split("/")[2] == "my-tickets" ? "Download My Time Sheet" : "Download Time Sheet"}
                                        isDisable={false}
                                        onPress={handleTimeSheetDownload}
                                    />
                                </div>
                                <div css={styles.createButton} className="me-2">
                                    <CustomButton onPress={() => {
                                        setFormEdit(false)
                                        setTicketForm({ ...storestates?.initialForm })
                                        getTicketTypes()
                                        openDrawer("createTicket")
                                    }} size={"l"} use={"primary"} leftIcon={"plus"} text={"Create Ticket"} />
                                </div>

                            </div>
                        }

                        <div className={`content ${true ? 'height-hiddenn overflow-hiddenn' : ''}`} css={{ paddingTop: location?.pathname?.split("/")[3]?.length ? "6.28571rem" : "1rem 0" }}>
                            <div className="container-fluid">
                                <Routes>
                                    {routes.map((route, i) => (
                                        <Route path={route.path} element={
                                            <route.component routes={route.children} />} key={i} />
                                    ))}
                                </Routes>
                            </div>
                        </div>

                        {
                            <Drawer
                                title={
                                    <span css={{ ...textStyles.h6 }}>{
                                        `${drawerOpen?.drawer == "createTicket" ?
                                            formEdit ?
                                                ticketDetails?.isSubTicket ? "Edit Subticket" : "Edit Ticket"
                                                : "Create Ticket"
                                            : "Create Subticket"}`
                                    }</span>
                                }
                                placement={"right"}
                                width={"60rem"}
                                // size="large"
                                headerStyle={{
                                    ...textStyles.h6
                                }}
                                onClose={onDrawerClose}
                                open={drawerOpen.open}
                                closable={false}
                                extra={<div css={{ cursor: "pointer" }} onClick={() => onDrawerClose()}>
                                    <FeatherIcon icon="x" />
                                </div>}
                                footer={
                                    drawerOpen?.drawer == "createTicket" ?
                                        <div className="d-flex flex-row-reverse">
                                            {formEdit ?
                                                <CustomButton text={"Edit Ticket"} onPress={() => { updateTicket() }} use={"primary"} leftIcon={"edit"} />
                                                :
                                                <button className="btn btn-primary" css={styles.ticketButtons({ maxWidth: "10rem" })}
                                                    onClick={() => !drawerActionLoading && createMainTicket()}
                                                >
                                                    <TicketSvg />
                                                    <div style={{ width: "100%" }}>{formEdit ? "Edit" : `Create Ticket`}</div>
                                                </button>}
                                            <div css={styles.secondaryButton}>
                                                <CustomButton onPress={() => onDrawerClose()} use={"tertiary"} text={"Cancel"} />
                                            </div>
                                            {/* <CustomTicketingButton
                                            className={"me-3"}
                                            buttonName={"Something"}
                                            featherIcon={"download"}
                                        /> */}
                                        </div>
                                        : false
                                }
                            >
                                {drawerOpen.open ?
                                    drawerOpen.drawer == "createTicket" ?
                                        <CreateTicket />
                                        :
                                        <SubTicket />
                                    : <></>
                                }
                            </Drawer>}

                    </div>
                </div >
            </div >
        </>
    )
}

export default Ticketing;