import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  padding: "1rem 0",
  minHeight: "53.8px",
  ".ant-table table ": {
    borderSpacing: "0 0.125rem",
  },
  ".ant-table-content .ant-table-thead": {
    border: "1px solid black",
  },
  ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before":
    {
      display: "none",
    },
  ".ant-table-thead>tr>th": {
    backgroundColor: `${Colors.primary[75]} !important`,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-container table > thead > tr:first-child th:first-child": {
    borderTopLeftRadius: "0.375rem",
    borderBottomLeftRadius: "0.375rem",
    borderLeft: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-container table > thead > tr:first-child th:last-child": {
    borderTopRightRadius: "0.375rem",
    borderBottomRightRadius: "0.375rem",
    borderRight: `1px solid ${Colors.neutral[50]}`,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
  },
  "ul.configuration-tab-list li:hover a, td:first-child.ant-table-cell div": {
    color: `${Colors.neutral[900]} !important`,
  },
  ".ant-table-container table > tbody > tr td:first-child": {
    borderTopLeftRadius: "0.375rem",
    borderBottomLeftRadius: "0.375rem",
    borderLeft: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-container table > tbody > tr td:last-child": {
    borderTopRightRadius: "0.375rem",
    borderBottomRightRadius: "0.375rem",
    borderRight: `1px solid ${Colors.neutral[50]}`,
  },
  ".ant-table-cell": {
    padding: "1.12rem 1.5rem !important",
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
    // textAlign: "center"
  },
  ".first-row .ant-table-cell div" : {
    color: `${Colors.neutral[900]} !important`,
  },
  ".second-row .ant-table-cell div" : {
    color: `${Colors.neutral[900]} !important`,
  }
});

export const title = css({
  ...textStyles.s1,
  color: Colors.black,
});

export const cell = (isPositive, isZero, isCompare) =>
  css({
    ...textStyles.b1,
    color: !isCompare ? "" : isZero
      ? Colors.secondary[500]
      : isPositive
      ? Colors.semantic[500]
      : Colors.error[800],
  });
