import { useEffect, useState } from "react";
import { addSOWActivity, updateSOWActivity } from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const AddUpdateSOWActivitiy = ({ update }) => {
  const [category, setCategory] = useState("OffPage")
  const [activity, setActivity] = useState("")
  const [error, setError] = useState("")
  const location = useLocation();

  useEffect(() => {
    if (location.state.isEdit) {
      setCategory(location.state.item.categories);
      setActivity(location.state.item.activities);
    }
  }, []);

  function addActivity() {
    APIManager.postAPI(
      addSOWActivity,
      {
        Categories: category,
        Activities: activity.trim()
      },
      null,
      true
    ).then((response) => {
        if (response?.data && response?.data?.isSuccessful == false) {
          toast.error(response?.data?.errors[0]?.errorMessage);
          return;
        }
        else if (response.status === 200) {
        update("activityList");
      }
    });
  }

  function updateActivity() {
    APIManager.putAPI(
      updateSOWActivity,
      {
        Categories: category,
        Activities: activity.trim(),
        id: location.state.item.id,
      },
      null,
      true
    ).then((response) => {
      if (response?.data && response?.data?.isSuccessful == false) {
        toast.error(response?.data?.errors[0]?.errorMessage);
        return;
      }
      else if (response.status === 200) {
      update("activityList");
    }
    });
  }

  function formRequiredValidation() {
    let isValidFields = true;
    if (activity.trim() == "") {
      isValidFields = false;
      setError("Activity is required!");
    } else {
      setError("");
    }
    return isValidFields;
  }

  return (
    <>
      <div className="row common-mb-26">
        <div class="dis-none align-items-center  p-0">
          <div class="portlet  slideInUp setting-top">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => {
                update("activityList");
              }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {localStorage.getItem("organizationName")} -{" "}
                {location.state.isEdit ? "Update" : "Add"} SOW Activities
              </h3>
            </div>
            <div className="portlet-body">
              <div class="col-md-12 mt-3">
                <div>
                  <label className="form-label">Category</label>
                  <select
                    className="w-100"
                    style={{ height: 40 + "px" }}
                    required
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="OffPage">Off Page</option>
                    <option value="Technical">Technical</option>
                    <option value="OnPage">On Page</option>
                    <option value="Reporting">Reporting</option>
                  </select>

                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="form-label">Activity</label>
                <label class="field-required"> * </label>

                <input
                  className="form-control w-100"
                  type="text"
                  value={activity}
                  onChange={(e) => {
                    setActivity(e.target.value);
                  }}
                  placeholder="Enter Activity"
                ></input>
                <div className="field-required">
                  <span> {error}</span>
                </div>
              </div>
            </div>

            <div className="portlet-footer portlet-footer-bordered mt-3">
              <div className="col-md-12">
                <button
                  onClick={() => {
                    if (formRequiredValidation()) {
                      location.state.isEdit ? updateActivity() : addActivity();
                    }
                  }}
                  className="btn btn-primary me-3"
                >
                  {location.state.isEdit ? "Update" : "Save"}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    update("activityList");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
export default AddUpdateSOWActivitiy;