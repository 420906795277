import { useEffect } from "react";
import APIManager from "../services/APIManager";
import { getCampaignStatus } from "../utility/Constant";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


function CampaignStatus() {
    const [status, setStatus] = useState()
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let rankingCampaignConfigurationId = params.get("rankingCampaignConfigurationId")
        let accept =  params.get("accept");
        let userId = params.get("userId");

        // let rankingCampaignConfigurationId = "21D9D53A-1728-4076-9E35-C590609388E1"
        // let accept = true;
        // let userId = "d6b80992-911a-4678-cb9c-08da711b181f"

        if (localStorage.length === 0) {
            navigate("/login" +
                "?rankingCampaignConfigurationId=" + rankingCampaignConfigurationId +
                "&accept=" + accept + "&userId=" + userId);
        }
        else {
            if (localStorage.getItem("loggedInUserId") === userId) {
                getCampaignStatusFun(rankingCampaignConfigurationId, accept, userId)
            }
            else {
                navigate("/show-permission" + "?rankingCampaignConfigurationId=" + rankingCampaignConfigurationId +
                    "&accept=" + accept + "&userId=" + userId)
            }
        }
    }, []);

    function getCampaignStatusFun(rankingCampaignConfigurationId, accept, userId) {
        APIManager.getAPI(getCampaignStatus + rankingCampaignConfigurationId + "&accept=" + accept + "&userId=" + userId).then(
            (response) => {
                if (response && response.status == 200) {
                    setStatus(response.data.status)
                }
            }
        );
    }
    return (
        <>
            <div class="message text-center" style={{ top: "50%", left: "50%" }}>
                {status === 1 ?
                    (<h4>
                        Campaign is Accepted
                    </h4>)
                    :
                    (<h4>
                        Campaign is Rejected
                    </h4>)
                }
            </div>
        </>
    );
}

export default CampaignStatus;