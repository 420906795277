import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  padding: "0 1.5rem",
});

export const header = css({
  ...textStyles.h6,
  color: Colors.black,
  padding: "1.03rem 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems : "center",
  borderBottom: `1px solid ${Colors.neutral[300]}`
});

export const switchContainer = css({
  display: "flex", 
  border: `1px solid ${Colors.black}`,
  borderRadius: "0.375rem",
  padding: "0.25rem"
});

export const buttonGraph = (isGraphView) => {
  return css({
    display: "flex",
    alignItems: "center",
    padding: "0.24rem 1.42rem",
    backgroundColor: isGraphView ? Colors.primary[500] : "",
    borderRadius: "0.375rem",
    cursor: "pointer"
  });
};

export const buttonTable = (isGraphView) => {
  return css({
    padding: "0.24rem 1.42rem",
    backgroundColor: isGraphView ? "" : Colors.primary[500],
    borderRadius: "0.375rem",
    cursor: "pointer"
  });
};

export const icon = (isGraphView) => {
  return css({
    width: "1.27005rem",
    height: "1.60169rem",
  });
};

export const keywordTable = css({
  
})

export const empty = css({
  height: "30rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...textStyles.h5,
})

export const comparisionCardcontainer = css({
  padding: "1.5rem"
})

export const errorScreen = css({
  minHeight : "313px"
})
