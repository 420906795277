import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  getOrganizations,
  updateOrganizations,
  deleteOrganizations,
  orgnizationIsActive,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { createOrganizations } from "../../utility/Constant";
import { Sorter } from "../../common/Sorter";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";

const Organization = () => {
  const navigate = useNavigate();
  const [organizationCol, setorganizationCol] = useState([]);
  const [OrganizationList, setOrganizationList] = useState([]);
  const [isUpdateOrg, setisUpdateOrg] = useState(false);
  const [OrgnizationName, setOrgnizationName] = useState("");
  const [OrgnizationNameCopy, setOrgnizationNameCopy] = useState("");
  const [message, setMessage] = useState("");
  const [isAccActive, setIsActiveAcc] = useState(0);
  const [orgId, setOrgId] = useState("");
  const [userOrg, setuserOrg] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const notifyDelete = () => toast.success("Successfully Deleted!");

  useEffect(() => {
    var data = [
      {
        title: "Organization Name",
        dataIndex: "organizationname",
        width: "80%",
        key: "organizationname",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.organizationname, b.organizationname),
          multiple: 3,
        },
      },
      {
        title: "Activate / Deactivate",
        dataIndex: "actde",
        key: "actde",
        width: "10%",
      },

      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
      },
    ];
    setorganizationCol(data);
  }, []);
  useEffect(() => {
    getOrganizationsList();
  }, []);

  function submit() {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOrgnizationById();
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function getOrganizationsList() {
    setLoading(true);
    APIManager.getAPI(getOrganizations).then((response) => {
      if (response && response.status == 200) {
        setLoading(false);
        const list = [];
        response.data.map((item, index) => {
          let obj = {
            value: item.organizationName,
            organizationname: item.organizationName,
            id: item.id,
            status: item.status,
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.status}
                  onClick={() => {
                    updateStatus(item.id);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            action: (
              <div>
                <i
                  onClick={() => {
                    submit(item);
                    deleteOrgnizationById(item.id);
                  }}
                  class="fa fa-trash me-3"
                  style={{ color: "#2196f3" }}
                ></i>
                <Link>
                  <i
                    onClick={() => {
                      setisUpdateOrg(true);
                      setIsActiveAcc(item.status);
                      setOrgId(item.id);
                      setOrgnizationName(item.organizationName);
                      setOrgnizationNameCopy(item.organizationName);
                      setMessage("");
                      var e = document.getElementById("input-s");
                      e.scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                        inline: "center",
                      });
                    }}
                    class="fa fa-edit me-5"
                    style={{ marginRight: 24 + "px", color: "#2196f3" }}
                  ></i>
                </Link>
              </div>
            ),
          };
          list.push(obj);
        });
        setOrganizationList(list);
        setuserOrg(list);
      }
    });
  }

  function updateStatus(id) {
    setLoading(true);
    APIManager.postAPI(
      orgnizationIsActive + "?organizationId=" + id,
      {},
      null,
      true
    ).then((response) => {
      setLoading(false);
      if (response.status == 200) {
        getOrganizationsList();
      }
    });
  }
  function clearData() {
    setisUpdateOrg(false);
    setOrgnizationName("");
    setOrgId("");
    getOrganizationsList();
  }

  function addOrganization() {
    APIManager.postAPI(
      createOrganizations,
      {
        OrganizationName: OrgnizationName.trim(),
        status: isAccActive,
      },
      null,
      true
    ).then((response) => {
      if (response && response?.status == 200) {
        clearData();
      }
    });
  }

  function updateOrganization() {
    APIManager.putAPI(
      updateOrganizations,
      {
        Id: orgId,
        organizationName: OrgnizationName.trim(),
        status: isAccActive,
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        setIsActiveAcc(0);
        clearData()
      }
    });
  }

  async function deleteOrgnizationById(id) {
    try {
      const response = await APIManager.deleteAPI(deleteOrganizations + id);
      if (response == 200) {
        setOrgnizationName("");
        getOrganizationsList();
        notifyDelete();
      }
    } catch (e) { }
  }

  return (
    <>
      <div className="row common-mb-26">
        <div class="dis-none p-0">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                navigate(-1)
              }}
            ></i>

          </div>
        </div>
      </div>

      <div className="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Organization</h3>
        </div>
        <div className="row">
          <div class="col-md-3  col-sm-12 search-box mt-3 d-flex employee-search">
            <div class="input-group-icon input-group-lg widget15-compact">
              <div class="input-group-prepend">
                <i class="fa fa-search text-primary"></i>
              </div>
              <Input allowClear
                type="text" class="form-control" placeholder="Type to search..."
                onChange={(nameSearch) => {
                  setuserOrg(
                    OrganizationList.filter((organization) =>
                      organization.organizationname
                        .toLocaleLowerCase()
                        .includes(
                          nameSearch.target.value.toLocaleLowerCase()
                        )
                    )
                  );
                }}
                id="input-s"
              />
            </div>
          </div>

          <div class="col-md-9 text-end mt-3 employee-tab">
            <input
              className="px-4"
              placeholder="Enter Organization Name"
              style={{ borderRight: 0, borderRadius: 0, width: 300 + "px" }}
              type="text"
              id="department-name"
              value={OrgnizationName}
              required
              onChange={(inputText) => {
                setOrgnizationName(inputText.target.value);
                setMessage("");
              }}
            />
            <button
              class="btn btn-primary"
              style={{ borderLeft: 0, borderRadius: 0, height: 36 + "px" }}
              onClick={() => {
                if (OrgnizationName.trim() != "") {
                  if (OrgnizationNameCopy.trim() == OrgnizationName.trim() && isUpdateOrg) {
                    setisUpdateOrg(false);
                    setOrgnizationName("");
                    setOrgId("");
                    return
                  }
                  let isEqualItem = false;
                  userOrg.map((i) => {
                    if (i.value == OrgnizationName.trim()) {
                      isEqualItem = true;
                    }
                  });
                  if (isEqualItem) {
                    setMessage("Organization already exists");
                  } else {
                    setMessage("");
                    if (isUpdateOrg) {
                      updateOrganization();
                    } else {
                      addOrganization();
                    }
                  }

                } else {
                  setMessage("Organization is required");
                }
              }}
            >
              {isUpdateOrg ? "Update" : "Add"}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 mt-3 organization-table">
            <Table
              className="table table-bordered table-hover mt-3"
              id="sample-module-expand"
              dataSource={userOrg}
              columns={organizationCol}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Organization;
