import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import Card from "react-bootstrap/Card";
import { ModuleExpandTickets } from "./index";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import {
  calanderDate,
  exportInToCSV,
  exportToEXCEL,
  // getCheckPermission,
  mmDDyyyyFormateDateSet,
  themeStyle,
} from "../utility/Globals";
import Chart from "react-apexcharts";
import APIManager from "../services/APIManager";
import {
  postKeywordResearch,
  getKeywordResearchChart,
  getAllCountry,
  getLanguage,
  getKeywordResearchConfig,
} from "../utility/Constant";
import { Sorter } from "../common/Sorter";

const CheckBox = (props) => {
  return (
    <li>
      <input
        key={props.id}
        onChange={props.handleCheckChieldElement}
        checked={props.isChecked}
        value={props.value}
      />{" "}
      {props.label}
    </li>
  );
};

function ModuleExpandKeywordResearch() {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="hidden"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      title: {
        text: "Day",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "Search Volume",
        style: {
          color: "#000",
        },
      },
    },
  });

  const [series, setSeries] = useState([]);
  const [open, setOpen] = useState(false);
  const [searched, setSearched] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [languageSelected, setLanguageSelected] = useState("English");
  const [languageList, setLanguageList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [teamcolHidden, setteamcolHidden] = useState([]);
  const [teamlistnew, setteamlistnew] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [chartdata, setchartdata] = useState([]);
  const [KeywordResearch, setKeywordResearch] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);

  useEffect(() => {
    getCountryList();
    getLanguageList();
    var data = [];

    setteamList(data);
    let getdata = {
      ProjectId: localStorage.getItem("projectGId"),
    };

    getKeywordData(getdata);
    const columnsHidden = [
      {
        dataIndex: "keyword",
      },
      {
        dataIndex: "volume",
      },
      {
        dataIndex: "competition",
      },
      {
        dataIndex: "bids",
      },
    ];
    setteamcolHidden(columnsHidden);
    const columns = [
      {
        title: "Keyword Ideas",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: (
          <div>
            {" "}
            Search Volume{" "}
            <i class="fa fa-info-circle info-msg ms-2" aria-hidden="true"></i>
            <div className="hover-msg">
              <span>message will apear here</span>
            </div>
          </div>
        ),
        dataIndex: "volume",
        key: "volume",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "Competition",
        dataIndex: "competition",
        key: "competition",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.competition, b.competition),
          multiple: 3,
        },
      },
      {
        title: "Top Page Bids",
        dataIndex: "bids",
        key: "bids",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.bids, b.bids),
          multiple: 3,
        },
      },
    ];
    setteamcol(columns);

    let getChartdata = {
      Keyword: "",
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      ProjectId: localStorage.getItem("projectGId"),
    };
    getKeywordChartData(getChartdata);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          keyword: item.keyword,
          volume: item.volume,
          competition: item.competition,
          bids: item.bids,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function getKeywordData(rowdata) {
    APIManager.postAPI(postKeywordResearch, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const exportList = [];
          let listData = [];
          let i = 0;
          response.data.map((item) => {
            exportList.push({
              key: (i += 1),
              keyword: item.keyword,
              volume: item.searchVolume,
              competition: item.competition,
              bids: item.topPaidBids,
            });
            listData.push({
              key: (i += 1),
              keyword: item.keyword,
              volume: item.searchVolume,
              competition: item.competition,
              bids: item.topPaidBids,
            });
          });

          setteamList(listData);
          setExportList(exportList);
        }
      }
    );
  }

  function getKeywordResearchData(list) {
    APIManager.getAPI(
      getKeywordResearchConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let keywordsname = [];
        response.data.keywordResearchConfigurationKeywordMappings.map(
          (item) => {
            keywordsname.push({
              key: item.keywords.id,
              id: item.keywords.id,
              label: item.keywords.fieldValue,
              value: item.keywords.fieldValue,
            });
            setKEYWORDList(keywordsname);
            setselectkeyword(keywordsname[0]);
          }
        );

        const cList = [];
        response.data.keywordResearchConfigurationLocationMappings.map(
          (item) => {
            list.map((data) => {
              if (item.locationId == data.id) {
                cList.push({
                  key: data.key,
                  id: data.id,
                  label: data.label,
                  value: data.value,
                });
              }
            });
          }
        );
        setCountrySelected(cList[0]);
      }
    });
  }
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open1, setOpen1] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open1 && ref.current && !ref.current.contains(e.target)) {
        setOpen1(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const [multicountryselectedList, setmulticountryselectedList] = useState([
    { Country: "", City: "" },
  ]);

  const [countryCityArray, setcountryCityArray] = useState([{ Country: "" }]);

  useEffect(() => {
    getCountryList();
    getKeywordResearchData();
  }, []);

  const handleCountryChange = (e, index) => {
    setcountry(e, index);
    const { name, value } = e.target;
    const list = [...multicountryselectedList];
    const optList = [...countryCityArray];
    list[index][name] = value;
    countryList.map((c) => {
      if (c.countryId == value) {
        optList[index]["City"] = c.cities;
      }
    });
    setcountryCityArray(optList);
    setmulticountryselectedList(list);
  };

  function setcountry(e, index) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  function getKeywordChartData(rowChartData) {
    APIManager.postAPI(getKeywordResearchChart, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          response.data.map((item) => {
            let graphData = [];
            graphData.push(item.months == "1" ? item.searchVolume : 0);
            graphData.push(item.months == "2" ? item.searchVolume : 0);
            graphData.push(item.months == "3" ? item.searchVolume : 0);
            graphData.push(item.months == "4" ? item.searchVolume : 0);
            graphData.push(item.months == "5" ? item.searchVolume : 0);
            graphData.push(item.months == "6" ? item.searchVolume : 0);
            graphData.push(item.months == "7" ? item.searchVolume : 0);
            graphData.push(item.months == "8" ? item.searchVolume : 0);
            graphData.push(item.months == "9" ? item.searchVolume : 0);
            graphData.push(item.months == "10" ? item.searchVolume : 0);
            graphData.push(item.months == "11" ? item.searchVolume : 0);
            graphData.push(item.months == "12" ? item.searchVolume : 0);

            chartdata.push({
              name: item.keyword,
              data: graphData,
            });
          });
          setObject(options);
          setSeries(chartdata);
        }
      }
    );
  }

  function getCountryList() {
    APIManager.getAPI(
      getAllCountry + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          if (item.locationCode) {
            list.push({
              key: item.locationCode,
              id: item.locationCode,
              value: item.countryName,
              label: item.countryName,
            });
          }
        });
        setCountryList(list);
        getKeywordResearchData(list);
      }
    });
  }

  const [keywordLIST, setKEYWORDList] = useState([]);
  const [selectkeyword, setselectkeyword] = useState("");

  function handlekeywordChange(selected) {
    setselectkeyword(selected);
  }

  function getLanguageList() {
    APIManager.getAPI(getLanguage).then((response) => {
      if (response && response.status == 200) {
        setLanguageList(response.data);
        response.data?.map((item) => {
          if (item.languageName == "English") {
            setLanguageSelected(item.languageCode);
          }
        });
      }
    });
  }
  return (
    <>
      <div className="row">
        <div ref={ref} class="calendar-main">
          <div className="add-new-btnw">
            <button
              className="btn btn-primary"
              style={{ width: "250px" }}
              onClick={() => setOpen1(!open1)}
            >
              {calanderDate(head1, head2)}
            </button>
          </div>

          {open1 && (
            <div id="example-collapse-text-calendar">
              <Card body className="daterange-picker-card  mt-2">
                <div className="d-flex justify-content-between">
                  <div className="calendar-col">
                    <Calendar
                      className="custom-calendar"
                      value={values}
                      onChange={(e) => {
                        setValues(e);
                        setValues(e);
                        setHead1(e[0].format());
                        setHead2(e[1]?.format());
                      }}
                      range
                      numberOfMonths={1}
                      showOtherDays
                    />
                  </div>
                  <div className="col-lg-4 mt-3 mt-lg-0 text-center">
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          onClick={() => {
                            setheadvalues();
                            setOpen1(!open1);
                            let getdata = {
                              ProjectId: localStorage.getItem("projectGId"),
                            };
                            getKeywordData(getdata);

                            let getChartdata = {
                              Keyword: "mobile website optimization services",
                              StartDate: mmDDyyyyFormateDateSet(head1),
                              EndDate: mmDDyyyyFormateDateSet(head2),
                              ProjectId: localStorage.getItem("projectGId"),
                            };
                            getKeywordChartData(getChartdata);
                            setRefreshTicketData(true);
                            setTimeout(() => {
                              setRefreshTicketData(false);
                            }, 1000);
                          }}
                          className="outline-btn"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <Tabs>
          <TabList className="tab-buttons mb-4 mt-5">
            <Tab
              className={
                KeywordResearch ? "btn btn-outline-primary" : "btn btn-primary"
              }
              onClick={() => {
                setKeywordResearch(false);
              }}
            >
              Keyword Research
            </Tab>
            <Tab
              className={
                KeywordResearch ? "btn btn-primary" : "btn btn-outline-primary"
              }
              onClick={() => {
                setKeywordResearch(true);
              }}
            >
              Tickets
            </Tab>
          </TabList>

          <TabPanel>
            <div className="col-md-12 mb-4 d-flex justify-content-end">
              <a className="bar-link-icon" style={{ color: "black" }}>
                <div class="dropdown">
                  <a
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      class="bar-img"
                      src="../../../images/white-filter-bar.svg"
                      alt=""
                    />
                  </a>
                  <form class="dropdown-menu p-0 m-0">
                    <div className="filter-popup">
                      <div className="col-lg-12 my-2">
                        <label className="common-label">Keyword</label>
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={keywordLIST}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={handlekeywordChange}
                          value={selectkeyword}
                          allowSelectAll={true}
                        />
                      </div>
                      <div className="col-lg-12 my-2">
                        <label className="common-label">Location</label>
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={countryList}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={handleCountryChange}
                          value={countrySelected}
                          allowSelectAll={true}
                        />
                      </div>
                      <div className="col-lg-12 my-2">
                        <label
                          className="common-label"
                          htmlFor=""
                          style={{ marginRight: 52 + "px" }}
                        >
                          Language
                        </label>
                        <div className="col-lg-12 p-0">
                          <select
                            className="w-200"
                            style={{ padding: 8 + "px" }}
                            required
                            value={languageSelected}
                            onChange={(e) => {
                              var c = e.target.value;

                              setLanguageSelected(c);
                            }}
                          >
                            {languageList?.map((item) => {
                              return (
                                <option value={item.languageCode}>
                                  {item.languageName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12 my-2">
                        <label
                          className="common-label"
                          htmlFor=""
                          style={{ marginRight: 52 + "px" }}
                        >
                          Data Source
                        </label>
                        <div>
                          <select className="common-height-30-padding w-100">
                            <option value="Google">Google</option>
                            <option value="Google Sheets">Google Sheets</option>
                          </select>
                        </div>
                        <div className="col-md-12 text-right mt-3">
                          <button
                            type="button"
                            className="btn btn-primary me-3"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </a>
            </div>
            <div className="row">
              <div className="col-10">
                <div>
                  {open ? (
                    <>
                      <div id="example-collapse-text">
                        <Card
                          body
                          className="keyword-card text-center mt-5"
                          style={{ width: "70%", minWidth: "250px" }}
                        >
                          <div className="row">
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2 common-label">
                                Metric Type
                              </label>
                              <select>
                                <option value="Keyword">Keyword</option>
                              </select>
                            </div>
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2">
                                Expression
                              </label>
                              <select>
                                <option value="All">All</option>
                                <option value="Contains">Contains</option>
                                <option>Does not Contain</option>
                                <option>Exact match</option>
                                <option value="regex">Regex</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 my-2">
                              <label className=" me-2">Value</label>
                              <input type="text" placeholder="enter value" />
                            </div>
                            <div className="col-md-6 my-2">
                              <label htmlFor="" className=" me-2 ">
                                Type of match
                              </label>
                              <select>
                                <option value="Exact Match">Exact match</option>
                                <option value="Semantic Matc">
                                  Semantic Match
                                </option>
                              </select>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row" style={{ marginTop: 64 + "px" }}>
              <div className="col-md-12 justify-content-center">
                {series ? (
                  <Chart
                    options={options}
                    series={series}
                    type="line"
                    height="300"
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-12">
                {searched.length === 0 ? (
                  <></>
                ) : (
                  <>
                    <div>
                      <Table
                        className="page-speed-second table table-bordered  table-hover mt-3 w-100"
                        id="sample"
                        columns={teamcol}
                        dataSource={searched}
                        rowSelection={{
                          type: "checkbox",
                          ...rowSelection,
                        }}
                        pagination={false}
                      />
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-between  mt-3">
                  <h4 className="table-title">Keyword Ideas</h4>
                  <span class="dropdown ">
                    {/* {getCheckPermission("export", "modules") ? ( */}
                      <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Keyword Research"
                          );
                        }}
                      >
                        Export
                      </button>
                    {/* ) : (
                      <></>
                    )} */}
                    {/* <div
                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Keyword Research"
                          );
                        }}
                      >
                        <a class="dropdown-item">Excel</a>
                      </div>
                      <div
                        onClick={() => {
                          exportInToCSV(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Keyword Research"
                          );
                        }}
                      >
                        <a class="dropdown-item">CSV</a>
                      </div>
                    </div> */}
                  </span>
                </div>

                {searched.length === 0 ? (
                  <>
                    <div>
                      <Table
                        className="page-speed-second table table-bordered table-hover  mt-3 w-100"
                        id="sample"
                        columns={teamcol}
                        dataSource={teamlist}
                        rowSelection={{
                          type: "checkbox",
                          ...rowSelection,
                        }}
                        pagination={{
                          position: ["bottomRight"],
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Table
                        id="sample"
                        columns={teamcolHidden}
                        dataSource={teamlistnew}
                        rowSelection={{
                          type: "checkbox",
                          ...rowSelection,
                        }}
                        pagination={{
                          position: ["bottomRight"],
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <hr />
          </TabPanel>
          <TabPanel>
            <ModuleExpandTickets
              startDate={head1}
              endDate={head2}
              refreshTicketData={refreshTicketData}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
function KeywordRefinement() {
  const [fruites, setfruites] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "Non-Brands", isChecked: false },
    { groupId: 2, id: 1, rolename: 1, value: "nike", isChecked: false },
    { groupId: 2, id: 2, rolename: 1, value: "adidas", isChecked: false },
    { groupId: 2, id: 3, rolename: 1, value: "jordans", isChecked: false },
    { groupId: 2, id: 4, rolename: 1, value: "converse", isChecked: false },
    { groupId: 2, id: 5, rolename: 1, value: "crocs", isChecked: false },
    { groupId: 2, id: 6, rolename: 1, value: "skechers", isChecked: false },
    { groupId: 2, id: 7, rolename: 1, value: "puma", isChecked: false },
  ]);
  const [shoes, setshoes] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "sneakers", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "nike shoes", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "adidas shoes", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "boots", isChecked: false },
    { groupId: 1, id: 5, rolename: 1, value: "bata shoes", isChecked: false },
    {
      groupId: 1,
      id: 6,
      rolename: 1,
      value: "liberty shoes",
      isChecked: false,
    },
    { groupId: 1, id: 7, rolename: 1, value: "puma shoes", isChecked: false },
  ]);
  const [Gender, setGender] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "male", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "female", isChecked: false },
  ]);
  const [Color, setColor] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "brown", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "black", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "pink", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "green", isChecked: false },
    { groupId: 1, id: 5, rolename: 1, value: "white", isChecked: false },
    { groupId: 1, id: 6, rolename: 1, value: "red", isChecked: false },
    { groupId: 1, id: 7, rolename: 1, value: "yellow", isChecked: false },
  ]);
  const [Other, setOther] = useState([
    { groupId: 1, id: 1, rolename: 1, value: "air", isChecked: false },
    { groupId: 1, id: 2, rolename: 1, value: "water", isChecked: false },
    { groupId: 1, id: 3, rolename: 1, value: "2020", isChecked: false },
    { groupId: 1, id: 4, rolename: 1, value: "2021", isChecked: false },
  ]);
  function handleAllChecked(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(fruites));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setfruites(fruiteslocal);
  }
  function handleCheckChieldElement(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(fruites));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setfruites(fruiteslocal);
  }
  function handleAllCheckedShoes(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(shoes));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setshoes(fruiteslocal);
  }
  function handleCheckChieldElementShoes(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(shoes));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setshoes(fruiteslocal);
  }
  function handleAllCheckedGender(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Gender));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setGender(fruiteslocal);
  }
  function handleCheckChieldElementGender(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Gender));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setGender(fruiteslocal);
  }
  function handleAllCheckedColor(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Color));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setColor(fruiteslocal);
  }
  function handleCheckChieldElementColor(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Color));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setColor(fruiteslocal);
  }
  function handleAllCheckedOther(id, event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Other));
    fruiteslocal
      .filter((f) => f.groupId === id)
      .forEach((fruite) => {
        fruite.isChecked = event.target.checked;
      });
    setOther(fruiteslocal);
  }
  function handleCheckChieldElementOther(event) {
    let fruiteslocal = JSON.parse(JSON.stringify(Other));
    fruiteslocal.forEach((fruite) => {
      if (`${fruite.groupId}-${fruite.id}` === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    setOther(fruiteslocal);
  }

  const [brandMain, setbrandMain] = useState(false);
  const [ShoeMain, setShoeMain] = useState(false);
  const [GenderMain, setGenderMain] = useState(false);
  const [ColorMain, setColorMain] = useState(false);
  const [OtherMain, setOtherMain] = useState(false);

  return (
    <>
      <div className="kr-list">
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setbrandMain(!brandMain);
          }}
        >
          <div className="custom-column-80">
            Brand or Non brand
            <p>Non Brands, nike, crocs, adidas</p>
          </div>
          <div className="custom-column-20">
            {brandMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div
          className={`kr-list-inner ${brandMain ? `` : `none`}`}
          id="brand-non-brand"
        >
          {[
            { id: 1, name: "Non Brands" },
            { id: 2, name: "Brands" },
          ].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllChecked(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {fruites
                  .filter((fruit) => fruit.groupId === item.id)
                  .map((fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElement}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  })}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setShoeMain(!ShoeMain);
          }}
        >
          <div className="custom-column-80">
            Shoe
            <p>sneakers, nike shoes, adidas, boots</p>
          </div>
          <div className="custom-column-20">
            {ShoeMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div className={`kr-list-inner ${ShoeMain ? `` : `none`}`} id="shoe">
          {[{ id: 1, name: "Shoes" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedShoes(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {shoes
                  .filter((fruit) => fruit.groupId === item.id)
                  .map((fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElementShoes}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  })}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setGenderMain(!GenderMain);
          }}
        >
          <div className="custom-column-80">
            Gender
            <p>Women, men</p>
          </div>
          <div className="custom-column-20">
            {GenderMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div
          className={`kr-list-inner ${GenderMain ? `` : `none`}`}
          id="gender"
        >
          {[{ id: 1, name: "Gender" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedGender(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {Gender.filter((fruit) => fruit.groupId === item.id).map(
                  (fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={
                          handleCheckChieldElementGender
                        }
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  }
                )}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setColorMain(!ColorMain);
          }}
        >
          <div className="custom-column-80">
            Color
            <p>black, white, brown, pink</p>
          </div>
          <div className="custom-column-20">
            {ColorMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
        <div className={`kr-list-inner ${ColorMain ? `` : `none`}`} id="color">
          {[{ id: 1, name: "Color" }].map((item) => (
            <div>
              <input
                type="checkbox"
                onChange={(e) => {
                  handleAllCheckedColor(item.id, e);
                }}
                value="checkedall"
              />{" "}
              {item.name}
              <ul>
                {Color.filter((fruit) => fruit.groupId === item.id).map(
                  (fruite) => {
                    return (
                      <CheckBox
                        key={`${item.id}-${fruite.id}`}
                        handleCheckChieldElement={handleCheckChieldElementColor}
                        {...fruite}
                        value={`${item.id}-${fruite.id}`}
                        label={fruite.value}
                      />
                    );
                  }
                )}
              </ul>
            </div>
          ))}
        </div>
        <div
          className="kr-list-inner custom-row"
          onClick={() => {
            setOtherMain(!OtherMain);
          }}
        >
          <div className="custom-column-80">
            Others
            <p>air, water, 2020,2021</p>
          </div>
          <div className="custom-column-20">
            {OtherMain ? (
              <i className="fa fa-angle-up"></i>
            ) : (
              <i className="fa fa-angle-down"></i>
            )}
          </div>
        </div>
      </div>
      <div className={`kr-list-inner ${OtherMain ? `` : `none`}`} id="others">
        {[{ id: 1, name: "Other" }].map((item) => (
          <div>
            <input
              type="checkbox"
              onChange={(e) => {
                handleAllCheckedOther(item.id, e);
              }}
              value="checkedall"
            />{" "}
            {item.name}
            <ul>
              {Other.filter((fruit) => fruit.groupId === item.id).map(
                (fruite) => {
                  return (
                    <CheckBox
                      key={`${item.id}-${fruite.id}`}
                      handleCheckChieldElement={handleCheckChieldElementOther}
                      {...fruite}
                      value={`${item.id}-${fruite.id}`}
                      label={fruite.value}
                    />
                  );
                }
              )}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}
export default ModuleExpandKeywordResearch;
