/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import * as styles from "./styles";
import Tooltip from "@semcore/ui/tooltip";
import DropDownInput from "../Input";
import { useState } from "react";
import SuggestionDropdown from "../suggestion";

const DropDownRangeInput = ({
  selectedRange,
  data,
  onchange,
  placeholderStart,
  placeholderEnd,
  defaultStart,
  defaultEnd,
  type,
  handleChange,
  inputContainerStyle,
  dropdownRangeStart,
  dropdownRangeEnd,
  inputStyles,
  onBlur,
}) => {
  const [startRange, setStartRange] = useState(dropdownRangeStart);
  const [endRange, setEndRange] = useState(dropdownRangeEnd);

  useEffect(() => {
    setStartRange(dropdownRangeStart);
    setEndRange(dropdownRangeEnd);
  }, [dropdownRangeStart, dropdownRangeEnd]);


  const [range, setRange] = useState({
    start: selectedRange.start,
    end: selectedRange.end,
  });

  const handleStartChange = (value) => {
    const obj = { start: value, end: range.end };
    handleChange(obj);
    setRange(obj);
  };

  const handleEndChange = (value) => {
    const obj = { start: range.start, end: value };
    handleChange(obj);
    setRange(obj);
  };

  const handleStartSearch = (input) => {
    if (!!input) {
      const newRange = dropdownRangeStart.options.filter(
        (item) => item.label.indexOf(input) !== -1
      );
      setStartRange((prev) => {
        return { ...prev, options: [...newRange] };
      });
    } else {
      setStartRange(dropdownRangeStart);
    }
  };
  const handleEndSearch = (input) => {
    if (!!input) {
      const newRange = dropdownRangeEnd.options.filter(
        (item) => item.label.indexOf(input) !== -1
      );
      setEndRange((prev) => {
        return { ...prev, options: newRange };
      });
    } else {
      setEndRange(dropdownRangeEnd);
    }
  };

  return (
    <>
      <div css={styles.inputGroup}>
        <div
          css={[
            styles.inputContainer,
            inputContainerStyle && inputContainerStyle,
          ]}
        >
          {startRange && startRange.options && (
            <SuggestionDropdown
              value={defaultStart}
              data={startRange}
              placeholder={placeholderStart}
              handleDropdownClick={(value) => {
                handleStartChange(value)
              }}
              showSearchBar={true}
              inputStyles={inputStyles}
              handleSearch={handleStartSearch}
              rightIcon={"chevron-down"}
              isDropdownLoading={false}
              key={defaultStart}
              onBlur={onBlur}
            />
          )}
        </div>
        <div>-</div>
        <div
          css={[
            styles.inputContainer,
            inputContainerStyle && inputContainerStyle,
          ]}
        >
          {endRange && endRange.options && (
            <SuggestionDropdown
              value={defaultEnd}
              data={endRange}
              placeholder={placeholderEnd}
              handleDropdownClick={(value) => handleEndChange(value)}
              showSearchBar={true}
              inputStyles={inputStyles}
              handleSearch={handleEndSearch}
              rightIcon={"chevron-down"}
              isDropdownLoading={false}
              key={defaultEnd}
              onBlur={onBlur}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DropDownRangeInput;
