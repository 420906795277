import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors"
import { textStyles } from "../../../styles/font"


export const main = css({
    
});

export const stepperContainer = css({
    borderBottom: `1px solid ${Colors.neutral[50]}`
})

export const formContainer = css({
    
})

