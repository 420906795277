/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from "react";
import Select, { InputSearch } from "@semcore/ui/select";
import * as styles from "./styles";
import { Text } from "@semcore/ui/typography";
import { Flex, Box } from "@semcore/ui/flex-box";
import Tooltip from "@semcore/ui/tooltip";
import InfoM from "@semcore/icon/Info/m";
import DropDownInput from "../Input";
import { useState } from "react";
import { getDropdownFormat } from "../../../../utility/helpers";
import FeatherIcon from "feather-icons-react";

const NestedDropdown = ({
  defaultValue,
  placeholder,
  showSearchBar,
  data,
  addStyles,
  onChange,
  isVisible,
  toggleVisible,
}) => {
  const [filter, setFilter] = React.useState("");
  const options = React.useMemo(
    () =>
      data.options.filter((option) =>
        option.label.toString().toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, data.options[data.active].categories.active]
  );

  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleVisible(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const getSelectedCategories = (selected) => {
    let selectedCategories = " / ";
    data.options[data.active].categories.active.map((selected, index) => {
      selectedCategories +=
        index === 0
          ? data.options[data.active].categories.options[selected].label
          : `, ${data.options[data.active].categories.options[selected].label}`;
    });
    return selectedCategories;
  };

  return (
    <Select
      defaultValue={defaultValue.label}
      placeholder={placeholder}
      css={[styles.main, addStyles && addStyles]}
      key={options}
    >
      <Select.Trigger
        css={styles.trigger}
        onClick={() => toggleVisible(!isVisible)}
      >
        <div css={styles.triggerText} ref={ref}>
          {data.options[data.active].label +
            (data.options[data.active].categories.options.length ===
            data.options[data.active].categories.active.length
              ? " / All Categories"
              : getSelectedCategories())}
        </div>
      </Select.Trigger>
      <Select.Popper
        visible={isVisible}
        css={[styles.popper, styles.borderNone]}
        key={data.active}
      >
        {({ highlightedIndex }) => (
          <>
            {showSearchBar && (
              <InputSearch value={filter} onChange={setFilter}>
                <InputSearch.Value
                  placeholder="Search"
                  role="combobox"
                  aria-autocomplete="list"
                  aria-controls="search-list"
                  aria-owns="search-list"
                  aria-expanded="true"
                  aria-activedescendant={`option-${highlightedIndex}`}
                  css={[styles.searchInput, styles.text]}
                />
                {filter ? (
                  <InputSearch.Clear
                    onClick={() => {
                      return true;
                    }}
                  />
                ) : (
                  <FeatherIcon icon={"search"} css={styles.searchIcon} />
                )}
              </InputSearch>
            )}
            <Select.List hMax={"224px"} id="search-list">
              {options.map((option, index) => (
                <Select.Option
                  value={option.label}
                  css={[styles.text, styles.optionContainer]}
                >
                  <DropDownInput
                    key={option.categories.active}
                    data={option.categories}
                    multiselect={true}
                    showSearchBar={true}
                    nestedTrigger={`${option.label}`}
                    placeholder={option.label}
                    value={""}
                    placement={"right-start"}
                    handleDropdownClick={(value, indexes) => {
                      const campaignIndex = data.options.findIndex(
                        (item) => option.label === item.label
                      );
                      onChange(value, indexes, option, campaignIndex);
                    }}
                    defaultValue={option.categories.active}
                    isVisible={option.categories.isOpen}
                    toggleVisible={(isVisible) => console.log("98988989", true)}
                    disableDeselectAll={false}
                  />
                </Select.Option>
              ))}
            </Select.List>
            {!options.length && (
              <Select.OptionHint key="Nothing">Nothing found</Select.OptionHint>
            )}
          </>
        )}
      </Select.Popper>
    </Select>
  );
};

export default NestedDropdown;
