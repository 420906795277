/** @jsxImportSource @emotion/react */
import React from "react";
import Popper from "@semcore/ui/popper";
import * as styles from "./styles";
import CustomButton from "../../button";
import { Flex, Box } from "@semcore/ui/flex-box";
import Tooltip from '@semcore/ui/tooltip';
import InfoM from '@semcore/icon/Info/m';

const ButtonDropDown = ({ buttonText, options, handleDropdownClick, customTrigger, customPopperStyles, placement = "bottom-end" }) => {

  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };


  return (
    <Popper placement={placement} visible={visible} onVisibleChange={toggleVisible} onOutsideClick={() => {
      setVisible(false);
    }}>
      <Popper.Trigger>
        {customTrigger ?
          customTrigger
          :
          < CustomButton
            text={"Add"}
            rightIcon={"chevron-down"}
            isDisable={false}
            use={"primary"}
          />}
      </Popper.Trigger>
      <Popper.Popper>
        <div css={[styles.popper,customPopperStyles && customPopperStyles]}>
          {options.options.map((option, index) => (
            <div
              value={option.label}
              key={index}
              onClick={() => {
                option.action();
                toggleVisible();
              }}
              css={[styles.text, styles.optionContainer]}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                css={styles.optionHeader}
              >
                <Flex>
                  {option.label}
                  {option.info && (
                    <Tooltip p={0} interaction={"hover"}>
                      <Tooltip.Trigger>
                        <InfoM
                          p={0}
                          color="gray-300"
                          cursor="help"
                          tabIndex={0}
                          css={styles.infoIcon}
                        />
                      </Tooltip.Trigger>
                      <Tooltip.Popper css={styles.tooltip}>
                        {option.info}
                      </Tooltip.Popper>
                    </Tooltip>
                  )}
                </Flex>
                {option.count && (
                  <Box css={styles.secondaryColor}>{option.count}</Box>
                )}
              </Flex>
              {option.additionalInfo && (
                <Flex css={styles.secondaryColor}>{option.additionalInfo}</Flex>
              )}
            </div>
          ))}
        </div>
      </Popper.Popper>
    </Popper>
  );
};

export default ButtonDropDown;
