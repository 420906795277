/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import * as styles from "./styles";
import { useState } from "react";
import Subtitle from "../../shared/subtitle";
import IconLabelInfoPill from "../../shared/pill/iconLabelInfo";
import DividerLine from "../../shared/dividerLine";
import CustomButton from "../../shared/button";
import CampaignCard from "../campaignCard";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaignConfig,
  setCampaignStatus,
  setCampaigns,
  setCredits,
  setIsUnlocked,
  setKeywordsTotal,
  setStatus,
} from "./campaignsSlice";
import {
  deleteCampaign,
  getCampaignDetails,
  getCampaigns,
  isConfigUnlocked,
  updateCampaignStatus,
} from "../../../services/ranking";
import { getLocal } from "../../../utility/helpers";
import toast from "react-hot-toast";
import ConfirmationModal from "../../shared/modal/confirmation";
import Spin from "@semcore/ui/spin";
import {
  resetCampaignDetails,
  setDetails,
  setDomainType,
  setTempCampaignName,
} from "../createEditCampaign/campaignDetailSlice";
import { Status } from "../../../utility/Constant";

const Campaigns = ({ createEditCampaign, isEdit }) => {
  const campaigns = useSelector((state) => state.campaigns.list);
  const isRankSwitchVisible = useSelector(
    (state) => state.campaigns.isRankSwitchVisible
  );
  const credits = useSelector((state) => state.campaigns.credits);
  const keywordsTotal = useSelector((state) => state.campaigns.keywordsTotal);
  const isRankingConfigUnlocked = useSelector(
    (state) => state.campaigns.isUnlocked
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const selectedProject = getLocal("projectGId");
  const userId = getLocal("userId");
  const status = useSelector((state) => state.campaigns.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStatus("loading"));
    dispatch(resetCampaignDetails());
    fetchCampaigns();
    checkIsConfigUnlocked();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await getCampaigns(selectedProject, userId);
      if (response.status === "success") {
        dispatch(
          setCampaigns({
            campaigns: response.data,
            isRankSwitchVisible: response.isRankSwitchVisible,
          })
        );
        dispatch(
          setCredits({
            total: response.TotalcreditsRanking,
            consuming: response.total_keyword_count,
            remaining: response.TotalRemainingKeywordsCredits,
          })
        );
        dispatch(setKeywordsTotal(response.total_keyword_count));
        dispatch(setStatus("success"));
      } else {
        dispatch(setStatus("error"));
      }
    } catch (error) {
      dispatch(setStatus("error"));
    }
  };

  const checkIsConfigUnlocked = async () => {
    try {
      const response = await isConfigUnlocked();
      if (response.status === Status.SUCCESS) {
        dispatch(setIsUnlocked(response.unlock));
      } else {
        dispatch(setIsUnlocked(false));
      }
    } catch (error) {
      dispatch(setIsUnlocked(false));
    }
  };

  const toggleCampaignStatus = async (status, id, index) => {
    try {
      const userId = getLocal("userId")
      const response = await updateCampaignStatus(status, id, userId);
      if (response.status === "success") {
        dispatch(setCampaignStatus({ index: index, status: response.data, updatedBy: response.updated_by }));
        toast.success(
          `Ranking Running Status ${response.data ? "Activated" : "Deactivated"
          } Successfully`
        );
      } else {
        toast.error("Ranking Running Status Update Failed");
      }
    } catch (error) {
      toast.error("Ranking Running Status Update Failed");
    }
  };

  const handleDeleteCampaign = async () => {
    try {
      setIsDeleteButtonLoading(true);
      const response = await deleteCampaign(campaignId);
      if (response.status === "success") {
        setIsDeleteButtonLoading(false);
        dispatch(deleteCampaignConfig(campaignId));
        setIsConfirmationOpen(false);
        if (response.TotalRemainingCredits !== undefined && !isNaN(response.TotalRemainingCredits)) {
          dispatch(setKeywordsTotal(response.TotalRemainingCredits));
          dispatch(
            setCredits({
              ...credits,
              consuming: response.TotalRemainingCredits,
              remaining: credits.total - response.TotalRemainingCredits,
            })
          );
        }
        toast.success("Campaign Deleted Successfully");
      } else {
        setIsDeleteButtonLoading(false);
        setIsConfirmationOpen(false);
        toast.error("Campaign Deleted Failed");
      }
    } catch (error) {
      setIsDeleteButtonLoading(false);
      setIsConfirmationOpen(false);
      toast.error("Campaign Delete Failed");
    }
  };

  const handleEditCampaign = async (id) => {
    try {
      const response = await getCampaignDetails(id, "1");
      if (response.status === "success") {
        const buckets = response.bucketsconfig.map((bucket) => ({
          id: bucket.id,
          start: bucket.minimum,
          end: bucket.maximum,
        }));
        const ranking_configuration = response.ranking_configuration;
        const details = {
          id: ranking_configuration.id,
          name: ranking_configuration.campaignname,
          domain: ranking_configuration.domain || "",
          subDomain: ranking_configuration.subdomain || "",
          location: ranking_configuration.location,
          searchEngine: ranking_configuration.searchengine,
          device: ranking_configuration.device,
          frequency: ranking_configuration.frequency,
          buckets: buckets,
          torsoMin: ranking_configuration.minsearchvolumetorso,
          torsoMax: ranking_configuration.maxsearchvolumetorso,
        };
        dispatch(ranking_configuration.domain ? setDomainType(true) : setDomainType(false))
        dispatch(setDetails(details));
        dispatch(setTempCampaignName(details.name));
        createEditCampaign(true);
      }
    } catch (error) {
      toast.error("Something Went Wrong.");
    }
  };

  if (status === "loading") {
    return (
      <div css={styles.loaderContainer}>
        <Spin size="xxl" />
      </div>
    );
  }

  if (status === "error") {
    return (
      <div css={styles.loaderContainer}>
        <div css={styles.errorContainer}>
          <h1>Unable to Fetch Campaigns.</h1>
          <br />
          <CustomButton
            text={"Try Again"}
            use={"primary"}
            leftIcon={"rotate-cw"}
            theme={"info"}
            onPress={() => fetchCampaigns()}
          />
        </div>
      </div>
    );
  }

  if (status === "success") {
    return (
      <div css={styles.main}>
        <div css={styles.section}>
          <Subtitle
            text={"Overview"}
            icon={"calendar"}
            addStyles={styles.mb75}
          />
          <div css={styles.pillGroup}>
            <IconLabelInfoPill
              label={"Credits Consuming"}
              iconLeft={"credit-card"}
              iconRight={"info"}
              tooltipInfo={
                "This displays cumulative sum of credits consumed by all the active ranking campaigns out of total credits allotted. The displayed credit count will be either weekly or monthly, depending on the ranking plan selected for the project."
              }
            >
              <div css={styles.pillInfo}>
                <div css={styles.consumedCredits}>{credits.consuming}</div>
                <div css={styles.availableCredits}>{`/ ${credits.total}`} </div>
              </div>
            </IconLabelInfoPill>
            <IconLabelInfoPill
              label={"Total Number of Keywords"}
              iconLeft={"hash"}
              iconRight={"info"}
              tooltipInfo={
                "This displays cumulative sum of all the keywords configured in all the active ranking campaigns"
              }
              tooltipPlacement={"top-end"}
            >
              <div css={styles.pillInfo}>
                <div css={styles.totalKeyWords}>{keywordsTotal}</div>
              </div>
            </IconLabelInfoPill>
          </div>
        </div>
        <DividerLine addStyles={styles.dividerLine} />
        <div css={styles.section}>
          <div css={styles.activeCampaignHeader}>
            <Subtitle text={"Active Campaigns"} icon={"calendar"} />
            <CustomButton
              text={"Create Campaign"}
              use={"primary"}
              leftIcon={"plus"}
              theme={"info"}
              onPress={() => createEditCampaign(false)}
              isDisable={!isRankingConfigUnlocked}
            />
          </div>
        </div>
        <div css={styles.campaigns}>
          {campaigns && campaigns.length > 0 ? (
            campaigns.map((campaign, index) => (
              <CampaignCard
                key={campaign.id}
                {...campaign}
                goToEditCampaign={() => handleEditCampaign(campaign.id)}
                onStatusChange={(status) =>
                  toggleCampaignStatus(status, campaign.id, index)
                }
                ondeleteClick={(id) => {
                  setIsConfirmationOpen(true);
                  setCampaignId(id);
                }}
                permissionToActivate={isRankSwitchVisible}
                isRankingConfigUnlocked={isRankingConfigUnlocked}
              />
            ))
          ) : (
            <div css={styles.empty}>No Campaigns Configured Yet</div>
          )}
        </div>
        {isConfirmationOpen && (
          <ConfirmationModal
            icon={"info"}
            theme={"danger"}
            title={"Confirm Delete"}
            confirmButtonText={"Confirm Delete"}
            cancelButtonText={"Cancel"}
            onConfirm={handleDeleteCampaign}
            onCancel={() => setIsConfirmationOpen(false)}
            isConfirmLoading={isDeleteButtonLoading}
          >
            <p css={styles.modalDescription}>
              This will permanently delete this campaign and all the reports
              associated with this campaign will be deleted as well. Are you
              sure you want to delete this campaign.
            </p>
          </ConfirmationModal>
        )}
      </div>
    );
  }
};

export default Campaigns;
