import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
    ".___SInput_letz7_gg_._state_normal_letz7_gg_ .___SOutline_letz7_gg_ " : {
      borderColor: Colors.black
    },
    ".___SValue_letz7_gg_:disabled" : {
      opacity: 0.65
    }
});

export const subtitle = css({
  ...textStyles.s1,
  color: Colors.neutral[900],
  marginBottom: "2rem"
})


export const form = css({
  display: "flex",
  flexDirection: "column",
})

export const error = css({
  ...textStyles.b3,
  marginTop: "0.13rem",
  marginLeft: '13.75rem',
  color: Colors.error[800],
  minHeight: "2.5rem"
})

export const buttonContainer = css({
  display: "flex",
  justifyContent: "flex-end"
})

export const buttonGroup = css({
  display: "flex",
  columnGap: "0.75rem"
})
