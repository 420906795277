import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const tabContainer = css({
    backgroundColor: Colors.plainWhite,
    marginBottom: "1rem",
    borderRadius: "6px 6px 0 0 ",
    "button": {
        padding: "2rem 4rem 1.5rem 4rem"
    },
    ".___STabLineItem_tx9g9_gg_.__selected_tx9g9_gg_::after": {
        backgroundColor: Colors.primary[500]
    }
})