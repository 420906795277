import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Input, Table } from "antd";
import "react-step-progress-bar/styles.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "antd/dist/antd.css";
import { Sorter } from "../../common/Sorter";
import {
  createRole,
  deleteRole,
  getRoleByOrg,
  updateRole,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import {
  exportInToCSV,
  exportToEXCEL,
} from "../../utility/Globals";
import { useNavigate } from "react-router-dom";

const Roles = () => {
  const [userroles, setuserroles] = useState([]);
  const [userrolesList, setuserrolesList] = useState([]);
  const [userrolescol, setuserrolescol] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [rolName, setRolName] = useState("");
  const [rolNameCopy, setRolNameCopy] = useState("");

  const [rolId, setRolId] = useState("");
  const [isEditRole, setIsEditRole] = useState(false);
  const [roleMassage, setRoleMessage] = useState("");
  const notifyAdd = () => toast.success("Successfully Add!");
  useEffect(() => {
    var data = [
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.role, b.role),
          multiple: 3,
        },
        width: "95%",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setuserrolescol(data);

    getRolesData();
  }, []);

  function submit(item) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteRoleItem(item.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          role: item.role,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function getRolesData() {
    APIManager.getAPI(
      getRoleByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let exportList = [];
        let count = 1;
        setCount(count);
        response.data.map((item) => {
          exportList.push({
            key: item.id,
            role: item.name,
          });
          let obj = {
            key: count,
            id: count,
            role: item.name,
            action: (
              <div class="text-right">
                <i
                  onClick={() => {
                    submit(item);
                    deleteRoleItem(item.id);
                  }}
                  class="fa fa-trash me-3"
                  style={{ color: "#2196f3" }}
                ></i>
                <i
                  onClick={() => {
                    setIsEditRole(true);
                    setRolId(item.id);
                    setRolName(item.name);
                    setRolNameCopy(item.name);
                    setRoleMessage("");
                    var e = document.getElementById("input-s");
                    e.scrollIntoView({
                      block: "end",
                      behavior: "smooth",
                      inline: "center",
                    });
                  }}
                  class="fa fa-edit"
                  style={{ color: "#2196f3" }}
                ></i>
              </div>
            ),
          };
          list.push(obj);
          count++;
        });
        setCount(count);
        setuserroles(list);
        setuserrolesList(list);
        setExportList(exportList);
      }
    });
  }

  function clearData() {
    setIsEditRole(false);
    setRolName("");
    setRolId("");
    getRolesData();
  }

  function addrole() {
    if (rolName.trim() === "") {
      toast.error("Role name is required!");
      return;
    }
    APIManager.postAPI(
      createRole,
      {
        name: rolName,
        organizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearData();
        notifyAdd();
      }
    });
  }

  function updateRoleItem() {
    if (rolName.trim() === "") {
      toast.error("Role name is required!");
      return;
    }
    APIManager.putAPI(
      updateRole,
      {
        Id: rolId,
        Name: rolName,
        organizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearData();
      }
    });
  }

  async function deleteRoleItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteRole + id);
      if (response == 200) {
        setRolName("");
        getRolesData();
      }
    } catch (e) {}
  }

  const navigate = useNavigate();

  return (
    <>
      <div className="row common-mb-24">
        <div class="dis-none p-0">
          <div className="col-md-12 d-flex mb-3  p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
          </div>
        </div>
      </div>

      <div className="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Roles</h3>
        </div>
        <div className="portlet-body">
          <div className="col-md-12 col-sm-12 roles-tab">
            <div className="d-flex justify-content-between roles-tab">
              <div className="d-flex justify-content-start align-items-center">
                <input
                  placeholder="Enter Role Name"
                  class="w-30"
                  style={{ borderRight: 0, borderRadius: 0, padding: 8 + "px" }}
                  type="text"
                  id="role-name"
                  value={rolName}
                  required
                  onChange={(Text) => {
                    setRolName(Text.target.value);
                    setRoleMessage("");
                  }}
                />
                <button
                  class="btn btn-primary"
                  style={{ borderLeft: 0, borderRadius: 0, height: 36 + "px" }}
                  onClick={() => {
                    if (rolName.trim() != "") {
                      if (rolNameCopy.trim() == rolName.trim() && isEditRole) {
                        setIsEditRole(false);
                        setRolName("");
                        setRolId("");
                        return;
                      }
                      let isEqualItem = false;
                      userrolesList.map((i) => {
                        if (i.role == rolName.trim()) {
                          isEqualItem = true;
                        }
                      });
                      if (isEqualItem) {
                        setRoleMessage("Role name already exist");
                      } else {
                        setRoleMessage("");
                        if (isEditRole) {
                          updateRoleItem();
                        } else {
                          addrole();
                        }
                      }
                    } else {
                      setRoleMessage("Role name  is required");
                    }
                  }}
                >
                  {isEditRole ? "Update" : "Add"}
                </button>
              </div>

              <div className="d-flex justify-content-end">
                <div class="input-group-icon input-group-lg widget15-compact">
                  <div class="input-group-prepend">
                    <i class="fa fa-search text-primary"></i>
                  </div>
                  <div className="search-box">
                    <span>
                      <Input
                        allowClear
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        style={{ height: 36 + "px" }}
                        onChange={(nameSearch) => {
                          setuserroles(
                            userrolesList.filter((person) =>
                              person.role
                                .toLocaleLowerCase()
                                .includes(
                                  nameSearch.target.value.toLocaleLowerCase()
                                )
                            )
                          );
                        }}
                        id="input-s"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="field-required">
                <span>{roleMassage}</span>
              </div>
            </>
          </div>
          <div className="add-new-btnw">
            <span class="dropdown d-inline">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  onClick={() => {
                    exportToEXCEL(
                      selectedExportList?.length > 0
                        ? selectedExportList
                        : exportList,
                      "Roles"
                    );
                  }}
                >
                  Export
                </button>
              {/* <div
                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                aria-labelledby="dropdownMenuButton"
              >
                <div
                  onClick={() => {
                    exportToEXCEL(
                      selectedExportList?.length > 0
                        ? selectedExportList
                        : exportList,
                      "Roles"
                    );
                  }}
                >
                  <a class="dropdown-item">Excel</a>
                </div>
                <div
                  onClick={() => {
                    exportInToCSV(
                      selectedExportList?.length > 0
                        ? selectedExportList
                        : exportList,
                      "Roles"
                    );
                  }}
                >
                  <a class="dropdown-item">CSV</a>
                </div>
              </div> */}
            </span>
          </div>
          <div class="col-md-12 mt-3 roles-table">
            <Table
              className="table table-bordered table-hover"
              id="sample-module-expand"
              dataSource={userroles}
              columns={userrolescol}
              rowSelection={{ type: "checkbox", ...rowSelection }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;
