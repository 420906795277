/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@semcore/ui/tooltip";

const IconLabelInfoPill = ({
  iconLeft,
  label,
  children,
  iconRight,
  tooltipInfo,
  tooltipPlacement
}) => {
  return (
    <div css={styles.main}>
      <div css={styles.left}>
        <FeatherIcon icon={iconLeft} css={styles.icon} />
        <div css={styles.label}>{label} :</div>
        {children}
      </div>
      {tooltipInfo && (
        <Tooltip placement={tooltipPlacement}>
          <Tooltip.Trigger>
            <FeatherIcon icon={iconRight} css={styles.icon} />
          </Tooltip.Trigger>
          <Tooltip.Popper css={styles.tooltipPopper}>{tooltipInfo}</Tooltip.Popper>
        </Tooltip>
      )}
    </div>
  );
};

export default IconLabelInfoPill;
