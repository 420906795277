import { TLD } from "./tld";

export const setLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocal = (key) => {
  const value = localStorage.getItem(key);
  if (value === null) {
    return undefined;
  }
  return value;
};

export const capitalizeWords = (sentence) => {
  return sentence.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}


export const validateURL = (url, isSubDomain) => {
  const urlRegex = /^(https?|ftp):\/\/([a-z0-9-]+\.)+[a-z]{2,}(\/[^/?#\s]*)?$/i;

  if (!!url) {
    if (url.split("").filter((value) => value === ".").length === 1 && url.split(".")[0].slice(-3).toLowerCase() === "www") {
      return false;
    }

    if (isSubDomain && ((url.split("").filter((value) => value === ".").length < 3 && url.split(".")[0].slice(-3).toLowerCase() === "www") || url.split("").filter((value) => value === ".").length < 2)) {
      return false;
    }
    if (!isSubDomain && ((url.split("").filter((value) => value === ".").length > 1 && url.split(".")[0].slice(-3).toLowerCase() !== "www"))) {
      console.log("passed 1", url.split("."))
      if (TLD.indexOf(url.split(".")[1]) === -1 || TLD.indexOf(url.split(".")[2]) === -1) {
        console.log("passed 2")
        return false;
      }
    }
    // if (url.split("").filter((value) => value === ".").length < 2) {
    //   return false;
    // }

    // if (
    //   isSubDomain &&
    //   url.split("").filter((value) => value === ".").length > 1 &&
    //   url.split(".")[0].slice(-3).toLowerCase() === "www"
    // ) {
    //   return false;
    // }
    if (url[url.length - 1] === "/") {
      return false;
    }
    if (url.indexOf("?") !== -1) {
      return false;
    }

    if (!urlRegex.test(url)) {
      return false;
    }

    return true;
  }
};


export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = today.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getDropdownFormat = (labelKey, valueKey, data, isNested) => {
  if (!isNested) {
    const options = data.map((item) => {
      return { label: item, value: item };
    });
    return {
      options: options,
      active: 0,
    };
  } else {
    const options = data.map((item) => {
      return { label: item[labelKey], value: item[valueKey], ...item };
    });
    return {
      options: options,
      active: 0,
    };
  }
};

export const parseRankString = (rankString) => {
  const rankRegex = /Rank (\d+)(?:-(\d+))?/;
  const match = rankString.match(rankRegex);

  if (!match) {
    return null;
  }
  const startRank = parseInt(match[1], 10);
  const endRank = match[2] ? parseInt(match[2], 10) : startRank;
  return [startRank, endRank];
};

export const shortenNumber = (number) => {
  if (number < 1000) {
    return number;
  } else if (number < 1000000) {
    const result = (number / 1000).toFixed(1);
    return result.endsWith(".0") ? result.slice(0, -2) + "k" : result + "k";
  } else {
    const result = (number / 1000000).toFixed(1);
    return result.endsWith(".0") ? result.slice(0, -2) + "m" : result + "m";
  }
};


export const areAllZeros = (array) => {
  return array.every(element => element === 0);
}
