import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const tabLineItem = (isActive) =>
  css({
    height: "auto",
    "::after": {
      content: '""',
      backgroundColor: isActive
        ? `${Colors.primary[500]} !important`
        : "transparent",
      height: "2px !important",
    },
    "::before": {
      content: '""',
      height: "2px !important",
      backgroundColor: isActive ? Colors.primary[500] : "transparent",
    },
  });

export const tabLineText = css({
  ...textStyles.s1,
});

export const tabLineTextActive = css({
  color: Colors.primary[500],
});

export const tabLineTextInActive = css({
  color: Colors.neutral[400],
});
