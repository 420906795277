import ic_arrow_left from "./ic_arrow_left.png";
import ic_arrow_down from "./ic_arrow_down.png";
import ic_home from "./ic_home.png";
import ic_home_white from "./ic_home_white.png";
import ic_arrow_up_white from "./ic_arrow_up_white.png"
import ic_brand from "./ic_brand.png";
import i_brand_logo_expanded from "./i_brand_logo_expanded.png";
import i_ranking_buckets_tooltip from "./i_ranking_buckets_tooltip.png";
import ic_tableIconWhite from "./table-icon-white.svg"
import ic_tableIconBlack from "./table-icon-black.svg"
import ic_graphIconWhite from "./graph-icon-white.svg"
import ic_graphIconBlack from "./graph-icon-black.svg"
import ic_chart_positive from "./ic_chart_positive.png"
import ic_chart_trend from "./ic_chart_trend.svg"
import rankingv2_mobile from "./rankingv2-mobile.svg"
import rankv2_desktop_black from "./rankv2-desktop-black.svg"
import rankv2_desktop_white from "./rankv2-desktop-white.svg"
import rankv2_mobile_black from "./rankv2-mobile-black.svg"
import rankv2_mobile_white from "./rankv2-mobile-white.svg"
import ic_infinity_green from "./ic_inifinity_green.png"
import ic_infinity_red from "./ic_inifinity_red.png"
import ic_empty_table_icon from "./ic_empty_table_icon.png"
import ic_ranking_active from "./ic_ranking_active.png";
import ic_ranking_inactive from "./ic_ranking_inactive.png";
import disclaimer_icon from "./disclaimer_icon.png"
import ticket_empty_table from "./ticket_empty_table.png"

const AssetsImg = {
  ic_arrow_left,
  ic_arrow_down,
  ic_home,
  ic_home_white,
  ic_arrow_up_white,
  ic_brand,
  ic_tableIconWhite,
  ic_tableIconBlack,
  i_brand_logo_expanded,
  disclaimer_icon,
  i_ranking_buckets_tooltip,
  ic_graphIconWhite,
  ic_graphIconBlack,
  ic_chart_positive,
  rankingv2_mobile,
  rankv2_desktop_black,
  rankv2_desktop_white,
  rankv2_mobile_black,
  rankv2_mobile_white,
  ic_chart_trend,
  ic_infinity_green,
  ic_infinity_red,
  ic_empty_table_icon,
  ic_ranking_active,
  ic_ranking_inactive,
  ticket_empty_table
};

export default AssetsImg;
