import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  credits: {
    total: "",
    consuming: "",
    remaining:""
  },
  keywordsTotal: "",
  isRankSwitchVisible:"",
  isUnlocked:"",
  status: "loading",
}

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.list = action.payload.campaigns;
      state.isRankSwitchVisible = action.payload.isRankSwitchVisible;
    },
    setCredits: (state,action) => {
      state.credits = action.payload
    },
    updateRemainingCredits: (state,action) => {
      state.credits.remaining = action.payload
    },
    setKeywordsTotal: (state,action) => {
      state.keywordsTotal = action.payload
    },
    setStatus: (state,action) => {
      state.status = action.payload
    },
    updateCampaigns: (state,action) => {
     state.list[action.payload.index] = action.payload.campaignDetails  
    },
    setCampaignStatus : (state,action) => {
      state.list[action.payload.index].isActive = action.payload.status 
      state.list[action.payload.index].updated_by = action.payload.updatedBy
    },
    deleteCampaignConfig : (state, action) => {
      state.list = state.list.filter((campaign)=> campaign.id !== action.payload);
    },
    setIsUnlocked : (state,action) => {
      state.isUnlocked = action.payload
    }
  },
});

export const {setCampaigns, setTotalCredits, setCredits, setKeywordsTotal, setStatus, updateCampaigns, setCampaignStatus, deleteCampaignConfig, updateRemainingCredits, setIsUnlocked} = campaignsSlice.actions;

export default campaignsSlice.reducer;


