import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const applyContainer = css({
    padding: "1rem",
    backgroundColor: Colors.plainWhite,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderRadius: "0 0 4px 4px",
    display: "flex",
    flexDirection: "row-reverse"
})

export const mainContainer = (custom) => {
    return css({
        ...custom,
        ".___SBoxInline_1p6qr_gg_": {
            width: "100%"
        }
    })
}

export const inputStyles = (custom) => {
    return css({
        "input": {
            ...textStyles.H1125Medium,
            border: "none !important",
            cursor: "default",
            ...custom
        }
    })
}

export const calendarStyles = css({
    // ...textStyles,
    marginTop: "1rem",
    ".rdrNextPrevButton": {
        background: "none"
    },
    ".rdrMonth": {
        width: "20rem"
    },
    ".rdrMonthAndYearPickers": {
        width: "7rem"
    },
    ".rdrMonthAndYearPickers select": {
        padding: "10px 22px 10px 10px"
    },
    ".rdrStartEdge": {
        borderRadius: "6px 0 0 6px"
    },
    ".rdrEndEdge": {
        borderRadius: "0 6px 6px 0"
    },
    // ".rdrInRange": {
    //     backgroundColor: Colors.primary[100],
    //     color: "#1d2429"
    // }
})