import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function PasswordSuccess() {
    const navigate = useNavigate();
   
    useEffect(() => {
        setTimeout(() => {
            navigate("/login");
        }, 3000);
      
    }, []);

    return (
        <>
            {/* <div class="float-btn float-btn-right">
                <button
                    class="btn btn-flat-primary btn-icon mb-2"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Change theme"
                >
                </button>
            </div> */}

            <div class="login-new password-success">
                <div class="container-fluid px-0 h-100">
                    <div class="row no-gutters px-0 d-flex h-100">
                        <div class="custom-col-7">
                            <div class="image-left h-100">
                                <img src="./images/login/email-sent.png" alt="#" />
                            </div>
                        </div>
                        <div class="custom-col-5">
                            <div class="field-right h-100">
                                <div className="wrap-class">
                                    <div className="login-title text-start">
                                        <div className="d-flex align-items-center">
                                            <h2>Success</h2>
                                            <img src="./images/login/e-sent.png" alt="#" />
                                        </div>
                                        <span>New password is set successfully</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordSuccess;
