import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
    ".apexcharts-yaxis .apexcharts-text.apexcharts-yaxis-label tspan ": {
        ...textStyles.b3,
        fill: Colors.black
    },
    ".apexcharts-xaxis .apexcharts-text.apexcharts-xaxis-label tspan": {
        ...textStyles.s3,
        fill: Colors.black
    },
    ".apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series": {
        display: "flex",
        alignItems: "center",
        marginTop: "10px !important",
        marginLeft: "12px!important",   
        padding: "5px",
        border: `solid ${Colors.neutral["50"]}`,
        borderWidth: "1px",
        borderRadius: "6px",
    },
    ".apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text": {
        ...textStyles.b3,
        // marginLeft: "0.1rem"
    }
})