import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Modal } from "react-bootstrap";
import CreateNewTicket from "../CreateNewTicket";

function ModelCreateTicket(props) {

  useEffect(() => {

  }, []);

  return (
    <>
      <div class="portlet-header portlet-header-bordered">
        <Modal
          show={props.modelShow}
          onHide={() => {
            props.modelCloseClick()
          }}
          className="ticket-filter-modal"
        >
          <Modal.Header closeButton>
            <div class="d-flex align-items-center">
            </div>
          </Modal.Header>
          <Modal.Body>
           <CreateNewTicket isModel={true} closeModel={()=>{props.modelCloseClick()}}/>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ModelCreateTicket;
