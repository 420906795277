import React from 'react'
import APIManager from "../../services/APIManager";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
    formatRankDate,
    mmDDyyyyFormateDateSet,
    returnMagnitude,
} from "../../utility/Globals";
import {
    getCampaignList1,
    getCategoryByProject,
    getKeywordDistributionChart
} from '../../utility/Constant';

function RankingChartV2(props) {
    const [isChart, setIsChart] = useState(0);
    const [series, setSeries] = useState([]);
    const [options, setObject] = useState({});
    const [isDateRange, setIsDateRange] = useState(true);
    const [keyDistributionTabCompareList, setKeyDistributionTabCompareList] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [CategorySelected, setCategorySelected] = useState();
    const [keywordType, setKeywordType] = useState(0);
    const [deviceType, setDeviceType] = useState(0);
    const [rankingType, setRankingType] = useState(2);

    useEffect(() => {
        getCampaign();
    }, [props?.dataParentToChild]);

    useEffect(() => {
        setIsChart(props?.IsSeoChart);
    }, [props?.IsSeoChart]);

    function getKeywordDistributionChartType(rowdata) {
        APIManager.postAPI(getKeywordDistributionChart, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let list1 = [];
                let barGraphData = [];
                let barGraphData1 = [];
                let minValue, maxValue;
                let listCategory = [];
                if (isDateRange == false) {
                    response.data?.tableContent?.table?.map((item, index) => {
                        list1 = Object.keys(item);
                        barGraphData1 = Object.values(item);
                        var array = list1[0].split('-');
                        minValue = array[0];
                        maxValue = array[1];
                    });
                    let totalIndex;
                    list1.map((item, index) => {
                        if (item != "total") {
                            list.push(item);
                            listCategory.push("Rank " + item)
                        } else {
                            totalIndex = index;
                        }
                    });
                    barGraphData1.map((item, index) => {
                        if (index != totalIndex) {
                            barGraphData.push(item)
                        }
                    })
                    setObject({
                        legend: {
                            show: true,
                        },
                        title:
                        {
                            text: "",
                            align: "left",
                        },
                        chart: {
                            id: "basic-bar",
                            stacked: true,
                            events:
                            {
                                click: function (event, chartContext, config) {
                                    list.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            var array = item.split('-');
                                            minValue = array[0];
                                            maxValue = array[1];
                                        }
                                    })
                                }
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: "10%",
                            },
                        },
                        dataLabels:
                        {
                            formatter: () => { return ""; },
                        },
                        xaxis: {
                            tickPlacement: "on",
                            categories: listCategory,
                            title: {
                                text: "",
                                style: { color: "#000", },
                            },
                        },
                        yaxis:
                        {
                            title:
                            {
                                text: "",
                                style: { color: "#000", },
                            },
                            logBase: 20,
                            tickAmount: 8,
                            min: 0,
                            show: true,
                        },
                    });
                    setSeries([
                        {
                            name: "Value",
                            data: barGraphData,
                            color: "#59d866"
                        }
                    ])
                }
                else {
                    let listCompare1 = [];
                    let listCompare11 = [];
                    let listCompare2 = [];
                    let listCompare22 = [];
                    let barGraphDataCompare1 = []
                    let barGraphDataCompare11 = []
                    let barGraphDataCompare2 = []
                    let barGraphDataCompare22 = []
                    let listdata = [];
                    response.data?.tableContent?.table?.map((item, index) => {
                        listCompare1 = Object.keys(item);
                        listCompare1.sort();
                        var array = listCompare1[0].split('-');
                        minValue = array[0];
                        maxValue = array[1];
                    });
                    if (response && response.data && response.data?.tableContent && response.data?.tableContent?.table.length) {
                        if (listCompare1.length)
                            barGraphDataCompare1.push(response.data?.tableContent?.table[0][listCompare1[0]]);
                        if (listCompare1.length > 1)
                            barGraphDataCompare1.push(response.data?.tableContent?.table[0][listCompare1[1]]);
                        if (listCompare1.length > 2)
                            barGraphDataCompare1.push(response.data?.tableContent?.table[0][listCompare1[2]]);
                    }
                    let totalIndex1;
                    listCompare1.map((item, index) => {
                        if (item != "total") {
                            if (index < 3) {
                                listCompare11.push(item);
                            }
                        } else {
                            totalIndex1 = index;
                        }
                    });
                    barGraphDataCompare1.map((item, index) => {
                        if (index < 3) {
                            if (index != totalIndex1) {
                                barGraphDataCompare11.push(item)
                            }
                        }
                    })
                    let listMain = []
                    listMain.push(
                        {
                            name: "Value",
                            data: barGraphDataCompare11,
                            color: "#59d866"
                        }
                    )
                    response.data?.compareTableContent?.table?.map((item, index) => {
                        listCompare2 = Object.keys(item);
                        listCompare2.sort();
                    });

                    if (response && response.data && response.data?.compareTableContent && response.data?.compareTableContent?.table.length) {
                        if (listCompare2.length)
                            barGraphDataCompare2.push(response.data?.compareTableContent?.table[0][listCompare2[0]]);
                        if (listCompare2.length > 1)
                            barGraphDataCompare2.push(response.data?.compareTableContent?.table[0][listCompare2[1]]);
                        if (listCompare2.length > 2)
                            barGraphDataCompare2.push(response.data?.compareTableContent?.table[0][listCompare2[2]]);
                    }

                    let totalIndex2;
                    listCompare2.map((item, index) => {
                        if (item != "total") {
                            listCompare22.push(item);
                            if (index < 3)
                                listCategory.push("Rank " + item)
                        } else {
                            totalIndex2 = index;
                        }
                    });
                    barGraphDataCompare2.map((item, index) => {
                        if (index != totalIndex2 && index < 3) {
                            barGraphDataCompare22.push(item)
                        }
                    })
                    listMain.push(
                        {
                            name: "Value",
                            data: barGraphDataCompare22,
                            color: "#0483E1"
                        }
                    )
                    response.data?.tableContent?.table?.map((item, index) => {
                        item["key"] = index + 1;
                        let keys = Object.keys(item);
                        keys.sort();
                        if (listdata?.length) {
                            keys.map((x, i) => {
                                if (listdata.some(y => y.label == x)) {
                                    let newindex = listdata.findIndex(y => y.label == x);
                                    listdata[newindex]['value'] = item[x]
                                }
                            });
                        } else {
                            keys.map((x, i) => {
                                if (i < keys.length - 2) {
                                    listdata.push({
                                        label: x,
                                        value: item[x]
                                    })
                                }
                            })
                        }
                    });
                    response.data?.compareTableContent?.table?.map((item, index) => {
                        item["key"] = index + 1;
                        let keys = Object.keys(item);
                        keys.sort();
                        if (listdata?.length) {
                            keys.map((x, i) => {
                                if (listdata.some(y => y.label == x)) {
                                    let newindex = listdata.findIndex(y => y.label == x);
                                    listdata[newindex]['value1'] = item[x]
                                }
                            });
                        } else {
                            keys.map((x, i) => {
                                if (i < keys.length - 2) {
                                    listdata.push({
                                        label: x,
                                        value1: item[x]
                                    })
                                }
                            });
                        }
                    });
                    setKeyDistributionTabCompareList(listdata);                   
                    setSeries(listMain)
                    setObject({
                        legend: {
                            show: false,
                        },
                        title:
                        {
                            text: "",
                            align: "left",
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        chart: {
                            id: "basic-bar",
                            stacked: false,
                            events:
                            {
                                click: function (event, chartContext, config) {
                                    listCompare11.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            var array = item.split('-');
                                            minValue = array[0];
                                            maxValue = array[1];
                                        }
                                    })
                                }
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: "10%",
                            },
                        },
                        xaxis: {
                            tickPlacement: "on",
                            categories: listCategory,
                            title: {
                                text: "",
                                style: { color: "#000", },
                            },
                        },
                        yaxis:
                        {
                            title:
                            {
                                text: "",
                                style: { color: "#000", },
                            },
                            labels: {
                                formatter: function (value) {
                                    return returnMagnitude(value);
                                },
                            },
                            logBase: 20,
                            tickAmount: 8,
                            min: 0,
                            show: true,
                        },
                    });
                }
            }
        });
    }

    function handleClickRankingChart(type) {
        if (isChart != type) {
            setIsChart(type);
        }
    }

    function getCategoryData() {
        APIManager.getAPI(
            getCategoryByProject + localStorage.getItem("projectGId")
        ).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let object = {
                    id: "",
                    categoryType: "All"
                }
                list.push(object)
                response.data.map((item) => {
                    let object = {
                        id: item.id,
                        categoryType: item.categoryType
                    }
                    list.push(object)
                })
                setCategorySelected(list[0].id);
            }
        });
    }

    function getCampaign() {
        APIManager.getAPI(getCampaignList1 + localStorage.getItem("projectGId")).then((response) => {
            if (response && response.status == 200 &&
                response.data != "No Content Found") {
                const list1 = response.data;
                getCategoryData();
                setIsDateRange(true)
                let rowdata
                if (isDateRange == true) {
                    rowdata = {
                        ProjectId: localStorage.getItem("projectGId"),
                        RankingCampaignConfigurationId: list1[0].id,
                        Type: rankingType,
                        Device: deviceType,
                        PageSize: pageSize,
                        KeywordCategoryId: CategorySelected,
                        DateRange: {
                            FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                            TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                        },
                        CompareDateRange: {
                            FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                            TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                        },
                        KeywordType: keywordType,
                    };
                    getKeywordDistributionChartType(rowdata)
                }
            }
        })
    }

    return (
        <>
            <div className="title pb-4 ">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="me-3 ranking-name">Rankings</h4>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">                       
                        <div className="btn-box">
                            <div className="d-flex align-items-center">
                                <button
                                    className={`${isChart === 0 ? "active" : ""}`}
                                    onClick={() => {
                                        handleClickRankingChart(0);
                                    }}
                                >
                                    <img
                                        src={`${isChart === 0
                                            ? "/images/graph-icon-white.svg"
                                            : "/images/graph-icon-black.svg"
                                            }`}
                                        alt=""
                                    />
                                </button>
                                <button
                                    className={`${isChart === 1 ? "active" : ""}`}
                                    onClick={() => {
                                        handleClickRankingChart(1);
                                    }}
                                >
                                    <img
                                        src={`${isChart === 1
                                            ? "/images/table-icon-white.svg"
                                            : "/images/table-icon-black.svg"
                                            }`}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                {isChart == 0 ? (
                    <>
                        <Chart options={options} series={series} type="bar" height="300" />
                        <div className="legend-box d-flex flex-wrap align-items-center justify-content-center w-100 mt-2">
                            <div className="d-flex align-items-center pr-5">
                                <div className="legend-dot blue"></div>
                                <div className="ml-2">{formatRankDate(props?.dataParentToChild?.selection1?.startDate)}</div>
                            </div>
                            <div className="d-flex align-items-center pr-5">
                                <div className="legend-dot"></div>
                                <div className="ml-2">{formatRankDate(props?.dataParentToChild?.selection2?.startDate)}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-12 text-lrft text-sm-right backlink-daterange">
                            Date Range : {formatRankDate(props?.dataParentToChild?.selection1?.startDate)} -{" "}
                            {formatRankDate(props?.dataParentToChild?.selection1?.endDate)}
                        </div>

                        <div className="row  ranking-scroll ranks_block">
                            {keyDistributionTabCompareList.map((i, index) => {
                                let ishighdata = 0;
                                if (i.value > i.value1) {
                                    ishighdata = 1
                                }
                                else {
                                    ishighdata = 0
                                }
                                if (index < 3) {
                                    return (
                                        <div className="col-xl-12 col-xxl-6 col-lg-12 ranking_chart_v2_custome mb-3">
                                            <div className="ranks">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4> {"Rank " + i.label}</h4>
                                                    <div className="grren-domain text-right">
                                                        {ishighdata != 1 ? (<>
                                                            <svg
                                                                width="35"
                                                                height="34"
                                                                viewBox="0 0 35 34"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                                                                <g clip-path="url(#clip0_4_4546)">
                                                                    <path
                                                                        d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                                                        stroke="#59D866"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M21.667 12H26.667V17"
                                                                        stroke="#59D866"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4_4546">
                                                                        <rect
                                                                            width="20"
                                                                            height="20"
                                                                            fill="white"
                                                                            transform="translate(7.5 7)"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </>) : (<>
                                                            <svg
                                                                width="34"
                                                                height="34"
                                                                viewBox="0 0 34 34"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <circle
                                                                    r="17"
                                                                    transform="matrix(1 0 0 -1 17 17)"
                                                                    fill="#FFC0C2"
                                                                />
                                                                <g clip-path="url(#clip0_4_4443)">
                                                                    <path
                                                                        d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                                                        stroke="#F64F53"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M21.167 22H26.167V17"
                                                                        stroke="#F64F53"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4_4443">
                                                                        <rect
                                                                            width="20"
                                                                            height="20"
                                                                            fill="white"
                                                                            transform="matrix(1 0 0 -1 7 27)"
                                                                        />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </>)}
                                                    </div>
                                                </div>
                                                <h3 className={(ishighdata != 1 ? "avg-val-positive mt-3 mb-4" : "avg-val-negative")}>
                                                    +{(i.value && i.value1 ? ((i.value != null ? i.value : 0 > i.value1 != null ? i.value1 : 0) ? (((i.value / i.value1) * 100) - 100).toFixed(2) : (((i.value1 / i.value) * 100) - 100).toFixed(2)) : (<>{
                                                        i.value ? i.value : i.value1 ? i.value1 : 0}</>))}%</h3>
                                                <div className="row mt-3">
                                                    <div className="d-flex align-items-end justify-content-between p-0">
                                                        <div className="col-sm-6 p-0">
                                                            <p>{i.value != null ? i.value : 0}</p>
                                                            <span>{formatRankDate(props?.dataParentToChild?.selection1?.startDate)}</span>
                                                        </div>
                                                        <div className="col-sm-6 roi-total-compare pl-2 pr-0">
                                                            <p>{i.value1 != null ? i.value1 : 0}</p>
                                                            <span>{formatRankDate(props?.dataParentToChild?.selection2?.startDate)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RankingChartV2