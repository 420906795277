import APIV1 from "../api/ticketingAxios";

export const getTicketTypesByOrg = async (orgId) => {
    try {
        const response = await APIV1(
            "get",
            `/ticketing/get_ticket_types_from_org_id/${orgId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getPrioritiesByOrg = async (orgId) => {
    try {
        const response = await APIV1(
            "get",
            `/ticketing/get_priorities_from_org_id/${orgId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getClientsByOrg = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_clients_from_org_id/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProjectsByClient = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_projects_from_client_id/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDepartmentsByProject = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_departments_from_project_id/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMembersByDepartment = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_members_from_department_id/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const postCreateMainTicket = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/create_main_ticket/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMyTickets = async (type, userId, orgId) => {
    let api = {
        0: "my_tickets_assigned_to_me",
        1: "my_tickets_created_by_me",
        2: "my_tickets_available",
    }
    try {
        const response = await APIV1(
            "get",
            `/ticketing/${api[type]}/${userId}/${orgId}/`
            // `/ticketing/${api[type]}/${userId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMyTicketsAvailable = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/my_tickets_available/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}


export const getTeamTickets = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/fetch_team_tickets_data/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllDetailsOfTicket = async (id, payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_all_details_of_ticket/${id}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateMainTicket = async (userId, payload) => {
    try {
        const response = await APIV1(
            "patch",
            `/ticketing/edit_main_ticket/${userId}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateSubTicket = async (userId, payload) => {
    try {
        const response = await APIV1(
            "patch",
            `/ticketing/edit_sub_ticket/${userId}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteMainTicket = async (userId, payload) => {
    try {
        const response = await APIV1(
            "delete",
            `/ticketing/delete_main_ticket/${userId}/`,
            { data: payload }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const createSubticket = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/create_sub_ticket/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteSubTicket = async (userId, payload) => {
    try {
        const response = await APIV1(
            "delete",
            `/ticketing/delete_sub_ticket/${userId}/`,
            { data: payload }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMembersForSubticket = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/get_members_for_create_subticket/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateTicketStatus = async (userId, payload) => {
    try {
        const response = await APIV1(
            "patch",
            `/ticketing/maintain_ticket_status/${userId}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateTicketTime = async (userId, payload) => {
    try {
        const response = await APIV1(
            "patch",
            `/ticketing/maintain_ticket_time_log/${userId}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}