/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles";
import RemainingCompetitorsCard from "./RemainingCompetitorsCard";
import CustomButton from "../../shared/button";
import FeatherIcon from "feather-icons-react";
import LabeledInput from "../../shared/Input";
import { Input, Table } from "antd";
import ConfirmationModal from "../../shared/modal/confirmation";
import ButtonDropDown from "../../shared/Dropdown/Button";
import AddSingleCompetitor from "./addSingleCompetitor";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCampaignDetails,
  setActiveStep,
  setCompetitors,
  setFormState,
} from "../createEditCampaign/campaignDetailSlice";
import { onlyCSVFile, onlyXlsFile } from "../../../utility/Constant";
import {
  createCampaign,
  deleteCompetitors,
  getCampaignDetails,
  importCompetitors,
  upDateCampaign,
  uploadCompetitors,
} from "../../../services/ranking";
import toast from "react-hot-toast";
import { getCurrentDate, getLocal } from "../../../utility/helpers";
import SpinContainer from "@semcore/ui/spin-container";
import CustomNotice from "../../shared/notice";
import competitorTemplate from "../../assets/templates/Competitors_Upload_Template.xlsx";
import { exportToEXCEL } from "../../../utility/Globals";

const AddCompetitors = ({ addStyles, gotoCampaigns, isEdit }) => {
  const [searchText, setSearchText] = useState(""); 
  const competitors = useSelector((state) => state.campaignDetails.competitors);
  const campaignDetails = useSelector((state) => state.campaignDetails.details);
  const keywords = useSelector((state) => state.campaignDetails.keywords);
  const [editValue, setEditValue] = useState("");
  const [editableRow, setEditableRow] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState("table");
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [creatingCampaign, setIsCreatingCampaign] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const campaignId = useSelector((state) => state.campaignDetails.details.id);
  const [showConfirmImport, setShowConfirmImport] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [showUploadFailedNotice, setShowUploadFailedNotice] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchInputRef = useRef();
  const user_id = getLocal("userId")

  useEffect(() => {
    if (isEdit && activeComponent === "table") {
      fetchCompetitors();
    }
  }, [activeComponent]);

  const fetchCompetitors = async () => {
    try {
      const response = await getCampaignDetails(campaignId, "3");
      if (
        response.status === "success" &&
        response.competitors_configurations.length > 0
      ) {
        const data = response.competitors_configurations.map((competitor) => ({
          key: competitor.id,
          competitor: competitor.competitor,
          action: "",
        }));
        dispatch(setCompetitors(data));
        dispatch(
          setFormState({ component: "addCompetitors", isComplete: true })
        );
      }
    } catch (error) {}
  };

  const handleSave = (row) => {
    const newData = [...competitors];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    dispatch(setCompetitors(newData));
    setEditableRow("");
  };

  const columns = [
    {
      title: "Competitors",
      dataIndex: "competitor",
      key: "competitor",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        handleSave,
      }) => (
        <>
          <Input
            ref={searchInputRef}
            placeholder={"Search"}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSelectedKeys(!!e.target.value ? [e.target.value] : []);
              confirm(false);
            }}
            onPressEnter={() => {
              confirm();
            }}
            css={styles.searchInput}
            suffix={<FeatherIcon icon={"search"} css={styles.searchIcon} />}
          />
        </>
      ),
      filterIcon: (filtered) => (
        <FeatherIcon
          icon="search"
          css={styles.searchIcon(filtered)}
          onClick={() => setIsSearchOpen(!isSearchOpen)}
        />
      ),
      onFilter: (value, record) =>
        record.competitor
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current?.select(), 100);
        }
      },
      render: (value, record) =>
        editableRow === record.key ? (
          <LabeledInput
            value={value}
            onChange={(value) => {
              setEditValue(value);
            }}
            onPressEnter={() => {
              let newRecord = { ...record };
              newRecord.competitor = editValue;
              handleSave(newRecord);
            }}
          />
        ) : (
          <div css={styles.keyword}>{value}</div>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, record) => {
        return (
          <div css={styles.actionIconContainer}>
            {/* {isEdit && (
              <FeatherIcon
                icon={"edit"}
                css={styles.actionIcon}
                onClick={() => {
                  dispatch(setIsEditingCompetitors(true));
                  dispatch(setSingleCompetitorData(record.competitor));
                  setActiveComponent("addCompetitor");
                }}
              />
            )} */}
            <FeatherIcon
              icon={"trash-2"}
              css={styles.actionIcon}
              onClick={() => deleteSingleCompetitor(record.key)}
            />
          </div>
        );
      },
      width: "7%",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const addCompetitorsOption = {
    options: [
      {
        label: "Add Single Competitor",
        action: () => {
          setActiveComponent("addCompetitor");
        },
      },
      {
        label: "Upload Competitors List",
        action: () => {
          handleImportClick();
        },
      },
    ],
    active: [],
  };

  const getComponent = () => {
    if (activeComponent === "table") {
      return (
        <>
          {showUploadFailedNotice && (
            <CustomNotice
              leftIcon={"alert-triangle"}
              title={"Upload Issue"}
              description={uploadError}
              onClose={() => setShowUploadFailedNotice(false)}
              theme={"danger"}
              addedStyle={{ marginBottom: "2rem" }}
            />
          )}
          <div css={styles.header}>
            <div>
              <RemainingCompetitorsCard
                competetorsCount={competitors.length}
                totlaCompetitors={15}
              />
            </div>
            <div css={styles.buttonGroup}>
              {/* <CustomButton
                text={selected.length > 0 ? "Delete Selected" : "Delete All"}
                leftIcon={"trash-2"}
                isDisable={!keywords.length > 0}
                use={selected.length > 0 ? "primary" : "secondary"}
                theme={"danger"}
                onPress={() => setShowConfirmDelete(true)}
                addStyles={
                  selected.length > 0 ? "" : styles.secondaryButtonDanger
                }
              /> */}
              {isEdit ? (
                <ButtonDropDown options={addCompetitorsOption} />
              ) : (
                <CustomButton
                  text={"Import"}
                  leftIcon={"upload"}
                  isDisable={false}
                  use={"primary"}
                  onPress={handleImportClick}
                />
              )}
              <CustomButton
                text={"Export"}
                leftIcon={"download"}
                isDisable={competitors?.length === 0}
                use={"primary"}
                onPress={() => {
                  exportToEXCEL(
                    formatExportData(competitors),
                    `Competitors ${getCurrentDate()}`
                  );
                }}
              />
              <a
                target="_blank"
                href={competitorTemplate}
                download={"Competitor_Upload_Template.xlsx"}
              >
                <CustomButton
                  text={"Download Template"}
                  leftIcon={"download"}
                  isDisable={false}
                  use={"secondary"}
                  theme={"info"}
                  addStyles={styles.secondaryButton}
                />
              </a>
            </div>
          </div>
          <div>
            {competitors.length > 0 ? (
              <Table
                id="keywords-table"
                className="table  table-hover mt-3 "
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                dataSource={competitors}
                columns={columns}
                pagination={{
                  size: "small",
                  position: ["bottomRight"],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} records`,
                }}
                getPopupContainer={() =>
                  document.getElementById("keywords-table")
                }
              />
            ) : (
              <div css={styles.empty}>No Competitors Configured Yet</div>
            )}
          </div>
          <div css={[styles.buttonContainer, styles.buttonGroup]}>
            <CustomButton
              text={"Back"}
              use={"secondary"}
              theme={"info"}
              onPress={() => dispatch(setActiveStep("addKeywords"))}
              addStyles={styles.secondaryButton}
            />
            <CustomButton
              text={isEdit ? "Save and Update" : "Complete Setup"}
              use={"primary"}
              theme={"info"}
              onPress={() => setConfirmationOpen(true)}
            />
          </div>
        </>
      );
    } else if (activeComponent === "addCompetitor") {
      return (
        <AddSingleCompetitor backToTable={() => setActiveComponent("table")} />
      );
    }
  };

  const handleImportClick = () => {
    if (competitors.length > 0 && !isEdit) {
      setShowConfirmImport(true);
    } else {
      inputRef.current.click();
    }
  };

  const handleImport = async () => {
    var input = document.getElementById("fileinputCompetitors");
    if (isEdit) {
      uploadNewCompetitors(input.files.item(0));
    } else {
      competitorsImport(input.files.item(0));
    }
  };

  const uploadNewCompetitors = async (file) => {
    setShowUploadFailedNotice(false);
    setIsLoading(true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("competitorscount", competitors.length);
      bodyFormData.append("user_id", user_id);
      const response = await uploadCompetitors(campaignId, bodyFormData);
      setIsLoading(false);
      if (response.status === "success") {
        const data = response.fileData.map((competitor) => ({
          key: competitor.id,
          competitor: competitor.competitor,
          action: "",
        }));
        dispatch(setCompetitors([...data, ...competitors]));
        dispatch(
          setFormState({ component: "addCompetitors", isComplete: true })
        );
        toast.success("New Competitors Added Successfully");
      } else {
        setIsLoading(false);
        toast.error("Add New Competitors Failed");
      }
      inputRef.current.value = null;
    } catch (error) {
      if (error.response.data.error_message) {
        setUploadError(error.response.data.error_message);
      } else {
        setUploadError(
          "There is some issue while uploading. Please check the data format in the given template and try uploading again."
        );
      }
      setIsLoading(false);
      setShowUploadFailedNotice(true);
      setIsLoading(false);
      inputRef.current.value = null;
    }
  };

  const competitorsImport = async (file) => {
    setShowUploadFailedNotice(false);
    setIsLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("competitorscount", 0);
    try {
      const response = await importCompetitors(bodyFormData);
      if (response?.status === "success" && response.fileData.length > 0) {
        const data = response.fileData.map((competitor) => ({
          key: competitor,
          competitor: competitor,
          action: "",
        }));
        dispatch(setCompetitors(data));
        dispatch(
          setFormState({ component: "addCompetitors", isComplete: true })
        );
        toast.success("Competitors Imported Successfully");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error("Competitors Import Failed.");
      }
      inputRef.current.value = null;
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.error) {
        setUploadError(error.response.data.error);
      } else {
        setUploadError(
          "There is some issue while uploading. Please check the data format in the given template and try uploading again."
        );
      }
      setShowUploadFailedNotice(true);
      setIsLoading(false);
      inputRef.current.value = null;
    }
  };

  const deleteFromDatabase = async () => {
    setIsDeleting(true);
    try {
      const newSelected = [...selected];
      let payload;
      if (newSelected.length > 0) {
        payload = {
          competitors_ids: newSelected,
          campaign_id: campaignId,
          user_id
        };
      } else {
        payload = {
          campaign_id: campaignId,
        };
      }
      const response = await deleteCompetitors(payload);
      if (response.status === "success") {
        let newData;
        if (selected.length > 0) {
          newData = competitors.filter(
            (item, index) => !selected.includes(item.key)
          );
        } else {
          newData = [];
        }
        dispatch(setCompetitors(newData));
        setIsDeleting(false);
        setShowConfirmDelete(false);
        setSelected([]);
        toast.success(`Deleted Successfully`);
      } else {
        toast.error(`Delete Failed`);
      }
    } catch (error) {}
  };

  const handleDelete = (key) => {
    if (isEdit) {
      deleteFromDatabase();
    } else {
      if (!selected.length > 0) {
        dispatch(setCompetitors([]));
        dispatch(
          setFormState({ component: "addCompetitors", isComplete: false })
        );
      } else {
        const newData = competitors.filter(
          (item, index) => !selected.includes(item.key)
        );
        dispatch(setCompetitors(newData));
      }
    }
  };

  const deleteSingleCompetitor = (key) => {
    if (isEdit) {
      setSelected([key]);
      setShowConfirmDelete(true);
    } else {
      const newData = competitors.filter((item) => item.key !== key);
      dispatch(setCompetitors(newData));
      const newSelected = selected.filter((id) => id !== key);
      setSelected(newSelected);
    }
  };

  const handleCreateCampaign = async () => {
    setIsCreatingCampaign(true);
    try {
      const userName = getLocal("userName");
      const projectId = getLocal("projectGId");
      const rankingBuckets = campaignDetails.buckets.map((bucket) => ({
        minimum: parseInt(bucket.start),
        maximum: parseInt(bucket.end),
      }));
      const payload = {
        campaignname: campaignDetails.name,
        domain: campaignDetails.domain,
        subdomain: campaignDetails.subDomain,
        location: campaignDetails.location,
        searchengine: campaignDetails.searchEngine,
        frequency: campaignDetails.frequency,
        device: campaignDetails.device,
        minsearchvolumetorso: parseInt(campaignDetails.torsoMin),
        maxsearchvolumetorso: parseInt(campaignDetails.torsoMax),
        projectid: projectId,
        isActive: true,
        updated_by: userName,
        rankingbuckets: rankingBuckets,
        keywords: keywords,
        compeititors: competitors,
      };
      const response = await createCampaign(payload);
      if (response.status === "success") {
        toast.success("Campaign Created Successfully.");
        dispatch(resetCampaignDetails());
        gotoCampaigns();
        setIsCreatingCampaign(false);
      } else {
        setConfirmationOpen(false);
        toast.error("Campaign Creation Failed.");
        setIsCreatingCampaign(false);
      }
    } catch (error) {
      setConfirmationOpen(false);
      toast.error("Campaign Creation Failed.");
      setIsCreatingCampaign(false);
    }
  };

  const handleUpdate = async () => {
    setIsCreatingCampaign(true);
    try {
      const userName = getLocal("userName");
      const rankingBuckets = campaignDetails.buckets.map((bucket) => ({
        minimum: parseInt(bucket.start),
        maximum: parseInt(bucket.end),
        id: bucket.id,
      }));
      const payload = {
        campaignname: campaignDetails.name,
        domain: campaignDetails.domain,
        subdomain: campaignDetails.subDomain,
        location: campaignDetails.location,
        searchengine: campaignDetails.searchEngine,
        frequency: campaignDetails.frequency,
        device: campaignDetails.device,
        minsearchvolumetorso: parseInt(campaignDetails.torsoMin),
        maxsearchvolumetorso: parseInt(campaignDetails.torsoMax),
        updated_by: userName,
        rankingbuckets: rankingBuckets,
      };
      const response = await upDateCampaign(campaignId, payload);
      if (response.status === "success") {
        toast.success("Campaign Updated Successfully.");
        dispatch(resetCampaignDetails());
        gotoCampaigns();
        setIsCreatingCampaign(false);
      } else {
        setConfirmationOpen(false);
        toast.error("Campaign Update Failed.");
        setIsCreatingCampaign(false);
      }
    } catch (error) {
      setConfirmationOpen(false);
      toast.error("Campaign Update Failed.");
      setIsCreatingCampaign(false);
    }
  };

  const formatExportData = (data) => {
    const formattedData = data.map((item) => {
      return {
        Urls: item.competitor,
      };
    });
    return formattedData;
  };

  return (
    <>
      <SpinContainer
        background={undefined}
        loading={isLoading}
        p="3px"
        size="xxl"
        theme="dark"
      >
        <div css={styles.main}>{getComponent()}</div>
      </SpinContainer>
      <input
        style={{ display: "none" }}
        type="file"
        id="fileinputCompetitors"
        name="fileinputCompetitors"
        multiple={false}
        accept={onlyXlsFile}
        ref={inputRef}
        onChange={() => {
          handleImport();
        }}
      />
      {isConfirmationOpen && (
        <ConfirmationModal
          icon={"info"}
          theme={"info"}
          title={"Review Your Campaign Setup"}
          confirmButtonText={isEdit ? "Save and Update" : "Complete Setup"}
          cancelButtonText={"Review Campaign"}
          onConfirm={() => {
            if (isEdit) {
              handleUpdate();
            } else {
              handleCreateCampaign();
            }
          }}
          onCancel={() => dispatch(setActiveStep("campaignDetails"))}
          isConfirmLoading={creatingCampaign}
        >
          <p css={styles.modalDescription}>
            Before finalizing your setup, we recommend taking a moment to review
            your campaign configuration to ensure everything is as you want it.
          </p>
        </ConfirmationModal>
      )}
      {showConfirmDelete && (
        <ConfirmationModal
          icon={"trash-2"}
          theme={"danger"}
          title={"Confirm Delete"}
          confirmButtonText={"Confirm Delete"}
          cancelButtonText={"Cancel"}
          onConfirm={() => {
            handleDelete();
          }}
          onCancel={() => setShowConfirmDelete(false)}
          isConfirmLoading={isDeleting}
        >
          <p css={styles.modalDescription}>
            This will permanently delete{" "}
            <span css={styles.redBold}>
              {selected.length > 0 ? selected.length : competitors.length}
            </span>{" "}
            <span css={styles.bold}>/ {competitors.length}</span> Competitors
            from this campaign and would no longer be tracked. Are you sure you
            want to delete these competitors.
          </p>
        </ConfirmationModal>
      )}
      {showConfirmImport && (
        <ConfirmationModal
          icon={"info"}
          theme={"info"}
          title={"Confirm Import"}
          confirmButtonText={"Confirm"}
          cancelButtonText={"Cancel"}
          onConfirm={() => {
            setShowConfirmImport(false);
            inputRef.current.click();
          }}
          onCancel={() => setShowConfirmImport(false)}
        >
          <p css={styles.modalDescription}>
            This will replace already uploaded compeititors from this campaign.
            Are you sure you want to re import these competitors.
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default AddCompetitors;
