import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";
import { Color } from "@progress/kendo-drawing";

export const main = (isLoading, isCompare, isCustom) => {
  return css({
    ".ant-table": {
      minHeight: "26rem",
      paddingBottom: isLoading? "0" :"0" 
    },
    ".ant-table table ": {
      borderSpacing: "0 0.125rem",
    },
    ".ranking-table-design .ant-pagination-item, .latest-table .ant-pagination-item":
      {
        minWidth: "30px",
        height: " 30px",
        border: 0,
      },
    ".ant-pagination-item-active": {
      background: "#E2E8F0",
    },
    ".ant-pagination-item": {
      border: 0,
      ...textStyles.s3,
      lineHeight: "24px !important",
    },
    ".ant-pagination-item-ellipsis": {
      height: "19px",
    },
    ".ant-table-content .ant-table-thead": {
      border: "1px solid black",
    },
    ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before":
      {
        display: "none",
      },
    ".ant-table-thead>tr>th": {
      backgroundColor: `${Colors.primary[75]} !important`,
      borderTop: `1px solid ${Colors.neutral[50]}`,
      borderBottom: `1px solid ${Colors.neutral[50]}`,
      padding: "0.375rem 1.12rem !important ",
    },
    ".ant-table-container table > thead > tr:first-child th:first-child": {
      borderTopLeftRadius: "0.375rem",
      borderBottomLeftRadius: "0.375rem",
      borderLeft: `1px solid ${Colors.neutral[50]}`,
    },
    ".ant-table-container table > thead > tr:first-child th:nth-child(4) > div": {
      display: isCompare ? "block" : "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      ".ant-table-filter-trigger" : {
        padding: 0
      }
    },
    ".ant-table-container table > thead > tr:first-child th:nth-child(5) > div": {
      display: "flex",
      flexDirection: isCompare ?"row-reverse" : "row" ,
      justifyContent: isCompare ? "flex-end" : "flex-start",
      ".ant-table-filter-trigger" : {
        padding: 0
      }
    },
    ".ant-table-container table > thead > tr:first-child th:nth-child(n+4) > div": {
      display: "flex",
      flexDirection: "row-reverse"  ,
      justifyContent:  "flex-end",
      ".ant-table-filter-trigger" : {
        padding: 0
      }
    },
    ".ant-table-container table > thead > tr:first-child th:nth-child(4) > div": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      ".ant-table-filter-trigger" : {
        padding: 0
      }
    },
    ".ant-table-container table > thead > tr:first-child th:last-child": {
      borderTopRightRadius: "0.375rem",
      borderBottomRightRadius: "0.375rem",
      borderRight: `1px solid ${Colors.neutral[50]}`,
      borderTop: `1px solid ${Colors.neutral[50]}`,
      borderBottom: `1px solid ${Colors.neutral[50]}`,
    },
    "ul.configuration-tab-list li:hover a, td:first-child.ant-table-cell div": {
      color: `${Colors.neutral[900]} !important`,
    },
    ".ant-table-container table > tbody > tr td:first-child": {
      borderTopLeftRadius: "0.375rem",
      borderBottomLeftRadius: "0.375rem",
      borderLeft: `1px solid ${Colors.neutral[50]}`,
    },
    ".ant-table-container table > tbody > tr td:last-child": {
      borderTopRightRadius: "0.375rem",
      borderBottomRightRadius: "0.375rem",
      borderRight: `1px solid ${Colors.neutral[50]}`,
    },
    ".ant-table-cell": {
      padding: "1.12rem 1.12rem !important",
      borderTop: `1px solid ${Colors.neutral[50]}`,
      borderBottom: `1px solid ${Colors.neutral[50]}`,
      // textAlign: "center"
    },
    ".ant-table-filter-column": {
      justifyContent: "flex-start",
      columnGap: "0.5rem",
      alignItems: "center",
    },
    ".ant-table-column-title": {
      flex: "none",
    },
    ".ant-table-column-sorters": {
      justifyContent: "flex-start",
      columnGap: "1rem",
    },
    ".ant-table-filter-trigger": {
      margin: 0,
    },
    ".ant-table-column-sorter": {
      marginLeft: 0,
    },
    ".ant-skeleton-element .ant-skeleton-button-sm": {
      height: "1.6rem !important",
    },
  });
};

export const sorterTitle = css({
  display: "flex"
})

export const title = css({
  ...textStyles.s1,
  color: Colors.black,
});

export const cell = css({
  ...textStyles.b1,
  color: Colors.neutral[900],
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  ellipsis: true,
});

export const flexCell = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start", 
})

export const rank = css({
  minWidth: "2rem"
})

export const status = (isImproved, isZero) => {
  return css({
    color: isZero
      ? Colors.secondary[500]
      : isImproved
      ? Colors.semantic[500]
      : Colors.error[800],
  });
};

export const keywordCell = css({
  display: "flex",
  alignItems: "center",
  columnGap: "0.38rem",
});

export const position = (position) => {
  return css({
    display: "block",
    fontFamily: "Montserrat",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1rem",
    color:
      position === "Head"
        ? Colors.primary[800]
        : position === "Torso"
        ? Colors.primary[600]
        : Colors.primary[200],
    borderColor:
      position === "Head"
        ? Colors.primary[800]
        : position === "Torso"
        ? Colors.primary[600]
        : Colors.primary[200],
    borderWidth: "1px",
    borderStyle: "solid",
    height: "1rem",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 0.2rem"
  });
};

export const positionText = (position) => {
  return css({
    color:
      position === "Head"
        ? `${Colors.primary[800]} !important`
        : position === "Torso"
        ? Colors.primary[600]
        : Colors.primary[200],
    
  });
};

export const category = css({
  marginRight: "0.75rem"
})

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

export const customSearchIcon = css({
  marginLeft: "0.75rem",
})

export const searchInput = css({
  ...textStyles.b3,
  width: "18.8125rem",
  border: `1px solid ${Colors.neutral[800]}`,
});

export const date = css({
  ...textStyles.b1,
  color: Colors.neutral[600],
});

export const popper = css({
  height: "15rem",
  overflowY: "scroll",
});

export const option = (isActive) =>{ 
  return css({
  ...textStyles.b3,
  padding: "0.38rem 0.5rem !important",
  cursor: "pointer",
  color: Colors.neutral[900],
  backgroundColor: isActive ? "rgba(196, 229, 254, 0.7)": Colors.white,
  boxShadow:isActive ? "2px 0 0 0 #008ff8 inset": "none"
})};

export const chartIcon = {};

export const progressContainer = (isPositive) => {
  return css({
    color: isPositive
      ? `${Colors.semantic[500]} !important`
      : `${Colors.error[800]} !important`,
  });
};

export const progress = (isPositive) => {
  return css({
    ...textStyles.b3,
  });
};

export const icon = (isPositive) => {
  return css({
    height: "1rem",
    width: "1rem",
  });
};

export const searchVolume = css({
  padding: "0.5rem",
  ...textStyles.b3,
});

export const errorScreen = css({
  minHeight: "19rem"
})