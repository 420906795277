export const defaultLocations = [
  { label: "India", value: "IN", type: "Country" },
  { label: "United States of America", value: "US", type: "Country" },
  { label: "United Arab Emirates", value: "AE", type: "Country" },
  { label: "South Africa", value: "ZA", type: "Country" },
  { label: "United Kingdom", value: "GB", type: "Country" },
  { label: "Saudi Arabia", value: "SA", type: "Country" },
  { label: "Australia", value: "AU", type: "Country" },
  { label: "Afghanistan", value: "AF", type: "Country" },
  { label: "Albania", value: "AL", type: "Country" },
  { label: "Algeria", value: "DZ", type: "Country" },
];
