/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";

const RemainingCreditsCard = ({ keywordCount, totalCredits,isEdit }) => {
  return (
    <div css={styles.main}>
      <div css={styles.details(false)}>
        <div css={styles.count}>{keywordCount}</div>
        <div css={styles.label}>Keywords Added</div>
      </div>
      <div css={styles.details}>
        <div
          css={[
            styles.count,
             totalCredits > 0 ? styles.success : styles.failure,
          ]}
        >
          {totalCredits - (isEdit ? 0 : keywordCount)}
        </div>
        <div css={styles.label}>Credits Remaining</div>
      </div>
    </div>
  );
};

export default RemainingCreditsCard;
