import { css } from "@emotion/react";
import { Colors } from "../../styles/colors";
import { textStyles } from "../../styles/font";
import { Home } from "feather-icons-react";

export const nestedDropdownContainer = css({
  width: "18rem",
});

export const headerLeft = css({
  display: "flex",
  columnGap: "0.75rem",
  justifyContent: "flex-end",
});

export const headerContainer = css({
  marginBottom: "1.5rem",
  // position: "absolute",
  // top: "4.9rem",
  // left: "4.9rem",
  // right: 0,
  // zIndex: 5
});

export const headerFixed = css({
  position: "absolute",
  top: "4.9rem",
  left: 0,
  right: 0,
  zIndex: 5
})

export const wrapper = (isCollapsed, isLocked) => css({
  paddingLeft: !isLocked ? "4.937rem" : isCollapsed ? "4.937rem" : "22.5rem",
  "@media (max-width: 1024.98px)": {
      paddingLeft: 0,
    },
})

export const keyDistributionContainer = css({
  backgroundColor: Colors.plainWhite,
  borderRadius: "0.75rem",
});

export const keyDistributionTabs = css({
  display: "flex",
  width: "100%",
});

export const comparisionCardcontainer = css({
  padding: "1.5rem",
});

export const exportTableContainer = css({
  backgroundColor: Colors.plainWhite,
  borderRadius: "0.75rem",
  marginTop: "1.5rem",
});

export const exportTableHeader = css({
  borderBottom: `1px solid ${Colors.neutral[50]}`,
});

export const selectDropDownContainer = css({
  width: "14.25rem",
});

export const keyword = css({
  ...textStyles.b1,
  color: Colors.neutral[900],
});

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

export const searchInput = css({
  ...textStyles.b3,
  width: "18.8125rem",
  border: `1px solid ${Colors.neutral[800]}`,
});

export const deviceContainer = css({});

export const switchContainer = css({
  display: "flex",
  border: `1px solid ${Colors.black}`,
  borderRadius: "0.375rem",
  padding: "0.15rem",
});

export const buttonGraph = (isGraphView, isSingle) => {
  return css({
    display: "flex",
    alignItems: "center",
    padding: "0.14rem 0.5rem",
    backgroundColor: isSingle ? "" : isGraphView ? Colors.primary[500] : "",
    borderRadius: "0.375rem",
    boxSizing: "border-box",
    cursor :"pointer"
  });
};

export const icon = () => {
  return css({
    width: "1.27005rem",
    height: "1.60169rem",
  });
};
export const mobileIcon = () => {
  return css({
    width: "0.87005rem",
    height: "1.60169rem",
  });
};

export const buttonTable = (isActive, isSingle) => {
  return css({
    padding: "0.14rem 0.7rem",
    backgroundColor: isSingle ? "" : isActive ? Colors.primary[500] : "",
    borderRadius: "0.375rem",
    boxSizing: "border-box",
    cursor :"pointer"
  });
};

export const exportLoader = css({
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 10,
})

export const content = css({
  marginTop: "5rem",
})