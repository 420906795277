import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  getAccountlist,
  getGA4Data,
  getGAdData,
  getGoogleAnalyticsConfig,
  getPropertylist,
  getViewSelectionlist,
} from "../../utility/Constant";
import { isValidJsonFile } from "../../utility/Globals";
import { Tabs } from "antd";

const GoogleAnalytics = ({ update }) => {
  const [viewSelection, setviewSelection] = useState([]);
  const [propertylist, setpropertylist] = useState([]);
  const [accountlist, setaccountlist] = useState([]);
  const [accountTypeSelected, setaccountTypeSelected] = useState("");
  const [propertyTypeSelected, setpropertyTypeSelected] = useState("");
  const [viewselected, setviewselected] = useState("");
  const [fileUpload, setfileUpload] = useState(null);
  const [isView, setIsView] = useState(false);
  const [acquistionCheckbox, setacquistionCheckbox] = useState(false);
  const [activeTab, setactiveTab] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [fileUploadGA4, setfileUploadGA4] = useState(null);
  let isFileUpload = false

  useEffect(() => {
    getConfigData();
  }, []);

  function getAccountlistData() {
    APIManager.getAPI(getAccountlist + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status === 200) {
          if (response.data.result.items !== null) {
            setaccountlist(response.data.result.items);
          }
        }
      }
    );
  }

  function getaccountselected(e) {
    var acc_selected = e.target.value;
    getPropertylistData(acc_selected);
    setaccountTypeSelected(acc_selected);
  }

  function getPropertylistData(accountId) {
    APIManager.getAPI(
      getPropertylist +
      "?accountId=" +
      accountId +
      "&projectId=" +
      localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data.result.items !== null) {
          setpropertylist(response.data.result.items);
        }
      }
    });
  }

  function getpropertyselected(e) {
    var propr_selected = e.target.value;
    setpropertyTypeSelected(propr_selected);
    getViewSelectionlistData(accountTypeSelected, propr_selected);
  }

  function getViewSelectionlistData(a_id, p_id) {
    APIManager.getAPI(
      getViewSelectionlist +
      "?accountId=" +
      a_id +
      "&properyId=" +
      p_id +
      "&projectId=" +
      localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data.result.items != null) {
          setviewSelection(response.data.result.items);
        }
      }
    });
  }

  function getViewselected(e) {
    var view_selected = e.target.value;
    setviewselected(view_selected);
  }

  function updateFile() {
    let input = document.getElementById("myfile");
    const fileExtension = input.files[0].name.split(".").at(-1);

    let isvalid = isValidJsonFile(fileExtension)
    if (isvalid === true) {
      let data = [];
      for (let i = 0; i < input.files.length; ++i) {
        data.push(input.files.item(i).name);
      }
      setfileUpload(input.files.item(0));
      isFileUpload = true;
      addUpdateGoogleAnalytics(input.files.item(0));
    }
    else {
      document.getElementById("myfile").value = "";
    }
  }

  function updateGA4File() {
    let input = document.getElementById("myfileGA4");
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidJsonFile(fileExtension)
    if (isvalid === true) {
      let data = [];
      for (let i = 0; i < input.files.length; ++i) {
        data.push(input.files.item(i).name);
      }
      setfileUploadGA4(input.files.item(0));
      isFileUpload = true;
    }
    else {
      document.getElementById("myfileGA4").value = "";
    }
  }

  function getConfigData() {
    APIManager.getAPI(
      getGoogleAnalyticsConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        response.data.isGA4 ? setactiveTab('GA4') : setactiveTab('UniversalAnalytics');
        setacquistionCheckbox(response.data.acquistionCheckbox);
        response.data.isGA4 ?
          setfileUploadGA4({ name: response.data.credentials.split("\\")[1] }) :
          setfileUpload({ name: response.data.credentials.split("\\")[1] });
        setaccountTypeSelected(response.data.accounts);
        getPropertylistData(response.data.accounts);
        response.data.isGA4 ?
          setPropertyId(response.data.property) :
          setpropertyTypeSelected(response.data.property);
        getViewSelectionlistData(
          response.data.accounts,
          response.data.property
        );
        setviewselected(response.data.view);
        setIsView(true);
        getAccountlistData();
      }
    });
  }

  function addUpdateGoogleAnalytics(file, type) {
    var bodyFormData = new FormData();
    if (type === 'UA') {
      bodyFormData.append("File", file);
      bodyFormData.append("AcquistionCheckbox", acquistionCheckbox);
      bodyFormData.append("Accounts", accountTypeSelected);
      bodyFormData.append("Property", propertyTypeSelected);
      bodyFormData.append("View", viewselected);
      bodyFormData.append("projectId", localStorage.getItem("projectGId"));
      APIManager.postAPI(getGAdData, {}, bodyFormData, false).then((response) => {
        if (response && response.status === 200) {
          setIsView(true);
          getAccountlistData();
          if (!isFileUpload) {
            update("");
          }
        }
      });
    } else {
      bodyFormData.append("File", file);
      bodyFormData.append("Property", propertyId);
      bodyFormData.append("projectId", localStorage.getItem("projectGId"));
      APIManager.postAPI(getGA4Data, {}, bodyFormData, false).then((response) => {
        if (response && response.status === 200) {
          setIsView(true);
          getAccountlistData();
          if (!isFileUpload) {
            update("");
          }
        }
      });
    }

  }

  function changePropertyId(e) {
    setPropertyId(e.target.value);
  }

  return (
    <div className="custom-column-70">
      <div className="portlet common-form-fields slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Traffic
          </h3>
        </div>

        <div class="title-content-tab ua-ga-tab">
          <Tabs
            activeKey={activeTab}
            onChange={(e) => {
              setactiveTab(e);
            }}
          >
            <Tabs.TabPane
              tab="Universal Analytics (UA)"
              key="UniversalAnalytics"
              className="ua-tab"
            // disabled={view !== true}
            >
              <div class="tab-content m-0" id="pills-tabContent" style={{ background: '#fff' }}>
                <div
                  class="portlet-border-0 tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="portlet-body traffic_file p-0">
                    <div class="col-md-12 d-flex align-items-center credential_document">
                      <label class="form-label me-4 title-part "> Credential Document Upload</label>
                      <div className="align-items-center file-upload flex-wrap">
                        <input
                          type="file"
                          id="myfile"
                          name="myfile"
                          className="d-inline-block"
                          multiple={false}
                          onChange={updateFile}
                        />
                        <label className="ga-file ms-0 mb-0 d-flex align-items-center" id="fileLabel">
                          {fileUpload?.name}
                        </label>
                      </div>

                    </div>
                    <hr style={{ border: '0.01pt solid rgb(214 214 214)' }} />
                    {isView ? (
                      <div className="form-box">
                        <div className="col-md-12 googleanalytics mt-4">
                          <input
                            type="checkbox"
                            checked={acquistionCheckbox}
                            onChange={(e) => setacquistionCheckbox(!acquistionCheckbox)}
                          ></input>
                          <label className="form-label ms-2 mb-0">Acquistion </label>
                        </div>

                        <div class="col-md-12 mt-4">
                          <label for="acc" className="form-label me-2">
                            Account
                          </label>
                          <select
                            style={{ padding: 8 + "px" }}
                            value={accountTypeSelected}
                            onChange={getaccountselected}
                            name="acc"
                            id="acc"
                            class="w-100"
                          >
                            <option value="" selected="selected">
                              Select Account
                            </option>
                            {accountlist.map((item) => {
                              return <option value={item.id}>{item.name}</option>;
                            })}
                          </select>
                        </div>

                        <div class="col-md-12 mt-3">
                          <label className="form-label" for="acc">
                            Property
                          </label>
                          <select
                            style={{ padding: 8 + "px" }}
                            value={propertyTypeSelected}
                            onChange={getpropertyselected}
                            name="acc"
                            id="acc"
                            class="w-100"
                          >
                            <option value="" selected="selected">
                              Select Property
                            </option>
                            {propertylist.map((item) => {
                              return <option value={item.id}>{item.name}</option>;
                            })}
                          </select>
                        </div>

                        <div class="col-md-12 mt-3">
                          <label className="form-label" for="acc">
                            View Selection
                          </label>
                          <select
                            style={{ padding: 8 + "px" }}
                            value={viewselected}
                            onChange={getViewselected}
                            class="w-100"
                          >
                            <option value="" selected="selected">
                              Select View
                            </option>
                            {viewSelection.map((item) => {
                              return <option value={item.id}>{item.name}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="portlet-footer portlet-footer-bordered mx-3 mt-3 ps-0">
                    <button
                      class="btn btn-primary"
                      onClick={() => {
                        isFileUpload = false
                        addUpdateGoogleAnalytics(fileUpload, 'UA');
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane
              class="personal"
              tab="GA4"
              key="GA4"
              className="ga-tab"
            >
              <div class="personal tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="portlet-body traffic_file p-0">
                    <div class="col-md-12 d-flex align-items-center credential_document">
                      <label class="form-label title-part me-4"> Credential Document Upload</label>
                      <div className="d-flex align-items-center file-upload flex-wrap">
                        <input
                          type="file"
                          id="myfileGA4"
                          name="myfileGA4"
                          multiple={false}
                          onChange={updateGA4File}
                        />
                       
                        <label className="ga-file ms-0" id="fileLabel">
                          {fileUploadGA4?.name}
                        </label>
                        <div>
                        </div>
                      
                      </div>
                    </div>
                    <hr style={{ border: '0.01pt solid rgb(214 214 214)' }} />

                    {isView ? (
                      <div className="form-box pb-3">
                        <div className="col-md-12 mt-4">
                          <label className="form-label" for="acc">
                            Property ID
                          </label>
                          <input type="text" value={propertyId} onChange={changePropertyId} className="form-control" placeholder="eg. 123456789" />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="portlet-footer portlet-footer-bordered">
                    <div className="col-md-12 pl-0">
                      <button
                        disabled={!propertyId || !fileUploadGA4}
                        className={"btn btn-primary " + (!propertyId || !fileUploadGA4 ? 'disable' : '')}
                        onClick={() => {
                          isFileUpload = false;
                          addUpdateGoogleAnalytics(fileUploadGA4, 'GA');
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>


      </div>
    </div>
  );
};

export default GoogleAnalytics;
