import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  padding: "1.25rem 1.5rem",
  border: `1px solid ${Colors.neutral[50]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "0.38rem",
  borderRadius: "2.625rem",
  width: "100%",
  boxShadow: Colors.pillShadow
});

export const left = css({
  display: "flex",
  alignItems: "center",
  columnGap: "0.38rem",
});

export const right = css({});

export const icon = css({
  height: "1rem",
  width: "1rem",
  color: Colors.neutral[400],
});

export const tooltipPopper = css({
  ...textStyles.b3,
  color: Colors.neutral[800],
  padding: "0.75rem",
});

export const label = css({
  ...textStyles.b3,
  color: Colors.neutral[800],
});
