import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const timeWidgetContainer = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "1rem",
    backgroundColor: Colors.plainWhite,
    border: `1px solid ${Colors.neutral[50]}`,
    borderRadius: "6px"
})

export const secondaryButton = css({
    "button": {
        ...textStyles.s2Medium,
        marginRight: "1rem",
        color: `${Colors.primary[500]} !important`,
        border: `1px solid ${Colors.primary[500]} !important`,
        backgroundColor: `${Colors.plainWhite} !important`,
        ":hover": {
            backgroundColor: `${Colors.primary[50]} !important`
        }
    }
})

export const timeInput = (active) => {
    let color = Colors.black
    return css({
        ...textStyles.H1125SemiBold,
        color: color,
        cursor: "default",
        maxWidth: "4rem",
        overflowX: "scroll",
        borderRadius: "8px",
        border: `1px solid ${color}`,
        display: "flex",
        padding: "0.5rem",
        "::-webkit-scrollbar": {
            display: "none",
            width: 0
        },
    })
}

export const inputSeperator = css({
    ...textStyles.H18SemiBold,
    margin: "0 0.5rem 1.5rem 0.5rem"
})

export const timeModalSection = css({
    padding: "1rem",
    borderBottom: `1px solid ${Colors.neutral[50]}`
})

export const timeInputSection = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})

export const errorMsg = css({
    ...textStyles.b1,
    // paddingTop: "2rem",
    color: Colors.error[800]
})

export const modalFooter = css({
    display: "flex",
    flexDirection: "row-reverse",
    padding: "2rem 0 1rem 0",
})