import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import { deleteCountryById, getCountryByOrg } from "../../utility/Constant";
import {
  exportToEXCEL,
  getCheckPermission,
} from "../../utility/Globals";
import EditCountryDir from "./EditCountryDir";
import { Link, useNavigate } from "react-router-dom";

const Country = ({ update }) => {
  const navigate = useNavigate();
  const [exportList, setExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [usercountry, setusercountry] = useState([]);
  const [usercountryList, setusercountryList] = useState([]);
  const [usercountrycol, setusercountrycol] = useState([]);
  const [settings, setSetting] = useState("country");

  useEffect(() => {
    var data = [
      {
        title: "Country Name",
        dataIndex: "countryname",

        key: "countryname",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.countryname, b.countryname),
          multiple: 3,
        },
      },
      {
        title: "Country Code",
        dataIndex: "countryCode",
        key: "countryCode",
      },
      {
        title: "Location Code",
        dataIndex: "locationCode",
        key: "locationCode",
      },
      {
        title: "UULE",
        dataIndex: "uule",
        key: "uule",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setusercountrycol(data);
    getCountryData();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item, index) => {
        let obj = {
          key: item.key,
          countryname: item.countryname? item.countryname.trim() : "",
          countryCode: item.countryCode ? item.countryCode.trim() : "",
          locationCode: item.locationCode ? item.locationCode.trim() : "",
          uule: item.uule ? item.uule.trim() : "",
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function getCountryData() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        let i = 0;
        response.data.forEach((item) => {
          exportList.push({
            key: (i += 1),
            countryname: item.countryName? item.countryName.trim() : "",
            countryCode: item.countryCode ? item.countryCode.trim() : "",
            locationCode: item.locationCode ? item.locationCode.trim() : "",
            uule: item.uule ? item.uule.trim() : "",
          });
          let obj = {
            key: (i += 1),
            id: item.id,
            countryname: item.countryName ? item.countryName.trim() :"", 
            countryCode: item.countryCode ? item.countryCode.trim() : "",
            locationCode: item.locationCode ? item.locationCode.trim() : "",
            uule: item.uule ? item.uule.trim() : "",
            action: (
              <div className="d-flex text-center">
                 {getCheckPermission("edit", "organization_setup") ?
                <Link
                  state={{ item: item, isEdit: true }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon"
                    onClick={() => {
                      setSetting("editCountry");
                      navigate("",{
                        state: { item: item, isEdit: true },
                      });
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Link>
                : <></>}
                {getCheckPermission("delete", "organization_setup") ?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon ms-3 mt-1"
                  onClick={() => {
                    deleteCountryItem(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
                : <></>}
              </div>
            ),
          };
          list.push(obj);
        });
        setusercountry(list);
        setusercountryList(list);
        setExportList(exportList);
      }
    });
  }

  async function deleteCountryItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteCountryById + id);
      if (response === 200) {
        getCountryData();
      }
    } catch (e) {}
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getCountryData();
  };

  function getComponent() {
    if (settings === "editCountry") {
      return <EditCountryDir update={onClickFunction} />;
    }
  }

  return (
    <>
      <div className="row common-mb-26 country_main">
        {settings === "country" ? (
          <div className="portlet p-0 slideInUp setting-top">
            <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 className="portlet-title main-title">
                {" "}
                {localStorage.getItem("organizationName")} - Country
              </h3>
            </div>
            <div className="portlet-body">
              <div class="row ">
                <div className="col-sm-6 col-md-8 d-flex justify-content-start">
                  <div class="input-group-icon input-group-lg widget15-compact">
                    <div class="input-group-prepend">
                      <i class="fa fa-search text-primary"></i>
                    </div>
                    <div className="search-box">
                      <span>
                        <Input
                          allowClear
                          style={{ height: 36 + "px" }}
                          type="text"
                          class="form-control"
                          placeholder="Type to search..."
                          onChange={(nameSearch) => {
                            setusercountry(
                              usercountryList.filter((person) =>
                                person.countryname
                                  .toLocaleLowerCase()
                                  .includes(
                                    nameSearch.target.value.toLocaleLowerCase()
                                  )
                              )
                            );
                          }}
                          id="input-s"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-4 mt-3 mt-sm-0">
                  <div class="data-export-client me-3">
                    <span class="count-drop"></span>
                    {getCheckPermission( "add", "organization_setup") ?
                    <Link
                      class="btn btn-primary add"
                      state={{ isEdit: false }}
                      onClick={() => {
                        setSetting("editCountry");
                      }}
                    >
                      Add
                    </Link>
                    : <></>}
                    <span class="dropdown d-inline ">
                      <button
                        class="btn btn-primary dropdown-toggle ms-3 export-btn"
                        type="button"
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Country"
                          );
                        }}
                      >
                        Export
                      </button>
                      {/* <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            exportToEXCEL(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "Country"
                            );
                          }}
                        >
                          <a class="dropdown-item">Excel</a>
                        </div>
                        <div
                          onClick={() => {
                            exportInToCSV(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "Country"
                            );
                          }}
                        >
                          <a class="dropdown-item">CSV</a>
                        </div>
                      </div> */}
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <Table
                  className="table table-bordered table-hover mt-3 country-table"
                  id="sample-module-expand"
                  dataSource={usercountry}
                  columns={usercountrycol}
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          getComponent()
        )}
      </div>
    </>
  );
};

export default Country;
