import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { ProgressBar } from "react-bootstrap";
import { convertDate, formateDateIntoDatemonth, getDaysInMonth, setMonths } from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import { requirementProject, getallpoc, updateProjectPoc, updateturnaroundtime } from "../../utility/Constant";
import { Pagination } from 'antd';
import { useNavigate } from "react-router-dom";

function RequirementContent() {
    // localStorage.setItem("openCalendar", false); 
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [head1, setHead1] = useState(
        new Date((new Date().getMonth() + 1) + "-01-" +
            (new Date().getFullYear())).toDateString());
    const [head2, setHead2] = useState(
        new Date((new Date().getMonth() + 1) + "-" +
            getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) + "-" +
            (new Date().getFullYear())).toDateString());
    const [monthYear, setMonthYear] = useState(setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear());
    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [poclist, setPocList] = useState([]);
    const [filterProjectId, setFilterProjectId] = useState("");
    const [Datevalues, setDateValues] = useState(new Date());
    const [requirement, setRequirement] = useState(false);
    const [pageNumber1, setPageNumber1] = useState(1);
    const [pageSize1, setPageSize1] = useState(10);
    const [listTotal1, setListTotal1] = useState(0)
    const [pageNumber2, setPageNumber2] = useState(1);
    const [pageSize2, setPageSize2] = useState(10);
    const [listTotal2, setListTotal2] = useState(0)
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        let rowData;
        if (requirement === false) {
            rowData = {
                UserId: localStorage.getItem('userId'),
                StartDate: convertDate(head1),
                endDate: convertDate(head2),
                IsAll: requirement,
                CurrentPage: pageNumber2,
                PageSize: pageSize2,
                Search: searchText
            }
        }
        else {
            rowData = {
                StartDate: convertDate(head1),
                endDate: convertDate(head2),
                IsAll: requirement,
                CurrentPage: pageNumber1,
                PageSize: pageSize1,
                Search: searchText
            }
        }
        getProjectList(rowData, requirement);
        // localStorage.setItem("openCalendar", false);
    }, []);

    const ref = useRef();

    // useEffect(() => {
    //     // const checkIfClickedOutside = (e) => {
    //     //     if (open && ref.current && !ref.current.contains(e.target)) {
    //     //         setOpen(false);
    //     //     }
    //     // };
    // //     document.addEventListener("mousedown", checkIfClickedOutside);
    // //     return () => {
    // //         document.removeEventListener("mousedown", checkIfClickedOutside);
    // //     };
    // }, [open]);

    useEffect(() => {
    //     const checkIfClickedOutside = (e) => {
    //         if (openCalendar && ref.current && !ref.current.contains(e.target)) {
    //             setOpenCalendar(false);
    //             localStorage.setItem("openCalendar", false);
    //         }
    //     };
    //     document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            localStorage.setItem("openCalendar", false);
        };
    }, []);

    function getProjectList(rowData, req) {
        APIManager.postAPI(requirementProject, rowData, null, true).then((res) => {
            if (req == true) {
                getPoc();
                setList1(res.data.table1);
                setList([]);
                setFilterList(res.data.table1);
                res.data.table.map((item) => {
                    setListTotal1(item.totalCount)
                })
            }
            else {
                if (localStorage.getItem('userId') == null) {
                    setList([]);
                    setFilterList([]);
                } else {
                    getPoc();
                    setList(res.data.table1);
                    setList1([]);
                    setFilterList(res.data.table1);
                    res.data.table.map((item) => {
                        setListTotal2(item.totalCount)
                    })
                }
            }
        });
    }

    const searchProject = (searchValue) => {
        // if (list.length > 0) {
        //     let searchList = list.filter((item) => { return item.projectName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) });

        //     if (searchList.length > 0)
        //         setFilterList(searchList);
        //     else
        //         setFilterList(list.filter((item) => { return item.niche?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) }));
        // }
        // else if (list1.length > 0) {

        //     let searchList = list1.filter((item) => { return item.projectName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) });

        //     if (searchList.length > 0)
        //         setFilterList(searchList);
        //     else
        //         setFilterList(list1.filter((item) => { return item.niche?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) }));
        // }
        setSearchText(searchValue)
        let rowData;
        if (requirement === false) {
            rowData = {
                UserId: localStorage.getItem('userId'),
                StartDate: convertDate(head1),
                endDate: convertDate(head2),
                IsAll: requirement,
                CurrentPage: pageNumber2,
                PageSize: pageSize2,
                Search: searchValue
            }
        }
        else {
            rowData = {
                StartDate: convertDate(head1),
                endDate: convertDate(head2),
                IsAll: requirement,
                CurrentPage: pageNumber1,
                PageSize: pageSize1,
                Search: searchValue
            }
        }
        getProjectList(rowData, requirement);
    }

    function getPoc() {
        APIManager.getAPI(
            getallpoc
        ).then((response) => {
            if (response.status == 200) {
                let list = [];
                response.data.map((item) => {
                    list.push({
                        value: item.id,
                        label: item.name,
                    });
                })
                setPocList(list);
            }
        });
    }

    const updatePoc = (id, projectID) => {

        let rawData = {
            ProjectId: projectID,
            PocId: id
        }
        APIManager.putAPI(updateProjectPoc, rawData, {}, true).then((response) => {
            if (response.status == 200) {
                let rowData;
                if (requirement === false) {
                    rowData = {
                        UserId: localStorage.getItem('userId'),
                        StartDate: convertDate(head1),
                        endDate: convertDate(head2),
                        IsAll: requirement,
                        CurrentPage: pageNumber2,
                        PageSize: pageSize2,
                        Search: searchText

                    }
                }
                else {
                    rowData = {
                        StartDate: convertDate(head1),
                        endDate: convertDate(head2),
                        IsAll: requirement,
                        CurrentPage: pageNumber1,
                        PageSize: pageSize1,
                        Search: searchText

                    }
                }
                getProjectList(rowData, requirement);
            }
        });
    }

    function clearForm() {
        document.getElementById("search").value = "";
    }

    function UpdateTrunAroundTime(projectid, date) {
        APIManager.putAPI(updateturnaroundtime + "?projectId=" + projectid + "&date=" + convertDate(date)).then((response) => {
            if (response.status == 200) {
                setOpenCalendar(false);
                localStorage.setItem("openCalendar", false);
                let rowData = {
                    UserId: localStorage.getItem('userId'),
                    StartDate: convertDate(head1),
                    endDate: convertDate(head2),
                    IsAll: requirement,
                    CurrentPage: pageNumber2,
                    PageSize: pageSize2,
                    Search: searchText
                }
                getProjectList(rowData, false);
            }
        });
    }

    const handleChange2 = (page, pageSize) => {
        setPageNumber2(page == 0 ? 1 : page)
        setPageSize2(pageSize)
        let rowData = {
            UserId: localStorage.getItem('userId'),
            StartDate: convertDate(head1),
            endDate: convertDate(head2),
            IsAll: requirement,
            CurrentPage: page == 0 ? 1 : page,
            PageSize: pageSize,
            Search: searchText
        }
        getProjectList(rowData, requirement);
    };

    const handleChange1 = (page, pageSize) => {
        setPageNumber1(page == 0 ? 1 : page)
        setPageSize1(pageSize)
        let rowData = {
            UserId: localStorage.getItem('userId'),
            StartDate: convertDate(head1),
            endDate: convertDate(head2),
            IsAll: requirement,
            CurrentPage: page == 0 ? 1 : page,
            PageSize: pageSize,
            Search: searchText
        }
        getProjectList(rowData, requirement);

    };

    return (
        <>
            <Tabs className="h-100 position-relative infi-requirement cms_requierment_main">
                <div className="portlet requirement-content portlet-header portlet-header-bordered px-0 ">
                    <div className="col-md-12 col-sm-12">
                        <TabList className="nav nav-lines portlet-nav">
                            <Tab
                                className={
                                    (requirement === false)
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                                onClick={() => {
                                    setSearchText("")
                                    setRequirement(false);
                                    setPageNumber2(1)
                                    setPageSize2(10)
                                    let rowData = {
                                        UserId: localStorage.getItem('userId'),
                                        StartDate: convertDate(head1),
                                        endDate: convertDate(head2),
                                        IsAll: false,
                                        CurrentPage: 1,
                                        PageSize: 10,
                                        Search: ""
                                    }
                                    getProjectList(rowData, false);
                                    clearForm();
                                }}
                            >
                                My Projects
                            </Tab>
                            <Tab
                                className={
                                    (requirement === true)
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                                onClick={() => {
                                    setSearchText("")
                                    setRequirement(true);
                                    setPageNumber1(1)
                                    setPageSize1(10)
                                    let rowData = {
                                        StartDate: convertDate(head1),
                                        endDate: convertDate(head2),
                                        IsAll: true,
                                        CurrentPage: 1,
                                        PageSize: 10,
                                        Search: ""
                                    }
                                    getProjectList(rowData, true);
                                    clearForm();
                                }}
                            >
                                All Projects
                            </Tab>
                        </TabList>
                    </div>
                </div>

                <div class="work-history-box px-0">
                    <div className="d-flex align-items-center myproject-search">

                        <div class="work-history-search w-100">
                            <form class="search-box w-100 d-flex">
                                <button type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                                <input
                                    type="text"
                                    class="scope-input w-100"
                                    placeholder="Search..."
                                    name="search"
                                    id="search"
                                    autoComplete="false"
                                    onChange={(e) => {
                                        searchProject(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </form>
                        </div>
                        <div ref={ref} className="d-flex align-items-center require-cal cms_fs16 cms_fw500 cms_fc_black cms_line-height cms_radius8 bg-white ms-3 cursor-pointer" >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-calendar me-2 ms-1"
                            >
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                <line x1="3" y1="10" x2="21" y2="10"></line>
                            </svg>
                            <div
                                onClick={() => setOpen(!open)}
                                style={{ width: "max-content" }}
                            >
                                {monthYear}
                            </div>

                            {open && (

                                <div className="require-content-calendar position-relative">
                                    <div id="example-collapse-text-calendar" >
                                        <Card body className="daterange-picker-card  mt-2">
                                            <div className=" d-flex justify-content-between">
                                                <div className="calendar-col">
                                                    <Calendar
                                                        // date={new Date()}
                                                        maxDate={new Date()}
                                                        onlyMonthPicker
                                                        onChange={(e) => {
                                                            var date1 = e.format().split("/");
                                                            var daysInMonth = getDaysInMonth(
                                                                date1[1],
                                                                date1[0]
                                                            );
                                                            let dateObjArr = [
                                                                new Date(date1[0] + "/01/" + date1[1]),
                                                                new Date(
                                                                    date1[0] +
                                                                    "/" +
                                                                    daysInMonth +
                                                                    "/" +
                                                                    date1[1]
                                                                ),
                                                            ];
                                                            setMonthYear(setMonths(dateObjArr[0].getMonth() + 1) + " " + date1[1]);
                                                            setHead1(
                                                                dateObjArr[0].toLocaleDateString()
                                                            );
                                                            setHead2(
                                                                dateObjArr[1].toLocaleDateString()
                                                            );
                                                        }}

                                                    />
                                                </div>

                                                <div className="mt-3 mt-lg-0 text-center">
                                                    <button
                                                        onClick={() => {
                                                            setOpen(false);
                                                            let rowData;

                                                            if (requirement === false) {
                                                                rowData = {
                                                                    UserId: localStorage.getItem('userId'),
                                                                    StartDate: convertDate(head1),
                                                                    endDate: convertDate(head2),
                                                                    IsAll: requirement,
                                                                    CurrentPage: pageNumber2,
                                                                    PageSize: pageSize2,
                                                                    Search: searchText
                                                                }
                                                            }
                                                            else {
                                                                rowData = {
                                                                    StartDate: convertDate(head1),
                                                                    endDate: convertDate(head2),
                                                                    IsAll: requirement,
                                                                    CurrentPage: pageNumber1,
                                                                    PageSize: pageSize1,
                                                                    Search: searchText
                                                                }
                                                            }
                                                            getProjectList(rowData, requirement);
                                                        }}
                                                        className="btn btn-primary ms-3"
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            )}

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                        </div>

                    </div>
                </div>

                {/* Start My project */}

                <TabPanel>
                    <div class="align-items-center">
                        <div className="row html2pdf__page-break all-channel-table requirementcontent-box">
                            <div className="px-0 channel_content_block">
                                <div className="allchannel-table mb-4 global-scrollbar require_project_scroll">
                                    <div className="row ">
                                        {filterList.length > 0 ?
                                            (<>
                                                {filterList.map((item) => {
                                                    return (<>
                                                        <div className="col-md-6 col-sm-12 mt-3 p-0 require-box">
                                                            <div className="requirement bg-white cursor-pointer cms_border_gray">
                                                                <div onClick={(e) => {
                                                                    
                                                                    if (e.target.parentElement.classList.value.includes('turnaround-block') || e.target.parentElement.classList.value == "feather feather-edit edit-icon") {
                                                                        setFilterProjectId(filterProjectId == "" ? item.projectId : "")
                                                                        setOpenCalendar(!openCalendar)
                                                                        localStorage.setItem("openCalendar", true);
                                                                    }
                                                                    else if(localStorage.getItem("openCalendar") === 'false') {
                                                                        localStorage.setItem("active", 1);
                                                                        setFilterProjectId("");
                                                                        navigate("",{
                                                                            state: { projectId: item.projectId, startDate: head1, endDate: head2, poc: item.pocId , setactive:1 }
                                                                        });
                                                                    }
                                                                    else{
                                                                        setOpenCalendar(!openCalendar)
                                                                        localStorage.setItem("openCalendar", false);
                                                                        setFilterProjectId("")
                                                                    }
                                                                }}>
                                                                    <div className="d-flex align-items-center justify-content-between article-btn mb-3 px-3 flex-wrap">
                                                                        <div className="d-flex align-items-center">
                                                                            <h3 className="cms_fs18 cms_fw600 cms_fc_black my-1">{item.projectName}</h3>
                                                                            {item.industries ?
                                                                                <button
                                                                                    className="requirement-site-btn bg-white cms_fs14 cms_fw500 cms_fc_dark-black cms_radius6 ml-2"
                                                                                    type="button"
                                                                                >
                                                                                    {item.industries}
                                                                                </button>
                                                                                : ''}
                                                                        </div>
                                                                        <div className="d-flex align-items-center">

                                                                            <button class="words-article-btn cms_fs14 cms_fw500 cms_fc_blue cms_radius6">{item['words/article'] ? item['words/article'] : 0} Words/Article </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="requirement-border-bottom"></div>
                                                                    <div className="px-3 pb-1">
                                                                        <div className="d-flex align-items-center justify-content-between requirement-complete my-2">
                                                                            <span><h5 className="cms_fs14 cms_fw600 cms_fc_dark-black mb-0">Completed : {item.articleCompleted ?? 0}/{item.requirementRaised ?? 0}</h5></span>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="align-items-center">
                                                                                    {item.turnAroundTime ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                                        :
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FC3D39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                                    }
                                                                                </div>
                                                                                {item.turnAroundTime ?
                                                                                    <div class="d-flex align-items-center turnaround-block">
                                                                                        <h6 className="cms_fs14 cms_fw600 cms_fc_dark-black mb-0 ms-1 ">Turn around : {formateDateIntoDatemonth(item.turnAroundTime)}</h6>
                                                                                        {/* <div ref={ref} className="align-items-center pt-1"> */}
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            class="feather feather-edit edit-icon"
                                                                                            onClick={() => {
                                                                                                setFilterProjectId(item.projectId)
                                                                                                //setOpenCalendar(!openCalendar)
                                                                                                localStorage.setItem("openCalendar", true);
                                                                                            }}
                                                                                        >
                                                                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                                                        </svg>
                                                                                        <div>
                                                                                            {localStorage.getItem("openCalendar") == "true" && filterProjectId == item.projectId && (
                                                                                                <Calendar
                                                                                                    value={Datevalues}
                                                                                                    onChange={(e) => {
                                                                                                        let selectdate = new Date(e.year + "-" + (e.monthIndex + 1) + "-" + e.day);
                                                                                                        setDateValues(selectdate);
                                                                                                        UpdateTrunAroundTime(item.projectId, selectdate);
                                                                                                    }} />

                                                                                            )}
                                                                                        </div>

                                                                                        {/* </div> */}
                                                                                    </div>
                                                                                    :
                                                                                    <h6 className="red-require-msg cms_fs14 cms_fw600 ms-1 mb-0">Requirement yet to be raised</h6>}
                                                                            </div>

                                                                        </div>

                                                                        <ProgressBar className="d-flex mb-2 requirement-progreesbar">
                                                                            <ProgressBar
                                                                                now={Math.ceil(item.articleCompleted * (100 / item.requirementRaised))}
                                                                                key={1}
                                                                            />
                                                                        </ProgressBar>
                                                                    </div>
                                                                    <div className="requirement-border-bottom mb-2"></div>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center point-contact pt-1 px-3 flex-wrap">
                                                                    <h5 className="fw-bold cms_fs16 cms_fc_dark-black mt-2">Point of Contact</h5>
                                                                    <select className="form-select ps-3 cursor-pointer" id="optsMain1" value={item.pocId != null ? item.pocId : ""}
                                                                        onChange={(e) => {
                                                                            updatePoc(e.target.value, item.projectId);
                                                                            let ghostSelect = document.createElement('select');
                                                                            const select = document.getElementById('optsMain1');
                                                                            var x = select.options[select.selectedIndex].text;

                                                                            const ghostOption = document.createElement("option");
                                                                            ghostOption.setAttribute("value", x);
                                                                            var t = document.createTextNode(x);
                                                                            ghostOption.appendChild(t);
                                                                            ghostSelect.appendChild(ghostOption);
                                                                            window.document.body.appendChild(ghostSelect)
                                                                            select.style.width = ghostSelect.offsetWidth + 'px';
                                                                            window.document.body.removeChild(ghostSelect)
                                                                        }}>
                                                                        <option value="">--Select--</option>
                                                                        {poclist.map((poc) => {
                                                                            return (<option value={poc.value}>{poc.label}</option>);
                                                                        })}

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )
                                                })}

                                            </>)


                                            :
                                            <>
                                                <div className="no-data">
                                                    <div className=" no-data-msg ">
                                                        <div className="ms-1">
                                                            <svg
                                                                class="ant-empty-img-simple"
                                                                width="100"
                                                                // height="100"
                                                                viewBox="0 0 64 41"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g
                                                                    transform="translate(0 1)"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <ellipse
                                                                        class="ant-empty-img-simple-ellipse"
                                                                        cx="32"
                                                                        cy="33"
                                                                        rx="32"
                                                                        ry="7"
                                                                    ></ellipse>
                                                                    <g
                                                                        class="ant-empty-img-simple-g"
                                                                        fill-rule="nonzero"
                                                                    >
                                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                                        <path
                                                                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                                            class="ant-empty-img-simple-path"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="d-block align-items-center ms-4">
                                                            <span > No Data</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="text-end all-pagination w-100 mt-3 project_pagination position-relative custome_project_pagination">
                        <Pagination
                            defaultCurrent={pageNumber2}
                            total={listTotal2}
                            defaultPageSize={10}
                            onChange={handleChange2}
                        /></div>
                            </div>
                           
                        </div>
                    </div>
                  
                </TabPanel>


                {/* Start All project */}
                <TabPanel>
                    <div class="align-items-center">
                        <div className="row html2pdf__page-break all-channel-table requirementcontent-box mt-4">
                            <div className="px-0 channel_content_block">
                                <div className="allchannel-table mb-4 global-scrollbar require_project_scroll">
                                    <div className="row ">
                                        {
                                            filterList.length > 0 ? (<>
                                                {filterList.map((item) => {
                                                    return (<>
                                                        <div className="col-md-6 col-sm-12 mt-3 p-0 require-box" >
                                                            <div className="requirement bg-white cursor-pointer cms_border_gray ">
                                                                <div onClick={(e) => {
                                                                    if (e.target.parentElement.classList.value.includes('turnaround-block') || e.target.parentElement.classList.value == "feather feather-edit edit-icon") {
                                                                        setFilterProjectId(filterProjectId == "" ? item.projectId : "")
                                                                        setOpenCalendar(!openCalendar)
                                                                        localStorage.setItem("openCalendar", true);
                                                                    }
                                                                    else if(localStorage.getItem("openCalendar") === 'false') {
                                                                        localStorage.setItem("active", 1);
                                                                        navigate('/content-main/requirement-details',{
                                                                            state: { projectId: item.projectId, startDate: head1, endDate: head2, poc: item.pocId  , active : 1}
                                                                        });
                                                                    }
                                                                    else{
                                                                        setOpenCalendar(!openCalendar)
                                                                        localStorage.setItem("openCalendar", false);
                                                                    }
                                                                }}>
                                                                    <div className="d-flex align-items-center justify-content-between article-btn mb-3 px-3 flex-wrap">
                                                                        <div className="d-flex align-items-center">
                                                                            <h3 className="cms_fs18 cms_fw600 cms_fc_black my-1">{item.projectName}</h3>
                                                                            {item.industries ?
                                                                                <button
                                                                                    className="requirement-site-btn  bg-white cms_fs14 cms_fw500 cms_fc_dark-black cms_radius6 ml-2"
                                                                                    type="button"
                                                                                >
                                                                                    {item.industries ?? ''}
                                                                                </button>
                                                                                : ''}
                                                                        </div>
                                                                        <div className="d-flex align-items-center">

                                                                            <button class="words-article-btn cms_fs14 cms_fw500 cms_fc_blue cms_radius6">{item['words/article'] ? item['words/article'] : 0} Words/Article </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="requirement-border-bottom"></div>

                                                                    <div className="px-3 pb-1">
                                                                        <div className="d-flex align-items-center justify-content-between requirement-complete my-2">
                                                                            <span><h5 className="cms_fs14 cms_fw600 cms_fc_dark-black mb-0">Completed : {item.articleCompleted ?? 0}/{item.requirementRaised ?? 0}</h5></span>
                                                                            <div className="d-flex align-items-center time-update">
                                                                                <div className="align-items-center">
                                                                                    {item.turnAroundTime ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                                        :
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FC3D39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                                    }
                                                                                </div>
                                                                                {item.turnAroundTime ?
                                                                                    <div class="d-flex align-items-center turnaround-block">
                                                                                        <h6 className="cms_fs14 cms_fw600 cms_fc_dark-black mb-0 ms-1">Turn around : {formateDateIntoDatemonth(item.turnAroundTime)}</h6>
                                                                                        {/* <div ref={ref} className="align-items-center pt-1 pe-1"> */}
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            class="feather feather-edit edit-icon"
                                                                                            onClick={() => {
                                                                                                setFilterProjectId(item.projectId)
                                                                                                localStorage.setItem("openCalendar", true);
                                                                                            }}
                                                                                        >
                                                                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>

                                                                                        </svg>
                                                                                        <div>
                                                                                            {localStorage.getItem("openCalendar") == "true" && filterProjectId == item.projectId && (
                                                                                                <Calendar
                                                                                                    value={Datevalues}
                                                                                                    onChange={(e) => {
                                                                                                        let selectdate = new Date(e.year + "-" + (e.monthIndex + 1) + "-" + e.day);
                                                                                                        setDateValues(selectdate);
                                                                                                        UpdateTrunAroundTime(item.projectId, selectdate);
                                                                                                    }} />

                                                                                            )}
                                                                                        </div>
                                                                                        {/* </div> */}
                                                                                    </div> :
                                                                                    <h6 className="red-require-msg cms_fs14 cms_fw600 ms-1 mb-0">Requirement yet to be raised</h6>}
                                                                                <div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ProgressBar className="d-flex mb-2 requirement-progreesbar">
                                                                            <ProgressBar
                                                                                now={!isNaN(Math.ceil(item.articleCompleted * (100 / item.requirementRaised))) ?
                                                                                Math.ceil(item.articleCompleted * (100 / item.requirementRaised))
                                                                                : 0}
                                                                                key={1}
                                                                            />
                                                                        </ProgressBar>
                                                                    </div>

                                                                    <div className="requirement-border-bottom mb-2"></div>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center point-contact pt-1 px-3 flex-wrap">
                                                                    <h5 className="fw-bold cms_fs16 cms_fc_dark-black mt-2">Point of Contact</h5>
                                                                    <select className="form-select ps-3 cursor-pointer" id="optsMain" value={item.pocId != null ? item.pocId : ""}
                                                                        onChange={(e) => {
                                                                            updatePoc(e.target.value, item.projectId);
                                                                            let ghostSelect = document.createElement('select');
                                                                            const select = document.getElementById('optsMain');
                                                                            var x = select.options[select.selectedIndex].text;
                                                                            const ghostOption = document.createElement("option");
                                                                            ghostOption.setAttribute("value", x);
                                                                            var t = document.createTextNode(x);
                                                                            ghostOption.appendChild(t);
                                                                            ghostSelect.appendChild(ghostOption);
                                                                            window.document.body.appendChild(ghostSelect)
                                                                            select.style.width = ghostSelect.offsetWidth + 'px';
                                                                            window.document.body.removeChild(ghostSelect)
                                                                        }}>
                                                                        <option value="">Select</option>
                                                                        {poclist.map((poc) => {
                                                                            return (<option value={poc.value}>{poc.label}</option>);
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )
                                                })}
                                                <div className="d-none text-end all-pagination  project_pagination w-100 mt-3 bg-white position-relative ">
                                                    <Pagination
                                                        defaultCurrent={pageNumber1}
                                                        total={listTotal1}
                                                        defaultPageSize={10}
                                                        onChange={handleChange1}
                                                    /></div>
                                            </>)

                                                :
                                                <>
                                                    <div className="no-data">
                                                        <div className=" no-data-msg ">
                                                            <svg
                                                                class="ant-empty-img-simple"
                                                                width="100"
                                                                height="100"
                                                                viewBox="0 0 64 41"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g
                                                                    transform="translate(0 1)"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <ellipse
                                                                        class="ant-empty-img-simple-ellipse"
                                                                        cx="32"
                                                                        cy="33"
                                                                        rx="32"
                                                                        ry="7"
                                                                    ></ellipse>
                                                                    <g
                                                                        class="ant-empty-img-simple-g"
                                                                        fill-rule="nonzero"
                                                                    >
                                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                                        <path
                                                                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                                            class="ant-empty-img-simple-path"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <span className="d-block align-items-center ms-3"> No Data</span>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="text-end all-pagination  project_pagination w-100 mt-3 position-relative custome_project_pagination">
                                <Pagination
                                    defaultCurrent={pageNumber1}
                                    total={listTotal1}
                                    defaultPageSize={10}
                                    onChange={handleChange1}
                                /></div>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </>
    )
}
export default RequirementContent;