import React, { useEffect, useRef, useState } from "react";
import { Table, Input } from "antd";
import {
  exportToEXCEL,
  isValidImportFile,
  removeTrailingSlash,
} from "../../utility/Globals";
import {
  createUrl,
  deleteUrl,
  documentFileType,
  getUrlByOrgId,
  GET_Server_URL,
  urlImport,
  deleteMultiUrls,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { Sorter } from "../../common/Sorter";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

const Url = ({ update }) => {
  const [urlName, setURLName] = useState("");
  const [urlNameCopy, setURLNameCopy] = useState("");
  const [URLTabcol, setURLTabcol] = useState([]);
  const [taburllist, settaburlList] = useState([]);
  const [selectedUrlExportList, setSelectedUrlExportList] = useState([]);
  const [SelectedUrlCheckList, setSelectedUrlCheckList] = useState([]);
  const [urlexportList, setUrlExportList] = useState([]);
  const [taburllistUrl, settaburllistUrl] = useState([]);
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const [isEditurl, setIsEditurl] = useState(false);
  const [UrlId, seturlId] = useState("");
  const notifyAdd = () => toast.success("Successfully Added!");
  
  useEffect(() => {
    let columns = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setURLTabcol(columns);
    getUrlList();
  }, []);

  const urlrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          url: item.url,
        };
        list.push(obj);
      });
      setSelectedUrlExportList(list);

      const list1 = [];
      selectedRows.forEach((item) => {      
        list1.push(item.id);
      });
      setSelectedUrlCheckList(list1);
    },
  };

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: removeTrailingSlash(urlName),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setURLName("");
        getUrlList();
        notifyAdd();
      }
    });
  }

  function updateURL() {
    APIManager.putAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: removeTrailingSlash(urlName),
        id: UrlId,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setIsEditurl(false);
        setURLName("");
        getUrlList();
      }
    });
  }

  function deleteMultiUrlId() {
    return new Promise((resolve, reject) => {
      APIManager.postAPIForDelete(
        deleteMultiUrls,
        SelectedUrlCheckList,
        {},
        true
      ).then((response) => {
        if (response && response.status === 200) {
          getUrlList();
          if (response.status === 200)
            if (response?.data?.message) {
            }
          resolve(response.status);
        }
      });
    });
  }

  function submit() {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteMultiUrlId();
            setSelectedUrlCheckList([]);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function deleteUrlItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteUrl + id);
      if (response === 200) {
        getUrlList();
      }
    } catch (e) { }
  }

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status === 200) {
          const list = [];
          const exportList = [];
          response.data.forEach((item) => {
            exportList.push({
              key: item.id,
              url: item.fieldValue,
            });
            list.push({
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
              delete: (
                <div className="d-flex">
                  <div
                    className="text-center"
                    to={{
                      state: { item: item, isEdit: true },
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon me-3 "
                      onClick={() => {
                        setIsEditurl(true);
                        seturlId(item.id);
                        setURLName(item.fieldValue);
                        setURLNameCopy(item.fieldValue);
                        setErrors("");
                        var e = document.getElementById("input-s");
                        e.scrollIntoView({
                          block: "end",
                          behavior: "smooth",
                          inline: "center",
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon"
                      onClick={() => {
                        deleteUrlItem(item.id);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </div>
              ),
            });
          });
          settaburlList(list);
          settaburllistUrl(list);
          setUrlExportList(exportList);
        }
      }
    );
  }

  function uploadUrlFile(e) {
    let input = inputFileRef.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid === true) {
      urlimport(e.target.files[0]);
    }
  }

  function urlimport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      urlImport + localStorage.getItem("projectGId"),
      {},
      bodyFormData,
      false
    ).then(() => {
      getUrlList();
    });
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function isValidURL() {
    let error = { ...errors };
    urlName.trim() === ""
      ? (error["urlName"] = "URL is required!")
      : delete error["urlName"];

    if (urlName.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );

      if (!pattern.test(urlName.trim())) {
        error["urlName"] = "Please enter valid URL";
      } else {
        delete error["urlName"];
        let isEqualItem = false;
        taburllist.forEach((item) => {
          if (item.value === urlName.trim()) {
            isEqualItem = true;
          }
        });
        if (isEqualItem) {
          error["urlName"] = "URL is already exist!";
        } else {
          delete error["urlName"];
        }
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  return (
    <div className="custom-column-70">
      <div className="portlet  slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg
            onClick={() => update("")}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left close-left-icon"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - URL
          </h3>
        </div>
        <div className="portlet-body px-3">
          <div class="row">
            <div className="col-md-4 col-sm-12 ps-0">
              <div className="d-flex">
                <span className="url-add w-50">
                  <input
                    className="w-100"
                    style={{ borderRight: 0, borderRadius: 0 }}
                    type="text"
                    value={urlName}
                    onChange={(text) => {
                      setURLName(text.target.value);
                    }}
                    placeholder="Enter URL"
                  />
                </span>
                <span>
                  <button
                    style={{
                      borderLeft: 0,
                      borderRadius: 0,
                      height: 100 + "%",
                      width: 100 + "%",
                    }}
                    class="btn btn-primary "
                    onClick={() => {
                      setsave(true);
                      if (urlNameCopy.trim() === urlName.trim() && isEditurl) {
                        setURLName("");
                        setErrors("");
                        return;
                      }
                      if (isValidURL()) {
                        if (isEditurl) {
                          updateURL();
                        } else {
                          addUrl();
                        }
                      }
                    }}
                  >
                    {isEditurl ? "Update" : "Add"}
                  </button>
                </span>
              </div>
              {save && errors?.urlName && (
                <>
                  <div className="field-required mt-2">
                    <span> {errors?.urlName}</span>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-8 col-sm-12"></div>
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <div class="tab-panel-url-options d-flex justify-content-start url-tab mt-3">
              <div class="input-group-icon input-group-lg widget15-compact">
                <div class="input-group-prepend">
                  <i class="fa fa-search text-primary"></i>
                </div>
                <span>
                  <Input
                    allowClear
                    style={{ padding: 7 + "px" }}
                    type="text"
                    class="form-control"
                    placeholder="Type to search..."
                    onChange={(nameSearch) => {
                      settaburlList(
                        taburllistUrl.filter((person) =>
                          person.url
                            .toLocaleLowerCase()
                            .includes(
                              nameSearch.target.value.toLocaleLowerCase()
                            )
                        )
                      );
                    }}
                    id="input-s"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-wrap tab-panel-url-options">
              <div class="export me-0 d-flex flex-wrap">
                <button
                  disabled={!SelectedUrlCheckList?.length}
                  style={{ marginLeft: 18 + "px" }}
                  class="btn btn-primary me-2 ms-0 download-temp mt-3"
                  onClick={() => {
                    setSelectedUrlCheckList([]);
                    submit();
                  }}
                >
                  Delete Selected
                </button>
                <a
                  class="btn btn-primary mx-2 download-temp mt-3"
                  style={{ marginLeft: 18 + "px" }}
                  target="_blank"
                  href={GET_Server_URL + "audit/Url.xlsx"}
                  rel="noreferrer"
                >
                  Download Template
                </a>
                <input
                  style={{
                    display: "none",
                  }}
                  type="file"
                  id="urlFile"
                  name="urlFile"
                  multiple={false}
                  accept={documentFileType}
                  ref={inputFileRef}
                  onChange={(e) => uploadUrlFile(e)}
                  className="mt-3"
                />
                <button
                  class="btn btn-primary url-import me-2 import mt-0 mt-3"
                  onClick={onBtnClick}
                >
                  Import
                </button>
              <span class="dropdown d-inline mt-3">
                <button
                  class="btn btn-primary dropdown-toggle export-btn"
                  type="button"
                  onClick={() => {
                    exportToEXCEL(
                      selectedUrlExportList?.length > 0
                        ? selectedUrlExportList
                        : urlexportList,
                      "Url"
                    );
                  }}
                >
                  Export
                </button>              
              </span>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-3 px-0">
            <Table
              className="table table-bordered table-hover url-table"
              id="url"
              columns={URLTabcol}
              dataSource={taburllist}
              rowSelection={{
                type: "checkbox",
                ...urlrowSelection,
              }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Url;
