/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import Modal from '@semcore/ui/modal';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";


const CustomModal = ({
    children,
    width,
    visible,
    handleModalClose
}) => {
    
    return (
        <Modal visible={visible} closable={false} onClose={() => { handleModalClose() }}>
            <Modal.Overlay zIndex={40000}>
                <Modal.Window w={width} px={5} py={2.5}>
                    {/* <Modal.Close>
                        <FeatherIcon icon={"x"} />
                    </Modal.Close> */}

                    {children}
                </Modal.Window>
            </Modal.Overlay>
        </Modal>
    )
}

export default CustomModal