import { css } from "@emotion/react";
import e from "cors";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({});

export const popperContainer = css({
  display: "flex",
  padding: 0,
  backgroundColor: Colors.plainWhite,
  boxShadow: Colors.cardShadow4,
  borderRadius: "0.375rem",
});

export const leftPane = css({
  borderRight: `1px solid ${Colors.neutral[50]}`,
  minWidth: "9.0625rem",
});

export const leftPaneOptions = (isActive,isDisabled) => {
  return css({
    padding: "0.75rem 0.62rem",
    borderBottom: `1px solid ${Colors.neutral[50]}`,
    cursor: "pointer",
    backgroundColor: isActive ? Colors.primary[50] : "",
    color: isDisabled ? Colors.neutral[400]:""

  });
};

export const pickerContainer = css({
  // padding: "1rem",
});

export const dateTrigger = css({
  ...textStyles.b3,
  lineHeight: "1.2rem",
  flex: "1",
  display: "flex",
  alignItems: "center",
  border: `1px solid ${Colors.neutral[900]}`,
  borderRadius: "0.375rem",
  padding: "0.4rem 0.75rem",
});

export const separater = css({
  padding: "0 0.5rem !important",
});

export const rightPane = css({
  width: "100%",
  padding: "1rem 0.75rem",
  display: "flex",
  justifyContent: "flex-end",
});

export const dateinput = css({
  minWidth: "7.21rem",
});

export const icon = css({
  height: "1rem",
  width: "1rem",
  color: Colors.neutral[800],
  marginRight: "0.5rem",
  strokeWidth: 2.5,
});
