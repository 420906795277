import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Sorter } from "../../../common/Sorter";
import APIManager from "../../../services/APIManager";
import {
  documentFileType,
  GET_Server_URL,
  deletecampaignviewkeyword,
  deleteAllcampaignviewkeyword,
  getaddkeyword,
  importrankingcampaignViewKeyword,
} from "../../../utility/Constant";
import {
  exportToEXCEL,
} from "../../../utility/Globals";
import Addcampaignviewkeword from "./AddcampaignviewKeyword";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

function Createcampaignkeyword(props) {
  const CompTabcolnew = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            a.keyword.toLocaleLowerCase(),
            b.keyword.toLocaleLowerCase()
          ),
        multiple: 3,
      },
    },
  ];

  const [tabdata, settabdata] = useState("");
  const [tabdataTotalAddKeyword, setTotalAddKeyword] = useState(0);
  const [tabkeywordlist, settabkeywordList] = useState([]);
  const [tabAddkeywordlist, setAddtabkeywordList] = useState([]);
  const [Totalkeyword, setTotalKeyword] = useState(0);
  const [Selectedkeyword, setSelectedkeyword] = useState(0);
  const [SelectedAddkeyword, setSelectedAddkeyword] = useState(0);
  const [KeywordTemp, setKeywordTemp] = useState([]);
  const [Newkeyword, setNewkeyword] = useState([]);
  const [SelectedkeywordCheckList, setSelectedkeywordkList] = useState([]);
  const [SelectedkeywordCheckNewList, setSelectedkeywordCheckNewList] = useState([]);
  const [settings, setSetting] = useState("");
  const [addviewId, setaddviewId] = useState("");

  useEffect(() => {
    getKeywordlist();
    setaddviewId(props.campaignviewID);
  }, [props]);

  var columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            a.keyword.toLocaleLowerCase(),
            b.keyword.toLocaleLowerCase()
          ),
        multiple: 3,
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      width: "9%",
    },
  ];

  const keyrowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (selectedRows?.length > 1) {
        localStorage.setItem("selectionInfoType", "all");
        const list = [];
        selectedRowKeys = [];
        tabkeywordlist.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            count: item.count,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            categoryName: item.categoryName,
            type: item.type,
          };
          list.push(obj);
        });
      } else {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            categoryName: item.categoryName,
            type: item.type,
          };
          list.push(obj);
        });
      }
      const list1 = [];
      selectedRows.map((item, index) => {
        list1.push(item.id);
      });
      setSelectedkeywordkList(list1);
      if (info.type == "all") {
        setSelectedkeyword(tabkeywordlist.length);
      } else {
        setSelectedkeyword(list1.length);
      }
    },
    selections: [
      Table.SELECTION_ALL
    ]
  };

  const NewkeyrowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (selectedRows?.length > 1) {
        localStorage.setItem("selectionInfoType", "all");
        const list = [];
        selectedRowKeys = [];
        tabAddkeywordlist.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            count: item.count,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            categoryName: item.categoryName,
            type: item.type,
          };
          list.push(obj);
        });
      } else {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            count: item.count,
            keyword: item.keyword,
            searchVolume: item.searchVolume,
            categoryName: item.categoryName,
            type: item.type,
          };
          list.push(obj);
        });
      }
      const list1 = [];
      selectedRows.map((item, index) => {
        list1.push(item.id);
      });
      setSelectedkeywordCheckNewList(list1);
      if (info.type == "all") {
        setSelectedAddkeyword(tabAddkeywordlist.length);
      } else {
        setSelectedAddkeyword(list1.length);
      }
    },
    selections: [
      Table.SELECTION_ALL
    ]
  };

  const [compExportList, selectedCompExportList] = useState([]);

  const keywordtype = [
    {
      value: "0",
      lable: "Brand",
    },
    {
      value: "1",
      lable: "Non Brand ",
    },
  ];

  const [viewkeywordcount, setviewkeywordcount] = useState("");

  function getKeywordTypeName(val) {
    var finalVal = val;
    keywordtype.map((i) => {
      if (val == i.value) {
        finalVal = i.lable;
      }
    });
    return finalVal;
  }

  function getKeywordlist() {
    APIManager.getAPI(getaddkeyword + props?.campaignviewID).then(
      (response) => {
        if (response && response.status == 200) {
          setviewkeywordcount(response?.data?.counts[0].keywordsCount);
          const list = [];
          const tablelist = [];
          let c = 1;
          let i = 0;
          const exportList = [];
          const exporttableList = [];
          response.data.table.map((item) => {
            exportList.push({
              key: item.keywordId,
              keyword: item.keyword,
              searchVolume: item.searchVolume,
              category: item.category,
              type: getKeywordTypeName(item.type),
            });
            list.push({
              count: c,
              key: i++,
              value: item.fieldValue,
              label: item.fieldValue,
              id: item.keywordId,
              keyword: item.keyword,
              searchVolume: item.searchVolume,
              category: item.category,
              priority: item.priority == 0 ? "No" : "Yes",
              type: getKeywordTypeName(item.type),
              delete: (
                <div className="d-flex text-centet">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2 trash-icon mt-1"
                    onClick={() => {
                      deletekeywordItem(item.keywordId);
                    }}
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </div>
              ),
            });
            c++;
          });
          response.data.table1.map((titem) => {
            exporttableList.push({
              keyword: titem.keyword,
            });
            selectedCompExportList(exporttableList);
            tablelist.push({
              count: c,
              key: i++,
              value: titem.fieldValue,
              label: titem.fieldValue,
              id: titem.keywordId,
              keyword: titem.keyword,
              searchvolume: titem.searchVolume,
              categorey: titem.category,
              type: getKeywordTypeName(titem.type),
              priority: titem.priority == 0 ? "No" : "Yes",
            });
            c++;
          });
          settabkeywordList(list);
          settabdata(tablelist);
          setKeywordTemp(list);
          setNewkeyword(tablelist);
          setAddtabkeywordList(tablelist);
          setTotalKeyword(list.length);
          setTotalAddKeyword(tablelist.length);

          if (list.length) {
            props.dataChildToParent(true)
          } else {
            if (tablelist.length) {
              props.dataChildToParent(true)
            } else {
              props.dataChildToParent(false)
            }
          }
        }
      }
    );
  }

  async function deletekeywordItem(id) {
    try {
      const response = await APIManager.deleteAPI(
        deletecampaignviewkeyword + id
      );
      if (response == 200) {
        getKeywordlist();
      }
    } catch (e) { }
  }

  function filterDataFromList(textData) {
    settabkeywordList(
      KeywordTemp.filter((person) =>
        person.keyword
          .toLocaleLowerCase()
          .includes(textData.toLocaleLowerCase())
      )
    );
    settabdata(
      Newkeyword.filter((person) =>
        person.keyword
          .toLocaleLowerCase()
          .includes(textData.toLocaleLowerCase())
      )
    );
  }

  function uploadkeywordFile() {
    var input = document.getElementById("keywordFile");
    keyWordimport(input.files.item(0));
  }

  function keyWordimport(file) {
    inputFileRef.current.value = null;
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      importrankingcampaignViewKeyword + props?.campaignviewID,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Some Column are Missing') {
        toast.error('Some Columns are Missing');
        getKeywordlist();
        return;
      }
      else if (response?.data && response?.data?.errors && response?.data?.errors[0]?.errorMessage === 'Invalid File') {
        toast.error('Invalid File');
        getKeywordlist();
        return;
      }
      else if (response && response.status == 200) {
        toast.success('Successfully Add Keywords');
        getKeywordlist();
        return;
      }
    });
  }

  function deleteMultikeywordId() {
    return new Promise((resolve, reject) => {
      APIManager.postAPIForDelete(
        deleteAllcampaignviewkeyword,
        SelectedkeywordCheckList?.length ? SelectedkeywordCheckList : SelectedkeywordCheckNewList,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          if (response.status === 200)
            getKeywordlist();
          setSelectedkeywordkList(null);
          setSelectedkeywordCheckNewList(null);
          resolve(response.status);
        }
      });
    });
  }

  function deleteKeyWord() {
    return new Promise((resolve, reject) => {
      APIManager.deteletItem(
        SelectedkeywordCheckList?.length ? deletecampaignviewkeyword + SelectedkeywordCheckList : deletecampaignviewkeyword + SelectedkeywordCheckNewList,
      ).then((response) => {
        if (response && response.status == 200) {
          getKeywordlist();
          if (response.status === 200)
          resolve(response.status);
        }
      });
    });
  }

  function Newkeywordsubmit() {
    confirmAlert({
      title: "Confirm to Delete",
      message:
        Selectedkeyword +
        " of " +
        Totalkeyword +
        " keywords selected will be deleted.",
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            if (localStorage.getItem("selectionInfoType") !== "all") {
              deleteKeyWord();
            } else {
              deleteMultikeywordId();
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  function NewAddkeywordsubmit() {
    confirmAlert({
      title: "Confirm to Delete",
      message:
        SelectedAddkeyword +
        " of " +
        tabdataTotalAddKeyword +
        " keywords selected will be deleted.",
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            if (localStorage.getItem("selectionInfoType") !== "all") {
              deleteKeyWord();
            } else {
              deleteMultikeywordId();
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  let onClickFunction = (arg) => {
    setSetting(arg);
    getKeywordlist();
    handleChangeOpen(false);
  };

  function getComponent() {
    if (settings == "addUpdateKeywords") {
      return (
        <Addcampaignviewkeword
          update={onClickFunction}
          campaignviewId={addviewId}
        ></Addcampaignviewkeword>
      );
    }
  }

  const handleChangeOpen = (val) => {
    props.isOpen(val);
  };

  return (
    <div className="containerr">
      <div class="portlet-header my-4 border-0">
        <h3 class="portlet-title main-title ps-2">Add Keywords</h3>
      </div>
      <div className="portlet-body">
        {settings == "" ? (
          <>
            <div className="row">
              <div className="col-md-8">
                {" "}
                <div className="keyword-select text-dark fw-bold">
                  <>Keywords Added :{viewkeywordcount}</>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-3 col-sm-12 keyword-search d-flex justify-content-start align-items-center">
                <div class="input-group-icon input-group-lg widget15-compact">
                  <div class="input-group-prepend">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </div>
                  <Input
                    type="text"
                    style={{ padding: 7 + "px" }}
                    class="form-control"
                    placeholder="Type to search"
                    allowClear
                    onChange={(nameSearch) => {
                      filterDataFromList(nameSearch.target.value);
                    }}
                    id="input-s"
                  />
                </div>
              </div>{" "}
              <div className="col-md-9 d-flex justify-content-end align-items-center tab-panel-url-options keyword-tab pe-0">
                <div className="d-block  me-3">
                  <button
                    disabled={!SelectedkeywordCheckList?.length}
                    class="btn btn-primary"
                    onClick={() => {
                      Newkeywordsubmit();
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div class="data-export-client me-3">
                  <Link
                    class="btn btn-primary add"
                    state={{ isEdit: false }}
                    onClick={() => {
                      setSetting("addUpdateKeywords");
                      handleChangeOpen(true);
                    }}
                  >
                    Add
                  </Link>
                </div>

                <input
                  style={{ display: "none" }}
                  type="file"
                  id="keywordFile"
                  name="keywordFile"
                  multiple={false}
                  accept={documentFileType}
                  ref={inputFileRef}
                  onChange={uploadkeywordFile}
                />

                <button
                  class="btn btn-primary import me-3"
                  onClick={onBtnClick}
                >
                  Import
                </button>
                <a
                  class="btn btn-outline download-temp "
                  target="_blank"
                  href={GET_Server_URL + "audit/RankingKeyword_View.xlsx"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#0691EF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-download"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  <span className="ms-2">Download Template</span>
                </a>
              </div>
            </div>

            <div className="ranking-campaing-table table-bordered border-0  mt-4 pt-2">
              <div className="col-md-12 px-1">
                <Table
                  className="table table-hover keyword-table"
                  id="url"
                  columns={columns}
                  dataSource={tabkeywordlist}
                  rowSelection={{
                    type: "checkbox",
                    ...keyrowSelection,
                  }}
                  pagination={{
                    defaultPageSize: 100,
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} Keywords`,
                  }}
                />
              </div>
            </div>
            {tabdata.length ? (
              <>
                <div className="borderr-red px-3 py-4">
                  <div className="d-flex justify-content-between align-items-center me-3 pe-1">
                    <div className="d-flex align-items-center title-bar">
                      <h1 class="portlet-title main-title me-3">
                        New Keywords
                      </h1>
                      <a
                        href="#"
                        class="text-dark"
                        data-toggle="tooltip"
                        data-html="true"
                        title="These are the set of keywords that aren't part of the main campaign and they won't be tracked."
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 16V12"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 8H12.01"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <button
                        disabled={!SelectedkeywordCheckNewList?.length}
                        class="btn btn-primary"
                        onClick={() => {
                          NewAddkeywordsubmit();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        class="ms-3 btn btn-outline"
                        onClick={() => {
                          exportToEXCEL(
                            selectedCompExportList?.length > 0
                              ? compExportList
                              : compExportList,
                            "New keywords"
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#0691EF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                        <span className="ms-2">Download List</span>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ranking-campaing-table table-bordered border-0">
                    <Table
                      className="table table-hover thead-yellow mb-0"
                      id="url"
                      columns={CompTabcolnew}
                      dataSource={tabdata}
                      rowSelection={{
                        type: "checkbox",
                        ...NewkeyrowSelection,
                      }}
                      pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} Keywords`,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>{getComponent()}</>
        )}
      </div>
    </div>
  );
}

export default Createcampaignkeyword;
