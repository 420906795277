import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const tableMain = css({

    ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before": {
        width: "0px !important"
    },
    // ".ant-table-content": {
    //     "::-webkit-scrollbar": {
    //         height: "5px",
    //         backgroundColor: Colors.plainWhite
    //     },
    //     "::-webkit-scrollbar-thumb": {

    //         borderRadius: "5rem",
    //         backgroundColor: Colors.neutral[500]
    //     }
    // },
    ".ant-table-content": {
        minHeight: "687.95px"
    },
    ".ant-table-content .ant-table-thead": {
        height: "5rem",
    },
    ".ant-table-column-title": {
        flex: "none"
    },
    ".ant-table-column-sorters": {
        justifyContent: "flex-start",
        columnGap: "1rem"
    },
    ".ant-table-filter-trigger": {
        margin: 0
    },
    ".ant-table-filter-column": {
        display: "flex",
        justifyContent: "flex-start"
    },
    ".ant-table-column-sorter": {
        marginLeft: 0
    },
    ".ant-table": {
        padding: 0,
        border: `1px solid #E2E8F0`,
        // borderRadius: `0.375rem 0.375rem 0rem 0rem`

    },
    ".ant-table-tbody > tr > td": {
        borderBottom: "none"
    },
    ".ant-table-measure-row": {
        display: "none"
    },
    ".ant-table-pagination.ant-pagination": {
        margin: 0,
        padding: "0.5rem 1.5rem"
    },
    // error screen
    "ul.configuration-tab-list li:hover a, td:first-child.ant-table-cell div": {
        color: `${Colors.black} !important`
    }
})

export const subtitles = css({
    color: `${Colors.neutral[600]} !important`
})


export const keyword = (disable) => {
    return css({
        ...textStyles.H1Medium,
        color: Colors.neutral[900],
        // backgroundColor: "red",
        textAlign: "center",
        maxWidth: "fit-content",
        ...(disable && { pointerEvents: "none" })
    });
}

export const selectDropDownContainer = css({
    width: "6.25rem !important",
});

export const priorityStyles = (priority, custom) => {
    let background = Colors.error[50];
    let color = Colors.error[800];
    if (priority == "Medium") {
        background = Colors.warning[50];
        color = Colors.warning[500];
    }
    if (priority == "Low") {
        background = Colors.success[50];
        color = Colors.success[200];
    }
    if (priority == "High") {
        background = Colors.error[50];
        color = Colors.error[800];
    }
    return css({
        ...textStyles.s2Medium,
        background: background,
        color: color,
        minHeight: "2.5rem !important",
        border: `1px solid ${color}`,
        ...custom,
    });
};

export const assignedToStyles = css({
    ...textStyles.b2,
    color: Colors.neutral[800],
    minHeight: "2.5rem !important",
})

export const applyContainer = css({
    padding: "1rem",
    backgroundColor: Colors.plainWhite,
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderRadius: "0 0 4px 4px",
    display: "flex",
    flexDirection: "row-reverse"
})

export const calendarStyles = css({
    // ...textStyles,
    marginTop: "1rem",
    ".rdrNextPrevButton": {
        background: "none"
    },
    ".rdrMonth": {
        width: "20rem"
    },
    ".rdrMonthAndYearPickers": {
        width: "7rem"
    },
    ".rdrMonthAndYearPickers select": {
        padding: "10px 22px 10px 10px"
    },
    ".rdrStartEdge": {
        borderRadius: "6px 0 0 6px"
    },
    ".rdrEndEdge": {
        borderRadius: "0 6px 6px 0"
    },
    // ".rdrInRange": {
    //     backgroundColor: Colors.primary[100],
    //     color: "#1d2429"
    // }
})

export const loader = css({
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 99999,
})