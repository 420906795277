import React from 'react'
import { useState, useEffect } from 'react';
import {
    exportToEXCEL,
    findUnique,
    fixTwoPlaceDecimal,
    mmDDyyyyFormateDateSet
} from '../../utility/Globals';
import { Drawer, Table, Input } from 'antd';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Sorter } from '../../common/Sorter';
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import APIManager from '../../services/APIManager';
import { bandwidthanalysis, projectprofile } from '../../utility/Constant';
import FeatherIcon from 'feather-icons-react';

function BandwidthAnalysis(props) {
    const [analysisexport, setanalysisExport] = useState([]);
    const [tabprojectdetaillist, setTabProjectDetailList] = useState([]);
    const [profileExportList, setProfileExportList] = useState([]);
    const [AnalysisTabcol, setAnalysisTabcol] = useState([]);
    const [tabAnalysislist, settabAnalysisList] = useState([]);
    const [profiletabcol, setProfileTabcol] = useState([]);
    const [projectdetaillist, setProjectDetailList] = useState([]);
    const [domain, setDomain] = useState("");
    const [projectname, setProjectName] = useState("");
    const [grophead, setGropHead] = useState("");
    const [manager, setManager] = useState("");
    const [billed, setBilled] = useState("");
    const [allocated, setAllocated] = useState("");
    const [BandwidthSplitTab, setBandwidthSplitTab] = useState(true);
    const [size, setSize] = useState();
    const [writeropen, setWriterOpen] = useState(false);

    const showLargeDrawer1 = () => {
        setSize("large");
        setWriterOpen(true);
    };
    const onClose = () => {
        setWriterOpen(false);
    };

    useEffect(() => {
        let rowdata = {
            StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
            EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
            OrganizationId: localStorage.getItem("organizationId")
        };
        getBandwidthAnalysisTable(rowdata);
    }, [props?.dataParentToChild]);

    function getBandwidthAnalysisTable(rowdata) {
        APIManager.postAPI(bandwidthanalysis, rowdata, null, true).then(
            (response) => {
                if (response && response.status === 200) {
                    const exportList = [];
                    let listData = [];
                    let i = 0;
                    let childrenData = [];
                    let childrenDataArray = [];
                    const filtervp = [];
                    const filtergrouphead = [];
                    const filterprojects = [];

                    response?.data.table1.forEach((item) => {
                        childrenDataArray = [];
                        if (response?.data?.table?.length) {
                            response?.data?.table.forEach((item1) => {
                                if (item.vp === item1.vp && item.groupHead === item1.groupHead) {
                                    childrenData = {
                                        vp: '',
                                        grouphead: '',
                                        projects: <span
                                            onClick={(i) => {
                                                showLargeDrawer1();
                                                let rowData = {
                                                    OrganizationId: localStorage.getItem("organizationId"),
                                                    startDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
                                                    endDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
                                                    projectId: item1.projectId,
                                                    groupHeadId: item1.groupHeadId
                                                }
                                                getProjectProfile(rowData)
                                            }}
                                        >
                                            {item1.projects}
                                        </span>,
                                        billed: fixTwoPlaceDecimal(item1.billedTeamMembers),
                                        allocate: fixTwoPlaceDecimal(item1.allocatedTeamMembers),
                                        variance: fixTwoPlaceDecimal(item1.variance),
                                    };
                                    childrenDataArray.push(childrenData);
                                }
                                let projectObj = {
                                    text: item1.projects,
                                    value: item1.projects
                                }
                                filterprojects.push(projectObj);
                                filterprojects.sort((a, b) => a.text.localeCompare(b.text))
                            })
                        }
                        listData.push({
                            expanded: false,
                            key: i += 1,
                            vp: item.vp,
                            grouphead: item.groupHead,
                            projects: item.projects,
                            billed: fixTwoPlaceDecimal(item.billedTeamMembers),
                            allocate: fixTwoPlaceDecimal(item.allocatedTeamMembers),
                            variance: fixTwoPlaceDecimal(item.variance),
                            children: childrenDataArray,
                        });

                        listData.sort((a, b) => b.variance - a.variance);
                        let filtervpobj = {
                            text: item.vp,
                            value: item.vp
                        }

                        filtervp.push(filtervpobj);
                        filtervp.sort((a, b) => a.text.localeCompare(b.text))
                        let filtergroupheadobj = {
                            text: item.groupHead,
                            value: item.groupHead
                        };

                        filtergrouphead.push(filtergroupheadobj);
                        filtergrouphead.sort((a, b) => a.text.localeCompare(b.text))
                    })
                    response?.data.table1.forEach((item1) => {
                        response?.data.table.forEach((item) => {
                            if ((item1.vp === item.vp && item1.groupHead === item.groupHead)) {
                                exportList.push({
                                    VP: item1.vp,
                                    GroupHead: item1.groupHead,
                                    Project: item.projects,
                                    BilledTeamMembers: fixTwoPlaceDecimal(item.billedTeamMembers),
                                    AllocateTeamMembers: fixTwoPlaceDecimal(item.allocatedTeamMembers),
                                    Variance: fixTwoPlaceDecimal(item.variance)
                                });
                            }
                        })

                    })
                    exportList.sort((a, b) => b.Variance - a.Variance);
                    let columns = [
                        {
                            title: "VP",
                            dataIndex: "vp",
                            key: "vp",
                            filters: findUnique(filtervp, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.vp === value;
                            },
                            width: '20%',
                        },
                        {
                            title: "Group Head",
                            dataIndex: "grouphead",
                            key: "grouphead",
                            filters: findUnique(filtergrouphead, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.grouphead === value;
                            },
                            width: '20%',

                        },
                        {
                            title: "Projects",
                            dataIndex: "projects",
                            key: "projects",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.projects, b.projects),
                                multiple: 3,
                            },
                            width: '15%',
                        },
                        {
                            title: "Billed Team Members",
                            dataIndex: "billed",
                            key: "billed",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.billed, b.billed),
                                multiple: 3,
                            },
                            width: '20%',
                        },
                        {
                            title: "Allocated Team Members",
                            dataIndex: "allocate",
                            key: "allocate",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.allocate, b.allocate),
                                multiple: 3,
                            },
                            width: '20%',
                        },
                        {
                            title: "Variance",
                            dataIndex: "variance",
                            key: "variance",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.variance, b.variance),
                                multiple: 3,
                            },
                            width: '15%',
                        },
                        {
                            dataIndex: "expanded",
                            key: "expanded",
                            align: "right",
                            width: '10%',
                        },
                    ];
                    setAnalysisTabcol(columns);
                    settabAnalysisList(listData);
                    setanalysisExport(exportList);
                };
            }
        );
    };

    function getProjectProfile(rowData) {
        APIManager.postAPI(projectprofile, rowData, null, true).then(
            (response) => {
                if (response && response.status === 200) {
                    let listData = [];
                    const filterrole = [];
                    response?.data?.table1?.forEach((item, index) => {
                        listData.push({
                            role: item.role,
                            billed: fixTwoPlaceDecimal(item.billedTeamMembers),
                            allocated: fixTwoPlaceDecimal(item.allocatedTeamMembers),
                            variance: fixTwoPlaceDecimal(item.variance)
                        })
                        let filterroleobj = {
                            text: item.role,
                            value: item.role,
                        }
                        filterrole.push(filterroleobj);
                        filterrole.sort((a, b) => a.text.localeCompare(b.text))
                    })
                    let columns1 = [
                        {
                            title: "Role",
                            dataIndex: "role",
                            key: "role",
                            filters: findUnique(filterrole, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.role === value;
                            },
                            width: '25%',
                        },
                        {
                            title: "Billed",
                            dataIndex: "billed",
                            key: "billed",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.billed, b.billed),
                                multiple: 3,
                            },
                            width: '25%',
                        },
                        {
                            title: "Allocated",
                            dataIndex: "allocated",
                            key: "allocated",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.allocated, b.allocated),
                                multiple: 3,
                            },
                            width: '25%',
                        },
                        {
                            title: "Variance",
                            dataIndex: "variance",
                            key: "variance",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.variance, b.variance),
                                multiple: 3,
                            },
                            width: '25%',
                        },
                    ]
                    response?.data?.table?.forEach((item, index) => {
                        if (index === 0) {
                            setDomain(item.domain);
                            setProjectName(item.projectCode);
                            setGropHead(item.groupHead);
                            setManager(item.manager);
                            setBilled(fixTwoPlaceDecimal(item.billedTeamMembers));
                            setAllocated(fixTwoPlaceDecimal(item.allocatedTeamMembers));
                        }
                    })
                    setProfileTabcol(columns1);
                    setTabProjectDetailList(listData);
                    setProjectDetailList(listData);
                    setProfileExportList(listData);
                };
            }
        );
    };

    return (
        <>
            <div className="portlet mt-4 bandwidth-analysis">

                <div className="portlet-header portlet-header-bordered px-4 mx-1">
                    <h3 className="portlet-title ps-0">Bandwidth Analysis</h3>
                    <div className="d-flex justify-content-between align-items-center">
                        <button
                            className="btn-outline-export d-flex align-items-center"
                            type="button"
                            onClick={() => {
                                exportToEXCEL(analysisexport, "BandwidthAnalysis");
                            }}
                        >
                            <FeatherIcon icon="download" />
                            <span className='ms-2'>Export</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-12 my-3 px-1">
                    <Table
                        className="bandwidth-analysis-table table-hover table-responsive custome_filter"
                        bordered
                        dataSource={tabAnalysislist}
                        columns={AnalysisTabcol}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            defaultPageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} records`,
                        }}
                        expandRowByClick={true}
                        expandIconColumnIndex={6}
                        rowClassName={(record) => {
                            return record.expanded ? "active" : "";
                        }}
                        expandable={{
                            onExpandedRowsChange: (data) => {
                                settabAnalysisList([]);
                                let list = tabAnalysislist
                                list.forEach(x => {
                                    x.expanded = data && data.includes(x.key);
                                });
                                settabAnalysisList(list);
                            },
                            expandIcon: ({ expanded, onExpand, record }) => {
                                return record.expanded ?
                                    (
                                        <UpOutlined style={{ float: 'right' }} />
                                    )
                                    :
                                    (
                                        <DownOutlined style={{ float: 'right' }} />
                                    );
                            },

                        }}
                    />
                    <Drawer
                        title="Project Profile"
                        className="project-profile-modal"
                        placement="right"
                        size={size}
                        onClose={onClose}
                        open={writeropen}
                    >
                        <div>
                            <div className='col-md-12 myntra-info'>
                                <div className='band-icon-circle'>
                                    <span>{projectname.charAt(0).toUpperCase()}</span>
                                </div>

                                <div className='myntra-title-text'>
                                    <div>
                                        <h4>{projectname}</h4>
                                        <span className='mynta-link mt-3 d-block'>{domain}</span>
                                    </div>

                                    <div className='d-flex align-items-end my-details mt-4'>
                                        <div className='me-3 pe-1 bandwidth_grouphead'>
                                            <p className='mb-0'>{grophead}</p>
                                            <span className='mynta-link'>Group Head</span>
                                        </div>

                                        <div className='me-3 pe-1'>
                                            <p className='mb-0 '>{manager}</p>
                                            <span className='mynta-link'>Managers</span>
                                        </div>

                                        <div className='me-3 pe-1'>
                                            <p className='mb-0'>{billed}</p>
                                            <span className='mynta-link'>Billed Team Members</span>
                                        </div>

                                        <div>
                                            <p className='mb-0'>{allocated}</p>
                                            <span className='mynta-link'>Allocated Team Members</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Tabs>
                                    <div className="portlet-header portlet-header-bordered ">
                                        <TabList className="nav nav-lines portlet-nav bandwidth-title ">
                                            <Tab
                                                className={
                                                    BandwidthSplitTab
                                                        ? "nav-item nav-link active"
                                                        : "nav-item nav-link"
                                                }
                                                onClick={() => {
                                                    setBandwidthSplitTab(true);
                                                }}>
                                                Bandwidth Split
                                            </Tab>
                                        </TabList>
                                    </div>
                                    <TabPanel></TabPanel>
                                    <TabPanel></TabPanel>
                                </Tabs>
                            </div>

                            <div className="row">
                                <div className="modal-search-btn d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="tab-panel-url-options url-tab">
                                        <div class="input-group-icon input-group-lg widget15-compact">
                                            <div class="input-group-prepend">
                                                <FeatherIcon icon="search" />
                                            </div>
                                            <span>
                                                <Input
                                                    allowClear
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                    onChange={(nameSearch) => {
                                                        setTabProjectDetailList(
                                                            projectdetaillist.filter((person) =>
                                                                person.role
                                                                    .toLocaleLowerCase()
                                                                    .includes(
                                                                        nameSearch.target.value.toLocaleLowerCase()
                                                                    )
                                                            )
                                                        );
                                                    }}
                                                    id="input-s"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="tab-panel-options competitors-tab">
                                        <button
                                            className="btn-outline-export d-flex align-items-center"
                                            type="button"
                                            onClick={() => {
                                                exportToEXCEL(
                                                    profileExportList,
                                                    "Project Profile"
                                                );
                                            }}>
                                            <FeatherIcon icon="download" />
                                            <span className='ms-2'>Export</span>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="myntra-bandwidth-table table-hover"
                                        id="sample"
                                        columns={profiletabcol}
                                        dataSource={tabprojectdetaillist}
                                        pagination={{
                                            position: ["bottomRight"],
                                            showSizeChanger: true,
                                            defaultPageSize: 5,
                                            pageSizeOptions: [5, 10, 20, 50, 100],
                                            showTotal: (total, range) =>
                                                `Showing ${range[0]} to ${range[1]} of ${total} records`,
                                        }}
                                    >
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div>
        </>
    )
}

export default BandwidthAnalysis