import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  padding: "0.75rem",
  border: `1px solid ${Colors.neutral[50]}`,
  minWidth: "26.3rem",
  display: "inline-block",
  borderRadius: "0.38rem",
});

export const title = css({
  ...textStyles.h6,
  color: Colors.neutral[600],
  display: "flex",
  alignItems: "center",
  columnGap: "0.4rem",
  marginBottom: "0.97rem",
});

export const subtitle = css({
  color: Colors.primary[500],
});

export const growthIcon = (isPositive) => {
  return css({
    height: "1.25rem",
    width: "1.25rem",
    color: isPositive ? Colors.semantic[500] : Colors.error[800],
  });
};

export const comparisionWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const left = css({
  display: "flex",
  flexDirection: "column",
  columnGap: "0.72rem",
});

export const date = css({
  ...textStyles.b3,
  color: Colors.neutral[600],
});
export const count = css({
  ...textStyles.b1,
  color: Colors.neutral[800],
});

export const growth = (isPositive, isZero) => {
  return css({
    ...textStyles.h5,
    color: isZero? Colors.secondary[500] : isPositive ? Colors.semantic[500] : Colors.error[800],
  });
} 

export const infinityIcon = css({
  display: "block",
  width: "2.25rem",
  height: "auto" 
})