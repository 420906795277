import React from "react";
import { useNavigate } from "react-router-dom";

const SEOAudit = () => {
  const navigate = useNavigate();

  return (
    <>
      <div id="main-angle" className="none dis-none">
        <i
          className="fa fa-arrow-left"
          id="angle-main"
          onClick={() => navigate(-1)}
        ></i>
      </div>
      <i
        className="fa fa-arrow-left"
        id="angle-main"
        onClick={() => navigate(-1)}
      ></i>
      <div class="row">
        <div class="col-sm-5 pad-lzero">
          <div class="main-title">Audit Findings</div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div>
      <div class="col-md-12 mb-3">
        <label htmlFor="" style={{ marginRight: 0 + "px" }}>
          Audit
        </label>
        <select style={{ padding: 8 + "px" }} class="w-100"></select>
      </div>
      <div class="col-md-12">
        <label>Audit Name</label>
        <input
          type="text"
          id="auditname"
          name="auditname"
          class="w-100"
        ></input>
      </div>
      <div className="col-md-12 mt-3 text-right">
        <button class="btn btn-primary">New Audit</button>

        <button class="btn btn-primary ms-3">Copy Audit</button>
      </div>
    </>
  );
};

export default SEOAudit;
