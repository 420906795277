import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  boxShadow: Colors.cardShadow3,
  border: `1px solid ${Colors.neutral[50]}`,
  borderRadius: "0.375rem",
  display: "inline-flex",
  padding : "0.75rem 0"
});

export const details = (isLast) =>  css({
    padding: "0 1.5rem",    borderRight: isLast ? "none" : `1px solid ${Colors.neutral[50]}`
})

export const count = css({
  ...textStyles.s1,
  color: Colors.neutral[900],
  marginBottom: "0.38rem"
});

export const label = css({
  ...textStyles.b3,
  color: Colors.neutral[600],
});

export const success = css({
  color: Colors.semantic[500],
});
export const failure = css({
  color: Colors.error[800],
});
