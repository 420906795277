/** @jsxImportSource @emotion/react */
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";
import * as styles from "./styles"
import { useEffect, useState } from "react";
import { TicketSvg } from "../ticketSvg";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CustomTicketingButton from "../customButton2";
import SubTicketCounter from "../subTicketCounter";
import TicketCard from "../ticketDetailCard";
import DropdownInput from "../customInput";
import DatePickerInput from "../customDatePicker";
import TimeWidget from "./timeWidget";
import TicketStatusCard from "../customComponents/ticketStatusCard";
import TicketActionCard from "../customComponents/ticketActionCard";
import MoreWidget from "../customComponents/moreWidget";
import { useTicketingStore } from "../ticketingStore";
import CustomDatePicker from "../customComponents/datePicker";
import DeleteModal from "../customComponents/deleteModal";
import { createSubticket, deleteMainTicket, deleteSubTicket, getAllDetailsOfTicket, getMembersForSubticket, updateMainTicket, updateSubTicket, updateTicketStatus } from "../../../services/ticketing";
import toast from "react-hot-toast";
import DropDownInput from "../../shared/Dropdown/Input";
import moment from "moment";
import SpinContainer from "@semcore/ui/spin-container"
import ErrorScreen from "../../shared/errorScreen";
import AssetsImg from "../../assets/images";

const TicketDetailPage = () => {

    const location = useLocation()
    const navigate = useNavigate();

    // STORE STATES
    const drawerOpen = useTicketingStore((state) => state.drawerOpen)
    const setDrawerOpen = useTicketingStore((state) => state.setDrawerOpen)

    const priorityList = useTicketingStore((state) => state.priorityList)

    const ticketDetails = useTicketingStore((state) => state.ticketDetails)
    const setTicketDetails = useTicketingStore((state) => state.setTicketDetails)
    const ticket = useTicketingStore((state) => state.ticket)
    const setTicket = useTicketingStore((state) => state.setTicket)
    const formEdit = useTicketingStore((state) => state.formEdit)
    const setFormEdit = useTicketingStore((state) => state.setFormEdit)


    const ticketForm = useTicketingStore((state) => state.ticketForm)
    const setTicketForm = useTicketingStore((state) => state.setTicketForm)
    const setTicketFormInit = useTicketingStore((state) => state.setTicketFormInit)
    // COMPONENT STATES
    const [isLoading, setIsLoading] = useState(false)

    const [ticketDetailsEdit, setTicketDetailsEdit] = useState({
        priority: "High",
        eta: "",
        // assignedTo: ""
    })

    const [priorityOptions, setPriorityOptions] = useState({
        options: [],
        active: 0
    })

    const [assignedToOptions, setAssignedToOptions] = useState({
        options: [],
        active: 0
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        id: null,
        isSubTicket: false
    })

    const [apiFail, setApiFail] = useState(false)


    // UTILITY FUNCTIONS

    const openDrawer = (drawerName) => {
        setDrawerOpen({ open: true, drawer: drawerName })
    }

    const onDrawerClose = () => {
        setDrawerOpen({ ...drawerOpen, open: false })
    }


    const getTicketDetails = (id) => {
        setIsLoading(true)
        let body = {
            user_id: localStorage.getItem("userId")
        }
        let ticketId = id ? id : location?.search?.slice(1).length > 0 ? location?.search?.slice(1) : localStorage.getItem("ticketId")
        getAllDetailsOfTicket(ticketId, body)?.then((response) => {
            if (response?.status == "success") {
                setApiFail(false)
                let mainDetails = {}
                let subTicketsDetails = [];
                let isSubTicket = false
                let isCreator = false
                if (!response?.mainticket_data) {
                    mainDetails = response?.subticket_data[0]
                    isSubTicket = true
                } else {
                    mainDetails = response?.mainticket_data
                    subTicketsDetails = response?.subticket_data ?? []
                    isSubTicket = false
                }
                isCreator = mainDetails?.created_by == localStorage?.getItem("userId")
                localStorage.setItem("ticketId", mainDetails?.id)
                setTicketDetails({ mainDetails, subTicketsDetails, isSubTicket, isCreator })
                // getMembers(isSubTicket ? mainDetails?.mainticket_id : mainDetails?.id)
                setTicketDetailsEdit({
                    priority: mainDetails?.ticket_priority_name ?? "",
                    eta: mainDetails?.eta ?? "",
                    assignedTo: mainDetails?.assigned_to_name ?? ""
                })
                let formData = {
                    type: mainDetails?.ticket_type_name,
                    typeId: mainDetails?.ticket_type,
                    client: mainDetails?.client_name,
                    clientId: mainDetails?.client_id,
                    project: mainDetails?.project_name,
                    projectId: mainDetails?.project_id,
                    subject: mainDetails?.subject,
                    description: mainDetails?.description,
                    eta: mainDetails?.eta,
                    priority: mainDetails?.ticket_priority_name,
                    priorityId: mainDetails?.ticket_priority,
                    department: mainDetails?.department_name,
                    departmentId: mainDetails?.department_id,
                    member: mainDetails?.assigned_to_name,
                    memberId: mainDetails?.assigned_to
                }
                setFormEdit(true)
                setTicketForm(formData)
                setTicketFormInit(formData)
                let priorityData = {
                    options: priorityList?.map((prio) => { return { label: prio.label, value: prio.label, id: prio.value } }) ?? [],
                    active: 0
                }
                setPriorityOptions(priorityData)
                setIsLoading(false)
            }
        }).catch((error) => {
            setApiFail(true)
            setIsLoading(false)
        });
    }

    useEffect(() => {
        getTicketDetails()
        return () => {
            setTicketDetails({})
        }
    }, [location?.search])



    useEffect(() => {
        // const handleCustomEvent = (event) => {
        //     // console.log('Custom event received in Component B:', event.detail.data);
        // };
        const callGetDetails = () => getTicketDetails()

        window.addEventListener('updatedTicket', callGetDetails);
        return () => {
            window.removeEventListener('updatedTicket', callGetDetails);
        };
    }, []);

    const getMembers = (id) => {
        let depId = ticketDetails?.mainDetails?.department_id
        let body = {
            mainticket_id: id,
            user_id: localStorage.getItem("userId"),
            ...(depId ? { department_id: depId } : ticketDetails?.mainDetails?.project_id ? { project_id: ticketForm?.project_id } : ticketDetails?.mainDetails?.client_id && { client_id: ticketForm?.client_id }),
            organization_id: localStorage.getItem("organizationId"),
        }
        getMembersForSubticket(body)?.then((response) => {
            if (response?.status == "success") {
                let memberData = {
                    options: response?.data?.map((val) => { return { label: val.name, value: val.name, id: val.id } }) ?? [],
                    active: 0
                }

                setAssignedToOptions(memberData)
            }
        })
    }

    const addSubTicket = () => {
        createSubticket().then((response) => {

        })
    }

    const updateApi = (isSubTicket, body) => {
        if (isSubTicket) {
            return updateSubTicket(localStorage?.getItem("userId"), body)
        } else {
            return updateMainTicket(localStorage?.getItem("userId"), body)
        }
    }

    const updateTicket = ({ id, priorityId, assignedToId, eta }) => {
        let isSubTicket = ticketDetails?.isSubTicket ? true : false
        let body = {
            // mainticket_id: isSubTicket ? ticketDetails?.mainDetails?.mainticket_id : ticketDetails?.mainDetails?.id,
            ...(isSubTicket ? { subticket_id: ticketDetails?.mainDetails?.id } : { mainticket_id: ticketDetails?.mainDetails?.id }),
            ...(priorityId && { ticket_priority: priorityId }),
            ...(assignedToId && { assigned_to: assignedToId }),
            ...(eta && { eta: eta })
        }
        updateApi(isSubTicket, body)?.then((response) => {
            if (response.status == "success") {
                getTicketDetails()
                toast.success("Ticket Updated Successfully")
            }
        })?.catch((error) => {
            if (error.response.data.status == "error") {
                getTicketDetails()
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error("Failed to Update Ticket");
                }
            }
        })
    }

    const deleteApi = () => {
        let mainBody = { mainticket_id: ticketDetails?.mainDetails?.id }
        let subBody = { subticket_id: ticketDetails?.mainDetails?.id }
        if (ticketDetails?.isSubTicket) {
            return deleteSubTicket(localStorage?.getItem("userId"), subBody)
        } else {
            return deleteMainTicket(localStorage?.getItem("userId"), mainBody)
        }
    }

    const deleteTicket = () => {
        deleteApi().then((response) => {
            if (response.status == "success") {
                setDeleteModal({ open: false })
                toast.success("Ticket Deleted Successfully")
                navigate(`/tickets/${location.pathname?.split('/')[2]}`)
            }
        })?.catch((error) => {
            if (error.response.data.status == "error") {
                // getTicketDetails()
                if (error.response.data.error) {
                    setDeleteModal({ open: false })
                    toast.error(error.response.data.error);
                } else {
                    // toast.error("Failed to ");
                }
            }
        })
    }

    const changeTicketStatus = (status) => {
        let body = {
            ticket_id: ticketDetails?.mainDetails?.id,
            ticket: ticketDetails?.isSubTicket ? "subticket" : "mainticket",
            ticket_status: status
        }
        updateTicketStatus(localStorage.getItem("userId"), body).then((response) => {
            if (response.status == "success") {
                // getTicketDetails()
                status == "Rejected" ? setStatus("Rejected", response?.updated_state) : setStatus(response?.updated_status, response?.updated_state)
                toast.success(response.message)
            }
        })?.catch((error) => {
            if (error.response.data.status == "error") {
                getTicketDetails()
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                } else {
                    // toast.error("Failed to ");
                }
            }
        })
    }

    const setStatus = (status, state) => {
        setTicketDetails({
            ...ticketDetails,
            mainDetails: {
                ...ticketDetails?.mainDetails,
                ticket_status_name: status,
                ...(state && { ticket_state_name: state })
            }
        })
    }

    const getTimeFormatted = (time) => {
        // let time = " 10 mins"
        const hoursRegex = /(\d+)\s*hrs/;
        const minutesRegex = /(\d+)\s*mins/;

        let hours = 0;
        let minutes = 0;

        const hoursMatch = time?.match(hoursRegex);
        if (hoursMatch) {
            hours = parseInt(hoursMatch[1]);
        }
        const minutesMatch = time?.match(minutesRegex);
        if (minutesMatch) {
            minutes = parseInt(minutesMatch[1]);
        }
        return (
            <div css={styles.timeValue}>
                {hours}
                <span css={styles.timeText}>{` hrs `}</span>
                {`: `}
                <span>{minutes}</span>
                <span css={styles.timeText}>{` min`}</span>
            </div>
        )
    }

    return (
        <>
            {apiFail ?
                <ErrorScreen
                    title={"No Data Found"}
                    subtitle={"Try Changing your filters or try refreshing the page"}
                    icon={AssetsImg.ticket_empty_table}
                    buttonText={"Refresh"}
                    buttonIcon={"refresh-cw"}
                    onRetry={() => getTicketDetails()}
                />
                :
                <div css={styles.TicketDetailPage}>
                    <div css={{ width: "80%", overflow: "hidden" }}>
                        <div className="m-3">
                            <div css={styles.ticketDetailMainCard}>
                                <div css={{ display: "flex", width: "95%" }}>
                                    <div css={styles.subTicketIcon}>
                                        <TicketSvg color={Colors.primary[500]} />
                                    </div>
                                    <div>
                                        <div css={{ ...textStyles.h6 }}>{ticketDetails?.mainDetails?.subject}</div>
                                        {ticketDetails?.mainDetails?.description && <div css={{ ...textStyles.H1Medium, color: Colors.neutral[500], wordBreak: "break-all" }}>
                                            {ticketDetails?.mainDetails?.description}
                                        </div>}
                                        <div css={styles.ticketDetails}>
                                            {/* <div css={{ marginRight: "1.5rem" }}>#00001</div> */}
                                            {ticketDetails?.mainDetails?.created_at && <div>
                                                {/* <ul><li>26 January 2024, 12.30 PM</li></ul> */}
                                                <ul><li>{ticketDetails?.mainDetails?.created_at}</li></ul>
                                            </div>}
                                        </div>

                                        {
                                            ticketDetails?.mainDetails?.mainticket_id ?
                                                <div css={styles.subTicketPill}>{`Subticket ${ticketDetails?.mainDetails?.subject?.split("#")[ticketDetails?.mainDetails?.subject?.split("#")?.length - 1]}`}</div>
                                                :
                                                <>

                                                    <div className="d-flex">
                                                        {!(ticketDetails?.mainDetails?.ticket_type_name == "SOW" || ticketDetails?.mainDetails?.ticket_type_name == "SOW Adhoc")
                                                            && [ticketDetails?.mainDetails?.created_by, ticketDetails?.mainDetails?.assigned_to].includes(localStorage.getItem("userId"))
                                                            &&
                                                            <CustomTicketingButton
                                                                onClick={() => openDrawer("subTicket")}
                                                                className={"me-5"}
                                                                size={4}
                                                                buttonName={"Create Subticket"}
                                                                featherIcon={"plus"}
                                                            />
                                                        }
                                                        {ticketDetails?.mainDetails?.sub_ticket_count > 0 &&
                                                            <SubTicketCounter onClick={() => openDrawer("subTicket")} count={ticketDetails?.mainDetails?.sub_ticket_count} />
                                                        }
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                {ticketDetails?.mainDetails?.created_by == localStorage.getItem("userId") &&
                                    <div>
                                        <MoreWidget
                                            dataOptions={[
                                                {
                                                    name: "Edit", icon: "edit", color: Colors.neutral[500], action: () => {
                                                        getTicketDetails()
                                                        openDrawer("createTicket")
                                                    }
                                                },
                                                { name: "Delete", icon: "trash-2", color: Colors.error[800], action: () => { setDeleteModal({ open: true }) } }
                                            ]}
                                        />
                                    </div>}
                            </div>
                        </div>
                        <div className="m-3" css={{ backgroundColor: "white", height: "100%" }}></div>
                    </div>
                    <div css={styles.rightSection}>

                        {!ticketDetails?.isCreator ?
                            ["Available", "Pending"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) ?
                                ((ticketDetails?.mainDetails?.assigned_to == localStorage.getItem("userId") || ticketDetails?.mainDetails?.assigned_to == null)) ?
                                    <div css={styles.pickUpButton} onClick={() => changeTicketStatus("Picked Up")}>
                                        <FeatherIcon icon="check" color="white" size={20} />
                                        <span className="ms-2">Pickup Ticket</span>
                                    </div> : <></>
                                : ["In Progress"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) ?
                                    <TimeWidget changeTicketStatus={changeTicketStatus} getTicketDetails={getTicketDetails} />
                                    : ticketDetails?.mainDetails?.ticket_status_name == "Awaiting Approval" ?
                                        <div className="">
                                            <TicketStatusCard ticketStatus={"awaitingApproval"} lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                        </div>
                                        : ticketDetails?.mainDetails?.ticket_status_name == "Completed" ?
                                            <div className="">
                                                <TicketStatusCard ticketStatus="closed" lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                            </div>
                                            : <></>
                            : <></>
                        }


                        {ticketDetails?.isCreator && ticketDetails?.mainDetails?.assigned_to !== localStorage.getItem("userId") ?
                            ticketDetails?.mainDetails?.ticket_status_name == "Awaiting Approval" ?
                                <div className="">
                                    <TicketActionCard handleSubmit={(status) => changeTicketStatus(status)} lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                </div>
                                : ticketDetails?.mainDetails?.ticket_status_name == "Rejected" ?
                                    <div className="">
                                        <TicketStatusCard ticketStatus="reassigned" lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                    </div>
                                    : ticketDetails?.mainDetails?.ticket_status_name == "Completed" ?
                                        <div className="">
                                            <TicketStatusCard ticketStatus="closed" lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                        </div>
                                        :
                                        <></>
                            :
                            <></>
                        }

                        {ticketDetails?.isCreator && ticketDetails?.mainDetails?.assigned_to == localStorage.getItem("userId") ?
                            <>
                                {["Rejected", "Available", "Pending"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) ?
                                    <div css={styles.pickUpButton} onClick={() => changeTicketStatus("Picked Up")}>
                                        <FeatherIcon icon="check" color="white" size={20} />
                                        <span className="ms-2">Pickup Ticket</span>
                                    </div>
                                    : ticketDetails?.mainDetails?.ticket_status_name == "In Progress" ?
                                        <TimeWidget changeTicketStatus={changeTicketStatus} getTicketDetails={getTicketDetails} />
                                        : ticketDetails?.mainDetails?.ticket_status_name == "Awaiting Approval" ?
                                            <div className="">
                                                <TicketActionCard handleSubmit={(status) => changeTicketStatus(status)} lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                            </div>
                                            : ticketDetails?.mainDetails?.ticket_status_name == "Completed" ?
                                                <div className="">
                                                    <TicketStatusCard ticketStatus="closed" lastUpdated={ticketDetails?.mainDetails?.updated_at} />
                                                </div>
                                                :
                                                <></>
                                }
                            </>
                            : <></>

                        }

                        <div className="mt-3">
                            <TicketCard title={
                                <div>
                                    <div>{ticketDetails?.mainDetails?.mainticket_id ? "Time Logged" : "Total Time Logged"}</div>
                                    {ticketDetails?.mainDetails?.mainticket_id &&
                                        <div css={{ ...textStyles.b3, textAlign: "center" }}>{`(Subticket)`}</div>
                                    }
                                </div>
                            } titleRight={ticketDetails?.mainDetails?.mainticket_id ? getTimeFormatted(ticketDetails?.mainDetails?.time_log) : getTimeFormatted(ticketDetails?.mainDetails?.total_mainticket_subticket_time_log)}>

                                {!ticketDetails?.isSubTicket && (Number(ticketDetails?.mainDetails?.time_log?.split(' ')[0]) > 0 || Number(ticketDetails?.mainDetails?.time_log?.split(' ')[2]) > 0) &&
                                    <div css={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>Main Ticket</div>
                                        <div>{getTimeFormatted(ticketDetails?.mainDetails?.time_log)}</div>
                                    </div>}
                                {!ticketDetails?.isSubTicket && (Number(ticketDetails?.mainDetails?.subticket_total_time_log?.split(' ')[0]) > 0 || Number(ticketDetails?.mainDetails?.subticket_total_time_log?.split(' ')[2]) > 0) &&
                                    <div css={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                                        <div>SubTicket</div>
                                        <div>{getTimeFormatted(ticketDetails?.mainDetails?.subticket_total_time_log)}</div>
                                    </div>}

                            </TicketCard>
                        </div>

                        <div className="mt-3">
                            <TicketCard title={"Client Details"}>
                                <div className="mb-3">
                                    <div css={{ ...textStyles.s3, color: Colors.neutral[500] }}>{"Client"}</div>
                                    <div css={{ ...textStyles.s1 }}>{ticketDetails?.mainDetails?.client_name}</div>
                                </div>
                                <div className="mb-3">
                                    <div css={{ ...textStyles.s3, color: Colors.neutral[500] }}>{"Created By"}</div>
                                    <div css={{ ...textStyles.s1 }}>{ticketDetails?.mainDetails?.created_by_name}</div>
                                </div>
                                {/* <div className="mb-3">
                            <div css={{ ...textStyles.s3, color: Colors.neutral[500] }}>{"Point of Contact"}</div>
                            <div css={{ ...textStyles.s1 }}>{ticketDetails?.mainDetails?.points_of_contact}</div>
                        </div> */}
                            </TicketCard>
                        </div>

                        <div className="mt-3">
                            <TicketCard title={"Ticket Details"}>
                                {["SOW", "SOW Adhoc"]?.includes(ticketDetails?.mainDetails?.ticket_type_name) && ticketDetails?.mainDetails?.assigned_units > 0 &&
                                    <div className="mb-3">
                                        <div css={{ ...textStyles.s2Medium, marginBottom: "0.3rem" }}>Units</div>
                                        <div css={styles.detailField()}>{ticketDetails?.mainDetails?.assigned_units}</div>
                                    </div>}
                                <div className="mb-3">
                                    <div css={{ ...textStyles.s2Medium, marginBottom: "0.3rem" }}>Assigned</div>
                                    {
                                        !ticketDetails?.isCreator ?
                                            <div css={styles.detailField()}>{ticketDetailsEdit?.assignedTo}</div>
                                            :
                                            <DropDownInput
                                                addPopperStyles={styles.selectDropDownContainer}
                                                data={assignedToOptions}
                                                showSearchBar={true}
                                                nestedTrigger={ticketDetailsEdit?.assignedTo}
                                                placeholder={"Select Member"}
                                                // placement={"right-start"}
                                                handleDropdownClick={(selected, value, id) => {
                                                    updateTicket({ assignedToId: id })
                                                }}
                                                onClick={() => getMembers(ticketDetails?.isSubTicket ? ticketDetails?.mainDetails?.mainticket_id : ticketDetails?.mainDetails?.id)}
                                                defaultValue={ticketDetailsEdit?.assignedTo}
                                                triggerStyles={styles.assignedToStyles(
                                                    // ["SOW", "SOW Adhoc"]?.includes(ticketDetails?.mainDetails?.ticket_type_name) ? true :
                                                    !["Picked Up", "Completed", "In Progress", "Completed", "Awaiting Approval"].includes(ticketDetails?.mainDetails?.ticket_status_name) ? false :
                                                        !["Not PickedUp", "Rejected", "Pending"].includes(ticketDetails?.mainDetails?.ticket_state_name) ? true : false
                                                )}
                                            />}
                                </div>
                                <div className="mb-4">
                                    <div css={{ ...textStyles.s2Medium }}>Priority</div>
                                    {
                                        !ticketDetails?.isCreator ?
                                            <div css={styles.priorityField(ticketDetailsEdit?.priority)}>{ticketDetailsEdit?.priority}</div>
                                            :
                                            <DropDownInput
                                                addPopperStyles={styles.selectDropDownContainer}
                                                data={priorityOptions}
                                                showSearchBar={false}
                                                nestedTrigger={ticketDetailsEdit?.priority}
                                                placeholder={"Select"}
                                                // placement={"right-start"}
                                                handleDropdownClick={(selected, value, id) => {
                                                    updateTicket({ priorityId: id })
                                                }}
                                                defaultValue={ticketDetailsEdit?.priority}
                                                triggerStyles={styles.priorityStyles(
                                                    ticketDetailsEdit?.priority,
                                                    ticketDetails?.mainDetails?.ticket_status_name ? ["Completed", "Awaiting Approval"]?.includes(ticketDetails?.mainDetails?.ticket_status_name) : false
                                                )}
                                            />}
                                </div>
                                <div className="mb-3">
                                    <div css={{ ...textStyles.s2Medium, marginBottom: "0.3rem" }}>ETA</div>
                                    {!ticketDetails?.isCreator ?
                                        <div css={styles.detailField()}>{new Date(ticketDetailsEdit?.eta).toLocaleDateString("en-uk", { day: "numeric", month: "long", year: "numeric" })}</div>
                                        :
                                        <div css={{ ...((["Completed", "Awaiting Approval"]?.includes(ticketDetails?.mainDetails?.ticket_status_name)) && { pointerEvents: "none", opacity: "80%" }) }}>
                                            <CustomDatePicker
                                                minDate={new Date()}
                                                placeholder={"ETA"}
                                                placement={"top-end"}
                                                value={ticketDetailsEdit?.eta}
                                                handleDateApply={(date) => {
                                                    updateTicket({ eta: moment(date).format("YYYY-MM-DD") })
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </TicketCard>
                        </div>

                    </div >

                    <DeleteModal visible={deleteModal?.open} handleModalClose={() => setDeleteModal({ open: false })} handleDelete={() => { deleteTicket() }} />

                    {(isLoading) && (
                        <SpinContainer
                            loading={true}
                            background={"rgba(0,0,0,0.4)"}
                            css={styles.loader}
                        ></SpinContainer>
                    )}
                </div >}
        </>
    )
}

export default TicketDetailPage;