import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const statusContainer = (theme) => {
    let color
    switch (theme) {
        case 'primary':
            color = Colors.primary[500]
            break;
        case 'success':
            color = Colors.success[300]
            break;
        case 'error':
            color = Colors.error[800]
            break;
        default:
            color = Colors.black
    }

    return css({
        ...textStyles.s2Medium,
        cursor: "default",
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "6px",
        padding: "1rem",
        backgroundColor: Colors.plainWhite,
        border: `1px solid ${color}`,
        color: color
    })
}