/** @jsxImportSource @emotion/react */
import React from 'react'
import * as styles from "./styles";

const Title = ({text}) => {
  return (
    <div css={styles.title}>
        {text}
    </div>
  )
}

export default Title