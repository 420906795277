/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import Select, { InputSearch } from "@semcore/ui/select";
import * as styles from "./styles";
import { Text } from "@semcore/ui/typography";
import { Flex, Box } from "@semcore/ui/flex-box";
import Tooltip from "@semcore/ui/tooltip";
import InfoM from "@semcore/icon/Info/m";
import FeatherIcon from "feather-icons-react";

const DropDownInput = ({
  defaultValue = "Select",
  placeholder,
  showSearchBar,
  data,
  handleDropdownClick,
  multiselect,
  addStyles,
  nestedTrigger,
  placement,
  toggleVisible,
  addPopperStyles,
  disableDeselectAll,
  onBlur,
  triggerStyles,
  optionStyles,
  showTrigger = true,
  value,
  onClick
}) => {
  const [filter, setFilter] = React.useState("");
  const options = React.useMemo(
    () =>
      data.options.filter((option) =>
        option.label.toString().toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, data]
  );

  const handleSelection = (selectedIndexes) => {
    const data1 = selectedIndexes.map((value) => options[value]);
    handleDropdownClick(data1, selectedIndexes);
  };

  const handleMultiSelection = (selectedIndexes) => {
    const data1 = selectedIndexes.map((value) => data.options[value]);
    handleDropdownClick(data1, selectedIndexes);
  };
  if (!multiselect) {
    return (
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        css={[styles.main, addStyles && addStyles]}
        value={value}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            toggleVisible();
          }
        }}
      >
        {(props, handlers) => {
          return (<React.Fragment><Select.Trigger
            onClick={onClick ? () => onClick() : () => { }}
            css={[styles.trigger, styles.iconStyle(props.visible), triggerStyles && triggerStyles]}
            onBlur={onBlur}
          />
            <Select.Popper css={[optionStyles && styles.customPopper]} zIndex={20000}>
              {({ highlightedIndex }) => (
                <>
                  {showSearchBar && (
                    <InputSearch value={filter} onChange={setFilter}>
                      <InputSearch.Value
                        placeholder="Search"
                        role="combobox"
                        aria-autocomplete="list"
                        aria-controls="search-list"
                        aria-owns="search-list"
                        aria-expanded="true"
                        aria-activedescendant={`option-${highlightedIndex}`}
                        css={[styles.searchInput, styles.text]}
                      />
                      <InputSearch.Clear
                        onClick={() => {
                          return true;
                        }}
                      />
                    </InputSearch>
                  )}
                  <Select.List hMax={"224px"} id="search-list">
                    {options.map((option, index) => (
                      <Select.Option
                        value={option.label}
                        key={index}
                        onClick={() => {
                          handleDropdownClick(index, option.value, option?.id)
                        }}
                        css={[styles.text, styles.optionContainer, optionStyles && optionStyles]}
                        onBlur={onBlur}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          css={[styles.optionHeader, optionStyles && styles.customOption(props.value === option.label)]}
                        >
                          <Flex>
                            {option.label}
                            {option.info && (
                              <Tooltip
                                p={0}
                                interaction={"hover"}
                                placement="top-start"
                              >
                                <Tooltip.Trigger>
                                  <InfoM
                                    p={0}
                                    color="gray-300"
                                    cursor="help"
                                    tabIndex={0}
                                    css={styles.infoIcon}
                                  />
                                </Tooltip.Trigger>
                                <Tooltip.Popper css={styles.tooltip}>
                                  {option.info}
                                </Tooltip.Popper>
                              </Tooltip>
                            )}
                          </Flex>
                          {option.count && (
                            <Box css={styles.secondaryColor}>{option.count}</Box>
                          )}
                        </Flex>
                        {option.additionalInfo && (
                          <Flex css={styles.secondaryColor}>
                            {option.additionalInfo}
                          </Flex>
                        )}
                      </Select.Option>
                    ))}
                    {!options.length && (
                      <Select.OptionHint key="Nothing">
                        Nothing found
                      </Select.OptionHint>
                    )}
                  </Select.List>
                </>
              )}
            </Select.Popper></React.Fragment>)
        }}
      </Select>
    );
  } else {
    return (
      <Select
        key={defaultValue}
        placeholder={placeholder}
        multiselect
        css={[styles.main, addStyles && addStyles]}
        placement={placement}
        defaultValue={defaultValue}
        // onChange={(value) => handleSelection(value)}
        onBlur={onBlur}
        isVisible={true}
      >
        {(props, handlers) => {
          const {
            value: currentValue
          } = props;
          const {
            visible, // function that controls the internal state of visibility
            value, // function that controls the internal state of the selected value
          } = handlers;
          const handleSelectAll = () => {
            const newValue = data.options.map((option, index) => index);
            value(newValue);
            return false; // cancel the default handler
          };
          const handleResetClick = () => {
            value([]);
          };
          const onApplyClick = () => {
            if (currentValue.length > 0) {
              handleMultiSelection(currentValue);
              visible(false);
            }
          };

          const checkSelected = (label) => {
            return currentValue.some((activeIndex)=> data.options[activeIndex].label === label)
          }
          const handleClick = (label) => {
            const index = data.options.findIndex((option) => option.label === label);
            if (currentValue.includes(index)) {
              value(currentValue.filter((item) => item !== index));
            } else {
              if(index !== -1){
                value([...currentValue, index]);
              } else {
                value([...currentValue]);
              }
            }
          }
          return (
            <React.Fragment>
              {nestedTrigger ? (
                <Select.Trigger css={[styles.trigger, styles.iconStyle(props.visible)]} >
                  <div css={styles.triggerText}>{nestedTrigger}</div>
                </Select.Trigger>
              ) : (
                <Select.Trigger css={styles.trigger} />
              )}
              <Select.Popper
                css={[styles.popper, addPopperStyles && addPopperStyles]}
                isVisible={true}
              >
                {({ highlightedIndex }) => (
                  <>
                    {showSearchBar && (
                      <InputSearch value={filter} onChange={setFilter}>
                        <InputSearch.Value
                          placeholder="Search"
                          role="combobox"
                          aria-autocomplete="list"
                          aria-controls="search-list"
                          aria-owns="search-list"
                          aria-expanded="true"
                          aria-activedescendant={`option-${highlightedIndex}`}
                          css={[styles.searchInput, styles.text]}
                        />
                        {filter ? (
                          <InputSearch.Clear
                            onClick={() => {
                              return true;
                            }}
                          />
                        ) : (
                          <FeatherIcon
                            icon={"search"}
                            css={styles.searchIcon}
                          />
                        )}
                      </InputSearch>
                    )}
                    <Select.List hMax={"224px"} id="search-list">
                      <Select.Option value="%all%" onClick={handleSelectAll}>
                        <Text color="denim-blue" css={styles.text}>
                          Select all
                        </Text>
                      </Select.Option>
                      {options.map((option, index) => (
                        <div
                          value={index}
                          key={index}
                          css={[styles.labelText, styles.optionContainer,styles.option(checkSelected(option.label))]}
                          disabled={
                            currentValue.length === 1 &&
                            currentValue.includes(index) &&
                            disableDeselectAll
                          }
                          selected={checkSelected(option.label)} 
                          onClick={() => handleClick(option.label)}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            css={styles.optionHeader}
                          >
                            <div css={{display: "flex", overflow: "hidden", whiteSpace:"nowrap" , textOverflow: "ellipsis"}}>
                              <Select.Option.Checkbox
                                selected={checkSelected(option.label)}
                                css={styles.checkbox}
                                key={data.active}
                              />
                              <div css={ {overflow: "hidden", whiteSpace:"nowrap" , textOverflow: "ellipsis"}}>{option.label}</div>
                              {option.info && (
                                <Tooltip
                                  p={0}
                                  interaction={"hover"}
                                  placement="top-start"
                                >
                                  <Tooltip.Trigger>
                                    <InfoM
                                      p={0}
                                      color="gray-300"
                                      cursor="help"
                                      tabIndex={0}
                                      css={styles.infoIcon}
                                    />
                                  </Tooltip.Trigger>
                                  <Tooltip.Popper css={styles.tooltip}>
                                    {option.info}
                                  </Tooltip.Popper>
                                </Tooltip>
                              )}
                            </div>
                            {option.count && (
                              <Box css={styles.secondaryColor}>
                                {option.count}
                              </Box>
                            )}
                          </Flex>
                          {option.additionalInfo && (
                            <Flex css={styles.secondaryColor}>
                              {option.additionalInfo}
                            </Flex>
                          )}
                        </div>
                      ))}
                    </Select.List>
                    <div css={styles.footer}>
                      <div css={styles.reset} onClick={handleResetClick}>
                        Reset
                      </div>
                      <div
                        css={styles.apply(currentValue.length > 0)}
                        onClick={onApplyClick}
                      >
                        Apply
                      </div>
                    </div>
                  </>
                )}
              </Select.Popper>
            </React.Fragment>
          );
        }}
      </Select>
    );
  }
};

export default DropDownInput;
