import APIV1 from "../api/ticketingAxios";

export const createSow = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            "/sow/upload_sow_file/",
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateSowApi = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            "/sow/update_or_create_sow_task/",
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const getSowId = async (projectId) => {
    try {
        const response = await APIV1(
            "get",
            `/sow/get_sow_id/${projectId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSowDataDates = async (projectId) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/get_sow_date/${projectId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSowTicketList = async (projectId, payload) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/get_sow_all_task/${projectId}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getStatusList = async (orgId) => {
    try {
        const response = await APIV1(
            "get",
            `/sow/get_sow_status_list/${orgId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getPriorityList = async (orgId) => {
    try {
        const response = await APIV1(
            "get",
            `/ticketing/get_priorities_from_org_id/${orgId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCategoriesList = async () => {
    try {
        const response = await APIV1(
            "get",
            `/sow/categories_list/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getFrequencyList = async () => {
    try {
        const response = await APIV1(
            "get",
            `/sow/get_sow_frequency_list/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getResonsibilityList = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/get_responsible_list/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getActivities = async (category) => {
    try {
        const response = await APIV1(
            "get",
            `/sow/get_activites_list/${category}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const bulkUploadTask = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/bulk_sow_import/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const uploadIndividualTask = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/single_sow_task_upload/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const sowUpdateTask = async (id, payload) => {
    try {
        const response = await APIV1(
            "patch",
            `/sow/update_single_sow_task/${id}/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const sowDeleteTask = async (id, payload) => {
    try {
        const response = await APIV1(
            "delete",
            `/sow/single_sow_task_delete/${id}/`,
            { data: payload }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSowAssigned = async (sowTaskId) => {
    try {
        const response = await APIV1(
            "get",
            `/ticketing/get_sow_sowadhoc_subtickets/${sowTaskId}/`
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSowMembers = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/sow/get_members_for_unit_assign/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const createSowMainTicket = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/create_sow_mainticket/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const createSowSubTicket = async (payload) => {
    try {
        const response = await APIV1(
            "post",
            `/ticketing/create_sow_subticket/`,
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}
