/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Tooltip from "@semcore/ui/tooltip";
import { Tool } from "feather-icons-react/build/IconComponents";
import AssetsImg from "../../assets/images";
import { useSelector } from "react-redux";
// import Skeleton from "@semcore/ui/skeleton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Status } from "../../../utility/Constant";
import { Text } from "@semcore/typography";
import TextSkeleton from "../../shared/Skeleton";
import TrendLineChart from "../../shared/chart/line/trend";
import AreaChart from "../../shared/chart/area";

const KeyDistributionCard = ({
  isActive,
  title,
  avgPosition,
  count,
  handleClick,
  isCompare,
  showTooltip,
  info,
  growth,
  compareDates,
  comparison,
  isCustom,
  loading,
  totalKeywordCount
}) => {
  const keywordsStautus = useSelector(
    (state) => state.rankingReport.keywords.status
  );
  const graphData = useSelector((state) => state.rankingReport.graphData);

  if (isCompare) {
    return (
      <div
        css={styles.main(isActive, isCompare)}
        onClick={() => {
          if (keywordsStautus !== Status.LOADING && !loading) {
            handleClick();
          }
        }}
      >
        <div css={styles.compareHeader}>
          <div css={styles.title(isActive)}>
            {title}
            <span>
              {showTooltip && (
                <Tooltip>
                  <Tooltip.Trigger>
                    <FeatherIcon icon={"info"} css={styles.tooltipIcon} />
                  </Tooltip.Trigger>
                  <Tooltip.Popper css={styles.tooltipPopper}>
                    {info}
                  </Tooltip.Popper>
                </Tooltip>
              )}
            </span>{" "}
          </div>
          {growth !== 0 && (
            <div
              css={styles.iconContainer(
                isActive,
                growth > 0 || growth === "infinity"
              )}
            >
              <FeatherIcon
                icon={
                  growth === "infinity"
                    ? "trending-up"
                    : growth > 0
                    ? "trending-up"
                    : "trending-down"
                }
                css={styles.icon(isActive, growth > 0 || growth === "infinity")}
              />
            </div>
          )}
        </div>
        {!loading && (
          <div css={styles.growthPercent(isActive, growth >= 0, growth === 0)}>
            {growth === "infinity" ? (
              <img
                src={AssetsImg.ic_infinity_green}
                css={styles.infinityIcon}
              />
            ) : (
              `${growth}%`
            )}
          </div>
        )}
        <TextSkeleton loading={loading} lines={1} boxWidth={"9rem"} />
        {count && count.length > 0 && (
          <div css={styles.countWrapper}>
            {!loading &&
              count.toReversed().map((count, index) => {
                return (
                  <Tooltip placement="top-start" interaction={!!totalKeywordCount ? "hover" : "none"}>
                    <Tooltip.Trigger
                      css={styles.countContainerCompare(index === 0)}
                    >
                      <div css={styles.count}>
                        {count} {!!totalKeywordCount && <span css={styles.outOfCompare}>{`/ ${totalKeywordCount.toReversed()[index]}`}</span>}
                      </div>
                      <div css={styles.date}>
                        {compareDates.toReversed()[index]}
                      </div>
                    </Tooltip.Trigger>
                    <Tooltip.Popper >
                      <div css={styles.tooltipPopperCount}>
                        <span css={styles.tooltipCount}>{count}</span> keywords
                        {index === 0 ? " are currently ": " were "} ranking on SERP, out of{" "}
                        <span css={styles.tooltipCount}>{totalKeywordCount?.toReversed()[index]}</span> keywords {index === 0 ? "" : " that were "}
                        configured in this type
                      </div>
                    </Tooltip.Popper>
                  </Tooltip>
                );
              })}
            <TextSkeleton loading={loading} lines={1} boxWidth={"100%"} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        css={styles.main(isActive, null, isCustom)}
        onClick={() => {
          if (keywordsStautus !== Status.LOADING && !loading) {
            handleClick();
          }
        }}
      >
        <div css={styles.left}>
          <div css={styles.title(isActive)}>
            {title}{" "}
            <span>
              {showTooltip && (
                <Tooltip>
                  <Tooltip.Trigger>
                    <FeatherIcon icon={"info"} css={styles.tooltipIcon} />
                  </Tooltip.Trigger>
                  <Tooltip.Popper css={styles.tooltipPopper}>
                    {info}
                  </Tooltip.Popper>
                </Tooltip>
              )}
            </span>{" "}
          </div>
          {!loading && (
            <>
              {!isCustom && (
                <div>
                  <span css={styles.lightText}>Avg. Position :</span>{" "}
                  <span css={styles.darkText}>{avgPosition}</span>
                </div>
              )}
            </>
          )}
          <TextSkeleton loading={loading} lines={1} boxWidth={"9rem"} />
        </div>
        <div css={styles.countContainer}>
          {!loading && (
            <>
             {isCustom ? <>
              <div>{isCustom ? count[count.length - 1] : count}</div>
             </> : <Tooltip placement="top-start" interaction={!!totalKeywordCount ? "hover" : "none"}>
                <Tooltip.Trigger css={{display: "flex", flexDirection: "column", alignItems: "flex-end", columnGap: "0.25rem"}}>
                  <div>{isCustom ? count[count.length - 1] : count}</div>
                  {totalKeywordCount !== undefined && <div css={styles.outOf}>{`/ ${totalKeywordCount}`}</div>}
                </Tooltip.Trigger>
                <Tooltip.Popper>
                  <div css={styles.tooltipPopperCount}>
                    <span css={styles.tooltipCount}>{count}</span> keywords are
                    currently ranking on SERP, out of{" "}
                    <span css={styles.tooltipCount}>{totalKeywordCount}</span> keywords
                    configured in this type
                  </div>
                </Tooltip.Popper>
              </Tooltip>}
              {isCustom && (
                <div css={styles.customGrowth(growth > 0, growth == 0)}>
                  {growth === "infinity" ? <img
                src={AssetsImg.ic_infinity_green}
                css={styles.infinityIconPercent}
              /> : `${growth}%`}
                </div>
              )}
              {isCustom && (
                <Tooltip interaction={"hover"} placement="bottom-start">
                  <Tooltip.Trigger>
                    <TrendLineChart
                      data={count}
                      addStyles={styles.chartWrapper}
                      isPositive={count[0] < count[count.length - 1]}
                      isStable={count[0] === count[count.length - 1]}
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Popper css={styles.tooltipPopperGraph}>
                    <AreaChart
                      title={`${title} - No. Of Keywords`}
                      data={count}
                      xAxis={graphData.xAxisCategories}
                      width={"300"}
                      height={"200"}
                      isPositive={count[0] < count[count.length - 1]}
                      isStable={count[0] === count[count.length - 1]}
                    />
                  </Tooltip.Popper>
                </Tooltip>
              )}
            </>
          )}
          <TextSkeleton
            loading={loading}
            lines={1}
            boxWidth={"9rem"}
            height={40}
          />
        </div>
      </div>
    );
  }
};

export default KeyDistributionCard;
