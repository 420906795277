import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: {
    id: "",
    name: "",
    domain: "",
    subDomain: null,
    location: "",
    searchEngine: "",
    device: "",
    frequency: "",
    buckets: [],
    torsoMin: "",
    torsoMax: "",
    isComplete: false,
  },
  keywords: [],
  competitors: [],
  categoryFilterOptions: [],
  typeFilterOptions: [],
  priorityFilterOptions: [],
  locationOptions: {
    options: [
      { label: "India", value: "IN", type: "Country" },
      { label: "United States of America", value: "US", type: "Country" },
      { label: "United Arab Emirates", value: "AE", type: "Country" },
      { label: "South Africa", value: "ZA", type: "Country" },
      { label: "United Kingdom", value: "GB", type: "Country" },
      { label: "Saudi Arabia", value: "SA", type: "Country" },
      { label: "Australia", value: "AU", type: "Country" },
      { label: "Afghanistan", value: "AF", type: "Country" },
      { label: "Albania", value: "AL", type: "Country" },
      { label: "Algeria", value: "DZ", type: "Country" },
    ],
    active: "",
  },
  isDomain: true,
  searchEngineOptions: [],
  frequencyOptions: [],
  deviceOptions: [],
  stepperData: {
    data: [
      {
        name: "Campaign Details",
        isComplete: false,
        component: "campaignDetails",
      },
      { name: "Add Keywords", isComplete: false, component: "addKeywords" },
      {
        name: "Add Competitors",
        isComplete: false,
        component: "addCompetitors",
      },
    ],
    active: 0,
  },
  activeStep: "campaignDetails",
  isEditingKeyword: false,
  singleKeywordData: {
    keywords: "",
    searchvolume: "",
    keywordcategory: "",
    types: "",
    priority: "",
    action: "",
    key: "",
  },
  isEditingCompetitor: false,
  singleCompetitorData: "",
  touchedInputs: {},
  tempName: {}
};

export const campaignDetailSlice = createSlice({
  name: "campaignDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.details = action.payload;
    },
    setLocationOptions: (state, action) => {
      state.locationOptions = action.payload;
    },
    setSearchEngineOptions: (state, action) => {
      state.searchEngineOptions = action.payload;
    },
    setFrequencyOptions: (state, action) => {
      state.frequencyOptions = action.payload;
    },
    setDeviceOptions: (state, action) => {
      state.deviceOptions = action.payload;
    },
    setIsComplete: (state, action) => {
      state.isComplete = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setCompetitors: (state, action) => {
      state.competitors = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
      state.stepperData.active = state.stepperData.data.findIndex(
        (item) => item.component === action.payload
      );
    },
    setFormState: (state, action) => {
      const index = state.stepperData.data.findIndex(
        (item) => item.component === action.payload.component
      );
      state.stepperData.data[index].isComplete = action.payload.isComplete;
    },
    setFilterOptions: (state, action) => {
      state.categoryFilterOptions = action.payload.categories;
      state.typeFilterOptions = action.payload.types;
      state.priorityFilterOptions = action.payload.priority;
    },
    setSingleKeywordData: (state, action) => {
      state.singleKeywordData = action.payload;
    },
    setIsEditingKeyword: (state, action) => {
      state.isEditingKeyword = action.payload;
    },
    setIsEditingCompetitors: (state, action) => {
      state.isEditingCompetitor = action.payload;
    },
    setSingleCompetitorData: (state, action) => {
      state.singleCompetitorData = action.payload;
    },
    resetCampaignDetails: () => initialState,
    resetSingleKeywordData: (state) => {
      state.singleKeywordData = initialState.singleKeywordData;
    },
    setTouchedInputs: (state, action) => {
      state.touched = action.payload
    },
    setTempCampaignName: (state, action) => {
      state.tempName = action.payload
    },
    setDomainType: (state, action) => {
      state.isDomain = action.payload
    }
  },
});

export const {
  setDetails,
  setLocationOptions,
  setSearchEngineOptions,
  setFrequencyOptions,
  setDeviceOptions,
  setIsComplete,
  setKeywords,
  setActiveStep,
  setFormState,
  setFilterOptions,
  setCompetitors,
  resetCampaignDetails,
  setSingleKeywordData,
  setIsEditingKeyword,
  resetSingleKeywordData,
  setIsEditingCompetitors,
  setSingleCompetitorData,
  setTouchedInputs,
  setTempCampaignName,
  setDomainType
} = campaignDetailSlice.actions;

export default campaignDetailSlice.reducer;
