import React from "react";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "react-circular-progressbar/dist/styles.css";
import {
  downloadPDFfile,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import PieChart from "./seoaudit/PieChart";
import HTMLdata from "./seoaudit/HTMLdata";
import DoughnutChart from "./seoaudit/DoughnutChart";
import { Drawer } from "antd";
import { Input } from "antd";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  GET_Server_URL,
  getSEOAuditDetail,
  moduleSEOAudit,
  getSEOAuditModuleList,
  indexLoGoImage_URL,
} from "../utility/Constant";
import { Doughnut } from "react-chartjs-2";
import "chart.piecelabel.js";
import ReactToPrint from 'react-to-print';
import { useNavigate } from "react-router-dom";

function ModuleExpandSEOAuditV3(props) {

  const [categoriesScore2, setCategoriesScore2] = useState([]);
  let globaleCount = 0
  let itemCount = 0

  const [categoryWiseScore, setCategoryWiseScore] = useState([]);
  const [categoriesScoreCopy, setCategoriesScoreCopy] = useState([])
  const [instruction, setIinstruction] = useState("")
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const [sectionListData, setSectionListData] = useState([
    { label: 'All', value: false, auditModuleId: 'All' }
  ])
  const [sectionListCopyData, setSectionListCopyData] = useState([])
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    getSEOAuditAuditDetail(props?.location?.state?.selectedAuditId);
    getSEOAuditModule(props?.location?.state?.selectedAuditId);
  }, []);

  useEffect(() => {
    if (searchText == "") {
      setSectionListData(sectionListCopyData)
    } else {
      let arr = []
      sectionListData.map((i) => {
        if (i.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) {
          arr.push(i)
        }
      })
      setSectionListData(arr)
    }
    // categoriesScore2, setCategoriesScore2
  }, [searchText, categoriesScore2]);

  function getSEOAuditAuditDetail(auditId) {
    APIManager.getAPI(
      getSEOAuditDetail +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId
    ).then((response) => {
      if (response && response.status == 200) {
        setCategoriesScore2(response.data?.auditCategoryDatas)
        setCategoryWiseScore(response.data?.categoryWiseScore)
        setCategoriesScoreCopy(response.data?.auditCategoryDatas)
        setIinstruction(response.data?.auditInstruction?.instruction)
      }
    });
  }

  function getSEOAuditModule(auditId) {
    APIManager.getAPI(getSEOAuditModuleList + auditId).then((response) => {
      if (response && response.status == 200) {
        let arr = []
        response?.data?.map((i) => {
          arr.push({ label: i.auditModuleName, value: false, auditModuleId: i.auditModuleId })
        })
        setSectionListData([...sectionListData, ...arr])
        setSectionListCopyData([...sectionListData, ...arr])
      }
    });
  }

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    if (document.getElementById('pdf-doughnut-txt00')) {
      document.getElementById('pdf-doughnut-txt00').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt01')) {
      document.getElementById('pdf-doughnut-txt01').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt02')) {
      document.getElementById('pdf-doughnut-txt02').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt03')) {
      document.getElementById('pdf-doughnut-txt03').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt10')) {
      document.getElementById('pdf-doughnut-txt10').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt11')) {
      document.getElementById('pdf-doughnut-txt11').classList.remove("pdf-doughnut-text");
    }
    if (document.getElementById('exportBtn') && document.getElementById('backArw')) {
      document.getElementById('exportBtn').classList.remove("d-none");
      document.getElementById('backArw').classList.remove("d-none");
    }
  };

  const [activePdfClass, activePDFFullDoc] = useState(1);

  const Checkbox = (props) => {
    return (
      <>
        <div class="custom-control custom-checkbox" onClick={() => {
          let arr = []
          if (props.auditModuleId == "All") {
            sectionListData.map((i) => {
              i.value = !props.value
              arr.push(i)
            })
            setSectionListData(arr)
          } else {
            sectionListData.map((i) => {
              if (i.auditModuleId == props.auditModuleId) {
                i.value = !props.value
              }
              arr.push(i)
            })

            let array = sectionListData.filter(x => x.label !== "All")
            sectionListData[0].value = array.every(x => x.value);
            setSectionListData(arr)
          }
        }}>
          <input
            class="form-check-input"
            type="checkbox"
            checked={props.value}
          />
          <label>{props.label}</label>
        </div>
      </>
    )
  }

  const navigate = useNavigate();

  function topFunction() {
    var e = document.getElementById("divToExport");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
  const componentRef = React.useRef();

  function onExport() {
    if (document.getElementById('pdf-doughnut-txt00')) {
      document.getElementById('pdf-doughnut-txt00').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt01')) {
      document.getElementById('pdf-doughnut-txt01').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt02')) {
      document.getElementById('pdf-doughnut-txt02').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt03')) {
      document.getElementById('pdf-doughnut-txt03').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt10')) {
      document.getElementById('pdf-doughnut-txt10').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('pdf-doughnut-txt11')) {
      document.getElementById('pdf-doughnut-txt11').classList.add("pdf-doughnut-text");
    }
    if (document.getElementById('exportBtn') && document.getElementById('backArw')) {
      document.getElementById('exportBtn').classList.add("d-none");
      document.getElementById('backArw').classList.add("d-none");
    }
  }

  return (
    <>
      <div id="divToExport" className="newseo-pdf">
        <>
          <PDFExport
            paperSize="A1"
            margin="2cm"
            fileName={props?.location?.state?.selectedAuditName}
            ref={pdfExportComponent}
            forcePageBreak=".page-break"
          >
            <div style={{ padding: 20 }}
              // ref={image}
              ref={componentRef}
            >
              <div className="row index-content">
                {isLoading ? <></> :
                  <div className="col-md-12 d-flex mb-3 p-0">
                    <i
                      id="backArw"
                      class="fa fa-chevron-circle-left"
                      style={{ fontSize: 20 + "px", color: "#2196f3" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    ></i>
                  </div>
                }
                <div className="col-md-12 d-flex mt-3 align-items-center p-0 module-title seo-pdf-title">
                  <h2 className="text-blue d-flex me-3 mb-0 ">
                    AUDIT VERSION:
                  </h2>
                  <p id="audit-version-lbl" className="text-capitalize  me-3 mb-0 p-0" style={{ fontSize: 24 }}>
                    {props?.location?.state?.selectedAuditName}
                  </p>
                </div>
                {isLoading ? <></>
                  : <div
                    id="exportBtn"
                    className="col-sm-12 d-flex justify-content-between align-items-center my-4 py-2 ps-"
                  >
                    <div
                      className="seo-title mt-0 d-flex align-items-center"
                    >
                      {/* Sorted By :<span className="fw-600 ms-2">SEO Categories</span> */}
                    </div>
                    <div
                      className="seo-export-btn seo-pdf"
                      onClick={() => {
                        showDrawer();
                        onExport();
                      }}
                    >
                      <button
                        class="btn btn-primary dropdown-item"
                      >Export PDF</button>
                    </div>
                  </div>}

                <div className="text-center">
                  <br />
                  {/* <img
                    src={indexLoGoImage_URL}
                    width="300px"
                    crossorigin="anonymous"
                  /> */}
                  <div className="aside-title logo-text">InfiGrowth</div>
                  <br />
                </div>
                <div className="text-center seo-index">
                  <label >Index</label>
                </div>
              </div>
              <div className="row" >
                <div>
                  {categoriesScore2 &&
                    categoriesScore2.length &&
                    categoriesScore2.map((item, index) => {
                      return (<div className="seo-main-sub-content">
                        <div className="seo-title on-page-title mt-0 d-flex align-items-center">
                          <h2 className="mt-3">{item?.category}</h2>
                        </div>
                        {item?.categoryWiseModuleScores.map((i, idx) => {
                          return (<div className="index-main-point mb-4">
                            <span >{idx + 1}. {i?.auditModuleName}</span>
                            {i?.auditResponseByCategories.map((ii, idxx) => {
                              globaleCount = globaleCount + 1
                              const a = globaleCount
                              return (
                                <div className="index-sub-point">
                                  <a
                                    onClick={() => {
                                      let idd = "audit_" + a.toString();
                                      var e = document.getElementById(idd);
                                      e.scrollIntoView({
                                        behavior: "smooth",
                                        inline: "center",
                                      });
                                    }}
                                  >{idx + 1}.{idxx + 1}- {ii?.pointTitle}</a>
                                </div>)
                            })}
                          </div>)
                        })}
                      </div>
                      )
                    })}
                </div>
                <div
                  className="bg-transparent divider"
                  dangerouslySetInnerHTML={{
                    __html: instruction,
                  }}
                ></div>
                <div className="row seo-content-start">
                  <div className="col-sm-12">
                    <div className="seo-accordian-box mt-4 pt-2">
                      <div className="mt-2">
                        {categoriesScore2 &&
                          categoriesScore2.length &&
                          categoriesScore2.map((item, index) => {
                            return (
                              <div className="">
                                <div className="accordian-title seo-on-page divider">
                                  <div className="left ">
                                    <span>{item?.category}</span>
                                  </div>
                                  <div className=" categories-score">
                                    <div className="text-center my-3">
                                      <h2> {Math.round(categoryWiseScore[index]?.auditScore)}</h2>

                                    </div>
                                    <span>Your Score</span>
                                    {/* <img src="/images/arrow-down.png" alt="#" /> */}
                                  </div>
                                </div>

                                {item.categoryWiseModuleScores.map((i, inx) => {
                                  return (
                                    <>
                                      {/* {Math.round(i.auditScore) == 0 ? <></> : <> */}
                                      <div className={inx === 0 ? "accordian-title seo-content" : "accordian-title seo-content divider"}>
                                        <div className="left">
                                          <span className="on-page-content">{inx + 1}. {i.auditModuleName}</span>
                                        </div>
                                        <div className="right d-flex align-items-center">
                                          {/* <span>Your Score : {Math.round(i.auditScore)}</span> */}
                                        </div>
                                      </div>
                                      <div className="accordian-content">
                                        <div className="main-accordian-box">
                                          <div className="box-wrappp" style={{
                                            display: "flex", justifyContent: "space-between",
                                            alignItems: "center"
                                          }}>
                                            <div className="chart-boxx"
                                            //  style={{ width: 85 + "%" }}
                                            >
                                              <div className="d-flexxx"
                                              // style={{display : "flex"}}
                                              >
                                                <table>
                                                  <tr>
                                                    <td
                                                      style={{ width: 20 + "%" }}
                                                    >
                                                      {/* <div className="row"> */}
                                                      {/* <div className="w-100 d-flex justify-content-between align-items-top"> */}
                                                      {i.compliantPoints > 0 ||
                                                        i.nonCompliantPoints > 0 ? (
                                                        <div
                                                          // style={{ width: 80 + "%" }}
                                                          style={{ width: 120 + "px" }}
                                                          className="chart-box"
                                                        >
                                                          <div className="custom-chart-size" style={{
                                                            // width: 80 + "%",
                                                            position: 'relative', marginLeft: "auto", marginRight: "auto"
                                                          }}>
                                                            <DoughnutChart
                                                              labels={[
                                                                "Compliant",
                                                                "Non compliant",
                                                              ]}
                                                              data={[
                                                                i.nonCompliantPoints,
                                                                i.compliantPoints,
                                                              ]} />
                                                            <div id={"pdf-doughnut-txt" + index + inx} style={{ position: 'absolute', width: '100%', top: '34%', left: 0, textAlign: 'center', fontWeight: 700 }}>
                                                              {i.nonCompliantPoints + i.compliantPoints}
                                                            </div>
                                                          </div>
                                                          <div className="chart-title d-flex justify-content-center">
                                                            <div className="text-center chart-name"
                                                              style={{ fontSize: 15 + "px" }}
                                                            >
                                                              Points Checked
                                                            </div>
                                                          </div>
                                                        </div>

                                                      ) : (
                                                        <></>
                                                      )}
                                                    </td>
                                                    {i.highImpact > 0 ||
                                                      i.mediumImpact > 0 ||
                                                      i.lowImpact > 0 ? (
                                                      <PieChart
                                                        title={"Impact"}
                                                        labels={["High", "Medium", "Low"]}
                                                        data={[
                                                          i.highImpact,
                                                          i.mediumImpact,
                                                          i.lowImpact,
                                                        ]}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {i.highPriority > 0 ||
                                                      i.mediumPriority > 0 ||
                                                      i.lowPriority > 0 ? (
                                                      <PieChart
                                                        title={"Priority"}
                                                        labels={["High", "Medium", "Low"]}
                                                        data={[
                                                          i.highPriority,
                                                          i.mediumPriority,
                                                          i.lowPriority,
                                                        ]}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {i.onHold > 0 ||
                                                      i.inprogress > 0 ||
                                                      i.implemented > 0 ? (
                                                      <PieChart
                                                        title={"Status"}
                                                        labels={["Implemented", "In process", "On hold"]}
                                                        data={[
                                                          i.implemented,
                                                          i.inprogress,
                                                          i.onHold,
                                                        ]}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}

                                                  </tr>
                                                </table>
                                              </div>
                                            </div>
                                            <div class="chart-textscore"
                                            // style={{ width: 15 + "%" }}
                                            >
                                              <div class="accord-content-textt"
                                                style={{ textAlign: "center" }}
                                              >
                                                <span className="digit-span">
                                                  {Math.round(i.auditScore)}
                                                </span>
                                                <span class="text-span"
                                                  style={{ fontSize: 16 + "px" }}
                                                >Your Score
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <HTMLdata title={"Introduction :"} data={i.description} />
                                        </div>
                                        {/**************** Inner Accordian ***********************************/}


                                        {i.auditResponseByCategories &&
                                          i.auditResponseByCategories.length &&
                                          i.auditResponseByCategories.map(
                                            (innerItem, index) => {
                                              itemCount = itemCount + 1;
                                              return (
                                                <>
                                                  <div className={"mt-3 inside-accordian expand-active seo-submenu-content"}>
                                                    <div id={"audit_" + itemCount} >
                                                      {/* {inx + 1}. */}
                                                      <label className="subpoint" htmlFor="">  {inx + 1}.{index + 1}{" - "} {innerItem.pointTitle}</label></div>
                                                    {innerItem.auditResponseValue == 0 ? (
                                                      <div >

                                                        {/* <div class="w-100 d-flex align-items-top seo-status">
                                                        <div className="left ">
                                                          <span>Audit Point:</span>
                                                        </div>
                                                        <div className="ms-5">
                                                          <span>{}</span>
                                                        </div>
                                                      </div> */}
                                                        <div class="w-100 d-flex align-items-top seo-status">
                                                          <div className="left">
                                                            <span>Status:</span>
                                                          </div>
                                                          <div
                                                            // className="seo-status-color-high ms-5"
                                                            className={
                                                              innerItem.status == 4
                                                                ? "seo-status-color-high ms-5 ps-2"
                                                                : innerItem.status == 3
                                                                  ? "seo-status-color-medium ms-5 ps-2"
                                                                  : innerItem.status == 0
                                                                    ? "seo-status-color-low ms-5"
                                                                    : "seo-status-color-default ms-5 ps-5"
                                                            }
                                                          >
                                                            <span>{innerItem.status == 1
                                                              ? "Completed"
                                                              : innerItem.status == 3
                                                                ? "WIP"
                                                                : innerItem.status == 4
                                                                  ? "On Hold"
                                                                  : innerItem.status == 5
                                                                    ? "Pending"
                                                                    : innerItem.status == 0
                                                                      ? "-" //Active
                                                                      : "-"}</span>
                                                          </div>
                                                        </div>
                                                        <div class="w-100 d-flex align-items-top seo-status">
                                                          <div className="left ">
                                                            <span>Impact:</span>
                                                          </div>
                                                          <div className={innerItem.impact == 0
                                                            ? "seo-status-color-high ms-5"
                                                            : innerItem.impact == 1
                                                              ? "seo-status-color-medium ms-5"
                                                              : innerItem.impact == 2
                                                                ? "seo-status-color-low ms-5"
                                                                : "seo-status-color-default ms-5"} >
                                                            <span>{innerItem.impact == 0
                                                              ? "Low"
                                                              : innerItem.impact == 1
                                                                ? "Medium"
                                                                : innerItem.impact == 2
                                                                  ? "High"
                                                                  : "-"}</span>
                                                          </div>
                                                        </div>
                                                        <div class="w-100 d-flex align-items-top seo-status">
                                                          <div className="left ">
                                                            <span>Priority:</span>
                                                          </div>
                                                          <div className={innerItem.priority == 0
                                                            ? "seo-status-color-high ms-5"
                                                            : innerItem.priority == 1
                                                              ? "seo-status-color-medium ms-5"
                                                              : innerItem.priority == 2
                                                                ? "seo-status-color-low ms-5"
                                                                : "seo-status-color-default ms-5"} >
                                                            <span>{innerItem.priority == 0
                                                              ? "Low"
                                                              : innerItem.priority == 1
                                                                ? "Medium"
                                                                : innerItem.priority == 2
                                                                  ? "High"
                                                                  : "-"}</span>
                                                          </div>
                                                        </div>
                                                        <div class="w-100 d-flex align-items-top seo-status">
                                                          <div className="left ">
                                                            <span>Responsible:</span>
                                                          </div>
                                                          <div className="ms-4 ps-3" >
                                                            <span>{innerItem.responsible
                                                              ? innerItem.responsible
                                                              : "-"}</span>
                                                          </div>
                                                        </div>

                                                        <div className="accordion-body  border-top-0 rounded accordian-identity">
                                                          {innerItem.comment ? (
                                                            <HTMLdata className="observation" title={"Observation :"} data={innerItem.comment} />
                                                          ) : (
                                                            <></>
                                                          )}
                                                          {innerItem.suggestion ? (
                                                            <HTMLdata className="recommendation" title={"Recommendation :"} data={innerItem.suggestion} />
                                                          ) : (
                                                            <></>
                                                          )}

                                                          <div className="row para-box">
                                                            {innerItem.supportingDocs.length ? (
                                                              <div className="col-md-12 pdf-box mt-4">
                                                                <div className="pdf-title">
                                                                  {/* {innerItem.supportingDocs.length}  */}
                                                                  Attachments
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                  <div className="row w-100">
                                                                    {innerItem.supportingDocs.length && innerItem.supportingDocs.map(sd_Item => {
                                                                      return (
                                                                        <div className="col-md-3 flex-pdf p-3 d-flex align-items-center mr-3">
                                                                          <a
                                                                            href={GET_Server_URL + sd_Item.documents}
                                                                            target="_blank" download="" className=" d-flex align-items-center" >
                                                                            <div className="icon" >
                                                                              {sd_Item?.documents?.includes(".jpg") ||
                                                                                sd_Item?.documents?.includes(".png") ||
                                                                                sd_Item?.documents?.includes(".jpeg") ? (
                                                                                <div>
                                                                                  <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-image"
                                                                                  >
                                                                                    <rect
                                                                                      x="3"
                                                                                      y="3"
                                                                                      width="18"
                                                                                      height="18"
                                                                                      rx="2"
                                                                                      ry="2"
                                                                                    ></rect>
                                                                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                                                                    <polyline points="21 15 16 10 5 21"></polyline>
                                                                                  </svg>
                                                                                  {sd_Item?.documents.substr(27)}
                                                                                </div>
                                                                              ) : (
                                                                                <></>
                                                                              )}
                                                                              {sd_Item?.documents?.includes(".pdf") ? (
                                                                                <div className="d-flex align-items-center attach-img attach-document">
                                                                                  <img src="/images/pdf-img.png" alt="#" />
                                                                                  <span className="ms-3">{sd_Item?.documents.substr(27)}</span>
                                                                                </div>
                                                                              ) : (
                                                                                <></>
                                                                              )}
                                                                              {sd_Item?.documents?.includes(".txt") ? (
                                                                                <div>
                                                                                  <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="#212121"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-file-text"
                                                                                  >
                                                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                                                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                                                  </svg>
                                                                                  {sd_Item?.documents.substr(27)}
                                                                                </div>
                                                                              ) : (
                                                                                <></>
                                                                              )}
                                                                              {sd_Item?.documents?.includes(".xlsx") ? (
                                                                                <div className="d-flex align-items-center attach-document">
                                                                                  <i
                                                                                    class="fa fa-file-excel me-3"
                                                                                    aria-hidden="true"
                                                                                  ></i>
                                                                                  <span>{sd_Item?.documents.substr(27)}</span>
                                                                                </div>

                                                                              ) : (
                                                                                <></>
                                                                              )}
                                                                            </div>
                                                                          </a>
                                                                          {/* <div className="text ms-3">
                                                                        <h5>
                                                                          <a href={sd_Item.documents} download >{sd_Item.documents}</a>
                                                                        </h5>
                                                                        <p className="mb-0">
                                                                          2.4 MB
                                                                        </p>
                                                                      </div> */}
                                                                        </div>
                                                                      )
                                                                    })} </div>
                                                                </div>
                                                              </div>
                                                            ) : <></>}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                      </div>
                                      {/* </>} */}
                                    </>
                                  )
                                })}
                              </div>
                            );
                          })}
                      </div>

                      <Drawer
                        title="Export Report"
                        className="export-report-modal seo-modal"
                        placement="right"
                        onClose={onClose}
                        open={open}
                      >
                        <div className="portlet-body">
                          <div className="radio-box">
                            <div className={activePdfClass == 1 ? 'custom-control custom-radio active' : 'custom-control custom-radio'} onClick={() => {
                              activePDFFullDoc(1);
                            }}>
                              <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" checked={activePdfClass == 1 ? true : false} />
                              <label class="custom-control-label" for="customRadio1">Full Report</label>
                              <p>Export whole report with all sections</p>
                            </div>
                            <div className={activePdfClass == 2 ? 'custom-control custom-radio mt-4 active' : 'custom-control custom-radio mt-4'} onClick={() => {
                              activePDFFullDoc(2);
                            }}>
                              <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" checked={activePdfClass == 2 ? true : false} />
                              <label class="custom-control-label" for="customRadio2">Selected Sections</label>
                              <p>Export report for all the selected sections</p>
                            </div>
                          </div>
                          {activePdfClass == 2 ? (<div className="div-collapse">
                            <div className="row">
                              <div class="col-md-10 col-sm-12">
                                <div class="input-box mt-5 w-100">
                                  <Input
                                    allowClear
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                    onChange={(nameSearch) => {
                                      setSearchText(nameSearch.target.value)
                                    }}
                                    id="input-s"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="selection-box">
                              {sectionListData.map((i) => {
                                return (
                                  <Checkbox onChange={(e) => {
                                  }} value={i.value} label={i.label} auditModuleId={i.auditModuleId} />
                                )
                              })}
                            </div>
                          </div>) : <></>}
                          <ReactToPrint
                            pageStyle="@page { margin: 15mm; } }"
                            trigger={() => <button className="btn btn-primary left">Print</button>}
                            content={() => componentRef.current}
                          />
                          <button className="btn btn-primary seo-modal-btn" onClick={async () => {
                            setLoading(true)
                            if (activePdfClass == 2) {
                              var selectedSection = []
                              sectionListData.map((i) => {
                                if (i.value) {
                                  selectedSection.push(i)
                                }
                              })
                              var categoriesScore21 = []
                              let obj = {}
                              categoriesScoreCopy.map((i) => {
                                obj = {
                                  category: i.category,
                                  categoryId: i.categoryId,
                                  categoryWiseModuleScores: []
                                }
                                var categoriesScore22 = []
                                let arr = []
                                selectedSection.map((selectedItem) => {
                                  i.categoryWiseModuleScores.map((ii) => {
                                    if (selectedItem.auditModuleId == ii?.auditModuleId) {
                                      categoriesScore22.push(ii)
                                      arr.push(ii)
                                    }
                                  })
                                })
                                obj.categoryWiseModuleScores = arr
                                if (arr.length > 0) {
                                  categoriesScore21.push(obj)
                                }
                              })
                              setCategoriesScore2(categoriesScore21)
                            } else {
                              setCategoriesScore2(categoriesScoreCopy)
                            }
                            setTimeout
                              (async () => {
                                const promise = await downloadPDFfile(componentRef, props?.location?.state?.selectedAuditName + ".pdf")//props?.location?.state?.selectedAuditName
                                try {
                                  if (promise) {
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                } catch (e) {
                                  setLoading(false);
                                }
                                setOpen(false);
                              }, 2000);
                          }}>Export</button>
                        </div>
                      </Drawer>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
          {isLoading ? (
            <></>
          ) : (
            <div onClick={topFunction} className="scroll-topbutton">
              <i
                id="myBtn"
                title="Go to top"
                class="fa fa-angle-up"
                aria-hidden="true"
              ></i>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default ModuleExpandSEOAuditV3;
