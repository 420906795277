/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import Input from "@semcore/ui/input";

const RangeInput = ({
  onStartChange,
  onEndChange,
  placeholderStart,
  placeholderEnd,
  startValue,
  endValue,
  type,
  inputContainerStyle,
  startId,
  endId,
  onBlur,
  onStartBlur,
  onEndBlur,
}) => {
  return (
    <>
      <div css={styles.inputGroup}>
        <Input
          css={[styles.input, inputContainerStyle && inputContainerStyle]}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (onBlur) {
                onBlur();
              }
            }
          }}
        >
          <Input.Value
            defaultValue={startValue}
            placeholder={placeholderStart}
            type={type === "text" ? "" : type}
            id={startId}
            onChange={(value) => {
              onStartChange(value);
            }}
            onBlur={onStartBlur}
          />
        </Input>
        <div>-</div>
        <Input
          css={[styles.input, inputContainerStyle && inputContainerStyle]}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (onBlur) {
                onBlur();
              }
            }
          }}
        >
          <Input.Value
            defaultValue={endValue}
            placeholder={placeholderEnd}
            type={type === "text" ? "" : type}
            id={endId}
            onChange={(value) => {
              onEndChange(value);
            }}
            onBlur={onEndBlur}
          />
        </Input>
      </div>
    </>
  );
};

export default RangeInput;
