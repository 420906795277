import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import APIManager from '../../../services/APIManager';
import {
    Addcampaignkeyword,
    getCategoryByProject,
    updatecampaigKeyword,
} from '../../../utility/Constant';

function CampaignaddKeyword(props) {
    const [categoryType, setCategoryType] = useState([]);
    const [fieldValue, SetfieldValue] = useState("");
    const [SearchVolume, setSearchVolume] = useState(false);
    const [CategorySelected, setCategorySelected] = useState("");
    const [keywordtypeselected, setkeywordtypeselected] = useState("");
    const [errors, setErrors] = useState("");
    const [save, setsave] = useState(false);
    const [selectedprioriry, setselectedprioriry] = useState("1");
    const [selectedtype, setselectedtype] = useState("0");
    const [isEdit, setisEdit] = useState(false);
    const [checked, setChecked] = useState(true);
    const notifyAdd = () => toast.success("Successfully Added!");
  
    const keywordtype = [
        {
            value: "0",
            lable: "Brand",
        },
        {
            value: "1",
            lable: "Non Brand ",
        },
        // {
        //     value: "2",
        //     lable: "All",
        // },
    ]
    useEffect(() => {
        getCategoryData();
        if (props?.items) {
            setisEdit(true);
            SetfieldValue(props?.items?.keyword);
            setSearchVolume(props?.items?.searchVolume);
            setselectedtype(props?.items?.types ? props?.items?.types : selectedtype);
            setChecked(props?.items?.priority == 0 ? false : true);
        }
    }, []);

    function formValidation() {
        let error = { ...errors };
        fieldValue.trim() == ""
            ? (error["fieldValue"] = "Keyword is required!")
            : delete error["fieldValue"];
        !SearchVolume
            ? (error["SearchVolume"] = "Search Volume is required!")
            : delete error["SearchVolume"];
        setErrors(error);
        let err = Object.keys(error);
        return err?.length ? false : true;
    }

    function addkeyword() {
        APIManager.postAPI(
            Addcampaignkeyword,
            {
                Keyword: fieldValue.trim(),
                KeywordCategoryId: CategorySelected,
                KeywordTypeId: keywordtypeselected,
                RankingCampaignConfigurationId: props?.configId,
                Priority: selectedprioriry,
                SearchVolume: SearchVolume,
                Types: selectedtype
            },
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                clearForm();
                notifyAdd();
            }
        });
    }

    function updatekeyword() {
        APIManager.putAPI(
            updatecampaigKeyword,
            {
                id: props?.items?.id,
                Keyword: fieldValue.trim(),
                KeywordCategoryId: CategorySelected,
                KeywordTypeId: keywordtypeselected,
                RankingCampaignConfigurationId: props?.configId,
                Priority: selectedprioriry,
                SearchVolume: SearchVolume,
                Types: selectedtype
            },
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                clearForm();
            }
        });
    }

    function getCategoryData() {
        APIManager.getAPI(
            getCategoryByProject + localStorage.getItem("projectGId")
        ).then((response) => {
            if (response && response.status == 200) {
                setCategoryType(response.data);
                setCategorySelected(props?.items?.keywordCategoryId ? props?.items?.keywordCategoryId : response.data[0]?.id);
            }
        });
    }
    
    function clearForm() {
        SetfieldValue("");
        setSearchVolume("");
        setCategorySelected("");
        props.update();
    }

    return (
        <>
            <div className="common-form-fields slideInUp">
                <div className="portlet-body ranking-keywordadd-form p-0 px-2">
                    <div class="col-md-12">
                        <label className="form-label">Keyword</label>
                        <label class="field-required"> * </label>
                        <input
                            className="form-control w-100"
                            type="text"
                            name=""
                            placeholder="Enter Keyword"
                            value={fieldValue}
                            onChange={(text) => {
                                SetfieldValue(text.target.value);
                            }}
                        ></input>
                        {save && errors?.fieldValue && (
                            <>
                                <div className="field-required">
                                    <span> {errors?.fieldValue}</span>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-md-12 mt-4 ">
                        <label className="form-label">Category</label>
                        <label class="field-required"> * </label>
                        <br />
                        <select
                            className="w-100 form-select"
                            style={{ padding: 8 + "px" }}
                            required
                            value={CategorySelected}
                            onChange={(e) => {
                                var c = e.target.value;
                                setCategorySelected(c);
                            }}
                        >
                            {categoryType?.map((item) => {
                                return <option value={item.id}>{item.categoryType}</option>;
                            })}
                        </select>
                    </div>
                    <div className="col-md-12 mt-4 py-2">
                        <div className="d-flex align-items-center">
                            <label className="form-label mb-0">Priority</label>
                            <input
                                className="ms-3"
                                type="checkbox"
                                checked={checked}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setselectedprioriry("1");
                                        setChecked(true);
                                    } else {
                                        setselectedprioriry("0");
                                        setChecked(false);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mt-4 ">
                        <label className="form-label">Search Volume</label>
                        <label class="field-required"> * </label>
                        <input
                            className="form-control w-100"
                            type="number"
                            name=""
                            min="0"
                            placeholder="Enter Search Volume"
                            value={SearchVolume}
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    setSearchVolume(e.target.value);
                                }
                            }}
                        ></input>
                        {save && errors?.SearchVolume && (
                            <>
                                <div className="field-required">
                                    <span> {errors?.SearchVolume}</span>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-md-12 mt-4 ">
                        <label className="form-label">Type</label>
                        <label class="field-required"> * </label>
                        <br />
                        <select
                            className="w-100 form-select"
                            style={{ padding: 8 + "px" }}
                            required
                            value={selectedtype}
                            onChange={(e) => {
                                var c = e.target.value;
                                setselectedtype(c);
                            }}
                        >
                            {keywordtype?.map((item) => {
                                return <option value={item.value}>{item.lable}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="portlet-footer portlet-footer-bordered mt-4 pb-0">
                    <div className="col-md-12 d-flex justify-content-end px-1">
                        <button
                            onClick={() => {
                                setsave(true);
                                if (formValidation()) {
                                    isEdit ? updatekeyword() : addkeyword();
                                }
                            }}
                            className="btn btn-primary me-3"
                        >
                            {isEdit ? "Update" : "Save"}
                        </button>
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                props.update();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignaddKeyword