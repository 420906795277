export const Colors = {
  primary: {
    25: "#E2F1FC",
    50: "#EAF6FF",
    75: "#EAF6FF",
    100: "#B9DCFA",
    200: "#8CC7F7",
    300: "#5EB1F3",
    400: "#39A0F1",
    500: "#0691EF",
    600: "#0483E1",
    700: "#0071CE",
    800: "#0061BC",
    900: "#00439D",
  },
  secondary: {
    25: "#F5F5F5",
    50: "#FFF9E5",
    100: "#FFEEBD",
    200: "#FFE495",
    300: "#FFDB6E",
    400: "#FFD156",
    500: "#FFCA4E",
    600: "#FFBC4A",
    700: "#FFAA46",
    800: "#FF9B44",
    900: "#FF7F40",
  },
  neutral: {
    50: "#E2E8F0",
    100:"#EEEEEE",
    200: "#E0E0E0",
    300: "#BDBDBD",
    400: "#A3A3A3",
    500: "#9E9E9E",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  semantic: {
    50: "#E4FFED",
    100: "#BFEEBF",
    200: "#91E395",
    300: "#59D866",
    400: "#00CE3F",
    500: "#43A047",
    600: "#29792D",
    700: "#9173E6"
  },
  warning: {
    50: "#FEFCF7",
    100: "#FFEEBD",
    200: "#FFE495",
    300: "#FFDB6E",
    400: "#FFAA46",
    500: "#FF8A00",
  },
  error: {
    50: "#FFEBEF",
    100: "#FFE6D9",
    800: "#F64F53",
    900: "#FC3D39",
  },
  success: {
    50: "#E6FAF8",
    200: "#0BA49B",
    300: "#00AB83"
  },
  info: "#0071CE",
  plainWhite: "#FFFFFF",
  black: "#000000",
  cardShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.16)",
  cardShadow2: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
  cardShadow3: "0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  cardShadow4: "0px 1px 12px 0px #191B2326",
  pillShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  background: "#F2F1F1",
  columnHeaderBackground: "#F6FBFF",
  textLink: "#006DCA"
};