/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import LabeledInput from "../../../shared/Input";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import InputNumber from '@semcore/ui/input-number';

const TimeInput = ({ disabled, hours, mins, onHoursChange, onMinsChange, maxState, minState }) => {
    const [time, setTime] = useState({
        hours: "15",
        minutes: "03"
    })

    return (
        <div css={styles.inputContainer}>
            <div>
                <InputNumber size="l" wMax={"3.7rem"} state={maxState} autoFocus={false}>
                    <InputNumber.Value
                        disabled={disabled}
                        // max={100}
                        value={hours}
                        onChange={(e) => { onHoursChange(e) }}
                        placeholder={"- -"}
                        min={0}
                        step={1}
                    />
                </InputNumber>
                <div css={{ ...textStyles.b3 }}>Hrs</div>
            </div>
            <div css={styles.inputSeperator}>:</div>
            <div>
                <InputNumber size="l" wMax={"3.7rem"} state={minState}>
                    <InputNumber.Value
                        disabled={disabled}
                        // max={100}
                        value={mins}
                        onChange={(e) => { onMinsChange(e) }}
                        placeholder={"- -"}
                        min={0}
                        // max={59}
                        step={1}
                    />
                </InputNumber>
                <div css={{ ...textStyles.b3 }}>Min</div>
            </div>
        </div>
    )
}

export default TimeInput;