import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  display: "flex",
});

export const labelContainer = css({});

export const label = css({
  display: "flex",
  alignItem: "center",
  columnGap: "0.37rem",
  width: "13.75rem",
});

export const labelText = css({
  ...textStyles.b1,
});

export const redText = css({
  color: Colors.error[800]
})

export const info = css({
  ...textStyles.b1,
  color: Colors.neutral[300],
});

export const tootipIcon = css({
  height: "1rem",
  width: "1rem",
  strokeWidth: 2,
});

export const tooltip = css({
  minWidth: "44.4rem"
});

export const tooltipImage = css({
  width: "100%",
  height: "auto"
})

export const rangeWrapper = {
  display: "flex",
  alignItems: "center",
  columnGap: "1.5rem",
  rowGap: "1.5rem",
  flexWrap: "wrap"
};

export const rangeContainer = css({
  display: "flex",
  flexDirection: "column",
  rowGap: "0.62rem",
});

export const number = css({
  ...textStyles.b3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: "0.38rem",
});

export const close = css({
  strokeWidth: 3,
  height: "0.75rem",
  width: "0.75rem",
  cursor: "pointer",
});

export const add = css({
  height: "1.5rem",
  width: "1.5rem",
  cursor: "pointer"
});

export const noteContainer = css({

})

export const note = css({
  ...textStyles.h3,
  color: Colors.black,
  marginBottom: "0.63rem"
})

export const noteTitle = css({
  ...textStyles.h3,
  color: Colors.error[800],
  marginBottom: "0.63rem"
})

export const addContainer = css({
  display: "flex",
  alignItems : "center",
  marginLeft: "0.62rem"
})