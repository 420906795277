import { css } from '@emotion/react'
import { Colors } from '../../styles/colors';
import { textStyles } from '../../styles/font';

export const wrapper = (isCollapsed, isLocked) => css({
    // paddingLeft: isCollapsed ? "4.937rem !important" : "22.5rem !important",
    paddingLeft: !isLocked ? "4.937rem" : isCollapsed ? "4.937rem" : "22.5rem",
    "@media (max-width: 1024.98px)": {
        paddingLeft: 0,
    },
})

// Common

export const searchButton = () => css({
    ...textStyles.s2,
    display: "flex",
    maxHeight: "3rem",
    padding: "1rem",
    width: "100%",
    maxWidth: "9.6rem"
})

export const ticketButtons = (custom) => css({
    ...textStyles.s2Medium,
    ...custom,
    display: "flex",
    width: "100%",
    alignItems: "center",
    "svg": {
        width: "1.5rem !important",
        height: "1.5rem !important",
        marginRight: "3px"
    }
})

export const secondaryButton = css({
    "button": {
        ...textStyles.s2Medium,
        marginRight: "1rem",
        color: `${Colors.primary[500]} !important`,
        border: `1px solid ${Colors.primary[500]} !important`,
        backgroundColor: `${Colors.plainWhite} !important`,
        ":hover": {
            backgroundColor: `${Colors.primary[50]} !important`
        }
    }
})

export const createButton = css({
    "button": {
        ...textStyles.s2Medium,
        // marginRight: "1rem",
        // color: `${Colors.primary[500]} !important`,
        // border: `1px solid ${Colors.primary[500]} !important`,
        // backgroundColor: `${Colors.plainWhite} !important`
    }
})

// BreadCrumbs Styles
export const breadCrumbsContainer = (isCollapsed, isLocked) => css({
    ...textStyles.H1125SemiBold,
    padding: "0.5rem",
    paddingLeft: !isLocked ? "0" : isCollapsed ? "0" : "18rem",
    "@media (max-width: 1024.98px)": {
        paddingLeft: 0,
    },
})

export const breadCrumbs = () => css({
    display: "flex",
    alignItems: "center"
})
export const breadCrumb = () => css({
    color: Colors.black,
    cursor: "pointer"
})

// Actions Container
export const ticketActionsContainer = () => css({
    backgroundColor: Colors.plainWhite,
    borderBottom: `1px solid ${Colors.neutral[50]}`,
    padding: "1rem",
    paddingTop: "6.28571rem",
    display: "flex",
    justifyContent: 'space-between'
})

export const searchBox = (isFocused) => css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${isFocused ? Colors.primary[500] : "#424242"}`,
    borderRadius: "6px",
    padding: "0 0.75rem",
    marginRight: "1rem"
})

export const searchInput = () => css({
    border: "0px !important",
    width: "100%",
    // padding: "0.5rem",
})


// Drawer
export const customDrawerStyles = () => css({
    ".ant-drawer-title": {
        ...textStyles.h6
    }
})