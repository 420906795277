/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import CustomTimeButton from "../timeButton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const TicketStatusCard = ({ ticketStatus = "awaitingApproval", lastUpdated }) => {

    const status = {
        awaitingApproval: {
            title: "Awaiting Approval",
            subText: "Submitted on",
            theme: "primary",
            icon: true
        },
        reassigned: {
            title: "Ticket Reassigned",
            subText: "Submitted on",
            theme: "error"
        },
        closed: {
            title: "Ticket Closed",
            subText: "Closed on",
            theme: "success"
        },
    }

    const assigneeStatus = {

    }



    return (
        <div css={styles.statusContainer(status[ticketStatus].theme)}>
            <div>
                <div>{status[ticketStatus].title}</div>
                <div css={{ ...textStyles.b3, color: Colors.neutral[500] }}>{`${status[ticketStatus].subText} ${lastUpdated}`}</div>
            </div>
            {false &&
                <div css={{ display: "flex", alignItems: "center" }}>
                    <div css={{ marginRight: "2rem" }}>
                        <CustomTimeButton width={"3rem"} name={
                            <FeatherIcon icon={"check"} />
                        } theme="success" onClick={() => { }} />
                    </div>
                    <CustomTimeButton width={"3rem"} name={
                        <FeatherIcon icon={"x"} />
                    } theme={"danger"} />
                </div>
            }
        </div >
    )
}

export default TicketStatusCard;