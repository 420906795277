import "antd/dist/antd.css";
import { saveAs } from "file-saver";
import { Tabs, Image, Drawer, Modal } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addattachment,
  createTickets,
  deleteTickets,
  getattached,
  getTickestSubticketlist,
  getTimeline,
  GET_Server_URL,
  pickupticket,
  prioritystatus,
  ticketsdetails,
  updateTicketETA,
  updateTicketStatus,
  getMemberByOrgId,
  getmemberbyproject,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { createTicketNotes, getAllTicketNotes } from "../../utility/Constant";
import {
  days,
  formatdatetime,
  formateDateIntoDatemonth,
  formateDateIntoDatemonthTime,
  mmDDyyyyFormateDateSet,
  mmDDyyyyFormateForForm,
  mmDDyyyyFormateForForm23time,
  ticketpriority,
} from "../../utility/Globals";
import {
  createconversations,
  getTickestAllconversation,
} from "../../utility/Constant";
import toast from "react-hot-toast";
import Picker from "emoji-picker-react";
import { default as ReactSelect } from "react-select";
import { confirmAlert } from "react-confirm-alert";
import FeatherIcon from 'feather-icons-react';
import { Link, useLocation, useNavigate } from "react-router-dom";

function Ticketsview() {
  const location = useLocation();
  const [state, setState] = useState(JSON.parse(localStorage.getItem("state")) != null ? JSON.parse(localStorage.getItem("state")) : location.state);

  const [notesArr, setnotesArr] = useState([]);
  const [notes, setnotes] = useState("");
  const [notesErr, setnotesErr] = useState(false);
  const [notesErrMsg, setnotesErrMsg] = useState("");
  const [conversationErr, setconversationErr] = useState(false);
  const [subticketErr, setsubticketErr] = useState(false);
  const [conversationErrMsg, setconversationErrMsg] = useState("");
  const [subticketErrMsg, setsubticketErrMsg] = useState("");
  const navigate = useNavigate();
  const [subticketcount, subticketCount] = useState("");

  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    location.state = state;
  }, [state]);

  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);
  const [fileName, setfilename] = useState("");
  const [subjectTicket, setSubjectTicket] = useState("");
  const [subjectTicketId, setSubjectTicketupdate] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [ticketSubStatus, setTicketSubStatus] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [createdbyid, setcreatedbyid] = useState("");
  const [createdondate, setcreatedondate] = useState("");
  const [assignId, setassignId] = useState("");
  const [prirotyid, setprirotyid] = useState("");
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [draweropen, setdraweropen] = useState(false);
  const showDrawer = () => {
    setdraweropen(true);
  }
  const drawerclose = () => {
    setlinklist("");
    setdraweropen(false);
  }
  const ref = useRef(null);
  const refEmoji = useRef(null);
  const [Emojiopen, setEmojiOpen] = useState(false);

  useEffect(() => {
    gettciketsdetails();
    getAllNotes();
    getalltiketsconversation();
    getattachement();
    getTimelineData();
    getAllSubTickets();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (refEmoji.current && !refEmoji.current.contains(event.target)) {
        setEmojiOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  const inputFileRef = useRef(null);


  const onBtnClick = () => {

    inputFileRef.current.click();
    document.getElementById('fileUpload').value = null;
  };
  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: 'none',
      height: 43,
      fontSize: '1.1rem'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121" // Custom colour
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
    })
  };
  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      // if (ConversationformValidation()) {
      createClientData();
      // }
    }
  }

  // function _handleKeyDownNote(e) {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (formValidation()) {
  //       let noteData = {
  //         TicketId: location?.state?.item?.id,
  //         Notes: notes,
  //         NotesBy: localStorage.getItem("userId"),
  //       };
  //       createNotes(noteData);
  //       setopenpopup(false);
  //     }
  //   }
  // }

  function _handleKeyLinkAdd(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      addurl();
    }
  }

  function formValidation() {
    let isValidFields = true;
    if (notes.trim() === "") {
      isValidFields = false;
      setnotesErr(true);
      setnotesErrMsg("Notes is required!");
    } else {
      setnotesErr(false);
      setnotesErrMsg("");
    }
    return isValidFields;
  }

  // function ConversationformValidation() {
  //   let isValidFields = true;
  //   if (conversation.trim() === "") {
  //     isValidFields = false;
  //     setconversationErr(true);
  //     setconversationErrMsg("Conversation Message is required!");
  //   } else {
  //     setconversationErr(false);
  //     setconversationErrMsg("");
  //   }
  //   return isValidFields;
  // }

  function SubticketformValidation() {
    let isValidFields = true;
    if (subjectTicket.trim() === "") {
      isValidFields = false;
      setsubticketErr(true);
      setsubticketErrMsg("Subject is required!");
    } else {
      setsubticketErr(false);
      setsubticketErrMsg("");
    }
    return isValidFields;
  }

  function prioritylist(val) {
    var finalVal = val;
    ticketpriority.forEach((item) => {
      if (item.value === val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }

  const [ticketId, setticketId] = useState("");
  const [ticketsubject, setticketsubject] = useState("");
  const [fileUpload, setfileUpload] = useState([]);
  const [memberlist, setmemberlist] = useState();
  const [conversation, setconversation] = useState("");
  const [ticketsconversationlist, setticketsconversationlist] = useState([]);
  const [attachedlist, setattachedlist] = useState([]);
  const [timeline, settimeline] = useState([]);
  const [subticketlist, setsubticketlist] = useState([]);
  const [ETA, setETA] = useState("");
  const [description, setdescription] = useState("");
  const notifyAdd = () => toast.success("Successfully Add!");
  const [cleintid, setcleintid] = useState("");
  const [projectId, setprojectId] = useState("");
  const [tickettypeid, settickettypeid] = useState("");
  const [departmentId, setdepartmentId] = useState("");
  const [priorityid, setpriorityid] = useState("");
  const [parentid, setparentid] = useState("");
  const [ticketnumber, setticketnumber] = useState("");
  const [contact, setcontact] = useState("");
  const [clientname, setclientname] = useState("");
  const [linkname, setlinkname] = useState([]);
  const [linklist, setlinklist] = useState("");
  const [updatestatusdate, setupdatestatusdate] = useState("");
  const [units, setunits] = useState("");
  const [fileNameDisplay, setfileNameDisplay] = useState("");
  const [fileSize, setfileSize] = useState("");
  const [isFile, setIsFile] = useState(false);

  function updateFile() {
    var input = document.getElementById("fileUpload");
    const a = [];
    for (var i = 0; i < input.files.length; ++i) {
      a.push(input.files.item(i));
    }
    setfileUpload(a);
    a.forEach((file) => {
      setfileNameDisplay(file.name);
      let size = bytesToSize(file.size);
      setfileSize(size);
      setIsFile(true)
    });
  }

  function bytesToSize(bytes) {
    const KB = 1024;
    const MB = KB * 1024;
    if (bytes < KB) {
      return bytes + ' bytes';
    } else if (bytes < MB) {
      return (bytes / KB).toFixed(2) + ' KB';
    } else {
      return (bytes / MB).toFixed(2) + ' MB';
    }
  }

  const [openpopup, setopenpopup] = useState(false);
  const showModal = () => {
    setopenpopup(true);
    setnotesErr(false);
  };
  const handleCancel = () => {
    setopenpopup(false);
    setnotesErr(false);
  };
  const handleOk = () => {
    if (formValidation()) {
      let noteData = {
        TicketId: location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'),
        Notes: notes,
        NotesBy: localStorage.getItem("userId"),
      };
      createNotes(noteData);
    }
  };
  function gettciketsdetails() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(ticketsdetails + ticketStateId, null, true).then(
      (response) => {
        if (response?.status === 200) {
          setTicketStatus(response.data[0].status);
          setticketsubject(response.data[0].subject);
          setticketId(response.data[0].id);
          setassignId(response.data[0].assignedMemberId);
          setprirotyid(response.data[0].priorityId);
          setETA(mmDDyyyyFormateDateSet(response.data[0].eta));
          setdescription(response.data[0].description);
          setcreatedBy(response.data[0].createdMember);
          setcleintid(response.data[0].clientId);
          setprojectId(response.data[0].projectId);
          settickettypeid(response.data[0].ticketTypeId);
          setdepartmentId(response.data[0].departmentId);
          setpriorityid(response.data[0].priorityId);
          setcreatedbyid(response.data[0].createdBy);
          setparentid(response.data[0].parentTicketId);
          setticketnumber(response.data[0].ticketNumber);
          setcontact(response.data[0].clientContact);
          setclientname(response.data[0].clientName);
          setcreatedondate(response.data[0].createdOn);
          setupdatestatusdate(response.data[0].updateStatusDate);
          setunits(response.data[0].units);
          getmembers(response.data[0].departmentId, response.data[0].projectId, response.data[0].assignedMemberId);
        }

      }
    );
  }
  function createNotes(rowData) {
    APIManager.postAPI(createTicketNotes, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          clearForm();
          setnotes("");
          notifyAdd();
          getAllNotes();
          setopenpopup(false);
        }
      }
    );
  }

  function getAllNotes() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(
      getAllTicketNotes + ticketStateId + "/notes"
    ).then((response) => {
      if (response?.status === 200) {
        const notesList = [];
        response.data.forEach((item) => {
          let obj = {
            createdOn: item.createdOn,
            createBy: item.member.name,
            notes: item.notes,
          };
          notesList.push(obj);
        });
        setnotesArr(notesList);
      }
    });
  }

  function getmembers(departmentID, projectID, memberId) {
    let memberListAPI = '';
    if (departmentID && projectID) {
      memberListAPI = getmemberbyproject + "?projectId=" + projectID + "&departmentId=" + departmentID;
    }
    if (!departmentID && projectID) {
      memberListAPI = getmemberbyproject + "?projectId=" + projectID;
    }
    if (departmentID && !projectID) {
      memberListAPI = getmemberbyproject + "?departmentId=" + departmentID;
    }
    APIManager.getAPI((!projectID && !departmentID) ? getMemberByOrgId + localStorage.getItem("organizationId") : memberListAPI).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            id: item.memberId ? item.memberId : item.id,
            name: item.name,
            key: item.memberId ? item.memberId : item.id,
            value: item.memberId ? item.memberId : item.id,
            label: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
      }
    });
  }

  function createClientData() {
    var bodyFormData = new FormData();
    bodyFormData.append("TicketId", location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'));
    bodyFormData.append("Comment", conversation);
    bodyFormData.append("CommentBy", localStorage.getItem("userId"));
    setfileUpload([]);
    if (fileUpload?.length) {
      fileUpload.forEach((file) => {
        bodyFormData.append("Files", file);
      });
    }
    if (fileUpload?.length > 0 || conversation.length > 0) {
      APIManager.postAPI(createconversations, {}, bodyFormData, false).then(
        (response) => {
          if (response?.status === 200) {
            clearForm();
            setfileUpload([]);
            setfileNameDisplay("")
            setfileSize("")
            setIsFile(false)
            getalltiketsconversation();
          }
        }
      );
    }

  }

  function createSubTicketupdate() {
    let rowData = {
      ClientId: cleintid,
      ProjectId: projectId,
      Subject: subjectTicket,
      ParentTicketId: ticketId,
      id: subjectTicketId,
      Description: subjectTicket,
      TicketTypeId: tickettypeid,
      ETA: ETA,
      PriorityId: priorityid,
      DepartmentId: departmentId,
      AssignedTo: assignId,
      createdby: createdbyid,
    };
    APIManager.putAPI(createTickets, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        setSubjectTicket("");
        getAllSubTickets();
      }
    });
  }

  function createSubTicket() {
    let rowData = {
      ClientId: cleintid,
      ProjectId: projectId,
      Subject: subjectTicket,
      ParentTicketId: ticketId,
      Description: subjectTicket,
      TicketTypeId: tickettypeid,
      ETA: ETA,
      PriorityId: priorityid,
      DepartmentId: departmentId,
      AssignedTo: assignId,
      createdby: localStorage.getItem("userId"),
    };
    APIManager.postAPI(createTickets, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        clearForm();
        setSubjectTicket("");
        notifyAdd();
        getAllSubTickets();
      }
    });
  }

  function getAllSubTickets() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(
      getTickestSubticketlist + ticketStateId + "/subtickets"
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item, index) => {
          subticketCount(response.data?.length);
          let obj = {
            count: index + 1,
            ticketid: item.id,
            ticketsub: item.subject,
            ticketnumber: item.ticketNumber,
            createdBy: item.createdBy,
            status: item.status,
            createdate: item.createdOn,
            clientContact: item.clientContact,
            createdMember: item.createdMember,
            clientName: item.clientName,
            assignedMemberId: item.assignedMemberId,
            priorityId: item.priorityId,
            assignedStatus: item.assignedStatus,
          };
          list.push(obj);
          index++
        });
        setsubticketlist(list);
      }
    });
  }

  function clearForm() {
    setconversation("");
  }

  function getalltiketsconversation() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(
      getTickestAllconversation + ticketStateId + "/conversation"
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          let obj = {
            comment: item.comment,
            commentBy: item.commentBy,
            createdOn: item.createdOn,
            member: item.member,
            ticketsConversationAttachments: item.ticketsConversationAttachments,
          };
          list.push(obj);
        });
        setticketsconversationlist(list);
        getattachement();
        setEmojiOpen(false);
      }
    });
  }

  function updateTicketSts(sts) {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.putAPI(
      updateTicketStatus +
      "?ticketId=" +
      ticketStateId +
      "&ticketsStatus=" + sts + "&userId=" + localStorage.getItem("userId")
      ,
      {
        ticketId: ticketStateId,
        ticketsStatus: sts,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data === true) {
          setTicketStatus(sts);
        }
      }
    });
  }

  function updateSubTicketSts(id, sts) {
    APIManager.putAPI(
      updateTicketStatus + "?ticketId=" + id + "&ticketsStatus=" + sts + "&userId=" + localStorage.getItem("userId"),
      {
        ticketId: id,
        ticketsStatus: sts,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        if (response.data === true) {
          setTicketSubStatus(sts);
        }
      }
    });
  }

  function handleaddattachment() {
    setlinkname([]);
    setfileUpload([]);
    var bodyFormData = new FormData();
    bodyFormData.append("TicketId", location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'));
    linkname.map((item, i) => {
      bodyFormData.append(`links[${i}]`, item);
    })
    bodyFormData.append("CreatedOn", mmDDyyyyFormateForForm23time(new Date()));
    if (fileUpload?.length) {
      fileUpload.forEach((file) => {
        bodyFormData.append("Files", file);
      });
    }
    APIManager.postAPI(addattachment, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status === 200) {
          getattachement();
          drawerclose();
        }
      }
    );
  }

  function getattachement() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(
      getattached + ticketStateId + "/attachment"
    ).then((response) => {
      if (response && response.status === 200) {
        const attached = [];
        response.data.forEach((item) => {
          let obj = {
            fileName: item.fileName,
            createdOn: item.createdOn,
            fileType: item.fileType
          };
          attached.push(obj);
        });
        setattachedlist(attached);
        getTimelineData();
      }
    });
  }

  function pickupData(sts) {
    let rowData = {
      TicketId: location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'),
      MemberId: localStorage.getItem("userId"),
      AssignedOn: mmDDyyyyFormateForForm(new Date()),
      Status: sts,
      CreatedBy: createdbyid

    };
    APIManager.postAPI(pickupticket, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        if (response.data?.ticketsAssignmentId) {
          setTicketStatus(1);
          gettciketsdetails();
        } else {
        }
      }
    });
  }

  async function deletesubticket(id) {
    try {
      const response = await APIManager.deleteAPI(deleteTickets + id);
      if (response === 200) {
        getAllSubTickets();
      }
    } catch (e) { }
  }

  function getTimelineData() {
    let ticketStateId = location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState');
    APIManager.getAPI(getTimeline + ticketStateId + "/timeline").then(
      (response) => {
        if (response && response.status === 200) {
          const timeline = [];
          response.data.forEach((item) => {
            let obj = {
              comments: item.comment,
              membername: item.member?.name,
              etaDate: item.tickets.eta,
              createdOn: item.createdOn,
              timelineType: item.timelineType,
            };
            timeline.push(obj);
          });
          settimeline(timeline.reverse());
        }
      }
    );
  }

  const [isEditRole, setIsEditRole] = useState(false);
  function AssignTickets(getdata) {
    APIManager.postAPI(pickupticket, getdata, null, true).then((response) => {
      if (response && response.status === 200) {
        setassignId(getdata.MemberId);
        const stateCopy = { ...location.state };
        stateCopy.item.assignId = getdata.MemberId;
        navigate("",{ state: stateCopy , replace: true });
        gettciketsdetails();
      }
    });
  }

  function prooritystatuschange(getdata) {
    APIManager.putAPI(
      prioritystatus +
      "ticketId=" +
      getdata.ticketid +
      "&memberId=" +
      localStorage.getItem("userId") +
      "&priorityList=" +
      getdata.priorityList,
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setprirotyid(getdata.priorityList);
        const stateCopy = { ...location.state };
        stateCopy.item.priorityId = getdata.priorityList;
        navigate("",{ state: stateCopy, replace: true });
      }
    });
  }

  const onEmojiClick = (event, emojiObject) => {
    const cursor = ref.current.selectionStart;
    const text =
      conversation.slice(0, cursor) +
      emojiObject.emoji +
      conversation.slice(cursor);
    setconversation(text);
  };

  function changeETA(getdata) {
    APIManager.putAPI(
      updateTicketETA +
      "?ticketId=" +
      getdata.ticketid +
      "&etaDate=" +
      getdata.etaDate,
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setETA(getdata.etaDate);
        const stateCopy = { ...location.state };
        stateCopy.item.etaDate = getdata.etaDate;
        navigate("",{ state: stateCopy, replace: true });
      }
    });
  }

  async function daleteticktes(id) {
    try {
      const response = await APIManager.ticketdeleteAPI(deleteTickets + id);
      if (response === 200) {
       navigate(-1);
      }
    } catch (e) { }
  }

  async function daletesubticktes(id) {
    try {
      const response = await APIManager.deleteAPI(deleteTickets + id);
      if (response === 200) {
       navigate(-1);
      }
    } catch (e) { }
  }
  function getAssignName(data) {
    if (assignId !== null) {
      if (data.MemberId !== assignId) {
        memberlist?.filter(x => {
          if (x.id === data.MemberId) {
            ticketReassign(data, x.name);
          }
        })
      }
    }
    else {
      AssignTickets(data);
    }
  }

  function getpriorityName(data) {
    ticketpriority?.forEach((item) => {
      if (data.priorityList === item.value.toString())
        changeticketpriority(data, item.label)
    })
  }

  async function ticketReassign(data, memberName) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui delete-alert ticket-reassign'>
              <div className='icon-box'>
                <FeatherIcon icon="user" />
              </div>
              <div className='text ms-3'>
                <h3>Are you sure you want to Reassign?</h3><p>This action will transfer ownership of the ticket to {memberName} .</p>
                <div className='d-flex align-items-center justify-content-end mt-5 pt-3'>
                  <button class="btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn-delete"
                    onClick={() => {
                      AssignTickets(data);
                      onClose();
                    }}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          );
        }
      });
    });
  }

  async function changeticketpriority(data, priorityName) {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui delete-alert ticket-priority'>
              <div className='icon-box'>
                <FeatherIcon icon="clock" />
              </div>
              <div className='text ms-3'>
                <h3>Confirm Priority Changes?</h3><p>This action will change the priority to {priorityName} .</p>
                <div className='d-flex align-items-center justify-content-end mt-5 pt-3'>
                  <button class="btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn-delete"
                    onClick={() => {
                      prooritystatuschange(data);
                      onClose();
                    }}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          );
        }
      });
    });
  }
  async function changeETADate(data, ETA) {
    const date = new Date(ETA);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
    const year = date.getFullYear();
    const formattedDate = `${day}${day === 1 || day === 11 || day === 21 ? 'st' : day === 2 || day === 12 || day === 22 ? 'nd' : day === 3 || day === 13 || day === 23 ? 'rd' : 'th'} ${month} ${year}`;
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui delete-alert ticket-eta'>
              <div className='icon-box'>
                <FeatherIcon icon="calendar" />
              </div>
              <div className='text ms-3'>
                <h3>Confirm ETA Changes?</h3><p>This action will change the ETA to {formattedDate} .</p>
                <div className='d-flex align-items-center justify-content-end mt-5 pt-3'>
                  <button class="btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn-delete"
                    onClick={() => {
                      changeETA(data);
                      onClose();
                    }}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          );
        }
      });
    });
  }
  function addurl() {
    if (linklist) {
      let url = linkname;
      url.push(linklist);
      setlinkname(url);
      setlinklist("");
    }
  }

  function removeItemURL(e, index) {
    let array = [...linkname];
    if (index !== -1) {
      array.splice(index, 1);
      setlinkname(array);
    }
  }

  function attachmentName(isUrl, fileName) {
    if (isUrl) return fileName;
    if (fileName.split('_').length)
      return fileName.split('_')[1];
    else
      return fileName;
  }

  return (
    <>
      <div class="top-title d-flex ticket-bredcrumb-top">
        <ul>
          <li>
            <a class="top-tickets mb-0 fw-bold text-capitalize" onClick={() => {
            }}>{location?.state?.viewtickets?.IsTeam === 1 ? (<Link to="/ticket/view-all-tickets">Team Tickets</Link>) : (<Link to="/ticket/personal-tickets">My Tickets</Link>)}</a>
          </li>
          <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
          <li>
            <a class="mb-0 text-capitalize fw-bold active" onClick={() => {
             navigate(-1);
            }}>
              #{ticketnumber}
            </a>
          </li>
        </ul>
      </div>
      <Drawer
        title="Create Subticket"
        className="create-subticket"
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div class="view-ticket-box main-view-ticket">
          <div class="p-4">
            <div class="d-flex ticket-name-box align-items-top">
              <div class="icon">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1444_22539)">
                    <path
                      d="M25 15C25 14.05 25.5375 13.225 26.325 12.8C27.075 12.3875 27.5 11.5375 27.5 10.675V7.5C27.5 6.125 26.375 5 25 5H5C3.625 5 2.5125 6.1125 2.5125 7.4875V10.675C2.5125 11.5375 2.925 12.3875 3.6875 12.7875C4.475 13.225 5 14.05 5 15C5 15.95 4.4625 16.7875 3.675 17.2C2.925 17.6125 2.5 18.4625 2.5 19.325V22.5C2.5 23.875 3.625 25 5 25H25C26.375 25 27.5 23.875 27.5 22.5V19.325C27.5 18.4625 27.075 17.6125 26.325 17.2C25.5375 16.775 25 15.95 25 15ZM18.125 20.125L15 18.125L11.875 20.1375C11.4 20.4375 10.7875 20 10.9375 19.45L11.875 15.85L9 13.5C8.5625 13.1375 8.7875 12.425 9.3625 12.3875L13.0625 12.175L14.4125 8.7375C14.625 8.2125 15.375 8.2125 15.575 8.7375L16.925 12.1875L20.625 12.4C21.1875 12.4375 21.425 13.15 20.9875 13.5125L18.1125 15.8625L19.0625 19.4375C19.2125 20 18.6 20.4375 18.125 20.125Z"
                      fill="#0691EF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1444_22539">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="ms-3">
                <h5 class="title fw-bold">{ticketsubject}</h5>
                <div class="d-flex number-date grey-text">
                  <span class="number"> #{ticketnumber}</span>
                  <span class="date-time ms-4">
                    {formateDateIntoDatemonthTime(createdondate)}
                  </span>
                </div>
              </div>
            </div>
            <div class="dticket-name-box">
              <div class="row mt-4">
                <div className="col-md-8 ps-0">
                  <div className="input-box w-100">
                    <input
                      type="text"
                      value={subjectTicket}
                      onChange={(e) => {
                        setSubjectTicket(e.target.value);
                      }}
                      className="form-control"
                    />
                    {subticketErr ? (
                      <div className="field-required mt-3">
                        <span>{subticketErrMsg}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>{" "}
                </div>
                <div class="col-md-4 create-btn text-end px-0">
                  <button
                    disabled={ticketStatus === 3 ? true : false}
                    className="btn btn-primary py-0 w-100"
                    onClick={() => {
                      if (SubticketformValidation()) {
                        setIsEditRole(false);
                        if (isEditRole) {
                          createSubTicketupdate();
                        } else {
                          createSubTicket();
                        }
                      }
                    }}
                  >
                    <div class="d-flex align-items-center">
                      <div class="icon-box">+</div>
                      <span class="ms-2">   {isEditRole ? "Update" : "add Subticket"}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-box mb-3">
            <hr />
            <h5 class="title fw-bold px-4 py-2 mb-0">
              {subticketcount
                ? "Subticket(" + subticketcount + ")"
                : "Subticket(0)"}
            </h5>
          </div>

          {subticketlist.map((item) => {
            return (
              <>
                <div className="row w-100 mb-3 px-3">
                  <div className="col-md-10">
                    <div
                      className={`${item.status === 3 || ticketSubStatus === 3
                        ? "text-sub-success portlet px-0 mb-0"
                        : "portlet px-0 mb-0"
                        }`}
                    >
                      <div className="d-flex justify-content-between px-3 py-3">
                        <div className="d-flex">
                          <i
                            class={`researching fa fa-check me-3 ${item.status === 3 || ticketSubStatus === 3
                              ? "text-circle-success"
                              : "text-secondary"
                              }`}
                            aria-hidden="true"
                            onClick={() => {
                              updateSubTicketSts(item.ticketid, 3);
                            }}
                          ></i>
                          <div class="research-creating title fw-bold">
                            <h5
                              class={`${item.status === 3 || ticketSubStatus === 3
                                ? "success-subticket"
                                : ""
                                } me-2 mb-0`}
                            >
                              {item.ticketsub}
                            </h5>
                          </div>
                        </div>

                        <div className="view-details text-end">
                          <Link
                            to={"/ticket/subticket-view"}
                            state={{ item: item, viewtickets: { IsTeam: location?.state?.viewtickets?.IsTeam, ticketnumber: ticketnumber } }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 subticket-btn d-flex justify-content-end align-items-center">
                    <i
                      class="fa fa-edit text-secondary me-4"
                      onClick={() => {
                        setIsEditRole(true);
                        setSubjectTicket(item.ticketsub);
                        setSubjectTicketupdate(item.ticketid);
                      }}
                      aria-hidden="true"
                    ></i>
                    <i
                      class="fa fa-trash text-danger"
                      onClick={() => {
                        deletesubticket(item.ticketid);
                        onClose();
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Drawer>
      <Drawer
        title="Add Attachment"
        className="add-attch-modal"
        placement="right"
        size={size}
        onClose={drawerclose}
        open={draweropen}
      >
        <div class="dticket-name-box">
          <div class="row">
            <div className="col-md-12 px-0">
              <div className="input-box w-100">
                <label>Upload Links(multiple Links can be added)</label>
                <div className="position-relative">
                  <div className="d-flex align-items-top">
                    <div className="url-input-box">
                      <input
                        className="form-control"
                        onKeyDown={(e) => {
                          _handleKeyLinkAdd(e);
                        }}
                        type="text"
                        value={linklist}
                        onChange={(text) => {
                          setlinklist(text.target.value);
                        }}
                        placeholder="Add url"
                      />
                      <ul>
                        {linkname.map((item, index) => {
                          return (<>
                            <li>
                              <div className="d-flex align-items-center me-3 cursor-pointer">
                                <span className="me-3"> {item}</span>
                                <span> <i className="fa fa-times" onClick={() => { removeItemURL(item, index) }}></i></span>
                              </div>
                            </li>
                          </>)
                        })}
                      </ul>
                    </div>
                    <button className="btn btn-primary py-4 ms-2" onClick={addurl}>Add</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 create-btn text-start ps-0 mt-4 border-top pt-3">
              <div className="btn-box">
                <div className="upload-text">Upload Files (.pdf, .docx, .jpeg, .jpg, .png, .xlsx, .txt & .csv )</div>
                <a className="btn btn-outline mt-2" onClick={onBtnClick} >Browse File</a>
                {fileUpload.map((item, index) => {
                  return (<>
                    <div className="d-flex justify-content-between align-items-center report-fil mt-3">
                      <div className="left d-flex align-items-center">
                        <div class="icon-single">

                          {item.name.includes(".jpg") ||
                            item.name.includes(".png") ||
                            item.name.includes(".jpeg") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-image"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="18"
                                height="18"
                                rx="2"
                                ry="2"
                              ></rect>
                              <circle cx="8.5" cy="8.5" r="1.5"></circle>
                              <polyline points="21 15 16 10 5 21"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".pdf") || item.name.includes(".docx") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#212121"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-file-text"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".txt") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#212121"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-file-text"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".xlsx") ? (
                            <i
                              class="fa fa-file-excel"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <>
                            </>

                          )}
                          <span className="bug-file">{item.name}</span>
                        </div>
                        <i
                          onClick={() => {
                            setfileUpload(
                              fileUpload.filter((e) => e !== item)
                            );
                            if (index > -1) {
                              fileUpload.splice(index, 1);
                            }
                            setfileUpload(fileUpload);
                          }}
                          style={{ color: "#757575" }}
                        ></i>
                      </div>

                      <i
                        onClick={() => {
                          setfileUpload(
                            fileUpload.filter((e) => e !== item)
                          );
                          if (index > -1) {
                            fileUpload.File.splice(index, 1);
                          }
                          setfileUpload(fileUpload);
                        }}
                        class="fa fa-times"
                        style={{ color: "#757575" }}
                      ></i>
                    </div>
                  </>)

                })}
              </div>
            </div>
            <div className="col-sm-12 attach-btn-box px-0">
              <hr />
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-outline" onClick={() => {
                  drawerclose();
                }} >Cancel</button>
                <button className="btn btn-primary ms-3 d-flex align-items-center" onClick={() => {
                  handleaddattachment();
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                  <span className="ms-3">Upload Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div class="row view-ticket-tab-row w-100">
        <div class="col-md-9 col-sm-12 ps-0 newticket-view">
          <div class="ticket-name-box portlet p-4">
            <div class="d-flex justify-content-between ">
              <div class="d-flex">
                <div class="icon">
                  <img class="ticket-icon" src="/images/sub-tic.png" alt="" />
                </div>
                <div class="text ms-3">
                  <h5 class="title fw-bold">{ticketsubject}</h5>
                  <p dangerouslySetInnerHTML={{
                    __html: description,
                  }}></p>
                  <div class="d-flex number-date grey-text">
                    <span class="number">
                      {" "}
                      #{ticketnumber}
                    </span>
                    <span class="date-time ms-4">
                      {formateDateIntoDatemonthTime(
                        createdondate
                      )}
                    </span>
                  </div>

                  <div class="d-flex mt-3">
                    {(!parentid && ticketStatus !== 2) ? (<>
                      <div class="create-btn">
                        <button
                          class="btn btn-primary d-inline-block"
                          disabled={ticketStatus === 3 ? true : false}
                          onClick={() => {
                            showLargeDrawer();
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="icon-box">+</div>
                            <span className="ms-2">create subtickets</span>
                          </div>
                        </button>
                      </div>
                      <div class="d-flex align-items-center ms-4">
                        <img class="ticket-icon-btn " src="/images/ticket.png" alt=""
                          // disabled={ticketStatus === 3 ? true : false}
                          onClick={() => {
                            // if (ticketStatus !== 3) {
                            showLargeDrawer();
                            // }

                          }} />
                        <span class="count ms-2"
                        >
                          {subticketcount ? subticketcount : "0"}
                        </span>
                      </div>
                    </>) : (<></>)}

                  </div>
                </div>
              </div>
              <div class="flex-end">
                {createdbyid ===
                  localStorage.getItem("userId") ? (<>
                    <span class="dropdown">
                      <button
                        type="kabeb button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>

                      <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={() => {
                            !parentid ?
                              daleteticktes(location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState')) :
                              daletesubticktes(location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'));
                          }}
                        >
                          <a class="dropdown-item d-flex align-items-center dot-drop-color delete">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            <span className="ms-3">Delete</span>
                          </a>
                        </div>
                      </div>
                    </span>
                  </>
                ) : (
                  <></>
                )}

              </div>
            </div>
          </div>
          <Tabs className="main-view-ticket portlet"
            onChange={(e) => {
              if (e === 'item-1') {
                setIsFile(false);
              }
            }}
          >
            <Tabs.TabPane
              tab={"Conversations (" + ticketsconversationlist?.length + ")"}
              key="item-1"

            >
              <div class="title-content-tab view-ticket-box-tabs">
                <div class="tab-content p-0 mt-0" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="conversation-tab">
                      {ticketsconversationlist.map((item) => {
                        let firstltter = item.member.name;
                        return (
                          <>
                            <div class="profile-box portlet mb-0">
                              <div class="d-flex">
                                <div class="profile">
                                  <div class="name-first-latter">
                                    {firstltter.charAt(0)}
                                  </div>
                                </div>
                                <div class="text ms-3 w-100">
                                  <h5
                                    class="name-title fw-bold 
                                                     text-capitalize mb-1"
                                  >
                                    {item.member.name}
                                  </h5>
                                  <span class="grey-text">
                                    {days(item.createdOn)}
                                  </span>
                                  <p class="mt-4 mb-3 pb-4 border-bottom">
                                    {item.comment}
                                  </p>
                                  <div class="d-flex justify-content-between align-items-center mb-3">
                                    {/* <span class="attachment fw-bold text-capitalize">
                                      {item.ticketsConversationAttachments.length === "" ? (<></>) : (<> {
                                        item.ticketsConversationAttachments
                                          ?.length
                                      }{" "}
                                        attachments</>)}
                                    </span> */}
                                    {item.ticketsConversationAttachments
                                      ?.length !== 0 ? (
                                      <>
                                        <div className="download-all">
                                          <a
                                            onClick={() => {
                                              item.ticketsConversationAttachments?.forEach(
                                                (i) => {
                                                  i.fileName.includes(".jpg") ||
                                                    i.fileName.includes(".png") ||
                                                    i.fileName.includes(
                                                      ".jpeg"
                                                    ) ||
                                                    i.fileName.includes(".txt") ||
                                                    i.fileName.includes(".pdf")
                                                    ? saveAs(
                                                      GET_Server_URL +
                                                      i.fileName,
                                                      i.fileName.split(
                                                        `\\`
                                                      )[1]
                                                    )
                                                    : window.open(
                                                      GET_Server_URL +
                                                      i.fileName
                                                    );
                                                }
                                              );
                                            }}
                                            class="download-btn text-capitalize"
                                          >
                                            {item.ticketsConversationAttachments
                                              ?.length ? "download" : item.ticketsConversationAttachments
                                                ?.length > 1
                                              ? "download all"
                                              : ""}
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div>
                                    <div className="row mt-2">
                                      {item.ticketsConversationAttachments?.map(
                                        (i) => {
                                          return (
                                            <>
                                              <div class="col-md-4 col-sm-12 ps-0">
                                                <div class="d-flex attchment align-items-center text-break pe-4">
                                                  {i.fileName.includes(".jpg") ||
                                                    i.fileName.includes(".png") ||
                                                    i.fileName.includes(".jpeg") ||
                                                    i.fileName.includes(".webp") ? (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      class="feather feather-image"
                                                    >
                                                      <rect
                                                        x="3"
                                                        y="3"
                                                        width="18"
                                                        height="18"
                                                        rx="2"
                                                        ry="2"
                                                      ></rect>
                                                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                                      <polyline points="21 15 16 10 5 21"></polyline>
                                                    </svg>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".pdf") || i.fileName.includes(".docx") ? (
                                                    // <svg
                                                    //   xmlns="http://www.w3.org/2000/svg"
                                                    //   width="24"
                                                    //   height="24"
                                                    //   viewBox="0 0 24 24"
                                                    //   fill="none"
                                                    //   stroke="#212121"
                                                    //   stroke-width="2"
                                                    //   stroke-linecap="round"
                                                    //   stroke-linejoin="round"
                                                    //   class="feather feather-file-text"
                                                    // >
                                                    //   <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    //   <polyline points="14 2 14 8 20 8"></polyline>
                                                    //   <line x1="16" y1="13" x2="8" y2="13"></line>
                                                    //   <line x1="16" y1="17" x2="8" y2="17"></line>
                                                    //   <polyline points="10 9 9 9 8 9"></polyline>
                                                    // </svg>
                                                    <img src="/images/pdf-img.png" className="me-3 ticket-pdf-img" alt="" />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".txt") ? (
                                                    // <img
                                                    //   src="/images/sub-pdf.png"
                                                    //   class="me-3"
                                                    //   alt="#"
                                                    // />
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="#212121"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      class="feather feather-file-text"
                                                    >
                                                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                      <polyline points="14 2 14 8 20 8"></polyline>
                                                      <line x1="16" y1="13" x2="8" y2="13"></line>
                                                      <line x1="16" y1="17" x2="8" y2="17"></line>
                                                      <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".docx") ||
                                                    i.fileName.includes(".xlsx") ? (
                                                    <i class="fa fa-file-excel me-3"></i>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".Url") ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <label
                                                    id="fileLabel"
                                                    class="m-0"
                                                  >
                                                    {i.fileName.split(`_`)[1]}
                                                  </label>
                                                </div> </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div ref={refEmoji}>
                        {Emojiopen ? (
                          <>
                            <Picker
                              style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "20px",
                              }}
                              onEmojiClick={onEmojiClick}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="conversation-type-box pt-4">
                      <form>
                        <div class="portlet form-group">
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="fileUpload"
                            name="fileUpload"
                            multiple={true}
                            onChange={updateFile}
                            ref={inputFileRef}
                          />
                          <textarea
                            cols={1}
                            class="form-control"
                            ref={ref}
                            placeholder="Write your message here"
                            value={conversation}
                            onKeyDown={(e) => {
                              _handleKeyDown(e);
                            }}
                            onChange={(item) => {
                              setconversation(item.target.value);
                            }}
                          />
                          {/* <input
                            type="text"
                            class="form-control"
                            ref={ref}
                            placeholder="Write your message here"
                            value={conversation}
                            onKeyDown={(e) => {
                              _handleKeyDown(e);
                            }}
                            onChange={(item) => {
                              setconversation(item.target.value);
                            }}
                          /> */}
                          {conversationErr ? (
                            <div className="field-required">
                              <span className="conversation-msg">{conversationErrMsg}</span>
                            </div>
                          ) : (
                            <></>
                          )}
                          {
                            isFile ? (<> <div className="a-box d-flex align-items-center">
                              <div class="d-flex attchment align-items-center text-break pe-4">
                                {fileNameDisplay.includes(".jpg") ||
                                  fileNameDisplay.includes(".png") ||
                                  fileNameDisplay.includes(".jpeg") ||
                                  fileNameDisplay.includes(".webp") ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-image"
                                  >
                                    <rect
                                      x="3"
                                      y="3"
                                      width="18"
                                      height="18"
                                      rx="2"
                                      ry="2"
                                    ></rect>
                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                    <polyline points="21 15 16 10 5 21"></polyline>
                                  </svg>
                                ) : (
                                  <></>
                                )}

                                {fileNameDisplay.includes(".pdf") || fileNameDisplay.includes(".docx") ? (
                                  // <svg
                                  //   xmlns="http://www.w3.org/2000/svg"
                                  //   width="24"
                                  //   height="24"
                                  //   viewBox="0 0 24 24"
                                  //   fill="none"
                                  //   stroke="#212121"
                                  //   stroke-width="2"
                                  //   stroke-linecap="round"
                                  //   stroke-linejoin="round"
                                  //   class="feather feather-file-text"
                                  // >
                                  //   <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  //   <polyline points="14 2 14 8 20 8"></polyline>
                                  //   <line x1="16" y1="13" x2="8" y2="13"></line>
                                  //   <line x1="16" y1="17" x2="8" y2="17"></line>
                                  //   <polyline points="10 9 9 9 8 9"></polyline>
                                  // </svg>

                                  <img src="/images/pdf-img.png" className="me-3 ticket-pdf-img" alt="" />
                                ) : (
                                  <></>
                                )}
                                {fileNameDisplay.includes(".txt") ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#212121"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-file-text"
                                  >
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                    <polyline points="14 2 14 8 20 8"></polyline>
                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                    <polyline points="10 9 9 9 8 9"></polyline>
                                  </svg>
                                ) : (
                                  <></>
                                )}

                                {fileNameDisplay.includes(".docx") ||
                                  fileNameDisplay.includes(".xlsx") ? (
                                  <i class="fa fa-file-excel me-3"></i>
                                ) : (
                                  <></>
                                )}

                                {fileNameDisplay.includes(".Url") ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                ) : (
                                  <></>)}
                                <div className="texxt">
                                  <label id="fileLabel" class="m-0">{fileNameDisplay}</label>
                                  <span className="d-block">{fileSize}</span>
                                </div>
                              </div>
                              <div className="close-btn ms-3">
                                <i class="fa fa-close"
                                  onClick={() => {
                                    setfileUpload([]);
                                    setfileNameDisplay("")
                                    setfileSize("")
                                    setIsFile(false)
                                  }}>

                                </i>
                              </div>
                            </div></>) : <></>
                          }



                          <div class="d-flex icon-box">
                            <a type="file"
                              onClick={onBtnClick}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
                            </a>
                            <a className="mx-3"
                              onClick={() => {
                                setEmojiOpen(!Emojiopen);
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>

                            </a>
                            <a
                              onClick={() => {
                                // if (ConversationformValidation()) {
                                createClientData();
                                // }
                              }}>
                              {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  ></div>

                  <div
                    class="tab-pane fade"
                    id="pills-timeline"
                    role="tabpanel"
                    aria-labelledby="pills-timeline-tab"
                  ></div>
                  <div
                    class="tab-pane fade show active"
                    id="pills-notes"
                    role="tabpanel"
                    aria-labelledby="pills-notes-tab"
                  ></div>
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Attachments" key="item-2">
              <div class="portlet attachment-tab border-0">
                <div class="title">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="text mb-md-0 mb-3">
                      <h5 class="text-capitalize mb-1">
                        attachments for tickets
                      </h5>
                      <span class="grey-text">
                        All the attachment of the tickets are listed below
                      </span>
                    </div>
                    <div class="outline-attch-btn mt-2 pt-1">
                      <a onClick={showDrawer}>
                        + add attachment
                      </a>
                    </div>
                  </div>
                </div>
                <div class="attach-content-box">
                  {attachedlist.map((item) => {
                    return (
                      <>
                        <div class="content-list d-flex justify-content-between align-items-center border-top">
                          <div class="text-box d-flex align-items-center">
                            <div class="icon-single">
                              {item.fileName.toLowerCase().includes(".jpg") ||
                                item.fileName.toLowerCase().includes(".png") ||
                                item.fileName.toLowerCase().includes(".webp") ||
                                item.fileName.toLowerCase().includes(".jpeg") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-image"
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    width="18"
                                    height="18"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                  <polyline points="21 15 16 10 5 21"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".pdf") || item.fileName.includes(".docx") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-file-text"
                                >
                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  <polyline points="14 2 14 8 20 8"></polyline>
                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                  <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".txt") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-file-text"
                                >
                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  <polyline points="14 2 14 8 20 8"></polyline>
                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                  <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".xlsx") ? (
                                <i
                                  class="fa fa-file-excel"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <>
                                </>
                              )}
                              {item.fileType.includes("Url") ? (<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></>) : (<>
                                </>)}
                            </div>
                            <div class="text ms-3">
                              <h5 class="text-capitalize">
                                {/* {item.fileType.includes("Url") ? item.fileName : item.fileName.split(`\\`)[1]} */}
                                {attachmentName(item.fileType.includes("Url"), item.fileType.includes("Url") ? item.fileName : item.fileName.split(`\\`)[1])}
                              </h5>
                              <span class="grey-text date-time">
                                {formateDateIntoDatemonthTime(item.createdOn)}
                              </span>

                              <Image
                                width={100}
                                style={{ display: "none" }}
                                src={fileName}
                                preview={{
                                  visible,
                                  scaleStep,
                                  src: fileName,
                                  onVisibleChange: (value) => {
                                    setVisible(value);
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div class="icon">
                            <div class="d-flex align-items-center justify-content-between">
                              {item.fileName.toLowerCase().includes(".jpg") ||
                                item.fileName.toLowerCase().includes(".png") ||
                                item.fileName.toLowerCase().includes(".webp") ||
                                item.fileName.toLowerCase().includes(".jpeg") ? (
                                <a
                                  onClick={() => {
                                    setfilename(GET_Server_URL + item.fileName);
                                    setVisible(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#212121"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </a>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".pdf") || item.fileType.includes("Url") ? (
                                <a
                                  href={item.fileType.includes("Url") ? item.fileName : GET_Server_URL + item.fileName}
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#212121"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </a>
                              ) : (
                                <></>
                              )}
                              {item.fileName.toLowerCase().includes(".jpg") ||
                                item.fileName.toLowerCase().includes(".png") ||
                                item.fileName.toLowerCase().includes(".jpeg") ||
                                item.fileName.toLowerCase().includes(".webp") ||
                                item.fileName.toLowerCase().includes(".pdf") ? (
                                <a
                                  target="_blank"
                                  onClick={() => {
                                    saveAs(
                                      GET_Server_URL + item.fileName,
                                      item.fileName.split(`\\`)[1]
                                    );
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                </a>
                              ) : (
                                <> {!item.fileType.includes("Url") ? (<>
                                  <a
                                    href={GET_Server_URL + item.fileName}
                                    target="_blank"
                                    download
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#212121"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-download"
                                    >
                                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                      <polyline points="7 10 12 15 17 10"></polyline>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="3"
                                      ></line>
                                    </svg>
                                  </a>
                                </>) : (<></>)
                                }</>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Timeline" key="item-3">
              <div class="portlet timeline-box border-0">
                {timeline?.length && timeline[0].timelineType === 7 ? (
                  <>
                    <div class="timeline-ticket-complete mb-4">
                      <div class="card mt-0 align-items-center d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div class="icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </div>
                          <div class="info">
                            <h5 class="title">tickets completed !</h5>
                            <span class="grey-text">
                              You've Officially done something really nice. Well
                              done for making it happen, you deserve a cookie!
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <i className="fa fa-ellipsis-v me-3"></i>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div class="timeline-list pt-0">
                  <div class="outer">
                    {timeline.map((item) => {
                      return (
                        <>
                          <div class="card">
                            <div class="icon">
                              {item.timelineType === 0 ? (
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 1 ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 2 ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 3 ? (
                                <i
                                  class="far fa-comment-alt"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 4 ? (
                                <i
                                  class="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 5 ? (
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 6 ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 7 ? (
                                <i class="fa fa-check" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 8 ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18 6L6 18" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M6 6L18 18" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType === 9 ? (
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                              ) : (
                                ""
                              )}
                              {item.timelineType === 10 ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M21.7988 14.1519L6.2326 20.4219L5.50885 18.3744C5.50885 18.3744 7.03885 17.5756 6.53635 16.1844C6.03385 14.7931 4.2151 15.0969 4.2151 15.0969L3.50635 12.9894L19.1251 6.82812L19.8976 8.65438C19.8976 8.65438 18.2326 9.67063 18.7501 11.1106C19.2676 12.5506 21.0938 12.2131 21.0938 12.2131L21.7988 14.1519Z" stroke="#0691EF" stroke-width="3" />
                                  <path d="M6.9375 11.6406L7.78875 13.5681" stroke="#0691EF" stroke-width="3" stroke-dasharray="9 4" />
                                  <path d="M8.18604 14.4941L8.90604 16.1216" stroke="#0691EF" stroke-width="3" stroke-dasharray="9 4" />
                                  <path d="M9.17627 16.8906L10.0313 18.8181" stroke="#0691EF" stroke-width="3" stroke-dasharray="9 4" />
                                  <path d="M3.5249 12.9519L15.5699 4.01562L16.8374 5.53813C16.8374 5.53813 15.5362 6.99688 16.4624 8.21938" stroke="#0691EF" stroke-width="3" />
                                </svg>

                              ) : (
                                ""
                              )}
                            </div>
                            <div class="info">
                              <h6 class="title">
                                {formatdatetime(item.createdOn)}
                              </h6>
                              <span class="grey-text">
                                {item.comments ? item.comments : "No commmnets"}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Notes" key="item-4">
              <div class="portlet notes-tab border-0">
                <div class="note-box d-flex flex-wrap justify-content-between align-items-top">
                  <div class="form-group mb-0">
                    <h6 class="title">notes</h6>
                    <span class="grey-text">
                      All the notes made for this ticket is listed below
                    </span>
                  </div>
                  <div class="outline-attch-btn mt-3 mt-md-0">
                    <a className="mt-1 d-block"
                      onClick={() => {
                        showModal();
                        setnotes('');
                      }}
                    >
                      {" "}
                      + add notes
                    </a>
                  </div>
                </div>
                <Modal
                  className="create-note-modal"
                  title="Create Note"
                  open={openpopup}
                  onOk={handleOk}
                  okText="Create Note"
                  // confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                >
                  <textarea
                    type="text"
                    class="form-control mb-0 m-h-100"
                    maxLength={1000}
                    value={notes}
                    onKeyDown={(e) => {
                      // _handleKeyDownNote(e);
                    }}
                    onChange={(e) => {
                      setnotes(e.target.value);
                    }}
                    aria-describedby="emailHelp"
                    placeholder="Start writing to leave note"
                  />{" "}
                  {notesErr ? (
                    <div className="field-required mt-3">
                      <span>{notesErrMsg}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </Modal>
                {notesArr.map((item) => {
                  return (
                    <>
                      <div class="note-posted mt-4">
                        <div class="d-flex justify-content-between mb-2">
                          <div class="note-by d-flex">
                            <div class="note-btn">
                              <a href="#">note</a>
                            </div>
                            <div class="by ms-3">
                              <span class="grey-text text-capitalize">
                                by {item.createBy}
                              </span>
                            </div>
                          </div>
                          <span class="grey-text posted-date text-capitalize">
                            {formateDateIntoDatemonthTime(item.createdOn)}
                          </span>
                        </div>
                        <p class="mb-0 mt-3">{item.notes}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>

        <div class="col-md-3 col-sm-12 pe-0">
          <div class="view-ticket-filters-box">
            <div class="create-btn">
              {(ticketStatus === 1 && assignId ===
                localStorage.getItem("userId")) || (ticketStatus === 4 && assignId ===
                  localStorage.getItem("userId")) ? (
                <>
                  <div class="d-inline-block d-flex align-items-center justify-content-center mb-4">
                    <button
                      class="btn btn-primary w-100 py-3"
                      onClick={() => {
                        pickupData("5");
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                      <span class="ms-2 text-capitalize"> pickup ticket</span>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {assignId ===
                localStorage.getItem("userId") && ticketStatus === 5 ? (
                <>
                  <div class="d-inline-block d-flex align-items-center justify-content-center mb-4">
                    <button
                      class="btn btn-success w-100 py-3"
                      onClick={() => {
                        updateTicketSts("2");
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                      <span class="ms-2 text-capitalize"> close ticket</span>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {assignId ===
                localStorage.getItem("userId") &&
                createdbyid !== localStorage.getItem("userId") &&
                ticketStatus === 2 ? (
                <>
                  <div class=" d-inline-block d-flex align-items-center justify-content-center mb-4 awaiting-box">
                    <div className="btn-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-successs text-start">
                          <span> Awaiting for Approval </span>
                          <p className="text-complete mb-0">
                            Submitted on{" "}
                            {formateDateIntoDatemonthTime(
                              updatestatusdate
                            )}
                          </p>
                        </div>
                        <div className="btn-successs">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                class="fa fa-ellipsis-v text-secondary"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  updateTicketSts(4);
                                }}
                              >
                                Reopen
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {createdbyid ===
                localStorage.getItem("userId") && ticketStatus === 2 ? (
                <>
                  <div class="portlet w-100 ticket-approval-btn">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="ticket-approval-text">
                        <span className="text-dark fw-bold">
                          Ticket Approval
                        </span>
                        <p className="text-secondary mt-2 mb-0">
                          on{" "}
                          {formateDateIntoDatemonth(
                            updatestatusdate
                          )}
                        </p>
                      </div>
                      <div className="ticket-approval">
                        <i
                          class="fa fa-check me-2 border border-success bg-success text-white"
                          onClick={() => {
                            updateTicketSts(3);
                          }}
                          aria-hidden="true"
                        ></i>
                        <i
                          class="fa fa-times border border-danger bg-danger text-white"
                          onClick={() => {
                            updateTicketSts(4);
                          }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {(localStorage.getItem("userId") ===
                createdbyid ||
                assignId ===
                localStorage.getItem("userId")) &&
                ticketStatus === 3 ? (
                <>
                  <div class=" d-inline-block d-flex align-items-center justify-content-center mb-4">
                    <button className="btn btn-outline-success w-100 ticket-approval-btn bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-successs text-start">
                          <span className="text-success fw-bold">
                            Tickets Closed
                          </span>
                          <p className="text-complete mt-2 mb-0">
                            Closed on{" "}
                            {formateDateIntoDatemonthTime(
                              updatestatusdate
                            )}
                          </p>
                        </div>
                        <div className="btn-successs">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                class="fa fa-ellipsis-v text-secondary"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  updateTicketSts(4);
                                }}
                              >
                                Reopen
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div class="portlet client-detail-box">
              <div class="title">
                <h5 class="text-capitalize mb-0">client details</h5>
              </div>
              <div class="details ">
                <div class="texts">
                  <span>client</span>
                  <strong>{clientname}</strong>
                </div>
                <div class="texts my-3">
                  <span>created by</span>
                  <strong>{createdBy}</strong>
                </div>
                <div class="texts">
                  <span>points of contact</span>
                  <strong>{contact}</strong>
                </div>
              </div>
            </div>
            <div class="portlet ticket-detail-box">
              <div class="title">
                <h5 class="text-capitalize mb-0">ticket details</h5>
              </div>
              <div class="details">
                {tickettypeid === 1 && parentid ? (<>
                  <div class="texts mb-4">
                    <span>units</span>
                    <input className="form-control mt-2" value={units} disabled></input>
                  </div></>) : (<></>)}

                <div class="texts">
                  <span class="mb-2 d-block">assigned</span>
                  <ReactSelect
                    isMulti={false}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    options={memberlist}
                    isDisabled={ticketStatus === 2 || ticketStatus === 5 ? true : false}
                    // value={assignId}
                    value={memberlist?.filter(x => {
                      if (x.id === assignId) {
                        return x;
                      }
                    })}
                    onChange={(a) => {
                      let data = {
                        TicketId: location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'),
                        MemberId: a.value,
                        AssignedOn: mmDDyyyyFormateDateSet(new Date()),
                        Status: 1,
                        CreatedBy: localStorage.getItem("userId"),
                      };
                      getAssignName(data);
                    }}
                  >
                  </ReactSelect>
                </div>
                <div class="texts my-4">
                  <span>priority</span>
                  <select
                    class={`form-select mt-2 ${prioritylist(
                      priorityid
                    ).toLowerCase()}-selected`}
                    value={prirotyid}
                    onChange={(i) => {
                      let getdata = {
                        ticketid: location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'),
                        priorityList: i.target.value,
                      };
                      // prooritystatuschange(getdata);
                      getpriorityName(getdata)
                      // changeticketpriority(getdata)
                    }}
                  >
                    {ticketpriority?.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </div>
                <div class="texts">
                  <span>ETA</span>
                  <div className="position-relative">
                    <input
                      type="date"
                      name=""
                      min={mmDDyyyyFormateDateSet(new Date())}
                      class="ticket-date w-100 mt-2"
                      value={ETA}
                      onChange={(text) => {
                        // setETA(text.target.value);
                        let getdata = {
                          ticketid: location?.state?.item?.id ? location?.state?.item?.id : localStorage.getItem('ticketState'),
                          etaDate: text.target.value,
                        };
                        changeETADate(getdata, text.target.value);
                        formValidation();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ticketsview;
