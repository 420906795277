import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import {
  addClientUser,
  clientsUserByClientId,
  getMemberByOrgId,
} from "../../utility/Constant";
import { useNavigate } from "react-router-dom";

const UserAccess = () => {
  const [userroles, setuserroles] = useState([]);
  const [userrolescol, setuserrolescol] = useState([]);
  useEffect(() => {
    var data = [
      {
        title: "Name",
        dataIndex: "role",
        key: "role",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.role, b.role),
          multiple: 3,
        },
      },
    ];
    setuserrolescol(data);

    getClientUserr();
  }, []);

  function getClientUserr() {
    APIManager.getAPI(
      clientsUserByClientId + localStorage.getItem("clientGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            id: item.memberId,
            key: item.memberId,
            role: item.memberName,
          });
        });
        setuserroles(list);
        getMemberlist(list);
      }
    });
  }

  const [memberList, setMemberList] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  function getMemberlist(listData) {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          let isExist = false;
          listData.map((i) => {
            if (i.id == item.id) {
              isExist = true;
            }
          });
          if (!isExist) {
            list.push(obj);
          }
        });
        setMemberList(list);
        setmemberlistSelected(list[0]?.id);
      }
    });
  }

  function setmember(e) {
    var c = e.target.value;
    setmemberlistSelected(c);
  }

  function addClientUserr() {
    APIManager.postAPI(
      addClientUser,
      {
        clientId: localStorage.getItem("clientGId"),
        memberId: memberlistSelected,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getClientUserr();
      }
    });
  }
  const navigate = useNavigate();
  return (
    <>
      <div className="row common-mb-24 align-items-center mt-3 mb-3">
        <div className="col-md-12 d-flex mb-3">
          <i
            class="fa fa-chevron-circle-left"
            style={{ fontSize: 20 + "px", color: "#2196f3" }}
            onClick={() => {
              navigate(-1);
            }}
          ></i>
        </div>
      </div>

      <div className="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Assigned Users</h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12 access text-right">
            <select
              style={{
                borderRight: 0,
                borderRadius: 0,
                padding: 9 + "px",
                width: 30 + "%",
              }}
              value={memberlistSelected}
              onChange={setmember}
            >
              {memberList.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            <button
              class="btn btn-primary"
              style={{
                borderLeft: 0,
                borderRadius: 0,
                padding: 9 + "px",
                width: 50 + "px",
              }}
              onClick={() => {
                if (memberlistSelected) {
                  addClientUserr();
                }
              }}
            >
              Add
            </button>
          </div>

          <Table
            className="table table-bordered table-hover mt-3"
            id="datatable-2"
            columns={userrolescol}
            dataSource={userroles}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UserAccess;
