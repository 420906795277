/** @jsxImportSource @emotion/react */
import { Drawer } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import TopNavBarCustom from "./../components/TopNavBar";
import APIManager from "./../services/APIManager";
import { getModule } from "./../utility/Constant";
import {
  getCheckPermissionByDisplayName,
  moduleIcons,
  moduleName,
  routerLinks,
} from "./../utility/Globals";
import RouteWithSubRoutes from "./../utils/RouteWithSubRoutes";
import CreateNewTicket from "./ticket/CreateNewTicket";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/shared/navigation/sidebar"
import { sidebarData } from "../utils/sidebar";
import * as styles from "../styles/main"
import { getLocal, setLocal } from "../utility/helpers";

axios.interceptors.request.use(
  function (response) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.remove("d-none");
    return response;
  },
  function (error) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return response;
  },
  function (error) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return error;
  }
);

const Main = ({ routes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ticketsMenuList, setTicketsMenuList] = useState([
    {
      name: "Ticket Dashboard",
      link: routerLinks["Ticket Dashboard"]
        ? routerLinks["Ticket Dashboard"]
        : "",
      iconClass: moduleIcons["Ticket Dashboard"],
    }, {
      name: "Team Evaluation",
      link: routerLinks["Team Evaluation"]
        ? routerLinks["Team Evaluation"]
        : "",
      iconClass: moduleIcons["Team Evaluation"],
    },
    {
      name: "My Tickets",
      link: routerLinks["My Tickets"]
        ? routerLinks["My Tickets"]
        : "",
      iconClass: moduleIcons["My Tickets"],
    }, {
      name: "Team Tickets",
      link: routerLinks["Team Tickets"]
        ? routerLinks["Team Tickets"]
        : "",
      iconClass: moduleIcons["Team Tickets"],
    }, {
      name: "Create New Ticket",
      link: routerLinks["Create New Ticket"]
        ? routerLinks["Create New Ticket"]
        : "",
      iconClass: moduleIcons["Create New Ticket"],
    },
  ]);

  useEffect(() => {
    let pathname = location.pathname.split('/');
    pathname = "/" + pathname[1] + "/" + pathname[2];
    setview(pathname == '/main/module-expand-seo-audit-view');
  }, [location])

  useEffect(() => {
    if (!localStorage.getItem("loggedInUserId")) {
      navigate("/login");
    }
    if (window.innerWidth < 1025) {
      document.querySelector(".bar-icon").classList.remove("d-none");
      document
        .querySelector(".header-holder")
        .classList.remove("header-holder-desktop");
      document
        .querySelector(".header-holder")
        .classList.add("header-holder-mobile");
    } else {
      document.querySelector(".bar-icon").classList.add("d-none");
      document
        .querySelector(".header-holder")
        .classList.add("header-holder-desktop");
      document
        .querySelector(".header-holder")
        .classList.remove("header-holder-mobile");
    }
    getModulesData();
  }, []);
  const [view, setview] = useState(false);

  const [createTicketsviewopen, setcreateTicketsviewopen] = useState(false);

  //Sidebar States and functions
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const [sidebarList,setSideBarList] = useState({data:[], active: 0});
  const [isMobileSibeBarOpen,setMobileSibeBarOpen] = useState(false);
  const activeSitebarOption = getLocal("activeSitebarOption")
  const   onSidebarItemClick = (index) => {
    setSideBarList((prev) => {
      return {...prev, active: index}
    })
    setLocal("activeSitebarOption", index)
  }

  const closeSideBar = () => {
    setMobileSibeBarOpen(false)
  }

  const openSideBar = () => {
    setIsCollapsed(false)
    setIsLocked(true)
    setMobileSibeBarOpen(true)
  }

  const ticketviewclose = () => {
    setcreateTicketsviewopen(false);
  }

  function getModulesData() {
    APIManager.getAPI(getModule).then((response) => {
      const a = response.data;
      if (response && response.status == 200) {
        const list = [];
        const researchList = [];
        const healthList = [];
        response.data.map((data) => {
          let obj = {
            name: data.name,
            link: routerLinks[data.name]
              ? routerLinks[data.name] + (data.name === "Rankings" ? "" : data.id)
              : "",
            iconClass: moduleIcons[data.name],
          };

          if (data.active) {
            var module = obj.name;
            if (obj.name == "Rankings V2") {
              module = "Rankings";
            }
            if (getCheckPermissionByDisplayName(module)) {
              if (
                obj.name == "Keyword Search" ||
                obj.name == "Organic Research" ||
                obj.name == "Backlink Profile" ||
                obj.name == "Content Word Count on a Page" ||
                obj.name == "SERP Volatility" ||
                obj.name == "Google Trends"
              ) {

                if (
                  obj.name == "Keyword Search" ||
                  obj.name == "Organic Research" ||
                  obj.name == "Backlink Profile") {
                  let researchObj = {
                    name: data.name,
                    link: routerLinks["Research " + data.name]
                      ? routerLinks["Research " + data.name] + (data.name === "Rankings" ? "" : data.id)
                      : "",
                    iconClass: moduleIcons[data.name],
                  };
                  researchList.push(researchObj);
                }
                else {
                  researchList.push(obj);
                }
              }
              obj = {
                name: data.name,
                link: routerLinks[data.name]
                  ? routerLinks[data.name] + (data.name === "Rankings" ? "" : data.id)
                  : "",
                iconClass: moduleIcons[data.name],
              };
              if (
                // obj.name == "Rankings V2" ||
                obj.name == "Traffic" ||
                obj.name == "Impressions & Clicks" ||
                obj.name == "Click Share" ||
                obj.name == "Return on Investment"
              ) {
                list.push(obj);
              }
              else if (
                obj.name == "Audit Findings" ||
                obj.name == "Page Speed" ||
                obj.name == "Site Uptime Monitor" ||
                obj.name == "Website Authority" ||
                obj.name == "Backlink Profile"
              ) {
                healthList.push(obj);
              }
            }
          }
        });
        const a = list;
        const b = researchList;
        const c = healthList;
        const mainList = []
        sidebarData.data.map((item,index)=>{
          if(item.title === "Dashboard") {
            mainList.push(item);
          }else if(item.title === "SEO Performance") {
            if(list.length > 0){
              mainList.push({...item, subMenu : list})
            }
          } else if(item.title === "SEO Health") {
            if(healthList.length > 0){
              mainList.push({...item, subMenu : healthList})
            }
          } else if(item.title === "Research") {
            if(researchList.length > 0){
              mainList.push({...item, subMenu : researchList})
            }
          } else if(item.title === "Configuration") {
            if(getLocal("userRole") !== "Customer"){
              mainList.push(item);
            }
          } else if(item.title === "Rankings"){
            if(getCheckPermissionByDisplayName("Rankings")){
              mainList.push(item);
            }
          } else {
            if(getCheckPermissionByDisplayName(item.title)){
              mainList.push(item);
            }
          }
        })
        const temp = sidebarList.data;
        temp.map((item,index) => {
          if(item.title === "SEO Performance") {
            temp[index].subMenu = list
          } else if (item.title === "SEO Health"){
            temp[index].subMenu = healthList
          } else if (item.title === "Research") {
            temp[index].subMenu = researchList
          }
        })
        const d= temp; 
        if(!!activeSitebarOption){
          setSideBarList((prev)=>{
            return {active: activeSitebarOption, data : mainList}
          });
        } else {
          setSideBarList((prev)=>{
            return {active: 0, data : mainList}
          });
        }
      }
    });
  }

  function clodeSideNav() {
    document.querySelector("body").classList.remove("aside-mobile-maximized");
    document.querySelector("body").classList.add("aside-mobile-minimized");
    document.getElementById("aside-backdrop")?.remove();
  }

  return (
    <>
      <div className="home asidebar_main">
      <Sidebar {...{sidebarList, setSideBarList, isCollapsed, setIsCollapsed, isLocked, setIsLocked, isMobileSibeBarOpen}} activeTab={activeSitebarOption} onTabClick={onSidebarItemClick} closeSideBar={closeSideBar}/>
        <div className="holder">
          <div className="wrapper" css={styles.wrapper(isCollapsed,isLocked)}>
            <div className={`content ${location.pathname == '/main/configuration' ? 'height-hiddenn overflow-hiddenn' : ''}`} css={{paddingTop:"6.28571rem"}} >
              <div className="container-fluid">
                <Routes>
                  {routes.map((route, i) => (
                    <Route path={route.path} element={
                      <route.component routes={route.children} isCollapsed={isCollapsed} isLocked={isLocked}/>} key={i} />
                  ))}
                </Routes>
              </div>
            </div>
            <TopNavBarCustom
              view={view}
              routing={ticketsMenuList} openSideBar={openSideBar} isCollapsed={isCollapsed} isLocked={isLocked}/>
          </div>
        </div>
      </div>
      <Drawer
        className="search-team-member-modal filter-closed"
        placement="right"
        onClose={ticketviewclose}
        open={createTicketsviewopen}>
        <CreateNewTicket isModel={true} closeModel={() => {
          ticketviewclose()
        }} />
      </Drawer >
    </>
  );
};

export default Main;
