/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react";
import Notice from "@semcore/ui/notice";
import { Text } from '@semcore/ui/typography';

const CustomNotice = ({
  hidden = false,
  leftIcon,
  title,
  addedTitleStyles,
  description,
  addedDescriptionStyles,
  onClose,
  theme,
  addedStyle,
}) => {
  return (
    <Notice hidden={hidden} theme={theme} css={addedStyle && addedStyle}>
      <Notice.Label>
        <FeatherIcon icon={leftIcon} css={styles.icon} />
      </Notice.Label>
      <Notice.Content>
        <Text tag="div" css={{ ...styles.title, ...addedTitleStyles }}>
          {title}
        </Text>
        <div css={{ ...styles.description, ...addedDescriptionStyles }}>
          {description}
        </div>
      </Notice.Content>
      <Notice.CloseIcon onClick={onClose} />
    </Notice>
  );
};

export default CustomNotice;
