import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import {
  calanderDate,
  getCheckPermission,
  getMonth,
  mmDDyyyyFormateDateSet,
  TeamEvtoMonthName,
  zeroPad,
} from "../../utility/Globals";
import { selectAllList } from "../../utility/Globals";
import { Button, Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import Chart from "react-apexcharts";
import APIManager from "../../services/APIManager";
import { DateRangePicker } from "react-date-range";
import { default as ReactSelect } from "react-select";
import {
  createteamEvaluationgraph,
  getAssignMemberByRole,
  getdepartment,
  getdesignationlistforcustomer,
  getevaltiontabledata,
  getMissedETA,
  getPristine,
  getProjectByMember,
  getticketcompleted,
  getTicketsRejected,
} from "../../utility/Constant";

import { Drawer } from "antd";
import CommonTable from "./components/CommonTable";
import NotFound from "../../NotFound";
function TeamEvaluation() {
  const drpenm = [
    { value: "0", label: "Daily" },
    { value: "1", label: "Weekly" },
    { value: "2", label: "Monthly" },
    { value: "3", label: "Yearly" },
  ];

  const [chartView, setChartView] = useState(0);
  const [notFound, setNotFound] = useState(true);
  const [options, setObject] = useState({});
  const [series, setSeries] = useState([]);
  const [isActive, setIsActive] = useState();
  const [isActive1, setIsActive1] = useState();
  const [isActive2, setIsActive2] = useState();
  const [headValues1, setHeadValues] = useState([
    new DateObject().subtract(30, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);
  const showDrawer = () => {
    setOpens(true);
  };

  const onClose = () => {
    setOpens(false);
    setSelectedMember(selectedMember);
  };
  const [values, setValues] = useState([
    new DateObject().subtract(30, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [head1, setHead1] = useState(headValues1[0].format());
  const [head2, setHead2] = useState(headValues1[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  const [clientlist, setclientlist] = useState([]);
  const [clientseleted, setclientseleted] = useState('00000000-0000-0000-0000-000000000000');
  const [oldclientList, setoldclientList] = useState([]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  const viewonclose = () => {
    setviewopen(false);
  };
  const [viewopen, setviewopen] = useState(false);
  const viewshowDrawer = () => {
    setviewopen(true);
  };
  const [Drawertitle, setDrawertitle] = useState("");
  const [Drawerlist, setDrawerlist] = useState("");
  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: 'none',
      height: 38,
      fontSize: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121"
    }),
  };
  const customStylesActive = {
    control: base => ({
      ...base,
      border: "1px solid #0691ef",
      // This line disable the blue border
      boxShadow: 'none',
      height: 38,
      fontSize: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#0691ef"
    }),
  };
  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #757575",
      // This line disable the blue border
      boxShadow: 'none',
      height: 42,
      minHeight: 42,
      fontSize: '1.2rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '42px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#757575"
    }),
  };

  useEffect(() => {
    setNotFound(getCheckPermission("tickets", "reports"));
    localStorage.setItem("isCompareVar", false);

    getDepartmentData();
    if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
      document
        .getElementsByClassName("rdrDateDisplayWrapper")[0]
        .children[1].classList.add("display-none");

  }, []);

  const titlecolumns = [
    {
      title: "Team Members",
      dataIndex: "teammembers",
      key: "teammembers",
    },
    {
      title: "",
      dataIndex: "count",
      key: "count",
    },
  ];

  const [TicketsClosed, setCompletedOnETA] = useState("");
  const [completedetatable, setCompletedOnETAtable] = useState("");
  const [rejectedtable, setrejected] = useState("");
  const [Pristine, setPristine] = useState("");
  const [MissedETA, setMissedETA] = useState("");
  const [selectedMember, setSelectedMember] = useState("00000000-0000-0000-0000-000000000000");
  const [userrolesList, setuserrolesList] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("00000000-0000-0000-0000-000000000000");
  const [departmentlist, setDepartmentlist] = useState([]);
  const [memberList, setmemberlist] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(localStorage.getItem('userDatadepartment'));
  const [checkedclose, setcheckedclose] = useState(false);
  const [checkedpristen, setcheckedpristen] = useState(false);
  const [checkedcompleted, setcheckedcompleted] = useState(true);
  const [checkedticketrework, setcheckedticketrework] = useState(false);
  const [checkedmissedeta, setcheckedmissedeta] = useState(false);
  const [updatedMember, setupdatedMember] = useState("");
  const [updateDepartment, setupdateDepartment] = useState("");
  const [updatedDesignation, setupdatedDesignation] = useState("");
  const [completedEtaLine, setcompletedEtaLine] = useState(null);
  const [pristineLine, setpristineLine] = useState(null);
  const [closedLine, setclosedLine] = useState(null);
  const [ticketsForReworklLine, setticketsForReworklLine] = useState(null);
  const [missedEtaLine, setmissedEtaLine] = useState(null);
  const [departmentName, setdepartment] = useState("");
  const [designationname, setdesignation] = useState("All Designations");
  const [missedETA, setmissedETA] = useState(0);
  const [closed, setclosed] = useState(0);
  const [completedOnETA, setcompletedOnETA] = useState(0);
  const [ticketsForRework, setticketsForRework] = useState(0);
  const [pristine, setpristine] = useState(0);
  const [memberName, setmemberName] = useState("All Members");

  function selecthandleChange(membarId) {
    memberList.map((i) => {
      if (i.id === membarId) {
        setmemberName(i.role);
      }
    });
  }

  function selecthandleChangedepartment(departmentId) {
    departmentlist.map((i) => {
      if (i.id === departmentId) {
        setdepartment(i.role);
      }
    });
  }

  function selecthandleChangedesignation(designationId) {
    userrolesList.map((i) => {
      if (i.id == designationId) {
        setdesignation(i.role);
      }
    });
  }

  function getDepartmentData() {
    APIManager.getAPI(
      getdepartment + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.map((item) => {
          if (localStorage.getItem("userDatadepartmentName") === "Management" || localStorage.getItem("userDatadepartment") == item.id) {
            let obj = {
              id: item.id,
              role: item.name,
              key: item.id,
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          }
        });
        setDepartmentlist(list);

        let departmentId;
        let departmentrole;
        list?.map((x) => {
          if (x.id === localStorage.getItem("userDatadepartment")) {
            departmentId = x.id;
            departmentrole = x.role;
          }
        });
        if (!departmentId) {
          departmentId = list[0].id;
          departmentrole = list[0].role;
        }
        setdepartment(departmentName ? departmentName : departmentrole);
        setSelectedDepartment(
          selectedDepartment ? selectedDepartment : departmentId
        );
        getRolesData(
          selectedDepartment
            ? selectedDepartment
            : (departmentId
              ? departmentId
              : list[0].id), true
        );
      }
    });
  }

  function getRolesData(departmentId, isAPICall) {
    APIManager.getAPI(getdesignationlistforcustomer + '?deptId=' + departmentId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let userRoleLevel;
        const list1 = [];
        list1.push({
          id: "00000000-0000-0000-0000-000000000000",
          role: "All Designations",
          key: "00000000-0000-0000-0000-000000000000",
          value: "00000000-0000-0000-0000-000000000000",
          label: "All Designations",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            role: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
            level: item.level
          };
          list1.push(obj);
          if (localStorage.getItem("userRole") === item.name) {
            userRoleLevel = item.level ?? 0;
          }
        });
        if (departmentId === localStorage.getItem("userDatadepartment")) {
          list1?.map((item) => {
            if (!item.level || item.level >= userRoleLevel) {
              let obj = {
                id: item.id,
                role: item.role,
                key: item.key,
                value: item.value,
                label: item.label,
                level: item.level
              };
              list.push(obj);
            }
          });
        }
        else {
          list1?.map((item) => {
            let obj = {
              id: item.id,
              role: item.role,
              key: item.key,
              value: item.value,
              label: item.label,
              level: item.level
            };
            list.push(obj);
          });
        }
        let designationId;
        list.map((x) => {
          if (x.role === localStorage.getItem("userRole")) {
            designationId = x.id;
          }
        });
        designationId = designationId ? designationId : "00000000-0000-0000-0000-000000000000";
        setuserrolesList(list);
        getMemberlist("00000000-0000-0000-0000-000000000000", departmentId, isAPICall);
      }
    });
  }

  function getMemberlist(designationId, departmentId, isAPICall) {
    APIManager.getAPI(
      getAssignMemberByRole + '?roleId=' + designationId + '&deptId=' + departmentId
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let obj = {
          id: "00000000-0000-0000-0000-000000000000",
          role: "All Members",
          key: "00000000-0000-0000-0000-000000000000",
          value: "00000000-0000-0000-0000-000000000000",
          label: "All Members",
        };
        list.push(obj);
        response.data.map((item) => {
          list.push({
            id: item.id,
            role: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          });
        });
        setmemberlist(list);
        setSelectedMember(list[0].id);
        setupdatedMember(list[0].id);       
        if (isAPICall) {
          let rowData = {
            departmentId: departmentId,
            designationId: designationId,
            memberId: localStorage.getItem("userId"),
          };
          getDashboardProject(rowData, list[0].id);
        }
      }
    });
  }

  function getDashboardProject(rowData, memberId) {
    if (localStorage.getItem('userRole') === 'Customer' && rowData.departmentId) {
      delete rowData.departmentId;
    }
    if (rowData && rowData.memberId) {
      if (rowData.designationId === '00000000-0000-0000-0000-000000000000') {
        rowData['memberId'] = '00000000-0000-0000-0000-000000000000';
      }
      else {
        rowData['memberId'] = localStorage.getItem("selectedMember");
      }
    }
    APIManager.postAPI(getProjectByMember, rowData, null, true).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let client = null;
        list.push({
          key: '00000000-0000-0000-0000-000000000000',
          id: '00000000-0000-0000-0000-000000000000',
          value: '00000000-0000-0000-0000-000000000000',
          label: 'All',
        });
        response.data.map((item) => {
          let obj = {
            key: item.id,
            id: item.id,
            value: item.id,
            label: item.projectCode,
          };
          list.push(obj);
        });
        if (!client) {
          client = list[0]
        }
        list.push({
          key: '11111111-1111-1111-1111-111111111111',
          id: '11111111-1111-1111-1111-111111111111',
          value: '11111111-1111-1111-1111-111111111111',
          label: 'Other',
        });
        setclientlist(list);
        setclientseleted(list[0]);
        let rowData = {
          memberId: selectedMember ? selectedMember : memberId,
          // clientIds: [client?.id],
          ProjectId: client?.id,
          startdate: mmDDyyyyFormateDateSet(head1),
          endDate: mmDDyyyyFormateDateSet(head2),
        };
        getevalutionClosedtabledata(rowData);
        getTicketsCompeltedAsPerETA(rowData);
        getTicketsRejectedBy(rowData);
        getTicketsPristine(rowData);
        getTicketsPMissedETA(rowData);
        let viewVal = 0;
        let rowData1 = {
          MemberId: selectedMember ? selectedMember : memberId,
          // ClientIds: [client?.id],
          ProjectId: client?.id,
          View: viewVal,
          startdate: mmDDyyyyFormateDateSet(head1),
          endDate: mmDDyyyyFormateDateSet(head2),
        };
        getChartData(rowData1, viewVal);
      }
    });
  }

  const [state, setState] = useState({
    selection1: {
      startDate: new Date(head1),
      endDate: new Date(head2),
      key: "selection1",
    },
    selection2: {
      startDate: new Date(head1),
      endDate: new Date(head2),
      key: "selection2",
    },
  });
  const [Closedtabledata, setClosedtabledata] = useState([]);
  function getevalutionClosedtabledata(rowData) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(getevaltiontabledata, rowData, null, true).then(
      (response) => {
        setClosedtabledata([]);
        if (response?.status === 200) {
          const tlist = [];
          response.data.map((item) => {
            let object = {
              teammembers: item.assignedMember,
              count: zeroPad(item.ticketCount, 2),
            };
            tlist.push(object);
          });
          setCompletedOnETA(tlist);
          if (tlist.length) {
            setClosedtabledata([]);
            let listLength = 0;
            tlist.length > 5
              ? (listLength = 5)
              : (listLength = tlist.length);
            for (let i = 0; i < listLength; i++) {
              setClosedtabledata((Closedtabledata) => [
                ...Closedtabledata,
                tlist[i],
              ]);
            }
          } else {
            setMissedETAdata([]);
          }
        }
      }
    );
  }
  const [CompeltedAsPerETAdata, setCompeltedAsPerETAdata] = useState([]);
  function getTicketsCompeltedAsPerETA(rowData) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(getticketcompleted, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          const tlist = [];
          response.data.map((item) => {
            let object = {
              teammembers: item.assignedMember,
              count: zeroPad(item.ticketCount, 2),
            };
            tlist.push(object);
          });
          setCompletedOnETAtable(tlist);
          if (tlist.length) {
            setCompeltedAsPerETAdata([]);
            let listLength = 0;
            tlist.length > 5
              ? (listLength = 5)
              : (listLength = tlist.length);
            for (let i = 0; i < listLength; i++) {
              setCompeltedAsPerETAdata((CompeltedAsPerETAdata) => [
                ...CompeltedAsPerETAdata,
                tlist[i],
              ]);
            }
          } else {
            setCompeltedAsPerETAdata([]);
          }
        }
      }
    );
  }
  const [RejectedBydata, setRejectedBydata] = useState([]);
  function getTicketsRejectedBy(rowData) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(getTicketsRejected, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          const tlist = [];
          response.data.map((item) => {
            let object = {
              teammembers: item.assignedMember,
              count: zeroPad(item.ticketCount, 2),
            };
            tlist.push(object);
          });
          setrejected(tlist);
          if (tlist.length) {
            setRejectedBydata([]);
            let listLength = 0;
            tlist.length > 5
              ? (listLength = 5)
              : (listLength = tlist.length);
            for (let i = 0; i < listLength; i++) {
              setRejectedBydata((RejectedBydata) => [
                ...RejectedBydata,
                tlist[i],
              ]);
            }
          } else {
            setRejectedBydata([]);
          }
        }
      }
    );
  }
  const [PristineAdata, setPristineAdata] = useState([]);
  function getTicketsPristine(rowData) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(getPristine, rowData, null, true).then((response) => {
      if (response?.status === 200) {
        const tlist = [];
        response.data.map((item) => {
          let object = {
            teammembers: item.assignedMember,
            count: zeroPad(item.ticketCount, 2),
          };
          tlist.push(object);
        });
        setPristine(tlist);
        if (tlist.length) {
          setPristineAdata([]);
          let listLength = 0;
          tlist.length > 5
            ? (listLength = 5)
            : (listLength = tlist.length);
          for (let i = 0; i < listLength; i++) {
            setPristineAdata((PristineAdata) => [
              ...PristineAdata,
              tlist[i],
            ]);
          }
        } else {
          setPristineAdata([]);
        }
      }
    });
  }
  const [MissedETAdata, setMissedETAdata] = useState([]);
  function getTicketsPMissedETA(rowData) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(getMissedETA, rowData, null, true).then((response) => {
      if (response?.status === 200) {
        const tlist = [];
        response.data.map((item) => {
          let object = {
            teammembers: item.assignedMember,
            count: zeroPad(item.ticketCount, 2),
          };
          tlist.push(object);
        });
        setMissedETA(tlist);
        if (tlist.length) {
          setMissedETAdata([]);
          let listLength = 0;
          tlist.length > 5
            ? (listLength = 5)
            : (listLength = tlist.length);
          const missedArray = [];
          for (let i = 0; i < listLength; i++) {
            missedArray.push(tlist[i]);
            setTimeout(() => {
              setMissedETAdata(missedArray);
            }, 2000);
          }
        } else {
          setMissedETAdata([]);
        }
      }
    });
  }

  function getChartData(rowData, viewVal) {
    if (selectedDepartment && selectedDesignation) {
      rowData['DepartmentId'] = selectedDepartment;
      rowData['DesignationId'] = selectedDesignation;
    }
    APIManager.postAPI(createteamEvaluationgraph, rowData, null, true).then(
      (response) => {
        if (response?.status === 200) {
          let totmissedETA = 0;
          let totclosed = 0;
          let totcompletedOnETA = 0;
          let totticketsForRework = 0;
          let totpristine = 0;
          response.data.map((item) => {
            totmissedETA += item.missedETA;
            totclosed += item.closed;
            totcompletedOnETA += item.completedOnETA;
            totticketsForRework += item.ticketsForRework;
            totpristine += item.pristine;
          });

          setmissedETA(totmissedETA);
          setclosed(totclosed);
          setcompletedOnETA(totcompletedOnETA);
          setticketsForRework(totticketsForRework);
          setpristine(totpristine);

          let dataForGraph = [];
          let graphData2 = [];
          let graphData3 = [];
          let graphData4 = [];
          let graphData5 = [];
          let graphData6 = [];
          let graphSeries = [];
          let optionData;
          const startDate = rowData.startDate;
          const endDate = rowData.endDate;
          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);

          for (let i = 0; i <= diffDays; i++) {

            graphSeries.push(
              getMonth(new Date(new Date(startDate).getTime() + i * 864e5))
            );
          }
          let value = viewVal.toString()
          let titleText = "";
          if(value === "0"){
            titleText = "Days";
          } else if (value === "1") {
            titleText = "Weeks";
          } else if (value === "2") {
            titleText = "Months";
          } else if (value === "3") {
            titleText = "Year";
          }
          graphSeries = [];
          graphData2 = [];
          graphData3 = [];
          graphData4 = [];
          graphData5 = [];
          graphData6 = [];
          response.data.map((item) => {            
            value === "0" ? graphSeries.push(item.createdDate) : graphSeries.push(item.number);
            graphData2.push(item.missedETA);
            graphData3.push(item.closed);
            graphData4.push(item.completedOnETA);
            graphData5.push(item.ticketsForRework);
            graphData6.push(item.pristine);
          });

          optionData = {
            chart: {
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  customIcons: [],
                },
              },
            },
            stroke: {
              curve: "smooth",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              categories: graphSeries,
              labels: {
                formatter: function (val) {
                  let text =   TeamEvtoMonthName(val, titleText);
                  return text;
                },
              },
            },
            yaxis: {
              logBase: 2,
              tickAmount: 5,
              min: 0,
            },
            legend: {
              show: false
            }
          };

          setObject(optionData);
          let completed = {
            name: "Completed as per ETA",
            data: graphData4,
            color: "#54baa3",
          };
          setcompletedEtaLine(completed);
          if (checkedcompleted) {
            dataForGraph.push(completed);
          }
          let pristen = {
            name: "Pristine",
            data: graphData6,
            color: "#9274e5",
          };
          setpristineLine(pristen);
          if (checkedpristen) {
            dataForGraph.push(pristen);
          }
          let closed = {
            name: "Tickets Closed",
            data: graphData3,
            color: "#2d8cae",
          };
          setclosedLine(closed);
          if (checkedclose) {
            dataForGraph.push(closed);
          }
          let ticketRework = {
            name: "Tickets For Rework",
            data: graphData5,
            color: "#f6a03c",
          };
          setticketsForReworklLine(ticketRework);
          if (checkedticketrework) {
            dataForGraph.push(ticketRework);
          }
          let missedEta = {
            name: "Missed ETA's",
            data: graphData2,
            color: "#e24f67",
          };
          setmissedEtaLine(missedEta);
          if (checkedmissedeta) {
            dataForGraph.push(missedEta);
          }
          setSeries(dataForGraph);
        }
      }
    );
  }

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          <div class="row">
            <div class="col-sm-12 p-0">
              <div class="top-title d-flex ticket-bredcrumb-top">
                <ul>
                  <li>
                    <h6 class="top-tickets mb-0 text-capitalize ticket-dashboard">tickets</h6>
                  </li>
                  <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                  <li>
                    {" "}
                    <h6 class="ticket-dashboard mb-0 text-capitalize active">
                      team evaluation
                    </h6>
                  </li>
                </ul>
              </div>
              <div class="portlet ticket-bread-crumb w-100">
                <ul>
                  <li>
                    <span>
                      {departmentName
                        ? "" + departmentName
                        : "For " + localStorage.getItem("userName")}
                    </span>
                  </li>
                  <li>
                    <span>
                      {designationname
                        ? "" + designationname
                        : "For " + localStorage.getItem("userName")}
                    </span>
                  </li>
                  <li>
                    <Button
                      className="p-0 border-0 evaluation-btn me-3"
                      onClick={showDrawer}
                    >
                      {memberName}
                    </Button>
                  </li>
                  <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                </ul>
              </div>
              <div className="portlet">
                <Drawer
                  title="Search Team Member"
                  className="search-team-member-modal evaluation-modal p-0"
                  placement="right"
                  onClose={onClose}
                  open={opens}
                >
                  <div className="portlet-body pt-0 p-0">
                    <div class="row">
                      <div className="col-md-12">
                        <label className="form-label ">Department</label>
                        <ReactSelect
                          isMulti={false}
                          styles={customStylesSearch}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          allowSelectAll={false}
                          options={departmentlist}
                          value={departmentlist?.map(x => {
                            if (x.id === selectedDepartment) {
                              return x;
                            }
                          })}
                          onChange={(e) => {
                            setSelectedDepartment(e.value);
                            setupdateDepartment(e.value);
                            getRolesData(e.value, false);
                          }}
                        >
                        </ReactSelect>
                      </div>

                      <div className="col-md-12 my-3 py-3">
                        <label className="form-label ">Designation</label>
                        <ReactSelect
                          isMulti={false}
                          styles={customStylesSearch}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          allowSelectAll={false}
                          options={userrolesList}
                          value={userrolesList?.map(x => {
                            if (x.id === selectedDesignation) {
                              return x;
                            }
                          })}
                          onChange={(e) => {
                            setSelectedDesignation(e.value);
                            setupdatedDesignation(e.value);
                            var desigId = e.value;
                            getMemberlist(desigId, selectedDepartment, false);
                          }}
                        >
                        </ReactSelect>

                      </div>

                      <div className="col-md-12">
                        <label className="form-label ">Member</label>
                        <ReactSelect
                          isMulti={false}
                          styles={customStylesSearch}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          allowSelectAll={false}
                          options={memberList}
                          value={memberList?.map(x => {
                            if (x.id === selectedMember) {
                              return x;
                            }
                          })}
                          onChange={(e) => {
                            setSelectedMember(e.value);
                            setupdatedMember(e.value);
                            localStorage.setItem(
                              "selectedMemberEv",
                              e.value
                            );
                          }}
                        >
                        </ReactSelect>
                      </div>
                    </div>
                  </div>
                  <div class="portlet-footer portlet-footer-bordered pt-3 pb-0">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary w-100"
                        type="primary"
                        onClick={() => {
                          onClose();
                          selecthandleChange(updatedMember);
                          selecthandleChangedepartment(updateDepartment);
                          selecthandleChangedesignation(updatedDesignation);
                          let rowData = {
                            departmentId: updateDepartment,
                            designationId: updatedDesignation,
                            memberId: localStorage.getItem("userId"),
                          };
                          getDashboardProject(rowData);                         
                        }}
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <span class="ms-2">Apply</span>
                      </button>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
          <div class="row">
            <div className="col-sm-12 px-0">
              <div className="portlet mb-3">
                <div class="portlet-header portlet-header-bordered p-2 h-auto p-3 eva-header">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex align-items-center justify-content-start">
                      <div className="left-box d-flex align-items-center justify-content-start">
                        <h3
                          class="portlet-title main-title text-capitalize"
                        >
                          Performance Evaluation
                        </h3>
                        <img
                          src="/images/info.png"
                          class="ticket-info ms-2"
                          title="This is the dashboard view which gives an overview of performance based on the tickets"
                        />
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 mt-md-0 mt-4 d-flex align-items-center justify-content-end">
                      <div className="right-box performance-evaluation">
                        <div className="d-flex align-items-center justify-content-end custom-flex">
                          <div className="eva-select" >
                            <ReactSelect
                              className={` ${isActive === 0
                                ? "active"
                                : ""
                                }`}
                              options={clientlist}
                              styles={isActive !== 0 ? customStyles : customStylesActive}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              value={clientseleted}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              onChange={(clientseleted) => {
                                setIsActive(0)
                                setclientseleted(
                                  clientseleted
                                );
                                selectAllList(
                                  clientseleted,
                                  oldclientList,
                                  clientlist
                                )
                                setoldclientList(clientseleted);
                                let rowData = {
                                  memberId: selectedMember,
                                  // clientIds: [clientseleted.id],
                                  ProjectId: clientseleted.id,
                                  startdate: mmDDyyyyFormateDateSet(head1),
                                  endDate: mmDDyyyyFormateDateSet(head2),
                                };
                                getevalutionClosedtabledata(rowData);
                                getTicketsCompeltedAsPerETA(rowData);
                                getTicketsRejectedBy(rowData);
                                getTicketsPristine(rowData);
                                getTicketsPMissedETA(rowData);
                                let rowData1 = {
                                  MemberId: selectedMember,
                                  // ClientIds: [clientseleted.id],
                                  ProjectId: clientseleted.id,
                                  View: chartView,
                                  startdate: mmDDyyyyFormateDateSet(head1),
                                  endDate: mmDDyyyyFormateDateSet(head2),
                                };
                                getChartData(rowData1, chartView);
                              }}
                              allowSelectAll={true}
                            />
                          </div>
                          {" "}
                          <div className="evaluation-month mx-0 mx-sm-3 my-3 my-sm-0">
                            <select
                              className={` ${isActive1 !== 1
                                ? "form-select"
                                : "form-select  active"
                                }`}
                              value={chartView}
                              aria-label="Default select example"
                              onChange={(e) => {
                                setChartView(e.target.value);
                                setIsActive1(1)
                                let rowData = {
                                  memberId: selectedMember,
                                  // clientIds: [clientseleted?.id],
                                  ProjectId: clientseleted.id,
                                  startdate: mmDDyyyyFormateDateSet(head1),
                                  endDate: mmDDyyyyFormateDateSet(head2)
                                };
                                getevalutionClosedtabledata(rowData);
                                getTicketsCompeltedAsPerETA(rowData);
                                getTicketsRejectedBy(rowData);
                                getTicketsPristine(rowData);
                                getTicketsPMissedETA(rowData);
                                let rowData1 = {
                                  MemberId: selectedMember,
                                  // ClientIds: [clientseleted.id],
                                  ProjectId: clientseleted.id,
                                  View: e.target.value,
                                  startdate: mmDDyyyyFormateDateSet(head1),
                                  endDate: mmDDyyyyFormateDateSet(head2),
                                };
                                getChartData(rowData1, e.target.value);
                              }}
                            >
                              {drpenm.map((item) => {
                                return <option value={item.value}>{item.label}</option>;
                              })}
                            </select>
                          </div>
                          <div className="evaluation-calendar-btn">
                            {" "}
                            <div ref={ref} className="cal-box">
                              <div className="add-new-btnw ">
                                <button
                                  className={` ${isActive2 !== 2
                                    ? "team-evaluation btn btn-primary"
                                    : "team-evaluation btn btn-primary  active"
                                    }`}
                                  onClick={() => setOpen(!open)}
                                >
                                  {calanderDate(head1, head2)}
                                </button>
                                {open && (
                                  <div className="evaluation-cal">
                                    <div id="example-collapse-text-calendar">
                                      <Card
                                        body
                                        className="daterange-picker-card  mt-2"
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div className="calendar-col">
                                            <DateRangePicker
                                              onChange={(item) => {
                                                let isCompareVarNew =
                                                  localStorage.getItem("isCompareVar");

                                                if (isCompareVarNew == "false") {
                                                  let selection23 = {};
                                                  if (item?.selection1) {
                                                    selection23 = {
                                                      selection2: {
                                                        startDate:
                                                          item?.selection1?.startDate,
                                                        endDate:
                                                          item?.selection1?.endDate,
                                                        key: "selection2",
                                                      },
                                                    };

                                                    setValues([
                                                      new DateObject(
                                                        item?.selection1?.startDate
                                                      ),
                                                      new DateObject(
                                                        item?.selection1?.endDate
                                                      ),
                                                    ]);
                                                    setHead1(
                                                      new DateObject(
                                                        item?.selection1?.startDate
                                                      ).format()
                                                    );
                                                    setHead2(
                                                      new DateObject(
                                                        item?.selection1?.endDate
                                                      ).format()
                                                    );
                                                  } else if (item?.selection2) {
                                                    selection23 = {
                                                      selection1: {
                                                        startDate:
                                                          item?.selection2?.startDate,
                                                        endDate:
                                                          item?.selection2?.endDate,
                                                        key: "selection1",
                                                      },
                                                    };

                                                    setValues([
                                                      new DateObject(
                                                        item?.selection2?.startDate
                                                      ),
                                                      new DateObject(
                                                        item?.selection2?.endDate
                                                      ),
                                                    ]);
                                                    setHead1(
                                                      new DateObject(
                                                        item?.selection2?.startDate
                                                      ).format()
                                                    );
                                                    setHead2(
                                                      new DateObject(
                                                        item?.selection2?.endDate
                                                      ).format()
                                                    );
                                                  }

                                                  setState({
                                                    ...state,
                                                    ...item,
                                                    ...selection23,
                                                  });
                                                }

                                              }}
                                              showSelectionPreview={true}
                                              moveRangeOnFirstSelection={false}
                                              months={1}
                                              ranges={[
                                                state?.selection1,
                                                state?.selection2,
                                              ]}
                                              direction="horizontal"
                                              ariaLabels={{
                                                dateInput: {
                                                  selection1: {
                                                    startDate:
                                                      "start date input of selction 1",
                                                    endDate:
                                                      "end date input of selction 1",
                                                  },
                                                  selection2: {
                                                    startDate:
                                                      "start date input of selction 2",
                                                    endDate:
                                                      "end date input of selction 2",
                                                  },
                                                },
                                                monthPicker: "month picker",
                                                yearPicker: "year picker",
                                                prevButton: "previous month button",
                                                nextButton: "next month button",
                                              }}

                                            />
                                          </div>
                                          <div className="ms-3">
                                            <button
                                              className={` ${isActive2 !== 2
                                                ? "btn btn-primary"
                                                : "btn btn-primary active"
                                                }`}
                                              onClick={() => {
                                                setheadvalues();
                                                setIsActive2(2)
                                                setOpen(!open);
                                                let rowData = {
                                                  memberId: selectedMember,
                                                  // clientIds: [clientseleted.id],
                                                  ProjectId: clientseleted.id,
                                                  startdate:
                                                    mmDDyyyyFormateDateSet(head1),
                                                  endDate:
                                                    mmDDyyyyFormateDateSet(head2),
                                                };
                                                getevalutionClosedtabledata(rowData);
                                                getTicketsCompeltedAsPerETA(rowData);
                                                getTicketsRejectedBy(rowData);
                                                getTicketsPristine(rowData);
                                                getTicketsPMissedETA(rowData);
                                                let rowData1 = {
                                                  MemberId: selectedMember,
                                                  // ClientIds: [clientseleted.id],
                                                  ProjectId: clientseleted.id,
                                                  View: chartView,
                                                  StartDate:
                                                    mmDDyyyyFormateDateSet(head1),
                                                  EndDate:
                                                    mmDDyyyyFormateDateSet(head2),
                                                };
                                                getChartData(rowData1, chartView);
                                              }}
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="portlet-body">
                  <div className="line-graph-input-box my-3">
                    <ul className="d-flex flex-wrap align-items-center">
                      <li
                        onClick={() => {
                          let updatedSeries = [];
                          if (!checkedcompleted) {
                            updatedSeries = [...series, completedEtaLine];
                            setSeries(updatedSeries);
                          } else {
                            const updatedSeries = series.filter((x) => x.name !== "Completed as per ETA");
                            setSeries(updatedSeries);
                          }
                          setcheckedcompleted(!checkedcompleted);
                        }}
                        className={`${checkedcompleted === true
                          ? "d-flex completed-active align-items-center"
                          : "d-flex completed align-items-center"
                          }`}
                      >
                        <div className="text d-flex align-items-center">
                          <span className="me-1">completed on ETA's</span>
                          <a
                            href="#"
                            class="text-dark"
                            data-toggle="tooltip"
                            data-html="true"
                            title="These are the tickets which have been completed as per ETA’s">
                            <svg className="comp-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="#0ba49b"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-info dark"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#757575"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-info selected"><circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8"
                                x2="12.01" y2="8"></line></svg>
                          </a>
                        </div>
                        <span className="number ms-3"> ({completedOnETA})</span>
                      </li>
                      <li
                        onClick={() => {
                          let updatedSeries = [];
                          if (!checkedpristen) {
                            updatedSeries = [...series, pristineLine];
                            setSeries(updatedSeries);
                          } else {
                            const updatedSeries = series.filter((x) => x.name !== "Pristine");
                            setSeries(updatedSeries);
                          }
                          setcheckedpristen(!checkedpristen);
                        }}
                        className={`${checkedpristen === true
                          ? "d-flex mx-3 pristine-active align-items-center"
                          : "d-flex mx-3 pristine align-items-center"
                          }`}
                      >
                        <div className="text d-flex align-items-center">
                          <span className="me-1">pristine</span>
                          <a
                            href="#"
                            class="text-dark"
                            data-toggle="tooltip"
                            data-html="true"
                            title="These are the tickets which have been approved by the manager without any rework">
                            <svg class="pris-icon feather feather-info dark" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#946cff" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"> </circle>
                              <line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" class="feather feather-info selected">
                              <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12">
                              </line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                          </a>
                        </div>
                        <span className="number ms-3"> ({pristine})</span>
                      </li>
                      <li
                        onClick={() => {
                          let updatedSeries = [];
                          if (!checkedclose) {
                            updatedSeries = [...series, closedLine];
                            setSeries(updatedSeries);
                          } else {
                            const updatedSeries = series.filter((x) => x.name !== "Tickets Closed");
                            setSeries(updatedSeries);
                          }
                          setcheckedclose(!checkedclose);
                        }}
                        className={`${checkedclose === true
                          ? "d-flex closed-active align-items-center"
                          : "d-flex closed align-items-center"
                          }`}
                      >
                        <div className="text d-flex align-items-center">
                          <span className="me-1">closed</span>
                          <a
                            href="#"
                            class="text-dark"
                            data-toggle="tooltip"
                            data-html="true"
                            title="These are the tickets which have been successfully closed">
                            <svg class="close-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                              height="24" viewBox="0 0 24 24" fill="none" stroke="#0691ef"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              className="feather feather-info dark">
                              <circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-info selected"><circle cx="12" cy="12" r="10">
                              </circle><line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                          </a>
                        </div>
                        <span className="number ms-3"> ({closed})</span>
                      </li>
                      <li
                        onClick={() => {
                          let updatedSeries = [];
                          if (!checkedticketrework) {
                            updatedSeries = [...series, ticketsForReworklLine];
                            setSeries(updatedSeries);
                          } else {
                            const updatedSeries = series.filter((x) => x.name !== "Tickets For Rework");
                            setSeries(updatedSeries);
                          }
                          setcheckedticketrework(!checkedticketrework);
                        }}
                        className={`${checkedticketrework === true
                          ? "d-flex mx-3 rework-active align-items-center"
                          : "d-flex mx-3 rework align-items-center"
                          }`}
                      >
                        <div className="text d-flex align-items-center">
                          <span className="me-1">tickets for rework</span>
                          <a
                            href="#"
                            class="text-dark"
                            data-toggle="tooltip"
                            data-html="true"
                            title="These are the tickets which are rejected and requires rework">
                            <svg className="rework-icon" xmlns="http://www.w3.org/2000/svg"
                              width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="#ff8a00" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-info dark"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-info selected"><circle cx="12" cy="12" r="10">
                              </circle><line x1="12" y1="16" x2="12" y2="12"></line><line
                                x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                          </a></div>
                        <span className="number ms-3"> ({ticketsForRework})</span>
                      </li>
                      <li
                        onClick={() => {
                          let updatedSeries = [];
                          if (!checkedmissedeta) {
                            updatedSeries = [...series, missedEtaLine];
                            setSeries(updatedSeries);
                          } else {
                            const updatedSeries = series.filter((x) => x.name !== "Missed ETA's");
                            setSeries(updatedSeries);
                          }
                          setcheckedmissedeta(!checkedmissedeta);
                        }}
                        className={`${checkedmissedeta === true
                          ? "d-flex missed-active align-items-center"
                          : "d-flex missed align-items-center"
                          }`}
                      >
                        <div className="text d-flex align-items-center">
                          <span className="me-1"> missed ETA's</span>
                          <a
                            href="#"
                            class="text-dark"
                            data-toggle="tooltip"
                            data-html="true"
                            title="These are the tickets which have missed the ETA’s.">
                            <svg className="miss-icon" xmlns="http://www.w3.org/2000/svg"
                              width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="#fc3d39" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-info dark"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-info selected"><circle cx="12" cy="12" r="10">
                              </circle><line x1="12" y1="16" x2="12" y2="12">
                              </line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                          </a>
                        </div>
                        <span className="number ms-3"> ({missedETA})</span>
                      </li>
                    </ul>
                  </div>
                  <Chart
                    options={options}
                    series={series}
                    type="line"
                    height="300"
                  />
                </div>
              </div>
            </div>

            <div class="portlet team-evaluation-box">
              <div class="title">
                <div className="row">
                  <div className="col-md-4">
                    <div class="assigned-ticket">
                      <CommonTable
                        title={"Tickets Closed"}
                        columns={titlecolumns}
                        datasource={Closedtabledata}
                        tooltip={"These are the tickets which have been successfully closed"}

                      ></CommonTable>
                      {TicketsClosed &&
                        TicketsClosed.length > 5 ? (
                        <a
                          onClick={() => {
                            setDrawertitle("Tickets Closed")
                            setDrawerlist(TicketsClosed);
                            viewshowDrawer()
                          }}>
                          View More
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="assigned-ticket">
                      <CommonTable
                        title={"Tickets Completed as per ETA's"}
                        columns={titlecolumns}
                        datasource={CompeltedAsPerETAdata}
                        tooltip={" These are the tickets which have been completed as per ETA’s"}
                      ></CommonTable>
                      {completedetatable &&
                        completedetatable.length > 5 ? (
                        <a
                          onClick={() => {
                            setDrawertitle("Tickets Completed as per ETA's");
                            setDrawerlist(completedetatable);
                            viewshowDrawer()
                          }}>
                          View More
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="assigned-ticket">
                      <CommonTable
                        title={"Tickets rejected"}
                        columns={titlecolumns}
                        datasource={RejectedBydata}
                        tooltip={"These are the tickets which are rejected and requires rework"}
                      ></CommonTable>
                      {rejectedtable &&
                        rejectedtable.length > 5 ? (
                        <a
                          onClick={() => {
                            setDrawertitle("Tickets rejected");
                            setDrawerlist(rejectedtable);
                            viewshowDrawer()
                          }}>
                          View More
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div class="assigned-ticket">
                      <CommonTable
                        title={"Pristine Tickets"}
                        columns={titlecolumns}
                        datasource={PristineAdata}
                        tooltip={"These are the tickets which have been approved by the manager without any rework"}
                      ></CommonTable>
                      {Pristine &&
                        Pristine.length > 5 ? (
                        <a
                          onClick={() => {
                            setDrawertitle("Pristine Tickets");
                            setDrawerlist(Pristine);
                            viewshowDrawer()
                          }}>
                          View More

                        </a>
                      ) : (
                        ""
                      )}

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="assigned-ticket">
                      <CommonTable
                        title={"Tickets missed the ETA's"}
                        columns={titlecolumns}
                        datasource={MissedETAdata}
                        tooltip={
                          "These are the tickets which have missed the ETA’s."
                        }
                      ></CommonTable>
                      {MissedETA &&
                        MissedETA.length > 5 ? (
                        <a
                          onClick={() => {
                            setDrawertitle("Tickets missed the ETA's");
                            setDrawerlist(MissedETA);
                            viewshowDrawer()
                          }}>
                          View More

                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Drawer
                      placement="right"
                      onClose={viewonclose}
                      open={viewopen}>
                      <CommonTable
                        title={Drawertitle}
                        columns={titlecolumns}
                        datasource={Drawerlist}
                      ></CommonTable>
                    </Drawer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}</>
  );
}

export default TeamEvaluation;
