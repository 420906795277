import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";
import { css } from "@emotion/react";

export const trigger = css({
  ...textStyles.b3,
  textAlign: "center",
});

export const popperContainer = (singleCalendar) =>
  css({
    padding: 0,
    display: "flex",
    flexDirection: "column",
    width: singleCalendar ? "21rem" : "10",
    boxShadow: "none",
    border: "none",
    ".___SCalendarUnit_1l518_gg_.__startSelected_1l518_gg_:not( .__highlighted_1l518_gg_)": {
      borderRadius: "6px !important",
    },
    ".___SCalendarUnit_1l518_gg_.__startSelected_1l518_gg_:not( .__selected_1l518_gg_)": {
      borderRadius: "6px !important",
    },
    ".___SCalendarUnit_1l518_gg_.__endSelected_1l518_gg_:not( .__highlighted_1l518_gg_)": {
      borderRadius: "6px !important",
    },
    ".___SCalendarUnit_1l518_gg_.__endSelected_1l518_gg_:not( .__selected_1l518_gg_)": {
      borderRadius: "6px !important",
    },
    ".___SCalendarUnit_1l518_gg_.__highlighted_1l518_gg_:not( .__startSelected_1l518_gg_)": {
      backgroundColor: Colors.plainWhite,
      borderRadius: "6px !important",
    },
    ".___SCalendarUnit_1l518_gg_.__selected_1l518_gg_:not( .__startSelected_1l518_gg_)": {
      backgroundColor: Colors.plainWhite,
      borderRadius: "6px !important",
    },
    ".___SGridMonths_1l518_gg_ ": {
      gridColumnGap: "8px",
    },
  });

export const noticeContainer = {
  borderTop: "none",
  borderRight: "none",
  borderLeft: "none",
  borderRadius: "0",
  padding: "1rem",
};

export const noticeLabelContainer = css({
  flexDirection: "column",
  rowGap: "0.25rem",
  padding: 0,
});

export const noticeTitle = css({
  ...textStyles.s2,
  color: Colors.neutral[900],
});

export const noticeIcon = css({
  width: "1rem",
  height: "1rem",
});

export const noticeDescription = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const headerTitle = css({
  ...textStyles.s1,
  color: Colors.neutral[600],
});

export const week = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const date = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const periods = css({
  borderLeft: `1px solid ${Colors.neutral[50]}`,
});

export const bottomApplyContainer = css({
  borderTop: `1px solid ${Colors.neutral[50]}`,
});

export const buttonText = css({
  ...textStyles.s2,
  color: Colors.black,
});

export const calendarText = css({
  ...textStyles.b3,
});

export const compareContainer = css({
  ...textStyles.b3,
  display: "flex",
  alignItems: "center",
  columnGap: "0.05rem",
  ".___SCheckbox_1fyp5_gg_._size_m_1fyp5_gg_": {
    marginTop: `0`,
  },
  label: {
    marginBottom: 0,
  },
});