import React, { useEffect, useState } from "react";
import APIManager from "../../../services/APIManager";
import {
  createCampingDetail,
  getcampaignbyId,
  getCountryCityByOrg,
  updateCampingDetail,
  API_BASE_URL
} from "../../../utility/Constant";
import AsyncSelect from 'react-select/async';
import { removeTrailingSlash } from "../../../utility/Globals";

function Campaigndetails(props) {

  useEffect(() => {
    clearFieldValues();
    setsumitted(false);
    setErrors("");
  }, [props?.tab]);

  useEffect(() => {
    if (props?.campaignID?.id) {
      getcampaignViewDetails(props.campaignID.id);
    } else {
      getCountryCityList("");
    }
  }, [props?.campaignID?.id]);

  useEffect(() => {
    if (props?.storeData == true) {
      if (formValidation()) {
        if (!sumitted) {
          props.campaignID.id
            ? Updatecampaingdetails()
            : createCampingDetails();
        }
      } else {
        handleChildToProp(0);
      }
    }
  }, [props]);

  const [inputFields, setInputFields] = useState([
    { Minimum: "", Maximum: "" },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { Minimum: "", Maximum: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const [campingName, setcampingName] = useState("");
  const [domainName, setdomainName] = useState("");
  const [SearchEngine, setSearchEngine] = useState("https://www.google.com/");
  const [Frequency, setFrequency] = useState("");
  const [Device, setDevice] = useState("");
  const [Location, setLocation] = useState("");
  const [cityId, setcityId] = useState("");
  const [MinSearchVolumeHead, setMinSearchVolumeHead] = useState(0);
  const [MinSearchVolumeTorso, setMinSearchVolumeTorso] = useState(0);
  const [MinSearchVolumeTail, setMinSearchVolumeTail] = useState(0);
  const [MaxSearchVolumeHead, setMaxSearchVolumeHead] = useState(0);
  const [MaxSearchVolumeTorso, setMaxSearchVolumeTorso] = useState(0);
  const [MaxSearchVolumeTail, setMaxSearchVolumeTail] = useState(0);
  const [ApproveStatus, setApproveStatus] = useState("");
  const [sumitted, setsumitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [isLocationChange, setIsLocationChange] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState(0);

  const handleChildToProp = (val) => {
    props.dataSetChild(val);
  };

  const customStylesSearch = {
    control
      : base => ({
        ...base,
        border: "1px solid #ced4da",
        boxShadow: 'none',
        height: 43,
        minHeight: 43,
        fontSize: '1.1rem',
        borderRadius: '5px'
      }),
    option: (base, state) => {
      return ({
        ...base,
        color: state.data == selectedValue ? "#ffffff" : "#212121",
        backgroundColor: state.data == selectedValue ? "#2196f3" : "#ffffff",
      })
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: '42px',
      padding: '0 0px 0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#ced4da"
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      minHeight: 20,
      zIndex: '9'
    }),
    menuPortal: base => ({
      ...base, zIndex: 9999
    })
  };

  function createCampingDetails() {
    setsumitted(true);
    let rowData = {
      CampaignName: campingName,
      Domain: removeTrailingSlash(domainName),
      Location: Location,
      cityId: cityId,
      SearchEngine: SearchEngine,
      Frequency: Frequency,
      Device: Device,
      Credits: 10000,
      Status: 0,
      ProjectId: localStorage.getItem("projectGId"),
      MinSearchVolumeHead: MinSearchVolumeHead,
      MinSearchVolumeTorso: MinSearchVolumeTorso,
      MinSearchVolumeTail: MinSearchVolumeTail,
      MaxSearchVolumeHead: MaxSearchVolumeHead,
      MaxSearchVolumeTorso: MaxSearchVolumeTorso,
      MaxSearchVolumeTail: MaxSearchVolumeTail,
      RankingCampaignBuckets: inputFields,
      createdBy: localStorage.getItem("userId"),
      SearchKey: searchKey
    };
    APIManager.postAPI(createCampingDetail, rowData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          props.getConfigId(response?.data?.campaignId);
          handleChildToProp(1);
          clearFieldValues();
        } else {
          handleChildToProp(0);
        }
      }
    );
  }

  function getcampaignViewDetails(id) {
    APIManager.postAPI(getcampaignbyId + id).then((response) => {
      if (response && response.status == 200) {
        setcampingName(response.data.campaignName);
        setdomainName(response.data.domain);
        setLocation(response.data.location);
        setcityId(response.data.cityId);
        setCampaignStatus(response.data.status)
        setFrequency(response.data.frequency);
        setDevice(response.data.device);
        setMinSearchVolumeHead(response.data.minSearchVolumeHead);
        setMinSearchVolumeTorso(response.data.minSearchVolumeTorso);
        setMinSearchVolumeTail(response.data.minSearchVolumeTail);
        setMaxSearchVolumeHead(response.data.maxSearchVolumeHead);
        setMaxSearchVolumeTorso(response.data.maxSearchVolumeTorso);
        setMaxSearchVolumeTail(response.data.maxSearchVolumeTail);
        setApproveStatus(response.data?.status);
        setValue(response.data?.searchKey)
        loadOptions(response.data?.searchKey)
        getCountryCityList(response.data?.searchKey ? response.data?.searchKey : ((response.data?.city) ? (response.data?.city.cityName) : (response.data?.country.countryName)), { countryId: response.data?.location, cityId: response.data?.cityId })
        const list = [];
        response.data.rankingCampaignBuckets.map((item) => {
          let obj = {
            Minimum: item.minimum,
            Maximum: item.maximum,
          };
          list.push(obj);
        });
        setInputFields(list);
      }
    });
  }

  function Updatecampaingdetails() {
    setsumitted(true);
    localStorage.setItem('rankingCampaignDomain', domainName);
    APIManager.putAPI(
      updateCampingDetail,
      {
        Id: props.campaignID.id,
        CampaignName: campingName,
        Domain: removeTrailingSlash(domainName),
        Location: Location,
        cityId: cityId,
        SearchEngine: SearchEngine,
        Frequency: Frequency,
        Device: Device,
        Credits: 10000,
        Status: campaignStatus,
        ProjectId: localStorage.getItem("projectGId"),
        MinSearchVolumeHead: MinSearchVolumeHead,
        MinSearchVolumeTorso: MinSearchVolumeTorso,
        MinSearchVolumeTail: MinSearchVolumeTail,
        MaxSearchVolumeHead: MaxSearchVolumeHead,
        MaxSearchVolumeTorso: MaxSearchVolumeTorso,
        MaxSearchVolumeTail: MaxSearchVolumeTail,
        RankingCampaignBuckets: inputFields,
        SearchKey: isLocationChange ? searchKey : inputValue,
        createdBy: localStorage.getItem("userId")
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        props.getConfigId(props?.campaignID?.id);
        handleChildToProp(1);
        clearFieldValues();
      } else {
        handleChildToProp(0);
      }
    });
  }

  function clearFieldValues() {
    setcampingName("");
    setdomainName("");
    setLocation("");
    setcityId("");
    setFrequency("");
    setDevice("");
    setMinSearchVolumeHead("");
    setMinSearchVolumeTorso("");
    setMinSearchVolumeTail("");
    setMaxSearchVolumeHead("");
    setMaxSearchVolumeTorso("");
    setMaxSearchVolumeTail("");
    setSelectedValue(null);
    setSelectedValuedrp(null);
    setInputFields([{ Minimum: "", Maximum: "" }]);
  }

  function formValidation() {
    let error = { ...errors };

    campingName.trim() == ""
      ? (error["campingName"] = "Campaing Name is required!")
      : delete error["campingName"];

    domainName.trim() == ""
      ? (error["domainName"] = "Domain name is required!")
      : delete error["domainName"];

    if (domainName.trim()?.length) {
      var pattern = new RegExp(
        "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$"
      );

      if (!pattern.test(domainName.trim())) {
        error["domainName"] = "Invalid domain: Remove / from end";
      } else {
        delete error["domainName"];
      }
    }

    !selectedValue
      ? (error["selectedValue"] = "Location is required!")
      : delete error["selectedValue"];

    !Frequency.toString()
      ? (error["Frequency"] = "Frequency is required!")
      : delete error["Frequency"];

    !Device.toString()
      ? (error["Device"] = "Device is required!")
      : delete error["Device"];

    SearchEngine.trim() == ""
      ? (error["SearchEngine"] = "Search Engine is required!")
      : delete error["SearchEngine"];

    !MinSearchVolumeTorso.toString()
      ? (error["MinSearchVolumeTorso"] = "Value is required!")
      : delete error["MinSearchVolumeTorso"];

    !MaxSearchVolumeTorso.toString()
      ? (error["MaxSearchVolumeTorso"] = "Value is required!")
      : delete error["MaxSearchVolumeTorso"];

    !MaxSearchVolumeTail.toString()
      ? (error["MaxSearchVolumeTail"] = "Value is required!")
      : delete error["MaxSearchVolumeTail"];

    !MinSearchVolumeTail.toString()
      ? (error["MinSearchVolumeTail"] = "Value is required!")
      : delete error["MinSearchVolumeTail"];

    !MaxSearchVolumeHead.toString()
      ? (error["MaxSearchVolumeHead"] = "Value is required!")
      : delete error["MaxSearchVolumeHead"];

    !MinSearchVolumeHead.toString()
      ? (error["MinSearchVolumeHead"] = "Value is required!")
      : delete error["MinSearchVolumeHead"];

    inputFields.map((item, i) => {
      !item["Minimum"].toString()
        ? (error[`Minimum_${i}`] = "Minimum Bucket value is required!")
        : delete error[`Minimum_${i}`];
      !item["Maximum"].toString()
        ? (error[`Maximum_${i}`] = "Maximum Bucket value is required!")
        : delete error[`Maximum_${i}`];

      item["Minimum"] < 0
        ? (error[`Minimum0_${i}`] = "Minimum Bucket value must be greater than 0")
        : delete error[`Minimum0_${i}`];
      item["Maximum0"] < 0
        ? (error[`Maximum0_${i}`] = "Maximum Bucket value must be greater than 0")
        : delete error[`Maximum0_${i}`];

      if (item["Minimum"] && item["Maximum"] && item["Minimum"] >= 0 && item["Maximum"] >= 0) {
        +item["Minimum"] > +item["Maximum"]
          ? (error[`bucketsLength_${i}`] =
            "Minimum buckets must be less than maximum")
          : delete error[`bucketsLength_${i}`];
      } else {
        delete error[`bucketsLength_${i}`];
      }
    });

    let data = [];
    inputFields.map((obj, i) => {
      data.push(obj.Minimum + '-' + obj.Maximum)
    })

    const hasDifferentMinMax = data.some((value, index, array) => {
      return array.indexOf(value) !== index;
    })

    hasDifferentMinMax == true ? (error["SameBucket"] = "Bucket value should not be same!")
      : delete error["SameBucket"];

    if (MinSearchVolumeTorso && MaxSearchVolumeTorso) {
      +MinSearchVolumeTorso >= +MaxSearchVolumeTorso
        ? (error["SearchVolumeTorso"] =
          "Min Search Volume Torso buckets must be less than max Search Volume Torso")
        : delete error["SearchVolumeTorso"];
    } else {
      delete error["SearchVolumeTorso"];
    }

    if (MinSearchVolumeTail && MaxSearchVolumeTail) {
      +MinSearchVolumeTail >= +MaxSearchVolumeTail
        ? (error["SearchVolumeTail"] =
          "Min Search Volume Tail buckets must be less than max Search Volume Tail")
        : delete error["SearchVolumeTail"];
    } else {
      delete error["SearchVolumeTail"];
    }

    if (MinSearchVolumeTail && MaxSearchVolumeHead) {
      +MinSearchVolumeHead >= +MaxSearchVolumeHead
        ? (error["SearchVolumeHead"] =
          "Min Search Volume Head buckets must be less than max Search Volume Head")
        : delete error["SearchVolumeHead"];
    } else {
      delete error["SearchVolumeHead"];
    }

    if (MinSearchVolumeHead && MaxSearchVolumeTorso) {
      +MinSearchVolumeHead <= +MaxSearchVolumeTorso
        ? (error["SearchVolumeTorso1"] =
          "Max Search Volume Torso buckets must be less than min Search Volume Head")
        : delete error["SearchVolumeTorso1"];
    } else {
      delete error["SearchVolumeTorso1"];
    }

    if (MinSearchVolumeTorso && MaxSearchVolumeTail) {
      +MinSearchVolumeTorso <= +MaxSearchVolumeTail
        ? (error["SearchVolumeTail1"] =
          "Max Search Volume Tail buckets must be less than min Search Volume Torso")
        : delete error["SearchVolumeTail1"];
    } else {
      delete error["SearchVolumeTail1"];
    }

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  const formatOptionLabel = (e) => {
    return (
      <div style={{ display: "flex", "justify-content": "space-between", "alignItems": "center" }}>
        <div>{e.name}</div>
        <div style={{ marginLeft: "0px", color: "#ccc" }}>
          {e.type}
        </div>
      </div>
    )
  };

  function getCountryCityList(val, resObj) {
    APIManager.getAPI(
      getCountryCityByOrg + localStorage.getItem("organizationId") + "?search=" + val
    ).then((response) => {
      const list = [];
      if (response && response.status == 200) {
        response.data.map((item) => {
          let obj = {
            cityId: item.cityId,
            countryId: item.countryId,
            name: item.name,
            type: item.type,
          };
          list.push(obj);
          if (resObj) {
            if (resObj?.cityId) {
              if (item.cityId == resObj?.cityId) {
                setSelectedValue(item);
                setSelectedValuedrp({
                  cityId: item.cityId ? item.cityId : null,
                  countryId: item.countryId,
                  name: item.name,
                  type: item.type,
                });
              }
            } else {
              if (item.countryId == resObj?.countryId && !item.cityId) {
                setSelectedValue(item);
                setSelectedValuedrp({
                  cityId: item.cityId ? item.cityId : null,
                  countryId: item.countryId,
                  name: item.name,
                  type: item.type,
                });
              }
            }
          }
        });
      }
    });
  }

  const [inputValue, setValue] = useState('');
  const [selectedValuedrp, setSelectedValuedrp] = useState(null);

  const handleInputChange = value => {
    setSearchKey(value);
  };

  const handleChange = value => {
    setIsLocationChange(true);
    setSelectedValue(value);
    setLocation(value?.countryId);
    setSelectedValuedrp(value);
    if (value?.type == "City") {
      setcityId(value?.cityId);
    } else {
      setcityId("");
    }
  }

  const loadOptions = (inputValue) => {
    setTimeout(() => {
    }, 5000);
    if (inputValue) {
      setSearchKey(inputValue);
    }
    return fetch(`${API_BASE_URL + getCountryCityByOrg + localStorage.getItem("organizationId") + "?search=" + inputValue ?? searchKey}`).then(res => res.json().then(x => x));
  };

  return (
    <div className="ranking-create-campaign px-3 mx-2">
      <div className="create-campaign-form px-0">
        <div class="row">
          <div class="col-md-12">
            <h5>
              <b> Campaign Details</b>
            </h5>
          </div>
        </div>
        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Campaign Name <label class="field-required"> * </label> :
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative">
            <input
              type="text"
              value={campingName}
              onBlur={() => {
                let error = { ...errors };
                campingName.trim() == ""
                  ? (error["campingName"] = "Campaing Name is required!")
                  : delete error["campingName"];
                setErrors(error);
              }}
              onChange={(e) => {
                setcampingName(e.target.value);
                let error = { ...errors };
                e.target.value.trim() == ""
                  ? (error["campingName"] = "Campaing Name is required!")
                  : delete error["campingName"];
                setErrors(error);
              }}
              class="form-control"
              placeholder="Ranking Project Name"
            />
            {errors?.campingName && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.campingName}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Domain <label class="field-required"> * </label> :
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative">
            <input
              type="Domain"
              value={domainName}
              onBlur={() => {
                let error = { ...errors };
                domainName.trim() == ""
                  ? (error["domainName"] = "Domain name is required!")
                  : delete error["domainName"];
                if (domainName.trim()?.length) {
                  var pattern = new RegExp(
                    "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
                  );

                  if (!pattern.test(domainName.trim())) {
                    error["domainName"] = "Invalid domain: Remove / from end";
                  } else {
                    delete error["domainName"];
                  }
                }
                setErrors(error);
              }}
              onChange={(e) => {
                setdomainName(e.target.value);
                localStorage.setItem('rankingCampaignDomain', e.target.value);
                let error = { ...errors };
                e.target.value.trim() == ""
                  ? (error["domainName"] = "Domain name is required!")
                  : delete error["domainName"];
                if (domainName.trim()?.length) {
                  var pattern = new RegExp(
                    "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
                  );

                  if (!pattern.test(domainName.trim())) {
                    error["domainName"] = "Invalid domain: Remove / from end";
                  } else {
                    delete error["domainName"];
                  }
                }
                setErrors(error);
              }}
              class="form-control"
              placeholder="Enter Domain Name"
            />
            {errors?.domainName && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.domainName}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Location <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative rankingv2_countryselect">
            <AsyncSelect
              cacheOptions
              styles={customStylesSearch}
              defaultOptions
              value={selectedValuedrp}
              getOptionLabel={e => e.name}
              getOptionValue={e => e.countryId}
              formatOptionLabel={formatOptionLabel}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={handleChange}
            />
            {errors?.selectedValue && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.selectedValue}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Search Engine <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative">
            <select
              required
              className="form-select w-100 mt-2"
              style={{ borderRadius: 0, height: "36px" }}
              value={SearchEngine}
              onBlur={() => {
                let error = { ...errors };
                SearchEngine.trim() == ""
                  ? (error["SearchEngine"] = "Search Engine is required!")
                  : delete error["SearchEngine"];
                setErrors(error);
              }}
              onChange={(e) => {
                setSearchEngine(e.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                Select Search Engine
              </option>
              <option value="https://www.google.com/" selected>
                https://www.google.com/
              </option>
            </select>
            {errors?.SearchEngine && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.SearchEngine}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Frequency <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative">
            <select
              disabled={ApproveStatus != 0 ? true : false}
              className="form-select w-100 mt-2"
              style={{ borderRadius: 0, height: "36px" }}
              value={Frequency}
              onBlur={() => {
                let error = { ...errors };
                !Frequency.toString()
                  ? (error["Frequency"] = "Frequency is required!")
                  : delete error["Frequency"];

                setErrors(error);
              }}
              onChange={(e) => {
                setFrequency(e.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                Select Frequency
              </option>
              <option value="0">Weekly</option>
              <option value="1">Monthly</option>
            </select>
            {errors?.Frequency && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.Frequency}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Device <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6  col-md-7 input-box position-relative">
            <select
              required
              className="form-select w-100 mt-2"
              value={Device}
              style={{ borderRadius: 0, height: "36px" }}
              onBlur={() => {
                let error = { ...errors };
                !Device.toString()
                  ? (error["Device"] = "Device is required!")
                  : delete error["Device"];
                setErrors(error);
              }}
              onChange={(e) => {
                setDevice(e.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                Select Device
              </option>
              <option value="0">Mobile</option>
              <option value="1">Desktop</option>
            </select>
            {errors?.Device && (
              <>
                <div className="field-required position-absolute">
                  <span> {errors?.Device}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-2 mb-md-0 mb-2 d-flex align-items-top">
            <div class="text-box mt-4">
              <div className="lable-box d-flex align-items-center">
                <div className="boxxx d-flex flex-wrap lign-items-center">
                  <label
                    for="inline-form-username"
                    className="max-content-lable"
                  >
                    Ranking Buckets <label class="field-required"> * </label> :{" "}
                  </label>
                  <div class="camp-tooltip">
                    <svg
                      fill="#808080"
                      width="15px"
                      height="16px"
                      viewBox="-1.28 -1.28 18.56 18.56"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#808080"
                      stroke-width="0.00016"
                      className="ms-2"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke="#CCCCCC"
                        stroke-width="0.032"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M7.37 6.7h1.25v5H7.37z" />
                        <circle cx="8" cy="4.85" r=".65" />
                        <path d="M8 .5A7.77 7.77 0 0 0 0 8a7.77 7.77 0 0 0 8 7.5A7.77 7.77 0 0 0 16 8 7.77 7.77 0 0 0 8 .5zm0 13.75A6.52 6.52 0 0 1 1.25 8 6.52 6.52 0 0 1 8 1.75 6.52 6.52 0 0 1 14.75 8 6.52 6.52 0 0 1 8 14.25z" />
                      </g>
                    </svg>
                    <span className="ranking-graph-hover">
                      <img
                        src="/images/rank-hover-graph.png"
                        width="450"
                        height="140px"
                        alt="#"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="span-box d-block">
                {" "}
                <span>(Max 7)</span>
              </div>
            </div>
          </div>
          <div class="col-md-9 fix-col-width p-0 ">
            <div className="input-fieldss position-relative d-block">
              <div class="row">
                {inputFields?.map((input, index) => {
                  return (
                    <>
                      <div class="col-lg-4 col-xl-3 col-md-4 col-sm-6 col-12 mt-3">
                        <div
                          className={
                            index == 0
                              ? "input-plus text-center position-relative width-set first-child"
                              : "input-plus text-center position-relative width-set"
                          }
                        >
                          <div
                            class="d-flex align-items-center position-relative"
                            key={index}
                          >
                            <input
                              type="number"
                              class="form-control"
                              value={input.Minimum}
                              min="0"
                              name="Minimum"
                              placeholder=""

                              onBlur={() => {
                                let error = { ...errors };
                                !input.Minimum.toString()
                                  ? (error[`Minimum_${index}`] =
                                    "Minimum Bucket value is required!")
                                  : delete error[`Minimum_${index}`];

                                input.Minimum < 0
                                  ? (error[`Minimum0_${index}`] = "Minimum Bucket value must be greater than 0")
                                  : delete error[`Minimum0_${index}`];

                                if (input.Minimum && input.Maximum && input.Minimum >= 0 && input.Maximum >= 0) {
                                  +input.Minimum > +input.Maximum
                                    ? (error[`bucketsLength_${index}`] =
                                      "Minimum buckets must be less than maximum")
                                    : delete error[`bucketsLength_${index}`];
                                } else {
                                  delete error[`bucketsLength_${index}`];
                                }
                                setErrors(error);
                              }}
                              onChange={(event) => {
                                handleFormChange(index, event);
                              }}
                            />
                            <span className="mx-3">-</span>
                            <input
                              type="number"
                              class="form-control"
                              value={input.Maximum}
                              min="0"
                              name="Maximum"
                              onBlur={() => {
                                let error = { ...errors };
                                !input.Maximum.toString()
                                  ? (error[`Maximum_${index}`] =
                                    "Maximum Bucket value is required!")
                                  : delete error[`Maximum_${index}`];

                                input.Maximum < 0
                                  ? (error[`Maximum0_${index}`] = "Maximum Bucket value must be greater than 0")
                                  : delete error[`Maximum0_${index}`];

                                if (input.Minimum && input.Maximum && input.Minimum >= 0 && input.Maximum >= 0) {
                                  +input.Minimum > +input.Maximum
                                    ? (error[`bucketsLength_${index}`] =
                                      "Minimum buckets must be less than maximum")
                                    : delete error[`bucketsLength_${index}`];
                                } else {
                                  delete error[`bucketsLength_${index}`];
                                }
                                setErrors(error);
                              }}
                              onChange={(event) => {
                                handleFormChange(index, event);
                              }}
                              placeholder=""
                            />
                          </div>
                          <div class="d-flex align-items-center position-relative" key={index}>
                            {errors[`Minimum_${index}`] && (
                              <>
                                <div className="field-required ">
                                  <span> {errors[`Minimum_${index}`]}</span>
                                </div>
                              </>
                            )}

                            {errors[`Maximum_${index}`] && (
                              <>
                                <div className="field-required">
                                  <span> {errors[`Maximum_${index}`]}</span>
                                </div>
                              </>
                            )}

                            {errors[`Minimum0_${index}`] && (
                              <>
                                <div className="field-required ">
                                  <span> {errors[`Minimum0_${index}`]}</span>
                                </div>
                              </>
                            )}

                            {errors[`Maximum0_${index}`] && (
                              <>
                                <div className="field-required">
                                  <span> {errors[`Maximum0_${index}`]}</span>
                                </div>
                              </>
                            )}
                          </div>
                          <div class="require">
                            {errors[`bucketsLength_${index}`] && (
                              <>
                                <div className="field-required">
                                  <span>
                                    {" "}
                                    {errors[`bucketsLength_${index}`]}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="plus-box ms-2">
                            {index == inputFields.length - 1 &&
                              inputFields.length < 7 ? (
                              <>
                                <span onClick={addFields}>
                                  <svg
                                    width="15px"
                                    height="15px"
                                    viewBox="0 0 24.00 24.00"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="#000000"
                                    stroke-width="0.00024000000000000003"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      stroke-width="0"
                                    />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke="#CCCCCC"
                                      stroke-width="0.096"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z"
                                        fill="#808080"
                                      />{" "}
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2 4.5C2 3.11929 3.11929 2 4.5 2H19.5C20.8807 2 22 3.11929 22 4.5V19.5C22 20.8807 20.8807 22 19.5 22H4.5C3.11929 22 2 20.8807 2 19.5V4.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5V19.5C4 19.7761 4.22386 20 4.5 20H19.5C19.7761 20 20 19.7761 20 19.5V4.5C20 4.22386 19.7761 4 19.5 4H4.5Z"
                                        fill="#808080"
                                      />{" "}
                                    </g>
                                  </svg>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {inputFields?.length > 1 ? (
                            <>
                              <div className="absolute-box d-flex align-items-center px-0 mt-3 pt-4">
                                <span
                                  className="add-on-svg"
                                  onClick={() => removeFields(index)}
                                >
                                  <svg
                                    width="15px"
                                    height="15px"
                                    viewBox="-1.92 -1.92 27.84 27.84"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      stroke-width="0"
                                    />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke="#CCCCCC"
                                      stroke-width="0.192"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        d="M15 9.00004L9 15M15 15L9 9.00004M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                                        stroke="#808080"
                                        stroke-width="1.3679999999999999"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />{" "}
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="rank-name mt-3">
                            <span>Rank</span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div class="require">
                  {errors[`SameBucket`] && (
                    <>
                      <div className="field-required ">
                        <span> {errors[`SameBucket`]}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h5 className="my-4">
              <b>Search Volume Range :</b>
            </h5>
          </div>
        </div>
        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Head <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6 col-md-7 input-box position-relative">
            <div className="d-flex align-items-center ">
              <div class="w-100">
                <input
                  type="number"
                  value={MinSearchVolumeHead}
                  min="0"
                  onBlur={() => {
                    let error = { ...errors };
                    !MinSearchVolumeHead.toString()
                      ? (error["MinSearchVolumeHead"] = "Value is required!")
                      : delete error["MinSearchVolumeHead"];

                    if (MinSearchVolumeHead && MaxSearchVolumeHead) {
                      +MinSearchVolumeHead >= +MaxSearchVolumeHead
                        ? (error["SearchVolumeHead"] =
                          "Min Search Volume Head buckets must be less than max Search Volume Head")
                        : delete error["SearchVolumeHead"];
                    } else {
                      delete error["SearchVolumeHead"];
                    }

                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMinSearchVolumeHead(e.target.value);
                  }}
                  class="form-control"
                  placeholder="Min.Search Volume"
                />
                {errors?.MinSearchVolumeHead && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MinSearchVolumeHead}</span>
                    </div>
                  </>
                )}
              </div>
              <span className="mx-3">-</span>
              <div class="w-100">
                <input
                  type="number"
                  value={MaxSearchVolumeHead}
                  min="0"
                  onBlur={() => {
                    let error = { ...errors };
                    !MaxSearchVolumeHead.toString()
                      ? (error["MaxSearchVolumeHead"] = "Value is required!")
                      : delete error["MaxSearchVolumeHead"];

                    if (MinSearchVolumeHead && MaxSearchVolumeHead) {
                      +MinSearchVolumeHead >= +MaxSearchVolumeHead
                        ? (error["SearchVolumeHead"] =
                          "Min Search Volume Head buckets must be less than max Search Volume Head")
                        : delete error["SearchVolumeHead"];
                    } else {
                      delete error["SearchVolumeHead"];
                    }
                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMaxSearchVolumeHead(e.target.value);
                  }}
                  class="form-control"
                  placeholder="Max.Search Volume"
                />
                {errors?.MaxSearchVolumeHead && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MaxSearchVolumeHead}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {errors["SearchVolumeHead"] && (
              <>
                <div className="field-required">
                  <span> {errors["SearchVolumeHead"]}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Torso <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6 col-md-7 input-box position-relative">
            <div className="d-flex align-items-center  ">
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  value={MinSearchVolumeTorso}
                  onBlur={() => {
                    let error = { ...errors };
                    !MinSearchVolumeTorso.toString()
                      ? (error["MinSearchVolumeTorso"] = "Value is required!")
                      : delete error["MinSearchVolumeTorso"];

                    if (MinSearchVolumeTorso && MaxSearchVolumeTorso) {
                      +MinSearchVolumeTorso >= +MaxSearchVolumeTorso
                        ? (error["SearchVolumeTorso"] =
                          "Min Search Volume Torso buckets must be less than max Search Volume Torso")
                        : delete error["SearchVolumeTorso"];
                    } else {
                      delete error["SearchVolumeTorso"];
                    }
                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMinSearchVolumeTorso(e.target.value);
                  }}
                  class="form-control"
                  placeholder="Min.Search Volume"
                />
                {errors?.MinSearchVolumeTorso && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MinSearchVolumeTorso}</span>
                    </div>
                  </>
                )}
              </div>
              <span className="mx-3">-</span>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  value={MaxSearchVolumeTorso}
                  onBlur={() => {
                    let error = { ...errors };
                    !MaxSearchVolumeTorso.toString()
                      ? (error["MaxSearchVolumeTorso"] = "Value is required!")
                      : delete error["MaxSearchVolumeTorso"];
                    if (MinSearchVolumeTorso && MaxSearchVolumeTorso) {
                      +MinSearchVolumeTorso >= +MaxSearchVolumeTorso
                        ? (error["SearchVolumeTorso"] =
                          "Min Search Volume Torso buckets must be less than max Search Volume Torso")
                        : delete error["SearchVolumeTorso"];
                    } else {
                      delete error["SearchVolumeTorso"];
                    }
                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMaxSearchVolumeTorso(e.target.value);
                  }}
                  class="form-control"
                  placeholder="Max.Search Volume"
                />
                {errors?.MaxSearchVolumeTorso && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MaxSearchVolumeTorso}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {errors["SearchVolumeTorso"] && (
              <>
                <div className="field-required">
                  <span> {errors["SearchVolumeTorso"]}</span>
                </div>
              </>
            )}
            {errors["SearchVolumeTorso1"] && (
              <>
                <div className="field-required">
                  <span> {errors["SearchVolumeTorso1"]}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div class="row mt-4 mb32">
          <div class="col-md-2 mb-md-0 mb-2 text-box d-flex align-items-center">
            <label for="inline-form-username">
              Tail <label class="field-required"> * </label> :{" "}
            </label>
          </div>
          <div class="col-lg-6 col-md-7 input-box">
            <div className="d-flex align-items-center ">
              <div class="w-100">
                <input
                  type="number"
                  value={MinSearchVolumeTail}
                  min="0"
                  onBlur={() => {
                    let error = { ...errors };
                    !MinSearchVolumeTail.toString()
                      ? (error["MinSearchVolumeTail"] = "Value is required!")
                      : delete error["MinSearchVolumeTail"];
                    if (MinSearchVolumeTail && MaxSearchVolumeTail) {
                      +MinSearchVolumeTail >= +MaxSearchVolumeTail
                        ? (error["SearchVolumeTail"] =
                          "Min Search Volume Tail buckets must be less than max Search Volume Tail")
                        : delete error["SearchVolumeTail"];
                    } else {
                      delete error["SearchVolumeTail"];
                    }
                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMinSearchVolumeTail(e.target.value);
                  }}
                  class="form-control w-100"
                  placeholder="Min.Search Volume"
                />
                {errors?.MinSearchVolumeTail && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MinSearchVolumeTail}</span>
                    </div>
                  </>
                )}
              </div>
              <span className="mx-3">-</span>
              <div class="w-100">
                <input
                  type="number"
                  min="0"
                  value={MaxSearchVolumeTail}
                  onBlur={() => {
                    let error = { ...errors };
                    !MaxSearchVolumeTail.toString()
                      ? (error["MaxSearchVolumeTail"] = "Value is required!")
                      : delete error["MaxSearchVolumeTail"];

                    if (MinSearchVolumeTail && MaxSearchVolumeTail) {
                      +MinSearchVolumeTail >= +MaxSearchVolumeTail
                        ? (error["SearchVolumeTail"] =
                          "Min Search Volume Tail buckets must be less than max Search Volume Tail")
                        : delete error["SearchVolumeTail"];
                    } else {
                      delete error["SearchVolumeTail"];
                    }
                    setErrors(error);
                  }}
                  onChange={(e) => {
                    setMaxSearchVolumeTail(e.target.value);
                  }}
                  class="form-control"
                  placeholder="Max.Search Volume"
                />
                {errors?.MaxSearchVolumeTail && (
                  <>
                    <div className="field-required position-absolute">
                      <span> {errors?.MaxSearchVolumeTail}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {errors["SearchVolumeTail"] && (
              <>
                <div className="field-required">
                  <span> {errors["SearchVolumeTail"]}</span>
                </div>
              </>
            )}
            {errors["SearchVolumeTail1"] && (
              <>
                <div className="field-required">
                  <span> {errors["SearchVolumeTail1"]}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigndetails;



