import Ticket2LineIcon from "remixicon-react/Ticket2LineIcon";
import AssetsImg from "../components/assets/images";
import { TicketSvg } from "../components/ticketing-v2/ticketSvg";

export const sidebarData = {
  data: [
    {
      title: "Dashboard",
      icon: "home",
      subMenu: [],
      link: "/main/dashboard",
    },
    {
      title: "Rankings",
      subMenu: [],
      link: "/main/ranking",
      isPngIcon: true,
      activeIcon: AssetsImg.ic_ranking_active,
      inactiveIcon: AssetsImg.ic_ranking_inactive,
    },
    {
      title: "SEO Performance",
      icon: "cpu",
      subMenu: [],
    },
    {
      title: "SEO Health",
      icon: "activity",
      subMenu: [],
    },
    {
      title: "Tickets",
      icon: <Ticket2LineIcon size={"17.4"} />,
      subMenu: [],
      link: "/tickets/my-tickets",
      openInNewTab: true,
      isCustom: true,
    },
    {
      title: "Bandwidth Monitoring",
      icon: "user",
      subMenu: [],
      link: "/main/bandwidth",
    },
    {
      title: "Research",
      icon: "search",
      subMenu: [],
    },
    {
      title: "Configuration",
      icon: "sliders",
      subMenu: [],
      link: "/main/configuration",
    },
  ],
  active: 0,
};

export const ticketSidebarData = {
  data: [
    {
      title: "Tickets",
      icon: "book",
      icon: <Ticket2LineIcon size={"17.4"} />,
      isCustom: true,
      // link: "/tickets/my-tickets",
      // openInNewTab: true,
      subMenu: [
        {
          name: "My Tickets",
          link: "/tickets/my-tickets",
          iconClass: ""
        },
        {
          name: "Team Tickets",
          link: "/tickets/team-tickets",
          iconClass: ""
        },
        {
          name: "Create New Ticket",
          // link: "",
          iconClass: ""
        },
      ],
    },
    // {
    //   title: "Tickets",
    //   icon: "book",
    //   subMenu: [],
    //   link: "/ticket/dashboard",
    // },
  ],
  active: 0
}

export const dummyColumnList = ["keyword", "KD", "CPC", "Vol", "MD"]
export const dummyTableData = [
  {
    keyword: 'ebay buy',
    KD: '77.8',
    CPC: '$1.25',
    Vol: '32,500,000',
    MD: '221',
  },
  {
    keyword: 'www.ebay.com',
    KD: '11.2',
    CPC: '$3.4',
    Vol: '65,457,920',
    MD: '221',
  },
  {
    keyword: 'www.ebay.com',
    KD: '10',
    CPC: '$0.65',
    Vol: '47,354,640',
    MD: 'n/a',
  },
  {
    keyword: 'ebay buy',
    KD: '-',
    CPC: '$0',
    Vol: 'n/a',
    MD: '221',
  },
  {
    keyword: 'ebay buy',
    KD: '75.89',
    CPC: '$0',
    Vol: '21,644,290',
    MD: '221',
  },
];