import { css } from "@emotion/react";
import e from "cors";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  display: "flex",
  flexDirection: "column",
  ".rdrDateDisplayItem input" :{
    ...textStyles.b3,
    color: Colors.neutral[900]
  },
  ".rdrMonthAndYearPickers select": {
    ...textStyles.s1,
    color: Colors.neutral[600],
    padding: "0 2rem 0 0"
  },
  ".rdrMonthAndYearWrapper": {
    paddingTop: "1rem",
    paddingBottom: "0.75rem",
    height: "auto"
  },
  ".rdrWeekDay" :{
    ...textStyles.s2,
    color: Colors.neutral[600],
  },
  ".rdrDayNumber" : {
    ...textStyles.s2,
    color: Colors.neutral[600],
  },
  ".rdrDayNumber span": {
    color: Colors.neutral[600]
  },
  ".rdrDayDisabled": {
    opacity: 0.2
  },
  ".rdrDayPassive .rdrDayNumber span": {
    opacity: 0.2
  },
  ".rdrMonth": {
    width: "23rem",
    padding: "0 1rem 0 1rem"
  },
  ".rdrDay":{
    height: "2.65rem"
  },
  ".rdrDayToday .rdrDayNumber span:after" : {
    bottom: 3 ,
    height: 1,
    width: 14
  }
});

export const bottomContainer= (showCompare) => {
  return css({
  display: "flex",
  justifyContent: showCompare? "space-between": "flex-end",
  padding: "1rem",
})};

export const compareContainer = css({
...textStyles.b3,
  display: "flex",
  alignItems: "center",
  columnGap: "0.05rem",
  ".___SCheckbox_1fyp5_gg_._size_m_1fyp5_gg_": {
    marginTop: `0`,
  },
  label: {
    marginBottom: 0,
  },
});

export const compareText = (iscompareEnable) => {
  return css({
    color: iscompareEnable ? Colors.neutral[900] : Colors.neutral[300],
  })
}
