import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { Table, Input } from "antd";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import APIManager from "../services/APIManager";
import {
  createCompetitors,
  createKeywords,
  createProjectModule,
  createUrl,
  deleteCompetitors,
  deleteprojectModule,
  deleteProjectAssignment,
  getModule,
  getProjectModuleByProjectId,
  getUrlProjectID,
  getProjectAssignmentById,
  getMemberByOrgId,
  documentFileType,
  competitorsImport,
  keyWordImport,
  deleteKeyWord,
  getCompetitorsByProjectId,
  getKeyWordByProjectId,
} from "../utility/Constant";
import {
  exportToEXCEL,
  isValidImportFile,
  mmDDyyyyFormateDate,
  removeTrailingSlash,
  themeStyle,
  uckList,
} from "../utility/Globals";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Sorter } from "../common/Sorter";
import EditprojectAssignTeamMembers from "./EditprojectAssignTeamMembers";
import { useNavigate } from "react-router-dom";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function EditProjectDir({ update }) {
  const navigate = useNavigate();
  const [optionSelected, setoptionSelected] = useState({});
  function handleChange(selected) {
    setoptionSelected(selected);
  }
  const [teamMemberList, setTeamMemberList] = useState([]);

  const [optionSelectedmodule, setoptionSelectedmodule] = useState(null);
  const [selectedIDs, setSelectedIDs] = useState([]);
  function handleChangemodule(selected) {
    let idsList = [];
    selected.forEach((item) => {
      idsList.push(item.id);
    });
    setSelectedIDs(idsList);
    setoptionSelectedmodule(selected);
  }
  const [colourOptionsmodule, setcolormodule] = useState([]);

  function assign() {
    if (optionSelected.length === 0) {
    } else {
      navigate("",{
        state: {
          optionSelected: optionSelected,
          memberId: optionSelected.id,
          role: "role",
          isAlreadyGroupHead: isAlreadyGroupHead
        },
      });
    }
  }

  const [teamlist, setteamList] = useState([]);
  const [restTabteamlist, setrestTabteamlist] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [searchdata, setsearch] = useState([]);
  const [modulelisr, setmodulelist] = useState([]);
  const [taburllist, settaburlList] = useState([]);
  const [resetTaburllist, setresetTaburllist] = useState([]);
  const [URLTabcol, setURLTabcol] = useState([]);
  const [searchdataURLTab, setsearchURLTab] = useState([]);
  const [tabcomplist, settabcompList] = useState([]);
  const [restTabcomplist, setrestTabcomplist] = useState([]);
  const [CompTabcol, setCompTabcol] = useState([]);
  const [searchdataCompTab, setsearchCompTab] = useState([]);
  const [tabkeywordlist, settabkeywordList] = useState([]);
  const [restTabkeywordlist, setrestTabkeywordlist] = useState([]);
  const [KeywordTabcol, setKeywordTabcol] = useState([]);
  const [searchdataKeywordTab, setsearchKeywordTab] = useState([]);
  const [selectedUrlExportList, setSelectedUrlExportList] = useState([]);
  const [urlexportList, setUrlExportList] = useState([]);
  const [selectedCompExportList, setSelectedCompExportList] = useState([]);
  const [compexportList, setCompExportList] = useState([]);
  const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
  const [keyexportList, setKeyExportList] = useState([]);
  const [urlerrors, seturlErrors] = useState("");
  const [urlsave, seturlsave] = useState(false);
  const [keyworderrors, setkeywordErrors] = useState("");
  const [keywordsave, setkeywordsave] = useState(false);
  const [comperrors, setcompErrors] = useState("");
  const [compsave, setcompsave] = useState(false);
  const [settings, setSetting] = useState("editProjectDir");
  const [teamMembers, setTeamMembers] = useState([])
  const [assignMsg, setAssignMsg] = useState("")

  useEffect(() => {
    var data = [];
    var columns = [
      {
        title: "Team Member",
        dataIndex: "teammember",
        key: "teammember",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.teammember, b.teammember),
          multiple: 3,
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.role, b.role),
          multiple: 3,
        },
      },
      {
        title: "Allocation %",
        dataIndex: "allocation",
        key: "allocation",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.allocation, b.allocation),
          multiple: 3,
        },
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.location, b.location),
          multiple: 3,
        },
      },
      {
        title: "Billing Start Date",
        dataIndex: "billingstart",
        key: "billingstart",
      },
      {
        title: "Billing End Date",
        dataIndex: "billingend",
        key: "billingend",
      },
      {
        title: "Time Zone",
        dataIndex: "timezone",
        key: "timezone",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.timezone, b.timezone),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setteamcol(columns);
    columns = [
      {
        title: "Module Name",
        dataIndex: "modulename",
        key: "modulename",
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
      getAddmodulelist(),
    ];
    data = [];
    data.push({
      key: "1",
      id: "1",
      modulename: `Website Authority`,
      delete: (
        <a style={{ marginLeft: 24 + "px" }} href="#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-trash-2 trash-icon"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </a>
      ),
    });
    data.push({
      key: "2",
      id: "2",
      modulename: "Google Trends",
      delete: (
        <a style={{ marginLeft: 24 + "px" }} href="#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-trash-2 trash-icon"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </a>
      ),
    });
    data.push({
      key: "3",
      id: "3",
      modulename: "Page Speed",
      delete: (
        <a style={{ marginLeft: 24 + "px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-trash-2 trash-icon"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </a>
      ),
    });

    columns = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setURLTabcol(columns);
    columns = [
      {
        title: "Competitors",
        dataIndex: "comp",
        key: "comp",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.comp, b.comp),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setCompTabcol(columns);
    columns = [
      {
        title: "Keywords",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
      },
    ];
    setKeywordTabcol(columns);
    getUrlList();
    getCompetitorsList();
    getKeyWordList();
    getProjectAssig();
  }, []);

  const [isAlreadyGroupHead, setIsAlreadyGroupHead] = useState(false)

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          key: item.key,
          teammember: item.teammember,
          allocation: item.allocation,
          location: item.location,
          billingstart: item.billingstart,
          billingend: item.billingend,
          timezone: item.timezone,
        };
        list.push(obj);
      });
    },
  };

  const urlrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          url: item.url,
        };
        list.push(obj);
      });
      setSelectedUrlExportList(list);
    },
  };

  const comprowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          competitors: item.comp,
        };
        list.push(obj);
      });
      setSelectedCompExportList(list);
    },
  };

  const keyrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item) => {
        let obj = {
          keyword: item.keyword,
        };
        list.push(obj);
      });
      setSelectedKeyExportList(list);
    },
  };

  function getProjectAssig() {
    APIManager.getAPI(
      getProjectAssignmentById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let c = 1;
        response.data.forEach((item) => {
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            modulename: item.name,
            delete: (
              <a style={{ marginLeft: 24 + "px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </a>
            ),
          });
          c++;
          setTeamMembers(item.members)
        });
        let list2 = [];
        const exportList = [];
        response.data.forEach((item) => {
          exportList.push({
            key: item.id,
            teammember: item.members[0]?.members.name,
            allocation: item.allocation + "%",
            location: item.location,
            billingstart: mmDDyyyyFormateDate(item.billingStartDate),
            billingend: mmDDyyyyFormateDate(item.billingEndDate),
            timezone: item.timezone,
          });
          list2.push({
            key: item.id,
            teammember: item.members?.name,
            teammemberId: item.members?.id,
            role: item.members.role?.name,
            allocation: item.allocation + "%",
            location: item.location,
            billingstart: mmDDyyyyFormateDate(item.billingStartDate),
            billingend: mmDDyyyyFormateDate(item.billingEndDate),
            timezone: item.timezone,
            delete: (
              <>
                <div class="d-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon"
                    onClick={() => {
                      setSetting("editProjectAssignTeamMember");
                      navigate("",{
                        state: {
                          id: item.id,
                          isUpdate: true,
                          teammember: item.members.name,
                          teammemberId: item.members.id,
                          optionSelected: {
                            id: item.members.id,
                            key: item.members.name,
                            label: item.members.name,
                            name: item.members.name,
                            role: item.members.role.name,
                            value: item.members.name,
                          },
                          role: item.members.role.name,
                          allocation: item.allocation + "%",
                          location: item.location,
                          billingstart: item.billingStartDate,
                          billingend: item.billingEndDate,
                          timezone: item.timezone,
                          teamMembers: teamMembers,
                        },
                      });
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2 trash-icon  ms-3"
                    onClick={() => {
                      deleteEditproj(item.id);
                    }}
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </div>
              </>
            ),
          });

        });
        if (list2?.some((x) => x.role === "Group Head")) {
          setIsAlreadyGroupHead(true);
        }
        else {
          setIsAlreadyGroupHead(false);
        }
        getMemberlist(list2);
        setoptionSelected([]);
        setsearch(list2);
        setrestTabteamlist(list2);
        setteamList(list2);
      }
    });
  }

  async function deleteEditproj(id) {
    try {
      const response = await APIManager.deleteAPI(deleteProjectAssignment + id);
      if (response === 200) {
        getProjectAssig();
      }
    } catch (e) { }
  }

  const [TeamMemberTab, setTeamMemberTab] = useState(true);
  const [ModulesTab, setModulesTab] = useState(false);
  const [URLsTab, setURLstTab] = useState(false);
  const [maindropselection, setmaindropselection] = useState("URL");
  const [tabledropselection, settabledropselection] = useState("URL");
  const [urlName, setURLName] = useState("");
  const [competitors, setCompetitors] = useState("");
  const [keyWordName, setKeyWordName] = useState("");

  function getUrlList() {
    APIManager.getAPI(
      getUrlProjectID + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        let c = 1;

        response.data.forEach((item) => {
          exportList.push({
            url: item.fieldValue,
          });
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            url: item.fieldValue,
            delete: (
              <div
                className="mx-3"
                onClick={() => {
                  deleteUrl(item.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                  onClick={() => {
                    deleteUrl(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
          c++;
        });
        settaburlList(list);
        setresetTaburllist(list);
        setsearchURLTab(list);
        setUrlExportList(exportList);
      }
    });
  }

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setURLName("");
        getUrlList();
      }
    });
  }

  async function deleteUrl(id) {
    try {
      const response = await APIManager.deleteAPI(deleteUrl + id);
      if (response === 200) {
        getUrlList();
      }
    } catch (e) { }
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        let c = 1;
        response.data.forEach((item) => {
          exportList.push({
            competitors: item.fieldValue,
          });
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            comp: item.fieldValue,
            delete: (
              <div
                className="mx-3"
                onClick={() => {
                  deleteCompetitorsItem(item.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
          c++;
        });
        settabcompList(list);
        setrestTabcomplist(list);
        setsearchCompTab(list);
        setCompExportList(exportList);
      }
    });
  }

  function addCompetitors() {
    APIManager.postAPI(
      createCompetitors,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: removeTrailingSlash(competitors),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setCompetitors("");
        getCompetitorsList();
      }
    });
  }

  async function deleteCompetitorsItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteCompetitors + id);
      if (response === 200) {
        getCompetitorsList();
      }
    } catch (e) { }
  }

  function getKeyWordList() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        let c = 1;
        response.data.forEach((item) => {
          exportList.push({
            keyword: item.fieldValue,
          });
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
            delete: (
              <div
                className=" mx-3"
                onClick={() => {
                  daleteKeyWord(item.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
          c++;
        });
        settabkeywordList(list);
        setrestTabkeywordlist(list);
        setsearchKeywordTab(list);
        setKeyExportList(exportList);
      }
    });
  }

  function addKeyWords() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: keyWordName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        setKeyWordName("");
        getKeyWordList();
      }
    });
  }

  async function daleteKeyWord(id) {
    try {
      const response = await APIManager.deleteAPI(deleteKeyWord + id);
      if (response === 200) {
        getKeyWordList();
      }
    } catch (e) { }
  }

  function getMemberlist(tableList) {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          if (!tableList?.some((x) => x.teammemberId === item.id)) {
            if (item.role?.name !== "Customer") {
              list.push({
                id: item.id,
                name: item.name,
                key: item.name,
                value: item.name,
                label: item.name,
                role: item?.role?.name,
              });
            }
          }
        });
        setTeamMemberList(list);
      }
    });
  }

  function getmodulelist(tabelList) {
    APIManager.getAPI(getModule).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {          
          if (!tabelList?.some((x) => x.moduleId === item.id)) {
            list.push({
              id: item.id,
              value: item.name,
              label: item.name,
            });
          }
        });
        setoptionSelectedmodule([]);
        setcolormodule(list);
      }
    });
  }

  function addmodule() {
    let moduleObj = [];
    let arrayOfObj = optionSelectedmodule;
    arrayOfObj.forEach((item) => {
      moduleObj.push({
        moduleIds: item.id,
      });
    });
    APIManager.postAPI(
      createProjectModule,
      {
        projectId: localStorage.getItem("projectGId"),
        moduleIds: selectedIDs,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        getAddmodulelist();
      }
    });
  }

  async function deleteprojectmodlue(id) {
    try {
      const response = await APIManager.deleteAPI(deleteprojectModule + id);
      if (response === 200) {
        getAddmodulelist();
      }
    } catch (e) { }
  }

  function getAddmodulelist() {
    let projectId = localStorage.getItem("projectGId");
    const url = `${getProjectModuleByProjectId}${projectId}`;
    APIManager.getAPI(url, true).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          list.push({
            id: item.id,
            modulename: item.name,
            moduleId: item.moduleId,
          });
        });
        setmodulelist(list);
        getmodulelist(list);
      }
    });
  }

  function filterAssignList(textData) {
    let list = [...restTabteamlist];
    setteamList(
      list.filter((assign) =>
        assign.teammember
          ?.toLocaleLowerCase()
          .includes(textData.toLocaleLowerCase())
      )
    );
    if (textData === "" && textData?.length === 0) {
      setteamList(searchdata);
    }
  }

  function filterUrlList(textData) {
    let list = [...resetTaburllist];
    settaburlList(
      list.filter((url) =>
        url.url.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
    if (textData === "" && textData?.length === 0) {
      setteamList(searchdataURLTab);
    }
  }

  function filterCompetetorList(textData) {
    let list = [...restTabcomplist];
    settabcompList(
      list.filter((comp) =>
        comp.comp.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
    if (textData === "" && textData?.length === 0) {
      setteamList(searchdataCompTab);
    }
  }

  function filterKeywordList(textData) {
    let list = [...restTabkeywordlist];
    settabkeywordList(
      list.filter((key) =>
        key.keyword.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
    if (textData === "" && textData?.length === 0) {
      setteamList(searchdataKeywordTab);
    }
  }

  function uploadUrlFile() {
    let input = inputFileRef.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid === true) {
      urlImport(input.files.item(0));
    }
  }

  function urlImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      urlImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then(() => { });
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function uploadCompFile() {
    let input = inputFileRefcomp.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid === true) {
      compImport(input.files.item(0));
    }
  }

  function compImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      competitorsImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then(() => { });
  }

  const inputFileRefcomp = useRef(null);
  const onBtnClickcomp = () => {
    inputFileRefcomp.current.click();
  };

  function uploadKeywordFile() {
    let input = inputFileRefkey.current;
    const fileExtension = input.files[0].name.split(".").at(-1);
    let isvalid = isValidImportFile(fileExtension)
    if (isvalid === true) {
      keyImport(input.files.item(0));
    }
  }

  function keyImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      keyWordImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then(() => { });
  }

  const inputFileRefkey = useRef(null);
  const onBtnClickkey = () => {
    inputFileRefkey.current.click();
  };

  function isValidURL() {
    let error = { ...urlerrors };
    urlName.trim() === ""
      ? (error["urlName"] = "URL is required!")
      : delete error["urlName"];

    if (urlName?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"

      );

      if (!pattern.test(urlName)) {
        error["urlName"] = "Please enter valid URL";
      } else {
        delete error["urlName"];
        let isEqualItem = false;
        taburllist.forEach((item) => {
          if (item.url === urlName) {
            isEqualItem = true;
          }
        });
        if (isEqualItem) {
          error["urlName"] = "URL is already exist!";
        } else {
          delete error["urlName"];
        }
      }
    }
    seturlErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function isValidCOMP() {
    let error = { ...comperrors };
    competitors.trim() === ""
      ? (error["competitors"] = "Competitors is required!")
      : delete error["competitors"];

    if (competitors?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );

      if (!pattern.test(competitors)) {
        error["competitors"] = "Please enter valid Competitors";
      } else {
        delete error["competitors"];
        let isEqualItem = false;
        tabcomplist.forEach((item) => {
          if (item.comp === competitors) {
            isEqualItem = true;
          }
        });
        if (isEqualItem) {
          error["competitors"] = "The Competitor is already added in the list";
        } else {
          delete error["competitors"];
        }
      }
    }
    setcompErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function isValidKeyword() {
    let error = { ...keyworderrors };

    keyWordName.trim() === ""
      ? (error["keyWordName"] = "Keyword is required!")
      : delete error["keyWordName"];

    setkeywordErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }
  let onClickFunction = (arg) => {
    setSetting(arg);
    getUrlList();
    getCompetitorsList();
    getKeyWordList();
    getProjectAssig();
  };
  function getComponent() {
    if (settings === "editProjectAssignTeamMember") {
      return <EditprojectAssignTeamMembers update={onClickFunction} />;
    }
  }

  return (
    <>
      {settings === "editProjectDir" ? (
        <>
          <div className="slideInUp">
            <div className="portlet ms-3 me-3 mb-0 ">
              <div class="portlet-header portlet-header-bordered">
                <svg
                  onClick={() => update("")}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-left close-left-icon"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
                <h3 class="portlet-title main-title">
                  {" "}
                  {localStorage.getItem("projectGName")} - Assign Team Members{" "}
                </h3>
              </div>

              <div className="row project-dir p-0">
                <Tabs>
                  <div className="portlet-header portlet-header-bordered ">
                    <TabList className="nav nav-lines portlet-nav">
                      <Tab
                        className={
                          TeamMemberTab
                            ? "nav-item nav-link  active "
                            : "nav-item nav-link "
                        }
                        onClick={() => {
                          setModulesTab(false);
                          setURLstTab(false);
                          setTeamMemberTab(true);
                        }}
                      >
                        Team Members
                      </Tab>
                      <Tab
                        className={
                          ModulesTab
                            ? "nav-item nav-link mx-3 active"
                            : "nav-item nav-link mx-3"
                        }
                        onClick={() => {
                          setModulesTab(true);
                          setURLstTab(false);
                          setTeamMemberTab(false);
                        }}
                      >
                        Modules
                      </Tab>
                      <Tab
                        className={
                          URLsTab
                            ? "nav-item nav-link active"
                            : "nav-item nav-link  "
                        }
                        onClick={() => {
                          setModulesTab(false);
                          setTeamMemberTab(false);
                          setURLstTab(true);
                        }}
                      >
                        Url, Competitors & Keywords
                      </Tab>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div class="row">
                      <div
                        class="add-new-btnw"
                        style={{ textAlign: "left" }}
                      ></div>
                      <div className=" px-0 confi-assign-tm">
                        <div class="portlet-header portlet-header-bordered px-0">
                          <h3 class="portlet-title main-title">
                            Assign Team Members
                          </h3>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <div className="col-md-5  d-flex align-items-center justify-content-start edit-project-dir">
                              <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                  <i class="fa fa-search text-primary"></i>
                                </div>
                                <div
                                  class="input-group"
                                  style={{ display: "block" }}
                                >
                                  <Input
                                    allowClear
                                    type="text"
                                    style={{ height: 40 + "px" }}
                                    class="form-control"
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterAssignList(nameSearch.target.value);
                                    }}
                                    id="input-s"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-7 d-flex align-items-center justify-content-end react-btn-box">
                              <div className="assign-search align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-start ">
                                  <div className="d-block w-100">
                                  <ReactSelect
                                  className="w-100"
                                  options={teamMemberList}
                                  isMulti={false}
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option,
                                  }}
                                  styles={themeStyle()}
                                  onChange={handleChange}
                                  allowSelectAll={true}
                                  value={optionSelected}
                                />
                                    <div
                                  class="ms-2 pt-2"
                                  style={{ color: "red" }}
                                >
                                  {assignMsg}
                                </div>
                                  </div>
                                  <button   
                                  onClick={() => {
                                    if (optionSelected.value) {
                                      setAssignMsg("")
                                      setSetting("editProjectAssignTeamMember");
                                      assign();
                                    }
                                    else {
                                      setAssignMsg("Please select team member!")
                                    }
                                  }}
                                  class="btn btn-primary my-0 ms-2">
                                  Assign
                                </button>
                                  </div>
                                </div>
                                </div>
                            </div>
                          </div>

                          <div class="common-table  mt-3 p-0">
                            <Table
                              className="table table-bordered table-hover assign-team-member-table"
                              id="datatable-1"
                              columns={teamcol}
                              dataSource={teamlist}
                              rowSelection={{
                                type: "checkbox",
                                ...rowSelection,
                              }}
                              pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div class="portlet-header portlet-header-bordered">
                      <h3 class="portlet-title main-title ps-0">Project Modules</h3>
                    </div>
                    <div className="portlet-body confi-assign-module">
                      <div className="d-flex justify-content-start flex-wrap">
                        <div className="search-box me-3">
                          <ReactSelect
                            placeholder="Select or search module"
                            options={colourOptionsmodule}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            components={{
                              Option,
                            }}
                            styles={themeStyle()}
                            onChange={handleChangemodule}
                            allowSelectAll={true}
                            value={optionSelectedmodule}
                          />
                        </div>
                        <div className=" bttn editproject-btn">
                          <button
                            onClick={() => addmodule()}
                            class="btn btn-primary"
                            style={{ padding: "9px 10px" }}
                          >
                            ADD MODULE
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <table
                          className="table table-bordered table-hover mt-3 "
                          id="datatable-1"
                        >
                          <thead>
                            <tr>
                              <th width="1%">#</th>
                              <th width="70%">Module Name</th>
                              <th width="30%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {modulelisr.map((i, index) => {
                              return (
                                <>
                                  <tr>
                                    <th width="1%">{index}</th>
                                    <th width="100%">{i.modulename}</th>
                                    <th width="20%">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-trash-2 trash-icon mx-3"
                                        onClick={() => {
                                          deleteprojectmodlue(i.id, i.index);
                                        }}
                                      >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line
                                          x1="10"
                                          y1="11"
                                          x2="10"
                                          y2="17"
                                        ></line>
                                        <line
                                          x1="14"
                                          y1="11"
                                          x2="14"
                                          y2="17"
                                        ></line>
                                      </svg>
                                    </th>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div class={"none"}>
                        <div class="common-wrapper">
                          <div class="common-wcard">
                            <div class="common-form-fields">
                              <div class="add-user">
                                <div className="da">
                                  <div class="form-wrappers">
                                    <label>DA Score</label>
                                    <input
                                      type="text"
                                      name=""
                                      placeholder="Value"
                                      disabled
                                    />
                                  </div>
                                  <div class="form-wrappers">
                                    <label>PA Score</label>
                                    <input
                                      type="text"
                                      name=""
                                      placeholder="Value"
                                      disabled
                                    />
                                  </div>
                                  <div class="form-wrappers">
                                    <label>Spam %</label>
                                    <input
                                      type="text"
                                      name=""
                                      placeholder="Value"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div class="form-wrappers">
                                  <label>Url's</label>
                                  <div>
                                    <select name="URL[]" multiple id="URL">
                                      <option value="Website Authority">
                                        Website Authority
                                      </option>
                                      <option value="Monal">Monal</option>
                                      <option value="Rahul">Rahul</option>
                                    </select>
                                    <button id="addinp">
                                      <i class="fa fa-plus-circle"></i>
                                    </button>
                                  </div>
                                  <div id="urlinputbox">
                                    <div class="form-wrappers">
                                      <label></label>
                                      <input
                                        type="text"
                                        name=""
                                        placeholder="Value"
                                        id="inputaddurl"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <ul id="urlinputs"></ul>
                                  </div>
                                </div>
                                <div class="form-wrappers">
                                  <label>Competitors</label>
                                  <div>
                                    <select
                                      name="Competitors[]"
                                      multiple
                                      id="Competitors"
                                    >
                                      <option value="Website Authority">
                                        Website Authority
                                      </option>
                                      <option value="Monal">Monal</option>
                                      <option value="Rahul">Rahul</option>
                                    </select>
                                    <button id="addinp">
                                      <i
                                        class="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  <div id="compinputbox">
                                    <div class="form-wrappers">
                                      <label></label>
                                      <input
                                        type="text"
                                        name=""
                                        placeholder="Value"
                                        id="inputaddcomp"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <ul id="compinputs"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul class="form-action-wrapper">
                            <li>
                              <a class="ol-btn">Cancel</a>
                            </li>
                            <li>
                              <a class="btn btn-primary">Save</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div class="portlet-header portlet-header-bordered">
                      <h3 class="portlet-title main-title">
                        Url, Competitors & Keywords
                      </h3>
                    </div>
                    <div className="portlet-body px-2">
                      <div className="row tab-panel-url my-3">
                        <div className="col-md-4">
                          <select
                            name=""
                            className="form-select w-50"
                            id="select-type"
                            value={maindropselection}
                            onChange={() => {
                              seturlErrors({});
                              setcompErrors({});
                              setkeywordErrors({});                   
                              setmaindropselection(
                                document.getElementById("select-type").value
                              );
                            }}
                          >
                            {uckList.map((i) => {
                              return <option value={i.value}>{i.label}</option>;
                            })}
                          </select>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4 ">
                          <div className="d-flex res-mt-3 justify-content-between">
                            {maindropselection === "URL" ? (
                              <>
                                <span className="w-100">
                                  <input
                                    className="edit-url w-100"
                                    style={{ height: 36 + "px" }}
                                    type="text"
                                    placeholder="Enter URL"
                                    value={urlName}
                                    onChange={(text) => {
                                      isValidURL();
                                      setURLName(text.target.value);
                                    }}
                                  />
                                </span>
                                <span>
                                  <button
                                    class="btn btn-primary ms-3"
                                    style={{
                                      height: 36 + "px",
                                      width: 70 + "px",
                                    }}
                                    onClick={() => {
                                      seturlsave(true);
                                      if (isValidURL()) {
                                        addUrl();
                                      }
                                    }}
                                  >
                                    Add
                                  </button>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                            {maindropselection === "Competitors" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <span className="w-100">
                                    <input
                                      className="edit-url w-100"
                                      style={{ height: 36 + "px" }}
                                      type="text"
                                      placeholder="Enter Competitors"
                                      value={competitors}
                                      onChange={(text) => {
                                        isValidCOMP();
                                        setCompetitors(text.target.value);
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <button
                                      class="btn btn-primary ms-3"
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                      onClick={() => {
                                        setcompsave(true);
                                        if (isValidCOMP()) {
                                          addCompetitors();
                                        }
                                      }}
                                    >
                                      Add
                                    </button>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {maindropselection === "Keywords" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    width: 100 + "%",
                                  }}
                                >
                                  <span className="w-100">
                                    <input
                                      className="edit-url w-100"
                                      style={{ height: 36 + "px" }}
                                      type="text"
                                      placeholder="Enter Keywords"
                                      value={keyWordName}
                                      onChange={(text) => {
                                        setkeywordsave(true);
                                        setKeyWordName(text.target.value);
                                        isValidKeyword();
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <button
                                      class="btn btn-primary ms-3"
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                      onClick={() => {
                                        if (isValidKeyword()) {
                                          addKeyWords();
                                        }
                                      }}
                                    >
                                      Add
                                    </button>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            {urlsave && urlerrors?.urlName && (
                              <>
                                <div className="field-required mt-2">
                                  <span> {urlerrors?.urlName}</span>
                                </div>
                              </>
                            )}
                          </div>
                          <div>
                            {compsave && comperrors?.competitors && (
                              <>
                                <div className="field-required">
                                  <span> {comperrors?.competitors}</span>
                                </div>
                              </>
                            )}
                          </div>
                          <div>
                            {keywordsave && keyworderrors?.keyWordName && (
                              <>
                                <div className="field-required">
                                  <span> {keyworderrors?.keyWordName}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {tabledropselection === "URL" ? (
                        <>
                          <div className="row">
                            <div className="col-md-4 d-flex justify-content-start ">
                              <div class="input-group-icon input-group-lg widget15-compact  ">
                                <div class="input-group-prepend">
                                  <i class="fa fa-search text-primary"></i>
                                </div>
                                <span>
                                  <Input
                                    allowClear
                                    type="text"
                                    class="form-control"
                                    style={{ height: 36 + "px" }}
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterUrlList(nameSearch.target.value);
                                    }}
                                    id="input-s"
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="d-flex justify-content-end editurl-tab res-mt-3">
                                <div className="tab-panel-url-options left-side d-flex me-3 mb-0">
                                  <span>
                                    <select
                                      class="form-select w-100 mx-2"
                                      style={{
                                        borderRadius: "0px",
                                        height: 36 + "px",
                                      }}
                                      name=""
                                      id="select-type-table"
                                      value={tabledropselection}
                                      onChange={() => {
                                        settabledropselection(
                                          document.getElementById(
                                            "select-type-table"
                                          ).value
                                        );
                                      }}
                                    >
                                      {uckList.map((i) => {
                                        return (
                                          <option value={i.value}>
                                            {i.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </span>
                                </div>
                                <div className="right-side">
                                  <span>
                                    <button
                                      className="btn btn-primary me-3"
                                      onClick={onBtnClick}
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                    >
                                      Import
                                    </button>
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      id="urlFile"
                                      name="urlFile"
                                      multiple={false}
                                      onChange={() => {
                                        uploadUrlFile()
                                      }}
                                      ref={inputFileRef}
                                      accept={documentFileType}
                                    />
                                  </span>
                                  <span class="dropdown edit-export">
                                    <button
                                      class="btn btn-primary dropdown-toggle "
                                      type="button"
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedUrlExportList?.length > 0
                                            ? selectedUrlExportList
                                            : urlexportList,
                                          "Url"
                                        );
                                      }}
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                    >
                                      Export
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Table
                            className="table table-bordered table-hover mt-3 assign-team-url-table"
                            id="datatable-1"
                            columns={URLTabcol}
                            dataSource={taburllist}
                            rowSelection={{
                              type: "checkbox",
                              ...urlrowSelection,
                            }}
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {tabledropselection === "Competitors" ? (
                        <>
                          <div className="row">
                            <div className="col-md-4 d-flex justify-content-start ">
                              <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                  <i class="fa fa-search text-primary"></i>
                                </div>
                                <span>
                                  <Input
                                    allowClear
                                    type="text"
                                    class="form-control"
                                    style={{ height: 36 + "px" }}
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterCompetetorList(
                                        nameSearch.target.value
                                      );
                                    }}
                                    id="input-s"
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="d-flex justify-content-end editurl-tab">
                                <div className="tab-panel-url-options left-side d-flex mb-0">
                                  <span>
                                    <select
                                      class="form-select w-100 mx-2"
                                      style={{
                                        borderRadius: "0px",
                                        height: 36 + "px",
                                      }}
                                      name=""
                                      id="select-type-table"
                                      value={tabledropselection}
                                      onChange={() => {
                                        settabledropselection(
                                          document.getElementById(
                                            "select-type-table"
                                          ).value
                                        );
                                      }}
                                    >
                                      {uckList.map((i) => {
                                        return (
                                          <option value={i.value}>
                                            {i.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </span>
                                </div>
                                <div className="right-side">
                                  <span className="ms-2">
                                    <button
                                      className="btn btn-primary mx-2 ms-2"
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                      onClick={onBtnClickcomp}
                                    >
                                      Import
                                    </button>
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      id="compFile"
                                      name="compFile"
                                      multiple={false}
                                      onChange={uploadCompFile}
                                      ref={inputFileRefcomp}
                                      accept={documentFileType}
                                    />
                                  </span>
                                  <span class="dropdown">
                                    <button
                                      class="btn btn-primary dropdown-toggle"
                                      style={{
                                        height: 36 + "px",
                                        width: 70 + "px",
                                      }}
                                      type="button"
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedCompExportList?.length > 0
                                            ? selectedCompExportList
                                            : compexportList,
                                          "Competitors"
                                        );
                                      }}
                                    >
                                      Export
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Table
                            className="table table-bordered table-hover mt-3 assign-team-competitors-table"
                            id="datatable-1"
                            columns={CompTabcol}
                            dataSource={tabcomplist}
                            rowSelection={{
                              type: "checkbox",
                              ...comprowSelection,
                            }}
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {tabledropselection === "Keywords" ? (
                        <>
                          <div className="row">
                            <div className="col-md-4 d-flex justify-content-start ">
                              <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                  <i class="fa fa-search text-primary"></i>
                                </div>
                                <span>
                                  <Input
                                    allowClear
                                    class="form-control"
                                    style={{ height: 36 + "px" }}
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterKeywordList(
                                        nameSearch.target.value
                                      );
                                    }}
                                    id="input-s"
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="d-flex justify-content-end editurl-tab">
                                <div className="tab-panel-url-options left-side d-flex mb-0">
                                  <span className="mx-2">
                                    <select
                                      className="form-select"
                                      name=""
                                      id="select-type-table"
                                      style={{ height: 36 + "px" }}
                                      value={tabledropselection}
                                      onChange={() => {
                                        settabledropselection(
                                          document.getElementById(
                                            "select-type-table"
                                          ).value
                                        );
                                      }}
                                    >
                                      {uckList.map((i) => {
                                        return (
                                          <option value={i.value}>
                                            {i.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </span>
                                </div>
                                <span className="me-2">
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      height: 36 + "px",
                                      width: 70 + "px",
                                    }}
                                    onClick={onBtnClickkey}
                                  >
                                    Import
                                  </button>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="keywordFile"
                                    name="keywordFile"
                                    multiple={false}
                                    accept={documentFileType}
                                    ref={inputFileRefkey}
                                    onChange={uploadKeywordFile}
                                  />
                                </span>
                                <span class="dropdown">
                                  <button
                                    class="btn btn-primary dropdown-toggle"
                                    style={{
                                      height: 36 + "px",
                                      width: 70 + "px",
                                    }}
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedKeyExportList?.length > 0
                                          ? selectedKeyExportList
                                          : keyexportList,
                                        "Keywords"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <Table
                            className="table table-bordered  table-hover mt-3 assign-team-keyword-table"
                            id="datatable-1"
                            columns={KeywordTabcol}
                            dataSource={tabkeywordlist}
                            rowSelection={{
                              type: "checkbox",
                              ...keyrowSelection,
                            }}
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
}

export default EditProjectDir;
