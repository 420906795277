import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  formatdatetime,
  formateDateIntoDatemonth,
  convertDate,
  mmDDyyyyFormateDateSet,
  formateDateTimeIntoDatemonthTime
} from "../../utility/Globals";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Stepper } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import APIManager from "../../services/APIManager";
import {DDMMMyyyyFormateDateSet} from "../../utility/Globals";
import FeatherIcon from "feather-icons-react";
import { 
  articleDetail, 
  getEditor, 
  getWriter, 
  getTimelineDetails, 
  getProjectById, 
  requirementById, 
  updateRequirementStatus, 
  articleStatus, 
  AssignWriter,
  AssignEditor,
  articleStatusBar,
  emailForApprovalCMS
} from "../../utility/Constant";
import { Drawer } from "antd";
import { default as ReactSelect } from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";

const RequirementKeywordDetails = (props) => {
  const location = useLocation();
  const id = location.state.requirementId;
  const sidebaractive =  location?.state?.active;
  const requirementId = location.state.requirementId;
  const [projectname, setProjectName] = useState();
  const [keywordId, setKeywordId] = useState(); 
  const [status, setStatus] = useState();
  const [ArticleTab, setArticleTab] = useState(true);
  const [EditorTab, setEditorTab] = useState(false);
  const [TimelineTab, setTimelineTab] = useState(false);
  const navigate = useNavigate();
  const [primaryKeyword, setPrimaryKeyword] = useState('');
  const [editorDetailList, setEditorDetailList] = useState();
  const [writreDetailList, setWritorDetailList] = useState();
  const [keywordDetailList, setKeywordDetailList] = useState();
  const [size, setSize] = useState();
  const [writeropen, setWriterOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [sizeEdit, setSizeEdit] = useState();
  const [writerList, setWriterList] = useState([]);
  const [cost, setCost] = useState(0);
  const [eta, setETA] = useState("");
  const [writerId, setWriterID] = useState('');
  const [editorId, setEditorID] = useState('');
  const [editorList, setEditorList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [isChangeEditor, setIsChangeEditor] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [errors, setErrors] = useState("");
  const [isCostPerWord, setIsCostPerWord] = useState();
  const [writerListSelect, setWriterListSelect] = useState([]);
  const [editorListSelect, setEditorListSelect] = useState([]);
  const[statusDate , setstatusDate] = useState([]);
  const [save, setsave] = useState(false);
  const [projectId, setProjectId] = useState("");
  const showLargeDrawer1 = () => {
    setSize("large");
    setWriterOpen(true);
  };

  const showLargeDrawerEdit = () => {
    setSizeEdit("large");
    setEditorOpen(true);
  };

  const onClose = () => {
    setWriterOpen(false);
    setEditorOpen(false);
    clearForm();
  };

  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #212121 ",
      boxShadow: 'none',
      // height: 38,
      // minHeight: 38,
      fontSize: '1rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 7px',
      // bottom: '1px',
      Margin: '0'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121"
    }),
    input: (provided) => ({
      ...provided,
      height: '38px',
      Padding: '0',
      Margin: '0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
      Margin: '0'
      // height: '25px',
      // marginTop: "-12px"
    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0',
      // height: '100px'
    }),
  };


  const ref = useRef();
  const [view, setview] = useState();
  const steps = [
    {
      value : 0,
      status: "Assign Writer",
    },
    {
      value: 1,
      status: (status == 2) ?
        <>
          Writing in progress
          <div className="text-center mt-2 px-4">
            <p className="fs-14 fw-6  rework">Rework</p>
          </div>
        </>
        : "Writing in progress"
    },
    {
      value: 3,
      status: "Awaiting QC",
    },
    { 
      value: 4,
      status: "Assign Editor",
    },
    {
      value: 5 ,
      status: (status == 6) ?
        <>
          Editing in Progress
          <div className="text-center mt-2 px-4">
            <p className="fs-14 fw-6  rework">Rework</p>
          </div>
        </>
        : "Editing in Progress"
    },
    {
      value: 7,
      status: "Awaiting Cross QC",
    },
    {
      value: 8,
      status: "Sent for Approval",
    },
    {
      value: 9,
      status: "Article Completed",
    },
  ];

  useEffect(() => {
    getRequirementDetail();
    getArticleDetailsAPI();
    setTimelineAPI();
    getRequirementStatusTime();
  }, [])

  const getArticleDetailsAPI = () => {
    APIManager.postAPI(articleDetail, { 'RequirementId': id }, {}, true).then((response) => {
      if (response && response.status == 200) {
        setEditorDetailList(response.data.editorDetailsResponse[0]);
        setWritorDetailList(response.data.writerDetailsResponse[0]);
        setKeywordDetailList(response.data.keywordDetailsResponse[0]);
        setPrimaryKeyword(response.data.keywordDetailsResponse[0].primaryKeywords)
        setETA(response.data?.writerDetailsResponse[0]?.eta)
        setCost(response.data?.writerDetailsResponse[0]?.cost)
        setWriterID(response.data?.writerDetailsResponse[0]?.writerId)
        setEditorID(response.data?.editorDetailsResponse[0]?.editorId)
      }
    })
  }

  const getAllWriter = () => {
    APIManager.getAPI(
      getWriter + '?organizationId=' + localStorage.getItem('organizationId') + "&requirementId=" + id
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
            costPerWord: item.costPerWord,
            costPerArticle: item.costPerArticle,
            eta : item.eta
          };
          if (item.id == writerId) {
            setWriterListSelect(obj);
            setCost(item.isCostPerWord == null ? 0 : item.isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
            setIsCostPerWord(item.isCostPerWord);
            setETA(item.eta ?? new Date());
          }
          list.push(obj);
        });
        setWriterList(list);
      }
    });
  }

  const getAllEditor = () => {
    APIManager.getAPI(
      getEditor + '?organizationId=' + localStorage.getItem('organizationId') + "&requirementId=" + id
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
            costPerWord: item.costPerWord,
            costPerArticle: item.costPerArticle,
            eta : item.eta
          };
          if (item.id == editorId) {
            setEditorListSelect(obj);
            setCost(item.isCostPerWord == null ? 0 : item.isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
            setIsCostPerWord(item.isCostPerWord);
            setETA(item.eta ?? new Date());
          }
          list.push(obj);
        });
        setEditorList(list);
      }
    })
  }

  function clearForm() {
    setIsCostPerWord(false);
    setWriterListSelect("");
    setEditorListSelect("");
    setCost("");
    setETA("");
    setErrors([]);
  }

  function setTimelineAPI() {
    APIManager.getAPI(getTimelineDetails + id).then((response) => {
      if (response && response.status == 200) {
        setTimeline(response.data.reverse());

      }
    })
  }

  function updateWriterData() {
    APIManager.postAPI(AssignWriter, {
      RequirementId: id,
      WriterId: writerListSelect.value,
      IsCostPerWord:isCostPerWord ? 1 : 0,
      Cost: parseFloat(cost),
      ETA: eta,
      CreatedBy: localStorage.getItem('userId'),
      status: 1
    }, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setIsChange(false);
          getArticleDetailsAPI();
          setTimelineAPI();
          onClose();
        }
      }
    );

  }

  function updateEditorData() {
    APIManager.postAPI(AssignEditor, {
      RequirementId: id,
      EditorId: editorListSelect.value,
      IsCostPerWord: isCostPerWord ? 1 : 0,
      Cost:parseFloat(cost),
      ETA: eta,
      CreatedBy: localStorage.getItem('userId'),
      status: 5
    }, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setIsChangeEditor();
          getArticleDetailsAPI();
          setTimelineAPI();
          onClose();
        }
      }
    );
  }

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + id).then((res) => {
      getProjectByID(res.data?.value?.projectId);
      setStatus(res.data?.value?.status);
      setKeywordId(res.data.value.keyNumber);
      setview((res.data.value.status > 1) ? (res.data.value.status > 5) ? res.data.value.status - 2 : res.data.value.status - 1 : res.data.value.status);
    });
  }

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setProjectName(res.data.value?.projectCode);
      setProjectId(id);
    })
  }

  const requirementStatusUpdate = (status) => {
    let rawData = {
      RequirementId: requirementId,
      status: status,
      userId: localStorage.getItem("userId"),
      isClient: localStorage.getItem("userDatadepartmentName") == "	Customer" ? 1 : 0,
    };
    APIManager.putAPI(updateRequirementStatus, rawData, {}, true).then((res) => {
      if (status != 1 && status != 5) {
        if (status === 8) {
          sendForApprovalFun(requirementId)
        }
        {sidebaractive == 2 ?  localStorage.setItem("active", 2) :
        localStorage.setItem("active", 1)}
        navigate("/content-main/requirement-details",{
          state: { requirementId: requirementId, projectId: projectId },
        });
      }
    })
  }

  function sendForApprovalFun(requirementId) {
    APIManager.getAPI(emailForApprovalCMS + requirementId).then(
      (response) => {
        if (response && response.status == 200) {

        }
      }
    );
  }

  const getRequirementStatusTime = () => {
    APIManager.getAPI(articleStatusBar + "?requirementId=" + id).then((response) => {
      if(response && response.status == 200)
      {
        setstatusDate(response.data);
      }
    })
  }

  function formValidation() {
    let error = { ...errors };
    eta?.trim() == ""
      ? (error["eta"] = "ETA is required!")
      : delete error["eta"];

    cost == 0
      ? (error["cost"] = "Cost Number is required!")
      : !cost.toString().match(/^[0-9.]+$/)
      ? (error["cost"] = "Cost Number is Number only!")
      :(/^\d{0,5}(\.\d{0,2})?$/i).test(cost)
      ? delete error["cost"]
      : error["cost"] = "Number should have follow pattern i.e. 12345.23";

    if(editorId == ''){
    !writerId || writerId == '' 
    ? (error["writer"] = "select Writer!")
    : delete error["writer"];
    }

    if(writerId == ''){
    editorId == '' || !editorId
    ? (error["editor"] = "select Editor!")
    : delete error["editor"];}

    let err = Object.keys(error);
    setErrors(error);
    return err?.length ? false : true;
  }

  return (
    <>
      <div className="breadcum_top bg-white p-3 cms-breadcum" onClick={() => { navigate(-1) }}>
        <small class="cms_fs16 cms_fw600 cms_fc_black mx-2">{projectname}</small>/<small class="cms_fs16 cms_fw600 cms_fc_black mx-2 ">#{keywordId}</small>/<small class="cms_fs16 cms_fw600 cms_fc_black ms-2 ">{primaryKeyword}</small></div>
      <div class="portlet portlet-header portlet-header-bordered  assign-breadcum mt-4 px-0">
        <div ref={ref} className="px-0 py-2 pb-20 m-25 w-100 cms-stepper">
          <Stepper
            className="w-100 m-auto mt-40"
            activeStep={view}
            alternativeLabel
          >
            {steps?.map((label, index) => (
              <Step key={label.status}>
                <StepLabel>{label.status}</StepLabel>
                {(index < view) ?
                  <>
                    {statusDate.map((date)=>{
                      return(
                        <>
                         {date.status == label.value ? <><div className="text-center mt-2">
                          <p className="cms_fs14 cms_fw600 cms_fc_gray assign-date mb-0">{formateDateTimeIntoDatemonthTime(date.createdAt)}</p>
                        </div></> : ''}
                        </>
                      )
                    })}
                    {/* <div className="text-center mt-2">
                      <p className="fw-6 fs-14 assign-date mb-0">14 Jan 2022</p>
                      <p className="fw-6 fs-14 assign-date">2:00 pm</p>
                    </div> */}


                  </>
                  :
                  <></>
                }

              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div>
        <Tabs>
          <div className="portlet bg-white h-100">
            <div className="portlet-header portlet-header-bordered">
              <div className="col-md-12 px-0">
                <TabList className="nav nav-lines portlet-nav ">
                  <Tab className={
                    ArticleTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(false);
                      setTimelineTab(false);
                      setArticleTab(true);
                    }}>Article Details</Tab>
                  <Tab className={
                    EditorTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(true);
                      setTimelineTab(false);
                      setArticleTab(false);
                      {sidebaractive == 2 ?  localStorage.setItem("active", 2) :
                        localStorage.setItem("active", 1)}
                      navigate(`/content-main/editorContent?requirementid=${id}`)
                    }}>
                    <>
                      {/* <Link className="editor-link cursor-pointer"
                        to={{
                          pathname: "/content-main/editorContent/" + "?requirementId="+id,
                          state: { requirementid: id, keywordid: keywordid }
                        }}
                      > */}
                        Editor
                      {/* </Link> */}
                    </>
                  </Tab>
                  <Tab className={
                    TimelineTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(false);
                      setTimelineTab(true);
                      setArticleTab(false);
                    }}>Timeline</Tab>
                </TabList>
              </div>
            </div>

            <TabPanel>
              <div className="seo-accordian-box keyword-details-accordian  border-dark px-3 my-3 ">
                <div class="d-flex align-items-center justify-content-between accordian-title  portlet-title cms_bg_light-blue cms_fs18 cms_fw600 p-15">
                  Keyword Details
                </div>
                <div className="assign-details-table bg-white border ">
                  <div class="row">
                    <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark ">Primary Keyword</div>
                    <div class="col-md-10 d-flex align-items-center flex-wrap py-2 px-3">
                    <div className="d-flex align-items-center flex-wrap">
                      <span className="website-btn py-1 px-2 me-3 cms_fs16 cms_fw500 cms_fc_dark ">
                        {keywordDetailList?.primaryKeywords}</span>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">Secondary Keyword</div>
                    <div class="col-md-10 d-flex align-items-center flex-wrap py-2 px-3">
                      {
                        keywordDetailList?.secondaryKeywords?.map((item) => {
                          return ( <div className="d-flex align-items-center flex-wrap"><span className="website-btn py-1 px-2 me-3 cms_fs16 cms_fw500 cms_fc_dark "> {item.secondaryKeyword} </span> </div>);
                        })
                      }
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2  article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">Include Keyword</div>
                    <div class="col-md-10 d-flex align-items-center flex-wrap py-2 px-3">
                      {keywordDetailList?.includeKeyword?.map((item) => {
                        return (<div className="d-flex align-items-center flex-wrap"> <span className="website-btn py-1 px-2 me-3 cms_fs16 cms_fw500 cms_fc_dark ">{item.includeKeyword}</span> </div>);
                      })
                      }
                    </div>
                  </div>
                  <div class="row assign-url">
                    <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">URL</div>
                    <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">
                      {keywordDetailList?.url}
                    </div>
                  </div>
                  <div class="row assign-ur">
                    <div class="col-md-2 article_detils_keyword  py-2 cms_fs16 cms_fw500 cms_fc_dark">Category</div>
                    <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">
                      {keywordDetailList?.category}
                    </div>
                  </div>
                </div>
              </div>
              {writreDetailList ?
                <>
                  <div className="seo-accordian-box keyword-details-accordian border-dark px-3 my-3">
                    <div class="accordian-title  portlet-title px-0">
                      <div className="col-md-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-betwwen cms_bg_light-blue cms_fs18 cms_fw600 ">
                          Writer Details
                        </div>
                        {localStorage.getItem("userDatadepartmentName") == "Content" && localStorage.getItem("userRole") == "Content" ?
                        <div className="d-flex align-items-center justify-content-between">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#424242"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            onClick={() => {
                              if (!isChange) {
                                getArticleDetailsAPI()
                              }
                              showLargeDrawer1()
                              getAllWriter();
                            }}
                            class="feather feather-edit edit-icon me-3">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                            </path></svg>
                        </div>
                        : <></>}
                      </div>
                    </div>
                    <div className="writer-details-table bg-white border">
                      <div class="row ">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">Name</div>
                        <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{writreDetailList?.name}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">Cost</div>
                        <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{writreDetailList?.cost ? "Rs." + writreDetailList?.cost  : 0}{writreDetailList?.isCostPerWord == false &&  writreDetailList?.cost  ? "/word" : "/article"}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">ETA</div>
                        <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{writreDetailList?.eta ? formateDateIntoDatemonth(writreDetailList?.eta) : ''}</div>
                      </div>
                    </div>
                  </div>
                </>
                : ''}
              {editorDetailList ?
                <>
                  <div className="seo-accordian-box keyword-details-accordian border-dark px-3 my-3">
                    <div class="accordian-title  portlet-title px-0">
                      <div className="col-md-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-betwwen cms_bg_light-blue cms_fs18 cms_fw600 ">
                          Editor Details
                        </div>
                        {localStorage.getItem("userDatadepartmentName") == "Content" && localStorage.getItem("userRole") == "Content" ?
                        <div className="d-flex align-items-center justify-content-between">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#424242" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-edit edit-icon me-3"
                            onClick={() => {
                              if (isChangeEditor) {
                                getArticleDetailsAPI()
                              }
                              showLargeDrawerEdit()
                              getAllEditor();
                            }}>
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                            </path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                        </div>
                      : <> </>}
                      </div>
                    </div>
                    <div className="writer-details-table bg-white border">
                      <div class="row">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark ">Name</div>
                        <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{editorDetailList?.name}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">Cost</div>
                        <div class="col-md-10 article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{editorDetailList?.cost ? "Rs." + editorDetailList?.cost : 0}{editorDetailList?.isCostPerWord == false &&  writreDetailList?.cost  ? "/word" : "/article"}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 article_detils_keyword py-2 cms_fs16 cms_fw500 cms_fc_dark">ETA</div>
                        <div class="col-md-10  article_detils_data py-2 px-3 cms_fs16 cms_fw500 cms_fc_dark ">{editorDetailList?.eta ? formateDateIntoDatemonth(editorDetailList?.eta) : ''}</div>
                      </div>
                    </div>
                  </div>
                </> : ''}
            </TabPanel>
            <TabPanel>
              <>
                <Link
                  to={`/content-main/editorContent?requirementid=${id}`}
                />
              </>
            </TabPanel>
            <TabPanel>
              <div className=" row bg-white h-100">
             
                <div className="md-6 ps-10">
                
                  <div class=" editor-timeline-box timeline-box border-0 global-scrollbar mt-3">
                    <div class="timeline-list editor-timelinelist pt-0">
                      <div class="outer">
                        {timeline?.map((item) => {
                          return (
                            <>
                              <div class="card">
                                <div class="icon">
                                  {item.timelineType == "0" ? (
                                    <FeatherIcon
                                      style={{ stroke: "#0691EF" }}
                                      className="timeline_text"
                                      icon="file-text"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {item.timelineType == "2" || item.timelineType == "3" ? (
                                    <FeatherIcon
                                      style={{
                                        stroke: "#0691EF",
                                        width: "17px",
                                      }}
                                      className="mb-3 ms-1"
                                      icon="user-check"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {item.timelineType == "4" ? (
                                    <FeatherIcon
                                      style={{
                                        stroke: "#0691EF",
                                        width: "17px",
                                      }}
                                      className="mb-3"
                                      icon="calendar"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {item.timelineType == "5" ? (
                                    <FeatherIcon
                                      style={{
                                        stroke: "#0691EF",
                                        width: "17px",
                                      }}
                                      className="mb-3"
                                      icon="clock"
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {item.timelineType == "9" ? (
                                    <FeatherIcon
                                      style={{
                                        stroke: "#0691EF",
                                        width: "17px",
                                      }}
                                      className="mb-3"
                                      icon="check"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {item.timelineType == "10" ? (
                                    <FeatherIcon
                                      style={{
                                        stroke: "#0691EF",
                                        width: "17px",
                                      }}
                                      className="mb-3"
                                      icon="x"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  
                                </div>
                                <div class="info mb-0">
                                  <h6 class="cms_timeline_title title fw-bold cms_fc_black mb-1">
                                    {formatdatetime(item.createdAt)}
                                  </h6>
                                  <span class="gray_font cms_fs16 cms_fw500 cms_fc_gray">
                                    {item.comment
                                      ? item.comment
                                      : "No comments"}
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {/* <div className="border-bottom my-3"></div> */}
                </div>
                <div className="col-md-6"></div>
              </div>
              {localStorage.getItem("userDatadepartmentName") == "Content" && localStorage.getItem("userRole") == "Content" ? <>
              {
                (status == 3 || status == 7) ? <>
                  <div className="row mb-4 mt-2 ms-5 ps-3 cms_timeline_box ">
                    <div className="col-md-5  cms-timeline-border p-3 ms-5 cms_content_timeline_block">
                      <div className="d-flex align-items-center justify-content-between cms_timeline_response flex-wrap">
                        <div className="d-flex align-items-center justify-content-between timeline_content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text mb-2 me-3">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                          </svg>
                          <label className="d-flex align-items-center fc-black1 fs-18 ">{primaryKeyword}</label>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <button className="d-flex align-items-center view-content  bg-white ">
                            <span className="fs-6 font_wegt"  onClick={()=>{
                              navigate(`/content-main/editorContent?requirementid=${id}`)
                            }}>
                              View Content
                              {/* <Link className="fs-6 fw-6"
                                to={{
                                  pathname: "/content-main/editorContent",
                                  state: { requirementid: id }
                                }}
                              >
                                View Content
                              </Link> */}
                            </span>

                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-lin ms-2">
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                              <polyline points="15 3 21 3 21 9"></polyline>
                              <line x1="10" y1="14" x2="21" y2="3"></line>
                            </svg>
                          </button>

                        </div>
                      </div>

                      <div className="border-bottom my-3"></div>
                      <div className="row">
                        <div className="d-flex align-items-center justify-content-between  px-0">
                          <div className="col-md-12 d-flex align-items-center justify-content-between timeline_request_rework px-0 ">
                            <div className="col-md-6 timeline_accept d-flex align-items-center justify-content-between ps-0">
                              <button className="btn btn-primary fs-6 fw-6 line-height24 w-100"
                                onClick={() => {
                                  if (status == 3) {
                                      requirementStatusUpdate(4)
                                      setStatus(4);
                                  }
                                  else {
                                    requirementStatusUpdate(8);
                                    setStatus(8);
                                  }
                                }}
                              >
                                Accept Work
                              </button>
                            </div>

                            <div className="col-md-6 pe-0 timeline_rework_btn">
                              <button className="request_btn border-radius6 fs-6 fw-6 w-100"
                                onClick={() => {
                                  if (status == 3) {
                                    requirementStatusUpdate(2);
                                    setStatus(2);
                                  }
                                  else {
                                    requirementStatusUpdate(6);
                                    setStatus(6);
                                  }
                                }}
                              >
                                Request Rework
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                     

                    </div>

                    <div className="col-md-7"></div>
                  </div>
                </> : <></>
              }</> : ''}

            </TabPanel>
          </div>
        </Tabs>
      </div>
      {/* Writer */}
      <Drawer
        title="Assign Writer"
        className=" industries-modal add-industries assign-writer-drawer"
        placement="right"
        size={size}
        onClose={onClose}
        open={writeropen}
      >
        <div
          class="modal-dialog scope-modal industries-form"
          role="document"
        >
          <div class="modal-content">
            <div className="col-md-12  industries-ticket-btn">
              <>
                <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                  <button
                    className="btn btn-outline-secondary close me-3"
                    type="button"
                    onClick={()=>{onClose()}}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary w-auto"
                    onClick={() => {
                      setIsChange(true)
                      if (formValidation()) {
                      updateWriterData();}
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    Assign Writer
                  </button>
                </div>
              </>
            </div>
            <div class="modal-body p-0 drawer-box">
              <div className="portlet common-form-fields writer_assign_input-box border-0">
                <div className="px-0">
                  <div className="portlet-body industries-individual px-1">
                    <div class="col-md-12 writer-assign-input">
                      <label className="form-label mb-0">Writer</label>
                      <ReactSelect className="write-box"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          isMulti={false}
                          // styles={customStyles}
                          styles={customStylesSearch}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          allowSelectAll={true}
                          options={writerList}
                          value={writerListSelect}
                          onChange={(e) => {
                            setWriterListSelect(e);
                            writerList.map((item, index) => {
                              if (
                                e.value == item.value 
                              ) {
                                setCost(isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                                setETA(item?.eta != null ? item.eta : "");
                              }
                            });
                          }
                        }
                        ></ReactSelect>
                          <div className="field-required ms-3 mt-2">
                              <span>{errors?.writer}</span>
                            </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">Cost</label>
                      <input className="w-100 cost-rupee"
                        type="number"
                        value={cost}
                        onChange={(e) => {
                          setCost(e.target.value);
                        }}
                        onBlur={()=>{formValidation()}}
                      />
                      <button className="cost-btn"
                        onClick={() => {
                          setIsCostPerWord(!isCostPerWord);
                          writerList.map((item, index) => {
                            if (item.value == writerListSelect.value) {
                              setCost(!isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                            }
                          });
                        }}
                      >
                        {!isCostPerWord ? "Per word" : "Per Article"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down my-2 ms-1"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </button>
                     
                    </div>
                    <div className="field-required ms-3 mt-2">
                        <span>{errors?.cost}</span>
                    </div>
                    <div class="col-md-12 mt-3 writer-eta">
                      <label className="form-label mb-0">ETA</label>
                      <input className="w-100 "
                        type="date"
                        min={mmDDyyyyFormateDateSet(new Date())}
                        value={writerId ? convertDate(eta) : ""}
                        onChange={(e) => {
                         setETA(e.target.value);
                        }}
                      />
                      {errors?.eta && (
                        <>
                          <div className="field-required ms-3 mt-2">
                            <span>{errors?.eta}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer >

      {/* editor */}
      < Drawer
        title="Assign Editor"
        className=" industries-modal add-industries assign-editor-drawer"
        placement="right"
        size={sizeEdit}
        onClose={onClose}
        open={editorOpen}
      >
        <div
          class="modal-dialog scope-modal industries-form"
          role="document"
        >
          <div class="modal-content">
            <div className="col-md-12  industries-ticket-btn">
              <>
                <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                  <button
                    className="btn btn-outline-secondary close me-3"
                    type="button"
                    onClick={()=>{onClose(); }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary w-auto"
                    onClick={() => {
                      setsave(true);
                      setIsChangeEditor(true)
                      if(formValidation()){
                        updateEditorData()
                      }
                     
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    Assign Editor
                  </button>
                </div>
              </>
            </div>
            <div class="modal-body p-0 drawer-box">
              <div className="portlet common-form-fields writer_assign_input-box border-0">
                <div className="px-0">
                  <div className="portlet-body industries-individual px-1">
                    <div class="col-md-12 writer-assign-input">
                      <label className="form-label mb-0">Editor</label>
                      <ReactSelect className="write-box"
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        isMulti={false}
                        // styles={customStyles}
                        styles={customStylesSearch}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        options={editorList}
                        value={editorListSelect}
                        onChange={(e) => {
                          setEditorListSelect(e);
                          editorList.map((item, index) => {
                            if (
                              e.value == item.value 
                            ) {
                              setCost(isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                              setETA(item?.eta != null ? item.eta : "");
                            }
                          });
                        }
                      }
                      ></ReactSelect>
                      <div className="field-required ms-3 mt-2">
                        <span>{errors?.editor}</span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">Cost</label>
                      <input className="w-100 cost-rupee"
                        type="number"
                        value={cost}
                        onChange={(e) => {
                          setCost(e.target.value);
                        }}
                        
                      />
                      <button className="cost-btn"
                        onClick={() => {
                          setIsCostPerWord(!isCostPerWord);
                          editorList.map((item, index) => {
                            if (item.value == editorListSelect.value) {
                              setCost(!isCostPerWord ? item.costPerArticle ?? 0 : item.costPerWord ?? 0);
                            }
                          });
                        }}
                      >
                        {!isCostPerWord ? "Per word" : "Per Article"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down my-2 ms-1"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </button> 
                      
                    </div>
                    <div className="field-required ms-3 mt-2">
                        <span>{errors?.cost}</span>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">ETA</label>
                      <input className="w-100 "
                      min={mmDDyyyyFormateDateSet(new Date())}
                        type="date"
                        value={editorId ? convertDate(eta) : ""}
                        onChange={(e) => {
                          setETA(e.target.value);
                          
                        }}
                      />
                        {save && errors?.eta && (
                              <>
                                <div className="field-required ms-3 mt-2">
                                  <span>{errors?.eta}</span>
                                </div>
                              </>
                            )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer >
    </>
  );
};

export default RequirementKeywordDetails;
