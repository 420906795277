import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  downloadPDFfile,
  exportToEXCEL,
  getBase64path,
  SiteUpTimeTypeInt,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  getSiteUpTimeFieldTable,
  moduleSiteUptime,
  sendEmailApi,
} from "../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";

function SiteUptimeMonitorTestData() {
  const location = useLocation();
  const navigate = useNavigate();
  const applyBtn = useRef(null);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [noContent, setNoContent] = useState(false);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Site Uptime Reports");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Site UpTime Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    if (location.state) {
      let startDate = location.state.getdata.startDate;
      let endDate = location.state.getdata.endDate;
      localStorage.setItem("fieldName", location.state.item.field)
      setHead1(startDate);
      setHead2(endDate);
      values[0] = startDate;
      values[1] = endDate;

      setValues([new DateObject(startDate), new DateObject(endDate)]);
      let selection23 = {
        selection1: {
          startDate: addDays(new Date(startDate), 0),
          endDate: addDays(new Date(endDate), 0),
          key: "selection1",
        },
        selection2: {
          startDate: addDays(new Date(startDate), 0),
          endDate: addDays(new Date(endDate), 0),
          key: "selection2",
        },
      }

      setState({
        ...state,
        ...selection23,
      });

      let getData = {
        projectId: localStorage.getItem("projectGId"),
        startDate: startDate,
        EndDate: endDate
      }
      getFieldTableData(getData);
    }
  }, []);

  const ref = useRef();

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [refreshTicketData, setRefreshTicketData] = useState(false);

  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  const onChange = (pagination, filters, sorter, extra) => {
    localStorage.setItem("fieldName", filters.type);
    let getData = {
      projectId: localStorage.getItem("projectGId"),
      startDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2)
    }
    getFieldTableData(getData);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  function GetTypeName(val) {
    var finalVal = val;
    SiteUpTimeTypeInt.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }

  function getFieldTableData(data) {
    let getData = data;
    let colms = [];

    APIManager.postAPI(
      getSiteUpTimeFieldTable, getData, null, true
    ).then((response) => {
      if (response && response.status == 200) {
        let i = 0;
        let list = [];
        let excelobj;
        let keys;
        let object1 = []
        let object2 = []
        let object3 = []
        let object4 = []
        let object5 = []

        response.data.map((item) => {
          if (item.type === location.state.item.field) {
            let dataObject =  {
              key: i += 1,
              type: GetTypeName(item.type),
              url: item.url,
              expectedResult1: item.expectedResult1,
              actualResult1: item.actualResult1,
              expectedResult2: item.expectedResult2,
              actualResult2: item.actualResult2,
              expectedResult3: item.expectedResult3,
              actualResult3: item.actualResult3,
              expectedResult4: item.expectedResult4,
              actualResult4: item.actualResult4,
              expectedResult5: item.expectedResult5,
              actualResult5: item.actualResult5,
              results: item.results == 0 ? "Fail" : "Pass"
            }
            Object.keys(dataObject).forEach((key) => {
              if (dataObject[key] == null) {
                delete dataObject[key];
              }
            });
            list.push(dataObject);
            keys = Object.keys(dataObject);
          }
        });
        setteamList(list)

        let type = {
          title: "Type",
          dataIndex: "type",
          key: "type",
        }

        let col1 = {
          title: "Url",
          dataIndex: "url",
          key: "url",
        };

        let colN = {
          title: "Result",
          dataIndex: "results",
          key: "results",
          sorter: true,
          sorter: {
            compare: (a, b) =>
              Sorter.DEFAULT(a.results, b.results),
            multiple: 3,
          },
          render: function (item) {
            return (
              <div className="d-flex justify-content-around align-items-center">{item} {(item == "Fail") ? <i className="fa fa-times" style={{ color: "red" }}></i> : <i className="fa fa-check" style={{ color: "green" }}></i>}</div>
            );
          }
        }
        keys.map((item) => {
          if (item != "url" && item != "type") {
            let value = item.charAt(item.length - 1)
            let text = item.slice(0, -1);  

            if (value == "1") {
              if (text == "expectedResult") {
                object1[0] = {
                  title: "Expected" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
              }
              else if (text == "actualResult")
                object1[1] = {
                  title: "Actual" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
            }
            else if (value == "2") {
              if (text == "expectedResult") {
                object2[0] = {
                  title: "Expected" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
              }
              else if (text == "actualResult")
                object2[1] = {
                  title: "Actual" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
            }
            else if (value == "3") {
              if (text == "expectedResult") {
                object3[0] = {
                  title: "Expected" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
              }
              else if (text == "actualResult")
                object3[1] = {
                  title: "Actual" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
            }

            else if (value == "4") {
              if (text == "expectedResult") {
                object4[0] = {
                  title: "Expected" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
              }
              else if (text == "actualResult")
                object4[1] = {
                  title: "Actual" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
            }
            else if (value == "5") {
              if (text == "expectedResult") {
                object5[0] = {
                  title: "Expected" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
              }
              else if (text == "actualResult")
                object5[1] = {
                  title: "Actual" + " " + getHeading() + " " + value,
                  dataIndex: item,
                  key: item
                }
            }
          }
        })

        colms.push(type)
        colms.push(col1)
        if (object1.length) { colms.push(...object1) }
        if (object2.length) { colms.push(...object2) }
        if (object3.length) { colms.push(...object3) }
        if (object4.length) { colms.push(...object4) }
        if (object5.length) { colms.push(...object5) }
        colms.push(colN)
        setteamcol(colms);

        colms.map((item) => {
          list.map((value) => {
            excelobj = {
              ...excelobj,
              [item.title]: value[item.dataIndex]
            }
          })
        })
        let expList = []
        expList.push(excelobj)
        setNoContent(expList.length ? true : false)
        setExportList(expList);
      }
    })
  }

  function getHeading() {
    let type = location.state.item.field;
    if (type == 0) { return "Title" }
    else if (type == 1) { return "Meta Description" }
    else if (type == 2) { return "H1 Tag" }
    else if (type == 3) { return "Canonical" }
    else if (type == 4) { return "NoIndex Tag" }
    else if (type == 5) { return "Status Code" }
    else if (type == 6) { return "SSL" }
    else if (type == 7) { return "Breadcrumbs" }
    else if (type == 8) { return "Structure Data" }
    else if (type == 9) { return "Hreflang Tag" }
    else if (type == 10) { return "Google Analytics" }
    else if (type == 11) { return "Robots.txt" }
    else if (type == 12) { return "Sitemap" }
  }

  const overalltableSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      let expObj ;
      selectedRows.map((item) => {
          teamcol.map((item) => {
          selectedRows.map((value) => {
            if(value != "key"){
              expObj = {
                ...expObj,
                [item.title]: value[item.dataIndex]
              }
            }
          })
        })
         list.push(expObj);
      });
      console.log(list);
      setSelectedExportList(list);
    },
  };

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleSiteUptime
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("Site UpTime Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  const [SiteUptimeMonitor, setSiteUptimeMonitor] = useState(false);
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  return (
    <>
      <>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between pb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
          </div>
          <Tabs>
            <div className="portlet ">
              <div className="portlet-header portlet-header-bordered my-4">
                <div className="col-md-8 col-sm-12">
                  <TabList className="nav nav-tabs portlet-nav">
                    <Tab
                      className={
                        SiteUptimeMonitor
                          ? "nav-item nav-link "
                          : "nav-item nav-link  active"
                      }
                      onClick={() => {
                        setSiteUptimeMonitor(false);
                      }}
                    >
                      Site Uptime Monitor
                    </Tab>
                  </TabList>
                </div>
                <div
                  ref={ref}
                  class="col-md-4 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {calanderDate(head1, head2)}
                    </button>
                  </div>
                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card  mt-3">
                        <div className="d-flex calendar-flex">
                          <div className="calendar-col">
                            <DateRangePicker
                              onChange={(item) => {
                                let isCompareVarNew = localStorage.getItem("isCompareVar");
                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                    setHead1(new DateObject(item?.selection1?.startDate).format());
                                    setHead2(new DateObject(item?.selection1?.endDate).format());

                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                    setHead1(new DateObject(item?.selection2?.startDate).format());
                                    setHead2(new DateObject(item?.selection2?.endDate).format());
                                  }
                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }
                              }}

                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={[state?.selection1, state?.selection2]}
                              direction="horizontal"
                              ariaLabels={{
                                dateInput: {
                                  selection1: {
                                    startDate: "start date input of selction 1",
                                    endDate: "end date input of selction 1",
                                  },
                                  selection2: {
                                    startDate: "start date input of selction 2",
                                    endDate: "end date input of selction 2",
                                  },
                                },

                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                              }}
                              maxDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate() - 1
                                )
                              }
                            />
                          </div>
                          <div className="mt-3 mt-lg-0 text-center">
                            <button
                              onClick={() => {
                                let dates = [];
                                dates.push(values[0]);
                                dates.push(values[1]);
                                setheadvalues();
                                setOpen(!open);
                                setRefreshTicketData(true);
                                setTimeout(() => {
                                  setRefreshTicketData(false);
                                }, 1000);
                                let getData = {
                                  projectId: localStorage.getItem("projectGId"),
                                  startDate: mmDDyyyyFormateDateSet(head1),
                                  EndDate: mmDDyyyyFormateDateSet(head2)
                                }
                                getFieldTableData(getData);
                              }}
                              className="outline-btn btn btn-primary"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="d-flex 3-icons">
                    <div className="ms-3  ">
                      <a
                        className="share bar-link-icon"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="fa fa-share-alt"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">From</label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Sender Email Id"
                                      value={Sender}
                                      className="w-100 form-control my-2"
                                      name="Sender"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">To</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Recipient Email Id"
                                      className="w-100 form-control my-2"
                                      value={Receiver}
                                      name="Receiver"
                                      onChange={(text) =>
                                        setReceiver(text.target.value)
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageReceiver}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Subject</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="text"
                                      required
                                      placeholder="Subject"
                                      value={subject}
                                      onChange={(text) => {
                                        setSubject(text.target.value);
                                      }}
                                      className="w-100 form-control my-2"
                                      name="Subject"
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageSubject}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Message</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    <textarea
                                      name=""
                                      id=""
                                      cols="80"
                                      rows="7"
                                      className="w-100 form-control my-2 gscmsg"
                                      value={EmailMessage}
                                      onChange={(text) => {
                                        setEmailMessage(text.target.value);
                                      }}
                                    ></textarea>
                                    <div style={{ color: "red" }}>
                                      {Message}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-10 my-2">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{
                                        width: 70 + "px",
                                        height: 30 + "px",
                                      }}
                                      onClick={() => {
                                        if (isValidEmailData()) {
                                          sendEmail();
                                        }
                                      }}
                                    >
                                      {" "}
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                    </div>

                    <div className="row">
                      <div className="col-md-12 download-filter">
                        <span
                          class="dropdown"
                          style={{
                            marginRight: 0 + "px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          <button
                            class="outline-btn dropdown-toggle btn btn-primary"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="fa fa-download "
                              aria-hidden="true"
                            ></i>
                          </button>
                         
                          <div
                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              onClick={async () => {
                                setLoading(true)
                                const promise = await downloadPDFfile(image, moduleSiteUptime)
                                try {
                                  if (promise) {
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                } catch (e) {
                                  setLoading(false);
                                }
                              }}
                            >
                              <a class="dropdown-item">PDF</a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TabPanel>
              <br />
              {noContent ? (
                <>
                  <PDFExport
                    paperSize="A1"
                    margin="2cm"
                    fileName={moduleSiteUptime}
                    ref={pdfExportComponent}
                    forcePageBreak=".page-break"
                  >
                    <div ref={image} >
                      <div
                        className="portlet portlet-header-bordered"
                        id="web-page-pdf"
                      >
                        <div className="portlet">
                          <div className="portlet-body siteup-date">
                            <div className="col-md-12 d-flex justify-content-end mt-3">
                              <span class="dropdown">
                                <button
                                  class="btn btn-primary dropdown-toggle ms-3"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Site Up Time"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                            
                              </span>
                            </div>

                            <div className="col-md-12">
                              <Table
                                className="table table-bordered  table-hover mt-3 siteup-date "
                                id="datatable-1"
                                columns={teamcol}
                                dataSource={teamlist}
                                rowSelection={{
                                  type: "checkbox",
                                  ...overalltableSelection,
                                }}
                                pagination={{
                                  position: ["bottomRight"],
                                  showSizeChanger: true,
                                }}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></PDFExport>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </>
      <Loading isLoading={isLoading} />
    </>
  );
}

export default SiteUptimeMonitorTestData;

