/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react";
import CustomButton from "../../button";

const ConfirmationModal = ({
  icon,
  theme,
  title,
  confirmButtonText,
  cancelButtonText,
  children,
  onCancel,
  onConfirm,
  isConfirmLoading = false
}) => {
  return (
    <>
      <div css={styles.backdrop}></div>
      <div css={styles.main}>
        <div css={styles.modal}>
          <div css={styles.top}>
            <div css={styles.iconContainer(theme)}>
              <FeatherIcon icon={icon} css={styles.icon(theme)} />
            </div>
            <div>
              <div css={styles.title}>{title}</div>
              {children}
            </div>
          </div>
          <div css={styles.buttonContainer}>
            <CustomButton
              text={cancelButtonText}
              use={"secondary"}
              theme={theme}
              onPress={() => onCancel()}
              addStyles={styles.secondaryButton(theme)}
              isDisable={isConfirmLoading}
            />
            <CustomButton
              text={confirmButtonText}
              use={"primary"}
              theme={theme}
              onPress={() => onConfirm()}
              isLoading={isConfirmLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
