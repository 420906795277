import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({
  minWidth: "16.125rem !important",
  '._neighborLocation_right_letz7_gg_ input[type="text"]': {
    border: "none !important",
  },

});

export const borderNone = css({
  '.___SValue_letz7_gg_ ._size_m_letz7_gg_ ._size_m_zwu69_gg_ ._state_normal_letz7_gg_ ._neighborLocation_right_letz7_gg_ input[type="text"] textarea': {
    border: "none !important",
  }
})

export const trigger = css({
  ...textStyles.b3,
  width: "100% !important",
  border: "1px solid black",
});

export const triggerText = css({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

export const popper = css({
  ".___SBaseTrigger_15wp9_gg_._size_m_15wp9_gg_": {
    border: "none !important",
    background: "transparent !important"
  }
});
export const searchWrapper = css({
  top: "0 !important",
  left: "0 !important",
});
export const searchIcon = css({
  height: "1.25rem !important",
});

export const text = css({
  ...textStyles.b3,
});

export const searchInput = css({
  padding: "0.6rem 0.75rem !important",
  height: "2.5rem !important",
});

export const optionContainer = css({
  width: "100%",
  display: "block",
  padding: "0 !important",
  minHeight: "auto !important",

  ".___SDropdownMenuItem_9x64n_gg_.__selected_9x64n_gg_": {
    backgroundColor: `${Colors.primary[75]} !important`,
  },
  "button": {
    border: "none",
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  ".__active_1y8zk_gg_": {
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  ".___SIcon_1jhua_gg_": {
    rotate: "-90deg",
    strokeWidth: 2,
    color: Colors.neutral[500],
  },
  ".___SText_15wp9_gg_": {
    color: Colors.black,
  },
  ".___SValue_letz7_gg_._size_m_letz7_gg_ ": {
    border: "none !important",
  },
  "[data-ui-name='InputSearch.Value']": {
    border: "none !important"
  }
});

export const optionHeader = css({
  width: "100%",
  color: `${Colors.neutral[900]} !important`,
});

export const checkbox = css({
  height: "1rem !important",
  width: "1rem !important",
});

export const tooltip = css({
  ...textStyles.b3,
  padding: "0.5rem",
});

export const infoIcon = css({
  width: "1rem",
  height: "1rem",
  marginLeft: "0.25rem",
});

export const secondaryColor = css({
  color: `${Colors.neutral[600]} !important`,
});

export const subDropdown = css({
  minWidth: "16.25rem !important",
});
