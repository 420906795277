import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';


// Delete Modal
export const modalBody = css({
    display: "flex",
    padding: "1rem 0",

})

export const modalFooter = css({
    display: "flex",
    flexDirection: "row-reverse",
    padding: "1rem 0",
})

export const icon = (theme) => {
    let color
    switch (theme) {
        case 'primary':
            color = Colors.primary[50]
            break;
        case 'success':
            color = Colors.success[300]
            break;
        case 'error':
            color = Colors.error[50]
            break;
        default:
            color = Colors.neutral[50]
    }


    return css({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "1rem",
        padding: "0.5rem",
        height: "2.5rem",
        width: "2.5rem",
        borderRadius: "100%",
        backgroundColor: color
    })
}

export const secondaryButton = css({
    "button": {
        ...textStyles.s2Medium,
        marginRight: "1rem",
        color: `${Colors.primary[500]} !important`,
        border: `1px solid ${Colors.primary[500]} !important`,
        backgroundColor: `${Colors.plainWhite} !important`,
        ":hover": {
            backgroundColor: `${Colors.primary[50]} !important`
        }
    }
})