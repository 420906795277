import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors"
import { textStyles } from "../../../styles/font"


export const main =  css({
  width: "100%",
  display: "flex",
  alignItems: "center",
  border: "none",
});

export const input = css({
  ...textStyles.b3,
    width: "34.375rem",
})


export const label = css({
    ...textStyles.b1,
    minWidth: "13.75rem",
})

export const redText = css({
  color: Colors.error[800]
})

export const icon = css({
  height: "1.125rem",
  width: "1.125rem",
  color: Colors.black
})