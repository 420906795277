import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const dropDownStyles = css({
    marginBottom: "1rem",
    "input": {
        border: "none",
        background: "none",
        cursor: "pointer",
        width: "100%"
    }
})

export const resetOption = css({
    // "div": {
        color: `${Colors.error[800]} !important`,
        backgroundColor: "white !important"
    // }
})

export const labelStyles = css({
    ...textStyles.H1Medium,
    marginBottom: "0.50rem"
})

export const errorMsg = css({
    ...textStyles.b1,
    marginTop: "0.25rem",
    color: Colors.error[800]
})

export const loader = css({
    // position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    // zIndex: 99999,
})

export const searchInput = css({
    padding: "0.6rem 0.75rem !important",
    height: "2.5rem !important",
});

export const text = css({
    ...textStyles.b2,
    color: Colors.textLink,
});