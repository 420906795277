import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import APIManager from './services/APIManager';
import { userRefreshToken } from './utility/Constant';
import { useEffect } from 'react';
import jwt_decode from "jwt-decode";

const CustomRouter = ({children}) => {
const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userId') && localStorage.getItem('refreshToken')) {
      const decoded = jwt_decode(localStorage.getItem('token'));
      if ((+decoded.exp - 60) < Date.now() / 1000) {
        APIManager.postAPI(userRefreshToken + 'userId=' + localStorage.getItem('userId') + '&refreshToken=' + localStorage.getItem('refreshToken')).then((response) => {
          if (response && response.status == 200) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.userId);
            localStorage.setItem('refreshToken', response.data.refreshToken);
          } 
        });
      }
    } else {
      if (navigate && location.pathname !== "/forgot-password") {
        navigate("/login");
        localStorage.clear();
        sessionStorage.clear();
      }
    }
}, [navigate]);

  return (
    <div>{children}</div>
  )
}

export default CustomRouter