/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import Input from '@semcore/ui/input';
import DropDownInput from "../Input";
import DropDownRangeInput from "../RangeInput";

const LabeledDropDown = ({
  addStyles,
  placeholder,
  onChange,
  label,
  options,
  value,
  showSearchBar,
  onBlur
}) => {

  return (
    <div css={[styles.main, addStyles && addStyles]}>
      <div css={styles.label}>{label}<span css={styles.redText}>*</span>:</div>
      <div css={styles.inputContainer}>
      <DropDownInput onBlur={onBlur} data={options} showSearchBar={showSearchBar} multiselect={false} placeholder={placeholder} handleDropdownClick={(index)=>onChange(options.options[index].value)} defaultValue={value}/>
      </div>
    </div>
  );
};

export default LabeledDropDown;
