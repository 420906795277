import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import {
  calanderDate,
  calanderDateCompare,
  convertDate,
  dateRagngeSelectWeek,
  downloadPDFfile,
  exportToEXCEL,
  getBase64path,
  getEstimatedClicksColor,
  getWeek,
  mmDDyyyyFormateForForm22,
  urlDomainName,
  dateRagngeSelectFornightly,
  dateRagngeSelectMonthly,
  getCheckPermission,
} from "../utility/Globals";
import Chart from "react-apexcharts";
import { Sorter } from "../common/Sorter";
import {
  getClickShare,
  getAreaGraphData,
  sendEmailApi,
  getCategoryByProject,
  getKeywordTypeAPI,
  ClickSharePieChart,
  getBarGraphData,
  GetTableDataaaa,
  moduleClickShare,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import { PDFExport } from "@progress/kendo-react-pdf";
import "jspdf-autotable";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import Loading from "../utility/Loading";
import NotFound from "../NotFound";

function ModuleExpandClickShare() {
  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);
  const [ClickShareTable, setClickShareTable] = useState([]);
  const [ClickShareTable1, setClickShareTable1] = useState([]);
  const [ClickShareCol, setClickShareCol] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Click Share Reports");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isDateRage, setIsDateRage] = useState(true);
  const [selectedType, setselectedType] = useState("0");
  const [keywordTypeList, setKeywordTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setselectedCategory] = useState("0");
  const [isCompare, setIsCompare] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [labels11, setLabels11] = useState([]);
  const [series11, setSeries11] = useState([]);
  const [labels22, setLabels22] = useState([]);
  const [series22, setSeries22] = useState([]);
  const [totalSearchVolume, settotalSearchVolume] = useState("");
  const [totestimatedOrganicShare, settotestimatedOrganicShare] = useState("");
  const [totalKeywords, settotalKeywords] = useState("");
  const [totalSearchVolumeareachart, settotalSearchVolumeareachart] =
    useState(0);
  const [
    totestimatedOrganicShareareachart,
    settotestimatedOrganicShareareachart,
  ] = useState(0);
  const [totalKeywordsareachart, settotalKeywordsareachart] = useState(0);
  const [totalSearchVolumebarchart1, settotalSearchVolumebarchart1] =
    useState(0);
  const [totalSearchVolumebarchart2, settotalSearchVolumebarchart2] =
    useState(0);
  const [totalKeywordsbarchart1, settotalKeywordsbarchart1] = useState(0);
  const [totalKeywordsbarchart2, settotalKeywordsbarchart2] = useState(0);
  const [
    totalestimatedOrganicSharebarchart1,
    settotalestimatedOrganicSharebarchart1,
  ] = useState(0);
  const [
    totalestimatedOrganicSharebarchart2,
    settotalestimatedOrganicSharebarchart2,
  ] = useState(0);
  const [totalkeywordforsingle, settotalkeywordforsingle] = useState(0);
  const [totalsearchvolumeforsingle, settotalsearchvolumeforsingle] =
    useState(0);
  const [totalestimatedforsingle, settotalestimatedforsingle] = useState(0);
  const [totalkeywordcompare, settotalkeywordcompare] = useState(0);
  const [totalsearchvolumecompare, settotalsearchvolumecompare] = useState(0);
  const [totalestimatecompare, settotalestimatecompare] = useState(0);
  const [totalkeywordpiechart, settotalkeywordpiechart] = useState(0);
  const [totalsearchvolumepiechart, settotalsearchvolumepiechart] = useState(0);
  const [totalestimatepiechart, settotalestimatepiechart] = useState(0);
  const [totalkeywordpiechartforsingle, settotalkeywordpiechartforsingle] =
    useState(0);
  const [
    totalsearchvolumepiechartforsingle,
    settotalsearchvolumepiechartforsingle,
  ] = useState(0);
  const [totalestimatepiechartforsingle, settotalestimatepiechartforsingle] =
    useState(0);
  let firstDay;
  let lastDay;
  const [calendarType, setCalendarType] = useState(0);
  let firstDayy;
  let lastDayy;
  const today = new Date();
  firstDayy = new Date(today.setDate(today.getDate() - today.getDay() - 7));
  lastDayy = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [state, setState] = useState({
    selection1: {
      startDate: firstDayy,
      endDate: lastDayy,
      key: "selection1",
    },
    selection2: {
      startDate: firstDayy,
      endDate: lastDayy,
      key: "selection2",
    },
  });

  useEffect(() => {
    setNotFound(getCheckPermission("click_share", "reports"));
    localStorage.setItem("isCompareVar", false);
    const today = new Date();
    firstDay = new Date(today.setDate(today.getDate() - today.getDay() - 7));
    lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    setState({
      selection1: {
        startDate: firstDay,
        endDate: lastDay,
        key: "selection1",
      },
      selection2: {
        startDate: firstDay,
        endDate: lastDay,
        key: "selection2",
      },
    });
    getClickShareConfig();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      let a =
        "Click Share1 (" +
        mmDDyyyyFormateForForm22(state.selection1.startDate) +
        " to " +
        mmDDyyyyFormateForForm22(state.selection1.endDate) +
        ")";
      let b =
        "Click Share2 (" +
        mmDDyyyyFormateForForm22(state.selection2.startDate) +
        " to " +
        mmDDyyyyFormateForForm22(state.selection2.endDate) +
        ")";
      let c =
        "estimatedClicks1 (" +
        mmDDyyyyFormateForForm22(state.selection1.startDate) +
        " to " +
        mmDDyyyyFormateForForm22(state.selection1.endDate) +
        ")";
      let d =
        "estimatedClicks2(" +
        mmDDyyyyFormateForForm22(state.selection2.startDate) +
        " to " +
        mmDDyyyyFormateForForm22(state.selection2.endDate) +
        ")";
      selectedRows.forEach((item) => {
        if (isDateRage) {
          let obj = {
            key: item.key,
            website: item.website,
            estimatedClicks: Number(
              parseFloat(item.estimatedClicks).toFixed(2)
            ),
            clickShare: Number(parseFloat(item.clickShare).toFixed(2)),
          };
          list.push(obj);
        } else {
          let compobj = {
            key: item.key,
            website: item.website,
          };
          compobj[a] = Number(parseFloat(item.clickShare1).toFixed(2));
          compobj[b] = Number(parseFloat(item.clickShare2).toFixed(2));
          compobj["clickShareDifference"] = Number(
            parseFloat(item.clickShareDifference).toFixed(2)
          );
          compobj[c] = Number(parseFloat(item.estimatedClicks1).toFixed(2));
          compobj[d] = Number(parseFloat(item.estimatedClicks2).toFixed(2));
          compobj["estimatedClicksDifference"] = Number(
            parseFloat(item.estimatedClicksDifference).toFixed(2)
          );
          list.push(compobj);
        }
      });

      setSelectedExportList(list);
    },
  };

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {  
        const rdrDefinedRangesWrapper = document.getElementsByClassName("rdrDefinedRangesWrapper")[0];
        if(rdrDefinedRangesWrapper){
          rdrDefinedRangesWrapper.classList.add("display-none");  
        }
      }
    };
  }, [open]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag === "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }

      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);

  function getCategory() {
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        list.push({
          key: "0",
          value: "All",
          label: "All",
          id: "0",
          category: "All",
        });
        response.data.forEach((item) => {
          list.push({
            key: item.id,
            value: item.categoryType,
            label: item.categoryType,
            id: item.id,
            category: item.categoryType,
          });
        });
        setCategoryList(list);
        getKeywordType(list[0]);
        let rowdata1 = {
          ClickShareDateRange: {
            from: convertDate(firstDay),
            to: convertDate(lastDay),
          },
          startDate: convertDate(firstDay),
          endDate: convertDate(lastDay),
          projectId: localStorage.getItem("projectGId"),
        };
        getAreaChartData(rowdata1, "1");
        let rowdata11 = {
          projectId: localStorage.getItem("projectGId"),
          ClickShareDateRange: {
            FROM: convertDate(state.selection1.startDate),
            TO: convertDate(state.selection1.endDate),
          },
        };
        getTableData(rowdata11);
      }
    });
  }

  function getKeywordType(item) {
    APIManager.getAPI(
      getKeywordTypeAPI + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        list.push({
          key: "0",
          value: "All",
          label: "All",
          id: "0",
          keywordType: "All",
        });
        response.data.forEach((item) => {
          list.push({
            key: item.id,
            value: item.type,
            label: item.type,
            id: item.id,
            keywordType: item.type,
          });
        });
        setKeywordTypeList(list);

        if (isDateRage) {
          let rowdata3 = {
            projectId: localStorage.getItem("projectGId"),
            ClickShareDateRange: {
              from: convertDate(state.selection1.startDate),
              to: convertDate(state.selection1.endDate),
            },
            CategoryId: item?.id,
            TypeId: list[0]?.id,
          };
          clickSharePieChartData(rowdata3);
        } else {
          let rowdata3 = {
            projectId: localStorage.getItem("projectGId"),
            ClickShareDateRange: {
              from: convertDate(state.selection1.startDate),
              to: convertDate(state.selection1.endDate),
            },
            ClickShareCompareToRange: {
              from: convertDate(state.selection2.startDate),
              to: convertDate(state.selection2.endDate),
            },
            CategoryId: item?.id,
            TypeId: list[0]?.id,
          };
          clickSharePieChartData(rowdata3);
        }
      }
    });
  }

  function getClickShareConfig() {
    APIManager.getAPI(getClickShare + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response) {
          setCalendarType(response.data.frequency);
          var todayDate = new Date();
          if (response?.data.frequency == 1) {
            var week = getWeek(new Date(todayDate));
            setState({
              selection1: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection1",
              },
              selection2: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection2",
              },
            });
          } else if (response?.data.frequency == 2) {
            setState({
              selection1: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection2",
              },
            });
          } else if (response?.data.frequency == 3) {
            var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
            var lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);
            setState({
              selection1: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection1",
              },
              selection2: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection2",
              },
            });
          } else {
            setState({
              selection1: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection2",
              },
            });
          }

          let urlList = [];
          urlList.push({
            key: response.data.urLs?.id,
            id: response.data.urLs?.id,
            value: response.data.urLs?.fieldValue,
            label: response.data.urLs?.fieldValue,
          });
          let complist = [];
          response.data.clickShareConfigurationCompetitorMappings?.forEach(
            (item) => {
              complist.push({
                key: item.competitors.id,
                id: item.competitors.id,
                value: item.competitors.fieldValue,
                label: item.competitors.fieldValue,
              });
            }
          );

          getCategory();
          localStorage.setItem("UrlSelected", response.data.urLs.fieldValue);
          let uid = localStorage.getItem("UserEmail");
          setSender(uid);
          var UserId = uid.toString();
          var message =
            UserId +
            " " +
            "has shared the Click Share Reports with you. Please find the attachment below.";
          setEmailMessage(message);
          setEmailMessage1(message);
          var data = [];
          data = [];
          setExportList(data);

          let rowdata3;

          rowdata3 = {
            projectId: localStorage.getItem("projectGId"),
            ClickShareDateRange: {
              from: convertDate(state.selection1.startDate),
              to: convertDate(state.selection1.endDate),
            },
            KeywordCategoryId: selectedCategory,
            KeywordTypeId: selectedType,
          };
          clickSharePieChartData(rowdata3);
        }
      }
    );
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }
  const [ClickShare, setClickShare] = useState(false);

  async function sendEmail() {
    let theme = "light";
    if (document.querySelector("body").classList.contains("theme-dark")) {
      theme = "dark";
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image);
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: moduleClickShare,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status == 200) {
        applyBtn.current.click();
        setReceiver("");
        setSubject("Click Share Reports");
        setEmailMessage(EmailMessage1);
        if (theme == "dark") {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  const [areaDates, setAreaDates] = useState([]);
  const [areaValue, setAreaValue] = useState([]);
  const [barURLs, setBarURLs] = useState([]);
  const [barURLsVal, setBarURLsVal] = useState([]);
  const [barURLsVal2, setBarURLsVal2] = useState([]);

  function getAreaChartData(rowdata1, tag) {
    APIManager.postAPI(getAreaGraphData, rowdata1, null, true).then(
      (response) => {
        let dates = [];
        let valuesObj = [];
        if (response && response.status == 200) {
          if (response.data.table.length > 0) {
            response.data.table.forEach((i, index) => {
              let keys = Object.keys(i);
              let values = Object.values(i);
              let urlName = urlDomainName(values[0]);
              values.shift();
              values = values.forEach(function (val, i) {
                return val === null ? 0 : Number(parseFloat(val).toFixed(2));
              });
              let mainObj = {
                name: urlName,
                data: values,
              };
              valuesObj.push(mainObj);
              keys.shift();
              dates = keys;
            });
            let totalkeyword = 0;
            let totalSearchVolume = 0;
            let totalestimatedOrganic = 0;
            response.data.table1.forEach((i) => {
              totalkeyword += +i.totalKeyword;
              totalSearchVolume += +i.totalSearchVolume;
              totalestimatedOrganic += +i.estimatedOrganicShare;
            });
            settotalSearchVolumeareachart(totalSearchVolume);
            let value = parseFloat((totalestimatedOrganic / totalkeyword).toFixed(2))
            settotestimatedOrganicShareareachart(
              value.toString() != "NaN" ? value : 0
            );
            settotalKeywordsareachart(totalkeyword);

            setAreaDates(dates);
            setAreaValue(valuesObj);
          } else {
            setAreaDates([]);
            setAreaValue([]);
          }
        }
      }
    );
  }

  function getTableData(rowdata11) {
    APIManager.postAPI(GetTableDataaaa, rowdata11, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let list = [];
          let list1 = [];
          let list2 = [];
          let totalkeyword = 0;
          let totalsearchvolume = 0;
          let totalestimated = 0;
          let i = 0;
          let exportList1 = [];
          let exportList2 = [];
          let exportList = [];

          response.data.tableContent.forEach((item) => {
            totalkeyword += item.totalKeyword ? +item.totalKeyword : 0;
            totalsearchvolume += item.totalSearchVolume
              ? +item.totalSearchVolume
              : 0;
            totalestimated += item.estimatedClicks ? +item.estimatedClicks : 0;
            settotalkeywordforsingle(totalkeyword);
            settotalsearchvolumeforsingle(totalsearchvolume);
            let value = parseFloat((totalestimated / totalkeyword).toFixed(2))
            settotalestimatedforsingle(
              value.toString() != "NaN" ? value : 0
            );
            var url = localStorage.getItem("UrlSelected");
            if (item.website == url) {
              let obj = {
                key: (i += 1),
                website: urlDomainName(item.website),
                estimatedClicks: item.estimatedClicks ? Number(
                  parseFloat(item.estimatedClicks).toFixed(2)
                ) : 0,
                clickShare: item.clickShare ?
                  Number(parseFloat(item.clickShare).toFixed(2)) + "%" : 0 + "%",
              };
              list1.push(obj);
              exportList1.push({
                key: item.key,
                website: urlDomainName(item.website),
                estimatedClicks: item.estimatedClicks ? Number(
                  parseFloat(item.estimatedClicks).toFixed(2)
                ) : 0,
                clickShare: item.clickShare ?
                  Number(parseFloat(item.clickShare).toFixed(2)) + "%" : 0 + "%",
              });
            } else {
              let obj = {
                key: (i += 1),
                website: urlDomainName(item.website),
                estimatedClicks: item.estimatedClicks ? Number(
                  parseFloat(item.estimatedClicks).toFixed(2)
                ) : 0,
                clickShare: item.clickShare ?
                  Number(parseFloat(item.clickShare).toFixed(2)) + "%" : 0 + "%",
              };
              list2.push(obj);
              exportList2.push({
                key: item.key,
                website: urlDomainName(item.website),
                estimatedClicks: item.estimatedClicks ? Number(
                  parseFloat(item.estimatedClicks).toFixed(2)
                ) : 0,
                clickShare: item.clickShare ?
                  Number(parseFloat(item.clickShare).toFixed(2)) + "%" : 0 + "%",
              });
            }
          });
          list = list1.concat(list2);
          exportList = exportList1.concat(exportList2);
          var columns = [
            {
              title: "Website",
              dataIndex: "website",
              key: "website",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.website, b.website),
                multiple: 3,
              },
            },
            {
              title: "Estimated Clicks",
              dataIndex: "estimatedClicks",
              key: "estimatedClicks",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.estimatedClicks, b.estimatedClicks),
                multiple: 3,
              },
            },
            {
              title: "Click Share",
              dataIndex: "clickShare",
              key: "clickShare",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.clickShare, b.clickShare),
                multiple: 3,
              },
            },
          ];

          setClickShareCol(columns);
          setClickShareTable(list);
          setExportList(exportList);
        }
      }
    );
  }

  function getTableCompareData(rowdata11) {
    APIManager.postAPI(GetTableDataaaa, rowdata11, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let list = [];
          let list1 = [];
          let list2 = [];
          let exportList = [];
          let exportList1 = [];
          let exportList2 = [];

          let estimatedclicks1 = 0;
          let estimatedclicks2 = 0;
          let totalkeyword1 = 0;
          let totalkeyword2 = 0;
          let totalserachvoluem1 = 0;
          let totalserachvoluem2 = 0;
          let i = 0;
          response.data.compareTableContent.forEach((item) => {
            totalkeyword1 += item.totalKeyword1 ? +item.totalKeyword1 : 0;
            totalkeyword2 += item.totalKeyword2 ? +item.totalKeyword2 : 0;
            estimatedclicks1 += item.estimatedClicks1
              ? +item.estimatedClicks1
              : 0;
            estimatedclicks2 += item.estimatedClicks2
              ? +item.estimatedClicks2
              : 0;
            totalserachvoluem1 += item.searchVolume1 ? +item.searchVolume1 : 0;
            totalserachvoluem2 += item.searchVolume2 ? +item.searchVolume2 : 0;
            settotalkeywordcompare(totalkeyword2);
            settotalkeywordforsingle(totalkeyword1);
            settotalsearchvolumecompare(totalserachvoluem2);
            settotalsearchvolumeforsingle(totalserachvoluem1);
            let value1 = parseFloat((estimatedclicks2 / totalkeyword2).toFixed(2))
            settotalestimatecompare(
              value1.toString() != "NaN" ? value : 0
            );
            let value =parseFloat((estimatedclicks1 / totalkeyword1).toFixed(2))
            settotalestimatedforsingle(
              value.toString() != "NaN" ? value : 0
            );
            var url = localStorage.getItem("UrlSelected");
            if (item.website == url) {
              let obj1 = {
                key: (i += 1),
                clickShare1: item.clickShare1 ?
                  Number(parseFloat(item.clickShare1).toFixed(2)) + "%" : 0 + "%",
                clickShare2: item.clickShare2 ?
                  Number(parseFloat(item.clickShare2).toFixed(2)) + "%" : 0 + "%",
                clickShareDifference: item.clickShareDifference ? Number(
                  parseFloat(item.clickShareDifference).toFixed(2)
                ) : 0,
                estimatedClicks1: item.estimatedClicks1 ? Number(
                  parseFloat(item.estimatedClicks1).toFixed(2)
                ) : 0,
                estimatedClicks2: item.estimatedClicks2 ? Number(
                  parseFloat(item.estimatedClicks2).toFixed(2)
                ) : 0,
                estimatedClicksDifference: item.estimatedClicksDifference ? Number(
                  parseFloat(item.estimatedClicksDifference).toFixed(2)
                ) : 0,
                clickShare: item.clickShare ? item.clickShare : 0,
                website: urlDomainName(item.website),
              };
              list1.push(obj1);
              let a =
                "Click Share1 (" +
                mmDDyyyyFormateForForm22(state.selection1.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection1.endDate) +
                ")";
              let b =
                "Click Share2 (" +
                mmDDyyyyFormateForForm22(state.selection2.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection2.endDate) +
                ")";
              let c =
                "estimatedClicks1 (" +
                mmDDyyyyFormateForForm22(state.selection1.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection1.endDate) +
                ")";
              let d =
                "estimatedClicks2(" +
                mmDDyyyyFormateForForm22(state.selection2.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection2.endDate) +
                ")";
              var obj = {
                key: item.key,
                website: urlDomainName(item.website),
              };
              obj[a] = item.clickShare1 ? Number(parseFloat(item.clickShare1).toFixed(2)) : 0;
              obj[b] = item.clickShare2 ? Number(parseFloat(item.clickShare2).toFixed(2)) : 0;
              obj["clickShareDifference"] = item.clickShareDifference ? Number(
                parseFloat(item.clickShareDifference).toFixed(2)
              ) : 0;
              obj[c] = item.estimatedClicks1 ? Number(parseFloat(item.estimatedClicks1).toFixed(2)) : 0;
              obj[d] = item.estimatedClicks2 ? Number(parseFloat(item.estimatedClicks2).toFixed(2)) : 0;

              obj["estimatedClicksDifference"] = item.estimatedClicksDifference ? Number(
                parseFloat(item.estimatedClicksDifference).toFixed(2)
              ) : 0;

              exportList1.push(obj);
            } else {
              let obj1 = {
                key: (i += 1),
                clickShare1: item.clickShare1 ?
                  Number(parseFloat(item.clickShare1).toFixed(2)) + "%" : 0 + "%",
                clickShare2: item.clickShare2 ?
                  Number(parseFloat(item.clickShare2).toFixed(2)) + "%" : 0 + "%",
                clickShareDifference: item.clickShareDifference ? Number(
                  parseFloat(item.clickShareDifference).toFixed(2)
                ) : 0,
                estimatedClicks1: item.estimatedClicks1 ? Number(
                  parseFloat(item.estimatedClicks1).toFixed(2)
                ) : 0,
                estimatedClicks2: item.estimatedClicks2 ? Number(
                  parseFloat(item.estimatedClicks2).toFixed(2)
                ) : 0,
                estimatedClicksDifference: item.estimatedClicksDifference ? Number(
                  parseFloat(item.estimatedClicksDifference).toFixed(2)
                ) : 0,
                clickShare: item.clickShare,
                website: urlDomainName(item.website),
              };
              list2.push(obj1);
              let a =
                "Click Share1 (" +
                mmDDyyyyFormateForForm22(state.selection1.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection1.endDate) +
                ")";
              let b =
                "Click Share2 (" +
                mmDDyyyyFormateForForm22(state.selection2.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection2.endDate) +
                ")";
              let c =
                "estimatedClicks1 (" +
                mmDDyyyyFormateForForm22(state.selection1.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection1.endDate) +
                ")";
              let d =
                "estimatedClicks2(" +
                mmDDyyyyFormateForForm22(state.selection2.startDate) +
                " to " +
                mmDDyyyyFormateForForm22(state.selection2.endDate) +
                ")";
              var obj = {
                key: item.key,
                website: urlDomainName(item.website),
              };
              obj[a] = item.clickShare1 ? Number(parseFloat(item.clickShare1).toFixed(2)) : 0;
              obj[b] = item.clickShare2 ? Number(parseFloat(item.clickShare2).toFixed(2)) : 0;
              obj["clickShareDifference"] = item.clickShareDifference ? Number(
                parseFloat(item.clickShareDifference).toFixed(2)
              ) : 0;

              obj[c] = item.estimatedClicks1 ? Number(parseFloat(item.estimatedClicks1).toFixed(2)) : 0;
              obj[d] = item.estimatedClicks2 ? Number(parseFloat(item.estimatedClicks2).toFixed(2)) : 0;

              obj["estimatedClicksDifference"] = item.estimatedClicksDifference ? Number(
                parseFloat(item.estimatedClicksDifference).toFixed(2)
              ) : 0;

              exportList2.push(obj);
            }
          });

          list = list1.concat(list2);
          exportList = exportList1.concat(exportList2);

          let csHead = () => {
            return (
              <>
                <div>Click Share</div>
                <div style={{ fontSize: 10 + "px" }}>
                  {mmDDyyyyFormateForForm22(state.selection1.startDate) +
                    " to " +
                    mmDDyyyyFormateForForm22(state.selection1.endDate)}
                </div>
              </>
            );
          };

          let csHead2 = () => {
            return (
              <>
                <div>Click Share</div>
                <div style={{ fontSize: 10 + "px" }}>
                  {mmDDyyyyFormateForForm22(state.selection2.startDate) +
                    " to " +
                    mmDDyyyyFormateForForm22(state.selection2.endDate)}
                </div>
              </>
            );
          };

          let ecHead = () => {
            return (
              <>
                <div>Estimated Clicks</div>
                <div style={{ fontSize: 10 + "px" }}>
                  {mmDDyyyyFormateForForm22(state.selection1.startDate) +
                    " to " +
                    mmDDyyyyFormateForForm22(state.selection1.endDate)}
                </div>
              </>
            );
          };

          let ecHead2 = () => {
            return (
              <>
                <div>Estimated Clicks</div>
                <div style={{ fontSize: 10 + "px" }}>
                  {mmDDyyyyFormateForForm22(state.selection2.startDate) +
                    " to " +
                    mmDDyyyyFormateForForm22(state.selection2.endDate)}
                </div>
              </>
            );
          };
          var columns = [
            {
              title: "Website",
              dataIndex: "website",
              key: "website",
              width: "10%",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.website, b.website),
                multiple: 3,
              },
            },
            {
              title: ecHead,
              dataIndex: "estimatedClicks1",
              key: "estimatedClicks1",
              width: "10%",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.estimatedClicks1, b.estimatedClicks1),
                multiple: 3,
              },
            },
            {
              title: ecHead2,
              dataIndex: "estimatedClicks2",
              key: "estimatedClicks2",
              width: "10%",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(a.estimatedClicks2, b.estimatedClicks2),
                multiple: 3,
              },
            },
            {
              title: "estimated Clicks Difference",
              dataIndex: "estimatedClicksDifference",
              key: "estimatedClicksDifference",
              width: "20%",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(
                    a.estimatedClicksDifference,
                    b.estimatedClicksDifference
                  ),
                multiple: 3,
              },
              render: (val) => (
                <div className={getEstimatedClicksColor(val)}>{val}</div>
              ),
            },
            {
              title: csHead,
              dataIndex: "clickShare1",
              key: "clickShare1",
              width: "10%",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.clickShare1, b.clickShare1),
                multiple: 3,
              },
            },
            {
              title: csHead2,
              dataIndex: "clickShare2",
              key: "clickShare2",
              width: "10%",
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.clickShare2, b.clickShare2),
                multiple: 3,
              },
            },
            {
              title: "Click Share Difference",
              dataIndex: "clickShareDifference",
              key: "clickShareDifference",
              width: "20%",
              sorter: {
                compare: (a, b) =>
                  Sorter.DEFAULT(
                    a.clickShareDifference,
                    b.clickShareDifference
                  ),
                multiple: 3,
              },
              render: (val) => (
                <div className={getEstimatedClicksColor(val)}>{val + "%"}</div>
              ),
            },
          ];

          setClickShareCol(columns);
          setClickShareTable1(list);
          setExportList(exportList);
        }
      }
    );
  }

  function clickSharePieChartData(rowdata3) {
    if (rowdata3.CategoryId == "0") {
      delete rowdata3.CategoryId;
    }
    if (rowdata3.TypeId == "0") {
      delete rowdata3.TypeId;
    }
    APIManager.postAPI(ClickSharePieChart, rowdata3, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let listURL = [];
          let listTotalClickShare = [];
          let listTotalKeyword = [];
          let totalKeywords = 0;
          let totalEstimateOrganicSearch = 0;
          let totalSearchVolume = 0;
          let pieChartResponse = [];
          response.data.pieChartResponse.forEach((i) => {
            totalKeywords += i.totalKeyword ? +i.totalKeyword : 0;
            totalEstimateOrganicSearch += i.estimatedOrganicShare
              ? +i.estimatedOrganicShare
              : 0;
            totalSearchVolume += i.totalSearchVolume ? +i.totalSearchVolume : 0;
            settotalkeywordpiechartforsingle(totalKeywords);
            settotalsearchvolumepiechartforsingle(totalSearchVolume);
            let value = parseFloat(
              (totalEstimateOrganicSearch / totalKeywords).toFixed(2)
            )
            settotalestimatepiechartforsingle(
              value.toString() != "NaN" ? value : 0
            );
            var oobj = {
              url: urlDomainName(i.url),
              totalKeyword: i.totalKeyword,
              totalClickShare: i.totalClickShare,
              estimatedOrganicShare: i.estimatedOrganicShare,
              totalSearchVolume: i.totalSearchVolume,
            };
            pieChartResponse.push(oobj);

            listURL.push(urlDomainName(i.url));
            listTotalKeyword.push(i.totalKeyword);
          });
          setLabels11(listURL);
          setSeries11(listTotalKeyword);
          settotalKeywords(totalKeywords);
          settotalSearchVolume(totalSearchVolume);
          let value = parseFloat((totalEstimateOrganicSearch / totalKeywords).toFixed(2))
          settotestimatedOrganicShare(
            value.toString() != "NaN" ? value : 0
          );
          listURL = [];
          listTotalClickShare = [];
          listTotalKeyword = [];
          let comparePieChartResponse = [];
          let totalsearchvolume = 0;
          let totalkeyword = 0;
          let totalestimate = 0;
          response.data.comparePieChartResponse.forEach((i) => {
            totalsearchvolume += i.totalSearchVolume ? +i.totalSearchVolume : 0;
            totalkeyword += i.totalKeyword ? +i.totalKeyword : 0;
            totalestimate += i.estimatedOrganicShare
              ? +i.estimatedOrganicShare
              : 0;
            var data = parseFloat((totalestimate / totalkeyword).toFixed(2));
            settotalkeywordpiechart(totalkeyword);
            settotalsearchvolumepiechart(totalsearchvolume);
            let value = parseFloat((totalestimate / totalkeyword).toFixed(2))
            settotalestimatepiechart(
              value.toString() != "NaN" ? value : 0
            );
            var cobj = {
              url: urlDomainName(i.url),
              totalClickShare: i.totalClickShare,
              totalKeyword: i.totalKeyword,
              estimatedOrganicShare: i.estimatedOrganicShare,
              totalSearchVolume: i.totalSearchVolume,
            };
            comparePieChartResponse.push(cobj);
            listURL.push(urlDomainName(i.url));
            listTotalKeyword.push(i.totalKeyword);
          });
          setLabels22(listURL);
          setSeries22(listTotalKeyword);
        }
      }
    );
  }

  function getBarChartData(rowBarChartData) {
    let listURL = [];
    let barChartValue1 = [];
    let barChartValue2 = [];
    let totalKeywords1 = 0;
    let totalEstimateOrganicSearch1 = 0;
    let totalSearchVolume1 = 0;
    let totalKeywords2 = 0;
    let totalEstimateOrganicSearch2 = 0;
    let totalSearchVolume2 = 0;

    APIManager.postAPI(getBarGraphData, rowBarChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          response.data.forEach((item) => {
            listURL.push(urlDomainName(item.url));
            barChartValue1.push(item.clickShare1 + "%");
            barChartValue2.push(item.clickShare2 + "%");

            totalKeywords1 += item.totalKeyword1 ? item.totalKeyword1 : 0;
            totalEstimateOrganicSearch1 += item.estimatedOrganicShare1
              ? +item.estimatedOrganicShare1
              : 0;
            totalSearchVolume1 += item.totalSearchVolume1
              ? item.totalSearchVolume1
              : 0;

            totalKeywords2 += item.totalKeyword2 ? item.totalKeyword2 : 0;
            totalEstimateOrganicSearch2 += item.estimatedOrganicShare2
              ? +item.estimatedOrganicShare2
              : 0;
            totalSearchVolume2 += item.totalSearchVolume2
              ? item.totalSearchVolume2
              : 0;
          });
        }
        setBarURLs(listURL);
        setBarURLsVal(barChartValue1);
        setBarURLsVal2(barChartValue2);
        settotalSearchVolumebarchart1(totalSearchVolume1);
        settotalKeywordsbarchart1(totalKeywords1);
        let value =  parseFloat((totalEstimateOrganicSearch1 / totalKeywords1).toFixed(2))
        settotalestimatedOrganicSharebarchart1(
          value.toString() != "NaN" ? value : 0
        );
        settotalKeywordsbarchart2(totalKeywords2);

        settotalSearchVolumebarchart2(totalSearchVolume2);
          let  value1 =   parseFloat((totalEstimateOrganicSearch2 / totalKeywords2).toFixed(2))
        settotalestimatedOrganicSharebarchart2(
          value1.toString() != "NaN" ? value : 0
        );
      }
    );
  }

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {!notFound ? (
        <>
          <NotFound />
        </>
      ) : (<>
        <div className="row">
          <Tabs>
            <div className="portlet ">
              <div className="portlet-header portlet-header-bordered my-3">
                <div className="col-md-9 col-sm-12">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        ClickShare
                          ? "nav-item nav-link "
                          : "nav-item nav-link  active"
                      }
                      onClick={() => {
                        setClickShare(false);
                      }}
                    >
                      Click Share
                    </Tab>
                  </TabList>
                </div>
                {!ClickShare ? (
                  <div
                    ref={ref}
                    class="col-md-3 col-sm-12 calendar-main d-flex justify-content-end align-items-center ga-calender "
                  >
                    <div className="add-new-btnw">
                      <button
                        className="click btn btn-primary"
                        onClick={() => setOpen(!open)}
                      >
                        <span>
                          {!isCompare
                            ? calanderDate(
                              state.selection1?.startDate,
                              state.selection1?.endDate
                            )
                            : calanderDateCompare(
                              state.selection1?.startDate,
                              state.selection1?.endDate,
                              state.selection2?.startDate,
                              state.selection2?.endDate
                            )}
                        </span>
                      </button>
                    </div>

                    {open && (
                      <div id="example-collapse-text-calendar">
                        <Card body className="daterange-picker-card  mt-2">
                          <div className="d-flex calendar-flex">
                            <div className="calendar-col">
                              <DateRangePicker
                                onChange={(item) => {
                                  let isCompareVarNew =
                                    localStorage.getItem("isCompareVar");

                                  if (calendarType == 1) {
                                    let selection = {};

                                    if (isCompareVarNew == "false") {
                                      let selection23 = {
                                        selection1: {
                                          startDate: item?.selection1
                                            ? item?.selection1?.startDate
                                            : item?.selection2?.startDate,
                                          endDate: item?.selection1
                                            ? item?.selection1?.endDate
                                            : item?.selection2?.endDate,
                                          key: "selection1",
                                        },
                                      };
                                      selection = dateRagngeSelectWeek(
                                        selection23,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    if (isCompareVarNew == "true") {
                                      selection = dateRagngeSelectWeek(
                                        item,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    setState({
                                      ...state,
                                      ...item,
                                      ...selection,
                                    });
                                  } else if (calendarType == 2) {
                                    let selection = {};

                                    if (isCompareVarNew == "false") {
                                      let selection23 = {
                                        selection1: {
                                          startDate: item?.selection1
                                            ? item?.selection1?.startDate
                                            : item?.selection2?.startDate,
                                          endDate: item?.selection1
                                            ? item?.selection1?.endDate
                                            : item?.selection2?.endDate,
                                          key: "selection1",
                                        },
                                      };

                                      selection = dateRagngeSelectFornightly(
                                        selection23,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    if (isCompareVarNew == "true") {
                                      selection = dateRagngeSelectFornightly(
                                        item,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    setState({
                                      ...state,
                                      ...item,
                                      ...selection,
                                    });
                                  } else if (calendarType == 3) {
                                    let selection = {};

                                    if (isCompareVarNew == "false") {
                                      let selection23 = {
                                        selection1: {
                                          startDate: item?.selection1
                                            ? item?.selection1?.startDate
                                            : item?.selection2?.startDate,
                                          endDate: item?.selection1
                                            ? item?.selection1?.endDate
                                            : item?.selection2?.endDate,
                                          key: "selection1",
                                        },
                                      };

                                      selection = dateRagngeSelectMonthly(
                                        selection23,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    if (isCompareVarNew == "true") {
                                      selection = dateRagngeSelectMonthly(
                                        item,
                                        state,
                                        isCompareVarNew
                                      );
                                    }

                                    setState({
                                      ...state,
                                      ...item,
                                      ...selection,
                                    });
                                  } else {
                                    if (isCompareVarNew == "false") {
                                      let selection23 = {};
                                      if (item?.selection1) {
                                        selection23 = {
                                          selection2: {
                                            startDate:
                                              item?.selection1?.startDate,
                                            endDate: item?.selection1?.endDate,
                                            key: "selection2",
                                          },
                                        };
                                      } else if (item?.selection2) {
                                        selection23 = {
                                          selection1: {
                                            startDate:
                                              item?.selection2?.startDate,
                                            endDate: item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };
                                      }
                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection23,
                                      });
                                    }

                                    if (isCompareVarNew == "true") {
                                      setState({ ...state, ...item });
                                    }
                                  }
                                }}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={[state?.selection1, state?.selection2]}
                                direction="horizontal"
                                ariaLabels={{
                                  dateInput: {
                                    selection1: {
                                      startDate: "start date input of selction 1",
                                      endDate: "end date input of selction 1",
                                    },
                                    selection2: {
                                      startDate: "start date input of selction 2",
                                      endDate: "end date input of selction 2",
                                    },
                                  },
                                  monthPicker: "month picker",
                                  yearPicker: "year picker",
                                  prevButton: "previous month button",
                                  nextButton: "next month button",
                                }}
                              />
                            </div>
                            <div className="mt-3 ms-3 mt-lg-0 text-center">
                              <label className="mt-10 d-flex ms-3">
                                <input
                                  className="me-3"
                                  checked={isCompare}
                                  onChange={(e) => {
                                    localStorage.setItem(
                                      "isCompareVar",
                                      e.target.checked
                                    );
                                    const todayNew = new Date();
                                    let firstDayNew = new Date(
                                      todayNew.setDate(
                                        todayNew.getDate() - todayNew.getDay() - 7
                                      )
                                    );
                                    let lastDayNew = new Date(
                                      todayNew.setDate(
                                        todayNew.getDate() - todayNew.getDay() + 6
                                      )
                                    );
                                    setIsCompare(e.target.checked);
                                    if (!e.target.checked) {
                                      document
                                        .getElementsByClassName(
                                          "rdrDateDisplayWrapper"
                                        )[0]
                                        .children[1].classList.remove(
                                          "display-flex"
                                        );
                                      document
                                        .getElementsByClassName(
                                          "rdrDateDisplayWrapper"
                                        )[0]
                                        .children[1].classList.add(
                                          "display-none"
                                        );
                                      setState({
                                        selection1: {
                                          startDate: state.selection1?.startDate,
                                          endDate: state.selection1?.endDate,
                                          key: "selection1",
                                        },
                                        selection2: {
                                          startDate: state.selection1?.startDate,
                                          endDate: state.selection1?.endDate,
                                          key: "selection2",
                                        },
                                      });
                                    } else {
                                      document
                                        .getElementsByClassName(
                                          "rdrDateDisplayWrapper"
                                        )[0]
                                        .children[1].classList.remove(
                                          "display-none"
                                        );
                                      document
                                        .getElementsByClassName(
                                          "rdrDateDisplayWrapper"
                                        )[0]
                                        .children[1].classList.add(
                                          "display-flex"
                                        );
                                      if (calendarType == 1) {
                                        var todayDate = new Date();
                                        var week = getWeek(new Date(todayDate));

                                        setState({
                                          selection1: {
                                            startDate: new Date(week[0]),
                                            endDate: new Date(week[week.length - 1]),
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: new Date(week[0]),
                                            endDate: new Date(week[week.length - 1]),
                                            key: "selection2",
                                          },
                                        });

                                      } else if (calendarType == 2) {
                                        var todayDate = new Date();

                                        setState({
                                          selection1: {
                                            startDate: addDays(todayDate, -14),
                                            endDate: addDays(todayDate, 0),
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: addDays(todayDate, -14),
                                            endDate: addDays(todayDate, 0),
                                            key: "selection2",
                                          },
                                        });

                                      } else if (calendarType == 3) {
                                        var todayDate = new Date();
                                        var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
                                        var lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

                                        setState({
                                          selection1: {
                                            startDate: firstDay,
                                            endDate: lastDay,
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: firstDay,
                                            endDate: lastDay,
                                            key: "selection2",
                                          },
                                        });

                                      } else {
                                        setState({
                                          selection1: {
                                            startDate: state.selection1?.startDate,
                                            endDate: state.selection1?.endDate,
                                            key: "selection1",
                                          },
                                          selection2: {
                                            startDate: firstDayNew,
                                            endDate: lastDayNew,
                                            key: "selection2",
                                          },
                                        });

                                      }
                                    }
                                  }}
                                  type="checkbox"
                                />
                                Compare
                              </label>
                              <button
                                onClick={() => {
                                  setOpen(!open);

                                  if (
                                    convertDate(state.selection1.startDate) ==
                                    convertDate(state.selection2.startDate) &&
                                    convertDate(state.selection1.endDate) ==
                                    convertDate(state.selection2.endDate)
                                  ) {
                                    setIsDateRage(true);
                                    let rowdata11 = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                    };
                                    let rowdata3 = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                    };
                                    let rowdata1 = {
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                      startDate: convertDate(
                                        state.selection1.startDate
                                      ),
                                      endDate: convertDate(
                                        state.selection1.endDate
                                      ),
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                    };
                                    if (selectedCategory != "0") {
                                      rowdata11 = {
                                        ...rowdata11,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                      rowdata3 = {
                                        ...rowdata3,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                      rowdata1 = {
                                        ...rowdata1,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                    }
                                    if (selectedType != "0") {
                                      rowdata11 = {
                                        ...rowdata11,
                                        KeywordTypeId: selectedType,
                                      };
                                      rowdata3 = {
                                        ...rowdata3,
                                        TypeId: selectedType,
                                      };
                                      rowdata1 = {
                                        ...rowdata1,
                                        KeywordTypeId: selectedType,
                                      };
                                    }
                                    getAreaChartData(rowdata1, "02");
                                    getTableData(rowdata11);
                                    clickSharePieChartData(rowdata3);
                                  } else {
                                    setIsDateRage(false);
                                    let rowdata11 = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                      ClickShareCompareToRange: {
                                        from: convertDate(
                                          state.selection2.startDate
                                        ),
                                        to: convertDate(state.selection2.endDate),
                                      },
                                    };
                                    let rowdata3 = {
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                      ClickShareCompareToRange: {
                                        from: convertDate(
                                          state.selection2.startDate
                                        ),
                                        to: convertDate(state.selection2.endDate),
                                      },
                                    };
                                    let rowdata1 = {
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                      startDate: convertDate(
                                        state.selection1.startDate
                                      ),
                                      endDate: convertDate(
                                        state.selection1.endDate
                                      ),
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                    };
                                    let rowBarChartData = {
                                      ClickShareDateRange: {
                                        from: convertDate(
                                          state.selection1.startDate
                                        ),
                                        to: convertDate(state.selection1.endDate),
                                      },
                                      ClickShareCompareToRange: {
                                        from: convertDate(
                                          state.selection2.startDate
                                        ),
                                        to: convertDate(state.selection2.endDate),
                                      },
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                    };
                                    if (selectedCategory != "0") {
                                      rowdata11 = {
                                        ...rowdata11,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                      rowdata3 = {
                                        ...rowdata3,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                      rowdata1 = {
                                        ...rowdata1,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                      rowBarChartData = {
                                        ...rowBarChartData,
                                        KeywordCategoryId: selectedCategory,
                                      };
                                    }
                                    if (selectedType != "0") {
                                      rowdata11 = {
                                        ...rowdata11,
                                        KeywordTypeId: selectedType,
                                      };
                                      rowdata3 = {
                                        ...rowdata3,
                                        TypeId: selectedType,
                                      };
                                      rowdata1 = {
                                        ...rowdata1,
                                        KeywordTypeId: selectedType,
                                      };
                                      rowBarChartData = {
                                        ...rowBarChartData,
                                        KeywordTypeId: selectedType,
                                      };
                                    }
                                    getAreaChartData(rowdata1, "2");
                                    getBarChartData(rowBarChartData);
                                    getTableCompareData(rowdata11);
                                    clickSharePieChartData(rowdata3);
                                  }
                                }}
                                className="btn btn-primary"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                    <div className="d-flex 3-icons">
                      <a
                        className="bar-link-icon ms-3"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn2}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-filter" aria-hidden="true"></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="col-md-12 my-2">
                                  <label className="common-label">
                                    Keyword Category
                                  </label>
                                  <select
                                    className="w-100"
                                    style={{ padding: 8 + "px" }}
                                    value={selectedCategory}
                                    onChange={(item) => {
                                      setselectedCategory(item.target.value);
                                    }}
                                  >
                                    {categoryList.forEach((item) => {
                                      return (
                                        <option value={item.id}>
                                          {item.category}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-md-12 my-2">
                                  <label style={{ fontSize: 14 + "px" }}>
                                    Keyword Type
                                  </label>
                                  <select
                                    className="w-100"
                                    style={{ padding: 8 + "px" }}
                                    value={selectedType}
                                    onChange={(item) => {
                                      setselectedType(item.target.value);
                                    }}
                                  >
                                    {keywordTypeList.forEach((item) => {
                                      return (
                                        <option value={item.id}>
                                          {item.keywordType}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-md-12 text-left mt-3">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    onClick={() => {
                                      applyBtn2.current.click();
                                      let rowdata1 = {
                                        ClickShareCompareToRange: {
                                          from: convertDate(
                                            state.selection2.startDate
                                          ),
                                          to: convertDate(
                                            state.selection2.endDate
                                          ),
                                        },
                                        startDate: convertDate(
                                          state.selection1.startDate
                                        ),
                                        endDate: convertDate(
                                          state.selection1.endDate
                                        ),
                                        projectId:
                                          localStorage.getItem("projectGId"),
                                      };
                                      let rowdata11 = {
                                        projectId:
                                          localStorage.getItem("projectGId"),
                                        ClickShareDateRange: {
                                          from: convertDate(
                                            state.selection1.startDate
                                          ),
                                          to: convertDate(
                                            state.selection1.endDate
                                          ),
                                        },
                                      };
                                      let rowdata3 = {
                                        projectId:
                                          localStorage.getItem("projectGId"),
                                        ClickShareDateRange: {
                                          from: convertDate(
                                            state.selection1.startDate
                                          ),
                                          to: convertDate(
                                            state.selection1.endDate
                                          ),
                                        },
                                      };

                                      if (selectedCategory != "0") {
                                        rowdata1 = {
                                          ...rowdata1,
                                          KeywordCategoryId: selectedCategory,
                                        };
                                        rowdata11 = {
                                          ...rowdata11,
                                          KeywordCategoryId: selectedCategory,
                                        };
                                        rowdata3 = {
                                          ...rowdata3,
                                          KeywordCategoryId: selectedCategory,
                                        };
                                      }
                                      if (selectedType != "0") {
                                        rowdata1 = {
                                          ...rowdata1,
                                          KeywordTypeId: selectedType,
                                        };
                                        rowdata11 = {
                                          ...rowdata11,
                                          KeywordTypeId: selectedType,
                                        };
                                        rowdata3 = {
                                          ...rowdata3,
                                          TypeId: selectedType,
                                        };
                                      }

                                      if (
                                        convertDate(state.selection1.startDate) ==
                                        convertDate(
                                          state.selection2.startDate
                                        ) &&
                                        convertDate(state.selection1.endDate) ==
                                        convertDate(state.selection2.endDate)
                                      ) {
                                      } else {
                                        rowdata1 = {
                                          ...rowdata1,
                                          ClickShareCompareToRange: {
                                            from: convertDate(
                                              state.selection2.startDate
                                            ),
                                            to: convertDate(
                                              state.selection2.endDate
                                            ),
                                          },
                                        };
                                        rowdata11 = {
                                          ...rowdata11,
                                          ClickShareCompareToRange: {
                                            from: convertDate(
                                              state.selection2.startDate
                                            ),
                                            to: convertDate(
                                              state.selection2.endDate
                                            ),
                                          },
                                        };
                                        rowdata3 = {
                                          ...rowdata3,
                                          ClickShareCompareToRange: {
                                            from: convertDate(
                                              state.selection2.startDate
                                            ),
                                            to: convertDate(
                                              state.selection2.endDate
                                            ),
                                          },
                                        };
                                      }

                                      let rowBarChartData = {
                                        ClickShareDateRange: {
                                          from: convertDate(
                                            state.selection1.startDate
                                          ),
                                          to: convertDate(
                                            state.selection1.endDate
                                          ),
                                        },
                                        ClickShareCompareToRange: {
                                          from: convertDate(
                                            state.selection2.startDate
                                          ),
                                          to: convertDate(
                                            state.selection2.endDate
                                          ),
                                        },
                                        projectId:
                                          localStorage.getItem("projectGId"),
                                      };
                                      if (selectedCategory != "0") {
                                        rowBarChartData = {
                                          ...rowBarChartData,
                                          KeywordCategoryId: selectedCategory,
                                        };
                                      }
                                      if (selectedType != "0") {
                                        rowBarChartData = {
                                          ...rowBarChartData,
                                          KeywordTypeId: selectedType,
                                        };
                                      }

                                      getAreaChartData(rowdata1, "5");
                                      if (
                                        convertDate(state.selection1.startDate) ==
                                        convertDate(
                                          state.selection2.startDate
                                        ) &&
                                        convertDate(state.selection1.endDate) ==
                                        convertDate(state.selection2.endDate)
                                      ) {
                                        getTableData(rowdata11);
                                      } else {
                                        getTableCompareData(rowdata11);
                                      }
                                      clickSharePieChartData(rowdata3);
                                      getAreaChartData(rowdata1, "2");
                                      getBarChartData(rowBarChartData);
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                                <div className="col-md-12 my-3"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                      <div className="ms-3">
                        <a
                          className="share bar-link-icon"
                          style={{ color: "black" }}
                        >
                          <div class="dropdown">
                            <a
                              type="button"
                              ref={applyBtn}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-4 filter-dropdown">
                              <div className="filter-popup">
                                <div className="portlet px-2 py-2">
                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">From</label>
                                    </div>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Sender Email Id"
                                        value={Sender}
                                        className="w-100 form-control my-2"
                                        name="Sender"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">To</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Recipient Email Id"
                                        className="w-100 form-control my-2"
                                        value={Receiver}
                                        name="Receiver"
                                        onChange={(text) =>
                                          setReceiver(text.target.value)
                                        }
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageReceiver}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Subject</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={(text) => {
                                          setSubject(text.target.value);
                                        }}
                                        className="w-100 form-control my-2"
                                        name="Subject"
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageSubject}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Message</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <textarea
                                        name=""
                                        id=""
                                        cols="80"
                                        rows="7"
                                        className="w-100 form-control my-2  gscmsg"
                                        value={EmailMessage}
                                        onChange={(text) => {
                                          setEmailMessage(text.target.value);
                                        }}
                                      ></textarea>
                                      <div style={{ color: "red" }}>
                                        {Message}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 my-2">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        style={{
                                          width: 70 + "px",
                                          height: 30 + "px",
                                        }}
                                        onClick={() => {
                                          if (isValidEmailData()) {
                                            sendEmail();
                                          }
                                        }}
                                      >
                                        Send
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                      </div>

                      <div className="row">
                        <div className="col-md-12 download-filter">
                          <span
                            class="dropdown"
                            style={{
                              marginRight: 0 + "px",
                              textAlign: "right",
                              display: "block",
                            }}
                          >
                            <button
                              class="outline-btn dropdown-toggle btn btn-primary"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-download " aria-hidden="true"></i>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <div
                                onClick={async () => {
                                  const promise = await downloadPDFfile(
                                    image,
                                    moduleClickShare
                                  );
                                  try {
                                    if (promise) {
                                      setLoading(false);
                                    } else {
                                      setLoading(false);
                                    }
                                  } catch (e) {
                                    setLoading(false);
                                  }
                                }}
                              >
                                <a class="dropdown-item">PDF</a>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <TabPanel>
              <PDFExport
                paperSize="A1"
                margin="2cm"
                fileName={moduleClickShare}
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
              >
                <div ref={image} className="row d-flex">
                  <div className="row click-share-charts d-flex px-0">
                    <div className="col-md-12 col-sm-12 ps-0 clickshare-chart d-flex">
                      <div className="portlet w-100">
                        <div className="portlet-header portlet-header-bordered">
                          <h3 className="portlet-title">Organic Share</h3>
                        </div>
                        <div className="row">
                          <div className={isDateRage ? "col-md-11" : "col-md-5"}>
                            {series11?.length ? (
                              <>
                                {series22?.length ? (
                                  <h6 className="text-center mt-3">
                                    {mmDDyyyyFormateForForm22(
                                      state.selection1.startDate
                                    ) +
                                      " to " +
                                      mmDDyyyyFormateForForm22(
                                        state.selection1.endDate
                                      )}
                                  </h6>
                                ) : (
                                  <></>
                                )}
                                <Chart
                                  options={{
                                    labels: labels11,
                                    dataLabels: {
                                      enabled: true,
                                    },
                                    legend: {
                                      show: isDateRage ? true : false
                                    },
                                    colors: [
                                      "#e6194B",
                                      "#f58231",
                                      "#ffe119",
                                      "#bfef45",
                                      "#3cb44b",
                                      "#42d4f4",
                                      "#4363d8",
                                      "#911eb4",
                                      "#f032e6",
                                      "#a9a9a9",
                                      "#9A6324",
                                      "#800000",
                                      "#469990",
                                      "#000075",
                                      "#fabed4",
                                    ],
                                  }}
                                  series={series11}
                                  type="pie"
                                  height={340}
                                />
                              </>
                            ) : (
                              <div className="my-5 no-data">
                                <svg
                                  class="ant-empty-img-simple"
                                  width="64"
                                  height="41"
                                  viewBox="0 0 64 41"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    transform="translate(0 1)"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <ellipse
                                      class="ant-empty-img-simple-ellipse"
                                      cx="32"
                                      cy="33"
                                      rx="32"
                                      ry="7"
                                    ></ellipse>
                                    <g
                                      class="ant-empty-img-simple-g"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                      <path
                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                        class="ant-empty-img-simple-path"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                                <span className="d-block"> No data</span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-5">
                            {series22?.length ? (
                              <>
                                <h6 className="text-center mt-3">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection2.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection2.endDate
                                    )}
                                </h6>
                                <Chart
                                  options={{
                                    labels: labels22,
                                    dataLabels: {
                                      enabled: true,
                                    },
                                    legend: {
                                      show: true,
                                    },

                                    colors: [
                                      "#e6194B",
                                      "#f58231",
                                      "#ffe119",
                                      "#bfef45",
                                      "#3cb44b",
                                      "#42d4f4",
                                      "#4363d8",
                                      "#911eb4",
                                      "#f032e6",
                                      "#a9a9a9",
                                      "#9A6324",
                                      "#800000",
                                      "#469990",
                                      "#000075",
                                      "#fabed4",
                                    ],
                                  }}
                                  series={series22}
                                  type="pie"
                                  height={340}
                                />
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>

                        {isDateRage ? (
                          <div className="organic-share clickshare-info">
                            <div class="information">
                              {" "}
                              <i class="fa fa-info" aria-hidden="true"></i>
                            </div>
                            <div className="info-hide py-3 px-3 portlet ">
                              <div className="row w-100">
                                <div class="col-md-6 px-3">
                                  <p>Total Search Volume: {totalSearchVolume}</p>
                                  <p>Total Number of Keyword: {totalKeywords}</p>
                                  <p>
                                    Estimate Organic Share:{" "}
                                    {totestimatedOrganicShare}%
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {isDateRage ? (
                        <></>
                      ) : (
                        <div className="compare-organic  clickshare-info">
                          <div class="information">
                            {" "}
                            <i class="fa fa-info" aria-hidden="true"></i>
                          </div>
                          <div className=" info-hide py-3 px-3 portlet ">
                            <div className="d-flex w-100">
                              <div class="px-3">
                                <h6 className="text-center">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection1.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection1.endDate
                                    )}
                                </h6>
                                <p>
                                  Total Search Volume:{" "}
                                  {totalsearchvolumepiechartforsingle}
                                </p>
                                <p>
                                  Total Number of Keyword:{" "}
                                  {totalkeywordpiechartforsingle}
                                </p>
                                <p>
                                  Estimate Organic Share:{" "}
                                  {totalestimatepiechartforsingle}%
                                </p>
                              </div>
                              <div class="px-3">
                                <h6 className="text-center">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection2.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection2.endDate
                                    )}
                                </h6>
                                <p>
                                  Total Search Volume: {totalsearchvolumepiechart}
                                </p>
                                <p>
                                  Total Number of Keyword: {totalkeywordpiechart}
                                </p>
                                <p>
                                  Estimate Organic Share: {totalestimatepiechart}%
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {isDateRage ? (
                      <div
                        className={
                          isDateRage
                            ? "col-md-12 col-sm-12 ps-0 clickshare-chart"
                            : "col-md-12 col-sm-12 pe-0 clickshare-chart"
                        }
                      >
                        <div className="col-md-12 portlet">
                          <div className="portlet-header portlet-header-bordered">
                            <h3 className="portlet-title">Click Share Trend</h3>
                          </div>
                          {areaDates.length > 0 ? (
                            <Chart
                              options={{
                                chart: {
                                  height: 350,
                                  type: "area",
                                },
                                dataLabels: {
                                  enabled: false,
                                },
                                stroke: {
                                  curve: "smooth",
                                },
                                xaxis: {
                                  type: "datetime",
                                  categories: areaDates,
                                },
                                grid: {
                                  show: false,
                                  padding: {
                                    left: 50,
                                    right: 50,
                                  },
                                },
                                yaxis: {
                                  title: {
                                    text: "",
                                  },
                                },
                                colors: [
                                  "#e6194B",
                                  "#f58231",
                                  "#ffe119",
                                  "#bfef45",
                                  "#3cb44b",
                                  "#42d4f4",
                                  "#4363d8",
                                  "#911eb4",
                                  "#f032e6",
                                  "#a9a9a9",
                                  "#9A6324",
                                  "#800000",
                                  "#469990",
                                  "#000075",
                                  "#fabed4",
                                ],
                              }}
                              series={areaValue}
                              type="area"
                              height={350}
                            />
                          ) : (
                            <div className="col-md-12">
                              <div className="my-5 no-data">
                                <svg
                                  class="ant-empty-img-simple"
                                  width="64"
                                  height="41"
                                  viewBox="0 0 64 41"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    transform="translate(0 1)"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <ellipse
                                      class="ant-empty-img-simple-ellipse"
                                      cx="32"
                                      cy="33"
                                      rx="32"
                                      ry="7"
                                    ></ellipse>
                                    <g
                                      class="ant-empty-img-simple-g"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                      <path
                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                        class="ant-empty-img-simple-path"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                                <span className="d-block"> No data</span>
                              </div>
                            </div>
                          )}
                        </div>
                        {isDateRage ? (
                          <div className="compare-organic clickshare-info">
                            <div class="information">
                              {" "}
                              <i class="fa fa-info" aria-hidden="true"></i>
                            </div>
                            <div className="info-hide py-3 px-3 portlet ">
                              <div className="row w-100">
                                <div class="col-md-6 px-3">
                                  <p>
                                    Total Search Volume:{" "}
                                    {totalSearchVolumeareachart}
                                  </p>
                                  <p>
                                    Total Number of Keyword:{" "}
                                    {totalKeywordsareachart}
                                  </p>
                                  <p>
                                    Estimate Organic Share:{" "}
                                    {totestimatedOrganicShareareachart}%
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {isDateRage ? (
                      <div></div>
                    ) : (
                      <div className="col-md-12 col-sm-12 ps-0 ">
                        <div className="portlet">
                          <div className="portlet-header portlet-header-bordered">
                            <div className="col-md-12 d-flex justify-content-between">
                              <h3 className="portlet-title">Click Share Trend</h3>
                              <div className="blue-box d-flex ">
                                <span className="box"></span>
                                <div className="date">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection1.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection1.endDate
                                    )}
                                </div>
                              </div>
                              <div className="green-box d-flex ms-4">
                                <span className="box"></span>
                                <div className="date">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection2.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection2.endDate
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {barURLs?.length ? (
                            <div>
                              <Chart
                                options={{
                                  legend: {
                                    show: false,
                                  },
                                  chart: {
                                    type: "bar",
                                    height: 350,
                                  },
                                  plotOptions: {
                                    bar: {
                                      horizontal: false,
                                      columnWidth: "55%",
                                      endingShape: "rounded",
                                    },
                                  },
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ["transparent"],
                                  },
                                  xaxis: {
                                    categories: barURLs,
                                  },
                                  yaxis: [
                                    {
                                      title: {
                                        text: "Click Share %",
                                      },
                                      labels: {
                                        formatter: function (val) {
                                          return val.toFixed(0) + "%";
                                        },
                                      },
                                    },
                                  ],
                                  fill: {
                                    opacity: 1,
                                  },
                                  tooltip: {
                                    y: {
                                      formatter: function (val) {
                                        return (
                                          Number(parseFloat(val).toFixed(2)) + "%"
                                        );
                                      },
                                    },
                                  },
                                }}
                                series={[
                                  {
                                    name: "ClickShare 1",
                                    data: barURLsVal,
                                  },
                                  {
                                    name: "ClickShare 2",
                                    data: barURLsVal2,
                                  },
                                ]}
                                type="bar"
                                height="300"
                              />
                              <div
                                className="text-center website"
                                style={{
                                  paddingBottom: "20px",
                                  color: "#424242",
                                  fontWeight: "700",
                                  fontSize: "1rem",
                                }}
                              >
                                WebSite
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12">
                              <div className="my-5 no-data">
                                <svg
                                  class="ant-empty-img-simple"
                                  width="64"
                                  height="41"
                                  viewBox="0 0 64 41"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    transform="translate(0 1)"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <ellipse
                                      class="ant-empty-img-simple-ellipse"
                                      cx="32"
                                      cy="33"
                                      rx="32"
                                      ry="7"
                                    ></ellipse>
                                    <g
                                      class="ant-empty-img-simple-g"
                                      fill-rule="nonzero"
                                    >
                                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                      <path
                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                        class="ant-empty-img-simple-path"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                                <span className="d-block"> No data</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="clickshare-trend clickshare-info">
                          <div class="information">
                            {" "}
                            <i class="fa fa-info" aria-hidden="true"></i>
                          </div>
                          <div className=" info-hide py-3 px-3 portlet ">
                            <div className="d-flex w-100">
                              <div class="px-3">
                                <h6 className="text-center">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection1.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection1.endDate
                                    )}
                                </h6>
                                <p>
                                  Total Search Volume:{" "}
                                  {totalSearchVolumebarchart1}
                                </p>
                                <p>
                                  Total Number of Keyword:{" "}
                                  {totalKeywordsbarchart1}
                                </p>
                                <p>
                                  Estimate Organic Share:{" "}
                                  {totalestimatedOrganicSharebarchart1}%
                                </p>
                              </div>
                              <div class="px-3">
                                <h6 className="text-center">
                                  {mmDDyyyyFormateForForm22(
                                    state.selection2.startDate
                                  ) +
                                    " to " +
                                    mmDDyyyyFormateForForm22(
                                      state.selection2.endDate
                                    )}
                                </h6>
                                <p>
                                  Total Search Volume:{" "}
                                  {totalSearchVolumebarchart2}
                                </p>
                                <p>
                                  Total Number of Keyword:{" "}
                                  {totalKeywordsbarchart2}
                                </p>
                                <p>
                                  Estimate Organic Share:{" "}
                                  {totalestimatedOrganicSharebarchart2}%
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="p-0">
                    <div className="row estimated">
                      <div className="portlet ">
                        <div className="portlet-header portlet-header-bordered ">
                          <h3 className="portlet-title">
                            Estimated Click Share{" "}
                          </h3>
                        </div>
                        <div className="portlet-body">
                          <div className="col-md-12 text-end estimate-table">
                            <div className="d-flex justify-content-end align-items-center">
                              <span class="dropdown">
                                <button
                                  class="btn btn-primary dropdown-toggle"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Click Share"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                                {/* <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Click Share"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">Excel</a>
                                </div>
                                <div
                                  onClick={() => {
                                    exportInToCSV(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Click Share"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">CSV</a>
                                </div>
                              </div> */}
                              </span>
                              {isDateRage ? (
                                <div>
                                  <div class="information ms-3">
                                    {" "}
                                    <i class="fa fa-info" aria-hidden="true"></i>
                                  </div>
                                  <div className="info-hide py-3 px-3 portlet ">
                                    <div className="row w-100">
                                      <div class="col-md-6 px-3 ">
                                        <p>
                                          Total Search Volume:{" "}
                                          {totalsearchvolumeforsingle}
                                        </p>
                                        <p>
                                          Total Number of Keyword:{" "}
                                          {totalkeywordforsingle}
                                        </p>
                                        <p>
                                          Estimate Organic Share:{" "}
                                          {totalestimatedforsingle}%
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <div class="information ms-3">
                                      {" "}
                                      <i
                                        class="fa fa-info"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className=" info-hide py-3 px-3 portlet ">
                                      <div className="d-flex w-100">
                                        <div class="px-3">
                                          <h6 className="text-center">
                                            {mmDDyyyyFormateForForm22(
                                              state.selection1.startDate
                                            ) +
                                              " to " +
                                              mmDDyyyyFormateForForm22(
                                                state.selection1.endDate
                                              )}
                                          </h6>
                                          <p>
                                            Total Search Volume:{" "}
                                            {totalsearchvolumeforsingle}
                                          </p>
                                          <p>
                                            Total Number of Keyword:{" "}
                                            {totalkeywordforsingle}
                                          </p>
                                          <p>
                                            Estimate Organic Share:{" "}
                                            {totalestimatedforsingle}%
                                          </p>
                                        </div>
                                        <div class="px-3">
                                          <h6 className="text-center">
                                            {mmDDyyyyFormateForForm22(
                                              state.selection2.startDate
                                            ) +
                                              " to " +
                                              mmDDyyyyFormateForForm22(
                                                state.selection2.endDate
                                              )}
                                          </h6>
                                          <p>
                                            Total Search Volume:{" "}
                                            {totalsearchvolumecompare}
                                          </p>
                                          <p>
                                            Total Number of Keyword:{" "}
                                            {totalkeywordcompare}
                                          </p>
                                          <p>
                                            Estimate Organic Share:{" "}
                                            {totalestimatecompare}%
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {isDateRage ? (
                          <div className="col-md-12">
                            <Table
                              className="table table-bordered  table-hover mt-3 "
                              id="datatable-1"
                              columns={ClickShareCol}
                              dataSource={ClickShareTable}
                              rowSelection={{ type: "checkbox", ...rowSelection }}
                              pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                              }}
                            />
                          </div>
                        ) : (
                          <div className="col-md-12">
                            <Table
                              className="table table-bordered  table-hover mt-3 estimated-click-share "
                              id="datatable-1"
                              columns={ClickShareCol}
                              dataSource={ClickShareTable1}
                              rowSelection={{ type: "checkbox", ...rowSelection }}
                              pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </PDFExport>
            </TabPanel>
          </Tabs>
        </div>
        <Loading isLoading={isLoading} />
      </>
      )}
    </>
  );
}

export default ModuleExpandClickShare;
