import React, { useEffect, useState } from "react";
import APIManager from "../services/APIManager";
import { getTrendsDetails } from "../utility/Constant";
import GoogleTrends from "./GoogleTrends";
import { default as ReactSelect, components } from "react-select";
import { getCountry, getAllCategory } from "../utility/Constant";
import { Frequency, themeStyle } from "../utility/Globals";

function GoogleTrendsView() {
  const [keywordsArray, setkeywordsArray] = useState([]);
  const [geo, setgeo] = useState("");
  const [time, settime] = useState("");
  useEffect(() => {
    getTrendsData();
    getCountryList();
    getCategorys();
  }, []);

  function getTrendsData() {
    APIManager.getAPI(
      getTrendsDetails + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response) {
        let keys = [];
        let dropdownKeys = [];
        response.data.googleTrendKeywordConfigurations.map((key) => {
          keys.push(key.keywordName);
          dropdownKeys.push({
            label: key.keywordName,
            value: key.keywordName,
            Key: key.keywordName,
            id: key.keywordName,
          });
        });
        setUserSelected(dropdownKeys);
        setfrequncySelected(response.data.frequency);
        setCountrySelected(response.data.country);
        setgeo(response.data.country);
        settime(response.data.frequency);
        setkeywordsArray(keys);
      }
    });
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function getCountryList() {
    APIManager.getAPI(getCountry).then((response) => {
      if (response && response.status == 200) {
        setCountryList(response.data.data);
      }
    });
  }

  function sayHello() {
    setgeo(countrySelected);
    settime(frequncySelected);
    setkeywordsArray(UserSelected);
  }

  const [UserSelected, setUserSelected] = useState({});
  function handleChangeUser(selected) {
    setUserSelected(selected);
  }

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("IN");
  function setcountry(e) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  const [searchSelected, setsearchSelected] = useState("");
  function setSearch(e) {
    var c = e.target.value;
    setsearchSelected(c);
  }

  const [frequncySelected, setfrequncySelected] = useState("");
  function setFrequncy(e) {
    var c = e.target.value;
    setfrequncySelected(c);
  }

  const [category, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  function getCategorys() {
    APIManager.getAPI(getAllCategory).then((response) => {
      if (response && response.status == 200) {
        setCategoryList(response.data);
      }
    });
  }

  return (
    <>
      <div className="row mt-4 ">
        <div className="col-md-4">
          <ReactSelect
            styles={themeStyle()}
            options={keywordsArray}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            onChange={handleChangeUser}
            value={UserSelected}
          />
        </div>
        <div className="col-md-4">
          <select
            onChange={setSearch}
            className="w-100"
            style={{ padding: 8 + "px" }}
          >
            <option value="">WebSearch</option>
            <option value="news">NewsSearch</option>
            <option value="froogle">GoogleShopping</option>
            <option value="images">ImageSearch</option>
            <option value="youtube">YouTubeSearch</option>
          </select>
        </div>
        <div className="col-md-4">
          <select
            onChange={setFrequncy}
            className="w-100"
            style={{ padding: 8 + "px" }}
          >
            {Frequency.map((item) => {
              return <option value={item.Name}>{item.DisplayName}</option>;
            })}
          </select>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-4">
          <select
            required
            value={countrySelected}
            onChange={setcountry}
            className="w-100"
            style={{ padding: 8 + "px" }}
          >
            {countryList.map((item) => {
              return <option value={item.iso2}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className="col-md-4">
          <select
            className="w-100"
            style={{ padding: 8 + "px" }}
            value={categorySelected}
            onChange={(item) => {
              setCategorySelected(item.target.value);
            }}
          >
            {category.map((item) => {
              return (
                <option value={item.categoryName}>{item.categoryName}</option>
              );
            })}
          </select>
        </div>
        <div className="col-md-4">
          <button
            class="btn btn-primary"
            style={{ padding: 8 + "px" }}
            onClick={sayHello}
          >
            Get Trends Data
          </button>
        </div>
      </div>

      <div class="row">
        {time && geo ? (
          <div id="widget">
            <GoogleTrends
              type="TIMESERIES"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />
            <GoogleTrends
              type="GEO_MAP"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />

            <GoogleTrends
              type="GEO_MAP_0"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />

            <GoogleTrends
              type="RELATED_QUERIES_0"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />

            <GoogleTrends
              type="GEO_MAP_1"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />

            <GoogleTrends
              type="RELATED_QUERIES_1"
              keywords={keywordsArray}
              url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
              geo={geo}
              time={time}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default GoogleTrendsView;
