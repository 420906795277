/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import Button from "@semcore/ui/button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const CustomButton = ({
  text,
  use,
  type,
  size,
  theme,
  addStyles,
  isDisable,
  isLoading,
  rightIcon,
  leftIcon,
  onPress,
  id
}) => {
  return (
    <Button
      id={id}
      use={use}
      size={size}
      theme={theme}
      disabled={isDisable}
      loading={isLoading}
      css={[addStyles && addStyles, styles.buttonContainer]}
      onClick={onPress}
      type={!!type ? type : ""}
    >
      {leftIcon && (
        <Button.Addon>
          <FeatherIcon icon={leftIcon} css={styles.icon} />
        </Button.Addon>
      )}
      <Button.Text css={styles.text}>{text}</Button.Text>
      {rightIcon && (
        <Button.Addon>
          <FeatherIcon icon={rightIcon} css={styles.icon} />
        </Button.Addon>
      )}
    </Button>
  );
};

export default CustomButton;
