/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from "react";
import Select, { InputSearch } from "@semcore/ui/select";
import * as styles from "./styles";
import { Text } from "@semcore/ui/typography";
import { Flex, Box } from "@semcore/ui/flex-box";
import Tooltip from "@semcore/ui/tooltip";
import InfoM from "@semcore/icon/Info/m";
import { AutoComplete } from "antd";
import FeatherIcon from "feather-icons-react";
import Input from "@semcore/ui/input";
import LabeledInput from "../../Input";
import { useState } from "react";
import Spin from "@semcore/ui/spin";

const SuggestionDropdown = ({
  defaultValue,
  value,
  placeholder,
  id,
  data,
  handleDropdownClick,
  onChange,
  widthStyle,
  rightIcon,
  handleSearch,
  isDropdownLoading,
  onBlur,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setValue] = useState(value);

  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current &&  !ref.current.contains(e.target)) {
        setTimeout(() => {
          setOpen(false);
          setValue(value);
        }, 500);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open, inputValue]);

  return (
    <div
      css={[styles.main, widthStyle && widthStyle]}
      id="suggestionContainer"
      ref={ref}
    >
      <AutoComplete
        notFoundContent={"No Options Found"}
        open={open}
        onFocus={() => setOpen(true)}
        popupMatchSelectWidth={252}
        options={data.options}
        onSearch={(value) => handleSearch(value)}
        getPopupContainer={() => document.getElementById("suggestionContainer")}
        dropdownStyle={styles.dropdown}
        dropdownRender={(menu) => {
          return isDropdownLoading ? (
            <div css={styles.spinnerContainer}>
              <Spin />
            </div>
          ) : data.options.length > 0 ? (
            data.options.map((option) => (
              <div
                css={styles.optionContainer}
                onClick={() => {
                  setValue(option.label);
                  handleDropdownClick(option.label);
                }}
              >
                <div css={styles.option}>{option.label}</div>
                {option.type && <div css={styles.type}>{option.type}</div>}
              </div>
            ))
          ) : (
            <div css={styles.spinnerContainer}>No Options Found</div>
          );
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (onBlur) {
              onBlur();
            }
          }
        }}
      >
        <div onClick={() => setOpen(true)}>
          <Input css={[styles.input, widthStyle && widthStyle]} onBlur={onBlur}>
            <Input.Value
              value={inputValue}
              placeholder={placeholder}
              id={id}
              onChange={(input) => {
                setValue(input);
              }}
              type={""}
            />
            <Input.Addon interactive={true}>
              <FeatherIcon icon={rightIcon} css={styles.icon} />
            </Input.Addon>
          </Input>
        </div>
      </AutoComplete>
    </div>
  );
};

export default SuggestionDropdown;
