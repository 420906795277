import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors"
import { textStyles } from "../../../styles/font"


export const main = css({
    padding: "2rem 1.5rem",
    ".ant-table-filter-column" :{
      justifyContent: "flex-start",
      columnGap: "0.5rem",
      alignItems: "center"
    },
    ".ant-table-column-title" :{
      flex: "none"
    },
    ".ant-table-column-sorters" :{
      justifyContent: "flex-start",
      columnGap: "1rem"
    },
    ".ant-table-filter-trigger" : {
      margin: 0
    },
    ".ant-table-column-sorter" : {
      marginLeft: 0
    },
    ".ant-table" : {
      padding: 0,
      border: `1px solid #E2E8F0`,
      borderRadius: `0.375rem 0.375rem 0rem 0rem`
    },
    ".ant-table-tbody > tr > td" : {
      borderBottom: "none"
    },
    ".ranking-table-design .ant-pagination-item, .latest-table .ant-pagination-item":
    {
      minWidth: "30px",
      height: " 30px",
      border: 0,
    },
  ".ant-pagination-item-active": {
    background: "#E2E8F0",
  },
  ".ant-pagination-item": {
    border: 0,
    ...textStyles.s3,
    lineHeight: "24px !important",
  },
  ".ant-pagination-item-ellipsis": {
    height: "19px",
  },
  
})

export const header = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems : "end",
  marginBottom: "2rem"
})

export const buttonGroup = css({
  display: "flex",
  columnGap: "0.75rem"
})

export const actionIconContainer = css({
  display: "flex",
  columnGap: "0.75rem"
})

export const actionIcon = css({
  height: "1.5rem",
  width: "1.5rem",
  cursor: "pointer"
})

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

export const keyword = css({
  ...textStyles.b1,
  color: Colors.neutral[900]
})

export const buttonContainer = css({
  display: "flex",
  justifyContent: "flex-end"
})

export const modalDescription = css({
  ...textStyles.b1,
  color: Colors.neutral[900]
})

export const empty = css({
  ...textStyles.h6,
  color: Colors.neutral[600],
  textAlign: "center",
  minHeight: "calc(100vh - 32rem)",
  display:"flex",
  justifyContent: "center",
  alignItems: "center"
});

export const secondaryButtonDanger = css({
  backgroundColor: "white !important",
  color:Colors.error[800],
  borderColor : Colors.error[800]
})

export const redBold = css({
  ...textStyles.s1,
  color: Colors.error[800]
})

export  const bold = css({
  ...textStyles.s1,
  color: Colors.neutral[600]
})

export const secondaryButton =  css({
  backgroundColor: "white !important",
  color:Colors.primary[800],
  borderColor: Colors.primary[700] ,
})

export const searchInput = css({
  ...textStyles.b3,
  width: "18.8125rem",
  border: `1px solid ${Colors.neutral[800]}`,
});