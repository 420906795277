import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { default as ReactSelect } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "./index";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import {
  calanderDate,
  downloadPDFfile,
  exportInToCSV,
  exportToEXCEL,
  formateDateIntoDatemonth,
  getBase64path,
  // getCheckPermission,
  themeStyle,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import APIManager from "../services/APIManager";
import {
  getROICalculator,
  getLanguage,
  getROIConfig,
  sendEmailApi,
  moduleROI,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";

function ModuleExpandROI() {
  const [options, setObject] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: "Dates",
        style: {
          color: "#000",
        },
      },
    },
  });

  const [series, setSeries] = useState([]);

  const [PaidCPCTable, setPaidCPCTable] = useState([]);
  const [PaidCTCCol, setPaidCTCCol] = useState([]);
  const [methodROI, setmethodROI] = useState("GSC");
  const [GSCTable, setGSCTable] = useState([]);
  const [GSCCol, setGSCCol] = useState([]);
  const [ClickShareCol, setClickShareCol] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [show, setshow] = useState(false);
  const [showGSC, setshowGSC] = useState(false);
  const [showClickShare, setshowClickShare] = useState(false);
  const [ROI, setROI] = useState([]);
  const [ROAS, setROAS] = useState([]);
  const [CostPerLead, setCostPerLead] = useState([]);
  // const [costPer, setCostPer] = useState([]);
  // const [leadsClientDocs, setLeadsClientDocs] = useState([]);
  const [AvgPaidCPC, setAvgPaidCPC] = useState([]);
  const [AvgORGANICCPC, setAvgORGANICCPC] = useState([]);
  const [selectedPaidExportList, setSelectedPaidExportList] = useState([]);
  const [paidexportList, setPaidExportList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedOrgExportList, setSelectedOrgExportList] = useState([]);
  const [orgexportList, setOrgExportList] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  // const [languageList, setLanguageList] = useState([]);
  // const [selectedLanguageCode, setSelectedLanguageCode] = useState("");

  // const [selectkeyword, setselectkeyword] = useState("");
  const [multicountryselectedList, setmulticountryselectedList] = useState([
    { Country: "", City: "" },
  ]);
  // const handleMultiCountrySelecctedAdd = () => {
  //   let list = [...multicountryselectedList];

  //   setmulticountryselectedList([
  //     ...multicountryselectedList,
  //     {
  //       Country: list[list?.length - 1]?.Country,
  //       City: list[list?.length - 1]?.City,
  //     },
  //   ]);
  // };
  useEffect(() => {
    getCountryList();

    var data = [];

    data = [
      {
        title: "Keyword List",
        dataIndex: "keyword",
        key: "keyword",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Search Volume",
        dataIndex: "volume",
        key: "volume",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "CPC",
        dataIndex: "ctc",
        key: "ctc",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ctc, b.ctc),
          multiple: 3,
        },
      },
    ];
    setPaidCTCCol(data);
    data = [];
    setPaidCPCTable(data);
    data = [
      {
        title: "Keywords",
        dataIndex: "keyword",
        key: "keyword",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Clicks",
        dataIndex: "click",
        key: "click",

        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.click, b.click),
          multiple: 3,
        },
      },
    ];
    setGSCCol(data);
    data = [
      {
        title: "Keywords",
        dataIndex: "keyword",
        key: "keyword",
      },
      {
        title: "EStd. Organic Traffic",
        dataIndex: "organictraffic",
        key: "organictraffic",
      },
      {
        title: "",
        dataIndex: "",
        key: "",
      },
    ];
    setClickShareCol(data);
    data = [];
    setGSCTable(data);
  }, []);

  const paidrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          // key: item.id,
          keyword: item.keyword,
          volume: item.searchVolume,
          ctc: item.cpc,
        };
        list.push(obj);
      });
      setSelectedPaidExportList(list);
    },
  };

  const orgrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let columns = Object.keys(selectedRows[0]);
        let obj = {
          key: item.id,
          keyword: item.keyword,
          click: item.clicks,
        };
        list.push(obj);
      });
      setSelectedOrgExportList(list);
    },
  };

  function handleCountryChange(selected) {
    setCountrySelected(selected);
    let getdata = {
      location: selected.id,
      // Language: selectedLanguageCode,
      // DataSource: selectDataSource,
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      ProjectId: localStorage.getItem("projectGId"),
    };
    createROICalculatorResearch(getdata);
  }

  function handleModalGSC() {
    setshowGSC(!showGSC);
  }

  function handleModalClickShare() {
    setshowClickShare(!showClickShare);
  }

  function openModal(a) {
    if (a == "GSC") {
      handleModalGSC();
    } else {
      handleModalClickShare();
    }
  }

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  function createROICalculatorResearch(rowdata) {
    APIManager.postAPI(getROICalculator, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setROI(response?.data.roi);
          setROAS(response?.data.roas);
          setCostPerLead(response?.data.costPerLead);
          setAvgPaidCPC(response?.data.avgPaidCPC);
          setAvgORGANICCPC(response?.data.avgOrganicCPC);
          const list1 = [];
          const paidexportList = [];
          let i = 0;
          response.data.roiCalculatorGoogleAdsKeywords.map((item, idx) => {
            paidexportList.push({
              key: (i += 1),
              keyword: item.keyword,
              volume: parseFloat(item.searchVolume)?.toFixed(2),
              ctc: parseFloat(item.cpc)?.toFixed(2),
            });

            let obj = {
              // key: (i += 1),
              keyword: item.keyword,
              volume: parseFloat(item.searchVolume)?.toFixed(2),
              ctc: parseFloat(item.cpc)?.toFixed(2),
            };
            list1.push(obj);
          });

          setPaidCPCTable(list1);
          setPaidExportList(paidexportList);
          const list2 = [];
          const exportList = [];
          let j = 0;
          response.data.roiCalculatorGoogleSearchConsoles.map((item) => {
            exportList.push({
              key: (j += 1),
              keyword: item.keyword,
              click: parseFloat(item.clicks)?.toFixed(2),
            });
            let obj2 = {
              // key: (j += 1),
              keyword: item.keyword,
              click: parseFloat(item.clicks)?.toFixed(2),
            };
            list2.push(obj2);
          });
          setGSCTable(list2);
          setOrgExportList(exportList);

          let options;
          let dataForGraph = [];
          response.data.dailyROIs.map((item) => {
            let graphData = [];
            let seriesData = [];
            const startDate = rowdata.startDate;
            const endDate = rowdata.endDate;
            const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
            const diffDays = 0 | (diffTime / 864e5);
            if (diffDays) {
              for (let i = 0; i <= diffDays; i++) {
                const datedata = mmDDyyyyFormateDateSet(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                );
                graphData.push(item[datedata] ? item[datedata] : 0);
                seriesData.push(
                  formateDateIntoDatemonth(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  )
                );

                options = {
                  chart: {
                    id: "basic-bar",
                  },
                  xaxis: {
                    tickPlacement: "on",
                    categories: seriesData,
                    title: {
                      text: "Dates",
                      style: {
                        color: "#000",
                      },
                    },
                  },
                };
              }
            }

            dataForGraph.push({
              name: item.dailyROI,
              data: graphData,
            });
          });
          setObject(options);
          setSeries(dataForGraph);
        }
      }
    );
  }
  const [roi, setroi] = useState(false);

  function getCountryList() {
    APIManager.getAPI(getROIConfig + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          response.data.roiCalculatorConfigurationLocationMappings.map(
            (item) => {
              list.push({
                key: item.locations.locationCode,
                id: item.locations.locationCode,
                value: item.locations.countryName,
                label: item.locations.countryName,
              });
            }
          );

          setCountryList(list);
          //getKeywordResearchData(list);
          getLanguageList(list[0].id);
          setCountrySelected(list[0]);
        }
      }
    );
  }

  // function getKeywordResearchData(list) {
  //   APIManager.getAPI(
  //     getKeywordResearchConfig + localStorage.getItem("projectGId")
  //   ).then((response) => {
  //     if (response && response.status == 200) {
  //       setLeadsClientDocs({ name: response.data.credentials?.split("\\")[1] });
  //       const keywordList = [];
  //       response.data.keywordResearchConfigurationKeywordMappings.map(
  //         (item) => {
  //           keywordList.push({
  //             key: item.keywords.id,
  //             id: item.keywords.id,
  //             label: item.keywords.fieldValue,
  //             value: item.keywords.fieldValue,
  //           });
  //         }
  //       );
  //       setselectkeyword(keywordList);
  //       const cList = [];
  //       response.data.keywordResearchConfigurationLocationMappings.map(
  //         (item) => {
  //           list.map((data) => {
  //             if (item.locationId == data.id) {
  //               cList.push({
  //                 key: data.key,
  //                 id: data.id,
  //                 label: data.label,
  //                 value: data.value,
  //               });
  //             }
  //           });
  //         }
  //       );
  //       setCountrySelected(cList);
  //     }
  //   });
  // }

  function getLanguageList(locationCode) {
    // APIManager.getAPI(getLanguage).then((response) => {
    //   if (response && response.status == 200) {
    //     let sortedLanguageList = [];
    //     sortedLanguageList = response.data;

    //     var sortedArray = sortedLanguageList.sort((a, b) =>
    //       a.languageName > b.languageName ? 1 : -1
    //     );
    //     setLanguageList(sortedArray);
    //     setSelectedLanguageCode(response.data[0].languageCode);
    //   }

    let getdata = {
      location: countrySelected.id,
      // location: selected.id,
      // Language: response.data[0].languageCode,
      // DataSource: selectDataSource,
      startDate: mmDDyyyyFormateDateSet(head1),
      endDate: mmDDyyyyFormateDateSet(head2),
      projectId: localStorage.getItem("projectGId"),
    };
    createROICalculatorResearch(getdata);
    //});
  }

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the ROI Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);

  const applyBtn = useRef(null);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("ROI Reports");

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let fileBase64 = await getBase64path(image)
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleROI
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("ROI Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const [isLoading, setLoading] = useState(false);
  return (
    <>
      <div className="row">
        <Tabs>
          <div className="portlet ">
            <div className="portlet-header portlet-header-bordered my-3">
              <div className="col-md-9 col-sm-12">
                <TabList className="nav nav-tabs portlet-nav">
                  <Tab
                    className={
                      roi ? "nav-item nav-link " : "nav-item nav-link  active"
                    }
                    onClick={() => {
                      setroi(false);
                    }}
                  >
                    Return on Investment
                  </Tab>
                </TabList>
              </div>
              {!roi ? (
                <div ref={ref} class="col-md-3 col-sm-12">
                  <div className="calendar-main d-flex justify-content-end align-items-center ga-calender">
                    <div className="add-new-btnw">
                      <button
                        className="btn btn-primary"
                        style={{ width: "250px" }}
                        onClick={() => setOpen(!open)}
                      >
                        {calanderDate(head1, head2)}
                      </button>
                    </div>

                    {open && (
                      <div id="example-collapse-text-calendar">
                        <Card body className="daterange-picker-card  mt-2">
                          <div className="d-flex justify-content-between">
                            <div className="calendar-col">
                              <Calendar
                                className="custom-calendar"
                                value={values}
                                onChange={(e) => {
                                  setValues(e);
                                  setHead1(e[0].format());
                                  setHead2(e[1]?.format());
                                }}
                                range
                                numberOfMonths={1}
                                showOtherDays
                              />
                            </div>
                            <div className="ms-3">
                              <button
                                onClick={() => {
                                  // setPaidCTCCol([]);
                                  // setPaidCPCTable([]);
                                  setheadvalues();
                                  setOpen(!open);
                                  let getdata = {
                                    location: countrySelected[0]?.id,
                                    // Language: selectedLanguageCode,
                                    // DataSource: selectDataSource,
                                    startDate: mmDDyyyyFormateDateSet(head1),
                                    endDate: mmDDyyyyFormateDateSet(head2),
                                    projectId:
                                      localStorage.getItem("projectGId"),
                                  };
                                  createROICalculatorResearch(getdata);
                                  setRefreshTicketData(true);
                                  setTimeout(() => {
                                    setRefreshTicketData(false);
                                  }, 1000);
                                }}
                                className="btn-outline btn btn-primary"
                              >
                                Apply
                              </button>
                              {/* </div> */}
                              {/* </div> */}
                              {/* </div> */}
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}

                    <div className="d-flex 3-icons">
                      <a
                        className="bar-link-icon ms-3"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-filter" aria-hidden="true"></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="col-md-12 my-2">
                                  <label className="common-label">
                                    {" "}
                                    Paid CPC
                                  </label>
                                </div>
                                <div className="col-md-12 mt-0">
                                  <label style={{ fontSize: 14 + "px" }}>
                                    Location
                                  </label>
                                  <br />
                                  <ReactSelect
                                    styles={themeStyle()}
                                    className="w-200"
                                    options={countryList}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleCountryChange}
                                    value={countrySelected}
                                    allowSelectAll={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>

                      <div className="ms-3">
                        <a
                          className="share bar-link-icon"
                          style={{ color: "black" }}
                        >
                          <div class="dropdown">
                            <a
                              type="button"
                              ref={applyBtn}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-4 filter-dropdown">
                              <div className="filter-popup">
                                <div className="portlet px-2 py-2">
                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">From</label>
                                    </div>
                                    <div className="col-md-10">
                                      {" "}
                                      <input
                                        type="text"
                                        required
                                        placeholder="Sender Email Id"
                                        value={Sender}
                                        className="w-100 form-control my-2"
                                        name="Sender"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">To</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      {" "}
                                      <input
                                        type="text"
                                        required
                                        placeholder="Recipient Email Id"
                                        className="w-100 form-control my-2"
                                        value={Receiver}
                                        name="Receiver"
                                        onChange={(text) =>
                                          setReceiver(text.target.value)
                                        }
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageReceiver}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Subject</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={(text) => {
                                          setSubject(text.target.value);
                                        }}
                                        className="w-100 form-control my-2"
                                        name="Subject"
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageSubject}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Message</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <textarea
                                        name=""
                                        id=""
                                        cols="80"
                                        rows="7"
                                        className="w-100 form-control my-2 gscmsg"
                                        value={EmailMessage}
                                        onChange={(text) => {
                                          setEmailMessage(text.target.value);
                                        }}
                                      ></textarea>
                                      <div style={{ color: "red" }}>
                                        {Message}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 my-2">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        style={{
                                          width: 70 + "px",
                                          height: 30 + "px",
                                        }}
                                        onClick={() => {
                                          if (isValidEmailData()) {
                                            sendEmail();
                                          }
                                        }}
                                      >
                                        {" "}
                                        Send
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                      </div>
                      <div className="row">
                        <div className="col-md-12 download-filter">
                          <span
                            class="dropdown"
                            style={{
                              marginRight: 0 + "px",
                              textAlign: "right",
                              display: "block",
                            }}
                          >
                            {/* {getCheckPermission("export", "modules") ? ( */}
                              <button
                                class="outline-btn dropdown-toggle btn btn-primary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  class="fa fa-download "
                                  aria-hidden="true"
                                ></i>
                              </button>
                            {/* ) : (
                              <></>
                            )} */}
                            <div
                              class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <div
                                onClick={async () => {
                                  setLoading(true)
                                  const promise = await downloadPDFfile(image, moduleROI)
                                  try {
                                    if (promise) {
                                      setLoading(false);
                                    } else {
                                      setLoading(false);
                                    }
                                  } catch (e) {
                                    setLoading(false);
                                  }
                                }}
                              >
                                <a class="dropdown-item">PDF</a>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <TabPanel>
            <PDFExport
              paperSize="A1"
              margin="2cm"
              fileName={moduleROI}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image} >

                <div className="portlet px-2 py-4" id="web-page-pdf">
                  <div className="ROI-outer mt-4">
                    <div className="col col-sm-12 ROI-inner ">
                      <h4>ROI</h4>
                      <p className="text-black">{ROI}</p>
                    </div>
                    <div className=" col col-sm-12 ROI-inner mx-4">
                      <h4>ROAS</h4>
                      <p className="text-black">{ROAS}</p>
                    </div>

                    <div className="col col-sm-12 ROI-inner">
                      <h4 className="text-black">Cost per Lead</h4>
                      {CostPerLead ? (
                        <p className="text-black">
                          {parseFloat(CostPerLead).toFixed(2)}
                        </p>
                      ) : (
                        <h4></h4>
                      )}
                    </div>
                  </div>
                </div>

                <div className="portlet px-2 py-4">
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 d-flex">
                          <label className="common-label my-3">Metric</label>
                          <select
                            class="w-50 ms-3"
                            style={{ ackground: "#FFFFFF" }}
                          >
                            <option value="ROI">Return on Investment</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div className="col-md-6 custom-borrig ps-0">
                    <div className="portlet px-2 py-4">
                      <div className="da-average-div common-mb-24 roi-small">
                        <h4 style={{ fontSize: 14 + "px" }}>
                          {" "}
                          Avg.{" "}
                          <span>
                            Paid CPC
                            <br />
                          </span>
                        </h4>
                        {AvgPaidCPC ? (
                          <h4>{parseFloat(AvgPaidCPC)?.toFixed(2)}</h4>
                        ) : (
                          <h4></h4>
                        )}
                      </div>

                      <div className="d-flex justify-content-between align-items-center mt-3 px-2">
                        <div class="main-title" style={{ fontSize: 14 + "px" }}>
                          Paid CPC
                        </div>
                        <div>
                          <span class="dropdown">
                            {/* {getCheckPermission("export", "modules") ? ( */}
                              <button
                                class="btn btn-primary dropdown-toggle ms-3"
                                type="button"
                                onClick={() => {
                                  exportToEXCEL(
                                    selectedPaidExportList?.length > 0
                                      ? selectedPaidExportList
                                      : paidexportList,
                                    "ROI"
                                  );
                                }}
                              >
                                Export
                              </button>
                            {/* ) : (
                              <></>
                            )} */}
                            {/* <div
                              class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <div
                                onClick={() => {
                                  exportToEXCEL(
                                    selectedPaidExportList?.length > 0
                                      ? selectedPaidExportList
                                      : paidexportList,
                                    "ROI"
                                  );
                                }}
                              >
                                <a class="dropdown-item">Excel</a>
                              </div>
                              <div
                                onClick={() => {
                                  exportInToCSV(
                                    selectedPaidExportList?.length > 0
                                      ? selectedPaidExportList
                                      : paidexportList,
                                    "ROI"
                                  );
                                }}
                              >
                                <a class="dropdown-item">CSV</a>
                              </div>
                            </div> */}
                          </span>
                        </div>
                      </div>
                      <Table
                        className="table table-bordered  table-hover mt-3 paid-table"
                        id="datatable-1"
                        columns={PaidCTCCol}
                        dataSource={PaidCPCTable}
                        rowSelection={{ type: "checkbox", ...paidrowSelection }}
                        pagination={{
                          position: ["bottomRight"],
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 pe-0 gsc-avg">
                    <div className="portlet px-2 py-4" id="web-page-pdf">
                      {methodROI == "GSC" ? (
                        <>
                          <div
                            className="da-average-div common-mb-24 roi-big d-flex justify-content-center"
                            style={{ marginTop: "unset" }}
                          >
                            <h4 style={{ fontSize: 14 + "px" }}>
                              Avg.{" "}
                              <span style={{ textTransform: "uppercase" }}>
                                ORGANIC CPC - GSC
                              </span>
                            </h4>
                            {/* {AvgORGANICCPC ? ( */}
                            <h4 class="ms-3" style={{ fontSize: 14 + "px" }}>
                              {parseFloat(AvgORGANICCPC)?.toFixed(2)}
                            </h4>
                            {/* ) : (
                          <h4></h4>
                        )} */}
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-3 px-2">
                            <div class="main-title" style={{ fontSize: 14 + "px" }}>
                              Organic CPC - GSC
                            </div>
                            <div>
                              <span class="dropdown">
                                {/* {getCheckPermission("export", "modules") ? ( */}
                                  <button
                                    class="btn btn-primary dropdown-toggle ms-3"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedOrgExportList?.length > 0
                                          ? selectedOrgExportList
                                          : orgexportList,
                                        "ROI"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                {/* ) : (
                                  <></>
                                )} */}
                                {/* <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedOrgExportList?.length > 0
                                          ? selectedOrgExportList
                                          : orgexportList,
                                        "ROI"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">Excel</a>
                                  </div>
                                  <div
                                    onClick={() => {
                                      exportInToCSV(
                                        selectedOrgExportList?.length > 0
                                          ? selectedOrgExportList
                                          : orgexportList,
                                        "ROI"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">CSV</a>
                                  </div>
                                </div> */}
                              </span>
                            </div>
                          </div>
                          <Table
                            className="table table-bordered table-hover mt-3 paid-table"
                            id="datatable-1"
                            columns={GSCCol}
                            dataSource={GSCTable}
                            rowSelection={{
                              type: "checkbox",
                              ...orgrowSelection,
                            }}
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div></PDFExport>
          </TabPanel>
        </Tabs>
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandROI;
