/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import RemainingCreditsCard from "./RemainingCreditsCard";
import CustomButton from "../../shared/button";
import { Input, Table } from "antd";
import { Colors } from "../../../styles/colors";
import LabeledInput from "../../shared/Input";
import { Sorter } from "../../../common/Sorter";
import { textStyles } from "../../../styles/font";
import ButtonDropDown from "../../shared/Dropdown/Button";
import AddSingleKeyword from "./addSingleKeyword";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setFilterOptions,
  setFormState,
  setIsComplete,
  setIsEditingKeyword,
  setKeywords,
  setSingleKeywordData,
} from "../createEditCampaign/campaignDetailSlice";
import ConfirmationModal from "../../shared/modal/confirmation";
import {
  documentFileType,
  onlyCSVFile,
  onlyXlsFile,
} from "../../../utility/Constant";
import CustomNotice from "../../shared/notice";
import {
  addKeywords,
  deleteKeywords,
  getCampaignDetails,
  importKeywords,
  replaceKeywords,
} from "../../../services/ranking";
import toast from "react-hot-toast";
import template from "../../assets/templates/Keyword_Upload_Template.xlsx";
import SpinContainer from "@semcore/ui/spin-container";
import { exportToEXCEL } from "../../../utility/Globals";
import { array } from "yup";
import { updateRemainingCredits } from "../campaigns/campaignsSlice";
import { getCurrentDate, getLocal } from "../../../utility/helpers";
import DropDownInput from "../../shared/Dropdown/Input";

const AddKeywords = ({ addStyles, onNext, onBack, isEdit }) => {
  const [searchText, setSearchText] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editableRow, setEditableRow] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState("keywordsTable");
  const [deleteButtonText, setDeletButtonText] = useState("Delete All");
  const [selected, setSelected] = useState([]);
  const [showConfirmImport, setShowConfirmImport] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showUploadFailedNotice, setShowUploadFailedNotice] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const searchInputRef = useRef();
  const dispatch = useDispatch();
  const credits = useSelector((state) => state.campaigns.credits);
  const keywordsTotal = useSelector((state) => state.campaigns.keywordsTotal);
  const keywords = useSelector((state) => state.campaignDetails.keywords);
  const uniqueCategories = useSelector(
    (state) => state.campaignDetails.categoryFilterOptions
  );
  const typeFilterOptions = useSelector(
    (state) => state.campaignDetails.typeFilterOptions
  );
  const priorityFilterOptions = useSelector(
    (state) => state.campaignDetails.priorityFilterOptions
  );
  const [uploadError, setUploadError] = useState("");
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
  const campaignId = useSelector((state) => state.campaignDetails.details.id);
  const [isDeleting, setIsDeleting] = useState(false);
  const [status, setStatus] = useState("loading");
  const [replace, setReplace] = useState(false);
  const [currentDataSource, setCurrentDataSource] = useState([]);
  const userId = getLocal("userId");

  useEffect(() => {
    if (isEdit && activeComponent === "keywordsTable") {
      fetchKeywords();
    }
  }, [activeComponent]);

  const fetchKeywords = async () => {
    try {
      setIsLoading(true);
      const response = await getCampaignDetails(campaignId, "2");
      if (response.status === "success") {
        const data = response.keywords_configurations;
        const keywords = data.map((keyword) => ({
          keywords: keyword.keywords,
          searchvolume: keyword.searchvolume,
          keywordcategory: keyword.keywordcategory,
          types: keyword.types,
          priority: keyword.priority,
          action: "",
          key: isEdit ? keyword.id : keyword.keywords,
        }));
        dispatch(setKeywords(keywords));
        dispatch(
          setFilterOptions({
            categories: response.unique_keywordcategories,
            types: response.unique_types,
            priority: response.unique_priority,
          })
        );
        setIsLoading(false);
        dispatch(setFormState({ component: "addKeywords", isComplete: true }));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleImportClick = () => {
    if (keywords.length > 0 && !!replace) {
      setShowConfirmImport(true);
    } else {
      inputRef.current.click();
    }
  };

  const handleImport = () => {
    var input = document.getElementById("fileinput");
    if (isEdit) {
      if (replace) {
        keywordsReplace(input.files.item(0));
      } else {
        keywordsAdd(input.files.item(0));
      }
    } else {
      keywordImport(input.files.item(0));
    }
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    const filterApplied = !Object.values(filters).every(
      (value) => value === null
    );
    setIsFilterApplied(filterApplied);
    setCurrentDataSource(extra.currentDataSource);
  };

  const keywordImport = async (file) => {
    setIsLoading(true);
    setShowUploadFailedNotice(false);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("TotalRemainingKeywordsCredits", credits.remaining);
    try {
      const response = await importKeywords(bodyFormData);
      if (response.status === "success") {
        const keywords = response.fileData.map((keyword) => ({
          keywords: keyword["Keywords"],
          searchvolume: keyword["Search Volume"],
          keywordcategory: keyword["Category Name"],
          types: keyword["Type"],
          priority: keyword["Priority"],
          action: "",
          key: keyword["Keywords"],
        }));
        dispatch(setKeywords(keywords));
        dispatch(
          setFilterOptions({
            categories: response.unique_keywords_categories,
            types: response.unique_keywords_Type,
            priority: response.unique_keywords_Priority,
          })
        );
        setIsLoading(false);
        toast.success("Keywords Imported Successfully");
        dispatch(setFormState({ component: "addKeywords", isComplete: true }));
      } else {
        setIsLoading(false);
        setShowUploadFailedNotice(true);
      }
      inputRef.current.value = null;
    } catch (error) {
      if (error?.response?.data?.error_message) {
        setUploadError(error.response.data.error_message);
      } else {
        setUploadError(
          "There is some issue while uploading. Please check the data format in the given template and try uploading again."
        );
      }
      setIsLoading(false);
      setShowUploadFailedNotice(true);
      inputRef.current.value = null;
    }
  };

  const keywordsReplace = async (file) => {
    setIsLoading(true);
    setShowUploadFailedNotice(false);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("TotalRemainingKeywordsCredits", credits.remaining);
    bodyFormData.append("user_id", userId);
    try {
      const response = await replaceKeywords(campaignId, bodyFormData);
      if (response.status === "success") {
        const data = response.fileData.map((keyword) => ({
          keywords: keyword.keywords,
          searchvolume: keyword.searchvolume,
          keywordcategory: keyword.keywordcategory,
          types: keyword.types,
          priority: keyword.priority,
          action: "",
          key: keyword.id,
        }));
        dispatch(setKeywords(data));
        dispatch(
          updateRemainingCredits(response.TotalRemainingKeywordsCredits)
        );
        dispatch(
          setFilterOptions({
            categories: response.unique_keywordcategories,
            types: response.unique_keywordtypes,
            priority: response.unique_keywordpriority,
          })
        );
        setIsLoading(false);
        toast.success("Keywords Imported Successfully");
        dispatch(setFormState({ component: "addKeywords", isComplete: true }));
      } else {
        setIsLoading(false);
        setShowUploadFailedNotice(true);
      }
      inputRef.current.value = null;
    } catch (error) {
      if (error.response.data.error_message) {
        setUploadError(error.response.data.error_message);
      } else {
        setUploadError(
          "There is some issue while uploading. Please check the data format in the given template and try uploading again."
        );
      }
      setIsLoading(false);
      setShowUploadFailedNotice(true);
      inputRef.current.value = null;
    }
  };

  const keywordsAdd = async (file) => {
    setIsLoading(true);
    setShowUploadFailedNotice(false);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("TotalRemainingKeywordsCredits", credits.remaining);
    bodyFormData.append("user_id", userId);
    try {
      const response = await addKeywords(campaignId, bodyFormData);
      if (response.status === "success") {
        const data = response.fileData.map((keyword) => ({
          keywords: keyword.keywords,
          searchvolume: keyword.searchvolume,
          keywordcategory: keyword.keywordcategory,
          types: keyword.types,
          priority: keyword.priority,
          action: "",
          key: keyword.id,
        }));
        dispatch(setKeywords(data));
        dispatch(
          updateRemainingCredits(response.TotalRemainingKeywordsCredits)
        );
        dispatch(
          setFilterOptions({
            categories: response.unique_keywordcategories,
            types: response.unique_keywordtypes,
            priority: response.unique_keywordpriority,
          })
        );
        setIsLoading(false);
        toast.success("Keywords Imported Successfully");
        dispatch(setFormState({ component: "addKeywords", isComplete: true }));
      } else {
        setIsLoading(false);
        setShowUploadFailedNotice(true);
      }
      inputRef.current.value = null;
    } catch (error) {
      if (error.response.data.error_message) {
        setUploadError(error.response.data.error_message);
      } else {
        setUploadError(
          "There is some issue while uploading. Please check the data format in the given template and try uploading again."
        );
      }
      setIsLoading(false);
      setShowUploadFailedNotice(true);
      inputRef.current.value = null;
    }
  };

  const deleteFromDataBase = async () => {
    setIsDeleting(true);
    try {
      const newSelected = [...selected];
      let payload;
      if (newSelected.length > 0) {
        payload = {
          keyword_ids: newSelected,
          TotalRemainingKeywordsCredits: credits.remaining,
          user_id: userId,
          campaign_id: campaignId,
        };
      } else {
        payload = {
          TotalRemainingKeywordsCredits: credits.remaining,
          campaign_id: campaignId,
        };
      }
      const response = await deleteKeywords(payload);
      if (response.status === "success") {
        let newData;
        if (selected.length > 0) {
          newData = keywords.filter(
            (item, index) => !selected.includes(item.key)
          );
          const newCurrenDataSource = currentDataSource.filter(
            (item) => !selected.includes(item.key)
          );
          setCurrentDataSource(newCurrenDataSource);
        } else {
          newData = [];
        }
        dispatch(
          updateRemainingCredits(response.TotalRemainingKeywordsCredits)
        );
        dispatch(setKeywords(newData));
        setIsDeleting(false);
        setShowConfirmDelete(false);
        setSelected([]);
        toast.success(`Deleted Successfully`);
      } else {
        toast.error(`Delete Failed`);
      }
    } catch (error) {
      setIsDeleting(false);
      setShowConfirmDelete(false);
      toast.error(`Delete Failed`);
      setIsDeleting(false);
    }
  };

  const handleDelete = () => {
    if (isEdit) {
      deleteFromDataBase();
    } else {
      if (!selected.length > 0) {
        dispatch(setKeywords([]));
        dispatch(setFormState({ component: "addKeywords", isComplete: false }));
      } else {
        const newData = keywords.filter(
          (item, index) => !selected.includes(item.key)
        );
        dispatch(setKeywords(newData));
      }
      setSelected([]);
    }
  };

  const deleteSingleKeyword = (key) => {
    if (isEdit) {
      setSelected([key]);
      setShowConfirmDelete(true);
    } else {
      const newData = keywords.filter((item) => item.key !== key);
      const newCurrenDataSource = currentDataSource.filter(
        (item) => item.key !== key
      );
      setCurrentDataSource(newCurrenDataSource);
      dispatch(setKeywords(newData));
      const newSelected = selected.filter((id) => id !== key);
      setSelected(newSelected);
    }
  };

  const handleSave = (row) => {
    const newData = [...keywords];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setKeywords(newData);
    setEditableRow("");
  };

  const columns = [
    {
      title: () => <div>Keyword</div>,
      dataIndex: "keywords",
      key: "keywords",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        handleSave,
      }) => (
        <>
          <Input
            ref={searchInputRef}
            placeholder={"Search"}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSelectedKeys(!!e.target.value ? [e.target.value] : []);
              confirm(false);
            }}
            onPressEnter={() => {
              confirm();
            }}
            css={styles.searchInput}
            suffix={<FeatherIcon icon={"search"} css={styles.searchIcon} />}
          />
        </>
      ),
      filterIcon: (filtered) => (
        <FeatherIcon
          icon="search"
          css={styles.searchIcon(filtered)}
          onClick={() => setIsSearchOpen(!isSearchOpen)}
        />
      ),
      onFilter: (value, record) =>
        record.keywords.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current?.select(), 100);
        }
      },
      render: (value, record) =>
        editableRow === record.key ? (
          <LabeledInput
            addStyles={{ width: "50%" }}
            value={value}
            onChange={(value) => {
              setEditValue(value);
            }}
            onPressEnter={() => {
              let newRecord = { ...record };
              newRecord.keywords = editValue;
              handleSave(newRecord);
            }}
          />
        ) : (
          <div css={styles.keyword}>{value}</div>
        ),
      width: "38%",
    },
    {
      title: "Search Volume",
      dataIndex: "searchvolume",
      key: "searchvolume",
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.searchvolume, b.searchvolume),
        multiple: 3,
      },
      width: "17%",
      render: (value, record) => <div css={styles.keyword}>{value}</div>,
    },
    {
      title: "Category",
      dataIndex: "keywordcategory",
      key: "keywordcategory",
      filterIcon: (filtered) => (
        <FeatherIcon
          icon={"filter"}
          css={styles.searchIcon(filtered)}
          fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
        />
      ),
      filters:
        uniqueCategories?.length > 0
          ? uniqueCategories?.map((keywordcategory) => ({
              text: keywordcategory,
              value: keywordcategory,
            }))
          : [],
      filterSearch: true,
      width: "13%",
      onFilter: (value, record) => record.keywordcategory === value,
      render: (value, record) => <div css={styles.keyword}>{value}</div>,
    },
    {
      title: "Type",
      dataIndex: "types",
      key: "types",
      filterIcon: (filtered) => (
        <FeatherIcon
          icon={"filter"}
          css={styles.searchIcon(filtered)}
          fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
        />
      ),
      filters:
        typeFilterOptions?.length > 0
          ? typeFilterOptions?.map((typeFilterOption) => ({
              text: typeFilterOption,
              value: typeFilterOption,
            }))
          : [],
      onFilter: (value, record) => record.types === value,
      width: "10%",
      render: (value, record) => <div css={styles.keyword}>{value}</div>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      filterIcon: (filtered) => (
        <FeatherIcon
          icon={"filter"}
          css={styles.searchIcon(filtered)}
          fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
        />
      ),
      filters:
        priorityFilterOptions?.length > 0
          ? priorityFilterOptions?.map((prioritFilterOptions) => ({
              text: prioritFilterOptions,
              value: prioritFilterOptions,
            }))
          : [],
      onFilter: (value, record) => record.priority === value,
      width: "10%",
      render: (value, record) => <div css={styles.keyword}>{value}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, record) => {
        return (
          <div css={styles.actionIconContainer}>
            {isEdit && (
              <FeatherIcon
                icon={"edit"}
                css={styles.actionIcon}
                onClick={() => {
                  dispatch(setIsEditingKeyword(true));
                  dispatch(setSingleKeywordData(record));
                  setActiveComponent("addSingleKeyword");
                }}
              />
            )}
            <FeatherIcon
              icon={"trash-2"}
              css={styles.actionIcon}
              onClick={() => {
                deleteSingleKeyword(record.key);
              }}
            />
          </div>
        );
      },
      width: "7%",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDeletButtonText(
        selectedRowKeys.length > 0 ? "Delete Selected" : "Delete All"
      );
      setSelected(selectedRowKeys);
      setSelectedKeyExportList(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const addKeywordOptions = {
    options: [
      {
        label: "Add Single Keyword",
        info: "This option will let you add a single unique keyword to the existing list",
        action: () => {
          setActiveComponent("addSingleKeyword");
        },
      },
      {
        label: "Upload Only New Keyword Set",
        info: "Keywords uploaded using this option will be updated to the existing list of keywords which are displayed on the screen.",
        action: () => {
          setReplace(false);
          handleImportClick();
        },
      },
      {
        label: "Upload Entire Keyword Set",
        info: "Keywords uploaded using this option replace the existing set with new upload list.",
        action: () => {
          setReplace(true);
          handleImportClick();
        },
      },
    ],
    active: [],
  };

  const formatExportData = (data) => {
    const formattedData = data.map((item) => {
      return {
        Keywords: item.keywords,
        "Search Volume": item.searchvolume,
        "Category Name": item.keywordcategory,
        Type: item.types,
        Priority: item.priority,
      };
    });
    return formattedData;
  };

  const getComponent = () => {
    if (activeComponent === "keywordsTable") {
      return (
        <>
          {showUploadFailedNotice && (
            <CustomNotice
              leftIcon={"alert-triangle"}
              title={"Upload Issue"}
              description={uploadError}
              onClose={() => setShowUploadFailedNotice(false)}
              theme={"danger"}
              addedStyle={{ marginBottom: "2rem" }}
            />
          )}
          <div css={styles.header}>
            <div>
              <RemainingCreditsCard
                keywordCount={
                  keywords && keywords.length > 0 ? keywords.length : 0
                }
                totalCredits={parseInt(credits.remaining)}
                isEdit={isEdit}
              />
            </div>
            <div css={styles.buttonGroup}>
              {/* <CustomButton
                text={selected.length > 0 ? "Delete Selected" : "Delete All"}
                leftIcon={"trash-2"}
                isDisable={!keywords.length > 0}
                use={selected.length > 0 ? "primary" : "secondary"}
                theme={"danger"}
                onPress={() => setShowConfirmDelete(true)}
                addStyles={
                  selected.length > 0 ? "" : styles.secondaryButtonDanger
                }
              /> */}
              {isEdit ? (
                <ButtonDropDown options={addKeywordOptions} />
              ) : (
                <CustomButton
                  text={"Import"}
                  leftIcon={"upload"}
                  isDisable={false}
                  use={"primary"}
                  onPress={handleImportClick}
                />
              )}
              <CustomButton
                text={"Export"}
                leftIcon={"download"}
                use={"primary"}
                isDisable={!keywords.length > 0}
                onPress={() => {
                  if (selectedKeyExportList.length > 0) {
                    const data = formatExportData(selectedKeyExportList);
                    exportToEXCEL(data, `Keywords ${getCurrentDate()}`);
                  } else {
                    if (currentDataSource.length > 0 && isFilterApplied) {
                      const data = formatExportData(currentDataSource);
                      exportToEXCEL(data, `Keywords ${getCurrentDate()}`);
                    } else {
                      const data = formatExportData(keywords);
                      exportToEXCEL(data, `Keywords ${getCurrentDate()}`);
                    }
                  }
                }}
              />
              <a
                target="_blank"
                href={template}
                download={"Keyword_Upload_Template.xlsx"}
              >
                <CustomButton
                  text={"Download Template"}
                  leftIcon={"download"}
                  isDisable={false}
                  use={"secondary"}
                  theme={"info"}
                  addStyles={styles.secondaryButton}
                />
              </a>
            </div>
          </div>
          <div>
            {keywords?.length > 0 && uniqueCategories?.length > 0 ? (
              !isLoading && (
                <Table
                  id="keywords-table"
                  className="table  table-hover mt-3 "
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  dataSource={keywords.length > 0 ? keywords : []}
                  columns={columns}
                  pagination={{
                    size: "small",
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} Keywords`,
                  }}
                  getPopupContainer={() =>
                    document.getElementById("keywords-table")
                  }
                  onChange={handleChange}
                />
              )
            ) : (
              <div css={styles.empty}>No Keywords Configured Yet</div>
            )}
            <div css={[styles.buttonContainer, styles.buttonGroup]}>
              <CustomButton
                text={"Back"}
                use={"secondary"}
                theme={"info"}
                onPress={() => dispatch(setActiveStep("campaignDetails"))}
                addStyles={styles.secondaryButton}
              />
              <CustomButton
                isDisable={!keywords.length > 0}
                text={"Next"}
                use={"primary"}
                theme={"info"}
                onPress={() => dispatch(setActiveStep("addCompetitors"))}
              />
            </div>
          </div>
        </>
      );
    } else if (activeComponent === "addSingleKeyword") {
      return (
        <AddSingleKeyword
          backToTable={() => setActiveComponent("keywordsTable")}
        />
      );
    }
  };

  return (
    <>
      <SpinContainer
        background={undefined}
        loading={isLoading}
        p="3px"
        size="xxl"
        theme="dark"
      >
        <div css={[styles.main, addStyles && addStyles]}>{getComponent()}</div>
      </SpinContainer>
      <input
        style={{ display: "none" }}
        type="file"
        id="fileinput"
        name="fileinput"
        multiple={false}
        accept={onlyXlsFile}
        ref={inputRef}
        onChange={() => {
          handleImport();
        }}
      />
      {showConfirmImport && (
        <ConfirmationModal
          icon={"info"}
          theme={"info"}
          title={"Confirm Import"}
          confirmButtonText={"Confirm"}
          cancelButtonText={"Cancel"}
          onConfirm={() => {
            setShowConfirmImport(false);
            inputRef.current.click();
          }}
          onCancel={() => setShowConfirmImport(false)}
        >
          <p css={styles.modalDescription}>
            This will replace already uploaded keywords from this campaign. Are
            you sure you want to re import these keywords.
          </p>
        </ConfirmationModal>
      )}
      {showConfirmDelete && (
        <ConfirmationModal
          icon={"trash-2"}
          theme={"danger"}
          title={"Confirm Delete"}
          confirmButtonText={"Confirm Delete"}
          cancelButtonText={"Cancel"}
          onConfirm={() => {
            handleDelete();
          }}
          onCancel={() => setShowConfirmDelete(false)}
          isConfirmLoading={isDeleting}
        >
          <p css={styles.modalDescription}>
            This will permanently delete{" "}
            <span css={styles.redBold}>
              {selected.length > 0 ? selected.length : keywords.length}
            </span>{" "}
            <span css={styles.bold}>/ {keywords.length}</span> Keywords from
            this campaign and would no longer be tracked. Are you sure you want
            to delete these keywords.
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default AddKeywords;
