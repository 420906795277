import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = (isActive, isCompare, isCustom) => {
  return css({
    borderTop: `1px solid ${Colors.neutral[50]}`,
    borderBottom: isActive
      ? `5px solid ${Colors.primary[500]}`
      : `1px solid ${Colors.neutral[50]}`,
    borderLeft: `1px solid ${
      isActive ? Colors.primary[500] : Colors.neutral[50]
    }`,
    borderRight: `1px solid ${
      isActive ? Colors.primary[500] : Colors.neutral[50]
    }`,
    padding: "0.75rem 1.5rem",
    flex: 1,
    display: "flex",
    justifyContent: isCustom ? "flex-start" : "space-between",
    cursor: "pointer",
    flexDirection: isCompare || isCustom ? "column" : "row",
    rowGap: isCompare ? "0.75rem" : 0,
  });
};

export const compareHeader = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export const iconContainer = (isActive, isPositive) => {
  return css({
    backgroundColor: !isActive
      ? Colors.neutral[50]
      : isPositive
      ? Colors.semantic[50]
      : Colors.error[50],
    padding: "0.44rem",
    borderRadius: "100%",
  });
};

export const icon = (isActive, isPositive) => {
  return css({
    height: "1.25rem",
    width: "1.25rem",
    color: !isActive
      ? Colors.neutral[600]
      : isPositive
      ? Colors.semantic[500]
      : Colors.error[800],
  });
};

export const left = css({
  display: "flex",
  flexDirection: "column",
  rowGap: "2.25rem",
});

export const title = (isActive) => {
  return css({
    ...textStyles.h6,
    color: isActive ? Colors.primary[500] : Colors.neutral[600],
    display: "flex",
    alignItems: "center",
  });
};

export const lightText = css({
  ...textStyles.b1,
  color: Colors.neutral[400],
});

export const darkText = css({
  ...textStyles.b1,
  color: Colors.neutral[600],
});

export const countContainer = css({
  ...textStyles.h4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

export const outOf = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const tooltipIcon = css({
  height: "1rem",
  width: "1rem",
  marginBottom: "0.2rem",
  marginLeft: "0.5rem",
  boxSizing: "border-box",
  color: Colors.neutral[400],
});

export const tooltipPopper = css({
  ...textStyles.b3,
  padding: "0.75rem",
  color: Colors.neutral[900],
});
export const tooltipPopperGraph = css({
  padding: "0.625rem 0.05rem",
  color: Colors.neutral[900],
  maxWidth: "31.875rem",
  maxHeight: "18.9375rem",
});

export const growthPercent = (isActive, isPositive, isZero) => {
  return css({
    ...textStyles.h5,
    color: !isActive
      ? Colors.neutral[600]
      : isZero
      ? Colors.secondary[500]
      : isPositive
      ? Colors.semantic[500]
      : Colors.error[800],
  });
};

export const infinityIcon = css({
  display: "block",
  width: "2rem",
  height: "auto",
});
export const infinityIconPercent = css({
  display: "block",
  width: "1.5rem",
  height: "auto",
});
export const countWrapper = css({
  display: "flex",
});

export const countContainerCompare = (showBorder) =>
  css({
    flex: 1,
    borderRight: showBorder ? `1px solid ${Colors.neutral[50]}` : "none",
    paddingLeft: showBorder ? 0 : "0.62rem",
  });

export const count = css({
  ...textStyles.b1,
  color: Colors.neutral[800],
});

export const outOfCompare = css({
  color: Colors.neutral[600],
});

export const tooltipCount = css({
  ...textStyles.s3,
  color: Colors.neutral[900],
});

export const tooltipPopperCount = css({
  ...textStyles.b3,
  color: Colors.neutral[900],
});

export const date = css({
  ...textStyles.b3,
  color: Colors.neutral[600],
});

export const customGrowth = (isPositive, isZero) => {
  return css({
    ...textStyles.b1,
    color: isZero
      ? Colors.secondary[500]
      : isPositive
      ? Colors.semantic[300]
      : Colors.error[800],
  });
};

export const chartIcon = (isPositive) => {
  return css({
    width: "21.212rem",
    height: "2.9527rem",
    transform: !isPositive ? "scaleY(-1)" : "",
    filter: !isPositive
      ? "invert(36%) sepia(92%) saturate(2119%) hue-rotate(331deg) brightness(100%) contrast(94%)"
      : "",
  });
};

export const chartWrapper = css({
  height: "6rem",
  width: "100%",
  marginTop: "-0.75rem",
  marginLeft: "-0.75rem",
});
