/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";
import * as styles from "./styles"
import { useState } from "react";


const TicketCard = ({ children, title, titleRight }) => {
    return (
        <div css={{ cursor: "default" }}>
            <div css={styles.titleStyles}>
                <div>
                    <div css={{ ...textStyles.s1 }}>{title}</div>
                    {/* <div css={{ ...textStyles.b3, color: Colors.neutral[500] }}>Subtitle</div> */}
                </div>
                {titleRight && <div>{titleRight}</div>}

            </div>
            {children?.length > 0 && !children?.every((child) => child == false) && < div css={styles.bodyStyles}>
                {children}
            </div>}
        </div >
    )
}

export default TicketCard