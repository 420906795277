import { Input, Table } from "antd";
import React, { useState, useEffect, useRef } from "react";
import APIManager from "../../services/APIManager";
import { exportInToCSV, exportToEXCEL } from "../../utility/Globals";
import { Sorter } from "../../common/Sorter";
import {
  GET_Server_URL,
  memberImport,
  getMemberByOrgId,
  memberIsActive,
  documentFileType,
} from "../../utility/Constant";
import { MemberStatusEmployee } from "../../utility/Globals";
import { mmDDyyyyFormateDate, findUnique } from "../../utility/Globals";
import { useNavigate } from "react-router-dom";

const Employees = () => {
  useEffect(() => {
    getMemberlist();
  }, []);

  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [teamlistEmp, setteamListEmp] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [edit, setedit] = useState(false);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          empcode: item.empcode,
          name: item.name,
          empdesignation: item.role?.name,
          department: item?.departments?.name,
          empjoinigdate: item.empjoinigdate,
          tmsupervisor: item.tmsupervisor,
          tmsrsupervisor: item.tmsrsupervisor,
          dob: item.dob,
          phone: item.phone,
          email: item.email,
          salary: item.salary,
          status: MemberStatusEmployee(item.status),
          active: item.accntactivation,
          lastwrkdate: item.lastwrkdate,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };
  function updateStatus(id, isActive) {
    APIManager.postAPI(memberIsActive + "?MemberId=" + id + "&isActive=" + isActive, {}, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          getMemberlist();
        }
      }
    );
  }

  function getMemberlist() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const exportList = [];
        const filterempdesig = [];
        const filterdpmt = [];
        const filtersupervisor = [];
        const filtersrsupervisor = [];
        response.data.map((item) => {
          let obj = {
            key: item.id,
            empcode: item.code,
            name1: item.name,
            name: item.name,
            empdesignation: item.role?.name,
            department: item.departments?.name,
            empjoinigdate: mmDDyyyyFormateDate(item.joinedOn),
            tmsupervisor: item.supervisor?.name,
            tmsrsupervisor: item.srSupervisor?.name,
            dob: mmDDyyyyFormateDate(item.dob),
            phone: item.phoneNumber,
            email: item.email,
            salary: item.salary,
            status: item.status,
            accntactivation: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.active}
                  onClick={() => {
                    updateStatus(item.id, !item.active);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            lastwrkdate:
              item.lastWorkingDate != null
                ? mmDDyyyyFormateDate(item.lastWorkingDate)
                : "",
          };
          if (item.role?.name == "Customer") {
            return false;
          } else {
            list.push(obj);
          }

          let exportObj = {
            empcode: item.code,
            name: item.name,
            empdesignation: item.role?.name,
            department: item.departments?.name,
            empjoinigdate: item.joinedOn,
            tmsupervisor: item.supervisor?.name,
            tmsrsupervisor: item.srSupervisor?.name,
            dob: item.dob,
            phone: item.phoneNumber,
            email: item.email,
            salary: item.salary,
            status: MemberStatusEmployee(item.status),
            active: item.active,
            lastwrkdate: item.lastWorkingDate,
          };
         
          let filterempdesigobj = {
            text: item.role?.name,
            value: item.role?.name,
          };

          if (filterempdesigobj?.value) filterempdesig.push(filterempdesigobj);
          let filterdpmtobj = {
            text: item.departments?.name,
            value: item.departments?.name,
          };
          if (filterdpmtobj?.value) filterdpmt.push(filterdpmtobj);
          let filtersupervisorobj = {
            text: item.supervisor?.name,
            value: item.supervisor?.name,
          };
          if (filtersupervisorobj?.value)
            filtersupervisor.push(filtersupervisorobj);
          let filtersrsupervisorobj = {
            text: item.srSupervisor?.name,
            value: item.srSupervisor?.name,
          };
          if (filtersrsupervisorobj?.value)
            filtersrsupervisor.push(filtersrsupervisorobj);
          exportList.push(exportObj);
        });
        setedit(false);
        setExportList(exportList);
        setteamList(list);
        setteamListEmp(list);
        const columns = [
          {
            title: "Code",
            dataIndex: "empcode",
            key: "empcode",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name, b.name),
              multiple: 3,
            },
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name1, b.name1),
              multiple: 3,
            },
          },
          {
            title: "Role",
            dataIndex: "empdesignation",
            key: "empdesignation",
            filters: findUnique(filterempdesig, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.empdesignation == value;
            },
          },
          {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: findUnique(filterdpmt, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.department == value;
            },
          },
          {
            title: "Joining Date",
            dataIndex: "empjoinigdate",
            key: "empjoinigdate",
          },
          {
            title: "Supervisor",
            dataIndex: "tmsupervisor",
            key: "tmsupervisor",
            filters: findUnique(filtersupervisor, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.tmsupervisor == value;
            },
          },
          {
            title: "Sr. Supervisor",
            dataIndex: "tmsrsupervisor",
            key: "tmsrsupervisor",
            filters: findUnique(filtersrsupervisor, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.tmsrsupervisor == value;
            },
          },
          {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
          },
          {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Email ID",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
          },
          {
            title: "Status",
            dataIndex: "lastwrkdate",
            key: "lastwrkdate",
            render(text, record) {
              if (record.lastwrkdate == "" || record.lastwrkdate == null) {
              } else {
                let today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();
                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;
                today = dd + "/" + mm + "/" + yyyy;
                var lwd = record.lastwrkdate;

                const convertToDate = (d) => {
                  const [day, month, year] = d.split("/");
                  return new Date(year, month - 1, day);
                };
                var dateStatus = convertToDate(today) > convertToDate(lwd);
              }

              return {
                children:
                  record.lastwrkdate == "" || record.lastwrkdate == null ? (
                    <button class="active"></button>
                  ) : dateStatus == false ? (
                    <></>
                  ) : (
                    <button class="deactivate"></button>
                  ),
              };
            },
          },
          {
            title: "Activate / Deactivate",
            dataIndex: "accntactivation",
            key: "accntactivation",
          },
          {
            title: "Last Working Date",
            dataIndex: "lastwrkdate",
            key: "lastwrkdate",
          },
        ];
        setteamcol(columns);
      }
    });
  }

  function filterDataFromList(textData) {
    setteamList(
      teamlistEmp.filter((person) =>
        person.name1.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
  }

  function uploadEmployeesFile() {
    var input = document.getElementById("employeesFile");
    empImport(input.files.item(0));
    resetFileInput();
  }

  function empImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      memberImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getMemberlist();
      }
    });
  }

  const onBtnClickempl = () => {
    inputFileRefkey.current.click();
  };
  const resetFileInput = () => {
    // reset input value
    inputFileRefkey.current.value = null;
  };
  const inputFileRefkey = useRef(null);

  const navigate = useNavigate();
  return (
    <>
      <div class="row">
        <div class="col-md-12 pad-lzero align-items-center p-0">
          <div className="col-md-12 d-flex mb-3 p-0 ">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                navigate(-1)
              }}
            ></i>
          </div>
        </div>
      </div>

      <div className="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Employees</h3>
        </div>
        <div className="portlet-body p-0 ">
          <div class="common-table px-3">
            <div className="row pt-3">
              <div className="col-sm-8"></div>
              <div className="col-md-4 add-new-btnw d-flex justify-content-end employee-search">
                <div class="search-box">
                  <div class="input-group" style={{ display: "block" }}>
                    <div class="input-group-icon input-group-lg widget15-compact">
                      <div class="input-group-prepend">
                        <i class="fa fa-search text-primary"></i>
                      </div>
                      <Input
                        allowClear
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        onChange={(nameSearch) => {
                          filterDataFromList(nameSearch.target.value);
                        }}
                        id="input-s"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-12 text-end mt-3 pe-0 employee-tab">
                    <div class="data-export mb-3">
                      <span class="export mb-2">
                        <a
                          class="btn btn-primary"
                          style={{ width: "auto" }}
                          target="_blank"
                          href={GET_Server_URL + "audit/Member.xlsx"}
                        >
                          Download Template
                        </a>
                        <button
                          class="btn btn-primary mb-0 mx-3 "
                          onClick={onBtnClickempl}
                        >
                          Import
                        </button>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="employeesFile"
                          name="employeesFile"
                          multiple={false}
                          accept={documentFileType}
                          ref={inputFileRefkey}
                          onChange={uploadEmployeesFile}
                        />
                      </span>
                      <span class="dropdown">
                        <button
                          class="btn btn-primary dropdown-toggle"
                          type="button"
                          onClick={() => {
                            exportToEXCEL(
                              selectedExportList?.length > 0
                                ? selectedExportList
                                : exportList,
                              "Employees"
                            );
                          }}
                        >
                          Export
                        </button>
                        {/* <div
                          class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            onClick={() => {
                              exportToEXCEL(
                                selectedExportList?.length > 0
                                  ? selectedExportList
                                  : exportList,
                                "Employees"
                              );
                            }}
                          >
                            <a class="dropdown-item">Excel</a>
                          </div>
                          <div
                            onClick={() => {
                              exportInToCSV(
                                selectedExportList?.length > 0
                                  ? selectedExportList
                                  : exportList,
                                "Employees"
                              );
                            }}
                          >
                            <a class="dropdown-item">CSV</a>
                          </div>
                        </div> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              className="table table-bordered table-hover employee-table"
              id="datatable-1"
              columns={teamcol}
              dataSource={teamlist}
              rowSelection={{ type: "checkbox", ...rowSelection }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Employees;
