import React, { useEffect } from "react";
import { useState } from "react";
import APIManager from "../services/APIManager";
import {
  createClient,
  documentFileType,
  getCountry,
  getIndustriesById,
  GET_Server_URL,
  updateClient,
} from "../utility/Constant";
import { ProgressBar, Step } from "react-step-progress-bar";
import { isValidFile } from "../utility/Globals";
import { useLocation } from "react-router-dom";

const steps = [
  {
    status: "Appointment Scheduled",
  },
  {
    status: "Qualified to Buy",
  },
  {
    status: "Presentation Scheduled",
  },
  {
    status: "Decision maker Bought-In",
  },
  {
    status: "contract Sent",
  },
  {
    status: "Closed Won",
  },
  {
    status: "Closed Lost",
  },
];

function EditClientDir({ update }) {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [clientid, setclientid] = useState(id);
  const [filelist, setfilelist] = useState([]);
  const [name, setName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [keyPOC, setKeyPOC] = useState("");
  const [countrySelected, setCountrySelected] = useState("India");
  const [statusySelected, setStatusySelected] = useState("0");
  const [countryList, setCountryList] = useState([]);
  const [save, setsave] = useState(false);
  const [statueList, setStatueList] = useState([
    { name: "Active", id: "0" },
    { name: "Lead", id: "1" },
    { name: "Prospect", id: "2" },
    { name: "Compeleted", id: "3" },
    { name: "Appointment Scheduled", id: "4" },
    { name: "Qualified to Buy", id: "5" },
    { name: "Presentation Scheduled", id: "6" },
    { name: "Decision maker Bought-In", id: "7" },
    { name: "Contract Sent", id: "8" },
    { name: "Closed Won", id: "9" },
    { name: "Closed Lost", id: "10" },
  ]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [documentURL, setDocumentURL] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [errors, setErrors] = useState("");
  const [isAccActive, setIsActiveAcc] = useState(false);
  const [industryData, setIndustryData] = useState([]);
  const [industrySelected, setIndustrySelected] = useState("")
  const location = useLocation();

  useEffect(() => {
    getIndustryListFun();
    if (location.state.isEdit) {
      setName(location.state.item.name);
      setIndustrySelected(location.state.item.industriesId);
      setKeyPOC(location.state.item.keyPOC);
      setIsActiveAcc(location.state.item.active);
      setStatusySelected(location.state.item.status);
      setleadClientStatusselected(location.state.item.status);
      setCountrySelected(location.state.item.country);
      setState(location.state.item.state);
      setCity(location.state.item.city);
      setPhoneNumber(location.state.item.phoneNumber);
      setEmail(location.state.item.email);
      if (location.state.item?.document) {
        setDocumentURL(location.state.item?.document.fileURL);
      }
      setOrganizationId(location.state.item.organizationId);
      setclientid(location.state.item.id);
    } else {
    }
    getCountryList();
    setStatueList([
      { name: "Active", id: "0" },
      { name: "Lead", id: "1" },
      { name: "Prospect", id: "2" },
      { name: "Compeleted", id: "3" },
      { name: "Appointment Scheduled", id: "4" },
      { name: "Qualified to Buy", id: "5" },
      { name: "Presentation Scheduled", id: "6" },
      { name: "Decision maker Bought-In", id: "7" },
      { name: "Contract Sent", id: "8" },
      { name: "Closed Won", id: "9" },
      { name: "Closed Lost", id: "10" },
    ])
  }, []);

  function getCountryList() {
    APIManager.getAPI(getCountry).then((response) => {
      if (response && response.status === 200) {
        setCountryList(response.data.data);
      }
    });
  }

  function setcountry(e) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  function changeStatus(e) {
    setStatusySelected(e.target.value);
    setleadClientStatusselected(e.target.value);
    onChangeStatus(e);
  }

  function updateList(input) {
    let output = document.getElementById("fileList");
    let data = [];
    let children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      setDocumentFile(input.files.item(i));
    }
    setfilelist(data);
    output.innerHTML = "<ul>" + children + "</ul>";
  }

  function createClientData() {
    let organizationId = localStorage.getItem("organizationId");
    var bodyFormData = new FormData();
    bodyFormData.append("Name", name.trim());
    bodyFormData.append("KeyPOC", keyPOC.trim());
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("Country", countrySelected);
    bodyFormData.append("State", state.trim());
    bodyFormData.append("City", city.trim());
    bodyFormData.append("PhoneNumber", phoneNumber);
    bodyFormData.append("Active", isAccActive);
    bodyFormData.append("Email", email);
    bodyFormData.append("CreatedBy", localStorage.getItem("userId"));
    bodyFormData.append("OrganizationId", organizationId);
    bodyFormData.append("IndustriesId", industrySelected);
    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", documentFile);
    }
    APIManager.postAPI(createClient, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status === 200) {
          clearForm();
        }
      }
    );
  }

  function formValidation() {
    let error = { ...errors };
    name.trim() === ""
      ? (error["name"] = "Client name is required!")
      : delete error["name"];

    if (name?.length) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9_&@?()\\\-"/,._:'\s]*$/
      );

      if (pattern?.test(name) === false) {
        error["name"] = "Please enter valid Client name!";
      }
    }

    keyPOC.trim() === ""
      ? (error["keyPOC"] = "Key point of contact is required!")
      : delete error["keyPOC"];

    email.trim() === ""
      ? (error["email"] = "Email is required!")
      : delete error["email"];

    if (email?.length) {
      var pattern1 = new RegExp(
        /^\w+([-+.']?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      );

      if (pattern1?.test(email) === false) {
        error["email"] = "Please enter valid Email!";
      }
    }
    phoneNumber.trim() === ""
      ? (error["phoneNumber"] = "Phone number is required!")
      : delete error["phoneNumber"];

    if (phoneNumber?.length) {
      var pattern2 = new RegExp(/^[0-9\b]+$/);

      if (!pattern2.test(phoneNumber)) {
        error["phoneNumber"] = "Please enter only number";
      } else if (phoneNumber.length >= 15) {
        error["phoneNumber"] =
          "Phone number must not exceed more than 15 letters.";
      } else if (phoneNumber.length < 10) {
        error["phoneNumber"] = "Phone number must not  less than 10 letters.";
      } else {
        delete error["phoneNumber"];
      }
    }
    countrySelected.trim() === ""
      ? (error["countrySelected"] = "Please select country!")
      : delete error["countrySelected"];

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setName("");
    setKeyPOC("");
    setState("");
    setCity("");
    setPhoneNumber("");
    setEmail("");
    setfilelist("");
    setDocumentFile("");
    setIsActiveAcc(false);
    update("clients");
  }

  function updateClientData() {
    var bodyFormData = new FormData();
    bodyFormData.append("Id", clientid?.trim());
    bodyFormData.append("Name", name?.trim());
    bodyFormData.append("OrganizationId", organizationId);
    bodyFormData.append("KeyPOC", keyPOC?.trim());
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("Active", isAccActive);
    bodyFormData.append("Country", countrySelected);
    bodyFormData.append("State", state);
    bodyFormData.append("City", city?.trim());
    bodyFormData.append("PhoneNumber", phoneNumber);
    bodyFormData.append("Email", email);
    bodyFormData.append("CreatedBy", localStorage.getItem("userId"));
    bodyFormData.append("IndustriesId", industrySelected)
    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", documentFile);
    }
    APIManager.putAPI(updateClient, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status === 200) {
          clearForm();
        }
      }
    );
  }

  const [leadClientStatusselected, setleadClientStatusselected] = useState("0");
  function onChangeStatus(e) {
    setleadClientStatusselected(e.target.value);
  }

  function getIndustryListFun() {
    let list = [];
    APIManager.getAPI(
      getIndustriesById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response.status === 200) {
        response.data.forEach((item) => {
          let object = {
            key: item.id,
            value: item.id,
            name: item.industriesName,
          }
          list.push(object);
        });
        setIndustryData(list);
        if (!location.state.isEdit) {
          setIndustrySelected(list[0].value)
        }
      }
    });
  }
  return (
    <>
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => {
            update("clients");
          }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} -{" "}
            {location.state.isEdit ? "Edit Client" : "Create Client"}
          </h3>
        </div>
        <div className="portlet-body">
          <div class="common-wrapper">
            {statusySelected > 3 ? (
              <div
                className="progress-bar-lead-add mt-2"
                style={{
                  marginTop: 25,
                  marginRight: 50,
                  marginBottom: 50,
                  marginLeft: 50,
                }}
              >
                <ProgressBar
                  percent={(100 / 6) * (leadClientStatusselected - 4)}
                  filledBackground="linear-gradient(to right, #41ad49, #41ad49)"
                >
                  {steps.map((step, index, arr) => {
                    return (
                      <Step
                        transition="scale"
                        children={({ accomplished }) => (
                          <div
                            style={{
                              borderRadius: "50%",
                              width: 20,
                              height: 20,
                              color: "black",
                              backgroundColor: accomplished ? "green" : "gray",
                            }}
                          >
                            <br />
                            <br />
                            <div className="step-status">{step.status}</div>
                          </div>
                        )}
                      />
                    );
                  })}
                </ProgressBar>
              </div>
            ) : (
              <></>
            )}
            <div class="common-wrapper client-form">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user w-100">
                    <div class="col-md-12">
                      <label className="form-label">Client{save}</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        className=""
                        placeholder="Enter Client"
                        required
                        value={name}
                        onChange={(text) => {
                          setName(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                    {save && errors?.name && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.name}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-3">
                      <label className="form-label">Industry</label>
                      <label class="field-required"> * </label>
                      <select className="form-select"
                        value={industrySelected}
                        onChange={(e) => {
                          setIndustrySelected(e.target.value);
                        }}>
                        {industryData.map((item) => {
                          return <option value={item.value}>{item.name}</option>;
                        })}
                      </select>

                    </div>

                    <div class="col-md-12 mt-3">
                      <label className="form-label">Key Point of Contact</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        placeholder="Enter Key Point of Contact "
                        required
                        value={keyPOC}
                        onChange={(text) => {
                          setKeyPOC(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                    {save && errors?.keyPOC && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.keyPOC}</span>
                        </div>
                      </>
                    )}
                    <div class="col-md-12 mt-3 dropdown">
                      <label className="form-label">Status</label>
                      <select
                        className="form-select "
                        value={leadClientStatusselected}
                        onChange={changeStatus}
                      >
                        {statueList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3">
                      <label className="form-label">Country{save}</label>
                      <label class="field-required"> * </label>
                      <select
                        className="form-select "
                        required
                        value={countrySelected}
                        onChange={setcountry}
                      >
                        {countryList.map((item) => {
                          return <option value={item.name}>{item.name}</option>;
                        })}
                      </select>
                    </div>

                    {save && errors?.countrySelected && (
                      <>
                        <div className="field-required ms-3 mt-3">
                          <span> {errors?.countrySelected}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-3">
                      <label className="form-label">State</label>
                      <input
                        type="text"
                        value={state}
                        placeholder="Enter State"
                        onChange={(text) => {
                          setState(text.target.value);
                        }}
                      />
                    </div>

                    <div class="col-md-12 mt-3">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        value={city}
                        placeholder="Enter City"
                        onChange={(text) => {
                          setCity(text.target.value);
                        }}
                      />
                    </div>

                    <div class="col-md-12 mt-3">
                      <label className="form-label">Email</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        value={email}
                        required
                        placeholder="Enter Email"
                        onChange={(text) => {
                          setEmail(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                    {save && errors?.email && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.email}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-3">
                      <label className="form-label">Phone Number</label>
                      <label class="field-required"> * </label>
                      <br />
                      <div style={{ display: "flex" }}>
                        <input
                          type="text"
                          value={phoneNumber}
                          required
                          placeholder="Enter Phone Number"
                          onChange={(text) => {
                            setPhoneNumber(text.target.value);
                            formValidation();
                          }}
                        />
                      </div>
                    </div>
                    {save && errors?.phoneNumber && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.phoneNumber}</span>
                        </div>
                      </>
                    )}
                    <div className="col-md-12 mt-3">
                      <label className="form-label">Account Activation</label>
                      <br />
                      <label class="switch p-0">
                        <input
                          type="checkbox"
                          checked={isAccActive}
                          onClick={(e) => {
                            setIsActiveAcc(e.target?.checked);
                          }}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-md-12">
                      <label className="form-label">Document Upload</label>
                      <br />
                      <input
                        className="client-choose-file p-3"
                        type="file"
                        id="myfile"
                        name="myfile"
                        multiple={false}
                        accept={documentFileType}
                        onChange={() => {
                          let input = document.getElementById("myfile");
                          const fileExtension = input.files[0].name.split(".").at(-1);
                          let isvalid = isValidFile(fileExtension)
                          if (isvalid === true) {
                            updateList(input)
                          }
                          else {
                            document.getElementById("myfile").value = "";
                          }
                        }}
                      />
                      {documentURL ? (
                        <a
                          target={"_blank"}
                          href={GET_Server_URL + documentURL}
                          rel="noreferrer"
                        >
                          {documentURL}
                        </a>
                      ) : null}

                      <label>
                        {filelist.length > 0 ? `${filelist.length} files` : ""}
                      </label>
                    </div>
                    <div id="fileList">
                      <ul>
                        {filelist &&
                          filelist.map((i, index) => {
                            return <li id={i}>{i}</li>;
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div className="col-md-12 ps-0">
            <button
              type="submit"
              class="btn btn-primary me-3"
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  location.state.isEdit
                    ? updateClientData()
                    : createClientData();
                }
              }}
            >
              {location.state.isEdit ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                clearForm();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditClientDir;
