import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import "antd/dist/antd.css";
import { Table } from "antd";
import Chart from "react-google-charts";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import APIManager from "../services/APIManager";
import {
  getuserassignment,
  getMemberIdByPieChartData,
} from "../utility/Constant";
import {
  calanderDate,
  mmDDyyyyFormateDate,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import { useLocation } from "react-router-dom";

const pieOptions = {
  title: "",
  slices: [
    {
      color: "#2BB673",
    },
    {
      color: "#d91e48",
    },
    {
      color: "#007fad",
    },
    {
      color: "#e9a227",
    },
  ],
  legend: {
    position: "none",
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 20,
    top: 100,
  },
};

function EditEmployeeDir({ update }) {
  const ref = useRef();
  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(0, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(0, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  const location = useLocation();
  const [piedata, setpiedata] = useState([]);
  const [ProjList, setProjList] = useState([]);
  const [projcol, setprojcol] = useState([]);

  function projectstatus(project) {
    let status = "";
    switch (project) {
      case "0":
        status = "Inactive";
        break;
      case "1":
        status = "Active";
        break;
      default:
        break;
    }
    return status;
  }

  useEffect(() => {
    const a = [
      ["Task", "Hours per Day"],
      ["Utilised", 0],
      ["Not utilised", 10],
    ];
    setpiedata(a);
    const columns = [
      {
        title: "Project Status",
        dataIndex: "projectstatus",
        key: "projectstatus",
        filterSearch: true,
        onFilter: (value, record) => record.projectstatus.indexOf(value) === 0,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.projectstatus, b.projectstatus),
          multiple: 3,
        },
      },
      {
        title: "Client name",
        dataIndex: "clientname",
        key: "clientname",
        filterSearch: true,
        onFilter: (value, record) => record.clientname.indexOf(value) === 0,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.clientname, b.clientname),
          multiple: 3,
        },
      },
      {
        title: "Billing Start Date",
        dataIndex: "billingstartdate",
        key: "billingstartdate",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.billingstartdate, b.billingstartdate),
          multiple: 3,
        },
      },
      {
        title: "Billing End Date",
        dataIndex: "billingenddate",
        key: "billingenddate",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.billingenddate, b.billingenddate),
          multiple: 3,
        },
      },
      {
        title: "% Allocation",
        dataIndex: "allocation",
        key: "allocation",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.allocation, b.allocation),
          multiple: 3,
        },
      },
      {
        title: "Billing Period",
        dataIndex: "billingperiod",
        key: "billingperiod",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.billingperiod, b.billingperiod),
          multiple: 3,
        },
      },
      {
        title: "Project POC",
        dataIndex: "projectpoc",
        key: "projectpoc",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.projectpoc, b.projectpoc),
          multiple: 3,
        },
      },
    ];
    setprojcol(columns);
    getList();
    getAssignPieChartData();
    setHeadValues([
      new DateObject().subtract(6, "days"),
      new DateObject().add(0, "days"),
    ])
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.forEach((item, index) => {
        let obj = {
          projectstatus: item.projectstatus,
          clientname: item.clientname,
          billingstartdate: item.billingstartdate,
          billingenddate: item.billingenddate,
          allocation: item.allocation,
          billingperiod: item.billingperiod,
          projectpoc: item.projectpoc,
        };
        list.push(obj);
      });
    },
  };

  function getList() {
    APIManager.postAPI(
      getuserassignment,
      {
        UserId: location.state.userId,
        BillingStartDate: mmDDyyyyFormateDateSet(head1),
        BillingEndDate: mmDDyyyyFormateDateSet(head2),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        const exportList = [];
        let i = 0;
        response.data.forEach((item) => {
          list.push({
            key: (i += 1),
            projectstatus: projectstatus(item.projectStatus),
            clientname: item.clientName,
            billingstartdate: mmDDyyyyFormateDate(item.billingStartDate),
            billingenddate: mmDDyyyyFormateDate(item.billingEndDate),
            allocation: item.allocation + "%",
            billingperiod: "Monthly",
            projectpoc: item.poc,
          });
          exportList.push({
            projectstatus: projectstatus(item.projectStatus),
            clientname: item.clientName,
            billingstartdate: item.billingStartDate,
            billingenddate: item.billingEndDate,
            allocation: item.allocation,
            billingperiod: "Monthly",
            projectpoc: item.poc,
          });
        });
        setProjList(list);
      }
    });
  }

  function getAssignPieChartData() {
    APIManager.getAPI(getMemberIdByPieChartData + location.state.userId).then(
      (response) => {
        if (response && response.status === 200) {
          let totalSum = 0;
          let notUtilisedData = 0;
          let a = [];
          response.data.forEach((i) => {
            let allocSum = i.allocationSum === null ? 0 : i.allocationSum;
            if (allocSum !== 0 || i.counts !== 0) {
              let utilisedData = allocSum / i.counts;
              totalSum = totalSum + utilisedData;
              notUtilisedData = 100 - totalSum;
            } else {
              a = [
                ["Task", "Hours per Day"],
                ["Utilised", 0],
                ["Not utilised", 10],
              ];
            }
          });
          if (totalSum > 0) {
            a = [
              ["Task", "Hours per Day"],
              ["Utilised", totalSum],
              ["Not utilised", notUtilisedData],
            ];
          }
          setpiedata(a);
        }
      }
    );
  }

  return (
    <>
      <div className="portlet slideInUp  setting-top">
        <div className="portlet-header portlet-header-bordered">
          <svg onClick={() => {
            update("user");
          }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} - Project Details And
            Utilization
          </h3>
        </div>
        <div className="portlet-body">
          <div class="row mt-3">
            <div class="common-filter">
              <div ref={ref} class="calendar-main">
                <div className="add-new-btnw">
                  <button
                    className="btn btn-primary"
                    style={{ width: "250px" }}
                    onClick={() => setOpen(!open)}
                  >
                    {calanderDate(head1, head2)}
                  </button>
                </div>
                {open && (
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card  mt-2">
                      <div className="row">
                        <div className="col-lg-8 calendar-col">
                          <Calendar
                            className="custom-calendar"
                            value={values}
                            onChange={(e) => {
                              setValues(e);
                              setHead1(e[0].format());
                              setHead2(e[1]?.format());
                            }}
                            range
                            numberOfMonths={1}
                            showOtherDays
                          />
                        </div>
                        <div className="col-lg-4 mt-3 mt-lg-0 text-center">
                          <div className="row mt-3">
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setheadvalues();
                                  setOpen(!open);
                                  getList();
                                }}
                                className="btn btn-primary"
                              >
                                Apply
                              </button>
                            </div>
                            <div className="col-6"></div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="common-table mt-3">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                <div className="portlet">
                  <div className="portlet-header portlet-header-bordered">
                    <h3 className="portlet-title">PieChart</h3>
                  </div>
                  <div className="portlet-body">
                    <div id="piechart">
                      <Chart
                        chartType="PieChart"
                        data={piedata}
                        options={pieOptions}
                        graph_id="PieChart"
                        width={"100%"}
                        height={"400px"}
                        legend_toggle
                      />
                      <div>
                        <div class="rect green"></div>
                        <div class="pie-content">Utilised</div>
                      </div>
                      <div>
                        <div class="rect red"></div>
                        <div class="pie-content">Not Utilised</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-sm-12">
                <div className="portlet">
                  <div className="portlet-header portlet-header-bordered">
                    <h3 className="portlet-title">Edit Employee</h3>
                  </div>
                  <div className="portlet-body">
                    <div className="col-md-12 text-right">
                      <span class="export">
                        <button
                          class="btn btn-primary dropdown-toggle"
                          type="button"                        
                        >
                          Export
                        </button>                       
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <Table
                      className="table table-bordered table-hover"
                      id="sample"
                      columns={projcol}
                      dataSource={ProjList}
                      rowSelection={{ type: "checkbox", ...rowSelection }}
                      pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEmployeeDir;
