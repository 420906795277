/** @jsxImportSource @emotion/react */
import React from "react";
import * as styles from "./styles";
import StepperIndicator from "../../shared/stepperIndicator";
import { useState } from "react";
import CampaignDetails from "../campaignDetails";
import AddKeywords from "../addKeywords";
import AddCompetitors from "../addCompetitors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "./campaignDetailSlice";
import { useRef } from "react";
import { CreateEditCampaignScreens } from "../../../utility/Constant";

const CreateEditCampaign = ({ addStyles, isEdit, gotoCampaigns }) => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.campaignDetails.stepperData);
  const campaign = useSelector((state) => state.campaignDetails.details);
  const [campaignNameForUpdate,setCampaignNameForUpdate] = useState("")

  const activeComponent = useSelector(
    (state) => state.campaignDetails.activeStep
  );

  const ref = useRef();

  useEffect(() => {
    setCampaignNameForUpdate(campaign.name)
    if (ref.current) {
      ref.current.scrollTo({ top: 0, left: 0 });
    }
  }, [activeComponent]);

  useEffect(() => {
    dispatch(setActiveStep(CreateEditCampaignScreens.DETAILS));
  }, []);

  const getComponent = () => {
    if (activeComponent === CreateEditCampaignScreens.DETAILS) {
      return <CampaignDetails isEdit={isEdit} />;
    } else if (activeComponent === CreateEditCampaignScreens  .KEYWORDS) {
      return <AddKeywords isEdit={isEdit} />;
    } else if (activeComponent === CreateEditCampaignScreens.COPETITORS) {
      return <AddCompetitors isEdit={isEdit} gotoCampaigns={gotoCampaigns} />;
    }
  };

  return (
    <div css={[styles.main, addStyles && addStyles]} >
      <div css={styles.stepperContainer}>
        <StepperIndicator data={steps.data} activeStep={steps.active} />
      </div>
      <div css={styles.formContainer} ref={ref}>{getComponent()}</div>
    </div>
  );
};

export default CreateEditCampaign;
