import { Input, Table, Drawer } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import APIManager from "../../services/APIManager";
import {
  creatDepartment,
  deleteDepartment,
  getDepartmentById,
  updateDepartment,
  deleteRoleId,
} from "../../utility/Constant";
import toast from "react-hot-toast";
import { getCheckPermission } from "../../utility/Globals";

const Department = ({ update }) => {
  const [userdepartment, setuserdepartment] = useState([]);
  const [departmentlist, setDepartmentlist] = useState([]);
  const [isModelDismiss, setisModelDismiss] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState();
  const [isEdit1, setIsEdit1] = useState(false);
  const [isEditIndex1, setIsEditIndex1] = useState();
  const [departmentlistMain, setDepartmentlistMain] = useState([]);
  const [textRole, setTextRole] = useState("");
  const [textRoleLevel, setTextRoleLevel] = useState(1);
  const [textRoleAdd, setTextRoleAdd] = useState("");
  const [textRoleLevelAdd, setTextRoleLevelAdd] = useState(1);

  const notifyAdd = () => toast.success("Successfully Add!");
  const accordion = useRef(null);
  let modelDismissAdd = false;
  let modelDismissEdit = false;
  const userdepartmentcol = [
    {
      title: "Department & Designations",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const [departmentNameEdit, setDepartmentNameEdit] = useState("");
  const [departmentNameAdd, setDepartmentNameAdd] = useState("");
  const [designationAdd, setdesignationAdd] = useState("");
  const [roleAdd, setroleAdd] = useState(1);
  const [desigationMsg, setDesigationMsg] = useState("");
  const [deptMsg, setDeptMsg] = useState("");
  const [deptMsgEdit, setDeptMsgEdit] = useState("");
  const [designationEdit, setdesignationEdit] = useState("");
  const [roleEdit, setroleEdit] = useState(1);
  const [desigationMsgEdit, setDesigationMsgEdit] = useState("");
  const [roleMsgEdit, setroleMsgEdit] = useState("");
  const [departmentId, setdepartmentId] = useState("");
  const [addDept, setAddDept] = useState(false);
  const [size, setSize] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [departmentList1, setDepartmentList1] = useState([]);
  const [departmentList1Edit, setDepartmentList1Edit] = useState([]);
  const [listLength, setListLength] = useState(true);
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);

  useEffect(() => {
    getDepartmentData();
    setsave(false)
  }, []);

  const showLargeDrawer = () => {
    setSize("large");
    setAddOpen(true);
  };
  const onAddClose = () => {
    setAddOpen(false);
  };

  const showLargeDrawerEdit = () => {
    setSize("large");
    setEditOpen(true);
  };
  const onEditClose = () => {
    setEditOpen(false);
  };

  function submit(item) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteDepartmentItem(item.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function getDepartmentData() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        let count = 1;
        let exportList = [];
        setDepartmentlistMain(response.data);
        response.data.forEach((item, index) => {
          exportList.push({
            key: item.id,
            role: item.name,
          });

          let obj = {
            key: count,
            id: count,
            role: (
              <>
                <div
                  ref={accordion}
                  class={
                    item.roles?.length === 0
                      ? "accordion-item d-flex justify-content-between collapsed test-class"
                      : "accordion-item d-flex justify-content-between collapsed"
                  }
                  data-toggle="collapse"
                  data-target={"#accordion1-collapse-" + index}
                  style={{ border: "none" }}
                >
                  <div>{item.name}</div>
                  <div>
                    {item.roles.length !== 0 ? (
                      <button class="acco-btn">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button class="acco-btn" style={{ visibility: "hidden" }}>
                        <i class="fa fa-angle-up"></i>
                      </button>
                    )}
                  </div>
                </div>
                {item.roles.map((i) => {
                  return (
                    <div id={"accordion1-collapse-" + index} class="collapse">
                      <ul class="audit-titles">
                        <li className="d-flex justify-content-between">
                          <div>
                            {i.level ?? "-"}
                          </div>

                          <div>
                            {i.name}
                          </div>

                          <div>
                            {getCheckPermission("delete", "organization_setup") ?

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2 trash-icon text-right"
                                onClick={() => {
                                  deleteRoleByIdWithConfirmation(i.id);
                                }}
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                              : <></>}</div>

                        </li>
                      </ul>
                    </div>
                  );
                })}
                <div id={"accordion1-collapse-" + index} class="collapse">
                  {item.roles.length !== 0 ? (
                    <ul class="audit-titles">
                      <li className="d-flex justify-content-between">
                        {getCheckPermission("edit", "organization_setup") ?
                          <i
                            style={{ color: "#2196f3", fontStyle: "normal" }}
                            type="button"
                            onClick={() => {
                              showLargeDrawerEdit()
                              modelDismissEdit = true;
                              setDesigationMsgEdit("");
                              setroleMsgEdit("");
                              setdesignationEdit("");
                              setroleEdit(1)
                              setdepartmentId(item.id);
                              setDepartmentNameEdit(item.name);
                              let deptRole = [];
                              let role = [];
                              let obj;
                              setDepartmentNameEdit(item.name);
                              item.roles.forEach((i) => {
                                role.push(i.name);
                                obj = {
                                  department: item.name,
                                  designation: i.name,
                                  departmentId: item.id,
                                  roleId: i.id,
                                  roleLevel: i.level
                                };
                                deptRole.push(obj);
                              });
                              if (deptRole.length > 0) {
                                setListLength(true);
                              } else {
                                setListLength(false);
                              }
                              setDepartmentList1Edit(deptRole);
                            }}
                          >
                            Add more
                          </i>
                          : <></>}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ),
            action: (
              <div className="d-flex text-center ">
                <div
                  class=" edit-department-btn mx-3 my-3"
                >
                  {getCheckPermission("edit", "organization_setup") ?
                    <svg
                      type="button"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon ms-3"
                      onClick={() => {
                        modelDismissEdit = true;
                        showLargeDrawerEdit()
                        setDesigationMsgEdit("");
                        setroleMsgEdit("");
                        setdesignationEdit("");
                        setroleEdit(1);
                        setdepartmentId(item.id);
                        setDepartmentNameEdit(item.name);
                        var e = document.getElementById("input-s");
                        e.scrollIntoView({
                          block: "end",
                          behavior: "smooth",
                          inline: "center",
                        });
                        let deptRole = [];
                        let role = [];
                        let obj;
                        item.roles.forEach((i) => {
                          role.push(i.name);
                          obj = {
                            department: item.name,
                            designation: i.name,
                            departmentId: item.id,
                            level: i.level,
                            roleId: i.id,
                          };
                          deptRole.push(obj);
                        });

                        if (deptRole.length > 0) {
                          setListLength(true);
                        } else {
                          setListLength(false);
                        }
                        setDepartmentList1Edit(deptRole);
                        setIsEdit1(false);
                        setIsEdit(false);
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    : <></>}
                  {getCheckPermission("delete", "organization_setup") ?

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon ms-2 "
                      onClick={() => {
                        submit(item);
                        deleteDepartmentItem(item.id);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                    : <></>}
                </div>
              </div>
            ),
          };
          list.push(obj);
          count++;
        });
        setuserdepartment(list);
        setDepartmentlist(list);
      }
    });
  }

  function clearData() {
    setDepartmentNameEdit("");
    setdepartmentId("");
    getDepartmentData();
  }


  function addDepartment() {
    let roleList = [];
    let isValid = true;
    var pattern = new RegExp(/^[^-0-9]+$/);  
    departmentList1.map((item) =>
      roleList.push({
        Name: item.designation,
        level: item.level
      })
    );
    if (departmentNameAdd === "") {
      setDeptMsg("Please enter department!");
      modelDismissAdd = false;
    } 
    else if(!pattern.test(departmentNameAdd)){
      setDeptMsg("Please enter valid department!");
      modelDismissAdd = false;
    }
    else {
      isValid = departmentlistMain.some(
        (item) => item.name === departmentNameAdd
      ); // true
      if (!isValid) {
        setDeptMsg("");
        modelDismissAdd = true;
        APIManager.postAPI(
          creatDepartment,
          {
            Name: departmentNameAdd.trim(),
            organizationId: localStorage.getItem("organizationId"),
            roles: roleList,
          },
          null,
          true
        ).then((response) => {
          if (response && response.status === 200) {
            onAddClose()
            getDepartmentData();
            clearData();
            notifyAdd();
            setisModelDismiss(true);
            setDepartmentNameAdd("");
            setroleAdd(1);
            setDepartmentList1([]);
          }
        });
      } else {
        setDeptMsg("This department already exists!");
        modelDismissAdd = false;
      }
    }
  }

  function updateDepartmentItem() {
    let roleList = [];
    let deptId;
    departmentList1Edit.forEach((item) => {
      if (item.departmentId !== undefined) {
        deptId = item.departmentId;
      }
    });
    departmentList1Edit.forEach((item) =>
      roleList.push({
        Name: item.designation,
        level: item.level,
        id: item.roleId
      })
    );
    if (departmentNameEdit.trim() === "") {
      setDeptMsgEdit("Please enter department!");
    } else {
      setDeptMsgEdit("");
      APIManager.putAPI(
        updateDepartment,
        {
          Id: deptId,
          Name: departmentNameEdit.trim(),
          organizationId: localStorage.getItem("organizationId"),
          Roles: roleList,
        },
        null,
        true
      ).then((response) => {
        if (response && response.status === 200) {
          onEditClose()
          setuserdepartment([]);
          setTimeout(() => {
            getDepartmentData();
          }, 500);
          getDepartmentData();
        }
      });
    }
  }

  async function deleteDepartmentItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteDepartment + id);
      if (response === 200) {
        setDepartmentNameEdit("");
        getDepartmentData();
      }
    } catch (e) { }
  }

  async function deleteRoleById(id) {
    try {
      const response = await APIManager.deteletItem(deleteRoleId + id);
      if (response && response.status === 200) {
        getDepartmentData();
      }
    } catch (e) { }
  }

  async function deleteRoleByIdWithConfirmation(id) {
    try {
      const response = await APIManager.deleteAPI(deleteRoleId + id);
      if (response === 200) {
        getDepartmentData();
      }
    } catch (e) { }
  }

  function removeContent(i) {
    let list = [...departmentList1];
    list.splice(i, 1);
    setDepartmentList1(list);
  }

  function removeContent1(i) {
    let list = [...departmentList1Edit];
    list.splice(i, 1);
    setDepartmentList1Edit(list);
  }

  return (
    <>
      <div className="portlet p-0 roles-setting-top role_main">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("organizationName")} - Roles
          </h3>
        </div>
        <div className="portlet-body">
          <div className="col-md-12 department-tab">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex justify-content-start align-items-center">
                <div class="input-group-icon input-group-lg widget15-compact">
                  <div class="input-group-prepend">
                    <i class="fa fa-search text-primary"></i>
                  </div>
                  <div className="search-box">
                    <span>
                      <Input
                        allowClear
                        style={{ height: 36 + "px" }}
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        onChange={(nameSearch) => {
                          setuserdepartment(
                            departmentlist.filter((person) =>
                              person.role.props.children[0].props.children[0].props.children
                                .toLocaleLowerCase()
                                .includes(
                                  nameSearch.target.value.toLocaleLowerCase()
                                )
                            )
                          );
                        }}
                        id="input-s"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {getCheckPermission("add", "organization_setup") ?
                  <button
                    class="btn btn-primary add-btn btn-icon add"
                    style={{ width: 70 + "px" }}
                    type="button"
                    onClick={() => {
                      showLargeDrawer()
                      setDepartmentNameAdd("");
                      setroleAdd(1);
                      setdesignationAdd("");
                      setAddDept(false);
                      setDesigationMsg("");
                      setDeptMsg("");
                      modelDismissAdd = true;
                    }}
                  >
                    Add
                  </button>
                  : <></>}
                <Drawer
                  title="Add New Department"
                  className="bug-modal add-department-modal depart-modal"
                  placement="right"
                  size={size}
                  onClose={onAddClose}
                  open={addOpen}
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-2">
                        <div className="common-form-fields ">
                          <div className="portlet-body p-4">
                            <form>
                              <div className="d-flex align-items-center jstify-content-between mt-4">
                                <div className="row w-100">
                                  <div className="col-md-3 text-start ps-0">
                                    <label class="form-label pt-2">
                                      Department :
                                    </label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-7 add-department">
                                    <input
                                      className="w-100"
                                      type="text"
                                      placeholder="Add department name"
                                      id="deptName"
                                      value={departmentNameAdd}
                                      onChange={(e) => {
                                        setDepartmentNameAdd(
                                          e.target.value
                                        );
                                        setDeptMsg("");
                                      }}
                                    />
                                    {save && errors?.departmentNameAdd && (
                                      <>
                                        <div className="field-required">
                                          <span> {errors?.departmentNameAdd}</span>
                                        </div>
                                      </>
                                    )}
                                    <div
                                      className="ms-4"
                                      style={{ color: "red" }}
                                    >
                                      {deptMsg}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-md-3 text-start ps-0">
                                  <label class="form-label my-2 pt-2">
                                    Designation :
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    className="w-100 my-2 "
                                    type="text"
                                    id="roleName"
                                    placeholder="Add designation"
                                    value={designationAdd}
                                    onChange={(e) => {
                                      setdesignationAdd(e.target.value);
                                    }}
                                  />
                                  {save && errors?.designationAdd && (
                                    <>
                                      <div className="field-required">
                                        <span> {errors?.designationAdd}</span>
                                      </div>
                                    </>
                                  )}
                                  <div style={{ color: "red" }}>
                                    {desigationMsg}
                                  </div>
                                </div>

                              </div>
                              <div className="row mt-2">
                                <div className="col-md-3 text-start ps-0">
                                  <label class="form-label my-2 pt-2">
                                    Level :
                                  </label>
                                </div>
                                <div className="col-md-7">

                                  <select className="form-select"
                                    value={roleAdd}
                                    onChange={(e) => {
                                      setroleAdd(e.target.value);
                                    }}
                                  >
                                    {departmentList1?.length ? (
                                      <>
                                        {departmentList1.map((data, index) => {
                                          return (
                                            <option value={index + 1}>{index + 1}</option>
                                          )
                                        })}
                                        <option value={departmentList1?.length + 1}>{departmentList1?.length + 1}</option>
                                      </>
                                    )
                                      :
                                      (<>
                                        <option value={1}>1</option>
                                      </>)
                                    }
                                  </select>
                                  {save && errors?.roleAdd && (
                                    <>
                                      <div className="field-required">
                                        <span> {errors?.roleAdd}</span>
                                      </div>
                                    </>
                                  )}                                 
                                </div>
                                <div className="col-md-2">
                                  <i
                                    className="btn btn-primary w-100 depart-add-btn mt-3 mt-md-0 p-2"
                                    style={{
                                      fontStyle: "normal",
                                    }}
                                    data-dismiss={
                                      isModelDismiss ? "modal" : ""
                                    }
                                    onClick={() => {
                                      let value = true;
                                      if (isEdit) {
                                        let flag = true;
                                        departmentList1.forEach((item) => {
                                          if (item.designation === textRoleAdd) {
                                            flag = false;
                                            modelDismissAdd = false;
                                          }
                                        });
                                        if (flag) {
                                          setDesigationMsg("");
                                          modelDismissAdd = true;
                                          departmentList1[
                                            isEditIndex
                                          ].designation = textRoleAdd;
                                          setIsEdit(false);
                                        }
                                      } else {
                                        if (departmentList1.length !== 0) {
                                          departmentList1.forEach((item) => {
                                            if (
                                              item.designation ===
                                              designationAdd
                                            ) {
                                              modelDismissAdd = false;
                                              value = false;
                                            }
                                          });
                                          if (value === true) {
                                            if (
                                              departmentNameAdd !== "" &&
                                              designationAdd !== "" &&
                                              roleAdd !== ""
                                            ) {
                                              modelDismissAdd = true;
                                              setDesigationMsg("");
                                              let obj = {
                                                department: departmentNameAdd.trim(),
                                                designation: designationAdd.trim(),
                                                level: +roleAdd
                                              };
                                              departmentList1.push(obj);
                                              setDepartmentList1(
                                                departmentList1
                                              );
                                              setdesignationAdd("");
                                              setroleAdd(1);
                                            }
                                          }
                                        } else if (
                                          departmentNameAdd !== "" &&
                                          designationAdd !== "" &&
                                          roleAdd !== ""
                                        ) {
                                          let obj = {
                                            department: departmentNameAdd.trim(),
                                            designation: designationAdd.trim(),
                                            level: +roleAdd
                                          };
                                          departmentList1.push(obj);
                                          setDepartmentList1(
                                            departmentList1
                                          );
                                          setdesignationAdd("");
                                          setroleAdd(1);
                                        } else {
                                        }
                                        setAddDept(true);
                                      }
                                    }}
                                  >
                                    Add1
                                  </i>
                                </div>
                              </div>
                              {addDept ? (
                                <div>
                                  <div className="col-md-12 mt-5 mb-3 p-0">
                                    <label
                                      htmlFor=""
                                      className="department-role form-label text-dark"
                                    >
                                      Roles List:
                                    </label>
                                  </div>
                                  {departmentList1.map((item, index) => {
                                    return (
                                      <div className="row ">
                                        <div className=" col-md-10 p-0">
                                          <div className={`role-list text d-flex 
                                              ${!(isEdit && index === isEditIndex) ? 'border-btm' : ''}`}>
                                            <div className="d-flex justify-content-between w-100 mb-2 align-items-center">

                                              <div className="d-flex align-items-center w-100">
                                                <div className="box-modal">
                                                  <span className="role-items">
                                                    {isEdit &&
                                                      index === isEditIndex ? (

                                                      <>
                                                        <select className="edit-role-select"
                                                          value={textRoleLevelAdd}
                                                          onChange={(e) => {
                                                            setTextRoleLevelAdd(e.target.value);
                                                          }}
                                                        >
                                                          {departmentList1?.length ? (
                                                            <>
                                                              {departmentList1.map((data, index) => {
                                                                return (
                                                                  <option value={index + 1}>{index + 1}</option>
                                                                )
                                                              })}
                                                              <option value={departmentList1?.length + 1}>{departmentList1?.length + 1}</option>
                                                            </>
                                                          )
                                                            :
                                                            (<>
                                                              <option value={1}>1</option>
                                                            </>)
                                                          }
                                                        </select></>
                                                    ) : (
                                                      item.level
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="box-modal mx-4">
                                                  <span className="role-items text-dark">
                                                    -
                                                  </span>
                                                </div>
                                                <div className="box-modal mx-4">
                                                  <span className="role-items">
                                                    {item.department}
                                                  </span>
                                                </div>
                                                <div className="box-modal mx-4">
                                                  <span className="role-items text-dark">
                                                    -
                                                  </span>
                                                </div>
                                                <div className="box-modal ms-4">
                                                  <span className="role-items">
                                                    {isEdit &&
                                                      index === isEditIndex ? (
                                                      <input
                                                        type="text"
                                                        className="w-100"
                                                        value={textRoleAdd}
                                                        onChange={(e) =>
                                                          setTextRoleAdd(
                                                            e.target.value
                                                          )
                                                        }
                                                      ></input>
                                                    ) : (
                                                      item.designation
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-2 mt-2">
                                          <div className="d-flex  icon">
                                            {isEdit && index === isEditIndex ? (
                                              <i
                                                class="fa fa-check mx-3"
                                                aria-hidden="true"
                                                onClick={() => {

                                                  let value = true;
                                                  if (isEdit) {
                                                    let flag = true;

                                                    if (flag) {
                                                      setDesigationMsg("");
                                                      modelDismissAdd = true;
                                                      departmentList1[isEditIndex].designation = textRoleAdd;
                                                      departmentList1[isEditIndex].level = +textRoleLevelAdd;
                                                      setIsEdit(false);
                                                    }
                                                  } else {
                                                    if (departmentList1.length !== 0) {
                                                      departmentList1.forEach(
                                                        (item) => {
                                                          if (item.designation === designationAdd) {
                                                            modelDismissAdd = false;
                                                            value = false;
                                                          }
                                                        }
                                                      );
                                                      if (value === true) {
                                                        if (
                                                          departmentNameAdd !== "" &&
                                                          designationAdd !== "" &&
                                                          roleAdd !== ""
                                                        ) {
                                                          modelDismissAdd = true;
                                                          setDesigationMsg("");
                                                          let obj = {
                                                            department: departmentNameAdd.trim(),
                                                            designation: designationAdd.trim(),
                                                            level: +roleAdd
                                                          };
                                                          departmentList1.push(obj);
                                                          setDepartmentList1(departmentList1);
                                                          setdesignationAdd("");
                                                        }
                                                      }
                                                    } else if (
                                                      departmentNameAdd !== "" &&
                                                      designationAdd !== "" &&
                                                      roleAdd !== ""
                                                    ) {
                                                      let obj = {
                                                        department: departmentNameAdd.trim(),
                                                        designation: designationAdd.trim(),
                                                        level: +roleAdd
                                                      };
                                                      departmentList1.push(obj);
                                                      setDepartmentList1(departmentList1);
                                                      setdesignationAdd("");
                                                    } else {
                                                    }
                                                    setAddDept(true);
                                                  }
                                                }}
                                              ></i>
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-edit edit-icon mx-3"
                                                onClick={() => {
                                                  setIsEdit(true);
                                                  modelDismissAdd = true;
                                                  setDesigationMsg("");
                                                  setTextRoleAdd(item.designation);
                                                  setTextRoleLevelAdd(item.level)
                                                  setIsEditIndex(index);
                                                }}
                                              >
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                              </svg>
                                            )}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-trash-2 trash-icon"
                                              onClick={() => {

                                                removeContent(index);
                                              }}
                                            >
                                              <polyline points="3 6 5 6 21 6"></polyline>
                                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                              <line
                                                x1="10"
                                                y1="11"
                                                x2="10"
                                                y2="17"
                                              ></line>
                                              <line
                                                x1="14"
                                                y1="11"
                                                x2="14"
                                                y2="17"
                                              ></line>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                          <div class="portlet-footer  text-end mt-3 edit-department-save pe-0">
                            <div className="col-md-12 ">
                              <button
                                id="SaveBtn"
                                className="btn btn-primary department-save close"
                                type="button"
                                aria-label="Close"
                                data-dismiss={modelDismissAdd ? "modal" : ""}
                                onClick={() => {
                                  setDepartmentList1([]);
                                  setdesignationAdd("");
                                  setAddDept(false);
                                  setErrors("");
                                  addDepartment();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </Drawer>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3 department-table">
            <Table
              className="table table-bordered table-hover mt-3 accordian-table"
              id="sample-module-expand"
              dataSource={userdepartment}
              columns={userdepartmentcol}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div >

      <Drawer
        title="Edit Department"
        className="bug-modal add-department-modal depart-modal"
        placement="right"
        size={size}
        onClose={onEditClose}
        open={editOpen}
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body p-2">
              <div className="common-form-fields ">
                <div className="portlet-body p-4">
                  <form>
                    <div className="row w-100 mt-4">
                      <div className="col-md-3 text-start ps-0">
                        <label class="form-label pt-2">
                          Department :
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          className="w-100 "
                          type="text"
                          value={departmentNameEdit}
                          onChange={(e) => {
                            setDepartmentNameEdit(e.target.value);
                            setDeptMsgEdit("");
                          }}
                        />
                        <div className="ms-4" style={{ color: "red" }}>
                          {deptMsgEdit}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3 text-start ps-0">
                        <label class="form-label my-2 pt-2">
                          Designation :
                        </label>
                      </div>
                      <div className="col-md-7 ">
                        <input
                          className="w-100 my-2 "
                          type="text"
                          placeholder="Add designation"
                          value={designationEdit}
                          onChange={(e) => {
                            setdesignationEdit(e.target.value);
                          }}
                        />
                        <div style={{ color: "red" }}>
                          {desigationMsgEdit}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 text-start ps-0">
                        <label class="form-label my-2 pt-2">
                          Level :
                        </label>
                      </div>
                      <div className="col-md-7 ">

                        <select className="form-select"
                          value={roleEdit}
                          onChange={(e) => {
                            setroleEdit(e.target.value);
                          }}
                        >
                          {departmentList1Edit?.length ? (
                            <>
                              {departmentList1Edit.map((data, index) => {
                                return (<option value={index + 1}>{index + 1}</option>)
                              })}
                              <option value={departmentList1Edit?.length + 1}>{departmentList1Edit?.length + 1}</option>
                            </>)
                            : (<><option value={1}>1</option></>)
                          }
                        </select>
                        <div style={{ color: "red" }}>
                          {roleMsgEdit}
                        </div>
                      </div>
                      <div className="col-md-2 my-1">
                        <i
                          className="btn btn-primary  w-100 mx-3"
                          style={{ fontStyle: "normal" }}
                          onClick={() => {

                            let value = true;
                            if (isEdit1) {
                              let flag = true;
                              departmentList1Edit.forEach((item) => {
                                if (item.designation === textRole) {
                                  modelDismissEdit = false;
                                  flag = false;
                                }
                              });
                              if (flag) {
                                setDesigationMsgEdit("");
                                setroleMsgEdit("");
                                modelDismissEdit = true;
                                departmentList1Edit[isEditIndex1].designation = textRole;
                                departmentList1Edit[isEditIndex1].level = +textRoleLevel;
                                setIsEdit1(false);
                              }
                            } else {
                              if (departmentList1Edit.length !== 0) {
                                departmentList1Edit.forEach((item) => {
                                  if (item.designation === designationEdit) {
                                    modelDismissEdit = false;
                                    value = false;
                                  }
                                });

                                if (value === true) {
                                  if (
                                    departmentNameEdit !== "" &&
                                    designationEdit !== "" &&
                                    roleEdit !== ""
                                  ) {
                                    setDesigationMsgEdit("");
                                    setroleMsgEdit("");
                                    let obj = {
                                      department: departmentNameEdit.trim(),
                                      designation: designationEdit.trim(),
                                      level: +roleEdit,
                                      departmentId: departmentList1Edit.departmentId,
                                    };
                                    departmentList1Edit.push(obj);
                                    setDepartmentList1Edit(
                                      departmentList1Edit
                                    );
                                    setdesignationEdit("");
                                    setroleEdit(1);
                                  }
                                }
                              } else {
                                let obj = {
                                  department: departmentNameEdit.trim(),
                                  designation: designationEdit.trim(),
                                  level: +roleEdit,
                                  departmentId: departmentId,
                                };
                                departmentList1Edit.push(obj);
                                setDepartmentList1Edit(departmentList1Edit);
                                setdesignationEdit("");
                                setroleEdit(1);
                              }
                              setListLength(true);
                            }
                          }}
                        >
                          Add
                        </i>
                      </div>
                    </div>

                    {listLength ? (
                      <div>
                        <div className="col-md-12 mt-5 mb-4 p-0">
                          <label
                            htmlFor=""
                            className="department-role form-label text-dark"
                          >
                            Roles List :
                          </label>
                        </div>
                        <div className="scroll-box">
                          {departmentList1Edit.map((item, index) => {
                            return (
                              <div className="row">
                                <div className=" col-md-10 p-0">
                                  <div className={`role-list text d-flex 
                                              ${!(isEdit1 && index === isEditIndex1) ? 'border-btm' : ''}`}>
                                    <div className="d-flex  w-100 mb-2 align-items-center ms-3">
                                      <div className="d-flex align-items-center">
                                        <div className="box-modal">
                                          <div className="box-modal">
                                            <span className="role-items">
                                              {isEdit1 &&
                                                index === isEditIndex1 ? (
                                                <>
                                                  <select className="edit-role-select"
                                                    value={textRoleLevel}
                                                    onChange={(e) => {

                                                      setTextRoleLevel(+e.target.value);
                                                    }}
                                                  >
                                                    {departmentList1Edit?.length ? (
                                                      <>
                                                        {departmentList1Edit.map((data, index) => {
                                                          return (
                                                            <option value={index + 1}>{index + 1}</option>
                                                          )
                                                        })}
                                                      </>
                                                    )
                                                      :
                                                      (<>
                                                        <option value={1}>1</option>
                                                      </>)
                                                    }
                                                  </select>
                                                </>
                                              ) : (
                                                item.level
                                              )}
                                            </span>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="box-modal mx-4">
                                        <span className="role-items text-dark">
                                          -
                                        </span>
                                      </div>
                                      <div className="box-modal mx-4">
                                        <span className="role-items">
                                          {item.department}
                                        </span>
                                      </div>
                                      <div className="box-modal mx-4">
                                        <span className="role-items text-dark">
                                          -
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="box-modal w-100">
                                          <span className="role-items">
                                            {isEdit1 &&
                                              index === isEditIndex1 ? (
                                              <input
                                                type="text"
                                                className="w-100"
                                                value={textRole}
                                                onChange={(e) =>
                                                  setTextRole(e.target.value)
                                                }
                                              ></input>
                                            ) : (
                                              item.designation
                                            )}
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div className="d-flex  icon">
                                    {isEdit1 && index === isEditIndex1 ? (
                                      <i
                                        class="fa fa-check mx-3"
                                        aria-hidden="true"
                                        onClick={() => {
                                          let value = true;
                                          if (isEdit1) {
                                            let flag = true;
                                            departmentList1Edit.forEach(
                                              (item, index1) => {                                            
                                                if (item.designation === textRole &&
                                                  index1 !== index) {
                                                  setDesigationMsgEdit(
                                                    "Please do not enter same designation!"
                                                  );
                                                  modelDismissEdit = false;
                                                  flag = false;
                                                }
                                              }

                                            );
                                            if (flag) {
                                              setDesigationMsgEdit("");
                                              setroleMsgEdit("");
                                              modelDismissEdit = true;
                                              departmentList1Edit[isEditIndex1].designation = textRole;
                                              departmentList1Edit[isEditIndex1].level = +textRoleLevel;
                                              setIsEdit1(false);
                                            }
                                          } else {
                                            if (
                                              departmentList1Edit.length !== 0
                                            ) {
                                              departmentList1Edit.forEach(
                                                (item) => {
                                                  if (
                                                    item.designation ===
                                                    designationEdit
                                                  ) {
                                                    modelDismissEdit = false;
                                                    value = false;
                                                  }
                                                }
                                              );

                                              if (value === true) {
                                                if (
                                                  departmentNameEdit !== "" &&
                                                  designationEdit !== "" &&
                                                  roleEdit !== ""
                                                ) {
                                                  setDesigationMsgEdit("");
                                                  setroleMsgEdit("");
                                                  let obj = {
                                                    department: departmentNameEdit.trim(),
                                                    designation: designationEdit.trim(),
                                                    level: +roleEdit,
                                                    departmentId: departmentList1Edit.departmentId,
                                                  };
                                                  departmentList1Edit.push(
                                                    obj
                                                  );
                                                  setDepartmentList1Edit(
                                                    departmentList1Edit
                                                  );
                                                  setdesignationEdit("");
                                                  setroleEdit(1);
                                                }
                                              }
                                            } else {
                                              let obj = {
                                                department: departmentNameEdit.trim(),
                                                designation: designationEdit.trim(),
                                                level: +roleEdit,
                                                departmentId: departmentId,
                                              };
                                              departmentList1Edit.push(obj);
                                              setDepartmentList1Edit(
                                                departmentList1Edit
                                              );
                                              setdesignationEdit("");
                                              setroleEdit(1);
                                            }
                                            setListLength(true);
                                          }
                                        }}
                                      ></i>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit edit-icon mx-3"
                                        onClick={() => {
                                          setIsEdit1(true);
                                          setIsEditIndex1(index);
                                          setDesigationMsgEdit("");
                                          setroleMsgEdit("");
                                          setTextRole(item.designation);
                                          setTextRoleLevel(item.level ? item.level : 1)
                                        }}
                                      >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                      </svg>
                                    )}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-trash-2 trash-icon"
                                      onClick={() => {
                                        if (item.roleId === undefined) {
                                          removeContent1(index);
                                        } else {
                                          deleteRoleById(item.roleId);
                                          removeContent1(index);
                                        }
                                      }}
                                    >
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                      ></line>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
                <div class="portlet-footer  text-end mt-3 edit-department-save">
                  <div className="col-md-12 ">
                    <button
                      id="EditBtn"
                      className="btn btn-primary"
                      data-dismiss={modelDismissEdit ? "modal" : ""}
                      onClick={updateDepartmentItem}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Department;



