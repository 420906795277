import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsLoginLoading } from './rankingReportV3/rankingReportSlice';

function RedirectToExternal() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsLoginLoading(true));
    window.location.href = 'https://www.infigrowth.com';
  }, []);
  return null; 
}

export default RedirectToExternal;