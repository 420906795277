import moment from "moment";
import { date } from "yup";

export const dateToClosestWeek = (date, test) => {
  const startWeek = new Date(date);
  const endWeek = new Date(date);
  if (startWeek.getDay !== 0) {
    startWeek.setDate(startWeek.getDate() - startWeek.getDay());
  }
  endWeek.setDate(endWeek.getDate() + 6 - endWeek.getDay());
  return [startWeek, endWeek];
};

export const formatDateRange = (startDate, endDate) => {
  const formattedStartDate = moment(startDate).format("DD MMM YYYY");
  const formattedEndDate = moment(endDate).format("DD MMM YYYY");
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  return [formattedDateRange];
};

export const formatDate = (date) => {
  const formattedDate = moment(date).format("DD MMM YYYY");
  return formattedDate;
};

export const getWeeksBetweenRange = (startDate, endDate,unavailableDates) => {
  const dateRanges = [];
  const startDates = []

  // Set the current date to the first Sunday on or after the start date
  let currentDate = new Date(startDate);
  currentDate.setDate(currentDate.getDate() + (7 - currentDate.getDay()) % 7);

  // Iterate through the weeks until the end date
  while (currentDate <= endDate) {
      const saturday = new Date(currentDate);
      saturday.setDate(currentDate.getDate() + 6);
      const rangeEndDate = saturday <= endDate ? saturday : endDate;
      if(unavailableDates){
        const formattedUnavailableDates = unavailableDates.map((date)=> moment(date).format("DD MMM YYYY"))
        if(!formattedUnavailableDates.includes(formatDate(currentDate))){
          dateRanges.push(
            `${formatDate(currentDate)} - ${formatDate(rangeEndDate)}`
          );
          startDates.push(formatDate(currentDate))
        }
      } else {
        dateRanges.push(
          `${formatDate(currentDate)} - ${formatDate(rangeEndDate)}`
        );
        startDates.push(formatDate(currentDate))
      }
      currentDate.setDate(currentDate.getDate() + 7);
  }

  return {dateRanges, startDates};
};

export const getCurrentAndPreviousMonth = (date) => {
  const currentMonth = moment(date);
  const previousMonth = moment(date).subtract(1, "months");
  return [currentMonth, previousMonth];
} 

export const getLastSundayOfMonth = (date) => {
  const lastSunday = moment(date).endOf("month").day("Sunday").format();
  return lastSunday;
}

export const getSmallestDate = (dates) => {
  let moments = dates.map(date => moment(date));
  const smallestDate = moment.min(moments);
  return moment(smallestDate).format();
}

export const getLargestDate = (dates) => {
  let moments = dates.map(date => moment(date));
  const largestDate = moment.max(moments);
  return moment(largestDate).format();
}

export const getSecondLargestDate = (dates) => {
  let moments = dates.map(date => moment(date).format("DD MMM YY"));
  moments = moments.filter((date)=> date !== moment(getLargestDate(dates)).format("DD MMM YY"));
  const formattedMoments = moments.map((date)=> moment(date));  
  const secondLargestDate = moment.max(formattedMoments);
  return moment(secondLargestDate).format();
}

export const getDatesBetweenRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(moment(currentDate).format("DD MMM YY"));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

function getMissingDates(startDate, endDate, datesArray) {
  const  allDates = getDatesBetweenRange(startDate,endDate);
  const availabelDates = datesArray.map((date)=> moment(date).format("DD MMM YY"));
  const missingDates = [];
  allDates.map((date)=> {
    if(!availabelDates.includes(date)) {
      missingDates.push(date)
    }
  })
  return missingDates.map((date)=> moment(date).format());
}

export const getDisabledDates = (dates) => {
  const startDate = getSmallestDate(dates);
  const endDate = dateToClosestWeek(getLargestDate(dates))[1];
  const availabelDates = []
   dates.map((date)=> {
    const weekStart = dateToClosestWeek(date)[0];
    const weekEnd = dateToClosestWeek(date)[1]
    const allDatesInWeek = getDatesBetweenRange(weekStart,weekEnd);
    allDatesInWeek.map((date)=> {
      availabelDates.push(moment(date).format())
    })
  })
  const datesNotPresent = getMissingDates(startDate,endDate,availabelDates);
  return datesNotPresent;
}

export const isLastSundayOfMonth = (date) => {
  const lastSunday = getLastSundayOfMonth(date);
  const isLastSunday = moment(date).format("DD MMM YYYY") === moment(lastSunday).format("DD MMM YYYY");
  return isLastSunday;
}

export const sortDatesToAscending = (dates) => {
  let tempDates = dates.map((date)=> moment(date));
  tempDates.sort((a,b)=> a.isBefore(b)? -1 : 1);
  const sortedDates = tempDates.map((date)=> moment(date).format("MMM 1, YYYY"));
  return sortedDates;
}

