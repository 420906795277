import React from "react";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "react-circular-progressbar/dist/styles.css";
import APIManager from "../services/APIManager";
import {
  addNewSeoAudit,
  copySEOaudit,
  deleteSeoAudit,
  getSeoAuditList,
  isShown,
} from "../utility/Constant";
import {
  DDMMyyyyFormateDateSet,
  getCheckPermission,
} from "../utility/Globals";
import { Table } from "antd";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { Tab, TabList } from "react-tabs";
import ModuleExpandSEOAuditSelection from "./ModuleExpandSEOAuditSelection";
import ModuleExpandSEOAuditDetailed from "./ModuleExpandSEOAuditDetailed";
import { useNavigate } from "react-router-dom";

function ModuleExpandSEOAuditPopUp({ update }) {
  const navigate = useNavigate();
  const [newAuditName, setAuditName] = useState("");
  const [isNoChecked, setNoChecked] = useState(false);
  const [teamcol, setteamcol] = useState([]);
  const [seoAuditListData, setSeoAuditData] = useState([]);
  const [seoAuditSelected, setAuditSelected] = useState("");
  const [seoAuditList, setSeoAudit] = useState([]);
  const [viewAudit, setViewAudit] = useState(true);
  const [settings, setSettings] = useState("");
  const [auditversion, setauditversion] = useState();
  const [searchvlue, setsearchvlue] = useState();
  const [seoAuditListFilteredData, setSeoAuditListFilteredData] = useState([]);
  const [errors, setErrors] = useState({});
  const [save, setsave] = useState(false);

  useEffect(() => {
    var columns = [
      {
        title: "Audit Name",
        dataIndex: "audit",
        key: "audit",
      },
      {
        title: "Points Checked",
        dataIndex: "pointschecked",
        key: "pointschecked",
      },
      {
        title: "Started By",
        dataIndex: "createdBy",
        key: "createdBy",
      },
      {
        title: "Start Date",
        dataIndex: "seoAuditDate",
        key: "seoAuditDate",
      },
      {
        title: "Status",
        dataIndex: "auditStatus",
        key: "auditStatus",
      },
      {
        title: "Show to Client",
        dataIndex: "isshow",
        key: "isshow",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setteamcol(columns);
    getSEOList();
  }, []);

  function getSEOList() {
    APIManager.getAPI(
      getSeoAuditList + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status === 200) {
        setSeoAudit(response?.data);
        setAuditSelected(response.data[0]?.seoAuditId);
        if (response.data?.length === 0) {
          setViewAudit(true);
        } else {
          setViewAudit(false);
        }
        let list = [];
        response.data.forEach((item, index) => {
          list.push({
            audit: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSettings("moduleExpandSeoAuditSelection");
                  navigate("",{
                    state: {
                      seoAuditId: item?.seoAuditId,
                      seoAuditName: item?.seoAuditName.trim(),
                    },
                  });
                }}
              >
                {item.seoAuditName}
              </div>
            ),
            pointschecked: (
              <div>
                {item.checkedPoints}/{item.totalPoints}
              </div>
            ),
            createdBy: <div>{item.createdBy}</div>,
            seoAuditDate: (
              <div>{DDMMyyyyFormateDateSet(item.seoAuditDate)}</div>
            ),
            auditStatus: <div>{Settatus(item.auditStatus)}</div>,
            action: (
              <>
                <div class="d-flex text-center mx-2">
                  <div
                    onClick={() => {
                      setSettings("moduleExpandSeoAuditSelection");
                      navigate("",{
                        state: {
                          seoAuditId: item?.seoAuditId,
                          seoAuditName: item?.seoAuditName.trim(),
                        },
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon"
                      title="Edit Audit"
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      deleteAudit(item.seoAuditId);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon ms-3"
                      title="Delete Audit"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </div>
              </>
            ),
            isshow: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item?.isShown}
                  onClick={() => {
                    let msg =
                      item?.isShown !== true
                        ? "Confirm to Show Client"
                        : "Confirm to Hide Client";
                    confirmAlert({
                      title: msg,
                      message: "Are you sure to do this.",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            isShownStatusChange(item, index);
                          },
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
          });
        });
        setSeoAuditData(list);
      }
    });
  }

  async function deleteAudit(id) {
    try {
      const response = await APIManager.deleteAPI(deleteSeoAudit + id);
      if (response === 200) {
        getSEOList();
      }
    } catch (e) { }
  }

  function isShownStatusChange(item, index) {
    APIManager.postAPI(isShown + item.seoAuditId, {}, null, true).then(
      (response) => {
        if (response.status === 200) {
          getSEOList();
        }
      }
    );
  }

  function Settatus(id) {
    switch (id) {
      case 0:
        return "Active";
      case 1:
        return "Completed";
      case 2:
        return "Prospect";
      case 3:
        return "WIP";
      case 4:
        return "OnHold";
      case 5:
        return "Pending";
      default:
        return "";
    }
  }

  function copyAuditNo(name, auditversion) {
    APIManager.postAPI(
      addNewSeoAudit,
      {
        ProjectId: localStorage.getItem("projectGId"),
        Status: 0,
        StatusUpdatedDate: new Date().toISOString().slice(0, 10),
        ProjectAuditName: name.trim(),
        CreatedBy: localStorage.getItem("userId"),
        AuditType: auditversion,
      },
      null,
      true
    ).then((response) => {      
        setViewAudit(false);
        clerData();      
    });
  }

  function copyAuditYes(name, version, id) {
    APIManager.postAPI(
      copySEOaudit,
      {
        ProjectAuditName: name.trim(),
        ProjectAuditId: id, 
      },
      null,
      true
    ).then((response) => {
      if (response.status === 200) {
        setViewAudit(false);
        clerData();
      }
    });
  }

  function formInputValidation() {
    let error = { ...errors };
    newAuditName.trim() === ""
      ? (error["newAuditName"] = "Audit Name is required!")
      : delete error["newAuditName"];

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clerData() {
    getSEOList();
    setAuditName("");
    setauditversion("");
    toast.success("Successfully added!");
  }
  let onClickFunction = (arg) => {
    setSettings(arg);
    getSEOList();
  };

  function getComponent() {
    if (settings === "moduleExpandSeoAuditSelection") {
      return <ModuleExpandSEOAuditSelection update={onClickFunction} />;
    } else if (settings === "auditDetails") {
      return <ModuleExpandSEOAuditDetailed update={onClickFunction} />;
    }
  }

  const search = (value) => {
    const filterTable = seoAuditListData.filter((o) => {
      return Object.keys(o).some((k) => {
        if (Array.isArray(o[k].props.children) === true) {
          for (var i = 0; i < o[k].props.children.length; i++) {
            var name = o[k].props.children[i].toString();
            var returnValue;
            if (name === value) {
              returnValue = o[k].props.children;
            } else if (name.includes(value)) {
              returnValue = o[k].props.children;
            }
          }
        } else
          {
            returnValue =  String(o[k].props.children)
            .toLowerCase()
            .includes(value.toLowerCase());
          }
          return returnValue;
      });
    });

    setSeoAuditListFilteredData(filterTable);
  };

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      search(searchvlue);
    }
  }

  return (
    <>
      <div className="portlet slideInUp">
        <div className="pop-over overflow-hidden">
          {settings === "" ? (
            <>
              <div class="portlet-header">
              <svg onClick={() => update("")}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                <h3  class="portlet-title main-title">
                  {localStorage.getItem("projectGName")} - Audit Findings
                </h3>
              </div>           
                <div className="row">
                <div className="config-universal-tab audit-finding mt-0 mb-3  px-0">
                  <TabList className="nav nav-lines portlet-nav d-flex justify-content-start tab-buttons border">
                    {seoAuditList.length > 0 ? (
                      <Tab
                        className={
                          viewAudit
                            ? "nav-item nav-link"
                            : "nav-item nav-link  active"
                        }
                        onClick={() => {
                          setViewAudit(false);
                        }}
                      >
                        View Audit
                      </Tab>
                    ) : (
                      <></>
                    )}
                    <Tab
                      className={
                        viewAudit
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setViewAudit(true);
                      }}
                    >
                      Create Audit
                    </Tab>
                  </TabList>
                </div>
                </div>
                <div className="portlet-body">
                <div className="px-3 pb-3  audit">
                  {/* CRAETE AUDIT TAB START */}
                  {viewAudit ? (
                    <div className="row mt-3">
                      <div className="credit-audit">
                        <div className="w-50 mt-3">
                          <div className="module-title">
                            <h5 className="text-dark">Enter Audit Name</h5>
                          </div>
                          <input
                            className="form-control w-100 mt-2"
                            type="text"
                            id="role-name"
                            placeholder="Audit name"
                            style={{ borderRadius: 0 }}
                            required
                            value={newAuditName}
                            onChange={(e) => {
                              setAuditName(e.target.value);
                              formInputValidation();
                            }}
                          />
                        </div>
                        {save && errors?.newAuditName ? (
                          <>
                            <div className="field-required">
                              <span> {errors?.newAuditName}</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="w-50 my-4">
                          <div className="module-title">
                            <h5 className="text-dark">
                              Select Audit Version Type
                            </h5>
                          </div>
                          <select
                            className="form-select w-100 mt-2"
                            style={{ borderRadius: 0, height: "36px" }}
                            value={auditversion}
                            onChange={(e) => {
                              setauditversion(e.target.value);
                            }}
                          >
                            <option value="0">Full Audit</option>
                            <option value="1">Migration Audit</option>
                            <option value="2">Technical Audit</option>
                            <option value="3">Others</option>
                          </select>
                        </div>

                        {seoAuditList.length > 0 ? (
                          <div className="w-50">
                            <div className="module-title mt-3">
                              <h5 className="text-dark">
                                Copy From Previous Audit?
                              </h5>
                            </div>
                            <div className="d-flex mt-3">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={isNoChecked ? true : false}
                                  onClick={() => {
                                    setNoChecked(true);
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Yes
                                </label>
                              </div>
                              <div class="form-check ms-4">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  checked={isNoChecked ? false : true}
                                  onClick={() => {
                                    setNoChecked(false);
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {!isNoChecked ? (
                          <></>
                        ) : (
                          <div className="mt-4">
                            <div className="module-title">
                              <h5 className="text-dark">
                                Select Audit Version
                              </h5>
                            </div>
                            <select
                              class="form-select w-50 mt-3 audit-finding-select"
                              style={{ padding: 10 + "px" }}
                              required
                              value={seoAuditSelected}
                              onChange={(e) => {
                                setAuditSelected(e.target.value);
                              }}
                            >
                              {seoAuditList.map((item) => {
                                return (
                                  <option value={item.seoAuditId}>
                                    {item.seoAuditName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                        <div className="col-sm-12 mt-3 p-0">
                          <button
                            className="btn btn-primary mt-4"
                            style={{ width: 70 + "px", height: 30 + "px" }}
                            onClick={() => {
                              setsave(true);
                              if (formInputValidation()) {
                                if (viewAudit) {
                                  if (isNoChecked) {
                                    copyAuditYes(
                                      newAuditName,
                                      auditversion,
                                      seoAuditSelected
                                    );
                                  } else {
                                    copyAuditNo(newAuditName, auditversion);
                                  }
                                }
                              }
                            }}
                          >
                            {" "}
                            Create
                          </button>
                        </div>
                      </div>

                      {/* CRAETE AUDIT TAB END */}
                    </div>
                  ) : (
                    <>
                      <div className="row mt-3">
                        <div class="col-md-4 col-sm-12 px-0 audit-detailed-search">
                          <form class="search-box w-100 d-flex">
                            <button
                              type="button"
                              onClick={() => {
                                search(searchvlue);
                              }}
                            >
                              <i class="fa fa-search"></i>
                            </button>
                            <input
                              value={searchvlue}
                              type="text"
                              class="w-100"
                              placeholder="Type to search"
                              name="search"
                              onKeyDown={(e) => {
                                _handleKeyDown(e);
                              }}
                              onChange={(item) => {
                                setsearchvlue(item.target.value);
                              }}
                            />
                          </form>
                        </div>
                        <div class="col-md-8 col-sm-12 px-0 mt-md-0 mt-3" align="right">
                          {getCheckPermission(
                            "detailed_audit_edit",
                            "projects_setup"
                          ) ? (
                            <div
                              class="detailed-audit-btn ms-3"
                              onClick={() => {
                                setSettings("auditDetails");
                                navigate("",{
                                  state: {
                                    seoAuditId: seoAuditList[0].seoAuditId,
                                    seoAuditName:
                                      seoAuditList[0].seoAuditName.trim(),
                                  },
                                });
                              }}
                            >
                              <div class="btn btn-detailed-audit">
                                <span class="py-2">Detailed Audit</span>
                              </div>
                            </div>
                          ) :
                            (<></>)}
                        </div>
                      </div>
                      <div className="row">
                        { }
                        {/* VIEW AUDIT TAB START */}
                        <div class="audit-detail-box px-0">
                          <Table
                            className="table table-bordered table-hover mt-3 seoaudit-table"
                            id="datatable-1"
                            columns={teamcol}
                            dataSource={
                              seoAuditListFilteredData.length === 0
                                ? seoAuditListData
                                : seoAuditListFilteredData
                            }
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>{getComponent()}</>
          )}
        </div>
      </div>
    </>
  );
}

export default ModuleExpandSEOAuditPopUp;
