import React, {  } from "react";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";

function TokenExpired() {
  const navigate = useNavigate();
  return (
    <>
      <button
        onClick={() => {
          navigate("/login");
        }}
      >
        click to login
      </button>
    </>
  );
}

export default TokenExpired;
