import React from "react";
import { Table } from "antd";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import APIManager from "../../services/APIManager";
import { getDaysInMonth, convertDate, setMonths, findUnique, formatRankDate, getStatus } from "../../utility/Globals";
import { FreelancersRequirement, freelancerproject, getFreelancerUser, getSeoAuditItemByModuleId } from "../../utility/Constant";
import { Link, useLocation } from "react-router-dom";

function FreelancersRequirementDetails() {
  const [projectCols, setProjectCol] = useState([]);
  const [projectWorkList, setProjectWorkList] = useState([]);
  const [projectseleted, setprojectseleted] = useState();
  const [projectList, setprojectList] = useState([]);
  const [freelancerCategory, setfreelancerCategory] = useState();
  const ref = useRef();

  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [head1, setHead1] = useState(
    convertDate(new Date().getMonth() + 1 + "-01-" + new Date().getFullYear())
  );
  const [head2, setHead2] = useState(
    convertDate(
      convertDate(
        new Date().getMonth() +
        1 +
        "-" +
        getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      )
    )
  );
  const [monthYear, setMonthYear] = useState(
    setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear()
  );
  const [assigned, setAssigned] = useState(0);
  const [awaitingApproval, setAwaitingApproval] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [rework, setRework] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [projectWorkListMain, setProjectWorkListMain] = useState([]);

  const searchByvalue = (value) => {
    const filterTable = projectWorkListMain.filter((o) => {
      return Object.keys(o).some((k) => {
        if (k != "cost" && k != "word_count" && k != "eta") {
          return String(o[k])
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      })
    });
    setProjectWorkList(filterTable);
    // setProjectWorkListMain(filterTable);
  };
  let DBStatus = [];

  if (freelancerCategory == 0) {

    DBStatus.push(
      { value: 1, text: "In Progress" },
      { value: 2, text: "Rework" },
      { value: 3, text: "Awaiting Approval" },
      { value: 4, text: "Completed" });

  }
  else if (freelancerCategory == 1 ) {
    DBStatus.push(
      { value: 5, text: "In Progress" },
      { value: 6, text: "Rework" },
      { value: 7, text: "Awaiting Approval" },
      { value: 8, text: "Completed" });
  }
  else if (freelancerCategory == 2 ) {
    DBStatus.push(
      { value: 1, text: "In Progress" },
      { value: 2, text: "Rework" },
      { value: 3, text: "Awaiting Approval" },
      { value: 4, text: "Completed" },
      { value: 5, text: "In Progress" },
      { value: 6, text: "Rework" },
      { value: 7, text: "Awaiting Approval" },
      { value: 8, text: "Completed" });
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        // width: "20%",
      },
      {
        title: "PROJECT",
        dataIndex: "project",
        key: "project",
        // width: "10%",
      },

      {
        title: "KEYWORD",
        dataIndex: "keyword",
        key: "keyword",
        // width: "10%",
      },
      {
        title: "COST",
        dataIndex: "cost",
        key: "cost",
        // width: "10%",
      },
      {
        title: "WORD COUNT",
        dataIndex: "word_count",
        key: "word_count",
        // width: "10%",
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        // width: "10%",
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        filters: findUnique(DBStatus, (d) => d.text),
        onFilter: (value, record) => {
          let itemValue = DBStatus.find(o => o.value === value);
          return record?.status?.props?.children == itemValue.text;
        },
      },
    ];
    setProjectCol(columns);
  }, [freelancerCategory]);

  useEffect(() => {
    getFreelancer();
  }, []);



  function freelancersrequirement(rowData, category) {
    APIManager.postAPI(
      FreelancersRequirement,
      rowData, {}, true
    ).then((response) => {
      let list = [];
      if (response.status == 200) {
        setAssigned(response.data.table[0]?.assigned);
        setAwaitingApproval(response.data.table[0]?.awaitingApproval);
        setCompleted(response.data.table[0].completed);
        setRework(response.data.table[0].rework)
        response.data.table1.map((item) => {
          let object = {
            key: item.id,
            id: '#' + item.id,
            project: item.project,
            keyword: (
              <>
                <Link
                  to={"/content-main/requirement-keyword-details"}
                  state = {{
                    isEdit: true,
                    requirementId: item.requirementId,
                    keywordId: item.id,
                    active: 2
                  }}
                >
                  <span className="keyword_detail">
                    {item.keyword}</span>
                </Link>
              </>
            ),
            keywordFilter: item.keyword,
            cost: <span><i class="fa fa-inr me-2" aria-hidden="true"></i>{item.cost == 0 ? item.costPerWord + "/Word" : item.costPerArticle + "/Article"}</span>,
            word_count: <span> {item.wordCount ? item.wordCount + ' Words' : 0 + ' Words '}</span>,
            eta: formatRankDate(item.eta),
            status: articleStatus(item.status, category, item.isWriter),
           
          }
          list.push(object);
        });
        setProjectWorkList(list);
        setProjectWorkListMain(list)
      }
    })
  }

  function articleStatus(status, freelancerCategory, isWriter) {
    if (freelancerCategory == 0 ) {
      switch (status) {
        case 1:
          return getStatus(1);
          break;
        case 2:
          return getStatus(2);
          break;
        case 3:
          return getStatus(3);
          break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return getStatus(4);
          break;
      }
    }
    else if (freelancerCategory == 1 ) {
      switch (status) {
        case 5:
          return getStatus(1);
          break;
        case 6:
          return getStatus(2);
          break;
        case 7:
          return getStatus(3);
          break;
        case 8:
          case 9:
          return getStatus(4);
          break;
      }
    }
    else if (freelancerCategory == 2) {
      if(isWriter == 1)
      {
        switch (status) {
          case 1:
            return getStatus(1);
            break;
          case 2:
            return getStatus(2);
            break;
          case 3:
            return getStatus(3);
            break;
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            return getStatus(4);
            break;
        }  
      }
      else{
        switch (status) {
          case 5:
            return getStatus(1);
            break;
          case 6:
            return getStatus(2);
            break;
          case 7:
            return getStatus(3);
            break;
          case 8:
          case 9:
            return getStatus(4);
            break;
        }
      }
    }
  }

  const getFreelancer = () => {
    APIManager.getAPI(getFreelancerUser + "/" + localStorage.getItem('userId')).then((response) => {
      if (response && response.status == 200) {
        setfreelancerCategory(response.data[0].freelancerCategory);
        getprojectlist(response.data[0].freelancerCategory);
      }
    });
  };

  function getprojectlist(catID) {
    const id = localStorage.getItem("userId");
    APIManager.getAPI(freelancerproject + id).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let object = {
          value: "00000000-0000-0000-0000-000000000000",
          label: "All Projects",
        }
        list.push(object);
        response.data.map((item) => {
          let object = {
            label: item.projectName,
            value: item.projectId
          }
          list.push(object)
        })
        setprojectList(list);
        // setprojectseleted(list[0].value);
        setprojectseleted(
          location.state ? location.state.projectId : list[0].value
        );
        let rowData = {
          projectId: list[0]?.value,
          UserId: localStorage.getItem('userId'),
          startDate: head1,
          endDate: head2,
          IsWriter: catID
        }
        freelancersrequirement(rowData, catID)
      }

    })
  }
  return (
    <>
      <div className="portlet work-history-cal cms_border_gray mb-3">
        <div className="work-hisory-select d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center justify-content-start">
            <select
              class=" form-select cms_fs16  ps-3"
              aria-label="Default select"
              value={projectseleted}
              onChange={(item) => {

                setprojectseleted(item.target.value);
                let rowData = {
                  projectId: item.target.value,
                  UserId: localStorage.getItem('userId'),
                  startDate: head1,
                  endDate: head2,
                  IsWriter: freelancerCategory
                }

                freelancersrequirement(rowData, freelancerCategory);


              }}
            >
              {projectList?.map((item) => {

                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div className="d-flex align-items-center  justify-content-end require-cal cms_fs16 cms_fw500 cms_fc_black cms_line-height cms_radius8 cursor-pointer" ref={ref}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-calendar me-1"
            >
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
            <div className="" onClick={() => setOpen(!open)}>
              {monthYear}
            </div>
            {open && (
              <div className="scope-calendar">
                <div id="example-collapse-text-calendar" style={{ right: "0" }}>
                  <Card body className="daterange-picker-card  mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="calendar-col">
                        <Calendar
                          //date={new Date()}
                          maxDate={new Date()}
                          onlyMonthPicker
                          onChange={(e) => {
                            var date1 = e.format().split("/");
                            var daysInMonth = getDaysInMonth(
                              date1[1],
                              date1[0]
                            );
                            let dateObjArr = [
                              new Date(date1[0] + "/01/" + date1[1]),
                              new Date(
                                date1[0] + "/" + daysInMonth + "/" + date1[1]
                              ),
                            ];
                            setMonthYear(
                              setMonths(dateObjArr[0].getMonth() + 1) +
                              " " +
                              date1[1]
                            );
                            setHead1(
                              convertDate(dateObjArr[0].toLocaleDateString())
                            );
                            setHead2(
                              convertDate(dateObjArr[1].toLocaleDateString())
                            );

                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <button
                          className="btn-outline btn btn-primary"
                          onClick={() => {
                            setOpen(false);
                            let rowData = {
                              projectId: projectseleted,
                              UserId: localStorage.getItem('userId'),
                              startDate: head1,
                              endDate: head2,
                              IsWriter: freelancerCategory
                            }
                            freelancersrequirement(rowData, freelancerCategory);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
      </div>

      {/* <div className="d-flex align-items-center justify-content-between p-0"> */}
      <div className="row freelancer-require-card">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 ps-0 freelancer-card require_assign_card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-target"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="6"></circle>
                <circle cx="12" cy="12" r="2"></circle>
              </svg>
            </div>
            <div>
              <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Assigned</label>
              <h3 className="fw-bold cms_fc_black">{assigned}</h3>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 px-1 freelancer-card require_complete_card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <div>
              <label className="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Completed </label>
              <h3 className="fw-bold cms_fc_black">{completed}</h3>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 awaiting-tab freelancer-card">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3 ">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-clock"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
            </div>
            <div>
              <label class="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Awaiting Approval</label>
              <h3 className="fw-bold cms_fc_black">{awaitingApproval}</h3>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 pe-0 ps-1 require_rework_card ">
          <div className="raise-tab d-flex align-items-center jusify-content-between cms_border_gray bg-white w-100 p-3 ">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-repeat"
              >
                <polyline points="17 1 21 5 17 9"></polyline>
                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                <polyline points="7 23 3 19 7 15"></polyline>
                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
              </svg>
            </div>
            <div>
              <label class="form-label cms_fs16 cms_fw500 cms_fc_gray cms_line-height">Rework</label>
              <h3 className="fw-bold cms_fc_black">{rework}</h3>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div class="work-history-box px-0 my-3">
        <div class="d-flex align-items-center justify-content-between">
          <div class="px-0 work-history-search w-100">
            <form class="search-box w-100 d-flex">
              <button type="button">
                <i class="fa fa-search"></i>
              </button>
              <input
                value={searchText}
                type="text"
                class="w-100"
                placeholder="Search..."
                name="search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  searchByvalue(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    e.preventDefault();
                  }
                }}
              />
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-12 px-0">
        <Table
          className="portlet table table-hover work-history-table freelancer-require-table  table-responsive"
          columns={projectCols}
          dataSource={projectWorkList}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]} to ${range[1]} of ${total} keywords`,
          }}
        // scroll={{ x: 100 }}
        />
      </div>
    </>
  );
}
export default FreelancersRequirementDetails;
