import APIV1 from "../api/axios";
import { ranksDefault } from "../utility/Constant";

export const getReportingCampaignsCategories = async (projectId) => {
  try {
    const response = await APIV1(
      "get",
      `/rankingsv2/get_campaigns_and_unique_categories/${projectId}/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRankingTabsData = async (
  campaignId,
  dateRange,
  rankingOption,
  category,
  type,
  isCompare,
  isCustom,
  custom_all_date_ranges
) => {
  const payload = {
    date_range: dateRange,
    config_id: campaignId,
    ranking_end_range: rankingOption,
    category: category,
    types: type,
    custom_all_date_ranges
  };
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/${
        isCompare || isCustom
          ? "get_head_torso_tails_weekly"
          : "get_head_torso_tails"
      }/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGraphData = async (
  campaignId,
  dateRange,
  rankingOption,
  category,
  type,
  searchVolumeType
) => {
  const payload = {
    date_range: dateRange,
    config_id: campaignId,
    ranking_end_range: rankingOption,
    category: category,
    types: type,
    searchvolumetype: searchVolumeType,
  };
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/${
        rankingOption === "100"
          ? "get_data_from_custom_buckets/"
          : "overall_rank_graph_and_tabular_data/"
      }`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCompareGraphData = async (
  campaignId,
  dateRange,
  rankingOption,
  category,
  type,
  searchVolumeType
) => {
  const payload = {
    date_range: dateRange,
    config_id: campaignId,
    ranking_end_range: rankingOption,
    category: category,
    types: type,
    searchvolumetype: searchVolumeType,
  };
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/${
        rankingOption === "100"
          ? "get_data_from_custom_buckets_weekly/"
          : "overall_rank_graph_and_tabular_data_weekly/"
      }`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCustomGraphData = async (
  campaignId,
  dateRange,
  rankingOption,
  category,
  type,
  searchVolumeType
) => {
  try {
    const payload = {
      date_range: dateRange,
      config_id: campaignId,
      ranking_end_range: rankingOption,
      category: category,
      types: type,
      searchvolumetype: searchVolumeType,
    };
    const response = await APIV1(
      "post",
      `/rankingsv2/${
        rankingOption === "100"
          ? "get_data_from_custom_buckets_timely/"
          : "timely_graph_and_tabular_data/"
      }`,
      payload
    );
    return response;
  } catch (error) {}
};

export const getKeywords = async (
  campaignId,
  dateRange,
  rankingOption,
  category,
  type,
  searchVolumeType,
  bucket,
  isCompare,
  isCustom
) => {
  const payload = {
    date_range: dateRange,
    config_id: campaignId,
    ranking_end_range: rankingOption,
    category: category,
    types: type,
    searchvolumetype: searchVolumeType,
    selected_custom_bucket: bucket,
  };
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/${
        isCompare
          ? "my_ranking_keywords_weekly_data"
          : isCustom
          ? "my_ranking_keywords_timely_data"
          : "my_ranking_keywords"
      }/`,
      payload
    );
    if (isCompare) {
      const formattedData = response.data.map((data) => {
        if (data.latest_week_rank === ranksDefault.NOT_RANKED) {
          return { ...data, change: "Lost" };
        } else if (
          data.previous_week_rank === ranksDefault.NOT_RANKED &&
          data.latest_week_rank !== ranksDefault.NOT_RANKED
        ) {
          return { ...data, change: "New" };
        } else {
          return data
        }
      });
      return { ...response, data: formattedData };
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getExportKeywords = async (
  campaignId,
  dateRange,
  category,
  type
) => {
  const payload = {
    date_range: dateRange,
    config_id: campaignId,
    category: category,
    types: type,
  };
  try {
    const response = await APIV1(
      "post",
      "/rankingsv2/my_rank_and_competitor_rank_data/",
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getKeywordsFile = async (
  config_id,
  date_range,
  category,
  types,
  name_of_columns,
  name_of_competitors,
  responseType
) => {
  try {
    const response = await APIV1(
      "post",
      "/rankingsv2/export_rank_table_data/",
      {
        config_id,
        date_range,
        category,
        types,
        name_of_columns,
        name_of_competitors,
        responseType
      }
    );
    return response;
  } catch (error) {
    throw error
  }
};
