import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors";
import { textStyles } from "../../../styles/font";

export const main = css({
  padding: "2rem 1.5rem",
  ".ant-table-filter-column": {
    justifyContent: "flex-start",
    columnGap: "0.5rem",
    alignItems: "center",
  },
  ".ranking-table-design .ant-pagination-item, .latest-table .ant-pagination-item":
    {
      minWidth: "30px",
      height: " 30px",
      border: 0,
    },
  ".ant-pagination-item-active": {
    background: "#E2E8F0",
  },
  ".ant-pagination-item": {
    border: 0,
    ...textStyles.s3,
    lineHeight: "24px !important",
  },
  ".ant-pagination-item-ellipsis": {
    height: "19px",
  },
  ".ant-table-column-title": {
    flex: "none",
  },
  ".ant-table-column-sorters": {
    justifyContent: "flex-start",
    columnGap: "1rem",
  },
  ".ant-table-filter-trigger": {
    margin: 0,
  },
  ".ant-table-column-sorter": {
    marginLeft: 0,
  },
  ".ant-table": {
    padding: 0,
    border: `1px solid #E2E8F0`,
    borderRadius: `0.375rem 0.375rem 0rem 0rem`,
  },
  ".ant-table-tbody > tr > td": {
    borderBottom: "none",
  },
  ".ant-table-filter-dropdown-search": {
    padding: 0,
  },
  ".ant-table-filter-dropdown": {
    minWidth: "5rem",
  },
  ".ant-input-affix-wrapper": {
    width: "19.4375rem",
    borderTopLeftRadius: "0.38rem",
    borderTopRightRadius: "0.38rem",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: "flex",
    flexDirection: "row-reverse",
  },
  ".ant-input-affix-wrapper > .ant-input": {
    ...textStyles.b3,
  },
  "ant-dropdown-menu-item": {
    padding: "0.38rem 0.5rem",
  },
  ".ant-checkbox-wrapper .ant-checkbox-inner": {
    height: "1rem",
    width: "1rem",
  },
  ".ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span":
    {
      ...textStyles.b3,
      paddingLeft: "0.5rem",
      color: Colors.neutral[900],
    },
  ".ant-table-filter-dropdown-btns .ant-btn-link[disabled]": {
    ...textStyles.s2,
    border: "none",
    backgroundColor: "transparent",
    color: `${Colors.error[800]} !important`,
    border: "none !important",
    ":hover": {
      backgroundColor: "transparent",
      color: `${Colors.error[800]} !important`,
      border: "none !important",
    },
  },
  " .ant-btn-sm": {
    ...textStyles.s2,
    border: "none !important",
  },
  ".ant-table-filter-dropdown-btns .ant-btn-primary": {
    color: Colors.plainWhite,
    backgroundColor: `${Colors.primary[500]} !important`,
    borderRadius: "0.38rem",
  },
  ".ant-checkbox-checked .ant-checkbox-inner::after": {
    height: "0.65rem",
    width: "0.35rem",
    transform: `rotate(45deg) scale(1) translate(-60%, -60%)`,
  },
});

export const buttonGroup = css({
  display: "flex",
  columnGap: "0.75rem",
});

export const header = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginBottom: "2rem",
});

export const buttonContainer = css({
  display: "flex",
  justifyContent: "flex-end",
});

export const actionIconContainer = css({
  display: "flex",
  columnGap: "0.75rem",
  width: "100%",
  justifyContent: "center",
});

export const actionIcon = css({
  height: "1.5rem",
  width: "1.5rem",
  cursor: "pointer",
});

export const searchIcon = (isFiltered) =>
  css({
    height: "1.125rem",
    width: "1.125rem",
    cursor: "pointer",
    color: isFiltered ? Colors.primary[400] : Colors.black,
    strokeWidth: 2,
    float: "left",
  });

export const searchInput = css({
  ...textStyles.b3,
  width: "18.8125rem",
  border: `1px solid ${Colors.neutral[800]}`,
  borderBottomLeftRadius: "0.38rem !important",
  borderBottomRightRadius: "0.38rem !important",
  display: "flex",
  flexDirection: "row !important",
});

export const seaarchIcon = css({
  height: "1.125rem",
  width: "1.125rem",
  color: Colors.black,
});

export const keyword = css({
  ...textStyles.b1,
  color: Colors.neutral[900],
});

export const empty = css({
  ...textStyles.h6,
  color: Colors.neutral[600],
  textAlign: "center",
  minHeight: "calc(100vh - 32rem)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const modalDescription = css({
  ...textStyles.b1,
  color: Colors.neutral[600],
});

export const redBold = css({
  ...textStyles.s1,
  color: Colors.error[800],
});

export const bold = css({
  ...textStyles.s1,
  color: Colors.neutral[600],
});

export const secondaryButton = css({
  backgroundColor: "white !important",
  color: Colors.primary[800],
  borderColor: Colors.primary[700],
});

export const secondaryButtonDanger = css({
  backgroundColor: "white !important",
  color: Colors.error[800],
  borderColor: Colors.error[800],
});

export const filterDwopdownWidth = css({
  width: "19.4375rem !important",
});

export const filterDropdownContainer = css({
  position: "relative",
});
