/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { graphColors } from "../../../../utility/Constant";

const LineChart = () => {
  const chart = useRef(null);
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    series: graphData.series,
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: graphColors,
      legend: {
        show: true,
        offsetY: 6,
        markers: {
          width: 3,
          height: 17,
          radius: 6,
          // customHTML: undefined,
          // onClick: undefined,
          // offsetX: 0,
          // offsetY: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 6,
      },
      stroke: {
        curve: "straight",
        width: 3,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        column: {
          colors: undefined, // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        title: {
          text: "No. of Keywords",
          rotate: -90,
          offsetX: -4,
          offsetY: 0,
          style: {
            fontSize: "12px",
            // fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 500,
          },
        },
      },
      xaxis: {
        categories: graphData.xAxisCategories,
      },
    },
  });

  useEffect(() => {
    if (graphData.active !== -1) {
      setData((prev) => {
        return {
          ...prev,
          series: [graphData.series[graphData.active]],
          options: {
            ...prev.options,
            xaxis: { categories: graphData.xAxisCategories },
            colors:[graphColors[graphData.active]]
          },
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          series: graphData.series,
          options: {
            ...prev.options,
            xaxis: { categories: graphData.xAxisCategories },
            colors: graphColors,
          },
        };
      });
    }
  }, [graphData.active, graphData.series]);

  return (
    <div css={styles.main}>
      <Chart
        id="LINE"
        options={data.options}
        series={data.series}
        type="line"
        width="100%"
        height={"353px"}
        ref={chart}
      />
    </div>
  );
};

export default LineChart;
