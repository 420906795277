import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import {
    calanderDate,
    dateConverter,
    downloadPDFfile,
    exportToEXCEL,
    getBase64path,
    SiteUpTimeTypeInt,
    fixTwoPlaceDecimal,
    getCheckPermission,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
    createSiteUpchart,
    getSiteUp,
    getsiteupgetfielddata,
    getSiteUptimeMonitorGetConfig,
    getSiteUpTimeTable,
    getwebsitedata,
    moduleSiteUptime,
    sendEmailApi,
} from "../utility/Constant";
import {
    mmDDyyyyFormateDateSet,
    formateDateIntoDatemonth,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import Loading from "../utility/Loading";
import { PDFExport } from "@progress/kendo-react-pdf";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import NotFound from "../NotFound";
import { Link } from "react-router-dom";

function SiteUptimeMonitorDoc() {
    const [options, setObject] = useState({});
    const [series, setSeries] = useState([]);
    const applyBtn = useRef(null);
    const [teamlist, setteamList] = useState([]);
    const [teamcol, setteamcol] = useState([]);
    const [websitelist, setwebsitelist] = useState([]);
    const [websitecolumns, setwebsitecolumns] = useState([]);
    const [exportwebiste, setexportwebiste] = useState([]);
    const [exportoverall, setexportoverall] = useState([]);
    const [selectedExportList, setSelectedExportList] = useState([]);
    const [selectedExportList1, setSelectedExportList1] = useState([]);
    const [exportList, setExportList] = useState([]);
    const [displayTable, setDisplayTable] = useState(false);
    const [noContent, setNoContent] = useState(false);
    const [noConfig, setNoConfig] = useState(true);
    const [Sender, setSender] = useState("");
    const [Receiver, setReceiver] = useState("");
    const [subject, setSubject] = useState("Site Uptime Reports");
    const [EmailMessage, setEmailMessage] = useState("");
    const [EmailMessage1, setEmailMessage1] = useState("");
    const [MessageReceiver, setMessageReceiver] = useState("");
    const [MessageSubject, setMessageSubject] = useState("");
    const [Message, setMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(true);

    useEffect(() => {
        setNotFound(getCheckPermission("site_uptime_monitor", "reports"));
        let uid = localStorage.getItem("UserEmail");
        localStorage.setItem("isCompareVar", false);
        setSender(uid);
        var UserId = uid.toString();
        var message =
            UserId +
            " " +
            "has shared the Site UpTime Reports with you. Please find the attachment below.";
        setEmailMessage(message);
        setEmailMessage1(message);
        var columns = [
            {
                title: "SEO Fields",
                dataIndex: "seo_filed",
                key: "seo_filed",
            },

            {
                title: "Total Passed",
                dataIndex: "total_passed",
                key: "total_passed",
            },

            {
                title: " Total Fail ",
                dataIndex: "total_fail",
                key: "total_fail",
            },
            {
                title: "Overall Result",
                dataIndex: "overall_result2",
                key: "overall_result2",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.overall_result2, b.overall_result2),
                    multiple: 3,
                },
                render: (val) => (
                    <div className="d-flex justify-content-around align-items-center">
                        {val != null ? (
                            <span> Fail &nbsp;<i className="fa fa-times" style={{ color: "red" }}></i></span>
                        ) : (
                            <span> Pass &nbsp;<i className="fa fa-check" style={{ color: "green" }}></i></span>
                        )}
                    </div>
                ),


            },
        ];
        var websitecolumn = [
            {
                title: "Server Down",
                dataIndex: "down",
                key: "down",
            },
            {
                title: "Server Up",
                dataIndex: "up",
                key: "up",
            },
            {
                title: "Duration",
                dataIndex: "duration",
                key: "duration",
            },
        ];
        setteamcol(columns);
        setwebsitecolumns(websitecolumn);
        setDisplayTable(true);
    }, []);

    const websitetableSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const list = [];
            selectedRows.forEach((item) => {
                let obj = {
                    down: item.down,
                    up: item.up,
                    duration: item.duration,
                };
                list.push(obj);
            });
            setSelectedExportList(list);
        },
    };
    function gettableseverupdown(data) {
        APIManager.postAPI(getwebsitedata, data, null, true).then(
            (response) => {
                if (response.data.length > 0) {
                    const list = [];
                    let i = 0;
                    response.data.forEach((item) => {
                        let obj = {
                            key: i += 1,
                            down: item.serverDown ? dateConverter(item.serverDown) : '-',
                            up: item.serverUp ? dateConverter(item.serverUp) : '-',
                            duration: item.duration ? item.duration : 0,
                        };
                        list.push(obj);
                        exportList.push({
                            down: item.serverDown ? dateConverter(item.serverDown) : '-',
                            up: item.serverUp ? dateConverter(item.serverUp) : '-',
                            duration: item.duration ? item.duration : 0,
                        });
                    });
                    setwebsitelist(list);
                    setexportwebiste(exportList);
                }
            }
        );
    }

    const ref = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const [values, setValues] = useState([
        new DateObject().subtract(7, "days"),
        new DateObject().add(-1, "days"),
    ]);
    const [headValues, setHeadValues] = useState([
        new DateObject().subtract(7, "days"),
        new DateObject().add(-1, "days"),
    ]);
    const [open, setOpen] = useState(false);

    const [head1, setHead1] = useState(headValues[0].format());
    const [head2, setHead2] = useState(headValues[1].format());

    function setheadvalues() {
        setHead1(values[0].format());
        setHead2(values[1].format());
    }

    useEffect(() => {
        setHeadValues([
            new DateObject().subtract(7, "days"),
            new DateObject().add(-1, "days"),
        ])
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
            var tag = localStorage.getItem("isCompareVar");
            if (tag === "false") {
                if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
                    document
                        .getElementsByClassName("rdrDateDisplayWrapper")[0]
                        .children[1].classList.add("display-none");
            }
        };
    }, [open]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);

            var tag = localStorage.getItem("isCompareVar");
            if (tag === "false") {
                if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
                    document
                        .getElementsByClassName("rdrDateDisplayWrapper")[0]
                        .children[1].classList.add("display-none");
            }
        };
    }, [open]);

    const [totalExecuted, settotalExecuted] = useState("");
    const [totalPassed, settotalPassed] = useState("");
    const [totalFailed, settotalFailed] = useState("");
    const [overallResult, setoverallResult] = useState("");

    useEffect(() => {
        getSiteUptimeConfig();
        let getdata = {
            projectId: localStorage.getItem("projectGId"),
            startDate: mmDDyyyyFormateDateSet(head1),
            endDate: mmDDyyyyFormateDateSet(head2),
        };
        GetSiteup(getdata);
        GetSiteupchart(getdata);
        getDataForTable(getdata);
        gettableseverupdown(getdata);
    }, []);

    function isValidEmailData() {
        let isvalid = true;
        if (Receiver.trim() === "") {
            setMessageReceiver("Receiver Email id is required!");
            isvalid = false;
        } else {
            setMessageReceiver("");
        }
        if (subject.trim() === "") {
            setMessageSubject("Subject is required!");
            isvalid = false;
        } else {
            setMessageSubject("");
        }
        if (EmailMessage.trim() === "") {
            setMessage("Message is required!");
            isvalid = false;
        } else {
            setMessage("");
        }
        return isvalid;
    }

    async function sendEmail() {
        let theme = 'light';
        if (
            document.querySelector("body").classList.contains("theme-dark")
        ) {
            theme = 'dark';
            document.querySelector("body").classList.remove("theme-dark");
            document.querySelector("body").classList.add("theme-light");
        }
        let fileBase64 = await getBase64path(image)
        let rowData = {
            "from": Sender.trim(),
            "to": Receiver.trim(),
            "subject": subject.trim(),
            "body": EmailMessage.trim(),
            "base64Data": fileBase64,
            "filename": moduleSiteUptime
        }
        APIManager.postAPI(sendEmailApi, rowData, null, true).then(
            (response) => {
                if (response?.status === 200) {
                    applyBtn.current.click();
                    setReceiver("");
                    setSubject("Site UpTime Reports");
                    setEmailMessage(EmailMessage1);
                    if (theme === 'dark') {
                        document.querySelector("body").classList.remove("theme-light");
                        document.querySelector("body").classList.add("theme-dark");
                    }
                }
            }
        );
    }

    const overalltableSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const list = [];
            selectedRows.forEach((item) => {
                let obj = {
                    seo_filed: GetTypeName(item.seo_filed.props.children),
                    total_passed: item.total_passed,
                    total_fail: item.total_fail,
                    overall_result: item.overall_result != null ? 'Fail' : 'Pass',
                };
                list.push(obj);
            });
            setSelectedExportList1(list);
        },
    };

    function getDataForTable(data) {
        let getdata = data;
        setteamList("");
        APIManager.postAPI(
            getSiteUpTimeTable, getdata, null, true
        ).then((response) => {
            if (response && response.status === 200) {
                if (response.data.length > 0) {
                    let obj = [];
                    let list = [];
                    let i = 0;
                    response.data.forEach((item) => {
                        obj.push({
                            key: i += 1,
                            seo_filed: (
                                <Link
                                    to={"/main/siteup_monitor_testdata"}
                                    state={{ item, getdata }}
                                >
                                    {GetTypeName(item.field)}
                                </Link>
                            ),
                            total_passed: GetTypeResult(item, 1),
                            total_fail: GetTypeResult(item, 0),
                            overall_result: (
                                <div className="d-flex justify-content-around align-items-center">
                                    {GetTypeResult(item, 0) != null ? (
                                        <span> Fail &nbsp;<i className="fa fa-times" style={{ color: "red" }}></i></span>
                                    ) : (
                                        <span> Pass &nbsp;<i className="fa fa-check" style={{ color: "green" }}></i></span>
                                    )}
                                </div>
                            ),
                            overall_result2: GetTypeResult(item, 0)
                        });

                        let obj1 = {
                            seo_filed: GetTypeName(item.field),
                            total_passed: GetTypeResult(item, 1),
                            total_fail: GetTypeResult(item, 0),
                            overall_result: GetTypeResult(item, 0) != null ? 'Fail' : 'Pass',
                        };
                        list.push(obj1);
                    });
                    setteamList(obj);
                    setexportoverall(list);
                }
            }
        });
    }

    function GetTypeName(val) {
        var finalVal = val;
        SiteUpTimeTypeInt.forEach((item) => {
            if (item.value === val) {
                finalVal = item.label;
            }
        });
        return finalVal;
    }

    function GetTypeResult(val, type) {
        var finalVal = null;
        var val0 = 0;
        var val1 = 1;
        var keys = Object.keys(val);
        keys.forEach((item) => {
            switch (type) {
                case 0:
                    if (item === "0") {
                        finalVal = val[val0];
                    }
                    break;

                case 1:
                    if (item === "1") {
                        finalVal = val[val1];
                    }
                    break;
                default:
                    break;
            }
        });
        return finalVal;
    }

    function getSiteUptimeConfig() {
        APIManager.getAPI(
            getSiteUptimeMonitorGetConfig + localStorage.getItem("projectGId")
        ).then((response) => {
            if (response && response.status === 200) {
                if (response?.data) {
                    setNoConfig(false);
                    setNoContent(true);
                    GetfieldDataGet();
                }
            }
        });
    }

    function isDataAvailable(data) {
        return data ? (
            <>
                <Tooltip title={data}>
                    <span>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                </Tooltip>
            </>
        ) : (
            <>
                <i class="fa fa-times"></i>
            </>
        );
    }

    function GetfieldDataGet() {
        const url = getsiteupgetfielddata + localStorage.getItem("projectGId");
        APIManager.getAPI(url, true).then((response) => {
            if (response.status === 200 && response) {
                let jsonData = response?.data;
                let list = [];
                let list2 = [];
                let i = 0;
                setNoContent(jsonData?.length);
                jsonData.forEach((data) => {
                    list.push({
                        key: (i += 1),
                        fields: data.URL,
                        MetaTitle: isDataAvailable(data.MetaTitle),
                        MetaDescription: isDataAvailable(data.RedirectionRedirection),
                        Canonicalonthepage: isDataAvailable(data.CanonicalUrl),
                        NoFollow: isDataAvailable(data.NoFollow),
                        NoIndex: isDataAvailable(data.NoIndex),
                        Redirection: isDataAvailable(data.Redirection),
                        StatusCode: isDataAvailable(data.StatusCode),
                        MetaTitleExp: data.MetaTitle,
                        MetaDescriptionExp: data.RedirectionRedirection,
                        CanonicalonthepageExp: data.CanonicalUrl,
                        NoFollowExp: data.NoFollow,
                        NoIndexExp: data.NoIndex,
                        RedirectionExp: data.Redirection,
                        StatusCodeExp: data.StatusCode,
                    });

                    let obj = {
                        fields: data.URL,
                        MetaTitle: data.MetaTitle,
                        MetaDescription: data.RedirectionRedirection,
                        Canonicalonthepage: data.CanonicalUrl,
                        NoFollow: data.NoFollow,
                        NoIndex: data.NoIndex,
                        Redirection: data.Redirection,
                        StatusCode: data.StatusCode,
                    };
                    list2.push(obj);
                });
                setExportList(list2);
            }
        });
    }

    function GetSiteup(data) {
        let getdata = data;
        APIManager.postAPI(getSiteUp, getdata, null, true).then((response) => {
            if (response.status === 200) {
                if (response.data.length !== 0) {
                    settotalExecuted(response?.data[0].totalTestExecuted ? response?.data[0].totalTestExecuted : "0");
                    settotalPassed(response?.data[0].totalPassed);
                    settotalFailed(response?.data[0].totalFailed);
                    setoverallResult(
                        ((response?.data[0].totalPassed /
                            (response?.data[0].totalPassed + response?.data[0].totalFailed)) * 100).toFixed(2));
                }
                else {
                    settotalExecuted(0);
                    settotalPassed(0);
                    settotalFailed(0);
                    setoverallResult(0);
                }
            }
        });
    }

    function GetSiteupchart(datalist) {
        APIManager.postAPI(createSiteUpchart, datalist, null, true).then(
            (response) => {
                if (response.status === 200 && response?.data) {
                    let dataForGraph = [];
                    let graphData = [];
                    let graphSeries = [];
                    const startDate = datalist.startDate;
                    const endDate = datalist.endDate;
                    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
                    const diffDays = 0 | (diffTime / 864e5);
                    for (let i = 0; i <= diffDays; i++) {
                        graphSeries.push(
                            formateDateIntoDatemonth(
                                new Date(new Date(startDate).getTime() + i * 864e5)
                            )
                        );
                    }
                    if (response.data.length) {
                        graphSeries.forEach((d) => {
                            if (response.data.some(x => d === formateDateIntoDatemonth(new Date(new Date(x.createdAt).getTime() + 0 * 864e5)))) {
                                response.data.forEach((j) => {
                                    if (d === formateDateIntoDatemonth(new Date(new Date(j.createdAt).getTime() + 0 * 864e5))) {
                                        graphData.push(j.overall == null ? 0 : j.overall)
                                    }
                                });
                            } else {
                                graphData.push(0);
                            }
                        });
                        let maxValue = Math.max(...graphData) * 0.3 + Math.max(...graphData); //imp
                        setChartObject(graphSeries, maxValue)
                        dataForGraph.push({
                            name: "UpTime",
                            data: graphData,
                        });
                        setSeries(dataForGraph);
                    }
                    else {
                        setChartObject(graphSeries)
                        graphSeries.forEach((d) => {
                            graphData.push(0);
                        });
                        dataForGraph.push({
                            name: "UpTime",
                            data: graphData,
                        });
                        setSeries(dataForGraph);
                    }
                }
            }
        );
    }

    function setChartObject(graphSeries, maxValue) {
        let optiondate = {
            chart: {
                id: "basic-bar",
            },
            xaxis: {
                tickPlacement: "on",
                categories: graphSeries,
                title: {
                    text: "Date",
                    style: {
                        color: "#000",
                    },
                },
            },
            yaxis: {
                logBase: 2,
                tickAmount: 5,
                min: 0,
                max: maxValue ? maxValue : "0",
                title: {
                    text: "Average Uptime",
                    style: {
                        color: "#000",
                    },
                },
                labels: {
                    formatter: function (value) {
                        return fixTwoPlaceDecimal(value) + "%";
                    }
                },
            },
        };
        setObject(optiondate);
    }

    const [SiteUptimeMonitor, setSiteUptimeMonitor] = useState(false);
    const image = React.useRef(null);
    const pdfExportComponent = React.useRef(null);
    const [state, setState] = useState({
        selection1: {
            startDate: addDays(new Date(), -7),
            endDate: addDays(new Date(), -1),
            key: "selection1",
        },
        selection2: {
            startDate: addDays(new Date(), -7),
            endDate: addDays(new Date(), -1),
            key: "selection2",
        },
    });

    return (
        <div className="siteuptime_main">
            <>
                {!notFound ? (
                    <>
                        <NotFound />
                    </>
                ) : (
                    <>
                        {noConfig ? (
                            <>
                                <div class=" message text-center ">
                                    <h4>
                                        The Site Uptime configuration is not set. <br />
                                        Please contact your administrator to set configuration.
                                    </h4>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <Tabs>
                                        <div className="portlet top_header">
                                            <div className="row portlet-header-bordered my-4">
                                                <div className="col-sm-4 col-md-6 col-12">
                                                    <TabList className="nav nav-lines">
                                                        <Tab
                                                            className={
                                                                SiteUptimeMonitor
                                                                    ? "nav-item nav-link "
                                                                    : "nav-item nav-link  active"
                                                            }
                                                            onClick={() => {
                                                                setSiteUptimeMonitor(false);
                                                            }}
                                                        >
                                                            Site Uptime Monitor
                                                        </Tab>
                                                    </TabList>
                                                </div>
                                                <div ref={ref}
                                                    class="col-sm-8 col-md-6 col-12 calendar-main calender_siteuptime  ga-calender">
                                                    <div className=" calender_toup  calender_responsive_design  d-block d-sm-flex  justify-content-start justify-content-sm-end mt-3 mt-sm-0">
                                                        <div className="add-new-btnw text-left mt-3 mt-sm-0">
                                                            <button
                                                                className="btn btn-primary"
                                                                style={{ width: "250px" }}
                                                                onClick={() => setOpen(!open)}
                                                            >
                                                                {calanderDate(head1, head2)}
                                                            </button>
                                                        </div>
                                                        {open && (
                                                            <div id="example-collapse-text-calendar">
                                                                <Card body className="daterange-picker-card  mt-3">
                                                                    <div className="d-flex calendar-flex">
                                                                        <div className="calendar-col">
                                                                            <DateRangePicker
                                                                                onChange={(item) => {
                                                                                    let isCompareVarNew = localStorage.getItem("isCompareVar");
                                                                                    if (isCompareVarNew === "false") {
                                                                                        let selection23 = {};
                                                                                        if (item?.selection1) {
                                                                                            selection23 = {
                                                                                                selection2: {
                                                                                                    startDate: item?.selection1?.startDate,
                                                                                                    endDate: item?.selection1?.endDate,
                                                                                                    key: "selection2",
                                                                                                },
                                                                                            };

                                                                                            setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                                                                            setHead1(new DateObject(item?.selection1?.startDate).format());
                                                                                            setHead2(new DateObject(item?.selection1?.endDate).format());

                                                                                        } else if (item?.selection2) {
                                                                                            selection23 = {
                                                                                                selection1: {
                                                                                                    startDate: item?.selection2?.startDate,
                                                                                                    endDate: item?.selection2?.endDate,
                                                                                                    key: "selection1",
                                                                                                },
                                                                                            };

                                                                                            setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                                                                            setHead1(new DateObject(item?.selection2?.startDate).format());
                                                                                            setHead2(new DateObject(item?.selection2?.endDate).format());
                                                                                        }
                                                                                        setState({
                                                                                            ...state,
                                                                                            ...item,
                                                                                            ...selection23,
                                                                                        });
                                                                                    }
                                                                                }}

                                                                                showSelectionPreview={true}
                                                                                moveRangeOnFirstSelection={false}
                                                                                months={1}
                                                                                ranges={[state?.selection1, state?.selection2]}
                                                                                direction="horizontal"
                                                                                ariaLabels={{
                                                                                    dateInput: {
                                                                                        selection1: {
                                                                                            startDate: "start date input of selction 1",
                                                                                            endDate: "end date input of selction 1",
                                                                                        },
                                                                                        selection2: {
                                                                                            startDate: "start date input of selction 2",
                                                                                            endDate: "end date input of selction 2",
                                                                                        },
                                                                                    },

                                                                                    monthPicker: "month picker",
                                                                                    yearPicker: "year picker",
                                                                                    prevButton: "previous month button",
                                                                                    nextButton: "next month button",
                                                                                }}
                                                                                maxDate={
                                                                                    new Date(
                                                                                        new Date().getFullYear(),
                                                                                        new Date().getMonth(),
                                                                                        new Date().getDate() - 1
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="ms-3 mt-3 mt-lg-0 text-center">
                                                                            <button
                                                                                onClick={() => {
                                                                                    let dates = [];
                                                                                    dates.push(values[0]);
                                                                                    dates.push(values[1]);
                                                                                    setheadvalues();
                                                                                    setOpen(!open);
                                                                                    let getdata = {
                                                                                        projectId:
                                                                                            localStorage.getItem("projectGId"),
                                                                                        startDate: mmDDyyyyFormateDateSet(head1),
                                                                                        endDate: mmDDyyyyFormateDateSet(head2),
                                                                                    };
                                                                                    GetSiteupchart(getdata);
                                                                                    GetSiteup(getdata);
                                                                                    getDataForTable(getdata);
                                                                                    gettableseverupdown(getdata);
                                                                                }}
                                                                                className="outline-btn btn btn-primary"
                                                                            >
                                                                                Apply
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        )}
                                                        <div className="d-flex 3-icons">
                                                            <div className="ms-3  ">
                                                                <a
                                                                    className="share  bar-link-icon"
                                                                    style={{ color: "black" }}
                                                                >
                                                                    <div class="dropdown mt-3 mt-sm-0">
                                                                        <a
                                                                            type="button"
                                                                            ref={applyBtn}
                                                                            id="dropdownMenuButton"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                                                                        </a>
                                                                        <form class="dropdown-menu filter-dropdown custome_dropdownmenu siteup_time_menu p-0">
                                                                            <div className="filter-popup dropdown_pop_filter">
                                                                                <div className="portlet px-2 py-2">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-start">
                                                                                            <label class="my-2 pt-2">From</label>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            {" "}
                                                                                            <input
                                                                                                type="text"
                                                                                                required
                                                                                                placeholder="Sender Email Id"
                                                                                                value={Sender}
                                                                                                className="w-100 form-control my-2"
                                                                                                name="Sender"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-12 text-start">
                                                                                            <label class="my-2 pt-2">To</label>
                                                                                            <label class="field-required"> * </label>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            {" "}
                                                                                            <input
                                                                                                type="text"
                                                                                                required
                                                                                                placeholder="Recipient Email Id"
                                                                                                className="w-100 form-control my-2"
                                                                                                value={Receiver}
                                                                                                name="Receiver"
                                                                                                onChange={(text) =>
                                                                                                    setReceiver(text.target.value)
                                                                                                }
                                                                                            />
                                                                                            <div style={{ color: "red" }}>
                                                                                                {MessageReceiver}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-12 text-start">
                                                                                            <label class="my-2 pt-2">Subject</label>
                                                                                            <label class="field-required"> * </label>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <input
                                                                                                type="text"
                                                                                                required
                                                                                                placeholder="Subject"
                                                                                                value={subject}
                                                                                                onChange={(text) => {
                                                                                                    setSubject(text.target.value);
                                                                                                }}
                                                                                                className="w-100 form-control my-2"
                                                                                                name="Subject"
                                                                                            />
                                                                                            <div style={{ color: "red" }}>
                                                                                                {MessageSubject}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-12 text-start">
                                                                                            <label class="my-2 pt-2">Message</label>
                                                                                            <label class="field-required"> * </label>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <textarea
                                                                                                name=""
                                                                                                id=""
                                                                                                cols="80"
                                                                                                rows="7"
                                                                                                className="w-100 form-control my-2 gscmsg"
                                                                                                value={EmailMessage}
                                                                                                onChange={(text) => {
                                                                                                    setEmailMessage(text.target.value);
                                                                                                }}
                                                                                            ></textarea>
                                                                                            <div style={{ color: "red" }}>
                                                                                                {Message}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-12"></div>
                                                                                        <div className="col-12 my-2">
                                                                                            <button
                                                                                                type="button"
                                                                                                class="btn btn-primary"
                                                                                                style={{
                                                                                                    width: 70 + "px",
                                                                                                    height: 30 + "px",
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    if (isValidEmailData()) {
                                                                                                        sendEmail();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                Send
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </a>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12 download-filter">
                                                                    <span
                                                                        class="dropdown mt-3 mt-sm-0"
                                                                        style={{
                                                                            marginRight: 0 + "px",
                                                                            textAlign: "right",
                                                                            display: "block",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            class="outline-btn dropdown-toggle btn btn-primary"
                                                                            type="button"
                                                                            id="dropdownMenuButton"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i
                                                                                class="fa fa-download "
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </button>
                                                                        <div
                                                                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                                                            aria-labelledby="dropdownMenuButton"
                                                                        >
                                                                            <div
                                                                                onClick={async () => {
                                                                                    setLoading(true)
                                                                                    const promise = await downloadPDFfile(image, moduleSiteUptime)
                                                                                    try {
                                                                                        if (promise) {
                                                                                            setLoading(false);
                                                                                        } else {
                                                                                            setLoading(false);
                                                                                        }
                                                                                    } catch (e) {
                                                                                        setLoading(false);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <a class="dropdown-item">PDF</a>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <TabPanel>
                                            <br />
                                            {displayTable && !noContent ? (
                                                <>
                                                    <PDFExport
                                                        paperSize="A1"
                                                        margin="2cm"
                                                        fileName={moduleSiteUptime}
                                                        ref={pdfExportComponent}
                                                        forcePageBreak=".page-break"
                                                    >
                                                        <div ref={image} >
                                                            <div className="portlet-header-bordered" >
                                                                <div className="ms-2">
                                                                    <h4 className="total-seo">SEO Uptime</h4>
                                                                </div>
                                                                <div className="site-uptime-box common-mb-24">
                                                                    <div className="row seo_uptime_block">
                                                                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0 siteuptime-col">
                                                                            <div className="box-outer bg-light-blue">
                                                                                <h4 className="box-heading font-weight-bolder">
                                                                                    Total Test Executed
                                                                                </h4>
                                                                                <p className="box-value">{totalExecuted}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0 siteuptime-col">
                                                                            <div className="box-outer bg-light-blue">
                                                                                <h4 className="box-heading font-weight-bolder">
                                                                                    Total Passed
                                                                                </h4>
                                                                                <p className="box-value">{totalPassed}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0 siteuptime-col">
                                                                            <div className="box-outer bg-light-blue">
                                                                                <h4 className="box-heading font-weight-bolder">
                                                                                    Total Failed
                                                                                </h4>
                                                                                <p className="box-value">{totalFailed}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-3 col-sm-6 mb-3 mb-md-0 siteuptime-col">
                                                                            <div className="box-outer bg-light-blue">
                                                                                <h4 className="box-heading font-weight-bolder">
                                                                                    Overall Result
                                                                                </h4>
                                                                                <p className="box-value">{overallResult + '%'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="portlet mt-3">
                                                                <div className="portlet-header portlet-header-bordered">
                                                                    <h3 className="portlet-title">Website Stability</h3>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col-md-12">
                                                                        <div className="rank-track-graph text-end">
                                                                            <div className="rank-track-graph-input-2"></div>
                                                                        </div>
                                                                        <div className="row common-mt-24">
                                                                            <div className="col-md-12"></div>
                                                                            <div className="col-md-1"></div>
                                                                        </div>
                                                                        <div className="col-12 justify-content-center mt-3">
                                                                            {series ? (
                                                                                <Chart
                                                                                    options={options}
                                                                                    series={series}
                                                                                    type="line"
                                                                                    height="300"
                                                                                />
                                                                            ) : (
                                                                                <div>No data</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="portlet">
                                                                <div className="portlet-header portlet-header-bordered">
                                                                    <h3 className="portlet-title">Overall Table</h3>
                                                                </div>
                                                                <div className="portlet-body">
                                                                    <div className="col-md-12 d-flex justify-content-end mt-3">
                                                                        <span class="dropdown">
                                                                            <button
                                                                                class="btn btn-primary dropdown-toggle ms-3"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    exportToEXCEL(
                                                                                        selectedExportList1?.length > 0
                                                                                            ? selectedExportList1
                                                                                            : exportoverall,
                                                                                        "Site Up Time"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Export
                                                                            </button>
                                                                        </span>
                                                                    </div>

                                                                    <div className="col-md-12 overall_table_main">
                                                                        <Table
                                                                            className="table table-bordered  table-hover mt-3"
                                                                            id="datatable-1"
                                                                            columns={teamcol}
                                                                            dataSource={teamlist}
                                                                            rowSelection={{
                                                                                type: "checkbox",
                                                                                ...overalltableSelection,
                                                                            }}
                                                                            pagination={{
                                                                                position: ["bottomRight"],
                                                                                showSizeChanger: true,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="portlet">
                                                                <div className=" portlet-header portlet-header-bordered">
                                                                    <h3 className="portlet-title">Website Table</h3>
                                                                </div>

                                                                <div className="portlet-body websitetable_main">
                                                                    <div className="col-md-12 d-flex justify-content-end mt-3">
                                                                        <span class="dropdown">
                                                                            <button
                                                                                class="btn btn-primary dropdown-toggle ms-3"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    exportToEXCEL(
                                                                                        selectedExportList?.length > 0
                                                                                            ? selectedExportList
                                                                                            : exportwebiste,
                                                                                        "Site Up Time"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Export
                                                                            </button>
                                                                        </span>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <Table
                                                                            className="table table-bordered  table-hover mt-3"
                                                                            id="datatable-1"
                                                                            columns={websitecolumns}
                                                                            dataSource={websitelist}
                                                                            rowSelection={{
                                                                                type: "checkbox",
                                                                                ...websitetableSelection,
                                                                            }}
                                                                            pagination={{
                                                                                position: ["bottomRight"],
                                                                                showSizeChanger: true,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></PDFExport>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {noContent ? (
                                                <>
                                                    <div class=" message text-center ">
                                                        <h4>
                                                            The Site Uptime is yet in progress. <br />
                                                            The status will be visible here once the Site Uptime
                                                            completed.
                                                        </h4>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </>
                        )}
                        <Loading isLoading={isLoading} />
                    </>
                )}</>
        </div>
    );
}

export default SiteUptimeMonitorDoc;
