import { css } from '@emotion/react'
import { Colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/font';

export const main = css({
    ".___SBaseTrigger_15wp9_gg_": {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
    },
    ".___SOutline_letz7_gg_" : {
        borderColor: `${Colors.neutral[800]} !important`,
        width: "100% !important",
        background: "none !important"
    },
    ".___SBoxInline_ibl27_gg_" : {
        width: "100%",
        background: "none !important"
    },
})

export const inputStyles = css({
    "input": {
        ...textStyles.H1125Medium,
    }
})

export const inputDropdown = (disabled) => {
    return css({
        "button": {
            width: "100% !important",
            borderColor: Colors.neutral[800]
        },
        ...(disabled && {
            pointerEvents: "none",
            opacity: "70%"
        })
    })
}

export const textArea = () => {
    return css({
        "textarea": {
            borderColor: Colors.neutral[800]
        }
    })
}

export const errorMsg = css({
    ...textStyles.b1,
    marginTop: "0.25rem",
    color: Colors.error[800]
})