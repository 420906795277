import { Table } from 'antd';
import React from 'react'
import { useState, useEffect } from 'react';
import { Sorter } from '../../common/Sorter';
import {
    exportToEXCELBandwidth,
    findUnique,
    mmDDyyyyFormateDateSet
} from '../../utility/Globals';
import APIManager from '../../services/APIManager';
import { bandwidthsplit } from '../../utility/Constant';
import FeatherIcon from 'feather-icons-react'
import { DownOutlined, UpOutlined } from "@ant-design/icons";
 
function TeamMember(props) {
    const [teammemberExportList, setteammemberExportList] = useState([]);
    const [teammemberExportList1, setteammemberExportList1] = useState([]);
    const [teammemberTabcol, setteammemberTabcol] = useState([]);
    const [tabteammemberlist, settabteammemberList] = useState([]);

    useEffect(() => {
        let rowdata = {
            StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
            EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
            OrganizationId: localStorage.getItem("organizationId")
        };
        getTableSplit(rowdata);
    }, [props?.dataParentToChild]);

    function getTableSplit(rowdata) {
        const filtername = [];
        const filterdesignation = [];
        const filterreportingmanager = [];
        const filterproject = [];
        const filterproject1 = [];
        let filterreportingmanager1 = []
        APIManager.postAPI(bandwidthsplit, rowdata, null, true).then(
            (response) => {
                if (response && response.status === 200) {
                    const exportList = [];
                    let listData = [];
                    let listData1 = [];
                    let i = 0;

                    const uniqueData = response?.data.filter((item, index, self) =>
                        self.findIndex(obj => (obj.name === item.name && obj.designation === item.designation && obj.reportingManager === item.reportingManager)) === index
                    );

                    uniqueData.forEach(data => {
                        const filteredData = response?.data.filter(item => (item.name === data.name && item.designation === data.designation && item.reportingManager === data.reportingManager));

                        filteredData?.forEach((parent) => {
                            parent['expanded'] = false;
                            const childData = response?.data
                                .filter(child => (child.name === data.name && child.designation === data.designation && child.reportingManager === data.reportingManager))
                                .map(child => ({ ...child, children: [] }));
                            parent['children'] = childData;
                            parent['key'] = i += 1;

                            let filternameobj = {
                                text: parent.name,
                                value: parent.name
                            }
                            filtername.push(filternameobj);
                            filtername.sort((a, b) => a.text.localeCompare(b.text));

                            let filterdesignationobj = {
                                text: parent.designation,
                                value: parent.designation
                            }
                            filterdesignation.push(filterdesignationobj);
                            filterdesignation.sort((a, b) => a.text - b.text);

                            let filterreportingmanagerobj = {
                                text: parent.reportingManager ? parent.reportingManager : "",
                                value: parent.reportingManager ? parent.reportingManager : ""
                            }
                            filterreportingmanager.push(filterreportingmanagerobj)
                            filterreportingmanager.sort((a, b) => a.text - b.text);
                            filterreportingmanager.forEach((item) => {
                                if (item.text !== "") {
                                    filterreportingmanager1.push(item)
                                }
                            });
                            let filterprojectobj = {
                                text: parent.project ? parent.project : "",
                                value: parent.project ? parent.project : ""
                            }
                            filterproject.push(filterprojectobj);
                            filterproject.sort((a, b) => a.text - b.text);
                            filterproject.forEach((item) => {
                                if (item.text !== "") {
                                    filterproject1.push(item)
                                }
                            });
                        });
                        exportList.push({
                            Name: filteredData[0].name,
                            Designation: filteredData[0].designation,
                            ReportingManager: filteredData[0].reportingManager,
                            Project: filteredData[0].project,
                            Allocated: filteredData[0].allocated,
                        });
                        if (filteredData[0]['children'].length > 1) {
                            filteredData[0]['project'] = filteredData[0]['project'] + ' and ' + (filteredData[0]['children'].length - 1) + ' ' + (filteredData[0]['children'].length > 2 ? 'others' : 'other');
                            filteredData[0]['allocated'] = filteredData[0]['children'].reduce((accumulator, object) => {
                                return +(accumulator + object.allocated).toFixed(2);
                            }, 0);
                            filteredData[0]['available'] = (1 - filteredData[0]['allocated']).toFixed(2);
                            filteredData[0]['children']?.forEach((e, index) => {
                                if (index > 0) {
                                    exportList.push({
                                        Name: e.name,
                                        Designation: e.designation,
                                        ReportingManager: e.reportingManager,
                                        Project: e.project,
                                        Allocated: e.allocated,
                                    })
                                }
                                exportList.sort((a, b) => a.Allocated - b.Allocated);
                                e.name = '';
                                e.designation = '';
                                e.reportingManager = '';
                            });
                        }
                        if (filteredData[0]['children'].length === 1)
                            filteredData[0]['children'] = filteredData[0]['children'].slice(1);
                        listData.push(filteredData[0]);
                        listData.sort((a, b) => a.allocated - b.allocated);

                        const mainList = {
                            name: filteredData[0].name,
                            designation: filteredData[0].designation,
                            reportingManager: filteredData[0].reportingManager,
                            project: filteredData[0]['children'].length ? filteredData[0]['children'].length : 1,
                            allocated: filteredData[0].allocated,
                            available: filteredData[0].available
                        }
                        listData1.push(mainList);
                        listData1.sort((a, b) => a.Allocated - b.Allocated);
                    });

                    let columns = [
                        {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            filters: findUnique(filtername, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.name === value;
                            },
                            width: '23%',
                        },
                        {
                            title: "Designation",
                            dataIndex: "designation",
                            key: "designation",
                            filters: findUnique(filterdesignation, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.designation === value;
                            },
                            width: '15%',
                        },
                        {
                            title: "Reporting Manager",
                            dataIndex: "reportingManager",
                            key: "reportingManager",
                            filters: findUnique(filterreportingmanager1, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.reportingManager === value;
                            },
                            width: '20%',
                        },
                        {
                            title: "Project",
                            dataIndex: "project",
                            key: "project",
                            filters: findUnique(filterproject1, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.project === value;
                            },
                            width: '20%',
                        },
                        {
                            title: "Allocate",
                            dataIndex: "allocated",
                            key: "allocated",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.allocated, b.allocated),
                                multiple: 3,
                            },
                            width: '10%',
                        },
                        {
                            title: "Available",
                            dataIndex: "available",
                            key: "available",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.available, b.available),
                                multiple: 3,
                            },
                            width: '10%',
                        },
                    ];
                    setteammemberTabcol(columns);
                    settabteammemberList(listData);
                    setteammemberExportList1(listData1);
                    setteammemberExportList(exportList);
                };
            }
        );
    };

    return (
        <>
            <div className="portlet team-bandwidth mt-4">
                <div className="portlet-header portlet-header-bordered px-4 mx-1">
                    <h3 className="portlet-title ps-0">Team Members Bandwidth Split </h3>
                    <div className="d-flex justify-content-between align-items-center">
                        <button
                            className="btn-outline-export d-flex align-items-center"
                            type="button"
                            onClick={() => {
                                exportToEXCELBandwidth(
                                    teammemberExportList1,
                                    teammemberExportList,
                                    "TeamMember"
                                );
                            }}
                        >
                            <FeatherIcon icon="download" />
                            <span className='ms-2'>Export</span>
                        </button>
                    </div>
                </div>
                <div className="col-md-12 mt-4 px-1">
                    <Table
                        className="table Bandwidth-Split table-hover table-responsive border-0 custome_filter"
                        id="sample"
                        columns={teammemberTabcol}
                        dataSource={tabteammemberlist}
                        expandRowByClick={true}
                        rowClassName={(record) => {
                            return record.expanded ? "active" : "";
                        }}
                        expandable={{
                            onExpandedRowsChange: (data) => {
                                settabteammemberList([]);
                                let list = tabteammemberlist;
                                list.forEach(x => {
                                    x.expanded = data && data.includes(x.key);
                                });
                                settabteammemberList(list);
                            },
                            expandIcon: ({ expanded, onExpand, record }) => {
                                return record.expanded ?
                                    (
                                        record.children.length > 1 ? (<UpOutlined style={{ float: 'right' }} />) : <></>
                                    )
                                    :
                                    (
                                        record.children.length > 1 ? (<DownOutlined style={{ float: 'right' }} />) : <></>
                                    );
                            },
                        }}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            defaultPageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} Team Members`,
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default TeamMember