import React from "react";
import "antd/dist/antd.css";
import {  Pie } from "react-chartjs-2";

const PieChart = (props) => {
    return (
        <>
            <td
             style={{ width: 20 + "%" }}
            >
                <div
                    style={{ width: 120 + "px" }}
                    className="chart-box">
                    <div className="custom-chart-size" style={{
                        position: 'relative', marginLeft: "auto", marginRight: "auto"
                    }}>
                        <Pie
                            data={{
                                labels: props.labels, // ["High", "Medium", "Low"],
                                datasets: [
                                    {
                                        label: props.title,
                                        data: props.data,
                                        backgroundColor: [
                                            "#91e395",
                                            "#ffaa46",
                                            "#ef5350",
                                        ],
                                        borderColor: [
                                            "#91e395",
                                            "#ffaa46",
                                            "#ef5350",
                                        ],
                                        borderWidth: 0,
                                    },
                                ],
                            }}
                            options={{
                                responsive: [
                                    {
                                        breakpoint: 200,
                                        options: {
                                            chart: {
                                            },
                                            legend: {
                                                show: false,
                                            },
                                        },
                                    },
                                ],
                                pieceLabel: {
                                    render: "value",
                                    fontColor: "#fff",
                                },
                                legend: false,
                            }}
                        />
                    </div>
                    <div className="chart-title">
                        <div className="text-center chart-name"
                           style={{ fontSize: 15 + "px"}}
                        >
                            {props.title}
                        </div>
                    </div>
                </div>
            </td>

        </>
    )
}

export default PieChart;