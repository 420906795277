/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import * as styles from "./styles";
import DropDownRangeInput from "../../../shared/Dropdown/RangeInput";
import Tooltip from "@semcore/ui/tooltip";
import FeatherIcon from "feather-icons-react";
import AssetsImg from "../../../assets/images";
import { bucketOptions } from "../../../../utility/bucketOptions";
import { deleteBucket } from "../../../../services/ranking";
import toast from "react-hot-toast";
import SpinContainer from "@semcore/ui/spin-container";
import { getLocal } from "../../../../utility/helpers";

const RankingBuckets = ({ addStyles, onChange, value, isEdit, onBlur,configId }) => {
  const [data, setData] = useState(value);
  const [dropdownOptions, setDropdownOptions] = useState(bucketOptions);
  const [isDeletingBucket, setIsDeletingBucket] = useState(false);
  const userId = getLocal("userId")

  const addBucket = () => {
    const obj = { start: "", end: "" };
    const temp = data.slice();
    onChange(temp);
    temp.push(obj);
    setData(temp);
  };

  const removeBucket = (index) => {
    const temp = data.slice();
    temp.splice(index, 1);
    onChange(temp);
    setData(temp);
  };

  const handleDeleteBucket = async (index, id) => {
    if (isEdit) {
      if (!!id) {
        setIsDeletingBucket(true);
        try {
          const response = await deleteBucket(id,userId,configId);
          if (response.status === "success") {
            removeBucket(index);
            toast.success("Bucket Deleted Successfully.");
            setIsDeletingBucket(false);
          }
        } catch (error) {
          toast.error("Bucket Delete Failed.");
          setIsDeletingBucket(false);
        }
      } else {
        removeBucket(index);
      }
    } else {
      removeBucket(index);
    }
  };

  const handleChange = (range, index) => {
    const temp = data.slice();
    console.log(range)
    temp[index] = !!temp[index].id ? {start: range.start, end: range.end, id: temp[index].id} : range;
    onChange(temp);
    setData(temp);
  };

  const getRangeStart = (index) => {
    if (index === 0) {
      const dropdownRange = {
        options: dropdownOptions.options.slice(
          1,
          data[index].end && data[index].end !== "--"
            ? data[index].end
            : data[index + 1] && data[index + 1].start
            ? data[index + 1].start
            : dropdownOptions.options.length
        ),
        active: "",
      };
      return dropdownRange;
    } else {
      if (!!data[index - 1].end && data[index - 1].end !== "--") {
        const dropdownRange = {
          options: dropdownOptions.options.slice(
            parseInt(data[index - 1].end) + 1,
            data[index].end && data[index].end !== "--"
              ? data[index].end
              : data[index + 1] && data[index + 1].start
              ? data[index + 1].start
              : dropdownOptions.options.length
          ),
          active: "",
        };
        return dropdownRange;
      } else if (data[index - 1].end == "--" && !!data[index - 1].start) {
        const dropdownRange = {
          options: dropdownOptions.options.slice(
            parseInt(data[index - 1].start) + 1,
            data[index].end && data[index].end !== "--"
              ? data[index].end
              : data[index + 1] && data[index + 1].start
              ? data[index + 1].start
              : dropdownOptions.options.length
          ),
          active: "",
        };
        return dropdownRange;
      } else {
        const dropdownRange = {
          options: dropdownOptions.options.slice(
            1,
            data[index].end && data[index].end !== "--"
              ? data[index].end
              : data[index + 1] && data[index + 1].start
              ? data[index + 1].start
              : dropdownOptions.options.length
          ),
          active: "",
        };
        return dropdownRange;
      }
    }
  };

  const getRangeEnd = (index) => {
    if (index === 0) {
      if (!!data[index].start) {
        const dropdownRange = {
          options: [
            {
              label: "--",
              value: "--",
            },
            ...dropdownOptions.options.slice(
              parseInt(data[index].start) + 1,
              !!data[index + 1] && data[index + 1].start
                ? data[index + 1].start
                : dropdownOptions.options.length
            ),
          ],
          active: "",
        };
        return dropdownRange;
      } else {
        const dropdownRange = {
          options: [
            {
              label: "--",
              value: "--",
            },
            ...dropdownOptions.options.slice(
              1,
              !!data[index + 1] && data[index + 1].start
                ? data[index + 1].start
                : dropdownOptions.options.length
            ),
          ],
          active: "",
        };
        return dropdownRange;
      }
    } else {
      if (!!data[index].start) {
        const dropdownRange = {
          options: [
            {
              label: "--",
              value: "--",
            },
            ...dropdownOptions.options.slice(
              parseInt(data[index].start) + 1,
              !!data[index + 1] && data[index + 1].start
                ? data[index + 1].start
                : dropdownOptions.options.length
            ),
          ],
          active: "",
        };
        return dropdownRange;
      } else if (!!data[index - 1].end) {
        const dropdownRange = {
          options: [
            {
              label: "--",
              value: "--",
            },
            ...dropdownOptions.options.slice(
              parseInt(data[index - 1].end) + 1,
              !!data[index + 1] && data[index + 1].start
                ? data[index + 1].start
                : dropdownOptions.options.length
            ),
          ],
          active: "",
        };
        return dropdownRange;
      }
    }
  };

  const showAddButton = (values) => {
    if (
      parseInt(values[values.length - 1].start) < 100 &&
      values[values.length - 1].end === "--"
    ) {
      return true;
    } else if (
      parseInt(values[values.length - 1].start) < 100 &&
      parseInt(values[values.length - 1].end) < 100
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div css={[styles.main, addStyles && addStyles]}>
      <div css={styles.labelContainer}>
        <div css={styles.label}>
          <div css={styles.labelText}>
            Ranking Buckets<span css={styles.redText}>*</span>:
          </div>
          <Tooltip p={0} interaction={"hover"} placement="top-start">
            <Tooltip.Trigger>
              <FeatherIcon icon={"info"} css={styles.tootipIcon} />
            </Tooltip.Trigger>
            <Tooltip.Popper css={styles.tooltip}>
              <img
                src={AssetsImg.i_ranking_buckets_tooltip}
                css={styles.tooltipImage}
              />
              <div css={styles.noteContainer}>
                <div css={styles.noteTitle}>Note</div>
                <ul>
                  <li css={styles.note}>
                    Buckets set here will be displayed on main reporting screen.
                  </li>
                  <li css={styles.note}>
                    To set a single rank bucket select a number in first
                    dropdown and ‘--’ in other{" "}
                  </li>
                </ul>
              </div>
            </Tooltip.Popper>
          </Tooltip>
        </div>
        <div css={styles.info}>(Max 7)</div>
      </div>
      <div css={styles.rangeWrapper} key={data}>
        {data.length > 0 &&
          data.map((item, index) => (
            <div
              loading={isDeletingBucket}
              css={styles.rangeContainer}
              key={index}
            >
              <DropDownRangeInput
                placeholderStart={"No."}
                placeholderEnd={"No."}
                defaultStart={value[index].start}
                defaultEnd={value[index].end}
                handleChange={(range) => {
                  handleChange(range, index);
                }}
                startValue={item.start}
                endValue={item.end}
                dropdownRangeStart={getRangeStart(index)}
                dropdownRangeEnd={getRangeEnd(index)}
                selectedRange={data[index]}
                onBlur={onBlur}
              />
              <div css={styles.number}>
                <div>{`Bucket ${index + 1}`}</div>
                {data.length > 1 && (
                  <FeatherIcon
                    icon={"x"}
                    css={styles.close}
                    onClick={() => handleDeleteBucket(index, item.id)}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      {data.length < 7 && showAddButton(data) && (
        <div css={styles.addContainer}>
          <FeatherIcon
            icon={"plus-square"}
            css={styles.add}
            onClick={() => {
              addBucket();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RankingBuckets;
