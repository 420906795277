import { Tabs } from 'antd'
import React, { useState } from 'react'
import Viewcompetitor from './rankingv2-configuration/Viewcompetitor'
import ViewKeywords from './rankingv2-configuration/ViewKeywords'
import ViewCampaign from './rankingv2-configuration/ViewCampaign'
import Campaigndetails from './rankingv2-configuration/Campaigndetails'
import Campaingreview from './rankingv2-configuration/Campaingreview'
import Createcampaingview from './rankingv2-configuration/Createcampaingview'
import { Stepper } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { getcampaignSendforApproval } from '../../utility/Constant'
import APIManager from '../../services/APIManager'
import { toast } from 'react-hot-toast'


function RankingV2({ update }) {
    const steps = [
        {
            status: "Campaign Detail",
        },
        {
            status: "Keywords",
        },
        {
            status: "Competitors",
        },
        {
            status: "Review",
        }
    ];

    const [view, setview] = useState(0);
    const [SelectedTab, setSelectedTab] = useState("viewCampaign");
    const [word, setWord] = useState(0);
    const [CampingDetailStore, setCampingDetailStore] = useState(false);
    const [CampaignID, setCampaignID] = useState('');
    const [CreateCampaignId, setCreateCampaignId] = useState('');
    const [CampaignparentId, setCampaignparentId] = useState('');
    const [CampaignviewId, setCampaignviewId] = useState('');
    const [divcetype, setdivcetype] = useState('');
    const [AddkeywordOpen, setAddkeywordOpen] = useState(false);
    const [isKeywordList, setIsKeywordList] = useState(true)

    const handleChildToParent = (words, id, parentID, device) => {
        setCampaignviewId(id);
        setCampaignparentId(parentID);
        setdivcetype(device);
        setWord(words)
    };

    const dataChildToParentKeyword = (isList) => {
        setIsKeywordList(isList)
    };
    const handleChangeValue = (val) => { 
         setWord(val)
         };

    const hadleisedit = (val) => {
        setCampaignID(val);
        setCreateCampaignId(val.id);
        setSelectedTab("createCampaign");
        setCampingDetailStore(false);
    };

    function handleNextbtn(val) {
        switch (val) {
            case 0:
                setCampaignID({ id: CreateCampaignId });
                setCampingDetailStore(true);
                break;
            case 1:
                if (isKeywordList) {
                    setview(val + 1);
                }
                else {
                    toast.error("Please enter at least one keyword!")
                }
                break;
            case 2:
                setview(val + 1);
                break;
            default:
                break;
        }
    }

    function handlePrevbtn(val) {
        setview(val - 1);
        setCampingDetailStore(false);
        setCampaignID({ id: CreateCampaignId });
    }

    function getSendApproval() {
        APIManager.getAPI(getcampaignSendforApproval + CreateCampaignId)
            .then((response) => {
                if (response && response.status === 200) {
                    setSelectedTab("viewCampaign");
                }
            });
    }

    return (
        <>
            <section className='ranking-box-start'>
                <div class="portlet slideInUp">
                    <div class="portlet-header portlet-header-bordered d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                            <h3 class="portlet-title main-title ps-2">
                                Ranking Configuration
                            </h3>
                        </div>
                    </div>
                    {word !== 1 ? (
                        <>
                            <div className="portlet-body p-0">
                                <Tabs className='tabs-header'
                                    activeKey={SelectedTab}
                                    onChange={(e) => {
                                        setSelectedTab(e);
                                        setCampaignID({ id: '' });
                                        setCreateCampaignId("");
                                        setCampingDetailStore(false);
                                        setview(0);
                                    }}
                                >
                                    <Tabs.TabPane className='tab-name'
                                        tab="View Campaign"
                                        key="viewCampaign">
                                        <ViewCampaign SelectedTab={SelectedTab} dataChildToParent={handleChildToParent} dataTabChild={hadleisedit} ></ViewCampaign>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Create Campaign" key="createCampaign">
                                        <Stepper className="campaign-stepper m-auto mt-5 rankingv2_campaning" activeStep={view} alternativeLabel>
                                            {steps.map((label) => (
                                                <Step key={label.status}>
                                                    <StepLabel>{label.status}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <div className="mt-5">
                                            {(() => {
                                                switch (view) {
                                                    case 0:
                                                        return (<><Campaigndetails campaignID={CampaignID} storeData={CampingDetailStore}
                                                            tab={SelectedTab}
                                                            dataSetChild={(val) => {
                                                                setview(val)
                                                            }}
                                                            getConfigId={(id) => {
                                                                setCreateCampaignId(id);
                                                            }}
                                                        /></>)
                                                    case 1:
                                                        return (<><ViewKeywords dataChildToParent={dataChildToParentKeyword} campId={CreateCampaignId} isOpen={(data) => {
                                                            setAddkeywordOpen(data);
                                                        }} /></>)
                                                    case 2:
                                                        return (<><Viewcompetitor CompetitorcampId={CreateCampaignId} /></>)
                                                    case 3:
                                                        return (<><Campaingreview reivewcampId={CreateCampaignId} isEdit={(val) => {
                                                            setview(val);
                                                            setCampaignID({ id: CreateCampaignId });
                                                            setCampingDetailStore(false);
                                                        }} /></>)
                                                    default:
                                                        return null
                                                }
                                            })()}
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </>
                    ) : (
                        <>
                            <Createcampaingview
                                dataChild={handleChangeValue}
                                campaignviewID={CampaignviewId}
                                campaignparentId={CampaignparentId}
                                device={divcetype}
                            ></Createcampaingview>
                        </>
                    )
                    }

                    {
                        SelectedTab === "createCampaign" ?
                            (
                                <>
                                    <div className="create-campaign-btn d-flex flex-wrap flex-sm-nowrap  justify-content-end  m-4 mt-3">
                                        {(view !== 0 && view !== 3) && !AddkeywordOpen ? (<>
                                            <button className="btn btn-outline me-3" onClick={() => {
                                                handlePrevbtn(view);
                                            }}>Back</button>
                                        </>) : (<></>)}
                                        {view === 3 ? (<>
                                            {localStorage.getItem("approverName") ? (<div className="btn-aprver d-flex align-items-center me-3">
                                                <span>(Approver : {localStorage.getItem("approverName")})</span>
                                            </div>) : <></>}
                                            <button className="btn btn-primary me-3 mt-3 mt-sm-0" onClick={() => {
                                                getSendApproval();
                                            }}>Send for Approval</button>
                                        </>) : (<></>)}
                                        {view !== 3 && !AddkeywordOpen ? (<>
                                            <button className="btn btn-primary" onClick={() => {
                                                setCampingDetailStore(false);
                                                handleNextbtn(view);
                                            }}>Next</button>
                                        </>) : (<></>)}
                                    </div>
                                </>
                            )
                            :
                            (<></>)
                    }
                </div>
            </section>

        </>
    )
}

export default RankingV2
