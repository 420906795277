/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber, returnMagnitude } from "../../../../utility/Globals";
import { selectBucket } from "../../rankingReportSlice";
import { areAllZeros } from "../../../../utility/helpers";
import ErrorScreen from "../../../shared/errorScreen";
import AssetsImg from "../../../assets/images";
import { Status } from "../../../../utility/Constant";

const Graph = () => {
  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const rankingOptions = useSelector(
    (state) => state.rankingReport.rankOptions
  );
  const keywordTabs = useSelector(
    (state) => state.rankingReport.keywordDistributionTabs
  );
  const [isEmpty, setIsEmpty] = useState(false);
  const chart = useRef(null);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    series: graphData.series,
    options: {
      chart: {
        type: "bar",
        height: 313,
        stacked: true,
        events: {
          click: (event, chartContext, config) => {
            dispatch(selectBucket(config.dataPointIndex));
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        formatter: (val) => {
          return val / 1000 + "K";
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
          borderRadius: 5,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "all",
        },
      },
      dataLabels: {
        formatter: () => {
          return "";
        },
      },
      xaxis: {
        categories: graphData.xAxisCategories,
        lines: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      colors: [
        ({ value, seriesIndex, w }) => {
          if (seriesIndex == graphData.active) {
            return Colors.semantic[600];
          } else {
            return Colors.semantic[300];
          }
        },
        ({ value, seriesIndex, w }) => {
          if (seriesIndex == graphData.active) {
            return Colors.primary[800];
          } else {
            return Colors.primary[600];
          }
        },
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return returnMagnitude(value);
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      tooltip: {
        y: {
          show: true,
          formatter: function (value) {
            return formatNumber(value);
          },
        },
      },
    },
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      series: graphData.series,
      options: {
        ...prev.options,
        chart: {
          ...prev.options.chart,
          events: {
            click: (event, chartContext, config) => {
              dispatch(selectBucket(config.dataPointIndex));
            },
          },
        },
        colors: [
          ({dataPointIndex}) => {
            if (dataPointIndex === graphData.active) {
              return Colors.semantic[600];
            } else {
              return Colors.semantic[300];
            }
          },
          ({dataPointIndex}) => {
            if (dataPointIndex === graphData.active) {
              return Colors.primary[800];
            } else {
              return Colors.primary[600];
            }
          },
        ],
        xaxis: {
          categories: graphData.xAxisCategories,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: rankingOptions.active === 0 ? "10%" : "20%",
            borderRadius: 5,
            borderRadiusApplication: "around",
            borderRadiusWhenStacked: "all",
          },
        },
        noData: {
          text: true ? "" : "No Data present in the graph!",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Helvetica",
          },
        },
      },
    }));
    let series1;
    let series2;
    if (isCompare && graphData.series.length > 0) {
      series1 = graphData?.series[0]?.data;
      series2 = graphData?.series[1]?.data;
      if (areAllZeros(series1) && areAllZeros(series2)) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    } else if(graphData.series.length > 0){
      series1 = graphData.series[0].data;
      if (areAllZeros(series1)) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    } else {
      setIsEmpty(true);
    }
  }, [graphData.series, graphData.xAxisCategories, graphData.active]);


  if (graphData.status !== Status.LOADING && isEmpty ) {
    return <ErrorScreen
        title={"No Data Found"}
        icon={AssetsImg.ic_empty_table_icon}
        addStyles={styles.errorScreen}
      />
  } else {
    return (
      <div css={styles.main} key={graphData.active}>
        <Chart
          id="GRAPH"
          options={state.options}
          series={state.series}
          type="bar"
          width="100%"
          height={"313px"}
          ref={chart}
          toolbar={false}
        />
      </div>
    );
  }
};

export default Graph;
