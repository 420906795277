/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import * as styles from "./styles";
import { Colors } from "../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { returnMagnitude } from "../../../../utility/Globals";
import SpinContainer from "@semcore/ui/spin-container";
import { Col, Empty, Input, Skeleton, Table } from "antd";
import { formatDate, getWeeksBetweenRange } from "../../../../utility/dates";
import FeatherIcon from "feather-icons-react";
import { Sorter } from "../../../../common/Sorter";
import DropdownMenu from "@semcore/ui/dropdown-menu";
import { Box, Flex } from "@semcore/ui/flex-box";
import { selectBucket } from "../../rankingReportSlice";
import AssetsImg from "../../../assets/images";
import Tooltip from "@semcore/ui/tooltip";
import {
  Status,
  ranksDefault,
  statusFilter,
} from "../../../../utility/Constant";
import { shortenNumber } from "../../../../utility/helpers";
import ErrorScreen from "../../../shared/errorScreen";

const KeywordTable = () => {
  const isCompare = useSelector((state) => state.rankingReport.isCompare);
  const graphData = useSelector((state) => state.rankingReport.graphData);
  const isCustom = useSelector((state) => state.rankingReport.isCustom);
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const formattedRange = useSelector(
    (state) => state.rankingReport.formattedRange
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUrlSearchOpen, setIsUrlSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [urlSearchText, setUrlSearchText] = useState("");
  const searchInputRef = useRef();
  const urlSearchInputRef = useRef();
  const campaigns = useSelector((state) => state.rankingReport.campaigns);

  const keywords = useSelector((state) => state.rankingReport.keywords.data);
  const keywordsStautus = useSelector(
    (state) => state.rankingReport.keywords.status
  );
  const unique_categories = useSelector(
    (state) => state.rankingReport.keywords.unique_categories
  );

  const dispatch = useDispatch();

  const getCustomColumns = () => {
    const { startDates } = getWeeksBetweenRange(
      selectedDates.selection.startDate,
      selectedDates.selection.endDate,
      campaigns.options[campaigns.active].unavailableDates
    );
    const buckets = graphData.series.map((bucket) => bucket.name);
    if (isCustom) {
      const customColumns = [];
      if (formattedRange.length > 0) {
        formattedRange.map((date, index) => {
          const object = {
            filterDropdown: () => <></>,
            filters: [],
            filterIcon: (filtered) => (
              <div css={styles.title}>
                <Box>
                  <DropdownMenu>
                    {(props, handlers) => (
                      <>
                        <DropdownMenu.Trigger>
                          <Flex
                            // css={styles.triggerText}
                            h={"100%"}
                            alignItems="center"
                            columnGap={"0.5rem"}
                          >
                            {buckets && buckets?.length > 0 && (
                              <Box>
                                {graphData.active === -1 ? (
                                  <>
                                    Rank{" "}
                                    <span css={styles.date}>(Overall)</span>
                                  </>
                                ) : (
                                  buckets[graphData.active]
                                )}
                              </Box>
                            )}
                            {index === formattedRange.length - 1 && (
                              <FeatherIcon
                                icon={"chevron-down"}
                                css={styles.searchIcon(false)}
                              />
                            )}
                          </Flex>
                        </DropdownMenu.Trigger>
                        {index === formattedRange.length - 1 && (
                          <DropdownMenu.Popper
                            ptb={"0.3rem"}
                            css={styles.popper}
                          >
                            <DropdownMenu.Item
                              p="0"
                              onClick={() => {
                                dispatch(selectBucket(-1));
                                handlers.visible(false);
                              }}
                              css={styles.option(graphData.active === -1)}
                            >
                              <Box>{"Overall"}</Box>
                            </DropdownMenu.Item>
                            {buckets.map((option, index) => (
                              <DropdownMenu.Item
                                p="0"
                                onClick={() => {
                                  dispatch(selectBucket(index));
                                  handlers.visible(false);
                                }}
                                css={styles.option(graphData.active === index)}
                              >
                                <Box>{option}</Box>
                              </DropdownMenu.Item>
                            ))}
                          </DropdownMenu.Popper>
                        )}
                      </>
                    )}
                  </DropdownMenu>
                </Box>
                <div css={styles.date}>{startDates[index]}</div>
              </div>
            ),
            sorter: {
              compare: (a, b) =>
                Sorter.DEFAULT(
                  parseInt(a[`my_rank_week_${index + 1}`]),
                  parseInt(b[`my_rank_week_${index + 1}`])
                ),
            },
            dataIndex: `my_rank_week_${index + 1}`,
            render: (value, record) => {
              return keywordsStautus === Status.LOADING ? (
                <Skeleton.Button
                  active={true}
                  size={"small"}
                  shape={"round"}
                  block={true}
                />
              ) : (
                <div css={[styles.cell, styles.flexCell]}>
                  <div css={styles.rank}>
                    {value === ranksDefault.NOT_TRACKED
                      ? "Not Tracked"
                      : value === ranksDefault.NOT_RANKED
                      ? "Not Ranking"
                      : value}
                  </div>
                  {record.status !== "--" &&
                    index !== 0 &&
                    value !== record[`my_rank_week_${index}`] && (
                      <span
                        css={styles.progressContainer(
                          value < record[`my_rank_week_${index}`]
                        )}
                      >
                        <FeatherIcon
                          icon={
                            value < record[`my_rank_week_${index}`]
                              ? "trending-up"
                              : "trending-down"
                          }
                          css={styles.icon(true)}
                        />
                        {value !== ranksDefault.NOT_RANKED &&
                          record[`my_rank_week_${index + 1}`] !==
                            ranksDefault.NOT_RANKED && (
                            <span
                              css={styles.progress(
                                value < record[`my_rank_week_${index}`]
                              )}
                            >
                              {value === record[`Change_week_${index}_to_${index + 1}`] ? "" : record[`Change_week_${index}_to_${index + 1}`] }
                            </span>
                          )}
                      </span>
                    )}
                </div>
              );
            },
          };
          customColumns.unshift(object);
        });
        return customColumns;
      }
    } else {
      return [];
    }
  };

  const columns = [
    {
      title: <div css={styles.title}>Keywords</div>,
      dataIndex: "keywords",
      key: "keywords",
      width: "30%",
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <Tooltip placement="right-start">
            <Tooltip.Trigger>
              <div css={[styles.cell, styles.keywordCell]}>
                <div>{value} </div>
                <div css={styles.position(record.searchvolumetype)}>
                  <span css={styles.positionText(record.searchvolumetype)}>
                    {record?.searchvolumetype === "Torso"
                      ? record?.searchvolumetype.slice(0, 2)
                      : record?.searchvolumetype[0]}
                  </span>
                </div>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Popper css={styles.searchVolume}>
              {record.searchvolumetype}
            </Tooltip.Popper>
          </Tooltip>
        );
      },
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        handleSave,
      }) => (
        <>
          <Input
            ref={searchInputRef}
            placeholder={"Search"}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSelectedKeys(!!e.target.value ? [e.target.value] : []);
              confirm(false);
            }}
            onPressEnter={() => {
              confirm();
            }}
            css={styles.searchInput}
            suffix={<FeatherIcon icon={"search"} css={styles.searchIcon} />}
          />
        </>
      ),
      onFilter: (value, record) =>
        record.keywords.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current?.select(), 100);
        }
      },
      filterIcon: (filtered) => (
        <FeatherIcon
          icon="search"
          css={styles.searchIcon(filtered)}
          onClick={() => setIsSearchOpen(!isSearchOpen)}
        />
      ),
    },
    {
      title: <div css={styles.title}>Status</div>,
      dataIndex: "status",
      hidden: isCustom ? !isCustom : !isCompare,
      filterIcon: (filtered) => (
        <FeatherIcon
          icon={"filter"}
          css={styles.searchIcon(filtered)}
          fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
        />
      ),
      filters: statusFilter.map((status) => {
        return { text: status, value: status };
      }),
      onFilter: (value, record) => record.status === value,
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div
            css={[
              styles.cell,
              styles.status(value === "Improved", value === "Stable"),
            ]}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: <div css={styles.title}>Search Volume</div>,
      dataIndex: "searchvolume",
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div css={styles.cell}>{shortenNumber(value)}</div>
        );
      },
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.searchvolume, b.searchvolume),
      },
    },
    {
      dataIndex: "category",
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div css={styles.cell}>{value}</div>
        );
      },
      filterIcon: (filtered) => (
        <>
          <div css={[styles.title, styles.category]}>Category</div>
          <FeatherIcon
            icon={"filter"}
            css={styles.searchIcon(filtered)}
            fill={filtered ? Colors.primary[400] : "rgba(0,0,0,0)"}
          />
        </>
      ),
      filters: unique_categories?.map((keywordcategory) => ({
        text: keywordcategory,
        value: keywordcategory,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      filterDropdown: () => <></>,
      filters: [],
      filterIcon: (filtered) => (
        <div css={styles.title}>
          <Box>
            <DropdownMenu>
              {(props, handlers) => (
                <>
                  <DropdownMenu.Trigger>
                    <Flex
                      // css={styles.triggerText}
                      h={"100%"}
                      alignItems="center"
                      columnGap={"0.5rem"}
                    >
                      {graphData?.xAxisCategories &&
                        graphData?.xAxisCategories.length > 0 && (
                          <Box>
                            {graphData.active === -1 ? (
                              <>
                                Rank <span css={styles.date}>(Overall)</span>
                              </>
                            ) : (
                              graphData?.xAxisCategories[graphData.active]
                            )}
                          </Box>
                        )}
                      <FeatherIcon
                        icon={"chevron-down"}
                        style={{ marginLeft: "8px" }}
                      />
                    </Flex>
                  </DropdownMenu.Trigger>
                  {
                    <DropdownMenu.Popper ptb={"0.3rem"} css={styles.popper}>
                      <DropdownMenu.Item
                        p="0"
                        onClick={() => {
                          dispatch(selectBucket(-1));
                          handlers.visible(false);
                        }}
                        css={styles.option(graphData.active === -1)}
                      >
                        <Box>{"Overall"}</Box>
                      </DropdownMenu.Item>
                      {graphData?.xAxisCategories?.map((option, index) => (
                        <DropdownMenu.Item
                          p="0"
                          onClick={() => {
                            dispatch(selectBucket(index));
                            handlers.visible(false);
                          }}
                          css={styles.option(graphData.active === index)}
                        >
                          <Box>{option}</Box>
                        </DropdownMenu.Item>
                      ))}
                    </DropdownMenu.Popper>
                  }
                </>
              )}
            </DropdownMenu>
          </Box>
          <div css={styles.date}>
            {formatDate(
              isCompare
                ? selectedDates?.compare?.startDate
                : selectedDates?.selection?.startDate
            )}
          </div>
        </div>
      ),
      //Change: my_rank > own_domain_rank,  my_rank_second_week > latest_week_rank
      dataIndex: isCompare ? "latest_week_rank" : "own_domain_rank",
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            isCompare ? a.latest_week_rank : a.own_domain_rank,
            isCompare ? b.latest_week_rank : b.own_domain_rank
          ),
      },
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div css={styles.cell}>
            {value === ranksDefault.NOT_TRACKED
              ? "Not Tracked"
              : value === ranksDefault.NOT_RANKED
              ? "Not Ranking"
              : value}
          </div>
        );
      },
      hidden: isCustom,
      width: "16%",
    },
    {
      filterDropdown: () => <></>,
      filters: [],
      filterIcon: (filtered) => (
        <div css={styles.title}>
          <Box>
            <DropdownMenu>
              {(props, handlers) => (
                <>
                  <DropdownMenu.Trigger>
                    <Flex
                      // css={styles.triggerText}
                      h={"100%"}
                      alignItems="center"
                      columnGap={"0.5rem"}
                    >
                      {graphData?.xAxisCategories &&
                        graphData?.xAxisCategories.length > 0 && (
                          <Box>
                            {graphData.active === -1 ? (
                              <>
                                Rank <span css={styles.date}>(Overall)</span>
                              </>
                            ) : (
                              graphData?.xAxisCategories[graphData.active]
                            )}
                          </Box>
                        )}
                      {/* <FeatherIcon
                        icon={"chevron-down"}
                        css={styles.searchIcon(false)}
                      /> */}
                    </Flex>
                  </DropdownMenu.Trigger>
                  {/* <DropdownMenu.Popper ptb={"0.3rem"} css={styles.popper}>
                    {graphData?.xAxisCategories?.map((option, index) => (
                      <DropdownMenu.Item
                        p="0"
                        onClick={() => {
                          dispatch(selectBucket(index));
                          handlers.visible(false);
                        }}
                      >
                        <Box css={styles.option}>{option}</Box>
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Popper> */}
                </>
              )}
            </DropdownMenu>
          </Box>
          <div css={styles.date}>
            {formatDate(selectedDates?.selection?.startDate)}
          </div>
        </div>
      ),
      dataIndex: "previous_week_rank",
      hidden: isCustom ? isCustom : !isCompare,
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div css={styles.cell}>
            {value === ranksDefault.NOT_TRACKED
              ? "Not Tracked"
              : value === ranksDefault.NOT_RANKED
              ? "Not Ranking"
              : value}
          </div>
        );
      },
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            parseInt(a.previous_week_rank),
            parseInt(b.previous_week_rank)
          ),
      },
    },
    {
      width: "30%",
      hidden: isCustom || isCompare,
      //Change: my_url > own_domain_url
      dataIndex: "own_domain_url",
      ellipsis: true,
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div css={styles.cell}>{value}</div>
        );
      },
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        handleSave,
      }) => (
        <>
          <Input
            ref={urlSearchInputRef}
            placeholder={"Search"}
            value={urlSearchText}
            onChange={(e) => {
              setUrlSearchText(e.target.value);
              setSelectedKeys(!!e.target.value ? [e.target.value] : []);
              confirm(false);
            }}
            onPressEnter={() => {
              confirm();
            }}
            css={styles.searchInput}
            suffix={<FeatherIcon icon={"search"} css={styles.searchIcon} />}
          />
        </>
      ),
      onFilter: (value, record) =>
        record?.own_domain_url
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => urlSearchInputRef.current?.select(), 100);
        }
      },
      filterIcon: (filtered) => (
        <>
          <div css={styles.title}>URLs </div>
          <FeatherIcon
            icon="search"
            css={[styles.searchIcon(filtered), styles.customSearchIcon]}
            onClick={() => setIsUrlSearchOpen(!isSearchOpen)}
          />
        </>
      ),
    },
    {
      filterDropdown: () => <></>,
      filters: [],
      filterIcon: (filtered) => <div css={styles.title}>Change</div>,
      dataIndex: "change",
      hidden: isCustom ? isCustom : !isCompare,
      render: (value, record) => {
        return keywordsStautus === Status.LOADING ? (
          <Skeleton.Button
            active={true}
            size={"small"}
            shape={"round"}
            block={true}
          />
        ) : (
          <div
            css={[
              styles.cell,
              styles.status(
                record.status === "Improved",
                parseInt(value) === 0
              ),
            ]}
          >
            {value}
          </div>
        );
      },
      sorter: {
        compare: (a, b) => {
          if(a.change!== "Lost" && b.change!== "Lost" && b.change!== "New" && a.change!== "New"){
            if (parseInt(a.change) < parseInt(b.change)) return -1;
            if (parseInt(b.change) < parseInt(a.change)) return 1;
            return 0;
          } if (a.change === "New" && (b.change !== "New" && b.change !== "Lost")){
            return 1;
          } else if(b.change === "New" && (a.change !== "New" && a.change !== "Lost")){
            return -1;
          } else if(a.change === "Lost" && (b.change !== "New" && b.change !== "Lost")){
            return -1;
          } else if(b.change === "Lost" && (a.change !== "New" && a.change !== "Lost")){
            return 1;
          } else if(a.change === "New" && b.change === "Lost"){
            return 1;
          } else if(a.change === "Lost" && b.change === "New"){
            return -1;
          }
        },
      },
    },
    ...getCustomColumns(),
  ].filter((item) => !item.hidden);

  return (
    <div
      css={styles.main(keywordsStautus === Status.LOADING, isCompare, isCustom)}
    >
      <Table
        id="keywords-table"
        dataSource={keywords}
        columns={columns}
        pagination={{
          size: "small",
          defaultPageSize: 5,
          position: ["bottomRight"],
          showSizeChanger: true,
          totalBoundaryShowSizeChanger: 5,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} Keywords`,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
        }}
        getPopupContainer={() => document.getElementById("keywords-table")}
        locale={{
          emptyText:
            keywordsStautus === Status.LOADING ? (
              <Skeleton active={true} />
            ) : (
              <ErrorScreen
                title={"No Data found"}
                icon={AssetsImg.ic_empty_table_icon}
                addStyles={styles.errorScreen}
              />
            ),
        }}
        loading={keywordsStautus === Status.LOADING}
        key={unique_categories}
      />
    </div>
  );
};
export default KeywordTable;
