import APIV1 from "../api/axios";

export const getCampaigns = async (projectId, userId) => {
  try {
    const response = await APIV1(
      "get",
      `/rankingsv2/view_all_rankings_campaigns_config/${projectId}/${userId}/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getLocations = async (value) => {
  try {
    const response = await APIV1(
      "get",
      `/rankingsv2/search_city_and_country/?query=${value}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCampaignStatus = async (status, campaignId, userId) => {
  try {
    const payload = {
      isActive: status,
      user_id: userId,
    };
    const response = await APIV1(
      "patch",
      `/rankingsv2/update_ranking_configuration_status/${campaignId}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCampaign = async (id) => {
  try {
    const response = await APIV1(
      "delete",
      `/rankingsv2/delete_configuration/${id}/configuration/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const validateCampaignName = async (name, projectId) => {
  try {
    const response = await APIV1(
      "get",
      `/rankingsv2/check_campaign/${projectId}/${name}/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const importKeywords = async (payload) => {
  try {
    const response = await APIV1(
      "post",
      "/rankingsv2/process_keyword_csv_file/",
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const importCompetitors = async (payload) => {
  try {
    const response = await APIV1(
      "post",
      "/rankingsv2/process_competitor_csv_file/",
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createCampaign = async (payload) => {
  try {
    const response = await APIV1(
      "post",
      "/rankingsv2/create_ranking_configuration/",
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCampaignDetails = async (id, step) => {
  try {
    const response = await APIV1(
      "get",
      `rankingsv2/get_created_ranking_campaign/${id}/?page=${step}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editSingleKeyword = async (id, payload) => {
  try {
    const response = await APIV1(
      "patch",
      `/rankingsv2/update_single_keyword/${id}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addSingleKeyword = async (payload) => {
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/create_single_keyword/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteKeywords = async (payload) => {
  try {
    const response = await APIV1(
      "delete",
      `/rankingsv2/delete_selected_keywords_all/`,
      { data: payload }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const replaceKeywords = async (campaignId, payload) => {
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/update_new_keyword_set/${campaignId}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addKeywords = async (campaignId, payload) => {
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/update_or_create_keywords/${campaignId}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addSingleCompetitor = async (payload) => {
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/create_single_competitor/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadCompetitors = async (id, payload) => {
  try {
    const response = await APIV1(
      "post",
      `/rankingsv2/upload_new_competitors/${id}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCompetitors = async (payload) => {
  try {
    const response = await APIV1(
      "delete",
      `/rankingsv2/delete_selected_competitors_all/`,
      { data: payload }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const upDateCampaign = async (campaignId, payload) => {
  try {
    const response = await APIV1(
      "patch",
      `/rankingsv2/update_ranking_config/${campaignId}/`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteBucket = async (id, userId, config_id) => {
  const payload = { user_id: userId, config_id };
  console.log(payload);
  try {
    const response = APIV1(
      "delete",
      `/rankingsv2/delete_configuration/${id}/bucket/`,
      {data: payload}
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const isConfigUnlocked = async () => {
  try {
    const response = await APIV1("get", "/rankingsv2/lock_unlock_config/");
    return response;
  } catch (error) {
    throw error;
  }
};
