/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { addDays, format, isWeekend, set } from "date-fns";
import { DateRangePicker } from "react-date-range";
import CustomButton from "../../button";
import * as styles from "./styles";
import Checkbox from "@semcore/ui/checkbox";
import useSelection from "antd/lib/table/hooks/useSelection";
import { useDispatch, useSelector } from "react-redux";
import {
  setDates,
  setFormattedDate,
  toggleIsCompare,
} from "../../../rankingReportV3/rankingReportSlice";
import {
  dateToClosestWeek,
  formatDateRange,
  getDatesBetweenRange,
  getLargestDate,
  getSecondLargestDate,
  getSmallestDate,
  getWeeksBetweenRange,
} from "../../../../utility/dates";
import { Colors } from "../../../../styles/colors";
import moment from "moment";
import { id } from "date-fns/locale";

const MultiRangeDatePicker = ({
  onChange,
  showCompare,
  onApplyClick,
  minDate,
  maxDate,
  unavailableDates,
  availableDates,
  isCustom,
  isCompare,
}) => {
  const selectedDates = useSelector(
    (state) => state.rankingReport.selectedDates
  );
  const [isChecked, setIsChecked] = useState(isCompare);
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [rangeColors, setRangeColors] = useState([
    Colors.semantic[300],
    Colors.primary[500],
  ]);
  const datePickerOptions = useSelector(
    (state) => state.rankingReport.datePickerOptions
  );
  const [state, setState] = useState({
    selection: {
      startDate: dateToClosestWeek(new Date())[0],
      endDate: dateToClosestWeek(new Date())[1],
      key: "range1",
    },
    compare: {
      startDate: dateToClosestWeek(addDays(new Date(), -7))[0],
      endDate: dateToClosestWeek(addDays(new Date(), -7))[1],
      key: "range2",
    },
  });
  const [minimumDate, setMinimumDate] = useState(new Date(minDate));
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const [unAvailableDates, setUnavailableDates] = useState([]);
  const latesWeekSaturday = dateToClosestWeek(maxDate)[1];
  const [selectionCount, setSelectionCount] = useState(0);
  const [isCompareEnable, setIsCompareEnable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(availableDates?.length < 1){
      setRangeColors([Colors.neutral[200], Colors.neutral[200]])
    } else {
      setRangeColors([Colors.semantic[300], Colors.primary[500]])
    }
  },[availableDates])

  useEffect(() => {
    const biweeklyStartRange = dateToClosestWeek(
      selectedDates.selection.startDate
    );
    if (isCustom) {
      const latestDate = getLargestDate(availableDates);
      const oldestDate = getSmallestDate(availableDates);
      if (
        moment(latestDate).format("DD MMM YY") ===
        moment(oldestDate).format("DD MMM YY")
      ) {
        setIsApplyDisabled(true);
      }
      dispatch(
        setDates({
          selection: {
            startDate: dateToClosestWeek(oldestDate)[0],
            endDate: dateToClosestWeek(latestDate)[1],
          },
          compare: {
            startDate: dateToClosestWeek(oldestDate)[0],
            endDate: dateToClosestWeek(latestDate)[1],
          },
        })
      );
    } else {
      setState( { selection: biweeklyStartRange, compare: selectedDates.compare });
      if(availableDates.length > 1){
        setIsCompareEnable(true)
      } else {
        setIsCompareEnable(false)
      }
    }
    setUnavailableDates(
      unavailableDates.map((date) =>
        moment(new Date(date)).format("DD MMM YYYY")
      )
    );
  }, [isCompare, isCustom]);

  const handleApplyClick = () => {
    onApplyClick(isChecked);
  };

  const checkIfDateIsUnavailable = (date) => {
    return unAvailableDates.includes(moment(date).format("DD MMM YYYY"));
  };

  const handleWeeklySelection = (item, keys, selectionCount) => {
    const date = dateToClosestWeek(item[keys[0]].startDate, "test");
    dispatch(
      setDates({
        selection: { startDate: date[0], endDate: date[1] },
        compare: selectedDates.compare,
      })
    );
    if (checkIfDateIsUnavailable(date[0])) {
      const colors = [...rangeColors];
      setRangeColors([Colors.neutral[200], colors[1]]);
      setIsApplyDisabled(true);
      setFocusedRange([0, 0]);
    } else {
      setIsApplyDisabled(false);
      const colors = [...rangeColors];
      setRangeColors([Colors.semantic[300], colors[1]]);
    }
  };

  const handleWeeklyCompareSelection = (item, keys) => {
    if (
      ((focusedRange[0] === 0 && focusedRange[1] === 0) ||
        moment(selectedDates.selection.startDate).format() >
          moment(item[keys[0]].startDate).format()) &&
      moment(item[keys[0]].startDate).format() <
        moment(selectedDates.compare.startDate).format()
    ) {
      const colors = [...rangeColors];
      setRangeColors([Colors.semantic[300], colors[1]]);
      const date = dateToClosestWeek(item[keys[0]].startDate, "test");
      dispatch(
        setDates({
          compare: selectedDates.compare,
          selection: { startDate: date[0], endDate: date[1] },
        })
      );
      if (
        moment(date[0]).format("DD MMM YYYY") ===
          moment(selectedDates.compare.startDate).format("DD MMM YYYY") ||
        new Date(date[0]) > new Date(selectedDates.compare.startDate) ||
        checkIfDateIsUnavailable(selectedDates.compare.startDate)
      ) {
        setIsApplyDisabled(true);
        setFocusedRange([1, 0]);
      } else if (checkIfDateIsUnavailable(date[0])) {
        const colors = [...rangeColors];
        setRangeColors([Colors.neutral[200], colors[1]]);
        setIsApplyDisabled(true);
        setFocusedRange([0, 0]);
      } else {
        setIsApplyDisabled(false);
        // setMinimumDate(new Date(date[0]));
        setFocusedRange([1, 0]);
      }
    } else {
      const colors = [...rangeColors];
      setRangeColors([colors[0], Colors.primary[500]]);
      const isLessThanPrevious =
        moment(selectedDates.selection.startDate).format() >
        moment(item[keys[0]].endDate).format();
      const date = dateToClosestWeek(item[keys[0]].startDate, "test");
      dispatch(
        setDates({
          selection: selectedDates.selection,
          compare: { startDate: date[0], endDate: date[1] },
        })
      );
      if (
        moment(date[0]).format("DD MMM YYYY") ===
          moment(selectedDates.selection.startDate).format("DD MMM YYYY") ||
        checkIfDateIsUnavailable(selectedDates.selection.startDate)
      ) {
        setIsApplyDisabled(true);
      } else if (checkIfDateIsUnavailable(date[0])) {
        const colors = [...rangeColors];
        setRangeColors([colors[0], Colors.neutral[200]]);
        setIsApplyDisabled(true);
      } else {
        setIsApplyDisabled(false);
        setFocusedRange([0, 0]);
      }
      // setMinimumDate(new Date(minDate));
    }
  };

  const handleCustomSelection = (item, keys) => {
    if (focusedRange[0] === 0 && focusedRange[1] === 0) {
      dispatch(
        setDates({
          selection: {
            startDate: dateToClosestWeek(item[keys[0]].startDate)[0],
            endDate: dateToClosestWeek(item[keys[0]].endDate)[1],
          },
          compare: selectedDates.compare,
        })
      );
      setIsApplyDisabled(true);
      setFocusedRange([0, 1]);
    } else {
      dispatch(
        setDates({
          selection: {
            startDate: dateToClosestWeek(item[keys[0]].startDate)[0],
            endDate: dateToClosestWeek(item[keys[0]].endDate)[1],
          },
          compare: selectedDates.compare,
        })
      );
      let {startDates} = getWeeksBetweenRange( dateToClosestWeek(item[keys[0]].startDate)[0], dateToClosestWeek(item[keys[0]].endDate)[1])
      const datesAvailableInRange = startDates.filter((date) => !checkIfDateIsUnavailable(date));
      if (
        moment(selectedDates.selection.startDate).format("DD MMM YY") ===
        moment(dateToClosestWeek(item[keys[0]].endDate)[0]).format(
          "DD MMM YY"
        ) || datesAvailableInRange.length < 2
      ) {
        setIsApplyDisabled(true);
      } else {
        setIsApplyDisabled(false);
      }
      setFocusedRange([0, 0]);
    }
  };

  const handleCompareClick = (checked) => {
    setRangeColors([Colors.semantic[300], Colors.primary[500]]);
    const selection = {
      startDate: dateToClosestWeek(getSecondLargestDate(availableDates))[0],
      endDate: dateToClosestWeek(getSecondLargestDate(availableDates))[1],
    };

    const compare = {
      startDate: dateToClosestWeek(getLargestDate(availableDates))[0],
      endDate: dateToClosestWeek(getLargestDate(availableDates))[1],
    };

    dispatch(
      setDates({
        selection: checked ? selection : compare,
        compare: checked ? compare : selection,
      })
    );
    setIsChecked(checked);
    setIsApplyDisabled(false);
  };

  return (
    <div css={styles.main}>
      <DateRangePicker
      dragSelectionEnabled={false}
        preventSnapRefocus={false}
        showPreview={false}
        rangeColors={rangeColors}
        minDate={new Date(dateToClosestWeek(minimumDate)[0])}
        maxDate={new Date(latesWeekSaturday)}
        scroll={{ enabled: false }}
        key={selectedDates}
        onChange={(item) => {
          if (datePickerOptions.active === 0) {
            const keys = Object.keys(item);
            handleCustomSelection(item, keys);
          } else {
            const keys = Object.keys(item);
            if (isChecked) {
              handleWeeklyCompareSelection(item, keys, selectionCount);
            } else {
              handleWeeklySelection(item, keys, selectionCount);
            }
            setSelectionCount((prev) => (prev > 2 ? 0 : prev + 1));
          }
        }}
        showMonthAndYearPickers={true}
        months={1}
        direction="vertical"
        ranges={
          isChecked && selectedDates.compare
            ? [selectedDates?.selection, selectedDates?.compare]
            : [selectedDates.selection]
        }
        // disabledDates={unavailableDates.map((date) => new Date(date))}
        focusedRange={focusedRange}
      />
      <div css={styles.bottomContainer(showCompare)}>
        {showCompare && (
          <div css={styles.compareContainer} key={isCustom}>
            <Checkbox
              checked={isChecked}
              onChange={(checked) => handleCompareClick(checked)}
              key={isCompare}
              disabled={!isCompareEnable}
            />
            <span css={styles.compareText(isCompareEnable)}>Compare</span>
          </div>
        )}
        <CustomButton
          text={"Apply"}
          theme={"info"}
          use={"primary"}
          onPress={() => handleApplyClick()}
          isDisable={isApplyDisabled}
        />
      </div>
    </div>
  );
};

export default MultiRangeDatePicker;
