import { Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Sorter } from "../../common/Sorter";
import APIManager from "../../services/APIManager";
import {
  documentFileType,
  getMemberByOrgId,
  GET_Server_URL,
  memberImport,
  memberIsActive,
} from "../../utility/Constant";
import {
  exportInToCSV,
  findUnique,
  mmDDyyyyFormateDate,
} from "../../utility/Globals";
import { useNavigate } from "react-router-dom";

const TeamMembers = () => {
  const [edit, setedit] = useState(false);
  const [exportEmployeeList, setExportEmployeeList] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamlistEmp, setteamListEmp] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);

  useEffect(() => {
    getMemberlist();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          key: item.key,
          empcode: item.empcode,
          name: item.name,
          empdesignation: item.empdesignation,
          department: item.department,
          empjoinigdate: item.empjoinigdate,
          tmsupervisor: item.tmsupervisor,
          tmsrsupervisor: item.tmsrsupervisor,
          dob: item.dob,
          phone: item.phone,
          email: item.email,
          salary: item.salary,
          status: item.status,
          active: item.accntactivation,
          lastwrkdate: item.lastwrkdate,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function getMemberlist() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const exportList = [];
        const filterempdesig = [];
        const filterdpmt = [];
        const filtersupervisor = [];
        const filtersrsupervisor = [];
        response.data.map((item) => {
          let obj = {
            key: item.id,
            empcode: item.code,
            name1: item.name,
            name: item.name,
            empdesignation: item.designation,
            department: item.department,
            empjoinigdate: mmDDyyyyFormateDate(item.joinedOn),
            tmsupervisor: item.supervisor?.name,
            tmsrsupervisor: item.srSupervisor?.name,
            dob: mmDDyyyyFormateDate(item.dob),
            phone: item.phoneNumber,
            email: item.email,
            salary: item.salary,
            status: item.status,
            accntactivation: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.active}
                  onClick={() => {
                    updateStatus(item.id);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            lastwrkdate:
              item.lastWorkingDate != null
                ? mmDDyyyyFormateDate(item.lastWorkingDate)
                : "",
          };
          list.push(obj);
          let exportObj = {
            empcode: item.code,
            name: item.name,
            empdesignation: item.designation,
            department: item.department,
            empjoinigdate: item.joinedOn,
            tmsupervisor: item.supervisor?.name,
            tmsrsupervisor: item.srSupervisor?.name,
            dob: item.dob,
            phone: item.phoneNumber,
            email: item.email,
            salary: item.salary,
            status: item.status,
            active: item.active,
            lastwrkdate: item.lastWorkingDate,
          };
          let filterempdesigobj = {
            text: item.designation,
            value: item.designation,
          };
          filterempdesig.push(filterempdesigobj);
          let filterdpmtobj = {
            text: item.department,
            value: item.department,
          };
          filterdpmt.push(filterdpmtobj);
          let filtersupervisorobj = {
            text: item.supervisor?.name,
            value: item.supervisor?.name,
          };
          filtersupervisor.push(filtersupervisorobj);
          let filtersrsupervisorobj = {
            text: item.srSupervisor?.name,
            value: item.srSupervisor?.name,
          };
          filtersrsupervisor.push(filtersrsupervisorobj);
          exportList.push(exportObj);
        });

        setedit(false);
        setExportEmployeeList(exportList);
        setteamList(list);
        setteamListEmp(list);

        const columns = [
          {
            title: "Emp Code",
            dataIndex: "empcode",
            key: "empcode",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name, b.name),
              multiple: 3,
            },
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name1, b.name1),
              multiple: 3,
            },
          },
          {
            title: "Emp Role",
            dataIndex: "empdesignation",
            key: "empdesignation",
            filters: findUnique(filterempdesig, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) =>
              record.empdesignation.indexOf(value) === 0,
          },
          {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: findUnique(filterdpmt, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.department.indexOf(value) === 0,
          },
          {
            title: "Emp Joining Date",
            dataIndex: "empjoinigdate",
            key: "empjoinigdate",
          },
          {
            title: "Emp Supervisor",
            dataIndex: "tmsupervisor",
            key: "tmsupervisor",
            filters: findUnique(filtersupervisor, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) =>
              record.tmsupervisor.indexOf(value) === 0,
          },
          {
            title: "Emp Sr. Supervisor",
            dataIndex: "tmsrsupervisor",
            key: "tmsrsupervisor",
            filters: findUnique(filtersrsupervisor, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) =>
              record.tmsrsupervisor.indexOf(value) === 0,
          },
          {
            title: "Date Of Birth",
            dataIndex: "dob",
            key: "dob",
          },
          {
            title: "Phone Number",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Email ID",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
          },
          {
            title: "Emp Status",
            dataIndex: "tmstatus",
            key: "lastwrkdate",
            render(text, record) {
              return {
                children:
                  record.lastwrkdate == "" ? (
                    <button class="active">{text}</button>
                  ) : (
                    <button class="deactivate">{text}</button>
                  ),
              };
            },
          },
          {
            title: "Account Activation",
            dataIndex: "accntactivation",
            key: "accntactivation",
          },
          {
            title: "Last Working Date",
            dataIndex: "lastwrkdate",
            key: "lastwrkdate",
          },
        ];
        setteamcol(columns);
      }
    });
  }

  function updateStatus(id) {
    APIManager.postAPI(memberIsActive + "?MemberId=" + id, {}, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          getMemberlist();
        }
      }
    );
  }

  const navigate = useNavigate();

  function uploadTeamMemberFile() {
    var input = document.getElementById("teamMemberFile");
    for (var i = 0; i < input.files.length; ++i) { }
    teammemberImport(input.files.item(0));
  }

  function filterDataFromList(textData) {
    setteamList(
      teamlist.filter((person) =>
        person.name1.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
    if (textData == "" && textData?.length == 0) {
      setteamList(teamlistEmp);
    }
  }

  function teammemberImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      memberImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then(() => { });
  }

  return (
    <>
      <div class="row">
        <div class="dis-none">
          <i
            class="fa fa-arrow-left"
            onClick={() => {
              navigate(-1)
            }}
          ></i>
        </div>
        <div class="col-sm-5 pad-lzero">
          <div class="main-title">TEAM MEMBERS</div>
          <br />
          <input
            style={{ display: "none" }}
            type="file"
            id="teamMemberFile"
            name="teamMemberFile"
            multiple={false}
            accept={documentFileType}
            onChange={uploadTeamMemberFile}
          />
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div>

      <div className="row">
        <div className="col-sm-5"></div>
        <div className="col-sm-7 add-new-btnw">
          <div
            class="search"
            style={{ marginLeft: 0 + "px", width: 100 + "%" }}
          >
            <div class="input-group" style={{ display: "block" }}>
              <Input.Search
                allowClear
                placeholder="Search By name"
                onChange={(nameSearch) => {
                  filterDataFromList(nameSearch.target.value);
                }}
                id="input-s"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="common-table">
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-12 text-end">
                <div class="data-export mb-3">
                  <span class="export mb-2">
                    <button class="btn btn-primary mb-0 " for="teamMemberFile">
                      Import
                    </button>
                    <a
                      class="btn btn-primary mx-3"
                      target="_blank"
                      href={GET_Server_URL + "audit/Member.xlsx"}
                    >
                      Download Template
                    </a>
                  </span>

                  <button
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Configuration"
                        );
                      }}
                    >
                      <a class="dropdown-content">Excel</a>
                    </div>
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Configuration"
                        );
                      }}
                    >
                      <a class="dropdown-content">CSV</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          className="table table-bordered table-striped table-hover ps-3 pe-3"
          id="sample"
          columns={teamcol}
          dataSource={teamlist}
          rowSelection={{ type: "checkbox", ...rowSelection }}
          pagination={{
            position: ["bottomRight"],
            showSizeChanger: true,
          }}
        />
      </div>
    </>
  );
};

export default TeamMembers;
