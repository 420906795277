import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import {
  getAssignedProjects,
  getPocoverview,
  getPOCData,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { Progress } from "antd";
import FeatherIcon from "feather-icons-react";
import {
  calculateWorkCompletionPercentage,
  convertDate,
  DDMMMyyyyFormateDateSet,
  fixTwoPlaceDecimal
} from "../../utility/Globals";
import { default as ReactSelect } from "react-select";
import { useNavigate } from "react-router-dom";

function PocOverview(props) {
  const [options, setObject] = useState({});
  const [series, setSeries] = useState([]);
  const [pocList, setPocList] = useState([]);
  const [pocSelected, setPocSelected] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projectSelected, setProjectSelected] = useState("00000000-0000-0000-0000-000000000000");
  const [projectCompleted, setProjectCompleted] = useState(0);
  const [turnaroundTimeDate, setTurnaroundTimeDate] = useState("");
  const [turnaroundTimePercentage, setTurnaroundTimeDatePercentage] = useState(0);
  const [isData, setIsData] = useState(true)
  const domElem = useRef();
  const [listData, setListData] = useState(
    [{ value: "requirementRaised", label: "Requirement Raised", dataValue: "null" },
    { value: "assignWriter", label: "Assign Writer", dataValue: 0 },
    { value: "writingInProgress", label: "Writing In Progress", dataValue: 1 },
    { value: "awaitingContentQC", label: "Awaiting Content QC", dataValue: 3 },
    { value: "assignEditor", label: "Assign Editor", dataValue: 4 },
    { value: "editingInProgress", label: "Editing In Progress", dataValue: 5 },
    { value: "awaitingContentQC2", label: "Awaiting Content QC1", dataValue: 7 },
    { value: "sentForApproval", label: "Sent For Approval", dataValue: 8 },
    { value: "articleCompleted", label: "Article Completed", dataValue: 9 },]
  )
  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: 'none',
      height: 43,
      fontSize: '1.1rem'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121" // Custom colour
    }),
    // menuList: styles => ({
    //   ...styles,
    //   height: '100px'
    // }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
    })
  };
  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      boxShadow: 'none',
      fontSize: '1rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 7px',
      // bottom: '3px',
      Margin: '0'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121",
    }),
    input: (provided) => ({
      ...provided,
      height: '38px',
      Padding: '0',
      Margin: '0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
      // marginbottom: '6px',
      Margin: '0',
      boxShadow: 'none'
    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0',
      width: "auto",
      // minWidth: "100%",
      background: '#fff',
      border: '1px solid #ddd',
      // overflow:"hidden",
      marginLeft: "-70px",
      position: "relative",
      right: "0"
    }),
  };

  const navigate = useNavigate();

  let projectSelectedId;
  useEffect(() => {
    getAllPOCFunction();
  }, [props]);

  function getAllPOCFunction() {
    APIManager.getAPI(getPOCData).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        response.data.map((item) => {
          let object = {
            label: item.name,
            value: item.id
          }
          list.push(object)
        })
        setPocList(list);
        getAssignedProjectsFun(list[0].value);
        setPocSelected(list[0]);
      }
    });
  }

  function getAssignedProjectsFun(pocId) {
    APIManager.getAPI(getAssignedProjects + "pocId=" + pocId).then(
      (response) => {
        if (response && response.status == 200) {
          let list = [];
          let object;
          response.data.map((item) => {
            object = {
              value: item.projectId,
              label: item.project.projectCode,
            }
            list.push(object)
          });
          if(list.length > 0)
          {
            setProjectList(list);
            setProjectSelected(list[0]);
            projectSelectedId = list[0].value;
            getPocoverviewData(list[0].value, pocId);
          }
          else{
            setProjectList([]);
            setProjectSelected("");
            setTurnaroundTimeDatePercentage(0);
            setTurnaroundTimeDate("");
            setSeries([]);
          }
        }
      }
    );
  }

  function getPocoverviewData(id, pocId) {
    let startDate = props?.dataParentToChild[0];
    let endDate = props?.dataParentToChild[1]
    setSeries([]);
    APIManager.postAPI(getPocoverview,
      {
        "projectId": id,
        "pocId": pocId,
        "startDate": convertDate(startDate),
        "endDate": convertDate(endDate)
      }, {}, true
    ).then((response) => {
      if (response && response.status == 200) {
        setTurnaroundTimeDatePercentage(0);
        setTurnaroundTimeDate("");
        setProjectCompleted(fixTwoPlaceDecimal(response.data.table[0]?.projectCompletionStatus));
        setTurnaroundTimeDate(response.data.table1.length ? response.data.table1[0]?.turnAroundTime : "");
        if (response.data.table1[response.data.table1.length - 1]?.createdAt != null) {
          let percent = calculateWorkCompletionPercentage(response.data.table1[0]?.createdAt, response.data.table1[0]?.turnAroundTime)
          setTurnaroundTimeDatePercentage(percent)
        }
        let key = Object.keys(response.data.table2[0]);
        let value = Object.values(response.data.table2[0]);
        // let maxChartData = Math.max(...key) * 0.3 + Math.max(...key);
        let key1 = [];

        let sum = value.reduce(function (x, y) {
          return x + y;
        }, 0);

        sum === 0 ? setIsData(false) : setIsData(true)

        key.map((item) => {
          listData.map((item1) => {
            if (item == item1.value) {
              let labelValueArray = [];
              let labelValue = item1.label.split(" ");

              for (let i = 0; i < labelValue.length; i++) {
                labelValueArray.push(labelValue[i]);
              }

              key1.push(labelValueArray);
            }
          })
        })

        let object = {
          title: {
            text: "",
            align: "left",
          },

          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                zoom: false,
                zoomin: true,
                zoomout: true,
                customIcons: [],
              },
            },
            type: "line",
            events: {
              dataPointSelection: function (event, chartContext, config) {
                let value = key[config.dataPointIndex]
                getParentElemFun(value);
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              borderRadiusApplication: 'end',
              dataLabels: {
                position: "top", // top, center, bottom
              },
              columnWidth: "88%",
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: key1,
            labels: {
              rotate: 0,
              hideOverlappingLabels: false,
            }
          },
          yaxis: {
            // max: maxChartData,
            logBase: 20,
            tickAmount: 8,
            min: 0,
            show: false,
          },
          grid: {
            show: false,
          },
        };
        setObject(object);
        setSeries([
          {
            name: "",
            data: value,
            color: "#42a6f5",
          },
        ]);
      }
    });
  }

  function getParentElemFun(value) {
    listData.map((item) => {
      if (item.value == value) {
        localStorage.setItem("requirementStatus", item.dataValue);
      }
    })
    localStorage.setItem("active", 1);
    navigate('/content-main/requirement-details',{
      state: {
        projectId: projectSelectedId,
        startDate: props.dataParentToChild[0],
        endDate: props.dataParentToChild[1],
      }
    });
  }

  return (
    <>
      <div className="title mt-4 POC">
        <div className="d-flex justify-content-between align-items-center poc-overview  cms_bg_border p-4 flex-wrap">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="cms_fw600 cms_fs18 cms_fc_dark me-3 mb-0">POC Overview</h4>
          </div>
          <div className="d-flex align-items-center poc-select">
            <ReactSelect
              className="write-box cursor-pointer"
              ref={domElem}
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti={false}
              // styles={customStyles}
              styles={customStylesSearch}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              allowSelectAll={true}
              options={pocList}
              value={pocSelected}
              onChange={(e) => {
                setPocSelected(e)
                getAssignedProjectsFun(e.value)
              }}
            ></ReactSelect>
          </div>
        </div>

        <div className="content-assign-project mb-3">
          <div className="assign-project">
            <h4 className="cms_fs16 cms_fw500 cms_fc_black cms_line-height">Assigned Projects</h4>
          </div>
          <ReactSelect
            // className="write-box"
            className="cursor-pointer"
            ref={domElem}
            components={{
              IndicatorSeparator: () => null,
            }}
            isMulti={false}
            styles={customStyles}
            // styles={customStylesSearch}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            allowSelectAll={true}
            options={projectList}
            value={projectSelected}
            onChange={(e) => {
              setProjectSelected(e);
              projectSelectedId=e.value;
              getPocoverviewData(e.value, pocSelected.value);
            }}
          ></ReactSelect>

          <div className="content-status-time bg-white">
            <div className="row p-0">
              <div className="col-md-12 d-flex align-items-center pt-2 flex-wrap completion-turnaround">
                <div className="completion-status cms_bg_border d-flex p-3 me-0 me-sm-3">
                  <div className="cms-dashboard-progress me-3">
                    <Progress
                      type="circle"
                      percent={projectCompleted}
                      strokeWidth={13}
                      strokeColor={"#0691EF"}
                      showInfo={false}
                      style={{ height: "50px" }}
                    />
                  </div>
                  <div className="me-5">
                    <span className="cms_fw500 cms_fs16 cms_line-height cms_fc_dark-black">Completion Status</span>
                    <p className="cms_fs18 cms_fw600 cms_fc_black">{projectCompleted}%</p>
                  </div>
                </div>
                <div className="turnaround-time cms_bg_border d-flex p-3  me-0 me-sm-3">
                  <div className="cms-dashboard-progress me-3">
                    <Progress
                      type="circle"
                      strokeColor={"#0691EF"}
                      percent={turnaroundTimePercentage}
                      showInfo={false}
                      strokeWidth={13}
                      style={{ height: "50px" }}
                    />
                  </div>
                  <div></div>
                  <div className="me-5">
                    <span className="cms_fw500 cms_fs16 cms_line-height cms_fc_dark-black">Turnaround Time</span>
                    <p className="cms_fs18 cms_fw600 cms_fc_black">{turnaroundTimeDate != "" ? DDMMMyyyyFormateDateSet(turnaroundTimeDate) : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="portlet-body position-relative content-chart mt-3 px-0">
            {
              isData ?
                (<Chart
                  className="cms_overview_chart"
                  options={options}
                  series={series}
                  type="bar"
                  height="380px"
                />) :
                (
                  <>
                    <div className="d-block text-center poc_no_data my-4 pt-5">
                      <FeatherIcon className="inbox_img" icon="inbox" />
                      <span className="d-block text-center mt-2"> No Data</span>
                    </div>
                  </>

                  // <span className="d-block align-items-center ms-3 text-center"> No Data</span>
                )
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default PocOverview;
