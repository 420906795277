import NotFound from "./NotFound";
import routesV2 from "./Routes";
import RouteWithSubRoutes from "./utils/RouteWithSubRoutes";
import "antd/dist/antd.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import CustomRouter from "./CustomRouter";
import RedirectToExternal from "./components/RedirectToExternal";

function App() {
  let timeout = null;

  const goBackToHome = () => {
    alert('Session Expired!');
    window.location.reload();
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      goBackToHome();
    }, 1000 * 3600);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener('mousemove', onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        console.log(timeout);
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, []);


  return (
    <>
      <div id="loader-main" className="d-none">
        <div className="loader loader-bg d-flex align-items-center justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <BrowserRouter>
        <CustomRouter>
          <Routes>
            <Route path="/" element={<RedirectToExternal />} />
            {routesV2.map((route, i) => (
              <Route path={route.path} element={
                <route.component routes={route.children} />} key={i} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CustomRouter>
      </BrowserRouter>
      <div>
        <Toaster />
      </div>
    </>

  );

}

export default App;
