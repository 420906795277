/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import * as styles from "./styles";
import { Link } from "react-router-dom";
import AssetsImg from "../../../assets/images";
import LeftIconText from "../../leftIconText";
import SubList from "./subList";
import { Home } from "feather-icons-react";
import FeatherIcon from "feather-icons-react";

const Sidebar = ({
  sidebarList,
  isLocked,
  setIsLocked,
  isCollapsed,
  setIsCollapsed,
  activeTab,
  onTabClick,
  isMobileSibeBarOpen,
  closeSideBar,
  defaultSublistExpanded = false
}) => {
  const [isSubListExpanded, setIsSublistExpanded] = useState(defaultSublistExpanded);
  const a = sidebarList;
  return (
    <>
      {isMobileSibeBarOpen && <div css={styles.overlay}></div>}
      <section
        css={styles.mainContainer(isCollapsed, isMobileSibeBarOpen)}
        onMouseMove={() => {
          !isLocked && isCollapsed && setIsCollapsed(false);
        }}
        onMouseLeave={() => !isLocked && !isCollapsed && setIsCollapsed(true)}
      >
        <div css={[styles.headerContainer(isCollapsed)]}>
          {!isCollapsed ? (
            <>
              {" "}
              <Link to="/main/dashboard">
                <div css={styles.brandIconContainer}>
                  <div css={styles.brandIconExpanded}>InfiGrowth</div>
                </div>
              </Link>
              <div
                css={styles.iconContainer}
                onClick={() => {
                  isLocked && setIsCollapsed(true);
                  setIsLocked(!isLocked);
                  closeSideBar();
                }}
              >
                <FeatherIcon
                  icon={"chevron-left"}
                  css={styles.collapseIcon(isLocked)}
                />
              </div>
            </>
          ) : (
            <div css={styles.brandIconContainer}>
              <img src={AssetsImg.ic_brand} css={styles.brandIcon} />
            </div>
          )}
        </div>
        <div css={styles.listContainer} key={sidebarList?.data}>
          {sidebarList?.data?.length > 0 && sidebarList?.data?.map((option, index) => (
            <div key={option.title} css={styles.title(isCollapsed)}>
              <LeftIconText
                icon={option.icon}
                activeIcon={option.activeIcon}
                inactiveIcon={option.inactiveIcon}
                text={option.title}
                isCustomIcon={option.isCustom}
                isPngIcon={option.isPngIcon}
                showArrow={option.subMenu.length > 0}
                isActive={activeTab == index}
                link={option.link}
                isCollapsed={isCollapsed}
                onPress={() => {
                  if (index === parseInt(activeTab)) {
                    setIsSublistExpanded(!isSubListExpanded);
                  } else {
                    setIsSublistExpanded(true);
                    onTabClick(index);
                  }
                }}
                isSubListExpanded={isSubListExpanded}
                openInNewTab={!!option.openInNewTab}
              />
              {option.subMenu && option.subMenu.length > 0 && (
                <SubList
                  list={option.subMenu}
                  isCollapsed={isCollapsed}
                  isExpanded={(activeTab == index) && isSubListExpanded}
                  onPress={(subIndex) => {
                    onTabClick(index, subIndex)
                  }}
                  activeTab={activeTab}
                />
              )}
            </div>
          ))}
          {/* <LeftIconText
            icon={"home"}
            text={"SEO Reports"}
            showArrow={true}
            isActive={activeTab == "main/dashboard"}
            link={"main/dashboard"}
            isCollapsed={isCollapsed}
            onPress={() => setActiveTab("main/dashboard")}
          />
          <SubList
            list={sublist}
            isCollapsed={isCollapsed}
            isExpanded={activeTab == "main/dashboard"}
            onPress={()=> {
               setActiveTab("main/dashboard")
            }}
          />
          <LeftIconText
            icon={"cpu"}
            iconActive={AssetsImg.ic_home_white}
            text={"Bandwidth"}
            showArrow={false}
            isActive={activeTab === "main/bandwidth"}
            link={"main/bandwidth"}
            isCollapsed={isCollapsed}
            onPress={() => {
              setActiveTab("main/bandwidth")
            }}
          /> */}
        </div>
      </section>
    </>
  );
};

export default Sidebar;
