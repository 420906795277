import { css } from "@emotion/react";
import { Colors } from "../../../styles/colors"
import { textStyles } from "../../../styles/font"


export const main = css({
    padding: "1.5rem",
    border: `1px solid ${Colors.neutral[50]}`,
    borderRadius: "1rem",
    boxShadow: Colors.cardShadow2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".___SToggle_8rqtn_gg_.__checked_8rqtn_gg_._theme_success_8rqtn_gg_" :{
        backgroundColor: Colors.semantic[500]
    }
});

export const wrapper = css({
    flex:1
})

export const header = css({
    ...textStyles.h6,
    color: Colors.neutral[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1.5rem"
})

export const icon = css({
    height: "1.5rem",
    width: "1.5rem"
})

export const popper = css({
   boxShadow: Colors.cardShadow4,
   display: "flex",
   flexDirection: "column",
   borderRadius : "0.35rem",
   zIndex: 99,
   background : Colors.plainWhite
})

export const option = css({
    ...textStyles.s3,
    color: Colors.neutral[900],
    padding: "0.5rem",
    cursor: "pointer",
    ":hover" :{
        background: Colors.primary[75]
    }
})
export const detail = css({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    columnGap: "1.5rem",
    ":nth-child(7)" : {
        borderRight : `none`
    }
})

export const detailItem = (isLast) => css({
    minWidth: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRight : isLast ? "none" : `1px solid ${Colors.neutral[50]}`
})

export const detailLabel = css({
    ...textStyles.b2,
    color: Colors.neutral[800]
})

export const data = css({
    ...textStyles.h6,
    color: Colors.neutral[900],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
})

export const active = css({
    color: Colors.semantic[500]
})

export const deactive = css({
    color: Colors.secondary[600]
})