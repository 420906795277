import React, { useState } from "react";
import { getCheckPermission } from "../../utility/Globals";
import TeamMembersSalesDir from "../TeamMembersSalesDir";
import AccessPermissions from "./AccessPermissions";
import AuditChecklist from "./AuditChecklist";
import City from "./City";
import Country from "./Country";
import Credentials from "./Credentials";
import CustomizeDashboard from "./CustomizeDashboard";
import Department from "./Department";
import Industries from "./Industries";
import SOWActivities from "./SOWActivities";

const Settings = ({ update }) => {
  const [settings, setSetting] = useState("");

  function getComponent() {
    if (settings === "credentials") {
      return <Credentials update={onClickFunction} />;
    } else if (settings === "country") {
      return <Country update={onClickFunction} />;
    } else if (settings === "city") {
      return <City update={onClickFunction} />;
    } else if (settings === "department") {
      return <Department update={onClickFunction} />;
    } else if (settings === "user") {
      return <TeamMembersSalesDir update={onClickFunction} />;
    } else if (settings === "accessPermission") {
      return <AccessPermissions update={onClickFunction} />;
    } else if (settings === "department") {
      return <Department update={onClickFunction} />;
    } else if (settings === "auditChecklist") {
      return <AuditChecklist update={onClickFunction} />;
    } else if (settings === "customizeDashboard") {
      return <CustomizeDashboard update={onClickFunction} />;
    } else if (settings === "industries") {
      return <Industries update={onClickFunction} />;
    } else if (settings === "sowActivities") {
      return <SOWActivities update={onClickFunction} />;
    }
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
  };

  return (
    <>
      {settings === "" ? (
        <div className="setting_configuration_tab">
          <div className="portlet setting-popup p-0  ">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => {
                update("");
              }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 className="portlet-title ">
                {" "}
                {localStorage.getItem("organizationName")} Settings
              </h3>
            </div>

            <div className="row">
              {/* <div className="d-flex align-items-center justify-content-between setting-tab"> */}
              {getCheckPermission("credentials", "organization_setup") ?
                <div
                  className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                  onClick={() => {
                    setSetting("credentials");
                  }}
                >
                  <div className="border d-flex align-items-center setting-tab  p-3">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="credential-icon me-3"
                    >
                      <path
                        d="M12.5 16.5C13.0933 16.5 13.6734 16.3241 14.1667 15.9944C14.6601 15.6648 15.0446 15.1962 15.2716 14.6481C15.4987 14.0999 15.5581 13.4967 15.4424 12.9147C15.3266 12.3328 15.0409 11.7982 14.6213 11.3787C14.2018 10.9591 13.6672 10.6734 13.0853 10.5576C12.5033 10.4419 11.9001 10.5013 11.3519 10.7284C10.8038 10.9554 10.3352 11.3399 10.0056 11.8333C9.67595 12.3266 9.5 12.9067 9.5 13.5C9.5 14.2957 9.81607 15.0587 10.3787 15.6213C10.9413 16.1839 11.7044 16.5 12.5 16.5ZM12.5 12C12.7967 12 13.0867 12.088 13.3334 12.2528C13.58 12.4176 13.7723 12.6519 13.8858 12.926C13.9994 13.2001 14.0291 13.5017 13.9712 13.7926C13.9133 14.0836 13.7704 14.3509 13.5607 14.5607C13.3509 14.7704 13.0836 14.9133 12.7926 14.9712C12.5017 15.0291 12.2001 14.9994 11.926 14.8858C11.6519 14.7723 11.4176 14.58 11.2528 14.3334C11.088 14.0867 11 13.7967 11 13.5C11 13.1022 11.158 12.7206 11.4393 12.4393C11.7206 12.158 12.1022 12 12.5 12Z"
                        fill="black"
                      />
                      <path d="M11 4.5H14V6H11V4.5Z" fill="black" />
                      <path
                        d="M18.5 1.5H6.5C6.1023 1.5004 5.721 1.65856 5.43978 1.93978C5.15856 2.221 5.0004 2.6023 5 3V21C5.00046 21.3977 5.15864 21.7789 5.43984 22.0602C5.72105 22.3414 6.10232 22.4995 6.5 22.5H18.5C18.8977 22.4995 19.2789 22.3413 19.5601 22.0601C19.8413 21.7789 19.9995 21.3977 20 21V3C19.9995 2.60232 19.8414 2.22105 19.5602 1.93984C19.279 1.65864 18.8977 1.50046 18.5 1.5ZM15.5 21H9.5V19.5C9.5 19.3011 9.57902 19.1103 9.71967 18.9697C9.86032 18.829 10.0511 18.75 10.25 18.75H14.75C14.9489 18.75 15.1397 18.829 15.2803 18.9697C15.421 19.1103 15.5 19.3011 15.5 19.5V21ZM17 21V19.5C17 18.9033 16.7629 18.331 16.341 17.909C15.919 17.4871 15.3467 17.25 14.75 17.25H10.25C9.65326 17.25 9.08097 17.4871 8.65901 17.909C8.23705 18.331 8 18.9033 8 19.5V21H6.5V3H18.5V21H17Z"
                        fill="black"
                      />
                    </svg>
                    <label className="form-label pt-3">Credentials</label>
                  </div>
                </div>
                : <></>}
              <div


                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (getCheckPermission("country", "organization_setup")) {
                    setSetting("country");
                  }
                }}
              >
                <div className="border d-flex align-items-center setting-tab  p-3">
                  <img class="setting-icon me-3" src="/images/country.png" alt="" />
                  <label className="form-label pt-3">Country</label>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (getCheckPermission("city", "organization_setup")) {
                    setSetting("city");
                  }
                }}
              >
                <div className="border d-flex align-items-center setting-tab p-3">
                  <img class="setting-icon me-3" src="/images/city.png" alt="" />
                  <label className="form-label pt-3">City</label>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (getCheckPermission("roles", "organization_setup")) {
                    setSetting("department");
                  }
                }}
              >
                <div className="border d-flex align-items-center setting-tab  p-3">
                  <img class="setting-icon me-3" src="/images/roles.png" alt="" />
                  <label class="form-label pt-3 mb-2">Roles</label>
                </div>
              </div>
              {/* </div> */}
              {/* </div>


            <div className="row mt-3"> */}
              {/* <div className="d-flex align-items-center justify-content-between setting-tab"> */}
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (getCheckPermission("users", "organization_setup")) {
                    setSetting("user");
                  }
                }}
              >
                <div className="border d-flex align-items-center setting-tab p-3">
                  <img class="setting-icon me-3" src="/images/user.png" alt="" />
                  <label className="form-label pt-3">User</label>
                </div>
              </div>
              {getCheckPermission(
                "access_permission",
                "organization_setup"
              ) ?
                <div
                  className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                  onClick={() => {
                    setSetting("accessPermission");
                  }}
                >
                  <div className=" border d-flex align-items-center setting-tab p-3">
                    <img
                      class="setting-icon me-3"
                      src="/images/access-permission.png"
                      alt=""
                    />
                    <label className="form-label pt-3">Access Permissions</label>
                  </div>
                </div>
                : <></>}
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (
                    getCheckPermission(
                      "audit_checklist",
                      "organization_setup"
                    )
                  ) {
                    setSetting("auditChecklist");
                  }
                }}
              >
                <div className="border d-flex align-items-center setting-tab p-3">
                  <img
                    class="setting-icon me-3"
                    src="/images/audit-checklist.png"
                    alt=""
                  />
                  <label className="form-label pt-3">Audit Checklist</label>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (
                    getCheckPermission(
                      "sow_activities",
                      "organization_setup"
                    )
                  ) {
                    setSetting("sowActivities");
                  }                  
                }}
              >
                <div className=" border d-flex align-items-center setting-tab  p-3">
                  <img
                    class="setting-icon me-3"
                    src="/images/customize-dashboard.png"
                    alt=""
                  />
                  <label className="form-label pt-3">
                    SOW Activities
                  </label>
                </div>
              </div>
              {/* </div> */}
              {/* </div>
            <div className="row mt-3"> */}
              {/* <div className="d-flex align-items-center justify-content-between  p-0 pe-5"> */}
              <div
                className="col-lg-4 col-md-4 col-xl-3 col-sm-6 col-12 mt-4"
                onClick={() => {
                  if (
                    getCheckPermission(
                      "industry",
                      "organization_setup"
                    )
                  ) {
                    setSetting("industries");
                  }
                }}
              >
                <div className="setting-tab border d-flex align-items-center p-3">
                  <img class="setting-icon me-3" src="/images/industry.png" alt="" />
                  <label className="form-label pt-3 mb-2">Industries</label>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
};

export default Settings;
