import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import toast from "react-hot-toast";
import {
  createMember,
  updateMember,
  getDepartmentById,
  getMemberByOrgId,
  getRoleByDept,
  deleteTeamMember,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import { useLocation } from "react-router-dom";

function TeamMembersSalesDirAddUpdate({ update }) {
  const [selectItemObj, setSelectObj] = useState(null);
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");
  const [code, setcode] = useState("");
  const [status, setStatus] = useState(true);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [departmentId, setdepartmentId] = useState("");
  const [joinedOn, setjoinedOn] = useState("");
  const [supervisor, setsupervisor] = useState("");
  const [srSupervisor, setsrSupervisor] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [dob, setdob] = useState("");
  const [email, setemail] = useState("");
  const [salary, setsalary] = useState("");
  const [lastWorkingDate, setLastWorkingDate] = useState("");
  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  const [EmpSrSupervisorselected, setEmpSrSupervisorselected] = useState("");
  const location = useLocation();
  const [isUpdateTM, setUpdateTMflag] = useState(false);
  const [errors, setErrors] = useState({});
  const [save, setsave] = useState(false);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    if (location.state.isUpdate) {
      setpassword(location.state.set_password);
      setUpdateTMflag(true);
      setSelectObj(location.state.set_SelectObj);
      setname(location.state.set_name);
      setcode(location.state.set_code);
      setStatus(location.state.set_Status);
      setSelectedDesignation(location.state.set_designation);
      setdepartmentId(location.state.set_departmentId);
      setjoinedOn(location.state.set_joinedOn);
      setsupervisor(location.state.set_supervisor);
      setsrSupervisor(location.state.set_srSupervisor);
      setdob(location.state.set_dob);
      setphoneNumber(location.state.set_phoneNumber);
      setemail(location.state.set_email);
      setsalary(location.state.set_salary);
      setLastWorkingDate(location.state.set_LastWorkingDate);
      setmemberlistSelected(location.state.set_memberlistSelected);
      setEmpSrSupervisorselected(location.state.set_EmpSrSupervisorselected);
      setselectedTeamStatus(location.state.set_AuthenticationType);
    } else {
      setUpdateTMflag(false);
    }
    getDepaertment();
    getTeamMemberList();
  }, []);

  function formInputValidation() {
    let error = { ...errors };
    code.trim() === ""
      ? (error["code"] = "Employee code is required!")
      : delete error["code"];


    name.trim() === ""
      ? (error["name"] = "Employee name is required!")
      : delete error["name"];

    if (name?.length) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9_&@?()\\\-"/,._:'\s]*$/
      );

      if (pattern?.test(name) === false) {
        error["name"] = "Please enter valid Employee name!";
      }
    }
    joinedOn.trim() === ""
      ? (error["joinedOn"] = "Employee joining date is required!")
      : delete error["joinedOn"];

    if (joinedOn !== "" && lastWorkingDate !== "") {
      if (joinedOn > lastWorkingDate) {
        error["joinedOn"] =
          "Employee joining date should be less then last working date.";
      } else {
        delete error["joinedOn"];
      }
    }
    dob.trim() === ""
      ? (error["dob"] = "Employee date of birth is required!")
      : delete error["dob"];
    phoneNumber.toString().trim() === ""
      ? (error["phoneNumber"] = "Employee phone number is required!")
      : delete error["phoneNumber"];

    if (phoneNumber?.length) {
      var pattern1 = new RegExp(/^[0-9\b]+$/);
      if (!pattern1.test(phoneNumber)) {
        error["phoneNumber"] = "Please enter only number";
      } else if (phoneNumber.length >= 15) {
        error["phoneNumber"] =
          "Phone number must not exceed more than 15 characters.";
      } else if (phoneNumber.length < 10) {
        error["phoneNumber"] =
          "Phone number must be of at least 10 characters.";
      } else {
        delete error["phoneNumber"];
      }
    }

    email.trim() === ""
      ? (error["email"] = "Employee email is required!")
      : delete error["email"];
    if (email?.length) {
      var pattern2 = new RegExp(/^\w+([-+.']?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
      if (pattern2?.test(email) === false) {
        error["email"] = "Please enter valid Email!";
      }
    }

    memberlistSelected.trim() === "" ? (error["memberlistSelected"] = "Employee Supervisor is required!")
      : delete error["memberlistSelected"];

    EmpSrSupervisorselected.trim() === "" ? (error["EmpSrSupervisorselected"] = "Employee Sr. Supervisor is required!")
      : delete error["EmpSrSupervisorselected"];

    salary === "" ? (error["salary"] = "Salary is required!")
      : delete error["salary"];
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function createTeamMember() {
    APIManager.postAPI(
      createMember,
      {
        name: name.trim(),
        code: code.trim(),
        designation: selectedDesignation,
        roleId: selectedDesignation,
        joinedOn: joinedOn,
        dob: dob,
        active: false,
        phoneNumber: phoneNumber.trim(),
        email: email.trim(),
        DepartmentId: departmentId,
        salary: salary ? salary : "0",
        supervisorId: supervisor === "Select Supervisor" ? "" : supervisor,
        srSupervisorId: srSupervisor === "Select Supervisor" ? "" : srSupervisor,
        organizationId: localStorage.getItem("organizationId"),
        authenticationType: selectedTeamStatus,
        lastWorkingDate: lastWorkingDate,
      },
      null,
      true
    ).then(
      (response) => {
        if (response?.status === 200) {
          if (response?.data?.message) {
            toast.error(response.data.message);
          } else {
            clearForm();
            notifyAdd();
          }
        }
      },
      () => { }
    );
  }

  function updateTeamMember() {
    APIManager.putAPI(
      updateMember,
      {
        id: selectItemObj.id,
        name: name.trim(),
        code: code.trim(),
        roleId: selectedDesignation,
        designation: selectedDesignation,
        joinedOn: joinedOn,
        dob: dob,
        active: status,
        phoneNumber: phoneNumber.trim(),
        email: email.trim(),
        departmentId: departmentId,
        salary: salary.toString().trim(),
        supervisorId: memberlistSelected,
        srSupervisorId: EmpSrSupervisorselected,
        lastWorkingDate:
          lastWorkingDate === "Invalid date" ? "" : lastWorkingDate,
        organizationId: localStorage.getItem("organizationId"),
        authenticationType: selectedTeamStatus,
        password: password,
      },
      null,
      true
    ).then((response) => {
      if (response && response?.status === 200 && response?.data) {
        clearForm();
      }
    });
  }

  function getTeamMemberList() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        let listData = [];
        response.data.forEach((item) => {
          if (item?.role?.name === "Customer") {
          } else {
            listData.push(item);
          }
        });
        setmemberlist(listData);
      }
    });
  }

  function setSupervisor(e) {
    var c = e.target.value;
    setmemberlistSelected(c);
    setsupervisor(c);
  }

  function clearForm() {
    setname("");
    setcode("");
    setSelectedDesignation("");
    setjoinedOn("");
    setsupervisor("");
    setsrSupervisor("");
    setdob("");
    setphoneNumber("");
    setemail("");
    setsalary("");
    setLastWorkingDate("");
    setmemberlistSelected("");
    setEmpSrSupervisorselected("");
    update("user");
  }

  function setSrSupermember(e) {
    var c = e.target.value;
    setEmpSrSupervisorselected(c);
    setsrSupervisor(c);
  }

  const [selectedTeamStatus, setselectedTeamStatus] = useState("0");

  function onChangeTeamStatus(e) {
    var c = e.target.value;
    setselectedTeamStatus(c);
  }

  const [getRole, setgetRole] = useState([]);
  const [getDepartment, setgetDepartment] = useState([]);

  function getDepaertment() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          if (item.name !== "Freelancer") {
            let obj = {
              id: item.id,
              role: item.name,
            };
            list.push(obj);
          }
        });
        setgetDepartment(list);
        if (location.state.isUpdate === false) {
          setdepartmentId(list[0].id);
          getRolesData(list[0].id, false);
        } else {
          getRolesData(location.state.set_departmentId, false);
        }
      }
    });
  }
  function getRolesData(departmentId, departmentChange) {
    APIManager.getAPI(getRoleByDept + departmentId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.forEach((item) => {
          if (item.name !== "Editor" && item.name !== "Writer") {
            let obj = {
              id: item.id,
              role: item.name,
            };
            list.push(obj);
          }

        });
        setgetRole(list);

        if (location.state.isUpdate) {
          setSelectedDesignation(location.state.set_designation);
        }
        else {
          setSelectedDesignation(list[0].id);
        }
        if (location.state.isUpdate && departmentChange === true) {
          setSelectedDesignation(list[0].id);
        }
      }
    });
  }

  async function deleteTeamMemberFun() {
    try {
      const response = await APIManager.deleteAPIUser(deleteTeamMember + selectItemObj.id);
      if (response === 200) {
        clearForm();
      }
    } catch (e) { }
  }

  return (
    <>
      <div className="portlet slideInUp setting-top">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => {
            update("user");
          }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} -
            {isUpdateTM ? "Update " : "Add "}Team Member
          </h3>
          
        </div>
        <div className="portlet-body">
          <div class="common-table">
            <div class="common-wcard">
              <div class="common-form-fields">
                <div class="add-user">
                  <div class="col-md-12">
                    <label className="form-label"> Emp Code </label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      code=""
                      placeholder=""
                      required
                      value={code}
                      onChange={(text) => {
                        setcode(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.code && (
                    <>
                      <div className="field-required">
                        <span> {errors?.code}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Name</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder=""
                      required
                      value={name}
                      onChange={(text) => {
                        setname(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.name && (
                    <>
                      <div className="field-required">
                        <span> {errors?.name}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Department</label>
                    <select className="form-select"
                      value={departmentId}
                      required
                      onChange={(e) => {
                        setdepartmentId(e.target.value);
                        getRolesData(e.target.value, true);

                      }}
                    >
                      {getDepartment.map((item) => {
                        return <option value={item.id}>{item.role}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Designation</label>
                    <label class="field-required"> * </label>
                    <select className="form-select"
                      required
                      value={selectedDesignation}
                      id="Designationdropdown"
                      onChange={(e) => {
                        setSelectedDesignation(e.target.value);
                        formInputValidation();
                      }}
                    >
                      {getRole.map((item) => {
                        return <option value={item.id}>{item.role}</option>;
                      })}
                    </select>
                  </div>
                  {save && errors?.selectedDesignation && (
                    <>
                      <div className="field-required">
                        <span> {errors?.selectedDesignation}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Emp Joining Date</label>
                    <label class="field-required"> * </label>
                    <input
                      type="date"
                      joinedOn=""
                      placeholder=""
                      required
                      data-date-format="DD MMMM YYYY"
                      value={joinedOn}
                      onChange={(text) => {
                        setjoinedOn(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.joinedOn && (
                    <>
                      <div className="field-required">
                        <span> {errors?.joinedOn}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Emp Supervisor</label>
                    <label class="field-required"> * </label>
                    <select className="form-select" value={memberlistSelected} onChange={setSupervisor}>
                      <option value="">Select Emp Supervisor</option>
                      {memberlist.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                    {save && errors?.memberlistSelected && (
                      <>
                        <div className="field-required">
                          <span> {errors?.memberlistSelected}</span>
                        </div>
                      </>
                    )}
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Emp Sr. Supervisor</label>
                    <label class="field-required"> * </label>
                    <select className="form-select"
                      value={EmpSrSupervisorselected}
                      onChange={setSrSupermember}
                    >
                      <option value="">Select Emp Sr. Supervisor</option>
                      {memberlist.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                    {save && errors?.EmpSrSupervisorselected && (
                      <>
                        <div className="field-required">
                          <span> {errors?.EmpSrSupervisorselected}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Date Of Birth</label>
                    <label class="field-required"> * </label>
                    <input
                      type="date"
                      dob=""
                      required
                      placeholder=""
                      value={dob}
                      data-date-format="DD MMMM YYYY"
                      onChange={(text) => {
                        setdob(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.dob && (
                    <>
                      <div className="field-required">
                        <span> {errors?.dob}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Phone Number</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      phoneNumber=""
                      placeholder=""
                      required
                      value={phoneNumber}
                      onChange={(text) => {
                        setphoneNumber(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.phoneNumber && (
                    <>
                      <div className="field-required">
                        <span> {errors?.phoneNumber}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Email</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      email=""
                      placeholder=""
                      required
                      value={email}
                      onChange={(text) => {
                        setemail(text.target.value);
                        formInputValidation();
                      }}
                    />
                  </div>
                  {save && errors?.email && (
                    <>
                      <div className="field-required">
                        <span> {errors?.email}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Salary</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      salary=""
                      placeholder=""
                      value={salary}
                      onChange={(text) => {
                        setsalary(text.target.value);
                      }}
                    />
                    {save && errors?.salary && (
                      <>
                        <div className="field-required">
                          <span> {errors?.salary}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Authentication Type</label>
                    <select className="form-select"
                      value={selectedTeamStatus}
                      onChange={onChangeTeamStatus}
                    >
                      <option value="0">Custom</option>
                      <option value="1">Google</option>
                    </select>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Last Working Date</label>
                    <input
                      type="date"
                      lastWorkingDate=""
                      placeholder=""
                      data-date-format="DD MMMM YYYY"
                      value={lastWorkingDate}
                      onChange={(text) => {
                        setLastWorkingDate(text.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="left-btn d-flex align-items-center">
                <button
                  onClick={() => {
                    setsave(true);
                    if (formInputValidation()) {
                      isUpdateTM ? updateTeamMember() : createTeamMember();
                    }
                  }}
                  className="btn btn-primary me-3"
                >
                  {isUpdateTM ? "Update" : "Save"}
                </button>
                <button
                  onClick={() => {
                    clearForm();
                  }}
                  className="btn btn-outline-secondary"
                >
                  Cancel
                </button>
              </div>
              <div className="right-btn">
                {isUpdateTM ?
                  <button
                    onClick={() => {
                      deleteTeamMemberFun()
                    }}
                    className="btn btn-outline-danger"
                  >
                    Delete
                  </button> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamMembersSalesDirAddUpdate;
