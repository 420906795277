import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";

export const main = css({ 
    
});

export const inputGroup = css({
    display: "flex",
    columnGap: "0.62rem",
    alignItems: "center"

})

export const input = css({
    ...textStyles.b3,
      width: "34.375rem;",
  })

export const inputContainer = css({ 
    width: "4.81rem"
});