import { css } from "@emotion/react";
import { Colors } from "../../../../styles/colors";
import { textStyles } from "../../../../styles/font";
import { Color } from "@progress/kendo-drawing";

export const popper = () =>  css({
    backgroundColor: Colors.plainWhite,
    minWidth: "17.3rem",
    boxShadow: Colors.cardShadow4,
  });

export const text = css({
  ...textStyles.b3,
});

export const optionContainer = css({
  width: "100%",
  display: "block",
  padding: "0.38rem 0.5rem !important",
  minHeight: "auto !important",
  cursor: "pointer",
  ":hover": {
    backgroundColor: Colors.primary[50]
  },
  // backgroundColor: Colors.primary[50],
  // borderLeft: `3px solid ${Colors.primary[500]}`,
});


export const optionHeader = css({
  ...textStyles.b2,
  width: "100%",
  color: `${Colors.neutral[900]} !important`,
});

export const tooltip = css({
  ...textStyles.b3,
  padding: "0.5rem !important",
  minWidth: "10rem",
  textAlign: "left"
});

export const infoIcon = css({
  width: "1rem",
  height: "1rem",
  marginLeft: "0.25rem"
})

export const secondaryColor = css({
  color: `${Colors.neutral[600]} !important`
});