import React, { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-pro-react";
import {
  GET_FrontEnd_URL,
  GET_Server_URL,
  addcomment,
  addsuggestion,
  getcomment,
  getdescription,
  getsuggesstion,
  getVersionHistory,
  editorETA,
  updatecommentstatus,
  updatesuggesstionstatus,
  adddescription,
  updateRequirementStatus,
  commentreply,
  requirementById,
  getProjectById,
  checklist,
  getMemberData,
  getAllKeywords,
  emailForApprovalCMS,
  deletecomment,
  deletesuggestion,
  latestVersion,
  deletecommentreply
} from "../../utility/Constant";
import {
  formateDateIntoDatemonthTime,
  formateDateIntoDatemonth,
  editorArticleStatus,
} from "../../utility/Globals";
import FeatherIcon from "feather-icons-react";
import APIManager from "../../services/APIManager";
import { Space } from "antd";
import { toast } from "react-hot-toast";
import { TabPanel } from "react-tabs";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

function EditorContent(props) {
  const [Editor, setEditor] = useState(true)
  const [complete, setComplete] = useState(false);
  const [instruction1, setInstruction] = useState("");
  const [buttonclick, setbuttonclick] = useState(0);
  const [versionhistory, setversionhistory] = useState(0);
  const [projectname, setProjectName] = useState();
  const [keywordId, setKeywordId] = useState(); 
  const navigate = useNavigate();
  const notifyAdd = () => toast.success("Successfully Added!");
  const [suggestionlist, setsuggestionlist] = useState([]);
  const [commentlist, setcommentlist] = useState([]);
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [commentupdatestatus, setcommentupdatestatus] = useState(false);
  const [comment, setComment] = useState("");
  const [suggestion, setsuggestion] = useState("");
  const [primaryKeyword, setPrimaryKeyword] = useState("");
  const [status, setStatus] = useState();
  const [replyList, setReplyList] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const editorRef = useRef(null);
  const closeBtn = useRef(null);
  const closesugBtn = useRef(null);
  const [ETA, setETA] = useState();
  const [memberList, setMemberList] = useState([]);
  const [CommentReply, setCommentReply] = useState("");
  const [commenthistory, setcommenthistory] = useState(false);
  const [commentcount, setcommentcount] = useState("");
  const [suggestioncount, setsuggestioncount] = useState("");
  const [suggestionhistory, setsuggestionhistory] = useState(false);
  const [descriptionChange, setDescriptionChange] = useState(false);
  const [proximityCount, setProimityCount] = useState(0);
  const [articlestatus, setArticleStatus] = useState("");
  const [proximityText, setProximityText] = useState([]);
  const [isreply, setIsreply] = useState(false);
  const [description, setDescription] = useState('');
  const [checkList, setCheckList] = useState([]);
  const [keywords, setKeywords] = useState();
  const [text, setText] = useState('');
  const [wordArticle, setWordArticle] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [isCommentSuggestionAdded, setIsCommentSuggestionAdded] = useState(null);
  const [versionCount, setVersionCount] = useState("");
  const [projectId, setProjectId] = useState("");
  const [characterCount, setcharacterCount] = useState(0);
  const [commentEdit , setCommentEdit] = useState(false);
  const [commentId , setCommentId]=useState('');
  const [commentEditReply , setCommentEditReply] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [CommentReplyObj, setCommentReplyObj] = useState();
  const [replyId ,setReplyId] = useState();
  const [isSave, setIsSave] = useState(false);
  const [editComment ,setEditComment] = useState("");
  // var highlighter = new Highlighter();
  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      'paragraph', '|',
      "fontsize",
      "font",
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "brush",
      "pencil",
      "|",
      "image",
      "link",
      "ol",
      "ul",
      "align",
      "|",
      "indent",
      "outdent",
      "hr",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "strikethrough",],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    defaultActionOnPaste: 'insert_only_text',
    defaultActionOnPasteFromWord: 'insert_only_text ',
    cursorAfterAutofocus: 'end',
    // autofocus : true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    disabled: selectedVersion == 0 ?  false : true,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
    enter: "div",
    pasteHTMLActionList: [
      { text: "insert_only_text", value: "insert_only_text" },
    ],
    pasteFromWordActionList: [
      { text: "insert_only_text", value: "insert_only_text" },
    ],
  };

  const params = new URLSearchParams(window.location.search);
  let requirementId = params.get("requirementid");
  useEffect(() => {
    setEditor(true);
    setIsCommentSuggestionAdded(null);
    if (localStorage.length === 0) {
      sessionStorage.setItem("requirementid", requirementId);
      navigate("/login");
    } else {
      if ((status != 0) || (status != 4)) {
        getEditorETA();
      }
      getDescription();
      getcommentlist();
      getsuggestion();
      getMemberList();
      document.getElementById("comment-icon").style.display = "none"
      localStorage.getItem("userDatadepartmentName") === "Customer" ? setEditor(false) : setEditor(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (e.target.parentElement.className == "jodit-wysiwyg") {
        localStorage.removeItem("highlightedObj");
        if(getSelection().isCollapsed === false && description != "") {
          let obj = {};
          if(getSelection().anchorOffset < getSelection().focusOffset && getSelection().anchorNode.parentElement.localName == getSelection().focusNode.parentElement.localName) {
            obj = {
              startIndex: getSelection().anchorOffset,
              endIndex: getSelection().focusOffset,
              text: getSelection().anchorNode.data?.slice(getSelection().anchorOffset, getSelection().focusOffset).replace(new RegExp(String.fromCharCode(160),"g")," "),
              styleText: getSelection().anchorNode.data?.slice(getSelection().anchorOffset, getSelection().focusOffset)
            }
          }
          else if(getSelection().anchorNode.parentElement.localName == getSelection().focusNode.parentElement.localName){
            obj = {
              startIndex: getSelection().focusOffset,
              endIndex: getSelection().anchorOffset,
              text: getSelection().anchorNode.data?.slice(getSelection().focusOffset, getSelection().anchorOffset).replace(new RegExp(String.fromCharCode(160),"g")," "),
              styleText: getSelection().anchorNode.data?.slice(getSelection().anchorOffset, getSelection().focusOffset)
            }  
          }
          localStorage.setItem("highlightedObj", JSON.stringify(obj));
          if(description == document.querySelector('.jodit-wysiwyg').innerHTML.replace(/&(nbsp|amp|quot);/g, " ") && (localStorage.getItem("userDatadepartmentName") === "Content" && localStorage.getItem("userRole") === "Content") || (localStorage.getItem("userDatadepartmentName") === "Customer"))
            document.getElementById("comment-icon").style.display = "block";
          else if((localStorage.getItem("userDatadepartmentName") === "Content" && localStorage.getItem("userRole") !== "Content") || (localStorage.getItem("userDatadepartmentName") === "Freelancer"))
            document.getElementById("comment-icon").style.display = "block";
        }
        else {
          document.getElementById("comment-icon").style.display = "none";
          localStorage.removeItem("highlightedObj");
          setIsCommentSuggestionAdded(null);
        }
      }
      else if (e.target.parentElement.className == "jodit-workplace" ) {
        document.getElementById("comment-icon").style.display = "none";
        localStorage.removeItem("highlightedObj");
        setIsCommentSuggestionAdded(null);
        setEditor(true);
      }
    });

  }, [description])

  let value = "";
  useEffect(() => {
    if (buttonclick == 3) {
      document.getElementById("commentblock").classList.add("show");
      document.getElementById("commentbutton").classList.remove("collapsed");
    }
    else if (buttonclick == 4) {
      document.getElementById("suggestionblock").classList.add("show");
      document.getElementById("suggestionbutton").classList.remove("collapsed");
    }
    if (isCommentSuggestionAdded && localStorage.getItem("highlightedObj")) {
      setSelectedText();
    }
  }, [Editor, isCommentSuggestionAdded]);

  function Addcomments(commentid) {
    if(commentid != undefined){
      let rawData = {
        RequirementId: requirementId,
        Comment: editComment,
        CommentBy: localStorage.getItem("userId"),
        SelectedDescription: localStorage.getItem("highlightedObj"),
        Id:commentid
      };

      APIManager.postAPI(addcomment, rawData, {}, true).then((response) => {
        if (response && response.status == 200) {
          localStorage.removeItem("highlightedObj");
          setcommentlist([]);
          setCommentId();
          setCommentEditReply(false);
          setCommentEdit(false)
          setCommentReplyObj();
          setCommentReply("");
          clearForm();
          setIsCommentSuggestionAdded(null);
          notifyAdd();
          getcommentlist();
          setInstruction(description);
          document.getElementById("commentblock").classList.add("show");
          document.getElementById("commentbutton").classList.remove("collapsed");
          document.getElementById("comment-icon").style.display = "none";
          clearForm();
        }
      });
    } else {
      if(descriptionChange)
        AddDescription(description);
      let rawData = {
        RequirementId: requirementId,
        Comment: comment,
        CommentBy: localStorage.getItem("userId"),
        SelectedDescription: localStorage.getItem("highlightedObj")
      }

      APIManager.postAPI(addcomment, rawData, {}, true).then((response) => {
        if (response && response.status == 200) {
          localStorage.removeItem("highlightedObj");
          setIsCommentSuggestionAdded(null);
          notifyAdd();
          getcommentlist();
          setInstruction(description);
          document.getElementById("commentblock").classList.add("show");
          document.getElementById("commentbutton").classList.remove("collapsed");
          document.getElementById("comment-icon").style.display = "none";
          clearForm();
          setDescriptionChange(false);
        }
      });
   }
  }

  function addCommentReply(id, replyId) {
    let rawData = {}
    if(replyId == '')
    {
      rawData = {
        CommentId: id,
        CommentReply: CommentReply,
        commentReplyBy: localStorage.getItem("userId") 
      };
    } else {
      rawData = {
        CommentId: replyId,
        CommentReply: CommentReply,
        commentReplyBy: localStorage.getItem("userId"),
        id: id 
      };
    } 
    
    APIManager.postAPI(commentreply, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        getcommentlist();
        setCommentEditReply(false);
        setCommentReplyObj();
        setCommentReply("");
        setIsreply(false);
        clearForm();
      }
    });
  }

  function Addsuggestion() {
    if(descriptionChange)
        AddDescription(description);
    delete suggestion.showText; 
    let rawData = {
      RequirementId: requirementId,
      Suggestion: JSON.stringify(suggestion),
      SuggestionBy: localStorage.getItem("userId"),
    };
    APIManager.postAPI(addsuggestion, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        notifyAdd();
        getsuggestion();
        localStorage.removeItem("highlightedObj");
        document.getElementById("comment-icon").style.display = "none";
        setIsCommentSuggestionAdded(null);
        document.getElementById("suggestionblock").classList.add("show");
        document.getElementById("suggestionbutton").classList.remove("collapsed");
        setEditor(true);
        clearForm();
        setInstruction(description);
        setDescriptionChange(false);
      }
    });
  }

  const showReply = (index) => {
    document.getElementById("reply" + index).classList.remove("d-none");
    document.getElementById("comment" + index).classList.add("d-none");
  };

  const hideReply = (index) => {
    document.getElementById("reply" + index).classList.add("d-none");
    document.getElementById("comment" + index).classList.remove("d-none");
  };

  function getcommentlist() {
    APIManager.getAPI(getcomment + requirementId).then((response) => {
      if (response && response.status == 200) {
        setcommentlist(response.data);
        setcommentcount(response.data.length);
        let viewReplays = [];

        response.data.map((item, index) => {
          if (item.status == 1) {
            let obj = {
              reply: (
                <>
               
                  <div className="addcoment_block  mt-2 px-0">
                    <div className="comenter_name border_gray justify-content-between align-items-center">
                      <div class="d-flex align-items-center flex-wrap">
                        <h4 className="dark_color fw-bold fs-6 m-0 me-2 ">
                          {item?.members?.name}
                        </h4>
                        <div>
                        <span className="date_time ">
                            {formateDateIntoDatemonthTime(item.createdAt)}
                          </span>
                          </div>
                          </div>
                          <div className="view_history_section">
                        <span className="view_history_comment">{item.comment}</span>
                        </div>
                      
                      <div className="border-top mb-2 mt-2"></div>
                      <div id={"comment" + index}>
                        <div
                          className="col-md-12 d-flex align-items-center ps-0"
                          onClick={() => {
                            showReply(index);
                          }}
                        >
                          <p className="d-flex align-items-center my-2 cms_fs16 cms_fw500 cms_fc_gray ">
                            View All Replies
                          </p>
                           <FeatherIcon className="history_hide mt-1" icon="chevron-down" />
                        </div>
                        <div className="border-top mb-2 mt-1"></div>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="d-flex align-items-center fs-6 fw-5 line-hight24 dark_gray flex-wrap ">
                              <FeatherIcon className="complete-check me-2" icon="check" />
                              {" "}
                            <span className="completed-color fs-6 fw-5 line-hight24">
                              Completed on 
                            </span>
                            <span class="completed_gray_date ms-1">
                              {item.comments}
                            </span>
                          </span>
                          <div>
                            <span class="dropdown">
                              <button className="kabab-btn"
                                type="kabeb button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </button>

                              <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated cms_comment_delete"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div class="dropdown-item d-flex align-items-center dot-drop-color delete position-sticky" onClick={() => {
                                  deleteConfirm(item.id, 0, false)
                                }}>
                                  <span
                                    className=""
                                  >
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div id={"reply" + index} class="d-none">
                        <div class="">
                          {item.editorCommentReplies.map((reply) => {
                            return (
                              <>
                              <div className="d-flex align-items-center">
                                <h4 className="dark_color fw-bold fs-6 m-0">
                                  {reply?.members?.name}
                                </h4>
                                <span className="date_time  ml-2">
                                    {formateDateIntoDatemonthTime(
                                      reply.createdAt
                                    )}
                                  </span>
                                  </div>
                                  <div className="view_history_section">
                                <span className="view_history_comment">{reply.commentReply}</span>
                                </div>
                                <div className="border-top mb-2 mt-1"></div>
                              </>
                            );
                          })}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="d-flex align-items-center fs-6 fw-5 line-hight24 dark_gray  flex-wrap">
                            <FeatherIcon className="complete-check me-2" icon="check" />
                                {" "}
                            <span className="completed-color fs-6 fw-5 line-hight24">
                              Completed on 
                            </span> {" "}
                            <span class="completed_gray_date ms-1">
                            {item.comments}
                            </span>
                          </span>
                          <div>
                              <span class="dropdown">
                                <button className="kabab-btn"
                                  type="kabeb button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>

                                <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated cms_comment_delete"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div class="dropdown-item d-flex align-items-center dot-drop-color delete position-sticky" onClick={() => {
                                    deleteConfirm(item.id, 0, false)
                                  }}>
                                    <span
                                      className=""
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </div>
                        </div>
                        <div className="border-top mb-2 mt-2"></div>
                        <div
                          className="col-md-12 d-flex align-items-center ps-0"
                          onClick={() => {
                            hideReply(index);
                          }}
                        >
                          <p className="d-flex align-items-center my-2 cms_fs16 cms_fw500 cms_fc_gray view-replies">
                            Hide All Replies
                          </p>
                           <FeatherIcon className="history_hide" icon="chevron-up" />
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </>
              ),
            };
            viewReplays.push(obj);
          }
        });
        setReplyList(viewReplays);
      }
    });
  }

  function getsuggestion() {
    APIManager.getAPI(getsuggesstion + requirementId).then((response) => {
      if (response && response.status == 200) {
        setsuggestionlist(response.data);
        setsuggestioncount(response.data.length);
      }
    });
  }

  function getDescription() {
    APIManager.getAPI(getdescription + requirementId).then((response) => {
      if (response && response.status == 200) {
        setInstruction(response.data[0]?.description ?? '');
        setDescription(response.data[0]?.description ?? '')
        getRequirementDetail();
        let desc = response.data[0]?.description;        
        getKeywords(desc);  
        if (desc) {
          let txt = desc.replace(/(<([^>]+)>)/gi, "");
          txt = txt.replace(/&(nbsp|amp|quot);/g, " ");
          let length = txt?.match(/[\w\d\’\',-]+/gi)?.length;
          setcharacterCount(txt.replaceAll(' ', '').length);
          setWordCount(txt?.match(/[\w\d\’\'-]+/gi).length);
          setLastDate(formateDateIntoDatemonthTime(
            response.data[0].createdAt
          ))
          
        }
      }
    });
  }

  function clearForm() {
    setComment("");
    setsuggestion("");
    setCommentReply("");
  }

  function getEditorETA() {
    APIManager.getAPI(editorETA + "requirementId=" + requirementId).then(
      (response) => {
        if (response && response.status == 200) {
          setETA(response.data);
        }
      }
    );
  }

  function getVersionHistoryData() {
    APIManager.getAPI(
      getVersionHistory + "&requirementId=" + requirementId
    ).then((response) => {
      if (response.data.length > 0 && response.status == 200) {
        setVersionHistoryList(response.data);
        setVersionCount(response.data.length);
        setInstruction(response.data[0].editor.description)
        setDescription(response.data[0].editor.description)
      }
    });
  }

  function selectversion (eid){
    versionHistoryList.map((item)=>{
      if(item.editorId == eid)
      {
        setInstruction(item.editor.description)
      }
    })
  }

  function getlastestVersion(id, version, editorId, editor, vid) {
    APIManager.postAPI(
      latestVersion,
      {
        requirementId: id,
        version: version,
        editor: editor,
        editorId: editorId,
        CreatedBy: localStorage.getItem("userId"),
        id: vid

      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getVersionHistoryData();
        getDescription();
        setSelectedVersion(0);
        toast.success("Successfully Restore Version");
      }
    });
  }

  function commentstatus(id, accept) {
    APIManager.putAPI(updatecommentstatus + "?Id=" + id + "&accept=" + accept, {}, {}, true).then(
      (response) => {
        if (response && response.status == 200) {
          setcommentupdatestatus(true);
          getcommentlist();
        }
      }
    );
  }

  function suggestionstatus(id, accept) {
    APIManager.putAPI(updatesuggesstionstatus + "?Id=" + id + "&accept=" + accept, {}, {}, true).then(
      (response) => {
        if (response && response.status == 200) {
          getsuggestion();
        }
      }
    );
  }

  function AddDescription(newtext=null) {
    APIManager.postAPI(
      adddescription,
      {
        RequirementId: requirementId,
        Description: newtext ?? instruction1,
        CreatedBy: localStorage.getItem("userId"),
        WordCount: wordCount,
        ArticleStatus: status,
        CharacterCount : characterCount
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getDescription();
      }
    });
  }

  const requirementStatusUpdate = (status) => {
    if(params.get("requirementid")){
      requirementId = params.get("requirementid");
    }
    let rawData = {
      RequirementId: requirementId,
      status: status,
      userId: localStorage.getItem("userId"),
      isClient: localStorage.getItem("userDatadepartmentName") == "Customer" ? 1 : 0,
    };
    APIManager.putAPI(
      updateRequirementStatus , rawData, {}, true
    ).then((res) => {
      if(localStorage.getItem("userDatadepartmentName") == "Customer")
      {
        getRequirementDetail(); 
      }
      else if (localStorage.getItem("userDatadepartmentName") === "Content" && localStorage.getItem("userRole") === "Content") {
        localStorage.setItem("active", 1);
        navigate("/content-main/requirement-details",{
          state: { requirementId: requirementId, projectId: projectId },
        });
      } else {
        localStorage.setItem("active", 2);
        navigate("/content-main/FreelancersRequirementDetails");
      }
    });
    if (status === 8) {
      sendForApprovalFun(requirementId)
    }
  };

  function sendForApprovalFun(requirementId) {
    APIManager.getAPI(emailForApprovalCMS + requirementId).then(
      (response) => {
        if (response && response.status == 200) {

        }
      }
    );
  }

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + requirementId).then((res) => {
      getProjectByID(res.data.value.projectId);
      setStatus(res.data.value.status);
      setPrimaryKeyword(res.data.value.primaryKeyword);
      setKeywordId(res.data.value.keyNumber)
      editorArticleStatus.map((article) => {
        if (res.data.value.status === article.value) {
          setArticleStatus(article.text)
        }
      });
    });
  };

  function getChecklist() {
    APIManager.getAPI(checklist + "?requirementId=" + requirementId).then(
      (response) => {
        if (response && response.status == 200) {
          setCheckList(response.data);
        }
      }
    );
  }

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setProjectName(res.data.value.projectCode);
      setWordArticle(res.data.value.wordCount);
      setProjectId(res.data.value.id);
    });
  };

  const findKeywordProximity = (text, keywords) => {
    if (text) {
      if(text !== description && buttonclick !== 5)
      {
        setDescription(text);
        setDescriptionChange(true);
      }
      let txt = text.replace(/(<([^>]+)>)/gi, "");
      txt = txt.replace(/&(nbsp|amp|quot);/g, " ");
      let word = txt?.match(/[\w\d\’\',-]+/gi);
      setProimityCount(0);
      setProximityText([]);

      let matchIndex = [];
      let keywordArr = [];
      let replaceText = "";
      let count = 0;
      let checklistObj = {};
      // text = txt;
      let temp = keywords.primaryKeyword[0];
      for (let key in keywords) {
        keywords[key].forEach((itemkeys) => {
          if(itemkeys.length < temp.length)
          temp = itemkeys
        });
    
        keywords[key].sort().forEach((wordarr, index) => {
          let istemp = 0;
          if(wordarr != '')
          {
            let keyword = wordarr?.match(/[\w\d\’\'-]+/gi);
            let obj = [];
            let checklistCount = 0;
            word?.forEach((item, index) => { 
              if (item.trim().toLocaleLowerCase() == keyword[0].trim().toLocaleLowerCase()) {
                let flag = 0;
                keyword?.forEach((wordItem, index1) => {
                  if (wordItem.trim().toLocaleLowerCase() == word[index + index1]?.trim()?.toLocaleLowerCase()) {
                    flag = 1;
                  }
                  else 
                    flag = 0;
                    
                });
                
                if(flag == 1)
                {
                  obj.push(index);
                  checklistCount++;
                }
                
              }
            });
            matchIndex[wordarr]=obj;
    
            if (key == "primaryKeyword") {
              checklistObj.primaryKeyword = wordarr;
              checklistObj.primaryKeywordCount = checklistCount;
              checklistObj.primaryKeywordDensity = (checklistCount / word.length).toFixed(4);
              checklistObj.secondaryKeyword = [];
              checklistObj.includeKeyword = [];
            }
            else if (key == "secondaryKeyword") {
              let obj1 = {
                keyword: wordarr,
                keywordCount: checklistCount,
                keywordDensity: (checklistCount / word.length).toFixed(4)
              }
              checklistObj.secondaryKeyword.push(obj1);
            }
            else if (key == "includeKeyword") {
              let obj1 = {
                keyword: wordarr,
                keywordCount: checklistCount,
                keywordDensity: (checklistCount / word.length).toFixed(4)
              }
              checklistObj.includeKeyword.push(obj1);
            }
          }
        });
      }
      for (let key in matchIndex) {
        let cnt
        let wordcnt
        
        if (matchIndex.hasOwnProperty(key)) {
          if(key.toLowerCase().includes(temp.toLowerCase()) && key.toLowerCase() !== temp.toLowerCase()){
             cnt = matchIndex[key];
             wordcnt = key.split(' ').indexOf(temp);
             if(wordcnt !== -1)
             {
            //   wordcnt = 0;        
             cnt.forEach((item) =>
             {
              let i = matchIndex[temp].indexOf(item + wordcnt);
              if(i == -1)
                i = 0;          
              matchIndex[temp][i] = null;
             });
            }
          }
        }
      }
      // text = text.toLocaleLowerCase();
    
      for (let key in matchIndex) {
        if (count > 0 || replaceText != '') {
          text = replaceText;
          replaceText = "";
        }
        for (let i = 0, j = 1; j < matchIndex[key].length; i++, j++) {
          text = text.toLocaleLowerCase();
          // key = key.trim();
          if (matchIndex[key][i] != null && matchIndex[key][j] != null && matchIndex[key][j] - (matchIndex[key][i] + key.length) < 10) {
            if(!keywordArr.includes(key))
            {
              ++count;
              keywordArr.push(key);
            }
            replaceText += text.slice(0, text.indexOf(key.trim().toLocaleLowerCase()));
            text = text.replace(text.slice(0, text.indexOf(key.trim().toLocaleLowerCase())) + key.trim().toLocaleLowerCase(), '');
            replaceText += '<span style="color: rgb(256,0,0); text-decoration: underline;"> ' + key.trim() + ' </span>';
            replaceText += text.slice(0, text.indexOf(key.trim().toLocaleLowerCase()));
            text = text.replace(text.slice(0, text.indexOf(key.trim().toLocaleLowerCase())) + key.trim().toLocaleLowerCase(), '');
            replaceText += '<span style="color: rgb(256,0,0); text-decoration: underline;"> ' + key.trim() + ' </span>';
            i++;
            j++;
          }
          else {
            replaceText += text.slice(0, text.indexOf(key.trim().toLocaleLowerCase())) + key.trim().toLocaleLowerCase();
            text = text.replace(text.slice(0, text.indexOf(key.trim().toLocaleLowerCase())) + key.trim().toLocaleLowerCase(), '');
          }
        }
        if(count > 0 || (count == 0 && text != ""))
        {
          replaceText += text.slice(0);
          text = text.replace(text.slice(0), "");
        }
      }
      if(count == 0)
        replaceText = text;
      setProximityText(keywordArr);
      setText(replaceText);
      setCheckList(checklistObj);
      setProimityCount(count);
    }
  };

  function getMemberList() {
    APIManager.postAPI(getMemberData + "?RequirementId=" + requirementId).then((response) => {
      if (response && response.status == 200) {
        setMemberList(response.data);
      }
    });
  }

  function getKeywords(desc) {
    APIManager.getAPI(getAllKeywords + requirementId).then((res) => {
      if (res && res.status == 200) {
        let ikeyword = [];
        let skeyword = [];
        let obj;
        res.data[0].includeKeywords.map((item) => {
          if(item.keyword != "")
            ikeyword.push(item.keyword.trim());
        })
        res.data[0].secondaryKeywords.map((item) => {
          if(item.keyword != "")
            skeyword.push(item.keyword.trim());
        })
        obj = {
          primaryKeyword: [res.data[0].primaryKeyword.trim()],
          includeKeyword: ikeyword,
          secondaryKeyword: skeyword
        }
        setKeywords(obj);
        setDescription(desc);
        findKeywordProximity(desc, obj);
      }
    })
  }

  function setSelectedText() {
    let value = "";
    let data = JSON.parse(localStorage.getItem("highlightedObj"));
    let joditEditorNodes = document.querySelector('.jodit-wysiwyg')?.children;
    let newstring = "";
    if (buttonclick == 3)
    {
      joditEditorNodes.forEach((node) => {
        newstring += "<p>";
        if(node.childNodes.length > 1)
        {
          node.childNodes.forEach((item) => {
            let pTagValue = item.localName ? item.outerHTML : item.textContent ;
            if(pTagValue.indexOf(data.text) == data.startIndex || pTagValue.indexOf(data.text) ==  data.endIndex) {
                newstring += pTagValue.substring(0, data.startIndex) + '<span style="background-color: rgb(251 192 60);">' 
              + data.text + '</span>' + pTagValue.substring(data.endIndex)
            }
            else{
              newstring += pTagValue;
            }
          })
        }
        else{
          let pTagValue = node.innerHTML;
          if(pTagValue.indexOf(data.text) == data.startIndex || pTagValue.indexOf(data.text) ==  data.endIndex) {
            newstring += pTagValue.substring(0, data.startIndex) + '<span style="background-color: rgb(251 192 60);">' 
            + data.text + '</span>' + pTagValue.substring(data.endIndex)
          }
          else{
            newstring += pTagValue;
          }
        }
        newstring += "</p>";
      });
      setInstruction("");
      setInstruction(newstring);
    }
    else{
      let range = new Range();
      joditEditorNodes.forEach((node) => {
        if(node.childNodes.length > 1) {
          node.childNodes.forEach((item, index) => {
            let pTagValue = item.localName ? item.outerHTML : item.textContent;
            if(pTagValue.indexOf(data.text) == data.startIndex || pTagValue.indexOf(data.text) ==  data.endIndex) {
              let getStartEnd = [pTagValue.indexOf(data.text), pTagValue.indexOf(data.text) + data.text.length];
              range.setStart(node.childNodes[index], getStartEnd[0]);
              range.setEnd(node.childNodes[index], getStartEnd[1]);
              document.getSelection().removeAllRanges();
              document.getSelection().addRange(range);
            }
          })
        } else {
          let pTagValue = node.innerHTML;
          if(pTagValue.indexOf(data.text) == data.startIndex || pTagValue.indexOf(data.text) ==  data.endIndex) {
            let getStartEnd = [pTagValue.indexOf(data.text), pTagValue.indexOf(data.text) + data.text.length];
            range.setStart(node.childNodes[0], getStartEnd[0]);
            range.setEnd(node.childNodes[0], getStartEnd[1]);
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);
          }
          else if([...pTagValue.matchAll(new RegExp(data.text, 'gi'))].map(a => a.index).includes(data.startIndex)){
            // let getStartEnd = [pTagValue.indexOf(data.text), pTagValue.indexOf(data.text) + data.text.length];
            range.setStart(node.childNodes[0], data.startIndex);
            range.setEnd(node.childNodes[0], data.endIndex);
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);
          }
        }
      });
    }

    let timer;
    if (buttonclick == 4) {
      // editorRef?.current.addEventListener("keypress", (e) => eventHandler(e), false);
      value = "";
      let data = JSON.parse(localStorage.getItem("highlightedObj"));
      editorRef?.current.addEventListener('keydown', ({ key }) => {
        if (["Backspace", "Delete"].includes(key) && localStorage.getItem("highlightedObj")) {
          value = value.slice(0, -1);
          let newtxt = instruction1.replace(
            data.text,
            value + '<s style="color: #FF7F40;">' + data.text + "</s> "
          );
          document.querySelector('.jodit-wysiwyg').innerHTML = newtxt;
          clearTimeout(timer);
          timer = setTimeout(() => {
            data.styleText = value;
            localStorage.setItem("highlightedObj", JSON.stringify(data));
            data.showText = `replace "${data.text}" with "${data.styleText}"`;
            value = "";
            setsuggestion(data);
            setInstruction(newtxt);
          }, 2000);
        }
      })
      let sel = window.getSelection();
      let editorText = document.querySelector('.jodit-wysiwyg').querySelector('p');
      let pos = sel.toString().length;
      editorRef?.current.addEventListener('input', (e) => {
        if (buttonclick == 4 && isCommentSuggestionAdded) {
          if (instruction1.indexOf('<s>') == -1) {
            if(e.data != null)
              value = value + e.data;
            
            if(value != "" && localStorage.getItem("highlightedObj")) {
                sel.extend(editorText, 0);
              let newtxt = instruction1.replace(
                data.text,
                value + '<s style="color: #FF7F40;">' + data.text + "</s> "
              );
              document.querySelector('.jodit-wysiwyg').innerHTML = newtxt
              while (pos-- > 0) {
                sel.modify("move", "forward", "character");
              } 

              clearTimeout(timer);
              timer = setTimeout(() => {
                data.styleText = value;
                localStorage.setItem("highlightedObj", JSON.stringify(data));
                data.showText = `replace "${data.text}" with "${data.styleText}"`;
                value = "";
                setsuggestion(data);
                setInstruction(newtxt);
              }, 2000);
            }
          }
        }
      });
    }
  }


  function setsuggestions(txt) {
    if(txt)
    {
      let data = JSON.parse(localStorage.getItem("highlightedObj"));
      let styleText = data?.text;
      let a = txt.toLowerCase().match(/\w+/g);
      let newdesc = instruction1 ;
      let b = newdesc.toLowerCase().match(/\w+/g);
      let txtword = styleText.toLowerCase().match(/\w+/g);
      let res2 = a.findIndex((i, index) => i !== b[index] && i.toLowerCase() == txtword[0].toLowerCase());
      res2 = a[res2 - 1];
      if (res2 == "em") {
        styleText = "<em>" + styleText + "</em>";       
      } else if (res2 == "u") {
        styleText = "<u>" + styleText + "</u>";        
      } else if (res2 == "strong") {
        styleText = "<strong>" + styleText + "</strong>";        
      }
        
        data.styleText = styleText;
        localStorage.setItem("highlightedObj", JSON.stringify(data));
        if (
          res2 != '' &&
          (res2 == "em" ||
          res2 == "u" ||
          res2 == "strong") && instruction1 !== txt
          ) {
            data.showText = `replace "${data.text}" with "${data.styleText}"`;
            setInstruction(txt);
            setsuggestion(data);
        }
    }
  }

  const deleteComment = ((id) => {
    APIManager.deteletItem(deletecomment + id).then((res) => {
      if (res.status == 200) {
        getcommentlist();
        toast.success("Successfully Delete!");
      }
    })
  });

  const deleteSuggestion = ((id) => {
    APIManager.deteletItem(deletesuggestion + id).then((res) => {
      if (res.status == 200) {
        getsuggestion();
        toast.success("Successfully Delete!");
      }
    })
  });
  
  const deleteCommentReply = ((id) => {
    APIManager.deteletItem( deletecommentreply + id).then((res) => {
      if (res.status == 200) {
        getcommentlist();
        toast.success("Successfully Delete!");
      }
    })
  });

  function deleteConfirm(id, flag, reply) {
    confirmAlert({
      overlayClassName: "delete requirenment-alert",
      title: (
        <div className="d-flex align-items-center ">
          <FeatherIcon className="confirm-delete" icon="trash-2" />
          <span className="please-confirm fw-bold ms-3">
            {flag == 0 ? "Are you sure you want to delete comment ?" : "Are you sure you want to delete suggestion ?"}
          </span>
        </div>
      ),

      message: (
        <span className="require-msg ms-5">
          {flag == 0 ? "This will delete the Selected comment from the requirement" : "This will delete the Selected suggestions from the requirement"}

        </span>
      ),
      buttons: [
        {
          label: (
            <span
              className="require-cancel-btn bg-white"
            >
              Cancel
            </span>
          ),
        },
        {
          label: (
            <span className="require-raise-btn dot-drop-color delete require-delete ">
              Delete
            </span>
          ),
          onClick: () => {
            if (flag == 0 && reply == false) {
              deleteComment(id);
            }
            else if(flag == 1 && reply == false) {
              deleteSuggestion(id);
            }
            else if(flag == 0 && reply == true)
            {
              deleteCommentReply(id);
            }
          },
        },
      ],
    });
  }

  const dispatch = () => {
    if(instruction1 !== description) {
      confirmAlert({
        overlayClassName: "editor_breadcrum_box delete requirenment-alert",
        title: (
          <div className="d-flex align-items-center ">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check confirm-check "><polyline points="20 6 9 17 4 12"></polyline></svg> */}
            <FeatherIcon className="confirm-check" icon="alert-triangle" />
            <span className="please-confirm fw-bold ms-3">
              {`Want to save your changes to "${primaryKeyword}"?`}
            </span>
          </div>
        ),

        message: (
          <span className="require-msg ms-5">
            This will create a version of all the changes and save them.
          </span>
        ),
        
        buttons: [
          {
            label: (
              <span className="require-raise-btn ">
                Save
              </span>
            ),
            onClick: () => {
            AddDescription();
            navigate(-1)
            },
          },
          {
            label: (
              <span
                className="require-cancel-btn bg-white"
              >
                Don't Save
              </span>
            ),
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: (
              <span
                className="require-cancel-btn bg-white editor_cancel_msg"
              >
                Cancel
              </span>
            ),
          },
        ],
      });
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <div className="row">
        <div className="p-0 cmseditor">
          <div className="portlet-header portlet-header-bordered px-0 pb-0">
            <div className="col-md-12 d-flex align-items-center bg-white p-3 flex-wrap">
              <div className={localStorage.getItem("userDatadepartmentName") !== "Customer" ? 
              "cms-breadcum cursor-pointer flex-wrap d-flex" : "cms-breadcum flex-wrap d-flex"} >
                <span
                  className="mr-4"
                  onClick={() => {
                  if  (localStorage.getItem("userDatadepartmentName") === "Freelancer" || (localStorage.getItem("userDatadepartmentName") === "Content" && localStorage.getItem("userRole") !== "Content")){
                    dispatch();
                  }else{
                    navigate(-1)
                  }
                     
                  }}
                >
                  <small class="mx-2 cms_fs16 cms_fw600 cms_fc_black cms_line-height text-capitalize">{projectname}</small>/
                  <small class="mx-2  cms_fs16 cms_fw600 cms_fc_black cms_line-height text-capitalize">#{keywordId}</small>/
                  <small class="ms-2  cms_fs16 cms_fw600 cms_fc_black cms_line-height text-capitalize">{primaryKeyword}</small>
                </span>

                <div className="d-flex mr-3">
                {
                  memberList?.map((item) => {
                    return (
                      <>
                        <div>
                          <Space size="middle" class="ml-minus">
                            <a class="px-2 name-icon" aria-hidden="true" type="button">
                              <span className="cms_fs16 cms_fw500 ">{(item.name.charAt(0).toUpperCase())}</span>
                            </a>
                          </Space>
                        </div>
                      </>
                    )
                  })
                }
              </div>
              <div className="  d-flex breadcrum-version">
                <div className="version-history">
                  <a
                    class="text-decoration-underline cms_fs18 cms_fw600"
                    onClick={() => {
                      getVersionHistoryData();
                      setversionhistory(true);
                      document.getElementById("comment-icon").style.display = "none";
                      localStorage.removeItem("highlightedObj");

                    }}
                  >
                    Version History
                  </a>
                </div>
              </div>
              </div>

            

           
              {(localStorage.getItem("userDatadepartmentName") === "Freelancer" || (localStorage.getItem("userDatadepartmentName") === "Content" 
              && localStorage.getItem("userRole") !== "Content")) ?
                <>

                <div className="vertical_border"></div>
                <div className="d-flex align-items-center flex-wrap ">
                <div className={isSave ? "d-flex align-items-center editor_save_btn light_blue_clr cms_radius6  p-2 cursor-pointer mt-3 mt-sm-0 mr-3"
                  :"d-flex align-items-center editor_save_btn cms_bg_gray cms_radius6  p-2 cursor-pointer mt-3 mt-sm-0 mr-3"}>
                  <button className="border-0 bg-transparent" disabled={
                      ((status == 1) || (status == 5) || (status == 2) || (status == 6)) && instruction1 !== ''
                        ? false
                        : true
                    } onClick={() => {
                      AddDescription();
                      setIsSave(true);
                    }} >
                    <FeatherIcon className="custome_feather" icon="save" />
                    <span className="editor_save cms_fc_dark-gray ms-2">Save</span>
                  </button>
                </div>
                <div className="mt-3 mt-sm-0">
                  <span className="editor_save_msg ">{ lastDate ? 'Last saved on ' + lastDate : ''}</span>
                </div>
                </div>
              </>
                :
                <></>
              }

            </div>
          </div>

          <div class="row">
            <div className="col-md-8 col-sm-12 editor-border bg-white  position-relative overflow-hidden full_height">
              <div className="left-side px-0 h-100vh">
              <div className="mt-2"></div>
                <div class="d-flex justify-content-between align-items-center edit-sugges-info flex-wrap">
                  <div className="d-flex align-items-center edit-suggestion-btn flex-wrap edit_new_left">
                    <div class="switch-tab switching_tab mb-3 py-3 px-2 mb-md-0">
                      <button
                        disabled={localStorage.getItem("userDatadepartmentName") === "Customer" ? true : false}
                        className={Editor ? "btn active btn-primary" : "btn border-0"}
                        onClick={() => {
                          setEditor(true);
                          setIsCommentSuggestionAdded(null);
                        }}
                      >
                        {Editor ? (
                          <FeatherIcon className="custome_feather_wh" icon="edit-3" />
                        ) : (
                          ""
                        )}

                        <span class="ms-2">Editing View</span>
                      </button>
                      <button
                        className={!Editor ? "btn active btn-primary" : "btn"}
                        onClick={() => {
                          setEditor(false);
                        }}
                      >
                        {!Editor ? (
                          <FeatherIcon className="custome_feather_wh" icon="users" />
                        ) : (
                          ""
                        )}
                        <span className="ml-2">Suggestion View</span>
                      </button>
                    </div>
                    <div className="d-flex align-items-center editor-info mt-3">
                      <div className="ms-3 me-2">
                        {/* <img class="ticket-info" src="/images/info.png" /> */}
                        <FeatherIcon className="custome_feather" icon="info" />
                      </div>
                      {Editor ? <><span>Edit the document directly</span></> : <><span>Edit becomes suggestion</span></>}
                    </div>
                  </div>
                  <div className="condition_accept mb-3 mb-md-0 mt-3 mt-xl-0">
                    {status < 9 ?
                    <>
                    {(localStorage.getItem("userDatadepartmentName") === "Content" && localStorage.getItem("userRole") === "Content") || (localStorage.getItem("userDatadepartmentName") === "Customer") ? (
                      <>
                        <button
                          disabled={
                            ((status == 3 || status == 7) || (status == 8 && localStorage.getItem("userDatadepartmentName") === "Customer"))
                              ? false
                              : true
                          }
                          class="btn border"
                          onClick={() => {
                            if (status == 3) {
                              requirementStatusUpdate(2);
                              setStatus(2);
                            }
                            else if (status == 8) {
                              requirementStatusUpdate(0);
                              setStatus(0);
                            }
                            else {
                              requirementStatusUpdate(6);
                              setStatus(6);
                            }

                          }}
                        >
                          <span className="request-rework">
                            Request for Rework
                          </span>
                        </button>
                        <button
                          class="btn btn-primary  ms-2"
                          disabled={
                            ((status == 3 || status == 7) || (status == 8 && localStorage.getItem("userDatadepartmentName") === "Customer"))
                              ? false
                              : true
                          }
                          onClick={() => {
                            if (status == 3) {
                              requirementStatusUpdate(4)
                              setStatus(2);
                            }
                            else if (status == 8) {
                              requirementStatusUpdate(9);
                              setStatus(9);
                            }
                            else {
                              requirementStatusUpdate(8);
                              setStatus(8);
                            }
                          }}
                        >
                          <i class="fa fa-check me-2" aria-hidden="true"></i>
                          <span> Accept</span>
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-primary"
                        id="editorButton"
                        disabled={
                          ((status == 1) || (status == 5) || (status == 2) || (status == 6)) && instruction1 !== ''
                            ? false
                            : true
                        }
                        onClick={() => {
                          setComplete(!complete);
                          AddDescription();
                          if (status < 3) {
                            setStatus(3);
                            requirementStatusUpdate(3);
                          } else {
                            requirementStatusUpdate(7);
                            setStatus(7);
                          }
                        }}
                      >
                        <i class="fa fa-check me-2" aria-hidden="true"></i>
                        <span>Mark as Complete</span>
                      </button>
                    )}
                    </> 
                    : <></>}
                  </div>
                </div>
                <div className="border-bottom mt-2"></div>
                <div className="editor-modal-box h-100">
                  <div className="d-flex mt-3">
                    <div ref={editorRef}>
                      <JoditEditor
                        value={
                          buttonclick == 3
                            ? instruction1
                            : buttonclick == 4
                            ? instruction1
                            : buttonclick == 5
                            ? text
                            : instruction1
                        }
                        id="editor"
                        config={config}
                        tabIndex={1}
                        onChange={(e) => {
                          setIsSave(false);
                          if (Editor == false && localStorage.getItem("highlightedObj")) {
                            let elements = document.querySelector('.jodit-wysiwyg').querySelector('p')?.getElementsByTagName("*");
                            if("<p>" + instruction1 + "</p>" !== e)
                              setsuggestions(e);
                          }
                        }}
                        onBlur={(text) => {
                          let txt = text.replace(/(<([^>]+)>)/gi, "");
                          txt = txt.replace(/&(nbsp|amp|quot);/g, " ");
                          let length = txt?.match(/[\w\d\’\',-]+/gi)?.length;
                          if(buttonclick === 5) {
                            document.getElementById("keywordproxy")?.classList.remove("show");
                            document.getElementById("keywordproxybutton")?.classList.add("collapsed");
                            setbuttonclick(0);
                            setDescription(text); 
                          }
                          else if(buttonclick !== 4)
                          {
                            findKeywordProximity(text, keywords);
                          }
                          setInstruction(text.replace(/&(nbsp|amp|quot);/g, " "));
                          setcharacterCount(txt.replaceAll(' ', '').length);
                          setWordCount(txt?.match(/[\w\d\’\'-]+/gi).length);
                        }}
                      ></JoditEditor>
                    </div>
                  </div>

                  {/* center icon */}

                  <div className="editor-middel-icon p-2" id="comment-icon" >
                    <div className="cursor-pointer" title="Comment"  onClick={() => {
                       if(instruction1 != description && isCommentSuggestionAdded == null)
                       {
                         setDescription("")
                         setDescription(instruction1);
                         setDescriptionChange(true);
                       } else {
                         setInstruction("");
                         setInstruction(description);
                         setDescriptionChange(false);
                       }
                      setversionhistory(false);
                      setbuttonclick(3);
                      setcommenthistory(false)
                      setIsCommentSuggestionAdded(true);
                      setEditor(true);
                    }}>
                      <FeatherIcon icon="plus-square"   />
                    </div>
                    <div  className="cursor-pointer"  title="Suggestion"  
                    onClick={() => {
                      if(instruction1 != description && isCommentSuggestionAdded == null)
                      {
                        setDescription("")
                        setDescription(instruction1);
                        setDescriptionChange(true);
                      } else {
                        setInstruction("");
                        setInstruction(description);
                        setDescriptionChange(false);
                      }

                      setversionhistory(false)
                      setbuttonclick(4);
                      setEditor(false);
                      setsuggestionhistory(false);
                      setIsCommentSuggestionAdded(true);
                    }}>
                      <FeatherIcon icon="message-square" />
                    </div>
                  </div>

                  {/* center icon  end*/}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 pe-0 ps-md-3 ps-0 editor_left_box full_height mt-3 mt-md-0">
              <div className="right_side bg-white p-3 pt-4 mt-md-0  h-100 bg-white">
                {versionhistory == 0 ? (
                  <>
                    <div class="position-relative progress_report">
                      {buttonclick == 0 && Editor ? (
                        <>
                          <div class="switch-tab p-3 d-flex justify-content-between align-items-center flex-wrap">
                            <span
                              // type="button"
                              class=""
                            >
                              Article Status
                            </span>
                            <span class="badge-article rounded-2 skyblue_border">
                              {articlestatus}
                            </span>

                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {buttonclick == 0 && Editor ? (
                        <>
                          <div className="mt-3 d-flex justify-content-between align-items-center switch-tab p-3 flex-wrap">
                            <span
                              // type="button"
                              class=""
                            >
                              ETA
                            </span>

                            <div className="eta-calendar">
                              <div className="d-flex align-items-center justify-content-between editot-eta">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-calendar"
                                >
                                  <rect
                                    x="3"
                                    y="4"
                                    width="15"
                                    height="15"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <line x1="16" y1="2" x2="16" y2="6"></line>
                                  <line x1="8" y1="2" x2="8" y2="6"></line>
                                  <line x1="3" y1="10" x2="21" y2="10"></line>
                                </svg>

                                <>
                                  <span className="ms-2">
                                    {formateDateIntoDatemonth(ETA)}
                                  </span>
                                </>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 0 && Editor ? (
                        <>
                          <div className="mt-3 d-flex justify-content-between align-items-center switch-tab p-3 flex-wrap">
                            <div>
                              <span>
                                Word Count
                              </span>
                            </div>
                            <div className=" px-0 ps-2">
                              <div className="d-flex align-items-center justify-content-between bg-white word_count_select">
                                <select className="form-select  ps-3 py-0" name="cars" id="cars">
                                <option value={wordCount}> {wordCount}/{wordArticle ? wordArticle + " " +'words' : 0 + " "+'words'}</option>
                                <option value={characterCount}> {characterCount ? characterCount + " " +'Characters' : 0 + " "+'Characters                                                                       '}</option>
                              </select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {buttonclick == 2 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2
                                  class="accordion-header"
                                  id="primarykeyword"
                                >
                                  <button
                                    class="accordion-button collapsed bg-white border-0 mt-3 checklist-arrow"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#primarykeywords"
                                    aria-expanded="true"
                                    aria-controls="primarykeywords"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 2 : 0);
                                      document.getElementById("comment-icon").style.display = "none";
                                      localStorage.removeItem("highlightedObj");
                                      findKeywordProximity(instruction1, keywords);
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className="cms_left_menu m-0">
                                        {" "}
                                        Checklist{" "}
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                      />
                                    </div>
                                  </button>
                                </h2>

                                <div
                                  id="primarykeywords"
                                  class="accordion-collapse collapse mt-2 "
                                  aria-labelledby="primarykeyword"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div
                                      class="accordion inner_accordian"
                                      id="primarykeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="primarykeywrdd"
                                        >
                                          <button
                                            class={checkList?.primaryKeywordCount != 0 ? "accordion-button collapsed keyword_btn" : "accordion-button collapsed light_color"}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#primarykeywrd"
                                            aria-expanded="true"
                                            aria-controls="primarykeywrd"
                                          >
                                            <div className={checkList?.primaryKeywordCount != 0 ? "checkmark" : "checkmark light"} >
                                              <FeatherIcon icon={checkList?.primaryKeywordCount != 0 ? "check" : 'uncheck'} />
                                            </div>
                                            <span className="checklist-keyword"> Primary Keyword :</span>
                                            <div>
                                            </div>
                                          </button>
                                        </h2>

                                          <div
                                            id="primarykeywrd"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="primarykeywrdd"
                                            data-bs-parent="#primarykeyword"
                                          >
                                            <div class="accordion-body p-0 keyword-checklist">
                                              <div className={checkList?.primaryKeywordCount != 0 ? "box_content_green" : 'box_content_green1 comment_check_box'}>
                                                <ul>
                                                  <li>
                                                    <div className="sub_box keyword_btn d-flex align-items-center">
                                                      <div className={checkList?.primaryKeywordCount != 0 ? "checkmark" : 'checkmark light'}>
                                                        <FeatherIcon icon={checkList?.primaryKeywordCount != 0 ? "check" : ''} />
                                                      </div>

                                                      <span>
                                                        {
                                                          checkList.primaryKeyword
                                                        }{" "}
                                                        (
                                                        {
                                                          checkList.primaryKeywordCount
                                                        }
                                                        ){checkList?.primaryKeywordCount != 0 ? <>: {
                                                          checkList.primaryKeywordDensity
                                                        }<span>%</span></> : ''}

                                                      </span>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div> 
                                      </div>
                                    </div>

                                    <div
                                      class="accordion inner_accordian"
                                      id="secondarykeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="secondarykeywrdd"
                                        >
                                          <button
                                            class={checkList?.secondaryKeyword?.find((item) => (item.keywordCount != 0)) ? "accordion-button collapsed keyword_btn" : "accordion-button collapsed light_color"}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#secondarykeywrd"
                                            aria-expanded="true"
                                            aria-controls="secondarykeywrd"
                                          >
                                            <div class=""
                                              className={checkList?.secondaryKeyword?.find((item) => (item.keywordCount != 0)) ? "checkmark" : "checkmark light"}
                                            >
                                              <FeatherIcon
                                                icon={checkList?.secondaryKeyword?.find((item) => (item.keywordCount != 0)) ? "check" : 'uncheck'}
                                              />
                                            </div>
                                            <span className="checklist-keyword">Secondary Keyword : </span>
                                          </button>
                                        </h2>
                                        {checkList?.secondaryKeyword?.map((secondary) => {
                                          return (<>
                                            <div
                                              id="secondarykeywrd"
                                              class="accordion-collapse collapse"
                                              aria-labelledby="secondarykeywrdd"
                                              data-bs-parent="#primarykeyword"
                                            >
                                              <div class="accordion-body  p-0 keyword-checklist">
                                                <div className={secondary.keywordCount != 0 ? "box_content_green " : 'comment_check_box'}>
                                                  <ul>
                                                    <li>
                                                      <div className="sub_box keyword_btn d-flex align-items-center">
                                                        <div className={secondary.keywordCount != 0 ? "checkmark " : "checkmark light"}>
                                                          <FeatherIcon icon={secondary.keywordCount != 0 ? "check" : ''} />
                                                        </div>
                                                        <span>
                                                          {secondary.keyword}({secondary.keywordCount}) {secondary.keywordCount != 0 ? <>: {secondary.keywordDensity}<span>%</span></> : ''}
                                                        </span>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </>)
                                        })}

                                      </div>
                                    </div>

                                    <div
                                      class="accordion inner_accordian"
                                      id="includekeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="secondarykeywrdd"
                                        >
                                          <button
                                            class={checkList?.includeKeyword?.find((item) => (item.keywordCount != 0)) ? "accordion-button collapsed keyword_btn" : "accordion-button collapsed light_color"}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#includekeywords"
                                            aria-expanded="true"
                                            aria-controls="includekeywords"
                                          >
                                            <div
                                              className={checkList?.includeKeyword?.find((item) => (item.keywordCount != 0)) ? "checkmark" : "checkmark light"}>

                                              <FeatherIcon
                                                icon={checkList?.includeKeyword?.find((item) => (item.keywordCount != 0)) ? "check" : ''}
                                              />
                                            </div>
                                            <span className="checklist-keyword"> Include Keyword : </span>
                                          </button>
                                        </h2>
                                        {checkList?.includeKeyword?.map((include) => {
                                          return (<>
                                            <div
                                              id="includekeywords"
                                              class="accordion-collapse collapse"
                                              aria-labelledby="secondarykeywrdd"
                                              data-bs-parent="#includekeyword"
                                            >
                                              <div class="accordion-body p-0 keyword-checklist">
                                                <div className={include.keywordCount != 0 ? "box_content_green" : 'comment_check_box'}>
                                                  <ul>
                                                    <li>
                                                      <div className="sub_box keyword_btn d-flex align-items-center ">
                                                        <div className={include.keywordCount != 0 ? "checkmark" : 'checkmark light'}>
                                                          <FeatherIcon icon={include.keywordCount != 0 ? "check" : ''} />
                                                        </div>
                                                        <span>
                                                          {include.keyword}({include.keywordCount}) {include.keywordCount != 0 ? <>: {include.keywordDensity}<span>%</span></> : ''}
                                                        </span>


                                                      </div>
                                                    </li>

                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </>)
                                        })}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 3 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section mt-3">
                            <div class="accordion w-100" id="accordionExample">
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="comment">
                                  <button
                                    ref={closeBtn}
                                    class="accordion-button collapsed border-0 checklist-arrow"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#commentblock"
                                    aria-expanded="true"
                                    aria-controls="commentblock"
                                    id="commentbutton"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 3 : 0);
                                      getcommentlist();
                                      setcommenthistory(false);
                                      // setInstruction("");
                                      setInstruction(description);
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" cms_left_menu m-0">
                                        Comments (
                                        {commentcount ? commentcount : "00"})
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                        onClick={() => {
                                          setbuttonclick(buttonclick == 0 ? 3 : 0);
                                          document.getElementById("comment-icon").style.display = "none";
                                          setIsCommentSuggestionAdded(null);
                                          localStorage.removeItem("highlightedObj");
                                        }}
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="commentblock"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="comment"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div className="Comment_section">
                                      <div className="inner_version_history">
                                        <div className="history ">
                                          <div className="d-flex align-items-center justify-content-between history_detail fs-6 fw-bold gray_font">
                                            {/* <i class="fa fa-clock-o mr-2" aria-hidden="true"></i> */}
                                            <div className="d-flex align-items-center justify-content-start">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke=" #757575"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-clock"
                                              >
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                ></circle>
                                                <polyline points="12 6 12 12 16 14"></polyline>
                                              </svg>
                                              <span
                                                className="ms-1"
                                                onClick={() => {
                                                  if (commenthistory == true) {
                                                    setcommenthistory(false)
                                                  } else {
                                                    setcommenthistory(true);
                                                  }
                                                  getcommentlist();
                                                  setCommentEdit(false);
                                                  setCommentId();
                                                  setCommentEditReply(false);
                                                  setCommentReplyObj();
                                                }}
                                              >
                                                View History
                                              </span>
                                            </div>

                                          </div>
                                        </div>

                                        {commenthistory ? (
                                          <>
                                          <div className="global-scrollbar view-history_scroll comment_history_block">
                                            {replyList.map((replyobj) => {
                                              return replyobj.reply;
                                            })}
                                          </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="addcoment_block border_blue mt-2">
                                              <div className="comenter_name d-flex justify-content-between align-items-center p-2">
                                                <div class="w-100">
                                                  <div className="d-flex align-items-center  justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-start flex-wrap">
                                                      <h4 className="dark_color fw-bold fs-6 m-0 me-2">
                                                        {localStorage.getItem(
                                                          "userName"
                                                        )}

                                                      </h4>
                                                      <div>
                                                        <div>
                                                        <span className="date_time">
                                                          {formateDateIntoDatemonthTime(
                                                            new Date()
                                                          )}{" "}
                                                        </span>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer me-2"></i>
                                                  </div>

                                                  <div className="input_data"></div>
                                                  <div className="button_group mt-2 text-right">
                                                    <input
                                                      type="text"
                                                      class="form-control ps-3"
                                                      placeholder="Write a comment..."
                                                      value={comment}
                                                      onChange={(e) => {
                                                        setComment(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />

                                                    <div className="mt-2 pt-1">
                                                      <>
                                                        <button
                                                          className="btn btn-primary fw-bold"
                                                          onClick={() => {
                                                            if (
                                                              comment.length !=
                                                              0
                                                            ) {
                                                              Addcomments();
                                                              getcommentlist();
                                                              setcommentupdatestatus(false);
                                                            }
                                                          }}
                                                        >
                                                          Add Comment
                                                        </button>
                                                      </>

                                                      <button
                                                        className="btn btn-light ml-3 fs-6 fw-6 gray_font cms-border-gray bg-white"
                                                        onClick={() => {
                                                          clearForm();
                                                          closeBtn.current.click();
                                                        }}
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="global-scrollbar view-history_scroll ">
                                            {commentlist?.map((item, index) => {                                           
                                              if (item.status === null) {
                                                return (
                                                  <>
                                                    <div className="addcoment_block border_blue mt-2 " >
                                                      <div className="comenter_name d-flex justify-content-between align-items-center p-2">
                                                        <div class="w-100">
                                                          <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center justify-content-start flex-wrap">
                                                              <h4 className="dark_color fw-bold fs-6 m-0 me-2">
                                                                {
                                                                  item?.members
                                                                    ?.name
                                                                }

                                                              </h4>

                                                                <div>
                                                              <span className="date_time me-1">
                                                                {formateDateIntoDatemonthTime(
                                                                  item.createdAt
                                                                )}
                                                              </span>
                                                              </div>

                                                            </div>

                                                            <div className="d-flex align-items-center icon_line">
                                                              <div className="d-flex align-items-center justify-content-end">
                                                                <span
                                                                  class=""
                                                                  onClick={() => {
                                                                    commentstatus(
                                                                      item.id, true
                                                                    );
                                                                    setcommentupdatestatus(
                                                                      true
                                                                    );
                                                                    setcommenthistory(
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  {commentupdatestatus ==
                                                                    false ? (
                                                                    <>
                                                                      <i
                                                                        class="fa fa-check ms-1"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </span>


                                                                <div>
                                                                  <span class="dropdown">
                                                                    <button className="kabab-btn"
                                                                      type="kabeb button"
                                                                      id="dropdownMenuButton"
                                                                      data-toggle="dropdown"
                                                                      aria-haspopup="true"
                                                                      aria-expanded="false"
                                                                    >
                                                                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>

                                                                    <div
                                                                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated cms_comment_delete"
                                                                      aria-labelledby="dropdownMenuButton"
                                                                    >
                                                                      <div class="dropdown-item d-flex align-items-center dot-drop-color  position-relative" onClick={() => {
                                                                       // deleteConfirm(item.id, 0)
                                                                       setCommentEdit(true);
                                                                       setCommentId(item.id);
                                                                       setEditComment(item.comment);
                                                                       setCommentReplyObj();
                                                                      }}>
                                                                        <span
                                                                          className=""
                                                                        >
                                                                          Edit
                                                                        </span>
                                                                      </div>

                                                                      <div class="dropdown-item d-flex align-items-center dot-drop-color delete position-sticky" onClick={() => {
                                                                        deleteConfirm(item.id, 0, false)
                                                                      }}>
                                                                        <span
                                                                          className=""
                                                                        >
                                                                          Delete
                                                                        </span>
                                                                      </div>
                                                                      
                                                                    </div>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>

                                                          </div>
                                                          <span className={item.id !== commentId ? "d-block" : "d-none"}>
                                                            {item.comment}
                                                          </span>
                                                          {commentEdit === true && item.id === commentId ? <>
                                                            {/* {item.id === commentId ?  */}
                                                            {/* <> */}
                                                            <div className="button_group mt-3 text-right">
                                                                <input
                                                                  type="text"
                                                                  className="form-control ps-3" 
                                                                  placeholder="Write a comment..."  
                                                                  value={
                                                                    editComment
                                                                  }
                                                                  onChange={(e) => {
                                                                    setEditComment(
                                                                      e.target.value
                                                                    );
                                                                  }}
                                                                />
                                                              <div className="mt-3">
                                                              <button
                                                                className="btn btn-primary fs-6 fw-6"
                                                                onClick={()=>{Addcomments(commentlist[index].id)}}
                                                              >
                                                                Edit Comment
                                                              </button>
                                                              <button
                                                                className="btn btn-light ml-3 fs-6 fw-6 gray_font cms-border-gray bg-white"
                                                                onClick={() => {
                                                                  clearForm();
                                                                  //closeBtn.current.click();
                                                                  setCommentEdit(false)
                                                                  setCommentId();
                                                                }}
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                          {/* </> : <></> } */}
                                                          </> : <>
                                                          {item.editorCommentReplies.map(
                                                            (reply) => {
                                                              return (
                                                                <>
                                                                {(CommentReplyObj?.id == reply.id && CommentReplyObj?.commentId == item.id) || CommentReplyObj?.commentId !== item.id || !CommentReplyObj ?
                                                                <div className="border-top my-2">
                                                                  <div className="mt-2">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                      <div className="d-flex align-items-center justify-content-start">
                                                                        <h4 className="dark_color fw-bold fs-6 m-0">
                                                                          {
                                                                            reply
                                                                              ?.members
                                                                              ?.name
                                                                          }
                                                                          <span className="date_time  ml-2">
                                                                            {formateDateIntoDatemonthTime(
                                                                              reply.createdAt
                                                                            )}
                                                                          </span>

                                                                        </h4>
                                                                      </div>
                                                                      <div>
                                                                        <span class="dropdown">
                                                                          <button className="kabab-btn"
                                                                            type="kabeb button"
                                                                            id="dropdownMenuButton"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                          >
                                                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                          </button>

                                                                          <div
                                                                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated cms_comment_delete"
                                                                            aria-labelledby="dropdownMenuButton"
                                                                          >
                                                                            <div class="dropdown-item d-flex align-items-center dot-drop-color  position-relative" onClick={() => {
                                                                              // deleteConfirm(item.id, 0)
                                                                              setCommentEditReply(true);
                                                                              setCommentReplyObj(reply);
                                                                              setCommentReply(reply.commentReply);
                                                                            }}>
                                                                              <span
                                                                                className=""
                                                                              >
                                                                                Edit
                                                                              </span>
                                                                            </div>
                                                                            <div class="dropdown-item d-flex align-items-center dot-drop-color delete position-sticky" onClick={() => {
                                                                              deleteConfirm(reply.id, 0, true)
                                                                            }}>
                                                                              <span
                                                                                className=""
                                                                              >
                                                                                Delete
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        </span>
                                                                      </div>


                                                                    </div>
                                                                    <span>
                                                                      {
                                                                        reply?.id !== CommentReplyObj?.id ? reply.commentReply : ''
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                : <></> }
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                          {commentEditReply == true &&  CommentReplyObj?.commentId == item.id? <>
                                                            <div className="button_group mt-3 text-right">
                                                            <input
                                                              type="text"
                                                              className="form-control ps-3"
                                                              value={
                                                                CommentReply
                                                              }
                                                              onChange={(e) => {
                                                                setCommentReply(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                            <div className="mt-3">
                                                              <button
                                                                className="btn btn-primary fs-6 fw-6"
                                                                onClick={()=>{addCommentReply(CommentReplyObj.id, CommentReplyObj.commentId);}}
                                                              >
                                                               Edit Reply
                                                              </button>
                                                              <button
                                                                className="btn btn-light ml-3 fs-6 fw-6 gray_font cms-border-gray bg-white"
                                                                onClick={() => {
                                                                  clearForm();
                                                                 // closeBtn.current.click();
                                                                  setCommentEditReply(false);
                                                                  setCommentReplyObj();
                                                                  setCommentReply("")
                                                                  setCommentId();
                                                                }}
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                          </> : <>
                                                          <div className="button_group mt-3 text-right">
                                                            <input
                                                              type="text"
                                                              className={
                                                                isreply === true && replyId == index
                                                                  ? "form-control ps-3"
                                                                  : "form-control m-2 d-none"
                                                              }
                                                              placeholder="Write your reply"
                                                              value={
                                                                CommentReply
                                                              }
                                                              onChange={(e) => {
                                                                setCommentReply(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                            <div className="mt-3">
                                                              <button
                                                                className="btn btn-primary fs-6 fw-6"
                                                                onClick={(e) => {
                                                                  if (isreply === false && replyId !== index)
                                                                  {
                                                                    setIsreply(true);
                                                                    setReplyId(index);
                                                                  }
                                                                  else if(isreply === true && replyId !== index)
                                                                  {
                                                                    setReplyId(index);
                                                                  }
                                                                  else if (CommentReply.length > 0 && replyId === index) {
                                                                    clearForm();
                                                                    addCommentReply(item.id, '');
                                                                    setReplyId();
                                                                  }
                                                                }}
                                                              >
                                                                {isreply === true && replyId == index ? "Add Reply" : "Reply"}
                                                              </button>
                                                              <button
                                                                className="btn btn-light ml-3 fs-6 fw-6 gray_font cms-border-gray bg-white"
                                                                onClick={() => {
                                                                  clearForm();
                                                                  if (isreply === false)
                                                                    closeBtn.current.click();
                                                                  else
                                                                    setIsreply(false);
                                                                    setCommentId();
                                                                    setReplyId();
                                                                }}
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                          </>}
                                                          
                                                          </>}
                                                     
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            })
                                            }
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 4 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="suggestion">
                                  <button
                                    ref={closeBtn}
                                    class="accordion-button collapsed bg-white border-0 mt-3 checklist-arrow"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#suggestionblock"
                                    aria-expanded="true"
                                    aria-controls="suggestionblock"
                                    id="suggestionbutton"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 4 : 0);
                                      getsuggestion();
                                      setsuggestionhistory(false);
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" cms_left_menu m-0">
                                        Suggestions (
                                        {suggestioncount
                                          ? suggestioncount
                                          : "00"}
                                        )
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                        onClick={() => {
                                          setbuttonclick(buttonclick == 0 ? 4 : 0);
                                          setInstruction("");
                                          document.getElementById("comment-icon").style.display = "none";
                                          setIsCommentSuggestionAdded(null);
                                          localStorage.removeItem("highlightedObj");
                                          setEditor(true);
                                          setsuggestion("");
                                          setInstruction(description);
                                        }}
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="suggestionblock"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="suggestion"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div className="Comment_section">
                                      <div className="inner_version_history">
                                        <div className="history">
                                          <div className="d-flex align-items-center justify-content-between history_detail fs-6 gray_font">
                                            <div className="d-flex align-items-center justify-content-start">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke=" #757575"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-clock"
                                              >
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                ></circle>

                                                <polyline points="12 6 12 12 16 14"></polyline>
                                              </svg>

                                              <span
                                                className="ms-1"
                                                onClick={() => {
                                                  { suggestionhistory == true ? setsuggestionhistory(false) : setsuggestionhistory(true); }
                                                  getsuggestion();
                                                }}
                                              >
                                                View History
                                              </span>
                                            </div>

                                          </div>
                                        </div>

                                        {suggestionhistory == true ? (
                                          <div className="global-scrollbar view-history_scroll common_block_scrollbar">
                                            {suggestionlist.map(
                                              (itemhistory) => {
                                                let obj = {};
                                                let comment = "";
                                                if (itemhistory.suggestion) {
                                                  obj = JSON.parse(
                                                    itemhistory.suggestion
                                                  );
                                                  comment =
                                                    'replace "' +
                                                    obj?.text +
                                                    '" with "' +
                                                    obj?.styleText +
                                                    '"';
                                                  }
                                                if (itemhistory.status !== null)
                                                {
                                                  return (
                                                    <>
                                                      <div className="addcoment_block mt-2">
                                                        <div className="comenter_name border_gray justify-content-between align-items-center">
                                                          <div class="d-flex align-items-center justify-content-between ">
                                                            <div className="d-flex align-items-center justify-content-start flex-wrap">
                                                            <h4 className="dark_color fw-bold fs-6 m-0 me-2 ">
                                                              {
                                                                itemhistory
                                                                  ?.members
                                                                  ?.name
                                                              }
                                                            </h4>
                                                            <div>
                                                            <span className="date_time">
                                                                {formateDateIntoDatemonthTime(
                                                                  itemhistory.createdAt
                                                                )}
                                                              </span>
                                                              </div>
                                                              </div>

                                                              <div className="d-flex align-items-center justify-content-end">
                                                            <span class="dropdown">
                                                              <button
                                                                className="kabab-btn"
                                                                type="kabeb button"
                                                                id="dropdownMenuButton"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <i
                                                                  class="fa fa-ellipsis-v"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>

                                                              <div
                                                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated cms_comment_delete"
                                                                aria-labelledby="dropdownMenuButton"
                                                              >
                                                                <div
                                                                  class="dropdown-item d-flex align-items-center dot-drop-color delete position-sticky"
                                                                  onClick={() => {
                                                                    deleteConfirm(
                                                                      itemhistory.id,
                                                                      1, false
                                                                    );
                                                                  }}
                                                                >
                                                                  <span className="">
                                                                    Delete
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </span>
                                                          </div>
                                                              </div>
                                                              <div className="suggestion_viewhistory mb-2">
                                                            <span dangerouslySetInnerHTML={{
                                                                __html: comment,
                                                              }}>
                                                            </span>
                                                            </div>

                                                          <div className="border-top"></div>
                                                          <div class="d-flex align-items-center justify-content-between mt-2 ">
                                                          {itemhistory.status ? (
                                                            <>
                                                              <span className="d-flex align-items-center fs-6 fw-5 line-hight24 dark_gray flex-wrap">
                                                                <div className="d-flex align-items-center">
                                                                <span className="completed-color fs-6 fw-5 line-hight24 ps-0">
                                                                  Suggestion accepted on
                                                                </span>
                                                                </div>
                                                              
                                                                <div className="d-flex align-items-center">
                                                                <span class="ms-1">
                                                                  {itemhistory.comment}
                                                                </span>
                                                                </div>
                                                              
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <span className="d-flex align-items-center fs-6 fw-5 line-hight24 dark_gray flex-wrap">
                                                                <span className="reject-color fs-6 fw-5 line-hight24 mx-2">
                                                                Suggestion rejected on
                                                                </span>
                                                                <div>
                                                                <span class="ms-1">
                                                                  {itemhistory.comment}
                                                                </span>
                                                                </div>
                                                              </span>
                                                            </>
                                                          )}
                                                       

                                                          </div>
                                                        </div>
                                                      </div>


                                                    </>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          <div className="global-scrollbar view-history_scroll  common_block_scrollbar">
                                          {
                                            Object.keys(suggestion).length > 0 ? 
                                            <>
                                              <div className="addcoment_block border_blue my-2 p-2">
                                                <div className="d-flex justify-content-between border-bottom pb-2">
                                                  <div className="suggestion_content">
                                                    <h4 className="dark_color fw-bold fs-6 m-0">
                                                    {localStorage.getItem(
                                                        "userName"
                                                      )}
                                                      <span className="date_time dark_gray ml-2">
                                                        {formateDateIntoDatemonthTime(
                                                            new Date()
                                                          )}{" "}
                                                      </span>
                                                    </h4>
                                                  {
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: suggestion.showText,
                                                      }}
                                                      
                                                    ></span>
                                                  }
                                                  </div>
                                                </div>

                                                <div className="button_group mt-3 text-right">
                                                  <button
                                                    className="btn btn-primary fs-6 fw-6"
                                                    onClick={() => {
                                                      Addsuggestion();
                                                    }}
                                                  >
                                                    Add suggestion
                                                  </button>

                                                  <button
                                                    className="btn btn-light ml-3 fs-6 fw-6 bg-white"
                                                    onClick={() => {
                                                      setbuttonclick(0);
                                                      document.getElementById("comment-icon").style.display = "none";
                                                      setIsCommentSuggestionAdded(null);
                                                      document.getElementById("suggestionblock").classList.remove("show");
                                                      document.getElementById("suggestionbutton").classList.add("collapsed");
                                                      localStorage.removeItem("highlightedObj");
                                                      // setInstruction("");
                                                      setEditor(true);
                                                      setInstruction(description);
                                                      setsuggestion("");
                                                    }}
                                                  >
                                                    cancel
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                            :
                                            <></>
                                          }
                                            {suggestionlist?.map(
                                              (item) => {
                                                let obj = {}
                                                let comment = '';
                                                if (item.suggestion) {
                                                  obj = JSON.parse(item.suggestion);
                                                  comment = 'replace "' + obj?.text + '" with "' + obj?.styleText + '"';
                                                }
                                                if (
                                                  item.status == null
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="addcoment_block border_blue my-2 p-2">
                                                        <div className="d-flex justify-content-between border-bottom pb-2">
                                                          <div className="" onClick={() => {
                                                            localStorage.removeItem("highlightedObj");
                                                            let joditEditorNodes = document.querySelector('.jodit-wysiwyg')?.children;
                                                            let range = new Range();
                                                            joditEditorNodes.forEach((node) => {
                                                              if(node.childNodes.length > 1) {
                                                                node.childNodes.forEach((item, index) => {
                                                                  let pTagValue = item.localName ? item.outerHTML : item.textContent;
                                                                  if(pTagValue.indexOf(obj.text) == obj.startIndex || pTagValue.indexOf(obj.text) ==  obj.endIndex) {
                                                                    let getStartEnd = [pTagValue.indexOf(obj.text), pTagValue.indexOf(obj.text) + obj.text.length];
                                                                    range.setStart(node.childNodes[index], getStartEnd[0]);
                                                                    range.setEnd(node.childNodes[index], getStartEnd[1]);
                                                                    document.getSelection().removeAllRanges();
                                                                    document.getSelection().addRange(range);
                                                                  }
                                                                })
                                                              } else {
                                                                let pTagValue = node.innerHTML;
                                                                if(pTagValue.indexOf(obj.text) == obj.startIndex || pTagValue.indexOf(obj.text) ==  obj.endIndex) {
                                                                  let getStartEnd = [pTagValue.indexOf(obj.text), pTagValue.indexOf(obj.text) + obj.text.length];
                                                                  range.setStart(node.childNodes[0], getStartEnd[0]);
                                                                  range.setEnd(node.childNodes[0], getStartEnd[1]);
                                                                  document.getSelection().removeAllRanges();
                                                                  document.getSelection().addRange(range);
                                                                }
                                                              }
                                                            });
                                                          }}>
                                                            <h4 className="dark_color fw-bold fs-6 m-0">
                                                              {
                                                                item
                                                                  ?.members
                                                                  ?.name
                                                              }
                                                              <span className="date_time dark_gray ml-2">
                                                                {formateDateIntoDatemonthTime(
                                                                  item.createdAt
                                                                )}{" "}
                                                              </span>
                                                            </h4>

                                                            <span className="suggestion_info" dangerouslySetInnerHTML={{ __html: comment }}></span>

                                                          </div>
                                                          <div className="d-flex justify-content-end">
                                                            <div>
                                                              <span class="dropdown">
                                                                <button className="kabab-btn"
                                                                  type="kabeb button"
                                                                  id="dropdownMenuButton"
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                >
                                                                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </button>

                                                                <div
                                                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated suggestion-delete"
                                                                  aria-labelledby="dropdownMenuButton"
                                                                >
                                                                  <div class="dropdown-item d-flex align-items-center dot-drop-color delete p-2">
                                                                    <span
                                                                      className=""
                                                                      onClick={() => {
                                                                        deleteConfirm(item.id, 1, false)
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                      <div className="button_group mt-3 text-right">
                                                        <button
                                                          className="btn btn-primary fs-6 fw-6"
                                                          onClick={() => {
                                                            setsuggestionhistory(
                                                              true
                                                            );
                                                              let newtext = description.replace(obj.text, obj.styleText);
                                                              setInstruction("")
                                                              setInstruction(newtext);
                                                              AddDescription(newtext);
                                                              suggestionstatus(
                                                                item.id, true);
                                                            
                                                          }}
                                                        >
                                                          <FeatherIcon className="me-2 suggestion_accept_btn" icon="check" />
                                                          Accept
                                                        </button>

                                                          <button
                                                            className="btn btn-light ml-3 fs-6 fw-6 suggestion-reject bg-white"
                                                            onClick={() => {
                                                              suggestionstatus(
                                                                item.id, false);
                                                              setsuggestionhistory(
                                                                true
                                                              );                                                            
                                                              setTimeout(() => {
                                                              getDescription()                                                                
                                                              }, 500);
                                                            }}
                                                          >
                                                            Reject
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {(buttonclick == 5 || buttonclick == 0) && Editor ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="keywordpoxi">
                                  <button
                                    ref={closesugBtn}
                                    class="accordion-button collapsed bg-white border-0 mt-3 checklist-arrow"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#keywordproxy"
                                    aria-expanded="true"
                                    aria-controls="keywordproxy"
                                    id="keywordproxybutton"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 5 : 0);
                                      buttonclick == 5 ? setInstruction(description) : setInstruction(text);
                                      document.querySelector('.jodit-wysiwyg').contentEditable = false;
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className="cms_left_menu m-0">
                                        {" "}
                                        Keyword Proximity ({proximityCount})
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                        onClick={() => {
                                         setbuttonclick(buttonclick == 0 ? 5 : 0);
                                         document.querySelector('.jodit-wysiwyg').contentEditable = true;
                                         setInstruction(description);
                                        }}
                                      />
                                    </div>
                                  </button>
                                </h2>
                                {proximityCount > 0 ? (
                                  <div
                                    id="keywordproxy"
                                    class="accordion-collapse collapse mt-2"
                                    aria-labelledby="keywordpoxi"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div class="accordion-body bg-white p-0">
                                      <div className="Comment_section">
                                        <div className="inner_version_history global-scrollbar view-history_scroll">
                                          {proximityText.map((item) => {
                                            return (
                                              <div className="addcoment_block red_border mt-3 p-2">
                                                <div className="comenter_name d-flex justify-content-between align-items-center p-2">
                                                  <h4 className="dark_color fw-bold fs-6 m-0">
                                                    "{item}"
                                                  </h4>
                                                </div>
                                                <p className="ps-2 pe-3 mb-0">
                                                  <span className="fst-italic me-1">
                                                    Keywords
                                                  </span>{" "}
                                                  <span className="keyword_proximity">
                                                    are close to each other, consider
                                                    spacing them out
                                                  </span>

                                                </p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {versionhistory == 1 ? (
                  <>
                    <div className="version_history">
                      <div className="inner_version_history">
                        <div className="top_header d-flex justify-content-between align-items-center pl-3">
                          <h4 className="cms_fs18 cms_fw600 cms_fc_dark m-0">
                            Version History
                          </h4>
                          <i
                            class="fa fa-close me-3 fs-6"
                            aria-hidden="true"
                            onClick={() => {
                              setversionhistory(0);
                              setbuttonclick(0);
                              setSelectedVersion(0);
                            }}
                          ></i>
                        </div>
                        <div className="current_version2 d-flex align-items-center py-3 px-3 rounded mt-4">
                          <div className="dot2"></div>
                          <div className="current_text cms_fs18 cms_fw600 pl-3">
                            Current Version
                          </div>
                        </div>
                        <div class="accordion mt-4  " id="accordionExample">
                          <div class="accordion-item border-0 version_history_scroll">
                            {versionHistoryList.length > 0 ? <>

                              <h2 class="accordion-header" id="headingOne">
                                <button
                                  class="accordion-button border-0 version-accord"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                  onClick={() => {
                                    if(selectedVersion !== 0)
                                    {
                                      setSelectedVersion(0);
                                      setInstruction(description);
                                    }
                                  }}
                                >
                                  {/* <div className="dot_blue mr-3 fs-6"></div> */}
                                  <span className="color_blue ms-4 ps-4 total-version fw-5">
                                    Total {versionCount} Version
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  {versionHistoryList?.map((item, index) => {
                                    return (
                                      <>
                                        <div tabindex= "1" className={selectedVersion == index ? "d-flex align-items-center justify-content-between date_detail active pt-3 ms-3 w-100" : "d-flex align-items-center justify-content-between date_detail  pt-3 ms-3 w-100"}
                                        onClick={()=>
                                          {selectversion(item.editorId)
                                            setSelectedVersion(index);
                                          }}> 
                                          <div className="ms-3">
                                            <h3 className="cms_fs16 cms_fw500 cms_fc_black">
                                              {formateDateIntoDatemonthTime(
                                                item.createdAt
                                              )}
                                            </h3>
                                            <p className="p-0 cms_fs14 cms_fw600 cms_fc_gray ">
                                              {item?.members?.name}
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center kabab-hover cursor-pointer ">
                                            <span class="dropdown">
                                              <button className="version_kabab_btn pe-3 mb-3"
                                                type="kabeb button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <i class="fa fa-ellipsis-v version-kabab" aria-hidden="true"></i>
                                              </button>

                                              <div
                                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <div class="dropdown-item d-flex align-items-center dot-drop-color delete" onClick={() => {
                                                  getlastestVersion(item.requirementId, item.version, item.editorId, item.editor, item.id)
                                                }}>
                                                  <span
                                                    className="restore-version position-relative"
                                                  >
                                                    Restore  version
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </div>
                                        </div>

                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </> : ''}


                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditorContent;