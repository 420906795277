/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import * as styles from "./styles"
import { useState } from "react";
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';
import Popper from '@semcore/ui/popper';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const MoreWidget = ({
    iconSize,
    isDelete = false,
    dataOptions = [
        { name: "Delete", icon: "trash-2", color: Colors.error[800], action: () => { } }
    ],
    show = true,
    disabled
}) => {
    const data = [
        { name: "Edit", icon: "edit", color: "black", action: () => { } },
        { name: "Delete", icon: "trash-2", color: "red", action: () => { } }
    ]
    const [visible, setVisible] = useState(false)
    return (
        <Popper placement="bottom-end" visible={visible} onVisibleChange={() => setVisible(!visible)}>
            <div css={{...(disabled && {pointerEvents: "none", opacity: "50%"})}}>
                <Popper.Trigger>
                    <FeatherIcon icon={"more-vertical"} size={iconSize} />
                </Popper.Trigger>
            </div>
            {show && <Popper.Popper zIndex={30000}>
                <div className="shadow" css={styles.dataContainer}>
                    {
                        dataOptions?.map((option, index) => {
                            return (
                                <div
                                    key={index}
                                    css={{ display: "flex", alignItems: "center", padding: "1rem" }}
                                    onClick={(e) => {
                                        option.action(e)
                                        setVisible(false)
                                    }}
                                >
                                    <div className="me-2">
                                        <FeatherIcon icon={option?.icon} size={16} color={option?.color} />
                                    </div>
                                    <div css={{ ...textStyles.s2Medium, color: option?.color }}>{option?.name}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </Popper.Popper>}
        </Popper>
    )
}

export default MoreWidget;