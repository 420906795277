import React from "react";
import { useEffect } from "react";
import APIManager from "../services/APIManager";
import { userResetPassword } from "../utility/Constant";

function ForgotPassword() {
  useEffect(() => {
    const paramss = new URLSearchParams(window.location.search);
    let email = paramss.get("email");
    let token = paramss.get("token");
    localStorage.setItem("userId", email);
    resetPassword(email, token);
  }, []);

  function resetPassword(email, token) {
    var bodyFormData = new FormData();
    bodyFormData.append("email", email.trim());
    bodyFormData.append("token", token.trim());
    APIManager.postAPI(userResetPassword + `?token=${token.trim()}&email=${email.trim()}`, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
        }
      }

    );
  }

  return (
    <>
      <div>Your password has been reset to default one.</div>
      <br />
      <div>
        Please <a href="/login">click</a> here to login
      </div>
    </>
  );
}

export default ForgotPassword;
