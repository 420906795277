import { css } from "@emotion/react";
import { textStyles } from "../../../../styles/font";
import { Colors } from "../../../../styles/colors";

export const mainContainer = (isCollapsed, isOpen) =>
  css({
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxShadow: "2px 4px 20px rgb(0 0 0 / 5%)",
    backgroundColor: "#ffffff",
    minWidth: isCollapsed ? "4.937rem" : "22.5rem",
    zIndex: 999,
    transition: "all 0.15s ease-in-out",
    "@media (max-width: 1024.98px)": {
      transform: isOpen ? "translateX(0)" : "translateX(-100%)",
    },
  });

export const headerContainer = (isCollapsed) =>
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: isCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: "0 1rem",
    height: "7rem",
    width: isCollapsed?"4.7rem":"100%",
    transition: "all 0.2s ease-in-out",
    overflow: "hidden",
  });

export const brandLogo = css({
  ...textStyles.H24Medium,
  cursor: "pointer",
});

export const collapseIcon = (isLocked) =>
  css({
    height: "1.25rem",
    width: "1.25rem",
    transition: "all 0.2s ease-in-out",
    rotate: isLocked ? "0deg" : "180deg",
  });

export const brandIcon = css({
  height: "1.5625rem",
  width: "1.5625rem",
});
export const brandIconExpanded = css({
  height: "5.75rem",
  color: Colors.neutral[800],
  display: "flex",
  alignItems: "center",
});
export const iconContainer = css({
  padding: "0.4rem",
  backgroundColor: "#EEEEEE",
  borderRadius: "0.31rem",
  cursor: "pointer",
  display: "flex",
});

export const brandIconContainer = css({
  padding: "0.4rem",
  cursor: "pointer",
  fontSize: "2.4rem",
  fontWeight: 700,
});

export const listContainer = css({
  padding: `0 1rem 1rem 1rem`,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  display: "flex",
  flexDirection: "column",
  rowGap: "0.75rem",
});

export const overlay = css({
  content: "''",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(33, 33, 33, 0.65)",
  zIndex: 1,
});

export const title = (isCollapsed) => {
  return css({
    width: isCollapsed?"2.7rem":"100%",
    transition: "all 0.2s ease-in-out",
    overflow: "hidden",
  });
};
