import { css } from '@emotion/react'
import { Colors } from '../../../../styles/colors';
import { textStyles } from '../../../../styles/font';

export const dataContainer = css({
    backgroundColor: Colors.plainWhite,
    cursor: "pointer",
    width: "12rem",
    marginTop: "0.5rem",
    borderRadius: "6px",
    // padding: "8px 10px"
})